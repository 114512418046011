import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { useFormik } from 'formik';
import { signUpValidationSchema } from '../validationSchema';
import Container from '../components/Container';
import Form from '../components/AuthorizationPagesForm';
import ResponseDialog from '../components/ResponseDialog';
import { useTranslate } from '../../../features/languages';

const SignUp = (props) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslate();
    const { staticForm } = props;

    const registerUser = ({ name, email, pass }) => {
        const { register } = props.redux.actions.GenericActions;
        register(name, email, pass, setOpen, setIsLoading);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            pass: '',
        },
        validationSchema: signUpValidationSchema,
        onSubmit: registerUser,
    });

    return (
        <Container title={t('ezlo.sign_up_page.title.create_new_account')} staticForm={staticForm}>
            <Form isLoading={isLoading} staticForm={staticForm} isSignUp formik={formik} />
            <ResponseDialog open={open} setOpen={setOpen} isSignUp />
        </Container>
    );
};

const mapStateToProps = (state) => ({
    data: state,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};
    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SignUp);
