import React from 'react';

import { PASSWORD_REQUIREMENTS } from '../../../../../constants/Users';
import PaperPasswordRequirements from './PaperPasswordRequirements';
import { isRequirementDone } from '../../../../../components/PasswordRequirements/utils';

import passRequirementsStyles from '../../../../../components/PasswordRequirements/style.module.scss';

export const NewPasswordRequirements = ({ passwordValue }) => {
    const getRequirementClassName = (requirement) => {
        if (isRequirementDone(requirement, passwordValue)) {
            return passRequirementsStyles.requirementFulfilled__green;
        }

        return '';
    };

    return (
        <PaperPasswordRequirements
            passwordRequirements={PASSWORD_REQUIREMENTS}
            getRequirementClassName={getRequirementClassName}
        />
    );
};
