import * as types from '../constants/ActionTypes/Backups';
import * as at from '../constants/ActionTypes';
import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
    MAIN_CONTENT_VIEW,
    CREATE_BACKUP_STEPS,
    RESTORE_BACKUP_STEPS,
} from '../containers/Ezlo/EzloHubBackups/constants';

const defaultState = {
    isBackupSupported: true,
    token: null, // token for auth to fetch 'storage_list'
    selectedBackup: null, // selected backup to restore
    backups: [], // list of fetched backups
    isBackupsLoading: true,
    fetchBackupsError: null,
    view: MAIN_CONTENT_VIEW.RESTORE, // 'create' OR 'restore' screens flag, 'create' by default
    createBackupStep: CREATE_BACKUP_STEPS.CREATE_BACKUP,
    restoreBackupStep: RESTORE_BACKUP_STEPS.RESTORE_BACKUP,
    isNewBackupInProgress: false,
    isNewBackupCompleted: false,
    newBackupError: null,
    isRestoreBackupConfirmed: false,
    isRestoreBackupInProgress: false,
    isRestoreBackupCompleted: false,
    restoreBackupError: false,
    createBackupProgressValue: 0,
    restoreBackupProgressValue: 0,
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case types.BACKUPS_FETCH_DEVICE_BACKUPS: {
            return update(state, {
                isBackupsLoading: { $set: true },
                backups: { $set: [] },
                fetchBackupsError: { $set: null },
            });
        }
        case types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS: {
            return update(state, {
                isBackupsLoading: { $set: false },
                backups: { $set: action.backups },
                fetchBackupsError: { $set: null },
            });
        }
        case types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR: {
            return update(state, {
                isBackupsLoading: { $set: false },
                backups: { $set: [] },
                fetchBackupsError: { $set: action.error },
            });
        }
        case types.BACKUPS_CREATE_BACKUP: {
            return update(state, {
                createBackupStep: { $set: CREATE_BACKUP_STEPS.IN_PROGRESS },
                isNewBackupInProgress: { $set: true },
                isNewBackupCompleted: { $set: false },
                view: { $set: MAIN_CONTENT_VIEW.CREATE },
            });
        }
        case types.BACKUPS_CREATE_BACKUP_IN_PROGRESS: {
            return update(state, {
                createBackupProgressValue: { $set: action.result.completed },
                view: { $set: MAIN_CONTENT_VIEW.CREATE },
            });
        }
        case types.BACKUPS_CREATE_BACKUP_ON_SUCCESS: {
            return update(state, {
                createBackupStep: { $set: CREATE_BACKUP_STEPS.BACKUP_COMPLETED },
                isNewBackupInProgress: { $set: false },
                isNewBackupCompleted: { $set: true },
                newBackupError: { $set: null },
            });
        }
        case types.BACKUPS_CREATE_BACKUP_ON_ERROR: {
            return update(state, {
                createBackupStep: { $set: CREATE_BACKUP_STEPS.BACKUP_ERROR },
                isNewBackupInProgress: { $set: false },
                isNewBackupCompleted: { $set: false },
                newBackupError: { $set: action.error },
                view: { $set: MAIN_CONTENT_VIEW.CREATE },
            });
        }

        case types.BACKUPS_ON_FINISH: {
            return update(state, {
                view: { $set: MAIN_CONTENT_VIEW.RESTORE },
                createBackupStep: { $set: CREATE_BACKUP_STEPS.CREATE_BACKUP },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.RESTORE_BACKUP },
                isNewBackupInProgress: { $set: false },
                isNewBackupCompleted: { $set: false },
                newBackupError: { $set: null },
                isRestoreBackupConfirmed: { $set: false },
                isRestoreBackupInProgress: { $set: false },
                isRestoreBackupCompleted: { $set: false },
                restoreBackupError: { $set: false },
            });
        }
        case types.BACKUPS_SELECT_BACKUP: {
            return update(state, {
                view: { $set: MAIN_CONTENT_VIEW.RESTORE },
                selectedBackup: { $set: action.selectedBackup },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.RESTORE_BACKUP },
            });
        }
        case types.BACKUPS_CLEAR_SELECTED_BACKUP: {
            return update(state, {
                selectedBackup: { $set: null },
            });
        }
        case types.BACKUPS_SELECT_DEFAULT_BACKUP: {
            return update(state, {
                view: { $set: MAIN_CONTENT_VIEW.RESTORE },
                selectedBackup: { $set: action.selectedBackup },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.RESTORE_BACKUP },
            });
        }
        case types.BACKUPS_RESTORE_BACKUP: {
            return update(state, {
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.CONFIRM_RESTORING },
            });
        }
        case types.BACKUPS_RESTORE_BACKUP_CONFIRM: {
            return update(state, {
                isRestoreBackupInProgress: { $set: true },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.IN_PROGRESS },
            });
        }
        case types.BACKUPS_RESTORE_BACKUP_IN_PROGRESS: {
            return update(state, {
                isRestoreBackupInProgress: { $set: true },
                restoreBackupProgressValue: { $set: action.result.completed },
            });
        }
        case types.BACKUPS_RESTORE_BACKUP_ON_SUCCESS: {
            return update(state, {
                isRestoreBackupInProgress: { $set: false },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.BACKUP_RESTORED },
                isRestoreBackupCompleted: { $set: true },
            });
        }
        case types.BACKUPS_RESTORE_BACKUP_ON_ERROR: {
            return update(state, {
                isRestoreBackupInProgress: { $set: false },
                isRestoreBackupCompleted: { $set: false },
                view: { $set: RESTORE_BACKUP_STEPS.BACKUP_ERROR },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.RESTORE_BACKUP },
            });
        }
        case types.BACKUPS_IS_BACKUP_SUPPORTED: {
            let spec = { isBackupSupported: { $set: action.isBackupSupported } };
            if (!action.isBackupSupported) {
                spec = { ...spec, view: { $set: MAIN_CONTENT_VIEW.NOT_SUPPORTED } };
            } else {
                spec = { ...spec, view: { $set: MAIN_CONTENT_VIEW.RESTORE } };
            }

            return update(state, spec);
        }
        case LOCATION_CHANGE: {
            return update(state, {
                selectedBackup: { $set: null },
                token: { $set: null },
                isBackupsLoading: { $set: true },
                isBackupSupported: { $set: true },
                backups: { $set: [] },
                fetchBackupsError: { $set: null },
                view: { $set: MAIN_CONTENT_VIEW.RESTORE },
                createBackupStep: { $set: CREATE_BACKUP_STEPS.CREATE_BACKUP },
                restoreBackupStep: { $set: RESTORE_BACKUP_STEPS.RESTORE_BACKUP },
                isNewBackupInProgress: { $set: false },
                isNewBackupCompleted: { $set: false },
                newBackupError: { $set: null },
                isRestoreBackupConfirmed: { $set: false },
                isRestoreBackupInProgress: { $set: false },
                isRestoreBackupCompleted: { $set: false },
                restoreBackupError: { $set: false },
            });
        }
        case at.LOGOUT: {
            return defaultState;
        }
        default:
            return state;
    }
}
