import React, { useEffect } from 'react';

import { EXPRESSIONS_TYPE } from '../../../constants/Expressions';
// import { GET_OFFLINE_EZLO } from '../../../constants/URLs';

import './styles.scss';

import ExpressionsLayout from './ExpressionsLayout';

const EzloExpressions = (props) => {
    const { offline } = props;
    // const { ezlos } = props.redux.state;

    useEffect(() => {
        if (offline) {
            //TODO: must be fixed, because throw the error here!
            // history.push(GET_OFFLINE_EZLO(ezlos[0].serial));
        }
    }, [offline]);

    return <ExpressionsLayout expressionType={EXPRESSIONS_TYPE.EXPRESSION} />;
};

export default EzloExpressions;
