import { apiGetAllUser } from '../api/apiScenes';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import at from '../constants/ActionTypes/Users';
import {
    pinCodeAPI,
    allowAccessOnControllersAPI,
    createNewUserAPI,
    deleteSelectedUserAPI,
    getUserDataFromIdentity,
    getUserDetailsAPI,
    getUserPinCodesAPI,
    updateUserAPI,
    changeUserPasswordAPI,
    resendEmailAPI,
} from '../api/apiUsers';
import { ACCOUNT_ADMIN_PERMISSION_ROLE, DATA_TYPES, PIN_CODE_API_ACTIONS } from '../constants/Users';
import EzloActions from '../actions/EzloActions';
import ItemActions from '../actions/ItemActions';
import wsm from '../helpers/wsm';
import {
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // buildCreatePinCodeParams,
    // buildUpdatePinCodeParams,
    getDevicesOfSelectedPinCode,
    getIsLoggedInUser,
    getPinCodeParams,
    getPinCodeValue,
} from '../containers/Ezlo/EzloAccount/UserManagement/utils';
import GenericActions from './GenericActions';
import { toast, TOAST_TYPE } from '../components/Toast';
import {
    EZLOGIC_TITLE_ACCESS_CODE_HASNT_REMOVED,
    EZLOGIC_TITLE_ACCESS_CODE_HASNT_UPDATED,
    EZLOGIC_TITLE_ACCESS_CODE_WASNT_ADDED,
    EZLOGIC_TITLE_CREATED_SUCCESSFULLY,
    EZLOGIC_TITLE_DELETED_SUCCESSFULLY,
    EZLOGIC_TITLE_PASSWORD_CHANGED_SUCCESSFULLY,
    EZLOGIC_TITLE_PLEASE_VERIFY_YOUR_EMAIL,
    EZLOGIC_TITLE_UPDATED_SUCCESSFULLY,
} from '../constants/language_tokens';
import { TOAST_MESSAGE_ON_ERROR } from '../constants/toasts';
import { ERROR_STATUS, SUCCESS_STATUS } from '../constants/api';
// todo [ENWT-3630] User Pin Codes updates. Implementation.
// import { isEmpty, isString } from 'lodash';
import { responseErrorHandling } from '../services/utilityService';

const UsersActions = {
    setUserList: () => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));
            // we get all users who are on the account, including the user of the current account
            const usersList = await apiGetAllUser();

            if (usersList) {
                await dispatch({ type: at.SET_USERS_LIST.success, usersList });
            }
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_USERS_LIST.rejected });
        } finally {
            dispatch(UsersActions.setIsLoading(false));
        }
    },

    createNewUser: (userData, t) => async (dispatch, getState) => {
        try {
            const { account } = getState();
            const PK_Account_Parent = account.data.PK_Account;
            const isAccountAdministrator = userData.update.PK_PermissionRole === ACCOUNT_ADMIN_PERMISSION_ROLE;

            dispatch(UsersActions.setIsLoading(true));

            // create user
            const createdUser = await createNewUserAPI(userData.create, PK_Account_Parent);

            // we get its Identity from the user creation response and decrypt it to get PK_User
            const { PK_User } = getUserDataFromIdentity(createdUser.data.Identity);

            // update first name, last name for created user
            await updateUserAPI(userData.update, PK_User);

            // allow the created user to have access to the selected controllers
            await allowAccessOnControllersAPI(userData.allowAccessOnControllers, PK_User, isAccountAdministrator);

            await dispatch(
                UsersActions.setRequestSuccessStatus(
                    `${userData.create.Username} ${t(EZLOGIC_TITLE_CREATED_SUCCESSFULLY)}`,
                ),
            );
        } catch (error) {
            // handle the error and display the error text to the user
            dispatch(UsersActions.handlingErrors(at.CREATE_NEW_USER.rejected, error));
        } finally {
            await dispatch(UsersActions.setIsLoading(false));
        }
    },

    // updating created user on edit page
    updateUser: (userData, selectedUser, t) => async (dispatch, getState) => {
        try {
            const { account } = getState();
            const isLoggedInUser = getIsLoggedInUser(account, selectedUser);
            const { update, allowAccessOnControllers } = userData;
            const isAccountAdministrator = update.PK_PermissionRole === ACCOUNT_ADMIN_PERMISSION_ROLE;

            dispatch(UsersActions.setIsLoading(true));

            // update first name, last name for an existing user
            await updateUserAPI(userData.update, selectedUser.PK_User);

            // allow the created user to have access to the selected controllers (if it's not a logged in user)
            if (!isLoggedInUser) {
                await allowAccessOnControllersAPI(
                    allowAccessOnControllers,
                    selectedUser.PK_User,
                    isAccountAdministrator,
                );
            }

            await dispatch(
                UsersActions.setRequestSuccessStatus(
                    `${selectedUser.Username} ${t(EZLOGIC_TITLE_UPDATED_SUCCESSFULLY)}`,
                ),
            );
        } catch (error) {
            // handle the error and display the error text to the user
            dispatch(UsersActions.handlingErrors(at.UPDATE_EXISTING_USER.rejected, error));
        } finally {
            await dispatch(UsersActions.setIsLoading(false));
        }
    },

    // deleting a user
    deleteSelectedUser: (selectedUser, t) => async (dispatch, getState) => {
        try {
            const { account } = getState();
            const PK_Account_Parent = account.data.PK_Account;

            // deleting a user
            await deleteSelectedUserAPI(PK_Account_Parent, selectedUser.PK_User);

            // user list update
            await dispatch(UsersActions.setUserList());
            await dispatch(
                UsersActions.setRequestSuccessStatus(
                    `${selectedUser.Username} ${t(EZLOGIC_TITLE_DELETED_SUCCESSFULLY)}`,
                ),
            );
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.DELETE_SELECTED_USER.rejected });
        }
    },

    // getting/storing in redux data about the selected user to identify the 'Devices' that are available to him
    setSelectedUserDetails: (selectedUser) => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));

            // get/save the details of the selected user
            const { data: userDetails } = await getUserDetailsAPI(selectedUser.PK_User);
            await dispatch({ type: at.SET_SELECTED_USER_DETAILS, userDetails });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.DELETE_SELECTED_USER.rejected });
        } finally {
            dispatch(UsersActions.setIsLoading(false));
        }
    },

    setUserPinCodesList: () => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));

            const userPinCodesList = await getUserPinCodesAPI();

            if (userPinCodesList) {
                dispatch({ type: at.SET_USER_PIN_CODES_LIST.success, userPinCodesList });
            }
        } catch (error) {
            dispatch(UsersActions.handlingErrors(at.SET_USER_PIN_CODES_LIST.rejected, error));
        } finally {
            dispatch(UsersActions.setIsLoading(false));
        }
    },
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    createPinCode: (pinCodeData, selectedDevices, t) => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));
            // save the pin code on the selected devices and get a list of devices
            // on which the pin code was successfully saved
            const listOfDevicesWithAddedPin = await dispatch(
                UsersActions.addPinCodeToSelectedDevices(selectedDevices, pinCodeData, t),
            );

            const deviceIds = listOfDevicesWithAddedPin.map(({ _id }) => _id);
            pinCodeData.meta.deviceIds = deviceIds;
            // save pin code on cloud
            const response = await pinCodeAPI(PIN_CODE_API_ACTIONS.CREATE, pinCodeData);
            responseErrorHandling(response);
            await dispatch(
                UsersActions.setRequestSuccessStatus(`${pinCodeData.name} ${t(EZLOGIC_TITLE_CREATED_SUCCESSFULLY)}`),
            );
        } catch (error) {
            dispatch(UsersActions.handlingErrors(at.CREATE_PIN_CODE.rejected, error));
        } finally {
            // update items on controllers
            dispatch(UsersActions.updateItemsData());
            dispatch(UsersActions.setIsLoading(false));
        }
    },
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // createPinCode: (pinCodeData, t) => async (dispatch, getState) => {
    //     try {
    //         dispatch(UsersActions.setIsLoading(true));
    //         const { groups } = getState();
    //         const params = buildCreatePinCodeParams(pinCodeData, groups.allControllersInfo);
    //
    //         // save pin code on cloud
    //         const response = await pinCodeAPI(PIN_CODE_API_ACTIONS.CREATE, params);
    //         responseErrorHandling(response);
    //         await dispatch(
    //             UsersActions.setRequestSuccessStatus(`${pinCodeData.name} ${t(EZLOGIC_TITLE_CREATED_SUCCESSFULLY)}`),
    //         );
    //     } catch (error) {
    //         dispatch(UsersActions.handlingErrors(at.CREATE_PIN_CODE.rejected, error));
    //     } finally {
    //         dispatch(UsersActions.setIsLoading(false));
    //     }
    // },
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    updatePinCode: (pinCodeData, devices, currentName, t) => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));
            // a bunch of requests (adding, updating, deleting pin codes) to selected devices when editing
            // pin codes and return a list of devices with successful addition, update of the pin code
            const listOfDevicesWithUpdatePin = await dispatch(
                UsersActions.updatePinCodeToSelectedDevices(devices, pinCodeData, t),
            );

            const deviceIds = listOfDevicesWithUpdatePin.map(({ _id }) => _id);
            pinCodeData.meta.deviceIds = deviceIds;
            // update pin code data on cloud
            const response = await pinCodeAPI(PIN_CODE_API_ACTIONS.UPDATE, pinCodeData);
            responseErrorHandling(response);
            await dispatch(
                UsersActions.setRequestSuccessStatus(`${currentName} ${t(EZLOGIC_TITLE_UPDATED_SUCCESSFULLY)}`),
            );
        } catch (error) {
            dispatch(UsersActions.handlingErrors(at.UPDATE_PIN_CODE.rejected, error));
        } finally {
            // update items on controllers
            dispatch(UsersActions.updateItemsData());
            dispatch(UsersActions.setIsLoading(false));
        }
    },
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // update pin code when editing
    // updatePinCode: (pinCodeUuid, pinCodeFormValues, currentName, t) => async (dispatch, getState) => {
    //     try {
    //         dispatch(UsersActions.setIsLoading(true));
    //         const { groups } = getState();
    //         const params = buildUpdatePinCodeParams(pinCodeUuid, pinCodeFormValues, groups.allControllersInfo);
    //
    //         // update pin code data on cloud
    //         const response = await pinCodeAPI(PIN_CODE_API_ACTIONS.UPDATE, params);
    //         responseErrorHandling(response);
    //         await dispatch(
    //             UsersActions.setRequestSuccessStatus(`${currentName} ${t(EZLOGIC_TITLE_UPDATED_SUCCESSFULLY)}`),
    //         );
    //     } catch (error) {
    //         dispatch(UsersActions.handlingErrors(at.UPDATE_PIN_CODE.rejected, error));
    //     } finally {
    //         dispatch(UsersActions.setIsLoading(false));
    //     }
    // },
    // update items on controllers
    updateItemsData: () => async (dispatch, getState) => {
        const { ezlo } = getState();
        for (const serial in ezlo.data) {
            if (ezlo.data[serial].isConnected) {
                const newItems = await dispatch(EzloActions.getItems(serial));
                await dispatch(ItemActions.updateItems(serial, newItems.items));
            }
        }
    },

    processingResultOfRemovingPinCode: (response, pinCodeName, t) => async (dispatch) => {
        if (response.data.status === ERROR_STATUS) {
            const errorMessage = response.data.data?.error_message || t(TOAST_MESSAGE_ON_ERROR);
            toast(errorMessage, { type: TOAST_TYPE.ERROR });
        }

        if (response.data.status === SUCCESS_STATUS) {
            await dispatch(
                UsersActions.setRequestSuccessStatus(`${pinCodeName} ${t(EZLOGIC_TITLE_DELETED_SUCCESSFULLY)}`),
            );
        }
    },

    deletePinCodeOnCloud: (pinCodeData, t) => async (dispatch) => {
        const { uuid } = pinCodeData;
        const response = await pinCodeAPI(PIN_CODE_API_ACTIONS.DELETE, { uuid });
        dispatch(UsersActions.processingResultOfRemovingPinCode(response, pinCodeData.name, t));
    },
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    deletePinCode: (pinCodeData, t) => async (dispatch, getState) => {
        try {
            const { ezlo } = getState();
            dispatch(UsersActions.setIsLoading(true));
            // find all devices on which the selected pin code is saved
            const devicesOfSelectedPinCode = getDevicesOfSelectedPinCode(ezlo.data, pinCodeData);
            // remove the pin code from all devices on which it is saved
            await dispatch(UsersActions.removePinCodeToDevices(devicesOfSelectedPinCode, pinCodeData, t));
            // delete pin code on cloud
            await dispatch(UsersActions.deletePinCodeOnCloud(pinCodeData, t));
            // updating the list of all pin codes
            await dispatch(UsersActions.setUserPinCodesList());
        } catch (error) {
            dispatch(UsersActions.handlingErrors(at.DELETE_PIN_CODE.rejected, error));
        } finally {
            // update items on controllers
            dispatch(UsersActions.updateItemsData());
            dispatch(UsersActions.setIsLoading(false));
        }
    },
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // deletePinCode: (pinCodeData, t) => async (dispatch, getState) => {
    //     try {
    //         dispatch(UsersActions.setIsLoading(true));
    //
    //         // Support for created PIN codes of the first version (start)
    //         const pinMeta = isString(pinCodeData.meta) ? JSON.parse(pinCodeData.meta) : {};
    //         if (pinMeta.deviceIds && !isEmpty(pinMeta.deviceIds)) {
    //             const { ezlo } = getState();
    //             // find all devices on which the selected pin code is saved
    //             const devicesOfSelectedPinCode = getDevicesOfSelectedPinCode(ezlo.data, pinCodeData);
    //             // remove the pin code from all devices on which it is saved
    //             await dispatch(UsersActions.removePinCodeToDevices(devicesOfSelectedPinCode, pinCodeData, t));
    //         }
    //         // Support for created PIN codes of the first version (end)
    //
    //         // delete pin code on cloud
    //         await dispatch(UsersActions.deletePinCodeOnCloud(pinCodeData, t));
    //         // updating the list of all pin codes
    //         await dispatch(UsersActions.setUserPinCodesList());
    //     } catch (error) {
    //         dispatch(UsersActions.handlingErrors(at.DELETE_PIN_CODE.rejected, error));
    //     } finally {
    //         dispatch(UsersActions.setIsLoading(false));
    //     }
    // },

    // clearing redux state from selected user data
    clearSelectedUserDetails: () => (dispatch) => {
        dispatch({ type: at.SET_SELECTED_USER_DETAILS, userDetails: null });
    },

    clearUsersList: () => (dispatch) => {
        dispatch({ type: at.SET_USERS_LIST.success, usersList: [] });
    },

    clearSelectedUserPinCodes: () => (dispatch) => {
        dispatch({ type: at.SET_USER_PIN_CODES_LIST.success, userPinCodesList: [] });
    },

    // handle the error and display the error text to the user
    handlingErrors: (type, error) => () => {
        if (typeof error?.response?.data === DATA_TYPES.STRING) {
            // if the response received a detailed description of the error, then redefine error.message
            error.message = error?.response?.data;
        }
        bugsnagNotifyWrapper(error, { type });
    },

    setRequestSuccessStatus: (message) => (dispatch) => {
        dispatch({ type: at.SET_REQUEST_STATUS.success, message });
    },

    clearRequestStatus: () => (dispatch) => {
        dispatch({ type: at.SET_REQUEST_STATUS.clear });
    },

    setSelectedUser: (user) => (dispatch) => {
        dispatch({ type: at.SET_SELECTED_USER, user });
    },

    setSelectedPinCode: (pinCode) => (dispatch) => {
        dispatch({ type: at.SET_SELECTED_PIN_CODE, pinCode });
    },

    setIsLoading: (isLoading) => (dispatch) => {
        dispatch({ type: at.SET_IS_LOADING, isLoading });
    },
    // a bunch of requests to add a new pin code to selected devices
    addPinCodeToSelectedDevices: (selectedDevices, pinCodeData, t) => async (dispatch) => {
        const listOfDevicesWithAddedPin = [];
        const value = getPinCodeValue(pinCodeData);

        const dispatchListOfAddPinCodeToDevice = selectedDevices.map((device) => {
            return dispatch(UsersActions.addPinCodeToDevice(value, device, listOfDevicesWithAddedPin, t));
        });

        await Promise.all(dispatchListOfAddPinCodeToDevice);

        return listOfDevicesWithAddedPin;
    },
    // adding a new pin code to the selected device
    addPinCodeToDevice: (value, device, listOfDevicesWithAddedPin, t) => async () => {
        const { serial, itemId } = device;

        await wsm.send(
            serial,
            'hub.item.dictionary.value.add',
            getPinCodeParams(PIN_CODE_API_ACTIONS.ADD, { itemId, value }),
            () => {
                listOfDevicesWithAddedPin.push(device);
            },
            (error) => {
                error.message = `${t(EZLOGIC_TITLE_ACCESS_CODE_WASNT_ADDED)} ${device.name}. ${error.message}`;
                const errorData = {
                    type: at.ADD_PIN_CODE_TO_DEVICE.rejected,
                    serial,
                    itemId,
                    deviceName: device.name,
                    pinCodeValue: value,
                };
                bugsnagNotifyWrapper(error, errorData);
            },
        );
    },
    // a bunch of requests (adding/updating/deleting pin codes) to selected devices when editing pin codes
    updatePinCodeToSelectedDevices: (selectedDevices, pinCodeData, t) => async (dispatch) => {
        const listOfDevicesWithAddedPin = [];
        const value = getPinCodeValue(pinCodeData);

        const dispatchListOfUpdatePinCodeToDevice = selectedDevices.map((device) => {
            if (device.isRemovePinCodeFromDevice) {
                return dispatch(UsersActions.removePinCodeToDevice(device, listOfDevicesWithAddedPin, t));
            }

            return device.pinCodeKey
                ? dispatch(UsersActions.updatePinCodeToDevice(value, device, listOfDevicesWithAddedPin, t))
                : dispatch(UsersActions.addPinCodeToDevice(value, device, listOfDevicesWithAddedPin, t));
        });

        await Promise.all(dispatchListOfUpdatePinCodeToDevice);

        return listOfDevicesWithAddedPin;
    },
    // update pin code on one device
    updatePinCodeToDevice: (value, device, listOfDevicesWithAddedPin, t) => async () => {
        const { serial, itemId, pinCodeKey } = device;

        await wsm.send(
            serial,
            'hub.item.dictionary.value.set',
            getPinCodeParams(PIN_CODE_API_ACTIONS.SET, { itemId, value, pinCodeKey }),
            () => {
                listOfDevicesWithAddedPin.push(device);
            },
            (error) => {
                error.message = `${t(EZLOGIC_TITLE_ACCESS_CODE_HASNT_UPDATED)} ${device.name}. ${error.message}`;
                const errorData = {
                    type: at.UPDATE_PIN_CODE_TO_DEVICE.rejected,
                    serial,
                    itemId,
                    pinCodeKey,
                    deviceName: device.name,
                    pinCodeValue: value,
                };
                bugsnagNotifyWrapper(error, errorData);
            },
        );
    },
    // deleting a pin code on one device
    removePinCodeToDevice: (device, value, t) => async () => {
        const { serial, itemId, pinCodeKey } = device;

        await wsm.send(
            serial,
            'hub.item.dictionary.value.remove',
            getPinCodeParams(PIN_CODE_API_ACTIONS.REMOVE, { itemId, pinCodeKey }),
            () => {},
            (error) => {
                if (error?.message) {
                    error.message = `${t(EZLOGIC_TITLE_ACCESS_CODE_HASNT_REMOVED)} ${device.name}. ${error.message}`;
                }

                const errorData = {
                    type: at.REMOVE_PIN_CODE_TO_DEVICE.rejected,
                    serial,
                    itemId,
                    pinCodeKey,
                    deviceName: device.name,
                };
                bugsnagNotifyWrapper(error, errorData);
            },
        );
    },
    // deleting a PIN code on all devices on which it was saved
    removePinCodeToDevices: (devicesOfSelectedPinCode, pinCodeData, t) => async (dispatch) => {
        const dispatchListOfRemovePinCodeToDevices = devicesOfSelectedPinCode.map((device) => {
            return dispatch(UsersActions.removePinCodeToDevice(device, pinCodeData, t));
        });

        return await Promise.all(dispatchListOfRemovePinCodeToDevices);
    },

    changeUserPassword: (changePasswordData, t) => async (dispatch) => {
        try {
            dispatch(UsersActions.setIsLoading(true));

            await changeUserPasswordAPI(changePasswordData);
            await dispatch(UsersActions.setRequestSuccessStatus(t(EZLOGIC_TITLE_PASSWORD_CHANGED_SUCCESSFULLY)));
            await dispatch(GenericActions.logout());
        } catch (error) {
            // handle the error and display the error text to the user
            dispatch(UsersActions.handlingErrors(at.CHANGE_USER_PASSWORD.rejected, error));
        } finally {
            await dispatch(UsersActions.setIsLoading(false));
        }
    },
    resendEmail: (PK_User, t) => async (dispatch, getState) => {
        try {
            const {
                users: { selectedUser },
            } = getState();
            await resendEmailAPI(selectedUser.PK_User);
            await toast(t(EZLOGIC_TITLE_PLEASE_VERIFY_YOUR_EMAIL), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.RESEND_EMAIL, PK_User });
        }
    },
};

export default UsersActions;
