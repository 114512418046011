import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { parseMTML } from '../../utils';
import { AGREE_TO_END_USER_LICENSE_AGREEMENT, READ_AND_AGREE_TO_END_USER_AGREEMENT } from '../../constants';

import styles from '../../components/EzloPoliciesLayout/ezloPolicies.module.scss';

const Eula = ({ policies, onAccept, onNext, accepted, buttonTitle }) => {
    const html = parseMTML(policies?.eulaContent);

    return (
        <div className={styles.innermain}>
            <div className={styles.title}>{AGREE_TO_END_USER_LICENSE_AGREEMENT}</div>
            <div>
                <div className={styles.text}>
                    <p dangerouslySetInnerHTML={{ __html: html }}></p>
                </div>

                <div className={styles.footer}>
                    <FormControlLabel
                        className={styles.formControl}
                        control={<Checkbox onChange={onAccept} className={styles.checkboxControl} color="primary" />}
                        label={<span className={styles.label}>{READ_AND_AGREE_TO_END_USER_AGREEMENT}</span>}
                    />

                    <div className={styles.button_wrapper}>
                        <Button
                            className={styles.button}
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={onNext}
                            disabled={!accepted}
                        >
                            {buttonTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Eula;
