import React from 'react';
import Button from '@material-ui/core/Button';

import { LARGE } from '../../../../../constants/DeviceAssociations';
import { OUTLINED } from '../../../../../constants/Plugins';
import { PRIMARY } from '../../../../../constants/Devices';
import {
    EZLOGIC_TITLE_INSTALL_PLUGIN,
    EZLOGIC_TITLE_IP_GENERATOR_IS_NOT_INSTALLED,
} from '../../../../../constants/language_tokens';

import { useTranslate } from '../../../../../features/languages';

import styles from '../../PluginWifiGenerator/pluginWifiGenerato.module.scss';

const DeviceGeneratorNotInstalled = ({ handleRouteToPlugin }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.wifiGenerator__notActive}>
            <div className={styles.wifiGenerator__notActive_body}>
                <h3>{t(EZLOGIC_TITLE_IP_GENERATOR_IS_NOT_INSTALLED)}</h3>
                <Button size={LARGE} variant={OUTLINED} color={PRIMARY} onClick={handleRouteToPlugin}>
                    {t(EZLOGIC_TITLE_INSTALL_PLUGIN)}
                </Button>
            </div>
        </div>
    );
};

export default DeviceGeneratorNotInstalled;
