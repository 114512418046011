// Сommented out logic for cloud meshbot in combination with local meshbot exclude goToNewRule 166 rows
// TODO refactore as soon as cloud Meshbot work
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import ModalCreate from './components/modalCreate';
import ListHeader from './components/ListHeader';
import { CustomGridProTable } from '../../../components/Table';
import EmptyMeshBotsTableOverlay from './components/EmptyMeshBotsTableOverlay';

import {
    MESH_BOT_NOTIFICATION_NEW,
    MESH_BOT_LOCAL_NEW,
    MESH_BOT_CLOUD_NEW,
    MESH_BOT_INTERACTION_NEW,
} from '../../../constants/URLs';
import { MESHBOT_TYPES } from './constants';
import { CODE, STATUSES } from '../../../constants/Plugins';
import { setLocalStorage } from '../../../helpers/helpersMeshBot';
import { isControllerOffline, getOnlineControllers } from '../utils';
import { Header } from '../../../components';
import BoxWithLinearProgress from '../../../components/BoxWithLinearProgress';

import {
    EZLOGIC_TITLE_YOUR_MESHBOT_WILL_APEAR,
    EZLOGIC_TITLE_YOU_HAVE_NO_MESHBOT_YET,
} from '../../../constants/language_tokens';
import { FAILED, FINISHED, STARTED } from '../../../constants/MeshbotConstant';
import useMeshbotsPageConfig from './hooks/useMeshbotsPageConfig';
import { MeshBotLabelsPanel } from '../../../features/Labels/MeshBotsLabels';
import useMeshBotsState from './hooks/useMeshBotsState';
import MeshBotDragDropContainer from './components/MeshBotDragDropContext';
import { MeshBotsTableContextProvider } from './hooks/useMeshBotsTableContext';
import MeshBotsTableToolbar from './components/MeshBotsTableToolbar';
import useSelectedMeshBotsState from './hooks/useSelectedMeshBotsState';
import SelectedMeshBotsCheckbox from './components/SelectedMeshBotsCheckbox';
import useMeshBotTablePagination from './hooks/useMeshBotTablePagination';

import './style.scss';
import styles from './styles.module.scss';

const EzloMeshBots = (props) => {
    const {
        isShowModalCreateRule,
        rules,
        MainAction,
        MeshBotAction,
        history,
        ezlo,
        cloudMeshBot,
        PluginActions,
        isMeshBotsLoading,
    } = props;
    const isControllerDisabled = useMemo(() => isControllerOffline(ezlo.data), [ezlo.data]);
    const { columns, density, meshBotsTableState, handleMeshBotsTableStateChange, systemMeshBotsState } =
        useMeshbotsPageConfig();
    const { allMeshbots, isEmptyMeshBots, isMeshBots } = useMeshBotsState(
        cloudMeshBot,
        rules,
        isMeshBotsLoading,
        systemMeshBotsState.isSystemMeshBotsChecked,
    );
    const paginationProps = useMeshBotTablePagination(allMeshbots, meshBotsTableState);
    const selectedMeshBotsState = useSelectedMeshBotsState(allMeshbots);

    const {
        removeMeshbot,
        updateMeshbot,
        subscribeMeshbotDelete,
        subscribeMeshbotUpdate,
        unsubscribeMeshbotDelete,
        unsubscribeMeshbotUpdate,
    } = MeshBotAction;

    const onlineControllers = getOnlineControllers(ezlo.data);

    useEffect(() => {
        if (!ezlo.isConnecting && onlineControllers && onlineControllers.length) {
            subscribeMeshbotUpdate(onlineControllers, (data) => updateMeshbotRule(data, 'change'));
            subscribeMeshbotDelete(onlineControllers, (data) => updateMeshbotRule(data, 'remove'));
            MeshBotAction.subscribeMeshbotRun(onlineControllers, (result) => getBroadcastStatus(result));

            return () => {
                unsubscribeMeshbotUpdate(onlineControllers);
                unsubscribeMeshbotDelete(onlineControllers);
                MeshBotAction.unsubscribeMeshbotRun(onlineControllers);
            };
        }
    }, [ezlo.isConnecting]);

    useEffect(() => {
        PluginActions.getPrivatePlugins(CODE);
        MeshBotAction.getCloudMeshBot();
    }, []);

    const getBroadcastStatus = (result) => {
        const { scene_id, controller_id } = result;
        const controller = controller_id || allMeshbots.find((meshBot) => meshBot.id === scene_id)?.serial;

        if (result?.status === STATUSES.FAILED) {
            MeshBotAction.setBroadcastStatus(scene_id, FAILED, controller);
        } else if (result?.status === STATUSES.FINISHED) {
            MeshBotAction.setBroadcastStatus(scene_id, FINISHED, controller);
        } else {
            MeshBotAction.setBroadcastStatus(scene_id, STARTED, controller);
        }
    };

    const updateMeshbotRule = (data, type) => {
        if (onlineControllers && onlineControllers.length) {
            onlineControllers.map((serial) => {
                const rule = ezlo.data[serial].rules?.find((item) => item._id === data._id);
                if (rule && type === 'change') {
                    updateMeshbot(serial, data);
                }

                if (rule && type === 'remove') {
                    removeMeshbot(serial, data);
                }
            });
        }

        return data;
    };

    const handlerCreateMeshbot = (type) => {
        MainAction.hideModal();
        MeshBotAction.clearMeshBot();
        // todo: fix this workaround
        setLocalStorage('createType', type);
        switch (type) {
            case MESHBOT_TYPES.CLOUD:
                history.push(MESH_BOT_CLOUD_NEW);
                break;
            case MESHBOT_TYPES.LOCAL:
                history.push(MESH_BOT_LOCAL_NEW);
                break;
            case MESHBOT_TYPES.CLOUD_NOTIFICATION:
                props.history.push(MESH_BOT_NOTIFICATION_NEW);
                break;
            case MESHBOT_TYPES.CLOUD_INTERACTION:
                props.history.push(MESH_BOT_INTERACTION_NEW);
        }
    };

    return (
        <MeshBotsTableContextProvider value={{ allMeshbots, systemMeshBotsState, ...selectedMeshBotsState }}>
            <BoxWithLinearProgress isLinearProgress={isMeshBotsLoading}>
                <MeshBotDragDropContainer>
                    <div className={`${styles.meshBotsContainer} ezlo-meshbots`}>
                        <Header headerClassName={styles.meshBotsHeader}>
                            <ListHeader onClick={MainAction.ShowCreateRule} />
                        </Header>
                        <MeshBotLabelsPanel />
                        <div className={styles.ezloMeshbotsList}>
                            <div className={styles.viewingGridTable}>
                                {isMeshBots && (
                                    <CustomGridProTable
                                        columns={columns}
                                        rows={allMeshbots}
                                        paginationProps={paginationProps}
                                        pagination={true}
                                        disableSelectionOnClick={true}
                                        showColumnRightBorder={false}
                                        hideFooterRowCount={false}
                                        disableExtendRowFullWidth={false}
                                        disableColumnReorder={true}
                                        disableColumnResize={false}
                                        checkboxSelection
                                        CheckboxComponent={SelectedMeshBotsCheckbox}
                                        ToolbarComponent={MeshBotsTableToolbar}
                                        hideFooter={true}
                                        density={density}
                                        onStateChange={handleMeshBotsTableStateChange}
                                        onSelectionModelChange={selectedMeshBotsState.updateSelectedMeshBots}
                                    />
                                )}
                                {isEmptyMeshBots && (
                                    <EmptyMeshBotsTableOverlay
                                        title={EZLOGIC_TITLE_YOU_HAVE_NO_MESHBOT_YET}
                                        description={EZLOGIC_TITLE_YOUR_MESHBOT_WILL_APEAR}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </MeshBotDragDropContainer>
                {isShowModalCreateRule && (
                    <ModalCreate
                        isModalVisible={isShowModalCreateRule}
                        onOk={MainAction.hideModal}
                        onCancel={MainAction.hideModal}
                        onCreate={handlerCreateMeshbot}
                        isControllerDisabled={isControllerDisabled}
                    />
                )}
            </BoxWithLinearProgress>
        </MeshBotsTableContextProvider>
    );
};

export default EzloMeshBots;

EzloMeshBots.propTypes = {
    serial: PropTypes.string,
    rules: PropTypes.array,
    isShowModalCreateRule: PropTypes.bool,
    MainAction: PropTypes.object,
    EzloActions: PropTypes.object,
    GenericActions: PropTypes.object,
    isMeshBotsLoading: PropTypes.bool,
};
