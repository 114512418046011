import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import './switchBool.scss';

/**
 * Create switch component
 * @param { object } props
 * @returns { node } Switch component
 * @example
 * <SwitchBool
 *      checked="example"
 *      label="example"
 *      onChange={func}
 *      onClick={func}
 * />
 */

const SwitchBool = (props) => {
    const { checked, label, onChange, onClick, noOffOn, disabled, color } = props;

    return (
        <div className="switch-bool">
            <div className="switch-bool__wrap">
                {!noOffOn && <span>Off</span>}
                <Switch checked={checked} onChange={onChange} disabled={disabled} color={color} />
                {!noOffOn && <span>On</span>}
            </div>

            {label && (
                <span className="switch-bool__label" onClick={onClick}>
                    {label}
                </span>
            )}
        </div>
    );
};

export default SwitchBool;

SwitchBool.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
};
