import React from 'react';
import { Button } from '@material-ui/core';
import './style.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_GO_BACK, EZLOGIC_HEADING_SETTING_NOT_SUPPORT } from '../../../../../constants/language_tokens';

const DeviceSettingDefault = ({ onCancel }) => {
    const { t } = useTranslate();

    return (
        <div className="device-default">
            <h2>{t(EZLOGIC_HEADING_SETTING_NOT_SUPPORT)}</h2>
            <Button variant="outlined" onClick={onCancel}>
                {t(EZLOGIC_BUTTON_GO_BACK)}
            </Button>
        </div>
    );
};

export default DeviceSettingDefault;
