import React from 'react';
import style from '../../../EzloParjectsSettings/IntegrationsSection/EnrollmentsList/EnrollmentListItem.module.scss';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Typography } from '@material-ui/core';
import { INTEGRATION_BUTTON_TEXT } from '../../../EzloParjectsSettings/constants';
import { useTranslate } from '../../../../../features/languages';
import styles from '../../EzloIntegrationsInformation/styles.module.scss';

const EzloIntegrationsEnrollmentBox = ({ abstract, additionInfo, handleAbstractEdit, icon }) => {
    const { SETTINGS } = INTEGRATION_BUTTON_TEXT;
    const { t } = useTranslate();

    return (
        <div className={styles.infoWrapper}>
            {icon ? <img src={icon} className={style.enrollmentImg} /> : <SettingsApplicationsIcon />}
            <div className={styles.col}>
                <div className={styles.row}>
                    <span className={styles.name}>{abstract.name}</span>
                    <span className={styles.info}>{additionInfo}</span>
                </div>
                <div className={styles.settingsTextButtonBox}>
                    <Typography color="primary" onClick={handleAbstractEdit}>
                        {t(SETTINGS)}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default EzloIntegrationsEnrollmentBox;
