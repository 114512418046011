import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PRIVATE } from '../../../constants/Plugins';
import { ROUTE_SCRIPT_LIST } from '../../../constants/ScriptConstants';
import Header from './components/Header';
import PrivateScripts from './PrivateScripts';
import CreateScript from './CreateScript';
import EditScript from './EditScript';
import NotFoundPageContent from '../../../containers/NotFoundPage/NotFoundPageContent';
import './styles.scss';

const EzloLuaScripts = (props) => {
    const [isActivePage, setIsActivePage] = useState(PRIVATE);
    const { history, offline } = props;
    // const { ezlos } = props.redux.state;
    const { url } = useRouteMatch();

    useEffect(() => {
        if (history.location.pathname === ROUTE_SCRIPT_LIST) {
            history.push(`${url}/private`);
        }
    }, [history.location.pathname]);

    useEffect(() => {
        if (offline) {
            //TODO: must be fixed, because throw the error here!
            // history.push(GET_OFFLINE_EZLO(ezlos[0].serial));
        }
    }, [offline]);

    const handlerRouteList = useCallback(
        (type) => {
            history.push(`${url}/${type}`);
            setIsActivePage(type);
        },
        [history, url],
    );

    return (
        <div className="lua-scripts">
            <Header history={history} />
            <Switch>
                <Route
                    path={`${url}/private`}
                    render={(props) => (
                        <PrivateScripts isActivePage={isActivePage} onHandlerRouteList={handlerRouteList} {...props} />
                    )}
                />
                <Route path={`${url}/create`} component={CreateScript} />
                <Route path={`${url}/edit/:id`} component={EditScript} />
                {!offline && <Route render={() => <NotFoundPageContent loggedIn />} />}
            </Switch>
        </div>
    );
};

EzloLuaScripts.propTypes = {
    history: PropTypes.object,
    offline: PropTypes.bool,
};

export default EzloLuaScripts;
