import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Checkbox, Button, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { HoursAndMinutesSelect } from '../sectionCustom';
import * as meshBot from '../../../../../../constants/MeshbotConstant';
import { getTime, getTimeForModifier, validateTimeFieldValue } from '../../../utils';

import styles from './TimeOfDaySelectionSection.module.scss';
import { TIME_NODE } from '../../../../../../constants/MeshbotConstant';
import {
    EZLOGIC_BUTTON_ADD_ANOTHER_TIME,
    EZLOGIC_TITLE_CHOOSE_THE_MOMENT_OF_DAY,
    EZLOGIC_TITLE_INCLUDE_ADVANCED_SCENES,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const TimeOfDaySelectionSection = (props) => {
    const { id, actionId, idGroup, blocks = [], onSetDateValue, isSupportsUpdatedAdvancedScenesForDays } = props;

    const [value, setValue] = useState(
        blocks?.[meshBot.INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find((field) => field.name === TIME_NODE)?.value || [],
    );
    const [checked, setChecked] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();
    const { t } = useTranslate();
    useEffect(() => {
        onSetDateValue(value, id, idGroup, null, actionId);
    }, [value]);

    useEffect(() => {
        if (!checked) {
            setValue([]);
        } else if (!value.length) {
            const currentTime = moment().format(meshBot.CURRENT_TIME_FORMAT);
            const newValue = [...value, currentTime];

            setValue(newValue);
        }
    }, [checked]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, meshBot.DELAY_TIME);
        }
    }, [showAlert]);

    useEffect(() => {
        if (location.pathname !== meshBot.PATHNAME && blocks.length && !validateTimeFieldValue(blocks)) {
            setChecked(false);
        }
    }, [location.pathname]);

    const handleChangeCheckbox = (event) => {
        if (isSupportsUpdatedAdvancedScenesForDays === false) {
            setShowAlert(true);
        } else if (isSupportsUpdatedAdvancedScenesForDays) {
            setChecked(event.target.checked);
        }
    };

    const handleTimeByIndexChange =
        (index) =>
        ([hour, minute], modifier) => {
            if (modifier === meshBot.AM && hour === meshBot.HOUR12) {
                hour = meshBot.ZERO;
            }

            if (modifier === meshBot.PM && hour !== meshBot.HOUR12) {
                hour = parseInt(hour, meshBot.RADIX) + meshBot.HOUR12;
            }

            const getCurrentHour =
                hour.toString().length > meshBot.ARRAY_LENGTH ? hour.toString() : meshBot.SINGLE_ZERO + hour.toString();
            const getCurrentMinutes =
                minute.toString().length > meshBot.ARRAY_LENGTH
                    ? minute.toString()
                    : meshBot.SINGLE_ZERO + minute.toString();
            const newValue = [...value];

            newValue[index] = getCurrentHour + ':' + getCurrentMinutes;
            setValue(newValue);
        };

    const handleAddTime = () => {
        const currentTime = moment().format(meshBot.CURRENT_TIME_FORMAT);
        const newValue = [...value, currentTime];

        setValue(newValue);
    };

    const handleDeleteTime = (index) => () => {
        const newValue = [...value];

        newValue.splice(index, meshBot.NUMBER_OF_ITEMS_TO_BE_REMOVED);
        setValue(newValue);
    };

    return (
        <div className={styles.container}>
            {showAlert && (
                <Alert variant="outlined" severity="warning">
                    <div dangerouslySetInnerHTML={{ __html: t(EZLOGIC_TITLE_INCLUDE_ADVANCED_SCENES) }}></div>
                </Alert>
            )}
            <div className={styles.switchingBlock}>
                <Checkbox disabled={showAlert} color="primary" checked={checked} onChange={handleChangeCheckbox} />
                <p>{t(EZLOGIC_TITLE_CHOOSE_THE_MOMENT_OF_DAY)}</p>
            </div>
            {checked && (
                <div className={styles.timeSettingBlock}>
                    <ul className={styles.timeSetterWrapper}>
                        {value.map((time, index) => {
                            return (
                                <li className={styles.timeSetter} key={`${time}_${index}`}>
                                    <HoursAndMinutesSelect
                                        onChangeCustomTime={handleTimeByIndexChange(index)}
                                        customHours={getTime(time).hours}
                                        customMinutes={getTime(time).minutes}
                                        isPm={getTimeForModifier(time).hours >= meshBot.HOUR12}
                                        isCloud={false}
                                    />
                                    {value.length > meshBot.ARRAY_LENGTH ? (
                                        <div>
                                            <IconButton className={styles.iconButton} onClick={handleDeleteTime(index)}>
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                    <Button className={styles.button} variant="outlined" onClick={handleAddTime}>
                        {t(EZLOGIC_BUTTON_ADD_ANOTHER_TIME)}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default TimeOfDaySelectionSection;

TimeOfDaySelectionSection.propTypes = {
    id: PropTypes.string,
    actionId: PropTypes.string,
    idGroup: PropTypes.string,
    blocks: PropTypes.array,
    onSetDateValue: PropTypes.func,
    isSupportsUpdatedAdvancedScenesForDays: PropTypes.bool,
    isSupportsUpdatedAdvancedScenesForWeeks: PropTypes.bool,
};
