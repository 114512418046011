import React, { memo } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import styles from '../../CloudVariable.module.scss';
import { EZLOGIC_TITLE_CREATE_YOUR_VARIABLE } from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';
import { CloseIcon } from '../../../../../../../../../assets/icons';

const TestVariablesDialogBox = ({ isOpen, handleClose, children }) => {
    const { t } = useTranslate();

    return (
        <Dialog className={styles.dialogBox} open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <div className={styles.variableDialogHeader}>
                <DialogTitle className={styles.variableDialogTitle}>
                    {t(EZLOGIC_TITLE_CREATE_YOUR_VARIABLE)}
                </DialogTitle>
                <div className={styles.closeButtonBox}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default memo(TestVariablesDialogBox);
