import React from 'react';
import { Grid } from '@material-ui/core';
import DynamicFields from '../../../../DynamicFields/DynamicFields';
import './style.scss';

const DynamicFieldsBlock = (props) => {
    const { values, handleUpdateFieldActionBlock, idDevice, _tempId, actionBlockName, interfaceInputs, description } =
        props;

    const onChange = (newValue, key, isRequired) => {
        const fieldsValues = { ...values, [key]: newValue };
        if (!isRequired && newValue === '') {
            delete fieldsValues[key];
        }
        // eslint-disable-next-line
        handleUpdateFieldActionBlock(
            typeof newValue === 'boolean' ? Object.values(fieldsValues)[0] : fieldsValues,
            idDevice,
            _tempId,
            actionBlockName,
        );
    };

    return (
        <Grid direction="column" container spacing={3} className="dynamic-fields-section">
            <Grid item xs={12} className="dynamic-fields-section__description">
                {description}
            </Grid>

            <DynamicFields inputs={interfaceInputs} onChange={onChange} values={values} />
        </Grid>
    );
};

export default DynamicFieldsBlock;
