import React from 'react';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem } from '@material-ui/core';

import { optionsSelect } from '../../../../../constants/MeshbotConstant';
import { BOOLEAN_TYPE_VALUES } from '../../../../../constants/Variables';
import { EZLOGIC_TITLE_SELECT_A_VALUE_LABEL } from '../../../../../constants/language_tokens';

import { useTranslate } from '../../../../../features/languages';

import { variablesDataType } from './VariablesBooleanDataType.module.scss';

const VariablesBooleanDataType = (props) => {
    const { code, onSetCode } = props;

    const { t } = useTranslate();

    return (
        <FormControl variant="outlined" size="small" className={variablesDataType}>
            <InputLabel>{t(EZLOGIC_TITLE_SELECT_A_VALUE_LABEL)}</InputLabel>
            <Select
                MenuProps={optionsSelect}
                onChange={(event) => onSetCode(event.target.value)}
                value={code}
                input={<OutlinedInput label={t(EZLOGIC_TITLE_SELECT_A_VALUE_LABEL)} />}
            >
                {BOOLEAN_TYPE_VALUES.map((valueType) => {
                    return (
                        <MenuItem key={valueType.value} value={valueType.value}>
                            {t(valueType.name)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default VariablesBooleanDataType;
