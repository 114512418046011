import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import MeshBotCloudCreate from './MeshBotCloudCreate';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    ruleCloudTriggers: state?.meshBot?.cloud?.ruleCloudTriggers || [],
    parentTriggerOptionType: state.meshBot.cloud.parentTriggerOptionType,
    cloudMeshbotName: state.meshBot.cloud.name,
    abstractsDevice: state.meshBot.abstractsDevice,
    capabilities: state.meshBot.capabilities,
    ruleCloudAction: state.meshBot.cloud.ruleCloudAction,
    abstractsCapabilities: state.main.abstractCapabilities.capabilities,
    currentScene: state.meshBot.cloud?.currentScene || {},
    abstracts: state.main.abstractsList?.abstracts || null,
    channels: state.notifications.channels,
    users: state.notifications.users,
    isDuplicate: state.meshBot.cloud.isDuplicate,
    uuids: state.notifications.uuids,
    dashboards: state.notifications.dashboards,
    notificationType: state.notifications.notificationType,
    integrationsWithCallBack: state?.main?.integrationList?.integrationsWithCallBack,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(MeshBotCloudCreate);
