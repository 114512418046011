import React from 'react';

import TimeZoneSelect from '../TimeZoneSelect';
import { HoursAndMinutesSelect } from '../../templateDateTimeBlocks/sectionCustom';
import Month from '../../../../../../components/Month';
import { PM } from '../../../../../../constants/MeshbotConstant';

const MonthlyNode = (props) => {
    const { onChangeDays, onChangeTime, blocks, handleChangeTimezone } = props;

    return (
        <>
            <HoursAndMinutesSelect
                onChangeCustomTime={(time, modifier) => onChangeTime({ time, isPm: modifier === PM })}
                customHours={blocks?.[0]?.parameters?.[0].time[0] || 12}
                customMinutes={blocks?.[0]?.parameters?.[0].time[1] || 0}
                isPm={blocks?.[0]?.parameters?.[0]?.isPm}
            />
            <TimeZoneSelect handleChangeTimezone={handleChangeTimezone} blocks={blocks} />
            <Month onChange={(e, day) => onChangeDays(day)} isArrayDate={blocks?.[0]?.parameters?.[0]?.days || []} />
        </>
    );
};

export default MonthlyNode;
