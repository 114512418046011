import React, { memo } from 'react';

import { ARRAY_FIELD_TYPES } from '../../../paas-constants';
import MultipleAutocomplete from './MultipleAutocomplete';

/**
 * Renders Multiple Autocomplete with enum
 * @param { object } props
 * @returns { JSX.Element } multiple Autocomplete
 * @example
 * <NumberAutocomplete
 *      value={['one', 'two']}
 *      fieldSchema={{
 *          type: 'array',
 *          items: {
 *              enum: ['one', 'two', 'three'],
 *              type: 'string',
 *              }
 *          }
 *      }
 *      fieldName={'favoriteStrings'}
 *      description={'Favorite Strings'}
 *      isRequired={true}
 *      onChange={func}
 * />
 */
const EnumAutocomplete = ({ value, fieldSchema, fieldName, description, isRequired, onChange }) => {
    const { ENUM, NUMBER } = ARRAY_FIELD_TYPES;
    const { [ENUM]: options, type } = fieldSchema.items;

    const handleChangeField = (e, value) => {
        onChange(value, fieldName);
    };

    const getOptionLabel = (option) => (type === NUMBER ? String(option) : option);

    return (
        <MultipleAutocomplete
            value={value}
            options={options}
            description={description}
            isRequired={isRequired}
            handleChangeField={handleChangeField}
            getOptionLabel={getOptionLabel}
        />
    );
};

export default memo(EnumAutocomplete);
