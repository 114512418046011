import React from 'react';
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';

import MeshBotSelectField from '../../MeshBotSelectField/MeshBotSelectField';

import { ALL, PRIMARY } from '../../../constants/MeshbotConstant';
import {
    EZLOGIC_HINT_SELECT_CHANNELS,
    EZLOGIC_TITLE_CHANNELS,
    EZLOGIC_TITLE_SELECT_ALL,
} from '../../../constants/language_tokens';
import { useTranslate } from '../../../features/languages';
import styles from '../NotificationCloudBlock.module.scss';

export const NotificationCloudChannels = (props) => {
    const { selectedChannel, handleChangeChannel, isAllSelected, channels } = props;
    const { t } = useTranslate();

    return (
        <MeshBotSelectField
            label={EZLOGIC_TITLE_CHANNELS}
            selectValue={selectedChannel}
            handleChange={handleChangeChannel}
            infoText={EZLOGIC_HINT_SELECT_CHANNELS}
            selectionAttributes={{
                renderValue: (selectedChannel) => selectedChannel.join(', '),
                multiple: true,
            }}
        >
            <MenuItem className={styles.menuItem} value={ALL}>
                <ListItemIcon>
                    <Checkbox
                        color="primary"
                        checked={isAllSelected}
                        indeterminate={selectedChannel.length > 0 && selectedChannel.length < channels.length}
                    />
                </ListItemIcon>
                <ListItemText primary={t(EZLOGIC_TITLE_SELECT_ALL)} />
            </MenuItem>
            {channels &&
                channels.map((item) => (
                    <MenuItem className={styles.menuItem} key={item.id} value={item.id}>
                        <ListItemIcon>
                            <Checkbox color={PRIMARY} checked={selectedChannel.indexOf(item.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={item.id} />
                    </MenuItem>
                ))}
        </MeshBotSelectField>
    );
};
