import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import AccountForm from '../AccountForm';
import { securityValidationSchema } from '../AccountForm/validationSchema';
import { getInitialValues, getRequestAddressData } from '../utils';
import AccountActions from '../../../../../actions/AccountActions';
import EzloPoliciesLinks from '../../../../../features/EzloPolicies/components/EzloPoliciesLinks';

import classes from '../AccountLayout/style.module.scss';
import { useTranslate } from '../../../../../features/languages';

const SecurityAccountInfo = () => {
    const { lineLoading } = useSelector(({ app }) => app);
    const { installationsList, countriesList, mailingBillingAddresses } = useSelector(({ account }) => account);
    const initialValues = getInitialValues(installationsList, countriesList, mailingBillingAddresses);
    const dispatch = useDispatch();
    const { t } = useTranslate();
    useEffect(() => {
        dispatch(AccountActions.setSecurityAccountInfo());
    }, []);

    const handleClickSubmit = (formValues) => {
        const { Mailing, Billing } = formValues;
        const addressesData = { Mailing: getRequestAddressData(Mailing), Billing: getRequestAddressData(Billing) };
        dispatch(AccountActions.updateSecurityAccountInfo(addressesData, t));
    };

    if (lineLoading) {
        return null;
    }

    return (
        <div className={classes.accountContainer}>
            <div className={classes.accountContent}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={securityValidationSchema}
                    onSubmit={handleClickSubmit}
                >
                    <AccountForm isSecurityENV={true} />
                </Formik>
            </div>
            <div className={classes.policiesLinks}>
                <EzloPoliciesLinks />
            </div>
        </div>
    );
};

export default SecurityAccountInfo;
