import React, { memo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { FormControl, TextField } from '@material-ui/core';

import IntegrationImg from './IntegrationImg';

import style from '../styles.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_VARIABLE } from '../../../../../../constants/language_tokens';

/**
 * Presentation component. Rendered Autocomplete select with cloud variables.
 */
const VariablesSelect = ({ updateSelectValue, integrationIcon, options, variableValue, CurrentValueJsx }) => {
    const { t } = useTranslate();

    const handleSelectValue = (e, value) => {
        updateSelectValue(value);
    };

    const getOptionJsx = (variableData) => {
        const { icon, UIVariableName, abstract: account } = variableData;

        return (
            <div className={style.list}>
                {icon && <IntegrationImg integrationIcon={icon} />}
                {` ${UIVariableName} (${account.name})`}
            </div>
        );
    };

    const getInput = (params) => {
        return (
            <TextField
                label={t(EZLOGIC_TITLE_VARIABLE)}
                {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: integrationIcon && <IntegrationImg integrationIcon={integrationIcon} />,
                }}
            />
        );
    };

    return (
        <div className={style.wrapper}>
            <FormControl className={style.select}>
                <Autocomplete
                    options={options}
                    renderOption={getOptionJsx}
                    classes={{ popper: style.popper }}
                    getOptionLabel={(option) => option.UIVariableName || ''}
                    value={variableValue || ''}
                    disableClearable
                    groupBy={(option) => option?.integrationName}
                    getOptionSelected={(option, value) => option.uuid === value.uuid}
                    onChange={handleSelectValue}
                    renderInput={getInput}
                />
                {CurrentValueJsx}
            </FormControl>
        </div>
    );
};

export default memo(VariablesSelect);
