/**
 * This file contains general URL paths for the external API endpoints
 *
 * RULES:
 * 1. Every path MUST NOT contain slash in the beginning
 * */

/**
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DASHBOARD Section
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * */

/**
 * Path, used for SSO (automatic login) to Dashboard app.
 * */
export const DASHBOARD_LOGIN_USER_WITH_TOKEN_PATH = '#/login-user-with-token';

/**
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * MMS Section
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * */

/**
 * Path, used to exchange MMS signatures to Cloud token.
 * */
export const MMS_MCA_ROUTER_TOKEN_EXCHANGE_LEGACY_TO_CLOUD_PATH = 'mca-router/token/exchange/legacy-to-cloud';

export const MCA_ROUTER_GET_SYSTEM_HISTORY = 'mca-router/event/event/events';
/**
 * Path, used to exchange get info from MMS config.
 * For example, get list of countries etc.
 * */
export const MMS_INFO_CONFIG_CONFIG_PATH = `info/config/config`;
export const MMS_INFO_CONFIG_REGIONS_PATH = `info/config/regions`;
export const MMS_INFO_CONFIG_CITIES_PATH = `info/config/cities`;

/**
 * Path, used to get account related info.
 * For example, it is the base path to:
 * 1. get the list of installations
 * 2. get users info
 * 3. delete user
 * */
export const MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH = 'account/account/account';

/**
 * Returns path to manage installation on the account by PK_Account, PK_Installation values.
 * @param {number} PK_Account - MMS Account primary key
 * @param {number} PK_Installation - MMS Installation primary key
 * @return {string} path
 * */
export const getMMSAccountInstallationPath = (PK_Account, PK_Installation) => {
    return `${MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH}/${PK_Account}/installation/${PK_Installation}`;
};

/**
 * Returns path to manage Mailing and Billing addresses on the account by PK_Account.
 * @param {number} PK_Account - MMS Account primary key
 * @return {string} path
 * */
export const getMMSAccountMailingBillingPath = (PK_Account) => {
    return `${MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH}/${PK_Account}`;
};

/**
 * Returns path to get installations on the account by PK_Account value.
 * @param {number} PK_Account - MMS Account primary key
 * @return {string} path
 * */
export const getMMSAccountInstallationsPath = (PK_Account) => {
    return `${MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH}/${PK_Account}/installations`;
};

/**
 * Returns path to manage user on the account by PK_Account and PK_User values.
 * @param {number} PK_Account_Parent - MMS Account Parent primary key
 * @param {number} PK_User - MMS User primary key
 * @return {string} path
 * */
export const getMMSAccount3UserPath = (PK_Account_Parent, PK_User) => {
    return `${MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH}/${PK_Account_Parent}/user/${PK_User}`;
};

/**
 * Returns path to list all devices on the account
 * @param {number} PK_Account - MMS Account primary key
 * @return {string} path
 * */
export const getMMSAccount3DevicePath = (PK_Account) => {
    return `${MMS_ACCOUNT_ACCOUNT_ACCOUNT_PATH}/${PK_Account}/devices`;
};

/**
 * Path, used to manage MMS user.
 * For example, it is the base path to create user or change password
 */
export const MMS_AUTHA_AUTH_USERNAME_PATH = 'autha/auth/username';

/**
 * Returns path to change password on the account.
 * @param {string} username - username, original user login
 * @return {string} path
 * */
export const getMMSChangeUserPasswordPath = (username) => {
    return `${MMS_AUTHA_AUTH_USERNAME_PATH}/${username}/password`;
};

/**
 * Returns path to login
 * @param {string} username - username, login
 * @return {string} path
 * */
export const getMMSAuthPath = (username) => {
    return `${MMS_AUTHA_AUTH_USERNAME_PATH}/${username}`;
};

/**
 * Path, used to get and set end user license agreement MMS Account.
 */
export const MMS_ACCOUNT_PRIVACY_EULA_PATH = 'account/privacy/eula';
export const MMS_ACCOUNT_PRIVACY_USER_EULA_PATH = 'account/privacy/user/eula';

/**
 * Path, used to get and set privacy policy agreement MMS Account.
 */
export const MMS_ACCOUNT_PRIVACY_NOTICE_PATH = 'account/privacy/notice/1';
export const MMS_ACCOUNT_PRIVACY_USER_NOTICE_PATH = 'account/privacy/user/notice/1';

/**
 * Path, used to get and set consent MMS Account.
 */
export const MMS_ACCOUNT_PRIVACY_CONSENT_PATH = 'account/privacy/consentgroup/1';
export const MMS_ACCOUNT_PRIVACY_USER_CONSENT_PATH = 'account/privacy/user/consentgroup/1';

/**
 * Path, used to manage users on MMS Account.
 * For example, it is the base path to get user(s) info, update the user, send a verification email.
 */
export const MMS_ACCOUNT_USER_USER_PATH = 'account/user/user';

/**
 * Path, used to get user details about MMS User on MMS Account
 * @param {number} PK_User - MMS User primary key
 * @return {string} path
 * */
export const getMMSUserPath = (PK_User) => {
    return `${MMS_ACCOUNT_USER_USER_PATH}/${PK_User}`;
};

/**
 * Path, used to send a verification email to the user's email
 * @param {number} PK_User - MMS User primary key
 * @return {string} path
 * */
export const getMMSResendEmailPath = (PK_User) => {
    return `${MMS_ACCOUNT_USER_USER_PATH}/${PK_User}/validate`;
};

/**
 * Path, used to manage auth data/permissions for MMS User.
 * For example, it is the base path to provide access for the controllers for the user.
 */
export const MMS_AUTHA_AUTH_USER_PATH = 'autha/auth/user';

/**
 * Path, used to manage permissions for MMS User.
 * @param {number} PK_User - MMS User primary key
 * @return {string} path
 * */
export const getMMSUserPermissionsPath = (PK_User) => {
    return `${MMS_AUTHA_AUTH_USER_PATH}/${PK_User}/permissions`;
};

/**
 * Path, used for external user interaction via email.
 * Example: could be used as a base path to send request to reset password
 * */
export const MMS_AUTHA_AUTH_EMAIL_PATH = 'autha/auth/email';

/**
 * Path, used to reset password for MMS User.
 * @param {string} email - user's email address
 * @returns {string} path
 * */
export const getMMSPasswordResetPath = (email) => {
    return `${MMS_AUTHA_AUTH_EMAIL_PATH}/${email}/password_reset`;
};

/**
 * Path, used as a root path to manage devices (controllers) on the Account.
 * */
export const MMS_DEVICE_DEVICE_DEVICE_PATH = 'device/device/device';

/**
 * Returns path t used as a root path to manage devices (controllers) on the Account.
 * */
export const getMMSDeviceInfoPath = (PK_Device) => {
    return `${MMS_DEVICE_DEVICE_DEVICE_PATH}/${PK_Device}`;
};

/**
 * TODO: discuss it?
 * Path used to manage specific device (controller) on installation
 * */
export const MMS_ACCOUNT_INSTALLATION2_DEVICE_PATH = 'account/installation/installation/0/device';

/**
 * Returns path to delete controller on the account
 * @return {string} path
 * */
export const getMMSDeleteControllerPath = (PK_Device) => {
    return `${MMS_ACCOUNT_INSTALLATION2_DEVICE_PATH}/${PK_Device}`;
};

/**
 * MMS KVS path on the account
 * */
export const MMS_ACCOUNT_KVS2_PATH = 'account/kvs/kvs';

/**
 * MMS user on the account
 * */
export const MMS_ACCOUNT_USER_USERS_PATH = 'account/user/users';

/**
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * CLOUD Section
 * * * * * * * * * * * * * * * * * * * * * * * * * *
 * */

/**
 * Cloud API request path
 * */
export const CLOUD_API_PATH = 'v1/request';
