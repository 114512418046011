import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import VirtualDeviceItem from './VirtualDeviceItem';

const VirtualDevicesList = (props) => {
    const { virtualDeviceList, onEdit, onDelete } = props;
    const virtualDevices = (virtualDeviceList && Object.values(virtualDeviceList)) || [];
    const isDeviceListAvailable = virtualDevices.length > 0;

    return isDeviceListAvailable ? (
        <ul className="virtual-devices-list">
            {virtualDevices.map((virtualDevice) => {
                return (
                    <VirtualDeviceItem
                        key={virtualDevice.abstract_uuid}
                        abstractUUID={virtualDevice.abstract_uuid}
                        virtualDevice={virtualDevice}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                );
            })}
        </ul>
    ) : null;
};

VirtualDevicesList.propTypes = {
    title: PropTypes.string,
    virtualDeviceList: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
};

export default VirtualDevicesList;
