import { useEffect, useState } from 'react';
import { INITIAL_TABLE_PAGE_NUMBER, MESHBOTS_TABLE_ROWS_PER_PAGE } from '../../constants';
import { getMeshBotPaginationCount } from '../../utils';

const useMeshBotTablePagination = (allMeshBots, meshBotsTableState) => {
    const [page, setPage] = useState(INITIAL_TABLE_PAGE_NUMBER);
    const onPageChange = (e, newPage) => setPage(newPage);

    useEffect(() => {
        //Handles pagination for the case when we use external filters (not from the table, for example, when filtering by labels)
        if (allMeshBots.length < meshBotsTableState?.pagination?.rowCount) {
            // We reduce the page number to the initial one if we have applied external filters
            setPage(INITIAL_TABLE_PAGE_NUMBER);
        }
        //We get the visible rows in the table. These rows are available in cases where internal filtering of the table is used
        const visibleRows = meshBotsTableState?.visibleRows?.visibleRows;
        if (!visibleRows) {
            return;
        }
        //Below we handle when the user is not on the first page and uses internal page filtering. In order not to see the white screen without meshBots, we change the page of our location.
        const pageCountOfVisibleRows = Math.floor(visibleRows.length / MESHBOTS_TABLE_ROWS_PER_PAGE);
        if (page > pageCountOfVisibleRows) {
            setPage(INITIAL_TABLE_PAGE_NUMBER);
        }
    }, [meshBotsTableState?.pagination, meshBotsTableState?.visibleRows?.visibleRows, allMeshBots]);

    return {
        rowsPerPage: MESHBOTS_TABLE_ROWS_PER_PAGE,
        count: getMeshBotPaginationCount(meshBotsTableState, allMeshBots),
        page,
        onPageChange,
    };
};

export default useMeshBotTablePagination;
