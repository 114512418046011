import { ALLOWED_ITARIAN_SSO_MENU_ITEMS, MENU_ITEMS } from './constants';
import { GET_EZLO_PAGE } from '../../constants/URLs';
import { CUSTOMIZATION, NOTIFICATIONS, ZERO } from '../Ezlo/EzloCustomization/constants';
import { USER_TYPE } from '../../constants/localStorageKeys';
import { ITARIAN_SSO_TYPE } from '../../services/sso/src/ItarianService/constants';
import { isArray } from 'lodash';

export const buildMenuItemsById = (menuBlock, totalItems) => {
    if (!isArray(menuBlock)) {
        return;
    }

    menuBlock.forEach((item) => {
        totalItems[item.id] = item;
        if (isArray(item.subItems)) {
            buildMenuItemsById(item.subItems, totalItems);
        }
    });
};
export const getMenuItemsById = (menuData) => {
    const menuItemsById = {};

    buildMenuItemsById(menuData, menuItemsById);

    return menuItemsById;
};

export const getMenuCustomizationData = (menuBarData, isControllerAvailable) => {
    const menuItemsById = getMenuItemsById(menuBarData);

    const updateMenu = (menuItems) => {
        return menuItems
            .map((item) => {
                const menuItemFromCustomization = menuItemsById.hasOwnProperty(item.id) && menuItemsById[item.id];

                if (menuItemFromCustomization?.checked === false) {
                    return null;
                }

                if (isArray(item.subItems)) {
                    const subItems = updateMenu(item.subItems);

                    return { ...item, subItems };
                }

                return item;
            })
            .filter(Boolean);
    };

    const updatedMenu = updateMenu(MENU_ITEMS);

    if (!isControllerAvailable) {
        const updatedControllerMenuItems = getControllerIndependentMenuItems(updatedMenu);

        return updatedControllerMenuItems;
    }

    return updatedMenu;
};

const getControllerIndependentMenuItems = (menuItems) => {
    const controllerIndependentMenuItems = menuItems.map((menuItem) => {
        if (!menuItem?.isControllerDependent) {
            return {
                ...menuItem,
                subItems:
                    menuItem?.subItems?.length > ZERO ? getControllerIndependentMenuItems(menuItem?.subItems) : null,
            };
        }
    });

    return controllerIndependentMenuItems.filter((menu) => menu != undefined);
};
const getMenuItems = (menuItem, isUser, isOemTemplateMasterUser) => {
    if ((isUser || menuItem?.id !== CUSTOMIZATION) && (isOemTemplateMasterUser || menuItem?.id !== NOTIFICATIONS)) {
        return customizationMenuItems(menuItem, isUser, isOemTemplateMasterUser);
    }
};

const customizationMenuItems = (menuItem, isUser, isOemTemplateMasterUser) => {
    return {
        ...menuItem,
        subItems:
            menuItem?.subItems?.length > ZERO
                ? subMenuItems(menuItem?.subItems, isUser, isOemTemplateMasterUser).filter(
                      (menuSubItem) => menuSubItem !== undefined,
                  )
                : null,
    };
};
const subMenuItems = (menuList, isUser, isOemTemplateMasterUser) => {
    return menuList?.map((subItem) => {
        return getMenuItems(subItem, isUser, isOemTemplateMasterUser);
    });
};

export const updatedMenuListItems = (menuItems, isUser, isOemTemplateMasterUser) => {
    return menuItems
        .map((menuItem) => getMenuItems(menuItem, isUser, isOemTemplateMasterUser))
        .filter((item) => !item?.subItems || item?.subItems?.length > ZERO);
};

export const checkVeraUser = () => {
    return Boolean(localStorage.getItem(USER_TYPE));
};

/**
 * Returns update navigation menu list, updated by Itarian SSO type
 * @param {Object} menuItems - array of menu items
 * @returns {array|undefined} updated menu list
 * */
export const updateNavigationMenuItemsByItarianSsoType = (menuItems) => {
    if (!Array.isArray(menuItems)) {
        return;
    }

    const updatedMenuItems = [];

    for (const menuItem of menuItems) {
        if (ALLOWED_ITARIAN_SSO_MENU_ITEMS.includes(menuItem && menuItem.id)) {
            updatedMenuItems.push(menuItem);
            if (menuItem && menuItem.subItems) {
                menuItem.subItems = updateNavigationMenuItemsByItarianSsoType(menuItem.subItems);
            }
        }
    }

    return updatedMenuItems;
};

/**
 * Returns update navigation menu list, updated by SSO type
 * @param {array} menuItems - array of menu items
 * @param {string|undefined} ssoType - current sso type
 * @returns {array} updated menu list
 * */
export const updateNavigationMenuItemsBySsoType = (menuItems, ssoType) => {
    if (ssoType && ssoType === ITARIAN_SSO_TYPE) {
        return updateNavigationMenuItemsByItarianSsoType(menuItems);
    }

    return menuItems;
};

export const goToEzloPage = (page, isAbsultePath = false, props) => {
    const { linkTo } = props.redux.actions.GenericActions;
    const { ezloSerial } = props.redux.state;
    if (isAbsultePath) {
        linkTo(`/${page}`);

        return;
    }
    linkTo(GET_EZLO_PAGE(ezloSerial, page));
};
