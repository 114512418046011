import React from 'react';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const DeleteImagesButton = ({ onClickButton }) => {
    return (
        <IconButton color="primary" onClick={onClickButton}>
            <Delete data-testid="delete-icon" fontSize="large" />
        </IconButton>
    );
};

export default DeleteImagesButton;
