import React from 'react';
import classname from 'classnames';
import { Label, MoreVert } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import useMenuState from '../../../../../hooks/useMenuState';
import { LabelMenuContainer } from '../LabelsManager';
import DraggingMeshBotLabel from '../DraggingMeshBotLabel';

import styles from './styles.module.scss';

const LabelItem = ({ labelData, labelUuid, handlerClickLabel, selectedLabelUuid }) => {
    const { menuOpen, openMenu, closeMenu, anchorEl } = useMenuState();
    const { name, backgroundColor } = labelData;

    const labelBoxClassName = classname(styles.labelContainer, {
        [styles.selectedLabel]: selectedLabelUuid === labelUuid,
    });

    return (
        <div className={labelBoxClassName} onClick={() => handlerClickLabel(labelUuid)}>
            <div className={styles.labelBox}>
                <div className={styles.labelContent}>
                    <Label style={{ color: backgroundColor }} />
                    <div className={styles.labelName}>{name}</div>
                </div>
                <DraggingMeshBotLabel {...labelData} />
            </div>
            <IconButton size="medium" onClick={openMenu}>
                <MoreVert />
            </IconButton>

            <LabelMenuContainer
                anchorEl={anchorEl}
                menuOpen={menuOpen}
                closeMenu={closeMenu}
                labelData={labelData}
                labelUuid={labelUuid}
            />
        </div>
    );
};

export default LabelItem;
