import hash from '../../../constants/uniqueHash';
import IconButton from '@material-ui/core/IconButton';
import { Delete, Edit, PlayArrow } from '@material-ui/icons';
import React from 'react';
import { EDIT, REMOVE } from '../../../constants/Plugins';
import {
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_BUTTON_MY_PRIVATE_SCRIPT,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_RUN,
    EZLOGIC_TITLE_SCRIPTS_NAME,
    EZLOGIC_TITLE_SCRIPT_EXECUTED_SUCCESSFULLY,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
} from '../../../constants/language_tokens';

/**
 * Working on the answer
 * @param {data} data - Object answer
 * @returns {null} Returns a message that is displayed in UI
 * @example
 * getScriptResponse(data)
 * */
export const getScriptResponse = (data) => {
    switch (data?.status) {
        case 'finished':
            return EZLOGIC_TITLE_SCRIPT_EXECUTED_SUCCESSFULLY;
        case 'failed':
            return data.error.reason;
        default:
            return null;
    }
};

/**
 * List buttons for
 * @returns {array} array of rows
 * */
export const listButtons = [{ id: hash(), name: EZLOGIC_BUTTON_MY_PRIVATE_SCRIPT, type: 'private' }];

/**
 * Rows array for Script table
 * @param {array} data - list of Script
 * @returns {array} array of rows
 * @example
 * createScriptTable(data)
 * */
export const createScriptTable = (data) => {
    return data.map((item) => {
        return {
            name: item.name,
            id: item._id,
        };
    });
};

/**
 * Columns config for Script table
 * @returns {array} array of columns
 * @example
 * buildMeshBotsTableColumns(props)
 * */
// TODO: rename!
export const buildMeshBotsTableColumns = (...args) => {
    const [type, onConfirm, onHandlerRouteToPage, onHandlerRunScript] = args;

    return [
        {
            field: 'id',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
            sortable: false,
            disableColumnMenu: true,
            bodyAlign: 'center',
            hide: true,
            disableSelectionOnClick: true,
        },
        {
            field: 'name',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_SCRIPTS_NAME,
            headerClassName: 'grid-field',
            minWidth: 250,
            align: 'left',
            disableSelectionOnClick: true,
        },
        type !== 'marketplace' && {
            field: 'run',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_RUN,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onHandlerRunScript(params)}>
                        <PlayArrow />
                    </IconButton>
                );
            },
        },
        type !== 'marketplace' && {
            field: 'edit',
            headerAlign: 'center',
            headerName: EZLOGIC_BUTTON_EDIT,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onHandlerRouteToPage(EDIT, params)}>
                        <Edit color="primary" />
                    </IconButton>
                );
            },
        },
        type !== 'marketplace' && {
            field: 'delete',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_DELETE,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, REMOVE)}>
                        <Delete color="primary" />
                    </IconButton>
                );
            },
        },
    ];
};
