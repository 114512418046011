import React, { useState, useEffect } from 'react';

import { InputMaterial } from '../index';

import at from '../../constants/ActionTypes/MeshBot';
import { DELAY } from '../../constants/MeshbotConstant';

import styles from './DelayTime.module.scss';
import { useTranslate } from '../../features/languages';
import {
    EZLOGIC_TITLE_DAYS,
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_SECONDS,
} from '../../constants/language_tokens';

const DelayTime = ({ currentRule, actionBlockName, id, onSetIsShowedDelay, onUpdateActionDelay }) => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const { t } = useTranslate();
    useEffect(() => {
        const result = currentRule[at.MESHBOT_LOCAL_ACTION_BLOCKS[actionBlockName].name].find((item) => item.id === id);
        const findDelay = result && result.blocks;

        if (findDelay && findDelay[0]?.delay) {
            const [{ delay }] = findDelay;
            onSetIsShowedDelay(DELAY);
            setSeconds(delay.seconds);
            setMinutes(delay.minutes);
            setHours(delay.hours);
            setDays(delay.days);
        }
    }, [currentRule, actionBlockName]);

    const changeValue = (e) => {
        const { name, value } = e.target;
        const delay = { seconds, minutes, hours, days };

        delay[name] = value;
        onUpdateActionDelay(id, delay);
    };

    return (
        <div className={styles.wrapper}>
            <InputMaterial
                label={t(EZLOGIC_TITLE_SECONDS)}
                placeholder={t(EZLOGIC_TITLE_SECONDS)}
                type="number"
                name="seconds"
                value={seconds}
                min={0}
                max={59}
                step={1}
                onChange={changeValue}
                xsSmall
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_MINUTES)}
                placeholder={t(EZLOGIC_TITLE_MINUTES)}
                type="number"
                name="minutes"
                value={minutes}
                min={0}
                max={59}
                step={1}
                onChange={changeValue}
                xsSmall
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_HOURS)}
                placeholder={t(EZLOGIC_TITLE_HOURS)}
                type="number"
                name="hours"
                value={hours}
                min={0}
                max={12}
                step={1}
                onChange={changeValue}
                xsSmall
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_DAYS)}
                placeholder={t(EZLOGIC_TITLE_DAYS)}
                type="number"
                name="days"
                value={days}
                min={0}
                max={30}
                step={1}
                onChange={changeValue}
                xsSmall
            />
        </div>
    );
};

export default DelayTime;
