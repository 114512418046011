import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ResetPasswordTitle.module.scss';

const ResetPasswordTitle = (props) => {
    const { title, subTitle, className } = props;

    return (
        <>
            {title && <h2 className={classNames(styles.title, className)}>{title}</h2>}
            {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </>
    );
};

ResetPasswordTitle.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

export default ResetPasswordTitle;
