import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import DisableRemoveSettings from './DisableRemove';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    ezlo: state.ezlo.data[state.ezlo.serial],
    ezlos: state.ezlos.items,
    account: state.account.data,
    groups: state.groups,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DisableRemoveSettings);
