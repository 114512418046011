import React from 'react';

import EzloVariablesDataTypesSelect from '../../components/EzloVariablesDataTypesSelect';
import VariablesNameInput from '../../components/VariablesNameInput';
import VariablesCommonDataTypes from '../../components/VariablesCommonDataTypes';
import VariablesBooleanDataType from '../../components/VariablesBooleanDataType';
import VariablesActionDataType from '../../components/VariablesActionDataType';
import InputAndColorPicker from '../../../../../components/ColorInputAndColorPickers';
import VariablesTokenDataType from '../../components/VariablesTokenDataType';
import VariablesScalableDataType from '../../components/VariablesScalableDataType';

import { DATA_TYPES_LIST } from '../../../../../constants/Variables';

import styles from '../ExpressionAddForm.module.scss';

const VariableAddForm = (props) => {
    const {
        name,
        code,
        dataType,
        initName,
        scalableType,
        isExpression,
        onSetDataType,
        onChangeName,
        onSetCode,
        onSetScalableType,
        isInputNameNotValid,
    } = props;

    const handleDataTypeSelect = (dataType) => {
        onSetDataType(dataType);
        onSetCode('');
        onSetScalableType('');
    };

    const handleActionDataTypeValue = (event) => {
        event.persist();
        onSetCode((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            };
        });
    };

    const handleChangeScalableDataTypeValue = (event) => {
        event.persist();
        onSetCode((prevState) => {
            return {
                ...prevState,
                [event.target.name]: isNaN(event.target.value) ? event.target.value : Number(event.target.value),
            };
        });
    };

    return (
        <>
            <EzloVariablesDataTypesSelect onSelectDataType={handleDataTypeSelect} valueType={dataType} />
            {dataType && dataType !== DATA_TYPES_LIST.TYPE_SCALABLE && (
                <VariablesNameInput
                    name={name}
                    onChangeName={onChangeName}
                    disabled={initName}
                    isInputNameNotValid={isInputNameNotValid}
                />
            )}
            {name && dataType !== DATA_TYPES_LIST.TYPE_SCALABLE && (
                <div className={isExpression ? styles.equalSymbol : styles.variablesEqualSymbol}>=</div>
            )}
            {name &&
                (dataType === DATA_TYPES_LIST.TYPE_INT ||
                    dataType === DATA_TYPES_LIST.TYPE_STRING ||
                    dataType === DATA_TYPES_LIST.TYPE_FLOAT) && (
                    <VariablesCommonDataTypes code={code} dataType={dataType} onSetCode={onSetCode} />
                )}
            {name && dataType === DATA_TYPES_LIST.TYPE_BOOLEAN && (
                <VariablesBooleanDataType code={code} onSetCode={onSetCode} />
            )}
            {name && dataType === DATA_TYPES_LIST.TYPE_ACTION && (
                <VariablesActionDataType code={code} onChangeActionValue={handleActionDataTypeValue} />
            )}
            {name && dataType === DATA_TYPES_LIST.TYPE_RGB && (
                <InputAndColorPicker isVariablePage={true} value={code} onChangeRgbValue={onSetCode} />
            )}
            {name && dataType === DATA_TYPES_LIST.TYPE_TOKEN && (
                <VariablesTokenDataType code={code} onChangeTokenValue={onSetCode} />
            )}
            {dataType === DATA_TYPES_LIST.TYPE_SCALABLE && (
                <VariablesScalableDataType
                    name={name}
                    code={code}
                    scalableType={scalableType}
                    onChangeName={onChangeName}
                    onChangeScalableType={onSetScalableType}
                    onChangeScalableDataTypeValue={handleChangeScalableDataTypeValue}
                />
            )}
        </>
    );
};

export default VariableAddForm;
