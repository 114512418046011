import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@material-ui/core';

import { Accounts } from './PaasSteps/Accounts/Accounts';
import { Integrations } from './PaasSteps/Integrations/Integrations';
import { MethodList } from './PaasSteps/Methods/MethodList';
import { DynamicFieldsList } from './PaasSteps/DynamicFields/DynamicFieldsList';
import { TestBlockComponent } from '../../../../../../../components/TestBlockComponent/TestBlockComponent';

import {
    FallbackMeshbotNodeComponent,
    withErrorBoundary,
} from '../../../../../../../components/HOCs/withErrorBoundary';
import SaveOutputBlock from '../../../../../EzloMeshbot/components/SaveOutputBlock/SaveOutputBlock';

import * as Actions from '../../../../../../../actions';
import { extractIntegrationUUID } from './PaasAPI/paas-responce-extractors';
import { generatePaasAbstractCommand, generateMeshbotCloudApiField, validate } from './utils';

import {
    buildInteractionNuCALBlockForTrigger,
    buildNuCALSubscriptionBlockForTrigger,
    buildInteractionNuCALBlock,
} from '../../../../../EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { MESHBOT_TYPES } from '../../../../../EzloMeshbots/constants';
import { MESHBOT_NODE_TYPES, TRIGGER_FORM } from '../../../../../../../constants/MeshbotConstant';
import { useActionSelect } from './hooks/useActionSelect';
import { useGetIntegration } from './hooks/useGetIntegration';
import useMeshBotType from '../../../../../EzloMeshbot/hooks/useMeshBotType';
import { useParseIntegrationPreview } from './hooks/useParseIntegrationPreview';
import { useCloudMeshBotPartUiState } from '../../../../../EzloMeshbot/hooks/useCloudMeshBotPartUiState';

import style from './Paas.module.scss';
import actions from '../../../../../../../actions/SubscriptionCloudActions';
import ActionDelay from '../../../../../../../components/ActionDelay';
import { CLOUD } from '../../../../../EzloMeshbot/constant';

export const PAASComponent = (props) => {
    const {
        id,
        abstracts = [],
        integrations,
        isAbstractsLoading,
        PAASAuthUuid,
        currentMeshBotType,
        calledFrom,
        onPreventDraggable,
        handlerSetError,
        blocks,
        isEditing,
        actionBlockName,
        onUpdateMeshbot,
        updateCloudRequiredFieldAction,
        updateCloudRequiredFieldTrigger,
        setIntegrationUuid,
        integrationUuid,
        triggerMethods,
        methods,
        paths,
        integrationPreviewResult,
        currentRule,
        selectedFieldTrigger,
        isShowedDelay,
        onUpdateActionDelay,
        onChangeDelay,
        onSetIsShowedDelay,
        typeMeshbot,
    } = props;

    const { abstractUuid, methodValue, fieldsList, subscriptionId, ...rest } = useActionSelect(
        id,
        currentMeshBotType,
        actionBlockName,
        calledFrom,
    );
    const [initialRequiredValues, setInitialRequiredValue] = useState([]);
    const { foundIntegration, integrationsList } = useGetIntegration(integrationUuid, calledFrom === TRIGGER_FORM);
    const methodsForMethodList = calledFrom === TRIGGER_FORM ? triggerMethods : methods;

    const isIntegrationsVisible = Boolean(integrations?.length);
    const isMethodsVisible = Boolean(methodsForMethodList?.length && abstractUuid && abstracts?.length);

    const [nucalComponent, setNucalComponent] = useState({});
    const dispatch = useDispatch();
    const { isCloudMeshBotTrigger } = useCloudMeshBotPartUiState(currentMeshBotType, calledFrom);

    useEffect(() => {
        if (subscriptionId) {
            dispatch(actions.getNucalSubscriptionData(subscriptionId, id));
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (calledFrom !== TRIGGER_FORM && currentMeshBotType === MESHBOT_TYPES.LOCAL) {
            onUpdateMeshbot({
                accountUuid: abstractUuid,
                method: methodValue,
                requiredFields: nucalComponent?.required,
                fields: fieldsList,
                code: rest?.code,
                blackListVariable: rest?.blackListVariable,
            });
        }

        if (nucalComponent && nucalComponent.required) {
            setInitialRequiredValue(nucalComponent.required);
        }
    }, [currentMeshBotType, nucalComponent]);

    useEffect(() => {
        if (currentMeshBotType === MESHBOT_TYPES.CLOUD && calledFrom !== TRIGGER_FORM) {
            updateCloudRequiredFieldAction(id, nucalComponent?.required);
        }

        if (isCloudMeshBotTrigger) {
            updateCloudRequiredFieldTrigger(id, nucalComponent?.required);
        }
    }, [nucalComponent]);

    useEffect(() => {
        if (handlerSetError) {
            const res = validate(initialRequiredValues, fieldsList);
            handlerSetError(res);
        }
    }, [fieldsList, initialRequiredValues, handlerSetError]);

    useEffect(() => {
        if (abstractUuid) {
            const foundAbstract = abstracts?.find(({ uuid }) => uuid === abstractUuid);
            const extractedId = extractIntegrationUUID(foundAbstract);
            setIntegrationUuid(extractedId);
        }
    }, []);

    const handleChangeMethod = (methodValue) => {
        onUpdateMeshbot({
            accountUuid: abstractUuid,
            method: methodValue,
            requiredFields: nucalComponent?.required,
            fields: {},
            code: rest?.code,
        });
    };

    const handleDynamicFieldChange = (value, propertyName) => {
        onUpdateMeshbot({
            accountUuid: abstractUuid,
            method: methodValue,
            fields: {
                ...fieldsList,
                [propertyName]: value,
            },
            code: rest?.code,
            requiredFields: nucalComponent?.required,
            blackListVariable: rest?.blackListVariable,
        });
    };

    const handleSetCode = (code) => {
        onUpdateMeshbot({
            accountUuid: abstractUuid,
            method: methodValue,
            fields: fieldsList,
            code: code,
            requiredFields: nucalComponent?.required,
            blackListVariable: rest?.blackListVariable,
        });
    };

    const handleSetBlackListVariable = (list) => {
        onUpdateMeshbot({
            accountUuid: abstractUuid,
            method: methodValue,
            fields: fieldsList,
            code: rest?.code,
            requiredFields: nucalComponent?.required,
            blackListVariable: list,
        });
    };

    const handleClearMeshbot = () => {
        onUpdateMeshbot({
            accountUuid: '',
            method: '',
            requiredFields: undefined,
            fields: {},
            code: '',
        });
    };

    const handleChangeIntegration = useCallback((uuid) => {
        setIntegrationUuid(uuid);
        handleClearMeshbot();
    }, []);

    return (
        <>
            {isIntegrationsVisible && (
                <Integrations
                    foundIntegration={foundIntegration}
                    onChangeIntegration={handleChangeIntegration}
                    integrationsList={integrationsList}
                />
            )}
            {integrationUuid && (
                <Accounts
                    abstracts={abstracts}
                    PAASAuthUuid={PAASAuthUuid}
                    isAbstractsLoading={isAbstractsLoading}
                    onUpdateMeshbot={onUpdateMeshbot}
                    abstractUuid={abstractUuid}
                    foundIntegration={foundIntegration}
                />
            )}

            {isMethodsVisible ? (
                <>
                    <MethodList
                        paths={paths}
                        parjectIcon={foundIntegration?.icon}
                        methods={methodsForMethodList}
                        methodValue={methodValue}
                        onChangeMethod={handleChangeMethod}
                    />
                    {typeMeshbot !== CLOUD && (
                        <ActionDelay
                            id={id}
                            currentRule={currentRule}
                            actionBlockName={actionBlockName}
                            type={selectedFieldTrigger}
                            isShowedDelay={isShowedDelay}
                            onUpdateActionDelay={onUpdateActionDelay}
                            onChangeDelay={onChangeDelay}
                            onSetIsShowedDelay={onSetIsShowedDelay}
                        />
                    )}
                </>
            ) : (
                abstractUuid && <CircularProgress />
            )}

            {methodValue && integrationPreviewResult && (
                <DynamicFieldsList
                    fields={fieldsList}
                    accountUuid={abstractUuid}
                    onDynamicFieldChange={handleDynamicFieldChange}
                    nucalComponent={nucalComponent}
                    integrationPreviewResult={integrationPreviewResult}
                    method={methodValue}
                    setNucalComponent={setNucalComponent}
                />
            )}

            {methodValue &&
                integrationPreviewResult &&
                (currentMeshBotType === MESHBOT_TYPES.CLOUD || currentMeshBotType === MESHBOT_TYPES.LOCAL) &&
                calledFrom !== TRIGGER_FORM && (
                    <div className={style.fullWidthBlock}>
                        <TestBlockComponent
                            accountUuid={abstractUuid}
                            method={methodValue}
                            fields={fieldsList}
                            onPreventDraggable={onPreventDraggable}
                            calledFrom={calledFrom}
                        />
                    </div>
                )}

            {methodValue && calledFrom !== TRIGGER_FORM && (
                <SaveOutputBlock
                    id={id}
                    isEditing={isEditing}
                    isIntegration={true}
                    isMethodsVisible={isMethodsVisible}
                    integrationPreviewResult={integrationPreviewResult}
                    blockName={actionBlockName}
                    blocks={blocks}
                    onSetCode={handleSetCode}
                    handleSetBlackListVariable={handleSetBlackListVariable}
                />
            )}
        </>
    );
};

const PAASContainer = (props) => {
    const {
        id,
        abstracts = null,
        integrations,
        isAbstractsLoading,
        paasAuthServiceUuid,
        MeshBotAction,
        getNotificationData,
        calledFrom,
        onUpdateTriggerForNuCAL,
        updateCloudRequiredFieldAction,
        onPreventDraggable,
        handlerSetError,
        blocks,
        isEditing,
        actionBlockName,
        updateCloudRequiredFieldTrigger,
        currentRule,
        isShowedDelay,
        onUpdateActionDelay,
        onChangeDelay,
        onSetIsShowedDelay,
        selectedFieldTrigger,
        typeMeshbot,
    } = props;

    const { currentMeshBotType } = useMeshBotType();
    const [integrationUuid, setIntegrationUuid] = useState('');
    const { triggerMethods, methods, paths, integrationPreviewResult } = useParseIntegrationPreview(integrationUuid);
    const dispatch = useDispatch();
    const { isCloudMeshBotTrigger } = useCloudMeshBotPartUiState(currentMeshBotType, calledFrom);

    const handleUpdateMeshbot = (data) => {
        if (currentMeshBotType === MESHBOT_TYPES.LOCAL) {
            const abstractCommand = generatePaasAbstractCommand(data);
            const cloudApiField = generateMeshbotCloudApiField(abstractCommand, actionBlockName);

            MeshBotAction.updatePaasAction(id, cloudApiField, data.fields, data.requiredFields, actionBlockName);
        } else if (currentMeshBotType === MESHBOT_TYPES.CLOUD && calledFrom !== TRIGGER_FORM) {
            const cloudAData = buildInteractionNuCALBlock(data);
            //  apiGetEnumListForTrigger(accountUuid, method?.slice(1), capabilities); // TODO: Why do we need it there?
            getNotificationData(cloudAData, id, MESHBOT_NODE_TYPES.PAAS);
        } else if (isCloudMeshBotTrigger) {
            const currentIntegration = integrations?.find(({ uuid }) => uuid === integrationUuid);
            const cloudTriggerCreationData = buildInteractionNuCALBlockForTrigger(
                data,
                currentIntegration?.name,
                paths,
            );

            const cloudTriggerNucalSubscriptionData = buildNuCALSubscriptionBlockForTrigger(
                data,
                currentIntegration?.name,
                paths,
            );

            const subscriptionUniqueId = cloudTriggerNucalSubscriptionData?.params?.parameters?.custom?.id;

            if (subscriptionUniqueId !== '') {
                dispatch(actions.checkNucalSubscriptionInKvsExists(subscriptionUniqueId, id));
            }

            onUpdateTriggerForNuCAL(
                cloudTriggerCreationData,
                cloudTriggerNucalSubscriptionData,
                id,
                MESHBOT_NODE_TYPES.PAAS,
            );
        }
    };

    return (
        <PAASComponent
            id={id}
            abstracts={abstracts}
            integrations={integrations}
            isAbstractsLoading={isAbstractsLoading}
            PAASAuthUuid={paasAuthServiceUuid}
            MeshBotAction={MeshBotAction}
            getNotificationData={getNotificationData}
            currentMeshBotType={currentMeshBotType}
            calledFrom={calledFrom}
            onUpdateTriggerForNuCAL={onUpdateTriggerForNuCAL}
            onPreventDraggable={onPreventDraggable}
            handlerSetError={handlerSetError}
            blocks={blocks}
            isEditing={isEditing}
            actionBlockName={actionBlockName}
            onUpdateMeshbot={handleUpdateMeshbot}
            updateCloudRequiredFieldAction={updateCloudRequiredFieldAction}
            integrationUuid={integrationUuid}
            setIntegrationUuid={setIntegrationUuid}
            triggerMethods={triggerMethods}
            methods={methods}
            paths={paths}
            integrationPreviewResult={integrationPreviewResult}
            updateCloudRequiredFieldTrigger={updateCloudRequiredFieldTrigger}
            currentRule={currentRule}
            isShowedDelay={isShowedDelay}
            onUpdateActionDelay={onUpdateActionDelay}
            onChangeDelay={onChangeDelay}
            onSetIsShowedDelay={onSetIsShowedDelay}
            selectedFieldTrigger={selectedFieldTrigger}
            typeMeshbot={typeMeshbot}
        />
    );
};

const mapStateToProps = (store) => ({
    abstracts: store?.main?.abstractsList?.abstracts,
    isAbstractsLoading: store?.main?.abstractsList?.isAbstractsListLoading,
    actions: store?.meshBot?.local.selectedRule.then,
    integrations: store?.main?.integrationList?.integrations,
    paasAuthServiceUuid: store?.main?.paas?.paasAuthServiceUuid,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).forEach((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const PAASConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(PAASContainer);
const PAASWithBoundary = withErrorBoundary(PAASConnectedToRedux, FallbackMeshbotNodeComponent);

export { PAASWithBoundary as PAAS };
