import React from 'react';
import { Header } from '../../../../components';
import {
    EZLOGIC_HEADING_CHANGE_THE_CONTROLLER,
    EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS,
    EZLOGIC_TITLE_SETTINGS,
} from '../../../../constants/language_tokens';
import EzlosDropdown from '../../../EzlosDropdown';
import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { CONTINUE } from '../../../../constants/ComponentConstants';
import { useTranslate } from '../../../../features/languages';
import { listHeader, titleSettings } from './styles.module.scss';

const DeviceAssociationsHeader = (props) => {
    const { openConfirmModal, isConfirmModalVisible, setIsConfirmModalVisible, handleConfirmation } = props;
    const { t } = useTranslate();

    return (
        <Header className={listHeader}>
            <h1 className={titleSettings}>{t(EZLOGIC_TITLE_SETTINGS)}</h1>
            <EzlosDropdown isShowOnlyOnlineControllers={true} openConfirmModal={openConfirmModal} />

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={t(EZLOGIC_HEADING_CHANGE_THE_CONTROLLER)}
                text={t(EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS)}
                confirmButtonTitle={t(CONTINUE)}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </Header>
    );
};

export default DeviceAssociationsHeader;
