import React, { memo } from 'react';
import PhoneInput from 'react-phone-number-input';

import CustomPhoneNumber from './PhoneMUIField';
import { DEFAULT_PHONE_COUNTRY, PHONE_NUMBER } from '../../../../../../constants/Users';

import 'react-phone-number-input/style.css';
import textInputStyles from '../../TextInput/styles.module.scss';
import styles from '../style.module.scss';
import { useTranslate } from '../../../../../../features/languages';

const InternationalPhoneNumber = ({ value, label, error, helperText, updatePhoneNumber }) => {
    const handleChange = (phoneNumber) => {
        updatePhoneNumber(phoneNumber);
    };
    const { t } = useTranslate();

    return (
        <PhoneInput
            international
            defaultCountry={DEFAULT_PHONE_COUNTRY}
            value={value}
            className={`${textInputStyles.textField} ${styles.phoneInput}`}
            inputComponent={CustomPhoneNumber}
            numberInputProps={{
                label,
                placeholder: t(PHONE_NUMBER.PLACEHOLDER),
                error,
                helperText,
            }}
            onChange={handleChange}
        />
    );
};

export default memo(InternationalPhoneNumber);
