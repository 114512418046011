import React, { useEffect } from 'react';
import { DynamicField } from './DynamicField';

import {
    X_DESCRIPTION_KEY,
    X_ENUM_LIST_BY_FUNCTION,
    X_GET_LIST_BY_FUNCTION_PATH,
    X_GET_LIST_KEY,
    X_GET_LIST_VALUE,
    TYPE,
    ENUM,
} from '../../paas-constants';
import { getNucalComponentBySchemaKey, getNucalSchemaKey } from '../../PaasAPI/paas-responce-extractors';

import styles from './DynamicField.module.scss';

export const DynamicFieldsList = (props) => {
    const {
        accountUuid,
        fields,
        onDynamicFieldChange,
        nucalComponent,
        integrationPreviewResult,
        method,
        setNucalComponent,
    } = props;

    useEffect(() => {
        const schemaKeys = getNucalSchemaKey(integrationPreviewResult, method);
        const component = getNucalComponentBySchemaKey(integrationPreviewResult, schemaKeys);
        setNucalComponent(component);
    }, [method]);

    if (!nucalComponent || !nucalComponent?.properties) {
        return null;
    }

    const { required, properties } = nucalComponent;

    const propertyNames = Object.keys(properties);

    return (
        <div className={styles.dynamicFields}>
            {propertyNames.map((propertyName) => {
                const property = properties[propertyName];
                const enumListMethod = property?.[X_ENUM_LIST_BY_FUNCTION];
                const enumListKey = property?.[X_GET_LIST_KEY];
                const enumListValue = property?.[X_GET_LIST_VALUE];
                const enumListByFunctionPath = property?.[X_GET_LIST_BY_FUNCTION_PATH];
                const description = property[X_DESCRIPTION_KEY];
                const typeField = property[TYPE];
                const enumValue = property[ENUM];

                return (
                    <DynamicField
                        key={propertyName}
                        accountUuid={accountUuid}
                        value={fields?.[propertyName] || ''}
                        isRequired={required?.includes(propertyName)}
                        propertyName={propertyName}
                        description={description}
                        onDynamicFieldChange={onDynamicFieldChange}
                        enumListMethod={enumListMethod}
                        enumListKey={enumListKey}
                        enumListValue={enumListValue}
                        enumListByFunctionPath={enumListByFunctionPath}
                        type={typeField}
                        enumValue={enumValue}
                        property={property}
                    />
                );
            })}
        </div>
    );
};
