import React, { createContext, useContext } from 'react';

export const GalleryContext = createContext({});

const useGalleryContext = () => {
    return useContext(GalleryContext);
};

export const GalleryContextProvider = ({ value, children }) => {
    return <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>;
};

export default useGalleryContext;
