import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import { optionsSelect } from '../../../../constants/MeshbotConstant';

export const CustomSelect = (props) => {
    const { menuItems, placeholder, labelId, onChange, value, isDisabled = false } = props;

    return (
        <FormControl>
            <InputLabel id={labelId}>{placeholder}</InputLabel>
            <Select labelId={labelId} value={value} onChange={onChange} disabled={isDisabled} MenuProps={optionsSelect}>
                {menuItems.map(({ value, label }) => {
                    return (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
