import React from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { FieldDescription } from './FieldDescription';
import { optionsSelect } from '../../../../../../../../../../constants/MeshbotConstant';
import { buildSelectOptions } from '../utils';

export const SelectBlock = ({ enumValue, label, isRequired, propertyName, value, onChange }) => {
    const options = buildSelectOptions(enumValue);

    return (
        <FormControl variant="standard" fullWidth>
            <FieldDescription description={label} isRequired={isRequired} />
            <Select name={propertyName} MenuProps={optionsSelect} value={value} onChange={onChange}>
                {options.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.id}>
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
