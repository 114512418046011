import './style.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ControllersList from './ControllersList/ControllersList';
import { IconButton } from '@material-ui/core';
import { DEFAULT_CONTROLLERS_GROUP_NAME } from '../constants';
import classNames from 'classnames';
import { Delete, Edit } from '@material-ui/icons';
import { getFilteredControllers } from '../ManageGroupDialog';
import { DropDownUpIcon } from '../../../../assets/icons';
// Hidden for task 3085 called "Remove grouping of hubs on UI".
// import { EZLOGIC_HEADING_UNASSIGNED_CONTROLLERS } from '../../../../constants/language_tokens';
// import { useTranslate } from '../../../../features/languages';

const GroupsSection = (props) => {
    const { groups, onDelete, allControllers, selectedGroup } = props;
    const isGroups = Object.entries(groups).length > 0;
    const filteredControllers = useMemo(() => {
        return getFilteredControllers(allControllers, groups) || [];
    }, [allControllers, groups]);
    // Hidden for task 3085 called "Remove grouping of hubs on UI".
    // const { t } = useTranslate();
    const handleGroupDelete = (group) => (e) => {
        e.stopPropagation();
        onDelete(group);
    };

    const handleGroupEdit = (group) => (e) => {
        e.stopPropagation();
        const { onEdit } = props;
        onEdit(group);
    };

    const getAccordionHeader = (group) => {
        const isDefaultGroup = group.name === DEFAULT_CONTROLLERS_GROUP_NAME;

        return (
            <div className="accordion__header">
                <h2 className="header-title">{group.name}</h2>
                <div className="header-actions">
                    <IconButton onClick={handleGroupEdit(group)}>
                        <Edit fontSize="large" color="primary" />
                    </IconButton>
                    <IconButton
                        disabled={isDefaultGroup}
                        className={classNames({ disabled: isDefaultGroup })}
                        onClick={handleGroupDelete(group)}
                    >
                        <Delete fontSize="large" color="primary" />
                    </IconButton>
                </div>
            </div>
        );
    };

    const getGroupsListContent = () => {
        return Object.entries(groups)
            .reverse()
            .map(([key, group]) => {
                const isGroupActive = selectedGroup.uuid === key;

                return (
                    <Accordion className="accordion" key={key} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<DropDownUpIcon height="8" width="16" className="expand-icon" />}
                            aria-label="Expand"
                        >
                            {getAccordionHeader(group)}
                        </AccordionSummary>
                        <AccordionDetails className="accordion__details">
                            <ControllersList
                                group={group}
                                groupKey={key}
                                isGroupActive={isGroupActive}
                                controllers={group.controllers}
                                deleteGroup={onDelete}
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            });
    };

    const getUnassignedControllersListContent = () => {
        return (
            // Hidden for task 3085 called "Remove grouping of hubs on UI".
            // <Accordion className="accordion" defaultExpanded>
            //     <AccordionSummary
            //         expandIcon={<DropDownUpIcon height="8" width="16" className="expand-icon" />}
            //         aria-label="Expand"
            //     >
            //         <h2 className="header-title">{t(EZLOGIC_HEADING_UNASSIGNED_CONTROLLERS)}</h2>
            //     </AccordionSummary>
            //     <AccordionDetails className="accordion__details">
            //         <ControllersList isUnassigned isGroupActive={false} controllers={filteredControllers} />
            //     </AccordionDetails>
            // </Accordion>
            <ControllersList isUnassigned isGroupActive={false} controllers={filteredControllers} />
        );
    };

    return (
        <div className="groups-section">
            {isGroups && getGroupsListContent()}
            {filteredControllers.length !== 0 && getUnassignedControllersListContent()}
        </div>
    );
};

GroupsSection.propTypes = {
    groups: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default GroupsSection;
