import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IcControlsClose from '../../../../../assets/icons/controls/ic_controls_close_56';
import { getCloseButtonClassName, getContrast } from '../../utils';

import styles from '../MeshBotLabelsList/styles.module.scss';

const MeshBotLabel = ({ label, onDelete }) => {
    const { backgroundColor } = label;
    const color = getContrast(backgroundColor);
    const closeBtnColor = getCloseButtonClassName(color);

    return (
        <li className={styles.labelWrapper} style={{ backgroundColor, color }}>
            <span className={classNames(styles.deleteBtn, closeBtnColor)} onClick={() => onDelete(label.id)}>
                <IcControlsClose />
            </span>
            <span className={styles.labelName} title={label.name}>
                {label.name}
            </span>
        </li>
    );
};

MeshBotLabel.propTypes = {
    label: PropTypes.object,
    onDelete: PropTypes.func,
};
export default MeshBotLabel;
