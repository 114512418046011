import React from 'react';
import {
    NUCAL_INTEGRATIONS_NEW_ENROLLMENT_GUIDE_FOR_SUBCRIBE_TEXT,
    NUCAL_INTEGRATIONS_NEW_ENROLLMENT_NOT_SUBCRIBE_TEXT,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

import style from './NewEnrollmentPage.module.scss';

const NewEnrollmentPage = () => {
    const { t } = useTranslate();

    return (
        <>
            <section className={style.emptyPage}>
                <div className={style.messageInformation}>
                    <p>{t(NUCAL_INTEGRATIONS_NEW_ENROLLMENT_NOT_SUBCRIBE_TEXT)}</p>
                    <p>{t(NUCAL_INTEGRATIONS_NEW_ENROLLMENT_GUIDE_FOR_SUBCRIBE_TEXT)}</p>
                </div>
            </section>
        </>
    );
};

export default NewEnrollmentPage;
