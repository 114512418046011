import React from 'react';
import { useDispatch } from 'react-redux';
import { TreeItem } from '@material-ui/lab';

import GeneralTreeItem from '../GeneralTreeItem';
import DeviceGroupCategoryLabelContainer from '../../../DeviceGroupCategoryLabelContainer';

import DeviceGroupsActions from '../../../../../../../actions/DeviceGroupsActions';
import { getTreeElementsStateCategoryOptions, getMenuButtonState } from '../../../../utils';

const DeviceGroupCategory = (props) => {
    const { name, payload } = props;
    const dispatch = useDispatch();
    const categoryId = payload.id;
    const buttonState = getMenuButtonState(payload._ui.state);

    const handleChangeCategoryState = (newCategoryState) => {
        dispatch(
            DeviceGroupsActions.updateDeviceGroupTreeStateByCategory({
                newCategoryState,
                categoryId,
            }),
        );
    };

    return (
        <TreeItem
            label={
                <DeviceGroupCategoryLabelContainer
                    value={payload?._ui.state}
                    labelName={payload?.category_name}
                    onChange={handleChangeCategoryState}
                    options={getTreeElementsStateCategoryOptions()}
                    buttonState={buttonState}
                    deviceId={payload?.id}
                    type={payload?._ui.type}
                />
            }
            nodeId={name}
        >
            {payload?.subcategories &&
                Object.entries(payload?.subcategories).map(([name, payload]) => (
                    <GeneralTreeItem payload={payload} categoryId={categoryId} key={name} />
                ))}
        </TreeItem>
    );
};

export default DeviceGroupCategory;
