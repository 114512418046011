import React from 'react';

import InfoIcon from '../icons/InfoIcon';
import classNames from 'classnames';
import {
    EZLOGIC_BUTTON_CREATE_BACKUP,
    EZLOGIC_TITLE_DO_NOT_USE_DEVICES,
    EZLOGIC_TITLE_TO_AVOID_ERROR_MESSAGE,
} from '../../../../constants/language_tokens';
import { Button } from '../../../../components';
import { useTranslate } from '../../../../features/languages';

const BackupCreateContent = ({ handleCreateBackup }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content" data-testid="create-backup">
            <InfoIcon className="icon" />
            <span className={classNames('text', 'title')}>{t(EZLOGIC_TITLE_DO_NOT_USE_DEVICES)}</span>
            <span className={classNames('text', 'description')}>{t(EZLOGIC_TITLE_TO_AVOID_ERROR_MESSAGE)}</span>
            <div className="main-content-footer">
                <Button color="primary" variant="contained" className="primary-btn" onClick={handleCreateBackup}>
                    {t(EZLOGIC_BUTTON_CREATE_BACKUP)}
                </Button>
            </div>
        </div>
    );
};

export default BackupCreateContent;
