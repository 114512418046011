import React from 'react';
import PluginInformationChangelogDescription from '../PluginInformationChangelogDescription/PluginInformationChangelogDescriptions';
import styles from '../styles.module.scss';

const PluginInformationChangelogList = ({ changelog }) => {
    return (
        <ul className={styles.listWrapper}>
            {changelog.map((item) => (
                <li>
                    <p>
                        <span>{item.version}</span>
                        <span>{item.data}</span>
                    </p>
                    <PluginInformationChangelogDescription item={item} />
                </li>
            ))}
        </ul>
    );
};

export default PluginInformationChangelogList;
