import React from 'react';

const CompletedIcon = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M148 100C148 126.51 126.51 148 100 148C73.4903 148 52 126.51 52 100C52 73.4903 73.4903 52 100 52"
            stroke="#00B491"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M133.519 55.167L100 88.6866L86.2715 74.9571L74.957 86.2715L94.3428 105.657H105.657L144.833 66.4812C141.62 62.1937 137.806 58.3806 133.519 55.167Z"
            fill="#00B491"
        />
        <path d="M100 88.6865L111.314 100V77.3723L100 88.6865Z" fill="#009377" />
        <path
            d="M122.042 133.356L133.519 144.833C147.158 134.61 156 118.318 156 100H140C140 113.915 132.854 126.188 122.042 133.356Z"
            fill="#009377"
        />
    </svg>
);

export default CompletedIcon;
