import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, TextField } from '@material-ui/core';

import { getIsLoggedInUser } from '../../UserManagement/utils';

import styles from '../../UserManagement/style.module.scss';
import { EZLOGIC_TITLE_SELECT_CONTROLLER_ACCESS } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { ArrowDropDown, ControllerIcon } from '../../../../../assets/icons';

const MultipleSelect = ({ name, label, isEditing }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const controllers = useSelector(({ ezlos }) => ezlos.items.map(({ serial }) => serial));
    const isLoggedInUser = useSelector(({ account, users }) => getIsLoggedInUser(account, users.selectedUser));
    const { t } = useTranslate();

    useEffect(() => {
        return () => setFieldValue(name, []);
    }, []);

    const handleChange = (e, value) => {
        setFieldValue(name, value);
    };

    const getRenderOption = (serial, option) => (
        <div key={serial} className={styles.menuItem}>
            <div className={styles.controllerBox}>
                <ControllerIcon className={styles.controllerIcon} />
                {serial}
            </div>
            <Checkbox className={styles.checkbox} size="medium" color="primary" checked={option.selected} />
        </div>
    );

    const getRenderInput = (params) => (
        <TextField
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name]}
            {...params}
            label={label}
            placeholder={values[name].length > 0 ? '' : t(EZLOGIC_TITLE_SELECT_CONTROLLER_ACCESS)}
        />
    );

    return (
        <Autocomplete
            name={name}
            multiple
            className={styles.selectField}
            disabled={isEditing ? isLoggedInUser : false}
            value={values[name] || []}
            onChange={handleChange}
            options={controllers}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={getRenderOption}
            renderInput={getRenderInput}
            popupIcon={<ArrowDropDown className={styles.popupIcon} />}
        />
    );
};

export default MultipleSelect;
