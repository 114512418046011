import React from 'react';
import { getContrast } from '../../utils';

import styles from './styles.module.scss';

const MeshBotLabelInTableCell = ({ name, backgroundColor, className }) => {
    const color = getContrast(backgroundColor);

    return (
        <div title={name} style={{ backgroundColor, color }} className={className || styles.label}>
            {name}
        </div>
    );
};

export default MeshBotLabelInTableCell;
