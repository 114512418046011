import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';

import styles from './style.module.scss';
import { EyeIcon, EyeOffIcon } from '../../../../../assets/icons';

const PasswordIconAdornment = ({ showPassword, setShowPassword }) => {
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <InputAdornment position="end">
            <IconButton size="small" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {showPassword ? (
                    <EyeIcon className={styles.passwordIcon} />
                ) : (
                    <EyeOffIcon className={styles.passwordIcon} />
                )}
            </IconButton>
        </InputAdornment>
    );
};

export default PasswordIconAdornment;
