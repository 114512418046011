import React from 'react';
import PropTypes from 'prop-types';
import { EZLOGIC_HEADING_ADDITIONAL_INFORMATION } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import PluginInformationContent from './PluginInformationContent';
import styles from './style.module.scss';

const InfoPlugin = ({ row }) => {
    const { name, config, changelog } = row;
    const metaData = config && config.meta;
    const { t } = useTranslate();

    return (
        <div className={styles.modal}>
            {config ? (
                <PluginInformationContent metaData={metaData} config={config} name={name} changelog={changelog} />
            ) : (
                <h2 className={styles.empty}>{t(EZLOGIC_HEADING_ADDITIONAL_INFORMATION)}</h2>
            )}
        </div>
    );
};

InfoPlugin.propTypes = {
    row: PropTypes.object,
};

export default InfoPlugin;
