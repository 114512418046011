import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'react-toastify/dist/ReactToastify.min.css';

import { history, store } from './store/configureStore';
import Main from './containers/Main';
import EzloToast from './containers/EzloToast';
import ErrorBoundary from './containers/ErrorBoundary';
import { registerLocales } from './api/apiLocales';
import { initializeEzlogic } from './features/app';

import './index.scss';

const rootElement = document.getElementById('app');

// Render the React application to the DOM
function startApp() {
    const render = (Component) => {
        return ReactDOM.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Component />
                    </ConnectedRouter>
                    <EzloToast />
                </Provider>
            </ErrorBoundary>,
            rootElement,
        );
    };

    render(Main);

    if (module.hot) {
        module.hot.accept('./containers/Main', () => {
            const NextMain = require('./containers/Main').default;
            render(NextMain);
        });
    }
}

initializeEzlogic().then(registerLocales()).then(startApp);
