import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import useFeatureSupportChecker from '../../hooks/useFeatureSupportChecker';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../constants/ControllerFeatures';
import { METHOD_NAME } from '../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_TOGGLE } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

/**
 * Create select component
 * @param { object } props
 * @returns { node } Select component
 * @example
 * <SelectBlock
 *      options="example"
 *      onChange={func}
 * />
 */

const SelectBlock = (props) => {
    const { options, type, onChange, isValueType, toggleValue, isItemType } = props;
    const isSupportsUpdatedAdvancedScenesForToggleValue = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_51,
    );
    const { t } = useTranslate();
    const valueText = (data) => {
        let newValue = '';

        data.labels.forEach((item) => {
            if ((type === 'action' ? item : item.value) === data.value) {
                newValue = type === 'action' ? item : item.value;
            }
        });

        return newValue;
    };

    return (
        <SelectComponent
            value={toggleValue || valueText(options)}
            label={info.hints.value}
            info={info.text.value}
            onChange={onChange}
        >
            {options.labels.map((item) => {
                return (
                    <MenuItem key={type === 'action' ? item : item.value} value={type === 'action' ? item : item.value}>
                        {type === 'action' ? item : item.label.text}
                    </MenuItem>
                );
            })}
            {isValueType && isItemType && isSupportsUpdatedAdvancedScenesForToggleValue && (
                <MenuItem value={METHOD_NAME}>{t(EZLOGIC_TITLE_TOGGLE)}</MenuItem>
            )}
        </SelectComponent>
    );
};

export default SelectBlock;
