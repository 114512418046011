import React from 'react';
import { TextField, Select, FormControl, InputLabel, OutlinedInput, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import VariablesNameInput from '../VariablesNameInput';

import { SCALABLE_TYPES, VARIABLES_FIELDS_LABELS } from '../../../../../constants/Variables';
import { optionsSelect } from '../../../../../constants/MeshbotConstant';

import { sortScalableTypesArrayByName, getSelectedScalableTypeObject } from '../../utils';

import { useTranslate } from '../../../../../features/languages';

import {
    scalableTypes,
    scalableWrapper,
    scalableTypeValueInput,
    scalableTypesAutocomplete,
} from './VariablesScalableDataType.module.scss';
import { expressionItemEqualSymbol } from '../../ExpressionItem/ExpressionItemInfo/ExpressionItemInfo.module.scss';
import {
    EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE,
    EZLOGIC_TITLE_VARIABLE_SCALE,
} from '../../../../../constants/language_tokens';

const VariablesScalableDataType = (props) => {
    const { code, name, onChangeName, disabled, onChangeScalableType, scalableType, onChangeScalableDataTypeValue } =
        props;
    const selectedScalableType = SCALABLE_TYPES.find((scalable) => scalable.value === scalableType);

    const { t } = useTranslate();

    return (
        <div className={scalableWrapper}>
            <FormControl variant="outlined" size="small" className={scalableTypes}>
                <Autocomplete
                    size="small"
                    className={scalableTypesAutocomplete}
                    options={sortScalableTypesArrayByName(SCALABLE_TYPES).map((scalableType) => scalableType)}
                    disableClearable={true}
                    disabled={disabled}
                    value={scalableType ? t(getSelectedScalableTypeObject(scalableType, SCALABLE_TYPES)?.name) : ''}
                    getOptionLabel={(option) => {
                        return typeof option === 'object' ? t(option.name) : option;
                    }}
                    getOptionSelected={(option, value) => t(option.name) === t(value.name)}
                    onChange={(e, scalableType) => onChangeScalableType(scalableType?.value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(VARIABLES_FIELDS_LABELS.SCALABLE.SCALABLE_TYPE)}
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>
            {scalableType && <VariablesNameInput name={name} onChangeName={onChangeName} disabled={disabled} />}
            {name && (
                <>
                    <div className={expressionItemEqualSymbol}>=</div>
                    <TextField
                        type="number"
                        variant="outlined"
                        name="value"
                        size="small"
                        value={code?.value}
                        disabled={disabled}
                        label={t(VARIABLES_FIELDS_LABELS.VARIABLE_VALUE)}
                        placeholder={t(EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE)}
                        className={scalableTypeValueInput}
                        onChange={onChangeScalableDataTypeValue}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl variant="outlined" size="small" className={scalableTypes}>
                        <InputLabel>{t(VARIABLES_FIELDS_LABELS.SCALABLE.SCALE)}</InputLabel>
                        <Select
                            value={code?.scale}
                            onChange={onChangeScalableDataTypeValue}
                            placeholder={t(EZLOGIC_TITLE_VARIABLE_SCALE)}
                            MenuProps={optionsSelect}
                            disabled={disabled}
                            name="scale"
                            input={<OutlinedInput label={t(VARIABLES_FIELDS_LABELS.SCALABLE.SCALE)} />}
                        >
                            {selectedScalableType?.scale.map((scalable, index) => {
                                return (
                                    <MenuItem key={index} value={scalable?.scaleValue}>
                                        {t(scalable?.scaleName)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </>
            )}
        </div>
    );
};

export default VariablesScalableDataType;
