export const getNode = (controllerData, selectedDeviceId) => {
    const gatewayId = controllerData?.devices?.find(({ _id }) => _id === selectedDeviceId)?.gatewayId;
    if (!gatewayId) {
        return null;
    }
    const pluginId = controllerData?.gateways?.find(({ _id }) => _id === gatewayId)?.pluginId;
    if (!pluginId) {
        return null;
    }

    const node = controllerData?.nodes?.find(({ plugin }) => plugin === pluginId);
    if (!node) {
        return null;
    }

    return node;
};

export const getInterfaceJSONItems = (interfaceJSONData) => {
    if (interfaceJSONData && interfaceJSONData.contents && interfaceJSONData.contents.length > 0) {
        const items = interfaceJSONData.contents.filter((content) => {
            if (content.apply && content.apply.length > 0) {
                return !!content.apply.find(({ element }) => element === 'item');
            }

            return false;
        });

        return items ? items : [];
    }

    return [];
};

export const getInterfaceDataForSelectedBlock = (interfaceJSONItems, firstBlock, blocks) => {
    if (interfaceJSONItems?.length > 0 && firstBlock && blocks?.length > 0) {
        const interfaceItemsForActionsSection = interfaceJSONItems.filter(({ type }) => type === 'setter-api') || [];
        const selectedBlock = blocks.find((item) => item._tempId === firstBlock);

        return (
            interfaceItemsForActionsSection?.find((item) => {
                if (item.apply && item.apply.length > 0) {
                    return !!item.apply.find(({ when }) => when.value === selectedBlock.label.text);
                }

                return false;
            }) || null
        );
    }

    return null;
};

export const getInterfaceJSONData = (plugins, selectedNode) => {
    if (selectedNode?.interface?.contents?.contents && selectedNode?.interface?.contents?.contents.length > 0) {
        return selectedNode.interface.contents;
    }

    if (plugins && plugins.length > 0 && selectedNode?.version) {
        const node = plugins.find((plugin) => {
            if (plugin.meta.config) {
                const { config } = plugin.meta;

                return config.id === selectedNode.plugin && config.version === selectedNode.version;
            }

            return false;
        });

        return node?.meta?.interface ? node.meta.interface : null;
    }

    return null;
};

export const getInitialValuesForDynamicFields = (inputs) => {
    return inputs.reduce((prev, input) => {
        if (input.required) {
            let inputValue = '';

            if (input.enum && input.enum.length > 0) {
                // eslint-disable-next-line
                inputValue = input.enum[0];
            }

            if (input.default) {
                inputValue = input.default;
            }

            return { ...prev, [input.name]: inputValue };
        }

        if (input.default) {
            return { ...prev, [input.name]: input.default };
        }

        return prev;
    }, {});
};
