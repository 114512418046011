import React from 'react';
import Input from '../../../../../components/InputMaterial';
import InfoButton from '../../../EzloMeshbot/components/InfoButton';
import * as plugin from '../../../../../constants/Plugins';
import { MenuItem } from '@material-ui/core';
import SelectComponent from '../../../EzloMeshbot/components/SelectComponent';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_DESCRIPTION } from '../../../../../constants/language_tokens';
import styles from '../../../EzloIntegrations/EzloIntegrationPages/EzloIntegrationsCreatePluginIntegration/style.module.scss';

const InputDefault = ({ input, inputs, error, onSetInputsValue, isIntegration }) => {
    const { name, label, required, type, description, enumList } = input;
    const { t } = useTranslate();

    return (
        <div className={isIntegration ? styles.inputField : 'modal-plugin__configure-field'}>
            <p className="modal-plugin__configure-label">{label}</p>
            {enumList ? (
                <SelectComponent
                    name={name}
                    value={inputs?.[name] ?? ''}
                    error={error}
                    info={description}
                    onChange={onSetInputsValue}
                >
                    {enumList.map((item) => {
                        return (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            ) : (
                <Input
                    type={type === plugin.INT || type === plugin.FLOAT ? plugin.NUMBER : type}
                    required={required}
                    name={name}
                    error={error}
                    value={inputs ? inputs[name] : ''}
                    autoComplete="new-password"
                    placeholder={`Add ${label}`}
                    endAdornment={
                        !isIntegration && (
                            <InfoButton>
                                <h4>{t(EZLOGIC_TITLE_DESCRIPTION)}</h4>
                                <p>{description}</p>
                            </InfoButton>
                        )
                    }
                    onChange={onSetInputsValue}
                />
            )}
        </div>
    );
};

export default InputDefault;
