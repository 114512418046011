import { createSelector } from 'reselect';

const getControllerTimeOffset = (currentController, ezlo) => {
    const currentControllerData = ezlo.data[currentController];
    if (currentControllerData && currentControllerData.properties?.ezloLocation) {
        return currentControllerData.properties.ezloLocation?.timeOffset || 0;
    }

    return 0;
};

const getControllerArchitecture = (currentController, ezlo) => {
    const currentControllerData = ezlo.data[currentController];
    if (currentControllerData && currentControllerData.info?.architecture) {
        return currentControllerData.info.architecture;
    }

    return null;
};

export const controllerTimeOffsetSelector = createSelector(
    (state) => state.ezlo,
    (_, currentController) => currentController,
    (ezlo, currentController) => getControllerTimeOffset(currentController, ezlo),
);
export const controllerArchitectureSelector = createSelector(
    (state) => state.ezlo,
    (_, currentController) => currentController,
    (ezlo, currentController) => getControllerArchitecture(currentController, ezlo),
);

const serialSelector = ({ ezlo }) => ezlo.serial;
const isConnectedSelector = ({ ezlo }) => ezlo.isConnected;
const backupsViewSelector = ({ backups }) => backups.view;
const isBackupSupportedSelector = ({ backups }) => backups.isBackupSupported;
const backupsSelector = ({ backups }) => backups.backups;
const createBackupProgressValueSelector = ({ backups }) => backups.createBackupProgressValue;
const restoreBackupStepSelector = ({ backups }) => backups.restoreBackupStep;
const selectedBackupSelector = ({ backups }) => backups.selectedBackup;
const restoreBackupProgressValueSelector = ({ backups }) => backups.restoreBackupProgressValue;
const isBackupsLoadingSelector = ({ backups }) => backups.isBackupsLoading;
const createBackupStepSelector = ({ backups }) => backups.createBackupStep;
const isNewBackupInProgressSelector = ({ backups }) => backups.isNewBackupInProgress;
const isRestoreBackupInProgressSelector = ({ backups }) => backups.isRestoreBackupInProgress;
const isNewBackupCompletedSelector = ({ backups }) => backups.isNewBackupCompleted;
const isRestoreBackupCompletedSelector = ({ backups }) => backups.isRestoreBackupCompleted;
const controllerItemsSelector = ({ ezlos }) => ezlos?.items;

export const structuredBackupsSelector = createSelector(
    serialSelector,
    isConnectedSelector,
    backupsViewSelector,
    backupsSelector,
    createBackupProgressValueSelector,
    restoreBackupStepSelector,
    selectedBackupSelector,
    restoreBackupProgressValueSelector,
    isBackupsLoadingSelector,
    createBackupStepSelector,
    isNewBackupInProgressSelector,
    isRestoreBackupInProgressSelector,
    isNewBackupCompletedSelector,
    isRestoreBackupCompletedSelector,
    controllerItemsSelector,
    isBackupSupportedSelector,
    (
        serial,
        isConnected,
        view,
        backups,
        createBackupProgressValue,
        restoreBackupStep,
        selectedBackup,
        restoreBackupProgressValue,
        isBackupsLoading,
        createBackupStep,
        isNewBackupInProgress,
        isRestoreBackupInProgress,
        isNewBackupCompleted,
        isRestoreBackupCompleted,
        controllersList,
        isBackupSupported,
    ) => {
        return {
            serial,
            isConnected,
            view,
            backups,
            backupsExist: !!backups.length,
            createBackupProgressValue,
            restoreBackupStep,
            selectedBackup,
            restoreBackupProgressValue,
            isBackupsLoading,
            createBackupStep,
            isNewBackupInProgress,
            isRestoreBackupInProgress,
            isNewBackupCompleted,
            isRestoreBackupCompleted,
            controllersList,
            isBackupSupported,
        };
    },
);
