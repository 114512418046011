import React from 'react';
import { Switch } from '@material-ui/core';

import { useTranslate } from '../../../languages';
import { EZLOGIC_TITLE_INSTALLER_DEALER_ACCESS } from '../../../../constants/language_tokens';

import styles from '../../components/InstallerAccessRights/installerAccess.module.scss';

const InstallerAccessRightsToggle = ({ value, onClick }) => {
    const { t } = useTranslate();

    return (
        <div className={styles['installer-access-rights']}>
            <p className={styles['installer-dealer-text']}>{t(EZLOGIC_TITLE_INSTALLER_DEALER_ACCESS)}</p>
            <Switch checked={value} color="primary" onClick={onClick} />
        </div>
    );
};

export default InstallerAccessRightsToggle;
