import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import mediaSlice from '../reducer/media';
import actionCreators from '../actions/media';

export const useMediaActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators({ ...actionCreators, ...mediaSlice.actions }, dispatch);
};
