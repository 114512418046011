import React, { useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import AutocompleteSelect from '../AutocompleteSelect';
import AccountActions from '../../../../../actions/AccountActions';
import { VALUE_TYPES } from '../../../../../constants/AccountInfo';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_CITY_OR_TOWN } from '../../../../../constants/language_tokens';

const CitySelect = ({ name, isDisabled, PK_Country, PK_Region }) => {
    const dispatch = useDispatch();
    const { setFieldValue, values } = useFormikContext();
    const fieldValue = getIn(values, name);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(!options?.length && !!PK_Country && !!PK_Region);
    const { t } = useTranslate();

    useEffect(() => {
        const fetchCities = async () => {
            setLoading(true);
            const cities = await dispatch(AccountActions.getCities(PK_Country, PK_Region));
            await setOptions(cities);
            setLoading(false);
        };
        if (PK_Country && PK_Region) {
            fetchCities();
        }
    }, [PK_Country, PK_Region]);

    useEffect(() => {
        if (options.length && fieldValue && typeof fieldValue === VALUE_TYPES.STRING) {
            const value = options.find((option) => option.Name === fieldValue) || '';
            setFieldValue(name, value);
        }
    }, [options]);

    const handleChange = (e, value) => {
        setFieldValue(name, value);
    };

    const autocompleteProps = {
        options,
        loading,
        disabled: isDisabled || !PK_Country || !PK_Region,
        name,
    };

    return (
        <AutocompleteSelect
            label={t(EZLOGIC_TITLE_CITY_OR_TOWN)}
            handleChange={handleChange}
            autocompleteProps={autocompleteProps}
            inputEndAdornment={loading ? <CircularProgress color="inherit" size={20} /> : null}
        />
    );
};

export default CitySelect;
