import React, { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../SelectComponent';

import { info } from '../../utils';
import { generateHouseModeField } from './utils';
import { getModesListByOemId } from '../TriggerHouseMode/utils';
import { HOUSE_MODE_DEFAULT_VALUE } from '../../../../../constants/MeshbotConstant';
import oemManager from '../../../../../../src/services/oem';

import styles from './HouseMode.module.scss';
import { useTranslate } from '../../../../../features/languages';
import ActionDelay from '../../../../../components/ActionDelay';

const HouseMode = ({
    id,
    MeshBotAction,
    currentItem,
    blockType,
    currentRule,
    isShowedDelay,
    onUpdateActionDelay,
    onChangeDelay,
    onSetIsShowedDelay,
    selectedFieldTrigger,
    actionBlockName,
}) => {
    const [mode, setMode] = useState(currentItem.blocks[0]?.fields[0]?.value || HOUSE_MODE_DEFAULT_VALUE);
    const oemId = oemManager.oem.getOemId();
    const { t } = useTranslate();
    useEffect(() => {
        MeshBotAction.updateHouseModeAction(id, generateHouseModeField(mode, blockType), blockType);
    }, [mode]);

    const handleChange = (e) => {
        setMode(e.target.value);
    };

    return (
        <div className={styles.houseModeBox}>
            <SelectComponent value={mode} label={info.hints.mode} info={info.text.mode} onChange={handleChange}>
                {getModesListByOemId(oemId).map((houseMode) => {
                    return (
                        <MenuItem key={houseMode.id} value={houseMode.value}>
                            {t(houseMode.name)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
            <ActionDelay
                id={id}
                currentRule={currentRule}
                actionBlockName={actionBlockName}
                type={selectedFieldTrigger}
                isShowedDelay={isShowedDelay}
                onUpdateActionDelay={onUpdateActionDelay}
                onChangeDelay={onChangeDelay}
                onSetIsShowedDelay={onSetIsShowedDelay}
            />
        </div>
    );
};

export default HouseMode;
