import React from 'react';
import { TreeView } from '@material-ui/lab';
import { ExpandMore, ChevronRight } from '@material-ui/icons';

import DeviceGroupCategories from '../DeviceGroupCategories';

import styles from './DeviceGroupRoot.module.scss';

const DeviceGroupRoot = (props) => {
    const { payload } = props;

    return (
        <TreeView
            className={styles.rootWrapper}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            disableSelection={true}
        >
            <DeviceGroupCategories payload={payload?.categories} />
        </TreeView>
    );
};

export default DeviceGroupRoot;
