import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../../components';
import Button from '@material-ui/core/Button';
import { MESHBOT_TYPES } from '../../constants';
import './modalCreate.scss';

import style from './ModalCreate.module.scss';
import { checkIsInteractionFlowMeshBotAllowedBySsoType, checkIsLocalMeshBotAllowedBySsoType } from './utils';
import * as localStorageKeys from '../../../../../constants/localStorageKeys';
import {
    EZLOGIC_BUTTON_CREATE,
    EZLOGIC_TITLE_AUTOMATION_MESHBOT,
    EZLOGIC_TITLE_CLOUD,
    EZLOGIC_TITLE_CREATE_MESHBOT,
    EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT,
    EZLOGIC_TITLE_LOCAL,
    EZLOGIC_TITLE_NOTIFICATION_MESHBOT,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { InteractionIcon, MeshbotNewIcon, NotificationMeshbot } from '../../../../../assets/icons';

const ModalCreate = (props) => {
    const { isModalVisible, onCancel, onCreate, isControllerDisabled } = props;
    // TODO: temp solution, should be moved to store
    const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
    const isInteractionFlowAllowed = checkIsInteractionFlowMeshBotAllowedBySsoType(ssoType);
    const isLocalMeshBotAllowed = checkIsLocalMeshBotAllowedBySsoType(ssoType);
    const { t } = useTranslate();

    return (
        <Modal visible={isModalVisible} onCancel={onCancel} className={style.modal}>
            <div className={style.wrapper}>
                <h2>{t(EZLOGIC_TITLE_CREATE_MESHBOT)}</h2>
                <div className={style.body}>
                    <section className={style.section}>
                        <div>
                            <div className={style.icon}>
                                <MeshbotNewIcon />
                            </div>
                            <h3>{t(EZLOGIC_TITLE_AUTOMATION_MESHBOT)}</h3>
                        </div>
                        <div className={style.group}>
                            <Button variant="contained" color="primary" onClick={() => onCreate(MESHBOT_TYPES.CLOUD)}>
                                {t(EZLOGIC_TITLE_CLOUD)}
                            </Button>
                            {isLocalMeshBotAllowed && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isControllerDisabled}
                                    onClick={() => onCreate(MESHBOT_TYPES.LOCAL)}
                                >
                                    {t(EZLOGIC_TITLE_LOCAL)}
                                </Button>
                            )}
                        </div>
                    </section>
                    <section className={style.section}>
                        <div>
                            <div className={style.icon}>
                                <NotificationMeshbot />
                            </div>
                            <h3>{t(EZLOGIC_TITLE_NOTIFICATION_MESHBOT)}</h3>
                        </div>
                        <Button variant="contained" color="primary" onClick={() => onCreate('notification')}>
                            {t(EZLOGIC_BUTTON_CREATE)}
                        </Button>
                    </section>
                    {isInteractionFlowAllowed && (
                        <section className={style.section}>
                            <div>
                                <div className={style.icon}>
                                    <InteractionIcon />
                                </div>
                                <h3>{t(EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT)}</h3>
                            </div>
                            <Button variant="contained" color="primary" onClick={() => onCreate('interaction')}>
                                {t(EZLOGIC_BUTTON_CREATE)}
                            </Button>
                        </section>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalCreate;

ModalCreate.prototype = {
    isModalVisible: PropTypes.bool,
    isControllerDisabled: PropTypes.bool,
    onCreate: PropTypes.func,
    onCancel: PropTypes.func,
};
