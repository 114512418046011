import React from 'react';
import ChildDevicesAutocomplete from '../ChildDevicesAutocomplete';
import { information } from './style.module.scss';
import { SAVE } from '../../../../../constants/ComponentConstants';
import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';
import {
    EZLOGIC_TITLE_CHILD_DEVICES,
    EZLOGIC_TITLE_MOVE_CHILD_DEVICES,
    EZLOGIC_TITLE_ROOM_MANAGEMENT_CONTENT,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DeviceRoomManagement = ({
    isModalVisible,
    onCancel,
    childDevices,
    onChange,
    checkedOptions,
    onSave,
    roomList,
}) => {
    const { t } = useTranslate();
    const DeviceRoomManagementFieldsJsx = (
        <>
            <span className={information}>{t(EZLOGIC_TITLE_ROOM_MANAGEMENT_CONTENT)}</span>
            <ChildDevicesAutocomplete
                options={childDevices}
                onChange={onChange}
                checkedOptions={checkedOptions}
                roomList={roomList}
                label={t(EZLOGIC_TITLE_CHILD_DEVICES)}
            />
        </>
    );

    return (
        <ConfirmModal
            isOpened={isModalVisible}
            onClose={onCancel}
            title={t(EZLOGIC_TITLE_MOVE_CHILD_DEVICES)}
            ConfirmModalFields={DeviceRoomManagementFieldsJsx}
            confirmButtonTitle={SAVE}
            onCancel={onCancel}
            onConfirm={onSave}
        />
    );
};

export default DeviceRoomManagement;
