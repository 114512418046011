import './style.scss';
import React from 'react';
import { Modal } from '../../../../components';
import { Button } from '@material-ui/core';
import InfoIcon from './InfoIcon';
import classNames from 'classnames';
import { EZLOGIC_BUTTON_CONFIRM_AND_REMOVE, EZLOGIC_HEADING_GROUP_DELETE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const DeleteGroupDialog = (props) => {
    const { show, message, onSubmit, onClose } = props;
    const { t } = useTranslate();

    return (
        <Modal
            footer={null}
            closable
            centered
            visible={show}
            onCancel={onClose}
            className="delete-group-modal"
            maskClosable
            maskStyle={{
                background: 'rgba(28, 30, 33, 0.75)',
            }}
        >
            <>
                <InfoIcon height={200} width={200} style={{ margin: '40px auto 0' }} />
                <h2 className="title">{t(EZLOGIC_HEADING_GROUP_DELETE)}</h2>
                <div className="message">{message}</div>
                <Button focused={true} className={classNames('btn', 'warning')} onClick={onSubmit}>
                    {t(EZLOGIC_BUTTON_CONFIRM_AND_REMOVE)}
                </Button>
            </>
        </Modal>
    );
};

export default DeleteGroupDialog;
