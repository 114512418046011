import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_BUTTON_CONFIRM,
    EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE,
} from '../../../../../constants/language_tokens';

const ConfirmationAction = (props) => {
    const { currentSerial, scriptData, currentAction, MeshBotAction, onCancel } = props;
    const { t } = useTranslate();
    const sendRequest = () => {
        switch (currentAction) {
            case 'remove':
                MeshBotAction.removeLuaScripts(currentSerial, scriptData.row.id);
                break;
            default:
                break;
        }

        onCancel();
    };

    return (
        <div className="modal-script__confirmation">
            <p className="modal-script__confirmation-title">{t(EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE)}</p>
            <div className="modal-script__confirmation-buttons">
                <Button variant="outlined" onClick={onCancel}>
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </Button>
                <Button variant="contained" color="primary" onClick={sendRequest}>
                    {t(EZLOGIC_BUTTON_CONFIRM)}
                </Button>
            </div>
        </div>
    );
};

ConfirmationAction.propTypes = {
    scriptData: PropTypes.object,
    currentAction: PropTypes.string,
    MeshBotAction: PropTypes.object,
    onCancel: PropTypes.func,
};

export default ConfirmationAction;
