import { toast } from 'react-toastify';

import { apiGetInstallerAccessRights } from '../../../api/apiScenes';
import { TOAST_TYPE } from '../../../components/Toast';
import { ALLOW_INSTALLER_ACCESS_KVS_KEY, LOCATION_TYPE, SUCCESS, ZERO_INT } from '../constants';

export const getInstallerAccessRightsStatus = async () => {
    try {
        const installerAccessRights = await apiGetInstallerAccessRights(ALLOW_INSTALLER_ACCESS_KVS_KEY, LOCATION_TYPE);
        if (installerAccessRights.data?.data?.list[ZERO_INT]?.value && installerAccessRights.data?.status === SUCCESS) {
            return installerAccessRights.data?.data?.list[ZERO_INT]?.value;
        }
    } catch (error) {
        toast(error, { type: TOAST_TYPE.ERROR });
    }

    return null;
};
