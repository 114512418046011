import React from 'react';

import HeaderComponent from '../../components/Header';
import SecurityAccountInfo from '../SecurityAccountInfo';
import AccountInfo from '../AccountInfo';
import oemManager from '../../../../../services/oem';
import { OEM_IDS } from '../../../../../services/oem/src/constants/oems';

import { accountWrapper } from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_ACCOUNT_INFO } from '../../../../../constants/language_tokens';

const AccountLayout = () => {
    const oemId = oemManager.oem.getOemId();
    const isSecurityOem = OEM_IDS.SECURITY_OEM_ID === oemId;
    const { t } = useTranslate();

    return (
        <section className={accountWrapper}>
            <HeaderComponent title={t(EZLOGIC_TITLE_ACCOUNT_INFO)} arrowBack={false} />

            {isSecurityOem ? <SecurityAccountInfo /> : <AccountInfo />}
        </section>
    );
};

export default AccountLayout;
