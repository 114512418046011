import './style.scss';
import React, { Component } from 'react';
import WarningIcon from '../../icons/WarningIcon';
import { Button } from '../../../../../components';
import classNames from 'classnames';
import {
    EZLOGIC_BUTTON_START_RESTORING_BACKUP,
    EZLOGIC_TITLE_CONTROLLER_WILL_NOT_RESPOND,
    EZLOGIC_TITLE_PROCESS_MAKE_A_FEW_MINUTES,
} from '../../../../../constants/language_tokens';

class RestoreBackupDialog extends Component {
    handleConfirmRestoring = () => {
        const { onConfirmRestoreBackup } = this.props;
        onConfirmRestoreBackup();
    };

    render() {
        const { t } = this.props;

        return (
            <div className="main-content-dialog">
                <WarningIcon className="icon-dialog" />
                <span className={classNames('text', 'title-dialog')}>
                    {t(EZLOGIC_TITLE_CONTROLLER_WILL_NOT_RESPOND)}
                </span>
                <span className={classNames('text', 'description')}>{t(EZLOGIC_TITLE_PROCESS_MAKE_A_FEW_MINUTES)}</span>
                <div className="main-content-footer-dialog">
                    <Button
                        color="primary"
                        variant="contained"
                        className="warning"
                        onClick={this.handleConfirmRestoring}
                    >
                        {t(EZLOGIC_BUTTON_START_RESTORING_BACKUP)}
                    </Button>
                </div>
            </div>
        );
    }
}

export default RestoreBackupDialog;
