import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpressionForCompareSelect from './components/ExpressionForCompareSelect';
import SelectValueToCompareType from '../../../../../components/SelectValueToCompareType';
import ExpressionComparatorDropdown from './components/ExpressionComparatorDropdown';
import ExpressionComparedValue from './components/ExpressionComparedValue';

import {
    BETWEEN,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    NOT_BETWEEN,
    MESHBOT_NODE_TYPES,
    COMPARISON_DATA,
} from '../../../../../constants/MeshbotConstant';
import { EXPRESSIONS_TYPE, COMPARED_TYPE_VALUE, COMPARATOR } from '../../../../../constants/Expressions';

import { MeshBotAction } from '../../../../../actions';

import {
    findSelectedExpression,
    generateExpressionComparisonBlock,
    getExpressionComparisonInitialValuesByFieldName,
    getExpressionComparisonInitialValuesByFieldType,
    isSupportedExpressionValueType,
    isSupportedVariableValueType,
} from './utils';
import { getExpressionsSelector } from '../../../EzloExpressions/utils';

const ExpressionComparison = (props) => {
    const { id, idGroup, currentItem, isException, expressionComparisonType } = props;

    const dispatch = useDispatch();
    const expressionListSelector = useSelector(getExpressionsSelector);

    const [expressionList, setExpressionList] = useState([]);

    const isExpression = expressionComparisonType === MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON;
    const initialExpressionName = getExpressionComparisonInitialValuesByFieldType(
        currentItem,
        EXPRESSIONS_TYPE.EXPRESSION,
    );
    const initialComparatorValue = getExpressionComparisonInitialValuesByFieldName(currentItem, COMPARATOR);
    const initialComparingValue = getExpressionComparisonInitialValuesByFieldName(currentItem, COMPARED_TYPE_VALUE);
    const initialRangeStartValue = getExpressionComparisonInitialValuesByFieldName(
        currentItem,
        COMPARISON_DATA.START_VALUE,
    );
    const initialRangeEndValue = getExpressionComparisonInitialValuesByFieldName(
        currentItem,
        COMPARISON_DATA.END_VALUE,
    );
    const initialComparedValue = currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockMeta?.comparedValue;
    const selectedExpressionType = typeof findSelectedExpression(initialExpressionName, expressionList)?.value;
    const selectedExpressionValueType =
        !isExpression && findSelectedExpression(initialExpressionName, expressionList)?.valueType;

    useEffect(() => {
        if (!isExpression) {
            setExpressionList(
                expressionListSelector?.filter(
                    (item) => item?.variable && isSupportedVariableValueType(item?.valueType),
                ),
            );
        } else {
            setExpressionList(
                expressionListSelector?.filter(
                    (item) => !item?.variable && isSupportedExpressionValueType(item?.value) && !item.error,
                ),
            );
        }
    }, [expressionListSelector, isExpression]);

    const onChangeValueToCompareType = (e) => {
        handleExpressionComparisonFieldsChange(
            initialExpressionName,
            expressionList,
            initialComparatorValue,
            e.target.value,
        );
    };

    const handleExpressionComparisonFieldsChange = (expressionName, expressionList, ...fieldsValue) => {
        if (isException) {
            dispatch(
                MeshBotAction.updateMeshBotExceptionTrigger(
                    expressionName,
                    id,
                    generateExpressionComparisonBlock(isExpression, expressionName, expressionList, ...fieldsValue),
                    idGroup,
                ),
            );
        }

        dispatch(
            MeshBotAction.updateMeshBotTrigger(
                expressionName,
                id,
                generateExpressionComparisonBlock(isExpression, expressionName, expressionList, ...fieldsValue),
                idGroup,
            ),
        );
    };

    return (
        <>
            <ExpressionForCompareSelect
                expressionList={expressionList}
                initialExpressionName={initialExpressionName}
                onHandleExpressionComparisonFieldsChange={handleExpressionComparisonFieldsChange}
                expressionComparisonType={expressionComparisonType}
            />
            {initialExpressionName && (
                <ExpressionComparatorDropdown
                    initialComparatorValue={initialComparatorValue}
                    onSetComparator={handleExpressionComparisonFieldsChange}
                    initialExpressionName={initialExpressionName}
                    expressionList={expressionList}
                    selectedExpressionValueType={selectedExpressionValueType}
                    isExpression={isExpression}
                    selectedExpressionType={selectedExpressionType}
                />
            )}
            {initialComparatorValue && (
                <SelectValueToCompareType
                    fieldValue={initialComparedValue}
                    onChangeValueToCompareType={onChangeValueToCompareType}
                    isExpressionComparison={true}
                    isRangeComparators={initialComparatorValue === BETWEEN || initialComparatorValue === NOT_BETWEEN}
                    expressionComparisonType={expressionComparisonType}
                />
            )}
            {initialComparedValue && (
                <ExpressionComparedValue
                    comparedType={initialComparedValue}
                    initialComparatorValue={initialComparatorValue}
                    expressionList={expressionList}
                    comparingValue={initialComparingValue}
                    isComparedValue={true}
                    initialExpressionName={initialExpressionName}
                    expressionListSelector={expressionListSelector}
                    selectedExpressionType={selectedExpressionType}
                    onHandleExpressionComparisonFieldsChange={handleExpressionComparisonFieldsChange}
                    isExpressionComparison={isExpression}
                    initialRangeStartValue={initialRangeStartValue}
                    initialRangeEndValue={initialRangeEndValue}
                />
            )}
        </>
    );
};

export default ExpressionComparison;
