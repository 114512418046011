import React from 'react';
import classNames from 'classnames';

import { section, sectionWithMargin } from './MeshBotSection.module.scss';

const MeshBotSection = ({ withMargin, children }) => {
    const className = classNames(section, {
        [sectionWithMargin]: withMargin,
    });

    return <section className={className}>{children}</section>;
};

export default MeshBotSection;
