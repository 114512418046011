import PropTypes from 'prop-types';

import { getIconByDeviceCategory } from '../containers/Ezlo/EzloSystemHistory/utils';

const DeviceIconContainer = ({ category, onClick = null, iconStyles }) => {
    const iconProps = {
        onClick,
        className: iconStyles,
    };

    return getIconByDeviceCategory(category, iconProps);
};

export default DeviceIconContainer;

DeviceIconContainer.propTypes = {
    onClick: PropTypes.func,
    category: PropTypes.string,
};
