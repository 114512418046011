import { connect } from 'react-redux';
import * as Actions from '../../../../../actions';
import { bindActionCreators } from 'redux';
import PluginsPrivate from './PluginsPrivate';
import { getControllers } from '../../../../../helpers/helpersPlugins';
import { prepareDevices } from '../../../EzloDevices/utils';

const mapStateToProps = (state) => ({
    devices: prepareDevices(state.ezlo.data),
    privatePlugins: state.plugins.privatePlugins,
    loaderList: state.plugins.loaderList,
    listInstalledPlugins: state.plugins.listInstalledPlugins,
    newJobUuid: state.plugins.newJobUuid,
    currentJob: state.plugins.currentJob,
    controllers: getControllers(state.ezlo.data),
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginsPrivate);
