import { ADDED } from '../../../constants/DeviceAssociations';
import { toast } from '../../../components/Toast';
import {
    EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_ADDED,
    EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_REMOVED,
} from '../../../constants/language_tokens';

import { ARRAY_ASSOCIATION } from '../../../constants/DeviceAssociations';
import { ONE } from '../../../features/EzloPolicies/constants';
import { ZERO } from '../EzloCustomization/constants';
import { ONE_INT, ZERO_INT } from '../../../constants/Variables';

export const getCurrentSourceChanel = (id = '', settings = []) => {
    if (id) {
        return settings.filter(
            (item) => item?.deviceId?.includes(id) && item?.elementType?.includes('association_group'),
        );
    }

    return [];
};

export const getArrayAssociations = (id = '', settings = []) => {
    if (id) {
        return settings.filter(
            (item) => item?.deviceId?.includes(id) && item?.elementType?.includes('array.association'),
        );
    }

    return [];
};

export const getTargetDeviceByNodeId = (nodeId, devicesList) => {
    let currentDevice = {};
    devicesList.forEach((device) => {
        if (nodeId === Number(device.info['zwave.node']) && !device.parentDeviceId) {
            currentDevice = device;
        }
    });

    return currentDevice;
};

export const getArrayAssociationsList = (settings = [], devicesList = []) => {
    const arrayOfElementType = settings.filter(
        (item) => item?.value && item?.elementType?.includes('array.association'),
    );
    const listOfAssociationsWithoutTargetDevice = [];
    const listOfAssociationsWithTargetDevice = [];
    arrayOfElementType.forEach((setting = []) => {
        devicesList.forEach((device) => {
            if (setting.deviceId === device._id) {
                listOfAssociationsWithoutTargetDevice.push({
                    sourceDevice: device,
                    values: setting.value,
                    settingId: setting._id,
                });
            }
        });
    });

    listOfAssociationsWithoutTargetDevice.forEach((association) => {
        for (const [keyOfTargetNodes, targetNodesOfSettings] of Object.entries(association.values)) {
            targetNodesOfSettings.forEach((targetNode) => {
                listOfAssociationsWithTargetDevice.push({
                    ...association,
                    targetDevice: getTargetDeviceByNodeId(targetNode.targetNode, devicesList),
                    keyOfTargetNodes: keyOfTargetNodes,
                    keyForRender: targetNode.targetNode,
                });
            });
        }
    });

    return listOfAssociationsWithTargetDevice;
};

export const getCurrentDevice = (devicesList = [], id) => {
    return devicesList.find((item) => item?._id === id);
};

export const returnAssociationsChannels = (associationsChannelsAndGroups = []) => {
    return Array.from(
        new Set(
            associationsChannelsAndGroups.map((item) => {
                return item.split('_')[0];
            }),
        ),
    );
};

export const returnAssociationsGroups = (associationsChannelsAndGroups = []) => {
    return Array.from(
        new Set(
            associationsChannelsAndGroups.map((item) => {
                return item.split('_')[1];
            }),
        ),
    );
};

export const createCurrentKey = (sourceChannelValue, sourceGroupValue) => {
    return sourceChannelValue + '_' + sourceGroupValue;
};

export const isTargetChannelSupported = (id, settings = []) => {
    return !!settings.filter((item) => item?.deviceId?.includes(id) && item?.targetChannelSupported).length;
};

export const returnUniqueNodeIdDevices = (devices) => {
    return Object.values(
        devices.reduce((unique, device) => {
            if (
                !unique[device?.info?.['zwave.node']] ||
                +device?.info?.['zwave.node'] > +unique[device?.info?.['zwave.node']]
            ) {
                unique[device?.info?.['zwave.node']] = device;
            }

            return unique;
        }, {}),
    );
};

export const filterSourceOptions = (options) => {
    return options.filter((item) => item?.info?.['zwave.node']);
};

export const filterTargetOptions = (options, currentDeviceId) => {
    return returnUniqueNodeIdDevices(options).filter(
        (item) => item?.info?.['zwave.node'] && item?._id !== currentDeviceId,
    );
};

export const returnCreateAssociationPayload = (targetChannelValue, targetNode) => {
    if (targetChannelValue !== 'non-specified') {
        return {
            targetChannel: targetChannelValue,
            targetNode,
        };
    } else {
        return { targetNode };
    }
};

export const returnAssociationObj = (currentDeviceId, sourceChannelValue, sourceGroupValue, settings) => {
    if (
        getArrayAssociations(currentDeviceId, settings)?.[0]?.value?.[
            createCurrentKey(sourceChannelValue, sourceGroupValue)
        ]
    ) {
        return [
            ...getArrayAssociations(currentDeviceId, settings)?.[0]?.value?.[
                createCurrentKey(sourceChannelValue, sourceGroupValue)
            ],
        ];
    }
};

export const createOnSavePayload = (
    currentDeviceId,
    sourceChannelValue,
    sourceGroupValue,
    targetChannelValue,
    targetNode,
    settings,
) => {
    if (returnAssociationObj(currentDeviceId, sourceChannelValue, sourceGroupValue, settings)) {
        return [
            ...returnAssociationObj(currentDeviceId, sourceChannelValue, sourceGroupValue, settings),
            returnCreateAssociationPayload(targetChannelValue, targetNode),
        ];
    } else {
        return [returnCreateAssociationPayload(targetChannelValue, targetNode)];
    }
};

export const createOnRemovePayload = (settingId, keyOfTargetNodes, zwaveNode, values) => {
    return {
        _id: settingId,
        key: keyOfTargetNodes,
        value: values[keyOfTargetNodes].filter((value) => value?.targetNode !== zwaveNode),
    };
};

export const validationForAssociations = (
    sourceChannelValue,
    sourceGroupValue,
    targetChannelValue,
    associationsFull,
) => {
    return (
        !(sourceChannelValue.length && sourceGroupValue.length && String(targetChannelValue).length) || associationsFull
    );
};

export const isMaximumAssociation = (deviceId, settings, currentKey) => {
    let currentAssociationsLength = getArrayAssociations(deviceId, settings)?.[0]?.value?.[currentKey]?.length;
    const maximumAssociationsLength = getCurrentSourceChanel(deviceId, settings)?.[0]?.value?.[currentKey]
        ?.maxAssociationsNumber;

    if (getArrayAssociations(deviceId, settings)?.[0]?.value?.[currentKey]?.[0]?.targetNode === 1) {
        currentAssociationsLength = currentAssociationsLength - 1;
    }

    if (maximumAssociationsLength && currentAssociationsLength) {
        return currentAssociationsLength === maximumAssociationsLength;
    } else {
        return false;
    }
};

export const getAssociationCommands = (id, settings, currentKey) => {
    return getCurrentSourceChanel(id, settings)[0]?.value[currentKey]?.commands;
};

export const getMaximumCountOfAssociations = (id, settings, currentKey) => {
    return getCurrentSourceChanel(id, settings)[0]?.value[currentKey]?.maxAssociationsNumber;
};

export const isButteryDevice = (devicesList = [], id) => {
    return getCurrentDevice(devicesList, id)?.batteryPowered;
};

export const returnFirmwareVersion = (version = '') => {
    const splitVersion = version.split('.');
    splitVersion.splice(3, 2);
    // eslint-disable-next-line
    const buildVersion = version.split('.')[3];

    return { dataBeforeBuildVersion: splitVersion.join('.'), buildVersion };
};

export const getUpdatedText = (isAddedText, t) => {
    isAddedText === ADDED
        ? toast(t(EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_ADDED))
        : toast(t(EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_REMOVED));
};

export const isDeleteBtnDisable = (targetDevice) => {
    return !Object.keys(targetDevice).length;
};

export const isAssociationsFull = ({ currentKey, settings, deviceId }) => {
    const countOfMaximumAssociations = getMaximumCountOfAssociations(deviceId, settings, currentKey);
    const lengthOfAssociationsByKey = getArrayAssociations(deviceId, settings).find(
        (el) => el.elementType === ARRAY_ASSOCIATION,
    )?.value?.[currentKey]?.length;

    return lengthOfAssociationsByKey >= countOfMaximumAssociations;
};

export const isDeviceWithOneSupportedAssociation = ({ deviceId, settings, currentKey }) => {
    const countOfMaximumAssociations = getMaximumCountOfAssociations(deviceId, settings, currentKey);

    return countOfMaximumAssociations === ONE;
};

export const getMaximumAssociations = (sourceId, settings) => {
    const result = getCurrentSourceChanel(sourceId, settings)?.[ZERO];
    const value = result !== undefined ? result.value : {};
    const associationsChannelsAndGroups = Object.keys(value);

    return getMaximumCountOfAssociations(
        sourceId,
        settings,
        createCurrentKey(
            returnAssociationsChannels(associationsChannelsAndGroups)[ZERO],
            returnAssociationsGroups(associationsChannelsAndGroups)[ZERO],
        ),
    );
};

export const getCurrentAssociation = (sourceId, associationsList) => {
    const settingIds = associationsList?.filter((item) => item.settingId === sourceId);

    return settingIds?.length;
};

export const getMaximumAssociationCount = (settingId, settings, associationsList, sourceDeviceId) => {
    const currentAssociation = getCurrentAssociation(settingId, associationsList);
    const maximumAssociation = getMaximumAssociations(sourceDeviceId, settings);

    return currentAssociation === maximumAssociation;
};

const isControllerAssociated = (settingId, associationsList) => {
    for (const device of associationsList) {
        if (device?.settingId === settingId) {
            if (!device?.targetDevice || Object.keys(device.targetDevice)?.length === ZERO_INT) {
                return true;
            } else {
                return false;
            }
        }
    }

    return null;
};

export const checkDeviceControllerAssociation = (settingId, settings, associationsList, sourceDeviceId) => {
    const maximumAssociations = getMaximumAssociations(sourceDeviceId, settings);
    if (maximumAssociations === ONE_INT) {
        const isAssociated = isControllerAssociated(settingId, associationsList);

        return isAssociated;
    }
};

export const getMaximumAssociationsFromAllGroups = (sourceDeviceId, settings) => {
    const result = getCurrentSourceChanel(sourceDeviceId, settings)?.[ZERO];

    if (!result || !result.value) {
        return 0;
    }

    return Object.values(result.value).reduce((sum, item) => sum + (item.maxAssociationsNumber || 0), 0);
};
