import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const EditButton = ({ handleClick }) => {
    return (
        <IconButton onClick={handleClick}>
            <Edit fontSize="large" color="primary" />
        </IconButton>
    );
};

export default memo(EditButton);
