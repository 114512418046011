import React, { useEffect, useState } from 'react';
import SearchPopover from '../../../../../../../../../components/SearchPopover';
import { EXCLUDED_METHODS } from '../../paas-constants';
import OpenButton from '../OpenButton';

import styles from './MethodList.module.scss';
import {
    EZLOGIC_PLACEHOLDER_METHODS,
    EZLOGIC_TITLE_SELECT_METHOD,
} from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';

const Method = (props) => {
    const { method, paths, parjectIcon, handleMethodChange } = props;

    return (
        <div
            data-testid={method}
            key={method}
            className={styles.paas__method}
            onClick={() => handleMethodChange(method)}
        >
            <img className={styles.icon} src={parjectIcon} alt={paths[method]?.summary} />
            <div>
                <p className={styles.summary}>{paths[method].summary}</p>
                <p className={styles.description}>{paths[method].description}</p>
            </div>
        </div>
    );
};

export const MethodList = (props) => {
    const { paths, methods, onChangeMethod, methodValue, parjectIcon } = props;

    const [anchorElMethods, setAnchorElMethods] = useState(null);
    const [filter, setFilter] = useState('');
    const [filteredMethods, setFilteredMethods] = useState([]);
    const { t } = useTranslate();

    useEffect(() => {
        const filteredMethods = methods
            ?.filter((method) => {
                return !paths[method].summary.toLowerCase().indexOf(filter.toLowerCase());
            })
            .filter((method) => !EXCLUDED_METHODS.includes(method));
        setFilteredMethods(filteredMethods);
    }, [filter]);

    const handleCloseMethods = () => {
        setAnchorElMethods(null);
    };

    const handleOpenMethods = (event) => {
        setAnchorElMethods(event.currentTarget);
    };

    const handleMethodChange = (methodName) => {
        onChangeMethod(methodName.slice(1));
        handleCloseMethods();
    };

    const { summary } = paths?.[`/${methodValue}`] || {};

    return (
        <>
            <OpenButton
                title={summary}
                onHandleClick={handleOpenMethods}
                icon={parjectIcon}
                defaultTitle={t(EZLOGIC_TITLE_SELECT_METHOD)}
                anchorElAbstract={anchorElMethods}
            />

            <SearchPopover
                placeholder={t(EZLOGIC_PLACEHOLDER_METHODS)}
                anchor={anchorElMethods}
                onChangeFilter={setFilter}
                handleClose={handleCloseMethods}
            >
                <div className={styles.container}>
                    {filteredMethods.map((method) => (
                        <Method
                            key={method}
                            parjectIcon={parjectIcon}
                            paths={paths}
                            method={method}
                            handleMethodChange={handleMethodChange}
                        />
                    ))}
                </div>
            </SearchPopover>
        </>
    );
};
