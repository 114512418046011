import React from 'react';
import PropTypes from 'prop-types';

import InputString from '../InputString';
import SelectEnum from '../SelectEnum';

import * as generator from '../../../../../../../constants/DevicePluginGenerator';

const ActionFields = (props) => {
    const { action, capabilityId, onSetValueItem } = props;

    const createActionField = (typeField) => {
        switch (typeField) {
            case generator.INTEGER:
                return (
                    <InputString
                        type="number"
                        action={action}
                        name={action.id}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                    />
                );
            default:
                return action.id === generator.SCALE ? (
                    <SelectEnum
                        action={action}
                        name={action.id}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                    />
                ) : (
                    <InputString
                        action={action}
                        name={action.id}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                    />
                );
        }
    };

    return createActionField(action.type);
};

ActionFields.propsTypes = {
    action: PropTypes.object,
    capabilityId: PropTypes.string,
    onSetValueItem: PropTypes.func,
};

export default ActionFields;
