import React from 'react';
import { MenuItem, Select } from '@material-ui/core';

import { optionsSelect } from '../../../../../../constants/MeshbotConstant';

import styles from '../styles.module.scss';

const SpecialTypeSelect = ({ treeItemValue, handleTypeChange, selectedType }) => {
    const handleSelectClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={styles.fieldBox}>
            <Select
                MenuProps={optionsSelect}
                onChange={handleTypeChange}
                value={selectedType}
                onClick={handleSelectClick}
            >
                {treeItemValue.keywordTypes.map((type) => {
                    return (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export default SpecialTypeSelect;
