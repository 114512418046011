import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { PRIMARY } from '../../../../../../../constants/MeshbotConstant';
import { EZLOGIC_BUTTON_SHOW_MORE, EZLOGIC_BUTTON_SHOW_LESS } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';
import styles from './DeviceTreeMoreButton.module.scss';

const DeviceTreeMoreButton = (props) => {
    const { onShowAllItems, onHideSomeItems, isShowMore } = props;
    const { t } = useTranslate();

    return (
        <div className={styles.moreButtonWrapper}>
            <Button
                className={styles.moreButton}
                color={PRIMARY}
                onClick={isShowMore ? onHideSomeItems : onShowAllItems}
                endIcon={isShowMore ? <ExpandLess /> : <ExpandMore />}
            >
                {isShowMore ? t(EZLOGIC_BUTTON_SHOW_LESS) : t(EZLOGIC_BUTTON_SHOW_MORE)}
            </Button>
        </div>
    );
};

export default DeviceTreeMoreButton;

DeviceTreeMoreButton.propTypes = {
    isShowMore: PropTypes.bool,
    onShowAllItems: PropTypes.func,
    onHideSomeItems: PropTypes.func,
};
