import React from 'react';

import TreeItemLabel from '../TreeItemLabels/TreeItemLabel';
import TreeItemComponent from '../components/TreeItemComponent';
import RegularTypeTreeElement from './RegularTypeTreeElement';
import ArrayTreeElement from './ArrayTreeElement';
import SpecialTypeTreeElement from './SpecialTypeTreeElement';
import { RESPONSE_ELEMENTS } from '../constants';

const TreeElement = ({ treeElementValue, prevPartPath, treeElementName }) => {
    const currentPath = `${prevPartPath}.${treeElementName}`;
    const elementData = { treeElementName, path: currentPath };
    const strElementData = JSON.stringify(elementData);
    const { TYPES, PROPERTIES, KEYWORD_TYPES } = RESPONSE_ELEMENTS;

    const defaultProps = {
        treeElementValue,
        treeElementName,
        elementData: strElementData,
    };

    if (treeElementValue.hasOwnProperty(KEYWORD_TYPES)) {
        return <SpecialTypeTreeElement {...defaultProps} prevPartPath={currentPath} />;
    }

    if (treeElementValue?.parentType === TYPES.ARRAY) {
        return <ArrayTreeElement {...defaultProps} prevPartPath={prevPartPath} />;
    }

    if (treeElementValue.hasOwnProperty(PROPERTIES)) {
        return <RegularTypeTreeElement {...defaultProps} prevPartPath={currentPath} />;
    }

    const labelJSX = (
        <TreeItemLabel treeItemName={treeElementName} elementData={strElementData} treeItemValue={treeElementValue} />
    );

    return (
        <TreeItemComponent nodeId={treeElementValue.elementId} prevPartPath={currentPath} labelComponent={labelJSX} />
    );
};

export default TreeElement;
