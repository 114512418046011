import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../SelectComponent';
import { info } from '../../utils';
import hash from '../../../../../constants/uniqueHash';

const DeviceGroupNodeForTriggerSection = (props) => {
    const { selectedDeviceGroup, currentDeviceGroups, onDeviceGroupChange } = props;

    return (
        <SelectComponent
            value={selectedDeviceGroup || ''}
            label={info.hints.node}
            info={info.text.nodeType}
            onChange={onDeviceGroupChange}
        >
            {currentDeviceGroups.map((item) => {
                return (
                    <MenuItem key={hash()} value={item._id}>
                        {item.name}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default DeviceGroupNodeForTriggerSection;

DeviceGroupNodeForTriggerSection.propTypes = {
    selectedDeviceGroup: PropTypes.string,
    currentDeviceGroups: PropTypes.array,
    onDeviceGroupChange: PropTypes.func,
};
