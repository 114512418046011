import React from 'react';

export const IcControlsArrowRight = () => {
    return (
        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.24 18.7599L11.3705 11.6295C11.8504 11.1496 12.12 10.4986 12.12 9.81994C12.12 9.14124 11.8504 8.49033 11.3705 8.01041L4.24 0.879941C3.07 -0.290059 1.17 -0.290059 0 0.879941L8.94 9.81994L0 18.7599C1.17 19.9299 3.07 19.9299 4.24 18.7599Z"
                fill="#1C1E21"
            />
        </svg>
    );
};
