import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@material-ui/core';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import TextInput from '../../components/TextInput';
import SaveButton from '../../components/SaveButton/SaveButton';
import AccessCodeField from '../../components/AccessCodeField';
import PinCodeDeviceField from '../../components/PinCodeDeviceField';
import UsersActions from '../../../../../actions/UsersActions';
import { AUTO_COMPLETE_VALUES, SUCCESS } from '../../../../../constants/Users';
import oemManager from '../../../../../services/oem';
import { OEM_IDS } from '../../../../../services/oem/src/constants/oems';

import styles from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_LOCKS,
    EZLOGIC_TITLE_PANELS,
    EZLOGIC_TITLE_PIN_NAME,
    EZLOGIC_TITLE_SECURITY_PANELS,
    EZLOGIC_TITLE_SELECT_ALL,
} from '../../../../../constants/language_tokens';
import { IconSquareOfSquares } from '../../../../../assets/icons';

const PinCodeForm = ({ isEditing }) => {
    const { t } = useTranslate();
    const history = useHistory();
    const dispatch = useDispatch();
    const { values, handleSubmit, setFieldValue, initialValues } = useFormikContext();
    const { isLoading, requestStatus, requestStatusMessage } = useSelector(({ users }) => users);
    const oemId = oemManager.oem.getOemId();
    const isSecurityOem = OEM_IDS.SECURITY_OEM_ID === oemId;
    const isSaveButtonDisabled = isEditing && initialValues.pin === values.pin;

    useEffect(() => {
        if (requestStatus === SUCCESS) {
            toast(requestStatusMessage, { type: TOAST_TYPE.SUCCESS });
            history.goBack();
        }

        return () => {
            dispatch(UsersActions.clearRequestStatus());
        };
    }, [requestStatus]);
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    const handleSelectAll = () => {
        values.devices.forEach((device, index) => {
            setFieldValue(`devices[${index}]`, {
                ...device,
                checked: device.reachable ? true : device.checked,
            });
        });
    };
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // const handleSelectAll = () => {
    //     values.devices.forEach((device, index) => {
    //         setFieldValue(`devices[${index}]`, {
    //             ...device,
    //             checked: device.reachable ? true : device.checked,
    //         });
    //     });
    // };

    return (
        <form className={styles.pinCodeForm} onSubmit={handleSubmit} autoComplete={t(AUTO_COMPLETE_VALUES.OFF)}>
            <div className={styles.formContainer}>
                <TextInput required={true} name="name" label={t(EZLOGIC_TITLE_PIN_NAME)} />
                <AccessCodeField required={true} isEditing={isEditing} setFieldValue={setFieldValue} />

                {isSecurityOem && (
                    <>
                        <div className={styles.subTitle}>
                            <div>{t(EZLOGIC_TITLE_SECURITY_PANELS)}</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>
                                <IconSquareOfSquares className={styles.iconSquareOfSquares} />
                                <div>{t(EZLOGIC_TITLE_PANELS)}</div>
                            </div>

                            <Switch color="primary" name="panel" checked={values.panel} onChange={() => {}} />
                        </div>
                    </>
                )}

                {values.devices?.length > 0 && (
                    <>
                        <div className={styles.subTitle}>
                            <div>
                                <div>{t(EZLOGIC_TITLE_LOCKS)}</div>
                                <div onClick={handleSelectAll} className={styles.selectAllButton}>
                                    {t(EZLOGIC_TITLE_SELECT_ALL)}
                                </div>
                            </div>
                        </div>

                        {values.devices.map((device, index) => (
                            <PinCodeDeviceField
                                key={device._id}
                                device={device}
                                deviceIndex={index}
                                setFieldValue={setFieldValue}
                            />
                        ))}
                    </>
                )}

                <SaveButton isDisabled={isLoading || isSaveButtonDisabled} />
            </div>
        </form>
    );
};

export default PinCodeForm;
