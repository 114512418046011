import React from 'react';
import PropTypes from 'prop-types';

import GeneralTreeItem from './components/GeneralTreeItem';

const DeviceGroupTreeContainer = ({ payload }) => {
    return <GeneralTreeItem payload={payload} />;
};

export default DeviceGroupTreeContainer;

DeviceGroupTreeContainer.propTypes = {
    payload: PropTypes.object,
};
