import React from 'react';

import SearchInput from './SearchInput';
import { EZLOGIC_TITLE_SEARCH_BY_IMAGE_NAME } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';
import useGalleryContext from '../../hooks/useGalleryContext';

const SearchInputContainer = () => {
    const {
        searchInputState: [searchInputValue, setSearchInputValue],
    } = useGalleryContext();
    const handlerChange = ({ target }) => setSearchInputValue(target.value);
    const { t } = useTranslate();

    return (
        <SearchInput
            value={searchInputValue}
            onChangeInput={handlerChange}
            placeholder={t(EZLOGIC_TITLE_SEARCH_BY_IMAGE_NAME)}
        />
    );
};

export default SearchInputContainer;
