import React from 'react';

import { CONFIRM_PASSWORD_REQUIREMENTS, PASSWORD_MUST_MATCH_KEY } from '../../../../../constants/Users';
import PaperPasswordRequirements from './PaperPasswordRequirements';

import passRequirementsStyles from '../../../../../components/PasswordRequirements/style.module.scss';

export const ConfirmPasswordRequirements = ({ formik }) => {
    const getRequirementClassName = (requirement) => {
        if (requirement.key === PASSWORD_MUST_MATCH_KEY) {
            const confirmPassValue = formik.values.confirmPassword;
            const isConfirmPasswordMatch = confirmPassValue && formik.values.newPassword === confirmPassValue;

            return isConfirmPasswordMatch ? passRequirementsStyles.requirementFulfilled__green : '';
        }

        return '';
    };

    return (
        <PaperPasswordRequirements
            passwordRequirements={CONFIRM_PASSWORD_REQUIREMENTS}
            getRequirementClassName={getRequirementClassName}
        />
    );
};
