import React, { useCallback, useEffect, useState } from 'react';
import { DASHBOARD_DOM_ID } from './constants';
import Header from '../../../components/Header';
import { getDashboardSsoSrc } from './utils';
import './style.scss';

const EzloDashboard = () => {
    const [src, setSrc] = useState('');

    const getSrc = useCallback(async () => {
        const newSrc = await getDashboardSsoSrc();
        setSrc(newSrc);
    }, []);

    useEffect(() => {
        // on every page mounting we must regenerate SSO token
        getSrc().catch();
    }, []);

    return (
        <>
            <Header headerClassName="dashboard-header" />
            <section data-testid="dashboardSection" className="ezlo-section ezlo-dashboard-page">
                {src && (
                    <iframe
                        src={src}
                        id={DASHBOARD_DOM_ID}
                        rel="preload"
                        frameBorder="0"
                        referrerPolicy="no-referrer"
                        allowFullScreen
                    />
                )}
            </section>
        </>
    );
};

export default EzloDashboard;
