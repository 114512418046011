import React, { memo } from 'react';
import { Button } from '@material-ui/core';

import { TABLE_BUTTONS } from '../../../../../../../constants/Users';

import { pinCodeBtn } from '../../style.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const PinCodeButton = ({ handleClick }) => {
    const { t } = useTranslate();

    return (
        <Button variant="contained" color="primary" className={pinCodeBtn} onClick={handleClick}>
            <span>{t(TABLE_BUTTONS.PIN_CODE)}</span>
        </Button>
    );
};

export default memo(PinCodeButton);
