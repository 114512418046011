import { isControllerLinuxBased, isControllerOnline } from '../../../../../helpers/controllers';

/**
 * Check is controller integration compatible
 * @param {object} controller - collected controller info
 * @returns {boolean} is compatible result
 * */
const isControllerIntegrationCompatible = (controller) => {
    // Check is online
    if (!isControllerOnline(controller)) {
        return false;
    }

    // Check is linux based
    if (!isControllerLinuxBased(controller)) {
        return false;
    }

    return true;
};

/**
 * Returns an array of online controllers which can be used to install integrations (Plugins, IP Templates)
 * @param {object} data - object with controllers {serial:controller}
 * @returns {array} array of online controllers
 * */
export const getIntegrationsCompatibleControllers = (data) => {
    const serialArray = [];

    for (const [key, controller] of Object.entries(data)) {
        if (isControllerIntegrationCompatible(controller)) {
            serialArray.push(key);
        }
    }

    return serialArray;
};
