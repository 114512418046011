import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash/isEqual';
import { Button, TextField } from '@material-ui/core';
import { ArrowBackIos, Add } from '@material-ui/icons';

import VirtualItem from './VirtualItem';
import { FORM_CREATING_NEW_CONTAINER, CONTAINER_EDITING_FORM } from '../../../constants/ActionConfirmDialog';
import { MainAction } from '../../../actions';

import { initialVirtualDevice, virtualItemKeys } from './virtual-templates';
import {
    addTriggerToVirtualDevice,
    convertVirtualDeviceApiStructureToState,
    deleteTriggerFromVirtualDevice,
    getVirtualDeviceTemplateForCreate,
} from './utils';
import useUnsavedChangesWarning from '../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';
import { Header } from '../../../components';

import style from './EzloVirtualDevice.module.scss';
import { useTranslate } from '../../../features/languages';
import {
    EZLOGIC_BUTTON_ADD_CAPABILITY,
    EZLOGIC_BUTTON_ADD_PROPERTY,
    EZLOGIC_TITLE_ACTION_ITEMS,
    EZLOGIC_TITLE_DEVICE_NAME,
    EZLOGIC_TITLE_EDIT_VIRTUAL_CONTAINER,
    EZLOGIC_TITLE_NEW_VIRTUAL_CONTAINER,
    EZLOGIC_TITLE_TRIGGER_ITEMS,
    EZLOGIC_BUTTON_SAVE,
} from '../../../constants/language_tokens';

const EzloVirtualDevice = (props) => {
    const {
        state: { virtualDevice, virtualDeviceList, savedVirtualDevice },
        actions: { VirtualContainerActions },
    } = props.redux;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const { setVirtualDevice, createVirtualDevice, setSavedVirtualDevice } = VirtualContainerActions;
    const { VIRTUAL_TRIGGERS, VIRTUAL_ACTIONS } = virtualItemKeys;
    const { abstractUuid } = useParams();
    const history = useHistory();
    const [deviceName, setDeviceName] = useState('');
    const [errors, setErrors] = useState({});
    const { name } = virtualDevice;
    const setDirty = useUnsavedChangesWarning();
    const isDirty = useSelector((state) => state.main.editableComponent?.isDirty);

    useEffect(() => {
        virtualDevice.name && setDeviceName(virtualDevice.name);
    }, [virtualDevice.name]);

    useEffect(() => {
        if (abstractUuid) {
            const deviceForEdit = convertVirtualDeviceApiStructureToState(virtualDeviceList[abstractUuid]);

            setVirtualDevice(deviceForEdit);
            setSavedVirtualDevice(deviceForEdit);
        } else {
            setVirtualDevice(initialVirtualDevice);
        }

        return () => {
            setVirtualDevice({});
            setSavedVirtualDevice({});
        };
    }, [abstractUuid, setVirtualDevice, virtualDeviceList]);

    useEffect(() => {
        if (abstractUuid) {
            dispatch(MainAction.setStatePageBeingEdited(CONTAINER_EDITING_FORM));
        } else {
            dispatch(MainAction.setStatePageBeingEdited(FORM_CREATING_NEW_CONTAINER));
        }

        return () => dispatch(MainAction.setStatePageBeingEdited({}));
    }, []);

    useEffect(() => {
        if (!deviceName && !virtualDevice?.virtualTriggers?.length && !virtualDevice?.virtualActions?.length) {
            setDirty(false);
            dispatch(MainAction.setConfirmationUser(false));
        } else if (isEqual(virtualDevice, savedVirtualDevice)) {
            setDirty(false);
            dispatch(MainAction.setConfirmationUser(false));
        } else if (!isEqual(virtualDevice, savedVirtualDevice)) {
            if (!isDirty) {
                setDirty(true);
                dispatch(MainAction.setConfirmationUser(true));
            }
        }
    }, [deviceName, virtualDevice?.virtualTriggers, virtualDevice?.virtualActions, virtualDevice, savedVirtualDevice]);

    const handleChangeVirtualDeviceName = (e) => {
        setDeviceName(e.target.value);
        setVirtualDevice({ ...virtualDevice, name: e.target.value });
    };

    const handleAddItemToVirtualDevice = (mapType) => {
        const updatedVirtualDevice = addTriggerToVirtualDevice(virtualDevice, mapType);

        setVirtualDevice(updatedVirtualDevice);
    };

    const handleDeleteTriggerFromVirtualDevice = (virtualItemUpdateParams, uuid) => {
        const updatedVirtualDevice = deleteTriggerFromVirtualDevice(virtualItemUpdateParams, uuid);

        setVirtualDevice(updatedVirtualDevice);
    };

    const isInvalidForm = useMemo(() => {
        return !!Object.keys(errors).length;
    }, [errors]);

    const handleErrors = useCallback((errors) => {
        setErrors(errors);
    }, []);

    const handleSetVirtualDevice = () => {
        const { name, virtualTriggers, virtualActions } = virtualDevice;

        const virtualDeviceForCreate = getVirtualDeviceTemplateForCreate(
            {
                name,
                virtualTriggers,
                virtualActions,
            },
            abstractUuid,
        );

        createVirtualDevice(virtualDeviceForCreate);
    };

    const backToVirtualDeviceList = () => {
        dispatch(MainAction.confirmUserAction(() => history.push('/ezlo/virtual-container')));
    };

    return (
        <>
            <Header headerClassName={style.virtualDeviceHeader} />
            <div className={style.virtualDeviceForm}>
                <div className={style.backButton} onClick={backToVirtualDeviceList}>
                    <ArrowBackIos />
                    <p>
                        {abstractUuid
                            ? t(EZLOGIC_TITLE_EDIT_VIRTUAL_CONTAINER)
                            : t(EZLOGIC_TITLE_NEW_VIRTUAL_CONTAINER)}
                    </p>
                </div>

                <TextField
                    className={style.inputWrapper}
                    label={t(EZLOGIC_TITLE_DEVICE_NAME)}
                    onChange={handleChangeVirtualDeviceName}
                    value={deviceName}
                />

                <div>
                    <p className={style.titleHeader}>{t(EZLOGIC_TITLE_TRIGGER_ITEMS)}</p>

                    {virtualDevice?.virtualTriggers?.map((virtualTrigger, index) => {
                        return (
                            <VirtualItem
                                key={virtualTrigger.uuid}
                                virtualItemIndex={index}
                                virtualItem={virtualTrigger}
                                virtualItemKey={VIRTUAL_TRIGGERS}
                                deleteTriggerFromVirtualDevice={handleDeleteTriggerFromVirtualDevice}
                            />
                        );
                    })}

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleAddItemToVirtualDevice(VIRTUAL_TRIGGERS)}
                    >
                        <Add fontSize="inherit" />
                        {t(EZLOGIC_BUTTON_ADD_PROPERTY)}
                    </Button>
                </div>

                <div>
                    <p className={style.titleHeader}>{t(EZLOGIC_TITLE_ACTION_ITEMS)}</p>

                    {virtualDevice?.virtualActions?.map((virtualAction, index) => {
                        return (
                            <VirtualItem
                                key={virtualAction.uuid}
                                virtualItemIndex={index}
                                virtualItem={virtualAction}
                                virtualItemKey={VIRTUAL_ACTIONS}
                                deleteTriggerFromVirtualDevice={handleDeleteTriggerFromVirtualDevice}
                                handleErrors={handleErrors}
                            />
                        );
                    })}

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleAddItemToVirtualDevice(VIRTUAL_ACTIONS)}
                    >
                        <Add fontSize="inherit" />
                        {t(EZLOGIC_BUTTON_ADD_CAPABILITY)}
                    </Button>
                </div>

                <div className={style.buttonContainer}>
                    <Button
                        className={style.saveButton}
                        variant="contained"
                        color="primary"
                        disabled={isInvalidForm || !name}
                        onClick={handleSetVirtualDevice}
                    >
                        {t(EZLOGIC_BUTTON_SAVE)}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default EzloVirtualDevice;
