import React from 'react';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import { ACCOUNT_TYPES_PERMISSION_INFO } from '../../../../../constants/Users';

import { tableContainer, selectedType, selectedTypeName } from '../InfoPopper/styles.module.scss';
import { EZLOGIC_TITLE_PERMISSION } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const AccountPermissionsTable = (props) => {
    const { selectedAccountType, onGetActiveAccountTypeClassName } = props;
    const { t } = useTranslate();

    return (
        <TableContainer className={tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t(EZLOGIC_TITLE_PERMISSION)}</TableCell>
                        {ACCOUNT_TYPES_PERMISSION_INFO.map(({ title, value, id }) => (
                            <TableCell
                                key={id}
                                className={onGetActiveAccountTypeClassName({ value, className: selectedTypeName })}
                            >
                                {t(title)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody
                    className={onGetActiveAccountTypeClassName(
                        { value: selectedAccountType.value, className: selectedType },
                        true,
                    )}
                >
                    {ACCOUNT_TYPES_PERMISSION_INFO.map(({ id, permissionInfo, permissions }) => {
                        return (
                            <TableRow key={id}>
                                <TableCell>{t(permissionInfo)}</TableCell>
                                {Object.values(permissions).map((isPermitted, index) => {
                                    return <TableCell key={index}>{isPermitted ? <DoneIcon /> : ''}</TableCell>;
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AccountPermissionsTable;
