import React from 'react';

import { AdvancedMapType } from '../../SaveOutputBlock/components/Variable/AdvancedMapType';

import style from '../../SaveOutputBlock/components/Variable/CloudVariable.module.scss';
import { EZLOGIC_TITLE_TEST_YOUR_VARIABLES } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const TestVariables = ({ advancedProps }) => {
    const { t } = useTranslate();

    return (
        <div className={style.testVariablesContainer}>
            <AdvancedMapType {...advancedProps} buttonText={t(EZLOGIC_TITLE_TEST_YOUR_VARIABLES)} />
        </div>
    );
};

export default TestVariables;
