import wsm from '../helpers/wsm';
import at from '../constants/ActionTypes/Plugins';
import { API_CLOUD_EZLO_REQUEST_URL, API_CLOUD_EZLO_UPDATE_PLUGIN_URL } from '../constants/URLs';
import { axiosCloudConfig, axiosCloudPlugin, axiosCloudConfigDownload } from '../api/config';
import {
    DASHBOARD_SET,
    PUBLIC_SET,
    PRIVATE,
    PUBLISH,
    ERROR,
    SUCCESS,
    PRIVATE_GENERATOR,
    PUBLISH_GENERATOR,
    WIFI_PLUGIN_MODEL,
    COMPANY_SET,
    COMPANY_LIST,
    CODE,
    STATUSES,
    UPGRADE,
    CUSTOM_PLUGIN,
    ERROR_OCCURRED_WHILE_UPDATING,
    INSTALL,
} from '../constants/Plugins';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import toastsActions from './toastsActions';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS } from '../constants/toastConstants';
import { notificationControllerUpdateMessage } from '../containers/Ezlo/EzloPlugins/utils';
import { EzloActions, IntegrationsActions, PluginActions } from './index';
import { buildPluginDownloadUrl, isPluginDownloadedFromMarketplace } from '../containers/Ezlo/EzloPlugins/utils';
import { toast } from 'react-toastify';
import { TOAST_TYPE } from '../components/Toast';
import {
    EZLOGIC_TITLE_ERROR_OCCURED_ADD_PLUGIN,
    EZLOGIC_TITLE_ERROR_OCCURED_GETTING_THE_PREFIX,
    EZLOGIC_TITLE_ERROR_OCCURED_PUBLISHING_TO_PRIVATE,
    EZLOGIC_TITLE_ERROR_OCCURED_SETTING_THE_PREFIX,
    EZLOGIC_TITLE_ERROR_OCCURED_WHILE_REMOVING,
    EZLOGIC_TITLE_ERROR_WHILE_CREATE_DASHBOARD,
    EZLOGIC_TITLE_ERROR_WHILE_CREATE_TEMPLATE,
    EZLOGIC_TITLE_ERROR_WHILE_UPDATE_DASHBOARD,
    EZLOGIC_TITLE_ERROR_WHILE_UPDATE_PLUGIN,
    EZLOGIC_TITLE_ERROR_WHILE_UPDATE_TEMPLATE,
    EZLOGIC_TITLE_HAS_BEEN_SUCCESSFULLY_REMOVED,
    EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_ADDED,
    EZLOGIC_TITLE_PREFIX_WAS_SUCCESSFULLY,
    EZLOGIC_TITLE_SOMETHING_WENT_WRONG,
    EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED,
    EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED_TO_PRIVATE,
    EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_UPDATED,
    EZLOGIC_TITLE_WAITING_FOR_UPDATE,
} from '../constants/language_tokens';
import mediaActions from '../features/MediaStoragePage/actions/media';
import GenericActions from './GenericActions';

const actions = {
    clearRequestStatus: () => (dispatch) => {
        dispatch({ type: at.SET_REQUEST_STATUS.clear });
    },

    clearJobUuid: () => (dispatch) => {
        dispatch({ type: at.CLEAR_JOB_UUID });
    },

    setPluginIntegrationPending: () => (dispatch) => {
        dispatch({ type: at.SET_PLUGIN_INTEGRATION.pending });
    },
    setPluginIntegrationSuccess: () => (dispatch) => {
        dispatch({ type: at.SET_PLUGIN_INTEGRATION.success });
    },
    setPluginIntegrationReject: (message) => (dispatch) => {
        dispatch({ type: at.SET_PLUGIN_INTEGRATION.rejected, data: message });
    },
    handleCloseSuccessPluginModal: () => (dispatch) => {
        dispatch({ type: at.HANDLE_CLOSE_SUCCESS_PLUGIN_MODAL });
    },

    onPluginRejectedClose: () => (dispatch) => {
        dispatch({ type: at.HANDLE_CLOSE_REJECTED_MODAL });
    },

    onPluginProcessClose: () => (dispatch) => {
        dispatch({ type: at.HANDLE_CLOSE_PENDING_MODAL });
    },

    notificationUpdateController: (serial, type, t) => (dispatch) => {
        toast(t(EZLOGIC_TITLE_WAITING_FOR_UPDATE), { type: TOAST_TYPE.INFO });

        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                toast(result.error.message, { type: TOAST_TYPE.ERROR });
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }

            if (result.status === STATUSES.FINISHED) {
                toast(t(notificationControllerUpdateMessage(type)), { type: TOAST_TYPE.SUCCESS });
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }
        };
    },

    notificationUpdateControllerAfterIntegrationUpdate: (serial) => (dispatch) => {
        dispatch(IntegrationsActions.setOpenIntegrationWaitingModal(true));

        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                toast(result.error.message, { type: TOAST_TYPE.ERROR });
                dispatch(IntegrationsActions.setOpenIntegrationWaitingModal(false));
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }

            if (result.status === STATUSES.FINISHED) {
                dispatch(IntegrationsActions.setOpenIntegrationSuccessfulModal(true));
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }
        };
    },

    notificationUpdateControllerAndSetConfigs: (serial, setConfig) => (dispatch) => {
        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                dispatch(PluginActions.setPluginIntegrationReject());
                toast(result.error.message, { type: TOAST_TYPE.ERROR });
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }

            if (result.status === STATUSES.FINISHED) {
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
                dispatch(PluginActions.setConfigurePlugins(serial, setConfig));
            }
        };
    },

    notificationUpdateControllerAndSetDeviceConfigs: (serial, params) => (dispatch) => {
        dispatch(PluginActions.setPluginIntegrationPending());

        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
                dispatch(PluginActions.setPluginIntegrationReject());
            }

            if (result.status === STATUSES.FINISHED) {
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
                dispatch(PluginActions.addDeviceWifiGenerator(serial, params));
                dispatch(PluginActions.setPluginIntegrationSuccess());
            }
        };
    },

    postRequest: (call, paramsCreate) => {
        const dataRequest = {
            call: call,
            params: paramsCreate,
        };

        return fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());
    },

    uploadPlugin: (data, t) => async (dispatch) => {
        dispatch({ type: at.UPLOAD_PLUGIN.pending });

        const bodyFormData = new FormData();
        bodyFormData.append('file', data);

        const resultUpdate = fetch(API_CLOUD_EZLO_UPDATE_PLUGIN_URL(), {
            method: 'post',
            ...axiosCloudPlugin(),
            body: bodyFormData,
        }).then((res) => res.json());

        await resultUpdate
            .then((res) => {
                if (res.hasOwnProperty(ERROR)) {
                    dispatch({ type: at.UPLOAD_PLUGIN.rejected });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_WHILE_UPDATE_PLUGIN),
                    });
                } else {
                    dispatch({ type: at.UPLOAD_PLUGIN.success });
                    dispatch(actions.createDashboard({ type: 'single-file', file_uuid: res.file.uuid }, t));
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.UPLOAD_PLUGIN.rejected, data });
                dispatch({ type: at.UPLOAD_PLUGIN.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_WHILE_UPDATE_PLUGIN),
                });
            });
    },

    createDashboard: (params, t) => async (dispatch) => {
        dispatch({ type: at.CREATE_DASHBOARD.pending });

        const dataRequest = {
            call: 'dashboard_create_package',
            params: params,
        };

        const resultCreate = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await resultCreate
            .then((res) => {
                if (res.data.hasOwnProperty('error_code')) {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_WHILE_CREATE_DASHBOARD),
                    });
                    dispatch({ type: at.CREATE_DASHBOARD.rejected });
                } else {
                    dispatch({ type: at.CREATE_DASHBOARD.success, payload: res.data.job_uuid });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.CREATE_DASHBOARD.rejected, params });
                dispatch({ type: at.CREATE_DASHBOARD.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_WHILE_CREATE_DASHBOARD),
                });
            });
    },

    createDashboardWifi: (paramsCreate, paramsSet, logoFieldsState, t) => async (dispatch) => {
        dispatch({ type: at.CREATE_DASHBOARD_WIFI.pending });
        dispatch(GenericActions.setLineLoading(true));

        await actions
            .postRequest('dashboard_create', paramsCreate)
            .then((res) => {
                const uuid = res.data?.created?.uuid;
                paramsSet.uuid = uuid;

                if (res.status === 0) {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_WHILE_CREATE_TEMPLATE),
                    });
                }

                if (uuid) {
                    dispatch(actions.setDashboardWifi(paramsSet, logoFieldsState, t));
                }

                dispatch({ type: at.CREATE_DASHBOARD_WIFI.success });
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.CREATE_DASHBOARD_WIFI.rejected, paramsCreate });
                dispatch({ type: at.CREATE_DASHBOARD_WIFI.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_WHILE_CREATE_TEMPLATE),
                });
            })
            .finally(() => dispatch(GenericActions.setLineLoading(false)));
    },

    addDeviceWifiGenerator: (serial, params, isIntegration) => async (dispatch) => {
        if (isIntegration) {
            dispatch(PluginActions.setPluginIntegrationPending());
        } else {
            dispatch({ type: at.ADD_DEVICE_WIFI_GENERATOR.pending });
        }
        await wsm.send(
            serial,
            'hub.extensions.plugin.run',
            {
                script: 'HUB:ezlo.wifi_device_generator/scripts/add_device',
                scriptParams: params,
            },
            () => {
                if (isIntegration) {
                    dispatch(PluginActions.setPluginIntegrationSuccess());
                } else {
                    dispatch({ type: at.ADD_DEVICE_WIFI_GENERATOR.success });
                }
            },
            () => {
                if (isIntegration) {
                    dispatch(PluginActions.setPluginIntegrationReject());
                } else {
                    dispatch({ type: at.ADD_DEVICE_WIFI_GENERATOR.rejected });
                }
            },
            true,
        );
    },

    getLogoLink: (logoFiled) => async (dispatch) => {
        if (logoFiled.imgFile) {
            return await dispatch(mediaActions.uploadImageAndGetImgLink(logoFiled.imgFile));
        }

        return logoFiled.logoFieldValue;
    },

    setDashboardWifi: (params, logoFiled, t) => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        params.data.meta.logo = await dispatch(actions.getLogoLink(logoFiled));

        await actions
            .postRequest('dashboard_set', params)
            .then((res) => {
                if (res.status === 1) {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.success,
                        payload: t(EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_UPDATED),
                    });
                } else {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_WHILE_UPDATE_TEMPLATE),
                    });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.SET_DASHBOARD_WIFI.rejected, params });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_WHILE_UPDATE_DASHBOARD),
                });
            })
            .finally(() => dispatch(GenericActions.setLineLoading(false)));
    },

    deleteDashboard: (params, type, t) => async (dispatch) => {
        dispatch({ type: at.DELETE_DASHBOARD.pending });
        const call = type === PRIVATE ? DASHBOARD_SET : PUBLIC_SET;

        const dataRequest = {
            call: call,
            params: params,
        };

        const resultDelete = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await resultDelete
            .then((res) => {
                if (res.status === 1) {
                    const title = params?.type === WIFI_PLUGIN_MODEL ? 'Template' : 'Plugin';

                    if (type === PRIVATE || type === PRIVATE_GENERATOR) {
                        dispatch(actions.getPrivatePlugins(params.type));
                    }

                    if (type === PUBLISH || type === PUBLISH_GENERATOR) {
                        dispatch(actions.getPublicPlugins(params.type, 1));
                    }

                    dispatch({
                        type: at.SET_REQUEST_STATUS.success,
                        payload: `${title} ${t(EZLOGIC_TITLE_HAS_BEEN_SUCCESSFULLY_REMOVED)}`,
                    });
                    dispatch({ type: at.DELETE_DASHBOARD.success });
                } else {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_OCCURED_WHILE_REMOVING),
                    });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.DELETE_DASHBOARD.rejected, params });
                dispatch({ type: at.DELETE_DASHBOARD.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_OCCURED_WHILE_REMOVING),
                });
            });
    },

    getDashboardCreateList: (params, t) => async (dispatch) => {
        dispatch({ type: at.GET_DASHBOARD_CREATE_LIST.pending });

        const dataRequest = {
            call: 'dashboard_create_package_list',
            params: { job_uuid: params },
        };

        if (params !== '') {
            const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                method: 'post',
                ...axiosCloudConfig(),
                body: JSON.stringify(dataRequest),
            }).then((res) => res.json());

            await result
                .then((res) => {
                    const job = res?.data?.jobs[0];

                    if (job.status === SUCCESS) {
                        dispatch({
                            type: at.SET_REQUEST_STATUS.success,
                            payload: t(EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_ADDED),
                        });
                    }

                    if (job.status === ERROR) {
                        dispatch({
                            type: at.SET_REQUEST_STATUS.rejected,
                            payload: t(EZLOGIC_TITLE_ERROR_OCCURED_ADD_PLUGIN),
                        });
                    }

                    dispatch({ type: at.GET_DASHBOARD_CREATE_LIST.success, payload: job });
                })
                .catch((err) => {
                    bugsnagNotify(err, { type: at.GET_DASHBOARD_CREATE_LIST.rejected, params });
                    dispatch({ type: at.GET_DASHBOARD_CREATE_LIST.rejected });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_OCCURED_ADD_PLUGIN),
                    });
                });
        }
    },

    getCapabilitiesPluginList: (serial) => async (dispatch) => {
        dispatch({ type: at.GET_CAPABILITIES_PLUGIN_LIST.pending });

        await wsm.send(
            serial,
            'hub.extensions.plugin.run',
            {
                script: 'HUB:ezlo.wifi_device_generator/scripts/get_supported_capabilities',
            },
            () => {
                dispatch({ type: at.GET_CAPABILITIES_PLUGIN_LIST.success });
            },
            () => {
                dispatch({ type: at.GET_CAPABILITIES_PLUGIN_LIST.rejected });
            },
            true,
        );
    },

    getPrivatePlugins: (typeDashboard, controllers) => async (dispatch) => {
        dispatch({ type: at.GET_PRIVATE_PLUGINS.pending });

        const dataRequest = {
            call: 'dashboard_list',
            params: { type: typeDashboard, data: 1 },
        };

        const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await result
            .then((res) => {
                const listPlugins = res.data.dashboards;
                let result = [];

                for (const prop in listPlugins) {
                    listPlugins[prop].uuid = prop;
                    result = [...result, listPlugins[prop]];
                }
                dispatch({ type: at.GET_PRIVATE_PLUGINS.success, payload: result, typeDashboard });

                controllers.forEach((controller) => {
                    if (controller.isConnected) {
                        dispatch(actions.getListInstalledPlugins(controller.serial));
                    }
                });
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.GET_PRIVATE_PLUGINS.rejected });
                dispatch({ type: at.GET_PRIVATE_PLUGINS.rejected });
            });
    },

    getPublicPlugins: (typeDashboard, creator, oemId) => async (dispatch) => {
        dispatch({ type: at.GET_PUBLIC_PLUGINS.pending });

        const dataRequest = {
            call: 'public_list',
            params: {
                type: typeDashboard,
                creator: creator ? creator : 0,
            },
        };

        if (Number.isInteger(oemId)) {
            dataRequest.params.oem_id = oemId;
        }

        const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await result
            .then((res) => {
                dispatch({ type: at.GET_PUBLIC_PLUGINS.success, payload: res.data.list, creator, typeDashboard });
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.GET_PUBLIC_PLUGINS.rejected, creator });
                dispatch({ type: at.GET_PUBLIC_PLUGINS.rejected });
            });
    },

    deployPlugin: (params, serial) => async (dispatch) => {
        dispatch({ type: at.DEPLOY_PLUGIN.pending });
        dispatch({ type: at.SET_ACTIVE_INSTALLING, data: serial });

        const dataRequest = {
            call: 'dashboard_deploy_package',
            params: params,
        };

        const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await result
            .then((res) => {
                if (res.status === 1) {
                    dispatch({ type: at.DEPLOY_PLUGIN.success });
                } else {
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: res?.data?.error_text || res?.data?.error_code?.error_text,
                    });
                    dispatch({ type: at.DEPLOY_PLUGIN.rejected });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.DEPLOY_PLUGIN.rejected, params });
                dispatch({ type: at.DEPLOY_PLUGIN.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: err.message,
                });
            });
    },

    getListInstalledPlugins: (serial, isUpgrade) => async (dispatch) => {
        dispatch({ type: at.GET_LIST_INSTALLED_PLUGINS.pending });
        await wsm.send(
            serial,
            'hub.custom.nodes.list',
            {
                showMeta: true,
                showAdvanced: true,
            },
            (data) => {
                dispatch({ type: at.GET_LIST_INSTALLED_PLUGINS.success, payload: { [serial]: data.nodes } });
                if (isUpgrade) {
                    dispatch({ type: at.UPGRADE_PLUGIN.success });
                }
            },
            () => {
                dispatch({ type: at.GET_LIST_INSTALLED_PLUGINS.rejected });
            },
            true,
        );
    },

    setConfigurePlugins: (serial, data) => async (dispatch) => {
        dispatch({ type: at.SET_CONFIGURE_PLUGIN.pending });
        await wsm.send(
            serial,
            'hub.extensions.plugin.run',
            {
                script: data?.script,
                scriptParams: data?.inputs,
            },
            () => {
                dispatch({ type: at.SET_CONFIGURE_PLUGIN.success });
            },
            () => {
                dispatch({ type: at.SET_CONFIGURE_PLUGIN.rejected });
            },
            true,
        );
    },

    removePlugin: (serial, id) => async (dispatch) => {
        dispatch({ type: at.REMOVE_PLUGIN.pending });
        dispatch({ type: at.SET_ACTIVE_INSTALLING, data: serial });

        await wsm.send(
            serial,
            'hub.software.update.execute',
            {
                actions: [
                    {
                        operation: 'uninstall',
                        type: 'custom_plugin',
                        id: id,
                    },
                ],
            },
            () => {
                dispatch({ type: at.REMOVE_PLUGIN.success });
            },
            () => {
                dispatch({ type: at.REMOVE_PLUGIN.rejected });
            },
        );
    },

    upgradePlugin:
        ({ serial, id, version, source, archive_hash_md5 }) =>
        async (dispatch) => {
            dispatch({ type: at.UPGRADE_PLUGIN.pending });
            await wsm.send(
                serial,
                'hub.software.update.execute',
                {
                    actions: [
                        {
                            operation: UPGRADE,
                            type: CUSTOM_PLUGIN,
                            id,
                            version,
                            source,
                            archive_hash_md5,
                        },
                    ],
                },
                () => {
                    dispatch(actions.getPrivatePlugins(CODE));
                    dispatch(actions.getListInstalledPlugins(serial, true));
                },
                () => {
                    dispatch(
                        toastsActions.showToast({
                            message: `${ERROR_OCCURRED_WHILE_UPDATING}`,
                            options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                            isSave: true,
                        }),
                    );
                    dispatch({ type: at.UPGRADE_PLUGIN.rejected });
                },
            );
        },

    setPublishPlugin: (params, t) => async (dispatch) => {
        dispatch({ type: at.SET_PUBLISH_PLUGIN.pending });

        const dataRequest = {
            call: PUBLIC_SET,
            params: params,
        };

        const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await result
            .then((res) => {
                if (res.status === 1) {
                    const title = params?.type === WIFI_PLUGIN_MODEL ? 'Template' : 'Plugin';

                    dispatch({ type: at.SET_PUBLISH_PLUGIN.success });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.success,
                        payload: `${title} ${t(EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED)}`,
                    });
                } else {
                    dispatch({ type: at.SET_REQUEST_STATUS.rejected, payload: t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG) });
                    dispatch({ type: at.SET_PUBLISH_PLUGIN.rejected });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.SET_PUBLISH_PLUGIN.rejected });
                dispatch({ type: at.SET_PUBLISH_PLUGIN.rejected });
                dispatch({ type: at.SET_REQUEST_STATUS.rejected, payload: t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG) });
            });
    },

    downloadPlugin: (downloadUrl) => async (dispatch) => {
        dispatch({ type: at.DOWNLOAD_PLUGIN.pending });

        await fetch(downloadUrl, {
            method: 'get',
            ...axiosCloudConfigDownload(),
        })
            .then((res) => {
                const link = document.createElement('a');
                link.href = res.url;
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                dispatch({ type: at.DOWNLOAD_PLUGIN.success });
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.DOWNLOAD_PLUGIN.rejected, downloadUrl });
                dispatch({ type: at.DOWNLOAD_PLUGIN.rejected });
            });
    },

    setPublishToPrivate: (type, id, t) => async (dispatch) => {
        dispatch({ type: at.SET_PUBLISH_TO_PRIVATE.pending });

        const dataRequest = {
            call: 'public_install',
            params: {
                uuid: id,
                type: type,
            },
        };

        const result = fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: 'post',
            ...axiosCloudConfig(),
            body: JSON.stringify(dataRequest),
        }).then((res) => res.json());

        await result
            .then((res) => {
                if (res.status === 1) {
                    const title = type === WIFI_PLUGIN_MODEL ? 'Template' : 'Plugin';
                    dispatch(actions.getPrivatePlugins(CODE));
                    dispatch({ type: at.SET_PUBLISH_TO_PRIVATE.success });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.success,
                        payload: `${title} ${t(EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED_TO_PRIVATE)}`,
                    });
                } else {
                    dispatch({ type: at.SET_PUBLISH_TO_PRIVATE.rejected });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_OCCURED_PUBLISHING_TO_PRIVATE),
                    });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.SET_PUBLISH_TO_PRIVATE.rejected, id });
                dispatch({ type: at.SET_PUBLISH_TO_PRIVATE.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_OCCURED_PUBLISHING_TO_PRIVATE),
                });
            });
    },

    setPrefixes: (params, t) => async (dispatch) => {
        dispatch({ type: at.SET_PREFIXES.pending });

        await actions
            .postRequest(COMPANY_SET, params)
            .then((res) => {
                const action = params.action === 'create' ? 'created' : 'updated';

                if (res.status === 0) {
                    dispatch({ type: at.SET_PREFIXES.rejected });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_OCCURED_SETTING_THE_PREFIX),
                    });
                }

                if (res.status === 1) {
                    dispatch(actions.getPrefixesList(t));
                    dispatch({ type: at.SET_PREFIXES.success, data: res.data });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.success,
                        payload: `${t(EZLOGIC_TITLE_PREFIX_WAS_SUCCESSFULLY)} ${action}`,
                    });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.GET_PREFIXES_LIST.rejected });
                dispatch({ type: at.GET_PREFIXES_LIST.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_OCCURED_SETTING_THE_PREFIX),
                });
            });
    },

    getPrefixesList: (t) => async (dispatch) => {
        dispatch({ type: at.GET_PREFIXES_LIST.pending });

        await actions
            .postRequest(COMPANY_LIST)
            .then((res) => {
                if (res.status === 0) {
                    dispatch({ type: at.GET_PREFIXES_LIST.rejected });
                    dispatch({
                        type: at.SET_REQUEST_STATUS.rejected,
                        payload: t(EZLOGIC_TITLE_ERROR_OCCURED_GETTING_THE_PREFIX),
                    });
                }

                if (res.status === 1) {
                    dispatch({ type: at.GET_PREFIXES_LIST.success, payload: res.data });
                }
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.GET_PREFIXES_LIST.rejected });
                dispatch({ type: at.GET_PREFIXES_LIST.rejected });
                dispatch({
                    type: at.SET_REQUEST_STATUS.rejected,
                    payload: t(EZLOGIC_TITLE_ERROR_OCCURED_GETTING_THE_PREFIX),
                });
            });
    },

    copyAndUpgradePlugin:
        ({ pluginData, marketplacePlugins, privatePlugins, isIntegration }, t) =>
        async (dispatch) => {
            dispatch({ type: at.SET_ACTIVE_INSTALLING, data: pluginData.serial });
            dispatch(
                actions.subscribeUpdateController(
                    pluginData?.serial,
                    isIntegration
                        ? dispatch(
                              actions.notificationUpdateControllerAfterIntegrationUpdate(pluginData.serial, UPGRADE, t),
                          )
                        : dispatch(actions.notificationUpdateController(pluginData.serial, UPGRADE, t)),
                ),
            );
            if (
                !isPluginDownloadedFromMarketplace({
                    marketplacePlugins,
                    hash_md5: pluginData.archive_hash_md5FromMarketPlace,
                    privatePlugins,
                })
            ) {
                await dispatch(actions.setPublishToPrivate(CODE, pluginData?.uuidFromMarketPlace, t));
            }
            await dispatch(
                actions.upgradePlugin({
                    serial: pluginData.serial,
                    id: pluginData?.config?.id || pluginData?.meta?.config?.id,
                    version: pluginData?.versionForUpdate,
                    source: pluginData?.linkForDownloadFromMarketPlace,
                    archive_hash_md5: pluginData?.archive_hash_md5FromMarketPlace,
                }),
            );
        },

    installPluginFromMarketplace:
        ({ serial, id, version, archive_hash_md5, source }) =>
        async (dispatch) => {
            dispatch({ type: at.SET_ACTIVE_INSTALLING, data: serial });
            await wsm.send(
                serial,
                'hub.software.update.execute',
                {
                    actions: [
                        {
                            operation: INSTALL,
                            type: CUSTOM_PLUGIN,
                            source,
                            id,
                            version,
                            archive_hash_md5,
                        },
                    ],
                },
                () => {
                    const isUpgrade = false;
                    dispatch(actions.getListInstalledPlugins(serial, isUpgrade));
                },
                () => {
                    dispatch({ type: at.SET_PLUGIN_INTEGRATION.rejected });
                },
            );
        },

    installPluginAndSetConfig: (marketplacePlugin, serial, data) => (dispatch) => {
        dispatch(
            PluginActions.subscribeUpdateController(
                serial,
                dispatch(PluginActions.notificationUpdateControllerAndSetConfigs(serial, data)),
            ),
        );

        const { archive_hash_md5 } = marketplacePlugin?.meta;
        const { id, version } = marketplacePlugin?.meta?.config;
        const source = buildPluginDownloadUrl(JSON.parse(marketplacePlugin.data)?.file);

        dispatch(PluginActions.installPluginFromMarketplace({ serial, id, version, archive_hash_md5, source }));
    },

    installIPDevicePluginAndSetConfig: (pluginFormMarketplace, serial, params) => async (dispatch) => {
        dispatch(
            PluginActions.subscribeUpdateController(
                serial,
                dispatch(PluginActions.notificationUpdateControllerAndSetDeviceConfigs(serial, params)),
            ),
        );

        const { archive_hash_md5 } = pluginFormMarketplace?.meta;
        const { id, version } = pluginFormMarketplace?.meta?.config;
        const source = buildPluginDownloadUrl(JSON.parse(pluginFormMarketplace.data)?.file);

        await dispatch(PluginActions.installPluginFromMarketplace({ serial, id, version, archive_hash_md5, source }));
    },

    onNotificationUpdateConfigure: (serial) => (dispatch) => {
        dispatch(PluginActions.unSubscribeBroadcastConfigure(serial));
        dispatch(EzloActions.loadEzloDataByPluginUpdate(serial));
        dispatch(PluginActions.getListInstalledPlugins(serial));
    },

    removeActiveInstalling: (serial) => (dispatch, getState) => {
        const state = getState();
        const {
            plugins: { activeInstalling },
        } = state;

        const updatedActiveInstalling = activeInstalling.filter((el) => el !== serial);
        dispatch({ type: at.REMOVE_ACTIVE_INSTALLING, data: updatedActiveInstalling });
    },

    subscribeUpdateController: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.software.update.progress', cb);
    },

    unSubscribeUpdateController: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.software.update.progress');
    },

    subscribeBroadcastConfigure: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.extensions.plugin.ui_broadcast', cb);
    },

    unSubscribeBroadcastConfigure: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.extensions.plugin.ui_broadcast');
    },

    subscribeUpdateConfigure: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.extensions.plugin.run.progress', cb);
    },

    unSubscribeUpdateConfigure: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.extensions.plugin.run.progress');
    },
};

export default actions;
