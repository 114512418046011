import { TablePagination as MuiTablePagination } from '@material-ui/core';
import React from 'react';

import { ROW_PRE_PAGE_OPTIONS } from './constants';
import { EZLOGIC_TITLE_ROWS_PER_PAGE } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const TablePagination = ({
    count,
    paginationStore: {
        state: { page, rowsPerPage },
        actions,
    },
}) => {
    const { t } = useTranslate();

    return (
        <MuiTablePagination
            rowsPerPageOptions={ROW_PRE_PAGE_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={actions.handleChangePage}
            onRowsPerPageChange={actions.handleChangeRowsPerPage}
            labelRowsPerPage={t(EZLOGIC_TITLE_ROWS_PER_PAGE)}
        />
    );
};

export default TablePagination;
