import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import SystemHistorySearchByDescription from './SystemHistorySearchByDescription';
import SystemHistorySearchByEventType from './SystemHistorySearchByEventType';
import SystemHistorySearchByDate from './SystemHistorySearchByDate';
import { systemHistoryActions } from '../../../../../actions/SystemHistoryActions';

import { CONTAINED, PRIMARY } from '../../../../../constants/MeshbotConstant';
import OEMConfigContainer from '../../../../../services/oem/src/OEMConfigContainer/OEMConfigContainer';
import { getParamsForApiCall, isValidDate } from '../../utils';

import { searchContainer, selectWrapper } from '../../systemHistory.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_SEARCH_TITLE } from '../../../../../constants/language_tokens';

const SystemHistorySearchContainer = () => {
    const dispatch = useDispatch();
    const { dateBefore, dateAfter } = useSelector((state) => state.systemHistory.systemHistoryFilter);
    const { isDateValid } = useSelector((state) => state.systemHistory);
    const serial = useSelector((state) => state.ezlo.serial);
    const { t } = useTranslate();

    useEffect(() => {
        dispatch(systemHistoryActions.setIsValidDate(isValidDate(dateBefore, dateAfter)));
    }, [dateBefore, dateAfter]);

    const onClick = () => {
        dispatch(
            systemHistoryActions.getHistoryAlerts(
                `${OEMConfigContainer.buildGetParamsStringWithQuestionMark(
                    getParamsForApiCall({ dateBefore, dateAfter, serial }),
                )}`,
                true,
            ),
        );
    };

    return (
        <div className={searchContainer}>
            <div className={selectWrapper}>
                <SystemHistorySearchByDescription />
                <SystemHistorySearchByEventType />
            </div>
            <div className={selectWrapper}>
                <SystemHistorySearchByDate />
                <Button onClick={onClick} color={PRIMARY} variant={CONTAINED} disabled={!isDateValid}>
                    {t(EZLOGIC_SEARCH_TITLE)}
                </Button>
            </div>
        </div>
    );
};

export default SystemHistorySearchContainer;
