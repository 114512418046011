import React, { Component } from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownMenuItem } from '../../components';
import { EZLO_NEW } from '../../constants/URLs';

import {
    ezlos_dropdown,
    dropdown_menu_item,
    connected,
    not_connected,
    dropdown_item_content,
    controller_icon,
    icon_container,
    online,
    controller__name,
} from './EzlosDropdown.module.scss';
import { filteredEzlosControllers, getValveControllers } from '../Ezlo/EzloPlugins/utils';
import { getControllers } from '../../helpers/helpersPlugins';
import { ControllerIcon } from '../../assets/icons';

class EzlosDropdown extends Component {
    componentDidMount() {
        const { selectController } = this.props.redux.actions.EzloActions;
        const { connected } = this.props.redux.state.currentEzlo;
        const { ezlos } = this.props.redux.state;

        if (!connected) {
            const onlineController = ezlos.find((controller) => controller.connected)?.serial;
            selectController(onlineController);
        }
    }

    handleAddNewEzloClick = () => {
        const { linkTo } = this.props.redux.actions.GenericActions;

        linkTo(EZLO_NEW);
    };

    handleDropdownMenuItemClick = (ezlo) => () => {
        const { serial } = ezlo;
        const { openConfirmModal } = this.props;

        if (openConfirmModal) {
            openConfirmModal(serial);

            return;
        }
    };

    getDropdownTemplate(ezlos, currentEzlo) {
        const { wizardMode, isShowOnlyOnlineControllers, controllerSerialWhileEditing, isAlertsHistory, isPlugins } =
            this.props;
        const { ezlo } = this.props?.redux?.state;
        const isWizardMode = wizardMode === true;
        let ezloControllers = ezlos;
        const valueWhileCreating = currentEzlo && currentEzlo.serial ? currentEzlo.serial : ezlos[0].serial;

        if (isShowOnlyOnlineControllers && !isAlertsHistory) {
            ezloControllers = ezlos.filter(({ connected }) => connected);
        }

        if (isShowOnlyOnlineControllers && isPlugins) {
            const controllers = getControllers(ezlo.data);

            ezloControllers = filteredEzlosControllers(ezloControllers, getValveControllers(controllers));
        }

        return (
            <Dropdown
                data-testid="hubDropdown"
                className={classNames(ezlos_dropdown, this.props.className)}
                value={controllerSerialWhileEditing ? controllerSerialWhileEditing : valueWhileCreating}
                itemsContainerMaxHeight={400}
                onChange={this.handleDropdownChange}
                isWizardMode={isWizardMode}
            >
                {ezloControllers.map((ezlo) => (
                    <DropdownMenuItem
                        key={ezlo.serial}
                        value={ezlo.serial}
                        className={classNames(dropdown_menu_item, {
                            [connected]: ezlo.connected,
                            [not_connected]: !ezlo.connected,
                        })}
                        baseClassName="dropdown"
                        onClick={this.handleDropdownMenuItemClick(ezlo)}
                    >
                        <div className={dropdown_item_content}>
                            <span
                                className={classNames(`${controller_icon} ${icon_container}`, {
                                    [online]: ezlo.connected,
                                })}
                            >
                                <ControllerIcon />
                            </span>
                            <span className={classNames(controller__name, { [online]: ezlo.connected })}>
                                {ezlo.serial}
                            </span>
                        </div>
                    </DropdownMenuItem>
                ))}
            </Dropdown>
        );
    }

    render() {
        const {
            redux: {
                state: { ezlos, currentEzlo },
            },
        } = this.props;
        if (!ezlos || !ezlos.length || !currentEzlo.serial || (!currentEzlo.connected && !this.props.isAlertsHistory)) {
            return null;
        }

        return this.getDropdownTemplate(ezlos, currentEzlo);
    }
}

export default EzlosDropdown;
