import { NAME } from '../../../../../constants/localStorageKeys';

const VERA_PLUGIN_MANAGEMENT_ACCOUNT = 'Ezlo_Plugins';
const CONSTELLATION_PLUGIN_MANAGEMENT_ACCOUNT = 'conplugintestezlocom';

// TODO: temporary solution, must be removed after prefix implementation on Cloud side! Discussion needed.
export const isUploadToMarketPlaceAllowed = () => {
    const name = localStorage.getItem(NAME);

    return name === VERA_PLUGIN_MANAGEMENT_ACCOUNT || name === CONSTELLATION_PLUGIN_MANAGEMENT_ACCOUNT;
};
