import { EZLOGIC_TITLE_PRIVACY_POLICY, EZLOGIC_TITLE_TERMS_AND_CONDITIONS } from '../../../constants/language_tokens';
import { EZLOGIC_PRIVACY_POLICY, OBJECT, ONE } from '../constants';

export const getPayloadNotices = (getNoticesData) => {
    const notices = getNoticesData?.data;
    const noticesData = {
        Accepted: notices?.Accepted,
        Title: notices?.Title,
        longDescription: notices?.LongDescription,
        pkNotice: notices?.PK_Notice,
    };

    return noticesData;
};

export const getPayloadConsent = (getConsentData) => {
    const consent = getConsentData?.data;
    const consentData = {
        title: consent?.Title,
        description: consent?.Description,
        consentSections: consent?.ConsentSections,
        pkConsentGroup: consent?.PK_ConsentGroup,
    };

    return consentData;
};

export const parseMTML = (mtml) => {
    mtml = mtml?.replace(/\n/g, ' ');

    mtml = mtml?.replace(/<paragraph>/g, '<p>');
    mtml = mtml?.replace(/<\/paragraph>/g, '</p>');

    mtml = mtml?.replace(/<header>/g, '<strong>');
    mtml = mtml?.replace(/<\/header>/g, '</strong>');

    mtml = mtml?.replace(/\n/g, '<br/>');
    mtml = mtml?.replace(/<linebreak/g, '<br');

    mtml = mtml?.replace(/<emphasis>/g, '<strong>');
    mtml = mtml?.replace(/<\/emphasis>/g, '</strong>');

    mtml = mtml?.replace(/<link url="/g, '<a target=”_blank href="');
    mtml = mtml?.replace(/">/g, '">');
    mtml = mtml?.replace(/<\/link>/g, '</a>');

    mtml = mtml?.replace(/<list>/g, '<ul>');
    mtml = mtml?.replace(/<\/list>/g, '</ul>');

    mtml = mtml?.replace(/<item>/g, '<li>');
    mtml = mtml?.replace(/<\/item>/g, '</li>');

    mtml = mtml?.replace(/<image src="/g, '<img src="');
    mtml = mtml?.replace(/"><\/image>/g, '">');

    mtml = mtml?.replace(/<quote>/g, '<blockquote>');
    mtml = mtml?.replace(/<\/quote>/g, '</blockquote>');

    return mtml;
};

export const setEulaPayload = (eula) => {
    const data = {
        UserAgent: EZLOGIC_PRIVACY_POLICY,
        Accepted: true,
        PK_Eula: eula?.pkEula,
    };

    return data;
};

export const setNoticesPayload = (notices) => {
    const data = {
        UserAgent: EZLOGIC_PRIVACY_POLICY,
        Accepted: true,
        PK_Notice: notices?.pkNotice,
    };

    return data;
};

export const setUpdatedPoliciesPayload = () => {
    const data = {
        eula: true,
        notices: true,
        consent: true,
    };

    return data;
};

export const getConsentPayload = (consent) => {
    const consentItems = consent?.consentSections.flatMap((section) =>
        section.ConsentItems.map((item) => ({
            PK_ConsentItem: item?.PK_ConsentItem,
            Accepted: item?.Accepted,
            Mandatory: item?.Mandatory,
        })),
    );
    const consentPayload = {
        UserAgent: EZLOGIC_PRIVACY_POLICY,
        PK_ConsentGroup: consent?.pkConsentGroup,
        AcceptArray: consentItems,
    };

    return consentPayload;
};

export const updateConsentPayload = (consent, checkboxState) => {
    consent?.AcceptArray?.forEach((item) => {
        if (checkboxState?.hasOwnProperty(item?.PK_ConsentItem)) {
            item.Accepted = checkboxState[item?.PK_ConsentItem];
        }
    });

    return consent;
};

export const checkAllConsentMandatoryAccepted = (data) => {
    if (!data || !data.AcceptArray) {
        return false;
    }

    const acceptArray = data.AcceptArray;
    let allMandatoryAccepted = true;

    for (const item of acceptArray) {
        if (item && item.Mandatory && !item.Accepted) {
            allMandatoryAccepted = false;
            break;
        }
    }

    return allMandatoryAccepted;
};

export const getPoliciesAccepted = (ezloPolicies) => {
    const isAllMandatoryAccepted = checkAllConsentMandatoryAccepted(ezloPolicies?.setConsent);
    const policiesAccepted = {
        eula: ezloPolicies?.eula?.accepted,
        notices: ezloPolicies?.notices?.accepted,
        consent: isAllMandatoryAccepted,
    };

    return policiesAccepted;
};

export const checkPoliciesAccepted = (policies) => {
    for (const key in policies) {
        if (policies[key] === false) {
            return true;
        }
    }

    return false;
};

export const getPoliciesNotAccepted = (policiesAccepted) => {
    if (policiesAccepted && typeof policiesAccepted === OBJECT) {
        const policiesNotAccepted = Object.values(policiesAccepted).filter((value) => value === false);

        return policiesNotAccepted;
    }

    return [];
};

export const getCurrentPage = (policiesAccepted) => {
    if (policiesAccepted && typeof policiesAccepted === OBJECT) {
        const policiesNotAccepted = getPoliciesNotAccepted(policiesAccepted);

        const initialPage =
            policiesNotAccepted.length > ONE ? ONE : Object.values(policiesAccepted).indexOf(false) + ONE;

        return initialPage;
    }

    return ONE;
};

export const getResetConsentPayload = (consent) => {
    consent?.AcceptArray?.forEach((item) => {
        item.Accepted = false;
    });

    return consent;
};

export const getPoliciesAndEulaLinks = (eulaUrl, policyUrl) => {
    const policyLinks = [
        eulaUrl ? { url: eulaUrl, title: EZLOGIC_TITLE_TERMS_AND_CONDITIONS } : null,
        policyUrl ? { url: policyUrl, title: EZLOGIC_TITLE_PRIVACY_POLICY } : null,
    ].filter((link) => link !== null);

    return policyLinks;
};

export default {
    checkAllConsentMandatoryAccepted,
    updateConsentPayload,
    getConsentPayload,
    getPayloadNotices,
    getPayloadConsent,
    parseMTML,
    setEulaPayload,
    setNoticesPayload,
    getPoliciesAccepted,
    getCurrentPage,
    getPoliciesNotAccepted,
    getPoliciesAndEulaLinks,
};
