import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import { Button, TextField } from '@material-ui/core';

import {
    EZLOGIC_TITLE_ADD_LOGO_IMAGE_ICON_URL,
    EZLOGIC_TITLE_LOGO_IMAGE_ICON_URL,
    EZLOGIC_TITLE_OR,
    EZLOGIC_TITLE_UPLOAD,
    EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_ERROR_TEXT,
    EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_INFO,
} from '../../../../../constants/language_tokens';
import { IMG_ALT, IMG_FIELD_ID, MODEL, OUTLINED, TEMPLATE_FORM_FIELD_SIZE } from '../../../../../constants/Plugins';

import styles from './styles.module.scss';
import formStyles from '../GeneratorForm/generatorForm.module.scss';

const UploadImageField = ({ logoFieldValue, handleLogoChange, imgFile, dropzoneState, isNotValidImgName }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.imgBlock}>
            <span className={`${formStyles.generatorForm__label} ${styles.imageFieldLabel}`}>
                {t(EZLOGIC_TITLE_LOGO_IMAGE_ICON_URL)}
            </span>
            <div className={styles.imagesFieldsBox}>
                <div className={styles.uploadImgFieldContainer}>
                    <TextField
                        data-testid={IMG_FIELD_ID}
                        size={TEMPLATE_FORM_FIELD_SIZE}
                        label={t(EZLOGIC_TITLE_ADD_LOGO_IMAGE_ICON_URL)}
                        name={MODEL}
                        placeholder={t(EZLOGIC_TITLE_ADD_LOGO_IMAGE_ICON_URL)}
                        value={logoFieldValue}
                        variant={OUTLINED}
                        className={formStyles.generatorForm__input}
                        onChange={handleLogoChange}
                    />
                </div>
                <div className={styles.conditionText}>{t(EZLOGIC_TITLE_OR)}</div>
                <div className={styles.uploadImgFieldContainer}>
                    <div className={styles.uploadImageBox}>
                        <div {...dropzoneState.getRootProps()} onClick={(e) => e.stopPropagation()}>
                            <input {...dropzoneState.getInputProps()} />
                            <Button onClick={dropzoneState.open} size="medium" variant="contained" color="primary">
                                {t(EZLOGIC_TITLE_UPLOAD)}
                            </Button>
                        </div>

                        {(imgFile || logoFieldValue) && (
                            <div className={styles.previewBox}>
                                <div className={styles.thumbInner}>
                                    <img src={imgFile?.preview || logoFieldValue} alt={IMG_ALT} />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.infoTextBox}>
                        {isNotValidImgName && (
                            <div className={styles.errorText}>{t(EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_ERROR_TEXT)}</div>
                        )}
                        <div className={styles.helperText}>{t(EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_INFO)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadImageField;
