import { DOMAIN_PARTNER_UUID, LOCALSTORAGE_TOP_LEVEL_DOMAIN } from '../../../constants/localStorageKeys';
import { INTERNAL_EZLOGIC_DOMAIN_URLS } from '../../../constants/URLs';
import { tokenParser } from '../../../helpers/auth';
/**
 * @returns {Boolean} of domain value for Environment dropdown
 * */
export const isEnvSwitcherEnabled = (host) => {
    return INTERNAL_EZLOGIC_DOMAIN_URLS.some((domainUrl) => domainUrl === host);
};

/**
 * @returns {boolean} true if top level domain else return false.
 * */
export const getTopLevelDomain = () => {
    return Boolean(localStorage.getItem(LOCALSTORAGE_TOP_LEVEL_DOMAIN));
};

/**
 * @returns {boolean} true if uuid domain else return false.
 * */
export const getUuidDomain = () => {
    return Boolean(localStorage.getItem(DOMAIN_PARTNER_UUID));
};

/**
 * @returns {boolean} true if have uuid or partner domain else return false.
 * */
export const checkPartnerDomain = () => {
    const isTLD = getTopLevelDomain();
    const isPartnerUuid = getUuidDomain();

    return isTLD || isPartnerUuid;
};

/**
 * @param {String} userIdentity - User's identity received during authorization
 * @returns {Object} with (PK_Account, PK_PermissionRole, PK_User) properties obtained from Identity.
 * */
export const getDataFromUserIdentity = (userIdentity) => {
    if (!userIdentity) {
        throw new Error('There is no user identity.');
    }
    const { PK_Account, PK_AccountChild, PK_PermissionRole, PK_User } = tokenParser(userIdentity);

    return { PK_Account: PK_AccountChild || PK_Account, PK_PermissionRole, PK_User };
};
