import React, { useEffect } from 'react';

import { useMediaActions } from '../../hooks/useMediaActions';
import GalleryBody from '../GalleryBody/GalleryBody';
import useSelectedImagesState from '../../hooks/useSelectedImagesState';
import useUiFiltersOfImages from '../../hooks/useUiFiltersOfImages';
import useFetchImagesByPagination from '../../hooks/useFetchImagesByPagination';
import GalleryHeader from '../GalleryHeader';
import StorageLimitComponent from '../StorageLimitComponent';
import { GalleryContextProvider } from '../../hooks/useGalleryContext';

import styles from './style.module.scss';

const GallerySection = () => {
    const selectedImagesState = useSelectedImagesState();
    const fetchImagesByPaginationState = useFetchImagesByPagination(selectedImagesState.clearSelectedImgList);
    const uiFiltersOfImagesState = useUiFiltersOfImages();
    const { clearImagesList } = useMediaActions();

    useEffect(() => {
        return () => clearImagesList();
    }, []);

    return (
        <GalleryContextProvider
            value={{
                ...fetchImagesByPaginationState,
                ...selectedImagesState,
                ...uiFiltersOfImagesState,
            }}
        >
            <section className={styles.gallerySection}>
                <GalleryHeader />
                <GalleryBody imagesList={uiFiltersOfImagesState.imagesList} />
                <StorageLimitComponent />
            </section>
        </GalleryContextProvider>
    );
};

export default GallerySection;
