import { QUERY_PARAMS, REDIRECT_SUPPORT_VALUE } from '../constants/network';
import { SVG_FILE_FORMAT } from '../../../../features/MediaStoragePage/constants';

/**
 * Determines extension of a file
 * @param {File} file - file
 * @returns {string} file extension (png or svg or other formats )
 * @example
 * getFileExtension({name: 'logo.png'})
 */
export function getFileExtension(file) {
    const fileName = file.name;
    const dotIndex = fileName.lastIndexOf('.');

    if (dotIndex === -1) {
        return '';
    }

    return fileName.substring(dotIndex + 1).toLowerCase();
}

/**
 * Build img link params
 * @param {string} uuid - img uuid
 * @param {string} key - img key
 * @param {string} file_format - file format
 * @returns {Object} Img link params
 * @example
 * getImgLinkParams('12hb', 'png')
 */
export const getImgLinkParams = (uuid, file_format, key) => {
    const cloudParams = { [QUERY_PARAMS.UUID]: uuid, [QUERY_PARAMS.KEY]: key };

    return file_format === SVG_FILE_FORMAT
        ? cloudParams
        : { ...cloudParams, [QUERY_PARAMS.REDIRECT_SUPPORT]: REDIRECT_SUPPORT_VALUE };
};

/**
 * Build img link params for download
 * @param {string} uuid - img uuid
 * @param {string} key - img key
 * @returns {Object} Img link params
 * @example
 * getDownloadImgLinkParams('12hb')
 */
export const getDownloadImgLinkParams = (uuid, key) => {
    return { [QUERY_PARAMS.UUID]: uuid, [QUERY_PARAMS.KEY]: key };
};
