import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { useDispatch } from 'react-redux';

import EzlosDropdown from '../../containers/EzlosDropdown';
import { ConfirmModal } from '../ConfirmationModal/ConfirmationModal';

import { EzloActions as ezloActions } from '../../actions';

import { CONTINUE } from '../../constants/ComponentConstants';
import { useTranslate } from '../../features/languages';

const SwitchControllers = (props) => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [newSerial, setNewSerial] = useState('');
    const { EzloActions, isCallByDispatch, isPlugins } = props;
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const openConfirmModal = (newSerial) => {
        setNewSerial(newSerial);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed && !isCallByDispatch) {
            EzloActions.selectController(newSerial);
        }

        if (isCallByDispatch && isConfirmed) {
            dispatch(ezloActions.selectController(newSerial));
        }

        setIsConfirmModalVisible(false);
    };

    return (
        <div>
            <EzlosDropdown
                isShowOnlyOnlineControllers={true}
                openConfirmModal={openConfirmModal}
                isPlugins={isPlugins}
            />

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title="Do you want to change the controller?"
                text="You will lose your current progress, are you sure?"
                confirmButtonTitle={t(CONTINUE)}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </div>
    );
};

SwitchControllers.propTypes = {
    EzloActions: Proptypes.object,
};

export default SwitchControllers;
