import hash from '../constants/uniqueHash';
import { ON_CHANGE, MESHBOT_NODE_TYPES, GREATER, LESSER, EQUAL, NOT_EQUAL } from '../constants/MeshbotConstant';
import {
    EZLOGIC_TITLE_AFTER,
    EZLOGIC_TITLE_AT_A_TIME_OF_DAY,
    EZLOGIC_TITLE_AT_ONE_TIME_ONLY,
    EZLOGIC_TITLE_BATTERY_LEVEL,
    EZLOGIC_TITLE_BATTERY_STATE,
    EZLOGIC_TITLE_BEFORE,
    EZLOGIC_TITLE_BETWEEN,
    EZLOGIC_TITLE_CLOUD_VARIABLES,
    EZLOGIC_TITLE_CONTROLLER,
    EZLOGIC_TITLE_CUSTOM_TIME,
    EZLOGIC_TITLE_DAILY,
    EZLOGIC_TITLE_DATE_AND_TIME,
    EZLOGIC_TITLE_DAYS_MONTH,
    EZLOGIC_TITLE_DAYS_WEEK,
    EZLOGIC_TITLE_DAY_UNTIL_SUNRISE,
    EZLOGIC_TITLE_DAY_UNTIL_SUNSET,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_EQUAL,
    EZLOGIC_TITLE_EXPRESSION,
    EZLOGIC_TITLE_GREATER,
    EZLOGIC_TITLE_GREATER_EQUAL,
    EZLOGIC_TITLE_HOUSE_MODE,
    EZLOGIC_TITLE_INTERVAL,
    EZLOGIC_TITLE_LESS,
    EZLOGIC_TITLE_LESS_EQUAL,
    EZLOGIC_TITLE_LOCAL_VARIABLE,
    EZLOGIC_TITLE_MESHBOT,
    EZLOGIC_TITLE_MONTHLY,
    EZLOGIC_TITLE_NOT_EQUAL,
    EZLOGIC_TITLE_NUCAL,
    EZLOGIC_TITLE_ONCE,
    EZLOGIC_TITLE_SPECIAL_TIME_OF_A_DAY,
    EZLOGIC_TITLE_SUNRISE,
    EZLOGIC_TITLE_SUNRISE_UNTIL_MIDNIGHT,
    EZLOGIC_TITLE_SUNSET,
    EZLOGIC_TITLE_SUNSET_UNTIL_MIDNIGHT,
    EZLOGIC_TITLE_WEEKLY,
    EZLOGIC_TITLE_WEEKS_MONTH,
    EZLOGIC_TITLE_WEEKS_YEAR,
    EZLOGIC_TITLE_YEAR,
    EZLOGIC_TITLE_EQUAL_LABEL,
    EZLOGIC_TITLE_GREATER_LABEL,
    EZLOGIC_TITLE_LESSER_LABEL,
    EZLOGIC_TITLE_NOT_EQUAL_LABEL,
    EZLOGIC_TITLE_ON_CHANGE_LABEL,
    EZLOGIC_TITLE_DEVICE_GROUP,
    EZLOGIC_TITLE_CHARGING,
    EZLOGIC_TITLE_DISCHARGING,
    EZLOGIC_TITLE_FULL,
    EZLOGIC_TITLE_MISSING,
    EZLOGIC_TITLE_CONNECTED,
    EZLOGIC_TITLE_DISCONNECTED,
    EZLOGIC_TITLE_CLOUD_CONNECTION,
} from './language_tokens';

export const fields = [
    { name: 'deviceState', value: 'Device State' },
    { name: 'groupState', value: 'Group state' },
    { name: 'expressingValue', value: 'Expressing value' },
    { name: 'scenes', value: 'Scenes' },
    { name: 'dataAndTime', value: 'Data and Time' },
    { name: 'location', value: 'Location' },
    { name: 'user', value: 'User' },
    { name: 'systemStatus', value: 'System Status' },
    { name: 'services', value: 'Services' },
];

export const deviceState = [
    {
        optGroup: 'Bedroom',
        options: [
            { name: 'tableLamp', value: 'Table Lamp' },
            { name: 'lightSensor', value: 'Light Sensor' },
            { name: 'plugBedroom', value: 'Plug Bedroom' },
        ],
    },
    {
        optGroup: 'Living room',
        options: [
            { name: 'colorLamp', value: 'Color Lamp' },
            { name: 'monitorSensorLiving', value: 'Monitor Sensor Living' },
            { name: 'plugBedroom', value: 'Plug Bedroom' },
        ],
    },
];

export const scenesList = [
    {
        optGroup: 'Scenes',
        options: [
            { name: 'lightsInTheKitchen1', value: 'Lights in the kitchen1' },
            { name: 'lightsInTheKitchen2', value: 'Lights in the kitchen2' },
            { name: 'lightsInTheKitchen3', value: 'Lights in the kitchen3' },
        ],
    },
    {
        optGroup: 'House modes',
        options: [
            { name: 'home', value: 'home' },
            { name: 'away', value: 'away' },
            { name: 'night', value: 'Night' },
            { name: 'vocation', value: 'Vocation' },
        ],
    },
];

export const scenesMode = [
    { name: 'sceneDisabled', value: 'Scene disabled' },
    { name: 'sceneFinishedSuccessFull', value: 'Scene finished success full' },
    { name: 'sceneFailed', value: 'Scene failed' },
    { name: 'sceneIsTriggered', value: 'Scene is triggered' },
    { name: 'sceneIsNotTriggered', value: 'Scene is not triggered' },
];

export const variables = [
    { name: 'agiInfo', value: 'AgiInfo' },
    { name: 'AssociationNum', value: 'AssociationNum' },
    { name: 'capabilities', value: 'Capabilities' },
    { name: 'commFailure', value: 'CommFailure' },
    { name: 'commFailureAlarm', value: 'CommFailureAlarm' },
    { name: 'commFailureTime', value: 'CommFailureTime' },
];
export const actions = [
    { name: 'device', value: 'Device' },
    { name: 'scenes', value: 'Scenes' },
    // {name: "voi", value: "Voi"},
    { name: 'houseMode', value: 'House mode' },
    { name: 'luaScript', value: 'Lua script' },
    { name: 'httpRequest', value: 'HTTP request' },
    { name: 'systemAction', value: 'System action' },
    { name: 'dashboard', value: 'Dashboard' },
];

export const parameters = [
    { name: 'equals', value: 'Equals' },
    { name: 'notEquals', value: 'Not Equals' },
    { name: '<', value: '<' },
    { name: '<=', value: '<=' },
    { name: '>=', value: '>=' },
    { name: 'between', value: 'Between' },
    { name: 'notBetween', value: 'Not between' },
    { name: 'startWith', value: 'Start with' },
    { name: 'doesNotStartWith', value: 'Does not start with' },
    { name: 'endWith', value: 'End with' },
    { name: 'doesNotEndWith', value: 'Does not end with' },
    { name: 'contain', value: 'Contain' },
    { name: 'doesNotContain', value: 'Does not contain' },
    { name: 'in', value: 'In' },
    { name: 'notIn', value: 'Not in' },
    { name: 'isTrue', value: 'Is TRUE' },
    { name: 'isFalse', value: 'Is FALSE' },
    { name: 'changes', value: 'Changes' },
    { name: 'update', value: 'Update' },
];

export const location = [
    { name: 'homeLocation', value: 'Home' },
    { name: 'Office', value: 'Office' },
    { name: 'summerHome', value: 'Summer home' },
    { name: 'parentsHome', value: 'Parents home' },
];

export const selectedUser = [
    { name: 'anySelectedUser', value: 'Any selected user' },
    { name: 'allSelectedUsers', value: 'All selected users' },
];

export const selectedUserMode = [
    { name: 'arrive', value: 'Arrive' },
    { name: 'leave', value: 'Leave' },
];
export const selectedFunction = [
    { name: 'output', value: 'Output function' },
    { name: 'condition', value: 'Condition function' },
];
export const functionOutput = [
    { name: 'delayReset', value: 'Delay reset' },
    { name: 'repeatOutput', value: 'Repeat output' },
    { name: 'keepOutput', value: 'Keep output' },
];
export const functionCondition = [
    { name: 'sequencingCondition', value: 'Sequencing condition' },
    { name: 'durationCondition', value: 'Duration condition' },
    { name: 'repeatCondition', value: 'Repeat condition' },
];
export const conditionTrigger2 = [
    { name: 'before+30', value: 'Before(sunset +30mins)' },
    { name: 'before-30', value: 'Before(sunset -30mins)' },
    { name: 'after-30', value: 'After(sunset -30mins)' },
    { name: 'doorEguals0', value: "Door sensor 'Tripper' equals '0'" },
    { name: 'group1', value: 'Group 1' },
    { name: 'group2', value: 'Group 2' },
];

export const houseModes = [
    { name: 'home', value: 'home' },
    { name: 'away', value: 'away' },
    { name: 'night', value: 'Night' },
    { name: 'vocation', value: 'Vocation' },
];

export const dateAndTime = [
    { name: 'weekdays', value: EZLOGIC_TITLE_DAYS_WEEK },
    { name: 'days', value: EZLOGIC_TITLE_DAYS_MONTH },
    { name: 'weeks', value: EZLOGIC_TITLE_WEEKS_MONTH },
    { name: 'yearWeeks', value: EZLOGIC_TITLE_WEEKS_YEAR },
    { name: 'endYear', value: EZLOGIC_TITLE_YEAR },
    // { name: 'daily', value: 'Special Time of The Day' },
    { name: 'custom', value: EZLOGIC_TITLE_CUSTOM_TIME },
    { name: 'interval', value: EZLOGIC_TITLE_INTERVAL },
    { name: 'isOnce', value: EZLOGIC_TITLE_ONCE },
];

export const dateAndTimeNames = [
    'weekdays',
    'days',
    'weeks',
    'weekly',
    'monthly',
    'specialTimeOfTheDay',
    'yearWeeks',
    'endYear',
    'daily',
    'custom',
    'interval',
    'isOnce',
    'once',
];

export const dateAndTimeCloud = [
    { name: 'interval', value: EZLOGIC_TITLE_INTERVAL },
    { name: 'daily', value: EZLOGIC_TITLE_DAILY },
    { name: 'weekly', value: EZLOGIC_TITLE_WEEKLY },
    { name: 'monthly', value: EZLOGIC_TITLE_MONTHLY },
    { name: 'once', value: EZLOGIC_TITLE_ONCE },
    { name: 'specialTimeOfTheDay', value: EZLOGIC_TITLE_SPECIAL_TIME_OF_A_DAY },
];

export const specificDate = [
    { name: 'rise', value: EZLOGIC_TITLE_SUNRISE },
    { name: 'set', value: EZLOGIC_TITLE_SUNSET },
];

export const specificDateLabel = {
    rise: [
        { name: 'afterSunrise', value: EZLOGIC_TITLE_SUNRISE_UNTIL_MIDNIGHT },
        { name: 'beforeSunrise', value: EZLOGIC_TITLE_DAY_UNTIL_SUNRISE },
        { name: 'sunrise', value: EZLOGIC_TITLE_AT_ONE_TIME_ONLY },
    ],
    set: [
        { name: 'afterSunset', value: EZLOGIC_TITLE_SUNSET_UNTIL_MIDNIGHT },
        { name: 'beforeSunset', value: EZLOGIC_TITLE_DAY_UNTIL_SUNSET },
        { name: 'sunset', value: EZLOGIC_TITLE_AT_ONE_TIME_ONLY },
    ],
};

export const customDate = [
    { name: 'startTime', value: EZLOGIC_TITLE_AFTER },
    { name: 'endTime', value: EZLOGIC_TITLE_BEFORE },
    { name: 'between', value: EZLOGIC_TITLE_BETWEEN },
    { name: 'timeOfDay', value: EZLOGIC_TITLE_AT_A_TIME_OF_DAY },
];

export const customMeridiemSelect = [
    { name: 'am', value: 'am' },
    { name: 'pm', value: 'pm' },
];

export const cloudOperatorTrig = [
    { value: GREATER, name: EZLOGIC_TITLE_GREATER_LABEL },
    { value: LESSER, name: EZLOGIC_TITLE_LESSER_LABEL },
    { value: EQUAL, name: EZLOGIC_TITLE_EQUAL_LABEL },
    { value: NOT_EQUAL, name: EZLOGIC_TITLE_NOT_EQUAL_LABEL },
    { value: ON_CHANGE, name: EZLOGIC_TITLE_ON_CHANGE_LABEL },
];

export const cloudOperatorTrigForNotificationTemp = [
    { name: '>', value: '>' },
    { name: '<', value: '<' },
    { name: '==', value: '==' },
    { name: '!=', value: '!=' },
];

export const localComparatorsTrig = [
    { name: EZLOGIC_TITLE_GREATER, value: '>', id: hash() },
    { name: EZLOGIC_TITLE_LESS, value: '<', id: hash() },
    { name: EZLOGIC_TITLE_EQUAL, value: '==', id: hash() },
    { name: EZLOGIC_TITLE_NOT_EQUAL, value: '!=', id: hash() },
    { name: EZLOGIC_TITLE_LESS_EQUAL, value: '<=', id: hash() },
    { name: EZLOGIC_TITLE_GREATER_EQUAL, value: '>=', id: hash() },
];

export const cloudOperatorTrigForBoolean = [
    { value: EQUAL, name: EZLOGIC_TITLE_EQUAL_LABEL },
    { value: NOT_EQUAL, name: EZLOGIC_TITLE_NOT_EQUAL_LABEL },
    { value: ON_CHANGE, name: EZLOGIC_TITLE_ON_CHANGE_LABEL },
];

export const cloudOperatorTrigForBooleanNotificationTemp = [
    { name: '==', value: '==' },
    { name: '!=', value: '!=' },
];

export const LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER = {
    DEVICE_STATE: EZLOGIC_TITLE_DEVICE,
    DATE_AND_TIME: EZLOGIC_TITLE_DATE_AND_TIME,
    MESHBOT_STATE: EZLOGIC_TITLE_MESHBOT,
    TRIGGER_HOUSE_MODE: EZLOGIC_TITLE_HOUSE_MODE,
    CLOUD_VARIABLES: EZLOGIC_TITLE_CLOUD_VARIABLES,
    PAAS: EZLOGIC_TITLE_NUCAL,
    CONTROLLER: EZLOGIC_TITLE_CONTROLLER,
    EXPRESSION_COMPARISON: EZLOGIC_TITLE_EXPRESSION,
    VARIABLE_COMPARISON: EZLOGIC_TITLE_LOCAL_VARIABLE,
    DEVICE_GROUP: EZLOGIC_TITLE_DEVICE_GROUP,
};

export const listNodesLocal = [
    {
        id: MESHBOT_NODE_TYPES.DEVICE_STATE,
        value: MESHBOT_NODE_TYPES.DEVICE_STATE,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.DEVICE_STATE,
    },
    {
        id: MESHBOT_NODE_TYPES.DEVICE_GROUP,
        value: MESHBOT_NODE_TYPES.DEVICE_GROUP,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.DEVICE_GROUP,
    },
    {
        id: MESHBOT_NODE_TYPES.DATE_AND_TIME,
        value: MESHBOT_NODE_TYPES.DATE_AND_TIME,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.DATE_AND_TIME,
    },
    {
        id: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        value: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.MESHBOT_STATE,
    },
    {
        id: MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE,
        value: MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.TRIGGER_HOUSE_MODE,
    },
    {
        id: MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
        value: MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.CLOUD_VARIABLES,
    },
    {
        id: MESHBOT_NODE_TYPES.PAAS,
        value: MESHBOT_NODE_TYPES.PAAS,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.PAAS,
    },
    {
        id: MESHBOT_NODE_TYPES.CONTROLLER,
        value: MESHBOT_NODE_TYPES.CONTROLLER,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.CONTROLLER,
    },
    {
        id: MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON,
        value: MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.EXPRESSION_COMPARISON,
    },
    {
        id: MESHBOT_NODE_TYPES.VARIABLE_COMPARISON,
        value: MESHBOT_NODE_TYPES.VARIABLE_COMPARISON,
        label: LOCAL_MESHBOT_TRIGGER_NODES_LABEL_CONTAINER.VARIABLE_COMPARISON,
    },
];

export const listNodesIdsForExceptions = [
    MESHBOT_NODE_TYPES.DEVICE_STATE,
    MESHBOT_NODE_TYPES.DATE_AND_TIME,
    MESHBOT_NODE_TYPES.MESHBOT_STATE,
    MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE,
    MESHBOT_NODE_TYPES.CONTROLLER,
    MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON,
    MESHBOT_NODE_TYPES.VARIABLE_COMPARISON,
];

export const CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER = {
    DEVICE_STATE: EZLOGIC_TITLE_DEVICE,
    DATE_AND_TIME: EZLOGIC_TITLE_DATE_AND_TIME,
    NUCAL: EZLOGIC_TITLE_NUCAL,
    CLOUD_VARIABLES: EZLOGIC_TITLE_CLOUD_VARIABLES,
};

export const listNodesForNotificationTemplate = [
    { id: hash(), value: 'deviceState', label: CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER.DEVICE_STATE },
];

export const CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER = {
    DEVICE_STATE: 'deviceState',
    DATE_AND_TIME: 'dataAndTime', // typo, but have to keep it to prevent issues
    NUCAL: MESHBOT_NODE_TYPES.PAAS,
    CLOUD_VARIABLES: MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
};

export const listNodesCloud = [
    {
        id: hash(),
        value: CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.DEVICE_STATE,
        label: CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER.DEVICE_STATE,
    },
    {
        id: hash(),
        value: CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.DATE_AND_TIME,
        label: CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER.DATE_AND_TIME,
    },
    {
        id: hash(),
        value: CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.NUCAL,
        label: CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER.NUCAL,
    },
    {
        id: hash(),
        value: CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.CLOUD_VARIABLES,
        label: CLOUD_MESHBOT_TRIGGER_NODES_LABELS_CONTAINER.CLOUD_VARIABLES,
    },
];

export const listCapabilitiesForController = [
    { id: hash(), value: 'cloud connection', label: EZLOGIC_TITLE_CLOUD_CONNECTION },
    { id: hash(), value: 'battery state', label: EZLOGIC_TITLE_BATTERY_STATE },
    { id: hash(), value: 'battery level', label: EZLOGIC_TITLE_BATTERY_LEVEL },
];

export const listOfValuesForCloudConnectionCapability = [
    { id: hash(), value: 'connected', label: EZLOGIC_TITLE_CONNECTED },
    { id: hash(), value: 'disconnected', label: EZLOGIC_TITLE_DISCONNECTED },
];

export const listOfValuesForBatteryStateCapability = [
    { id: hash(), value: 'charging', label: EZLOGIC_TITLE_CHARGING },
    { id: hash(), value: 'full', label: EZLOGIC_TITLE_FULL },
    { id: hash(), value: 'discharging', label: EZLOGIC_TITLE_DISCHARGING },
    { id: hash(), value: 'missing', label: EZLOGIC_TITLE_MISSING },
];

export const OFFSET_DIRECTION = [
    { name: EZLOGIC_TITLE_AFTER, value: 'plus' },
    { name: EZLOGIC_TITLE_BEFORE, value: 'minus' },
];
