import React from 'react';
import { Input } from '../../../../components';
import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_TITLE_DUPLICATE,
    EZLOGIC_TITLE_DUPLICATE_TEMPLATE,
} from '../../../../constants/language_tokens';

import {
    duplicateNotificationWrapper,
    title,
    buttonContainer,
    duplicate,
} from './EzloOEMNotificationsModal.module.scss';

export const EzloOEMNotificationsDuplicateModal = ({
    templateName = '',
    onChangeTemplateName,
    isDuplicateModalVisible,
    onSave,
    handleOpen,
}) => {
    const { t } = useTranslate();

    return (
        <ConfirmModal isOpened={isDuplicateModalVisible} handleOpen={handleOpen}>
            <div className={duplicateNotificationWrapper}>
                <span className={title}>{t(EZLOGIC_TITLE_DUPLICATE_TEMPLATE)}</span>
                <Input type="text" min="1" value={templateName} onChange={(e) => onChangeTemplateName(e)} />
                <div className={buttonContainer}>
                    <button onClick={handleOpen}>{t(EZLOGIC_BUTTON_CANCEL)}</button>
                    <button onClick={onSave} className={duplicate}>
                        {t(EZLOGIC_TITLE_DUPLICATE)}
                    </button>
                </div>
            </div>
        </ConfirmModal>
    );
};
