import React from 'react';
import Button from '@material-ui/core/Button';

import { InputMaterial } from '../../../../../../components';

import {
    button,
    creatingCategoryWrapper,
    inputWrapper,
    row,
    title,
} from '../modalNotificationCategoryCreating.module.scss';
import {
    EZLOGIC_TITLE_ADD_CATEGORY,
    EZLOGIC_TITLE_CATEGORY_NAME,
    EZLOGIC_TITLE_CREATE_CATEGORY,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

export const NotificationAddingSection = (props) => {
    const { onChange, inputCreatedValue, disabled, onSave } = props;
    const { t } = useTranslate();

    return (
        <div className={creatingCategoryWrapper}>
            <h2 className={title}>{t(EZLOGIC_TITLE_CREATE_CATEGORY)}</h2>
            <div className={row}>
                <div className={inputWrapper}>
                    <InputMaterial
                        label={t(EZLOGIC_TITLE_CATEGORY_NAME)}
                        type="string"
                        onChange={onChange}
                        fullWidthInput
                        value={inputCreatedValue}
                    />
                </div>
                <Button
                    className={`${button} ${!inputCreatedValue && disabled}`}
                    onClick={onSave}
                    disabled={!inputCreatedValue}
                >
                    {t(EZLOGIC_TITLE_ADD_CATEGORY)}
                </Button>
            </div>
        </div>
    );
};
