import React from 'react';
import PropTypes from 'prop-types';
import SwitchControllers from '../../../../../components/SwitchControllers';
import './styles.scss';
import { ArrowBackIos } from '@material-ui/icons';
import styles from '../../PluginWifiGenerator/pluginWifiGenerato.module.scss';
import { useTranslate } from '../../../../../features/languages';

const HeaderForGenerator = (props) => {
    const { title, isEdit, handleRouteToPlugin } = props;
    const { t } = useTranslate();

    return (
        <header className="header-generator">
            {isEdit && <ArrowBackIos onClick={handleRouteToPlugin} className={styles.btnBack} fontSize="large" />}
            <h2>{t(title)}</h2>
            {<SwitchControllers isPlugins={true} />}
        </header>
    );
};

HeaderForGenerator.propTypes = {
    title: PropTypes.string,
    isEdit: PropTypes.bool,
};

export default HeaderForGenerator;
