import React, { memo } from 'react';

import MultipleAutocomplete from './MultipleAutocomplete';

/**
 * Renders Multiple Autocomplete for type number
 * @param { object } props
 * @returns { JSX.Element } multiple Autocomplete
 * @example
 * <NumberAutocomplete
 *      value={[10, 250]}
 *      description={'Favorite numbers'}
 *      fieldName={'favoriteNumbers'}
 *      isRequired={true}
 *      onChange={func}
 * />
 */
const NumberAutocomplete = ({ value, fieldName, description, isRequired, onChange }) => {
    const handleChangeField = (e, value) => {
        const arrayWithOnlyNumbers = value.map((numberAsString) => Number(numberAsString));
        onChange(arrayWithOnlyNumbers, fieldName);
    };

    const handleInputBlur = (e, params) => {
        const inputValue = params?.inputProps?.value;
        const currentAutocompleteValue = value || [];

        if (inputValue) {
            handleChangeField(e, [...currentAutocompleteValue, inputValue]);
        }
    };

    return (
        <MultipleAutocomplete
            freeSolo
            value={value}
            inputType="number"
            description={description}
            isRequired={isRequired}
            handleChangeField={handleChangeField}
            handleInputBlur={handleInputBlur}
        />
    );
};

export default memo(NumberAutocomplete);
