import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton, useScrollTrigger } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';

import HideOnScroll from './HideOnScroll';

import * as Actions from '../../actions';

import styles from './Header.module.scss';
import { AppLogo } from '../../assets/icons';

const Header = (props) => {
    const { children, MainAction, threshold, headerClassName, disableHysteresis } = props;

    const isAppBarEmpty = typeof children === 'undefined';
    const trigger = useScrollTrigger({
        threshold: threshold,
        disableHysteresis: disableHysteresis,
    });

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    className={classNames(styles.header, headerClassName, {
                        header_active: trigger,
                        header_empty: isAppBarEmpty,
                    })}
                >
                    <div className={styles.tabletLogo}>
                        <IconButton onClick={MainAction.showNavigation}>
                            <MenuIcon />
                        </IconButton>
                        <AppLogo isHeader={true} />
                    </div>
                    {children}
                </AppBar>
            </HideOnScroll>
        </>
    );
};

const mapStateToProps = (state) => ({
    isShowNavigation: state.main.isShowNavigation,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

Header.defaultProps = {
    threshold: 0,
    disableHysteresis: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
