import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import Ezlo from './Ezlo';

const mapStateToProps = (state) => {
    return {
        ezloData: state.ezlo.data,
        logged: state.account.logged,
        isControllerAvailable: !state.ezlos?.items?.length,
        targetEzlo: state.ezlos.items.find((ezlo) => ezlo.connected),
        isConnecting: state.ezlo.isConnecting,
        isConnected: state.ezlo.isConnected,
        connectedEzloSerial: state.ezlo.serial,
        isShowModalClearMeshBot: state.main.isShowModalClearMeshBot,
        savedAction: state.main.savedAction,
        editableComponent: state.main.editableComponent,
        serial: state.ezlo.serial,
        onlineControllers: state.ezlo.onlineControllerList,
    };
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Ezlo);
