import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, MenuItem, InputAdornment } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';
import InfoButton from '../../../InfoButton';

import {
    SCALABLE_TYPES,
    SCALE_INPUT_NAME,
    VARIABLES_FIELDS_LABELS,
    INPUT_ADORONMENT_END_POSITION,
    INPUT_TYPE_NUMBER,
    VALUE_INPUT_NAME,
    OBJECT_TYPE,
} from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { sortScalableTypesArrayByName, getSelectedScalableTypeObject } from '../../../../../EzloExpressions/utils';

import { scalableTypesAutocomplete, scalableTypesInput } from './LocalVariableScalableTypeValueBlock.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const LocalVariableScalableTypeValueBlock = (props) => {
    const { scalableType, onChangeScalableType, onHandleChangeScalableTypeValue, value } = props;
    const { t } = useTranslate();

    const selectedScalableType = SCALABLE_TYPES.find((scalable) => scalable.value === scalableType);

    return (
        <>
            <Autocomplete
                options={sortScalableTypesArrayByName(SCALABLE_TYPES).map((scalableType) => scalableType)}
                className={scalableTypesAutocomplete}
                disableClearable={true}
                value={scalableType ? getSelectedScalableTypeObject(scalableType, SCALABLE_TYPES)?.name : ''}
                getOptionLabel={(option) => {
                    return typeof option === OBJECT_TYPE ? option.name : option;
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={(e, scalableType) => onChangeScalableType(scalableType?.value)}
                renderInput={(params) => (
                    <TextField {...params} label={t(VARIABLES_FIELDS_LABELS.SCALABLE.SCALABLE_TYPE)} />
                )}
            />
            {scalableType && (
                <>
                    <TextField
                        type={INPUT_TYPE_NUMBER}
                        name={VALUE_INPUT_NAME}
                        value={value?.value}
                        className={scalableTypesInput}
                        onChange={onHandleChangeScalableTypeValue}
                        label={t(info.hints.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: info.text.value }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <SelectComponent
                        name={SCALE_INPUT_NAME}
                        label={t(VARIABLES_FIELDS_LABELS.SCALABLE.SCALE)}
                        value={value?.scale}
                        onChange={onHandleChangeScalableTypeValue}
                    >
                        {selectedScalableType?.scale.map((scalable, index) => {
                            return (
                                <MenuItem key={index} value={t(scalable?.scaleValue)}>
                                    {t(scalable?.scaleName)}
                                </MenuItem>
                            );
                        })}
                    </SelectComponent>
                </>
            )}
        </>
    );
};

export default LocalVariableScalableTypeValueBlock;
