import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../../containers/Ezlo/EzloMeshbot/components/SelectComponent';
import {
    info,
    getPinCodeList,
    getUserLockOperationItem,
    generateSelectedComparatorBlock,
} from '../../../../containers/Ezlo/EzloMeshbot/utils';
import { VALUE } from '../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_ERROR_TEXT_PIN_CODE } from '../../../../constants/language_tokens';

const SelectPinCode = (props) => {
    const { onDataUpdate, items, deviceId, selectedAction, selectedPinCode, setSelectedPinCode } = props;
    const { t } = useTranslate();
    const error = selectedPinCode === null;

    const handleSelectCapability = (event) => {
        setSelectedPinCode(event.target.value);
        onDataUpdate({
            selectedCapability: getUserLockOperationItem(items, deviceId),
            selectedComparator: generateSelectedComparatorBlock(items, selectedAction, event.target.value, deviceId),
            compareTo: VALUE,
            comparingValue: event.target.value,
        });
    };

    return (
        <SelectComponent
            value={selectedPinCode}
            label={info.hints.pinCode}
            onChange={handleSelectCapability}
            error={error}
            helperText={t(EZLOGIC_ERROR_TEXT_PIN_CODE)}
        >
            {getPinCodeList(items, deviceId)?.map(([key, value]) => {
                return (
                    <MenuItem key={key} value={key}>
                        {value?.name}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default SelectPinCode;

SelectPinCode.propTypes = {
    deviceId: PropTypes.string,
    selectedAction: PropTypes.string,
    items: PropTypes.array,
    onDataUpdate: PropTypes.func,
    selectedPinCode: PropTypes.string,
    setSelectedPinCode: PropTypes.func,
};
