import React from 'react';

import OrderSortingSelectContainer from '../OrderSortingSelect';
import SearchInputContainer from '../SearchInput';
import TimeIntervalsSelectContainer from '../TimeIntervalsSelect';
import PaginationArrowsContainer from '../PaginationArrows';
import DeleteImagesButtonContainer from '../DeleteImagesButton';

import styles from './styles.module.scss';

const GalleryHeader = () => {
    return (
        <div className={styles.galleryHeader}>
            <div className={styles.galleryActionsBox__left}>
                <SearchInputContainer />
                <OrderSortingSelectContainer />
            </div>

            <div className={styles.galleryActionsBox__right}>
                <DeleteImagesButtonContainer />
                <PaginationArrowsContainer />
                <TimeIntervalsSelectContainer />
            </div>
        </div>
    );
};

export default GalleryHeader;
