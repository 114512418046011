import React from 'react';
import PropTypes from 'prop-types';

import DelayTime from '../DelayTime';
import SwitchDelayComponent from '../SwitchDelayComponent';

import { DELAY } from '../../constants/MeshbotConstant';

const ActionDelay = (props) => {
    const { id, actionBlockName, currentRule, isShowedDelay, onChangeDelay, onUpdateActionDelay, onSetIsShowedDelay } =
        props;

    return (
        <>
            <SwitchDelayComponent isShowedDelay={isShowedDelay} onChangeDelay={onChangeDelay} />
            {isShowedDelay === DELAY && (
                <DelayTime
                    currentRule={currentRule}
                    actionBlockName={actionBlockName}
                    id={id}
                    onSetIsShowedDelay={onSetIsShowedDelay}
                    onUpdateActionDelay={onUpdateActionDelay}
                />
            )}
        </>
    );
};

ActionDelay.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    isShowedDelay: PropTypes.string,
    actionBlockName: PropTypes.string,
    currentRule: PropTypes.object,
    delayDisabled: PropTypes.bool,
    onChangeDelay: PropTypes.func,
    onUpdateActionDelay: PropTypes.func,
    onSetIsShowedDelay: PropTypes.func,
};

export default ActionDelay;
