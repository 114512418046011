import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { axiosCloudConfig } from '../api/config';
import {
    BACKUP_FILES_NUMBER_PER_PAGE,
    PAGE_NUMBER,
    BACKUP_FILE_TYPE,
    CALL_VERSION,
    DEFAULT_BUCKET,
    REQUEST_METHOD_POST,
    STORAGE_LIST_CALL,
    CONTROLLER_BACKUPS_BUCKET,
} from '../containers/Ezlo/EzloHubBackups/constants';
import { getSubstractedTimestamp } from '../containers/Ezlo/EzloHubBackups/utils';

const fetchStorageList = async (bucket) => {
    try {
        const response = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
            method: REQUEST_METHOD_POST,
            ...axiosCloudConfig(),
            body: JSON.stringify({
                call: STORAGE_LIST_CALL,
                version: CALL_VERSION,
                params: {
                    filters: {
                        timestamp_after: getSubstractedTimestamp(),
                        page: PAGE_NUMBER,
                        per_page: BACKUP_FILES_NUMBER_PER_PAGE,
                        type: BACKUP_FILE_TYPE,
                        bucket,
                    },
                },
            }),
        });

        const payload = await response.json();
        if (!response.ok) {
            throw new Error(payload?.error?.message);
        }

        return payload?.data?.list;
    } catch (error) {
        return { error: error.message };
    }
};

export const fetchStorageListByDefaultBucket = async () => {
    return fetchStorageList(DEFAULT_BUCKET);
};

export const fetchStorageListByControllerBackupsBucket = async () => {
    return fetchStorageList(CONTROLLER_BACKUPS_BUCKET);
};
