import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import GenericActions from '../../../../actions/GenericActions';
import virtualDevicesActions from '../../../../actions/virtualDevicesActions';

import styles from '../styles.module.scss';
import {
    EZLOGIC_BUTTON_INSTALL,
    EZLOGIC_TITLE_INSTALL_PLUGIN_ON_VIRTUAL_DEVICE,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const VirtualDevicesContent = ({ children }) => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const {
        app: { lineLoading },
        virtualDeviceManager: { isInstallPlugin },
    } = useSelector((state) => state);
    const { gateways } = useSelector(({ ezlo }) => ezlo.data[ezlo.serial]);
    const hasTestPlugin = Boolean(gateways?.find(({ pluginId }) => pluginId === 'test_plugin'));

    useEffect(() => {
        if (isInstallPlugin && hasTestPlugin) {
            dispatch(GenericActions.setLineLoading(false));
        }
    }, [isInstallPlugin, hasTestPlugin]);

    if (hasTestPlugin) {
        return children;
    }
    const handleInstallClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(virtualDevicesActions.installPlugin());
    };

    return (
        <div className={styles.installPluginBox}>
            <div className={styles.description}>{t(EZLOGIC_TITLE_INSTALL_PLUGIN_ON_VIRTUAL_DEVICE)}</div>
            <Button disabled={lineLoading} onClick={handleInstallClick} variant="contained" color="primary">
                {t(EZLOGIC_BUTTON_INSTALL)}
            </Button>
        </div>
    );
};

export default VirtualDevicesContent;
