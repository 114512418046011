import React from 'react';
import moment from 'moment/moment';
import {
    AFTER,
    ALL_EVENTS_TYPE,
    BEFORE,
    COUNT,
    COUNT_NUMBER,
    DEVICE,
    DIMMABLE_LIGHT,
    DOOR_LOCK,
    GATEWAY,
    GENERIC_SENSOR,
    HVAC,
    IMAGE,
    INTERFACE,
    LEVEL_SENSOR,
    LIGHT_SENSOR,
    SECURITY_SENSOR,
    SIREN,
    START,
    START_NUMBER,
    SWITCH,
    TEMPERATURE,
    TRIGGER,
    VIDEO,
    WINDOW_COV,
    DOORBELL,
    FLOW_METER,
    GARAGE_DOOR,
    POWER_METER,
    SCENE_CONTROLLER,
    CAMERA_SUITE,
    HUMIDITY,
    VOLTAGE_SENSOR,
    UV_SENSOR,
    STATE_SENSOR,
} from '../../../constants/SystemHistory';
import { LOGIN } from '../../../constants/URLs';
import { ABSTRACT, CAMERA } from '../../../constants/MeshbotConstant';
import {
    AlarmEvent,
    Blinds,
    CameraCategory,
    ClimateCategory,
    DeviceCategory,
    DoorLocksCategory,
    Doorbell,
    GarageDoor,
    IcActionsRecord,
    IcActionsSceneController,
    IcDeviceSensorPowerMeter,
    ImageEvent,
    LightingCategory,
    LoginCategory,
    OtherCategory,
    SensorCategory,
    SwitchesCategory,
    TriggerEvent,
    VideoEvent,
    VirtualDevicesCategory,
} from '../../../assets/icons';
import FlowMeter from '../../../assets/icons/visuals/deviceCategories/flow-meter/FlowMeter';

export const buildSystemHistoryTableData = (systemHistoryList = []) => {
    return systemHistoryList?.map((event, key) => {
        return {
            id: key,
            eventType: getEventType(event?.EventType),
            eventTypeNumber: event?.EventType,
            description: event?.Description,
            dateAndTime: getDateFromTimestamp(event?.Timestamp),
            deviceId: event?.DeviceID,
        };
    });
};

export const getEventType = (EventType) => {
    switch (EventType) {
        case '1':
            return IMAGE;
        case '2':
            return VIDEO;
        case '3':
            return TRIGGER;
        case '4':
            return DEVICE;
        case '5':
            return LOGIN;
        default:
            return '';
    }
};

export const getDateFromTimestamp = (timestamp) => {
    return moment.unix(timestamp).format('MM/DD/YYYY, h:mm:ss a');
};

export const filterEventByDescription = (event, filter) => {
    return event?.description.indexOf(filter.eventDescription) >= 0;
};

export const filterEventByType = (event, filter) => {
    if (filter.eventType === ALL_EVENTS_TYPE) {
        return true;
    } else {
        return Number(event?.eventTypeNumber) === filter.eventType;
    }
};

export const filterHistoryEvents = (eventsList, filter) => {
    return eventsList.filter((event) => {
        return filterEventByDescription(event, filter) && filterEventByType(event, filter);
    });
};

export const getParamsForApiCall = ({ dateBefore, dateAfter, serial, count, start }) => {
    return {
        [GATEWAY]: serial,
        [BEFORE]: dateBefore || '',
        [AFTER]: dateAfter || '',
        [COUNT]: count || COUNT_NUMBER,
        [START]: start || START_NUMBER,
    };
};

export const getIconByEventType = (devicesList, deviceId, eventType) => {
    switch (eventType) {
        case IMAGE:
            return <ImageEvent />;
        case VIDEO:
            return <VideoEvent />;
        case TRIGGER:
            return <TriggerEvent />;
        case DEVICE:
            return getIconByDeviceId(devicesList, deviceId);
        case LOGIN:
            return <LoginCategory />;
        default:
            return '';
    }
};

export const getDeviceCategory = (devicesList, deviceId) => {
    return devicesList?.find((device) => device._id === deviceId)?.category;
};

export const getIconByDeviceCategory = (category, iconProps = {}) => {
    switch (category) {
        case SIREN:
            return <AlarmEvent {...iconProps} />;
        case SECURITY_SENSOR:
        case GENERIC_SENSOR:
        case LEVEL_SENSOR:
        case LIGHT_SENSOR:
        case TEMPERATURE:
        case HUMIDITY:
        case VOLTAGE_SENSOR:
        case UV_SENSOR:
        case STATE_SENSOR:
            return <SensorCategory {...iconProps} />;
        case CAMERA:
            return <CameraCategory {...iconProps} />;
        case DIMMABLE_LIGHT:
            return <LightingCategory {...iconProps} />;
        case SWITCH:
            return <SwitchesCategory {...iconProps} />;
        case DOOR_LOCK:
            return <DoorLocksCategory {...iconProps} />;
        case ABSTRACT:
            return <VirtualDevicesCategory {...iconProps} />;
        case HVAC:
            return <ClimateCategory {...iconProps} />;
        case INTERFACE:
            return <OtherCategory {...iconProps} />;
        case WINDOW_COV:
            return <Blinds {...iconProps} />;
        case DOORBELL:
            return <Doorbell {...iconProps} />;
        case FLOW_METER:
            return <FlowMeter {...iconProps} />;
        case GARAGE_DOOR:
            return <GarageDoor {...iconProps} />;
        case POWER_METER:
            return <IcDeviceSensorPowerMeter {...iconProps} />;
        case SCENE_CONTROLLER:
            return <IcActionsSceneController {...iconProps} />;
        case CAMERA_SUITE:
            return <IcActionsRecord {...iconProps} />;
        default:
            return <DeviceCategory {...iconProps} />;
    }
};

export const getIconByDeviceId = (devicesList, deviceId) => {
    const category = getDeviceCategory(devicesList, deviceId);

    return getIconByDeviceCategory(category);
};

export const isControllersSelectVisible = (controllers) => {
    return controllers.length > 1;
};

export const getUniqueObjectsFromArray = (array) => {
    return [...new Set(array.map((o) => JSON.stringify(o)))].map((element) => JSON.parse(element));
};

export const isValidDate = (dateBefore, dateAfter) => {
    return dateAfter <= dateBefore;
};

export const getLastWeek = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
};
