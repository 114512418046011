import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import genericActions from '../../../actions/GenericActions';
import { routerActions } from 'react-router-redux';
import { TOKEN_GET_PARAM_NAME } from './constants';
import { LOGIN } from '../../../constants/URLs';

const ItarianSso = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();

    const { replace } = routerActions;

    useEffect(() => {
        const query = new URLSearchParams(search);
        const token = query.get(TOKEN_GET_PARAM_NAME);

        if (!token) {
            replace(LOGIN);
        } else {
            dispatch(genericActions.loginUserWithItarianToken(token));
        }
    }, []);

    return <></>;
};

export default ItarianSso;
