import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, LinearProgress } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import { Header } from '../../../../../components';
import styles from './style.module.scss';

const HeaderComponent = ({ title, arrowBack = true, children }) => {
    const { isLoading } = useSelector(({ users }) => users);
    const { lineLoading } = useSelector(({ app }) => app);
    const history = useHistory();

    const handleClickBack = () => {
        history.goBack();
    };

    return (
        <>
            {(isLoading || lineLoading) && (
                <Box className={styles.linearProgressBox}>
                    <LinearProgress className={styles.linearProgress} />
                </Box>
            )}
            <Header>
                <div className={styles.titleBox}>
                    {arrowBack && (
                        <ArrowBackIos onClick={handleClickBack} className={styles.arrowBackIcon} fontSize="large" />
                    )}
                    <div className={styles.title}>{title}</div>
                </div>
                {children}
            </Header>
        </>
    );
};

export default HeaderComponent;
