import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import styles from '../CloudVariable.module.scss';
import {
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_CREATE_CUSTOM_VARIABLE,
    EZLOGIC_TITLE_CREATE_EDIT_VARIABLE,
} from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';

const VariableDialogBox = ({ isCreate, isOpen, isSaveButtonDisabled, handleClose, handleSave, children }) => {
    const { t } = useTranslate();

    return (
        <Dialog className={styles.dialogBox} open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle className={styles.variableDialogTitle}>
                {isCreate ? t(EZLOGIC_TITLE_CREATE_CUSTOM_VARIABLE) : t(EZLOGIC_TITLE_CREATE_EDIT_VARIABLE)}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button disabled={isSaveButtonDisabled} onClick={handleSave} variant="contained" color="primary">
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VariableDialogBox;
