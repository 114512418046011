import { createTheme } from '@material-ui/core';
import * as at from '../containers/cutomThemeColors';

export const customTheme = (colorObject) =>
    createTheme({
        palette: {
            primary: {
                main: colorObject['--primary-color-theme'] || at.PRIMARY_COLOR,
            },
            error: {
                main: at.ERROR_COLOR,
            },
        },

        overrides: {
            MuiFormLabel: {
                root: {
                    'color': at.LABEL_COLOR,
                    'fontFamily': at.MAIN_FONT_FAMILY,
                    'fontWeight': at.FONT_WEIGHT_SEMI,
                    'fontSize': '12px',
                    '&$focused': {
                        color: at.LIGHT_PRIMARY_COLOR,
                        fontFamily: at.MAIN_FONT_FAMILY,
                        fontSize: '12px',
                        fontWeight: at.FONT_WEIGHT_REGULAR,
                    },
                },
            },

            MuiListItem: {
                root: {
                    color: colorObject['--navigation-menu-text-color'],
                },
            },

            MuiInputBase: {
                root: {
                    'fontFamily': at.MAIN_FONT_FAMILY,
                    'fontWeight': at.FONT_WEIGHT_REGULAR,
                    'fontSize': '16px',
                    'color': at.MAIN_DARK_COLOR,
                    '& legend': {
                        fontSize: '0.6em',
                    },
                },
                input: {
                    padding: '4px 0 4px',
                },

                multiline: {
                    padding: '4px 0 4px',
                },
            },
            MuiInput: {
                underline: {
                    '&:before': {
                        borderBottom: `2px solid rgba( ${at.INPUT_BORDER_BOTTOM_COLOR}, 0.5)`,
                    },
                },
            },
            MuiSelect: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                },
                icon: {
                    fontSize: '24px',
                    color: at.MAIN_DARK_COLOR,
                    top: 'calc(50% - 12px)',
                },
            },
            MuiAutocomplete: {
                inputRoot: {
                    '&&[class*="MuiInput-root"] $input:first-child': {
                        padding: '4px 0',
                    },
                },
                endAdornment: {
                    '& span': {
                        '& svg': {
                            fontSize: '24px',
                            color: at.MAIN_DARK_COLOR,
                        },
                    },
                },
                listbox: {
                    'scrollbarWidth': 'thin',
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: at.SCROLLBAR_TRACK,
                    },
                    '&::-webkit-scrollbar': {
                        backgroundColor: at.SCROLLBAR,
                        width: '6px',
                        height: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: at.SCROLLBAR_THUMB,
                        borderRadius: '0px',
                    },
                },
            },
            MuiButton: {
                root: {
                    fontFamily: at.MAIN_FONT_FAMILY,
                    fontWeight: at.FONT_WEIGHT_SEMI,
                    fontSize: '16px',
                    textTransform: 'capitalize',
                },
                containedSecondary: {
                    'backgroundColor': `rgba( ${at.SECONDARY_BUTTON_BG}, 0.15)`,
                    'color': at.SECONDARY_BUTTON_COLOR,
                    '&:hover': {
                        backgroundColor: `rgba( ${at.SECONDARY_BUTTON_BG}, 0.35)`,
                    },
                },
                containedPrimary: {
                    'backgroundColor': colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                    'color': at.WHITE_COLOR,
                    '&:hover': {
                        backgroundColor: colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                    },
                },
                outlinedPrimary: {
                    'border': `1px solid ${colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR}`,
                    'backgroundColor': colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                    'color': at.WHITE_COLOR,
                    '&:hover': {
                        backgroundColor: colorObject['--primary-button-color'] || at.PRIMARY_BUTTON_COLOR,
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                    },
                },
                sizeLarge: {
                    fontSize: '16px',
                },
            },
            MuiMenuItem: {
                root: {
                    fontFamily: at.MAIN_FONT_FAMILY,
                    fontWeight: at.FONT_WEIGHT_REGULAR,
                    fontSize: '16px',
                    color: at.MAIN_DARK_COLOR,
                },
            },
        },
    });
