import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { optionsSelect } from '../../../constants/MeshbotConstant';

const StringSelectField = ({ value, onChange, name, options }) => {
    const handleChange = (e) => {
        onChange(e.target.value, name);
    };

    return (
        <Select MenuProps={optionsSelect} fullWidth={true} value={value} onChange={handleChange}>
            {options.map((item) => {
                return (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default StringSelectField;
