import React from 'react';
import { Link } from '@material-ui/core';

import { useTranslate } from '../../../languages';
import oemManager from '../../../../services/oem';
import { getPoliciesAndEulaLinks } from '../../utils';

import styles from './policieslinks.module.scss';

const EzloPoliciesLinks = () => {
    const eulaUrl = oemManager.oem.getEulaUrl();
    const policyUrl = oemManager.oem.getPrivacyPolicyUrl();
    const policyLinks = getPoliciesAndEulaLinks(eulaUrl, policyUrl);

    const { t } = useTranslate();

    return (
        <div className={styles.policiesLinks}>
            {policyLinks?.map((policy, index) => (
                <Link className={styles.link} href={policy?.url} key={index} rel="noreferrer" target="_blank">
                    {t(policy?.title)}
                </Link>
            ))}
        </div>
    );
};

export default EzloPoliciesLinks;
