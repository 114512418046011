import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    CONFIRMATION,
    SETTING_TYPE_ACTION,
    SETTING_TYPE_BOOL,
    SETTING_TYPE_DICTIONARY,
    SETTING_TYPE_INT,
    SETTING_TYPE_MULTI_TOKEN,
    SETTING_TYPE_RGB,
    SETTING_TYPE_TEMP,
    SETTING_TYPE_TOKEN,
    SETTING_TYPE_STRING,
    DEVICE_DESCRIPTION,
} from '../../../../../constants/Devices';
import { getNameSubscribe } from '../../utils';
import { Modal } from '../../../../../components';
import DeviceSettingBool from '../DeviceSettingBool';
import DeviceSettingInt from '../DeviceSettingInt';
import DeviceSettingToken from '../DeviceSettingToken';
import DeviceSettingMultiToken from '../DeviceSettingMultiToken';
import DeviceSettingGRBLight from '../DeviceSettingGRBLight';
import DeviceSettingAction from '../DeviceSettingAction';
import DeviceCustomSettings from '../DeviceCustomSettings';
import DeviceSettingString from '../DeviceSettingString';
import DeviceSettingDefault from '../DeviceSettingDefault';
import ConfirmationModal from '../ConfirmationModal';
import CameraStreamSettings from '../CameraStreamSettings';
import './deviceModal.scss';
import {
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_HEADING_CAMERA_STREAM_SETTINGS,
    EZLOGIC_TITLE_BLUE,
    EZLOGIC_TITLE_GREEN,
    EZLOGIC_TITLE_PARAMETER_ID,
    EZLOGIC_TITLE_RED,
    EZLOGIC_TITLE_RESTORE,
    EZLOGIC_TITLE_STATUS,
    EZLOGIC_TITLE_VALUE,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DeviceModal = (props) => {
    const { isModalVisible, onCancel, onCreate, currentFunction, onOk, onSubscribeBroadcast } = props;

    const { t } = useTranslate();
    useEffect(() => {
        if (currentFunction?.device?.serial && currentFunction?.setting !== CONFIRMATION) {
            onSubscribeBroadcast(
                currentFunction.device.serial,
                getNameSubscribe(currentFunction),
                currentFunction?.setting._id,
            );
        }
    }, []);
    const titles = {
        status: t(EZLOGIC_TITLE_STATUS),
        parameterId: t(EZLOGIC_TITLE_PARAMETER_ID),
        value: t(EZLOGIC_TITLE_VALUE),
        restore: t(EZLOGIC_TITLE_RESTORE),
        save: t(EZLOGIC_BUTTON_SAVE),
        red: t(EZLOGIC_TITLE_RED),
        green: t(EZLOGIC_TITLE_GREEN),
        blue: t(EZLOGIC_TITLE_BLUE),
    };
    const createTemplateSettings = (data) => {
        if (data?.setting === DEVICE_DESCRIPTION.CAMERA_STREAM_NAME) {
            return <CameraStreamSettings data={data} title={t(EZLOGIC_HEADING_CAMERA_STREAM_SETTINGS)} />;
        }

        if (data?.setting === CONFIRMATION) {
            return (
                <ConfirmationModal
                    currentFunction={currentFunction}
                    onCancel={onOk}
                    onSubscribeBroadcast={onSubscribeBroadcast}
                />
            );
        } else {
            switch (data?.setting?.valueType) {
                case SETTING_TYPE_STRING:
                    return <DeviceSettingString settings={data.setting} onSave={onCreate} />;
                case SETTING_TYPE_BOOL:
                    return (
                        <DeviceSettingBool
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            statusTitle={t(EZLOGIC_TITLE_STATUS)}
                            parameterIdTitle={t(EZLOGIC_TITLE_PARAMETER_ID)}
                        />
                    );
                case SETTING_TYPE_INT:
                case SETTING_TYPE_TEMP:
                    return (
                        <DeviceSettingInt
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            titles={titles}
                        />
                    );
                case SETTING_TYPE_TOKEN:
                    return (
                        <DeviceSettingToken
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            titles={titles}
                        />
                    );
                case SETTING_TYPE_MULTI_TOKEN:
                    return (
                        <DeviceSettingMultiToken
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            titles={titles}
                        />
                    );
                case SETTING_TYPE_RGB:
                    return (
                        <DeviceSettingGRBLight
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            titles={titles}
                        />
                    );
                case SETTING_TYPE_ACTION:
                    return (
                        <DeviceSettingAction
                            device={data.device}
                            settings={data.setting}
                            onSave={onCreate}
                            titles={titles}
                        />
                    );
                case SETTING_TYPE_DICTIONARY:
                    return <DeviceCustomSettings device={data.device} settings={data?.setting} onCancel={onCancel} />;
                default:
                    return <DeviceSettingDefault onCancel={onCancel} />;
            }
        }
    };

    const handlerHide = () => {
        if (currentFunction) {
            onCancel(currentFunction, getNameSubscribe(currentFunction));
        }
    };

    return (
        <Modal
            visible={isModalVisible}
            onCancel={currentFunction?.setting !== CONFIRMATION ? handlerHide : onOk}
            className="device-modal"
        >
            {createTemplateSettings(currentFunction)}
        </Modal>
    );
};

export default React.memo(DeviceModal);

DeviceModal.propTypes = {
    isModalVisible: PropTypes.bool,
    onCreate: PropTypes.func,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    currentFunction: PropTypes.object,
    DeviceActions: PropTypes.object,
    onSubscribeBroadcast: PropTypes.func,
};
