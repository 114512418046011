import React from 'react';
import DeviceGroupSubcategory from '../DeviceGroupSubcategory';

const DeviceGroupSubcategories = (props) => {
    const { payload, categoryId } = props;

    return <DeviceGroupSubcategory payload={payload} categoryId={categoryId} />;
};

export default DeviceGroupSubcategories;
