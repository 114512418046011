import React from 'react';
import { useFormikContext } from 'formik';

import ValidatedIcon from '../ValidatedIcon';
import InternationalPhoneNumber from './InternationalPhoneNumber/InternationalPhoneNumber';
import USAPhoneNumberInputWithMask from './USAPhoneNumberInputWithMask/USAPhoneNumberInputWithMask';

import oemManager from '../../../../../services/oem';
import { PHONE_NUMBER, PHONE_NUMBER_INPUT_TYPES } from '../../../../../constants/Users';
import { getPhoneNumberInputTypeByOemId } from '../../UserManagement/utils';

import textInputStyles from '../TextInput/styles.module.scss';

const PhoneNumberInputs = {
    [PHONE_NUMBER_INPUT_TYPES.USA_FORMAT_WITH_MASK]: (props) => <USAPhoneNumberInputWithMask {...props} />,
    [PHONE_NUMBER_INPUT_TYPES.INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT]: (props) => (
        <InternationalPhoneNumber {...props} />
    ),
};

const PhoneNumberInputContainer = () => {
    const oemId = oemManager.oem.getOemId();
    const phoneNumberInputType = getPhoneNumberInputTypeByOemId(oemId);
    const { values, touched, errors, setFieldValue } = useFormikContext();
    const { FIELD_NAME, FIELD_LABEL } = PHONE_NUMBER;
    const inputTouched = touched[FIELD_NAME];
    const inputErrorMessage = errors[FIELD_NAME];

    const phoneNumberProps = {
        label: FIELD_LABEL,
        value: values[FIELD_NAME],
        error: inputTouched && Boolean(inputErrorMessage),
        helperText: inputTouched && inputErrorMessage,
        updatePhoneNumber: (phoneNumber) => setFieldValue(FIELD_NAME, phoneNumber),
    };

    return (
        <div className={textInputStyles.textFieldBox}>
            {PhoneNumberInputs[phoneNumberInputType](phoneNumberProps)}

            <ValidatedIcon name={FIELD_NAME} validated={values.phoneValidated} />
        </div>
    );
};

export default PhoneNumberInputContainer;
