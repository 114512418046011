import React from 'react';
import { FormControl, InputAdornment, MenuItem, Select } from '@material-ui/core';
import { SwapVert } from '@material-ui/icons';

import { OPTIONS_OF_ORDER_SORTING } from '../../constants';
import { useTranslate } from '../../../languages';
import { optionsSelect } from '../../../../constants/MeshbotConstant';

const OrderSortingSelect = ({ value, onChangeSelect }) => {
    const { t } = useTranslate();

    return (
        <FormControl>
            <Select
                id="orderSortingSelect"
                value={value}
                onChange={onChangeSelect}
                MenuProps={optionsSelect}
                startAdornment={
                    <InputAdornment position="start">
                        <SwapVert color="primary" fontSize="large" />
                    </InputAdornment>
                }
            >
                {OPTIONS_OF_ORDER_SORTING.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {t(label)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default OrderSortingSelect;
