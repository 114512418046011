import { getMeshBotLabels, updateMeshBotLabels, editMeshBotLabels, deleteMeshBotLabels } from '../services/kvs';
import { setIsLoading, setMeshBotLabels } from '../reducers/kvs';
import { TOAST_TYPE, toast } from '../components/Toast';
import { t } from '../helpers/language';
import {
    EZLOGIC_TITLE_HAS_BEEN_CREATED_SUCCESSFULLY,
    EZLOGIC_TITLE_LABEL_HAS_BEEN_DELETED_SUCCESSFULLY,
    EZLOGIC_TITLE_LABEL_HAS_BEEN_EDITED_SUCCESSFULLY,
    EZLOGIC_TITLE_SOMETHING_WENT_WRONG,
} from '../constants/language_tokens';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { setLabelUuidForFiltering } from '../reducers/labels';

const kvsActions = {
    setMeshBotLabels: async (dispatch) => {
        try {
            const meshBotLabels = await getMeshBotLabels();

            dispatch(setMeshBotLabels(meshBotLabels));
        } catch (e) {
            const errorMessage = e.message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
            toast(errorMessage, { type: TOAST_TYPE.ERROR });
            bugsnagNotify(e, { type: 'setMeshBotLabels' });
        }
    },
    updateMeshBotLabels: (newLabel) => async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            await updateMeshBotLabels(newLabel);
            await dispatch(kvsActions.setMeshBotLabels);
            const toastMessage = `${newLabel.name} ${t(EZLOGIC_TITLE_HAS_BEEN_CREATED_SUCCESSFULLY)}`;
            await toast(toastMessage, { type: TOAST_TYPE.SUCCESS });

            return { isError: false };
        } catch (e) {
            const errorMessage = e.message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
            toast(errorMessage, { type: TOAST_TYPE.ERROR });
            bugsnagNotify(e, { type: 'updateMeshBotLabels', newLabel });

            return { isError: true };
        } finally {
            dispatch(setIsLoading(false));
        }
    },
    editMeshBotLabel: (updatedLabel, labelUuid) => async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            await editMeshBotLabels(updatedLabel, labelUuid);
            await dispatch(kvsActions.setMeshBotLabels);
            const toastMessage = `${t(EZLOGIC_TITLE_LABEL_HAS_BEEN_EDITED_SUCCESSFULLY)}`;
            await toast(toastMessage, { type: TOAST_TYPE.SUCCESS });

            return { isError: false };
        } catch (e) {
            const errorMessage = e.message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
            toast(errorMessage, { type: TOAST_TYPE.ERROR });
            bugsnagNotify(e, { type: 'editMeshBotLabel', updatedLabel, labelUuid });

            return { isError: true };
        } finally {
            dispatch(setIsLoading(false));
        }
    },
    deleteMeshBotLabel: (labelUuid) => async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            await deleteMeshBotLabels(labelUuid);
            await dispatch(kvsActions.setMeshBotLabels);
            const toastMessage = `${t(EZLOGIC_TITLE_LABEL_HAS_BEEN_DELETED_SUCCESSFULLY)}`;
            await toast(toastMessage, { type: TOAST_TYPE.SUCCESS });

            return { isError: false };
        } catch (e) {
            const errorMessage = e.message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
            toast(errorMessage, { type: TOAST_TYPE.ERROR });
            bugsnagNotify(e, { type: 'deleteMeshBotLabel', labelUuid });

            return { isError: true };
        } finally {
            dispatch(setIsLoading(false));
            dispatch(setLabelUuidForFiltering(null));
        }
    },
};

export default kvsActions;
