import { useMemo, useState } from 'react';
import { ROW_PRE_PAGE_25 } from './constants';

export const useTablePagination = (items = []) => {
    const [rowsPerPage, setRowsPerPage] = useState(ROW_PRE_PAGE_25);
    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const partOfItems = useMemo(() => {
        return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [items, page, rowsPerPage]);

    return {
        state: { page, rowsPerPage, items: partOfItems },
        actions: { handleChangePage, handleChangeRowsPerPage },
    };
};
