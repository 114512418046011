import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, Button, TextField } from '@material-ui/core';
import * as generator from '../../../../../constants/DevicePluginGenerator';
import { installedOnControllers, transformCapabilitiesFrom } from '../../utils';
import {
    PRIVATE,
    PORT,
    DEVICE_NAME,
    MAC_ADDRESS,
    IP_ADDRESS,
    FIELD_PORT,
    USER_NAME,
    PASSWORD,
    STATUSES,
    EDGE_PRIVATE,
} from '../../../../../constants/Plugins';
import { WIFI_DEVICE_CREATING_PAGE } from '../../../../../constants/ActionConfirmDialog';
import {
    ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
    INFO_OPTIONS,
    SUCCESS_OPTIONS,
} from '../../../../../constants/toastConstants';
import toastsActions from '../../../../../actions/toastsActions';

import Header from '../HeaderForGenerator';
import usePreviousState from '../../../../../hooks/usePreviousState';
import useUnsavedChangesWarning from '../../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';

import form from '../GeneratorForm/generatorForm.module.scss';
import {
    EZLOGIC_BUTTON_CREATE_DEVICE,
    EZLOGIC_HEADING_CREATE_YOUR_IP_DEVICE,
    EZLOGIC_HEADING_CREDENTIALS,
    EZLOGIC_TITLE_DEVICE_NAME,
    EZLOGIC_TITLE_IP,
    EZLOGIC_TITLE_IP_ADDRESS,
    EZLOGIC_TITLE_MAC,
    EZLOGIC_TITLE_MAC_ADDRESS,
    EZLOGIC_TITLE_NAME,
    EZLOGIC_TITLE_PASSWORD,
    EZLOGIC_TITLE_PORT,
    EZLOGIC_TITLE_PUT_DEVICE_NAME,
    EZLOGIC_TITLE_PUT_USER_NAME,
    EZLOGIC_TITLE_TEMPLATE_NAME,
    EZLOGIC_TITLE_USER,
    EZLOGIC_TITLE_USER_NAME,
    EZLOGIC_TOAST_DEVICE_ADDED,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { DEVICE_NAME_FIELD } from '../../../../../constants/Devices';
import DeviceGeneratorNotInstalled from '../DeviceGeneratorNotInstalled';
import { EyeIcon, EyeOffIcon } from '../../../../../assets/icons';

const CreateDevice = (props) => {
    const [template, setTemplate] = useState(null);
    const [deviceName, setDeviceName] = useState('');
    const [port, setPort] = useState(PORT);
    const [macAddress, setMacAddress] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isInstalled, setIsInstalled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const setDirty = useUnsavedChangesWarning();
    const isDirty = useSelector((state) => state.main.editableComponent?.isDirty);
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const {
        serial,
        match,
        privateWifiTemplates,
        history,
        onSetIsActivePage,
        EzloActions,
        PluginActions,
        MainAction,
        listInstalledPlugins,
    } = props;

    const prevSerial = usePreviousState({ serial });

    useEffect(() => {
        const currentTemplate = privateWifiTemplates.filter((template) => template.uuid === match?.params?.id);
        if (currentTemplate) {
            setTemplate(currentTemplate.shift());
        }

        MainAction.setStatePageBeingEdited(WIFI_DEVICE_CREATING_PAGE);

        return () => {
            MainAction.setStatePageBeingEdited({});
            PluginActions.unSubscribeUpdateConfigure(serial);
            PluginActions.unSubscribeBroadcastConfigure(serial);
        };
    }, []);

    useEffect(() => {
        if (!deviceName && !macAddress && !ipAddress && !userName && !password && !port) {
            setDirty(false);
            MainAction.setConfirmationUser(false);
        } else {
            if (!isDirty) {
                setDirty(true);
                MainAction.setConfirmationUser(true);
            }
        }
    }, [deviceName, macAddress, ipAddress, userName, password, port]);

    useEffect(() => {
        if (prevSerial) {
            PluginActions.unSubscribeUpdateConfigure(prevSerial.serial);
            PluginActions.unSubscribeBroadcastConfigure(prevSerial.serial);
        }

        PluginActions.subscribeBroadcastConfigure(serial, notificationUpdateConfigure(serial));
        PluginActions.subscribeUpdateConfigure(serial, notificationUpdateConfigure(serial));
    }, [serial]);

    useEffect(() => {
        if (installedOnControllers(listInstalledPlugins)[serial]) {
            setIsInstalled(true);
        } else {
            setIsInstalled(false);
        }
    }, [serial, listInstalledPlugins]);

    const notificationUpdateConfigure = (serial) => {
        return ({ result }) => {
            if (result.status === STATUSES.ERROR || result.status === STATUSES.ADD_DEVICE_FAIL) {
                dispatch(
                    toastsActions.showToast({
                        message: result.error?.message,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
                PluginActions.unSubscribeUpdateConfigure(serial);
                PluginActions.unSubscribeBroadcastConfigure(serial);
            } else {
                if (result.status === STATUSES.SUCCESS || result.status === STATUSES.OK) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_DEVICE_ADDED),
                            options: SUCCESS_OPTIONS,
                        }),
                    );
                    PluginActions.unSubscribeBroadcastConfigure(serial);
                    EzloActions.loadEzloDataByPluginUpdate(serial);
                    history.replace(generator.WIFI_GENERATOR_PRIVATE);
                    onSetIsActivePage(PRIVATE);
                }

                if (result.status === STATUSES.FAILED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR),
                            options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                            isSave: true,
                        }),
                    );
                    PluginActions.unSubscribeUpdateConfigure(serial);
                }

                if (result.status === STATUSES.FINISHED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY),
                            options: INFO_OPTIONS,
                        }),
                    );
                    PluginActions.unSubscribeUpdateConfigure(serial);
                }
            }
        };
    };

    const handleChangeSelect = (e) => {
        const { value, name } = e.target;

        switch (name) {
            case DEVICE_NAME:
                setDeviceName(value);
                break;
            case MAC_ADDRESS:
                setMacAddress(value);
                break;
            case IP_ADDRESS:
                setIpAddress(value);
                break;
            case FIELD_PORT:
                setPort(value);
                break;
            case USER_NAME:
                setUserName(value);
                break;
            case PASSWORD:
                setPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        const { data, uuid } = template;

        const params = {
            device_name: deviceName,
            mac_address: macAddress,
            ip_address: ipAddress,
            port: port,
            username: userName,
            password: password,
            template: {
                uuid: uuid,
                model: data?.config?.model,
                device_type: data?.config?.device_type,
                category: data?.config?.category,
                subcategory: data?.config?.subcategory,
                capabilities: transformCapabilitiesFrom(data?.config?.capabilities),
            },
        };

        PluginActions.addDeviceWifiGenerator(serial, params);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRouteToPlugin = () => {
        history.replace(EDGE_PRIVATE);
    };

    return (
        <>
            <Header title={EZLOGIC_HEADING_CREATE_YOUR_IP_DEVICE} isEdit={false} />
            {isInstalled ? (
                <div className={form.generatorForm}>
                    <div className={form.generatorForm__block}>
                        <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_TEMPLATE_NAME)}</span>
                        <h3>{template?.data?.config?.model}</h3>
                    </div>
                    <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                        <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_DEVICE_NAME)}</span>
                        <TextField
                            label={t(EZLOGIC_TITLE_NAME)}
                            name={DEVICE_NAME_FIELD}
                            placeholder={t(EZLOGIC_TITLE_PUT_DEVICE_NAME)}
                            value={deviceName ?? deviceName}
                            className={form.generatorForm__input}
                            onChange={handleChangeSelect}
                        />
                    </div>
                    <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                        <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_MAC_ADDRESS)}</span>
                        <TextField
                            label={t(EZLOGIC_TITLE_MAC)}
                            name="mac_address"
                            placeholder="00:00:5e:00:53:af"
                            value={macAddress ?? macAddress}
                            className={form.generatorForm__input}
                            onChange={handleChangeSelect}
                        />
                    </div>
                    <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                        <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_IP_ADDRESS)}</span>
                        <TextField
                            label={t(EZLOGIC_TITLE_IP)}
                            name="ip_address"
                            placeholder="10.0.0.34"
                            value={ipAddress ?? ipAddress}
                            className={form.generatorForm__input}
                            onChange={handleChangeSelect}
                        />
                    </div>
                    <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                        <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_PORT)}</span>
                        <TextField
                            label={t(EZLOGIC_TITLE_PORT)}
                            name="port"
                            placeholder="80"
                            value={port ?? port}
                            className={form.generatorForm__input}
                            onChange={handleChangeSelect}
                        />
                    </div>
                    <h3 className={form.generatorForm__card_title}>{t(EZLOGIC_HEADING_CREDENTIALS)}</h3>
                    <div className={form.generatorForm__card}>
                        <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                            <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_USER_NAME)}</span>
                            <TextField
                                label={t(EZLOGIC_TITLE_USER)}
                                name="username"
                                placeholder={t(EZLOGIC_TITLE_PUT_USER_NAME)}
                                value={userName ?? userName}
                                className={form.generatorForm__input}
                                onChange={handleChangeSelect}
                            />
                        </div>
                        <div className={`${form.generatorForm__block} ${form.generatorForm__block_item}`}>
                            <span className={form.generatorForm__label}>{t(EZLOGIC_TITLE_PASSWORD)}</span>
                            <TextField
                                label={t(EZLOGIC_TITLE_PASSWORD)}
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                placeholder={t(EZLOGIC_TITLE_PASSWORD)}
                                value={password ?? password}
                                className={form.generatorForm__input}
                                onChange={handleChangeSelect}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? (
                                                    <EyeIcon className="toggle-icon eye" />
                                                ) : (
                                                    <EyeOffIcon className="toggle-icon" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={form.generatorForm__buttonCreate}
                        onClick={handleSave}
                    >
                        {t(EZLOGIC_BUTTON_CREATE_DEVICE)}
                    </Button>
                </div>
            ) : (
                <DeviceGeneratorNotInstalled handleRouteToPlugin={handleRouteToPlugin} />
            )}
        </>
    );
};

export default React.memo(CreateDevice);

CreateDevice.propTypes = {
    serial: PropTypes.string,
    privateWifiTemplates: PropTypes.array,
    match: PropTypes.object,
    history: PropTypes.object,
    PluginActions: PropTypes.object,
};
