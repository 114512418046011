import { MenuItem } from '@material-ui/core';
import React from 'react';
import { CONTROLLER_RESET_CAPABILITY_VALUES } from '../../../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../../../features/languages';
import { info } from '../../../../utils';
import SelectComponent from '../../../SelectComponent';

const ControllerCapabilityResetValue = (props) => {
    const { onHandleControllerResetCapabilityValueChange, initialControllerResetCapabilityValue } = props;
    const { t } = useTranslate();

    return (
        <SelectComponent
            label={info.hints.value}
            info={info.text.value}
            value={initialControllerResetCapabilityValue ?? ''}
            onChange={onHandleControllerResetCapabilityValueChange}
        >
            {CONTROLLER_RESET_CAPABILITY_VALUES.map((resetValue) => {
                return (
                    <MenuItem key={resetValue.id} value={resetValue.value}>
                        {t(resetValue.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ControllerCapabilityResetValue;
