import {
    EZLOGIC_TITLE_DATA_TYPE_ACTION_BUTTON,
    EZLOGIC_TITLE_DATA_TYPE_BOOLEAN,
    EZLOGIC_TITLE_DATA_TYPE_COLOR,
    EZLOGIC_TITLE_DATA_TYPE_FLOAT,
    EZLOGIC_TITLE_DATA_TYPE_INTEGER,
    EZLOGIC_TITLE_DATA_TYPE_SCALABLE,
    EZLOGIC_TITLE_DATA_TYPE_STRING,
    EZLOGIC_TITLE_DATA_TYPE_TOKEN,
    EZLOGIC_TITLE_SCALABLE_TYPE_ACCELERATION,
    EZLOGIC_TITLE_SCALABLE_TYPE_ACIDITY,
    EZLOGIC_TITLE_SCALABLE_TYPE_AMOUNT_OF_USEFUL_ENERGY,
    EZLOGIC_TITLE_SCALABLE_TYPE_ANGLE,
    EZLOGIC_TITLE_SCALABLE_TYPE_BLOOD_PRESSURE,
    EZLOGIC_TITLE_SCALABLE_TYPE_DIRECTION,
    EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_CONDUCTIVITY,
    EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_RESISTANCE,
    EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_CURRENT,
    EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_POTENTIAL,
    EZLOGIC_TITLE_SCALABLE_TYPE_ENERGY,
    EZLOGIC_TITLE_SCALABLE_TYPE_FORCE,
    EZLOGIC_TITLE_SCALABLE_TYPE_FREQUENCY,
    EZLOGIC_TITLE_SCALABLE_TYPE_GENERAL_PURPOSE,
    EZLOGIC_TITLE_SCALABLE_TYPE_HUMIDITY,
    EZLOGIC_TITLE_SCALABLE_TYPE_ILLUMINANCE,
    EZLOGIC_TITLE_SCALABLE_TYPE_IRRADIANCE,
    EZLOGIC_TITLE_SCALABLE_TYPE_KILO_VOLT_AMPERE_HOUR,
    EZLOGIC_TITLE_SCALABLE_TYPE_LENGTH,
    EZLOGIC_TITLE_SCALABLE_TYPE_LOUDNESS,
    EZLOGIC_TITLE_SCALABLE_TYPE_MASS,
    EZLOGIC_TITLE_SCALABLE_TYPE_MOISTURE,
    EZLOGIC_TITLE_SCALABLE_TYPE_POWER,
    EZLOGIC_TITLE_SCALABLE_TYPE_PRECIPITATION,
    EZLOGIC_TITLE_SCALABLE_TYPE_PRESSURE,
    EZLOGIC_TITLE_SCALABLE_TYPE_RADON_CONCENTRATION,
    EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_CONSUMPTION,
    EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_INSTANT,
    EZLOGIC_TITLE_SCALABLE_TYPE_RF_SIGNAL_STRENGTH,
    EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_INTENSITY,
    EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_MAGNITUDE,
    EZLOGIC_TITLE_SCALABLE_TYPE_SUBSTANCE_AMOUNT,
    EZLOGIC_TITLE_SCALABLE_TYPE_TEMPERATURE,
    EZLOGIC_TITLE_SCALABLE_TYPE_TIME,
    EZLOGIC_TITLE_SCALABLE_TYPE_ULTRAVIOLET,
    EZLOGIC_TITLE_SCALABLE_TYPE_VELOCITY,
    EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME,
    EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME_FLOW,
    EZLOGIC_TITLE_SCALE_TYPE_AMPERE,
    EZLOGIC_TITLE_SCALE_TYPE_A_WEIGHTED_DECIBEL,
    EZLOGIC_TITLE_SCALE_TYPE_BEATS_PER_MINUTE,
    EZLOGIC_TITLE_SCALE_TYPE_BECQUEREL_PER_CUBIC_METER,
    EZLOGIC_TITLE_SCALE_TYPE_BODY_WAVE,
    EZLOGIC_TITLE_SCALE_TYPE_BREATHS_PER_MINUTE,
    EZLOGIC_TITLE_SCALE_TYPE_BTU_PER_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_CELSIUS,
    EZLOGIC_TITLE_SCALE_TYPE_CENTI_METER,
    EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET,
    EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET_PER_MINUTE,
    EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER,
    EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER_PER_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_DECIBEL,
    EZLOGIC_TITLE_SCALE_TYPE_DECIBEL_MILLI_WATT,
    EZLOGIC_TITLE_SCALE_TYPE_DIASTOLIC,
    EZLOGIC_TITLE_SCALE_TYPE_EAST,
    EZLOGIC_TITLE_SCALE_TYPE_EUROPEAN_MACROSEISMIC,
    EZLOGIC_TITLE_SCALE_TYPE_FAHRENHEIT,
    EZLOGIC_TITLE_SCALE_TYPE_FEET,
    EZLOGIC_TITLE_SCALE_TYPE_FLOAT,
    EZLOGIC_TITLE_SCALE_TYPE_GALLONS,
    EZLOGIC_TITLE_SCALE_TYPE_GRAM_PER_CUBIC_METER,
    EZLOGIC_TITLE_SCALE_TYPE_HERTZ,
    EZLOGIC_TITLE_SCALE_TYPE_IMPEDANCE,
    EZLOGIC_TITLE_SCALE_TYPE_INCHES_OF_MERCURY,
    EZLOGIC_TITLE_SCALE_TYPE_INCHES_PER_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_JOULE,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_GRAM,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_HERTZ,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_PASCAL,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_KILO_WATT_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_LIEDU,
    EZLOGIC_TITLE_SCALE_TYPE_LITER,
    EZLOGIC_TITLE_SCALE_TYPE_LITER_PER_HOUR,
    EZLOGIC_TITLE_LOCAL,
    EZLOGIC_TITLE_SCALE_TYPE_LUX,
    EZLOGIC_TITLE_SCALE_TYPE_MERCALLI,
    EZLOGIC_TITLE_SCALE_TYPE_METER,
    EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SECOND,
    EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SQUARE_SECOND,
    EZLOGIC_TITLE_SCALE_TYPE_MICRO_GRAM_PER_CUBIC_METER,
    EZLOGIC_TITLE_SCALE_TYPE_MILE_PER_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_MILLI_AMPERE,
    EZLOGIC_TITLE_SCALE_TYPE_MILLI_GRAM_PER_LITER,
    EZLOGIC_TITLE_SCALE_TYPE_MILLI_METER_PER_HOUR,
    EZLOGIC_TITLE_SCALE_TYPE_MILLI_VOLT,
    EZLOGIC_TITLE_SCALE_TYPE_MOLE_PER_CUBIC_METER,
    EZLOGIC_TITLE_SCALE_TYPE_MOMENT,
    EZLOGIC_TITLE_SCALE_TYPE_NEWTON,
    EZLOGIC_TITLE_SCALE_TYPE_NORTH,
    EZLOGIC_TITLE_SCALE_TYPE_NORTH_POLE_DEGREES,
    EZLOGIC_TITLE_SCALE_TYPE_NO_DIRECTION,
    EZLOGIC_TITLE_SCALE_TYPE_OHM_METER,
    EZLOGIC_TITLE_SCALE_TYPE_PARTS_PER_MILLION,
    EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
    EZLOGIC_TITLE_SCALE_TYPE_PICOCURIES_PER_LITER,
    EZLOGIC_TITLE_SCALE_TYPE_POTENTIAL_OF_HYDROGEN,
    EZLOGIC_TITLE_SCALE_TYPE_POUNDS,
    EZLOGIC_TITLE_SCALE_TYPE_POUND_PER_SQUARE_INCH,
    EZLOGIC_TITLE_SCALE_TYPE_REVOLUTIONS_PER_MINUTE,
    EZLOGIC_TITLE_SCALE_TYPE_SHINDO,
    EZLOGIC_TITLE_SCALE_TYPE_SIEMENS_PER_METER,
    EZLOGIC_TITLE_SCALE_TYPE_SOUTH,
    EZLOGIC_TITLE_SCALE_TYPE_SURFACE_WAVE,
    EZLOGIC_TITLE_SCALE_TYPE_SYSTOLIC,
    EZLOGIC_TITLE_SCALE_TYPE_UV_INDEX,
    EZLOGIC_TITLE_SCALE_TYPE_VOLT,
    EZLOGIC_TITLE_SCALE_TYPE_VOLUME_WATER_CONTENT,
    EZLOGIC_TITLE_SCALE_TYPE_WATER_ACTIVITY,
    EZLOGIC_TITLE_SCALE_TYPE_WATT,
    EZLOGIC_TITLE_SCALE_TYPE_WATT_PER_SQUARE_METER,
    EZLOGIC_TITLE_SCALE_TYPE_WEST,
    EZLOGIC_TITLE_VARIABLE_LANGUAGE_TAG,
    EZLOGIC_TITLE_VARIABLE_NAME,
    EZLOGIC_TITLE_VARIABLE_SCALE,
    EZLOGIC_TITLE_VARIABLE_SCALE_TYPE,
    EZLOGIC_TITLE_VARIABLE_TEXT,
    EZLOGIC_TITLE_VARIABLE_TYPE,
    EZLOGIC_TITLE_VARIABLE_VALUE,
    EZLOGIC_TITLE_SECONDS,
} from './language_tokens';
import hash from './uniqueHash';

export const DATA_TYPES_OBJ_VALID_LENGTH = 2;
export const EXPRESSION_NAME_MAX_LENGTH = 29;
export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_ADORONMENT_END_POSITION = 'end';
export const HEX_COLOR_SYMBOLS_MAX_LENGTH = 7;
export const ACTION_LANG_TAG_INPUT_NAME = 'lang_tag';
export const SCALE_INPUT_NAME = 'scale';
export const VALUE_INPUT_NAME = 'value';
export const OBJECT_TYPE = 'object';
export const EXPRESSION_TYPE_VARIABLE = 'variable';
export const INPUT_APPEARANCE_VARIANT_OUTLINED = 'outlined';
export const INPUT_APPEARANCE_VARIANT_STANDARD = 'standard';
export const INPUT_TYPE_NUMBER_STEP = '.01';
export const COMMA = ',';
export const DOT = '.';
export const ZERO_INT = 0;
export const ONE_INT = 1;
export const BOOLEAN_TRUE = 'true';
export const BOOLEAN_FALSE = 'false';
export const BOOLEAN = 'boolean';

export const LOCAL_VARIABLE_ACTION_FIELDS_VALUES = {
    NAME: 'name',
    TYPE: 'type',
    VALUE: 'value',
    VALUE_TYPE: 'valueType',
    METADATA: 'metadata',
    BLOCKOPTIONS_METHOD_NAME: 'setVariable',
};

export const VARIABLES_DATA_TYPES = [
    {
        value: 'int',
        name: EZLOGIC_TITLE_DATA_TYPE_INTEGER,
        id: hash(),
    },
    {
        value: 'float',
        name: EZLOGIC_TITLE_DATA_TYPE_FLOAT,
        id: hash(),
    },
    {
        value: 'string',
        name: EZLOGIC_TITLE_DATA_TYPE_STRING,
        id: hash(),
    },
    {
        value: 'bool',
        name: EZLOGIC_TITLE_DATA_TYPE_BOOLEAN,
        id: hash(),
    },
    {
        value: 'action',
        name: EZLOGIC_TITLE_DATA_TYPE_ACTION_BUTTON,
        id: hash(),
    },
    {
        value: 'rgb',
        name: EZLOGIC_TITLE_DATA_TYPE_COLOR,
        id: hash(),
    },
    {
        value: 'token',
        name: EZLOGIC_TITLE_DATA_TYPE_TOKEN,
        id: hash(),
    },
    {
        value: 'scalableValueTypes',
        name: EZLOGIC_TITLE_DATA_TYPE_SCALABLE,
        id: hash(),
    },
];

export const DATA_TYPES_LIST = {
    TYPE_INT: 'int',
    TYPE_STRING: 'string',
    TYPE_BOOLEAN: 'bool',
    TYPE_ACTION: 'action',
    TYPE_RGB: 'rgb',
    TYPE_TOKEN: 'token',
    TYPE_SCALABLE: 'scalableValueTypes',
    TYPE_FLOAT: 'float',
};

export const COMMON_DATA_TYPES_LIST = [
    DATA_TYPES_LIST.TYPE_BOOLEAN,
    DATA_TYPES_LIST.TYPE_INT,
    DATA_TYPES_LIST.TYPE_STRING,
    DATA_TYPES_LIST.TYPE_FLOAT,
];

export const BOOLEAN_TYPE_VALUES = [
    {
        value: 'true',
        name: 'true',
    },
    {
        value: 'false',
        name: 'false',
    },
];

export const VARIABLES_FIELDS_LABELS = {
    VARIABLE_NAME: EZLOGIC_TITLE_VARIABLE_NAME,
    VARIABLE_VALUE: EZLOGIC_TITLE_VARIABLE_VALUE,
    VARIABLE_TYPE: EZLOGIC_TITLE_VARIABLE_TYPE,
    ACTION: {
        LANGUAGE_TAG: EZLOGIC_TITLE_VARIABLE_LANGUAGE_TAG,
        TEXT: EZLOGIC_TITLE_VARIABLE_TEXT,
    },
    SCALABLE: {
        SCALABLE_TYPE: EZLOGIC_TITLE_VARIABLE_SCALE_TYPE,
        SCALE: EZLOGIC_TITLE_VARIABLE_SCALE,
    },
};

export const SCALABLE_TYPES = [
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_TEMPERATURE,
        value: 'temperature',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CELSIUS,
                scaleValue: 'celsius',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_FAHRENHEIT,
                scaleValue: 'fahrenheit',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ILLUMINANCE,
        value: 'illuminance',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_LUX,
                scaleValue: 'lux',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_PRESSURE,
        value: 'presure',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_PASCAL,
                scaleValue: 'kilo_pascal',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_POUND_PER_SQUARE_INCH,
                scaleValue: 'pound_per_square_inch',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_INCHES_OF_MERCURY,
                scaleValue: 'inches_of_mercury',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_MOISTURE,
        value: 'moisture',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_VOLUME_WATER_CONTENT,
                scaleValue: 'volume_water_content',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_IMPEDANCE,
                scaleValue: 'impedance',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_WATER_ACTIVITY,
                scaleValue: 'water_activity',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_SUBSTANCE_AMOUNT,
        value: 'substance_amount',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_GRAM_PER_CUBIC_METER,
                scaleValue: 'gram_per_cubic_meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MICRO_GRAM_PER_CUBIC_METER,
                scaleValue: 'micro_gram_per_cubic_meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MOLE_PER_CUBIC_METER,
                scaleValue: 'mole_per_cubic_meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PARTS_PER_MILLION,
                scaleValue: 'parts_per_million',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MILLI_GRAM_PER_LITER,
                scaleValue: 'milli_gram_per_liter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_POWER,
        value: 'power',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_WATT,
                scaleValue: 'watt',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_BTU_PER_HOUR,
                scaleValue: 'btu_per_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_VELOCITY,
        value: 'velocity',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SECOND,
                scaleValue: 'meter_per_second',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MILE_PER_HOUR,
                scaleValue: 'mile_per_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ACCELERATION,
        value: 'acceleration',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SQUARE_SECOND,
                scaleValue: 'meter_per_square_second',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_DIRECTION,
        value: 'direction',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_NO_DIRECTION,
                scaleValue: 'no_direction',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_EAST,
                scaleValue: 'east',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_SOUTH,
                scaleValue: 'south',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_WEST,
                scaleValue: 'west',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_NORTH,
                scaleValue: 'north',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_GENERAL_PURPOSE,
        value: 'general_purpose',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_FLOAT,
                scaleValue: 'float',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ACIDITY,
        value: 'acidity',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_POTENTIAL_OF_HYDROGEN,
                scaleValue: 'potential_of_hydrogen',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_POTENTIAL,
        value: 'electric_potential',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MILLI_VOLT,
                scaleValue: 'milli_volt',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_VOLT,
                scaleValue: 'volt',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_CURRENT,
        value: 'electric_current',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_AMPERE,
                scaleValue: 'ampere',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MILLI_AMPERE,
                scaleValue: 'milli_ampere',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_FORCE,
        value: 'force',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_NEWTON,
                scaleValue: 'newton',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_IRRADIANCE,
        value: 'irradiance',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_WATT_PER_SQUARE_METER,
                scaleValue: 'watt_per_square_meter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_PRECIPITATION,
        value: 'precipitation',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MILLI_METER_PER_HOUR,
                scaleValue: 'milli_meter_per_hour',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_INCHES_PER_HOUR,
                scaleValue: 'inches_per_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_LENGTH,
        value: 'length',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_METER,
                scaleValue: 'meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_FEET,
                scaleValue: 'feet',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CENTI_METER,
                scaleValue: 'centi_meter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_MASS,
        value: 'mass',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_GRAM,
                scaleValue: 'kilo_gram',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_POUNDS,
                scaleValue: 'pounds',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME_FLOW,
        value: 'volume_flow',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER_PER_HOUR,
                scaleValue: 'cubic_meter_per_hour',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET_PER_MINUTE,
                scaleValue: 'cubic_feet_per_minute',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_LITER_PER_HOUR,
                scaleValue: 'liter_per_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME,
        value: 'volume',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_LITER,
                scaleValue: 'liter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER,
                scaleValue: 'cubic_meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET,
                scaleValue: 'cubic_feet',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_GALLONS,
                scaleValue: 'gallons',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ANGLE,
        value: 'angle',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_NORTH_POLE_DEGREES,
                scaleValue: 'north_pole_degress',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_FREQUENCY,
        value: 'frequency',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_REVOLUTIONS_PER_MINUTE,
                scaleValue: 'revolutions_per_minute',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_HERTZ,
                scaleValue: 'hertz',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_HERTZ,
                scaleValue: 'kilo_hertz',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_BREATHS_PER_MINUTE,
                scaleValue: 'breaths_per_minute',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_BEATS_PER_MINUTE,
                scaleValue: 'beats_per_minute',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_INTENSITY,
        value: 'seismic_intensity',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MERCALLI,
                scaleValue: 'mercalli',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_EUROPEAN_MACROSEISMIC,
                scaleValue: 'european_macroseismic',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_LIEDU,
                scaleValue: 'liedu',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_SHINDO,
                scaleValue: 'shindo',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_MAGNITUDE,
        value: 'seismic_magnitude',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_LOCAL,
                scaleValue: 'local',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_MOMENT,
                scaleValue: 'moment',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_SURFACE_WAVE,
                scaleValue: 'surface_wave',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_BODY_WAVE,
                scaleValue: 'body_wave',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ULTRAVIOLET,
        value: 'ultraviolet',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_UV_INDEX,
                scaleValue: 'uv_index',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_RESISTANCE,
        value: 'electrical_resistance',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_OHM_METER,
                scaleValue: 'ohm_meter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_CONDUCTIVITY,
        value: 'electrical_conductivity',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_SIEMENS_PER_METER,
                scaleValue: 'siemens_per_meter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_LOUDNESS,
        value: 'loudness',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_DECIBEL,
                scaleValue: 'decibel',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_A_WEIGHTED_DECIBEL,
                scaleValue: 'a_weighted_decibels',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_TIME,
        value: 'time',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SECONDS,
                scaleValue: 'seconds',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_RADON_CONCENTRATION,
        value: 'radon_concentration',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_BECQUEREL_PER_CUBIC_METER,
                scaleValue: 'becquerel_per_cubic_meter',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PICOCURIES_PER_LITER,
                scaleValue: 'picocuries_per_liter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_BLOOD_PRESSURE,
        value: 'blood_pressure',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_SYSTOLIC,
                scaleValue: 'systolic',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_DIASTOLIC,
                scaleValue: 'diastolic',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_ENERGY,
        value: 'energy',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_JOULE,
                scaleValue: 'joule',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_WATT_HOUR,
                scaleValue: 'kilo_watt_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_RF_SIGNAL_STRENGTH,
        value: 'rf_signal_strength',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_DECIBEL_MILLI_WATT,
                scaleValue: 'decibel_milli_watt',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_HUMIDITY,
        value: 'humidity',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_PERCENT,
                scaleValue: 'percent',
            },
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_GRAM_PER_CUBIC_METER,
                scaleValue: 'gram_per_cubic_meter',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_KILO_VOLT_AMPERE_HOUR,
        value: 'kilo_volt_ampere_hour',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_HOUR,
                scaleValue: 'kilo_volt_ampere_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_INSTANT,
        value: 'reactive_power_instant',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE,
                scaleValue: 'kilo_volt_amper_reactive',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_AMOUNT_OF_USEFUL_ENERGY,
        value: 'amount_of_useful_energy',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_WATT_HOUR,
                scaleValue: 'kilo_watt_hour',
            },
        ],
    },
    {
        name: EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_CONSUMPTION,
        value: 'reactive_power_consumption',
        scale: [
            {
                scaleName: EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE_HOUR,
                scaleValue: 'kilo_volt_amper_reactive_hour',
            },
        ],
    },
];

export const SUCCESS_STATUS = 1;

export const ERROR_STATUS = 0;

export const FIRST_BLOCK = 1;
