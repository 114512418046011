import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * Returns 'params' that are in the query string.
 * @param {string} queryString - Query parameter string. example: '?user=15506072&code=J0F43i_if~D1Wm'
 * @returns {object} params that are the query string. example: {user: '15506072', code: 'J0F43i_if~D1Wm'}
 * */
export function parseQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    const paramsObject = {};

    for (const [key, value] of params) {
        paramsObject[key] = value;
    }

    return paramsObject;
}

/**
 * Hook that provides query parameters from the URL
 * @returns {Object} returns query params
 * */
const useQueryParams = () => {
    const location = useLocation();

    return useMemo(() => {
        return parseQueryString(location.search);
    }, [location.search]);
};

export default useQueryParams;
