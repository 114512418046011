import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';

import InfoButton from '../../containers/Ezlo/EzloMeshbot/components/InfoButton';
import InputNumberBlock from '../inputNumber';
import SelectValueToCompareType from '../SelectValueToCompareType';
import SelectExpressionValue from '../SelectExpressionValue';
import { InputMaterial } from '../index';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { getExpressionsSelector } from '../../containers/Ezlo/EzloExpressions/utils';
import {
    BLOCK_FIELD_TYPES,
    COMPARISON_DATA,
    stepNumberInput,
    MIN_NUMBER_INPUT_VALUE,
    ZERO_INT,
} from '../../constants/MeshbotConstant';

import styles from './styles.module.scss';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_TYPE_A_VALUE } from '../../constants/language_tokens';

const SelectValueToCompare = ({
    onDataUpdate,
    capabilityValueType,
    comparingValue,
    compareTo,
    isCompareToVariable,
    selectedComparator,
    deviceId,
}) => {
    const { VALUE, EXPRESSION, VARIABLE } = BLOCK_FIELD_TYPES;
    const comparisonMethod = selectedComparator?.method;
    let compareToInitialValue = compareTo;
    let selectedExpressionInitValue = {};
    const { t } = useTranslate();
    const expressionsList = useSelector(getExpressionsSelector);

    if (compareToInitialValue === EXPRESSION) {
        selectedExpressionInitValue = expressionsList.find((expression) => expression.name === comparingValue);
        if (selectedExpressionInitValue?.metadata?.dataType === VARIABLE || isCompareToVariable) {
            compareToInitialValue = VARIABLE;
        }
    }

    const valueToCompareInit =
        comparisonMethod === COMPARISON_DATA.METHOD.COMPARE_NUMBER ||
        selectedComparator?.op === COMPARISON_DATA.OPERATION.LENGTH ||
        selectedComparator?.op === COMPARISON_DATA.OPERATION.NOT_LENGTH
            ? 0
            : '';

    const showValueSelect =
        comparisonMethod !== COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE &&
        comparisonMethod !== COMPARISON_DATA.METHOD.STRING_OPERATION &&
        deviceId;
    const showInputNumberBlock =
        (comparisonMethod === COMPARISON_DATA.METHOD.COMPARE_NUMBER ||
            selectedComparator?.op === COMPARISON_DATA.OPERATION.LENGTH ||
            selectedComparator?.op === COMPARISON_DATA.OPERATION.NOT_LENGTH) &&
        compareToInitialValue === VALUE;
    const showInputNumberRangeBlock =
        comparisonMethod === COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE && compareToInitialValue === VALUE;
    const showInputString =
        (comparisonMethod === COMPARISON_DATA.METHOD.COMPARE_STRINGS ||
            (comparisonMethod === COMPARISON_DATA.METHOD.STRING_OPERATION &&
                selectedComparator?.op !== COMPARISON_DATA.OPERATION.LENGTH &&
                selectedComparator?.op !== COMPARISON_DATA.OPERATION.NOT_LENGTH)) &&
        compareToInitialValue === VALUE;
    const showExpressionSelect = compareToInitialValue === EXPRESSION || compareToInitialValue === VARIABLE;

    const handleChangeValueSelect = (event) => {
        const isCompareToValue = event.target.value === VALUE;

        onDataUpdate({
            compareTo: isCompareToValue ? VALUE : EXPRESSION,
            comparingValue: isCompareToValue ? valueToCompareInit : null,
            isCompareToVariable: event.target.value === VARIABLE,
        });
    };

    const handleChangeSingleValueToCompare = (event) => {
        if (!selectedComparator) {
            return;
        }
        let value = null;

        if (
            selectedComparator?.op === COMPARISON_DATA.OPERATION.LENGTH ||
            selectedComparator?.op === COMPARISON_DATA.OPERATION.NOT_LENGTH
        ) {
            value = event.target.value && Number(event.target.value);
        } else {
            value = String(event.target.value);
        }

        onDataUpdate({ comparingValue: value });
    };

    const handleChangeValueToCompareStart = (event) => {
        onDataUpdate({
            comparingValue: { start: event.target.value && +event.target.value, end: comparingValue?.end || 0 },
        });
    };

    const handleChangeValueToCompareEnd = (event) => {
        onDataUpdate({
            comparingValue: { start: comparingValue?.start || 0, end: event.target.value && +event.target.value },
        });
    };

    const handleSelectExpression = (event) => {
        onDataUpdate({
            comparingValue: event.target.value,
        });
    };

    useEffect(() => {
        if (
            comparisonMethod === COMPARISON_DATA.METHOD.COMPARE_NUMBER_RANGE
            // || comparisonMethod === COMPARISON_DATA.METHOD.STRING_OPERATION //  Why do we need it because this line breaks edit validation for cloud variables trigger
        ) {
            onDataUpdate({ compareTo: VALUE });
        }
    }, [comparisonMethod]);

    return (
        <>
            {showValueSelect && (
                <SelectValueToCompareType
                    onChangeValueToCompareType={handleChangeValueSelect}
                    fieldValue={compareToInitialValue}
                />
            )}
            {showInputNumberBlock && (
                <InputNumberBlock
                    min={MIN_NUMBER_INPUT_VALUE}
                    value={comparingValue || ZERO_INT}
                    step={stepNumberInput[capabilityValueType]}
                    label={info.hints.value}
                    className={styles.inputNumber}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <InfoButton>
                                    <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                </InfoButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChangeSingleValueToCompare}
                    small
                />
            )}
            {showInputNumberRangeBlock && (
                <>
                    <InputNumberBlock
                        min={MIN_NUMBER_INPUT_VALUE}
                        value={(comparingValue?.start && String(comparingValue?.start)) || ZERO_INT}
                        step={stepNumberInput[capabilityValueType]}
                        label={info.hints.valueStart}
                        className={styles.inputNumber}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.valueStart) }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangeValueToCompareStart}
                        small
                    />
                    <InputNumberBlock
                        min={MIN_NUMBER_INPUT_VALUE}
                        value={(comparingValue?.end && String(comparingValue?.end)) || ZERO_INT}
                        step={stepNumberInput[capabilityValueType]}
                        label={info.hints.valueEnd}
                        className={styles.inputNumber}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.valueEnd) }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangeValueToCompareEnd}
                        small
                    />
                </>
            )}
            {showInputString && (
                <InputMaterial
                    className={styles.inputString}
                    label={t(info.hints.value)}
                    placeholder={t(EZLOGIC_TITLE_TYPE_A_VALUE)}
                    onChange={handleChangeSingleValueToCompare}
                    value={comparingValue || valueToCompareInit}
                />
            )}
            {showExpressionSelect && (
                <SelectExpressionValue
                    expressionType={compareToInitialValue}
                    selectedExpression={comparingValue}
                    onSelectExpression={handleSelectExpression}
                    capabilityValueType={capabilityValueType}
                />
            )}
        </>
    );
};

export default SelectValueToCompare;
