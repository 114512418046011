import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthorizationContext';
import useValidateEmail from '../../hooks/useValidateEmail';
import { CODE_GET_PARAM_NAME, USER_GET_PARAM_NAME } from '../../../constants/getParams';
import toastsActions from '../../../../../actions/toastsActions';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS } from '../../../../../constants/toastConstants';
import AnimatedSpinner from '../../../../../components/AnimatedSpinner';

const ValidateEmailFormContainer = () => {
    useAuthContext();
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const [data, error, loading, setPayload] = useValidateEmail();

    useEffect(() => {
        const query = new URLSearchParams(search);
        const code = query.get(CODE_GET_PARAM_NAME);
        const user = query.get(USER_GET_PARAM_NAME);
        (async () => setPayload({ pk_user: Number(user), code }))();
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(
                toastsActions.showToast({
                    message: error,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                }),
            );
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            history.replace({ pathname: '/verify/success', state: { isActive: true } });
        }
    }, [data]);

    return loading && <AnimatedSpinner />;
};

export default ValidateEmailFormContainer;
