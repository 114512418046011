import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import { PUBLISH, PRIVATE } from '../../../../../constants/Plugins';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_SELECT_FILE,
    EZLOGIC_BUTTON_UPLOAD_MARKETPlACE,
    EZLOGIC_BUTTON_UPLOAD_MY_ACCOUNT,
    EZLOGIC_HEADING_UPDATE_PLUGIN,
    EZLOGIC_PLACEHOLDER_FILE_NAME,
    EZLOGIC_TITLE_FILE_NAME,
    EZLOGIC_TITLE_FIlE_TOO_LARGE,
    EZLOGIC_TITLE_NAME_TOO_LARGE_END,
    EZLOGIC_TITLE_NAME_TOO_LARGE_START,
} from '../../../../../constants/language_tokens';
import { isUploadToMarketPlaceAllowed } from './utils';

const UpdatePlugin = (props) => {
    const [fileValue, setFileValue] = useState(null);
    const { setStatusPage, PluginActions, onHandlerModalType, onCancel } = props;
    const { t } = useTranslate();

    const nameLengthValidator = (file) => {
        const maxLength = 255;
        if (file.name.length > maxLength) {
            return {
                code: 'name-too-large',
                message: `${t(EZLOGIC_TITLE_NAME_TOO_LARGE_START)}${maxLength}${t(EZLOGIC_TITLE_NAME_TOO_LARGE_END)}`,
            };
        }

        if (file.size > 1000000) {
            return {
                code: 'file-too-large',
                message: t(EZLOGIC_TITLE_FIlE_TOO_LARGE),
            };
        }

        return null;
    };

    const onDrop = useCallback((file) => {
        setFileValue(file[0]);
    }, []);

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        validator: nameLengthValidator,
        minSize: 100,
        maxFiles: 1,
        accept: ['.gz', '.tgz'],
        onDrop: onDrop,
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            <span>
                {file.path} / {(file.size / 1000 / 1000).toFixed(2)} MB -{' '}
            </span>
            <span className="modal-plugin__errors-item">{errors[0].message}</span>
        </li>
    ));

    const handlerUpload = (type) => {
        onCancel();
        PluginActions.uploadPlugin(fileValue, t);
        onHandlerModalType('');
        setStatusPage(type);
    };

    // TODO: temporary solution, must be removed after prefix implementation on Cloud side!!!
    const isAndriiPolonsky = isUploadToMarketPlaceAllowed();

    return (
        <div className="modal-plugin__update">
            <h3>{t(EZLOGIC_HEADING_UPDATE_PLUGIN)}</h3>
            {/*<section>*/}
            {/*    <div className="modal-plugin__update-field">*/}
            {/*        <span className="modal-plugin__update-label">Plugin Name</span>*/}
            {/*        <TextField placeholder="Add name" variant="outlined" />*/}
            {/*    </div>*/}
            {/*    <div className="modal-plugin__update-field">*/}
            {/*        <span className="modal-plugin__update-label">Category</span>*/}
            {/*        <TextField placeholder="Add category" variant="outlined" />*/}
            {/*    </div>*/}
            {/*    <div className="modal-plugin__update-field">*/}
            {/*        <span className="modal-plugin__update-label">Version</span>*/}
            {/*        <TextField placeholder="Add version" variant="outlined" />*/}
            {/*    </div>*/}
            {/*    <div className="modal-plugin__update-field">*/}
            {/*        <span className="modal-plugin__update-label">Description</span>*/}
            {/*        <TextField placeholder="Add description" multiline variant="outlined"/>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <div className="modal-plugin__group modal-plugin__dropZone">
                <p className="modal-plugin__dropZone-label">
                    {t(EZLOGIC_TITLE_FILE_NAME)}
                    <span>{fileValue && fileValue.name ? fileValue.name : t(EZLOGIC_PLACEHOLDER_FILE_NAME)}</span>
                </p>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button variant="contained" color="primary" size="medium">
                        {t(EZLOGIC_BUTTON_SELECT_FILE)}
                    </Button>
                </div>
            </div>
            <ul className="modal-plugin__errors">{fileRejectionItems}</ul>
            <div className="modal-plugin__update-buttons">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlerUpload(PRIVATE)}
                    disabled={!fileValue}
                >
                    {t(EZLOGIC_BUTTON_UPLOAD_MY_ACCOUNT)}
                </Button>
                {isAndriiPolonsky && (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!fileValue}
                        onClick={() => handlerUpload(PUBLISH)}
                    >
                        {t(EZLOGIC_BUTTON_UPLOAD_MARKETPlACE)}
                    </Button>
                )}
            </div>
        </div>
    );
};

UpdatePlugin.propTypes = {
    PluginActions: PropTypes.object,
    onHandlerModalType: PropTypes.func,
    onCancel: PropTypes.func,
};

export default UpdatePlugin;
