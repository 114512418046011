import React from 'react';
import DeleteImagesButton from './DeleteImagesButton';
import { useMediaActions } from '../../hooks/useMediaActions';
import { useTranslate } from '../../../languages';
import ConfirmDialog from '../../../../containers/Ezlo/EzloAccount/components/ConfirmDialog';
import {
    EZLOGIC_TITLE_ARE_YOU_SURE_TO_WANT_DELETED_TO_SELECTED_IMAGES,
    EZLOGIC_TITLE_DELETE,
} from '../../../../constants/language_tokens';
import useDialogState from '../../../../hooks/useDialogState/useDialogState';
import useGalleryContext from '../../hooks/useGalleryContext';

const DeleteImagesButtonContainer = () => {
    const { selectedImgUuids, clearSelectedImgList, isSelectedImg } = useGalleryContext();
    const { deleteImages } = useMediaActions();
    const { toggleOpenDialog, open } = useDialogState();
    const { t } = useTranslate();

    if (!isSelectedImg) {
        return null;
    }

    const handlerDeleteImg = async () => {
        toggleOpenDialog();
        await deleteImages(selectedImgUuids);
        await clearSelectedImgList();
    };

    return (
        <>
            <DeleteImagesButton onClickButton={toggleOpenDialog} />
            <ConfirmDialog
                confirmFn={handlerDeleteImg}
                handleClose={toggleOpenDialog}
                dialogText={t(EZLOGIC_TITLE_ARE_YOU_SURE_TO_WANT_DELETED_TO_SELECTED_IMAGES)}
                confirmButtonText={t(EZLOGIC_TITLE_DELETE)}
                isModalVisible={open}
            />
        </>
    );
};

export default DeleteImagesButtonContainer;
