import React from 'react';

import { Header } from '../../../../components';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_SELECT_CUSTOMIZATION } from '../constants';
import { isPageValue } from '../../utils';
import CustomizationsTabs from '../CustomizationsTabs';

import classes from './customizationLayout.module.scss';

const CustomizationLayout = () => {
    const isPage = isPageValue();
    const { t } = useTranslate();

    return (
        <>
            {isPage === false && <Header />}
            <section className={classes.customizationWrapper}>
                <header className={classes.listHeader}>
                    <h1 className={classes.listHeaderHeading}>{t(EZLOGIC_TITLE_SELECT_CUSTOMIZATION)}</h1>
                </header>
                <CustomizationsTabs />
            </section>
        </>
    );
};

export default CustomizationLayout;
