import {
    uploadImageApi,
    getImagesFromCloud,
    deleteFileFromCloud,
    buildDownloadImgLink,
} from '../../../services/mediaStore';
import GenericActions from '../../../actions/GenericActions';
import { unshiftImages, setImages, toggleUploadingImages, setIsEndPage } from '../reducer/media';
import { buildImagesList, buildImgFormData, buildNewImageInUiFormat } from '../utils';
import { bugsnagNotify } from '../../../containers/ErrorBoundary/utils';
import { toast, TOAST_TYPE } from '../../../components/Toast';
import { isArray, isEmpty } from 'lodash';
import {
    EZLOGIC_TITLE_IMAGE_HAS_BEEN_SUCCESSFULLY_UPLOADED,
    EZLOGIC_TITLE_IMAGES_HAVE_BEEN_SUCCESSFULLY_DELETED,
    EZLOGIC_TITLE_SOMETHING_WENT_WRONG,
} from '../../../constants/language_tokens';
import { SUCCESS_API_STATUS, SUCCESS_API_STATUS_CODE } from '../../../services/mediaStore/src/constants/network';
import { t } from '../../../helpers/language';

/**
 * Async function, the responsibility of which is to implement the cycle of upload one img with the processing of the result;
 * @param {Object} multipartFormData - data of image that we need to upload in the FormData format
 * @param {{file: File}} - Additional parameters, the file for upload
 * @returns {Promise<Object>} Upload image data
 * @example
 * uploadImage({name: 'logo.png', ...other}, {{name: 'logo.png'}})
 */
export const uploadImage = async (multipartFormData, { file }) => {
    try {
        const response = await uploadImageApi(multipartFormData, file);

        if (response.data.file && response.status === SUCCESS_API_STATUS_CODE) {
            toast(`${file.name} ${t(EZLOGIC_TITLE_IMAGE_HAS_BEEN_SUCCESSFULLY_UPLOADED)}`, {
                type: TOAST_TYPE.SUCCESS,
            });
        }

        if (response.data.file) {
            return buildNewImageInUiFormat(response.data.file, file);
        }
    } catch (error) {
        const errorMessage = error.message || error.toString();
        bugsnagNotify(error, { message: 'Error in uploadImage fn' });
        toast(errorMessage, { type: TOAST_TYPE.ERROR });
    }
};

/**
 * Build batch of async functions, that implement the cycle of upload a lot of images with the processing of the result;
 * @param {File[]} imagesData - data of images that we need to upload
 * @returns {Promise<Object>[]} uploaded images
 * @example
 * buildBatchOfUploadingImgRequests({name: 'logo.png', ...other})
 */
export const buildBatchOfUploadingImgRequests = (imagesData) => {
    return imagesData.map((imageFile) => {
        const multipartFormData = buildImgFormData(imageFile);

        return uploadImage(multipartFormData, { file: imageFile });
    });
};

/**
 * Thunk creator, the responsibility of which is to implement the cycle of upload a lot of images with the processing of the result;
 * @param {File[]} imagesData - data of images that we need to upload
 * @returns {function} thunk function
 * @example
 * uploadImages([{name: 'logo.png', ...other}])
 */
export const uploadImages = (imagesData) => async (dispatch) => {
    dispatch(toggleUploadingImages());

    const uploadImageRequestList = buildBatchOfUploadingImgRequests(imagesData);
    const responseList = await Promise.all(uploadImageRequestList);
    await dispatch(unshiftImages(buildImagesList(responseList)));

    await dispatch(toggleUploadingImages());
};

/**
 * Thunk creator, the responsibility of which is to implement the cycle of upload a image with the processing of the result and return uploaded img link;
 * @param {File} imagesData - data of images that we need to upload
 * @returns {function} thunk function
 * @example
 * uploadImageAndGetImgLink({name: 'logo.png', ...other})
 */
const uploadImageAndGetImgLink = (imagesData) => async (dispatch) => {
    dispatch(toggleUploadingImages());

    const multipartFormData = buildImgFormData(imagesData);
    const response = await uploadImage(multipartFormData, { file: imagesData });

    await dispatch(toggleUploadingImages());

    return buildDownloadImgLink(response);
};

/**
 * Thunk creator, the responsibility of which is to implement the cycle of fetch images with the processing of the result;
 * @param {Object} filters - cloud filters for fetch images
 * @returns {function} thunk function
 * @example
 * fetchImages(['1', '2'])
 */
const fetchImages = (filters) => async (dispatch) => {
    dispatch(GenericActions.setLineLoading(true));

    const response = await getImagesFromCloud(filters);

    if (response?.status === 0) {
        const error = response.data?.error_message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
        toast(error, { type: TOAST_TYPE.ERROR });
    }
    const imgList = response.data?.list;

    if (isArray(imgList)) {
        dispatch(setImages(buildImagesList(imgList)));
    }

    dispatch(setIsEndPage(isEmpty(imgList)));
    await dispatch(GenericActions.setLineLoading(false));

    return { isSuccessfulRequest: response?.status === SUCCESS_API_STATUS };
};
/**
 * Thunk creator, the responsibility of this thunk is to clear redux store from deleted photos
 * @param {string[]} removedImgUuidList - list of image uuids for clearing from ui store
 * @returns {function} thunk function
 * @example
 * clearUiStoreFromRemovedImg(['1', '2'])
 */
const clearUiStoreFromRemovedImg = (removedImgUuidList) => (dispatch, getState) => {
    const { imagesList } = getState().mediaStore;
    const updatedList = imagesList.filter((imgData) => !removedImgUuidList.includes(imgData.uuid));
    dispatch(setImages(updatedList));
};

/**
 * Thunk creator, the responsibility of which is to implement the cycle of deleting pictures with the processing of the result;
 * @param {string[]} removedImgUuidList - list of image uuids for deleting
 * @returns {Promise<undefined>}
 * @example
 * deleteImages(['1', '2'])
 */
const deleteImages = (removedImgUuidList) => async (dispatch) => {
    try {
        dispatch(GenericActions.setLineLoading(true));

        const response = await deleteFileFromCloud(removedImgUuidList);

        if (response.status === SUCCESS_API_STATUS_CODE && response.data.status === SUCCESS_API_STATUS) {
            toast(t(EZLOGIC_TITLE_IMAGES_HAVE_BEEN_SUCCESSFULLY_DELETED), { type: TOAST_TYPE.SUCCESS });
        } else {
            toast(t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG), { type: TOAST_TYPE.ERROR });
        }

        return await dispatch(clearUiStoreFromRemovedImg(removedImgUuidList));
    } catch (error) {
        bugsnagNotify(error, { message: 'Error in deleteImage fn', removedImgUuidList: removedImgUuidList });
        toast(error.message || error.toString(), { type: TOAST_TYPE.ERROR });
    } finally {
        dispatch(GenericActions.setLineLoading(false));
    }
};

export default {
    fetchImages,
    uploadImages,
    deleteImages,
    uploadImageAndGetImgLink,
};
