import React from 'react';
import PropTypes from 'prop-types';
import InputDefault from '../configureInputTempaltes/InputDefault';
import InputBoolean from '../configureInputTempaltes/InputBoolean';

const ConfigureFormField = (props) => {
    const { input, inputs, error, onSetInputsValue, isIntegration } = props;
    const { type } = input;

    const getInputTemplate = (typeInput) => {
        switch (typeInput) {
            case 'string':
                return (
                    <InputDefault
                        input={input}
                        error={error}
                        inputs={inputs}
                        onSetInputsValue={onSetInputsValue}
                        isIntegration={isIntegration}
                    />
                );
            case 'int':
            case 'float':
                return (
                    <InputDefault
                        input={input}
                        error={error}
                        inputs={inputs}
                        onSetInputsValue={onSetInputsValue}
                        isIntegration={isIntegration}
                    />
                );
            case 'boolean':
                return (
                    <InputBoolean
                        input={input}
                        error={error}
                        inputs={inputs}
                        onSetInputsValue={onSetInputsValue}
                        isIntegration={isIntegration}
                    />
                );

            default:
                return null;
        }
    };

    return getInputTemplate(type);
};

ConfigureFormField.propTypes = {
    input: PropTypes.object,
    inputs: PropTypes.object,
    onSetInputsValue: PropTypes.func,
};
export default ConfigureFormField;
