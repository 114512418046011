import React from 'react';
// import {object} from 'prop-types';
import './style.scss';
import { Button, Checkbox } from '../../../../../components';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';

export default class DeviceSettingToken extends React.Component {
    // static propTypes = {
    //     settings: object.isRequired,
    //     device: object,
    // };

    state = {
        value: this.props.settings.value || [],
        dataList: [],
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    componentDidMount() {
        this.getEnumList();
    }

    getEnumList = () => {
        const { settings } = this.props;
        const { enum: enumList } = settings;
        const listOfValues = [];

        Object.keys(enumList).map((item) => {
            listOfValues.push({
                value: item,
                text: enumList[item].text,
            });
        });

        if (listOfValues.length) {
            this.setState({ dataList: listOfValues });
        }
    };

    restoreHandler = () => {
        const iniValue = this.props.settings.value || [];
        this.setState({ value: iniValue });
    };

    saveHandler = () => {
        const { onSave, settings } = this.props;
        const { value } = this.state;

        if (settings._id) {
            onSave(settings._id, value);
        }
    };

    toggleCheck = (itemId) => {
        const { value } = this.state;
        const itemToCheck = value.find((id) => id === itemId);

        const updatedItems = itemToCheck ? value.filter((id) => id !== itemToCheck) : value.concat(itemId);

        this.setState({ value: updatedItems });
    };

    render() {
        const { settings, titles } = this.props;
        const { value, dataList } = this.state;
        const { description, status, info, valueDefault } = settings;
        const paramId = info && info['zwave.parameter_number'];

        return (
            <div key={paramId} className="device-alarm__wrapper">
                {status && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.status}</h5>
                            <span className="device-alarm__status">{status}</span>
                        </div>
                        <DeviceSettingInfo />
                    </div>
                )}

                {paramId && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.parameterId}</h5>
                            <span className="device-alarm__status">{paramId}</span>
                        </div>
                    </div>
                )}

                {description && <p className="device-alarm__text">{description.text}</p>}

                <ul className="device-range-duration__box">
                    {dataList.map((item) => {
                        const isChecked = value.includes(item.value);

                        return (
                            <li key={item.text} className="device-range-duration__set">
                                <label onClick={() => this.toggleCheck(item.value)}>{item.text}</label>

                                <Checkbox checked={isChecked} onChange={() => this.toggleCheck(item.value)}></Checkbox>
                            </li>
                        );
                    })}
                </ul>

                <div className="device-range-footer">
                    {valueDefault && (
                        <div className="device-range-restore">
                            <Button className="btn-basic small device-range__button" onClick={this.restoreHandler}>
                                {titles.restore}
                            </Button>
                            <span>{'Set parameter to the default value'}</span>
                        </div>
                    )}

                    <Button className="btn-rounded device-range__button-save" onClick={this.saveHandler}>
                        {titles.save}
                    </Button>
                </div>
            </div>
        );
    }
}
