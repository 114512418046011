import { MESHBOT_SECTION_TYPE } from '../../constants';
import { EZLOGIC_TITLE_ACTION, EZLOGIC_TITLE_TRIGGER } from '../../../../../constants/language_tokens';

export const getMeshBotSectionTitleByType = (type) => {
    if (typeof type !== 'string') {
        throw new Error('Wrong params type');
    }

    switch (type) {
        case MESHBOT_SECTION_TYPE.TRIGGER:
            return EZLOGIC_TITLE_TRIGGER;
        case MESHBOT_SECTION_TYPE.ACTION:
            return EZLOGIC_TITLE_ACTION;
        default:
            throw new Error('Unknown MeshBot type');
    }
};
