import * as types from '../constants/ActionTypes';
import * as localStorageKeys from '../constants/localStorageKeys';
import {
    apiGetCustomizationData,
    apiGetCustomizationDataForDomain,
    apiGetCustomizationForUser,
    getPartnerUuidFromTLD,
} from '../api/apiScenes';
import { ENCODED_EMPTY_OBJECT, VERA_USER, ZERO } from '../containers/Ezlo/EzloCustomization/constants';
import {
    getCustomizationPayload,
    getUpdatedColorCustomizationPayload,
    parseData,
    setUpdatedColors,
} from '../containers/Ezlo/EzloCustomization/utils';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import {
    getDomainForIncorrectUuidFormat,
    getPartnerUuidFromDomain,
    getRootDomain,
    setTLD,
} from '../containers/Ezlo/utils';
import EzloCustomization from '../actions/CustomizationActions';
import { DOMAIN_PARTNER_UUID } from '../constants/localStorageKeys';
import { EZLOGIC_KNOWN_DOMAINS } from '../constants/URLs';
import { checkVeraUser } from '../containers/Navigation/utils';
import { PRIMARY_COLOR } from '../containers/cutomThemeColors';

const actions = {
    getCustomization: () => async (dispatch, getState) => {
        const state = getState();
        const { partnerUuid } = state.supportData;

        const customizationData = await apiGetCustomizationData(partnerUuid);
        const customizedData = customizationData?.data?.data?.list;
        if (customizedData?.length > ZERO) {
            const getCustomizationData = customizedData[ZERO]?.value;
            const decodedCustomizationData = atob(getCustomizationData);
            const getParsedCustomizationData = parseData(decodedCustomizationData);
            if (getParsedCustomizationData) {
                dispatch(actions.updateCustomization(getParsedCustomizationData));
                const { ezlogicPortal, ...kvsPayload } = getParsedCustomizationData;
                const themeColor = kvsPayload?.colors?.paletteColor?.hex
                    ? kvsPayload?.colors?.paletteColor?.hex
                    : PRIMARY_COLOR;
                dispatch(EzloCustomization.primaryColorAction(themeColor));
                const updatedInitialCustomizationPayload = getUpdatedColorCustomizationPayload(
                    ezlogicPortal?.colorTheme,
                    themeColor,
                );
                const initialCustomizationPayload = getCustomizationPayload(
                    ezlogicPortal?.menuBar,
                    ezlogicPortal?.listUi,
                    ezlogicPortal?.meshbot,
                    updatedInitialCustomizationPayload,
                );
                const isVeraUser = checkVeraUser();
                dispatch(EzloCustomization.isVeraUser(isVeraUser));
                dispatch(EzloCustomization.currentCustomization(initialCustomizationPayload));
                dispatch(EzloCustomization.setInitialCustomization(ezlogicPortal, kvsPayload, themeColor));
                setUpdatedColors(getParsedCustomizationData?.ezlogicPortal?.colorTheme);
            }
        }
    },

    getCustomizationForUser: () => async (dispatch, getState) => {
        try {
            const state = getState();
            const { partnerUuid } = state.supportData;

            if (partnerUuid?.length === ZERO) {
                localStorage.setItem(localStorageKeys.USER_TYPE, VERA_USER);
                const isVeraUser = checkVeraUser();
                dispatch(EzloCustomization.isVeraUser(isVeraUser));
                const customizationData = await apiGetCustomizationForUser();
                const customizedData = customizationData?.data?.data?.list;
                dispatch(actions.getCustomizationData(customizedData));
            }
        } catch (error) {
            bugsnagNotify(error);
        }
    },

    getPartnerUuidFromTopLevelDomain: (hostname) => async (dispatch) => {
        try {
            const partnerDomainToPartnerUuidResponse = await getPartnerUuidFromTLD(hostname);
            dispatch(
                actions.updatePartnerDomainToPartnerUuidApiStatus(partnerDomainToPartnerUuidResponse?.data?.status),
            );
            const partnerUuid = partnerDomainToPartnerUuidResponse?.data?.data?.partner_uuid;
            if (partnerUuid?.length) {
                localStorage.setItem(DOMAIN_PARTNER_UUID, partnerUuid);
                dispatch(actions.getCustomizationDataForDomain(partnerUuid));
            }
        } catch (error) {
            bugsnagNotify(error);
        }
    },

    getCustomizationData: (customizedData) => async (dispatch) => {
        const getCustomizationData = customizedData[ZERO]?.value;
        const decodedCustomizationData = atob(getCustomizationData ? getCustomizationData : ENCODED_EMPTY_OBJECT);
        const getParsedCustomizationData = parseData(decodedCustomizationData);
        dispatch(actions.getParsedCustomizationData(getParsedCustomizationData));
    },

    getParsedCustomizationData: (getParsedCustomizationData) => async (dispatch) => {
        if (getParsedCustomizationData) {
            const { ezlogicPortal, ...kvsPayload } = getParsedCustomizationData;
            const initialCustomizationPayload = getCustomizationPayload(
                ezlogicPortal?.menuBar,
                ezlogicPortal?.listUi,
                ezlogicPortal?.meshbot,
                ezlogicPortal?.nodesControllables,
                ezlogicPortal?.colorTheme,
            );
            dispatch(EzloCustomization.currentCustomization(initialCustomizationPayload));
            setUpdatedColors(ezlogicPortal?.colorTheme);
            dispatch(actions.kvsPayloadAction(kvsPayload));
            dispatch(actions.updateCustomization(getParsedCustomizationData));
            dispatch(EzloCustomization.setDefaultCustomization());
            dispatch(EzloCustomization.setInitialCustomization(ezlogicPortal, kvsPayload));
            dispatch(EzloCustomization.setDefaultLogoCustomization(kvsPayload));
        }
    },

    getCustomizationDataForDomain: (partnerUuid) => async (dispatch) => {
        const customizationData = await apiGetCustomizationDataForDomain(partnerUuid);
        dispatch(actions.getCustomizationStatus(customizationData?.data?.status));
        const customizedData = customizationData?.data?.data?.list;
        if (customizedData?.length > ZERO) {
            const getCustomizationData = customizedData[ZERO]?.value;
            const decodedCustomizationData = atob(getCustomizationData ? getCustomizationData : ENCODED_EMPTY_OBJECT);
            const getParsedCustomizationData = parseData(decodedCustomizationData);
            if (getParsedCustomizationData) {
                const { ezlogicPortal, ...kvsPayload } = getParsedCustomizationData;
                const themeColor = kvsPayload?.colors?.themeColor
                    ? kvsPayload?.colors?.paletteColor?.hex
                    : PRIMARY_COLOR;
                const updatedInitialColorCustomizationPayload = getUpdatedColorCustomizationPayload(
                    ezlogicPortal?.colorTheme,
                    themeColor,
                );
                const initialCustomizationPayload = getCustomizationPayload(
                    ezlogicPortal?.menuBar,
                    ezlogicPortal?.listUi,
                    ezlogicPortal?.meshbot,
                    ezlogicPortal?.nodesControllables,
                    updatedInitialColorCustomizationPayload,
                );
                dispatch(EzloCustomization.currentCustomization(initialCustomizationPayload));
                dispatch(EzloCustomization.setInitialCustomization(ezlogicPortal, kvsPayload, themeColor));
                dispatch(actions.updateCustomization(getParsedCustomizationData));
            }
        }
    },

    uuidDomainCustomization: (hostname, protocol) => (dispatch) => {
        const partnerUuid = getPartnerUuidFromDomain(hostname);

        if (partnerUuid?.length) {
            localStorage.setItem(DOMAIN_PARTNER_UUID, partnerUuid);
            dispatch(actions.getCustomizationDataForDomain(partnerUuid));
        }

        if (partnerUuid === null) {
            const rootDomain = getRootDomain(hostname);
            const redirectDomain = getDomainForIncorrectUuidFormat(rootDomain, protocol);
            window?.location?.replace(redirectDomain);
        }
    },

    topLevelDomainCustomization: (hostname) => (dispatch) => {
        const domain = EZLOGIC_KNOWN_DOMAINS[hostname];
        if (domain === undefined) {
            setTLD();
            dispatch(actions.getPartnerUuidFromTopLevelDomain(hostname));
        }
    },

    //----------------------------------------- REDUX ACTIONS SECTION -----------------------------------------------//

    updateCustomization: (data) => ({
        type: types.UPDATE_EZLO_CUSTOMIZATION,
        data,
    }),

    setDefaultStateToUpdatedCustomization: (data) => ({
        type: types.SET_DEFAULT_CUSTOMIZATION_TO_UPDATE_CUSTOMIZATION,
        data,
    }),

    getCustomizationStatus: (data) => ({
        type: types.CUSTOMIZATION_API_STATUS,
        data,
    }),

    updatePartnerDomainToPartnerUuidApiStatus: (data) => ({
        type: types.PARTNER_DOMAIN_TO_PARTNER_UUID_API_STATUS,
        data,
    }),

    listingUiAction: (data) => ({
        type: types.LISTING_UI_ACTION,
        data,
    }),

    meshbotUiAction: (data) => ({
        type: types.MESHBOT_UI_ACTION,
        data,
    }),

    defaultColorTheme: (data) => ({
        type: types.DEFAULT_COLOR_THEME,
        data,
    }),

    kvsPayloadAction: (data) => ({
        type: types.KVS_PAYLOAD_ACTION,
        data,
    }),

    menuUiAction: (data) => ({
        type: types.MENU_UI_ACTION,
        data,
    }),

    initialCustomization: (data) => ({
        type: types.INITIAL_CUSTOMIZATION,
        data,
    }),

    defaultCustomization: (data) => ({
        type: types.DEFAULT_CUSTOMIZATION,
        data,
    }),
};

export default actions;
