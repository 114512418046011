export const devicesCapabilitiesList = [
    { name: 'acceleration_x_axis', valueType: 'acceleration' },
    { name: 'acceleration_y_axis', valueType: 'acceleration' },
    { name: 'acceleration_z_axis', valueType: 'acceleration' },
    {
        name: 'appliance_status',
        valueType: 'token',
        enum: [
            'idle',
            'supplying_water',
            'boiling',
            'washing',
            'rinsing',
            'draining',
            'spinning',
            'drying',
            'in_progress',
            'unknown',
        ],
    },
    {
        name: 'lock_operation',
        valueType: 'token',
        enum: [
            'manual_lock_operation',
            'manual_unlock_operation',
            'rf_lock_operation',
            'rf_unlock_operation',
            'keypad_lock_operation',
            'keypad_unlock_operation',
            'manual_not_fully_locked_operation',
            'rf_not_fully_locked_operation',
            'auto_lock_not_fully_locked_operation',
            'unlock_by_rf_with_invalid_user_code',
            'lock_by_rf_with_invalid_user_code',
            'lock_jammed',
            'unknown',
        ],
    },
    {
        name: 'user_code_operation',
        valueType: 'token',
        enum: [
            'no_operation',
            'all_user_codes_deleted',
            'single_user_code_deleted',
            'new_user_code_added',
            'new_user_code_not_added_due_to_duplicated_code',
            'new_program_code_entered',
            'manually_enter_user_code_exceeds_code_limit',
        ],
    },
    { name: 'dw_handle_state', valueType: 'token', enum: ['dw_handle_is_opened', 'dw_handle_is_closed', 'unknown'] },
    { name: 'dw_state', valueType: 'token', enum: ['dw_is_opened', 'dw_is_closed', 'unknown'] },
    { name: 'tilt', valueType: 'token', enum: ['no_tilt', 'tilt', 'unknown'] },
    { name: 'latch_state', valueType: 'token', enum: ['latch_open', 'latch_closed', 'unknown'] },
    {
        name: 'keypad_state',
        valueType: 'token',
        enum: ['keypad_idle', 'keypad_busy', 'keypad_temporary_disabled', 'unknown'],
    },
    { name: 'emergency_state', valueType: 'token', enum: ['panic', 'emergency', 'emergency_and_panic', 'idle'] },
    {
        name: 'barrier_initialization',
        valueType: 'token',
        enum: ['not_started', 'performing_process', 'process_completed', 'unknown'],
    },
    {
        name: 'barrier_unattended_operation',
        valueType: 'token',
        enum: ['unattended_operation_enabled', 'unattended_operation_disabled_per_ul_requirements', 'unknown'],
    },
    { name: 'barrier_vacation_mode', valueType: 'token', enum: ['mode_enabled', 'mode_disabled', 'unknown'] },
    { name: 'barrier_safety_beam_obstacle', valueType: 'token', enum: ['obstruction', 'no_obstruction', 'unknown'] },
    { name: 'barrier_problem_sensors', valueType: 'dictionary of token' },
    {
        name: 'barrier_short_circuit',
        valueType: 'token',
        enum: ['no_short_circuit', 'short_circuit_detected', 'unknown'],
    },
    {
        name: 'barrier_fail_events',
        valueType: 'token',
        enum: [
            'no_barrier_fails',
            'barrier_operation_force_has_been_exceeded',
            'barrier_motor_exceeded_operational_time_limit',
            'barrier_operation_exceeded_physical_mechanical_limits',
            'barrier_failed_to_perform_requested_operation_device_malfunction',
            'barrier_unable_to_perform_requested_operation_due_to_ul_requirements',
        ],
    },
    {
        name: 'button_state',
        valueType: 'token',
        enum: [
            'idle',
            'press_1_time',
            'released',
            'held_down',
            'press_2_times',
            'press_3_times',
            'press_4_times',
            'press_5_times',
        ],
    },
    {
        name: 'test_state',
        valueType: 'token',
        enum: ['no_test', 'test_ok', 'test_failed', 'test_in_porgress', 'unknown'],
    },
    { name: 'co_alarm', valueType: 'token', enum: ['no_co', 'co_detected', 'unknown'] },
    {
        name: 'maintenance_state',
        valueType: 'token',
        enum: ['ok', 'replacement_required', 'device_end_of_life', 'replace_main_filter', 'unknown'],
    },
    { name: 'sounding_mode', valueType: 'token', enum: ['silent', 'audible', 'unknown'] },
    { name: 'sound_list', valueType: 'dictionary of sound_info' },
    { name: 'sound_volume', valueType: 'int' },
    { name: 'sound_select', valueType: 'int' },
    { name: 'sound_playback', valueType: 'token', enum: ['stop', 'play', 'unknown'] },
    {
        name: 'periodic_inspection_state',
        valueType: 'token',
        enum: ['inspection_not_required', 'inspection_required', 'unknown'],
    },
    { name: 'co2_alarm', valueType: 'token', enum: ['no_co2', 'co2_detected', 'unknown'] },
    {
        name: 'gas_alarm',
        valueType: 'token',
        enum: ['no_gas', 'combustible_gas_detected', 'toxic_gas_detected', 'unknown'],
    },
    {
        name: 'heat_alarm',
        valueType: 'token',
        enum: ['heat_ok', 'overheat_detected', 'under_heat_detected', 'unknown'],
    },
    { name: 'siren_alarm', valueType: 'token', enum: ['siren_inactive', 'siren_active', 'unknown'] },
    { name: 'light_alarm', valueType: 'token', enum: ['no_light', 'light_detected', 'unknown'] },
    {
        name: 'light_color_transition',
        valueType: 'token',
        enum: ['no_light_color_transition', 'light_color_transition_detected', 'unknown'],
    },
    {
        name: 'temperature_changes',
        valueType: 'token',
        enum: ['no_changes', 'rapid_temperature_rise', 'rapid_temperature_fail', 'unknown'],
    },
    { name: 'intrusion_alarm', valueType: 'token', enum: ['no_intrusion', 'intrusion', 'unknown'] },
    { name: 'tampering_cover_alarm', valueType: 'token', enum: ['no_tampering_cover', 'tampering_cover', 'unknown'] },
    { name: 'glass_breakage_alarm', valueType: 'token', enum: ['no_glass_breakage', 'glass_breakage', 'unknown'] },
    { name: 'tampering_move_alarm', valueType: 'token', enum: ['no_moving', 'product_moved', 'unknown'] },
    { name: 'tampering_impact_alarm', valueType: 'token', enum: ['no_impact', 'impact_detected', 'unknown'] },
    { name: 'tampering_invalid_code_alarm', valueType: 'token', enum: ['no_invalid_code', 'invalid_code', 'unknown'] },
    { name: 'smoke_alarm', valueType: 'token', enum: ['no_smoke', 'smoke_detected', 'unknown'] },
    { name: 'dust_in_device', valueType: 'token', enum: ['no_dust', 'dust_detected', 'unknown'] },
    { name: 'water_leak_alarm', valueType: 'token', enum: ['no_water_leak', 'water_leak_detected', 'unknown'] },
    {
        name: 'water_filter_replacement_alarm',
        valueType: 'token',
        enum: ['water_filter_ok', 'replace_water_filter', 'unknown'],
    },
    {
        name: 'water_flow_alarm',
        valueType: 'token',
        enum: [
            'water_flow_ok',
            'water_flow_below_low_threshold',
            'water_flow_above_high_threshold',
            'water_flow_max',
            'unknown',
        ],
    },
    {
        name: 'water_pressure_alarm',
        valueType: 'token',
        enum: [
            'water_pressure_ok',
            'water_pressure_below_low_threshold',
            'water_pressure_above_high_threshold',
            'water_pressure_max',
            'unknown',
        ],
    },
    {
        name: 'water_temperature_alarm',
        valueType: 'token',
        enum: [
            'water_temperature_ok',
            'water_temperature_below_low_threshold',
            'water_temperature_above_high_threshold',
            'unknown',
        ],
    },
    {
        name: 'water_level_alarm',
        valueType: 'token',
        enum: ['water_level_ok', 'water_level_below_low_threshold', 'water_level_above_high_threshold', 'unknown'],
    },
    { name: 'water_pump_state', valueType: 'token', enum: ['pump_idle', 'pump_active', 'pump_failure', 'unknown'] },
    { name: 'water_valve_state', valueType: 'token', enum: ['valve_is_opened', 'valve_is_closed', 'unknown'] },
    { name: 'master_water_valve_state', valueType: 'token', enum: ['valve_is_opened', 'valve_is_closed', 'unknown'] },
    {
        name: 'water_valve_short_circuit',
        valueType: 'token',
        enum: ['no_short_circuit', 'short_circuit_detected', 'unknown'],
    },
    {
        name: 'master_water_valve_short_circuit',
        valueType: 'token',
        enum: ['no_short_circuit', 'short_circuit_detected', 'unknown'],
    },
    {
        name: 'water_valve_current_alarm',
        valueType: 'token',
        enum: ['current_ok', 'current_below_low_threshold', 'current_above_high_threshold', 'current_max', 'unknown'],
    },
    {
        name: 'master_water_valve_current_alarm',
        valueType: 'token',
        enum: ['current_ok', 'current_below_low_threshold', 'current_above_high_threshold', 'current_max', 'unknown'],
    },
    { name: 'rain_alarm', valueType: 'token', enum: ['no_rain', 'rain_detected', 'unknown'] },
    { name: 'moisture_alarm', valueType: 'token', enum: ['no_moisture', 'moisture_detected', 'unknown'] },
    { name: 'freeze_alarm', valueType: 'token', enum: ['no_freeze', 'freeze_detected', 'unknown'] },
    { name: 'power_state', valueType: 'token', enum: ['no_power_applied', 'power_applied', 'unknown'] },
    { name: 'ac_state', valueType: 'token', enum: ['disconnected', 'connected', 'unknown'] },
    { name: 'power_surge_state', valueType: 'token', enum: ['no_surge', 'surge_detected'] },
    { name: 'voltage_drop_drift_state', valueType: 'token', enum: ['no_drop_drift', 'drop_drift_detected', 'unknown'] },
    { name: 'over_current_state', valueType: 'token', enum: ['no_over_current', 'over_current_detected', 'unknown'] },
    { name: 'over_voltage_state', valueType: 'token', enum: ['no_over_voltage', 'over_voltage_detected', 'unknown'] },
    { name: 'over_load_state', valueType: 'token', enum: ['no_over_load', 'over_load_detected', 'unknown'] },
    { name: 'load_error_state', valueType: 'token', enum: ['no_load_error', 'load_error', 'unknown'] },
    {
        name: 'battery_maintenance_state',
        valueType: 'token',
        enum: [
            'battery_maintenance_ok',
            'replace_battery_soon',
            'replace_battery_now',
            'battery_fluid_is_low',
            'unknown',
        ],
    },
    { name: 'battery_charging_state', valueType: 'token', enum: ['no_charging', 'in_progress', 'unknown'] },
    { name: 'battery_backup', valueType: 'int' },
    { name: 'angle_position', valueType: 'angle' },
    { name: 'atmospheric_pressure', valueType: 'pressure' },
    { name: 'barometric_pressure', valueType: 'pressure' },
    {
        name: 'barrier',
        valueType: 'token',
        enum: ['barrier_closed', 'operating_closing', 'operating_unknown', 'operating_opening', 'barrier_opened'],
    },
    {
        name: 'barrier_state',
        valueType: 'token',
        enum: ['barrier_closed', 'operating_closing', 'operating_unknown', 'operating_opening', 'barrier_opened'],
    },
    { name: 'hw_state', valueType: 'token', enum: ['hardware_ok', 'hardware_failure', 'unknown'] },
    { name: 'sw_state', valueType: 'token', enum: ['software_ok', 'software_failure', 'unknown'] },
    { name: 'emergency_shutoff', valueType: 'token', enum: ['state_ok', 'emergency_shutoff', 'unknown'] },
    {
        name: 'digital_input_state',
        valueType: 'token',
        enum: ['digital_input_high_state', 'digital_input_low_state', 'digital_input_open', 'unknown'],
    },
    { name: 'clock_state', valueType: 'token', enum: ['no_state', 'wake_up_alert', 'time_ended', 'unknown'] },
    { name: 'remaining_time', valueType: 'time' },
    { name: 'basic', valueType: 'bool' },
    { name: 'battery', valueType: 'int' },
    { name: 'co2_level', valueType: 'substance_amount' },
    { name: 'co_level', valueType: 'substance_amount' },
    { name: 'dew_point', valueType: 'temperature' },
    { name: 'dimmer', valueType: 'int' },
    { name: 'dimmer_down', valueType: 'int' },
    { name: 'dimmer_stop', valueType: 'int' },
    { name: 'dimmer_up', valueType: 'int' },
    { name: 'direction', valueType: 'direction' },
    { name: 'distance', valueType: 'length' },
    {
        name: 'door_lock',
        valueType: 'token',
        enum: [
            'unsecured',
            'unsecured_with_timeout',
            'unsecured_for_inside',
            'unsecured_for_inside_with_timeout',
            'unsecured_for_outside',
            'unsecured_for_outside_with_timeout',
            'secured',
            'unknown',
        ],
    },
    { name: 'electric_meter_amper', valueType: 'electric_current' },
    { name: 'electric_meter_kvah', valueType: 'kilo_volt_ampere_hour' },
    { name: 'electric_meter_kvar', valueType: 'reactive_power_instant' },
    { name: 'electric_meter_kvarh', valueType: 'reactive_power_consumption' },
    { name: 'electric_meter_kwh', valueType: 'amount_of_useful_energy' },
    { name: 'electric_meter_power_factor', valueType: 'general_purpose' },
    { name: 'electric_meter_pulse', valueType: 'int' },
    { name: 'electric_meter_volt', valueType: 'electric_potential' },
    { name: 'electric_meter_watt', valueType: 'power' },
    { name: 'electric_resist', valueType: 'float' },
    { name: 'water_meter_volume', valueType: 'volume' },
    { name: 'water_meter_pulse', valueType: 'int' },
    { name: 'gas_meter_volume', valueType: 'volume' },
    { name: 'gas_meter_pulse', valueType: 'int' },
    { name: 'heating_meter_energy', valueType: 'energy' },
    { name: 'cooling_meter_energy', valueType: 'energy' },
    { name: 'humidity', valueType: 'humidity' },
    { name: 'loudness', valueType: 'loudness' },
    { name: 'lux', valueType: 'illuminance' },
    { name: 'meter_reset', valueType: 'int' },
    { name: 'moisture', valueType: 'moisture' },
    { name: 'motion', valueType: 'bool' },
    { name: 'power', valueType: 'power' },
    { name: 'pressure', valueType: 'pressure' },
    { name: 'rgbcolor', valueType: 'rgb' },
    {
        name: 'rgbcolor_down',
        valueType: 'token',
        enum: ['wwhite', 'cwhite', 'red', 'green', 'blue', 'amber', 'cyan', 'purple', 'indexed'],
    },
    {
        name: 'rgbcolor_stop',
        valueType: 'token',
        enum: ['all', 'cwhite', 'red', 'green', 'blue', 'amber', 'cyan', 'purple', 'indexed'],
    },
    {
        name: 'rgbcolor_up',
        valueType: 'token',
        enum: ['wwhite', 'cwhite', 'red', 'green', 'blue', 'amber', 'cyan', 'purple', 'indexed'],
    },
    { name: 'rotation', valueType: 'frequency' },
    { name: 'security_threat', valueType: 'bool' },
    { name: 'seismic_intensity', valueType: 'seismic_intensity' },
    { name: 'seismic_magnitude', valueType: 'seismic_magnitude' },
    { name: 'rain_rate', valueType: 'precipitation' },
    {
        name: 'shutter_command',
        valueType: 'token',
        enum: ['off', 'store', 'learn_upper', 'learn_lower', 'learn_favorite', 'change_roll_direction'],
    },
    {
        name: 'shutter_state',
        valueType: 'token',
        enum: ['idle', 'learn_upper', 'learn_lower', 'learn_favorite', 'change_roll_direction', 'unknown'],
    },
    { name: 'shutter_second_part', valueType: 'shutter_second_part' },
    { name: 'soil_temperature', valueType: 'temperature' },
    { name: 'solar_radiation', valueType: 'irradiance' },
    { name: 'switch', valueType: 'bool' },
    { name: 'temp', valueType: 'temperature' },
    {
        name: 'thermostat_fan_mode',
        valueType: 'token',
        enum: [
            'auto_low',
            'low',
            'auto_high',
            'high',
            'auto_medium',
            'medium',
            'circulation',
            'humidity_circulation',
            'left_and_right',
            'up_and_down',
            'quiet',
            'on',
            'off',
            'auto',
        ],
    },
    {
        name: 'thermostat_fan_state',
        valueType: 'token',
        enum: [
            'idle_off',
            'running_low',
            'running_high',
            'running_medium',
            'circulation_mode',
            'humidity_circulation_mode',
            'right_left_circulation_mode',
            'up_down_circulation_mode',
            'quiet_circulation_mode',
        ],
    },
    {
        name: 'thermostat_mode',
        valueType: 'token',
        enum: [
            'off',
            'heat',
            'cool',
            'auto',
            'aux_mode',
            'resume',
            'fan_only',
            'furnace',
            'dry_air',
            'moist_air',
            'auto_change_over',
            'saving_heat',
            'saving_cool',
            'away_heat',
            'away_cool',
            'full_power',
            'special',
            'eco',
            'emergency_heating',
            'precooling',
            'sleep',
            'manufacturer_specific',
        ],
    },
    {
        name: 'thermostat_energy_saving_mode',
        valueType: 'token',
        enum: ['off', 'low', 'moderate', 'aggressive', 'low_postponed', 'moderate_postponed', 'aggressive_postponed'],
    },
    {
        name: 'thermostat_operating_state',
        valueType: 'token',
        enum: [
            'idle',
            'heating',
            'cooling',
            'fan_only',
            'pending_heat',
            'pending_cool',
            'vent_economizer',
            'aux_heating',
            '2nd_stage_heating',
            '2nd_stage_cooling',
            '2nd_stage_aux_heat',
            '3rd_stage_aux_heat',
            '2nd_stage_fan',
            '3rd_stage_fan',
        ],
    },
    { name: 'thermostat_setpoint', valueType: 'temperature' },
    { name: 'thermostat_setpoint_heating', valueType: 'temperature' },
    { name: 'thermostat_setpoint_cooling', valueType: 'temperature' },
    { name: 'tide_level', valueType: 'length' },
    { name: 'ultraviolet', valueType: 'ultraviolet' },
    {
        name: 'user_codes_keypad_mode',
        valueType: 'token',
        enum: ['normal', 'vacation', 'privacy', 'locked_out', 'unknown'],
    },
    { name: 'master_code', valueType: 'string' },
    { name: 'master_code_state', valueType: 'token', enum: ['enabled', 'disabled', 'unknown'] },
    { name: 'user_codes', valueType: 'dictionary of userCode' },
    { name: 'user_lock_operation', valueType: 'user_lock_operation' },
    { name: 'user_code_action', valueType: 'user_code_action' },
    { name: 'user_codes_scan_progress', valueType: 'int' },
    { name: 'velocity', valueType: 'velocity' },
    { name: 'voltage', valueType: 'electric_potential' },
    { name: 'current', valueType: 'electric_current' },
    { name: 'weight', valueType: 'mass' },
    { name: 'air_flow', valueType: 'volume_flow' },
    { name: 'tank_capacity', valueType: 'volume' },
    { name: 'water_temperature', valueType: 'temperature' },
    { name: 'electrical_resistivity', valueType: 'electrical_resistance' },
    { name: 'electrical_conductivity', valueType: 'electrical_conductivity' },
    { name: 'frequency', valueType: 'frequency' },
    { name: 'time_period', valueType: 'time' },
    { name: 'target_temperature', valueType: 'temperature' },
    { name: 'blood_pressure', valueType: 'blood_pressure' },
    { name: 'water_flow', valueType: 'volume_flow' },
    { name: 'water_pressure', valueType: 'pressure' },
    { name: 'boiler_water_temperature', valueType: 'temperature' },
    { name: 'domestic_hot_water_temperature', valueType: 'temperature' },
    { name: 'outside_temperature', valueType: 'temperature' },
    { name: 'exhaust_temperature', valueType: 'temperature' },
    { name: 'heart_rate_lf_hf_ratio', valueType: 'float' },
    { name: 'water_oxidation_reduction_potential', valueType: 'electric_potential' },
    { name: 'water_chlorine_level', valueType: 'substance_amount' },
    { name: 'exhaust_temperature', valueType: 'temperature' },
    { name: 'outside_temperature', valueType: 'temperature' },
    { name: 'water_chlorine_level', valueType: 'substance_amount' },
    { name: 'water_acidity', valueType: 'acidity' },
    { name: 'particulate_matter_10', valueType: 'substance_amount' },
    { name: 'particulate_matter_2_dot_5', valueType: 'substance_amount' },
    { name: 'program_status', valueType: 'token', enum: ['idle', 'started', 'in_progress', 'completed', 'unknown'] },
    { name: 'program_failures', valueType: 'dictionary of token' },
    {
        name: 'position',
        valueType: 'token',
        enum: ['leaving_bed', 'sitting_on_bed', 'lying_on_bed', 'sitting_on_bed_edge', 'unknown'],
    },
    { name: 'sleep_apnea', valueType: 'token', enum: ['not_detected', 'low_breathe', 'no_breathe', 'unknown'] },
    {
        name: 'sleep_stage',
        valueType: 'token',
        enum: ['not_sleeping', 'dreaming', 'light_sleep', 'medium_sleep', 'deep_sleep', 'unknown'],
    },
    {
        name: 'voc_level_status',
        valueType: 'token',
        enum: ['clean', 'slightly_poluted', 'moderately_polluted', 'highly_polluted', 'unknown'],
    },
    { name: 'rf_signal_strength', valueType: 'rf_signal_strength' },
    { name: 'basal_metabolic_rate', valueType: 'energy' },
    { name: 'body_mass_index', valueType: 'float' },
    { name: 'body_mass', valueType: 'mass' },
    { name: 'total_body_water', valueType: 'mass' },
    { name: 'fat_mass', valueType: 'mass' },
    { name: 'muscle_mass', valueType: 'mass' },
    { name: 'relative_modulation_level', valueType: 'general_purpose' },
    { name: 'respiratory_rate', valueType: 'frequency' },
    { name: 'smoke_density', valueType: 'substance_amount' },
    { name: 'heart_rate', valueType: 'frequency' },
    { name: 'soil_salinity', valueType: 'substance_amount' },
    { name: 'soil_reactivity', valueType: 'substance_amount' },
    { name: 'soil_humidity', valueType: 'humidity' },
    { name: 'volatile_organic_compound_level', valueType: 'substance_amount' },
    { name: 'methane_density', valueType: 'substance_amount' },
    { name: 'radon_concentration', valueType: 'radon_concentration' },
    { name: 'formaldehyde_level', valueType: 'substance_amount' },
    { name: 'weekly_user_code_intervals', valueType: 'dictionary of weekly_interval_array' },
    { name: 'daily_user_code_intervals', valueType: 'dictionary of daily_interval_array' },
    { name: 'goto_favorite', valueType: 'bool' },
    { name: 'zones_status', valueType: 'token', enum: ['disarmed', 'stay', 'night', 'away'] },
    { name: 'activity', valueType: 'token', enum: ['no_activity', 'shake', 'tilt', 'drop'] },
    { name: 'bed_activity', valueType: 'int' },
    { name: 'ammonia', valueType: 'substance_amount' },
    { name: 'applied_force_on_sensor', valueType: 'force' },
    { name: 'bone_mass', valueType: 'mass' },
    { name: 'condenser_coil_temperature', valueType: 'temperature' },
    { name: 'defrost_temperature', valueType: 'temperature' },
    { name: 'discharge_line_temperature', valueType: 'temperature' },
    { name: 'discharge_pressure', valueType: 'pressure' },
    { name: 'evaporator_coil_temperature', valueType: 'temperature' },
    { name: 'lead', valueType: 'substance_amount' },
    { name: 'liquid_line_temperature', valueType: 'temperature' },
    { name: 'motion_direction', valueType: 'direction' },
    { name: 'nitrogen_dioxide', valueType: 'substance_amount' },
    { name: 'ozone', valueType: 'substance_amount' },
    { name: 'particulate_matter_1', valueType: 'substance_amount' },
    { name: 'return_air_temperature', valueType: 'temperature' },
    { name: 'suction_pressure', valueType: 'pressure' },
    { name: 'sulfur_dioxide', valueType: 'substance_amount' },
    { name: 'supply_air_temperature', valueType: 'temperature' },
];
