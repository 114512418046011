import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalAntd } from 'antd';
import 'antd/dist/antd.css';

const Modal = (props) => {
    const { children, ...rest } = props;

    return (
        <ModalAntd {...rest} footer={false}>
            {children}
        </ModalAntd>
    );
};

Modal.propTypes = {
    closable: PropTypes.bool,
    className: PropTypes.string,
    centered: PropTypes.bool,
    keyboard: PropTypes.bool,
    maskClosable: PropTypes.bool,
    children: PropTypes.element,
    bodyStyle: PropTypes.object,
    okText: PropTypes.string,
};

Modal.defaultProps = {
    centered: true,
    keyboard: true,
    maskClosable: true,
    okText: 'Save',
};

export default Modal;
