import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import DeviceAssociationSourceDeviceInfo from '../DeviceAssociationControllerInfo/DeviceAssociationSourceDeviceInfo';

import styles from '../../DeviceAssociationsList/DeviceAssociationList.module.scss';
import DeviceIconContainer from '../../../../../components/DeviceIconContainer';
import useTooltip from '../../hooks/useToolTip';
import { EZLOGIC_TITLE_SOURCE_DEVICE } from '../../../../../constants/language_tokens';

const DeviceAssociationSourceDeviceBlock = (props) => {
    const { deviceInfo, device, keyOfTargetNodes } = props;
    const { t } = useTranslate();
    const { showTooltip, tooltipRef, handleIconClick, hideToolTip } = useTooltip();

    return (
        <>
            <div className={styles.sourceDeviceColumn}>
                <p className={styles.deviceName}>{t(EZLOGIC_TITLE_SOURCE_DEVICE)}:</p>
                <div className={styles.dataDevice}>
                    <div className={styles.icon}>
                        <DeviceIconContainer
                            category={device?.category}
                            onClick={handleIconClick}
                            iconStyles={styles.deviceIcon}
                        />
                        {showTooltip && (
                            <div className={`${styles.tooltip}`} ref={tooltipRef}>
                                <DeviceAssociationSourceDeviceInfo
                                    deviceInfo={deviceInfo}
                                    keyOfTargetNodes={keyOfTargetNodes}
                                    hideToolTip={hideToolTip}
                                />
                            </div>
                        )}
                    </div>
                    <span className={styles.targetDeviceName}>{device?.name}</span>
                </div>
            </div>
        </>
    );
};

export default DeviceAssociationSourceDeviceBlock;
