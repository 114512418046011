import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
    FormControl,
    Input,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import { Header } from '../../../../../components';
import ActionTableCells from './components/ActionTableCells';
import UsersActions from '../../../../../actions/UsersActions';
import {
    ACCOUNT_ADMIN_PERMISSION_ROLE,
    CHANGE_USER_PASSWORD,
    CREATE_USER,
    EDIT_USER,
    PIN_CODE,
    SUCCESS,
} from '../../../../../constants/Users';
import AddButton from '../../components/AddButton';
import { getSearchUsers } from '../utils';
import {
    EZLOGIC_BUTTON_ADD_NEW_USER,
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_HEADING_USERS,
    EZLOGIC_SEARCH_TITLE,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_EMAIL,
    EZLOGIC_TITLE_FIRST_NAME,
    EZLOGIC_TITLE_LAST_NAME,
    EZLOGIC_TITLE_PASSWORD,
    EZLOGIC_TITLE_PIN_CODE,
    EZLOGIC_TITLE_USER_NAME,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import TablePagination, { useTablePagination } from '../../../../../components/TablePagination';
import UsersLineLoading from './components/UsersLineLoading';

import styles from './style.module.scss';
import { LoginIcon } from '../../../../../assets/icons';

const UsersPage = () => {
    const [searchValue, setSearchValue] = useState('');
    const { users } = useSelector(({ users }) => ({ users: getSearchUsers(searchValue, users.usersList) }));
    const paginationStore = useTablePagination(users);
    const { Username: loggedInUserUsername, PK_PermissionRole } = useSelector(({ account }) => account.data);
    const { requestStatus, requestStatusMessage } = useSelector(({ users }) => users);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslate();

    const updateUsersList = () => {
        dispatch(UsersActions.setUserList());
    };

    useEffect(() => {
        updateUsersList();

        return () => {
            dispatch(UsersActions.clearUsersList());
        };
    }, []);

    useEffect(() => {
        if (requestStatus === SUCCESS) {
            toast(requestStatusMessage, { type: TOAST_TYPE.SUCCESS });
            dispatch(UsersActions.clearRequestStatus());
        }
    }, [requestStatus]);

    const getIsLoggedIn = (user) => {
        return loggedInUserUsername === user.Username;
    };

    const handleRouteToPage = useCallback((type, user) => {
        if (type === EDIT_USER) {
            history.push(`${location.pathname}/userEdit`);
            dispatch(UsersActions.setSelectedUser(user));
        }

        if (type === CREATE_USER) {
            history.push(`${location.pathname}/userCreate`);
        }

        if (type === PIN_CODE) {
            history.push(`${location.pathname}/pinCodes`);
            dispatch(UsersActions.setSelectedUser(user));
        }

        if (type === CHANGE_USER_PASSWORD) {
            dispatch(UsersActions.setSelectedUser(user));
            history.push(`${location.pathname}/userEdit/changePassword`);
        }
    }, []);

    const getLoginIconClassName = (user) => {
        return `${styles.loginIcon} ${getIsLoggedIn(user) ? styles.loginIconVisible : styles.loginIconHidden}`;
    };

    return (
        <div className={styles.usersPage}>
            <UsersLineLoading />
            <Header>
                <h1 className={styles.headerTitle}>{t(EZLOGIC_HEADING_USERS)}</h1>
                <div className={styles.headerAction}>
                    <FormControl className={styles.searchField}>
                        <Input
                            value={searchValue}
                            placeholder={t(EZLOGIC_SEARCH_TITLE)}
                            onChange={(e) => setSearchValue(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <AddButton
                        label={t(EZLOGIC_BUTTON_ADD_NEW_USER)}
                        handleClick={() => handleRouteToPage(CREATE_USER)}
                    />
                </div>
            </Header>

            <div className={styles.tableContainer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t(EZLOGIC_TITLE_FIRST_NAME)}</TableCell>
                                <TableCell>{t(EZLOGIC_TITLE_LAST_NAME)}</TableCell>
                                <TableCell>{t(EZLOGIC_TITLE_USER_NAME)}</TableCell>
                                <TableCell>{t(EZLOGIC_TITLE_EMAIL)}</TableCell>
                                <TableCell align="center">{t(EZLOGIC_TITLE_PIN_CODE)}</TableCell>
                                <TableCell align="center">{t(EZLOGIC_TITLE_PASSWORD)}</TableCell>
                                <TableCell className={styles.headerIconCell}>{t(EZLOGIC_BUTTON_EDIT)}</TableCell>
                                {PK_PermissionRole === ACCOUNT_ADMIN_PERMISSION_ROLE && (
                                    <TableCell className={styles.headerIconCell}>{t(EZLOGIC_TITLE_DELETE)}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginationStore.state.items.map((user) => (
                                <TableRow key={user.PK_User}>
                                    <TableCell className={styles.cellWithFirstName}>
                                        <div className={styles.tableCellBox}>
                                            <LoginIcon
                                                className={getLoginIconClassName(user)}
                                                fontSize="large"
                                                color="primary"
                                            />
                                            <div>{user.FirstName || ' - '}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{user.Name || ' - '}</TableCell>
                                    <TableCell>{user.Username}</TableCell>
                                    <TableCell>{user.Email}</TableCell>
                                    <ActionTableCells user={user} handleRouteToPage={handleRouteToPage} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination count={users.length} paginationStore={paginationStore} />
            </div>
        </div>
    );
};

export default UsersPage;
