import React, { memo } from 'react';

import ExpressionComparedCommonTypes from '../ExpressionComparedCommonTypes';
import ExpressionComparedBooleanValue from '../ExpressionComparedBooleanValue';
import InputAndColorPicker from '../../../../../../../components/ColorInputAndColorPickers';

import { BOOLEAN_TYPE, RGB_COLORS } from '../../../../../../../constants/Expressions';
import { NUMBER, OBJECT, STRING } from '../../../../../../../constants/MeshbotConstant';

const ExpressionComparedValueTypes = (props) => {
    const {
        comparingValue,
        onHandleChangeComparingValue,
        selectedExpressionType,
        onHandleChangeComparingRGBValue,
        selectedExpressionObject,
        convertedExpressionType,
        comparatorValue,
        initialRangeStartValue,
        initialRangeEndValue,
    } = props;

    const isRGBType =
        selectedExpressionObject &&
        Object.keys(selectedExpressionObject?.value).includes(RGB_COLORS.RED) &&
        Object.keys(selectedExpressionObject?.value).includes(RGB_COLORS.GREEN) &&
        Object.keys(selectedExpressionObject?.value).includes(RGB_COLORS.BLUE);

    return (
        <>
            {selectedExpressionType === BOOLEAN_TYPE && (
                <ExpressionComparedBooleanValue
                    comparingValue={comparingValue}
                    onHandleChangeComparingValue={onHandleChangeComparingValue}
                />
            )}
            {(selectedExpressionType === STRING || selectedExpressionType === NUMBER) && (
                <ExpressionComparedCommonTypes
                    comparingValue={comparingValue}
                    selectedExpressionType={selectedExpressionType}
                    onHandleChangeComparingValue={onHandleChangeComparingValue}
                    convertedExpressionType={convertedExpressionType}
                    comparatorValue={comparatorValue}
                    onHandleChangeComparingRangeValue={onHandleChangeComparingRGBValue}
                    initialRangeStartValue={initialRangeStartValue}
                    initialRangeEndValue={initialRangeEndValue}
                />
            )}
            {selectedExpressionType === OBJECT && isRGBType && (
                <InputAndColorPicker
                    value={comparingValue}
                    isVariablePage={true}
                    isMeshbotPage={true}
                    onChangeRgbValue={onHandleChangeComparingRGBValue}
                />
            )}
        </>
    );
};

export default memo(ExpressionComparedValueTypes);
