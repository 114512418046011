import React from 'react';
import { Button } from '@material-ui/core';

import { wrapper } from './MeshBotButtonsWrapper.module.scss';
import LoadingEllipses from '../../../../AuthorizationPages/Login/components/LoadingEllipses';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_SAVE } from '../../../../../constants/language_tokens';

const MeshBotButtonsWrapper = ({ disabled, onClick, isLoading }) => {
    const { t } = useTranslate();

    return (
        <div className={wrapper}>
            <Button variant="contained" size="large" disabled={disabled} onClick={onClick} color="primary">
                {isLoading ? <LoadingEllipses /> : t(EZLOGIC_BUTTON_SAVE)}
            </Button>
        </div>
    );
};

export default MeshBotButtonsWrapper;
