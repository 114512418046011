import React from 'react';

import AccountTypesItem from '../AccountTypesItem';

import { ACCOUNT_TYPES_INFO } from '../../../../../constants/Users';
import { useTranslate } from '../../../../../features/languages';

const AccountTypesList = (props) => {
    const { onGetActiveAccountTypeClassName } = props;
    const { t } = useTranslate();

    return (
        <ul>
            {ACCOUNT_TYPES_INFO.map(({ title, description, value }, index) => (
                <AccountTypesItem
                    key={index}
                    title={t(title)}
                    description={t(description)}
                    value={value}
                    onGetActiveAccountTypeClassName={onGetActiveAccountTypeClassName}
                />
            ))}
        </ul>
    );
};

export default AccountTypesList;
