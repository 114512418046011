import React from 'react';
import { Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import './style.scss';

const ControllerSettingsDialog = ({ isOpenDialogOfSettings, setIsOpenDialogOfSettings, title, children }) => {
    return (
        <Dialog
            maxWidth="xs"
            className="controller-settings-dialog"
            open={isOpenDialogOfSettings}
            onClose={() => setIsOpenDialogOfSettings(false)}
        >
            <div className="dialog__item controller-settings-dialog__title">
                {title}
                <ClearIcon
                    className="controller-settings-dialog__icon-hide"
                    onClick={() => setIsOpenDialogOfSettings(false)}
                />
            </div>
            {children}
        </Dialog>
    );
};

export default ControllerSettingsDialog;
