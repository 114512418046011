import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_ADD_MORE,
    EZLOGIC_TITLE_CREATE_MESHBOT,
    EZLOGIC_TITLE_DEVICE_ADDED_SUCCESSFULLY,
    EZLOGIC_TITLE_DONE,
    EZLOGIC_TITLE_PLUGIN_INTEGRATION_ADDED,
} from '../../../../../constants/language_tokens';
import { MESH_BOT_LOCAL_NEW } from '../../../../../constants/URLs';

import { IcControlsClose } from '../../../../../assets/icons';
import { IcVisualsSuccess } from '../../../../../assets/icons/visuals/neutralVisuals/ic_visuals_success';

import style from './style.module.scss';

const EzloIntegrationsIntegrationCreated = ({ open, onClose, onCreateMore }) => {
    const { t } = useTranslate();
    const history = useHistory();
    const handleRoute = () => {
        history.push(MESH_BOT_LOCAL_NEW);
    };

    return (
        <Modal open={open} onClose={onClose} className={style.modal}>
            <div className={style.modalContainer}>
                <p className={style.closeBtn}>
                    <span onClick={onClose}>
                        <IcControlsClose />
                    </span>
                </p>
                <span className={style.imgWrapper}>
                    <IcVisualsSuccess />
                </span>
                <h3>{t(EZLOGIC_TITLE_DEVICE_ADDED_SUCCESSFULLY)}</h3>
                <div className={style.content}>
                    {t(EZLOGIC_TITLE_PLUGIN_INTEGRATION_ADDED)}{' '}
                    <span onClick={handleRoute}>{t(EZLOGIC_TITLE_CREATE_MESHBOT)}</span>
                </div>
                <Button size="large" variant="contained" color="primary" onClick={onClose}>
                    {t(EZLOGIC_TITLE_DONE)}
                </Button>
                <span onClick={onCreateMore} className={style.createMore}>
                    {t(EZLOGIC_BUTTON_ADD_MORE)}
                </span>
            </div>
        </Modal>
    );
};

export default EzloIntegrationsIntegrationCreated;
