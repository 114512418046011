import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, LinearProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { isBackupSupportedDevice, getCurrentControllerBackups } from './utils';
import NoBackups from './SectionContent/NoBackups';
import Dialog from './SectionContent/BackupDialogs/Dialog';
import CreateBackupDialog from './SectionContent/BackupDialogs/CreateBackupDialog';
import RestoreBackupDialog from './SectionContent/BackupDialogs/RestoreBackupDialog';
import { Header } from '../../../components';
import { withBackups } from '../../../components/HOCs/withBackups';
import { BackupActions } from '../../../actions';
import BackupsLeftPanel from './BackupsLeftPanel';
import BackupsSectionContent from './BackupsSectionContent';
import { useTranslate } from '../../../features/languages';
import { CREATE_BACKUP_STEPS } from './constants';
import useParamSelector from '../../../hooks/useParamSelector';
import { controllerArchitectureSelector, controllerTimeOffsetSelector } from './selectors';

import './style.scss';
import styles from '../EzloAccount/components/Header/style.module.scss';

const EzloHubBackups = (props) => {
    const [isCreateBackupModalShow, setIsCreateBackupModalShow] = useState(false);
    const [isRestoreBackupModalShow, setIsRestoreBackupModalShow] = useState(false);
    const [isCurrentControllerConnected, setIsCurrentControllerConnected] = useState(false);
    const {
        serial,
        backups,
        isBackupsLoading,
        controllersList,
        isNewBackupInProgress,
        isRestoreBackupInProgress,
        view,
        createBackupStep,
        isBackupSupported,
    } = props;
    const [currentControllerForBackup, setCurrentControllerForBackup] = useState(serial);
    const timeOffset = useParamSelector(controllerTimeOffsetSelector, currentControllerForBackup);
    const architecture = useParamSelector(controllerArchitectureSelector, currentControllerForBackup);
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const selectedControllerBackups = useMemo(
        () => getCurrentControllerBackups(currentControllerForBackup, backups),
        [backups, currentControllerForBackup],
    );

    useEffect(() => {
        if (!isEmpty(selectedControllerBackups)) {
            dispatch(BackupActions.selectBackup(selectedControllerBackups[0].uuid));

            return;
        }

        dispatch(BackupActions.clearSelectedBackup());
    }, [selectedControllerBackups]);

    const showEmpty =
        !isBackupsLoading &&
        !isNewBackupInProgress &&
        isBackupSupported &&
        isEmpty(selectedControllerBackups) &&
        createBackupStep !== CREATE_BACKUP_STEPS.BACKUP_ERROR;

    useEffect(() => {
        setCurrentControllerForBackup(!currentControllerForBackup ? serial : currentControllerForBackup);
    }, [serial, backups, isBackupSupported]);

    useEffect(() => {
        if (isBackupSupported) {
            dispatch(BackupActions.fetchDeviceBackups());
        }
    }, [isBackupSupported]);

    useEffect(() => {
        if (currentControllerForBackup) {
            dispatch(BackupActions.setBackupSupported(isBackupSupportedDevice(architecture)));
        }
    }, [architecture, currentControllerForBackup]);

    useEffect(() => {
        if (currentControllerForBackup) {
            setIsCurrentControllerConnected(true);
        }
    }, [currentControllerForBackup]);

    const handleCreateBackup = () => {
        handleCloseCreateBackupModal();
        dispatch(BackupActions.createBackup(currentControllerForBackup));
    };
    const handleRestoreBackup = () => {
        dispatch(BackupActions.restoreBackup());
    };
    const handleCloseCreateBackupModal = () => {
        setIsCreateBackupModalShow(false);
    };
    const handleOpenCreateBackupModal = () => {
        setIsCreateBackupModalShow(true);
    };
    const handleOpenRestoreBackupModal = () => {
        setIsRestoreBackupModalShow(true);
    };
    const handleCloseRestoreBackupModal = () => {
        setIsRestoreBackupModalShow(false);
    };
    const handleConfirmRestoreBackup = () => {
        handleCloseRestoreBackupModal();
        dispatch(BackupActions.confirmRestoreBackup(currentControllerForBackup));
    };
    const handleBackupClick = (uuid) => {
        dispatch(BackupActions.selectBackup(uuid));
    };
    const handleFinish = () => {
        dispatch(BackupActions.onFinish());
    };
    const handleGetStatusController = (isConnected) => {
        setIsCurrentControllerConnected(isConnected);
    };
    const handleSelectController = (controllerSerial) => {
        setCurrentControllerForBackup(controllerSerial);
        if (isEmpty(selectedControllerBackups)) {
            dispatch(BackupActions.clearSelectedBackup());
        } else {
            const backupUuid = selectedControllerBackups[0].uuid;
            dispatch(BackupActions.selectDefaultBackup(backupUuid));
        }
    };

    return (
        <>
            {isBackupsLoading && (
                <Box className={styles.linearProgressBox}>
                    <LinearProgress aria-label="LinearProgress" className={styles.linearProgress} />
                </Box>
            )}
            <Header headerClassName="backups-header" />
            <Dialog open={isRestoreBackupModalShow} onHide={handleCloseRestoreBackupModal} style={{ height: '63rem' }}>
                <RestoreBackupDialog onConfirmRestoreBackup={handleConfirmRestoreBackup} t={t} />
            </Dialog>
            <Dialog open={isCreateBackupModalShow} onHide={handleCloseCreateBackupModal} style={{ height: '54.5rem' }}>
                <CreateBackupDialog onCreateBackup={handleCreateBackup} t={t} />
            </Dialog>
            <section className="ezlo-section ezlo-backups-page">
                {!isNewBackupInProgress && !isRestoreBackupInProgress && (
                    <BackupsLeftPanel
                        handleSelectController={handleSelectController}
                        handleGetStatusController={handleGetStatusController}
                        currentController={currentControllerForBackup}
                        controllersList={controllersList}
                        isCurrentControllerConnected={isCurrentControllerConnected}
                        handleBackupClick={handleBackupClick}
                        handleOpenCreateBackupModal={handleOpenCreateBackupModal}
                        selectedControllerBackups={selectedControllerBackups}
                        timeOffset={timeOffset}
                    />
                )}
                <div className="ezlo-section-content">
                    {showEmpty ? (
                        <NoBackups />
                    ) : (
                        <BackupsSectionContent
                            handleConfirmRestoreBackup={handleConfirmRestoreBackup}
                            handleOpenRestoreBackupModal={handleOpenRestoreBackupModal}
                            handleFinish={handleFinish}
                            handleRestoreBackup={handleRestoreBackup}
                            isCurrentControllerConnected={isCurrentControllerConnected}
                            view={view}
                            handleCreateBackup={handleCreateBackup}
                            timeOffset={timeOffset}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default withBackups(EzloHubBackups);
