import React from 'react';
import { Add } from '@material-ui/icons';
import { Button, useMediaQuery } from '@material-ui/core';

import GallerySection from './components/GallerySection/GallerySection';
import { Header } from '../../components';
import UploadImagesDialog from './components/UploadImagesDialog/UploadImagesDialog';
import MediaContainer from './components/MediaContainer';
import useDialogState from '../../hooks/useDialogState/useDialogState';
import { useTranslate } from '../languages';
import { EZLOGIC_TITLE_MEDIA_STORAGE, EZLOGIC_TITLE_UPLOAD_IMAGES } from '../../constants/language_tokens';
import { ADD_ICON_SHOW_BREAKPOINT } from './constants';

import styles from './styles.module.scss';

const MediaStoragePage = () => {
    const dialogState = useDialogState();
    const { t } = useTranslate();
    const showIcon = useMediaQuery(ADD_ICON_SHOW_BREAKPOINT);

    return (
        <MediaContainer>
            <Header headerClassName={styles.headerClassName}>
                <h1>{t(EZLOGIC_TITLE_MEDIA_STORAGE)}</h1>
                <div className={styles.buttonBox}>
                    <Button color="primary" variant="outlined" onClick={dialogState.toggleOpenDialog}>
                        {showIcon && <Add aria-label="add-icon" />}
                        {t(EZLOGIC_TITLE_UPLOAD_IMAGES)}
                    </Button>
                </div>
            </Header>

            <GallerySection />

            <UploadImagesDialog dialogState={dialogState} />
        </MediaContainer>
    );
};

export default MediaStoragePage;
