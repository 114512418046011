import * as types from '../constants/ActionTypes';
import wsm from '../helpers/wsm';
import { GenericActions, ItemActions } from '../actions';
import at from '../constants/ActionTypes/Devices';
import { associationConstants } from '../constants/ActionTypes/Associations';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { getUpdatedText } from '../containers/Ezlo/EzloDeviceAssociations/utils';

const actions = {
    clearErrors: () => (dispatch) => {
        dispatch({ type: at.CLEAR_ERRORS });
    },

    deviceForceRemove: (serial, params) => async (dispatch) => {
        dispatch({ type: at.DEVICE_FORCE_REMOVE.pending });

        await wsm.send(
            serial,
            'hub.device.force_remove',
            { ...params },
            () => {
                dispatch({ type: at.DEVICE_FORCE_REMOVE.success });
            },
            (error) => {
                bugsnagNotify(error, { type: at.DEVICE_FORCE_REMOVE.rejected, serial, params });
                dispatch({ type: at.DEVICE_FORCE_REMOVE.rejected, payload: error });
            },
        );
    },

    deviceCheck: (serial, params) => async (dispatch) => {
        dispatch({ type: at.DEVICE_CHECK.pending });

        await wsm.send(
            serial,
            'hub.device.check',
            { ...params },
            () => {
                dispatch({ type: at.DEVICE_CHECK.success });
            },
            (error) => {
                bugsnagNotify(error, { type: at.DEVICE_CHECK.rejected, serial, params });
                dispatch({ type: at.DEVICE_CHECK.rejected });
            },
        );
    },

    getSettingDevice: (serial, params) => async (dispatch) => {
        dispatch({ type: at.GET_SETTINGS_DEVICE.pending });

        await wsm.send(
            serial,
            'hub.device.settings.list',
            { ...params },
            (data) => {
                dispatch({ type: at.GET_SETTINGS_DEVICE.success, setting: data.settings[0], serial });
            },
            (error) => {
                bugsnagNotify(error, { type: at.GET_SETTINGS_DEVICE.rejected, serial, params });
                dispatch({ type: at.GET_SETTINGS_DEVICE.rejected });
            },
        );
    },

    updateSettings: (serial, params, isAddedText, t) => async (dispatch) => {
        dispatch({ type: at.GET_SETTINGS_DEVICE.pending });
        await wsm.send(
            serial,
            'hub.device.settings.list',
            { ...params },
            (data) => {
                dispatch({ type: associationConstants.GET_SETTINGS.success, setting: data.settings, serial });
                dispatch({ type: at.CREATE_ASSOCIATION.success });
                getUpdatedText(isAddedText, t);
            },
            (error) => {
                bugsnagNotify(error, { type: associationConstants.GET_SETTINGS.rejected, serial, params });
                dispatch({ type: associationConstants.GET_SETTINGS.rejected });
            },
        );
    },

    getDevicesList: (serial) => async (dispatch) => {
        dispatch({ type: at.GET_DEVICE_LIST.pending });

        await wsm.send(
            serial,
            'hub.devices.list',
            {},
            (data) => {
                dispatch({ type: at.GET_DEVICE_LIST.success, devices: data.devices, serial });
            },
            (error) => {
                bugsnagNotify(error, { type: at.GET_DEVICE_LIST.rejected, serial });
                dispatch({ type: at.GET_DEVICE_LIST.rejected });
            },
        );
    },

    changeDevicePlace: (serial, _id, roomId) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.room.set',
                {
                    _id,
                    roomId,
                },
                () => resolve(),
                (error) => {
                    bugsnagNotify(error, { serial, _id, roomId });
                    reject(error);
                },
            );
        }),

    replaceChildDevices: (serial, _id, roomId, separateChildDevices) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.room.set',
                {
                    _id,
                    roomId,
                    separateChildDevices,
                },
                () => resolve(),
                (error) => {
                    bugsnagNotify(error, { serial, _id, roomId });
                    reject(error);
                },
            );
        }),

    // TODO make debounce or throttle with the lodash
    getWhatHappened:
        ({ startTime, startRecords = 0, length, limitRecords, deviceFilter = [] }) =>
        (dispatch) =>
            new Promise((resolve) => {
                actions.__cacheWhatHappenedRequest(
                    {
                        startTime,
                        startRecords,
                        length,
                        limitRecords,
                        deviceFilter,
                    },
                    resolve,
                );
            })
                .then((eventDataItems) => {
                    dispatch({ type: types.SET_WHATHAPPENED, whatHappenedData: { eventDataItems } });

                    return eventDataItems;
                })
                .catch((err) => {
                    bugsnagNotify(err, { startTime, startRecords, length, limitRecords, deviceFilter });
                }),

    changeDeviceRoom: (serial, deviceId, roomId) => (dispatch) =>
        new Promise((resolve, reject) => {
            Promise.all([
                //  dispatch(actions.unplaceDevice(deviceId)),
                dispatch(actions.changeDevicePlace(serial, deviceId, roomId)),
            ])
                .then(() => {
                    dispatch(actions.updateDeviceRoom(serial, deviceId, roomId));
                    dispatch(ItemActions.bindItemsToDeviceRoom(serial, deviceId));
                    resolve();
                })
                .catch((error) => {
                    bugsnagNotify(error, { serial, deviceId, roomId });
                    reject();
                });
        }),

    renameDevice: (serial, _id, name) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.name.set',
                {
                    _id,
                    name,
                },
                () => {
                    dispatch(actions.updateDeviceName(serial, _id, name));
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id, name });
                    reject(error);
                    dispatch(GenericActions.showError(error));
                },
            );
        }),

    setDeviceSetting: (serial, _id, value) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.setting.value.set',
                {
                    _id,
                    value,
                },
                (res) => {
                    resolve(res);
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id, value });
                    reject(error);
                    dispatch(GenericActions.showError(error));
                },
            );
        }),

    updateDeviceDictionarySettings: (serial, params) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.setting.dictionary.value.set',
                params,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    bugsnagNotify(error, { serial, params });
                    reject(error);
                    dispatch(GenericActions.showError(error));
                },
            );
        }),

    resetDeviceSettings: (serial, param) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.setting.reset',
                param,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    bugsnagNotify(error, { serial, param });
                    reject(error);
                    dispatch(GenericActions.showError(error));
                },
            );
        }),

    __cacheWhatHappenedRequest: () => null,

    getFavorites: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.favorite.list',
                {
                    request: ['devices', 'items', 'rules'],
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    setFavorite: (serial, deviceId, isFavorite) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.favorite.set',
                {
                    devices: [
                        {
                            _id: deviceId,
                            favorite: isFavorite,
                        },
                    ],
                    items: [],
                    rules: [],
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, deviceId, isFavorite });
                    reject(error);
                },
            );
        }),

    addDevice: (serial, data) => ({
        type: types.ADD_DEVICE,
        serial,
        data,
    }),

    removeDevice: (serial, _id) => ({
        type: types.REMOVE_DEVICE,
        serial,
        _id,
    }),

    sortDevices: (sortedDevices, sortMap) => ({
        type: types.SORT_DEVICES,
        sortedDevices,
        sortMap,
    }),

    showReachableDevices: (bool) => ({
        type: types.REACHABLE_DEVICES,
        show: bool,
    }),

    showAllDevices: (bool) => ({
        type: types.SHOW_ALL_DEVICES,
        show: bool,
    }),

    search: (text) => ({
        type: types.SEARCH_DEVICE_TEXT,
        searchText: text,
    }),

    updateDeviceName: (serial, _id, name) => ({
        type: types.UPDATE_DEVICE_NAME,
        serial,
        _id,
        name,
    }),

    updateDeviceRoom: (serial, deviceId, roomId) => ({
        type: types.UPDATE_DEVICE_ROOM,
        serial,
        roomId,
        deviceId,
    }),

    setDeviceReachable: (_id, reachable) => ({
        type: types.SET_DEVICE_REACHABLE,
        _id,
        reachable,
    }),

    unsetDeviceTemplate: () => ({
        type: types.UNSET_DEVICE_TEMPLATE,
    }),

    setDeviceTemplate: (templateId) => ({
        type: types.SET_DEVICE_TEMPLATE,
        templateId,
    }),

    storeDeviceTemplate: (deviceId, template) => ({
        type: types.STORE_DEVICE_TEMPLATE,
        deviceId,
        template,
    }),

    removeDeviceTemplate: (deviceId) => ({
        type: types.REMOVE_DEVICE_TEMPLATE,
        deviceId,
    }),

    addFavorite: (serial, deviceId) => ({
        type: types.FAVORITE_DEVICE_ADDED,
        serial,
        deviceId,
    }),

    removeFavorite: (serial, deviceId) => ({
        type: types.FAVORITE_DEVICE_REMOVED,
        serial,
        deviceId,
    }),

    subscribeDeviceRemoved: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.device.setting.removed', cb);
    },

    unsubscribeDeviceRemoved: (serial, cb) => () => {
        wsm.unsubscribe(serial, 'hub.device.setting.removed', cb);
    },

    subscribeDeviceSettingUpdate: (serial, name, cb) => () => {
        wsm.subscribe(serial, name, cb);
    },

    unSubscribeDeviceSettingUpdate: (serial, name) => () => {
        wsm.unsubscribe(serial, name);
    },
};

export default actions;
