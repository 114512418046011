import React from 'react';
import { EZLOGIC_TITLE_LIST_OF_COMMANDS } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const DeviceAssociationsNotification = (props) => {
    const { associationCommands } = props;
    const { t } = useTranslate();

    return (
        <div className="association-notifications">
            {associationCommands && associationCommands.length && (
                <>
                    <div className="association-notifications_commands-wrapper">
                        <p className="association-notifications_commands-wrapper_title">
                            {t(EZLOGIC_TITLE_LIST_OF_COMMANDS)}:{' '}
                        </p>
                        <span className="association-notifications_commands-wrapper_commands">
                            {associationCommands.map((command) => {
                                return command + '; ';
                            })}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default DeviceAssociationsNotification;
