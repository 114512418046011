import React, { useEffect, useState, memo } from 'react';
import _debounce from 'lodash/debounce';
import EzloDevices from './EzloDevices';

const EzloDevicesMemo = memo(EzloDevices);

const DebouncedWebSocketComponent = (props) => {
    const [debouncedData, setDebouncedData] = useState(props);

    useEffect(() => {
        const debouncedSetData = _debounce(setDebouncedData, 100);

        debouncedSetData(props);

        return () => {
            debouncedSetData.cancel();
        };
    }, [props]);

    return <EzloDevicesMemo {...debouncedData} />;
};

export default DebouncedWebSocketComponent;
