import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import EzloParjectsSettings from './EzloParjectsSettings';

const mapStateToProps = (state) => ({
    detailedIntegrations: state.main.integrationList.integrations,
    ...state.integrations,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EzloParjectsSettings);
