import React from 'react';

import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';

import {
    EZLOGIC_HEADING_DELETE_ASSOCIATION,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DELETE_ASSOCIATION_MESSAGE,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const DeviceAssociationsConfirmationAction = (props) => {
    const { isConfirmModalVisible, setIsConfirmModalVisible, onRemoveAssociation, isVirtualContainerPage } = props;
    const { t } = useTranslate();
    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            onRemoveAssociation();
        }
        setIsConfirmModalVisible(false);
    };

    return (
        <ConfirmModal
            isOpened={isConfirmModalVisible}
            onClose={setIsConfirmModalVisible}
            title={t(EZLOGIC_HEADING_DELETE_ASSOCIATION)}
            text={t(EZLOGIC_TITLE_DELETE_ASSOCIATION_MESSAGE)}
            confirmButtonTitle={t(EZLOGIC_TITLE_DELETE)}
            onCancel={() => handleConfirmation(false)}
            onConfirm={() => handleConfirmation(true)}
            isVirtualContainerPage={isVirtualContainerPage}
        />
    );
};

export default DeviceAssociationsConfirmationAction;
