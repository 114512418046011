import React from 'react';
import { InputMaterial } from '../../../../../components';
import { NUMBER, NUMERIC, TEXT } from '../../constants';
import { restrictionRuleToPreventFloat } from '../utils';
import style from './SelectValueToCompareCloud.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_PLACEHOLDER_ENTER_VALUE, EZLOGIC_TITLE_VALUE } from '../../../../../constants/language_tokens';

const SelectValueToCompareCloud = ({ onDataUpdate, comparingValue, variableValueType }) => {
    const { t } = useTranslate();
    const handleChangeValueToCompare = (event) => {
        onDataUpdate({ comparingValue: event.target.value.toString() });
    };

    const preventDecimalNumber = (e) => {
        const {
            target: { value },
            key,
        } = e;

        if (restrictionRuleToPreventFloat(value, key)) {
            e.preventDefault();
        }
    };

    return (
        <div className={style.wrapper}>
            <InputMaterial
                label={t(EZLOGIC_TITLE_VALUE)}
                placeholder={t(EZLOGIC_PLACEHOLDER_ENTER_VALUE)}
                type={variableValueType === NUMERIC ? NUMBER : TEXT}
                onKeyPress={variableValueType === NUMERIC ? preventDecimalNumber : null}
                min={variableValueType === NUMERIC ? Number.MIN_SAFE_INTEGER : null}
                onChange={handleChangeValueToCompare}
                value={comparingValue}
            />
        </div>
    );
};

export default SelectValueToCompareCloud;
