import React from 'react';
import { EZLOGIC_TITLE_EZLO } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

export const PersonIcon = (props) => {
    const { t } = useTranslate();

    return (
        <svg {...props} viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
            <title>{t(EZLOGIC_TITLE_EZLO)}</title>
            <path
                d="M16 5C16 7.8 13.8 10 11 10C8.2 10 6 7.8 6 5C6 2.2 8.2 0 11 0C13.8 0 16 2.2 16 5ZM16 11L15.5 12.6C15.2 13.4 14.5 14 13.6 14H8.4C7.5 14 6.8 13.4 6.5 12.6L6 11C4.2 11 2.7 12.2 2.4 13.9L0 25H22L19.6 14C19.3 12.2 17.7 11 16 11Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default PersonIcon;
