import {
    EZLOGIC_TITLE_ANY_MOTION,
    EZLOGIC_TITLE_ANY_RESULT,
    EZLOGIC_TITLE_FACE_DETECTION,
    EZLOGIC_TITLE_FAILED,
    EZLOGIC_TITLE_FINISHED,
    EZLOGIC_TITLE_MESHBOT_DISABLED,
    EZLOGIC_TITLE_MESHBOT_ENABLED,
    EZLOGIC_TITLE_OBJECT_DETECTION,
    EZLOGIC_TITLE_PARTIALLY_FINISHED,
    EZLOGIC_TITLE_QR_CODE_DETECTION,
} from './language_tokens';

export const dataAndTimeList = [
    { name: 'timeOfTheDay', value: 'Time of the Day' },
    { name: 'specialTimeOfTheDay', value: 'Special Time of the Day' },
    { name: 'daysOfTheWeek', value: 'Days of the week' },
    { name: 'daysOfTheMonth', value: 'Days of the month' },
    { name: 'weeksOfTheMonth', value: 'Weeks of the Month' },
    { name: 'weeksOfTheYear', value: 'Weeks of the year' },
    { name: 'monthOfTheYear', value: 'Month of the year' },
    { name: 'year', value: 'Year' },
    { name: 'range', value: 'Range' },
];

export const localMeshbot = [
    { value: 'deviceState', name: 'Device' },
    { value: 'script', name: 'Script' },
    { value: 'sendHttpRequest', name: 'sendHttpRequest' },
];

export const cloudMeshbot = [{ value: 'deviceState', name: 'Device' }];

export const MESHBOT_STATE_VALUES = [
    {
        value: 'failed',
        label: EZLOGIC_TITLE_FAILED,
    },
    {
        value: 'finished',
        label: EZLOGIC_TITLE_FINISHED,
    },
    {
        value: 'partially_finished',
        label: EZLOGIC_TITLE_PARTIALLY_FINISHED,
    },
    {
        value: 'any_result',
        label: EZLOGIC_TITLE_ANY_RESULT,
    },
    {
        value: 'scene_enabled',
        label: EZLOGIC_TITLE_MESHBOT_ENABLED,
    },
    {
        value: 'scene_disabled',
        label: EZLOGIC_TITLE_MESHBOT_DISABLED,
    },
];

export const VIDOO_TYPES_LIST = [
    {
        value: 'any_motion',
        label: EZLOGIC_TITLE_ANY_MOTION,
    },
    {
        value: 'face_detection',
        label: EZLOGIC_TITLE_FACE_DETECTION,
    },
    {
        value: 'object_detection',
        label: EZLOGIC_TITLE_OBJECT_DETECTION,
    },
    {
        value: 'qr_code_detection',
        label: EZLOGIC_TITLE_QR_CODE_DETECTION,
    },
];

export const DELAY_TIME = 5000;
export const VIDOO = 'vidoo';
export const MOTION = 'motion';
export const HOTZONES = 'hotzones';
