import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentTrigger } from '../../containers/Ezlo/EzloMeshbot/utils';

const useTriggerSelect = (ruleId, groupId, isException) => {
    const triggers = useSelector((state) => state.meshBot?.local?.ruleTriggers) || {};
    const exceptions = useSelector((state) => state.meshBot?.local?.exceptions) || {};

    return isException
        ? getCurrentTrigger(exceptions?.[0]?.triggers, ruleId, groupId)
        : getCurrentTrigger(triggers, ruleId, groupId);
};

useTriggerSelect.propTypes = {
    ruleId: PropTypes.string,
    groupId: PropTypes.string,
    isException: PropTypes.bool,
};

export default useTriggerSelect;
