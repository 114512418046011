import React from 'react';

const IcActionsIcIntegration = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5 8.5C25.5711 8.5 27.25 6.82107 27.25 4.75C27.25 2.67893 25.5711 1 23.5 1C21.4289 1 19.75 2.67893 19.75 4.75C19.75 5.43982 19.9363 6.08613 20.2612 6.6414L18.3086 9.16829C17.4758 8.74107 16.5317 8.5 15.5312 8.5C14.127 8.5 12.8337 8.97497 11.8033 9.77301L9.07676 7.29436C9.30807 6.80733 9.4375 6.26253 9.4375 5.6875C9.4375 3.61643 7.75857 1.9375 5.6875 1.9375C3.61643 1.9375 1.9375 3.61643 1.9375 5.6875C1.9375 7.75857 3.61643 9.4375 5.6875 9.4375C6.14749 9.4375 6.58813 9.35468 6.99534 9.20314L10.0325 11.9642C9.6511 12.7603 9.4375 13.6521 9.4375 14.5938C9.4375 15.6258 9.69405 16.5979 10.1468 17.4497L6.78731 20.3512C6.20072 19.9708 5.50115 19.75 4.75 19.75C2.67893 19.75 1 21.4289 1 23.5C1 25.5711 2.67893 27.25 4.75 27.25C6.82107 27.25 8.5 25.5711 8.5 23.5C8.5 23.2141 8.46801 22.9357 8.40742 22.6682L12.0031 19.5628C12.9987 20.271 14.2164 20.6875 15.5312 20.6875C15.6618 20.6875 15.7914 20.6834 15.9199 20.6753L17.1961 24.504C16.4603 25.1887 16 26.1656 16 27.25C16 29.3211 17.6789 31 19.75 31C21.8211 31 23.5 29.3211 23.5 27.25C23.5 25.2044 21.8622 23.5414 19.8263 23.5008L18.6105 19.8534C19.2631 19.4705 19.838 18.9697 20.3061 18.3802L23.5002 19.7111C23.5001 19.7241 23.5 19.737 23.5 19.75C23.5 21.8211 25.1789 23.5 27.25 23.5C29.3211 23.5 31 21.8211 31 19.75C31 17.6789 29.3211 16 27.25 16C26.2061 16 25.2618 16.4266 24.5819 17.1149L21.4994 15.8306C21.5817 15.4312 21.625 15.0175 21.625 14.5938C21.625 13.2482 21.1889 12.0046 20.4504 10.9964L22.4865 8.36143C22.8088 8.45172 23.1488 8.5 23.5 8.5Z"
                fill="#1C1E21"
            />
        </svg>
    );
};

export default IcActionsIcIntegration;
