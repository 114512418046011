/**
 * Root app config prop, used to keep all the environment objects
 * */
export const ENVIRONMENTS = 'environments';

/**
 * Used to keep user-friendly OEM and environment name. Displayed in Environment select on internal domain.
 * */
export const NAME = 'name';
export const PK_OEM = 'pk_oem';
/**
 * X_APP key. See {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=OEM-EZLO+-+X-APP more info}
 * */
export const X_APP = 'x_app';
/**
 * ENV_UUID value. See {@link https://confluence.mios.com/display/EDOT/Servers+Info more info}
 * */
export const ENV_UUID = 'env_uuid';

/**
 * Used for Dashboard App url
 * */
export const DASHBOARD_URL = 'dashboard_url';

/**
 * Used for EULA Url
 * */
export const EULA_URL = 'eula_url';

/**
 * Used for Privacy Policy Url
 * */
export const PRIVACY_POLICY_URL = 'privacy_policy_url';

/**
 * Used to keep oem template master Pk_Account value
 * */
export const OEM_TEMPLATE_MASTER_PK_ACCOUNT = 'oem_template_master_pk_account';

/**
 * Used to keep env specific endpoints object
 * */
export const ENDPOINTS = 'endpoints';

/**
 * Key for "cloud" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const CLOUD = 'cloud';

/**
 * Key for "api-cloud" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const API_CLOUD = 'api-cloud';

/**
 * Key for "media-cloud" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const MEDIA_CLOUD = 'media-cloud';

/**
 * Key for "download-cloud" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const DOWNLOAD_CLOUD = 'download-cloud';

/**
 * Key for "upload-cloud" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const UPLOAD_CLOUD = 'upload-cloud';

/**
 * Key for "autha" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const AUTHA = 'autha';

/**
 * Key for "account" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const ACCOUNT = 'account';

/**
 * Key for "device" role server URL.
 * See example {@link https://mms1.qa.mios.com/info/config/config?Server=1&PK_Oem=1 here}
 * */
export const DEVICE = 'device';

/**
 * Android Resident mobile application. Used in password reset flow.
 * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
 * */
export const ANDROID_RESIDENT_APP_URL = 'android_resident_app_url';

/**
 * Android Installer mobile application. Used for password reset flow.
 * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
 * */
export const ANDROID_INSTALLER_APP_URL = 'android_installer_app_url';

/**
 * IOS Resident mobile application. Used for password reset flow.
 * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
 * */
export const IOS_RESIDENT_APP_URL = 'ios_resident_app_url';

/**
 * IOS Installer mobile application. Used for password reset flow.
 * PRD: https://confluence.mios.com/display/PDMT/PRD+-+Initial+password+creation+flow
 * */
export const IOS_INSTALLER_APP_URL = 'ios_installer_app_url';
