import {
    BLOCK_FIELD_TYPES,
    COMPARISON_DATA,
    DEVICE_NODE_SUPPORTED_TYPES,
    IDLE,
    INDEX_OF_VARIABLE_NAME,
} from '../constants/MeshbotConstant';

export const getDataState = (data, dataItem) => {
    //Todo NEED REFACTORING
    return Object.entries(data).reduce((acc, [serial, controller]) => {
        if (
            serial === 'globalScenes' ||
            (!controller && Object.keys(controller).length === 0) ||
            !dataItem ||
            !controller[dataItem]
        ) {
            return acc;
        }

        return [
            ...acc,
            ...controller[dataItem]?.map((item) => {
                return { ...item, serial, isConnected: controller.isConnected };
            }),
        ];
    }, []);
};

export const getSingleData = (data, serial) => data[serial];

export const setLocalStorage = (key, value) => {
    let val = value;
    if (typeof val === 'object') {
        try {
            val = JSON.stringify(val);
        } catch (err) {
            // eslint-disable-next-line
            console.error('Set Local Storage', err);
        }
    }
    localStorage.setItem(key, val);
};

export const getLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
};

export const getNameCapability = (data) => {
    if (data.hasOwnProperty('parameters')) {
        return getNameCapability(data.parameters[0]);
    } else {
        return data.capability;
    }
};

export const getNameVariable = (data) => {
    if (data.hasOwnProperty('parameters')) {
        return getNameVariable(data.parameters[0]);
    } else {
        return data.variable;
    }
};

export const delLocalStorage = (key) => {
    localStorage.removeItem(key);
};

/**
 * Returns formatted name of string passed as argument.
 * Returns error if passed argument is no a string.
 * @param {string} name - not formatted capability name
 * @returns {string} with capital first letter and with spaces instead underscores
 * */

export const formatNameWithUnderscores = (name) => {
    if (typeof name === 'string') {
        return (name.charAt(0).toUpperCase() + name.slice(1)).split('_').join(' ');
    }
    throw new Error('Function #formatNameWithUnderscores expect string as name parameter');
};

/**
 * Function filters device items depending on the device and item visibility.
 * @param {Array<Object>} deviceItems - not formatted capability name
 * @param {string} deviceId - not formatted capability name
 * @returns {Array<Object>} Returns an array of filtered device items
 * */
export const getFilteredDeviceItemsForTrigger = (deviceItems, deviceId) => {
    if (deviceId && Array.isArray(deviceItems)) {
        return deviceItems.filter((item) => {
            const visibilityCondition = item.show && item.hasGetter;
            const deviceCondition = item.deviceId === deviceId && DEVICE_NODE_SUPPORTED_TYPES.includes(item.valueType);

            return deviceCondition && visibilityCondition;
        });
    }
    throw new Error(
        'Function #getFilteredDeviceItemsForTrigger expect array as deviceItems parameter and deviceId parameter',
    );
};

/**
 * Returns formatted text for comparator label.
 * Returns error if passed argument is no a string.
 * @param {object} comparatorObj - object with data related to comparator
 * @returns {string} formatted label text
 * */

export const generateComparatorLabel = (comparatorObj) => {
    const { name, op: operator } = comparatorObj;

    if (name && operator) {
        return name === operator.split('_').join(' ')
            ? formatNameWithUnderscores(name)
            : `${formatNameWithUnderscores(name)} ( ${formatNameWithUnderscores(operator)} )`;
    }
};

export const parseDataForUpdatePayload = (addedItem, foundDevice) => {
    return {
        _id: addedItem._id,
        abstract_uuid: foundDevice.name,
        variableName: addedItem.name,
    };
};

export const extractNameOfVariable = (item) => {
    return item.name.split('.')[INDEX_OF_VARIABLE_NAME];
};

export const getSelectedComparator = (event) => {
    return event.target.value?.name === BLOCK_FIELD_TYPES.BUTTON_STATE
        ? {
              method: COMPARISON_DATA.METHOD.IS_BUTTON_STATE,
          }
        : {};
};
export const getComparingValue = (event) => {
    return event.target.value?.name === BLOCK_FIELD_TYPES.BUTTON_STATE ? IDLE : 0;
};
