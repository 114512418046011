import React from 'react';
import { IconButton } from '@material-ui/core';

import classNames from 'classnames';
import { MESHBOT_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';

import { meshbotDuplicateWrapper, disabled } from './meshbotDuplicate.module.scss';
import { DuplicateIcon } from '../../assets/icons';

const MeshbotDuplicate = ({ params, onClick }) => {
    const handleSelectRule = () => {
        if (params?.type !== MESHBOT_TYPES.LOCAL) {
            onClick(params);
        }
    };

    return (
        <div className={meshbotDuplicateWrapper}>
            <IconButton
                color="primary"
                onClick={handleSelectRule}
                disabled={params.type === MESHBOT_TYPES.LOCAL || params.isDisabled}
                className={classNames({ [disabled]: params.type === MESHBOT_TYPES.LOCAL || params.isDisabled })}
            >
                <DuplicateIcon />
            </IconButton>
        </div>
    );
};

export default MeshbotDuplicate;
