import * as meshbot from '../../constants/MeshbotConstant';

const getFwWeekDay = (date) => {
    let weekDay = date.getDay();
    if (weekDay === meshbot.ZERO_INT) {
        weekDay = meshbot.DAYS_IN_WEEK;
    }

    return weekDay;
};

const setFirstWeekDates = (startYearDate, daysRange) => ({
    startDate: new Date(startYearDate.getTime()),
    endDate: new Date(startYearDate.getTime() + daysRange * meshbot.MS_IN_DAY),
});

export const getWeekDaysRange = (year) => {
    if (year) {
        const startYearDate = new Date(Date.UTC(year, meshbot.ZERO_INT, meshbot.INITIAL_VALUE));
        const endYearDate = new Date(Date.UTC(year, meshbot.MONTH_NUMBER, meshbot.DATE_OF_THE_MONTH));

        const weeksDateRange = {};

        // define first week dates range
        const firstDayNumber = getFwWeekDay(startYearDate);
        const daysRange = meshbot.DAYS_IN_WEEK - firstDayNumber;

        let currentWeek = meshbot.ZERO_INT;
        // set the first week dates
        weeksDateRange[currentWeek] = setFirstWeekDates(startYearDate, daysRange);

        // set middle weeks dates
        do {
            currentWeek++;
            weeksDateRange[currentWeek] = {
                startDate: new Date(
                    weeksDateRange[currentWeek - meshbot.CORRECTION_FACTOR].endDate.getTime() + meshbot.MS_IN_DAY,
                ),
                endDate: new Date(
                    weeksDateRange[currentWeek - meshbot.CORRECTION_FACTOR].endDate.getTime() +
                        meshbot.DAYS_IN_WEEK * meshbot.MS_IN_DAY,
                ),
            };
        } while (weeksDateRange[currentWeek].endDate.getTime() < endYearDate.getTime());

        // set/truncate the last week date
        weeksDateRange[currentWeek].endDate = new Date(endYearDate.getTime());

        return weeksDateRange;
    }
};

export const getDatesRangeByWeek = (week, weekNumber) => {
    if (week) {
        const weeksDateRange = getWeekDaysRange(new Date().getFullYear());

        if (week.name === meshbot.LAST_WEEK_NAME) {
            const lastWeekNumber = Object.keys(weeksDateRange).length - meshbot.CORRECTION_FACTOR;

            return weeksDateRange[lastWeekNumber];
        }

        return weeksDateRange[weekNumber];
    }
};

export const getTitleForTooltip = (datesWeek) => {
    if (datesWeek) {
        let firstDayOfTheWeek = datesWeek.startDate.getDate();
        let lastDayOfTheWeek = datesWeek.endDate.getDate();
        let monthFirstDayOfTheWeek = datesWeek.startDate.getMonth() + meshbot.CORRECTION_FACTOR;
        let monthLastDayOfTheWeek = datesWeek.endDate.getMonth() + meshbot.CORRECTION_FACTOR;
        const year = datesWeek.startDate.getFullYear();

        firstDayOfTheWeek =
            firstDayOfTheWeek < meshbot.OPERAND ? `${meshbot.SINGLE_ZERO}${firstDayOfTheWeek}` : firstDayOfTheWeek;
        lastDayOfTheWeek =
            lastDayOfTheWeek < meshbot.OPERAND ? `${meshbot.SINGLE_ZERO}${lastDayOfTheWeek}` : lastDayOfTheWeek;
        monthFirstDayOfTheWeek =
            monthFirstDayOfTheWeek < meshbot.OPERAND
                ? `${meshbot.SINGLE_ZERO}${monthFirstDayOfTheWeek}`
                : monthFirstDayOfTheWeek;
        monthLastDayOfTheWeek =
            monthLastDayOfTheWeek < meshbot.OPERAND
                ? `${meshbot.SINGLE_ZERO}${monthLastDayOfTheWeek}`
                : monthLastDayOfTheWeek;

        return `${meshbot.FROM} ${firstDayOfTheWeek}.${monthFirstDayOfTheWeek}.${year} ${meshbot.TO} ${lastDayOfTheWeek}.${monthLastDayOfTheWeek}.${year}`;
    }
};
