import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../actions';
import Navigation from './Navigation';
import { atomRE } from '../../constants/Regexp';
import { prepareRules } from '../Ezlo/EzloDevice/utils';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    isAtom: atomRE.test(state.ezlo && state.ezlo.serial),
    rules: prepareRules(state),
    ezloSerial: state.ezlo && state.ezlo.serial,
    location: state.router.location,
    isCustomizationProgressing: state.account.isCustomizationProgressing,
    currentPkAccount: state.account?.data?.PK_Account,
    isShowModalClearMeshBot: state.main.isShowModalClearMeshBot,
    isEzlos: state.ezlos && state.ezlos.items && state.ezlos.items.length > 0,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps, mergeProps))(Navigation);
