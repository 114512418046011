import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import HeaderComponent from '../../components/Header';
import PinCodeForm from '../PinCodeForm';
import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import UsersActions from '../../../../../actions/UsersActions';
import { getInitialDevices, setPanel } from '../utils';
import { validationSchema } from '../PinCodeForm/validationSchema';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_PIN_CODE } from '../../../../../constants/language_tokens';

const PinCodeEdit = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { selectedPinCode } = useSelector(({ users }) => users);
    const { name, meta, uuid } = selectedPinCode;
    const pinMeta = JSON.parse(meta);
    const ezloData = useSelector(({ ezlo }) => ezlo.data);
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    const devices = getInitialDevices(ezloData, pinMeta, name);
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // const devices = getInitialDevices(ezloData, pinMeta, name, selectedPinCode);

    const initialValues = {
        name,
        pin: '',
        devices,
    };
    setPanel(initialValues, pinMeta.panel);
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    const handleClickSave = (values) => {
        const { name, pin, panel, devices } = values;
        const deviceList = devices.filter((device) =>
            Boolean(device.checked || device.isRemovePinCodeFromDevice || device.pinCodeKey),
        );
        const deviceIds = deviceList.filter(({ checked }) => checked).map((device) => device._id);

        const pinCodeData = {
            name,
            pin,
            uuid,
            meta: {
                deviceIds,
            },
        };
        setPanel(pinCodeData.meta, panel);
        dispatch(UsersActions.updatePinCode(pinCodeData, deviceList, selectedPinCode.name, t));
    };
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // const handleClickSave = (values) => {
    //     dispatch(UsersActions.updatePinCode(uuid, values, selectedPinCode.name, t));
    // };

    return (
        <>
            <HeaderComponent title={t(EZLOGIC_TITLE_PIN_CODE)} />

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClickSave}>
                <PinCodeForm isEditing={true} />
            </Formik>
        </>
    );
};

export default withErrorBoundary(PinCodeEdit, FallbackMeshbotNodeComponent);
