import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';

import { Layer } from '../../components';

import styles from './Dialog.module.scss';
import { PlusIcon } from '../../assets/icons';

/**
 * Create a new dialog.
 *
 * <Dialog
 *    title="Title",
 *    open={true|false},
 *    actions={<Button>}
 *    onHide={function () {}},
 * >
 *     ..........
 * </Dialog>
 */
const Dialog = ({
    title,
    open,
    showCloseButton,
    onHide,
    children,
    actions,
    className,
    style,
    headerStyle,
    bodyStyle,
    footerStyle,
}) => {
    const containerClassName = classNames(styles.dialogContainer, className);
    const isLayerDisplayed = open ? styles.layerShow : styles.layerHide;

    return (
        <Layer className={classNames(styles.dialogLayer, isLayerDisplayed)}>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="transition"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={200}
            >
                {open && (
                    <div className={containerClassName} style={style}>
                        <div className={styles.dialogHeader}>
                            <div className={styles.dialogTitle} style={headerStyle}>
                                {title}
                            </div>
                            {showCloseButton && <PlusIcon className={styles.dialogCloseBtn} onClick={onHide} />}
                        </div>
                        <div className={styles.dialogBody} style={bodyStyle}>
                            {children}
                        </div>
                        {actions && (
                            <div className={styles.dialogFooter} style={footerStyle}>
                                {actions}
                            </div>
                        )}
                    </div>
                )}
                {open && <div className={styles.dialogOverlay} onClick={onHide} />}
            </ReactCSSTransitionGroup>
        </Layer>
    );
};

Dialog.defaultProps = {
    open: false,
    showCloseButton: true,
    actions: null,
    onHide: function () {},
};

export default Dialog;
