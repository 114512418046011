import React from 'react';
import classes from 'classnames';
import { NUCAL } from '../../../../../constants/Integrations';
import EzloIntegrationsIcon from '../EzloIntegrationsIcon';
import styles from '../styles.module.scss';

const EzloIntegrationCardInfo = ({
    icon,
    handleClick,
    integrationName,
    integrationTypeIcon,
    count,
    integrationType,
    prefix,
    prefixType,
    integrationCountNaming,
}) => {
    const getWrapperClassName = (integrationType) => {
        if (integrationType === NUCAL) {
            return styles.cloudRow;
        } else {
            return styles.row;
        }
    };

    const wrapperClassName = getWrapperClassName(integrationType);

    return (
        <div className={styles.integration} onClick={handleClick}>
            <EzloIntegrationsIcon icon={icon} wrapperClassName={wrapperClassName} integrationType={integrationType} />
            <span className={classes(styles.prefixWrapper, styles[prefixType])}>{prefix}</span>
            <div className={styles.rowType}>
                <span className={styles.integrationName}>{integrationName}</span>
                <p>
                    <span className={styles.integrationTypeIcon}>{integrationTypeIcon}</span>
                    <span className={styles.devicesCount}>
                        <span>{count}</span> <span>{integrationCountNaming}</span>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default EzloIntegrationCardInfo;
