import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../../../EzloMeshbot/components/SelectComponent';

import { formatNameWithUnderscores } from '../../../../../../../helpers/helpersMeshBot';
import * as plugin from '../../../../../../../constants/Plugins';

import style from '../RequestFields/requestFields.module.scss';

const SelectEnum = (props) => {
    const { action, name, label, className, capabilityId, onSetValueItem } = props;

    return (
        <div className={`${style.requestFields} ${style.requestFields__select} ${className}`}>
            <p className={style.requestFields__label}>{label ?? formatNameWithUnderscores(name)}</p>
            <SelectComponent
                name={name}
                value={action[name] ?? ''}
                label="Choose scale"
                info={plugin.info.enum}
                onChange={(e) => onSetValueItem(e, capabilityId, action?.id, action?.type)}
            >
                {action?.enum?.map(({ value, label }) => {
                    return (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </div>
    );
};

SelectEnum.propTypes = {
    action: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    capabilityId: PropTypes.string,
    onSetValueItem: PropTypes.func,
};

export default SelectEnum;
