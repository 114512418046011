import React from 'react';
import { useSelector } from 'react-redux';

import { WITH_BACKUPS_COMPONENT } from '../../constants/Backups';
import { structuredBackupsSelector } from '../../containers/Ezlo/EzloHubBackups/selectors';

export const withBackups = (WrappedComponent) => {
    const WithBackups = (props) => {
        const backupsData = useSelector(structuredBackupsSelector);

        return <WrappedComponent {...props} {...backupsData} />;
    };

    WithBackups.displayName = WITH_BACKUPS_COMPONENT;

    return WithBackups;
};
