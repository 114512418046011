import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { DATE_FORMAT, INLINE, NORMAL } from '../../../../../../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_INCORRECT_DATE } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

const SystemHistorySearchByDatePicker = (props) => {
    const { selectedDate, handleChangeDate, placeholder, isDateValid } = props;
    const { t } = useTranslate();

    return (
        <KeyboardDatePicker
            disableToolbar
            variant={INLINE}
            format={DATE_FORMAT}
            margin={NORMAL}
            value={selectedDate}
            onChange={handleChangeDate}
            placeholder={placeholder}
            error={!isDateValid}
            helperText={!isDateValid && t(EZLOGIC_TITLE_INCORRECT_DATE)}
        />
    );
};

export default SystemHistorySearchByDatePicker;
