import * as Actions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TemplateCreate from './TemplateCreate';

const mapStateToProps = (state) => ({
    templateName: state.meshBot.cloud.name,
    ruleCloudTriggers: state.meshBot.cloud.ruleCloudTriggers,
    ruleCloudAction: state.meshBot.cloud.ruleCloudAction,
    selectedNotificationCapability: state?.notifications?.notificationSelectedCapability,
    notificationType: state?.notifications?.notificationType,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCreate);
