import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import UsersActions from '../../../../../actions/UsersActions';
import { getInitialValuesForUserForm } from '../utils';
import UserForm from '../UserForm';
import Header from '../../components/Header';
import { validationSchema } from '../UserForm/validationSchema';
import { EZLOGIC_TITLE_USER_DETAILS } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const UserCreate = () => {
    const dispatch = useDispatch();
    const initialValues = getInitialValuesForUserForm();
    const { t } = useTranslate();

    const handleClickSave = (values) => {
        const { Username, PK_PermissionRole, Password, FirstName, Name, Email, Controllers } = values;
        const numberControllerList = Controllers.map((controller) => Number(controller));

        const userData = {
            create: {
                Username,
                Email,
                Password,
            },
            update: {
                FirstName,
                Name,
                PK_PermissionRole: PK_PermissionRole.value,
            },
            allowAccessOnControllers: {
                PK_PermissionRole: PK_PermissionRole.value,
                Arguments: numberControllerList,
            },
        };

        dispatch(UsersActions.createNewUser(userData, t));
    };

    return (
        <>
            <Header title={t(EZLOGIC_TITLE_USER_DETAILS)} />

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClickSave}>
                <UserForm />
            </Formik>
        </>
    );
};

export default UserCreate;
