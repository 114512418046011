import hash from './uniqueHash';

export const DEVICES_PLUGIN_EDIT = 'edit';
export const DEVICES_PLUGIN_SETTING = 'settings';
export const DEVICES_PLUGIN_CREATE = 'new';
export const WIFI_GENERATOR_PRIVATE = '/ezlo/plugins/wifi-generator/private';
export const ROWS_PER_PAGE = [10, 25, 50];
export const METHOD = 'method';
export const INTEGER = 'integer';
export const HEADERS = 'headers';
export const ACTION = 'Action';
export const URI = 'uri';
export const DATA = 'data';
export const CONTENT_TYPE = 'content_type';
export const KEY = 'key';
export const VALUE = 'value';
export const COMMAND = 'command';
export const STRING = 'string';
export const OBJECT = 'object';
export const SCALE = 'scale';
export const TARGET_VALUE = 'target_value';

export const TARGET_DELTA = 'target_delta';

export const LIST_PROTOCOLS = [
    { id: hash(), value: 'POST', label: 'POST' },
    { id: hash(), value: 'GET', label: 'GET' },
    { id: hash(), value: 'PUT', label: 'PUT' },
    { id: hash(), value: 'HEAD', label: 'HEAD' },
    { id: hash(), value: 'DELETE', label: 'DELETE' },
    { id: hash(), value: 'CONNECT', label: 'CONNECT' },
    { id: hash(), value: 'OPTIONS', label: 'OPTIONS' },
    { id: hash(), value: 'TRACE', label: 'TRACE' },
];
