import React from 'react';
import { getListOfCapabilitiesForControllerNode, info } from '../../../utils';
import { MenuItem } from '@material-ui/core';
import SelectComponent from '../../SelectComponent';
import { useTranslate } from '../../../../../../features/languages';

const ControllerCapabilityValue = ({ selectedControllerCapabilityValue, onChange, selectedControllerCapability }) => {
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={selectedControllerCapabilityValue ? selectedControllerCapabilityValue : ''}
            label={info.hints.value}
            info={info.text.value}
            onChange={(e) => onChange({ selectedControllerCapabilityValue: e.target.value })}
        >
            {getListOfCapabilitiesForControllerNode(selectedControllerCapability)?.map((item) => {
                return (
                    <MenuItem key={item.id} value={item.value}>
                        {t(item.label)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ControllerCapabilityValue;
