import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Header } from '../../../../../components';
import DeviceGroupLayout from '../DeviceGroupLayout';
import { LISTING_PAGE, CREATE_BUTTON } from '../../LanguageTokensConstants';
import BoxWithLinearProgress from '../../../../../components/BoxWithLinearProgress';
import { PRIMARY, CONTAINED } from '../../../../../constants/MeshbotConstant';

import styles from './DeviceGroupPage.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { validateDataLoading } from '../../../utils';

const DeviceGroupPage = () => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslate();
    const state = useSelector((state) => state);
    const isDeviceGroupsLoading = validateDataLoading(state);

    const handleCreateNewDeviceGroupBtn = () => {
        history.push(`${location.pathname}/create`);
    };

    return (
        <BoxWithLinearProgress isLinearProgress={isDeviceGroupsLoading}>
            <Header headerClassName={styles.header}>
                <h1 className={styles.title}>{t(LISTING_PAGE)}</h1>
                <Button
                    color={PRIMARY}
                    startIcon={<AddIcon />}
                    variant={CONTAINED}
                    className={styles.deviceGroupCreateBtn}
                    onClick={handleCreateNewDeviceGroupBtn}
                >
                    {t(CREATE_BUTTON)}
                </Button>
            </Header>
            <DeviceGroupLayout />
        </BoxWithLinearProgress>
    );
};

export default DeviceGroupPage;
