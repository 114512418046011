import React, { useRef } from 'react';

import useMeshBotsTableContext from '../../../../../containers/Ezlo/EzloMeshbots/hooks/useMeshBotsTableContext';
import { LabelDropdownContextProvider } from '../../hooks/useLabelDropdownContext';
import LabelSelectedButton from '../../LabelSelectedButton';
import LabelsDropdownPopper from '../LabelsDropdown/LabelsDropdownPopper';
import useSelectedMeshBotsSelectedLabelsDropdownState from '../../hooks/useSelectedMeshBotsSelectedLabelsDropdownState';

export const LabelSelectedDropdown = () => {
    const anchorRef = useRef(null);
    const { selectedMeshBotsNumber } = useMeshBotsTableContext();
    const { applyLabelSelectionChanges, toggleOpen, selectLabel, isEdit, labelsWithSelectStatus, open, setOpen } =
        useSelectedMeshBotsSelectedLabelsDropdownState();

    if (!Boolean(selectedMeshBotsNumber)) {
        return null;
    }

    const contextValue = {
        selectLabel,
        labelsWithSelectStatus,
        isEdit,
        onApplyClick: applyLabelSelectionChanges,
    };

    return (
        <LabelDropdownContextProvider value={contextValue}>
            <div>
                <LabelSelectedButton onButtonClick={toggleOpen} anchorRef={anchorRef} />

                <LabelsDropdownPopper anchorRef={anchorRef} popperOpen={open} setPopperOpen={setOpen} />
            </div>
        </LabelDropdownContextProvider>
    );
};

export default LabelSelectedDropdown;
