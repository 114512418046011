import React from 'react';
import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_MORE } from '../../../../../constants/language_tokens';
import SystemMeshBotsSwitch from '../SystemMeshBotsSwitch';
import useMenuState from '../../../../../hooks/useMenuState';
import { moreMenuAnchorOrigin, moreMenuTransformOrigin } from '../../constants';

const MoreMenuButton = () => {
    const { menuOpen, openMenu, closeMenu, anchorEl } = useMenuState();
    const { t } = useTranslate();
    const buttonTitle = t(EZLOGIC_TITLE_MORE);

    return (
        <div>
            <Tooltip title={buttonTitle}>
                <Button color="primary" startIcon={<MoreVert />} onClick={openMenu}>
                    {buttonTitle}
                </Button>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={closeMenu}
                PaperProps={{ elevation: 1 }}
                getContentAnchorEl={null}
                anchorOrigin={moreMenuAnchorOrigin}
                transformOrigin={moreMenuTransformOrigin}
            >
                <MenuItem>
                    <SystemMeshBotsSwitch />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default MoreMenuButton;
