import { axiosCloudConfig } from '../api/config';
import mainTypes from '../constants/ActionTypes/Main';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { sleep } from './sleep';

export const extractCachedData = (params) => {
    const { parsedData } = getCachedDataFromStorage();
    const { call: method } = params;

    return new Promise((resolve) => {
        if (parsedData?.hasOwnProperty(method)) {
            fetchAndSaveDataToCache(params);
            resolve(parsedData[method]);
        } else {
            fetchAndSaveDataToCache(params).then((result) => resolve(result));
        }
    });
};

export const getCachedDataFromStorage = () => {
    const storageKey = localStorage.getItem(mainTypes.CLOUD_ENVIRONMENT);
    const cachedData = localStorage.getItem(storageKey);
    const parsedData = JSON.parse(cachedData);

    return { parsedData, storageKey };
};

const fetchAndSaveDataToCache = async (params) => {
    const { call: method } = params;

    const buildRequestParams = {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(params),
    };

    try {
        const response = await fetchRetry(buildRequestParams);
        const { status, data } = await response.json();

        if (status !== mainTypes.SUCCESS_STATUS) {
            throw data;
        }

        const keys = Object.keys(data);
        const key = keys[mainTypes.KEY_INDEX];

        setDataToCache(data[key], method);

        return data[key];
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

const setDataToCache = (data, method) => {
    const { storageKey, parsedData } = getCachedDataFromStorage();
    const savedData = parsedData ?? {};
    savedData[method] = data;

    localStorage.setItem(storageKey, JSON.stringify(savedData));
};

const fetchRetry = async (
    fetchParams,
    { limit = mainTypes.DEFAULT_LIMIT_COUNT, delay = mainTypes.DEFAULT_TIMEOUT, ...opts } = {},
) => {
    async function onError(err) {
        const shouldRetry = limit > mainTypes.MIN_LIMIT_COUNT;
        if (!shouldRetry) {
            throw err;
        }

        await sleep(delay);

        return fetchRetry(fetchParams, { limit: limit - mainTypes.STEP_DECREASING_LIMIT, delay, ...opts });
    }

    return fetch(API_CLOUD_EZLO_REQUEST_URL(), fetchParams).catch(onError);
};
