import React from 'react';
import classNames from 'classnames';
import { Input, Popover } from '@material-ui/core';

import { input_wrapper, input_block, input_container, icon, large_wrapper } from './SearchPopover.module.scss';
import { SearchIcon } from '../../assets/icons';

const SearchPopover = ({ placeholder, onChangeFilter, anchor, handleClose, children, size }) => {
    const className = classNames(input_wrapper, {
        [large_wrapper]: size === 'large',
    });

    return (
        <Popover
            elevation={2}
            id="abstracts-menu"
            anchorEl={anchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleClose}
        >
            <div className={className}>
                <div className={input_block}>
                    <Input
                        className={input_container}
                        placeholder={placeholder}
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />

                    {children}

                    <div className={icon}>
                        <SearchIcon />
                    </div>
                </div>
            </div>
        </Popover>
    );
};

export default SearchPopover;
