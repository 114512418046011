export const SCRIPT_EDIT = 'edit';
export const SCRIPT_CREATE = 'create';
export const ROUTE_SCRIPT_LIST = '/ezlo/scripts';
export const ROUTE_PRIVATE = `${ROUTE_SCRIPT_LIST}/private`;

/**
 * Lua Script creation restriction that allows to enter up to 30 characters
 * {@link https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesscriptsadd}
 */

export const LUA_SCRIPT_NAME_INPUT_MAX_LENGTH = 29;
export const NEW_SCRIPT = 'newScript';
