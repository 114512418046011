import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import {
    info,
    getCurrentExpressionValue,
    getValueForEmptyList,
    getCurrentExpressionParameters,
} from '../../containers/Ezlo/EzloMeshbot/utils';
import useExpressionList from '../../hooks/useExpressionList';
import { BLOCK_FIELD_TYPES } from '../../constants/MeshbotConstant';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_CURRENT_VALUE } from '../../constants/language_tokens';

const SelectExpressionValue = ({ expressionType, selectedExpression, onSelectExpression, capabilityValueType }) => {
    const expressionList = useExpressionList(expressionType, capabilityValueType);
    const selectedValue = expressionList.length ? selectedExpression : getValueForEmptyList(expressionType);
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={selectedValue}
            label={info.hints.value}
            info={info.text.value}
            onChange={onSelectExpression}
            disabled={!expressionList.length}
            renderValue={(value) => <div>{value}</div>}
            deviceSetting={
                expressionList.length && selectedExpression ? (
                    <div>
                        {t(EZLOGIC_TITLE_CURRENT_VALUE)}{' '}
                        {expressionType === BLOCK_FIELD_TYPES.VARIABLE &&
                            `(${getCurrentExpressionParameters(expressionList, selectedExpression)})`}
                        : {getCurrentExpressionValue(expressionList, selectedExpression)}
                    </div>
                ) : null
            }
        >
            {expressionList?.map((expression) => (
                <MenuItem key={expression.name} value={expression.name}>
                    {expression.name}
                </MenuItem>
            ))}
        </SelectComponent>
    );
};

export default SelectExpressionValue;
