import React from 'react';

import { CLOUD_VARIABLE, VALUE_TYPE } from '../../../../../../constants/MeshbotConstant';
import useAbstractState from '../../../hooks/useAbstractState';
import SelectValueToCompareCloud from '../../SelectValueToCompareCloud';
import ComparedCloudVariableSelectForCloudMeshBot from './ComparedCloudVariableSelectForCloudMeshBot';
import { SelectValueType } from './SelectValueType';

const DynamicComparisonFields = ({
    showSelectValueType,
    showSelectValueToCompare,
    handleCloudVariableTriggerUpdate,
    ruleCloudTrigger,
    ruleCloudTriggerId,
}) => {
    const { comparingValue, selectedValueType, variableValueType } = ruleCloudTrigger;

    const variablesInfo = useAbstractState();

    return (
        <>
            {showSelectValueToCompare && (
                <SelectValueType
                    onDataUpdate={handleCloudVariableTriggerUpdate}
                    selectedValueType={selectedValueType}
                />
            )}
            {showSelectValueType === VALUE_TYPE && (
                <SelectValueToCompareCloud
                    onDataUpdate={handleCloudVariableTriggerUpdate}
                    comparingValue={comparingValue}
                    variableValueType={variableValueType}
                />
            )}
            {variablesInfo && showSelectValueType === CLOUD_VARIABLE && (
                <ComparedCloudVariableSelectForCloudMeshBot
                    ruleCloudTrigger={ruleCloudTrigger}
                    ruleCloudTriggerId={ruleCloudTriggerId}
                />
            )}
        </>
    );
};

export { DynamicComparisonFields };
