import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import CreateDeviceTemplate from './CreateDeviceTemplate';
import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    isRequestStatus: state.plugins.isRequestStatus,
    isRequestStatusMessage: state.plugins.isRequestStatusMessage,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default withErrorBoundary(
    connect(mapStateToProps, mapDispatchToProps)(CreateDeviceTemplate),
    FallbackMeshbotNodeComponent,
);
