import hash from './uniqueHash';
import React from 'react';
import {
    EZLOGIC_TITLE_ACTIVE_INSTALLATION_DESCRIPTION,
    EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE,
    EZLOGIC_TITLE_ON,
    EZLOGIC_TITLE_PRIVATE_TEMPLATE_TO_MARKET,
    EZLOGIC_TITLE_TO,
    EZLOGIC_TITLE_WOULD_YOU_LIKE_TO,
    EZLOGIC_TITLE_YOU_HAVE,
} from './language_tokens';

export const DASHBOARD_SET = 'dashboard_set';
export const PUBLIC_SET = 'public_set';

/**
 * Read all OEMs records
 * More info: https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Public+-+List
 * */
export const ALL_OEMS_ID = 0;

/**
 * set to 1 if you only want your own dashboards and tiles
 * More info: https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Public+-+List
 * */
export const DEFAULT_CREATOR_VALUE = 0;
export const CREATOR = 1;
export const PORT = 8000;
export const REMOVE = 'remove';
export const PRIVATE = 'private';
export const PRIVATE_GENERATOR = 'private_generator';
export const PUBLISH = 'publish';
export const PUBLISH_GENERATOR = 'publish_generator';
export const MARKETPLACE = 'marketplace';
export const MARKETPLACE_GENERATOR = 'marketplace_generator';

export const PLUGIN_ALREADY_EXIST =
    'This plugin already exists in your account. You can manage your plugins on the My Private Plugins tab';
export const PRIVATE_TEMPLATE_TO_MARKETPLACE = EZLOGIC_TITLE_PRIVATE_TEMPLATE_TO_MARKET;
export const INFORMATION = 'information';
export const INFO = 'info';
export const TEMPLATE_INFO = 'template_info';
export const REJECTED = 'rejected';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WIFI_PLUGIN_MODEL = 'wifi_plugin_model';
export const CODE = 'code';
export const DEVICE_NAME = 'device_name';
export const MAC_ADDRESS = 'mac_address';
export const IP_ADDRESS = 'ip_address';
export const FIELD_PORT = 'port';
export const USER_NAME = 'username';
export const PASSWORD = 'password';
export const DEVICE_TYPE = 'device_type';
export const MODEL = 'model';
export const SUBCATEGORY = 'subcategory';
export const CATEGORY = 'category';
export const WIFI = 'wifi';
export const EDGE = 'edge';
export const CREATE = 'create';
export const EDIT = 'edit';
export const TIMEOUT = 5000;
export const COMPANY_SET = 'custom_scripts_company_set';
export const COMPANY_LIST = 'custom_scripts_company_list';
export const WIFI_DEVICE_GENERATOR = 'ezlo.wifi_device_generator';
export const EDGE_PRIVATE = '/ezlo/plugins/edge/private';
export const ENUM = 'enum';
export const INT = 'int';
export const FLOAT = 'float';
export const NUMBER = 'number';
export const PATTERN = 'pattern';
export const INTERVAL = 'poll_interval';
export const UPGRADE = 'upgrade';
export const CUSTOM_PLUGIN = 'custom_plugin';

export const INSTALL = 'install';
export const COPY_AND_UPGRADE = 'copy_and_upgrade';
export const COPING = 'coping';
export const VALVE = 'valve';
export const ERROR_OCCURRED_WHILE_UPDATING = 'An error occurred while coping';
export const COPY = 'copy';
export const CONFIRM_ACTION = EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE;
export const OUTLINED = 'outlined';
export const TEMPLATE_FORM_FIELD_SIZE = 'small';
export const IMG_FIELD_ID = 'imgTextField';
export const IMG_ALT = 'img';
export const AUTHOR = 'author';
export const DESCRIPTION = 'description';
export const SUPPORTED_MODELS = 'supported_models';
export const GetRemoveContent = (countInstalled, t) => (
    <div className="modal-plugin__confirmation-title">
        {t(EZLOGIC_TITLE_YOU_HAVE)} <span>{countInstalled}</span> {t(EZLOGIC_TITLE_ACTIVE_INSTALLATION_DESCRIPTION)}
    </div>
);

export const CONFIRM = 'confirm';

export const getUpgradeText = (pluginName, versionForUpgrade, serial, t) => (
    <span>
        {t(EZLOGIC_TITLE_WOULD_YOU_LIKE_TO)} {pluginName} {t(EZLOGIC_TITLE_ON)} {serial} {t(EZLOGIC_TITLE_TO)} v.
        {versionForUpgrade}?
    </span>
);

export const info = {
    pattern: (
        <>
            <h4>Pattern</h4>
            <p>Lua template for parsing the results of an http request e.g.: &quot;pressure&quot;</p>
        </>
    ),
    poll_interval: (
        <>
            <h4>Interval</h4>
            <p>Interval between http requests in seconds</p>
        </>
    ),
    enum: (
        <>
            <h4>Scale</h4>
            <p>Pressure scale [kilo_pascal, pound_per_square_inch, inches_of_mercury]</p>
        </>
    ),
};

export const ACTIONS_LABELS = [
    { id: 'off', value: 'Turn off' },
    { id: 'on', value: 'Turn on' },
    { id: 'level', value: 'Set din level' },
];

export const DEVICE_TYPES = [
    { id: '1', value: 'sensor', label: 'Common Sensors' },
    { id: '2', value: 'sensor.motion', label: 'Sensor Motion' },
    { id: '3', value: 'sensor.multisensor', label: 'Multi Sensor' },
    { id: '4', value: 'light.strip', label: 'Light Strip' },
    { id: '5', value: 'light.bulb', label: 'Light Bulb' },
    { id: '6', value: 'light.controller', label: 'Light Controller' },
    { id: '7', value: 'thermostat', label: 'Thermostat' },
    { id: '8', value: 'switch.outlet', label: 'Plug-Switch' },
    { id: '9', value: 'dimmer.outlet', label: 'Plug-Dimmer' },
    { id: '10', value: 'switch.inwall', label: 'In-wall Switch' },
    { id: '11', value: 'dimmer.inwall', label: 'In-wall Dimmer' },
    { id: '12', value: 'multi.outlet', label: 'Outlet with a few separate swithes' },
    { id: '13', value: 'doorlock', label: 'Doorlock' },
    { id: '14', value: 'siren', label: 'Siren' },
    { id: '15', value: 'doorbell', label: 'Doorbell' },
    { id: '16', value: 'repeater', label: 'Repeater' },
    { id: '17', value: 'shutter.valve', label: 'Shutter Valve' },
    { id: '18', value: 'shutter.garage', label: 'Shutter Garage' },
    { id: '19', value: 'shutter.roller', label: 'Shutter Roller' },
    { id: '20', value: 'controller.remote', label: 'Remote Controller' },
    { id: '21', value: 'controller.portable', label: 'Portable Controller' },
    { id: '22', value: 'controller.panic', label: 'Panic button' },
    { id: '23', value: 'mousetrap', label: 'Trap for Mouse' },
    { id: '24', value: 'transmitter.ir', label: 'Transmitter' },
    { id: '25', value: 'meter.power', label: 'Power meter' },
    { id: '26', value: 'meter.flow', label: 'Flow meter' },
    { id: '27', value: 'camera', label: 'Camera' },
];

export const DEVICE_CATEGORY = [
    { id: hash(), value: 'interface', label: 'Interface', subcategory: [] },
    {
        id: hash(),
        value: 'dimmable_light',
        label: 'Dimmable Light',
        subcategory: [
            { id: hash(), value: 'dimmable_bulb', label: 'Bulb' },
            { id: hash(), value: 'dimmable_plugged', label: 'Plugged' },
            { id: hash(), value: 'dimmable_in_wall', label: 'In Wall' },
            { id: hash(), value: 'dimmable_colored', label: 'RGB' },
        ],
    },
    {
        id: hash(),
        value: 'switch',
        label: 'Switch',
        subcategory: [
            { id: hash(), value: 'interior_plugin', label: 'Interior' },
            { id: hash(), value: 'exterior_plugin', label: 'Exterior' },
            { id: hash(), value: 'in_wall', label: 'In Wall' },
            { id: hash(), value: 'refrigerator', label: 'Refrigerator' },
            { id: hash(), value: 'valve', label: 'Valve' },
            { id: hash(), value: 'relay', label: 'Relay' },
        ],
    },
    {
        id: hash(),
        value: 'security_sensor',
        label: 'Security Sensor',
        subcategory: [
            { id: hash(), value: 'door', label: 'Door Sensor' },
            { id: hash(), value: 'leak', label: 'Leak Sensor' },
            { id: hash(), value: 'motion', label: 'Motion Sensor' },
            { id: hash(), value: 'smoke', label: 'Smoke Sensor' },
            { id: hash(), value: 'co', label: 'CO Sensor' },
            { id: hash(), value: 'glass', label: 'Glass Break Sensor' },
            { id: hash(), value: 'co2', label: 'CO2 Sensor' },
            { id: hash(), value: 'gas', label: 'Gas Sensor' },
            { id: hash(), value: 'heat', label: 'Heat Sensor' },
        ],
    },
    {
        id: hash(),
        value: 'hvac',
        label: 'HVAC',
        subcategory: [
            { id: hash(), value: 'hvac', label: 'HVAC' },
            { id: hash(), value: 'heater', label: 'Heater' },
            { id: hash(), value: 'cooler', label: 'Cooler' },
            { id: hash(), value: 'custom_hvac', label: 'Custom HVAC' },
        ],
    },
    {
        id: hash(),
        value: 'camera',
        label: 'Camera',
        subcategory: [
            { id: hash(), value: 'indoor_cam', label: 'Indoor' },
            { id: hash(), value: 'outdoor_cam', label: 'Outdoor' },
            { id: hash(), value: 'doorbell_cam', label: 'Doorbell' },
        ],
    },
    { id: hash(), value: 'door_lock', label: 'Door Lock', subcategory: [] },
    {
        id: hash(),
        value: 'window_cov',
        label: 'Window Covering',
        subcategory: [
            { id: hash(), value: 'window_cov', label: 'Window Covering' },
            { id: hash(), value: 'zrtsi', label: 'ZRTSI' },
        ],
    },
    { id: hash(), value: 'remote_control', label: 'Remote Control', subcategory: [] },
    {
        id: hash(),
        value: 'ir_tx',
        label: 'IR Transmitter',
        subcategory: [
            { id: hash(), value: 'irt', label: 'IR Transmitter' },
            { id: hash(), value: 'usbuirt', label: 'USB UIRT' },
        ],
    },
    {
        id: hash(),
        value: 'generic_io',
        label: 'Generic I/O',
        subcategory: [
            { id: hash(), value: 'generic_io', label: 'Generic I/O' },
            { id: hash(), value: 'repeater', label: 'Repeater' },
            { id: hash(), value: 'controller', label: 'Controller' },
        ],
    },
    {
        id: hash(),
        value: 'generic_sensor',
        label: 'Generic Sensor',
        subcategory: [{ id: hash(), value: 'motion', label: 'Non-secure motion sensor' }],
    },
    { id: hash(), value: 'serial_port', label: 'Serial Port', subcategory: [] },
    {
        id: hash(),
        value: 'scene_controller',
        label: 'Scene Controller',
        subcategory: [
            { id: hash(), value: 'generic', label: 'Generic' },
            { id: hash(), value: 'panic', label: 'Panic' },
        ],
    },
    { id: hash(), value: 'av', label: 'A/V', subcategory: [] },
    { id: hash(), value: 'humidity', label: 'Humidity Sensor', subcategory: [] },
    { id: hash(), value: 'temperature', label: 'Temperature Sensor', subcategory: [] },
    { id: hash(), value: 'light_sensor', label: 'Light Sensor', subcategory: [] },
    { id: hash(), value: 'zwave_int', label: 'Z-Wave Interface', subcategory: [] },
    { id: hash(), value: 'insteon_int', label: 'Insteon Interface', subcategory: [] },
    { id: hash(), value: 'power_meter', label: 'Power Meter', subcategory: [] },
    { id: hash(), value: 'alarm_panel', label: 'Alarm Panel', subcategory: [] },
    { id: hash(), value: 'alarm_partition', label: 'Alarm Partition', subcategory: [] },
    { id: hash(), value: 'siren', label: 'Siren', subcategory: [] },
    { id: hash(), value: 'weather', label: 'Weather', subcategory: [] },
    { id: hash(), value: 'philips_controller', label: 'Philips Controller', subcategory: [] },
    { id: hash(), value: 'appliance', label: 'Appliance', subcategory: [] },
    { id: hash(), value: 'uv_sensor', label: 'UV Sensor', subcategory: [] },
    { id: hash(), value: 'mouse_trap', label: 'Mouse Trap', subcategory: [] },
    { id: hash(), value: 'doorbell', label: 'Doorbell', subcategory: [] },
    { id: hash(), value: 'keypad', label: 'Keypad', subcategory: [] },
    { id: hash(), value: 'garage_door', label: 'Garage Door', subcategory: [] },
    { id: hash(), value: 'flow_meter', label: 'Flow meter', subcategory: [] },
    { id: hash(), value: 'voltage_sensor', label: 'Voltage Sensor', subcategory: [] },
    {
        id: hash(),
        value: 'state_sensor',
        label: 'State Sensor',
        subcategory: [
            { id: hash(), value: 'light', label: 'Light' },
            { id: hash(), value: 'rain', label: 'Rain' },
            { id: hash(), value: 'moisture', label: 'Moisture' },
            { id: hash(), value: 'freeze', label: 'Freeze' },
            { id: hash(), value: 'power', label: 'Power' },
            { id: hash(), value: 'activity', label: 'Activity' },
        ],
    },
    {
        id: hash(),
        value: 'level_sensor',
        label: 'Level Sensor',
        subcategory: [
            { id: hash(), value: 'co2', label: 'CO2' },
            { id: hash(), value: 'co', label: 'CO' },
            { id: hash(), value: 'current', label: 'Current' },
            { id: hash(), value: 'velocity', label: 'Velocity' },
            { id: hash(), value: 'capacity', label: 'Capacity' },
            { id: hash(), value: 'water', label: 'Water' },
            { id: hash(), value: 'particulate_matter', label: 'Particulate Matter' },
            { id: hash(), value: 'frequency', label: 'Frequency' },
            { id: hash(), value: 'health', label: 'Health' },
            { id: hash(), value: 'modulation', label: 'Modulation' },
            { id: hash(), value: 'smoke', label: 'Smoke' },
            { id: hash(), value: 'soil', label: 'Soil' },
            { id: hash(), value: 'moisture', label: 'Moisture' },
            { id: hash(), value: 'air_pollution', label: 'Air Pollution' },
            { id: hash(), value: 'electricity', label: 'Electricity' },
            { id: hash(), value: 'sound', label: 'Sound' },
            { id: hash(), value: 'navigation', label: 'Navigation' },
            { id: hash(), value: 'seismicity', label: 'Seismicity' },
            { id: hash(), value: 'time', label: 'Time' },
        ],
    },
    { id: hash(), value: 'clock', label: 'Clock', subcategory: [] },
];
export const STATUSES = {
    ABORTED: 'aborted',
    FINISHED: 'finished',
    SUCCESS: 'success',
    OK: 'ok',
    ERROR: 'error',
    ADD_DEVICE_FAIL: 'add_device_fail',
    FAILED: 'failed',
};

export const IP_DEVICE_GENERATOR = 'ezlo.wifi_device_generator';
