import './style.scss';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import { optionsSelect } from '../../../../constants/MeshbotConstant';
import { ControllerIcon } from '../../../../assets/icons';

const ControllersDropdown = ({
    controllersList,
    currentController,
    onSelectController,
    onGetStatusController,
    ezloSerial,
}) => {
    const handleChange = (event) => {
        const selectedController = controllersList.find((controller) => controller.serial === event.target.value);

        onSelectController(event.target.value);
        onGetStatusController(selectedController.connected);
    };

    if (ezloSerial) {
        return (
            <div className="controllers-select">
                <FormControl>
                    <Select
                        MenuProps={optionsSelect}
                        id="demo-simple-select-outlined"
                        value={currentController}
                        onChange={handleChange}
                    >
                        {controllersList?.map((controller) => {
                            const controllerIconClasses = classNames('controller_icon icon-container', {
                                online: controller.connected,
                            });
                            const controllerSerialClasses = classNames('controller-serial', {
                                online: controller.connected,
                            });

                            return (
                                <MenuItem key={controller.serial} value={controller.serial}>
                                    <div className="select-item-content">
                                        <span className={controllerIconClasses}>
                                            <ControllerIcon />
                                        </span>
                                        <span className={controllerSerialClasses}>{controller.serial}</span>
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    return null;
};

export default ControllersDropdown;
