import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@material-ui/icons/Clear';

import InfoItem from './components/InfoItem';

import styles from './TooltipInfo.module.scss';
// import { useTranslate } from '../../../../../../../../../../../../../features/languages';
// import { EZLOGIC_TITLE_NO_INFORMATION } from '../../../../../../../../../../../../../constants/language_tokens';

const TooltipInfo = ({ info, onHideTooltip, tooltipData }) => (
    <div className={styles.tooltipBody}>
        <ClearIcon className={styles.iconHide} onClick={onHideTooltip} />
        <ul>
            {tooltipData.map((item) => (
                <InfoItem key={item.id} item={item} info={info} />
            ))}
        </ul>
    </div>
);

export default TooltipInfo;

TooltipInfo.propTypes = {
    info: PropTypes.object,
    deviceId: PropTypes.string,
    onHideTooltip: PropTypes.func,
    label: PropTypes.string,
};
