import React, { useEffect, useState } from 'react';
import { SettingsApplications } from '@material-ui/icons';

import IntegrationImage from '../IntegrationImage';
import styles from '../IntegrationCard.module.scss';
import { integrationIcon } from '../../../containers/Ezlo/EzloIntegrations/utils';

const IntegrationContent = ({ icon, name, capabilities }) => {
    const [isImageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        const fetchImage = async () => {
            const isImageLoaded = await integrationIcon(icon);
            setImageLoaded(isImageLoaded);
        };

        fetchImage();
    }, []);

    return (
        <div className={styles.integration__content}>
            {icon && isImageLoaded ? <IntegrationImage icon={icon} /> : <SettingsApplications />}
            <div>
                <p className={styles.name}>{name}</p>
                <p className={styles.title}>{capabilities?.join(', ')}</p>
            </div>
        </div>
    );
};

export default IntegrationContent;
