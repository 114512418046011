import React, { useMemo } from 'react';
import SimpleIntegerField from '../SimpleIntegerField/SimpleIntegerField';
import { getNumberError, getMinMax } from '../utils';

const DynamicIntegerField = ({ input, onChange, value, name, className }) => {
    const fieldSettings = useMemo(() => {
        return getMinMax(input);
    }, [input]);

    const getError = () => {
        return getNumberError(fieldSettings.min, fieldSettings.max, value);
    };

    return (
        <div className={`${className}`}>
            <SimpleIntegerField
                onChange={onChange}
                getError={getError}
                value={value}
                name={name}
                min={fieldSettings.min}
                max={fieldSettings.max}
                isRequired={input.required}
            />
        </div>
    );
};

export default DynamicIntegerField;
