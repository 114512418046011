import React from 'react';
import OrderSortingSelect from './OrderSortingSelect';
import useGalleryContext from '../../hooks/useGalleryContext';

const OrderSortingSelectContainer = () => {
    const { sortingType, handlerChangeImgSortingType } = useGalleryContext();

    return <OrderSortingSelect value={sortingType} onChangeSelect={handlerChangeImgSortingType} />;
};

export default OrderSortingSelectContainer;
