import React from 'react';
import styles from '../container/language.module.scss';
import { FLAGURL, LANGUAGES, optionsSelect } from '../constants';
import { useTranslate } from '../hooks';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const LanguageSelector = ({ switchLanguage, currentLang, isLoggin }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.dropdownContainer}>
            <FormControl fullWidth className={styles.dropdown}>
                <Select
                    value={currentLang}
                    IconComponent={isLoggin && ExpandMoreIcon}
                    onChange={switchLanguage}
                    disableUnderline={isLoggin}
                    MenuProps={{ ...optionsSelect }}
                >
                    {LANGUAGES.map((lang, index) => {
                        return (
                            <MenuItem key={index} value={lang?.value} disableGutters className={styles.dropdownchild}>
                                <img className={styles.flag_img} src={FLAGURL + `${lang.flag}.png`} />
                                <span className={styles.languageName}>{t(lang?.text)}</span>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSelector;
