import * as yup from 'yup';

export const MIN_PASSWORD_LENGTH = 8;

// TODO: multi-language. Translate warning messages
export const validationSchema = yup.object({
    newPassword: yup
        .string()
        .min(MIN_PASSWORD_LENGTH, 'Password should be of minimum 8 characters length')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
            'At least one uppercase, lowercase, number and non-alphabetic character',
        )
        .required('The field is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('The field is required'),
});
