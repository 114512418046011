import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import { createTemplateForSliceFunc, parseValueFromIncludeOrSliceFunction } from '../../../utils';
import { EMPTY_LINE, POSITION_MIN_NUMBER } from '../../../constants';

import style from '../CloudVariable.module.scss';
import { EZLOGIC_TITLE_POS_END, EZLOGIC_TITLE_POS_START } from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';

export const GetStringFromPosition = ({ onChangeValue, defaultValue }) => {
    const [posStart, setPosStart] = useState(POSITION_MIN_NUMBER);
    const [posEnd, setPosEnd] = useState(POSITION_MIN_NUMBER);
    const { t } = useTranslate();
    useEffect(() => {
        if (parseValueFromIncludeOrSliceFunction(defaultValue)) {
            const parsedValue = parseValueFromIncludeOrSliceFunction(defaultValue);
            const [startPos, endPos] = parsedValue.split(',');
            setPosStart(Number(startPos));
            setPosEnd(Number(endPos));
        }
    }, []);

    useEffect(() => {
        const hasNotPostStar = posStart !== POSITION_MIN_NUMBER && !posStart;
        const hasNotPostEnd = posEnd !== POSITION_MIN_NUMBER && !posEnd;
        const updatedValue =
            hasNotPostStar || hasNotPostEnd ? EMPTY_LINE : createTemplateForSliceFunc(posStart, posEnd);
        onChangeValue(updatedValue);
    }, [posStart, posEnd]);

    const fieldSetUpProps = {
        InputProps: { inputProps: { min: 0, step: 1 } },
        required: true,
        type: 'number',
        variant: 'outlined',
    };

    return (
        <div className={style.positionBlock}>
            <TextField
                name="posStart"
                label={t(EZLOGIC_TITLE_POS_START)}
                onChange={(e) => setPosStart(e.target.value)}
                value={posStart}
                {...fieldSetUpProps}
            />
            <TextField
                name="posEnd"
                label={t(EZLOGIC_TITLE_POS_END)}
                value={posEnd}
                {...fieldSetUpProps}
                onChange={(e) => setPosEnd(e.target.value)}
            />
        </div>
    );
};
