import React from 'react';

import MeshbotSerial from '../../../components/MeshbotSerial';
import MeshbotSwitch from '../../../components/MeshbotSwitch';
import MeshbotEditRule from '../../../components/MeshbotEditRule';
import MeshbotDelete from '../../../components/MeshbotDelete';
import MeshBotNameCell from './components/MeshBotNameCell/MeshBotNameCell';

import hash from '../../../constants/uniqueHash';
import at from '../../../constants/ActionTypes/MeshBot';
import { HIDE } from '../EzloCustomization/constants';
import MeshbotDuplicate from '../../../components/DuplicateMeshbot';
import {
    EZLOGIC_TITLE_ACTIVE,
    EZLOGIC_TITLE_CHECKBOX_SELECTION,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DUPLICATE,
    EZLOGIC_TITLE_EDGE_COMPUTER,
    EZLOGIC_TITLE_EDIT,
    EZLOGIC_TITLE_GROUP_NAME,
    EZLOGIC_TITLE_ID_MESHBOT,
    EZLOGIC_TITLE_MESHBOT_NAME,
    EZLOGIC_TITLE_MESHBOT_TYPE,
    EZLOGIC_TITLE_RUN_ONCE,
} from '../../../constants/language_tokens';
import MeshbotType from '../../../components/MeshbotType';
import { isArray, isNumber } from 'lodash';
import { MESHBOT_TYPES, meshBotsTypeCellConfig, SYSTEM } from './constants';
import { getMeshBotLabelsUuidsFromMeshBot } from '../../../features/Labels/MeshBotsLabels/utils';
import MeshbotRunWrapper from '../../../components/MeshbotRunWrapper';
import { DATA_MESHBOT_ATTRIBUTE, ZERO_INT } from '../../../constants/MeshbotConstant';
import { MeshBotAction } from '../../../actions';
import { store } from '../../../store/configureStore';
import { t } from '../../../helpers/language';
import { SHOW_SYSTEM_MESHBOTS } from '../../../services/kvs';
import { GridDensityTypes } from '@mui/x-data-grid-pro';

const onEditMeshbot = (data) => store.dispatch(MeshBotAction.goEditMeshbot(data));
const onDuplicate = (data) => store.dispatch(MeshBotAction.goDuplicateMeshbot(data));

/**
 * Retrieves the value used for sorting in the MeshBot Type column.
 *
 * @param {Object} param - The parameter object containing information about the row and API.
 * @param {Object} param.api - The API object.
 * @param {string} param.id - The ID of the row.
 * @returns {string} The value to be used for sorting in the MeshBot Type column.
 */
function getValueForSort(param) {
    const row = param.api.getRow(param.id);
    const cellConfig = getMeshBotTypeCellConfig(row);

    if (cellConfig) {
        return t(cellConfig.tableTitleKey);
    }

    return row.type;
}
/**
 * Comparator function for sorting values in the MeshBot Type column.
 *
 * @param {any} v1 - The first value to compare.
 * @param {any} v2 - The second value to compare.
 * @param {Object} param1 - The parameter object for the first value.
 * @param {Object} param2 - The parameter object for the second value.
 * @returns {number} A number indicating the sorting order (-1, 0, or 1).
 */
export function sortComparatorOfMeshBotTypeColumn(v1, v2, param1, param2) {
    const typeValue1 = getValueForSort(param1);
    const typeValue2 = getValueForSort(param2);

    return typeValue1.localeCompare(typeValue2);
}

/**
 * MeshBots table columns initial config
 * More info you can find here {@link https://confluence.mios.com/display/PDMT/PRD+Meshbot+CRUD PRD}
 * */
export const meshBotsTableColumnsInitialConfig = [
    {
        columnName: '__check__',
        headerName: EZLOGIC_TITLE_CHECKBOX_SELECTION,
        width: 50,
        hide: false,
        field: '__check__',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'id',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_ID_MESHBOT,
        minWidth: 340,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
        disableSelectionOnClick: true,
    },
    {
        field: 'name',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_MESHBOT_NAME,
        headerClassName: 'grid-field',
        minWidth: 400,
        hide: false,
        disableSelectionOnClick: true,
        renderCell: (params) => <MeshBotNameCell params={params.row} onClick={onEditMeshbot} />,
    },
    {
        field: 'groupId',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_GROUP_NAME,
        headerClassName: 'grid-field',
        minWidth: 168,
        disableSelectionOnClick: true,
        hide: true,
    },
    {
        field: 'type',
        headerAlign: 'left',
        align: 'left',
        headerName: EZLOGIC_TITLE_MESHBOT_TYPE,
        headerClassName: 'grid-field',
        minWidth: 184,
        hide: false,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotType params={params} />;
        },
        sortComparator: sortComparatorOfMeshBotTypeColumn,
    },
    {
        // width:'10%',
        field: 'serial',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_EDGE_COMPUTER,
        headerClassName: 'grid-field',
        minWidth: 120,
        hide: false,
        sortable: false,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotSerial params={params.row} />;
        },
    },
    {
        field: 'active',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_ACTIVE,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotSwitch params={params.row} />;
        },
    },
    {
        field: 'run',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_RUN_ONCE,
        headerClassName: 'grid-empty-field grid-field',
        width: 112,
        hide: false,
        sortable: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotRunWrapper params={params.row} />;
        },
    },
    {
        field: 'edit',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_EDIT,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotEditRule params={params.row} onClick={onEditMeshbot} />;
        },
    },
    {
        field: 'delete',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_DELETE,
        headerClassName: 'grid-empty-field grid-field',
        width: 90,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotDelete params={params.row} />;
        },
    },
    {
        field: 'duplicate',
        headerAlign: 'center',
        headerName: EZLOGIC_TITLE_DUPLICATE,
        headerClassName: 'grid-empty-field grid-field',
        width: 105,
        sortable: false,
        hide: false,
        disableColumnMenu: true,
        disableClickEventBubbling: true,
        disableSelectionOnClick: true,
        renderCell: (params) => {
            return <MeshbotDuplicate params={params.row} onClick={onDuplicate} />;
        },
    },
];

/**
 * Returns array for MeshBot listing Ui
 * @param {array} listingColumn - list data of meshbot columns
 *  @param {array} updatedListingUIColumns - list data of meshbot column customization
 * @returns {array} array of meshbot columns.
 * */

export const getUpdatedListingUiColumns = (listingColumn, updatedListingUIColumns) => {
    listingColumn?.filter((meshbotTableColumnItem) => {
        updatedListingUIColumns?.some((updatedListColumnItem) => {
            if (meshbotTableColumnItem?.field === updatedListColumnItem?.field) {
                meshbotTableColumnItem[HIDE] = updatedListColumnItem?.hide;
            }
        });
    });

    return listingColumn;
};

/**
 * Returns rows array for MeshBot table
 * @param {array} data - list of local MeshBots
 * @returns {array} array of rows
 * */
export const createMeshBotForTable = (data) => {
    return data.map((item) => {
        return {
            id: item._id,
            enabled: item.enabled,
            groupId: item.group_id ? item.group_id : 'not group',
            name: item.name,
            type: 'local',
            serial: item.serial,
            isDisabled: !item.isConnected,
            labelsUuids: getMeshBotLabelsUuidsFromMeshBot(item) || [],
        };
    });
};

// TODO: Split file

// const setNot = (element) => getMethodName(element.blockOptions) === 'not';
// const getItemId = (item) => item.fields[0].value;

// const options = (data, name, id, result, not) => {
//     return {
//         id: id,
//         not: not,
//         type: at.GROUP,
//         optionType: getMethodName(data.blockOptions),
//         blockName: data.blockName,
//         blocks: name === 'select' ? result.select : result.triggers,
//     };
// };

// const createBlockSelect = (block, id, not) => {
//     return { id: id, not: not, blocks: [block] };
// };

export const getMethodName = (blockOptions) => blockOptions.method.name;
export const getMethodArgs = (item) => {
    const { args } = item.blockOptions.method;
    const name = getMethodName(item.blockOptions);
    const isGroup = () => item.fields[0].value.hasOwnProperty('blockName');
    const checkField = (data) => args.hasOwnProperty(data);

    if (checkField('weekdays')) {
        return args.weekdays;
    } else if (checkField('days')) {
        return args.days;
    } else if (checkField(at.BLOCKS) && !item.hasOwnProperty('blockName')) {
        return at.BLOCKS;
    } else if (item.hasOwnProperty('blockName')) {
        return at.GROUP;
    } else if (isGroup()) {
        return at.GROUP_NOT;
    } else if (!isGroup() && name === 'not') {
        return at.DEVICE_NOT;
    } else {
        return at.DEVICE;
    }
};

export const deviceId = (value, items) => {
    const itemRule = items.find((elem) => elem._id === value);

    return { deviceId: itemRule.deviceId, itemId: value };
};

// const createBlockList = (block, items, devices, type, id) => {
//     const getBlockId = (item) => item.blockId;
//
//     if (type === at.DEVICE) {
//         const [{ value }] = block.fields;
//         const setId = setNot(block) ? deviceId(getItemId(value), items) : deviceId(value, items);
//         const getDeviceName = (deviceId) => devices.find((item) => item._id === deviceId).name;
//
//         return {
//             id: id,
//             selectedFieldTrigger: 'deviceState',
//             not: setNot(block),
//             name: getDeviceName(setId.deviceId),
//             firstBlock: setNot(block) ? getBlockId(value) : block.blockId,
//             idDev: setId.deviceId,
//             blocks: setNot(block) ? [value] : [block],
//         };
//     }
//
//     if (type === 'date') {
//         return {
//             id: id,
//             selectedFieldTrigger: 'dataAndTime',
//             selectedFieldDate: getMethodArgs(block),
//             selectedSpecificDate: getFieldType(block.fields),
//             blocks: [block],
//         };
//     }
// };

export const getActionsBlocks = (data) => {
    let actionsList = [];
    let actionsSelect = [];
    // "setDeviceArmed" dev
    // "runCustomScript"
    // "setItemValue" dev
    // "sendHttpRequest"`

    // let triggersList = [];
    // let triggersSelect = { blocks: [] };
    // let triggersDevicesBlock = [];

    // id(pin):"9c0616e5-a2ca-cabb-bc67-147f7ddf10bf"
    // _tempId(pin):"247a52c8-de17-f4fe-7809-6c20d45c605e"
    // selectedFieldTrigger(pin):"deviceState"
    // firstBlock(pin):"e4fee903-9e22-1868-830b-1762a4621b20"
    // name(pin):"In Wall Switch"
    // idDev(pin):"608182b2129e151122f130ae"
    // blocks

    const actionItem = (id, data, field) => {
        return {
            id: id,
            name: getMethodName(data.blockOptions),
            selectedFieldTrigger: field,
            firstBlock: data._tempId,
            blocks: [data],
        };
    };

    data.map((item) => {
        const id = hash();
        switch (getMethodName(item.blockOptions)) {
            case 'runCustomScript':
                actionsList = [...actionsList, actionItem(id, item, 'script')];
                actionsSelect = [...actionsSelect, { id: id, blocks: [item] }];
                break;
            case 'sendHttpRequest':
                actionsList = [...actionsList, actionItem(id, item, 'sendHttpRequest')];
                actionsSelect = [...actionsSelect, { id: id, blocks: [item] }];
                break;
            case 'setDeviceArmed':
                // const currentDevice = devices.filter(item => item._id === idItem)[0];
                // console.log('currentDevice', currentDevice);
                // const itemsDevice = items.filter(item => item.deviceId === currentDevice._id && item.hasSetter);
                // console.log('itemsDevice', itemsDevice);
                break;

            default:
                // const currentItem = items.filter(item => item._id === idItem)[0];
                // console.log('currentItem', currentItem);
                // const currentDevice2 = devices.filter(item => item._id === currentItem.deviceId)[0];
                // // console.log('currentItem', currentItem);
                // const itemsDevice2 = items.filter(item => item.deviceId === currentDevice2._id && item.hasSetter);
                // console.log('itemsDevice2', itemsDevice2);
                break;
        }
    });

    return { actionsList, actionsSelect };
};

const getGroupBlock = (data) => {
    return data.blocks.map((item) => {
        if (item.not) {
            return {
                blockOptions: { method: { args: { block: 'block' }, name: 'not' } },
                blockType: 'when',
                fields: [{ name: 'block', type: 'block', value: Object.assign(item.blocks[0]) }],
            };
        }

        return item.blocks[0];
    });
};

const updateOptionsName = (type) => {
    return { method: { args: { blocks: 'blocks' }, name: type } };
};

export const dataRestructuring = (data) => {
    let resultWhen = [];
    const resultThen = [];
    let deviceOptionType = null;

    data.when.forEach((item) => {
        const notBlock = {
            blockOptions: { method: { args: { block: 'block' }, name: 'not' } },
            blockType: 'when',
            fields: [],
        };

        if (item.hasOwnProperty('blockName')) {
            const group = {
                blockName: item.blockName,
                blockOptions: updateOptionsName(item.optionType),
                blockType: 'when',
                fields: [],
            };

            if (item.not) {
                group.fields = [{ name: 'blocks', type: 'blocks', value: getGroupBlock(item) }];
                notBlock.fields = [{ name: 'block', type: 'block', value: group }];

                resultWhen.push(notBlock);
            } else {
                group.fields = [{ name: 'blocks', type: 'blocks', value: getGroupBlock(item) }];
                resultWhen.push(group);
            }
        } else if (item.blocks) {
            if (item.not) {
                deviceOptionType = item.optionType;
                notBlock.fields = [
                    {
                        name: 'block',
                        type: 'block',
                        value: Object.assign(item.blocks[0]),
                    },
                ];
                resultWhen.push(notBlock);
            } else {
                //
                deviceOptionType = item.optionType;
                resultWhen.push(...item.blocks);
            }
        } else {
            resultWhen.push(item);
        }
    });

    // todo: FIX EMPTY THEN BLOCK: Cannot destructure property 'label' of 'item.blocks[0]' as it is undefined.
    data.then.forEach((item) => {
        if (item.blocks) {
            // eslint-disable-next-line
            const { label, ...rest } = item.blocks[0];
            if (rest && rest.fields && rest.fields[1]) {
                delete rest.fields[1].enum;
            }
            const data = [rest];

            return resultThen.push(...data);
        } else {
            resultThen.push(item);
        }
    });

    if (resultWhen.length > 1) {
        // todo for me
        deviceOptionType = data.when[0].optionType;
        resultWhen = [
            {
                blockOptions: updateOptionsName(deviceOptionType),
                blockType: 'when',
                fields: [{ name: 'blocks', type: 'blocks', value: resultWhen }],
            },
        ];
    }

    data.when = resultWhen;
    data.then = resultThen;
    deviceOptionType = null;

    return data;
};

/**
 * Collects cloud mesbots in the format needed to display them in the table;
 * @param {array} cloudMeshs - list of cloud MeshBots from cloud side
 * @returns {array} array of cloud mesbots in the format needed to display them in the table
 * */
export const createCloudMeshBotForTable = (cloudMeshs) => {
    if (!cloudMeshs || !isArray(cloudMeshs)) {
        return [];
    }

    return cloudMeshs.map((item) => {
        return {
            id: item.uuid,
            enabled: Boolean(item.enabled),
            groupId: 'not group',
            name: item.name,
            type: item?.meta?.type || MESHBOT_TYPES.CLOUD,
            serial: '',
            labelsUuids: getMeshBotLabelsUuidsFromMeshBot(item) || [],
            lastModifiedBy: item?.last_modified_by_entity_type,
        };
    });
};

export const getUpdatedCloudMeshbots = (cloudMeshbots, checked) => {
    if (!Array.isArray(cloudMeshbots) || cloudMeshbots?.length === ZERO_INT) {
        return [];
    }

    if (checked) {
        return cloudMeshbots;
    }

    return cloudMeshbots?.filter((meshbot) => !(meshbot?.lastModifiedBy === SYSTEM));
};

/**
 * Builds a data structure that organizes selected meshbots by their types, based on their IDs.
 *
 * @param {Array} selectedMeshBotsIds - An array of IDs representing the selected meshbots.
 * @param {Array} allMeshbots - An array of all available meshbots with all data.
 * @returns {Object} An object containing selected mesh bots grouped by their types.
 *
 * @example
 * const selectedIds = [1, 3, 5];
 * const allMeshbots = [
 *     { id: 1, serial: "ABC", name: "Bot1", labelsUuids: [101, 102], type: "TypeA" },
 *     { id: 2, serial: "DEF", name: "Bot2", labelsUuids: [103, 104], type: "TypeB" },
 *     { id: 3, serial: "GHI", name: "Bot3", labelsUuids: [105, 106], type: "TypeA" },
 *     // ...
 * ];
 *
 * const selectedMeshBotsData = buildSelectedMeshBotsDataByType(selectedIds, allMeshbots);
 * console.log(selectedMeshBotsData);
 * // Output: {
 * //   "TypeA": {
 * //     1: { id: 1, serial: "ABC", name: "Bot1", labelsUuids: [101, 102] },
 * //     3: { id: 3, serial: "GHI", name: "Bot3", labelsUuids: [105, 106] }
 * //   },
 * //   // ...
 * // }
 */
export const buildSelectedMeshBotsDataByType = (selectedMeshBotsIds, allMeshbots) => {
    return allMeshbots.reduce((prevState, currentMeshBot) => {
        if (selectedMeshBotsIds.includes(currentMeshBot.id)) {
            const { id, serial, name, labelsUuids, type } = currentMeshBot;
            prevState[type] = {
                ...prevState[type],
                [id]: { id, serial, name, labelsUuids },
            };
        }

        return prevState;
    }, {});
};
/**
 * Prepare MeshBots by type for query
 * @param {Object} selectedMeshBotsByType - selected MeshBots by type (MeshBots` list in Object format)
 * @returns {{localMeshBots: Object, cloudMeshBots: Object}} MeshBots by type for query
 * */
export const prepareMeshBotsByTypeForQuery = (selectedMeshBotsByType) => {
    return {
        localMeshBots: { ...selectedMeshBotsByType[MESHBOT_TYPES.LOCAL] },
        cloudMeshBots: {
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD],
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD_NOTIFICATION],
            ...selectedMeshBotsByType[MESHBOT_TYPES.CLOUD_INTERACTION],
        },
    };
};

export const getMeshBotDropZoneAttributes = (params) => ({ [DATA_MESHBOT_ATTRIBUTE]: JSON.stringify(params) });
/**
 * Builds and configures a Mesh Bots page configuration object based on the provided settings.
 *
 * @param {object} kvsMeshBotsPageConfig - The configuration settings and overrides for the Mesh Bots page from kvs.
 * @returns {object} The configured Mesh Bots page configuration object.
 */
export const buildMeshBotsPageConfig = (kvsMeshBotsPageConfig) => {
    const meshBotsPageConfig = {
        columns: meshBotsTableColumnsInitialConfig.map((column) => ({
            ...column,
            headerName: t(column.headerName),
        })),
        density: GridDensityTypes.Standard,
        [SHOW_SYSTEM_MESHBOTS]: false,
    };

    if (kvsMeshBotsPageConfig?.hasOwnProperty(SHOW_SYSTEM_MESHBOTS)) {
        meshBotsPageConfig[SHOW_SYSTEM_MESHBOTS] = kvsMeshBotsPageConfig[SHOW_SYSTEM_MESHBOTS];
    }

    if (kvsMeshBotsPageConfig?.columnData?.length) {
        const kvsColumns = kvsMeshBotsPageConfig?.columnData;
        meshBotsPageConfig.columns = meshBotsPageConfig.columns.map((column) => {
            const data = kvsColumns.find((kvsColumn) => kvsColumn.field === column.field);

            return { ...column, ...data };
        });
    }

    if (kvsMeshBotsPageConfig?.densityValue) {
        meshBotsPageConfig.density = kvsMeshBotsPageConfig.densityValue;
    }

    return meshBotsPageConfig;
};
/**
 * Retrieves the configuration for a Meshbot type based on the provided Meshbot data.
 *
 * @param {Object} meshBotData - Data of Meshbot.
 * @returns {Object|null} Configuration for the Meshbot type, or null if not found.
 */
export const getMeshBotTypeCellConfig = (meshBotData) => {
    if (!meshBotData) {
        throw new Error('missing meshBotData in getMeshBotTypeCellConfig fn. meshBotData is required');
    }

    if (meshBotData?.lastModifiedBy === SYSTEM) {
        return meshBotsTypeCellConfig[SYSTEM];
    }

    if (meshBotsTypeCellConfig?.hasOwnProperty(meshBotData.type)) {
        return meshBotsTypeCellConfig[meshBotData.type];
    }

    return null;
};
/**
 * Retrieves the pagination count for a MeshBots table or returns the total count of all MeshBots if unavailable.
 *
 * @param {object|null} meshBotsTableState - The state of the MeshBots table, including pagination information.
 * @param {number} meshBotsTableState.pagination.rowCount - The number of rows per page in the table.
 * @param {Array} allMeshBots - An array containing all MeshBots.
 * @returns {number} The pagination count if available; otherwise, the total count of all MeshBots.
 */
export const getMeshBotPaginationCount = (meshBotsTableState, allMeshBots) => {
    const count = meshBotsTableState?.pagination?.rowCount;

    if (!isNumber(count)) {
        return allMeshBots.length;
    }

    return count;
};
