import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Input from '../../../../../components/InputMaterial';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';
import './style.scss';
import {
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_PENDING_DEVICE_INFO_TOOLTIP,
    EZLOGIC_TITLE_PUT_YOUR_TEXT_HERE,
    EZLOGIC_TITLE_STATUS,
    EZLOGIC_TITLE_SYNCHRONIZED_INFO_TOOLTIP,
    EZLOGIC_TITLE_SYNC_FAILED_INFO_TOOLTIP,
    EZLOGIC_TITLE_TEXT_INPUT,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DeviceSettingString = (props) => {
    const [valueInput, setValueInput] = useState('');
    const { settings, onSave } = props;
    const { description, label, status, value } = settings;
    const { t } = useTranslate();
    const deviceSettingInfoOption = {
        option1: t(EZLOGIC_TITLE_SYNCHRONIZED_INFO_TOOLTIP),
        option2: t(EZLOGIC_TITLE_PENDING_DEVICE_INFO_TOOLTIP),
        option3: t(EZLOGIC_TITLE_SYNC_FAILED_INFO_TOOLTIP),
    };
    useEffect(() => {
        setValueInput(value);
    }, [value]);

    const handlerSetValue = (e) => {
        setValueInput(e.target.value);
    };

    const handlerSaveValue = () => {
        if (settings._id) {
            onSave(settings._id, valueInput);
        }
    };

    return (
        <div className="device-settings__string">
            <h2 className="device-info__title">{label.text}</h2>
            {status && (
                <div className="device-alarm__block">
                    <div>
                        <h5 className="device-alarm__title">{t(EZLOGIC_TITLE_STATUS)}</h5>
                        <span className="device-alarm__status">{status}</span>
                    </div>
                    <DeviceSettingInfo titles={deviceSettingInfoOption} />
                </div>
            )}
            {description && <p className="device-alarm__text">{description.text}</p>}
            <Input
                label={t(EZLOGIC_TITLE_TEXT_INPUT)}
                placeholder={t(EZLOGIC_TITLE_PUT_YOUR_TEXT_HERE) + ' ...'}
                otherClass="device-settings__string-input"
                value={valueInput}
                onChange={handlerSetValue}
            />
            <div className="device-settings__string-buttons">
                <Button variant="contained" color="primary" onClick={handlerSaveValue}>
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </div>
        </div>
    );
};

DeviceSettingString.propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func,
};

export default DeviceSettingString;
