import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

import EzloNotificationCapabilityDropdown from '../EzloNotificationCapabilityDropdown';
import MeshBotNameBlock from '../../../EzloMeshbot/MeshBot/MeshBotNameBlock';
import MeshBotContent from '../../../EzloMeshbot/MeshBot/MeshBotContent';
import MeshBotWrapper from '../../../EzloMeshbot/MeshBot/MeshBotWrapper';
import { CloudMeshbotFormTriggers } from '../../../EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/CloudMeshBotFormParts/CloudMeshbotFormTriggers';
import { CloudMeshbotFormAction } from '../../../EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/CloudMeshBotFormParts/CloudMeshbotFormActions';
import { CloudMeshbotActions, NotificationActions } from '../../../../../actions';

import { MESHBOT_TYPES } from '../../../EzloMeshbots/constants';
import { MESHBOT_TEMPLATE, SYSTEM } from '../../../../../constants/Notifications';
import { STATIC } from '../../../../../constants/MeshbotConstant';
import {
    DATA_FROM_FIRST_ACTION,
    DATA_FROM_FIRST_BLOCK,
    DATA_FROM_SECOND_BLOCK,
    DATA_FROM_VALUE_FROM_BLOCK_STATIC,
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    NOTIFICATION_TEMPLATE,
} from '../../../../../constants/NotificationTemplates';

import { filterAbstractOptions } from '../../../utils';
import {
    currentNotificationTemplate,
    getFirstStepText,
    getListOfCapabilityNames,
    getSecondStepText,
    isNotificationTemplateActionValid,
    isNotificationTemplateEdited,
    isNotificationTemplateTriggerValid,
} from '../../utils';
import {
    returnNotificationTemplateObjectForActionsSection,
    returnNotificationTemplateObjectForTriggersSection,
} from '../../../EzloOEMNotifications/utils';

import { useTranslate } from '../../../../../features/languages';

import styles from '../../EzloNotificationTemplate.module.scss';
import { ArrowRightIcon } from '../../../../../assets/icons';

// TODO: provide better name, cover by unit tests with next refactoring!
const TemplateForm = (props) => {
    const { uuid, currentTemplate, onSubmit, selectedNotificationCapability } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const [listCapabilityNamesForNotificationTemplate, setListCapabilityNamesForNotificationTemplate] = useState([]);

    const [initialMeshBotDefinition, setInitialMeshBotDefinition] = useState([]);

    const meshBotName = useSelector((state) => state.meshBot.cloud.name);
    const nameBeforeEditing = useSelector((state) => state.meshBot.cloud.nameBeforeEditing);
    const ruleCloudTriggers = useSelector((state) => state.meshBot.cloud.ruleCloudTriggers);
    const ruleCloudActions = useSelector((state) => state.meshBot.cloud.ruleCloudAction);
    const abstractsCapabilities = useSelector((state) => state.main.abstractCapabilities.capabilities);
    const notificationTemplates = useSelector((state) => state.notifications.notificationTemplates);
    const abstracts = useSelector((state) => state.main.abstractsList?.abstracts || null);
    const channels = useSelector((state) => state.notifications.channels);
    const uuids = useSelector((state) => state.notifications.uuids);
    const allCapabilities = useSelector((state) => state.main.abstractCapabilities?.capabilities);
    const parentTriggerOptionType = useSelector((state) => state.meshBot.cloud.parentTriggerOptionType);
    const isSystemNotificationTemplate = useSelector((state) => state.notifications.notificationType) === SYSTEM;
    const { notificationType } = useSelector((state) => state.notifications);

    useEffect(() => {
        if (currentTemplate?.meshbot_definition) {
            setInitialMeshBotDefinition(JSON.parse(currentTemplate?.meshbot_definition));
            dispatch(CloudMeshbotActions.updateCurrentCloudMeshscene(JSON.parse(currentTemplate?.meshbot_definition)));
        }
    }, [currentTemplate]);

    useEffect(() => {
        if (channels && !channels.length) {
            dispatch(NotificationActions.getChannelsData());
        }
    }, []);

    useEffect(() => {
        setListCapabilityNamesForNotificationTemplate(getListOfCapabilityNames(allCapabilities));
    }, [allCapabilities]);

    useEffect(() => {
        if (uuid && listCapabilityNamesForNotificationTemplate.length) {
            const parseMeshBotDefinition = JSON.parse(currentTemplate?.meshbot_definition);
            const applyCapability = currentTemplate?.capability;

            if (
                [parseMeshBotDefinition?.triggers]?.[DATA_FROM_FIRST_BLOCK]?.parameters?.length > 1 &&
                [parseMeshBotDefinition?.triggers]?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_SECOND_BLOCK]
                    ?.name !== STATIC
            ) {
                const operatorName = parseMeshBotDefinition?.triggers?.name;

                parseMeshBotDefinition?.triggers?.parameters.map(() => {
                    dispatch(
                        CloudMeshbotActions.setCloudTriggersForEdit(
                            returnNotificationTemplateObjectForTriggersSection(
                                parseMeshBotDefinition?.triggers.parameters,
                                listCapabilityNamesForNotificationTemplate,
                            ),
                            operatorName,
                        ),
                    );

                    dispatch(
                        CloudMeshbotActions.setCloudActionsForEdit(
                            returnNotificationTemplateObjectForActionsSection(parseMeshBotDefinition.actions[0]),
                        ),
                    );

                    dispatch(CloudMeshbotActions.setCloudMeshBotName(parseMeshBotDefinition?.name));
                    dispatch(NotificationActions.setNotificationSelectedCapability(currentTemplate?.capability));
                    dispatch(CloudMeshbotActions.setCloudMeshBotNameBeforeEditing(parseMeshBotDefinition?.name));
                });
            } else {
                const parametersForFindDefinition =
                    parseMeshBotDefinition?.triggers?.parameters?.[DATA_FROM_VALUE_FROM_BLOCK_STATIC]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ].capability ||
                    parseMeshBotDefinition?.triggers?.parameters?.[DATA_FROM_VALUE_FROM_BLOCK_STATIC]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ].parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability;

                if (currentTemplate.type === SYSTEM) {
                    dispatch(NotificationActions.updateNotificationType(SYSTEM));
                    dispatch(NotificationActions.setNotificationSelectedCapability(applyCapability));
                } else if (currentTemplate.type === MESHBOT_TEMPLATE) {
                    dispatch(NotificationActions.updateNotificationType(MESHBOT_TEMPLATE));
                    dispatch(NotificationActions.setNotificationSelectedCapability(applyCapability));
                }

                dispatch(
                    CloudMeshbotActions.setCloudTriggersForEdit(
                        currentTemplate.type === SYSTEM
                            ? []
                            : returnNotificationTemplateObjectForTriggersSection(
                                  parseMeshBotDefinition,
                                  listCapabilityNamesForNotificationTemplate,
                                  parametersForFindDefinition,
                                  currentTemplate?.capability,
                              ),
                    ),
                );
                dispatch(
                    CloudMeshbotActions.setCloudActionsForEdit(
                        returnNotificationTemplateObjectForActionsSection(
                            parseMeshBotDefinition.actions[DATA_FROM_FIRST_ACTION],
                            parametersForFindDefinition,
                        ),
                    ),
                );
                dispatch(CloudMeshbotActions.setCloudMeshBotName(parseMeshBotDefinition?.name));
                dispatch(NotificationActions.setNotificationSelectedCapability(currentTemplate?.capability));
                dispatch(CloudMeshbotActions.setCloudMeshBotNameBeforeEditing(parseMeshBotDefinition?.name));
            }
        }
    }, [listCapabilityNamesForNotificationTemplate]);

    const changeNotificationTemplateName = (name) => {
        dispatch(CloudMeshbotActions.setCloudMeshBotName(name));
    };

    const inputNameClasses = `meshbot-name ${meshBotName ? 'is-valid' : ''}`;

    const handleBackClick = () => {
        history.goBack();
        dispatch(NotificationActions.updateNotificationType(NOTIFICATION_TEMPLATE));
        dispatch(NotificationActions.setNotificationSelectedCapability(''));
    };

    const firstStepText = t(getFirstStepText(notificationType));
    const secondStepText = t(getSecondStepText(notificationType));

    return (
        <MeshBotWrapper>
            <IconButton className={styles.iconContainer} size="small" onClick={handleBackClick}>
                <ArrowRightIcon />
            </IconButton>
            <MeshBotNameBlock
                otherClass={inputNameClasses}
                value={meshBotName}
                title={meshBotName}
                onChange={(e) => changeNotificationTemplateName(e.target.value)}
                isNotificationTemplatePage={true}
            />
            <p className={styles.infoContent}>{firstStepText}</p>
            <EzloNotificationCapabilityDropdown
                isSystemNotificationTemplate={isSystemNotificationTemplate}
                listCapabilities={listCapabilityNamesForNotificationTemplate}
            />
            <p className={styles.infoContent}>{secondStepText}</p>
            <MeshBotContent
                isDisabledSaveButton={
                    meshBotName === '' ||
                    selectedNotificationCapability === '' ||
                    !isNotificationTemplateTriggerValid(ruleCloudTriggers) ||
                    !isNotificationTemplateActionValid(ruleCloudActions) ||
                    !isNotificationTemplateEdited(
                        ruleCloudActions,
                        ruleCloudTriggers,
                        initialMeshBotDefinition,
                        selectedNotificationCapability,
                        currentTemplate?.capability,
                        parentTriggerOptionType,
                        nameBeforeEditing,
                        meshBotName,
                    ) ||
                    (!ruleCloudTriggers.length && !isSystemNotificationTemplate)
                }
                onClickSaveButton={onSubmit}
            >
                {!isSystemNotificationTemplate && (
                    <CloudMeshbotFormTriggers
                        listCapabilities={listCapabilityNamesForNotificationTemplate}
                        abstractDeviceTriggers={filterAbstractOptions(abstracts)}
                        ruleCloudTriggers={ruleCloudTriggers}
                        CloudMeshbotActions={CloudMeshbotActions}
                        currentScene={currentNotificationTemplate(notificationTemplates, nameBeforeEditing)}
                        notificationTemplatePage={true}
                        parentTriggerOptionType={parentTriggerOptionType}
                        isSystemNotificationTemplate={isSystemNotificationTemplate}
                    />
                )}

                <CloudMeshbotFormAction
                    abstractDeviceActions={filterAbstractOptions(abstracts)}
                    abstractsCapabilities={abstractsCapabilities}
                    ruleCloudActions={ruleCloudActions}
                    CloudMeshbotActions={CloudMeshbotActions}
                    currentScene={currentNotificationTemplate(notificationTemplates, nameBeforeEditing)}
                    channels={channels}
                    usersData={uuids}
                    notificationTemplatePage={true}
                    notificationType={MESHBOT_TYPES.NOTIFICATION_TEMPLATE}
                />
            </MeshBotContent>
        </MeshBotWrapper>
    );
};

export default TemplateForm;
