import { INTEGRATIONS_SUPPORTED_ARCHITECTURES } from '../containers/Ezlo/EzloHubBackups/constants';

/**
 * Returns is linux-based controller
 * @param {object} controller - controller data
 * @returns {boolean} is linux-based
 * */
export const isControllerLinuxBased = (controller) => {
    const architecture = controller?.info?.architecture;

    if (typeof architecture === 'string' && architecture !== '') {
        return INTEGRATIONS_SUPPORTED_ARCHITECTURES.includes(architecture);
    }

    return false;
};

/**
 * Returns is online status.
 * @param {object} controller - controller data
 * @returns {boolean} isOnline
 * */
export const isControllerOnline = (controller) => {
    return controller?.isConnected;
};
