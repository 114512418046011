import React from 'react';
import SelectComponent from '../SelectComponent';
import { MenuItem } from '@material-ui/core';
import { cloudOperatorTrig } from '../../../../../constants/rulesInSelect';
import style from './SelectComparatorCloud.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { TOAST_TYPE, toast } from '../../../../../components/Toast';
import { EZLOGIC_HINTS_COMPARATOR, EZLOGIC_LABEL_COMPARATOR } from '../../../../../constants/language_tokens';
import { checkForSingleOnChangeTrigger, determineToastMessageAccordingToTriggerNodeType } from '../../../utils';

const SelectComparatorCloud = (props) => {
    const { selectedComparator, onDataUpdate, ruleCloudTriggers, parentTriggerOperator } = props;
    const { t } = useTranslate();

    const handleSelectComparator = (event) => {
        checkForSingleOnChangeTrigger(ruleCloudTriggers, event.target.value, parentTriggerOperator)
            ? onDataUpdate({ selectedComparator: event.target.value })
            : toast(t(determineToastMessageAccordingToTriggerNodeType(ruleCloudTriggers, event.target.value)), {
                  type: TOAST_TYPE.WARNING,
              });
    };

    return (
        <div className={style.wrapper}>
            <SelectComponent
                value={selectedComparator}
                label={t(EZLOGIC_LABEL_COMPARATOR)}
                info={t(EZLOGIC_HINTS_COMPARATOR)}
                onChange={handleSelectComparator}
            >
                {cloudOperatorTrig.map((item) => {
                    return (
                        <MenuItem key={item.name} value={item.value}>
                            {t(item.name)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </div>
    );
};

export default SelectComparatorCloud;
