import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import EzlosDropdown from '../../../../EzlosDropdown';
import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

import { EzloActions, MeshBotAction } from '../../../../../actions';
import { CONTINUE } from '../../../../../constants/ComponentConstants';
import { SCENES_NAME_MAX_LENGTH } from '../../../../../constants/MeshbotConstant';

import { content, inputWrap, serial_wrapper } from './MeshBotNameBlock.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { getNameBlockLabel } from '../../../EzloNotificationTemplate/utils';
import hash from '../../../../../constants/uniqueHash';
import {
    EZLOGIC_HEADING_CHANGE_THE_CONTROLLER,
    EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS,
} from '../../../../../constants/language_tokens';

const MeshBotNameBlock = (props) => {
    const { value, title, onChange, serial, showDropdown, wizardMode, isNotificationTemplatePage } = props;

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [newSerial, setNewSerial] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const { t } = useTranslate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isConfirmed && !isConfirmModalVisible) {
            dispatch(EzloActions.selectController(newSerial));
            setIsConfirmed(false);
        }
    }, [isConfirmed, isConfirmModalVisible]);

    const handleConfirmation = (isConfirmed) => {
        setIsConfirmed(isConfirmed);
        setIsConfirmModalVisible(false);
        if (isConfirmed) {
            dispatch(MeshBotAction.clearMeshBot());
        }
    };

    const openConfirmModal = (newSerial) => {
        setNewSerial(newSerial);
        setIsConfirmModalVisible(true);
    };

    return (
        <div className={content}>
            <div className={inputWrap}>
                <TextField
                    variant="outlined"
                    value={value}
                    autoFocus
                    onChange={onChange}
                    label={t(getNameBlockLabel(isNotificationTemplatePage))}
                    fullWidth
                    title={title}
                    error={!value}
                    inputProps={{ maxLength: SCENES_NAME_MAX_LENGTH }}
                    id={hash()}
                />
            </div>
            {showDropdown && (
                <div className={serial_wrapper}>
                    <EzlosDropdown
                        isShowOnlyOnlineControllers={true}
                        controllerSerialWhileEditing={serial}
                        openConfirmModal={openConfirmModal}
                        wizardMode={wizardMode}
                    />

                    <ConfirmModal
                        isOpened={isConfirmModalVisible}
                        onClose={setIsConfirmModalVisible}
                        title={t(EZLOGIC_HEADING_CHANGE_THE_CONTROLLER)}
                        text={t(EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS)}
                        confirmButtonTitle={t(CONTINUE)}
                        onCancel={() => handleConfirmation(false)}
                        onConfirm={() => handleConfirmation(true)}
                    />
                </div>
            )}
        </div>
    );
};

export default MeshBotNameBlock;
