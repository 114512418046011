import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@material-ui/core';

import { VARIABLES_DATA_TYPES } from '../../../../../constants/Variables';
import { optionsSelect } from '../../../../../constants/MeshbotConstant';

import { useTranslate } from '../../../../../features/languages';

import { dataTypes } from './EzloVariablesDataTypesSelect.module.scss';
import { EZLOGIC_TITLE_VARIABLE_TYPE } from '../../../../../constants/language_tokens';

const EzloVariablesDataTypesSelect = (props) => {
    const { onSelectDataType, valueType, disabled } = props;

    const { t } = useTranslate();

    return (
        <FormControl variant="outlined" size="small" className={dataTypes}>
            <InputLabel>{t(EZLOGIC_TITLE_VARIABLE_TYPE)}</InputLabel>
            <Select
                value={valueType}
                onChange={(event) => onSelectDataType(event.target.value)}
                MenuProps={optionsSelect}
                disabled={disabled}
                input={<OutlinedInput label={t(EZLOGIC_TITLE_VARIABLE_TYPE)} />}
            >
                {VARIABLES_DATA_TYPES.map((dataType) => {
                    return (
                        <MenuItem key={dataType.id} value={dataType.value}>
                            {t(dataType.name)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default EzloVariablesDataTypesSelect;
