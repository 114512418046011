import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { mediaStoreSelector, sortImagesByTimestamp } from '../utils';
import { ORDER_SORTING_VALUES } from '../constants';

const useUiFiltersOfImages = () => {
    const { imagesList } = useSelector(mediaStoreSelector);
    const [sortingType, setSortingType] = useState(ORDER_SORTING_VALUES.DEFAULT);
    const searchInputState = useState('');
    const [searchInputValue] = searchInputState;

    const sortedImg = useMemo(() => {
        const filteredImagesByName = searchInputValue
            ? imagesList.filter(({ filename }) => filename.toLowerCase().includes(searchInputValue.toLowerCase()))
            : imagesList;

        return sortImagesByTimestamp(filteredImagesByName, sortingType);
    }, [sortingType, imagesList, searchInputValue]);

    const handlerChangeImgSortingType = (e) => {
        setSortingType(e.target.value);
    };

    return {
        imagesList: sortedImg,
        handlerChangeImgSortingType,
        sortingType,
        searchInputState,
    };
};

export default useUiFiltersOfImages;
