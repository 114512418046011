import {
    ACCOUNT_ADMIN_PERMISSION_ROLE,
    ACCOUNT_TYPES,
    PHONE_NUMBER_INPUT_TYPES,
    PIN_CODE_API_ACTIONS,
    PIN_CODE_DEVICE_TYPE,
    PIN_CODE_ITEM_NAME,
    REGEXs,
    USER_CODE_TYPE,
    USER_TABLE_ACTIONS_TYPES,
} from '../../../../constants/Users';
import oemManager from '../../../../services/oem';
import { OEM_IDS } from '../../../../services/oem/src/constants/oems';
import { isValidPhoneNumber } from 'react-phone-number-input';
// import { isArray, isEmpty, isString } from 'lodash';
import { isString } from 'lodash';

const isMatch = (field, searchValue) => {
    if (field) {
        return field.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
    }

    return false;
};

const getAccountType = (PK_PermissionRole) => {
    return ACCOUNT_TYPES.find(({ value }) => value === PK_PermissionRole) || '';
};

export const getSearchUsers = (searchValue, users) => {
    if (!users) {
        return [];
    }

    if (!searchValue) {
        return users;
    }
    const filteredUsers = users.filter((user) => {
        return (
            isMatch(user.FirstName, searchValue) ||
            isMatch(user.Name, searchValue) ||
            isMatch(user.Username, searchValue) ||
            isMatch(user.Email, searchValue)
        );
    });

    return filteredUsers || [];
};

export const getInitialValuesForUserForm = (selectedUser, selectedUserDetails) => {
    if (selectedUser) {
        const { FirstName, Name, PK_PermissionRole } = selectedUser;
        const initValue = {
            Name: Name || '',
            FirstName: FirstName || '',
            Email: selectedUser.Email || '',
            PK_PermissionRole: getAccountType(PK_PermissionRole),
            Controllers: [],
            Phone: selectedUser?.Phone?.Phone || '',
            EmailValidated: selectedUser.Validated,
            PhoneValidated: selectedUser.Phone.Validated,
        };

        if (selectedUserDetails?.Devices) {
            const controllerSerials = JSON.parse(selectedUserDetails?.Devices).map((controller) => String(controller));
            initValue.Controllers = controllerSerials;
        }

        return initValue;
    }

    return {
        Username: '',
        FirstName: '',
        Name: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        PK_PermissionRole: '',
        Controllers: [],
    };
};
// todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
export const getInitialDevices = (ezloData, pinMeta, pinCodeName) => {
    const pinCodeDevices = [];

    for (const key in ezloData) {
        const { items, devices, isConnected } = ezloData[key];

        if (isConnected && items && devices) {
            const pinCodeItems = items.filter(({ name }) => name === PIN_CODE_ITEM_NAME);
            pinCodeItems.forEach(({ deviceId, _id, value }) => {
                const foundDevice = devices.find(({ _id }) => deviceId === _id);

                const pinCodeDevice = getPinCodeDeviceData({
                    foundDevice,
                    _id,
                    key,
                    pinMeta,
                });

                if (pinCodeName) {
                    setPinCodeKey(pinCodeDevice, value, pinCodeName);
                }

                pinCodeDevices.push(pinCodeDevice);
            });
        }
    }

    return pinCodeDevices;
};
// todo [ENWT-3630] User Pin Codes updates. Implementation.
// export const getInitialDevices = (ezloData, pinMeta, pinCodeName, selectedPinCode) => {
//     const pinCodeDevices = [];
//
//     for (const key in ezloData) {
//         const { items, devices, isConnected } = ezloData[key];
//
//         if (isConnected && items && devices) {
//             const pinCodeItems = items.filter(({ name }) => name === PIN_CODE_ITEM_NAME);
//             pinCodeItems.forEach(({ deviceId, _id }) => {
//                 const foundDevice = devices.find(({ _id }) => deviceId === _id);
//                 const pinCodeDevice = getPinCodeDeviceData({
//                     foundDevice,
//                     _id,
//                     key,
//                     pinMeta,
//                     selectedPinCode,
//                 });
//
//                 pinCodeDevices.push(pinCodeDevice);
//             });
//         }
//     }
//
//     return pinCodeDevices;
// };

export const getDevicesOfSelectedPinCode = (ezloData, pinCodeData) => {
    if (!pinCodeData || !ezloData) {
        return [];
    }

    const { name, meta } = pinCodeData;
    const pinCodeDeviceDataList = [];

    for (const key in ezloData) {
        const { items, devices, isConnected } = ezloData[key];

        if (isConnected && items && devices) {
            const pinCodeItems = items.filter(({ name }) => name === PIN_CODE_ITEM_NAME);
            pinCodeItems.forEach(({ deviceId, _id, value }) => {
                const pinMeta = JSON.parse(meta);
                const foundDevice = devices.find(({ _id }) => deviceId === _id && pinMeta.deviceIds?.includes(_id));

                if (foundDevice) {
                    for (const keyValue in value) {
                        if (value[keyValue].name === name) {
                            const pinCodeDeviceData = getPinCodeDeviceData({
                                type: PIN_CODE_DEVICE_TYPE.FOR_REQUEST,
                                foundDevice,
                                _id,
                                key,
                                keyValue,
                            });

                            pinCodeDeviceDataList.push(pinCodeDeviceData);
                        }
                    }
                }
            });
        }
    }

    return pinCodeDeviceDataList;
};

export const getSortPinCodes = (pinCodes) => {
    const copyPinCodes = [...pinCodes];
    copyPinCodes.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }

        if (a.name > b.name) {
            return 1;
        }

        return 0;
    });

    return copyPinCodes;
};

export const getPinCodeValue = (pinCodeData) => {
    return {
        code: pinCodeData.pin,
        name: pinCodeData.name,
    };
};

export const getPinCodeParams = (action, { itemId, value, pinCodeKey }) => {
    if (action === PIN_CODE_API_ACTIONS.ADD) {
        return {
            _id: itemId,
            element: {
                type: USER_CODE_TYPE,
                value,
            },
        };
    }

    if (action === PIN_CODE_API_ACTIONS.SET) {
        return {
            _id: itemId,
            key: pinCodeKey,
            element: {
                type: USER_CODE_TYPE,
                value,
            },
        };
    }

    if (action === PIN_CODE_API_ACTIONS.REMOVE) {
        return {
            _id: itemId,
            key: pinCodeKey,
        };
    }
};
const setPinCodeKey = (pinCodeDevice, value, pinCodeName) => {
    for (const keyValue in value) {
        if (value[keyValue].name === pinCodeName) {
            pinCodeDevice.pinCodeKey = keyValue;
        }
    }
};
// todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
const getPinCodeDeviceData = ({ type, foundDevice, _id, key, pinMeta, keyValue }) => {
    if (type === PIN_CODE_DEVICE_TYPE.FOR_REQUEST) {
        return {
            ...foundDevice,
            itemId: _id,
            serial: key,
            pinCodeKey: keyValue,
        };
    }

    return {
        ...foundDevice,
        itemId: _id,
        serial: key,
        checked: pinMeta?.deviceIds ? pinMeta.deviceIds.includes(foundDevice._id) : false,
        isRemovePinCodeFromDevice: false,
    };
};
// todo [ENWT-3630] User Pin Codes updates. Implementation.
// const getPinCodeDeviceData = ({ type, foundDevice, _id, key, pinMeta, keyValue, selectedPinCode }) => {
//     if (type === PIN_CODE_DEVICE_TYPE.FOR_REQUEST) {
//         return {
//             ...foundDevice,
//             itemId: _id,
//             serial: key,
//             pinCodeKey: keyValue,
//         };
//     }
//
//     return {
//         ...foundDevice,
//         itemId: _id,
//         serial: key,
//         checked: isCheckedDevice(foundDevice._id, selectedPinCode?.door_locks_config, pinMeta),
//     };
// };
// /**
//  * Checks if the device is selected(checked)
//  * @param {string} deviceId - device id
//  * @param {Object[]} door_locks_config - array in which data about the selected devices is stored;
//  * @param {Object} pinMeta - pin code meta from cloud;
//  * @returns {Boolean} If the pin code contains the device id, it will return true, otherwise false will be returned;
//  * @example
//  * isCheckedDevice('123', [{"device_id":"123","controller_uuid":"456"}], {deviceIds: []})
//  */
// const isCheckedDevice = (deviceId, door_locks_config, pinMeta) => {
//     //Support for created PIN codes of the first version
//     if (pinMeta?.deviceIds && !isEmpty(pinMeta?.deviceIds)) {
//         return pinMeta.deviceIds.includes(deviceId);
//     }
//
//     //Support for created PIN codes of the last version
//     if (isArray(door_locks_config)) {
//         return Boolean(door_locks_config.find(({ device_id }) => device_id === deviceId));
//     }
//
//     return false;
// };

export const setPanel = (data, panelValue) => {
    const oemId = oemManager.oem.getOemId();
    const isSecurityOem = OEM_IDS.SECURITY_OEM_ID === oemId;

    if (isSecurityOem) {
        data.panel = panelValue;
    }
};

export const getIsLoggedInUser = (account, user) => {
    if (!user || !account) {
        return false;
    }

    return account.data.Username === user.Username;
};

const buildReplacementInUSNationalNumberFormat = (match, group1, group2, group3) => {
    if (!group1) {
        return '(';
    }

    if (group3) {
        return `(${group1}) ${group2}-${group3}`;
    }

    if (group2) {
        return group2.length === 3 ? `(${group1}) ${group2}-` : `(${group1}) ${group2}`;
    }

    if (group1) {
        return group1.length === 3 ? `(${group1})` : `(${group1}`;
    }
};

export const getOnlyNumbers = (string) => {
    if (!string) {
        return '';
    }

    const { EVERYTHING_BUT_NUMBERS } = REGEXs;

    return string.replace(EVERYTHING_BUT_NUMBERS, '');
};

const getOnlyNumbersUpTo10 = (string) => {
    return getOnlyNumbers(string).slice(0, 10);
};

export const generateNationalUASFormatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return '';
    }
    const { USA_NATIONAL_PHONE_NUMBER_PATTERN } = REGEXs;
    const onlyNumber = getOnlyNumbersUpTo10(phoneNumber);

    return onlyNumber.replace(USA_NATIONAL_PHONE_NUMBER_PATTERN, buildReplacementInUSNationalNumberFormat);
};

export const getPhoneNumberInputTypeByOemId = (oemId) => {
    const { USA_FORMAT_WITH_MASK, INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT } = PHONE_NUMBER_INPUT_TYPES;
    if (oemId === OEM_IDS.SECURITY_OEM_ID) {
        return USA_FORMAT_WITH_MASK;
    }

    return INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT;
};

const validFieldByInputTypes = {
    [PHONE_NUMBER_INPUT_TYPES.USA_FORMAT_WITH_MASK]: (phoneNumber) => {
        return Boolean(phoneNumber.length === 10);
    },
    [PHONE_NUMBER_INPUT_TYPES.INTERNATIONAL_FORMAT_WITH_COUNTRY_SELECT]: (phoneNumber) => {
        return isValidPhoneNumber(phoneNumber);
    },
};

export const getIsValidPhoneNumber = (phoneNumber, oemId) => {
    if (!phoneNumber) {
        return false;
    }
    const phoneNumberInputType = getPhoneNumberInputTypeByOemId(oemId);

    return validFieldByInputTypes[phoneNumberInputType](phoneNumber);
};

export const getActionsTypeByPermissionRole = (PK_PermissionRole) => {
    if (PK_PermissionRole === ACCOUNT_ADMIN_PERMISSION_ROLE) {
        return USER_TABLE_ACTIONS_TYPES.ADMIN;
    }

    return USER_TABLE_ACTIONS_TYPES.SIMPLE;
};

/**
 * Converts the string to the format of the first uppercase letter and the remaining lowercase.
 * @param {string} string
 * @return {string} string in format: the first uppercase letter and the remaining lowercase.
 */
export function capitalizeFirstLetter(string) {
    if (!isString(string)) {
        throw new Error('"string" argument is not string(capitalizeFirstLetter)');
    }

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Extracts serial from selected devices, and also removes repeating serial from the result.
 * @param {Object[]} selectedDevices - selected devices
 * @returns {String[]} list of serials
 * @example
 * const selectedDevices = [{_id: '123', serial: '7009658', checked: true, ...other}, ...other];
 * pullControllersSerialsFromSelectedDevices(selectedDevices)
 */
const pullControllersSerialsFromSelectedDevices = (selectedDevices) => {
    const serialsList = selectedDevices.map(({ serial }) => serial);
    const uniqueSerials = new Set(serialsList);

    return Array.from(uniqueSerials);
};

/**
 * Retrieves and returns controller_uuid by controller serial of the selected devices
 * @param {Object[]} selectedDevices - selected devices
 * @param {Object[]} allControllersInfo - List of controllers with information about them
 * @returns {Object} controller_uuid by controller serial of the selected devices
 * @example
 * const selectedDevices = [{_id: '123', serial: '7009658', checked: true, ...other}, ...other];
 * const allControllersInfo = [{ controller_uuid: '458', serial: '7009658', ...other}, ...other];
 * getControllersUuidsForSelectedDevices(selectedDevices, allControllersInfo)
 */
const getControllersUuidsForSelectedDevices = (selectedDevices, allControllersInfo) => {
    const serials = pullControllersSerialsFromSelectedDevices(selectedDevices);

    return serials.reduce(
        (prevState, currentSerial) => ({
            ...prevState,
            [currentSerial]: allControllersInfo.find(({ PK_Device }) => PK_Device === currentSerial)?.controller_uuid,
        }),
        {},
    );
};

/**
 * Build door_locks_config for a request to create/update pin code
 * @param {Object[]} selectedDevices - selected devices
 * @param {Object[]} allControllersInfo - List of controllers with information about them
 * @returns {Object} door_locks_config for a request to create/update pin code
 * @example
 * const selectedDevices = [{_id: '123', serial: '7009658', checked: true, ...other}, ...other];
 * const allControllersInfo = [{ controller_uuid: '458', serial: '90009745', ...other}, ...other];
 * buildDoorLocksConfig(selectedDevices, allControllersInfo)
 */
const buildDoorLocksConfig = (selectedDevices, allControllersInfo) => {
    const controllersUuids = getControllersUuidsForSelectedDevices(selectedDevices, allControllersInfo);

    return selectedDevices.map(({ _id, serial }) => ({ device_id: _id, controller_uuid: controllersUuids[serial] }));
};
/**
 * Build params for a request to update pin code
 * @param {String} pinCodeUuid - uuid of pin code witch we want to delete
 * @param {Object} pinCodeFormValues - pin code form values
 * @param {Object[]} allControllersInfo - List of controllers with information about them
 * @returns {Object} params for a request to update pin code
 * @example
 * const pinCodeUuid = '74589'
 * const pinCodeFormValues = { name: 'Pin4444', pin: '4444', panel: true, devices: [{_id: '123', checked: true, ...other}] };
 * const allControllersInfo = [{ controller_uuid: '458', serial: '90009745', ...other}, ...other];
 * buildUpdatePinCodeParams(pinCodeUuid, pinCodeFormValues, allControllersInfo)
 */
export const buildUpdatePinCodeParams = (pinCodeUuid, pinCodeFormValues, allControllersInfo) => {
    const { name, pin, panel, devices } = pinCodeFormValues;
    const selectedDevices = devices.filter(({ checked }) => checked);

    const pinCodeParams = {
        name,
        pin,
        uuid: pinCodeUuid,
        meta: {},
    };
    setPanel(pinCodeParams.meta, panel);
    pinCodeParams.door_locks_config = buildDoorLocksConfig(selectedDevices, allControllersInfo);

    return pinCodeParams;
};
/**
 * Build params for a request to create a new pin code
 * @param {Object} pinCodeFormValues - pin code form values
 * @param {Object[]} allControllersInfo - List of controllers with information about them
 * @returns {Object} params for a request to create a new pin code
 * @example
 * const pinCodeFormValues = { name: 'Pin4444', pin: '4444', panel: true, devices: [{_id: '123', checked: true, ...other}] };
 * const allControllersInfo = [{ controller_uuid: '458', serial: '90009745', ...other}, ...other];
 * buildCreatePinCodeParams(pinCodeFormValues, allControllersInfo)
 */
export const buildCreatePinCodeParams = (pinCodeFormValues, allControllersInfo) => {
    const { name, pin, panel, devices } = pinCodeFormValues;
    const selectedDevices = devices.filter(({ checked }) => Boolean(checked));

    const pinCodeParams = {
        name,
        pin,
        meta: {},
    };

    setPanel(pinCodeParams.meta, panel);
    pinCodeParams.door_locks_config = buildDoorLocksConfig(selectedDevices, allControllersInfo);

    return pinCodeParams;
};
