import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MeshBotLocEdit from './MeshBotLocEdit';
import MeshBotLocCreate from './MeshBotLocCreate';
import ThemeContext from '../ThemeContext';
import NotFoundPageContent from '../../../../containers/NotFoundPage/NotFoundPageContent';

const MeshBotLoc = ({ MainAction }) => {
    const { url } = useRouteMatch();
    const abstracts = useSelector((state) => state.main?.abstractsList?.abstracts);

    useEffect(() => {
        // update abstracts details to get the newly created abstracts
        MainAction.getAbstractsList(abstracts);
    }, []);

    return (
        <ThemeContext.Provider value={{}}>
            <Switch>
                <Route path={`${url}/new`} component={MeshBotLocCreate} />
                <Route path={`${url}/:id`} component={MeshBotLocEdit} />
                <Route render={() => <NotFoundPageContent loggedIn />} />
            </Switch>
        </ThemeContext.Provider>
    );
};

export default MeshBotLoc;
