import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import GridTable from '../../../../../components/Table';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import { ROWS_PER_PAGE } from '../../../EzloMeshbots/constants';

import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';

import { buildDeviceGroupsTableColumns, buildDeviceGroupsTableRows } from '../../utils';

import styles from './DeviceGroupLayout.module.scss';

const DeviceGroupLayout = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [sortModel, setSortModel] = useState([]);

    const serial = useSelector((state) => state.ezlo.serial);
    const data = useSelector((state) => state.ezlo.data);
    const deviceGroupsList = useSelector((state) => state?.ezlo?.data[serial]?.deviceGroups?.deviceGroupsList);

    useEffect(() => {
        if (deviceGroupsList) {
            setColumns(buildDeviceGroupsTableColumns(handleEditClick, handleDeleteClick));
            setRows(buildDeviceGroupsTableRows(data));
        }
    }, [data]);

    const notifyDeletedSuccess = (serial) => {
        toast('Device group successfully deleted', { type: TOAST_TYPE.SUCCESS });
        dispatch(DeviceGroupsActions.unsubscribeDeviceGroupRemoved(serial));
    };

    const handleEditClick = (params) => {
        const { id, serial } = params?.row;
        dispatch(DeviceGroupsActions.getDeviceGroupById(serial, id));
        history.push(`${location.pathname}/edit`);
    };

    const handleDeleteClick = (params) => {
        const { id, serial } = params?.row;
        dispatch(DeviceGroupsActions.removeDeviceGroup(serial, id));
        dispatch(DeviceGroupsActions.subscribeDeviceGroupRemoved(serial, () => notifyDeletedSuccess(serial)));
    };

    return (
        <div className={styles.deviceGroupsLayout}>
            <GridTable
                className={styles.deviceGroupsTable}
                rows={rows}
                columns={columns}
                pagination={true}
                rowsPerPageOptions={ROWS_PER_PAGE}
                showColumnRightBorder={true}
                disableExtendRowFullWidth={false}
                showCellRightBorder={true}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
            />
        </div>
    );
};

export default DeviceGroupLayout;
