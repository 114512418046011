import React, { memo } from 'react';
import { Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { FieldDescription } from './FieldDescription';
import { TextField } from '../../../../../../../../../../components';
import { AUTOCOMPLETE_INPUT_ROLE } from '../../../paas-constants';

import { numberInput, dynamicFieldAutocomplete, dynamicField } from '../DynamicField.module.scss';

/**
 * Create Autocomplete with multiple
 * @param { object } props
 * @returns { JSX.Element } multiple Autocomplete
 * @example
 * <MultipleAutocomplete
 *      value={['one', 'two']}
 *      options={['one', 'two', 'three']}
 *      getOptionLabel={func}
 *      handleChangeField={func}
 *      handleInputBlur={func}
 *      isRequired={true}
 *      description={"field description"}
 *      freeSolo={true}
 *      inputType="number"
 * />
 */
const MultipleAutocomplete = (props) => {
    const {
        description,
        isRequired,
        value,
        options,
        handleChangeField,
        freeSolo,
        getOptionLabel,
        handleInputBlur,
        inputType,
    } = props;

    const renderInput = (params) => {
        params.inputProps.role = AUTOCOMPLETE_INPUT_ROLE;

        return (
            <TextField
                type={inputType}
                onBlur={(e) => handleInputBlur && handleInputBlur(e, params)}
                variant="standard"
                className={`${dynamicFieldAutocomplete} ${numberInput}`}
                {...params}
            />
        );
    };
    const defaultTags = (value, getTagProps) =>
        value.map((option, index) => (
            <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
        ));

    const defaultGetOptionLabel = (option) => option || '';

    return (
        <div className={dynamicField}>
            {description && <FieldDescription description={description} isRequired={isRequired} />}
            <Autocomplete
                multiple
                freeSolo={freeSolo}
                value={value || []}
                options={options || []}
                getOptionLabel={getOptionLabel || defaultGetOptionLabel}
                onChange={handleChangeField}
                renderInput={renderInput}
                renderTags={defaultTags}
                disableCloseOnSelect
            />
        </div>
    );
};

MultipleAutocomplete.defaultProps = {
    inputType: 'text',
};

export default memo(MultipleAutocomplete);
