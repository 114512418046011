import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import TextInput from '../../components/TextInput';
import PasswordIconAdornment from '../../components/PasswordIconAdornment';
import NewPasswordRequirements from '../../components/NewPasswordRequirements';
import SaveButton from '../../components/SaveButton/SaveButton';
import { AUTO_COMPLETE_VALUES, FIELD_TYPES } from '../../../../../constants/Users';
import {
    EZLOGIC_TITLE_CONFIRM_PASSWORD,
    EZLOGIC_TITLE_CONFIRM_YOUR_NEW_PASSWORD,
    EZLOGIC_TITLE_CURRENT_PASSWORD,
    NEW_PASSWORD_TITLE,
} from '../../../../../constants/language_tokens';

import userFormStyles from '../UserForm/styles.module.scss';
import styles from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { CONFIRM_PASSWORD_FIELD_NAME, CURRENT_PASSWORD_FIELD_NAME, NEW_PASSWORD_FIELD_NAME } from './constants';

const ChangeUserPasswordForm = () => {
    const { t } = useTranslate();
    const [showPassword, setShowPassword] = useState(false);
    const { isLoading } = useSelector(({ users }) => users);
    const { handleChange, values, touched, errors, handleSubmit } = useFormikContext();
    const formikArg = { values, errors, touched, handleChange };
    const passwordType = showPassword ? FIELD_TYPES.TEXT : FIELD_TYPES.PASSWORD;

    return (
        <form onSubmit={handleSubmit} autoComplete={t(AUTO_COMPLETE_VALUES.OFF)}>
            <div className={`${userFormStyles.userForm} ${styles.changePasswordContainer}`}>
                <TextInput
                    required={true}
                    name={CURRENT_PASSWORD_FIELD_NAME}
                    formikArg={formikArg}
                    label={t(EZLOGIC_TITLE_CURRENT_PASSWORD)}
                    className={userFormStyles.passwordField}
                    otherArguments={{
                        type: passwordType,
                        InputProps: {
                            autoComplete: t(AUTO_COMPLETE_VALUES.NEW_PASSWORD),
                            endAdornment: (
                                <PasswordIconAdornment setShowPassword={setShowPassword} showPassword={showPassword} />
                            ),
                        },
                    }}
                />

                <TextInput
                    required={true}
                    name={NEW_PASSWORD_FIELD_NAME}
                    formikArg={formikArg}
                    label={t(NEW_PASSWORD_TITLE)}
                    otherArguments={{
                        type: passwordType,
                    }}
                />

                <TextInput
                    required={true}
                    name={CONFIRM_PASSWORD_FIELD_NAME}
                    label={t(EZLOGIC_TITLE_CONFIRM_PASSWORD)}
                    otherArguments={{
                        type: passwordType,
                        placeholder: t(EZLOGIC_TITLE_CONFIRM_YOUR_NEW_PASSWORD),
                    }}
                />

                <NewPasswordRequirements passwordValue={values.NewPassword} />

                <SaveButton isDisabled={isLoading} />
            </div>
        </form>
    );
};

export default ChangeUserPasswordForm;
