import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classes from 'classnames';

import TimeOfDaySelectionSection from '../../containers/Ezlo/EzloMeshbot/components/templateDateTimeBlocks/TimeOfDaySelectionSection';
import at from '../../constants/ActionTypes/MeshBot';
import { week_wrapper, week_item, active } from './Week.module.scss';
import {
    EZLOGIC_TITLE_FRI,
    EZLOGIC_TITLE_MON,
    EZLOGIC_TITLE_SAT,
    EZLOGIC_TITLE_SUN,
    EZLOGIC_TITLE_THUR,
    EZLOGIC_TITLE_TUE,
    EZLOGIC_TITLE_WED,
} from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

/**
 * Create week component
 * @param { object } props
 * @returns { node } Week component
 * @example
 * <Week
 *      isArrayDate="example"
 *      onChange={func}
 * />
 */
export function WithTranslaion(Week) {
    return function MakeTraslationFn(props) {
        const useTranslateHook = useTranslate();

        return <Week {...props} useTranslate={() => useTranslateHook} />;
    };
}
class Week extends Component {
    constructor(props) {
        super(props);
        this.state = {
            week: [
                { name: EZLOGIC_TITLE_MON, checked: false },
                { name: EZLOGIC_TITLE_TUE, checked: false },
                { name: EZLOGIC_TITLE_WED, checked: false },
                { name: EZLOGIC_TITLE_THUR, checked: false },
                { name: EZLOGIC_TITLE_FRI, checked: false },
                { name: EZLOGIC_TITLE_SAT, checked: false },
                { name: EZLOGIC_TITLE_SUN, checked: false },
            ],
        };
    }

    componentDidMount() {
        this.setState({ week: this.formattingWeek(this.props.isArrayDate) });
    }

    // eslint-disable-next-line
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isArrayDate) {
            this.setState({ week: this.formattingWeek(nextProps.isArrayDate) });
        }
    }

    formattingWeek = (data) => {
        return this.state.week.map((item, index) => {
            if (data.includes(index + 1)) {
                item.checked = true;

                return item;
            }
            item.checked = false;

            return item;
        });
    };

    handleChange = (e, item) => {
        const { onChange } = this.props;

        onChange(e, item);
    };

    render() {
        const { week } = this.state;
        const { t } = this.props.useTranslate();
        const {
            id,
            actionId,
            idGroup,
            blocks,
            onSetDateValue,
            onSelectedDate,
            selectedFieldDate,
            isSupportsUpdatedAdvancedScenesForDays,
            typeMeshbot,
        } = this.props;

        return (
            <>
                <ul className={week_wrapper}>
                    {week.map((item, index) => {
                        return (
                            <li key={index + 1} className={classes(week_item, { [active]: item.checked })}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={(e) => this.handleChange(e, index + 1)}
                                        />
                                    }
                                    label={t(item.name)}
                                />
                            </li>
                        );
                    })}
                </ul>
                {typeMeshbot === at.LOCAL && (
                    <TimeOfDaySelectionSection
                        id={id}
                        actionId={actionId}
                        idGroup={idGroup}
                        blocks={blocks}
                        onSetDateValue={onSetDateValue}
                        onSelectedDate={onSelectedDate}
                        selectedFieldDate={selectedFieldDate}
                        isSupportsUpdatedAdvancedScenesForDays={isSupportsUpdatedAdvancedScenesForDays}
                    />
                )}
            </>
        );
    }
}

export default WithTranslaion(Week);

// Week.propTypes = {
//     isArrayDate: PropTypes.array,
//     onChange: PropTypes.func,
// };
