import React from 'react';
import PropTypes from 'prop-types';

import SettingsContent from './SettingsContent';
import styles from './CameraStreamSettings.module.scss';

const CameraStreamSettings = ({ data, title }) => (
    <>
        <h3 className={styles.title}>{title}</h3>
        {Object.entries(data.value).map((element) => (
            <SettingsContent element={element} />
        ))}
    </>
);

export default CameraStreamSettings;

CameraStreamSettings.propTypes = {
    data: PropTypes.object,
};
