const initState = {
    isValid: true,
    ezloName: '',
};

export default function (state = initState, action) {
    switch (action.type) {
        case '@react-redux-wizard/NEXT_STEP': {
            const { nextStep } = action.payload;
            if (action.payload.nextStep) {
                const newState = { ...state };

                newState.eZLOWizard.currentStep = nextStep;
                newState.eZLOWizard.stack = [].concat(newState.eZLOWizard.stack, nextStep);

                return newState;
            } else {
                return { ...state };
            }
        }
        case 'WIZARD_HUB_SERIAL_SELECTED_SERIAL_ID': {
            const { serialId } = action.payload;
            const newState = { ...state };
            newState.eZLOWizard.selectedSerialId = serialId;

            return newState;
        }
        case 'WIZARD_HUB_SET_VALIDATION_STATE': {
            const { isValid } = action.payload;

            return { ...state, isValid };
        }
        case 'WIZARD_HUB_SET_EZLO_DEVICE_NAME': {
            const { ezloName } = action.payload;

            return { ...state, ezloName };
        }
        case 'WIZARD_HUB_SET_EZLO_ATOM_SERIAL_NUMBER': {
            const { SN } = action.payload;
            const newState = { ...state };
            newState.eZLOWizard.SN = SN;

            return newState;
        }
        default:
            return state;
    }
}
