import React, { useState, useEffect, useMemo } from 'react';

import { CustomSelect } from './CustomSelect';

import { getMenuItems, updateMapConf } from '../utils';
import {
    capabilityNeedToReplace,
    getJsForCommand,
    virtualItemKeys,
    VIRTUAL_DEVICE_CALL,
    VIRTUAL_DEVICE_CODE,
    VIRTUAL_DEVICE_COMMAND,
} from '../virtual-templates';

import { API_CAPABILITY_ABSTRACT } from '../../../../constants/VirtualDeviceConstant';
import { EZLOGIC_TITLE_COMMAND, EZLOGIC_TITLE_COMMAND_VARIABLE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const { VIRTUAL_ACTIONS } = virtualItemKeys;

export const CommandsSelect = (props) => {
    const { command, isEdit, setVirtualDevice, capabilityAbstract, abstractCapabilities, virtualItemUpdateParams } =
        props;
    const [isDirtyState, setIsDirtyState] = useState(false);
    const [prevCommand, setPrevCommand] = useState(command);
    const { virtualItemKey } = virtualItemUpdateParams;
    const { capabilities } = abstractCapabilities;
    const capabilitiesValues = Object.values(capabilities);

    const { EXISTING_VALUE, EXPECTING_VALUE } = capabilityNeedToReplace;
    const changedCapabilities = capabilitiesValues.map((item) =>
        item.capability_name === EXISTING_VALUE ? { ...item, capability_name: EXPECTING_VALUE } : item,
    ); //TODO: remove when api_command will be renamed to api on BE side

    const { definition } = changedCapabilities?.find(({ capability_name }) => capability_name === capabilityAbstract);
    const { commands } = definition;
    const commandsList = commands.map((command) => Object.keys(command)[0]);
    const isVirtualAction = virtualItemKey === VIRTUAL_ACTIONS;
    const { t } = useTranslate();

    useEffect(() => {
        if (command !== prevCommand) {
            setIsDirtyState(true);
            setPrevCommand(command);
        }
    }, [command]);

    let commandVariablesList;
    let commandVariables;
    if (command) {
        let indexOfCommandVariable;

        commandsList.forEach((item, index) => {
            if (item === command) {
                indexOfCommandVariable = index;
            }
        });

        commandVariables = commands[indexOfCommandVariable]?.[command]?.variables;
        commandVariablesList = Object.keys(commandVariables || {});
    }

    const handleChangeCommand = (e) => {
        const updatedDevice = updateMapConf(virtualItemUpdateParams, 'command', e.target.value);

        setVirtualDevice(updatedDevice);
    };

    const isCommandVariablesEmpty = commands.some((commandObject) => {
        if (Object.keys(commandObject).includes('command')) {
            return Object.keys(commandObject).length;
        }

        return true;
    });
    const handleChangeCommandVariable = (e) => {
        const commandVariable = e.target.value;
        const jsCode = getJsForCommand(commandVariable, commandVariables[commandVariable].type);

        const updatedDevice = updateMapConf(virtualItemUpdateParams, VIRTUAL_DEVICE_CODE, jsCode);

        setVirtualDevice(updatedDevice);
    };

    useEffect(() => {
        if (capabilityAbstract === API_CAPABILITY_ABSTRACT) {
            const updatedDevice = updateMapConf(virtualItemUpdateParams, VIRTUAL_DEVICE_COMMAND, VIRTUAL_DEVICE_CALL);

            setVirtualDevice(updatedDevice);
        }
    }, [capabilityAbstract, setVirtualDevice]);

    const isRenderCustomSelect = useMemo(() => {
        return (
            (isEdit ? isDirtyState : true) &&
            isCommandVariablesEmpty &&
            isVirtualAction &&
            commandVariablesList &&
            !!getMenuItems(commandVariablesList).length
        );
    }, [commandVariablesList, isCommandVariablesEmpty, isDirtyState, isEdit, isVirtualAction]);

    return (
        <>
            <CustomSelect
                menuItems={getMenuItems(commandsList)}
                placeholder={t(EZLOGIC_TITLE_COMMAND)}
                labelId="select-device-service-capability"
                onChange={handleChangeCommand}
                value={command}
                isDisabled={capabilityAbstract === API_CAPABILITY_ABSTRACT}
            />

            {command && isRenderCustomSelect && (
                <CustomSelect
                    menuItems={getMenuItems(commandVariablesList)}
                    placeholder={t(EZLOGIC_TITLE_COMMAND_VARIABLE)}
                    labelId="select-command-variable-capability"
                    onChange={handleChangeCommandVariable}
                />
            )}
        </>
    );
};
