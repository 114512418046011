import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CloudOnceNode } from './CloudDateAndTimeSubnodes/CloudOnceSubnode/CloudOnceSubnode';
import { CloudSpecialTimeOfTheDay } from './CloudDateAndTimeSubnodes/CloudSpecialTimeOfTheDay/CloudSpecialTimeOfTheDay';
import DailyNode from './DateAndTime/DailyNode';
import IntervalNode from './DateAndTime/IntervalNode';
import WeeklyNode from './DateAndTime/WeeklyNode';
import MonthlyNode from './DateAndTime/MonthlyNode';

import {
    DATE_AND_TIME_SUB_NODES,
    getDefaultHourAndMinute,
    INTERVAL_MIN_INPUT_VALUE,
    INTERVAL_MINUTE_OPTION,
} from '../../../../constants/CloudMeshbotConstant';
import { SCHEDULE_DATE_TYPES } from '../../../../components/blockCloudTriggerTemplate/dateTemplatesForCloudMeshbot';

const isSpecialTimeOfTheDayType = (selectedFieldDate) => {
    return (
        selectedFieldDate === SCHEDULE_DATE_TYPES.SPECIAL_TIME_OF_THE_DAY ||
        selectedFieldDate === 'sunrise' ||
        selectedFieldDate === 'sunset'
    );
};

const TriggerDateFieldsCloud = (props) => {
    const { id, idGroup, blocks, selectedFieldDate, onUpdateDateTriggersCloud } = props;
    const { INTERVAL, DAILY, WEEKLY, MONTHLY, ONCE } = DATE_AND_TIME_SUB_NODES;

    // TODO create functions for extract fields from blocks instead of chaining
    const [intervalNumber, serIntervalNumber] = useState(
        blocks?.[0]?.parameters?.[0]?.value || INTERVAL_MIN_INPUT_VALUE,
    );
    const [intervalType, serIntervalType] = useState(
        blocks?.[0]?.parameters?.[0]?.measurement || INTERVAL_MINUTE_OPTION,
    );

    const onChangeTime = (time) => {
        onUpdateDateTriggersCloud(time, id, selectedFieldDate, idGroup);
    };

    // TODO refactor and simplify solution
    const onChangeDays = (item) => {
        let days = blocks[0]?.parameters?.[0].days;
        const time = blocks[0]?.parameters?.[0].time;

        if (days.includes(item)) {
            days = days.filter((dayNumber) => dayNumber !== item);
        } else {
            days.push(item);
            days.sort((a, b) => a - b);
        }

        if (time) {
            const timeToSet = time.length ? time : getDefaultHourAndMinute();

            onUpdateDateTriggersCloud({ days, time: timeToSet }, id, selectedFieldDate, idGroup);
        }
    };

    const handleChangeTimezone = (e, timezone) => {
        onUpdateDateTriggersCloud({ timezone }, id, selectedFieldDate, idGroup, 'timezone');
    };

    return (
        <>
            {selectedFieldDate === INTERVAL && (
                <IntervalNode
                    id={id}
                    selectedFieldDate={selectedFieldDate}
                    idGroup={idGroup}
                    blocks={blocks}
                    intervalNumber={intervalNumber}
                    intervalType={intervalType}
                    onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                    serIntervalNumber={serIntervalNumber}
                    serIntervalType={serIntervalType}
                    handleChangeTimezone={handleChangeTimezone}
                />
            )}
            {selectedFieldDate === DAILY && (
                <DailyNode blocks={blocks} onChangeTime={onChangeTime} handleChangeTimezone={handleChangeTimezone} />
            )}
            {selectedFieldDate === WEEKLY && (
                <WeeklyNode
                    blocks={blocks}
                    onChangeTime={onChangeTime}
                    onChangeDays={onChangeDays}
                    handleChangeTimezone={handleChangeTimezone}
                />
            )}
            {selectedFieldDate === MONTHLY && (
                <MonthlyNode
                    blocks={blocks}
                    onChangeDays={onChangeDays}
                    onChangeTime={onChangeTime}
                    handleChangeTimezone={handleChangeTimezone}
                />
            )}
            {selectedFieldDate === ONCE && (
                <CloudOnceNode
                    id={id}
                    selectedFieldDate={selectedFieldDate}
                    idGroup={idGroup}
                    blocks={blocks}
                    onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                    handleChangeTimezone={handleChangeTimezone}
                />
            )}
            {isSpecialTimeOfTheDayType(selectedFieldDate) && (
                <CloudSpecialTimeOfTheDay
                    id={id}
                    selectedFieldDate={selectedFieldDate}
                    idGroup={idGroup}
                    blocks={blocks}
                    onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                    handleChangeTimezone={handleChangeTimezone}
                />
            )}
        </>
    );
};

export default TriggerDateFieldsCloud;

TriggerDateFieldsCloud.propTypes = {
    id: PropTypes.string,
    selectedFieldDate: PropTypes.string,
    onUpdateDateTriggersCloud: PropTypes.func,
};
