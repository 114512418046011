import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import MeshBotSection from '../../../MeshBot/MeshBotSection';
import ActionTriggerBlockWrapper from '../../../components/TriggerBlock/ActionTriggerBlockWrapper';
import TriggerForActions from '../../../components/triggerForActions';
import BuilderFooter from '../../../MeshBot/BuilderFooter';

import { MESHBOT_TYPES } from '../../../../EzloMeshbots/constants';
import at from '../../../../../../constants/ActionTypes/MeshBot';
import hash from '../../../../../../constants/uniqueHash';
import { useDispatch, useSelector } from 'react-redux';
import MeshBotSectionCaption from '../../../components/MeshBotSectionCaption';
import { MESHBOT_SECTION_TYPE } from '../../../constants';

import {
    mesh_bot__builder,
    mesh_bot__builder_action,
    mesh_bot__list_action_cloud,
    list_actions,
} from '../../../EzloMeshbot.module.scss';
import { CLOUD_NOTIFICATION } from '../../../../../../constants/MeshbotConstant';

export const CloudMeshbotFormAction = (props) => {
    const {
        abstractDeviceActions,
        abstractsCapabilities,
        ruleCloudActions,
        CloudMeshbotActions,
        currentScene,
        channels,
        usersData,
        notificationType,
        handlerSetError,
        notificationTemplatePage,
    } = props;

    const [actionsList, updateActionsList] = useState([]);
    const [isDraggableAction, setIsDraggableAction] = useState(false);
    const dispatch = useDispatch();
    const isDisabledAddActionButton = notificationTemplatePage && actionsList.length === 1;
    const { cloudMetaLabels } = useSelector(({ meshBot }) => {
        return { cloudMetaLabels: meshBot.cloud.meta.labels };
    });

    useEffect(() => {
        updateActionsList(ruleCloudActions);
    }, [ruleCloudActions]);

    const handlePreventDraggable = useCallback((value) => {
        setIsDraggableAction(value);
    }, []);

    const addAction = () => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.addCloudAction({ id: hash(), isEditingState: false }));
        } else {
            CloudMeshbotActions.addCloudAction({ id: hash(), isEditingState: false });
        }
    };

    const deleteAction = (id) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.deleteCloudActions(id));
        } else {
            CloudMeshbotActions.deleteCloudActions(id);
        }
    };

    const onSelected = (value, idDevice, name = 'abstract') => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateActionCloud(idDevice, value, name));
        } else {
            CloudMeshbotActions.updateActionCloud(idDevice, value, name);
        }
    };

    const updateTriggerAction = (e, id, field) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateActionCloud(id, e.target.value, field));
            dispatch(CloudMeshbotActions.updateCloudActionMeta(e.target.value, cloudMetaLabels));
        } else {
            CloudMeshbotActions.updateActionCloud(id, e.target.value, field);
            CloudMeshbotActions.updateCloudActionMeta(e.target.value, cloudMetaLabels);
        }
    };

    const getNotificationData = (e, id, type = CLOUD_NOTIFICATION) => {
        // TODO: Let's remove IF condition, remove "dispatch" and make code the same.
        // TODO: Required with next component refactoring!
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateActionCloud(id, e, type));
        } else {
            CloudMeshbotActions.updateActionCloud(id, e, type);
        }
    };

    const handleOnDragEndDrop = (result, actionsList) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(actionsList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateActionsList(items);
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateCloudActionDragAndDrop(items));
        } else {
            CloudMeshbotActions.updateCloudActionDragAndDrop(items);
        }
    };

    const updateCloudRequiredFieldAction = (id, required) => {
        CloudMeshbotActions.updateCloudRequiredAction(id, required);
    };

    const validationCheck = (props, id) => {
        CloudMeshbotActions.validateCloudAction(props, id);
    };

    const validationCheckEdit = (props, id) => {
        CloudMeshbotActions.validateCloudActionEdit(props, id);
    };

    return (
        <MeshBotSection>
            <MeshBotSectionCaption type={MESHBOT_SECTION_TYPE.ACTION} />
            <div className={`${mesh_bot__builder} ${mesh_bot__builder_action}`}>
                <>
                    <div className={mesh_bot__list_action_cloud}>
                        <DragDropContext onDragEnd={(e) => handleOnDragEndDrop(e, actionsList, 'then')}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                        {actionsList.map((device, index) => {
                                            return (
                                                <Draggable
                                                    key={device.id ? device.id : device._tempId}
                                                    draggableId={device.id ? device.id : device._tempId}
                                                    index={index}
                                                    isDragDisabled={isDraggableAction}
                                                >
                                                    {(provided, snapshot) => (
                                                        <ActionTriggerBlockWrapper
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                        >
                                                            <div className={list_actions}>
                                                                <TriggerForActions
                                                                    provided={provided}
                                                                    key={device.id}
                                                                    id={device.id}
                                                                    items={ruleCloudActions}
                                                                    name={device?.abstract?.name}
                                                                    onPreventDraggable={handlePreventDraggable}
                                                                    {...device}
                                                                    // TODO why we have 2 different options with the same value
                                                                    typeMeshbot={at.CLOUD}
                                                                    type="cloud"
                                                                    currentItem={device}
                                                                    currentDeviceAction={abstractDeviceActions}
                                                                    onUpdateTriggerAction={updateTriggerAction}
                                                                    onSelected={onSelected}
                                                                    abstractsCapabilities={abstractsCapabilities}
                                                                    deleteTriggerAction={deleteAction}
                                                                    currentRule={ruleCloudActions}
                                                                    currentScene={currentScene}
                                                                    actionsList={actionsList}
                                                                    channels={channels}
                                                                    usersData={usersData}
                                                                    getNotificationData={getNotificationData}
                                                                    notificationType={notificationType}
                                                                    handlerSetError={handlerSetError}
                                                                    updateCloudRequiredFieldAction={
                                                                        updateCloudRequiredFieldAction
                                                                    }
                                                                    updateValidation={validationCheck}
                                                                    updateValidationEdit={validationCheckEdit}
                                                                />
                                                            </div>
                                                        </ActionTriggerBlockWrapper>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <BuilderFooter
                            meshbotType={MESHBOT_TYPES.CLOUD}
                            type="actions"
                            onAddTrigger={addAction}
                            disabled={isDisabledAddActionButton}
                        />
                    </div>
                </>
            </div>
        </MeshBotSection>
    );
};
