import React from 'react';
// import { object } from 'prop-types';
import './style.scss';
import SwitchBool from '../../../../../components/SwitchBool';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';

export default class DeviceSettingBool extends React.Component {
    // static propTypes = {
    //     settings: object.isRequired,
    //     device: object,
    // };

    state = {
        value: this.props.settings.value || false,
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    saveHandler = () => {
        const { onSave, settings } = this.props;
        const { value } = this.state;

        if (settings._id) {
            onSave(settings._id, value);
        }
    };

    onValueChange = () => {
        const { value } = this.state;

        this.setState({ value: !value }, () => this.saveHandler());
    };

    render() {
        const { settings, parameterIdTitle, statusTitle } = this.props;
        const { value } = this.state;
        const { description, status, info } = settings;
        const paramId = info && info['zwave.parameter_number'];

        return (
            <div key={paramId} className="device-alarm__wrapper">
                {status && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{statusTitle}</h5>
                            <span className="device-alarm__status">{status}</span>
                        </div>
                        <DeviceSettingInfo />
                    </div>
                )}

                {paramId && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{parameterIdTitle}</h5>
                            <span className="device-alarm__status">{paramId}</span>
                        </div>
                    </div>
                )}

                {description && <p className="device-alarm__text">{description.text}</p>}

                <div className="device-range-slider__box">
                    <SwitchBool checked={value} onChange={this.onValueChange} />
                </div>
            </div>
        );
    }
}
