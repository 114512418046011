import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';

import { getDefaultVirtualDevice } from '../utils';
import { SETTINGS_OF_VIRTUAL_DEVICE_FIELD } from '../../../../constants/VirtualDeviceConstant';

import styles from '../styles.module.scss';
import { EZLOGIC_BUTTON_ADD_MORE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const AddVirtualDeviceButton = ({ arrayHelpers }) => {
    const { t } = useTranslate();
    const {
        values: { virtualDevices },
    } = useFormikContext();
    const {
        VIRTUAL_DEVICES: { MAX },
    } = SETTINGS_OF_VIRTUAL_DEVICE_FIELD;
    const idDisabledAddButton = Boolean(virtualDevices.length === MAX);

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        arrayHelpers.push(getDefaultVirtualDevice());
    }, []);

    const memoButton = useMemo(() => {
        return (
            <div className={styles.addButton}>
                <Button disabled={idDisabledAddButton} variant="outlined" onClick={handleClick}>
                    {t(EZLOGIC_BUTTON_ADD_MORE)}
                </Button>
            </div>
        );
    }, [idDisabledAddButton]);

    return memoButton;
};

export default AddVirtualDeviceButton;
