import './style.scss';
import React, { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { ROWS_PER_PAGE } from '../../containers/Ezlo/EzloMeshbots/constants';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../features/languages';
import { dataGridProLocaleText } from './confige';

const ToolbarComponent = () => <GridToolbar className="grid-toolbar" />;

// TODO: COVER BY UNIT TESTS EVERY SINGLE PROPERTY WITH NEXT REFACTORING! A LOT OF UNDEFINED VALUES!
const GridTable = (props) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(ROWS_PER_PAGE[1]);
    const { systemHistoryList } = useSelector(({ systemHistory }) => systemHistory);
    const { t } = useTranslate();
    const { loaderList, isSystemHistory, loadMoreData } = props;

    const handlePageChange = (newPage) => {
        const start = systemHistoryList?.length;
        const count = (page + 2) * pageSize;
        setPage(newPage);

        if (isSystemHistory && count >= start && newPage > page) {
            loadMoreData(start, count);
        }
    };

    const handlePageSizeChange = (newPageSize) => {
        const start = systemHistoryList?.length;
        const count = (page + 2) * newPageSize;
        setPageSize(newPageSize);

        if (isSystemHistory && count > start) {
            loadMoreData(start, count);
        }
    };

    return (
        <div className="grid-table-wrapper">
            <DataGridPro
                className="grid-table"
                components={{ Toolbar: ToolbarComponent }}
                page={page}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                loading={loaderList}
                localeText={dataGridProLocaleText(t)}
                {...props}
            />
        </div>
    );
};
export default GridTable;

export const CustomGridProTable = (props) => {
    const { t } = useTranslate();
    const { ToolbarComponent, CheckboxComponent, paginationProps } = props;
    const localeText = dataGridProLocaleText(t);

    return (
        <div className="grid-table-wrapper">
            <DataGridPro
                className="grid-table"
                components={{
                    Toolbar: ToolbarComponent,
                    Checkbox: CheckboxComponent,
                }}
                page={paginationProps.page}
                pageSize={paginationProps.rowsPerPage}
                localeText={localeText}
                componentsProps={{ toolbar: { paginationProps } }}
                {...props}
            />
        </div>
    );
};
