import { useEffect, useState, useRef } from 'react';

import { buildMeshBotsPageConfig } from '../../utils';
import {
    debounceFnForUpdateMeshbotsPageConfig,
    getMeshbotsPageConfigFromKvs,
    updateMeshbotsPageConfig,
    SHOW_SYSTEM_MESHBOTS,
} from '../../../../../services/kvs';
import { isEmpty } from 'lodash';

const useMeshbotsPageConfig = () => {
    const [meshBotsTableState, setMeshBotsTableState] = useState({});
    const [meshBotPageConfig, setMeshBotPageConfig] = useState(buildMeshBotsPageConfig());
    const configStateRef = useRef({ meshBotsTableState, meshBotPageConfig });

    // Save up-to-date state that we need when the component is unmounting
    useEffect(() => {
        configStateRef.current = { meshBotsTableState, meshBotPageConfig };
    }, [meshBotsTableState, meshBotPageConfig]);

    // Updating the MeshBots page config in kvs with the help of debounce function
    useEffect(() => {
        debounceFnForUpdateMeshbotsPageConfig(meshBotsTableState, meshBotPageConfig[SHOW_SYSTEM_MESHBOTS]);
    }, [
        meshBotsTableState?.columns?.lookup,
        meshBotsTableState?.density?.value,
        meshBotPageConfig[SHOW_SYSTEM_MESHBOTS],
    ]);

    // Fetch the MeshBots page config from kvs and save it to the application state
    useEffect(() => {
        const fetchMeshbotsPageConfig = async () => {
            const kvsMeshBotsPageConfig = await getMeshbotsPageConfigFromKvs();

            if (kvsMeshBotsPageConfig) {
                setMeshBotPageConfig(buildMeshBotsPageConfig(kvsMeshBotsPageConfig));
            }
        };

        fetchMeshbotsPageConfig();

        // Saving the page config in KVS at the time of unmounting the component
        return () => {
            updateMeshbotsPageConfig(
                configStateRef.current.meshBotsTableState,
                configStateRef.current.meshBotPageConfig[SHOW_SYSTEM_MESHBOTS],
            );
        };
    }, []);

    // Subscribe and unsubscribe to the page refresh event to save the page config in KVS
    useEffect(() => {
        const beforeUnLoad = () => {
            updateMeshbotsPageConfig(meshBotsTableState, meshBotPageConfig[SHOW_SYSTEM_MESHBOTS]);
        };

        window.addEventListener('beforeunload', beforeUnLoad);

        return () => window.removeEventListener('beforeunload', beforeUnLoad);
    }, [meshBotsTableState, meshBotPageConfig[SHOW_SYSTEM_MESHBOTS]]);

    const systemMeshBotsToggle = ({ target }) => {
        setMeshBotPageConfig((prevState) => ({ ...prevState, [SHOW_SYSTEM_MESHBOTS]: target.checked }));
    };
    const handleMeshBotsTableStateChange = (state) => {
        if (!isEmpty(state)) {
            setMeshBotsTableState(state);
        }
    };

    return {
        ...meshBotPageConfig,
        meshBotsTableState,
        handleMeshBotsTableStateChange,
        systemMeshBotsState: {
            isSystemMeshBotsChecked: meshBotPageConfig[SHOW_SYSTEM_MESHBOTS],
            handleSystemMeshBotsToggle: systemMeshBotsToggle,
        },
    };
};

export default useMeshbotsPageConfig;
