import PasswordTitle from '../PasswordTitle';
import React from 'react';
import SetPasswordForm from './SetPasswordForm';
import { useFormik } from 'formik';

import { validationSchema } from '../../utils/validationSchema';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_PLEASE_ENTER_PASSWORD,
    EZLOGIC_TITLE_SET_YOUR_PASSWORD,
} from '../../../../../constants/language_tokens';
import ActionsWrapper from '../../../components/ActionsWrapper';

import styles from './SetPasswordForm.module.scss';

const SetPasswordFormContainer = ({ isLoading, onSubmit }) => {
    const { t } = useTranslate();
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnMount: true,
    });

    return (
        <>
            <PasswordTitle
                title={t(EZLOGIC_TITLE_SET_YOUR_PASSWORD)}
                subTitle={t(EZLOGIC_TITLE_PLEASE_ENTER_PASSWORD)}
                className={styles.title}
            />
            <ActionsWrapper>
                <SetPasswordForm isLoading={isLoading} formik={formik} />
            </ActionsWrapper>
        </>
    );
};

export default SetPasswordFormContainer;
