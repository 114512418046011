import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';

import { useTranslate } from '../../../languages';
import {
    EZLOGIC_TITLE_CLOUD_SPACE_INFO,
    EZLOGIC_TITLE_STORAGE_LIMIT_INFO_TEXT,
} from '../../../../constants/language_tokens';

import styles from './styles.module.scss';
import Info from '../../../../assets/icons/visuals/generalIcons/Info';

const Tooltip = withStyles({
    tooltip: {
        fontSize: '13px!important',
        minWidth: '500px',
    },
})(MuiTooltip);

const StorageLimitComponent = () => {
    const { t } = useTranslate();

    return (
        <div className={styles.limitInfoSection}>
            <Tooltip arrow title={t(EZLOGIC_TITLE_STORAGE_LIMIT_INFO_TEXT)} role="mui-tooltip">
                <div>
                    <Info role="icon" />
                </div>
            </Tooltip>
            <span>{t(EZLOGIC_TITLE_CLOUD_SPACE_INFO)}</span>
        </div>
    );
};

export default StorageLimitComponent;
