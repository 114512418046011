import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import InfoButton from '../../../InfoButton';

import {
    INPUT_ADORONMENT_END_POSITION,
    INPUT_TYPE_NUMBER,
    DATA_TYPES_LIST,
    INPUT_TYPE_NUMBER_STEP,
} from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';

import { rangeInput } from './ExpressionNumberRangeInputs.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const ExpressionNumberRangeInputs = (props) => {
    const { convertedExpressionType, onHandleChangeComparingValue, initialRangeStartValue, initialRangeEndValue } =
        props;
    const { t } = useTranslate();

    return (
        <>
            <TextField
                type={INPUT_TYPE_NUMBER}
                label={t(info.hints.valueStart)}
                className={rangeInput}
                value={initialRangeStartValue ?? ''}
                onChange={(e) =>
                    onHandleChangeComparingValue({ startValue: +e.target.value, endValue: initialRangeEndValue })
                }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                            <InfoButton>
                                <div dangerouslySetInnerHTML={{ __html: t(info.text.valueStart) }}></div>
                            </InfoButton>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    step: convertedExpressionType === DATA_TYPES_LIST.TYPE_FLOAT && INPUT_TYPE_NUMBER_STEP,
                }}
            />
            <TextField
                type={INPUT_TYPE_NUMBER}
                label={t(info.hints.valueEnd)}
                className={rangeInput}
                value={initialRangeEndValue ?? ''}
                onChange={(e) =>
                    onHandleChangeComparingValue({ endValue: +e.target.value, startValue: initialRangeStartValue })
                }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                            <InfoButton>
                                <div dangerouslySetInnerHTML={{ __html: t(info.text.valueEnd) }}></div>
                            </InfoButton>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    step: convertedExpressionType === DATA_TYPES_LIST.TYPE_FLOAT && INPUT_TYPE_NUMBER_STEP,
                }}
            />
        </>
    );
};

export default ExpressionNumberRangeInputs;
