import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormControl, IconButton, MenuItem, Select } from '@material-ui/core';

import RequestFields from './components/RequestFields';
import ActionFields from './components/ActionFields';

import * as generator from '../../../../../constants/DevicePluginGenerator';
import { optionsSelect } from '../../../../../constants/MeshbotConstant';
import { formatNameWithUnderscores } from '../../../../../helpers/helpersMeshBot';

import style from './templateCapability.module.scss';
import field from './components/RequestFields/requestFields.module.scss';

const TemplateCapability = (props) => {
    const { capability, commandList, onSetValueItem, onSetHeaders, onSetCommand, onRemoveCapability } = props;

    return (
        <div className={style.templateCapability}>
            <FormControl className={style.templateCapability__select}>
                <p className={style.templateCapability__label}>Ezlo Capability</p>
                <Select
                    MenuProps={optionsSelect}
                    name={generator.COMMAND}
                    value={capability.command ?? ''}
                    onChange={(e) => onSetCommand(e, capability.id)}
                >
                    {commandList.map((command) => {
                        return (
                            <MenuItem key={command.id} value={command.name}>
                                {formatNameWithUnderscores(command.name)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {capability.command !== '' && (
                <div className={style.templateCapability__actions}>
                    {Array.isArray(capability.actions) &&
                        capability.actions.map((action) => {
                            if (action.hasOwnProperty(generator.METHOD)) {
                                return (
                                    <div
                                        key={action.id}
                                        className={`${field.requestFields} ${style.templateCapability__actions_item}`}
                                    >
                                        <div className={style.templateCapability__actions_name}>
                                            <p className={field.requestFields__label}>{generator.ACTION}</p>
                                            <p className={field.requestFields__value}>
                                                {formatNameWithUnderscores(action.id)}
                                            </p>
                                        </div>
                                        <RequestFields
                                            action={action}
                                            capabilityId={capability.id}
                                            onSetValueItem={onSetValueItem}
                                            onSetHeaders={onSetHeaders}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <ActionFields
                                        key={action.id}
                                        action={action}
                                        capabilityId={capability.id}
                                        onSetValueItem={onSetValueItem}
                                    />
                                );
                            }
                        })}
                </div>
            )}
            <IconButton
                className={style.templateCapability__buttonDelete}
                onClick={() => onRemoveCapability(capability.id)}
            >
                <DeleteIcon />
            </IconButton>
        </div>
    );
};

TemplateCapability.propTypes = {
    capability: PropTypes.object,
    commandList: PropTypes.array,
    onSetValueItem: PropTypes.func,
    onSetHeaders: PropTypes.func,
    onSetCommand: PropTypes.func,
    onRemoveCapability: PropTypes.func,
};

export default TemplateCapability;
