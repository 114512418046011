import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Input from '../../../../../components/InputMaterial';

import { SELECTED_HEADERS, HEADERS } from '../../../../../constants/MeshbotConstant';
import hash from '../../../../../constants/uniqueHash';
import { transformHeaders, checkedEmptyKey } from '../../utils';

import styles from './HttpRequest.module.scss';
import {
    EZLOGIC_TITLE_ADD_HEADER,
    EZLOGIC_TITLE_KEY,
    EZLOGIC_TITLE_KEY_HEADER,
    EZLOGIC_TITLE_SET_OF_CUSTOM_HTTP,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VALUE_HEADER,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { DeleteIcon } from '../../../../../assets/icons';

const Headers = (props) => {
    const [headersList, setHeadersList] = useState([]);
    const { selectedHeaders, onHandlerSetActionField } = props;
    const { t } = useTranslate();
    useEffect(() => {
        setHeadersList(
            Object.entries(selectedHeaders).map((item) => {
                return { id: hash(), key: item[0], value: item[1], valid: true };
            }),
        );
    }, [selectedHeaders]);

    const handlerAddHeader = () => {
        const header = { id: hash(), key: `__empty-${hash()}`, value: '', valid: true };
        const value = transformHeaders([...headersList, header]);

        onHandlerSetActionField(value, SELECTED_HEADERS, HEADERS);
    };

    const handlerDelete = (id) => {
        const result = headersList.filter((item) => item.id !== id);
        const value = transformHeaders(result);

        onHandlerSetActionField(value, SELECTED_HEADERS, HEADERS);
        setHeadersList(result);
    };

    const handlerChangeHeaders = (e, field, id) => {
        setHeadersList(
            headersList.map((item) => {
                const { value } = e.target;
                if (item.id === id) {
                    if (field === 'key' && !value) {
                        item[field] = `__empty-${hash()}`;

                        return item;
                    }
                    item[field] = value;

                    return item;
                }

                return item;
            }),
        );
    };

    const validationHeader = () => {
        const value = transformHeaders(headersList);
        onHandlerSetActionField(value, SELECTED_HEADERS, HEADERS);
    };

    return (
        <section className={styles.section}>
            <h4 className={styles.title}>{t(EZLOGIC_TITLE_SET_OF_CUSTOM_HTTP)}</h4>
            {headersList.map((item) => {
                return (
                    <div key={item.id} className={styles.header_item}>
                        <FormControl className={styles.input_block}>
                            <Input
                                label={t(EZLOGIC_TITLE_KEY)}
                                error={!checkedEmptyKey(item.key)}
                                placeholder={t(EZLOGIC_TITLE_KEY_HEADER)}
                                value={checkedEmptyKey(item.key)}
                                onBlur={validationHeader}
                                onChange={(e) => handlerChangeHeaders(e, 'key', item.id)}
                            />
                        </FormControl>
                        <FormControl className={styles.input_block}>
                            <Input
                                label={t(EZLOGIC_TITLE_VALUE)}
                                error={!checkedEmptyKey(item.value)}
                                placeholder={t(EZLOGIC_TITLE_VALUE_HEADER)}
                                value={item.value}
                                disabled={!checkedEmptyKey(item.key)}
                                onBlur={validationHeader}
                                onChange={(e) => handlerChangeHeaders(e, 'value', item.id)}
                            />
                        </FormControl>
                        <IconButton onClick={() => handlerDelete(item.id)} className={styles.header_delete_button}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            })}
            <Button
                variant="contained"
                color="primary"
                className={styles.buttonHeader}
                startIcon={<AddIcon />}
                onClick={handlerAddHeader}
            >
                {t(EZLOGIC_TITLE_ADD_HEADER)}
            </Button>
        </section>
    );
};

export default React.memo(Headers);

Headers.propTypes = {
    selectedHeaders: PropTypes.object,
    onHandlerSetActionField: PropTypes.func,
};
