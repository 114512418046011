import React from 'react';
import PropTypes from 'prop-types';

import { IntegrationCard } from '../../../../components/IntegrationCard/IntegrationCard';

const IntegrationCardContainer = (props) => {
    const { name, onClick, icon, capabilities, description, title, uuid, isIntegrations, integrationType, data, meta } =
        props;

    const handleClick = () => {
        onClick(uuid);
    };

    return (
        <IntegrationCard
            name={name}
            handleParjectClick={handleClick}
            icon={icon}
            capabilities={capabilities}
            description={description}
            title={title}
            isIntegrations={isIntegrations}
            integrationType={integrationType}
            data={data}
            meta={meta}
            uuid={uuid}
        />
    );
};

IntegrationCardContainer.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    capabilities: PropTypes.array,
    description: PropTypes.string,
    title: PropTypes.string,
    uuid: PropTypes.string,
};

export default IntegrationCardContainer;
