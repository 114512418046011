import React from 'react';

import ErrorIcon from '../icons/ErrorIcon';
import classNames from 'classnames';
import {
    EZLOGIC_BUTTON_GOT_IT,
    EZLOGIC_TITLE_BACKUP_PROCESS_WAS_INTERRUPTED,
    EZLOGIC_TITLE_CONNECTION_ISSUE,
} from '../../../../constants/language_tokens';
import { Button } from '../../../../components';
import { useTranslate } from '../../../../features/languages';

const BackupCreateError = ({ onFinish }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <ErrorIcon className="icon-completed" />
            <span className={classNames('text', 'title')}>{t(EZLOGIC_TITLE_BACKUP_PROCESS_WAS_INTERRUPTED)}</span>
            <span className={classNames('text-auto-width', 'description')}>{t(EZLOGIC_TITLE_CONNECTION_ISSUE)}</span>
            <div className="main-content-footer">
                <Button
                    color="primary"
                    variant="contained"
                    className={classNames('primary-btn', 'warning')}
                    onClick={onFinish}
                >
                    {t(EZLOGIC_BUTTON_GOT_IT)}
                </Button>
            </div>
        </div>
    );
};

export default BackupCreateError;
