export const getHex = (red, green, blue) => {
    const componentToHex = (c) => {
        const hex = c.toString(16);

        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${componentToHex(red)}${componentToHex(green)}${componentToHex(blue)}`;
};

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return {
        red: parseInt(result?.[1], 16),
        green: parseInt(result?.[2], 16),
        blue: parseInt(result?.[3], 16),
    };
};

export const getDesiredFormatValue = (hex, value, isChangingColorPicker) => {
    const rgbObj = hexToRgb(hex);

    return isChangingColorPicker ? { wwhite: 0, cwhite: 0, ...rgbObj } : value;
};

export const isHex = (string) => {
    return /^#[0-9A-F]{6}$/i.test(string);
};
