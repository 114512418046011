import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../../actions';
import EditDeviceTemplate from './EditDeviceTemplate';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    isRequestStatus: state.plugins.isRequestStatus,
    isRequestStatusMessage: state.plugins.isRequestStatusMessage,
    privateWifiTemplates: state.plugins.privateWifiTemplates,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditDeviceTemplate);
