import React from 'react';
import PropTypes from 'prop-types';
import { InputMaterial } from '../index';
import { Box, MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';
import InputAndColorPicker, { ColorSlider } from '../ColorInputAndColorPickers';

import {
    isRgb,
    isSimpleInput,
    isString,
    isTemperature,
    isValidRgbValue,
} from '../../containers/Ezlo/EzloVirtualDevice/utils'; // TODO: avoid Virtual Device dependencies
import { isTemperatureCapability } from '../../containers/Ezlo/EzloMeshbot/utils';

import { ACTIONS } from '../../constants/MeshbotConstant';
import { CLOUD_CAPABILITY_VARIABLE_TYPE } from './constants';
import { MIN_TEMPERATURE, STATUS } from '../../constants/NotificationTemplates';

import styles from './InputTypeCloud.module.scss';
import FieldsForInputTypeObject from '../../containers/Ezlo/EzloMeshbot/components/FieldsForInputTypeObject';
import {
    EZLOGIC_HINTS_VARIABLE,
    EZLOGIC_PLACEHOLDER_ENTER_NAME,
    EZLOGIC_PLACEHOLDER_ENTER_NUMBER,
    EZLOGIC_PLACEHOLDER_ENTER_VALUE,
    EZLOGIC_TITLE_VALUE,
} from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

// TODO: refactoring needed:
// Rename - use the name based on the component responsibility - CloudAbstractCapabilityVariableValueInput - self explaining
// Simplify the component, use only necessary props:
// 1) "value" - variable value itself
// 2) "onChange" - method to modify the value
// 3) "inputType" - will define UI component representation:
// 3.1) primitives (integer, string)
// 3.2) complex/custom types, like "rgb", "scalable", "temperature" etc.
// Split current component by many small components for each specific type
// Remove all outside dependencies - e.g. "calledFrom", "notificationTemplate", nameSubBlock etc.
const InputTypeCloud = (props) => {
    const {
        onChange,
        currentInputType = {}, // variable name
        value,
        nameSubBlock, // capability name
        calledFrom, // todo: we must not care where from
        notificationTemplate,
        inputType,
    } = props;
    const { t } = useTranslate();

    const updatedValue = () => {
        if (value?.[0]) {
            return value?.[0].value ? value?.[0].value : '';
        } else {
            return value?.value;
        }
    };

    if (!currentInputType || (notificationTemplate && !nameSubBlock)) {
        return null;
    }

    const boolIntTemplate = [
        { name: 'true', value: '1' },
        { name: 'false', value: '0' },
    ];

    const onChangeMultipleSelect = (e) => {
        onChange(e);
    };

    const onChangeSimpleInputTypeValue = (e) => {
        onChange(e.target.value, !isValidRgbValue(e, nameSubBlock));
    };

    const newSliderValue = () => {
        if (typeof value === 'object' && value.red) {
            return value;
        } else {
            return {
                red: value?.[0]?.value ? Number(value?.[0]?.value) : 0,
                green: value[1]?.value ? Number(value[1]?.value) : 0,
                blue: value[1]?.value ? Number(value[2]?.value) : 0,
            };
        }
    };

    // TODO: why boolean by default?
    // Move each input for different types to separate components
    // Let's prevent function call on every render
    const getInput = () => {
        let input; // No need to have variable, just return component

        // TODO: hotfix for the "string" type
        if (inputType === CLOUD_CAPABILITY_VARIABLE_TYPE.STRING) {
            return (
                <div className={styles.input}>
                    <InputMaterial
                        label={t(EZLOGIC_TITLE_VALUE)}
                        placeholder={t(EZLOGIC_PLACEHOLDER_ENTER_VALUE)}
                        type={inputType}
                        onChange={(e) => onChangeSimpleInputTypeValue(e, currentInputType)}
                        value={calledFrom === ACTIONS ? updatedValue() : value}
                    />
                </div>
            );
        } else if (isSimpleInput(currentInputType, nameSubBlock) && currentInputType !== STATUS) {
            input = (
                <div className={styles.input}>
                    <InputMaterial
                        label={t(EZLOGIC_TITLE_VALUE)}
                        placeholder={
                            isString(currentInputType, nameSubBlock)
                                ? t(EZLOGIC_PLACEHOLDER_ENTER_NAME)
                                : t(EZLOGIC_PLACEHOLDER_ENTER_NUMBER)
                        }
                        type={isString(currentInputType, nameSubBlock) ? 'string' : 'number'}
                        onChange={(e) => onChangeSimpleInputTypeValue(e, currentInputType)}
                        value={calledFrom === ACTIONS ? updatedValue() : value}
                        error={isValidRgbValue(value, nameSubBlock)} // TODO: why rgb validation here???
                        min={isTemperatureCapability(nameSubBlock) ? MIN_TEMPERATURE : 0}
                    />
                </div>
            );
        } else if (isTemperature(currentInputType)) {
            input = (
                <Box className={styles.sliders_box}>
                    <ColorSlider
                        sliderView="target_cool_white"
                        value={
                            value?.[1]?.value
                                ? Number(value[1].value)
                                : currentInputType?.target_cool_white?.value || value.target_cool_white
                        }
                        onChangeSlider={onChange}
                    />
                    <ColorSlider
                        sliderView="target_warm_white"
                        value={
                            value?.[0]?.value
                                ? Number(value?.[0].value)
                                : currentInputType?.target_warm_white?.value || value.target_warm_white
                        }
                        onChangeSlider={onChange}
                    />
                </Box>
            );
        } else if (isRgb(currentInputType)) {
            input = (
                <InputAndColorPicker
                    isCloud={true}
                    isEdit={!!value?.[0]?.value}
                    handleUpdateFieldActionBlock={onChange}
                    value={newSliderValue()}
                />
            );
        } else if (Object.keys(currentInputType).length > 1 && typeof currentInputType === 'object') {
            input = (
                <FieldsForInputTypeObject
                    onChangeMultipleSelect={onChangeMultipleSelect}
                    value={value}
                    nameSubBlock={nameSubBlock}
                />
            );
        } else {
            input = (
                <SelectComponent
                    value={value?.[0]?.value || value?.value || value}
                    label={t(EZLOGIC_TITLE_VALUE)}
                    info={t(EZLOGIC_HINTS_VARIABLE)}
                    onChange={onChange}
                    small
                >
                    {boolIntTemplate.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            );
        }

        return input;
    };

    return <div className={styles.field}>{getInput()}</div>;
};

InputTypeCloud.propTypes = {
    inputType: PropTypes.string,
};

export default InputTypeCloud;
