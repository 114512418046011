import React, { useState, useRef } from 'react';
import { MenuItem, Button, Grow, Paper, Popper, MenuList, ClickAwayListener } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslate } from '../../../../../../../features/languages';
import classNames from 'classnames';

import {
    TREE_ELEMENTS_STATE_OPTIONS_LIST,
    DEVICE_GROUP_OTHERS_SUBCATEGORY,
    MENU_BUTTONS_STATES,
} from '../../../../../../../constants/DevicesGroups';
import {
    TAB,
    TRUE_STRING,
    CENTER_TOP,
    BOTTOM,
    MENU_LIST_GROW,
    CENTER_BOTTOM,
} from '../../../../../../../constants/Devices';

import styles from './DeviceGroupTreeItemStateMenu.module.scss';

const DeviceGroupTreeItemStateMenu = (props) => {
    const { value, onChange, options, labelName, buttonState } = props;
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const anchorRef = useRef(null);
    const buttonStyles = classNames(styles.menuButton, {
        [styles.inherited]:
            buttonState === MENU_BUTTONS_STATES.INHERITED || buttonState === MENU_BUTTONS_STATES.EXCLUDED,
        [styles.included]: buttonState === MENU_BUTTONS_STATES.INCLUDED,
        [styles.inheritedIncluded]: buttonState === MENU_BUTTONS_STATES.INHERITED_INCLUDED,
        [styles.inheritedIncludedDisabled]:
            labelName === DEVICE_GROUP_OTHERS_SUBCATEGORY && buttonState === MENU_BUTTONS_STATES.INHERITED_INCLUDED,
    });
    const { t } = useTranslate();

    const handleToggleMenu = (event) => {
        event.stopPropagation();
        setIsOpenMenu((prevOpen) => !prevOpen);
    };

    const handleSelectStateValue = (event, value) => {
        event.stopPropagation();

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (value) {
            onChange(value);
        }

        setIsOpenMenu(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === TAB) {
            event.preventDefault();
            setIsOpenMenu(false);
        }
    };

    return (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={isOpenMenu ? MENU_LIST_GROW : undefined}
                aria-haspopup={TRUE_STRING}
                onClick={handleToggleMenu}
                variant="outlined"
                size="small"
                disabled={labelName === DEVICE_GROUP_OTHERS_SUBCATEGORY}
                endIcon={<ExpandMore className={styles.arrowIcon} />}
                className={buttonStyles}
            >
                {t(TREE_ELEMENTS_STATE_OPTIONS_LIST[value])}
            </Button>
            <Popper open={isOpenMenu} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === BOTTOM ? CENTER_TOP : CENTER_BOTTOM }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleSelectStateValue}>
                                <MenuList autoFocusItem={isOpenMenu} id={MENU_LIST_GROW} onKeyDown={handleListKeyDown}>
                                    {options?.map((elem, index) => {
                                        return (
                                            <MenuItem
                                                key={index + elem.name}
                                                onClick={(e) => handleSelectStateValue(e, elem.value)}
                                            >
                                                {t(elem.name)}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default DeviceGroupTreeItemStateMenu;
