import './style.scss';
import React from 'react';
import classNames from 'classnames';

const LoadingEllipses = (props) => {
    const { className } = props;

    return (
        <div className={classNames('ellipses', { [className]: className })}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default LoadingEllipses;
