import React from 'react';
import { useFormikContext } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';

import AddressSectionContent from './AddressSectionContent';

import { accordionBox, addressColumn } from '../AccountForm/style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_USE_INSTALLATION_ADDRESS } from '../../../../../constants/language_tokens';
import { ArrowDownIcon } from '../../../../../assets/icons';

const AccordionAddressSection = ({ title, isDisabled, addressName }) => {
    const { values, setFieldValue } = useFormikContext();
    const handleClickUseInstallationAddress = (e) => {
        e.stopPropagation();
        const installationValue = { ...values.Installations[0] };
        delete installationValue.PK_Installation;
        setFieldValue(addressName, installationValue);
    };
    const { t } = useTranslate();

    return (
        <Accordion className={accordionBox}>
            <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <h2>{title}</h2>
                <Button onClick={handleClickUseInstallationAddress} variant="outlined">
                    {t(EZLOGIC_BUTTON_USE_INSTALLATION_ADDRESS)}
                </Button>
            </AccordionSummary>
            <AccordionDetails>
                <div className={addressColumn}>
                    <AddressSectionContent isDisabled={isDisabled} addressName={addressName} />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionAddressSection;
