import React from 'react';
import { EZLOGIC_HEADING_ADDITIONAL_INFORMATION } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';
import ListOfMetaTemplateFields from './LisiOfMetaTemplateFields';

import styles from '../style.module.scss';

const TemplateInformationContent = ({ row }) => {
    const { t } = useTranslate();
    const name = row?.nameId;
    const metaData = JSON.parse(row?.data)?.meta;

    return (
        <div className={styles.modal}>
            <h2 className={styles.title}>{name}</h2>
            {metaData ? (
                <div className={styles.group}>
                    <section className={styles.section}>
                        {metaData && <ListOfMetaTemplateFields metaData={metaData} />}
                    </section>
                </div>
            ) : (
                <h2 className={styles.empty}>{t(EZLOGIC_HEADING_ADDITIONAL_INFORMATION)}</h2>
            )}
        </div>
    );
};

export default TemplateInformationContent;
