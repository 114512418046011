import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SelectDeviceCapability from '../SelectDeviceCapability';
import SelectComparisonOperator from '../SelectComparisonOperator';
import SelectValueToCompare from '../SelectValueToCompare';
import SelectItemState from '../SelectItemState';
import SelectButtonState from '../SelectButtonState';
import SelectCapabilityFromList from '../SelectCapabilityFromList';

import {
    isItemStateComparison,
    validateCurrentDataTypeForScalableValueTypes,
    getSelectedPinCodeKey,
    getSelectedUserAction,
} from '../../containers/Ezlo/EzloMeshbot/utils';
import useTriggerSelect from '../../hooks/useTriggerSelect';
import { VALUE_TYPES, EMPTY_STRING, PIN_CODE_USED, USER_LOCK_OPERATION, LOCK } from '../../constants/MeshbotConstant';
import { devicesCapabilitiesList } from './constants';
import UserLockOperationBlock from './components/UserLockOperationBlock';

const DeviceComparisonBlock = ({
    deviceId,
    ruleId,
    groupId,
    hasDeviceArmedProperty,
    updateTriggerData,
    isException,
    deviceGroupId,
    items,
}) => {
    const serial = useSelector((state) => state.ezlo.serial);
    const scalableValueTypes = useSelector((state) => state.ezlo.data[serial].blockDataList?.scalableValueTypes) || [];
    const ruleTrigger = useTriggerSelect(ruleId, groupId, isException);
    const [isVisibleUserLockOperationBlock, setIsVisibleUserLockOperationBlock] = useState(false);
    const [pinCodeMenuItemValue, setPinCodeMenuItemValue] = useState(null);
    const [selectedAction, setSelectedAction] = useState(LOCK);
    const [selectedPinCode, setSelectedPinCode] = useState(EMPTY_STRING);
    const { selectedCapability, comparingValue, armedState, selectedComparator, compareTo, isCompareToVariable } =
        ruleTrigger;
    const showItemStateSelect = isItemStateComparison(selectedCapability);
    const selectedCapabilityValueType = validateCurrentDataTypeForScalableValueTypes(
        scalableValueTypes,
        selectedCapability,
    )
        ? VALUE_TYPES.SCALABLE_VALUE_TYPES
        : selectedCapability?.valueType;
    const showButtonStateSelect = selectedCapabilityValueType === VALUE_TYPES.BUTTON_STATE;
    const showSelectComparisonOperator =
        (selectedCapability?._id || selectedCapability?.name) &&
        !showItemStateSelect &&
        !showButtonStateSelect &&
        !isVisibleUserLockOperationBlock;
    const showSelectValueToCompare = (selectedCapability?._id || selectedCapability?.name) && selectedComparator?.op;

    useEffect(() => {
        if (
            ruleTrigger?.blocks &&
            ruleTrigger?.blocks.length &&
            ruleTrigger?.selectedCapability?.name === USER_LOCK_OPERATION
        ) {
            setPinCodeMenuItemValue(PIN_CODE_USED);
            setIsVisibleUserLockOperationBlock(true);
            setSelectedAction(getSelectedUserAction(ruleTrigger));
            setSelectedPinCode(getSelectedPinCodeKey(ruleTrigger, items, deviceId));
        } else {
            return;
        }
    }, []);

    return (
        <>
            {deviceId && (
                <SelectDeviceCapability
                    deviceId={deviceId}
                    selectedCapability={selectedCapability}
                    onDataUpdate={updateTriggerData}
                    setIsVisibleUserLockOperationBlock={setIsVisibleUserLockOperationBlock}
                    pinCodeMenuItemValue={pinCodeMenuItemValue}
                    setPinCodeMenuItemValue={setPinCodeMenuItemValue}
                    setSelectedPinCode={setSelectedPinCode}
                    setSelectedAction={setSelectedAction}
                />
            )}
            {deviceGroupId && (
                <SelectCapabilityFromList
                    devicesCapabilitiesList={devicesCapabilitiesList}
                    selectedCapability={selectedCapability}
                    onDataUpdate={updateTriggerData}
                />
            )}
            {showItemStateSelect && (
                <SelectItemState
                    hasArmedProperty={hasDeviceArmedProperty}
                    selectedCapability={selectedCapability}
                    comparingValue={comparingValue}
                    armedState={armedState}
                    onDataUpdate={updateTriggerData}
                />
            )}
            {showButtonStateSelect && (
                <SelectButtonState
                    selectedCapability={selectedCapability}
                    comparingValue={comparingValue}
                    onDataUpdate={updateTriggerData}
                />
            )}
            {showSelectComparisonOperator && (
                <SelectComparisonOperator
                    capabilityValueType={selectedCapabilityValueType}
                    selectedComparator={selectedComparator}
                    onDataUpdate={updateTriggerData}
                />
            )}
            {showSelectValueToCompare && (
                <SelectValueToCompare
                    onDataUpdate={updateTriggerData}
                    capabilityValueType={selectedCapabilityValueType}
                    comparingValue={comparingValue}
                    compareTo={compareTo}
                    isCompareToVariable={isCompareToVariable}
                    selectedComparator={selectedComparator}
                    deviceId={deviceId}
                />
            )}
            {isVisibleUserLockOperationBlock && (
                <UserLockOperationBlock
                    deviceId={deviceId}
                    onDataUpdate={updateTriggerData}
                    selectedPinCode={selectedPinCode}
                    setSelectedPinCode={setSelectedPinCode}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                />
            )}
        </>
    );
};

export default DeviceComparisonBlock;
