import React from 'react';

import InfoButton from '../../../EzloMeshbot/components/InfoButton';

import { useTranslate } from '../../../../../features/languages';
import { TREE_TITLE, TOOLTIP } from '../../LanguageTokensConstants';

import { categoryTitleWrapper } from './DeviceGroupCategorySubcategoryTitle.module.scss';

const DeviceGroupCategorySubcategoryTitle = () => {
    const { t } = useTranslate();

    return (
        <div className={categoryTitleWrapper}>
            <h2>{t(TREE_TITLE)}</h2>
            <InfoButton>{t(TOOLTIP)}</InfoButton>
        </div>
    );
};

export default DeviceGroupCategorySubcategoryTitle;
