import React, { useEffect } from 'react';

import { CustomSelect } from './CustomSelect';

import { getMenuItems, updateMapConf } from '../utils';
import { getJsForState } from '../virtual-templates';

import { variableSelect } from './VirtualItem.module.scss';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_VARIABLE } from '../../../../constants/language_tokens';

export const VariableSelect = (props) => {
    const { variable, capabilityAbstract, abstractCapabilities, virtualItemUpdateParams, setVirtualDevice } = props;
    const { t } = useTranslate();
    const { capabilities } = abstractCapabilities;
    const capabilitiesValues = Object.values(capabilities);
    const {
        definition: { variables },
    } = capabilitiesValues?.find(({ capability_name }) => capability_name === capabilityAbstract);

    const variablesList = Object.keys(variables);

    const handleChangeVariable = (e) => {
        const updatedDevice = updateMapConf(virtualItemUpdateParams, 'variable', e.target.value);

        setVirtualDevice(updatedDevice);
    };

    useEffect(() => {
        if (variable) {
            const jsCode = getJsForState(capabilityAbstract, variable, variables[variable]?.type);

            const updatedDevice = updateMapConf(virtualItemUpdateParams, 'code', jsCode);

            setVirtualDevice(updatedDevice);
        }
    }, [variable]);

    return (
        <div className={variableSelect}>
            <CustomSelect
                menuItems={getMenuItems(variablesList)}
                placeholder={t(EZLOGIC_TITLE_VARIABLE)}
                labelId="select-variable-capability"
                onChange={handleChangeVariable}
                value={variable}
            />
        </div>
    );
};
