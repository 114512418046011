import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

const PreviewImages = ({ previewFiles, removeFile }) => {
    return previewFiles.map((file) => (
        <div className={styles.thumb} key={file.name + file.lastModified}>
            <div className={styles.thumbInner}>
                <img src={file.preview} className={styles.img} alt={file.name} />
            </div>
            <div className={styles.closeIconBox}>
                <CloseIcon aria-label="CloseIcon" fontSize="inherit" onClick={removeFile(file)} />
            </div>
        </div>
    ));
};

export default PreviewImages;
