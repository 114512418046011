import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import AdminActionsCells from '../AdminActionsCells';
import ActionCells from '../ActionCells';

import {
    CHANGE_USER_PASSWORD,
    EDIT_USER,
    PIN_CODE,
    USER_TABLE_ACTIONS_TYPES,
} from '../../../../../../../constants/Users';
import { getActionsTypeByPermissionRole } from '../../../utils';

const TableCellsByRole = {
    [USER_TABLE_ACTIONS_TYPES.ADMIN]: (props) => <AdminActionsCells {...props} />,
    [USER_TABLE_ACTIONS_TYPES.SIMPLE]: (props) => <ActionCells {...props} />,
};

const ActionTableCells = ({ user, handleRouteToPage }) => {
    const { Username: loggedInUserUsername, PK_PermissionRole } = useSelector(({ account }) => account.data);
    const actionsType = getActionsTypeByPermissionRole(PK_PermissionRole);

    const handleEditBtnClick = () => handleRouteToPage(EDIT_USER, user);
    const handleChangePasswordBtnClick = () => handleRouteToPage(CHANGE_USER_PASSWORD, user);
    const handlePinCodeBtnClick = () => handleRouteToPage(PIN_CODE, user);

    const cellsProps = {
        isLoggedIn: loggedInUserUsername === user.Username,
        user,
        handleEditBtnClick,
        handleChangePasswordBtnClick,
        handlePinCodeBtnClick,
    };

    return TableCellsByRole[actionsType](cellsProps);
};

export default memo(ActionTableCells);
