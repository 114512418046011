import React from 'react';

import ValidateEmailTitle from '../ValidateEmailTitle';
import { useTranslate } from '../../../../../features/languages';
import { LoginButtonContainer } from '../../../components/LoginButtonContainer';
import {
    EZLOGIC_BUTTON_CONTINUE_TO_LOGIN,
    EZLOGIC_SUBTITLE_EMAIL_VALIDATED,
    EZLOGIC_TITLE_EMAIL_VALIDATED,
} from '../../../../../constants/language_tokens';

import styles from './ValidateEmailSuccessContainer.module.scss';
import ActionsWrapper from '../../../components/ActionsWrapper';
import { IcVisualsMail } from '../../../../../assets/icons';

const ValidateEmailSuccessContainer = () => {
    const { t } = useTranslate();

    return (
        <>
            <IcVisualsMail className={styles.image} />
            <ValidateEmailTitle
                title={t(EZLOGIC_TITLE_EMAIL_VALIDATED)}
                subTitle={t(EZLOGIC_SUBTITLE_EMAIL_VALIDATED)}
            />
            <ActionsWrapper>
                <LoginButtonContainer label={t(EZLOGIC_BUTTON_CONTINUE_TO_LOGIN)} />
            </ActionsWrapper>
        </>
    );
};

export default ValidateEmailSuccessContainer;
