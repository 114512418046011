import React from 'react';

import { FormHelperText, MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { generateComparatorLabel } from '../../helpers/helpersMeshBot';
import useComparatorOptions from '../../hooks/useComparatorOptions';
import { BLOCK_FIELD_TYPES, COMPARISON_DATA, ZERO_INT } from '../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_ERROR_COMMUNICATING_WITH_HUB } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const SelectComparisonOperator = (props) => {
    const { selectedComparator, onDataUpdate, capabilityValueType } = props;
    const comparatorOptions = useComparatorOptions(capabilityValueType);
    const { t } = useTranslate();

    const handleSelectComparator = (event) => {
        const comparisonOperatorValue = event.target.value;

        onDataUpdate({
            selectedComparator: comparisonOperatorValue,
            comparingValue:
                comparisonOperatorValue?.op === COMPARISON_DATA.OPERATION.LENGTH ||
                comparisonOperatorValue?.op === COMPARISON_DATA.OPERATION.NOT_LENGTH
                    ? ZERO_INT
                    : '',
            compareTo: BLOCK_FIELD_TYPES.VALUE,
        });
    };

    return (
        <>
            <SelectComponent
                value={selectedComparator || {}}
                label={info.hints.comparator}
                info={info.text.comparator}
                onChange={handleSelectComparator}
                renderValue={(value) => <div>{generateComparatorLabel(value)}</div>}
                deviceSetting={
                    !comparatorOptions && (
                        <FormHelperText>{t(EZLOGIC_TITLE_ERROR_COMMUNICATING_WITH_HUB)}</FormHelperText>
                    )
                }
                error={!comparatorOptions}
            >
                {comparatorOptions?.methods.map((method) => {
                    return (
                        <MenuItem key={method?.op} value={method}>
                            {generateComparatorLabel(method)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </>
    );
};

export default SelectComparisonOperator;
