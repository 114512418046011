import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../../../../../components';
import SwitchControllers from '../../../../../components/SwitchControllers';
import DeviceGroupTreeLayout from '../DeviceGroupTreeLayout';
import DeviceGroupHeader from '../DeviceGroupHeader';

import styles from './DeviceGroupCreate.module.scss';

const DeviceGroupsCreate = (props) => {
    const { isEditing } = props;

    return (
        <>
            <Header headerClassName={styles.header}>
                <DeviceGroupHeader isEditing={isEditing} />
                {!isEditing && (
                    <div className={styles.controllerDropdown}>
                        <SwitchControllers isCallByDispatch={true} />
                    </div>
                )}
            </Header>
            <DeviceGroupTreeLayout isEditing={isEditing} />
        </>
    );
};

export default DeviceGroupsCreate;

DeviceGroupsCreate.propTypes = {
    isEditing: PropTypes.bool,
};
