import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { OUTLINED } from '../../../../../constants/Integrations';
import styles from './style.module.scss';

const AutocompleteSelect = ({ onChange, options, name, value, disabled, label, size }) => {
    const handleChange = (e, value) => {
        onChange(value, name);
    };

    return (
        <Autocomplete
            size={size}
            options={options}
            getOptionLabel={(option) => option.label ?? ''}
            getOptionSelected={(option, value) => option?.value === value?.value}
            clearOnEscape
            disableClearable
            value={value}
            disabled={disabled}
            onChange={handleChange}
            className={styles.select}
            renderInput={(params) => (
                <TextField {...params} variant={OUTLINED} className={styles.textField} label={label} />
            )}
        />
    );
};
export default AutocompleteSelect;
