import React from 'react';
import PropTypes from 'prop-types';
import UpdatePlugin from '../modalTemplates/UpdatePlugin';
import UploadToMarketplace from '../modalTemplates/UploadToMarketplace';
import ConfirmationAction from '../modalTemplates/ConfirmationAction';
import InstallPlugin from '../modalTemplates/InstallPlugin';
import InfoPlugin from '../modalTemplates/InfoPlugin';
import DevicesList from '../modalTemplates/DevicesList';
import Modal from '../../components/Modal';
import './modalPlugin.scss';
import { TEMPLATE_INFO } from '../../../../../constants/Plugins';
import TemplateInformationContent from '../modalTemplates/TemplateInformationContent';

const ModalPlugin = (props) => {
    const {
        typeList,
        open,
        onCancel,
        devices,
        pluginData,
        currentAction,
        PluginActions,
        typeModal,
        controllers,
        listInstalledPlugins,
        setStatusPage,
        onHandlerModalType,
        onHandlerShowConfigureModal,
        onHandlerShowUpdateModal,
        marketplacePlugins,
        onCancelUpgrade,
        isDispatch,
    } = props;

    const createTemplateSettings = (type) => {
        switch (type) {
            case TEMPLATE_INFO:
                return <TemplateInformationContent {...pluginData} />;
            case 'market':
                return <UploadToMarketplace PluginActions={PluginActions} />;
            case 'info':
                return <InfoPlugin {...pluginData} />;
            case 'confirm':
                return (
                    <ConfirmationAction
                        typeList={typeList}
                        PluginActions={PluginActions}
                        pluginData={pluginData}
                        currentAction={currentAction}
                        listInstalledPlugins={listInstalledPlugins}
                        onCancel={onCancel}
                        onCancelUpgrade={onCancelUpgrade}
                        isDispatch={isDispatch}
                    />
                );
            case 'install':
                return (
                    <InstallPlugin
                        controllers={controllers}
                        pluginData={pluginData}
                        listInstalledPlugins={listInstalledPlugins}
                        PluginActions={PluginActions}
                        onHandlerShowConfigureModal={onHandlerShowConfigureModal}
                        onHandlerShowUpdateModal={onHandlerShowUpdateModal}
                        marketplacePlugins={marketplacePlugins}
                        isDispatch={isDispatch}
                    />
                );
            case 'update':
                return (
                    <UpdatePlugin
                        setStatusPage={setStatusPage}
                        PluginActions={PluginActions}
                        onCancel={onCancel}
                        onHandlerModalType={onHandlerModalType}
                    />
                );
            case 'devices':
                return (
                    <DevicesList
                        typeList={typeList}
                        pluginData={pluginData}
                        devices={devices}
                        listInstalledPlugins={listInstalledPlugins}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <Modal open={open} handleClose={onCancel} className="device-modal" typeModal={typeModal}>
            {createTemplateSettings(typeModal)}
        </Modal>
    );
};

ModalPlugin.propTypes = {
    typeList: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    typeModal: PropTypes.string,
    currentSerial: PropTypes.string,
    currentAction: PropTypes.string,
    currentCustomNode: PropTypes.object,
    controllers: PropTypes.array,
    listInstalledPlugins: PropTypes.object,
    devices: PropTypes.array,
    pluginData: PropTypes.object,
    PluginActions: PropTypes.object,
    EzloActions: PropTypes.object,
    onHandlerModalType: PropTypes.func,
    onHandlerShowConfigureModal: PropTypes.func,
};

export default React.memo(ModalPlugin);
