import React from 'react';
import { useDispatch } from 'react-redux';

import MeshBotLabelsList from '../MeshBotLabelsList';
import MeshBotLabelsDropdown from '../MeshBotLabelsDropdown/MeshBotLabelsDropdown';
import { MeshBotAction } from '../../../../../actions';

import styles from './styles.module.scss';

const MeshBotLabelsContainer = ({ type }) => {
    const dispatch = useDispatch();

    const onDelete = (data) => {
        dispatch(MeshBotAction.removeSelectedMeshBotLabel(data, type));
    };

    return (
        <div className={styles.labelsWrapper}>
            <MeshBotLabelsList onDelete={onDelete} type={type} />
            <MeshBotLabelsDropdown type={type} />
        </div>
    );
};

export default MeshBotLabelsContainer;
