import { UserAuthWrapper } from 'redux-auth-wrapper';
import { routerActions } from 'react-router-redux';
import { ITARIAN_SSO_TYPE } from '../services/sso/src/ItarianService/constants';
import { EZLO_CUSTOMIZATION, EZLO_MESHBOTS } from '../constants/URLs';
import { EZLOGIC, NOTIFICATIONS, NOTIFICATIONS_LINK } from '../containers/Ezlo/EzloCustomization/constants';

export const UserIsAuthenticated = UserAuthWrapper({
    authSelector: (state) => state.account, // how to get the user state
    redirectAction: routerActions.replace, // the redux action to dispatch for redirect
    wrapperDisplayName: 'UserIsAuthenticated', // a nice name for this auth check
    predicate: (account) => account.logged === true,
    //authenticatingSelector: state => state.account.loginPreloader,
    //LoadingComponent: Preloader
});

export const tokenParser = (data) => JSON.parse(atob(data));

/**
 * Function that returns the path of the first page after login, depending on Sso type
 * @param {String} ssoType - Sso type
 * @return {string} the path of the first page after login, depending on Sso type
 * @example getPathOfFirstPageBySso(ITARIAN_SSO_TYPE);
 * */
export const getPathOfFirstPageBySso = (ssoType) => {
    if (ssoType === ITARIAN_SSO_TYPE) {
        return EZLO_MESHBOTS;
    }
};

/**
 * Function that returns the path of the first page after login, depending on page value
 * @param {String} pageValue - page value from login request
 * @return {string} the path of the first page after login, depending on page value
 * @example getPathOfFirstPageByPageValue(NOTIFICATIONS);
 * */
export const getPathOfFirstPageByPageValue = (pageValue) => {
    if (pageValue === NOTIFICATIONS) {
        return NOTIFICATIONS_LINK;
    } else if (pageValue === EZLOGIC) {
        return EZLO_CUSTOMIZATION;
    }
};
