import { ITARIAN_SSO_TYPE } from '../../../../services/sso/src/common/constants';
import { CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER } from '../../../../constants/rulesInSelect';
import {
    CLOUD_NOTIFICATION,
    MESHBOT_NODE_TYPES,
    DEVICE_CAMERA_CAPABILITIES,
    NAME_ARGUMENT_FIELDS,
    VALUE_TYPES,
} from '../../../../constants/MeshbotConstant';
import { getIntegrationAbstracts, isVariable } from './CloudVariablesSelect/utils';

/**
 * List of allowed Cloud Trigger nodes for Itarian SSO
 * */
const ALLOWED_ITARIAN_CLOUD_TRIGGER_NODES = [
    CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.DATE_AND_TIME,
    CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.NUCAL,
    CLOUD_MESHBOT_TRIGGER_NODES_VALUES_CONTAINER.CLOUD_VARIABLES,
];

/**
 * Returns filtered Cloud MeshBot Trigger nodes by Itarian SSO Type
 * @param {array} nodes - trigger nodes
 * @returns {array} filtered nodes
 * */
const filterCloudTriggerNodesByItarianSsoType = (nodes) => {
    return nodes.filter((menuItem) => {
        if (menuItem && menuItem.value) {
            return ALLOWED_ITARIAN_CLOUD_TRIGGER_NODES.includes(menuItem.value);
        }
    });
};

/**
 * Returns filtered Cloud MeshBot Trigger nodes by SSO Type
 * @param {array} nodes - trigger nodes
 * @param {string} ssoType - SSO Type
 * @returns {array} filtered nodes
 * */
export const filterCloudTriggerNodesBySsoType = (nodes, ssoType) => {
    if (!Array.isArray(nodes)) {
        return [];
    }

    if (ssoType === ITARIAN_SSO_TYPE) {
        return filterCloudTriggerNodesByItarianSsoType(nodes);
    }

    return nodes;
};

/**
 * List of allowed Cloud Action nodes for Itarian SSO
 * */
const ALLOWED_ITARIAN_CLOUD_ACTION_NODES = [
    // DEVICE_STATE, // not allowed
    // DASHBOARD, // not allowed
    CLOUD_NOTIFICATION,
    MESHBOT_NODE_TYPES.PAAS,
];

/**
 * Returns filtered Cloud MeshBot Action nodes by Itarian SSO Type
 * @param {array} nodes - action nodes
 * @returns {array} filtered nodes
 * */
export const filterCloudActionNodesByItarianSsoType = (nodes) => {
    return nodes.filter((node) => {
        if (node && node.value) {
            return ALLOWED_ITARIAN_CLOUD_ACTION_NODES.includes(node.value);
        }
    });
};

/**
 * Returns filtered Cloud MeshBot Action nodes by SSO Type
 * @param {array} nodes - action nodes
 * @param {string} ssoType - SSO Type
 * @returns {array} filtered nodes
 * */
export const filterCloudActionNodesBySsoType = (nodes, ssoType) => {
    if (!Array.isArray(nodes)) {
        return [];
    }

    if (ssoType === ITARIAN_SSO_TYPE) {
        return filterCloudActionNodesByItarianSsoType(nodes);
    }

    return nodes;
};

/**
 * function generates a list of all abstracts with custom variables
 * @param {Array<Object>} abstracts - List of all abstracts
 * @returns {Array<Object>} - list of all abstracts with custom variables
 */
export const generateAbstractListWithCustomVariables = (abstracts) => {
    if (!abstracts) {
        return [];
    }
    const integrationAbstracts = getIntegrationAbstracts(abstracts);

    return getAbstractListWithVariables(integrationAbstracts);
};

/**
 * function collects abstracts that will have only variable names in their capabilities
 * @param {Array<Object>} abstracts - Abstracts' list
 * @returns {Array} - List of abstracts with capabilities that store only variable names
 */
const getAbstractListWithVariables = (abstracts) => {
    const abstractsWithVariables = [];

    abstracts.forEach((currentAbstract) => {
        const { capabilities } = currentAbstract;

        if (capabilities.some(isVariable)) {
            const abstractWithOnlyVariables = currentAbstract.uuid;
            abstractsWithVariables.push(abstractWithOnlyVariables);
        }
    });

    return abstractsWithVariables;
};

/**
 * function checks whether event value satisfies restrictions for float or not.
 * @param {Number} value - value inputted by user
 * @param {String} key - key pressed by user
 * @returns {Boolean} - true if satisfies rule, false if not.
 */

export const restrictionRuleToPreventFloat = (value, key) => {
    return !(value <= Number.MAX_SAFE_INTEGER && value >= Number.MIN_SAFE_INTEGER && key !== 'e' && key !== '.');
};

/**
 * This function detects the camera capabilities with the type "int".
 * @param {array} fields - the fields of the then block or else block
 * @param {array} items - the devices items of the selected controller
 * @returns {string || undefined} - capability name or undefined
 */
export const detectCameraCapabilitiesOfIntType = (fields = [], items) => {
    const capabilityId = fields.find((field) => field.name === NAME_ARGUMENT_FIELDS.ITEM)?.value;
    const capabilityName = items.find((item) => item._id === capabilityId)?.name;
    const detectedCapability = Object.values(DEVICE_CAMERA_CAPABILITIES).find(
        (value) => value.type === VALUE_TYPES.INT && value.name === capabilityName,
    );

    if (detectedCapability) {
        return detectedCapability.name;
    }
};
