import React from 'react';
import PropTypes from 'prop-types';

import ControllerDevicesGroup from './components/ControllerDevicesGroup/ControllerDevicesGroup';

import styles from '../../ezloDevices.module.scss';

const ControllerDevicesGroupList = (props) => {
    const {
        roomList,
        onNameChange,
        onHandlerSelectFunction,
        onHandlerRefreshDevice,
        onSetCurrentFunction,
        onChangeDevicePlace,
        filteredDevices,
    } = props;

    return (
        <div className={styles.devicesGroupList}>
            {filteredDevices &&
                Object.entries(filteredDevices).length &&
                Object.entries(filteredDevices).map(([serial, devices]) => {
                    return (
                        <ControllerDevicesGroup
                            key={serial}
                            serial={serial}
                            roomList={roomList}
                            onNameChange={onNameChange}
                            onHandlerSelectFunction={onHandlerSelectFunction}
                            onHandlerRefreshDevice={onHandlerRefreshDevice}
                            onSetCurrentFunction={onSetCurrentFunction}
                            onChangeDevicePlace={onChangeDevicePlace}
                            devices={devices}
                        />
                    );
                })}
        </div>
    );
};

export default ControllerDevicesGroupList;

ControllerDevicesGroupList.propTypes = {
    roomList: PropTypes.array,
    onNameChange: PropTypes.func,
    onHandlerSelectFunction: PropTypes.func,
    onHandlerRefreshDevice: PropTypes.func,
    onSetCurrentFunction: PropTypes.func,
    onChangeDevicePlace: PropTypes.func,
    devices: PropTypes.array,
};
