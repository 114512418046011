import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { VIRTUAL_DEVICE_TYPES } from '../../../../constants/VirtualDeviceConstant';
import { ONLY_NUMBER_REGEX } from '../../../../constants/regexConstants';

const useFieldsState = (deviceTypeName, deviceCountName, arrayHelpers, virtualDeviceKey) => {
    const { setFieldValue } = useFormikContext();

    const filteredDeviceTypes = useMemo(() => {
        return VIRTUAL_DEVICE_TYPES.filter((type) => type.isVisible);
    }, [VIRTUAL_DEVICE_TYPES]);

    const handleDeviceTypeChange = useCallback(
        (e, value) => {
            setFieldValue(deviceTypeName, value);
        },
        [deviceTypeName],
    );

    const handleQuantityChange = useCallback(
        ({ target: { value } }) => {
            if (ONLY_NUMBER_REGEX.test(value) || value === '') {
                setFieldValue(deviceCountName, value);
            }
        },
        [deviceCountName],
    );

    const handleDeleteButtonClick = useCallback(() => {
        arrayHelpers.remove(virtualDeviceKey);
    }, [virtualDeviceKey]);

    return { filteredDeviceTypes, handleDeviceTypeChange, handleQuantityChange, handleDeleteButtonClick };
};

export default useFieldsState;
