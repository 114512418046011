import throttle from 'lodash.throttle';
import { MeshBotAction } from '.';
import * as types from '../constants/ActionTypes';
import * as GenericHelper from '../helpers/generic';
import wsm from '../helpers/wsm';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { DELAY_TIME, VIDOO, MOTION, HOTZONES } from '../constants/constMeshBot';
import { SUBSCRIPTIONS_STATUS } from '../constants/Subscription';

const actions = {
    setShowInTemplate: (serial, _id, visibility) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'setShowInTemplate',
                {
                    _id,
                    visibility,
                },
                () => resolve(),
                (error) => {
                    bugsnagNotify(error, { serial, _id, visibility });
                    reject(error);
                },
            );
        }),

    sendItemValue: (serial, _id, value) => () => {
        wsm.send(
            serial,
            'hub.item.value.set',
            {
                _id,
                value,
            },
            () => {},
        );
    },

    sendItemArmedValue: (serial, _id, armed) => () => {
        wsm.send(
            serial,
            'hub.device.armed.set',
            {
                _id,
                armed,
            },
            () => {},
        );
    },

    saveItemLabel: (serial, _id, label) => (dispatch) => {
        wsm.send(
            serial,
            'renameItem',
            {
                _id,
                label,
            },
            () => dispatch(actions.updateItemLabel(serial, _id, label)),
        );
    },

    setOnItemHidden: (serial, itemId, isOnHidden) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'foSetWhatsonHidden',
                { itemId, isOnHidden },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, itemId, isOnHidden });
                    reject(error);
                },
            );
        }),

    turnDevicesOff: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'turnDevicesOff',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getFavorites: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'foGetItemFavorites',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    setFavorite: (serial, itemId, isFavorite) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.favorite.added',
                {
                    itemId,
                    isFavorite,
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, itemId, isFavorite });
                    reject(error);
                },
            );
        }),

    updateEzlosState: (serial, id, armed) => ({
        type: types.UPDATE_EZLO_STATE,
        serial,
        id,
        armed,
    }),

    addItem: (serial, data) => ({
        type: types.ADD_ITEM,
        serial,
        data,
    }),

    updateItem: (serial, data) => ({
        type: types.UPDATE_ITEM,
        serial,
        data,
    }),

    removeItem: (serial, _id) => ({
        type: types.REMOVE_ITEM,
        serial,
        _id,
    }),

    addFavorite: (serial, itemId) => ({
        type: types.FAVORITE_ITEM_ADDED,
        serial,
        itemId,
    }),

    removeFavorite: (serial, itemId) => ({
        type: types.FAVORITE_ITEM_REMOVED,
        serial,
        itemId,
    }),

    updateShowInTemplate: (serial, _id, visibility) => ({
        type: types.UPDATE_SHOW_IN_TEMPLATE,
        serial,
        _id,
        visibility,
    }),

    setItemValueSuffix: (serial, _id, suffix) => ({
        type: types.SET_ITEM_VALUE_SUFFIX,
        serial,
        _id,
        suffix,
    }),

    setItemValue: (serial, _id, value, valueFormatted) => ({
        type: types.SET_ITEM_VALUE,
        serial,
        _id,
        value,
        valueFormatted,
    }),

    setItemEnum: (serial, _id, enumValues) => ({
        type: types.SET_ITEM_ENUM,
        serial,
        _id,
        enum: enumValues,
    }),

    updateItemLabel: (serial, _id, label) => ({
        type: types.UPDATE_ITEM_LABEL,
        serial,
        _id,
        label,
    }),

    setItemReachable: (serial, _id, reachable) => ({
        type: types.SET_ITEM_REACHABLE,
        serial,
        _id,
        reachable,
    }),

    setItemDefault: (serial, _id) => ({
        type: types.SET_ITEM_DEFAULT,
        serial,
        _id,
    }),

    updateItems: (serial, items) => ({
        type: types.UPDATE_ITEMS,
        serial,
        items,
    }),

    bindItemsToDeviceRoom: (serial, deviceId) => (dispatch, getState) => {
        const newItems = getState().ezlo.data[serial].items.map((item) => {
            const device = GenericHelper.getFromHashMap(getState().ezlo.data[serial].devices, '_id', item.deviceId);
            if (item.deviceId === deviceId) {
                item.roomId = device.roomId;
            }

            return item;
        });
        dispatch(actions.updateItems(serial, newItems));
    },

    setItemShow: (serial, deviceId, itemId) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'setItemShow',
                { deviceId, itemId },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, deviceId, itemId });
                    reject(error);
                },
            );
        }),

    updateShowDefaultItem: (serial, itemId, showInDashboard) => ({
        type: types.UPDATE_SHOW_DEFAULT_ITEM,
        serial,
        showInDashboard,
        itemId,
    }),

    updateItemRoom: (serial, itemId, roomId) => ({
        type: types.UPDATE_ITEM_ROOM,
        serial,
        roomId,
        itemId,
    }),

    updateDevice: (serial, device) => ({
        type: types.UPDATE_DEVICE,
        serial,
        device,
    }),

    search: (text) => ({
        type: types.SEARCH_ITEM_TEXT,
        searchText: text,
    }),

    subscribeDeviceItemsUpdateState: (serials, cb) => () => {
        const throttledCb = throttle(cb, DELAY_TIME);

        serials.map((item) =>
            wsm.subscribe(item, 'hub.item.updated', (data) => {
                if (data && (data.result.deviceSubcategory === VIDOO || data.result.deviceSubcategory === MOTION)) {
                    return;
                }

                if (data && data.result.name === HOTZONES) {
                    throttledCb(data.result);

                    return;
                }

                return cb(data.result);
            }),
        );
    },

    addNewItem: (serial, data, t) => (dispatch, getState) => {
        const state = getState();

        dispatch(actions.addItem(serial, data));

        if (state.meshBotV2.local.flagsContainer.subscriptionStatus === SUBSCRIPTIONS_STATUS.IN_PROGRESS) {
            dispatch(MeshBotAction.updatePayloadAndAddItem(data, t));
        }
    },

    unsubscribeDeviceItemsUpdateState: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.item.updated'));
    },

    subscribeDeviceUpdateState: (serials, cb) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.device.updated', (data) => cb(data.result)));
    },

    unsubscribeDeviceUpdateState: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.device.updated'));
    },
};

export default actions;
