import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PRIVATE } from '../../../../constants/Plugins';
import TemplatesPrivate from '../PluginWifiGenerator/TemplatesPrivate';
import TemplatesPublish from '../PluginWifiGenerator/TemplatesPublish';
import TemplatesMarketplace from '../PluginWifiGenerator/Marketplace';
import CreateDeviceTemplate from '../components/CreateDeviceTemplate';
import EditDeviceTemplate from '../components/EditDeviceTemplate';
import CreateDevice from '../components/CreateDevice';
import Header from '../components/Header';
import NotFoundPageContent from '../../../../containers/NotFoundPage/NotFoundPageContent';
import styles from './pluginWifiGenerato.module.scss';
import { Box, LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { EZLOGIC_HEADING_CREATE_NEW_CONFIGURATION } from '../../../../constants/language_tokens';

const ListDeviceGenerator = (props) => {
    const { loaderInstalled } = useSelector((state) => state.plugins);
    const [isActivePage, setIsActivePage] = useState(PRIVATE);
    const { controllers, history, PluginActions } = props;
    const { url } = useRouteMatch();

    useEffect(() => {
        history.push(`${url}/${PRIVATE}`);

        if (controllers) {
            controllers.forEach((controller) => {
                if (controller.isConnected) {
                    PluginActions.getListInstalledPlugins(controller.serial);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname.split('/').pop() === 'wifi-generator') {
            history.push(`${url}/${PRIVATE}`);
            setIsActivePage(PRIVATE);
        }
    }, [history.location.pathname]);

    const handlerRouteList = useCallback(
        (type) => {
            history.push(`${url}/${type}`);
            setIsActivePage(type);
        },
        [history, url],
    );

    const handleRouterEdit = useCallback((type) => {
        setIsActivePage(type);
    }, []);

    const handlerCreateTemplate = useCallback(() => {
        history.push(`${url}/create`);
    }, [history, url]);

    const handlerSetIsActivePage = useCallback((type) => {
        setIsActivePage(type);
    }, []);

    return (
        <div className={styles.wifiGenerator}>
            <>
                {loaderInstalled && (
                    <Box className={styles.linearProgressBoxPlugins}>
                        <LinearProgress />
                    </Box>
                )}
                <Header
                    typePage="wifi"
                    isActivePage={isActivePage}
                    nameButton={EZLOGIC_HEADING_CREATE_NEW_CONFIGURATION}
                    onHandlerRouteList={handlerRouteList}
                    onSubmit={handlerCreateTemplate}
                />
                <Switch>
                    <Route
                        path={`${url}/private`}
                        render={() => (
                            <TemplatesPrivate
                                onSetIsActivePage={handlerSetIsActivePage}
                                history={history}
                                handleRouterEdit={handleRouterEdit}
                            />
                        )}
                    />
                    <Route path={`${url}/publish`} component={TemplatesPublish} />
                    <Route path={`${url}/market`} render={() => <TemplatesMarketplace />} />
                    <Route
                        path={`${url}/:id/devices`}
                        render={() => <CreateDevice onSetIsActivePage={handlerSetIsActivePage} history={history} />}
                    />
                    <Route
                        path={`${url}/create`}
                        render={() => (
                            <CreateDeviceTemplate onSetIsActivePage={handlerSetIsActivePage} history={history} />
                        )}
                    />
                    <Route
                        path={`${url}/:id`}
                        render={() => (
                            <EditDeviceTemplate onSetIsActivePage={handlerSetIsActivePage} history={history} />
                        )}
                    />
                    <Route render={() => <NotFoundPageContent loggedIn />} />
                </Switch>
            </>
        </div>
    );
};

ListDeviceGenerator.propTypes = {
    controllers: PropTypes.array,
    listInstalledPlugins: PropTypes.object,
    loaderInstalled: PropTypes.bool,
    history: PropTypes.object,
    PluginActions: PropTypes.object,
};

export default ListDeviceGenerator;
