import React from 'react';

import {
    EZLOGIC_TITLE_AUTHOR,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_SUPPORTED_MODELS,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const ListOfMetaTemplateFields = ({ metaData }) => {
    const { t } = useTranslate();

    return (
        <ul>
            <li>
                <span>{t(EZLOGIC_TITLE_AUTHOR)}:</span> {metaData?.author}
            </li>
            <li>
                <span>{t(EZLOGIC_TITLE_DESCRIPTION)}:</span> {metaData?.description}
            </li>
            <li>
                <span>{t(EZLOGIC_TITLE_SUPPORTED_MODELS)}:</span> {metaData?.supported_models}
            </li>
        </ul>
    );
};

export default ListOfMetaTemplateFields;
