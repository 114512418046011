import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { TOAST_TYPE } from '../../../../../components/Toast';
import { COPIED } from '../../../../../constants/Notifications';

import styles from './EzloNotificationTemplateKeywordsItem.module.scss';

const EzloNotificationTemplateKeywordsItem = (props) => {
    const { name, value } = props;

    return (
        <CopyToClipboard text={value} onCopy={() => toast(COPIED, { type: TOAST_TYPE.SUCCESS })}>
            <button className={styles.keyword} value={value}>
                {name}
            </button>
        </CopyToClipboard>
    );
};

export default EzloNotificationTemplateKeywordsItem;
