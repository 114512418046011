import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { IconButton, TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import TriggerBlock from './triggerBlock';
import { CheckboxButton, GroupRadioButton } from '../../../../components';

import { getNameCapability } from '../../../../helpers/helpersMeshBot';
import hash from '../../../../constants/uniqueHash';
import at from '../../../../constants/ActionTypes/MeshBot';
import * as meshBot from '../../../../constants/MeshbotConstant';
import FunctionForTriggerBlock from './FunctionForTriggerBlock';
import { groupNameValidation } from '../MeshbotLocal/MeshBotLocalForm/utils';

import {
    mesh_bot__group,
    editing,
    is_valid,
    connection,
    activeConnection,
    mesh_bot__group_operators_wrap,
    show,
    mesh_bot__group_operators,
    mesh_bot__group_header,
    mesh_bot__group_block,
    group_name,
    mesh_bot__group_buttons,
    mesh_bot__group_operators_inner,
    mesh_bot__group_buttons_header,
    collapse_button,
} from './TriggerBlock.module.scss';
import { mesh_bot__list_triggers, collapse_open, collapse_close } from '../EzloMeshbot.module.scss';
import TriggerBlockButtonsWrapper from './TriggerBlock/TriggerBlockButtonsWrapper';
import { OPERATORS_LOCAL } from '../../../../constants/MeshbotConstant';
import {
    EZLOGIC_TITLE_ADD_GROUP,
    EZLOGIC_TITLE_ADD_TRIGGER,
    EZLOGIC_TITLE_GROUP_NAME,
    EZLOGIC_TITLE_NOT,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import { DropDownUpIcon } from '../../../../assets/icons';

const GroupBlock = (props) => {
    const {
        idGroup,
        actionId,
        blockName,
        blocks,
        not,
        model,
        typeMeshbot,
        actionBlockName,
        typeTrigger,
        typeForm,
        prevGroup,
        ezloSerial,
        items,
        devices,
        itemsDevice,
        listCapabilities,
        currentDeviceSelect,
        optionType,
        onUpdateTriggerNot,
        onUpdateTrigger,
        onUpdateMeshBotTriggerValue,
        DeleteTrigger,
        onAddTrigger,
        onChangeNameGroup,
        onSelected,
        onUpdateFieldBlock,
        onAddBlock,
        onSelectedDate,
        onSetDateValue,
        onSetSpecificDate,
        onSetFieldValue,
        onUpdateTriggerType,
        changeExpression,
        UpdateExpression,
        currentExpression,
        onGetCurrentDeviceId,
        CreateExpression,
        deviceBlocks,
        handlerUpdateTriggerBlock,
        DeleteExpression,
        onGetNameCapability,
        onUpdateCloudTriggerBlock,
        onUpdateDateTriggersCloud,
        updateTriggerNameBlock,
        onSelectedSpecificLabel,
        onCheckedNotRadio,
        onUpdateFieldVidoo,
        isNestedGroup,
        foundValidatedWhenGroup,
        scenesList,
        onSelectedMeshBotTriggerState,
        onResetMeshBotTriggerBlocks,
        onUpdateTriggerBlockFunction,
        currentItem,
        onSelectedVidooTrigger,
        currentMeshbot,
        MeshBotAction,
        onUpdateHouseModeTrigger,
        isOpenedExceptionBlock,
    } = props;

    const [isCollapsed, setCollapse] = useState(false);
    const [groupName, setGroupName] = useState(blockName);
    const { t } = useTranslate();
    const checkedNot = (e) => {
        if (typeMeshbot === at.CLOUD) {
            onUpdateTriggerNot(e.target.checked, idGroup, null, prevGroup);
        } else {
            onUpdateTriggerNot(e.target.checked, idGroup, meshBot.NOT, prevGroup, actionId);
        }
    };

    const checkedNotRadio = (e) => {
        if (typeMeshbot === at.CLOUD) {
            onCheckedNotRadio(e.target.value, idGroup, prevGroup);
        } else {
            onUpdateTriggerType(e.target.value, idGroup, meshBot.OPTION_TYPE, prevGroup, actionId);
        }
    };

    const updateClassesNamesForWhenGroups = (group) => {
        if (group) {
            if (group.isValid === true && group.isEdited === false) {
                return `${mesh_bot__group} ${editing} ${connection}`;
            } else if (group.isValid === true && group.isEdited === true) {
                return `${mesh_bot__group} ${is_valid} ${connection}`;
            } else {
                return `${mesh_bot__group} ${connection}`;
            }
        }
    };

    const findValidatedWhenBlock = (id) => {
        if (foundValidatedWhenGroup) {
            return (
                foundValidatedWhenGroup.blocks &&
                foundValidatedWhenGroup.blocks.find((block) => {
                    if (block.id === id) {
                        return block;
                    }
                })
            );
        }
    };

    const findValidatedWhenGroup = (id) => {
        if (foundValidatedWhenGroup) {
            return (
                foundValidatedWhenGroup.blocks &&
                foundValidatedWhenGroup.blocks.find((block) => {
                    if (block.id === id) {
                        return block;
                    }
                })
            );
        }
    };

    const handleUpdateTriggerFunctionInsideGroup = (functionData, idBlock, idGroup) => {
        // TODO падает ошибка если зайти с cloud мешбота и нажать на добавить группу -> добавить девайс
        // TODO TypeError: onUpdateTriggerBlockFunction is not a function

        if (typeTrigger === meshBot.EXCEPTION) {
            onUpdateTriggerBlockFunction(functionData, idBlock, meshBot.TRIGGER_TYPES.FUNCTION, null, actionId);
        } else {
            onUpdateTriggerBlockFunction(functionData, idBlock, idGroup);
        }
    };

    const handleSetGroupName = (e) => {
        const { value } = e.target;

        if (groupNameValidation(foundValidatedWhenGroup, value, groupName)) {
            onChangeNameGroup(value, idGroup, meshBot.BLOCK_NAME, null, actionId);
        }

        setGroupName(value);
    };

    const handleUpdateGroupName = () => {
        onChangeNameGroup(groupName, idGroup, meshBot.BLOCK_NAME, null, actionId);
    };

    const handleDeleteGroup = () => {
        let idPrevGroup = prevGroup;

        if (!prevGroup) {
            idPrevGroup = null;
        }

        DeleteTrigger(idGroup, idPrevGroup, actionId, actionBlockName);
    };

    const handleAddTrigger = (type, inGroup) => {
        if (typeTrigger === meshBot.EXCEPTION) {
            onAddTrigger(actionId, actionBlockName, type, idGroup);
        } else {
            onAddTrigger(type, idGroup, inGroup);
        }
    };

    const handleUpdateFunction = (functionData) => {
        if (typeTrigger === meshBot.EXCEPTION) {
            onUpdateTriggerBlockFunction(functionData, idGroup, meshBot.TRIGGER_TYPES.FUNCTION, null, actionId);
        } else {
            onUpdateTriggerBlockFunction(functionData, idGroup, prevGroup);
        }
    };

    const className = classes(mesh_bot__list_triggers, { [activeConnection]: blocks.length > 1 });
    const classWrap = classes(mesh_bot__group_operators_wrap, { [show]: blocks.length > 1 });

    return (
        <div className={updateClassesNamesForWhenGroups(foundValidatedWhenGroup)}>
            <div className={isCollapsed ? `${collapse_close}` : `${collapse_open}`}>
                <header className={mesh_bot__group_header} id={`trigger-block__container-${idGroup}`}>
                    <div className={mesh_bot__group_block}>
                        <div className={classWrap}>
                            <div className={mesh_bot__group_operators_inner}>
                                <CheckboxButton
                                    id={hash()}
                                    label={t(EZLOGIC_TITLE_NOT)}
                                    name="not"
                                    value={not}
                                    activeGroup
                                    onChange={checkedNot}
                                />

                                {blocks.length > 1 && (
                                    <div className={mesh_bot__group_operators}>
                                        {OPERATORS_LOCAL.map((item, index) => {
                                            return (
                                                <GroupRadioButton
                                                    id={hash()}
                                                    key={index}
                                                    label={item}
                                                    name={`type${idGroup}`}
                                                    value={item}
                                                    optionType={optionType}
                                                    activeGroup={idGroup}
                                                    onClick={(e) => checkedNotRadio(e)}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        {typeMeshbot !== at.CLOUD && (
                            <TextField
                                placeholder={t(EZLOGIC_TITLE_GROUP_NAME)}
                                autoFocus
                                error={!blockName}
                                className={group_name}
                                value={groupName}
                                onBlur={handleUpdateGroupName}
                                onChange={handleSetGroupName}
                            />
                        )}
                    </div>
                    <div className={`${mesh_bot__group_buttons} ${mesh_bot__group_buttons_header}`}>
                        {typeMeshbot !== at.CLOUD && (
                            <FunctionForTriggerBlock
                                marginRight
                                onUpdate={handleUpdateFunction}
                                initState={currentItem.function}
                                isPortal
                                id={idGroup}
                                groupHeaderPlace
                            />
                        )}

                        <TriggerBlockButtonsWrapper onDeleteTrigger={handleDeleteGroup} />
                        <div className={collapse_button}>
                            <IconButton onClick={() => setCollapse(!isCollapsed)}>
                                <DropDownUpIcon height="8" width="16" />
                            </IconButton>
                        </div>
                    </div>
                </header>
                <div className={className}>
                    {blocks.map((item) => {
                        if (item.type === at.GROUP) {
                            return (
                                <GroupBlock
                                    key={item.id}
                                    items={items}
                                    model={model}
                                    devices={devices}
                                    idGroup={item.id}
                                    actionId={actionId}
                                    ezloSerial={ezloSerial}
                                    prevGroup={idGroup}
                                    type={item.type}
                                    typeMeshbot={typeMeshbot}
                                    typeTrigger={typeTrigger}
                                    actionBlockName={actionBlockName}
                                    typeForm={typeForm}
                                    {...item}
                                    currentItem={item}
                                    listCapabilities={listCapabilities}
                                    currentDeviceSelect={currentDeviceSelect}
                                    onCheckedNotRadio={onCheckedNotRadio}
                                    onAddTrigger={onAddTrigger}
                                    onAddBlock={onAddBlock}
                                    onUpdateTriggerType={onUpdateTriggerType}
                                    onChangeNameGroup={onChangeNameGroup}
                                    onUpdateFieldBlock={onUpdateFieldBlock}
                                    onUpdateTriggerNot={onUpdateTriggerNot}
                                    updateTriggerNameBlock={updateTriggerNameBlock}
                                    onUpdateTrigger={onUpdateTrigger}
                                    onUpdateMeshBotTriggerValue={onUpdateMeshBotTriggerValue}
                                    DeleteTrigger={DeleteTrigger}
                                    onSelected={onSelected}
                                    onSetDateValue={onSetDateValue}
                                    onSelectedDate={onSelectedDate}
                                    onSetSpecificDate={onSetSpecificDate}
                                    onSetFieldValue={onSetFieldValue}
                                    onGetNameCapability={getNameCapability}
                                    onSelectedSpecificLabel={onSelectedSpecificLabel}
                                    onUpdateCloudTriggerBlock={onUpdateCloudTriggerBlock}
                                    onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                                    onGetCurrentDeviceId={onGetCurrentDeviceId}
                                    handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                    UpdateExpression={UpdateExpression}
                                    changeExpression={changeExpression}
                                    CreateExpression={CreateExpression}
                                    DeleteExpression={DeleteExpression}
                                    isNestedGroup={true}
                                    foundValidatedWhenGroup={findValidatedWhenGroup(item.id)}
                                    scenesList={scenesList}
                                    onSelectedMeshBotTriggerState={onSelectedMeshBotTriggerState}
                                    onResetMeshBotTriggerBlocks={onResetMeshBotTriggerBlocks}
                                    onUpdateTriggerBlockFunction={handleUpdateTriggerFunctionInsideGroup}
                                    onSelectedVidooTrigger={onSelectedVidooTrigger}
                                    currentMeshbot={currentMeshbot}
                                    MeshBotAction={MeshBotAction}
                                    onUpdateHouseModeTrigger={onUpdateHouseModeTrigger}
                                    isOpenedExceptionBlock={isOpenedExceptionBlock}
                                />
                            );
                        }

                        return (
                            <TriggerBlock
                                key={item.id}
                                actionId={actionId}
                                currentItem={item}
                                model={model}
                                {...item}
                                typeMeshbot={typeMeshbot}
                                typeForm={typeForm}
                                typeTrigger={typeTrigger}
                                listCapabilities={listCapabilities}
                                idGroup={idGroup}
                                ezloSerial={ezloSerial}
                                items={items}
                                devices={devices}
                                itemsDevice={itemsDevice}
                                deviceBlocks={deviceBlocks}
                                currentDeviceSelect={currentDeviceSelect}
                                updateTriggerNameBlock={updateTriggerNameBlock}
                                onUpdateTriggerNot={onUpdateTriggerNot}
                                onUpdateTrigger={onUpdateTrigger}
                                onUpdateMeshBotTriggerValue={onUpdateMeshBotTriggerValue}
                                onUpdateFieldBlock={onUpdateFieldBlock}
                                DeleteTrigger={DeleteTrigger}
                                onSelected={onSelected}
                                onAddBlock={onAddBlock}
                                onUpdateFieldVidoo={onUpdateFieldVidoo}
                                onSelectedDate={onSelectedDate}
                                onSetDateValue={onSetDateValue}
                                onSetSpecificDate={onSetSpecificDate}
                                onSetFieldValue={onSetFieldValue}
                                onSelectedSpecificLabel={onSelectedSpecificLabel}
                                onSelectedMeshBotTriggerState={onSelectedMeshBotTriggerState}
                                onResetMeshBotTriggerBlocks={onResetMeshBotTriggerBlocks}
                                changeExpression={changeExpression}
                                UpdateExpression={UpdateExpression}
                                currentExpression={currentExpression}
                                onGetCurrentDeviceId={onGetCurrentDeviceId}
                                CreateExpression={CreateExpression}
                                DeleteExpression={DeleteExpression}
                                handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                onGetNameCapability={onGetNameCapability}
                                onUpdateCloudTriggerBlock={onUpdateCloudTriggerBlock}
                                onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                                isNestedGroup={isNestedGroup}
                                foundValidatedWhenBlock={findValidatedWhenBlock(item.id)}
                                scenesList={scenesList}
                                onUpdateTriggerBlockFunction={handleUpdateTriggerFunctionInsideGroup}
                                onSelectedVidooTrigger={onSelectedVidooTrigger}
                                currentMeshbot={currentMeshbot}
                                MeshBotAction={MeshBotAction}
                                onUpdateHouseModeTrigger={onUpdateHouseModeTrigger}
                                isOpenedExceptionBlock={isOpenedExceptionBlock}
                            />
                        );
                    })}
                </div>
                <div className={mesh_bot__group_buttons}>
                    <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleAddTrigger(meshBot.TRIGGER)}
                    >
                        {t(EZLOGIC_TITLE_ADD_TRIGGER)}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleAddTrigger(meshBot.GROUP, meshBot.IN_GROUP)}
                    >
                        {t(EZLOGIC_TITLE_ADD_GROUP)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default GroupBlock;

GroupBlock.propTypes = {
    idGroup: PropTypes.string,
    prevGroup: PropTypes.string,
    optionType: PropTypes.string,
    typeForm: PropTypes.string,
    not: PropTypes.bool,
    blocks: PropTypes.array,
    blockName: PropTypes.string,
    currentDeviceSelect: PropTypes.array,
    onUpdateTriggerNot: PropTypes.func,
    onUpdateTrigger: PropTypes.func,
    onUpdateMeshBotTriggerValue: PropTypes.func,
    onResetMeshBotTriggerBlocks: PropTypes.func,
    onUpdateFieldBlock: PropTypes.func,
    DeleteTrigger: PropTypes.func,
    onSelected: PropTypes.func,
    onAddBlock: PropTypes.func,
    onSelectedDate: PropTypes.func,
    onCheckedNotRadio: PropTypes.func,
    updateTriggerNameBlock: PropTypes.func,
    onSelectedSpecificLabel: PropTypes.func,
    scenesList: PropTypes.array,
    onUpdateHouseModeTrigger: PropTypes.func,
};
