import { buildImgLink } from '../../../services/mediaStore';
import {
    CARD_ELEVATION,
    ORDER_SORTING_VALUES,
    PAGINATION_ARROWS,
    PAGINATION_FIRST_PAGE_VALUE,
    PAGINATION_PER_PAGE_VALUE,
    PAGINATION_STEP,
    SELECTED_CARD_ELEVATION,
    TIME_INTERVALS,
} from '../constants';
import { getFileExtension } from '../../../services/mediaStore/src/utils';
/**
 * Removes the specified query parameters from the url
 * @param {string} url - any url
 * @param {string[]} paramsToRemove - Query list of parameters to remove from the URL
 * @returns {string} new url without params (https://download-cloud.ezlo.com?uuid=1)
 * @example
 * removeParamsFromUrl('https://download-cloud.ezlo.com?uuid=1&redirect_support=1', ['redirect_support'])
 */
export function removeParamsFromUrl(url, paramsToRemove) {
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);

    paramsToRemove.forEach((param) => {
        if (searchParams.has(param)) {
            searchParams.delete(param);
        }
    });

    urlObj.search = searchParams.toString();

    return urlObj.toString();
}
/**
 * Builds a link image in the desired format for copying
 * @param {string} url - image url
 * @returns {string} new url for copy (https://download-cloud.ezlo.com?uuid=1) without redirect_support
 * @example
 * buildUrlForCopy('https://download-cloud.ezlo.com?uuid=1&redirect_support=1')
 */
export const buildUrlForCopy = (url) => {
    return removeParamsFromUrl(url, ['redirect_support']);
};
/**
 * Builds a list of images in the format required by the UI
 * @param {Array<Object>} imgListFromResponse - list of images from response of cloud request
 * @returns {Array<Object>} list of images in the format required by the UI [{{uuid: '1', filename:'logo.png', timestamp: 1236, link: 'https://download-cloud.ezlo.com?uuid=1&redirect_support=1'}}]
 * @example
 * buildImagesList([{uuid: '1', filename:'logo.png', timestamp: 1236, file_format: png}])
 */
export function buildImagesList(imgListFromResponse) {
    return imgListFromResponse
        .filter((response) => Boolean(response?.uuid && response?.key))
        .map(({ uuid, key, timestamp, file_format, filename }) => ({
            link: buildImgLink({ uuid, file_format, key }),
            uuid,
            timestamp,
            filename,
        }));
}
/**
 * Function build filters for fetch images request depending on the input parameters
 * @param {'today' | 'week' | 'year' | 'month' | 'all_time'} param - time interval param
 * @param {number} page - page number
 * @param {number} per_page - per_page number
 * @returns {Object} filters for fetch images request depending on the input parameters
 * @example
 * getFetchImagesFilter('today', 3, 25)
 */
export function getFetchImagesFilter(param, page = PAGINATION_FIRST_PAGE_VALUE, per_page = PAGINATION_PER_PAGE_VALUE) {
    const currentTime = new Date();

    const filters = {
        page: page,
        per_page: per_page,
    };

    if (param === TIME_INTERVALS.TODAY) {
        const todayStart = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
        filters.timestamp_after = todayStart.getTime();
    } else if (param === TIME_INTERVALS.WEEK) {
        const weekAgo = new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000);
        filters.timestamp_after = weekAgo.getTime();
    } else if (param === TIME_INTERVALS.MONTH) {
        const monthAgo = new Date(currentTime.getFullYear(), currentTime.getMonth() - 1, currentTime.getDate());
        filters.timestamp_after = monthAgo.getTime();
    } else if (param === TIME_INTERVALS.YEAR) {
        const yearAgo = new Date(currentTime.getTime() - 365 * 24 * 60 * 60 * 1000);
        filters.timestamp_after = yearAgo.getTime();
    }

    return filters;
}
/**
 * Function adds uuid to the list if it is not in the list and removes uuid from the list if it is there
 * @param {string} uuid - image uuid
 * @param {string[]} selectedImgUuids - list of selected image uuids
 * @returns {Array<Object>} new selected images uuid list
 * @example
 * toggleSelectedImgUuids('1', ['2', '10', '1'])
 */
export const toggleSelectedImgUuids = (uuid, selectedImgUuids) => {
    return selectedImgUuids.includes(uuid)
        ? selectedImgUuids.filter((imgUuid) => imgUuid !== uuid)
        : [...selectedImgUuids, uuid];
};
/**
 * Function checks the file name is in the ISO-8859-1 format
 * @param {string} fileName - file name
 * @returns {boolean} if the name is valid, then return true
 * @example
 * isFileNameValid('logo.png')
 */
export function isFileNameValid(fileName) {
    // eslint-disable-next-line no-control-regex
    const regex = /[^\x00-\xFF]/; // Regular expression to search for non-ISO-8859-1 characters

    return !regex.test(fileName);
}
/**
 * Function sorts the list of images depending on order parameter using 'timestamp'
 * @param {Array<Object>} images - list of images
 * @param {string} order - pagination param ('new' or 'old')
 * @returns {Array<Object>} sorted list of images
 * @example
 * sortImagesByTimestamp([{uuid: '1', filename:'logo.png', timestamp: 1236}], 'new')
 */
export function sortImagesByTimestamp(images, order) {
    if (order === ORDER_SORTING_VALUES.NEW) {
        return [...images].sort((a, b) => b.timestamp - a.timestamp);
    }

    if (order === ORDER_SORTING_VALUES.OLD) {
        return [...images].sort((a, b) => a.timestamp - b.timestamp);
    }

    return images;
}
/**
 * Determines, depending on pagination param, what the new page number should be
 * @param {number} currentPage - current page number
 * @param {string} param - pagination param ('next' or 'prev')
 * @returns {number} new page number
 * @example
 * getPageByPaginationParam(2, 'next')
 */
export const getPageByPaginationParam = (currentPage, param) => {
    if (param === PAGINATION_ARROWS.NEXT) {
        return currentPage + PAGINATION_STEP;
    }

    if (param === PAGINATION_ARROWS.PREV) {
        return currentPage - PAGINATION_STEP;
    }

    return currentPage;
};

/**
 * Depending on isSelectedImg, the function returns elevation number for the card
 * @param {boolean} isSelectedImg - Indicates whether the image is selected
 * @returns {number} elevation number
 * @example
 * getCardElevation(true)
 */
export const getCardElevation = (isSelectedImg) => {
    return isSelectedImg ? SELECTED_CARD_ELEVATION : CARD_ELEVATION;
};

/**
 * Determines if uuid is in the list of selected uuids
 * @param {string} uuid - image uuid
 * @param {string[]} selectedImgUuids - list of selected image uuids
 * @returns {boolean} will return true if the uuid is in the list of selected uuids
 * @example
 * determineIsSelectedImg('2sfr53', ['485sdf6', '2sfr53', '4125sfe8'])
 */
export const determineIsSelectedImg = (uuid, selectedImgUuids) => {
    return selectedImgUuids.includes(uuid);
};
/**
 * Build image file in FormData format
 * @param {File} imageFile - image file
 * @returns {Object} image file in FormData format
 * @example
 * buildImgFormData(file)
 */
export function buildImgFormData(imageFile) {
    const multipartFormData = new FormData();
    multipartFormData.append('file', imageFile);

    return multipartFormData;
}

/**
 * Provides data from the redux store
 * @param {Object} - redux store
 * @returns {Object} data from redux store
 * @example
 * useSelector(mediaStoreSelector)
 */
export const mediaStoreSelector = ({ app, mediaStore }) => ({
    isLoading: app.lineLoading,
    imagesList: mediaStore.imagesList,
    uploadingImages: mediaStore.uploadingImages,
    isEndPage: mediaStore.isEndPage,
});

/**
 * Build img in ui format
 * @param {Object} responseFile - uploaded file response
 * @param {File} file - uploaded file
 * @returns {Object} img in ui format
 * @example
 * buildNewImageInUiFormat({uuid: '1', key: '524jg'}, {name: 'logo.png, ...other})
 */
export const buildNewImageInUiFormat = (responseFile, file) => {
    return {
        ...responseFile,
        filename: file.name,
        timestamp: new Date().getTime(),
        file_format: getFileExtension(file),
    };
};
