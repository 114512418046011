import React from 'react';
import { Dialog } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ConfigureForm from '../../containers/Ezlo/EzloPlugins/components/modalTemplates/ConfigureForm';
import styles from './styles.module.scss';
import EzloIntegrationsCreateNewDeviceFromPlugin from '../../containers/Ezlo/EzloIntegrations/EzloIntegrationPages/EzloIntegrationsCreatePluginIntegration/EzloIntegrationsCreateNewDeviceFromPlugin';

const FullScreenDialog = ({
    isConfigureModalOpen,
    handleConfigureModalClose,
    currentSerial,
    currentCustomNode,
    inIntegration,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open={isConfigureModalOpen}
            fullScreen={fullScreen}
            onClose={handleConfigureModalClose}
            classes={{ paper: inIntegration ? styles.fullScreenIntegrationWrapper : styles.fullScreenDialogWrapper }}
        >
            {inIntegration ? (
                <EzloIntegrationsCreateNewDeviceFromPlugin
                    currentSerial={currentSerial}
                    currentCustomNode={currentCustomNode}
                    onCancel={handleConfigureModalClose}
                    open={isConfigureModalOpen}
                />
            ) : (
                <ConfigureForm
                    currentSerial={currentSerial}
                    currentCustomNode={currentCustomNode}
                    onCancel={handleConfigureModalClose}
                    open={isConfigureModalOpen}
                />
            )}
        </Dialog>
    );
};

export default FullScreenDialog;
