/**
 * "user" GET param identified.
 * @example
 * Password reset: https://ezlogic.mios.com/#/passwordreset?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * Verify email: https://ezlogic.mios.com/#/verify/email?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * */
export const USER_GET_PARAM_NAME = 'user';

/**
 * "code" GET param identified.
 * @example
 * Password reset: https://ezlogic.mios.com/#/passwordreset?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * Verify email: https://ezlogic.mios.com/#/verify/email?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * */
export const CODE_GET_PARAM_NAME = 'code';

/**
 * "partner" GET param identified.
 * @example
 * Password reset: https://ezlogic.mios.com/#/passwordreset?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * Verify email: https://ezlogic.mios.com/#/verify/email?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * */
export const PARTNER_GET_PARAM_NAME = 'partner';

/**
 * List of possible values for "partner" GET param.
 * '1' - belongs to partner, otherwise - no.
 * @example
 * Password reset: https://ezlogic.mios.com/#/passwordreset?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * Verify email: https://ezlogic.mios.com/#/verify/email?user=2950622&code=0XEqCqa4Uh-85~&partner=1
 * */
export const PARTNER_GET_PARAM_VALUES = {
    PARTNER: '1',
};

/**
 * "userpasswordinfo" GET param identified.
 * @example
 * First login page: https://protect-qa.mios.com/#/firstlogin?userpasswordinfo=eyJ1c2VybmFtZSI6InJpZmZhdGVicmlwcGEzOTk1IiwicGFzc3dvcmQiOiJ4dnJ0V0dnODRGbSEifQ==
 * */
export const USER_PASSWORD_INFO_GET_PARAM_NAME = 'userpasswordinfo';
