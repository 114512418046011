export const LANGUAGES = [
    {
        text: 'ezlogic.title.english',
        value: 'en',
        flag: 'us',
    },
    {
        text: 'ezlogic.title.spanish',
        value: 'es',
        flag: 'es',
    },
    {
        text: 'ezlogic.title.french',
        value: 'fr',
        flag: 'fr',
    },
    {
        text: 'ezlogic.title.german',
        value: 'de',
        flag: 'de',
    },
];

export const FLAGURL = 'https://flagcdn.com/w40/';

export const optionsSelect = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    getContentAnchorEl: null,
    disableScrollLock: true,
};
