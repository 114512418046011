import React from 'react';

import { selectedTypeItem } from '../InfoPopper/styles.module.scss';

const AccountTypesItem = (props) => {
    const { title, description, onGetActiveAccountTypeClassName, value } = props;

    return (
        <li className={onGetActiveAccountTypeClassName({ value, className: selectedTypeItem })}>
            <h3>{title}</h3>
            <p>{description}</p>
        </li>
    );
};

export default AccountTypesItem;
