import { useState, useEffect, useRef } from 'react';

const useTooltip = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    const handleIconClick = () => {
        setShowTooltip(!showTooltip);
    };
    const hideToolTip = () => {
        setShowTooltip(false);
    };

    const handleOutsideClick = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (showTooltip) {
            window.addEventListener('click', handleOutsideClick);
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [showTooltip]);

    return { showTooltip, tooltipRef, handleIconClick, hideToolTip };
};

export default useTooltip;
