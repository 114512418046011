import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PluginEdge from './PluginEdge';
import PluginCloud from './PluginCloud';
import PluginWifiGenerator from './PluginWifiGenerator';
import PluginSettings from './PluginSettings';
import NotFoundPageContent from '../../../containers/NotFoundPage/NotFoundPageContent';

const EzloPlugins = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}/edge`} component={PluginEdge} />
            <Route path={`${url}/cloud`} component={PluginCloud} />
            <Route path={`${url}/wifi-generator`} component={PluginWifiGenerator} />
            <Route path={`${url}/settings`} component={PluginSettings} />
            <Route render={() => <NotFoundPageContent loggedIn />} />
        </Switch>
    );
};

export default EzloPlugins;
