import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { EyeIcon, EyeOffIcon } from '../../../assets/icons';

const PasswordField = ({ onChange, name, value, isRequired }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (e) => {
        onChange(e.target.value, name, isRequired);
    };

    return (
        <TextField
            onChange={handleChange}
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={handleClickShowPassword}>
                            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;
