import React from 'react';
import classes from 'classnames';

import {
    button,
    checkbox_label,
    checkbox_active,
    checkbox_activeGroup,
    wrapper_buttonGroup,
} from './CheckboxButton.module.scss';

const CheckboxButton = (props) => {
    const { name, id, value, label, activeGroup, onChange } = props;
    const className = classes(checkbox_label, { [checkbox_active]: value }, { [checkbox_activeGroup]: activeGroup });

    return (
        <div
            className={classes(button, {
                [wrapper_buttonGroup]: activeGroup,
            })}
        >
            <input type="checkbox" name={name} checked={value} id={id} onChange={(e) => onChange(e)} />
            <label htmlFor={id} className={className}>
                {label}
            </label>
        </div>
    );
};

export default CheckboxButton;
