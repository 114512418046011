import { useSelector } from 'react-redux';
import React from 'react';
import { Box, LinearProgress } from '@material-ui/core';

import styles from '../../../../components/Header/style.module.scss';

const UsersLineLoading = () => {
    const isLoading = useSelector(({ users }) => users.isLoading);

    if (!isLoading) {
        return null;
    }

    return (
        <Box className={styles.linearProgressBox}>
            <LinearProgress aria-label="LinearProgress" className={styles.linearProgress} />
        </Box>
    );
};

export default UsersLineLoading;
