import React, { useState } from 'react';

import SpecialTypeLabel from '../TreeItemLabels/SpecialTypeLabel';
import TreeItemComponent from '../components/TreeItemComponent';

const SpecialTypeTreeElement = ({ treeElementValue, treeElementName, elementData, prevPartPath }) => {
    const [selectedSpecialType, setSpecialType] = useState(treeElementValue.keywordTypes[0]);
    const treeElementProperties = Object.entries(treeElementValue[selectedSpecialType]?.properties);

    const handleTypeChange = (e) => {
        setSpecialType(e.target.value);
    };

    const labelJSX = (
        <SpecialTypeLabel
            treeItemName={treeElementName}
            elementData={elementData}
            treeItemValue={treeElementValue}
            handleTypeChange={handleTypeChange}
            selectedType={selectedSpecialType}
        />
    );

    return (
        <TreeItemComponent
            nodeId={treeElementValue.elementId}
            treeElementProperties={treeElementProperties}
            prevPartPath={prevPartPath}
            labelComponent={labelJSX}
        />
    );
};

export default SpecialTypeTreeElement;
