import React from 'react';

const InfoIcon = (props) => (
    <svg {...props} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M100 148C126.51 148 148 126.51 148 100C148 73.4903 126.51 52 100 52C73.4903 52 52 73.4903 52 100C52 126.51 73.4903 148 100 148Z"
            stroke="#697580"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path d="M100 106.5V70.5" stroke="#697580" strokeWidth="16" strokeMiterlimit="10" />
        <path
            d="M107.5 122C107.5 126.142 104.142 129.5 100 129.5C95.8579 129.5 92.5 126.142 92.5 122C92.5 117.858 95.8579 114.5 100 114.5C104.142 114.5 107.5 117.858 107.5 122Z"
            fill="#697580"
            stroke="#697580"
        />
        <path
            d="M154.3 113.8L140 99.5C140 99.7 140 99.8 140 100C140 120.3 124.9 137 105.3 139.6L118.5 152.8C136.1 146.7 149.7 132 154.3 113.8Z"
            fill="#697580"
        />
    </svg>
);

export default InfoIcon;
