import '../style.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../actions';
import MeshBotCloud from './MeshBotCloud';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    router: state?.router?.location?.pathname,
    isDirty: state.main.editableComponent?.isDirty,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(MeshBotCloud);
