import React from 'react';
import { info } from '../../../utils';
import { InputAdornment } from '@material-ui/core';
import styles from '../../../../../../components/SelectValueToCompare/styles.module.scss';
import InfoButton from '../../InfoButton';
import InputNumberBlock from '../../../../../../components/inputNumber';
import { useTranslate } from '../../../../../../features/languages';

const ControllerCapabilityComparatorValue = ({ selectedControllerCapabilityComparatorValue, onChange }) => {
    const { t } = useTranslate();

    return (
        <InputNumberBlock
            label={info.hints.value}
            info={info.text.value}
            className={styles.inputNumber}
            value={selectedControllerCapabilityComparatorValue ? selectedControllerCapabilityComparatorValue : ''}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <InfoButton>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(info.text.value),
                                }}
                            ></div>
                        </InfoButton>
                    </InputAdornment>
                ),
            }}
            onChange={(e) => onChange({ selectedControllerCapabilityComparatorValue: e.target.value })}
        />
    );
};

export default ControllerCapabilityComparatorValue;
