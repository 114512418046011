import React from 'react';
import { Button } from '@material-ui/core';

import {
    EZLOGIC_TITLE_DRAG_N_DROP_SOME_IMAGES_HERE_OR_CLICK,
    EZLOGIC_TITLE_ONLY_PNG_JPG_SVG_IMAGES_WILL_BE_ACCEPTED,
    EZLOGIC_TITLE_OPEN_FILE_DIALOG,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';

import styles from './styles.module.scss';

const DropzoneBox = ({ getRootProps, getInputProps, open }) => {
    const { t } = useTranslate();

    return (
        <div {...getRootProps({ className: styles.dropzone })} onClick={(e) => e.stopPropagation}>
            <input {...getInputProps()} />
            <p>{t(EZLOGIC_TITLE_DRAG_N_DROP_SOME_IMAGES_HERE_OR_CLICK)}</p>
            <p>{t(EZLOGIC_TITLE_ONLY_PNG_JPG_SVG_IMAGES_WILL_BE_ACCEPTED)}</p>
            <div>
                <Button variant="outlined" type="button" onClick={open}>
                    {t(EZLOGIC_TITLE_OPEN_FILE_DIALOG)}
                </Button>
            </div>
        </div>
    );
};

export default DropzoneBox;
