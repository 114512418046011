import React from 'react';
import { TextField } from '@material-ui/core';

import withDraggingState from '../HOC/withDraggingState';

import styles from '../styles.module.scss';

const LabelWithInput = (props) => {
    const { elementData, treeItemName, treeItemValue, handleIndexChange, itemIndex, handleDragStart, handleDragEnd } =
        props;

    const handleInputClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className={styles.treeItemLabelBox}
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            data-element={elementData}
        >
            {treeItemName}[
            <div className={`${styles.treeItemInput}`}>
                <TextField
                    autoFocus={true}
                    inputProps={{ min: 0 }}
                    type="number"
                    onClick={handleInputClick}
                    onChange={handleIndexChange}
                    value={itemIndex}
                />
            </div>
            ]<span className={styles.elementType}>: {treeItemValue?.type}</span>
        </div>
    );
};

export default withDraggingState(LabelWithInput);
