import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

import { NOTIFICATIONS_CREATE } from '../../../../../constants/URLs';

import { actions as NotificationActions } from '../../../../../actions/NotificationActions';

import { MESHBOT_TEMPLATE, SYSTEM } from '../../../../../constants/Notifications';

import styles from './EzloNotificationTemplateTypeItem.module.scss';

const EzloNotificationTemplateTypeItem = (props) => {
    const { title, icon, name, onClose } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const handleCreateTemplate = (e) => {
        onClose();

        if (e.currentTarget.name === SYSTEM) {
            dispatch(NotificationActions.updateNotificationType(SYSTEM));
        } else {
            dispatch(NotificationActions.updateNotificationType(MESHBOT_TEMPLATE));
        }

        history.push(NOTIFICATIONS_CREATE);
    };

    return (
        <div className={styles.templateTypeItemWrapper}>
            <div className={styles.templateTypeItemIcon}>{icon}</div>
            <h3 className={styles.templateTypeItemTitle}>{title}</h3>
            <Button
                className={styles.templateTypeItemCreateBtn}
                name={name}
                color="primary"
                variant="contained"
                onClick={handleCreateTemplate}
            >
                Create
            </Button>
        </div>
    );
};

export default EzloNotificationTemplateTypeItem;
