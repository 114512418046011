import React, { memo } from 'react';

import { Header } from '../../../../../components';
import SwitchControllers from '../../../../../components/SwitchControllers';
import { useTranslate } from '../../../../../features/languages';

import styles from '../../styles.module.scss';

const HeaderWithControllersSelect = ({ title }) => {
    const { t } = useTranslate();

    return (
        <Header>
            <h1 className={styles.headerTitle}>{t(title)}</h1>
            <div className={styles.switchControllersBox}>
                <SwitchControllers />
            </div>
        </Header>
    );
};

export default memo(HeaderWithControllersSelect);
