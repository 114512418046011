import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import wsm from '../helpers/wsm';
import toastsActions from './toastsActions';
import {
    BROADCAST_STATUSES,
    CREATE_VIRTUAL_DEVICE,
    GET_HUB_UPDATE_PLAN,
    INSTALL_TEST_PLUGIN,
} from '../constants/queryConstants';
import {
    getErrorData,
    getErrorDataForBugsnagNotify,
    getPayloadForErrorToast,
    getPayloadOfCreateVirtualDevice,
    getPayloadOfInstallPluginSuccessfully,
} from '../containers/Ezlo/VirtualDevices/utils';
import { TEST_PLUGIN_ID } from '../constants/VirtualDeviceConstant';
import GenericActions from './GenericActions';
import * as types from '../constants/ActionTypes/VirtualDeviceManager';
import actions from './PluginActions';

const virtualDevicesActions = {
    createNewVirtualDevices: (virtualDevices, t) => async (dispatch, getState) => {
        try {
            const {
                ezlo: { serial },
            } = getState();
            const dispatchListOfCreateNewVirtualDevices = virtualDevices.map((device) => {
                return dispatch(virtualDevicesActions.createNewVirtualDevice(device, serial, t));
            });

            await Promise.all(dispatchListOfCreateNewVirtualDevices);
        } catch (error) {
            bugsnagNotify(error);
        }
    },

    createNewVirtualDevice: (device, serial, t) => async (dispatch) => {
        const { devices_count, device_type } = device;
        const scriptParams = {
            devices_count: Number(devices_count),
            device_type: device_type.apiName,
        };
        await wsm.send(
            serial,
            CREATE_VIRTUAL_DEVICE.METHOD,
            {
                script: CREATE_VIRTUAL_DEVICE.SCRIPT,
                scriptParams,
            },
            () => {
                const payload = getPayloadOfCreateVirtualDevice(device, undefined, t);
                dispatch(toastsActions.showToast(payload));
            },
            (error) => {
                const payload = getPayloadOfCreateVirtualDevice(device, error, t);
                dispatch(toastsActions.showToast(payload));

                const errorData = getErrorData(device, serial);
                bugsnagNotify(error, errorData);
            },
        );
    },

    installPlugin: () => async (dispatch) => {
        try {
            dispatch(GenericActions.setLineLoading(true));
            // get actions (from the controller update plan) to install the plugin and then install the plugin
            // with pluginId='test_plugin'
            await dispatch(virtualDevicesActions.getActionsAndInstallTestPlugin());
        } catch (e) {
            dispatch(GenericActions.setLineLoading(false));
            bugsnagNotify(e);
        }
    },
    getActionsAndInstallTestPlugin: () => (dispatch, getState) => {
        const {
            ezlo: { serial },
        } = getState();

        // Broadcast subscription of get an update plan
        dispatch(virtualDevicesActions.subscribeToBroadcastOfUpdatePlan());
        // Subscribe to a broadcast that tells us if the plugin has been successfully installed on the hub
        dispatch(virtualDevicesActions.subscribeToBroadcastOfUpdateProgress());
        // A request to receive data that let us know what plans to update on the controller
        dispatch(virtualDevicesActions.getUpdatePlan(serial));
    },
    //  Plugin installation request(ws)
    installTestPlugin: (actions) => async (dispatch, getState) => {
        const {
            ezlo: { serial },
        } = getState();
        const params = { actions };

        await wsm.send(
            serial,
            INSTALL_TEST_PLUGIN.METHOD,
            params,
            () => {},
            (error) => {
                dispatch(virtualDevicesActions.handleWsError(error, serial, params, INSTALL_TEST_PLUGIN.METHOD));
            },
        );
    },
    // Controller update plan request(ws)
    getUpdatePlan: (serial) => async (dispatch) => {
        const params = GET_HUB_UPDATE_PLAN.PARAMS;
        await wsm.send(
            serial,
            GET_HUB_UPDATE_PLAN.METHOD,
            params,
            () => {},
            (error) => {
                dispatch(virtualDevicesActions.handleWsError(error, serial, params, GET_HUB_UPDATE_PLAN.METHOD));
            },
        );
    },
    // subscribe to the broadcast and get actions from it. among the actions we are looking for
    // an action with "test_plugin". this action is passed to the plugin installation request.
    subscribeToBroadcastOfUpdatePlan: () => (dispatch, getState) => {
        const {
            ezlo: { serial },
        } = getState();

        const cb = () => {
            return ({ result }) => {
                const testPluginAction = result.actions.find(({ id }) => id === TEST_PLUGIN_ID);
                if (testPluginAction) {
                    dispatch(virtualDevicesActions.installTestPlugin([testPluginAction]));
                }
                dispatch(virtualDevicesActions.unsubscribeToBroadcastOfUpdatePlan(serial));
            };
        };

        wsm.subscribe(serial, GET_HUB_UPDATE_PLAN.BROADCAST_NAME, cb());
    },
    unsubscribeToBroadcastOfUpdatePlan: (serial) => () => {
        wsm.unsubscribe(serial, GET_HUB_UPDATE_PLAN.BROADCAST_NAME);
    },
    subscribeToBroadcastOfUpdateProgress: () => (dispatch, getState) => {
        const {
            ezlo: { serial },
        } = getState();
        const cb = () => {
            return ({ result }) => {
                if (result.status === BROADCAST_STATUSES.FINISHED) {
                    dispatch({ type: types.SET_IS_INSTALL_PLUGIN, isInstallPlugin: true });
                    dispatch(toastsActions.showToast(getPayloadOfInstallPluginSuccessfully()));
                    dispatch(actions.getListInstalledPlugins(serial));
                    dispatch(virtualDevicesActions.unsubscribeToBroadcastOfUpdateProgress(serial));
                }
            };
        };
        wsm.subscribe(serial, INSTALL_TEST_PLUGIN.BROADCAST_NAME, cb());
    },
    unsubscribeToBroadcastOfUpdateProgress: (serial) => () => {
        wsm.unsubscribe(serial, INSTALL_TEST_PLUGIN.BROADCAST_NAME);
    },
    handleWsError: (error, serial, params, method) => (dispatch) => {
        dispatch(GenericActions.setLineLoading(false));
        const payload = getPayloadForErrorToast(error);
        dispatch(toastsActions.showToast(payload));

        const errorData = getErrorDataForBugsnagNotify(params, serial, method);
        bugsnagNotify(error, errorData);
    },
};

export default virtualDevicesActions;
