import React from 'react';
import ActionDelay from '../../../../../components/ActionDelay';
import TriggerFieldActionBlock from '../triggerFieldActionBlock';

import at from '../../../../../constants/ActionTypes/MeshBot';

const TriggerFieldActionContainer = (props) => {
    const {
        id,
        blocks,
        type,
        firstBlock,
        nameDevice,
        currentRule,
        actionBlockName,
        isShowedDelay,
        onChangeDelay,
        onUpdateActionDelay,
        onUpdateFieldActionBlock,
        onSetIsShowedDelay,
        onAddActionBlock,
    } = props;

    const addLabelName = (data) => {
        return data.replace(/_DEVICE_NAME_/gi, nameDevice);
    };

    return (
        <>
            {blocks.map((item) => {
                if (type !== at.CLOUD && item._tempId === firstBlock) {
                    return (
                        <React.Fragment key={item._tempId}>
                            <TriggerFieldActionBlock
                                {...item}
                                block={item}
                                idDevice={id}
                                addLabelName={addLabelName}
                                onUpdateFieldActionBlock={onUpdateFieldActionBlock}
                                actionBlockName={actionBlockName}
                                onAddActionBlock={onAddActionBlock}
                            />
                            <ActionDelay
                                id={id}
                                delayDisabled={false}
                                actionBlockName={actionBlockName}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                onUpdateActionDelay={onUpdateActionDelay}
                                onChangeDelay={onChangeDelay}
                                onSetIsShowedDelay={onSetIsShowedDelay}
                            />
                        </React.Fragment>
                    );
                }
            })}
        </>
    );
};

export default TriggerFieldActionContainer;
