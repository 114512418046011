import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import sha1 from 'sha1';
import classNames from 'classnames';
import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import * as Actions from '../../../actions';
import { FORGOT_PASSWORD, REGISTER } from '../../../constants/URLs';
import { HASH_STRING } from '../../../services/auth';
import { setEnvManually } from '../../../constants/envs';
import { CLOUD_ENVIRONMENT } from '../../../constants/localStorageKeys';
import LoadingEllipses from './components/LoadingEllipses';
import Form from '../components/AuthorizationPagesForm';
import Container from '../components/Container';
import EnvironmentsSelect from '../../../components/EnvironmentsSelect/EnvironmentsSelect';
import { loginValidationSchema } from '../validationSchema';
import { isEnvSwitcherEnabled } from './utils';
import { useTranslate } from '../../../features/languages';
import {
    EZLOGIC_TITLE_NAME_OR_PASSWORD_INCORRECT,
    LOGIN_BUTTON_TITLE,
    LOGIN_PAGE_DONT_HAVE_ACCONT_TITLE,
    LOGIN_PAGE_FORGOT_TITLE,
    LOGIN_PAGE_MAIN_HEADING,
    SIGN_IN_ENVIRONMENT_TITLE,
    SIGN_UP_BUTTON_TITLE,
} from '../../../constants/language_tokens';
import useShowSignUp from '../hooks/useShowSignUp';

import styles from './Login.module.scss';

const Login = (props) => {
    const { isAuthenticating } = props.redux.state;
    const { t } = useTranslate();
    const isEnvSwitcherDisplayed = isEnvSwitcherEnabled(window.location.host);
    const [isSignUp, updateIsSignUp] = useShowSignUp();

    const login = ({ name, pass }) => {
        if (!isAuthenticating) {
            const { login } = props.redux.actions.GenericActions;
            login(name, sha1(name + pass + HASH_STRING), sha1(name.toLowerCase() + pass + HASH_STRING));
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            pass: '',
        },
        validationSchema: loginValidationSchema,
        onSubmit: login,
    });

    useEffect(() => {
        const { isAuthenticated, redirect } = props.redux.state;
        const { replace } = props.redux.actions.routerActions;

        if (isAuthenticated) {
            // TODO: must be fixed, Legacy workaround, redirect to '/' if user already authenticated
            setTimeout(() => {
                replace(redirect);
            }, 0);
        }
    }, [props]);

    useEffect(() => {
        const { isCredentialsError } = props.redux.state;

        if (isCredentialsError) {
            formik && formik.setErrors({ pass: t(EZLOGIC_TITLE_NAME_OR_PASSWORD_INCORRECT) });
        }
    }, [props, formik]);

    const handleRegisterClick = () => {
        const { replace } = props.redux.actions.routerActions;
        replace(REGISTER);
    };

    const getLoginButtonContent = () => {
        const buttonLabel = t(LOGIN_BUTTON_TITLE);

        return !isAuthenticating ? buttonLabel : <LoadingEllipses />;
    };

    const handleChangeEnvironment = (e) => {
        setEnvManually(true, e.target.value);
        updateIsSignUp();
    };

    return (
        <Container isLogin title={t(LOGIN_PAGE_MAIN_HEADING)}>
            <Form getLoginButtonContent={getLoginButtonContent} isLogin formik={formik}>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={classNames('submit__btn', { 'disabled-pointer-events': isAuthenticating })}
                >
                    {getLoginButtonContent()}
                </Button>
            </Form>
            {isEnvSwitcherDisplayed && (
                <div className="environment">
                    <p className="title">{t(SIGN_IN_ENVIRONMENT_TITLE)}</p>
                    <FormControl variant="outlined" size="small">
                        <EnvironmentsSelect
                            onChange={handleChangeEnvironment}
                            defaultValue={localStorage.getItem(CLOUD_ENVIRONMENT)}
                        />
                    </FormControl>
                </div>
            )}

            <div className="link-wrapper">
                <Link className="link" to={FORGOT_PASSWORD}>
                    {t(LOGIN_PAGE_FORGOT_TITLE)}
                </Link>
            </div>
            {isSignUp && (
                <>
                    <label className={styles.signupLabel}>{t(LOGIN_PAGE_DONT_HAVE_ACCONT_TITLE)}</label>
                    <Button variant="outlined" fullWidth className={styles.signupBtn} onClick={handleRegisterClick}>
                        {t(SIGN_UP_BUTTON_TITLE)}
                    </Button>
                </>
            )}
        </Container>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.account.logged || false,
    isAuthenticating: state.account.isLogging || false,
    isCredentialsError: state.account.isCredentialsError || false,
    redirect: '/',
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    obj.routerActions = bindActionCreators(routerActions, dispatch);

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

Login.propTypes = {
    redux: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Login);
