import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import * as Actions from '../../../actions';
import { LOGIN } from '../../../constants/URLs';

const LoginWithToken = (props) => {
    const { search } = useLocation();
    const history = useHistory();
    const { loginUserWithToken } = props.redux.actions.GenericActions;
    const { replace } = props.redux.actions.routerActions;

    useEffect(() => {
        const query = new URLSearchParams(search);
        const key = query.get('token');

        if (!key) {
            replace(LOGIN);
        } else {
            history.push({ search: key });
            loginUserWithToken(key);
        }
    }, [null]);

    return <></>;
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    obj.routerActions = bindActionCreators(routerActions, dispatch);

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(null, mapDispatchToProps, mergeProps)(LoginWithToken);
