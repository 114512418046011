import React, { Component } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import classes from 'classnames';

import TimeOfDaySelectionSection from '../../containers/Ezlo/EzloMeshbot/components/templateDateTimeBlocks/TimeOfDaySelectionSection';
import at from '../../constants/ActionTypes/MeshBot';
import { wrapper, item_month, active } from './Month.module.scss';
/**
 * Create month component
 * @param { object } props
 * @returns { node } Month component
 * @example
 * <Month
 *      isArrayDate="example"
 *      onChange={func}
 * />
 */

class Month extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: this.createCurrentMonth(),
        };
    }

    componentDidMount() {
        this.setState({ month: this.formattingMonth(this.props.isArrayDate) });
    }

    // eslint-disable-next-line
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isArrayDate) {
            this.setState({ month: this.formattingMonth(nextProps.isArrayDate) });
        }
    }

    createCurrentMonth = () => {
        // const month = moment().daysInMonth();
        let result = [];
        for (let i = 1; i <= 31; i++) {
            result = [...result, { name: i, checked: false }];
        }

        return result;
    };

    formattingMonth = (data) => {
        return this.state.month.map((item, index) => {
            if (data.includes(index + 1)) {
                item.checked = true;

                return item;
            }
            item.checked = false;

            return item;
        });
    };

    handleChange = (e, item) => {
        const { onChange } = this.props;

        onChange(e, item);
    };

    render() {
        const { month } = this.state;
        const {
            id,
            actionId,
            idGroup,
            blocks,
            onSetDateValue,
            onSelectedDate,
            selectedFieldDate,
            isSupportsUpdatedAdvancedScenesForDays,
            typeMeshbot,
        } = this.props;

        return (
            <>
                <ul className={wrapper}>
                    {month.map((item, index) => {
                        return (
                            <li key={index + 1} className={classes(item_month, { [active]: item.checked })}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={(e) => this.handleChange(e, index + 1)}
                                        />
                                    }
                                    label={item.name}
                                />
                            </li>
                        );
                    })}
                </ul>
                {typeMeshbot === at.LOCAL && (
                    <TimeOfDaySelectionSection
                        id={id}
                        actionId={actionId}
                        idGroup={idGroup}
                        blocks={blocks}
                        onSetDateValue={onSetDateValue}
                        onSelectedDate={onSelectedDate}
                        selectedFieldDate={selectedFieldDate}
                        isSupportsUpdatedAdvancedScenesForDays={isSupportsUpdatedAdvancedScenesForDays}
                    />
                )}
            </>
        );
    }
}

export default Month;

// Month.propTypes = {
//     isArrayDate: PropTypes.array,
//     onChange: PropTypes.func,
// };
