import React from 'react';
// import {func, object, array} from 'prop-types';
import { SETTING_TYPE_BOOL, SETTING_TYPE_TOKEN, SETTING_NAMES } from '../../../../../constants/Devices';
import DeviceHubSettingToken from './DeviceHubSettingToken';
import DeviceHubSettingBool from './DeviceHubSettingBool';
import './style.scss';

export const nameParser = (name) => {
    if (typeof name === 'string') {
        const result = name.split('.').join(' ');

        return result.split('_').join(' ');
    }
};

// TODO: refactoring for class-based component needed!
export default class DeviceHubSettings extends React.Component {
    getDeviceSettings = (item) => {
        switch (item.valueType) {
            case SETTING_TYPE_BOOL:
                return <DeviceHubSettingBool settings={item} onSave={this.props.onSaveHubSettings} />;
            case SETTING_TYPE_TOKEN:
                return <DeviceHubSettingToken settings={item} onSave={this.props.onSaveHubSettings} />;
            default:
                return null;
        }
    };

    render() {
        const { properties } = this.props;
        const hasDeviceProperties = properties && properties.length > 0;

        return (
            <>
                {hasDeviceProperties &&
                    properties
                        .filter((prop) => prop.name !== SETTING_NAMES.FIRST_START)
                        .map((item, key) => {
                            const deviceSetting = this.getDeviceSettings(item);

                            if (deviceSetting) {
                                return (
                                    <div className="dialog__item" key={key}>
                                        {deviceSetting}
                                    </div>
                                );
                            }
                        })}
            </>
        );
    }
}
