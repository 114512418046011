export const VIRTUAL_DEVICE_PAGES = {
    // INDEX: 'virtual-container',
    CREATE: 'create',
    EDIT: 'edit',
    MAP: 'map',
};

export const MANAGE_VIRTUAL_DEVICE_FORM_CATEGORIES = {
    DEVICES: 'devices', // do not rename
    SERVICES: 'services', // do not rename
};

export const ABSTRACT_SOURCES = {
    CONTROLLER: 'controller',
    INTEGRATION: 'integration',
    VIRTUAL_DEVICE: 'virtual_device',
};

export const CAPABILITIES = {
    TEXT_COMMAND: 'text_command',
    POWER_COMMAND: 'power_command',
    DIMMER_COMMAND: 'dimmer_command',
};

export const VOI_SUPPORTED_DEVICE_CATEGORIES = {
    SWITCH: 'switch',
    DIMMER: 'dimmer',
};
