import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from '../../../../../constants/uniqueHash';
import {
    controllersPluginInstalled,
    listDevicesInstalled,
    listDevicesInstalledWifi,
    getDevicesWithVersion,
} from '../../utils';
import { PRIVATE_GENERATOR } from '../../../../../constants/Plugins';
import DeviceItemTemplate from '../DeviceItemTemplate';
import {
    EZLOGIC_TITLE_CONTROLLER,
    EZLOGIC_TITLE_DEVICE_NAME,
    EZLOGIC_TITLE_NO_CREATED_DEVICE_TEXT,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { prepareDevices } from '../../../EzloDevices/utils';
import { prepareGateways } from '../../../EzloDevice/utils';
import { useSelector } from 'react-redux';

const DevicesList = (props) => {
    const [listDevices, setListDevices] = useState([]);
    const { pluginData, listInstalledPlugins, devices, typeList } = props;
    const { t } = useTranslate();
    const { data } = useSelector((state) => state.ezlo);
    const state = useSelector((state) => state);

    useEffect(() => {
        if (typeList === PRIVATE_GENERATOR) {
            setListDevices(listDevicesInstalledWifi(pluginData, devices));
        } else {
            const pluginInstalled = controllersPluginInstalled(listInstalledPlugins, pluginData);
            setListDevices(
                listDevicesInstalled(
                    getDevicesWithVersion(prepareDevices(data), listInstalledPlugins, prepareGateways(state)),
                    pluginInstalled,
                ),
            );
        }
    }, [pluginData, listInstalledPlugins, devices]);

    return (
        <div className="modal-plugin__devices">
            {listDevices.length > 0 && (
                <>
                    <div className="modal-plugin__scroll-thead">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t(EZLOGIC_TITLE_DEVICE_NAME)}</th>
                                    <th>{t(EZLOGIC_TITLE_CONTROLLER)}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="modal-plugin__scroll-body">
                        <table>
                            <tbody>
                                {listDevices.map((item) => {
                                    return <DeviceItemTemplate key={hash()} {...item} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {listDevices.length === 0 && (
                <p className="modal-plugin__devices-empty">{t(EZLOGIC_TITLE_NO_CREATED_DEVICE_TEXT)}</p>
            )}
        </div>
    );
};

DevicesList.propTypes = {
    typeList: PropTypes.string,
    pluginData: PropTypes.object,
    listInstalledPlugins: PropTypes.object,
    devices: PropTypes.array,
};

export default DevicesList;
