import { useState } from 'react';
import { apiUserValidateEmail } from '../../../../services/auth';

const SUCCESS_API_CALL_STATUS = 1;
const API_CALL_TIMEOUT = 2000;

/**
 * Hook, used to encapsulate logic related to validate email based on Cloud API call.
 * */
const useValidateEmail = () => {
    // send the request and update the status
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const setPayload = (payload) => {
        setError('');
        setLoading(true);
        setData(null);

        // TODO: workaround, timeout could be removed only after subscription implementation to OEM manager.
        const timeout = setTimeout(async () => {
            const { pk_user, code } = payload;

            try {
                const response = await apiUserValidateEmail({ pk_user, code });

                if (
                    response?.data?.complete === SUCCESS_API_CALL_STATUS &&
                    response?.data.status === SUCCESS_API_CALL_STATUS
                ) {
                    setData(response?.data?.data);
                } else {
                    setError(response?.data?.data?.error_text);
                }
            } catch (err) {
                setError(err?.message);
            } finally {
                setLoading(false);
            }
        }, API_CALL_TIMEOUT);

        return () => {
            clearTimeout(timeout);
        };
    };

    return [data, error, loading, setPayload];
};

export default useValidateEmail;
