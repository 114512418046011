import React from 'react';
import { FieldArray, Form, useFormikContext } from 'formik';

import SaveButton from '../../components/SaveButton/SaveButton';
import AddressSection from '../AddressSection';
import AccordionAddressSection from '../AddressSection/AccordionAddressSection';

import { formContainer } from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_HEADING_BILLING_ADDRESS,
    EZLOGIC_HEADING_INSTALLATION_ADDRESS,
    EZLOGIC_HEADING_MAILING_ADDRESS,
} from '../../../../../constants/language_tokens';

const AccountForm = ({ isSecurityENV }) => {
    const { handleSubmit, values } = useFormikContext();
    const { t } = useTranslate();

    return (
        <Form className={formContainer} onSubmit={handleSubmit} autoComplete="off">
            <FieldArray name="Installations">
                <>
                    {values.Installations.map((installation, index) => (
                        <AddressSection
                            title={t(EZLOGIC_HEADING_INSTALLATION_ADDRESS)}
                            addressName={`Installations.${index}`}
                            key={index}
                            isDisabled={isSecurityENV}
                        />
                    ))}
                </>
            </FieldArray>

            {isSecurityENV && (
                <>
                    <AccordionAddressSection title={t(EZLOGIC_HEADING_BILLING_ADDRESS)} addressName="Billing" />

                    <AccordionAddressSection title={t(EZLOGIC_HEADING_MAILING_ADDRESS)} addressName="Mailing" />
                </>
            )}

            <SaveButton />
        </Form>
    );
};

export default AccountForm;
