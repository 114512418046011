import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { LinearProgress } from '@material-ui/core';

import MeshBotNameBlock from '../../MeshBot/MeshBotNameBlock';
import { CloudMeshbotFormTriggers } from './CloudMeshBotFormParts/CloudMeshbotFormTriggers';
import { CloudMeshbotFormAction } from './CloudMeshBotFormParts/CloudMeshbotFormActions';
import MeshBotWrapper from '../../MeshBot/MeshBotWrapper';
import MeshBotContent from '../../MeshBot/MeshBotContent';
import { Header } from '../../../../../components';
import {
    convertCloudMeshbotTriggersStructureToUI,
    convertDateAndTimeCloudMeshbotTriggersStructureToUI,
    convertCloudMeshbotActionsStructureToUI,
    convertCloudMeshbotNestedTriggersStructureToUI,
    convertNucalCloudMeshbotTriggersStructureToUI,
} from './convertCloudMeshbotStructureToUI';
import useUnsavedChangesWarning from '../../MeshBot/CustomHooks/useUnsavedChangesWarning';
import { apiSubscribeToCallBackForTrigger } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import {
    OPERATOR_AND,
    OPERATOR_OR,
    SET_MESHBOT,
    SCHEDULE,
    OPERATOR_NOT,
    SINGLE,
    FAIL,
    INDEX_OF_ZERO,
    MESHBOT_TIMEOUT,
} from '../../../../../constants/MeshbotConstant';
import { CLOUD_MESHBOT_CREATING_PAGE } from '../../../../../constants/ActionConfirmDialog';
import { EZLO_MESHBOTS } from '../../../../../constants/URLs';
import { createMeshSceneRequestPayload } from './createMeshSceneRequestPayload';
import { filterAbstractOptions } from '../../../utils';
import {
    checkIsActionChanged,
    isEditActions,
    isValidActions,
    isValidate,
    notValidActions,
    notValidTriggers,
    getEditTriggers,
    checkIfNucalTrigger,
    getNucalSubscriptionErrorText,
} from '../../utils';
import { SubscriptionActions } from '../../../../../actions';

import style from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED } from '../../../../../constants/language_tokens';
import { TOAST_MESSAGE_ON_ERROR } from '../../../../../constants/toasts';
import { CLOUD } from '../../constant';
import { MESHBOT_LABELS } from '../../../../../services/kvs';

// TODO: bad naming -> MeshBotCloudForm
const MeshBotCloudCreate = (props) => {
    const {
        ruleCloudTriggers,
        abstractsDevice,
        capabilities,
        // TODO rename ruleCloudAction to ruleCloudActions in redux
        ruleCloudAction: ruleCloudActions,
        abstracts,
        MeshBotAction,
        CloudMeshbotActions,
        abstractsCapabilities,
        cloudMeshbotName,
        currentScene,
        NotificationActions,
        channels,
        users,
        uuids,
        notificationType,
        parentTriggerOptionType,
        MainAction,
        isDirty,
        isDuplicate,
        integrationsWithCallBack,
    } = props;
    const [listAbstractsDevice, setAbstractsDevice] = useState([]);
    const [listCapabilities, setListCapabilities] = useState([]);
    const [, setErrors] = useState({});
    const [, setAbstractDeviceTriggers] = useState([]);
    const [, setAbstractDeviceAction] = useState([]);
    const cloudMeta = useSelector((state) => state.meshBot.cloud.meta);
    const isCloudLoading = useSelector((state) => state.meshBot.cloud.isLoading);
    const history = useHistory();
    const { id: sceneUuid } = useParams();
    const { t } = useTranslate();
    const setDirty = useUnsavedChangesWarning();
    const [blockTriggerField] = ruleCloudTriggers;
    const [blockActionField] = ruleCloudActions;
    const dispatch = useDispatch();
    const { cloudMetaLabels, labels } = useSelector(({ meshBot, kvs }) => {
        return { cloudMetaLabels: meshBot.cloud.meta.labels, labels: kvs[MESHBOT_LABELS] };
    });

    useEffect(() => {
        if (capabilities !== listCapabilities) {
            setListCapabilities(capabilities);
        }
    }, [capabilities]);

    useEffect(() => {
        if (users && users.length === 0) {
            NotificationActions.getUsersData();
        }

        if (channels && channels.length === 0) {
            NotificationActions.getChannelsData();
        }

        NotificationActions.getDashboardsList();
    }, []);

    useEffect(() => {
        if (abstractsDevice !== listAbstractsDevice) {
            setAbstractsDevice(abstractsDevice);
            sortAbstractDevice(abstractsDevice);
        }
    }, [abstractsDevice, listAbstractsDevice]);

    useEffect(() => {
        const { triggers, actions, meta } = props.currentScene;
        const setCloudMeshbotForEdit = (actions, triggers, parentOperator) => {
            CloudMeshbotActions.setCloudTriggersForEdit(triggers, parentOperator);
            CloudMeshbotActions.setCloudActionsForEdit(actions);
            CloudMeshbotActions.setCloudMeshBotName(cloudMeshbotName);
            // Setting Meshbots labels in Redux store for editing changes.
            MeshBotAction.setInitialCloudMeshBotLabels(meta);
        };

        if (Object.keys(props.currentScene).length) {
            const isNucalTrigger = checkIfNucalTrigger(triggers);

            const isDateAndTime =
                triggers.name === OPERATOR_NOT
                    ? triggers?.parameters?.[INDEX_OF_ZERO].name === SCHEDULE
                    : triggers?.name === SCHEDULE;

            if (isNucalTrigger) {
                const triggersForUI = convertNucalCloudMeshbotTriggersStructureToUI(triggers, SINGLE);
                const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                    actions,
                    abstracts,
                    Object.values(abstractsCapabilities),
                );

                setCloudMeshbotForEdit(actionsForUI, triggersForUI);
            } else if (isDateAndTime) {
                const triggersForUI = convertDateAndTimeCloudMeshbotTriggersStructureToUI(triggers);

                const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                    actions,
                    abstracts,
                    Object.values(abstractsCapabilities),
                );

                setCloudMeshbotForEdit(actionsForUI, triggersForUI);
            } else {
                const triggersForUI =
                    triggers.name === OPERATOR_OR || triggers.name === OPERATOR_AND
                        ? convertCloudMeshbotNestedTriggersStructureToUI(
                              triggers,
                              abstracts,
                              Object.values(abstractsCapabilities),
                          )
                        : convertCloudMeshbotTriggersStructureToUI(
                              triggers,
                              abstracts,
                              Object.values(abstractsCapabilities),
                          );
                const actionsForUI = convertCloudMeshbotActionsStructureToUI(
                    actions,
                    abstracts,
                    Object.values(abstractsCapabilities),
                );

                setCloudMeshbotForEdit(actionsForUI, triggersForUI, triggers.name);
            }
        }
    }, [CloudMeshbotActions, abstracts, abstractsCapabilities, currentScene.name, integrationsWithCallBack]);

    useEffect(() => {
        const fetchSceneForEdit = async () => {
            const { getOneMeshScene } = props.MesheneActions;

            await getOneMeshScene(sceneUuid);
        };

        if (sceneUuid) {
            fetchSceneForEdit();
        }
    }, [sceneUuid]);

    useEffect(() => {
        return () => CloudMeshbotActions.clearCloudCurrentScene();
    }, []);

    useEffect(() => {
        MainAction.setStatePageBeingEdited(CLOUD_MESHBOT_CREATING_PAGE);

        return () => MainAction.setStatePageBeingEdited({});
    }, []);

    useEffect(() => {
        if (!cloudMeshbotName && !blockTriggerField && !blockActionField) {
            setDirty(false);
            MainAction.setConfirmationUser(false);
        } else if (cloudMeshbotName || blockTriggerField || blockActionField) {
            if (!isDirty) {
                setDirty(true);
                MainAction.setConfirmationUser(true);
            }
        }
    }, [cloudMeshbotName, blockTriggerField, blockActionField]);

    const saveCloudMeshBot = async () => {
        const { setMeshScene, getPromiseWithTimeout } = props.MesheneActions;
        const meshScenePayload = createMeshSceneRequestPayload({
            name: cloudMeshbotName,
            optionType: parentTriggerOptionType, // TODO add option type to cloud meshbot
            triggers: ruleCloudTriggers,
            actions: ruleCloudActions,
            sceneUuid,
            notificationType,
            cloudMeta,
            isDuplicate,
            metaLabels: cloudMetaLabels,
        });

        const triggersWithNucalSubscriptionRequest = await Promise.all(
            ruleCloudTriggers.map(async (item) => {
                if (item?.subscription) {
                    const subscriptionResponse = await apiSubscribeToCallBackForTrigger(item.subscription);
                    if (subscriptionResponse.status !== FAIL) {
                        dispatch(SubscriptionActions.setSubscriptionInKvs(item.subscription));
                    }

                    return subscriptionResponse;
                }
            }),
        );

        const triggersWithNucalSubscription = triggersWithNucalSubscriptionRequest.filter((item) => !!item);
        const triggersWithFailedNucalSubscription = triggersWithNucalSubscription.filter(
            (item) => item.status === FAIL,
        );

        if (triggersWithNucalSubscription) {
            if (_.isEmpty(triggersWithFailedNucalSubscription)) {
                await setMeshScene(meshScenePayload, SET_MESHBOT);
                await getPromiseWithTimeout(MESHBOT_TIMEOUT);

                toast(t(EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED), { type: TOAST_TYPE.SUCCESS });
                history.push(EZLO_MESHBOTS);
            } else {
                const errorMessage = getNucalSubscriptionErrorText(triggersWithFailedNucalSubscription);
                toast(errorMessage || t(TOAST_MESSAGE_ON_ERROR), { type: TOAST_TYPE.ERROR });
            }
        } else {
            await setMeshScene(meshScenePayload, SET_MESHBOT);
            toast(t(EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED), { type: TOAST_TYPE.SUCCESS });
            history.push(EZLO_MESHBOTS);
        }
    };

    const sortAbstractDevice = (data) => {
        const isDeviceTrigger = (lastItem) => {
            return lastItem !== 'command' && lastItem !== 'run';
        };

        const isDeviceAction = (lastItem) => {
            return lastItem === 'command' || lastItem === 'run';
        };

        const getDevices = (isAppropriateLastItem) => {
            return _.cloneDeep(data).map((item) => {
                item.capabilities = item.capabilities.filter((name) => {
                    const lastItem = name.split('_').pop();

                    return isAppropriateLastItem(lastItem);
                });

                return item;
            });
        };

        const newDevTrigger = getDevices(isDeviceTrigger);
        const newDevAction = getDevices(isDeviceAction);

        setAbstractDeviceTriggers(newDevTrigger);
        setAbstractDeviceAction(newDevAction);
    };

    const handlerSetError = useCallback((errors) => {
        setErrors(errors);
    }, []);

    const handleChangeCloudMeshbotName = (meshbotName) => {
        CloudMeshbotActions.setCloudMeshBotName(meshbotName);
    };

    const isEditedTriggerArray = getEditTriggers(ruleCloudTriggers);

    const notValidTriggerArray = notValidTriggers(ruleCloudTriggers);

    const notValidActionArray = notValidActions(ruleCloudActions);

    const isEditedActionArray = isEditActions(ruleCloudActions);

    const isRuleCloudTriggerEmpty = ruleCloudTriggers.length === 0;

    const isValidOrChanged = isValidate(
        cloudMeshbotName,
        isRuleCloudTriggerEmpty,
        notValidTriggerArray,
        currentScene,
        isEditedTriggerArray,
        ruleCloudTriggers,
        parentTriggerOptionType,
        ruleCloudActions,
        isValidActions(ruleCloudActions),
        checkIsActionChanged(ruleCloudActions, currentScene.actions),
        notValidActionArray,
        isEditedActionArray,
        labels,
        cloudMeta,
    );

    return (
        <>
            <Header />
            {isCloudLoading && <LinearProgress className={style.progressBar} />}
            <MeshBotWrapper>
                <MeshBotNameBlock
                    value={cloudMeshbotName}
                    title={cloudMeshbotName}
                    onChange={(e) => handleChangeCloudMeshbotName(e.target.value)}
                />

                <MeshBotContent
                    isDisabledSaveButton={isValidOrChanged || isCloudLoading}
                    onClickSaveButton={saveCloudMeshBot}
                    type={CLOUD}
                >
                    <CloudMeshbotFormTriggers
                        listCapabilities={listCapabilities}
                        abstractDeviceTriggers={filterAbstractOptions(abstracts)}
                        ruleCloudTriggers={ruleCloudTriggers}
                        parentTriggerOptionType={parentTriggerOptionType}
                        CloudMeshbotActions={CloudMeshbotActions}
                        currentScene={currentScene}
                        handlerSetError={handlerSetError}
                        notificationType={notificationType}
                    />

                    <CloudMeshbotFormAction
                        abstractDeviceActions={filterAbstractOptions(abstracts)}
                        abstractsCapabilities={abstractsCapabilities}
                        ruleCloudActions={ruleCloudActions}
                        CloudMeshbotActions={CloudMeshbotActions}
                        currentScene={currentScene}
                        channels={channels}
                        usersData={uuids}
                        notificationType={notificationType}
                        handlerSetError={handlerSetError}
                    />
                </MeshBotContent>
            </MeshBotWrapper>
        </>
    );
};

export default MeshBotCloudCreate;
