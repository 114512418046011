import {
    PK_COUNTRIES_OF_COUNTRIES_WITH_ZIP,
    PK_COUNTRY,
    PK_COUNTRY_LIST_OF_ALLOWED_COUNTRIES,
} from '../../../../constants/AccountInfo';

const getCountry = (countryCode, countriesList) => {
    return countriesList.find(({ PK_Country }) => Number(PK_Country) === Number(countryCode));
};

const getAddressFields = (address, countries) => {
    return {
        Address1: address?.Address1 || '',
        Address2: address?.Address2 || '',
        City: address?.City || '',
        FirstName: address?.FirstName || '',
        LastName: address?.LastName || '',
        Country: address?.PK_Country ? getCountry(address?.PK_Country, countries) || '' : '',
        PostalCode: address?.PostalCode || '',
        State: address?.State || '',
    };
};

export const getInitialValues = (installations, countries, mailingBillingAddresses) => {
    const initialValues = {
        Installations: installations.map((installation) => ({
            ...getAddressFields(installation, countries),
            PK_Installation: installation.PK_Installation,
        })),
    };

    if (mailingBillingAddresses) {
        initialValues.Billing = getAddressFields(mailingBillingAddresses.Billing, countries);
        initialValues.Mailing = getAddressFields(mailingBillingAddresses.Mailing, countries);
    }

    return initialValues;
};

export const getRequestAddressData = (address) => {
    const addressData = {
        ...address,
        City: address.City.Name,
        State: address.State.Name,
        PK_Country: Number(address.Country.PK_Country),
    };
    delete addressData.Country;

    return addressData;
};

const getAlphabeticallySortedCountries = (countries) => {
    return countries.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });
};
/**
 * Sort countries.
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} sorted list of countries
 * */
const sortCountries = (countriesList) => {
    const newCountryList = [];
    // Sort alphabetically.
    const sortCountries = getAlphabeticallySortedCountries(countriesList);
    // Put USA and Canada at the top of the list of countries.
    sortCountries.forEach((country) => {
        if (country.PK_Country === PK_COUNTRY.CANADA || country.PK_Country === PK_COUNTRY.USA) {
            newCountryList.unshift(country);
        } else {
            newCountryList.push(country);
        }
    });

    return newCountryList;
};

/**
 * Filters countries.
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} filtered list of countries
 * */
const filterCountries = (countriesList) => {
    return countriesList.filter((country) => PK_COUNTRY_LIST_OF_ALLOWED_COUNTRIES.includes(country.PK_Country));
};
/**
 * Filter and sort countries
 * @param {Object[]} countriesList - List of countries
 * @returns {Object[]} filtered and sorted countries
 * */
export const filterAndSortCountries = (countriesList) => {
    const filteredCountries = filterCountries(countriesList);

    return sortCountries(filteredCountries);
};

/**
 * Determine if postalcode is required for a country using the country's PK_Country
 * @param {Number} PK_Country - PK_Country
 * @returns {Boolean} return true if the required postalcode
 * */
export const getIsPostalCodeRequired = (PK_Country) => {
    if (!PK_Country) {
        return false;
    }

    return PK_COUNTRIES_OF_COUNTRIES_WITH_ZIP.includes(PK_Country);
};
