import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { LabelOutlined } from '@material-ui/icons';

import { EZLOGIC_TITLE_LABEL_AS } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';

import styles from './styles.module.scss';

const LabelSelectedButton = ({ onButtonClick, anchorRef }) => {
    const { t } = useTranslate();
    const title = t(EZLOGIC_TITLE_LABEL_AS);

    return (
        <Tooltip title={title}>
            <Button onClick={onButtonClick} ref={anchorRef} color="primary" size="small">
                <LabelOutlined className={styles.labelIcon} color="primary" />
                {title}
            </Button>
        </Tooltip>
    );
};

export default LabelSelectedButton;
