import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { MenuItem, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
    select: {
        '& ul': {
            padding: '4px',
            width: '356px', // based on select width
        },
        '& li': {
            width: '100%',
            fontSize: 16,
            fontFamily: 'Ezlo Sans',
            margin: '0',
            height: '56px',
            padding: '4px 4px 4px 20px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& li:hover': {
            backgroundColor: 'rgba(144, 160, 175, 0.15)',
            borderRadius: '16px',
        },
        '& .Mui-selected': {
            backgroundColor: 'rgba(144, 160, 175, 0.15)',
            borderRadius: '16px',
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(144, 160, 175, 0.15)',
            borderRadius: '16px',
        },
    },
});

const ControllerGroupsSelect = (props) => {
    const classes = useStyles();

    const onMenuItemClick = (e) => {
        const { onSelectGroup } = props;
        const groupStr = e.target.value;
        onSelectGroup(JSON.parse(groupStr));
    };

    const getSelectContent = () => {
        const { groups } = props;

        return Object.entries(groups)
            .reverse()
            .map(([key, group]) => {
                return (
                    <MenuItem key={key} value={JSON.stringify(group)}>
                        {group.name}
                    </MenuItem>
                );
            });
    };

    const { selectedGroup, groups, className } = props;
    const isGroupsAvailable = groups && Object.keys(groups).length > 0;

    return (
        isGroupsAvailable && (
            <Select
                labelId="group_selector_label"
                id="group_selector"
                variant="outlined"
                MenuProps={{
                    classes: {
                        paper: classes.select,
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                value={JSON.stringify(selectedGroup)}
                onChange={onMenuItemClick}
                className={classNames('controller-groups-select', { [className]: className })}
            >
                {getSelectContent()}
            </Select>
        )
    );
};

ControllerGroupsSelect.propTypes = {
    groups: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object,
    onSelectGroup: PropTypes.func.isRequired,
};

export default ControllerGroupsSelect;
