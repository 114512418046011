import React from 'react';
import { Modal } from '@material-ui/core';

import { IntegrationsGrid } from './IntegrationsGrid';

export const CreateEnrollmentModal = ({ open, handleClose, abstracts, onFetchInitialInfo }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <IntegrationsGrid
                abstracts={abstracts}
                onFetchInitialInfo={onFetchInitialInfo}
                onHandleClose={handleClose}
            />
        </Modal>
    );
};
