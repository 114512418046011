import React from 'react';
import { useFormikContext } from 'formik';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import InfoPopper from '../InfoPopper';
import { optionsSelect } from '../../../../../constants/MeshbotConstant';
import { ACCOUNT_TYPES } from '../../../../../constants/Users';

import styles from '../../UserManagement/style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { ArrowDropDown } from '../../../../../assets/icons';

const AccountTypeSelectWithInfo = ({ name, label, required }) => {
    const { values, touched, errors, handleChange } = useFormikContext();
    const { t } = useTranslate();

    return (
        <FormControl
            className={styles.selectField}
            fullWidth
            size="small"
            error={touched[name] && Boolean(errors[name])}
            required={required}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={values[name]}
                name={name}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
                MenuProps={optionsSelect}
            >
                {ACCOUNT_TYPES.map((type) => (
                    <MenuItem key={type.name} value={type}>
                        {t(type.name)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{touched[name] && errors[name]}</FormHelperText>

            <InfoPopper label={label} selectedAccountType={values[name]} />
        </FormControl>
    );
};

export default AccountTypeSelectWithInfo;
