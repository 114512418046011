import React from 'react';
import at from '../../../../../constants/ActionTypes/MeshBot';
import { getInitialValuesForDynamicFields } from './utils';
import DynamicFieldsBlock from './DynamicFieldsBlock';
import ActionDelay from '../../../../../components/ActionDelay';

const DynamicFieldsBlockForActionBlock = (props) => {
    const {
        id,
        type,
        blocks,
        firstBlock,
        currentRule,
        interfaceItem,
        isShowedDelay,
        actionBlockName,
        selectedFieldTrigger,
        onUpdateFieldActionBlock,
        onUpdateActionDelay,
        onChangeDelay,
        onSetIsShowedDelay,
    } = props;

    const getValuesFields = (fields) => {
        let values = null;
        fields.forEach((item) => {
            if (item.name === 'value') {
                !!item.value
                    ? (values = item.value)
                    : (values = getInitialValuesForDynamicFields(interfaceItem.inputs));
            }
        });

        return values;
    };

    return blocks.map((item) => {
        if (type !== at.CLOUD && item._tempId === firstBlock) {
            return (
                <React.Fragment key={item._tempId}>
                    <ActionDelay
                        id={id}
                        delayDisabled={false}
                        type={selectedFieldTrigger}
                        actionBlockName={actionBlockName}
                        currentRule={currentRule}
                        isShowedDelay={isShowedDelay}
                        onUpdateActionDelay={onUpdateActionDelay}
                        onChangeDelay={onChangeDelay}
                        onSetIsShowedDelay={onSetIsShowedDelay}
                    />
                    <DynamicFieldsBlock
                        key={item._tempId}
                        actionBlockName={actionBlockName}
                        _tempId={item._tempId}
                        idDevice={id}
                        values={getValuesFields(item.fields)}
                        handleUpdateFieldActionBlock={onUpdateFieldActionBlock}
                        interfaceInputs={interfaceItem.inputs}
                        description={interfaceItem.ui.description}
                    />
                </React.Fragment>
            );
        }
    });
};

export default DynamicFieldsBlockForActionBlock;
