import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { IntegrationList } from './IntegrationList';
import OpenButton from '../OpenButton';
import SearchPopover from '../../../../../../../../../components/SearchPopover';

import { getParjectNamesToFilter } from '../../utils';
import { NOT_REPRESENTED_INDEX } from '../../paas-constants';

import styles from './Integrations.module.scss';
import { useTranslate } from '../../../../../../../../../features/languages';
import {
    EZLOGIC_LABEL_EXPAND,
    EZLOGIC_PLACEHOLDER_SELECT_NAME,
    EZLOGIC_TITLE_SELECT_SERVICE,
} from '../../../../../../../../../constants/language_tokens';
import ExpandArrowUp from '../../../../../../../../../assets/icons/visuals/generalIcons/ExpandArrowUp/ExpandArrowUp';

export const Integrations = (props) => {
    const { onChangeIntegration, foundIntegration, integrationsList } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const [filter, setFilter] = useState('');
    const [filteredIntegrations, setFilteredIntegrations] = useState([]);
    const { t } = useTranslate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const parjectNamesToFilter = getParjectNamesToFilter();
        const filteredIntegrations = integrationsList?.filter(({ name }) => {
            const isIntegrationHaveAllowedName = !parjectNamesToFilter.includes(name);
            const isIntegrationShouldBeFiltered =
                name.toLowerCase().indexOf(filter.toLowerCase()) > NOT_REPRESENTED_INDEX;

            return isIntegrationShouldBeFiltered && isIntegrationHaveAllowedName;
        });

        setFilteredIntegrations(filteredIntegrations);
    }, [filter, integrationsList]);

    const capabilitiesList = [
        ...new Set(filteredIntegrations?.reduce((acc, item) => [...acc, ...item.capabilities], [])),
    ];

    const groupList = capabilitiesList.map((capability) =>
        filteredIntegrations
            .filter((integration) => integration.capabilities.includes(capability))
            .reduce(
                (result, integration) => {
                    result['name'] = capability;
                    result['values'].push(integration);

                    return result;
                },
                { name: '', values: [] },
            ),
    );

    const handleParjectClick = (uuid) => {
        handleClose();
        onChangeIntegration(uuid);
    };

    return (
        <>
            <OpenButton
                title={foundIntegration.name}
                onHandleClick={handleClick}
                icon={foundIntegration.icon}
                defaultTitle={t(EZLOGIC_TITLE_SELECT_SERVICE)}
            />

            <SearchPopover
                placeholder={t(EZLOGIC_PLACEHOLDER_SELECT_NAME)}
                anchor={anchorEl}
                onChangeFilter={setFilter}
                handleClose={handleClose}
                size="large"
            >
                <div className={styles.container}>
                    {groupList.map(({ name, values }) => (
                        <Accordion key={name} className={styles.accordion} defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandArrowUp className={styles.expanded_icon} />}
                                aria-label={t(EZLOGIC_LABEL_EXPAND)}
                                className={styles.accordion__summary}
                            >
                                <div className={styles.accordion_title}>{name}</div>
                            </AccordionSummary>
                            <AccordionDetails className={styles.accordion__details}>
                                <IntegrationList list={values} onParjectClick={handleParjectClick} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </SearchPopover>
        </>
    );
};
