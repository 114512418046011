import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoizeOne from 'memoize-one';
import { prepareDevices, prepareRooms, getDevicesByControllers } from './utils';
import * as Actions from '../../../actions';
import DebouncedWebSocketComponent from './DebouncedWebSocketComponent';

const memoizedPrepareDevices = memoizeOne(prepareDevices);
const memoizedPrepareRooms = memoizeOne(prepareRooms);

const mapStateToProps = (state) => ({
    devices: memoizedPrepareDevices(state.ezlo.data),
    rooms: memoizedPrepareRooms(state.ezlo.data),
    isShowModalDevice: state.main.isShowModalDevice,
    errors: state.deviceSettings.errors,
    devicesContainer: getDevicesByControllers(state.ezlo.data),
    isConnected: state.ezlo.isConnected,
});

const mapDispatchToProps = (dispatch) => ({
    MainAction: bindActionCreators(Actions.MainAction, dispatch),
    DeviceActions: bindActionCreators(Actions.DeviceActions, dispatch),
    EzloActions: bindActionCreators(Actions.EzloActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebouncedWebSocketComponent);
