import React from 'react';

import TimeIntervalsSelect from './TimeIntervalsSelect';
import { useSelector } from 'react-redux';
import { mediaStoreSelector } from '../../utils';
import useGalleryContext from '../../hooks/useGalleryContext';

const TimeIntervalsSelectContainer = () => {
    const { selectedTimeInterval, handlerChangeTimeInterval } = useGalleryContext();
    const { isLoading } = useSelector(mediaStoreSelector);

    return (
        <TimeIntervalsSelect
            value={selectedTimeInterval}
            onChangeSelect={handlerChangeTimeInterval}
            disabled={isLoading}
        />
    );
};

export default TimeIntervalsSelectContainer;
