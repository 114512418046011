import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TreeItem } from '@material-ui/lab';

import GeneralTreeItem from '../GeneralTreeItem';
import DeviceGroupCategoryLabelContainer from '../../../DeviceGroupCategoryLabelContainer';

import { SUBCATEGORY_ID, CATEGORY_ID } from '../../../../../../../constants/DevicesGroups';

import DeviceGroupsActions from '../../../../../../../actions/DeviceGroupsActions';
import { getTreeElementsStateSubcategoryOptions, getMenuButtonState } from '../../../../utils';

const DeviceGroupSubcategory = (props) => {
    const { payload, categoryId } = props;
    const dispatch = useDispatch();
    const parentChainIds = {
        [CATEGORY_ID]: categoryId,
        [SUBCATEGORY_ID]: payload.id,
    };

    const categoryState = useSelector(
        (state) => state.deviceGroups.deviceGroupTreeState?.categories[categoryId]?._ui.state,
    );
    const buttonState = getMenuButtonState(payload._ui.state);

    const handleChangeSubcategoryState = (newSubcategoryState) => {
        dispatch(
            DeviceGroupsActions.updateDeviceGroupTreeStateBySubcategory({
                newSubcategoryState,
                categoryId: parentChainIds.categoryId,
                subcategoryId: parentChainIds.subcategoryId,
            }),
        );
    };

    return (
        <TreeItem
            label={
                <DeviceGroupCategoryLabelContainer
                    value={payload?._ui?.state}
                    labelName={payload?.subcategory_name}
                    onChange={handleChangeSubcategoryState}
                    options={getTreeElementsStateSubcategoryOptions(categoryState)}
                    buttonState={buttonState}
                />
            }
            nodeId={payload?.subcategory_name}
        >
            {payload?.devices &&
                Object.entries(payload?.devices).map(([name, payload]) => (
                    <GeneralTreeItem payload={payload} parentChainIds={parentChainIds} key={name} />
                ))}
        </TreeItem>
    );
};

export default DeviceGroupSubcategory;
