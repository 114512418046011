import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../SelectComponent';
import DeviceCurrentSetting from '../../../../../components/DeviceCurrentSetting';

import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_HINT_CAPABILITY, EZLOGIC_TITLE_CAPABILITY } from '../../../../../constants/language_tokens';

const SelectDeviceCapability = ({ firstBlock, onAddActionBlock, idDev, blocks, currentDeviceAction, id, items }) => {
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={firstBlock ? firstBlock : ''}
            label={t(EZLOGIC_TITLE_CAPABILITY)}
            info={t(EZLOGIC_HINT_CAPABILITY)}
            onChange={(e) => {
                onAddActionBlock(e.target.value);
            }}
            deviceSetting={
                idDev &&
                idDev !== '' && (
                    <DeviceCurrentSetting
                        firstBlock={firstBlock}
                        id={id}
                        items={items}
                        idDev={idDev}
                        blocks={blocks}
                        currentDevice={currentDeviceAction}
                    />
                )
            }
        >
            {blocks.map((item) => {
                return (
                    <MenuItem key={item._tempId} value={item._tempId}>
                        {item.label.text}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default SelectDeviceCapability;
