import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_PASSWORD_REQUIREMENTS } from '../../constants/language_tokens';

import styles from './style.module.scss';

const PasswordRequirements = ({ passwordRequirements, isTitle, getClassName }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.passwordRequirementsBox}>
            {isTitle && <div className={styles.title}>{t(EZLOGIC_TITLE_PASSWORD_REQUIREMENTS)}</div>}
            {passwordRequirements.map((requirement) => {
                const className = getClassName(requirement);

                return (
                    <div key={requirement.key} className={`${styles.requirementItemBox} ${className}`}>
                        <CheckIcon className={styles.itemIcon} fontSize="large" color="primary" />
                        <Typography color={className ? 'primary' : 'initial'} className={styles.requirementText}>
                            {t(requirement.requirementText)}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

PasswordRequirements.propTypes = {
    isTitle: PropTypes.bool,
    passwordRequirements: PropTypes.array,
    getClassName: PropTypes.func,
};

PasswordRequirements.defaultProps = {
    isTitle: true,
};

export default PasswordRequirements;
