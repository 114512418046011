import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button } from '@material-ui/core';

import Input from '../../../../../components/InputMaterial';
import ActionDelay from '../../../../../components/ActionDelay';

import { luaPoints, IMMEDIATELY, SELECTED_CODE } from '../../../../../constants/MeshbotConstant';
import { info } from '../../utils';
import { LUA_SCRIPT_NAME_INPUT_MAX_LENGTH, NEW_SCRIPT } from '../../../../../constants/ScriptConstants';

import styles from './LuaScript.module.scss';
import SelectComponent from '../SelectComponent';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_EDIT_SCRIPT,
    EZLOGIC_BUTTON_SAVE_SCRIPT,
    EZLOGIC_LABEL_LUA_CODE,
    EZLOGIC_TITLE_NAME_SCRIPT,
    EZLOGIC_TITLE_PUT_SCRIPT_BODY,
    EZLOGIC_TITLE_TURN_OFF_ALL_DEVICES,
} from '../../../../../constants/language_tokens';

const LuaScript = (props) => {
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [initialCode, setInitialCode] = useState('');
    const [delayDisabled, setDelayDisabled] = useState(true);
    const [isDisabledEditScriptButton, setIsDisabledEditScriptButton] = useState(true);
    const [isDisabledSaveScriptButton, setIsDisabledSaveScriptButton] = useState(false);
    const { t } = useTranslate();

    const {
        id,
        serial,
        selectedPoint,
        selectedName,
        selectedCode,
        selectedFieldTrigger,
        isShowedDelay,
        currentRule,
        blocks,
        actionBlockName,
        listLuaScripts,
        isEditing,
        MeshBotAction,
        onUpdateActionDelay,
        onHandlerSetActionField,
        onChangeDelay,
        onSetIsShowedDelay,
    } = props;

    useEffect(() => {
        if (isEditing) {
            const idScript = blocks[0]?.fields[0].value;
            MeshBotAction.getLuaScript(serial, { _id: idScript }, id, actionBlockName);
        }
    }, []);

    useEffect(() => {
        if (blocks.length === 0) {
            onSetIsShowedDelay(IMMEDIATELY);
        }
        setDelayDisabled(blocks.length === 0 && true);
    }, [blocks]);

    useEffect(() => {
        if (selectedName !== name) {
            setName(selectedName);
        }
    }, [selectedName]);

    useEffect(() => {
        if (code !== selectedCode) {
            setCode(selectedCode);
            setIsDisabledEditScriptButton(true);
        }
    }, [selectedCode]);

    useEffect(() => {
        setInitialCode(selectedCode);
    }, [name]);

    useEffect(() => {
        if (selectedPoint === NEW_SCRIPT) {
            setIsDisabledSaveScriptButton(false);
        }
    }, [selectedPoint]);

    const handlerSaveScript = () => {
        const params = { name, code };

        MeshBotAction.addLuaScript(serial, params, id, actionBlockName);
        setIsDisabledSaveScriptButton(true);
    };

    const handlerEditScript = () => {
        const params = { _id: name, code };
        MeshBotAction.setLuaScript(serial, params);
        setIsDisabledEditScriptButton(true);
    };

    const handlerSelectScript = (e) => {
        MeshBotAction.getLuaScript(serial, { _id: e.target.value }, id, actionBlockName);
        onSetIsShowedDelay(IMMEDIATELY);
    };

    const handleChangeScript = (e) => {
        const { value } = e.target;

        if (initialCode !== value) {
            setIsDisabledEditScriptButton(false);
        } else {
            setIsDisabledEditScriptButton(true);
        }

        setCode(value);
        onHandlerSetActionField(value, SELECTED_CODE);
    };

    return (
        <>
            <SelectComponent
                value={selectedPoint ? selectedPoint : ''}
                label={info.hints.node}
                info={info.text.luaScriptNode}
                onChange={(e) => onHandlerSetActionField(e.target.value, 'selectedPoint')}
            >
                {luaPoints.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.name}>
                            {t(item.label)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
            {selectedPoint !== '' && (
                <>
                    {selectedPoint === NEW_SCRIPT && (
                        <>
                            <Input
                                label={t(EZLOGIC_TITLE_NAME_SCRIPT)}
                                placeholder={t(EZLOGIC_TITLE_TURN_OFF_ALL_DEVICES)}
                                maxLength={LUA_SCRIPT_NAME_INPUT_MAX_LENGTH}
                                multiline
                                onBlur={() => onHandlerSetActionField(name, 'selectedName')}
                                value={name ?? ''}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <ActionDelay
                                id={id}
                                type={selectedFieldTrigger}
                                actionBlockName={actionBlockName}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                delayDisabled={delayDisabled}
                                onUpdateActionDelay={onUpdateActionDelay}
                                onChangeDelay={onChangeDelay}
                                onSetIsShowedDelay={onSetIsShowedDelay}
                            />
                            <div className={styles.footer}>
                                <div className={styles.footer_group}>
                                    <div className={styles.text_area}>
                                        <Input
                                            label={t(EZLOGIC_LABEL_LUA_CODE)}
                                            placeholder={t(EZLOGIC_TITLE_PUT_SCRIPT_BODY)}
                                            multiline
                                            onBlur={() => onHandlerSetActionField(code, 'selectedCode')}
                                            value={code ?? ''}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>
                                    <Button
                                        className={styles.button}
                                        variant="contained"
                                        color="primary"
                                        disabled={!name || !code || isDisabledSaveScriptButton}
                                        onClick={handlerSaveScript}
                                    >
                                        {t(EZLOGIC_BUTTON_SAVE_SCRIPT)}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    {selectedPoint === 'existingScript' && (
                        <>
                            <SelectComponent
                                value={name ? name : ''}
                                label={info.hints.value}
                                info={info.text.value}
                                onChange={handlerSelectScript}
                            >
                                {listLuaScripts.map((item) => {
                                    return (
                                        <MenuItem key={item._id} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </SelectComponent>
                            <ActionDelay
                                id={id}
                                type={selectedFieldTrigger}
                                actionBlockName={actionBlockName}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                delayDisabled={delayDisabled}
                                onUpdateActionDelay={onUpdateActionDelay}
                                onChangeDelay={onChangeDelay}
                                onSetIsShowedDelay={onSetIsShowedDelay}
                            />
                            <div className={styles.footer}>
                                <div className={styles.footer_group}>
                                    <div className={styles.text_area}>
                                        <Input
                                            label={t(EZLOGIC_LABEL_LUA_CODE)}
                                            placeholder={t(EZLOGIC_TITLE_PUT_SCRIPT_BODY)}
                                            multiline
                                            value={code ?? ''}
                                            onChange={handleChangeScript}
                                        />
                                    </div>
                                    <Button
                                        className={styles.button}
                                        variant="contained"
                                        color="primary"
                                        disabled={isDisabledEditScriptButton}
                                        onClick={handlerEditScript}
                                    >
                                        {t(EZLOGIC_BUTTON_EDIT_SCRIPT)}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

LuaScript.propTypes = {
    id: PropTypes.string,
    serial: PropTypes.string,
    selectedPoint: PropTypes.string,
    selectedName: PropTypes.string,
    selectedCode: PropTypes.string,
    actionBlockName: PropTypes.string,
    selectedFieldTrigger: PropTypes.string,
    isEditing: PropTypes.bool,
    isShowedDelay: PropTypes.string,
    listLuaScripts: PropTypes.array,
    blocks: PropTypes.array,
    currentRule: PropTypes.object,
    MeshBotAction: PropTypes.object,
    onUpdateActionDelay: PropTypes.func,
    onHandlerSetActionField: PropTypes.func,
    onChangeDelay: PropTypes.func,
    onSetIsShowedDelay: PropTypes.func,
};

export default LuaScript;
