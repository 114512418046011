import * as yup from 'yup';

export const validationSchema = yup.object({
    CurrentPassword: yup.string().required('Password is required.'),
    NewPassword: yup
        .string()
        .notOneOf([yup.ref('CurrentPassword'), null], "New password mustn't be the same as the current password.")
        .min(8, 'Password should be of minimum 8 characters length.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
            'At least one uppercase, lowercase, number and non-alphabetic character',
        )
        .required('Password is required.'),
    ConfirmPassword: yup
        .string()
        .oneOf([yup.ref('NewPassword'), null], 'Passwords must match.')
        .required('Confirm Password is required.'),
});
