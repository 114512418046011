import React from 'react';
import { Modal } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import classNames from 'classnames';
import { useTranslate } from '../../../languages';
import { IcControlsClose, IcWarningVisualsNotResponding } from '../../../../assets/icons';
import { EZLOGIC_TITLE_DISABLE, EZLOGIC_TITLE_INSTALLER_MESSAGE } from '../../../../constants/language_tokens';

import style from './style.module.scss';

const InstallerAccessDisabledModal = ({ open, onClose, onDisableButtonClick }) => {
    const { t } = useTranslate();

    return (
        <Modal open={open} onClose={onClose} className={style.modal}>
            <div className={classNames(style.modalContainer, style.modalContainerFailed)}>
                <p className={style.closeBtn}>
                    <span onClick={onClose}>
                        <IcControlsClose />
                    </span>
                </p>
                <IcWarningVisualsNotResponding />
                <p className={style.message}>{t(EZLOGIC_TITLE_INSTALLER_MESSAGE)}</p>
                <Button className={style.btn} onClick={onDisableButtonClick}>
                    {t(EZLOGIC_TITLE_DISABLE)}
                </Button>
            </div>
        </Modal>
    );
};

export default InstallerAccessDisabledModal;
