import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, InputAdornment, LinearProgress, TextField } from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { Header } from '../../../components';
import IntegrationsSection from './IntegrationsSection';
import NewEnrollmentPage from './NewEnrollmentPage/NewEnrollmentPage';
import UpdateIntegrationDialog from './UpdateIntegrationDialog';
import { CreateEnrollmentModal } from './NewEnrollmentPage/CreateEnrollmentModal';

import { INTEGRATION_BUTTON_TEXT, NUCAL_INTEGRATION_CONTENT, PARJECTS_PAGES } from './constants';
import {
    getActualIntegrations,
    getEnrolmentNumber,
    getParjectNamesToFilter,
} from '../EzloRule/EditForm/RuleSettings/components/PAAS/utils';

import styles from './EzloParjectsSettings.module.scss';
import { useTranslate } from '../../../features/languages';
import { EZLOGIC_SEARCH_TITLE } from '../../../constants/language_tokens';
import { CloseIcon, PlusIcon } from '../../../assets/icons';

const EzloParjectsSettings = (props) => {
    const { t } = useTranslate();
    const {
        detailedIntegrations,
        userIntegrations,
        isEnrollmentInProgress,
        isIntegrationsLoading,
        isAbstractsLoading,
        isAccountInfoLoading,
        abstracts,
    } = props.redux.state;

    const { linkTo } = props.redux.actions.GenericActions;
    const { fetchAbstractListDetailed, setUserIntegrations, setAccountsInfo } = props.redux.actions.IntegrationsActions;
    const { getAbstractsList } = props.redux.actions.MainAction;

    const fetchInitialInfo = async () => {
        await fetchAbstractListDetailed();
        await setUserIntegrations();
        await getAbstractsList();
    };

    const [editEnrollmentDialogState, setEditEnrollmentDialogState] = useState({
        isOpen: false,
        abstract: null,
    });

    const [searchInput, setSearchInput] = useState('');
    const [filteredUserIntegrations, setFilteredUserIntegrations] = useState([]);
    const isSearching = Boolean(searchInput);

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (userIntegrations) {
            const parjectsToFilter = getParjectNamesToFilter();

            setFilteredUserIntegrations(
                Object.values(userIntegrations)
                    .filter(({ name }) => !parjectsToFilter.includes(name))
                    .sort((a, b) => {
                        return getEnrolmentNumber(b.name) - getEnrolmentNumber(a.name);
                    }),
            );
        }
    }, [userIntegrations]);

    useEffect(() => {
        const { userIntegrations } = props.redux.state;
        if (userIntegrations) {
            const integrationsList = Object.values(userIntegrations);
            const userIntegrationsList = getActualIntegrations(integrationsList, searchInput);
            setFilteredUserIntegrations(userIntegrationsList);
        }
    }, [searchInput]);

    const clearSearch = () => {
        setSearchInput('');
    };

    useEffect(() => {
        const { fetchIntegrationsPageData } = props.redux.actions.IntegrationsActions;
        fetchIntegrationsPageData();
    }, []);

    const handleOpenEditEnrollmentDialog = (abstract) => {
        setEditEnrollmentDialogState({ isOpen: true, abstract });
    };

    const handleCloseEditEnrollmentDialog = () => {
        setEditEnrollmentDialogState({ isOpen: false, abstract: null });
    };

    const handleEditEnrollment = (payload) => {
        const { editIntegration } = props.redux.actions.IntegrationsActions;
        editIntegration(payload);
    };

    const isLoading = isEnrollmentInProgress || isIntegrationsLoading || isAbstractsLoading || isAccountInfoLoading;
    const isUserIntegrationsAvailable = userIntegrations && Object.entries(userIntegrations).length > 0;
    const isNewEnrollmentPage = props.location.pathname.includes(PARJECTS_PAGES.NEW) || !isUserIntegrationsAvailable;

    useEffect(() => {
        if (abstracts && abstracts.length) {
            setAccountsInfo();
        }
    }, [setAccountsInfo, abstracts]);

    return (
        <div className={styles.settingsIntegrations}>
            {isLoading && <LinearProgress className={styles.progressBar} />}

            <div className={styles.headerContainer}>
                <Header className={styles.header}>
                    <h1 className={styles.headerTitle}>{t(NUCAL_INTEGRATION_CONTENT.TITLE)}</h1>
                    <div className={styles.newEnrollmentBtnBox}>
                        <Button
                            className={styles.newEnrollmentBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleOpen}
                        >
                            <PlusIcon />
                            <span>{t(INTEGRATION_BUTTON_TEXT.ADD)}</span>
                        </Button>
                    </div>
                </Header>
            </div>
            {!isNewEnrollmentPage && (
                <>
                    <section className={styles.sectionContent}>
                        <TextField
                            autoFocus={true}
                            variant="outlined"
                            autoComplete="off"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon className={styles.searchTwoToneIcon} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {searchInput ? (
                                            <IconButton position="end" onClick={clearSearch}>
                                                <CloseIcon className={styles.closeIcon} />
                                            </IconButton>
                                        ) : null}
                                    </>
                                ),
                            }}
                            placeholder={t(EZLOGIC_SEARCH_TITLE)}
                            fullWidth
                            name="service name"
                            className={styles.inputBlock}
                        />
                        <IntegrationsSection
                            userIntegrations={filteredUserIntegrations}
                            detailedIntegrations={detailedIntegrations}
                            linkTo={linkTo}
                            onEdit={handleOpenEditEnrollmentDialog}
                            getAbstractsList={getAbstractsList}
                            setUserIntegrations={setUserIntegrations}
                            fetchAbstractListDetailed={fetchAbstractListDetailed}
                            isAbstractsLoading={isAbstractsLoading}
                            isSearching={isSearching}
                        />
                    </section>
                </>
            )}
            {isNewEnrollmentPage && !isLoading && <NewEnrollmentPage />}
            <UpdateIntegrationDialog
                open={editEnrollmentDialogState.isOpen}
                onClose={handleCloseEditEnrollmentDialog}
                onSubmit={handleEditEnrollment}
                abstract={editEnrollmentDialogState.abstract}
            />

            <CreateEnrollmentModal
                open={open}
                handleClose={handleClose}
                abstracts={abstracts}
                onFetchInitialInfo={fetchInitialInfo}
            />
        </div>
    );
};

export default EzloParjectsSettings;
