import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Header } from '../../../components';
import VirtualDevicesList from './VirtualDevicesList';
import { VirtualItemsErrorPlug } from './VirtualDevicesList/VirtualItemsErrorPlug/VirtualItemsErrorPlug';

import { VIRTUAL_DEVICE_PAGES } from './constants';
import { GET_VIRTUAL_DEVICE_PAGE_INTO_EZLO } from '../../../constants/URLs';
import { buildDeleteVirtualDeviceParams } from './utils';
// import EditVirtualDevicePage from './EditVirtualDevicePage';
// import CreateVirtualDevicePage from './CreateVirtualDevicePage';

import styles from './EzloVirtualContainer.module.scss';
import { useTranslate } from '../../../features/languages';
import {
    EZLOGIC_BUTTON_CREATE_NEW_CONTAINER,
    EZLOGIC_TITLE_VIRTUAL_CONTAINER,
} from '../../../constants/language_tokens';

const EzloVirtualContainer = (props) => {
    const history = useHistory();
    const { t } = useTranslate();
    useEffect(() => {
        const { fetchVirtualDeviceList } = props.redux.actions.VirtualContainerActions;
        fetchVirtualDeviceList();
    }, []);

    // const handleBackClick = () => {
    //     const { linkTo } = props.redux.actions.GenericActions;
    //     linkTo(GET_VIRTUAL_CONTAINER());
    // };

    // const handleCreateVirtualDeviceClick = () => {
    //     const { linkTo } = props.redux.actions.GenericActions;
    //     linkTo(GET_VIRTUAL_DEVICE_PAGE_INTO_EZLO(VIRTUAL_DEVICE_PAGES.CREATE));
    // };

    const handleDeleteClick = (virtualDevice) => {
        const { deleteVirtualDevice } = props.redux.actions.VirtualContainerActions;
        deleteVirtualDevice(buildDeleteVirtualDeviceParams(virtualDevice.abstract_uuid));
    };

    const handleEditClick = (virtualDevice) => {
        const { linkTo } = props.redux.actions.GenericActions;
        const { selectVirtualDevice } = props.redux.actions.VirtualContainerActions;
        selectVirtualDevice(virtualDevice);
        linkTo(GET_VIRTUAL_DEVICE_PAGE_INTO_EZLO(VIRTUAL_DEVICE_PAGES.EDIT));
    };

    const handleOpenClick = (virtualDevice) => {
        const { linkTo } = props.redux.actions.GenericActions;
        const { selectVirtualDevice } = props.redux.actions.VirtualContainerActions;
        selectVirtualDevice(virtualDevice);
        linkTo(GET_VIRTUAL_DEVICE_PAGE_INTO_EZLO(VIRTUAL_DEVICE_PAGES.MAP));
    };

    const navigaToCreateVirtualDeviceForm = () => {
        history.push('/ezlo/virtual-device/create');
    };

    const { virtualDeviceList, main } = props.redux.state;
    const { getAbstractCapabilities, getAbstractsList } = props.redux.actions.MainAction;

    const isCreateVirtualDevice = props.location.pathname.includes(VIRTUAL_DEVICE_PAGES.CREATE);
    const isEditVirtualDevice = props.location.pathname.includes(VIRTUAL_DEVICE_PAGES.EDIT);
    const isMapVirtualDevice = props.location.pathname.includes(VIRTUAL_DEVICE_PAGES.MAP);
    const { abstractCapabilities, abstractsList } = main;
    const { isAbstractsCapabilitiesLoading, capabilities } = abstractCapabilities;
    const { isAbstractsListLoading, abstracts } = abstractsList;
    const isVirtualDeviceDataEmpty = Boolean(Object.keys(capabilities || {})?.length === 0 || abstracts?.length === 0);

    if (isVirtualDeviceDataEmpty) {
        return (
            <VirtualItemsErrorPlug
                isAbstractsListLoading={isAbstractsListLoading}
                isAbstractsCapabilitiesLoading={isAbstractsCapabilitiesLoading}
                getAbstractCapabilities={getAbstractCapabilities}
                getAbstractsList={getAbstractsList}
            />
        );
    }

    return (
        <div className={styles.virtualContainerWrapper}>
            {!isCreateVirtualDevice && !isEditVirtualDevice && !isMapVirtualDevice && (
                <>
                    <Header headerClassName={styles.virtualContainerHeader}>
                        <h1 className={styles.virtualContainerTitle}>{t(EZLOGIC_TITLE_VIRTUAL_CONTAINER)}</h1>
                        <Button color="primary" variant="outlined" onClick={navigaToCreateVirtualDeviceForm}>
                            <Add />
                            {t(EZLOGIC_BUTTON_CREATE_NEW_CONTAINER)}
                        </Button>
                    </Header>
                    <VirtualDevicesList
                        virtualDeviceList={virtualDeviceList}
                        onDelete={handleDeleteClick}
                        onEdit={handleEditClick}
                        onOpen={handleOpenClick}
                    />
                </>
            )}
            {/* TODO: not completed */}
            {/*{*/}
            {/*    isCreateVirtualDevice &&*/}
            {/*    (*/}
            {/*        <CreateVirtualDevicePage onSubmit={createVirtualDevice}*/}
            {/*                                 devices={devices}*/}
            {/*                                 services={services}*/}
            {/*                                 goBack={handleBackClick}*/}
            {/*                                 textCommandOutputsMap={textCommandOutputsMap}*/}
            {/*                                 onTextCommandSubmit={submitTextCommand}*/}
            {/*                                 fetchAbstractListDetailed={fetchAbstractListDetailed}*/}
            {/*        />*/}
            {/*    )*/}
            {/*}*/}
            {/*{*/}
            {/*    isEditVirtualDevice &&*/}
            {/*    (<EditVirtualDevicePage onSubmit={editVirtualDevice}*/}
            {/*                            devices={devices}*/}
            {/*                            services={services}*/}
            {/*                            textCommandOutputsMap={textCommandOutputsMap}*/}
            {/*                            onTextCommandSubmit={submitTextCommand}*/}
            {/*                            fetchAbstractListDetailed={fetchAbstractListDetailed}*/}
            {/*                            virtualDevice={selectedVirtualDevice}*/}
            {/*                            isEdit*/}
            {/*    />)*/}
            {/*}*/}
        </div>
    );
};

EzloVirtualContainer.propTypes = {
    params: PropTypes.object,
    redux: PropTypes.object,
    location: PropTypes.object,
};

export default EzloVirtualContainer;
