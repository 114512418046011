import React, { memo } from 'react';
import { TableCell } from '@material-ui/core';

const ActionTableCellBox = ({ isDisplayingChildren = true, className, align = 'left', children }) => {
    return (
        <TableCell className={className} align={align}>
            {isDisplayingChildren && children}
        </TableCell>
    );
};

export default memo(ActionTableCellBox);
