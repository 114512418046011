import React, { createContext, useContext } from 'react';

export const LabelDropdownContext = createContext({});

const useLabelDropdownContext = () => {
    return useContext(LabelDropdownContext);
};

export const LabelDropdownContextProvider = ({ value = {}, children }) => {
    return <LabelDropdownContext.Provider value={value}>{children}</LabelDropdownContext.Provider>;
};

export default useLabelDropdownContext;
