import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Input } from '@material-ui/core';

import { IntegrationModal } from './IntegrationModal';
import { Search } from '@material-ui/icons';

import { filterIntegrations } from '../utils';

import { NUCAL_INTEGRATIONS_CREATE_ENROLLMENT_SEARCH_TITLE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import IntegrationCardContainer from './IntegrationCardContainer';
import styles from './NewEnrollmentPage.module.scss';

export const IntegrationsGrid = ({ abstracts, onFetchInitialInfo, onHandleClose }) => {
    const integrations = useSelector((state) => state.main.integrationList.integrations);

    const [filter, setFilter] = useState('');
    const [filteredIntegrations, setFilteredIntegrations] = useState([]);
    const [isEnrolmentLoading, setIsEnrolmentLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [isClicked, setIsClicked] = useState(null);
    const handleChangeFilter = (e) => {
        setFilter(e.target.value);
    };

    const { t } = useTranslate();
    useEffect(() => {
        const filteredIntegrations = filterIntegrations(integrations, filter);

        setFilteredIntegrations(filteredIntegrations);
    }, [filter, integrations]);

    const handleOpenModal = (id) => {
        setIsClicked(filteredIntegrations.find(({ uuid }) => uuid === id));
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setIsClicked(null);
    };

    const handleSetIsEnrollmentLoading = (state) => {
        setIsEnrolmentLoading(state);
    };

    return (
        <Box className={styles.integrationWrapper}>
            <div className={styles.search_popover__wrapper}>
                <div className={styles.search_popover__input}>
                    <Input
                        className={styles.search_popover__input_container}
                        placeholder={t(NUCAL_INTEGRATIONS_CREATE_ENROLLMENT_SEARCH_TITLE)}
                        value={filter}
                        onChange={handleChangeFilter}
                    />
                    <div className={styles.paas__integrations_list}>
                        {filteredIntegrations?.map(({ name, uuid, icon, description, title, capabilities }) => (
                            <IntegrationCardContainer
                                key={uuid}
                                name={name}
                                onClick={handleOpenModal}
                                icon={icon}
                                capabilities={capabilities}
                                description={description}
                                title={title}
                                uuid={uuid}
                            />
                        ))}
                    </div>
                    <div className={styles.search_popover__input_icon}>
                        <Search />
                    </div>

                    {isClicked && (
                        <IntegrationModal
                            open={open}
                            onClose={handleCloseModal}
                            integration={isClicked}
                            isEnrolmentLoading={isEnrolmentLoading}
                            handleSetIsEnrollmentLoading={handleSetIsEnrollmentLoading}
                            setIsEnrolmentLoading={setIsEnrolmentLoading}
                            onFetchInitialInfo={onFetchInitialInfo}
                            abstracts={abstracts}
                            onHandleClose={onHandleClose}
                        />
                    )}
                </div>
            </div>
        </Box>
    );
};
