import { LicenseInfo } from '@mui/x-data-grid-pro';
import { MUI_DATA_GRID_PRO_LICENSE_KEY } from '../../constants/envs';
import {
    EZLOGIC_TITLE_COLUMN,
    EZLOGIC_TITLE_COLUMNS,
    EZLOGIC_TITLE_COMFORTABLE,
    EZLOGIC_TITLE_COMPACT,
    EZLOGIC_TITLE_CONTAINS,
    EZLOGIC_TITLE_DENSITY,
    EZLOGIC_TITLE_DOWNLOAD_AS_CSV,
    EZLOGIC_TITLE_END_WITH,
    EZLOGIC_TITLE_EQUALS,
    EZLOGIC_TITLE_EXPORT,
    EZLOGIC_TITLE_FILTER,
    EZLOGIC_TITLE_FILTER_VALUE,
    EZLOGIC_TITLE_FILTERS,
    EZLOGIC_TITLE_HIDE,
    EZLOGIC_TITLE_IS_EMPTY,
    EZLOGIC_TITLE_IS_NOT_EMPTY,
    EZLOGIC_TITLE_NO_RESULT_FOUND,
    EZLOGIC_TITLE_NO_ROWS,
    EZLOGIC_TITLE_OPERATORS,
    EZLOGIC_TITLE_ROWS_PER_PAGE,
    EZLOGIC_TITLE_SHOW_COLUMNS,
    EZLOGIC_TITLE_SORT_BY_ASC,
    EZLOGIC_TITLE_SORT_BY_DESC,
    EZLOGIC_TITLE_STANDARD,
    EZLOGIC_TITLE_STARTS_WITH,
    EZLOGIC_TITLE_UNSORT,
    EZLOGIC_TITLE_VALUE,
} from '../../constants/language_tokens';

LicenseInfo.setLicenseKey(MUI_DATA_GRID_PRO_LICENSE_KEY);

export const dataGridProLocaleText = (t) => ({
    noRowsLabel: t(EZLOGIC_TITLE_NO_ROWS),
    noResultsOverlayLabel: t(EZLOGIC_TITLE_NO_RESULT_FOUND),
    toolbarDensity: t(EZLOGIC_TITLE_DENSITY),
    toolbarDensityLabel: t(EZLOGIC_TITLE_DENSITY),
    toolbarDensityCompact: t(EZLOGIC_TITLE_COMPACT),
    toolbarDensityStandard: t(EZLOGIC_TITLE_STANDARD),
    toolbarDensityComfortable: t(EZLOGIC_TITLE_COMFORTABLE),
    toolbarColumns: t(EZLOGIC_TITLE_COLUMNS),
    toolbarColumnsLabel: t(EZLOGIC_TITLE_COLUMNS),
    columnsPanelTextFieldLabel: t(EZLOGIC_TITLE_COLUMN),
    columnsPanelTextFieldPlaceholder: t(EZLOGIC_TITLE_COLUMN),
    toolbarFilters: t(EZLOGIC_TITLE_FILTERS),
    filterPanelColumns: t(EZLOGIC_TITLE_COLUMNS),
    filterPanelOperators: t(EZLOGIC_TITLE_OPERATORS),
    filterPanelInputLabel: t(EZLOGIC_TITLE_VALUE),
    filterPanelInputPlaceholder: t(EZLOGIC_TITLE_FILTER_VALUE),
    filterOperatorContains: t(EZLOGIC_TITLE_CONTAINS),
    filterOperatorEquals: t(EZLOGIC_TITLE_EQUALS),
    filterOperatorStartsWith: t(EZLOGIC_TITLE_STARTS_WITH),
    filterOperatorEndsWith: t(EZLOGIC_TITLE_END_WITH),
    filterOperatorIsEmpty: t(EZLOGIC_TITLE_IS_EMPTY),
    filterOperatorIsNotEmpty: t(EZLOGIC_TITLE_IS_NOT_EMPTY),
    columnHeaderFiltersLabel: t(EZLOGIC_TITLE_FILTER),
    toolbarExport: t(EZLOGIC_TITLE_EXPORT),
    toolbarExportCSV: t(EZLOGIC_TITLE_DOWNLOAD_AS_CSV),
    MuiTablePagination: { labelRowsPerPage: t(EZLOGIC_TITLE_ROWS_PER_PAGE) },
    // columnMenuLabel: 'Menu',
    // columnMenuManageColumns: 'Manage columns',
    columnMenuShowColumns: t(EZLOGIC_TITLE_SHOW_COLUMNS),
    columnMenuFilter: t(EZLOGIC_TITLE_FILTER),
    columnMenuHideColumn: t(EZLOGIC_TITLE_HIDE),
    columnMenuUnsort: t(EZLOGIC_TITLE_UNSORT),
    columnMenuSortAsc: t(EZLOGIC_TITLE_SORT_BY_ASC),
    columnMenuSortDesc: t(EZLOGIC_TITLE_SORT_BY_DESC),
});
