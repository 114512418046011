import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';

import { GroupRadioButton } from '../../../../../components';

import hash from '../../../../../constants/uniqueHash';
import at from '../../../../../constants/ActionTypes/MeshBot';

import { builder_header, active } from './BuilderHeader.module.scss';
import {
    mesh_bot__group_operators_wrap,
    show,
    mesh_bot__group_operators,
    mesh_bot__group_operators_inner,
} from '../TriggerBlock.module.scss';
import FunctionForTriggerBlock from '../FunctionForTriggerBlock';
import { OPERATORS_CLOUD, OPERATORS_LOCAL } from '../../../../../constants/MeshbotConstant';

const BuilderHeader = (props) => {
    const {
        type,
        typePage,
        optionType,
        listRuleTriggers,
        onCheckedNotRadio,
        showSectionFunction,
        sectionFunctionInitState,
        onUpdateSectionFunction,
        onCheckedOperatorRadio,
        meshbotType,
    } = props;

    const className = classes(builder_header, {
        [active]: (listRuleTriggers && listRuleTriggers.length > 1) || typePage === at.CLOUD,
    });

    const classWrap = classes(mesh_bot__group_operators_wrap, {
        [show]: listRuleTriggers && listRuleTriggers.length > 1,
    });

    const handleButtonClickCloud = (e) => {
        onCheckedOperatorRadio(e.target.value);
    };
    const handleButtonClickLocal = (e) => {
        onCheckedNotRadio(e.target.value);
    };

    return (
        <header className={className}>
            {type === at.TRIGGER && meshbotType === at.LOCAL ? (
                <>
                    <div className={classWrap}>
                        {listRuleTriggers && listRuleTriggers.length > 1 && (
                            <div className={mesh_bot__group_operators}>
                                <div className={mesh_bot__group_operators_inner}>
                                    {OPERATORS_LOCAL.map((item, index) => {
                                        return (
                                            <GroupRadioButton
                                                id={hash()}
                                                key={index}
                                                label={item}
                                                name={`type${hash()}`}
                                                value={item}
                                                optionType={optionType}
                                                onClick={handleButtonClickLocal}
                                                triggerType
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    {showSectionFunction && (
                        <FunctionForTriggerBlock
                            onUpdate={onUpdateSectionFunction}
                            initState={sectionFunctionInitState}
                        />
                    )}
                </>
            ) : (
                <div className={classWrap}>
                    {listRuleTriggers && listRuleTriggers.length > 1 && (
                        <div className="mesh-bot__group-operators">
                            {OPERATORS_CLOUD.map((item, index) => {
                                return (
                                    <GroupRadioButton
                                        id={hash()}
                                        key={index}
                                        label={item}
                                        name={`type${hash()}`}
                                        value={item}
                                        optionType={optionType}
                                        onClick={handleButtonClickCloud}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </header>
    );
};

export default BuilderHeader;

BuilderHeader.propTypes = {
    type: PropTypes.string,
    meshbotType: PropTypes.string,
    optionType: PropTypes.string,
    listRuleTriggers: PropTypes.array,
    onCheckedNotRadio: PropTypes.func,
    // onGetValueOptionType: PropTypes.func,
};
