import { useSelector } from 'react-redux';
import { SELECTED_LABEL_UUID_FOR_FILTERING } from '../../../../../reducers/labels';
import { useMemo } from 'react';
import { filterMeshBotsBySelectedLabelUuid, labelsSelector } from '../../../../../features/Labels/MeshBotsLabels/utils';

const useMeshBotsFiltering = (allMeshBots) => {
    const labelsUiState = useSelector(labelsSelector);
    const selectedLabelUuid = labelsUiState[SELECTED_LABEL_UUID_FOR_FILTERING];

    return useMemo(() => {
        return filterMeshBotsBySelectedLabelUuid(selectedLabelUuid, allMeshBots);
    }, [selectedLabelUuid, allMeshBots]);
};

export default useMeshBotsFiltering;
