import React from 'react';
import { Button } from '@material-ui/core';

import style from './NewEnrollmentPage.module.scss';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_ADD,
    NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_END_TITLE,
    NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_START_TITLE,
} from '../../../../constants/language_tokens';
import { IcControlsClose, IcVisualsAddNew } from '../../../../assets/icons';
import { PRIMARY } from '../../../../constants/Devices';
import { CONTAINED } from '../../../../constants/MeshbotConstant';

export const AddBlock = ({ title, onClose, onStartAuthentication, authExplanation }) => {
    const { t } = useTranslate();

    return (
        <div className={style.infoBlock}>
            <p className={style.closeBtn}>
                <span onClick={onClose}>
                    <IcControlsClose />
                </span>
            </p>
            <span className={style.imgWrapper}>
                <IcVisualsAddNew />
            </span>
            <h3>
                {t(NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_START_TITLE)}
                {title}
                {t(NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_END_TITLE)}
            </h3>
            {authExplanation && <p className={style.auth_explanation}>{authExplanation}</p>}
            <div className={style.buttonsBlock}>
                <Button
                    className={style.headerButton}
                    variant={CONTAINED}
                    color={PRIMARY}
                    onClick={onStartAuthentication}
                >
                    {t(EZLOGIC_BUTTON_ADD)}
                </Button>
            </div>
        </div>
    );
};
