import React, { useState } from 'react';

import TextInput from '../TextInput';
import { ACCESS_CODE_LABEL, AUTO_COMPLETE_VALUES, FIELD_TYPES } from '../../../../../constants/Users';
import PasswordIconAdornment from '../PasswordIconAdornment';

import styles from '../../UserManagement/PinCodeForm/style.module.scss';
import { EZLOGIC_TITLE_ACCESS_CODE_DESCRIPTION } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const AccessCodeField = ({ isEditing, required }) => {
    const { t } = useTranslate();
    const [showPassword, setShowPassword] = useState(false);
    const passwordType = showPassword ? FIELD_TYPES.TEXT : FIELD_TYPES.PASSWORD;

    return (
        <div>
            <TextInput
                required={required}
                name="pin"
                label={isEditing ? t(ACCESS_CODE_LABEL.EDIT) : t(ACCESS_CODE_LABEL.CREATE)}
                className={styles.passwordField}
                otherArguments={{
                    type: passwordType,
                    InputProps: {
                        autoComplete: t(AUTO_COMPLETE_VALUES.NEW_PASSWORD),
                        endAdornment: (
                            <PasswordIconAdornment showPassword={showPassword} setShowPassword={setShowPassword} />
                        ),
                    },
                }}
            />
            <span className={styles.fieldInfo}>{t(EZLOGIC_TITLE_ACCESS_CODE_DESCRIPTION)}</span>
        </div>
    );
};

export default AccessCodeField;
