import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CloudVariableSelect from '../../CloudVariablesSelect';

import { CloudMeshbotActions } from '../../../../../../actions';
import actions from '../../../../../../actions/IntegrationsActions';
import useCloudVariableCurrentValue from '../../../../../../hooks/useCloudVariableCurrentValue';
import { determineVariableValueDataType } from '../utils';

/**
 * Container component for cloud variables' select in cloud meshbot triggers. It collects and provides data for the select,
 * also provides a function to change this data.
 */
const CloudVariableSelectForCloudMeshBot = ({
    ruleCloudTriggerId,
    ruleCloudTrigger,
    onUpdateVariableValueDataType,
}) => {
    const dispatch = useDispatch();
    const { updateCloudVariableInTrigger } = CloudMeshbotActions;
    const { selectedAbstract, selectedVariable } = ruleCloudTrigger;
    const currentValueData = useCloudVariableCurrentValue(selectedAbstract?.uuid, selectedVariable);
    const variableValueType = determineVariableValueDataType(currentValueData?.value);

    useEffect(() => {
        if (selectedAbstract && selectedVariable && ruleCloudTriggerId) {
            dispatch(actions.setCloudVariableCurrentValue(selectedAbstract.uuid, selectedVariable));
        }
    }, []);

    useEffect(() => {
        onUpdateVariableValueDataType({ variableValueType });
    }, [variableValueType]);

    const updateSelectValue = (variableData) => {
        dispatch(updateCloudVariableInTrigger(variableData, ruleCloudTriggerId));
    };

    return (
        <CloudVariableSelect
            selectedVariable={selectedVariable}
            selectedAbstract={selectedAbstract}
            updateSelectValue={updateSelectValue}
            currentValueData={currentValueData}
        />
    );
};

export default CloudVariableSelectForCloudMeshBot;
