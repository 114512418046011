import React from 'react';
import PropTypes from 'prop-types';
import EnrollmentListItem from './EnrollmentListItem';

import style from './EnrollmentListItem.module.scss';
import { integrationEnrollmentsListWrapper } from '../../../EzloIntegrations/EzloIntegrationsInformation/styles.module.scss';

const EnrollmentsList = (props) => {
    const { abstracts, icon, onEdit, onDelete, fetchAbstracts, isIntegrationInfo } = props;

    return (
        <ul className={isIntegrationInfo ? integrationEnrollmentsListWrapper : style.enrollmentsList}>
            {abstracts.map((abstract) => (
                <EnrollmentListItem
                    key={abstract.uuid}
                    icon={icon}
                    abstract={abstract}
                    onEdit={onEdit}
                    onDelete={() => onDelete(abstract.uuid)}
                    fetchAbstracts={fetchAbstracts}
                    isIntegrationInfo={isIntegrationInfo}
                />
            ))}
        </ul>
    );
};

EnrollmentsList.propTypes = {
    abstracts: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default EnrollmentsList;
