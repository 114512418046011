import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { MenuItem, Grow, Paper, Popper, MenuList, ClickAwayListener } from '@material-ui/core';

import {
    CONFIRMATION,
    ZWAVE_CONFIGURATION,
    TAB,
    TRUE_STRING,
    LEFT_TOP,
    BOTTOM_START,
    MENU_LIST_GROW,
    LEFT_BOTTOM,
    OBJECT,
} from '../../../../constants/Devices';
import hash from '../../../../constants/uniqueHash';
import { filterSettings, getCameraStreamItems } from '../../../../containers/Ezlo/EzloDevices/utils';

import styles from '../../DeviceCard.module.scss';
import { EZLOGIC_BUTTON_SETTINGS, EZLOGIC_TITLE_REMOVE_DEVICE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import { LeftMenuSettingsIcon } from '../../../../assets/icons';

const DeviceSettings = (props) => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const anchorRef = useRef(null);
    const { t } = useTranslate();
    const { device, settings, onHandlerSelectFunction, isAvailable, isConnected, isIntegration } = props;

    const sortSettings = (data) => data.sort((a, b) => a.label?.text.localeCompare(b.label.text));
    const settingsStyles = classes(styles.settings, { [styles.disabled]: !isConnected });
    const disabledSomSetting = (data) => data?.valueType === ZWAVE_CONFIGURATION && true;

    const handleToggleMenu = () => {
        setIsOpenMenu((prevOpen) => !prevOpen);
    };

    const handleCloseMenu = (event, value) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (typeof value === OBJECT) {
            onHandlerSelectFunction(value);
        }

        setIsOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === TAB) {
            event.preventDefault();
            setIsOpenMenu(false);
        }
    }

    return (
        <div className={styles.settingsWrapper}>
            <a
                className={isIntegration ? styles.settingsStylesIntegration : settingsStyles}
                ref={anchorRef}
                aria-controls={isOpenMenu ? MENU_LIST_GROW : undefined}
                aria-haspopup={TRUE_STRING}
                onClick={handleToggleMenu}
            >
                {isIntegration && <LeftMenuSettingsIcon />} {t(EZLOGIC_BUTTON_SETTINGS)}
            </a>
            <Popper
                className={isIntegration ? styles.deviceSettingsPoper : styles.popper}
                open={isOpenMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement={BOTTOM_START}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === BOTTOM_START ? LEFT_TOP : LEFT_BOTTOM }}
                    >
                        <Paper className={styles.paper}>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList autoFocusItem={isOpenMenu} id={MENU_LIST_GROW} onKeyDown={handleListKeyDown}>
                                    {isAvailable &&
                                        getCameraStreamItems(device).map((setting) => (
                                            <MenuItem
                                                key={hash}
                                                onClick={(e) =>
                                                    handleCloseMenu(e, {
                                                        setting: setting.name,
                                                        value: setting.value,
                                                    })
                                                }
                                            >
                                                {setting.name}
                                            </MenuItem>
                                        ))}
                                    {isAvailable &&
                                        sortSettings(filterSettings(settings)).map((item) => (
                                            <MenuItem
                                                key={item?._id}
                                                disabled={disabledSomSetting(item)}
                                                onClick={(e) => handleCloseMenu(e, { setting: item, device: device })}
                                            >
                                                {item?.label.text}
                                            </MenuItem>
                                        ))}
                                    <MenuItem
                                        onClick={(e) => handleCloseMenu(e, { setting: CONFIRMATION, device: device })}
                                        disabled={device.persistent}
                                    >
                                        {t(EZLOGIC_TITLE_REMOVE_DEVICE)}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default DeviceSettings;

DeviceSettings.propTypes = {
    device: PropTypes.object,
    isAvailable: PropTypes.bool,
    settings: PropTypes.array,
    onHandlerSelectFunction: PropTypes.func,
    isConnected: PropTypes.bool,
};
