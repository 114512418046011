import React from 'react';
import { MenuItem } from '@material-ui/core';

import TimeInputsBlock from '../TimeInputsBlock';
import SelectComponent from '../SelectComponent';
import { InputMaterial } from '../../../../../components';

import { PULSE_FUNCTION_ITEM } from '../../../../../constants/MeshbotConstant';
import { getPulseFunctionSupportedType } from '../../utils';

import styles from './PulseFunctionBlock.module.scss';
import {
    EZLOGIC_TITLE_AFTER,
    EZLOGIC_TITLE_TIMES,
    EZLOGIC_TITLE_TRUE_FOR,
    EZLOGIC_TITLE_UP_TO,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const PulseFunctionBlock = (props) => {
    const {
        handleSecondsUpdate,
        seconds,
        timesNumber,
        handleOnChangeTimesNumber,
        selectedPulseType,
        setSelectedPulseType,
        falsePeriodSeconds,
        setFalsePeriodSeconds,
        advancedScenesVersion,
    } = props;
    const { t } = useTranslate();
    const onChangeSelectOfPulseType = (event) => {
        const selectValue = event.target.value;

        if (selectValue === PULSE_FUNCTION_ITEM.repeat) {
            setFalsePeriodSeconds(0);
        }

        setSelectedPulseType(selectValue);
    };

    const handleUpdateFalsePeriodSeconds = (seconds) => setFalsePeriodSeconds(seconds);
    const getLowerCase = (word) => word.charAt(0).toLowerCase() + word.slice(1);

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div className={styles.label}>{t(EZLOGIC_TITLE_TRUE_FOR)}</div>
                <TimeInputsBlock onTimeUpdate={handleSecondsUpdate} mainStateSeconds={seconds} />

                <SelectComponent value={selectedPulseType} onChange={onChangeSelectOfPulseType} small>
                    {getPulseFunctionSupportedType(advancedScenesVersion).map((item) => (
                        <MenuItem key={item.key} value={item.value}>
                            {t(item.label)}
                        </MenuItem>
                    ))}
                </SelectComponent>
            </div>

            {selectedPulseType === PULSE_FUNCTION_ITEM.repeat && (
                <div className={styles.block}>
                    <div className={styles.label}>{getLowerCase(t(EZLOGIC_TITLE_AFTER))}</div>
                    <TimeInputsBlock
                        onTimeUpdate={handleUpdateFalsePeriodSeconds}
                        mainStateSeconds={falsePeriodSeconds}
                    />
                    <div className={styles.label}>{t(EZLOGIC_TITLE_UP_TO)}</div>
                    <InputMaterial
                        label={t(EZLOGIC_TITLE_TIMES)}
                        type="number"
                        value={timesNumber}
                        min={2}
                        step={1}
                        onChange={handleOnChangeTimesNumber}
                        styleForFunctionBlock
                        xsSmall
                    />
                </div>
            )}

            {selectedPulseType === PULSE_FUNCTION_ITEM.infinite && (
                <div className={styles.block}>
                    <div className={styles.label}>{getLowerCase(t(EZLOGIC_TITLE_AFTER))}</div>
                    <TimeInputsBlock
                        onTimeUpdate={handleUpdateFalsePeriodSeconds}
                        mainStateSeconds={falsePeriodSeconds}
                    />
                </div>
            )}
        </div>
    );
};

export default PulseFunctionBlock;
