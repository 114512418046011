import React from 'react';

import CompletedIcon from '../icons/CompletedIcon';
import classNames from 'classnames';
import { formatBackupTimestamp } from '../utils';
import {
    EZLOGIC_BUTTON_GOT_IT,
    EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_RESTORED,
    EZLOGIC_TITLE_YOU_MAY_NOW_CONTINUE_USING,
} from '../../../../constants/language_tokens';
import Button from '@material-ui/core/Button';
import { useTranslate } from '../../../../features/languages';

const BackupRestoreCompletedContent = ({ backup, timeOffset, handleFinish }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <CompletedIcon className="icon-completed" />
            <span className={classNames('text-auto-width', 'title')}>{`${formatBackupTimestamp(
                backup.timestamp,
                timeOffset,
            )} ${t(EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_RESTORED)}`}</span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_YOU_MAY_NOW_CONTINUE_USING)}
            </span>
            <div className="main-content-footer">
                <Button color="primary" variant="contained" className="primary-btn" onClick={handleFinish}>
                    {t(EZLOGIC_BUTTON_GOT_IT)}
                </Button>
            </div>
        </div>
    );
};

export default BackupRestoreCompletedContent;
