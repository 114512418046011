import React, { useMemo, useState } from 'react';
import { CircularProgress, Button } from '@material-ui/core';

import { AdvancedTextArea } from './AdvancedTextArea';
import { AdvancedTableWithResponse } from './AdvancedTableWithResponse';
import TestVariablesDialogBox from './components/TestVariablesDialogBox/TestVariablesDialogBox';

import { useFetchCodeTestRun } from '../../hooks/useFetchCodeTestRun';
import { getVariablesForTable } from '../../utils';

import style from './CloudVariable.module.scss';

export const AdvancedMapType = ({
    handleSetBlackListVariable,
    onChangeState,
    currentActionData,
    mappingState,
    onSetCode,
    buttonText,
    withTextarea,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { code } = mappingState;
    const { abstractUuid, methodValue, fieldsList } = currentActionData;
    const blackListVariable = currentActionData?.blackListVariable || [];
    const { loading, error, response, callbackMemoized } = useFetchCodeTestRun(
        abstractUuid,
        methodValue,
        fieldsList,
        code,
        setIsDialogOpen,
    );

    const dataForTable = useMemo(() => {
        return getVariablesForTable(response, blackListVariable);
    }, [response, blackListVariable]);

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <div>
            {withTextarea && <AdvancedTextArea code={code} handleChangeState={onChangeState} onSetCode={onSetCode} />}
            <Button
                color="primary"
                className={style.runButton}
                size="large"
                variant="contained"
                onClick={callbackMemoized}
                disabled={!code || loading}
            >
                {loading && <CircularProgress className={style.progress} size={16} />}
                {buttonText}
            </Button>
            <TestVariablesDialogBox handleClose={handleClose} isOpen={isDialogOpen}>
                <AdvancedTableWithResponse
                    dataForTable={dataForTable}
                    blackListVariable={blackListVariable}
                    handleSetBlackListVariable={handleSetBlackListVariable}
                />
            </TestVariablesDialogBox>
            {error && <div className={style.errorBlock}>{error}</div>}
        </div>
    );
};
