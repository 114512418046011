import React from 'react';
import PropTypes from 'prop-types';
import { Modal, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import './Modal.scss';

const ModalPlugin = (props) => {
    const { open, handleClose, children } = props;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modal"
        >
            <div className="modal__paper">
                <IconButton className="modal__button-close" onClick={handleClose}>
                    <ClearIcon />
                </IconButton>
                {children}
            </div>
        </Modal>
    );
};

ModalPlugin.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    children: PropTypes.object,
};

export default ModalPlugin;
