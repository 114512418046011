import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';

import FunctionForTriggerBlockContainer from './FunctionForTriggerBlockContainer';
import InfoButton from '../../components/InfoButton';

import { createWrapperAndAppendToParentNode } from '../../utils';

import { SELECT_FUNCTION } from '../../../../../constants/MeshbotConstant';

import {
    unselected,
    function_block,
    function_button,
    info_button_wrapper,
    margin_right,
    move_right,
} from './FunctionForTriggerBlock.module.scss';
import { EZLOGIC_TITLE_TRIGGER_FUNCTION } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { FunctionIcon } from '../../../../../assets/icons';

const FunctionButtonPortal = (props) => {
    const { isPortal, id } = props;
    const [wrapperElement, setWrapperElement] = useState(null);
    const wrapperPortal = `function-block-${id}`;

    useLayoutEffect(() => {
        if (isPortal) {
            let element = document.getElementById(wrapperPortal);
            let systemCreated = false;
            if (!element) {
                systemCreated = true;
                element = createWrapperAndAppendToParentNode(wrapperPortal, id);
            }
            setWrapperElement(element);

            return () => {
                if (systemCreated && element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            };
        }
    }, [wrapperPortal, id, isPortal]);

    return (
        <>
            {isPortal && wrapperElement ? (
                createPortal(<FunctionForTriggerBlockContainer {...props} />, wrapperElement)
            ) : (
                <FunctionForTriggerBlockContainer {...props} />
            )}
        </>
    );
};

const FunctionForTriggerBlock = (props) => {
    const [showFunctionSettings, setShowFunctionSettings] = useState(false);
    const [selectedFunctionType, setSelectedFunctionType] = useState(SELECT_FUNCTION);
    const { t } = useTranslate();
    const {
        onUpdate,
        initState,
        triggerType,
        idFunction,
        ezloSerial,
        typeForm,
        currentMeshbot,
        MeshBotAction,
        isPortal,
        id,
        marginRight,
        groupHeaderPlace,
    } = props;

    useEffect(() => {
        if (initState) {
            setSelectedFunctionType(Object.keys(initState)[0]);
        }
    }, []);

    const wrapperClasses = classNames(function_block, {
        [unselected]: selectedFunctionType === SELECT_FUNCTION,
    });

    const handleShowFunctionSettings = () => {
        setShowFunctionSettings((prevValue) => !prevValue);
    };

    const handleSetSelectedFunctionType = useCallback((functionType) => {
        setSelectedFunctionType(functionType);
    }, []);

    return (
        <div className={wrapperClasses}>
            {!showFunctionSettings && (
                <>
                    <IconButton
                        className={classNames(function_button, {
                            [margin_right]: marginRight,
                        })}
                        onClick={handleShowFunctionSettings}
                    >
                        <FunctionIcon />
                    </IconButton>
                    <div
                        className={classNames(info_button_wrapper, {
                            [move_right]: marginRight,
                        })}
                    >
                        <InfoButton>
                            <div dangerouslySetInnerHTML={{ __html: t(EZLOGIC_TITLE_TRIGGER_FUNCTION) }} />
                        </InfoButton>
                    </div>
                </>
            )}

            {showFunctionSettings && (
                <FunctionButtonPortal
                    alignRight
                    triggerType={triggerType}
                    onUpdate={onUpdate}
                    initState={initState}
                    idFunction={idFunction}
                    ezloSerial={ezloSerial}
                    typeForm={typeForm}
                    currentMeshbot={currentMeshbot}
                    MeshBotAction={MeshBotAction}
                    showFunctionSettings={showFunctionSettings}
                    handleShowFunctionSettings={handleShowFunctionSettings}
                    selectedFunctionType={selectedFunctionType}
                    onSetSelectedFunctionType={handleSetSelectedFunctionType}
                    isPortal={isPortal}
                    id={id}
                    groupHeaderPlace={groupHeaderPlace}
                />
            )}
        </div>
    );
};

export default FunctionForTriggerBlock;

FunctionForTriggerBlock.propTypes = {
    ezloSerial: PropTypes.string,
    typeForm: PropTypes.string,
    triggerType: PropTypes.string,
    idFunction: PropTypes.string,
    borderColor: PropTypes.string,
    currentMeshbot: PropTypes.object,
    initState: PropTypes.object,
    MeshBotAction: PropTypes.object,
    onUpdate: PropTypes.func,
};
