import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SelectComparisonOperator from '../../../../../components/SelectComparisonOperator';
import SelectValueToCompare from '../../../../../components/SelectValueToCompare';
import CloudVariablesSelectForLocalMeshbot from './components/CloudVariablesSelectForLocalMeshbot';
import useTriggerSelect from '../../../../../hooks/useTriggerSelect';

import { extractTypeOfVariable } from './utils';

/**
 * Component for the selected node cloud variables in local meshbot triggers
 */
const PaasNodeForTrigger = ({ updateTriggerData, ruleId, groupId, isException }) => {
    const capabilities = useSelector((state) => state.main.abstractCapabilities.capabilities);
    const ruleTrigger = useTriggerSelect(ruleId, groupId, isException);
    const {
        selectedAbstract,
        selectedComparator,
        compareTo,
        comparingValue,
        selectedVariable,
        typeVariable,
        isCompareToVariable,
    } = ruleTrigger;
    const showSelectValueToCompare = selectedAbstract?.uuid && selectedComparator?.op;

    useEffect(() => {
        if (!typeVariable) {
            const typeOfVariable = extractTypeOfVariable(capabilities);
            updateTriggerData({
                typeVariable: typeOfVariable,
            });
        }
    }, [typeVariable]);

    return (
        <>
            <CloudVariablesSelectForLocalMeshbot groupId={groupId} ruleId={ruleId} ruleTrigger={ruleTrigger} />
            {selectedVariable && (
                <SelectComparisonOperator
                    capabilityValueType={typeVariable}
                    selectedComparator={selectedComparator}
                    onDataUpdate={updateTriggerData}
                />
            )}
            {showSelectValueToCompare && (
                <SelectValueToCompare
                    onDataUpdate={updateTriggerData}
                    capabilityValueType={typeVariable}
                    comparingValue={comparingValue}
                    compareTo={compareTo}
                    isCompareToVariable={isCompareToVariable}
                    selectedComparator={selectedComparator}
                />
            )}
        </>
    );
};

export { PaasNodeForTrigger };
