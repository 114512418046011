import { EZLOGIC_PRODUCTION_URL } from './URLs';
import { CLOUD_ENVIRONMENT } from './localStorageKeys';
import oemManager from '../services/oem';

export const SWITCHING_ENV_MANUALLY = 'switching_cloud_env_manually';

// export const CLOUD_ENVS = {
//     PROD: 'vera',
//     QA: 'vera-qa',
//     STG: 'vera-stg',
//     SECURITY: 'security',
//     SECURITY_QA: 'security-qa',
//     SECURITY_STG: 'security-stg',
//     PROPERTY: 'property',
//     PROPERTY_QA: 'property-qa',
//     PROPERTY_STG: 'property-stg',
//     HORUS: 'horus',
//     // HORUS_QA: 'horus-qa', // we don't have horus qa
//     HORUS_STG: 'horus-stg',
//     CONSTELLATION: 'constellation',
//     CONSTELLATION_STG: 'constellation-stg',
// };
//
// // TODO: apply refactoring
// export const EZLOGIC_DOMAIN_ENV_PAIR_CONTAINER = {
//     'ezlogic.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic-qa.mios.com': CLOUD_ENVS.QA,
//     'qa-ezlogic.mios.com': CLOUD_ENVS.QA, // legacy, but still will be compatible
//     'ezlogic-stg.mios.com': CLOUD_ENVS.STG,
//     'stg-ezlogic.mios.com': CLOUD_ENVS.STG, // legacy, but still will be compatible
//     'ezlogic2.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic3.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic4.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic5.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic6.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic7.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic8.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic9.mios.com': CLOUD_ENVS.PROD,
//     'ezlogic10.mios.com': CLOUD_ENVS.PROD,
//     'protect.mios.com': CLOUD_ENVS.SECURITY,
//     'protect-qa.mios.com': CLOUD_ENVS.SECURITY_QA,
//     'protect-stg.mios.com': CLOUD_ENVS.SECURITY_STG,
//     'resident.mios.com': CLOUD_ENVS.PROPERTY,
//     'resident-qa.mios.com': CLOUD_ENVS.PROPERTY_QA,
//     'resident-stg.mios.com': CLOUD_ENVS.PROPERTY_STG,
//     'horusexpert.com': CLOUD_ENVS.HORUS,
//     'www.horusexpert.com': CLOUD_ENVS.HORUS,
//     // 'ezlogic-horus-qa': CLOUD_ENVS.HORUS_QA,
//     'ezlogic-horus-stg.mios.com': CLOUD_ENVS.HORUS_STG,
//     'ezlogic-constellation-stg.mios.com': CLOUD_ENVS.CONSTELLATION_STG,
// };

/**
 * Get environment key
 * @returns {string} environment key
 * @example
 * "vera", "security-qa"
 **/
export const getEnvKey = () => {
    return localStorage.getItem(CLOUD_ENVIRONMENT);
};

/**
 Check is EZLogic runs on production
 **/
export const isEzlogicProdEnv = () => {
    return window?.location?.origin === EZLOGIC_PRODUCTION_URL;
};

export const setEzlogicEnvironment = (key) => {
    let environmentKey = key;
    const isEnvSwitchedManually = localStorage.getItem(SWITCHING_ENV_MANUALLY);
    if (isEnvSwitchedManually) {
        environmentKey = getEnvKey();
    }

    oemManager.setEnvironmentByKey(environmentKey);

    // NOTE:
    // read applied env key again and update local storage
    // it is necessary to do in case of broken/not actual key
    environmentKey = oemManager.getEnvironmentKey();
    localStorage.setItem(CLOUD_ENVIRONMENT, environmentKey);
};

export const setEnvManually = (bool, newEnv) => {
    localStorage.setItem(SWITCHING_ENV_MANUALLY, bool);
    localStorage.setItem(CLOUD_ENVIRONMENT, newEnv);
    setEzlogicEnvironment(newEnv);
};

/**
 License key for Mui DataGridPro
 **/
export const MUI_DATA_GRID_PRO_LICENSE_KEY =
    'ccc026e985ffdcf00d414afe6e902c7aT1JERVI6Mjc3MDgsRVhQSVJZPTE2NTkyNjczMzgwMDAsS0VZVkVSU0lPTj0x';
