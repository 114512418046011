import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { TextField } from '../../../../components';
import { REGEXP_TEMPLATE_METHOD_NAME } from '../virtual-templates';
import { EZLOGIC_TITLE_METHOD } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

export const MethodsSelect = ({ paths, onSelectMethod, method }) => {
    const handleChangeMethod = (_, option) => {
        onSelectMethod('/' + option.uuid);
    };
    const [value, setValue] = useState({
        name: '',
        uuid: '',
    });
    const { t } = useTranslate();
    useEffect(() => {
        if (paths.length && method) {
            const { name, uuid } = paths.find(({ uuid }) => uuid === method.replace(REGEXP_TEMPLATE_METHOD_NAME, ''));
            setValue({
                name,
                uuid,
            });
        }
    }, [paths, method]);

    return (
        <div>
            <Autocomplete
                value={value}
                options={paths}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={handleChangeMethod}
                disableClearable={true}
                renderInput={(params) => <TextField {...params} label={t(EZLOGIC_TITLE_METHOD)} />}
            />
        </div>
    );
};
