import React from 'react';
import { Paper } from '@material-ui/core';

import PasswordRequirements from '../../../../../components/PasswordRequirements';

import styles from './PaperPasswordRequirements.module.scss';

const PaperPasswordRequirements = ({ passwordRequirements, getRequirementClassName }) => {
    return (
        <Paper>
            <div className={styles.passwordRequirementsBox}>
                <PasswordRequirements
                    passwordRequirements={passwordRequirements}
                    getClassName={getRequirementClassName}
                    isTitle={false}
                />
            </div>
        </Paper>
    );
};

export default PaperPasswordRequirements;
