import React from 'react';
import { TextField } from '@material-ui/core';

const SimpleStringField = ({ value, onChange, name, isRequired }) => {
    const handleChange = (e) => {
        onChange(e.target.value, name, isRequired);
    };

    return (
        <TextField
            InputProps={{
                inputProps: { autoComplete: 'new-password' },
            }}
            fullWidth
            value={value}
            variant="outlined"
            size="small"
            onChange={handleChange}
        />
    );
};

export default SimpleStringField;
