import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

import { useTranslate } from '../../../../languages';
import { EZLOGIC_SEARCH_TITLE } from '../../../../../constants/language_tokens';
import { SearchIcon } from '../../../../../assets/icons';

import styles from './styles.module.scss';

const SearchField = ({ value, onChangeValue }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.fieldWrapper}>
            <TextField
                fullWidth
                value={value}
                onChange={onChangeValue}
                placeholder={t(EZLOGIC_SEARCH_TITLE)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default SearchField;
