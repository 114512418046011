import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { languageService } from '../../../services/language';
import { LanguageSelector } from '../../../features/languages/components';
import { updateLang } from '../../../features/languages/utils';
import { AuthorizationContextProvider } from '../context/AuthorizationContext';

import ValidateEmailFormContainer from './components/ValidateEmailForm';
import ValidateEmailSuccessContainer from './components/ValidateEmailSuccessContainer';
import NotFoundPageContent from '../../NotFoundPage/NotFoundPageContent';

import styles from './ValidateEmailPage.module.scss';
import { AppLogo } from '../../../assets/icons';

const ValidateEmailPage = () => {
    const { url } = useRouteMatch();

    // TODO: go over all the places and hide multi language inside a custom hook.
    // TODO: check all not authenticated pages
    const language = languageService.getLanguageKey();
    const [currentLang, setCurrentLang] = useState(language);

    // TODO: incapsulate inside custom hook
    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);
    };

    // TODO: incapsulate inside custom hook
    useEffect(() => {
        updateLang(currentLang);
    }, [currentLang]);

    return (
        <AuthorizationContextProvider>
            <div className="logo">
                <AppLogo className="logo__icon" />
            </div>
            <div className={styles.language}>
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} isLoggin={true} />
            </div>
            <section className={styles.contentContainer}>
                <Switch>
                    <Route path={`${url}/email`} render={() => <ValidateEmailFormContainer />} />
                    <Route path={`${url}/success`} render={() => <ValidateEmailSuccessContainer />} />
                    <Route render={() => <NotFoundPageContent loggedIn />} />
                </Switch>
            </section>
        </AuthorizationContextProvider>
    );
};

export default ValidateEmailPage;
