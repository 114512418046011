import React from 'react';
// import { object } from 'prop-types';
import './style.scss';
import { Button } from '../../../../../components';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';

export default class DeviceSettingAction extends React.Component {
    // static propTypes = {
    //     settings: object.isRequired,
    //     device: object,
    // };

    saveHandler = () => {
        const { onSave, settings } = this.props;

        if (settings._id) {
            onSave(settings._id, '');
        }
    };

    render() {
        const { settings, titles } = this.props;
        const { description, status, info, value } = settings;
        const paramId = info && info['zwave.parameter_number'];

        return (
            <div key={paramId} className="device-alarm__wrapper">
                {status && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.status}</h5>
                            <span className="device-alarm__status">{status}</span>
                        </div>
                        <DeviceSettingInfo />
                    </div>
                )}

                {paramId && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.parameterId}</h5>
                            <span className="device-alarm__status">{paramId}</span>
                        </div>
                    </div>
                )}

                {description && <p className="device-alarm__text">{description.text}</p>}

                <div className="device-range-footer">
                    <Button className="btn-rounded device-range__button-save" onClick={this.saveHandler}>
                        {value.text || titles.execute}
                    </Button>
                </div>
            </div>
        );
    }
}
