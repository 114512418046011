import React from 'react';
import { useTranslate } from '../../../../../features/languages';

const FirmwareVersionContent = ({ controllerData }) => {
    const { t } = useTranslate();

    return (
        <div className="dialog__item">
            <div>{controllerData?.info?.firmware}</div>
            <div className="controller-settings-dialog__info-description">
                {t`ezlo.settings.firmware_version.title`}
            </div>
        </div>
    );
};

export default FirmwareVersionContent;
