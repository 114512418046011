import React, { useState, useEffect } from 'react';
import { Switch } from '@material-ui/core';

import { IMMEDIATELY, DELAY } from '../../constants/MeshbotConstant';

import styles from './SwitchDelayComponent.module.scss';
import { EZLOGIC_TITLE_DEALY, EZLOGIC_TITLE_IMMEDIATELY } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const SwitchDelayComponent = ({ isShowedDelay, onChangeDelay }) => {
    const [switchState, setSwitchState] = useState(false);
    const { t } = useTranslate();
    useEffect(() => {
        setSwitchState(isShowedDelay === IMMEDIATELY ? false : true);
    }, [isShowedDelay]);

    const handleSwitchState = (e) => {
        const { checked } = e.target;
        setSwitchState(checked);
        onChangeDelay(!checked ? IMMEDIATELY : DELAY);
    };

    return (
        <div className={styles.wrapper}>
            <span className={styles.label}>{t(EZLOGIC_TITLE_IMMEDIATELY)}</span>
            <Switch checked={switchState} onChange={handleSwitchState} color="primary" />
            <span className={styles.label}>{t(EZLOGIC_TITLE_DEALY)}</span>
        </div>
    );
};

export default SwitchDelayComponent;
