import * as yup from 'yup';

export const validationSchema = yup.object({
    virtualDevices: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            device_type: yup.object().required('Required'),
            devices_count: yup
                .number()
                .max(100, 'Should not be more than 100')
                .min(1, 'Must be more 0')
                .integer('Must be integer')
                .positive('Must be more than 0')
                .required('Must be an integer'),
        }),
    ),
});
