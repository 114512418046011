import { useEffect, useMemo, useState } from 'react';

import { apiUserPasswordResetSet } from '../../../../services/auth';
import { toast, TOAST_TYPE } from '../../../../components/Toast';
import { TOAST_MESSAGE_ON_ERROR } from '../../../../constants/toasts';
import { buildResetPasswordLinkValidatePayload, isValidPasswordResetLink, isValidQueryParams } from '../utils/utils';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';
import { useTranslate } from '../../../../features/languages';
import { useOemManagerState } from '../../../../hooks/useOemManagerState';

/**
 * Hook that determines the validation of the password reset link and returns the result of the check
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=User+-+Password+Reset+Set here}
 * @returns {{ isResetPasswordLinkValid: boolean, isLinkValidating: boolean }} returns validation status of Password Reset Link
 * */
const useValidationPasswordResetLink = () => {
    const params = useQueryParams();
    const { t } = useTranslate();
    const [isResetPasswordLinkValid, setResetPasswordLinkValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const { isOemManagerConfig } = useOemManagerState();

    const fetchResetPasswordLinkValidate = async (params) => {
        if (!isValidQueryParams(params)) {
            setLoading(false);

            return;
        }

        try {
            setLoading(true);
            const response = await apiUserPasswordResetSet(buildResetPasswordLinkValidatePayload(params));
            await setResetPasswordLinkValid(isValidPasswordResetLink(response?.data));
        } catch (err) {
            toast(err?.message || t(TOAST_MESSAGE_ON_ERROR), { type: TOAST_TYPE.ERROR });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        if (isOemManagerConfig) {
            fetchResetPasswordLinkValidate(params);
        }

        return () => {
            controller.abort();
        };
    }, [params, isOemManagerConfig]);

    return useMemo(
        () => ({ isResetPasswordLinkValid, isLinkValidating: loading }),
        [isResetPasswordLinkValid, loading],
    );
};

export default useValidationPasswordResetLink;
