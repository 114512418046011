import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import classNames from 'classnames';

const TextField = (props) => {
    const { className, ...rest } = props;

    return <MuiTextField {...rest} className={classNames('text-field', { [className]: className })} />;
};

TextField.propTypes = {
    type: PropTypes.string,
};

TextField.defaultProps = {
    type: 'text',
};

export default TextField;
