import React, { memo } from 'react';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

import TreeElement from './TreeElement';
import { BODY_PATH, RESPONSE_NAME } from './constants';

import { treeViewBox, treeTitle } from './styles.module.scss';
import { EZLOGIC_TITLE_DATA_STRUCTURE } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DataTreeView = ({ dataTree }) => {
    const { t } = useTranslate();

    return (
        <div className={treeViewBox}>
            <div className={treeTitle}>{t(EZLOGIC_TITLE_DATA_STRUCTURE)}</div>
            <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpanded={[dataTree.elementId]}
                defaultExpandIcon={<ChevronRight />}
            >
                <TreeElement treeElementName={RESPONSE_NAME} treeElementValue={dataTree} prevPartPath={BODY_PATH} />
            </TreeView>
        </div>
    );
};

export default memo(DataTreeView);
