import React, { useEffect } from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { COMPARISON_DATA } from '../../constants/MeshbotConstant';
import { itemStateValuesToSelect } from '../../containers/Ezlo/EzloMeshbot/MeshbotLocal/MeshBotLocalForm/utils';
import { formatNameWithUnderscores } from '../../helpers/helpersMeshBot';

const SelectButtonState = ({ selectedCapability, comparingValue, onDataUpdate }) => {
    const enumValues = itemStateValuesToSelect(selectedCapability);

    useEffect(() => {
        if (!comparingValue) {
            onDataUpdate({
                selectedComparator: { method: COMPARISON_DATA.METHOD.IS_BUTTON_STATE },
                comparingValue: enumValues[0].value,
            });
        }
    }, []);

    const handleSelectValue = (event) => onDataUpdate({ comparingValue: event.target.value });

    return (
        <SelectComponent
            value={comparingValue || enumValues[0].value}
            label={info.hints.value}
            info={info.text.value}
            onChange={handleSelectValue}
            renderValue={(value) => <div>{formatNameWithUnderscores(value || '')}</div>}
        >
            {enumValues.map((option, id) => {
                return (
                    <MenuItem key={`${option.value}_${id}`} value={option.value}>
                        {formatNameWithUnderscores(option.value)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default SelectButtonState;
