import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import oemManager from '../../../../../services/oem';
import Header from '../../../../../components/Header';
import Languages from '../../../../../features/languages/container';
import { useTranslate } from '../../../../../features/languages/hooks';
import { ENDUSER_PERMISSION } from '../../../EzloCustomization/constants';
import InstallerAccess from '../../../../../features/InstallerAccess/container';
import { SECURITY_OEM_ID } from '../../../../../services/oem/src/constants/oems';
import BoxWithLinearProgress from '../../../../../components/BoxWithLinearProgress';
import actions from '../../../../../features/InstallerAccess/actions/InstallerAccessRights';
import { EZLOGIC_HEADING_ACCOUNT_SETTING, EZLOGIC_TITLE_LANGUAGE } from '../../../../../constants/language_tokens';
import { DISABLE, InstallerAccessDisabledModal } from '../../../../../features/InstallerAccess';

import { container, accountSectionWrapper, headerTitleBox } from './style.module.scss';

const AccountSettingsLayout = () => {
    const isLoading = useSelector((state) => state.installerAccessStore?.isLoading);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const Pk_Permission_Role = useSelector((state) => state.account?.data?.PK_PermissionRole);
    const oemId = oemManager.oem.getOemId();
    useEffect(() => {
        if (isModalVisible) {
            setSaveButtonDisabled(false);
        }
    }, [isModalVisible]);
    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };
    const handleDisableButtonClick = () => {
        dispatch(actions.uploadInstallerAccess(DISABLE));
        toggleModal();
        setSaveButtonDisabled(true);
    };

    return (
        <BoxWithLinearProgress isLinearProgress={isLoading}>
            <div className={container}>
                <Header>
                    <h1 className={headerTitleBox}>{t(EZLOGIC_HEADING_ACCOUNT_SETTING)}</h1>
                </Header>
                <section className={accountSectionWrapper}>
                    <Languages title={t(EZLOGIC_TITLE_LANGUAGE)} />
                    {oemId === SECURITY_OEM_ID && Pk_Permission_Role === ENDUSER_PERMISSION && (
                        <InstallerAccess onShowModal={toggleModal} saveButtonDisabled={saveButtonDisabled} />
                    )}
                </section>
            </div>
            {isModalVisible && (
                <InstallerAccessDisabledModal
                    onClose={toggleModal}
                    open={isModalVisible}
                    onDisableButtonClick={handleDisableButtonClick}
                />
            )}
        </BoxWithLinearProgress>
    );
};

export default AccountSettingsLayout;
