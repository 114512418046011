import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ControllerGroupsSelect from '../../../../components/ControllerGroupsSelect';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_HEADING_ACTIVE_GROUP } from '../../../../constants/language_tokens';

const ActiveGroupSection = (props) => {
    const { groups, selectedGroup, onSelectGroup } = props;
    const isGroupsAvailable = groups && Object.keys(groups).length > 0;
    const { t } = useTranslate();

    return (
        isGroupsAvailable && (
            <div className="active-group">
                <h2 className="active-group__title">{t(EZLOGIC_HEADING_ACTIVE_GROUP)}</h2>
                <ControllerGroupsSelect
                    groups={groups}
                    selectedGroup={selectedGroup}
                    onSelectGroup={onSelectGroup}
                    className="active-group__select"
                />
            </div>
        )
    );
};

ActiveGroupSection.propTypes = {
    groups: PropTypes.object,
    selectedGroup: PropTypes.object,
    onSelectGroup: PropTypes.func,
};

export default ActiveGroupSection;
