import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';

import InfoButton from '../InfoButton';
import InputNumberBlock from '../../../../../components/inputNumber';
import InputRecordingLengthValue from '../InputRecordingLengthValue';
import { MAKE_RECORDING } from '../../../../../constants/MeshbotConstant';
import { detectCameraCapabilitiesOfIntType } from '../utils';
import { info } from '../../utils';
import { useTranslate } from '../../../../../features/languages';

const InputBlockIntegerType = (props) => {
    const { fields, items, onUpdateFieldActionBlock, value } = props;
    const { t } = useTranslate();

    if (!detectCameraCapabilitiesOfIntType(fields, items)) {
        return (
            <InputNumberBlock
                value={value}
                step={1}
                label={info.hints.value}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <InfoButton>
                                {<div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>}
                            </InfoButton>
                        </InputAdornment>
                    ),
                }}
                onChange={onUpdateFieldActionBlock}
            />
        );
    }

    if (detectCameraCapabilitiesOfIntType(fields, items) === MAKE_RECORDING) {
        return <InputRecordingLengthValue value={value} onUpdateFieldActionBlock={onUpdateFieldActionBlock} />;
    } else {
        return null;
    }
};

export default InputBlockIntegerType;

InputBlockIntegerType.propTypes = {
    value: PropTypes.number,
    onUpdateFieldActionBlock: PropTypes.func,
    fields: PropTypes.array,
    items: PropTypes.array,
};
