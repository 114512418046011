import React from 'react';
import { Button } from '@material-ui/core';

import { useTranslate } from '../../../languages';
import { EZLOGIC_BUTTON_SAVE } from '../../../../constants/language_tokens';

import styles from '../../components/InstallerAccessRights/installerAccess.module.scss';

const InstallerAccessRightsSaveButton = ({ disabled, onClick }) => {
    const { t } = useTranslate();

    return (
        <div className={styles['button-container']}>
            <Button disabled={disabled} type="submit" fullWidth color="primary" variant="contained" onClick={onClick}>
                {t(EZLOGIC_BUTTON_SAVE)}
            </Button>
        </div>
    );
};

export default InstallerAccessRightsSaveButton;
