import React from 'react';
import { editButtonsWrapper, disabled, cancelEditing } from '../modalNotificationCategoryCreating.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_BUTTON_SAVE, NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE } from '../../../../../../constants/language_tokens';

export const NotificationButtonsForEditingCategory = (props) => {
    const { category, onSaveEditing, onCancelEditingCategory } = props;
    const { t } = useTranslate();

    return (
        <span className={editButtonsWrapper}>
            <button
                className={!category.display_name.length && disabled}
                disabled={!category.display_name.length}
                onClick={() => onSaveEditing(category.uuid)}
            >
                {t(EZLOGIC_BUTTON_SAVE)}
            </button>
            <button
                className={cancelEditing}
                onClick={() =>
                    onCancelEditingCategory({
                        uuid: category.uuid,
                        value: false,
                    })
                }
            >
                {t(NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE)}
            </button>
        </span>
    );
};
