import React from 'react';
import ErrorIcon from '../icons/ErrorIcon';
import classNames from 'classnames';
import {
    EZLOGIC_TITLE_NOT_SUPPORTED,
    EZLOGIC_TITLE_THE_BACKUP_IS_NOT_SUPPORTED,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import './style.scss';

const NotSupported = () => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <ErrorIcon className="icon-completed" />
            <span className={classNames('text', 'title')}>{t(EZLOGIC_TITLE_NOT_SUPPORTED)}</span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_THE_BACKUP_IS_NOT_SUPPORTED)}
            </span>
        </div>
    );
};

export default NotSupported;
