import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { ChromePicker } from 'react-color';

import EzloCustomization from '../../../../actions/CustomizationActions';
import { EZLOGIC_NAVIGATION_MENU_TEXT_COLOR } from '../constants';
import { useTranslate } from '../../../../features/languages';

import classes from './colorTheme.module.scss';

const ColorTheme = () => {
    const { t } = useTranslate();
    const newCustomizationData = useSelector((state) => state.customizationData);
    const updatedColor = newCustomizationData.ezlogicPortal?.colorTheme;
    const [colorsArray, setColorsArray] = useState(updatedColor);
    const dispatch = useDispatch();
    const [openColorPicker, setOpenColorPicker] = useState({});
    const popperRef = useRef(null);
    const handleChangeComplete = (color) => {
        const updatedColorsArray = colorsArray.map((colorObj) => {
            if (colorObj.name === openColorPicker.colorName) {
                return { ...colorObj, value: color.hex };
            }

            return colorObj;
        });

        setColorsArray(updatedColorsArray);
        dispatch(EzloCustomization.ezlogicColorAction(updatedColorsArray));
    };

    const handleClickOpen = (colorName, colorValue) => {
        setOpenColorPicker({
            ...openColorPicker,
            colorName,
            colorValue,
        });
    };

    const handleClickOutside = (event) => {
        const picker = event.target.closest('.picker-component');
        if (!picker) {
            setOpenColorPicker({});
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        setColorsArray(updatedColor);
    }, [updatedColor]);

    return (
        <form ref={popperRef} className={classes.root} noValidate autoComplete="off">
            {colorsArray?.map((color) => (
                <div className={classes.title} key={color?.name}>
                    <div className={classes.titleText}>{t(color?.name)}:</div>

                    <div className={classes.colorValue} onClick={() => handleClickOpen(color?.name, color?.value)}>
                        <div className={classes.colorBox} style={{ background: color?.value }}></div>
                        <div>
                            <Button key={color?.name} id={color?.name} className={classes.input}>
                                {color?.value}
                            </Button>
                        </div>
                    </div>
                    {openColorPicker?.colorName === color?.name && (
                        <ChromePicker
                            className="picker-component"
                            color={color?.value}
                            onChangeComplete={handleChangeComplete}
                            disableAlpha={true}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    )}

                    {openColorPicker.colorName === color?.name && (
                        <img
                            className={
                                color?.name === EZLOGIC_NAVIGATION_MENU_TEXT_COLOR
                                    ? classes.imgPortrait
                                    : classes.imgLandscape
                            }
                            src={color?.img}
                            alt={color?.name}
                        />
                    )}
                </div>
            ))}
        </form>
    );
};

export default ColorTheme;
