import React from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { TablePagination, Tooltip } from '@material-ui/core';
import classname from 'classnames';

import DeleteSelectedMeshBotsButton from '../DeleteSelectedMeshBotsButton';
import { LabelSelectedDropdown } from '../../../../../features/Labels/MeshBotsLabels/components/LabelSelectedDropdown';
import { EZLOGIC_TITLE_COLUMNS, EZLOGIC_TITLE_DENSITY } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import useMeshBotsTableContext from '../../hooks/useMeshBotsTableContext';
import MoreMenuButton from '../MoreMenuButton';

import styles from './styles.module.scss';

const MeshBotsTableToolbar = ({ paginationProps }) => {
    const { labelsPanelOpen } = useMeshBotsTableContext();
    const { t } = useTranslate();
    const classNames = classname(styles.meshBotsToolbarBox, { [styles.labelsPanelOpen]: labelsPanelOpen });

    return (
        <div className={classNames}>
            <GridToolbarContainer className={styles.gridToolbar}>
                <Tooltip title={t(EZLOGIC_TITLE_COLUMNS)}>
                    <GridToolbarColumnsButton />
                </Tooltip>
                <GridToolbarFilterButton />
                <Tooltip title={t(EZLOGIC_TITLE_DENSITY)}>
                    <GridToolbarDensitySelector />
                </Tooltip>
                <MoreMenuButton />
                <DeleteSelectedMeshBotsButton />
                <LabelSelectedDropdown />
            </GridToolbarContainer>
            <TablePagination
                component={'div'}
                {...paginationProps}
                rowsPerPageOptions={[paginationProps.rowsPerPage]}
            />
        </div>
    );
};

export default MeshBotsTableToolbar;
