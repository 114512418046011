import React, { useEffect, useState } from 'react';
import IcActionsIcIntegration from '../../../../../assets/icons/actions/ic_actions_ic-integration';
import styles from '../styles.module.scss';
import { NUCAL } from '../../../../../constants/Integrations';
import { integrationIcon } from '../../utils';

const EzloIntegrationsIcon = ({ icon, wrapperClassName, integrationType }) => {
    const [isImageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        if (integrationType === NUCAL) {
            const fetchImage = async () => {
                const isImageLoaded = await integrationIcon(icon);
                setImageLoaded(isImageLoaded);
            };

            fetchImage();
        }
    }, []);

    return (
        <>
            {icon && isImageLoaded ? (
                <div className={wrapperClassName} style={{ backgroundImage: `url(${icon})` }}></div>
            ) : (
                <span className={styles.noIcon}>
                    <IcActionsIcIntegration />
                </span>
            )}
        </>
    );
};

export default EzloIntegrationsIcon;
