import React, { useMemo } from 'react';
import { FieldArray, Form, useFormikContext } from 'formik';

import VirtualDevices from './VirtualDevices';
import SubmitButton from '../components/SubmitButton/SubmitButton';

import { isEmptyObject } from '../utils';
import { VIRTUAL_DEVICE_ARRAY_NAME } from '../../../../constants/VirtualDeviceConstant';

import styles from '../styles.module.scss';
import { EZLOGIC_BUTTON_CREATE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const VirtualDevicesForm = () => {
    const { handleSubmit, errors, submitForm } = useFormikContext();
    const { t } = useTranslate();
    const isDisabledSubmitButton = useMemo(() => {
        return !isEmptyObject(errors);
    }, [errors]);

    return (
        <Form className={styles.virtualDevicesForm} onSubmit={handleSubmit}>
            <FieldArray
                name={VIRTUAL_DEVICE_ARRAY_NAME}
                render={(arrayHelpers) => <VirtualDevices arrayHelpers={arrayHelpers} />}
            />
            <SubmitButton submitForm={submitForm} disabled={isDisabledSubmitButton}>
                {t(EZLOGIC_BUTTON_CREATE)}
            </SubmitButton>
        </Form>
    );
};

export default VirtualDevicesForm;
