import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import LockOpen from '@material-ui/icons/LockOpen';

import TimeInputsBlock from '../TimeInputsBlock';
import { InputMaterial } from '../../../../../components';
import PulseFunctionBlock from '../PulseFunctionBlock/PulseFunctionBlock';

import {
    MESHBOT_FUNCTION_FOR_SELECT_VALUES,
    optionsSelect,
    FOR,
    REPEAT,
    FOLLOW,
    PULSE,
    PULSE_FUNCTION_ITEM,
    SELECT_FUNCTION,
    LATCH,
    EDIT,
    MIN_FUNCTION_REPEAT_TIMES,
} from '../../../../../constants/MeshbotConstant';
import { getTriggerFunctionTypeList, info } from '../../utils';
import useFeatureData from '../../../../../hooks/useFeatureData';
import { CONTROLLER_FEATURES_NAMES } from '../../../../../constants/ControllerFeatures';

import {
    unselected,
    function_block,
    function_button,
    function_block__settings,
    function_block__select,
    not_selected,
    label,
    function_button_settings,
    info_button_wrapper,
    group_header_place,
} from './FunctionForTriggerBlock.module.scss';
import InfoButton from '../InfoButton';
import {
    EZLOGIC_TITLE_DELAY_RESET,
    EZLOGIC_TITLE_ENTER_FRIENDLY_NAME,
    EZLOGIC_TITLE_SELECT_FUNCTION,
    EZLOGIC_TITLE_WITHIN,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { FunctionIcon } from '../../../../../assets/icons';

const FunctionForTriggerBlockContainer = (props) => {
    const [selectedFunctionForType, setSelectedFunctionForType] = useState('least');
    const [timesNumber, setTimesNumber] = useState(MIN_FUNCTION_REPEAT_TIMES);
    const [seconds, setSeconds] = useState(0);
    const [selectedPulseType, setSelectedPulseType] = useState(PULSE_FUNCTION_ITEM.once);
    const [falsePeriodSeconds, setFalsePeriodSeconds] = useState(0);
    const [latchName, setLatchName] = useState('');
    const [advancedScenesStatus, advancedScenesVersion] = useFeatureData(CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES);
    const { t } = useTranslate();
    const {
        onUpdate,
        initState,
        triggerType,
        idFunction,
        ezloSerial,
        typeForm,
        currentMeshbot,
        MeshBotAction,
        showFunctionSettings,
        selectedFunctionType,
        onSetSelectedFunctionType,
        handleShowFunctionSettings,
        groupHeaderPlace,
    } = props;

    const functionTypeList = getTriggerFunctionTypeList(triggerType, advancedScenesStatus, advancedScenesVersion);
    useEffect(() => {
        if (initState) {
            const [functionType] = Object.keys(initState);
            const initValues = initState[functionType];
            const initSeconds = initValues.seconds || initValues.delayReset || initValues.truePeriod;

            onSetSelectedFunctionType(functionType);
            setSeconds(initSeconds);
            if (functionType === FOR) {
                setSelectedFunctionForType(initValues.method);
            }

            if (functionType === REPEAT || functionType === PULSE) {
                setTimesNumber(initValues.times ?? MIN_FUNCTION_REPEAT_TIMES);
            }

            if (functionType === PULSE) {
                if (initValues.hasOwnProperty('falsePeriod') && initValues.hasOwnProperty('times')) {
                    setSelectedPulseType(PULSE_FUNCTION_ITEM.repeat);
                    setFalsePeriodSeconds(initValues.falsePeriod);
                    setTimesNumber(initValues.times);
                } else if (initValues.hasOwnProperty('falsePeriod') && !initValues.hasOwnProperty('times')) {
                    setSelectedPulseType(PULSE_FUNCTION_ITEM.infinite);
                    setFalsePeriodSeconds(initValues.falsePeriod);
                } else {
                    setSelectedPulseType(PULSE_FUNCTION_ITEM.once);
                }
            }

            if (initState.latch) {
                setLatchName(initState.latch?.name);
            }
        }
    }, []);

    useEffect(() => {
        const functionData = {
            type: selectedFunctionType,
            method: selectedFunctionForType,
            timesNumber: +timesNumber,
            seconds,
            latchName,
        };
        if (selectedFunctionType === PULSE) {
            functionData.pulseFunctionPayload = {
                truePeriod: seconds,
                falsePeriod: falsePeriodSeconds,
                times: +timesNumber,
            };

            if (selectedPulseType === PULSE_FUNCTION_ITEM.once) {
                delete functionData.pulseFunctionPayload.falsePeriod;
                delete functionData.pulseFunctionPayload.times;
            }

            if (selectedPulseType === PULSE_FUNCTION_ITEM.infinite) {
                delete functionData.pulseFunctionPayload.times;
            }
        }

        onUpdate(functionData);
    }, [selectedFunctionType, selectedFunctionForType, seconds, timesNumber, falsePeriodSeconds, selectedPulseType]);

    useEffect(() => {
        const functionData = {
            type: selectedFunctionType,
            method: selectedFunctionForType,
            latchName,
        };

        onUpdate(functionData);
    }, [latchName]);

    const wrapperClasses = classNames(function_block, {
        [unselected]: selectedFunctionType === SELECT_FUNCTION,
        [group_header_place]: groupHeaderPlace && showFunctionSettings,
    });
    const functionTypeSelectClasses = classNames(function_block__select, {
        [not_selected]: selectedFunctionType === SELECT_FUNCTION,
    });

    const handleOnChangeFunctionType = (event) => {
        setSeconds(0);
        setTimesNumber(MIN_FUNCTION_REPEAT_TIMES);
        if (event.target.value === PULSE) {
            setSelectedPulseType(PULSE_FUNCTION_ITEM.once);
        }
        onSetSelectedFunctionType(event.target.value);
    };

    const handleOnChangeSelectFor = (event) => {
        setSelectedFunctionForType(event.target.value);
    };

    const handleSecondsUpdate = (seconds) => {
        setSeconds(seconds);
    };

    const handleOnChangeTimesNumber = (e) => {
        const functionTimes = parseInt(Math.abs(e.target.value)).toFixed(0);
        setTimesNumber(functionTimes);
    };

    const handleLatchName = (e) => {
        setLatchName(e.target.value);
    };

    const handleUnlatch = () => {
        const params = { sceneId: currentMeshbot?._id, blockId: idFunction };
        MeshBotAction.setUnLatch(ezloSerial, params);
    };

    return (
        <div className={wrapperClasses}>
            {showFunctionSettings && (
                <div className={function_block__settings}>
                    <IconButton
                        className={`${function_button} ${function_button_settings}`}
                        onClick={handleShowFunctionSettings}
                    >
                        <FunctionIcon />
                    </IconButton>
                    <Select
                        className={functionTypeSelectClasses}
                        MenuProps={optionsSelect}
                        placeholder={t(EZLOGIC_TITLE_SELECT_FUNCTION)}
                        value={selectedFunctionType}
                        onChange={handleOnChangeFunctionType}
                    >
                        {functionTypeList.map((item) => (
                            <MenuItem key={item.key} value={item.value}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                    <div className={info_button_wrapper}>
                        <InfoButton>
                            <div dangerouslySetInnerHTML={{ __html: t(info.text.triggerFunc) }} />
                        </InfoButton>
                    </div>

                    {selectedFunctionType === FOR && (
                        <Select
                            className={function_block__select}
                            MenuProps={optionsSelect}
                            placeholder={t(EZLOGIC_TITLE_SELECT_FUNCTION)}
                            value={selectedFunctionForType}
                            onChange={handleOnChangeSelectFor}
                        >
                            {MESHBOT_FUNCTION_FOR_SELECT_VALUES.map((item) => (
                                <MenuItem key={item.key} value={item.value}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    )}

                    {selectedFunctionType === REPEAT && (
                        <>
                            <InputMaterial
                                type="number"
                                value={timesNumber}
                                min={MIN_FUNCTION_REPEAT_TIMES}
                                step={1}
                                onChange={(e) => handleOnChangeTimesNumber(e)}
                                xsSmall
                                styleForFunctionBlock
                            />
                            <div className={label}>{t(EZLOGIC_TITLE_WITHIN)}</div>
                        </>
                    )}

                    {selectedFunctionType === FOLLOW && <div className={label}>{t(EZLOGIC_TITLE_DELAY_RESET)}</div>}

                    {selectedFunctionType !== PULSE &&
                        selectedFunctionType !== SELECT_FUNCTION &&
                        selectedFunctionType !== LATCH && (
                            <TimeInputsBlock
                                styleForFunctionBlock
                                onTimeUpdate={handleSecondsUpdate}
                                mainStateSeconds={seconds}
                            />
                        )}

                    {selectedFunctionType === PULSE && (
                        <PulseFunctionBlock
                            seconds={seconds}
                            handleSecondsUpdate={handleSecondsUpdate}
                            timesNumber={timesNumber}
                            handleOnChangeTimesNumber={handleOnChangeTimesNumber}
                            selectedPulseType={selectedPulseType}
                            setSelectedPulseType={setSelectedPulseType}
                            falsePeriodSeconds={falsePeriodSeconds}
                            setFalsePeriodSeconds={setFalsePeriodSeconds}
                            advancedScenesVersion={advancedScenesVersion}
                        />
                    )}

                    {selectedFunctionType === LATCH && (
                        <>
                            <InputMaterial
                                value={latchName}
                                placeholder={t(EZLOGIC_TITLE_ENTER_FRIENDLY_NAME)}
                                onChange={handleLatchName}
                                styleForFunctionBlock
                            />

                            {typeForm === EDIT && (
                                <IconButton aria-label="block" color="secondary" onClick={handleUnlatch}>
                                    <LockOpen />
                                </IconButton>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default FunctionForTriggerBlockContainer;

FunctionForTriggerBlockContainer.propTypes = {
    ezloSerial: PropTypes.string,
    typeForm: PropTypes.string,
    triggerType: PropTypes.string,
    idFunction: PropTypes.string,
    borderColor: PropTypes.string,
    currentMeshbot: PropTypes.object,
    initState: PropTypes.object,
    MeshBotAction: PropTypes.object,
    onUpdate: PropTypes.func,
    selectedFunctionType: PropTypes.string,
    onSetSelectedFunctionType: PropTypes.func.isRequired,
};
