import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../actions';
import CreateScript from './CreateScript';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    scriptNotification: state.meshBot.local.scriptNotification,
    errorsScript: state.meshBot.local.errorsScript,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateScript);
