import { connect } from 'react-redux';
import * as Actions from '../../../../../actions';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Marketplace from './Marketplace';

const mapStateToProps = (state) => ({
    marketplacePlugins: state.plugins.marketplacePlugins,
    loaderList: state.plugins.loaderList,
    isRequestStatus: state.plugins.isRequestStatus,
    isRequestStatusMessage: state.plugins.isRequestStatusMessage,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Marketplace);
