import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../actions';
import PluginWifiGenerator from './PluginWifiGenerator';
import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../components/HOCs/withErrorBoundary';
import { getControllers } from '../../../../helpers/helpersPlugins';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    loaderInstalled: state.plugins.loaderInstalled,
    wifiPluginList: state.plugins.wifiPluginList,
    controllers: getControllers(state.ezlo.data),
    listInstalledPlugins: state.plugins.listInstalledPlugins,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default withErrorBoundary(
    connect(mapStateToProps, mapDispatchToProps)(PluginWifiGenerator),
    FallbackMeshbotNodeComponent,
);
