// -------------- COLORS -------------- //
export const PRIMARY_COLOR = '#3E46AE';
export const LIGHT_PRIMARY_COLOR = '#1976D2';
export const NAVIGATION_MENU_TEXT_COLOR = '#697580';
export const PRIMARY_BUTTON_COLOR = '#3E46AE';
export const TRIGGER_ACTION_BG_COLOR = '#f9fafb';
export const LABEL_COLOR = '#697580';
export const WHITE_COLOR = '#ffffff';
export const ERROR_COLOR = '#e64d4e';
export const MAIN_DARK_COLOR = '#1C1E21';
export const INPUT_BORDER_BOTTOM_COLOR = '144, 160, 175';
export const SCROLLBAR_TRACK = '#f0f0f0';
export const SCROLLBAR = '#f5f5f5';
export const SCROLLBAR_THUMB = '#b0b0b0';

// -------------- FONT -------------- //
export const MAIN_FONT_FAMILY = 'Ezlo Sans';
export const FONT_WEIGHT_SEMI = '500';
export const FONT_WEIGHT_REGULAR = '400';

// -------------- BUTTONS -------------- //
export const SECONDARY_BUTTON_BG = '144, 160, 175';
export const SECONDARY_BUTTON_COLOR = '#90A0AF';
