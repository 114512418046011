import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UsersActions from '../../../../../actions/UsersActions';
import { EZLOGIC_TITLE_ARE_YOU_SURE_DELETE } from '../../../../../constants/language_tokens';
import { ACCOUNT_ADMIN_PERMISSION_ROLE } from '../../../../../constants/Users';
import { useTranslate } from '../../../../../features/languages';
import DeleteConfirmDialog from '../../components/DeleteConfirmDialog';

const UserDelete = ({ selectedUser }) => {
    const dispatch = useDispatch();
    const isDisabled = useSelector(({ account }) => account.data.PK_PermissionRole !== ACCOUNT_ADMIN_PERMISSION_ROLE);
    const { FirstName, Name, Username } = selectedUser;
    const UserName = FirstName && Name ? `${FirstName || ''} ${Name || ''}` : Username;
    const { t } = useTranslate();
    const deleteSelectedUser = () => {
        dispatch(UsersActions.deleteSelectedUser(selectedUser, t));
    };

    return (
        <DeleteConfirmDialog
            dialogText={`${t(EZLOGIC_TITLE_ARE_YOU_SURE_DELETE)} ${UserName}?`}
            confirmFn={deleteSelectedUser}
            isDisabled={isDisabled}
        />
    );
};

export default UserDelete;
