import React from 'react';

export const IcVisualsLink = () => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M92.5895 107.411L92.5894 107.411C85.6437 100.465 85.6437 89.2036 92.5894 82.2578L100.329 74.5186"
                stroke="#38A7C1"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M99.6714 125.481L82.3621 142.791C75.4164 149.736 64.1551 149.736 57.2093 142.791L57.2093 142.791C50.2636 135.845 50.2636 124.584 57.2093 117.638L82.2578 92.5894C89.2036 85.6436 100.465 85.6436 107.411 92.5894L107.411 92.5894"
                stroke="#3E46AE"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M107.411 92.5894L107.411 92.5894C114.356 99.5351 114.356 110.796 107.411 117.742L99.6714 125.481"
                stroke="#1F2581"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
            <path
                d="M100.329 74.5186L117.638 57.2093C124.584 50.2636 135.845 50.2636 142.791 57.2093L142.791 57.2093C149.736 64.1551 149.736 75.4164 142.791 82.3621L117.742 107.411C110.796 114.356 99.5351 114.356 92.5894 107.411"
                stroke="#50C1D6"
                strokeWidth="16"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
