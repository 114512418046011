import { Button } from '@material-ui/core';
import { COPY_AND_UPGRADE } from '../../constants/constants';
import {
    EZLOGIC_BUTTON_CONFIGURE,
    EZLOGIC_BUTTON_UNINSTALL,
    EZLOGIC_TITLE_UPDATE,
} from '../../../../../constants/language_tokens';
import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import style from '../ControllerTemplate/style.module.scss';

const ControllerTemplateButtons = (props) => {
    const {
        onHandlerShowConfigureModal,
        currentInstallPlugin,
        onHandlerRemovePlugin,
        currentPluginShouldUpdate,
        onHandlerShowUpdateModal,
        pluginWithUpdatedInformation,
        serial,
        isControllerInProcess,
    } = props;

    const { t } = useTranslate();

    return (
        <div className={style.installedPluginWrapper}>
            {currentPluginShouldUpdate && (
                <>
                    <Button
                        variant="contained"
                        className={`${isControllerInProcess ? style.upgradeBtnDisabled : style.upgradeBtn}`}
                        onClick={() =>
                            onHandlerShowUpdateModal(
                                {
                                    ...pluginWithUpdatedInformation,
                                    serial,
                                },
                                COPY_AND_UPGRADE,
                            )
                        }
                        disabled={isControllerInProcess}
                    >
                        {t(EZLOGIC_TITLE_UPDATE)}
                    </Button>
                </>
            )}
            <Button
                variant="contained"
                color="secondary"
                onClick={() => onHandlerRemovePlugin(serial)}
                disabled={isControllerInProcess}
            >
                {t(EZLOGIC_BUTTON_UNINSTALL)}
            </Button>
            {currentInstallPlugin.configuration.inputs && (
                <Button variant="contained" color="primary" onClick={() => onHandlerShowConfigureModal(serial)}>
                    {t(EZLOGIC_BUTTON_CONFIGURE)}
                </Button>
            )}
        </div>
    );
};

export default ControllerTemplateButtons;
