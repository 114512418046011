import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DeviceGroupPage from './components/DeviceGroupPage';
import DeviceGroupCreate from './components/DeviceGroupCreate';
// Temporarily hidden until a single data source is obtained regarding the list of integrated devices
// import DeviceGroupIntegratedDevicesPage from './components/DeviceGroupIntegratedDevicesPage';

const EzloDeviceGroups = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}`} component={DeviceGroupPage} exact />
            <Route path={`${url}/create`} component={DeviceGroupCreate} exact />
            <Route path={`${url}/edit`} render={(props) => <DeviceGroupCreate {...props} isEditing={true} />} exact />
            {/* Temporarily hidden until a single data source is obtained regarding the list of integrated devices */}
            {/* <Route path={`${url}/create/integrated-devices`} component={DeviceGroupIntegratedDevicesPage} /> */}
        </Switch>
    );
};

export default EzloDeviceGroups;
