import React from 'react';
import PropTypes from 'prop-types';

import { EZLOGIC_TITLE_DELETE_WARNING } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import DeleteConfirmDialog from '../../../EzloAccount/components/DeleteConfirmDialog';

const ExpressionDelete = ({ name, onDeleteExpression }) => {
    const { t } = useTranslate();

    return (
        <DeleteConfirmDialog
            dialogText={`${t(EZLOGIC_TITLE_DELETE_WARNING)} ${name}?`}
            confirmFn={onDeleteExpression}
        />
    );
};

export default ExpressionDelete;

ExpressionDelete.propTypes = {
    name: PropTypes.string,
    onDeleteExpression: PropTypes.func,
};
