import { createSlice } from '@reduxjs/toolkit';
import { MESHBOT_LABELS } from '../services/kvs';
import { LOGOUT } from '../constants/ActionTypes';

export const KVS_NAME = 'kvs';

const initialState = {
    [MESHBOT_LABELS]: {},
    isLoading: false,
};

const kvs = createSlice({
    name: KVS_NAME,
    initialState,
    reducers: {
        setMeshBotLabels: (state, action) => {
            state[MESHBOT_LABELS] = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});

export const { setMeshBotLabels, setIsLoading } = kvs.actions;
export default kvs;
export const kvsReducer = kvs.reducer;
