import React from 'react';
import './style.scss';
import SwitchBool from '../../../../../components/SwitchBool';
import { nameParser } from './index';

// TODO: Refactoring for class based component needed!
export default class DeviceHubSettingBool extends React.Component {
    state = {
        value: this.props.settings.value || false,
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    saveHandler = () => {
        const { onSave, settings } = this.props;
        const { value } = this.state;
        const data = {
            name: settings.name,
            value: value,
        };

        onSave(data);
    };

    onValueChange = () => {
        const { value } = this.state;

        this.setState({ value: !value }, () => this.saveHandler());
    };

    render() {
        const { settings } = this.props;
        const { value } = this.state;
        const { name } = settings;

        const formattedName = nameParser(name) || '';

        return (
            <div className="device-hub-settings device-hub-settings__wrapper">
                <h5 className="device-hub-settings__title">{formattedName}</h5>
                <SwitchBool noOffOn checked={value} onChange={this.onValueChange} />
            </div>
        );
    }
}
