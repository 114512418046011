import ResetPasswordTitle from '../ResetPasswordTitle';
import React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import { useFormik } from 'formik';

import { validationSchema } from '../../utils/validationSchema';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_PLEASE_ENTER_NEW_PASSWORD,
    EZLOGIC_TITLE_RESET_YOUR_PASSWORD,
} from '../../../../../constants/language_tokens';
import { useAuthContext } from '../../../context/AuthorizationContext';
import ActionsWrapper from '../../../components/ActionsWrapper';
import InvalidResetPasswordLink from '../InvalidResetPasswordLink';
import useValidationPasswordResetLink from '../../hooks/useValidationPasswordResetLink';
import AnimatedSpinner from '../../../../../components/AnimatedSpinner';

import styles from './ResetPasswordForm.module.scss';

const ResetPasswordFormContainer = ({ isLoading, onSubmit }) => {
    useAuthContext();
    const { t } = useTranslate();
    const { isResetPasswordLinkValid, isLinkValidating } = useValidationPasswordResetLink();
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnMount: true,
    });

    if (isLinkValidating) {
        return <AnimatedSpinner height={200} width={200} />;
    }

    if (!isResetPasswordLinkValid) {
        return <InvalidResetPasswordLink />;
    }

    return (
        <>
            <ResetPasswordTitle
                title={t(EZLOGIC_TITLE_RESET_YOUR_PASSWORD)}
                subTitle={t(EZLOGIC_TITLE_PLEASE_ENTER_NEW_PASSWORD)}
                className={styles.title}
            />
            <ActionsWrapper>
                <ResetPasswordForm isLoading={isLoading} formik={formik} />
            </ActionsWrapper>
        </>
    );
};

export default ResetPasswordFormContainer;
