import React from 'react';

import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Paper,
    Checkbox,
} from '@material-ui/core';

import { CUSTOM_PREFIX, IS_USE_VARIABLE_FIELD } from '../../constants';
import { MESHBOT_TYPES } from '../../../../../EzloMeshbots/constants';
import useMeshBotType from '../../../../hooks/useMeshBotType';
import { getVariableValueAsStr } from '../../utils';

import style from './CloudVariable.module.scss';
import {
    EZLOGIC_TITLE_USE_VARIABLE,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VARIABLE_NAME,
} from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

export const AdvancedTableWithResponse = ({ dataForTable, blackListVariable, handleSetBlackListVariable }) => {
    const { currentMeshBotType } = useMeshBotType();
    const isCloudMeshBot = currentMeshBotType === MESHBOT_TYPES.CLOUD;
    const { t } = useTranslate();
    const handleChange = (e) => {
        const { checked, name } = e.target;
        if (checked) {
            handleSetBlackListVariable(blackListVariable.filter((item) => item !== name));
        } else {
            handleSetBlackListVariable([...blackListVariable, name]);
        }
    };

    return (
        <>
            {dataForTable.length > 0 && (
                <div className={style.resultBlock}>
                    <div className={style.table}>
                        <TableContainer component={Paper}>
                            <Table aria-label="result table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t(EZLOGIC_TITLE_VARIABLE_NAME)}</TableCell>
                                        <TableCell>{t(EZLOGIC_TITLE_VALUE)}</TableCell>
                                        {isCloudMeshBot && IS_USE_VARIABLE_FIELD && (
                                            <TableCell align="center">{t(EZLOGIC_TITLE_USE_VARIABLE)}</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {dataForTable.map((row) => {
                                        const variableValue = getVariableValueAsStr(row.value);

                                        return (
                                            <TableRow key={row.name}>
                                                <TableCell scope="row">
                                                    {' '}
                                                    {row.name.replace(CUSTOM_PREFIX, '')}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        multiline
                                                        maxRows={1}
                                                        value={variableValue}
                                                        variant="outlined"
                                                        fullWidth
                                                        title={variableValue}
                                                        className={style.runTextResultBlock}
                                                    />
                                                </TableCell>
                                                {isCloudMeshBot && IS_USE_VARIABLE_FIELD && (
                                                    <TableCell scope="row">
                                                        <div className={style.checkboxBlock}>
                                                            <Checkbox
                                                                checked={!row.hasVariableInBlacklist}
                                                                name={row.name}
                                                                onChange={handleChange}
                                                                color="primary"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
        </>
    );
};
