import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../actions/InstallerAccessRights';
import { ENABLE, NULL_VALUE } from '../../constants';
import { addDurationToCurrentTime, compareDateTimes, formatTimeInAMPM } from '../../utils';
import { resetInstallerAccessState } from '../../reducers/installerAccessRights';

const useInstallerAccessRights = ({ onShowModal, saveButtonDisabled }) => {
    const installerAccessDeadlineData = useSelector(
        (state) => state.installerAccessStore?.installerAccessRightDeadlineData,
    );
    const installerAccessStatus = useSelector((state) => state.installerAccessStore?.installerAccessStatus);
    const initialAccessStatus = useSelector((state) => state.installerAccessStore?.initialAccessStatus);
    const dispatch = useDispatch();

    const [toggleValue, setToggleValue] = useState(false);
    const [selectedTime, setSelectedTime] = useState(NULL_VALUE);
    const [enableDropdown, setEnableDropdown] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [finalTime, setFinalTime] = useState('');

    useEffect(() => {
        if (saveButtonDisabled) {
            setEnableSaveButton(!saveButtonDisabled);
        }
    }, [saveButtonDisabled]);

    useEffect(() => {
        dispatch(actions.getInstallerAccessRightsDeadlineData(true));
        dispatch(actions.getIntallerAccessStatus);
        const currentTime = moment();
        const isAccessEnabled = compareDateTimes(currentTime, installerAccessDeadlineData);
        const formattedTime = formatTimeInAMPM(installerAccessDeadlineData);
        if (installerAccessStatus === ENABLE && isAccessEnabled) {
            setToggleValue(true);
            setFinalTime(formattedTime?.toLocaleString());
        } else {
            setFinalTime('');
            setToggleValue(false);
        }
    }, [installerAccessStatus, installerAccessDeadlineData]);

    useEffect(() => {
        return () => {
            dispatch(resetInstallerAccessState());
        };
    }, []);

    const handleTimeSelection = (event) => {
        setSelectedTime(event.target.value);
        setEnableSaveButton(event.target.value !== NULL_VALUE && toggleValue);
    };

    const handleToggleSwitch = () => {
        setEnableSaveButton(false);
        if (toggleValue) {
            setToggleValue(false);
            setEnableDropdown(false);
            setFinalTime('');
            setSelectedTime(NULL_VALUE);
            if (initialAccessStatus === ENABLE) {
                setEnableSaveButton(true);
            }
        } else {
            setEnableDropdown(true);
            setToggleValue(true);
            setSelectedTime('');
            setFinalTime('');
            setEnableSaveButton(selectedTime !== NULL_VALUE);
        }
    };

    const handleSaveButtonClick = () => {
        if (toggleValue) {
            const currentTime = moment();
            const payloadTime = addDurationToCurrentTime(currentTime, selectedTime);
            const formattedTime = formatTimeInAMPM(payloadTime);
            setFinalTime(formattedTime);
            dispatch(actions.handleUploadInstallerAccessRightsDeadlineData(payloadTime));
            dispatch(actions.uploadInstallerAccess(ENABLE));
            setEnableSaveButton(false);
        } else {
            if (onShowModal) {
                onShowModal();
            }
        }
        setEnableDropdown(false);
    };

    return {
        toggleValue,
        selectedTime,
        enableDropdown,
        enableSaveButton,
        finalTime,
        handleTimeSelection,
        handleToggleSwitch,
        handleSaveButtonClick,
    };
};

export default useInstallerAccessRights;
