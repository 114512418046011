import wsm from '../helpers/wsm';
import * as types from '../constants/ActionTypes/Backups';
import { fetchStorageListByDefaultBucket, fetchStorageListByControllerBackupsBucket } from '../api/apiBackups';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { BackupActions } from './index';

const actions = {
    fetchDeviceBackups: () => async (dispatch) => {
        try {
            let backups = [];

            const defaultBackups = await fetchStorageListByDefaultBucket();
            const controllerBackups = await fetchStorageListByControllerBackupsBucket();

            if (defaultBackups.error && controllerBackups.error) {
                throw new Error(`Error: ${defaultBackups.error}, ${controllerBackups.error}`);
            }

            if (!defaultBackups.error) {
                backups = [...backups, ...defaultBackups];
            }

            if (!controllerBackups.error) {
                backups = [...backups, ...controllerBackups];
            }

            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_SUCCESS, backups });
        } catch (err) {
            bugsnagNotifyWrapper(err, { type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR });
            dispatch({ type: types.BACKUPS_FETCH_DEVICE_BACKUPS_ON_ERROR, error: err });
        }
    },

    createBackup:
        (serial, params = {}) =>
        async (dispatch) => {
            dispatch(
                BackupActions.subscribeBackupProgress(
                    serial,
                    dispatch(BackupActions.onBackupCreateProgressBroadcast(serial)),
                ),
            );
            await wsm.send(
                serial,
                'hub.backup.create',
                params,
                () => {
                    dispatch({ type: types.BACKUPS_CREATE_BACKUP }); // backup started!
                },
                (error) => {
                    bugsnagNotify(error, { type: types.BACKUPS_CREATE_BACKUP_ON_ERROR, serial, params });
                    dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_ERROR }); // backup failed!
                },
            );
        },

    selectBackup: (uuid) => (dispatch, getState) => {
        const state = getState();
        const { backups } = state.backups;
        const selectedBackup = backups.find((backup) => backup.uuid === uuid);
        dispatch({ type: types.BACKUPS_SELECT_BACKUP, selectedBackup });
    },

    clearSelectedBackup: () => (dispatch) => {
        dispatch({ type: types.BACKUPS_CLEAR_SELECTED_BACKUP });
    },

    selectDefaultBackup: (uuid) => (dispatch, getState) => {
        const state = getState();
        const { backups } = state.backups;
        const selectedBackup = backups.find((backup) => backup.uuid === uuid);
        dispatch({ type: types.BACKUPS_SELECT_DEFAULT_BACKUP, selectedBackup });
    },

    restoreBackup: () => (dispatch) => {
        new Promise(() => {
            dispatch({ type: types.BACKUPS_RESTORE_BACKUP });
        });
    },

    confirmRestoreBackup: (serial) => async (dispatch, getState) => {
        dispatch(
            BackupActions.subscribeRestoreProgress(
                serial,
                dispatch(BackupActions.onBackupRestoreProgressBroadcast(serial)),
            ),
        );
        const backup = getState().backups.selectedBackup;

        await wsm.send(
            serial,
            'hub.backup.restore',
            {
                key: backup.key,
                uuid: backup.uuid,
            },
            () => {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_CONFIRM });
            },
            (error) => {
                bugsnagNotify(error, { type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR, serial });
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR });
            },
        );
    },

    setBackupSupported: (isBackupSupported) => (dispatch) => {
        dispatch({ type: types.BACKUPS_IS_BACKUP_SUPPORTED, isBackupSupported });
    },

    onFinish: () => (dispatch) => {
        dispatch({ type: types.BACKUPS_ON_FINISH });
    },

    onBackupCreateProgressBroadcast: (serial) => (dispatch) => {
        return ({ result }) => {
            if (result.status === 'started' || result.status === 'process') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_IN_PROGRESS, result });
            } else if (result.status === 'finished') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_SUCCESS, result });
                dispatch(actions.fetchDeviceBackups());
                dispatch(BackupActions.unsubscribeBackupProgress(serial));
            } else if (result.status === 'aborted') {
                dispatch({ type: types.BACKUPS_CREATE_BACKUP_ON_ERROR, result });
                dispatch(BackupActions.unsubscribeBackupProgress(serial));
            }
        };
    },

    onBackupRestoreProgressBroadcast: (serial) => (dispatch) => {
        return ({ result }) => {
            if (result.status === 'started' || result.status === 'process') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_IN_PROGRESS, result });
            } else if (result.status === 'finished') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_SUCCESS, result });
                dispatch(BackupActions.unsubscribeRestoreProgress(serial));
            } else if (result.status === 'aborted') {
                dispatch({ type: types.BACKUPS_RESTORE_BACKUP_ON_ERROR, result });
                dispatch(BackupActions.unsubscribeRestoreProgress(serial));
            }
        };
    },

    subscribeBackupProgress: (serial, cb) => () => {
        return wsm.subscribe(serial, 'hub.backup.create.progress', cb);
    },

    unsubscribeBackupProgress: (serial) => () => {
        return wsm.unsubscribe(serial, 'hub.backup.create.progress');
    },

    subscribeRestoreProgress: (serial, cb) => () => {
        return wsm.subscribe(serial, 'hub.backup.restore.progress', cb);
    },

    unsubscribeRestoreProgress: (serial) => () => {
        return wsm.unsubscribe(serial, 'hub.backup.restore.progress');
    },
};

export default actions;
