import {
    AV,
    CLOUD_VARIABLE,
    UNSUPPORTED_COMPARATOR,
    VALUE_TYPE,
    STATIC,
    ON_CHANGE,
} from '../../../../../constants/MeshbotConstant';
import { VALUE } from '../../../../../constants/DevicePluginGenerator';
import { COMPARED_CLOUD_VARIABLE, NUMERIC } from '../../constants';
import { STRING } from '../SaveOutputBlock/constants';
import { ZERO } from '../../../EzloCustomization/constants';
import { BOOLEAN_TYPE } from '../../../../../constants/Expressions';

const buildCloudVariableVsStaticTriggerBlockStructure = (
    selectedAbstract,
    selectedComparator,
    comparingValue,
    selectedVariable,
    variableValueType,
) => {
    return {
        name: selectedComparator,
        parameters: [
            {
                name: AV,
                parameters: [
                    {
                        abstract: selectedAbstract?.uuid,
                        capability: selectedVariable,
                        variable: VALUE,
                        transform: variableValueType,
                    },
                ],
            },
            {
                name: STATIC,
                parameters: [
                    {
                        value: comparingValue,
                        transform: variableValueType,
                    },
                ],
            },
        ],
    };
};

const buildCloudVariableVsCloudVariableTriggerBlockStructure = (
    selectedAbstract,
    selectedVariableCompared,
    selectedComparator,
    selectedVariable,
    selectedAbstractForCompared,
    variableValueType,
) => {
    return {
        name: selectedComparator,
        parameters: [
            {
                name: AV,
                parameters: [
                    {
                        abstract: selectedAbstract?.uuid,
                        capability: selectedVariable,
                        variable: VALUE,
                        transform: variableValueType,
                    },
                ],
            },
            {
                name: AV,
                parameters: [
                    {
                        abstract: selectedAbstractForCompared?.uuid,
                        capability: selectedVariableCompared,
                        variable: VALUE,
                        transform: variableValueType,
                    },
                ],
            },
        ],
    };
};

const buildCloudVariableDefaultTriggerBlockStructure = () => {
    return {
        name: '',
        parameters: [
            {
                name: AV,
                parameters: [
                    {
                        abstract: '',
                        capability: '',
                        variable: VALUE,
                        transform: '',
                    },
                ],
            },
        ],
    };
};

const buildCloudVariableTriggerBlockStructureWithAbstractAndVariable = (
    selectedAbstract,
    selectedVariable,
    variableValueType,
) => {
    return {
        name: '',
        parameters: [
            {
                name: AV,
                parameters: [
                    {
                        abstract: selectedAbstract?.uuid,
                        capability: selectedVariable,
                        variable: VALUE,
                        transform: variableValueType,
                    },
                ],
            },
        ],
    };
};

const buildCloudVariableTriggerBlockStructureWithSelectedComparator = (
    selectedComparator,
    selectedVariable,
    selectedAbstract,
    variableValueType,
) => {
    return {
        name: selectedComparator,
        parameters: [
            {
                name: AV,
                parameters: [
                    {
                        abstract: selectedAbstract?.uuid,
                        capability: selectedVariable,
                        variable: VALUE,
                        transform: variableValueType,
                    },
                ],
            },
        ],
    };
};

const buildCloudVariableTriggerBlockStructureWithOnChangeSelectedComparator = (
    selectedComparator,
    selectedVariable,
    selectedAbstract,
    variableValueType,
) => {
    return {
        name: selectedComparator,
        parameters: [
            {
                abstract: selectedAbstract?.uuid,
                capability: selectedVariable,
                variable: VALUE,
                transform: variableValueType,
            },
        ],
    };
};

export const buildCloudVariableTriggerBlockStructure = (data) => {
    const {
        selectedAbstract,
        selectedComparator,
        comparingValue,
        selectedVariable,
        selectedValueType,
        selectedVariableCompared,
        selectedAbstractForCompared,
        variableValueType,
    } = data;

    if (selectedVariable && selectedAbstract && selectedComparator === undefined) {
        return buildCloudVariableTriggerBlockStructureWithAbstractAndVariable(
            selectedAbstract,
            selectedVariable,
            variableValueType,
        );
    }

    if (selectedComparator === ON_CHANGE) {
        return buildCloudVariableTriggerBlockStructureWithOnChangeSelectedComparator(
            selectedComparator,
            selectedVariable,
            selectedAbstract,
            variableValueType,
        );
    }

    if (selectedComparator && selectedValueType === undefined) {
        return buildCloudVariableTriggerBlockStructureWithSelectedComparator(
            selectedComparator,
            selectedVariable,
            selectedAbstract,
            variableValueType,
        );
    }

    if (selectedValueType === VALUE_TYPE) {
        return buildCloudVariableVsStaticTriggerBlockStructure(
            selectedAbstract,
            selectedComparator,
            comparingValue,
            selectedVariable,
            variableValueType,
        );
    }

    if (selectedValueType === CLOUD_VARIABLE) {
        return buildCloudVariableVsCloudVariableTriggerBlockStructure(
            selectedAbstract,
            selectedVariableCompared,
            selectedComparator,
            selectedVariable,
            selectedAbstractForCompared,
            variableValueType,
        );
    }

    if (selectedValueType && selectedValueType !== VALUE_TYPE && selectedValueType !== CLOUD_VARIABLE) {
        throw new Error(UNSUPPORTED_COMPARATOR);
    }

    return buildCloudVariableDefaultTriggerBlockStructure();
};

/**
 * Function that collects updated data in the correct format to update this data in the store
 * @param {Object} variableData - Selected variable data(cloudVariableName, abstract, integrationUuid)
 * @param {String} type - Field type for which the variable is selected
 * @returns {Object} - Object with updated data that was collected depending on the type
 * @example {
 *     selectedIntegrationId: 'gj7566jhgv',
 *     selectedAbstract: {
 *          uuid: 'd98f149f-3841-4af6-9602-a66a9c19c70c',
 *          name: 'EzloWeather',
 *          capabilities: ['custom_idLesia', 'custom_temperatureLesia', 'custom_cityIdLesia', 'custom_temperature78'],
 *          details: {
 *              source: 'integration',
 *              integration_uuid: 'a4bdb8d9-393d-417e-af0b-4989c2387b99',
 *          },
 *          return: '',
 *     },
 *     selectedComparator: null,
 *     comparingValue: '',
 *     selectedVariable: 'custom_cityIdLesia',
 * }
 */
export const getUpdatedCloudVariableProperties = (variableData, type) => {
    const { cloudVariableName, abstract, integrationUuid } = variableData;

    if (type === COMPARED_CLOUD_VARIABLE) {
        return {
            selectedIntegrationIdForCompared: integrationUuid,
            selectedAbstractForCompared: abstract,
            comparingValue: '',
            selectedVariableCompared: cloudVariableName,
            selectedValueType: CLOUD_VARIABLE,
        };
    }

    return {
        selectedIntegrationId: integrationUuid,
        selectedAbstract: abstract,
        selectedComparator: null,
        comparingValue: '',
        selectedVariable: cloudVariableName,
    };
};

/**
 * Function that return data type after getting value
 * @param {String} value - get Current Value result (value)
 * @example {
 * when you select the variable it return Current value
 *     value : 'gj7566jhgv',
 * then the function check the value and returns its type string or intiger
 * }
 */

export const determineVariableValueDataType = (value) => {
    if (value) {
        return getDataTypeofValue(value);
    }

    return null;
};

const getDataTypeofValue = (value) => {
    const isString = isNaN(value);

    if (isString || value?.length === ZERO || typeof value === BOOLEAN_TYPE) {
        return STRING;
    } else {
        return NUMERIC;
    }
};
