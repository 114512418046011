import React from 'react';
import { Modal } from '@material-ui/core';
import { confirmationModal } from '../../../../../components/ConfirmationModal/ConfirmationModal.module.scss';
import { EZLOGIC_LOADING_PLESE_WAIT } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

export const WaitModal = (props) => {
    const { isOpened } = props;
    const { t } = useTranslate();

    return (
        <Modal open={isOpened}>
            <div className={confirmationModal}>{t(EZLOGIC_LOADING_PLESE_WAIT)}</div>
        </Modal>
    );
};
