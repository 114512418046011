import { useSelector } from 'react-redux';
import { DEVICE_ASSOCIATIONS } from '../../constants/DeviceAssociations';

const useFeatureSupportFirmware = (version) => {
    const serial = useSelector((state) => state.ezlo.serial);
    const firmwareVersion =
        useSelector((state) => state.ezlo?.data[serial]?.firmware) ||
        DEVICE_ASSOCIATIONS.ALTERNATIVE_FIRMWARE_BUILD_VERSION;
    const currentVersion = Number(firmwareVersion.split('.')[DEVICE_ASSOCIATIONS.FIRMWARE_BUILD_VERSION_INDEX]);

    return currentVersion === DEVICE_ASSOCIATIONS.FIRMWARE_DEVELOPMENT_BUILD_VERSION || currentVersion >= version;
};

export default useFeatureSupportFirmware;
