import React, { useEffect, useReducer } from 'react';

import { SelectedComponent } from './SelectedComponent';
import { MapTypeGroup } from './MapTypeGroup';
import { AdvancedMapType } from './AdvancedMapType';
import SimpleCodeContainer from './SimpleCodeContainer';
import TestVariables from '../../../ResponseTree/components/TestVariables';
import { updateCloudVariableState } from '../../utils';
import { mapTypes, CLOUD_VARIABLE_INITIAL_STATE } from '../../template';
import { useActionSelect } from '../../../../../EzloRule/EditForm/RuleSettings/components/PAAS/hooks/useActionSelect';
import { buildCloudVariableInitialState } from '../../../../../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { initialState, reducer } from './store/reducer';
import { SET_MAP_CONF } from './store/action';

import style from './CloudVariable.module.scss';
import { EZLOGIC_TITLE_TEST_YOUR_VARIABLE } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

export const VariableContainer = ({
    id,
    blockName,
    onSetCode,
    integrationPreviewResult,
    meshBotType,
    handleSetBlackListVariable,
}) => {
    const [cloudVariableState, dispatch] = useReducer(reducer, initialState);
    const { mappingState, initialMethod } = cloudVariableState;
    const { code, mapType, codeArray, selectedSaveOutput, isSimpleDisabled } = mappingState;
    const currentActionData = useActionSelect(id, meshBotType, blockName);
    const { methodValue, code: currentActionCode } = currentActionData;
    const { t } = useTranslate();
    const handleSetMappingConfig = (value, methodValue) => {
        dispatch({
            type: SET_MAP_CONF,
            payload: {
                mappingState: value,
                initialMethod: methodValue,
            },
        });
    };
    const clearMappingConfig = () => {
        handleSetMappingConfig(CLOUD_VARIABLE_INITIAL_STATE, null);
        onSetCode(undefined);
    };
    useEffect(() => {
        if (currentActionCode) {
            const result = buildCloudVariableInitialState(currentActionCode);
            handleSetMappingConfig(result, methodValue);
        }
    }, []);
    useEffect(() => {
        if (!selectedSaveOutput && (codeArray.length || code)) {
            clearMappingConfig();
        }
    }, [selectedSaveOutput]);
    useEffect(() => {
        if (initialMethod !== null && initialMethod !== methodValue) {
            clearMappingConfig();
        }
    }, [methodValue]);
    const handleChangeState = (value, itemKey, valueAdditional, itemKeyAdditional) => {
        const updatedState = updateCloudVariableState(mappingState, itemKey, value, itemKeyAdditional, valueAdditional);
        handleSetMappingConfig(updatedState);
    };
    const defaultProps = {
        currentActionData,
        onSetCode,
        handleSetBlackListVariable,
        onChangeState: handleChangeState,
        mappingState,
    };

    return (
        <div className={style.wrapper}>
            <div className={style.topBlock}>
                <SelectedComponent selectedSaveOutput={selectedSaveOutput} onChangeState={handleChangeState} />
                {selectedSaveOutput && (
                    <MapTypeGroup
                        mapType={mapType}
                        onSetCloudVariableState={handleChangeState}
                        disabled={isSimpleDisabled}
                    />
                )}
            </div>
            {selectedSaveOutput && (
                <div className={style.mainBlock}>
                    {mapType === mapTypes.SIMPLE && (
                        <SimpleCodeContainer
                            integrationPreviewResult={integrationPreviewResult}
                            handleSetMappingConfig={handleSetMappingConfig}
                            {...defaultProps}
                            ComponentOfToTestVariables={<TestVariables advancedProps={defaultProps} />}
                        />
                    )}
                    {mapType === mapTypes.ADVANCED && (
                        <AdvancedMapType
                            {...defaultProps}
                            buttonText={t(EZLOGIC_TITLE_TEST_YOUR_VARIABLE)}
                            withTextarea={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
