import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import NotificationActions from '../../../../actions/NotificationActions';
import { buildNotificationTemplatesTableColumns } from '../utils';
import EzloOEMNotificationTemplateTable from '../EzloOEMNotificationTemplateTable';
import { useTranslate } from '../../../../features/languages';

const EzloOEMNotificationTemplatesTableContainer = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { notificationTemplates, uuid, onDuplicate, onEdit, handleSetActive, onDelete } = props;

    useEffect(() => {
        dispatch(NotificationActions.getNotificationTemplates());
    }, []);

    const [columns] = useState(
        buildNotificationTemplatesTableColumns(onDuplicate, onEdit, handleSetActive, onDelete).map((colData) => ({
            ...colData,
            headerName: t(colData?.headerName),
        })),
    );

    return (
        <EzloOEMNotificationTemplateTable notificationTemplates={notificationTemplates} uuid={uuid} columns={columns} />
    );
};

export default EzloOEMNotificationTemplatesTableContainer;
