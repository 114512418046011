import React from 'react';

import SpecialTypeSelect from '../components/SpecialTypeSelect';
import withDraggingState from '../HOC/withDraggingState';

import styles from '../styles.module.scss';

const SpecialTypeLabel = ({
    handleDragStart,
    elementData,
    treeItemName,
    treeItemValue,
    selectedType,
    handleTypeChange,
    handleDragEnd,
}) => {
    return (
        <div
            className={styles.treeItemLabelBox}
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            data-element={elementData}
        >
            {treeItemName}
            <SpecialTypeSelect
                treeItemValue={treeItemValue}
                selectedType={selectedType}
                handleTypeChange={handleTypeChange}
            />
        </div>
    );
};

export default withDraggingState(SpecialTypeLabel);
