import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import MeshBotLocalForm from './MeshBotLocalForm';
import at from '../../../../../constants/ActionTypes/MeshBot';

const mapStateToProps = (state) => {
    const { serial } = state.ezlo;
    const controller = state.ezlo.data[serial];

    return {
        rooms: controller?.pages,
        serial: state.ezlo.serial,
        model: controller?.model,
        devices: controller && controller.devices,
        devicesForAction: controller && controller.devicesForAction,
        items: controller && controller.items,
        listDevicesBlocks: state.meshBot.local.listDevicesBlocks,
        ruleTriggers: state.meshBot.local.ruleTriggers,
        expressionList: state.ezlo.data?.[state.ezlo.serial]?.expressions,
        [at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions]: state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions],
        [at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions]: state.meshBot.local[at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions],
        initialRule: state.meshBot.local.initialRule,
        scenesList: state.ezlo?.data?.[serial]?.rules,
        channels: state.notifications.channels,
        users: state.notifications.users,
        uuids: state.notifications.uuids,
        listLuaScripts: state.meshBot.listLuaScripts,
        scriptNotification: state.meshBot.local.scriptNotification,
        interfaceJSONItems: state.meshBot.interfaceJSONItems,
        errorsScript: state.meshBot.local.errorsScript,
        exceptions: state.meshBot.local.exceptions,
        isWsRequestStatus: state.meshBot.isWsRequestStatus,
        isWsRequestStatusMessage: state.meshBot.isWsRequestStatusMessage,
        isDirty: state.main.editableComponent?.isDirty,
        optionType: state.meshBot.local.selectedRule?.optionType,
        itemGroupsStatus: state.meshBotV2.local.flagsContainer.itemGroupsStatus,
        subscriptionStatus: state.meshBotV2.local.flagsContainer.subscriptionStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(MeshBotLocalForm);
