import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Input from '../../../../../../../../../components/InputMaterial';
import { TextField } from '../../../../../../../../../components';

import { apiGetEnumList, apiGetEnumListForTrigger } from '../../PaasAPI/paas-api-requests';
import { createKeyValueEnumSelectOptions, createListEnumSelectOptions, getTypeOfField } from './utils';
import { SelectBlock } from './components/Select';
import { SwitchComponent } from './components/SwitchComponent';
import { FieldDescription } from './components/FieldDescription';
import ArrayTypeField from './ArrayTypeField';
import {
    CALLBACK_METHODS,
    TYPE_FIELD_ARRAY,
    TYPE_FIELD_BOOLEAN,
    TYPE_FIELD_ENUM,
    TYPE_FIELD_LIST,
    TYPE_FIELD_STRING,
} from '../../paas-constants';

import style from './DynamicField.module.scss';

const EnumListOption = (props) => {
    const { id, name } = props;

    return (
        <div className={style.select_option}>
            <p className={style.option_name}>{name}</p>
            <p>{id}</p>
        </div>
    );
};

export const DynamicField = (props) => {
    const {
        value,
        accountUuid,
        isRequired,
        propertyName,
        description,
        enumListKey,
        enumListValue,
        enumListMethod,
        onDynamicFieldChange,
        enumListByFunctionPath,
        type,
        enumValue,
        property,
    } = props;

    const [enumList, setEnumList] = useState([]);
    const [isEnumListLoading, setIsEnumListLoading] = useState(false);
    const [initialEnumItem, setInitialEnumItem] = useState(null);

    const isKeyValueEnumType = Boolean(enumListKey && enumListValue);
    // TODO: apply refactoring
    const isListEnumType = enumListByFunctionPath === '.list';

    useEffect(() => {
        const initialEnumItem = enumList?.find(({ id }) => id === value);
        if (initialEnumItem) {
            setInitialEnumItem(initialEnumItem);
        }

        if (!initialEnumItem && value) {
            setInitialEnumItem({ id: value, name: value });
        }
    }, [value]);

    // TODO: refactoring needed!
    useEffect(() => {
        // TODO: move outside
        // TODO: no direct API calls from the component
        const fetchEnumList = async () => {
            const enumList = await apiGetEnumList(accountUuid, enumListMethod);
            if (CALLBACK_METHODS.includes(enumListMethod)) {
                await apiGetEnumListForTrigger(accountUuid, enumListMethod);
            }

            let enumListSelectOptions;

            if (isListEnumType) {
                enumListSelectOptions = createListEnumSelectOptions(enumList);
            }

            if (isKeyValueEnumType) {
                enumListSelectOptions = createKeyValueEnumSelectOptions(enumList, enumListKey, enumListValue);
            }

            const initialEnumItem = enumListSelectOptions?.find(({ id }) => id === value);

            setEnumList(enumListSelectOptions);
            setIsEnumListLoading(false);

            if (initialEnumItem) {
                setInitialEnumItem(initialEnumItem);
            }
        };

        if (enumListMethod) {
            setIsEnumListLoading(true);
            fetchEnumList();
        }
    }, []);

    const handleChangeDynamicField = (e, option) => {
        onDynamicFieldChange(option?.id, propertyName);
    };

    const fieldType = getTypeOfField(enumListMethod, enumValue, type);

    if (fieldType === TYPE_FIELD_LIST && (isKeyValueEnumType || isListEnumType)) {
        return (
            <div className={style.dynamicField}>
                <FieldDescription description={description} isRequired={isRequired} />
                <Autocomplete
                    value={initialEnumItem}
                    options={enumList || []}
                    getOptionLabel={(option) => (option.name ? option.name : '')}
                    getOptionSelected={(option, value) => option.name === value.name}
                    onChange={handleChangeDynamicField}
                    disableClearable={true}
                    loading={isEnumListLoading}
                    renderInput={(params) => (
                        <TextField variant="standard" className={style.dynamicFieldAutocomplete} {...params} />
                    )}
                    renderOption={({ id, name }) => <EnumListOption id={id} name={name} />}
                />
            </div>
        );
    }

    if (fieldType === TYPE_FIELD_ARRAY) {
        return (
            <ArrayTypeField
                value={value}
                fieldSchema={property}
                fieldName={propertyName}
                description={description}
                isRequired={isRequired}
                onChange={onDynamicFieldChange}
            />
        );
    }

    return (
        <div className={style.dynamicField}>
            {fieldType === TYPE_FIELD_ENUM && (
                <SelectBlock
                    enumValue={enumValue}
                    label={description}
                    isRequired={isRequired}
                    propertyName={propertyName}
                    onChange={(e) => onDynamicFieldChange(e.target.value, e.target.name)}
                    value={value}
                />
            )}

            {fieldType === TYPE_FIELD_BOOLEAN && (
                <>
                    <SwitchComponent
                        label={description}
                        isRequired={isRequired}
                        propertyName={propertyName}
                        onChange={onDynamicFieldChange}
                        value={value}
                    />
                </>
            )}
            {fieldType === TYPE_FIELD_STRING && (
                <>
                    <FieldDescription description={description} isRequired={isRequired} />
                    <Input
                        name={propertyName}
                        value={value}
                        variant="standard"
                        otherClass={style.dynamicFieldInput}
                        onChange={(e) => onDynamicFieldChange(e.target.value, e.target.name)}
                    />
                </>
            )}
            {fieldType === TYPE_FIELD_LIST && (!isKeyValueEnumType || !isListEnumType) && (
                <>
                    <FieldDescription description={description} isRequired={isRequired} />
                    <Input
                        name={propertyName}
                        value={value}
                        variant="standard"
                        otherClass={style.dynamicFieldInput}
                        onChange={(e) => onDynamicFieldChange(e.target.value, e.target.name)}
                    />
                </>
            )}
        </div>
    );
};
