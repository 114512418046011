import React, { useState } from 'react';

export const AuthorizationContext = React.createContext();

export const AuthorizationContextProvider = (props) => {
    const [authContext, setAuthContext] = useState();

    return (
        <AuthorizationContext.Provider value={[authContext, setAuthContext]}>
            {props.children}
        </AuthorizationContext.Provider>
    );
};
