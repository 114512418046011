import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Dialog, Slide, Paper, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import InfoButton from '../../../InfoButton';

import { MeshBotAction } from '../../../../../../../actions';
import { getSelectedSaveOutput, getSelectedVariable } from '../../utils';
import { info } from '../../../../utils';
import EzloVariables from '../../../../../EzloExpressions/EzloVariables';
import { SET_VARIABLE } from '../../../../../../../constants/MeshbotConstant';
import { getExpressionsSelector } from '../../../../../EzloExpressions/utils';

import style from '../../style.module.scss';
import {
    EZLOGIC_TITLE_CHOOSE_A_VARIABLE,
    EZLOGIC_TITLE_CREATE_NEW_VARIABLE,
    EZLOGIC_TITLE_SAVE_TO_LOCAL_VARIABLE,
    EZLOGIC_TITLE_THE,
    EZLOGIC_TITLE_VARIABLES,
    EZLOGIC_TITLE_VARIABLE_NO_LONGER_EXISTS,
} from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';
import { CloseIcon } from '../../../../../../../assets/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorText = ({ blocks, t }) => {
    const variableName = blocks[0]?.saveResult?.args?.name || '';

    return (
        <span className={style.errorText}>
            {t(EZLOGIC_TITLE_THE)} `{variableName}` {t(EZLOGIC_TITLE_VARIABLE_NO_LONGER_EXISTS)}.
        </span>
    );
};

export const LocalVariable = ({ blocks, isEditing, id, blockName }) => {
    const dispatch = useDispatch();
    const existingVariables = useSelector(getExpressionsSelector);

    const [open, setOpen] = useState(false);
    const { t } = useTranslate();
    const selectedVariable = getSelectedVariable(blocks, existingVariables);
    const selectedSaveOutput = getSelectedSaveOutput(blocks);

    const updateSaveResult = (value, isSaveResult = true) => {
        dispatch(
            MeshBotAction.updateActionSaveResult(
                {
                    id,
                    blockName,
                    method: SET_VARIABLE,
                    value,
                },
                isSaveResult,
            ),
        );
    };

    useEffect(() => {
        if (selectedVariable === undefined && !isEditing) {
            updateSaveResult({}, selectedSaveOutput);
        }
    }, [selectedVariable]);

    const handleChangeVariable = (e, variable) => {
        if (variable?.name) {
            const variableArgs = {
                name: variable.name,
            };
            if (variable?.valueType) {
                variableArgs.valueType = variable.valueType;
            }
            updateSaveResult({ ...variableArgs });
        }
    };

    const getOptionSelected = (option, value) => option?.name === value?.name;
    const getOptionLabel = (option) => (option?.name ? option.name : '');
    const getRenderInput = (params) => {
        return (
            <TextField
                {...params}
                placeholder={t(EZLOGIC_TITLE_CHOOSE_A_VARIABLE)}
                label={t(EZLOGIC_TITLE_VARIABLES)}
            />
        );
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleChangeSaveOutput = (e) => {
        const checkedValue = e.target.checked;
        updateSaveResult({}, checkedValue);
    };

    const PaperWithCreateButton = (props) => (
        <Paper className={style.paper}>
            <div className={style.paperContent}>{props.children}</div>
            <div onMouseDown={handleOpenDialog} className={style.createButton}>
                + {t(EZLOGIC_TITLE_CREATE_NEW_VARIABLE)}
            </div>
        </Paper>
    );

    return (
        <>
            <div className={style.checkboxBlock}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={style.checkBox}
                            checked={selectedSaveOutput}
                            onChange={handleChangeSaveOutput}
                            color="primary"
                        />
                    }
                    label={t(EZLOGIC_TITLE_SAVE_TO_LOCAL_VARIABLE)}
                />
                <div className={style.infoButtonWrapper}>
                    <InfoButton>
                        <div dangerouslySetInnerHTML={{ __html: t(info.text.saveResultNuCALInVariableText) }}></div>
                    </InfoButton>
                </div>
            </div>
            {selectedSaveOutput && (
                <div className={style.selectBlock}>
                    <FormControl>
                        <Autocomplete
                            options={existingVariables}
                            getOptionLabel={getOptionLabel}
                            value={selectedVariable || ''}
                            getOptionSelected={getOptionSelected}
                            onChange={handleChangeVariable}
                            size="small"
                            renderInput={getRenderInput}
                            disableClearable
                            className={style.autocompleteSelect}
                            PaperComponent={PaperWithCreateButton}
                        />
                        {selectedVariable === undefined && isEditing && <ErrorText blocks={blocks} t={t} />}
                    </FormControl>
                </div>
            )}

            {open && (
                <Dialog fullScreen open={open} onClose={handleCloseDialog} TransitionComponent={Transition}>
                    <div className={style.toolBar}>
                        <div className={style.toolBarContent}>
                            <div className={style.title}>{t(EZLOGIC_TITLE_VARIABLES)}</div>
                            <div className={style.iconBox} onClick={handleCloseDialog}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>

                    <EzloVariables withHeader={false} />
                </Dialog>
            )}
        </>
    );
};

LocalVariable.propTypes = {
    blocks: PropTypes.array,
    isEditing: PropTypes.bool,
    infoButtonText: PropTypes.node,
    onUpdateSaveResult: PropTypes.func,
};
