import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import DisplayIcon from '@material-ui/icons/Airplay';
import ContactSupport from '@material-ui/icons/Message';
import {
    supportListItem,
    supportListItemIcon,
    supportListItemText,
    supportListItemTitle,
    supportListItemWrapper,
    supportListWrapper,
    createTicketButton,
} from '../EzloSupport.module.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    EZLOGIC_TITLE_CONTACT_AS_FOR_HELP,
    EZLOGIC_TITLE_DEALER_SUPPORT_CENTER,
    EZLOGIC_TITLE_EMAIL,
    EZLOGIC_TITLE_HERE,
    EZLOGIC_TITLE_OPEN_A_TICKET,
    EZLOGIC_TITLE_SUPPORT_TICKET,
    EZLOGIC_TITLE_WEBSITE,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

export const SupportOptionsList = () => {
    const supportData = useSelector((state) => state.supportData);
    const history = useHistory();
    const { supportWebsite = '', supportPhone = '', supportEmail = '' } = supportData;
    const { t } = useTranslate();

    const onOpenCreateTicketPage = () => {
        history.push('/ezlo/create-ticket');
    };

    return (
        <ul className={supportListWrapper}>
            {supportPhone && (
                <li className={supportListItemWrapper}>
                    <PhoneIcon className={supportListItemIcon} />
                    <p className={supportListItem}>
                        <span className={supportListItemTitle}>{supportPhone}</span>
                        <span className={supportListItemText}>{t(EZLOGIC_TITLE_CONTACT_AS_FOR_HELP)}</span>
                    </p>
                </li>
            )}
            {supportEmail && supportWebsite && (
                <li className={supportListItemWrapper}>
                    <DisplayIcon className={supportListItemIcon} />
                    <p className={supportListItem}>
                        <span className={supportListItemTitle}>{t(EZLOGIC_TITLE_DEALER_SUPPORT_CENTER)}</span>
                        <p>
                            <span className={supportListItemText}>
                                {t(EZLOGIC_TITLE_EMAIL)}: {supportEmail}
                            </span>
                        </p>
                        <p>
                            <span className={supportListItemText}>
                                {t(EZLOGIC_TITLE_WEBSITE)}: {supportWebsite}
                            </span>
                        </p>
                    </p>
                </li>
            )}

            <li className={supportListItemWrapper}>
                <ContactSupport className={supportListItemIcon} />
                <p className={supportListItem}>
                    <span className={supportListItemTitle}>{t(EZLOGIC_TITLE_SUPPORT_TICKET)}</span>
                    <span className={supportListItemText}>
                        {t(EZLOGIC_TITLE_OPEN_A_TICKET)}{' '}
                        <span className={createTicketButton} onClick={onOpenCreateTicketPage}>
                            {t(EZLOGIC_TITLE_HERE)}
                        </span>
                    </span>
                </p>
            </li>
        </ul>
    );
};
