import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import { getRoomName } from '../../../../EzloDevice/utils';
import {
    checkbox,
    currentRoomName,
    optionWrapper,
    roomNameWrapper,
} from '../../../../EzloAccount/UserManagement/style.module.scss';

const CheckboxesList = ({ selected, option, roomList }) => {
    return (
        <li className={optionWrapper}>
            <Checkbox className={checkbox} size="medium" color="primary" checked={selected} />
            <span className={roomNameWrapper}>
                {option.name}
                <span className={currentRoomName}>Current room: {getRoomName(roomList, option?.roomId)}</span>
            </span>
        </li>
    );
};
export default CheckboxesList;
