import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { info } from '../../../../utils';

import { useTranslate } from '../../../../../../../features/languages';
import { EZLOGIC_TITLE_SET_VARIABLE_CAPABILITY } from '../../../../../../../constants/language_tokens';

const LocalVariableCapabilityField = () => {
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={t(EZLOGIC_TITLE_SET_VARIABLE_CAPABILITY)}
            label={info.hints.capability}
            info={info.text.capability}
        >
            <MenuItem value={t(EZLOGIC_TITLE_SET_VARIABLE_CAPABILITY)}>
                {t(EZLOGIC_TITLE_SET_VARIABLE_CAPABILITY)}
            </MenuItem>
        </SelectComponent>
    );
};

export default LocalVariableCapabilityField;
