import { useCallback, useMemo, useState } from 'react';
import { filterObjectsListBySearchValue } from '../../../../../services/utilityService';
import { LABEL_FIELDS } from '../../constants';

const useFilterLabelsBySearchValue = (labels) => {
    const [searchValue, setSearchValue] = useState('');

    const handleChangeSearch = useCallback(({ target }) => {
        setSearchValue(target.value);
    }, []);

    const filteredLabels = useMemo(() => {
        return filterObjectsListBySearchValue(searchValue, labels, LABEL_FIELDS.NAME);
    }, [labels, searchValue]);

    return { searchValue, handleChangeSearch, filteredLabels };
};

export default useFilterLabelsBySearchValue;
