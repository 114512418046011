import React, { useCallback, useEffect, useMemo } from 'react';
import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import HeaderComponent from '../../components/Header';
import { CREATE_PIN_CODE, EDIT_PIN_CODE, SUCCESS } from '../../../../../constants/Users';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import UsersActions from '../../../../../actions/UsersActions';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@material-ui/icons';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import AddButton from '../../components/AddButton';
import PinCodeDelete from '../PinCodeDelete';
import { getSortPinCodes } from '../utils';

import styles from './style.module.scss';
import {
    EZLOGIC_BUTTON_CREATE_PIN_CODE,
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_PIN_CODE_FOR,
    EZLOGIC_TITLE_PIN_NAME,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { PersonIcon } from '../../../../../assets/icons';

const PinCodesPage = () => {
    const { t } = useTranslate();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        requestStatus,
        requestStatusMessage,
        selectedUser: { Name, FirstName, Username },
        selectedUserPinCodes,
    } = useSelector(({ users }) => users);

    const userName = FirstName && Name ? `${FirstName} ${Name}` : Username;

    const updatePinCodesList = () => {
        dispatch(UsersActions.setUserPinCodesList());
    };

    useEffect(() => {
        updatePinCodesList();

        return () => {
            dispatch(UsersActions.clearSelectedUserPinCodes());
        };
    }, []);

    useEffect(() => {
        if (requestStatus === SUCCESS) {
            toast(requestStatusMessage, { type: TOAST_TYPE.SUCCESS });
            dispatch(UsersActions.clearRequestStatus());
        }
    }, [requestStatus]);

    const pinCodes = useMemo(() => {
        return getSortPinCodes(selectedUserPinCodes);
    }, [selectedUserPinCodes]);

    const handleRouteToPage = useCallback((type, params) => {
        if (type === CREATE_PIN_CODE) {
            history.push(`${location.pathname}/create`);
        }

        if (type === EDIT_PIN_CODE) {
            dispatch(UsersActions.setSelectedPinCode(params));
            history.push(`${location.pathname}/edit/`);
        }
    }, []);

    return (
        <div className={styles.pinCodesContainer}>
            <HeaderComponent title={t(EZLOGIC_TITLE_PIN_CODE_FOR)}>
                <div className={styles.buttonBox}>
                    <AddButton
                        label={t(EZLOGIC_BUTTON_CREATE_PIN_CODE)}
                        handleClick={() => handleRouteToPage(CREATE_PIN_CODE)}
                    />
                </div>
            </HeaderComponent>

            <div className={styles.personBox}>
                <PersonIcon className={styles.personIcon} />
                {userName || ''}
            </div>

            <div className={styles.tableContainer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t(EZLOGIC_TITLE_PIN_NAME)}</TableCell>
                                <TableCell className={styles.headerIconCell}>{t(EZLOGIC_BUTTON_EDIT)}</TableCell>
                                <TableCell className={styles.headerIconCell}>{t(EZLOGIC_TITLE_DELETE)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pinCodes.map((pinCode) => (
                                <TableRow key={pinCode.uuid}>
                                    <TableCell>{pinCode.name || ' - '}</TableCell>
                                    <TableCell align="center" className={styles.iconCell}>
                                        <IconButton onClick={() => handleRouteToPage(EDIT_PIN_CODE, pinCode)}>
                                            <Edit fontSize="large" color="primary" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center" className={styles.iconCell}>
                                        <PinCodeDelete pinCode={pinCode} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default withErrorBoundary(PinCodesPage, FallbackMeshbotNodeComponent);
