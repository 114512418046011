import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { AuthorizationContext } from '../../context/AuthorizationContext';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_LOGIN } from '../../../../constants/language_tokens';
import { useGoToLogin } from '../../hooks/useGoToLogin';

import styles from './LoginButtonContainer.module.scss';
import { readFirstLoginConfig, removeFirstLoginConfig } from '../../FirstLoginPage/utils/utils';

export const LoginButtonContainer = (props) => {
    const { t } = useTranslate();
    const [goToLogin] = useGoToLogin();
    const [authContext] = useContext(AuthorizationContext);

    const handleLogin = () => {
        // default config
        let config = authContext;
        const firstLoginPageConfig = readFirstLoginConfig();
        removeFirstLoginConfig();
        if (firstLoginPageConfig) {
            config = firstLoginPageConfig;
        }
        goToLogin(config);
    };

    const buttonLabel = useMemo(() => {
        if (props.label) {
            return props.label;
        }

        return t(EZLOGIC_TITLE_LOGIN);
    }, [props.label]);

    return (
        <Button variant="contained" color="primary" className={styles.loginBtn} onClick={handleLogin}>
            {buttonLabel}
        </Button>
    );
};

LoginButtonContainer.propTypes = {
    label: PropTypes.string,
};
