import React from 'react';
import { RESTORE_BACKUP_STEPS as STEPS } from '../constants';
import BackupRestoreContent from '../BackupRestoreContent';
import BackupRestoreCompletedContent from '../BackupRestoreCompletedContent';
import BackupRestoreProgressContent from '../BackupRestoreProgressContent';
import BackupRestoreErrorContent from '../BackupRestoreErrorContent';
import './style.scss';

const RestoreBackup = ({
    isCurrentControllerConnected,
    onOpenRestoreBackupModal,
    timeOffset,
    backup,
    step,
    progress,
    onFinish,
}) => {
    switch (step) {
        case STEPS.IN_PROGRESS:
            return <BackupRestoreProgressContent progress={progress} />;
        case STEPS.BACKUP_RESTORED:
            return <BackupRestoreCompletedContent backup={backup} timeOffset={timeOffset} handleFinish={onFinish} />;
        case STEPS.BACKUP_ERROR:
            return <BackupRestoreErrorContent handleFinish={onFinish} />;
        default:
            return (
                <BackupRestoreContent
                    backup={backup}
                    timeOffset={timeOffset}
                    isCurrentControllerConnected={isCurrentControllerConnected}
                    handleOpenRestoreBackupModal={onOpenRestoreBackupModal}
                />
            );
    }
};

export default RestoreBackup;
