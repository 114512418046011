import React, { memo, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';

import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';
import styles from '../../styles.module.scss';
import {
    EZLOGIC_BUTTON_CREATE,
    EZLOGIC_HEADING_CREATE_VIRTUAL_DEVICE,
    EZLOGIC_TITLE_CREATE_VIRTUAL_DEVICE_DESCRIPTION,
} from '../../../../../constants/language_tokens';

const SubmitButton = ({ disabled, submitForm, children }) => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            submitForm();
        }
        setIsConfirmModalVisible(false);
    };

    const handleButtonClick = useCallback(() => {
        setIsConfirmModalVisible(true);
    }, []);

    return (
        <div className={styles.submitButton}>
            <Button onClick={handleButtonClick} disabled={disabled} variant="contained" color="primary">
                {children}
            </Button>

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={EZLOGIC_HEADING_CREATE_VIRTUAL_DEVICE}
                text={EZLOGIC_TITLE_CREATE_VIRTUAL_DEVICE_DESCRIPTION}
                confirmButtonTitle={EZLOGIC_BUTTON_CREATE}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </div>
    );
};

export default memo(SubmitButton);
