import './style.scss';
import React, { Component } from 'react';
import { t } from '../../../../../helpers/language';
import { checkOwnerPermission } from '../../../../../helpers/permissions';
import { GET_EZLO, MAIN } from '../../../../../constants/URLs';
import { Button } from '@material-ui/core';
import ConfirmCustomDialog from '../../../../../components/ConfirmCustomDialog/ConfirmCustomDialog';
import { bugsnagNotify } from '../../../../ErrorBoundary/utils';
import { EZLOGIC_TITLE_REMOVE_YOUR_HUB } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

export function WithTranslaion(DisableRemoveSettings) {
    return function MakeTraslationFn(props) {
        const useTranslateHook = useTranslate();

        return <DisableRemoveSettings {...props} useTranslate={() => useTranslateHook} />;
    };
}
class DisableRemoveSettings extends Component {
    state = {
        showTurnOffConfirmDialog: false,
        showRebootConfirmDialog: false,
        showRemoveAllConfirmDialog: false,
        showCleanConfirmDialog: false,
        showRemoveConfirmDialog: false,
        isRemovingAll: false,
        isCleaning: false,
        isRemoving: false,
        clearDeviceInfoChecked: false,
    };

    cleanEzlo = () => {
        const { serial } = this.props.redux.state;
        const {
            // connectEzlo,
            cleanEzlo,
            getEzloInfo,
            updateEzloProperties,
        } = this.props.redux.actions.EzloActions;
        const { linkTo, showError } = this.props.redux.actions.GenericActions;

        this.setState({
            isCleaning: true,
        });
        cleanEzlo(serial)
            .then(() => {
                this.setState({
                    isCleaning: false,
                });

                getEzloInfo(serial).then((info) => {
                    updateEzloProperties(serial, info.properties || {});
                    // connectEzlo(serial);
                    linkTo(GET_EZLO(serial));
                });
            })
            .catch((e) => {
                bugsnagNotify(e, { serial });
                showError(e);
            });
    };

    removeEzlo() {
        const { groupKey, isControllerOnline, closeSettingsDialog, group, deleteGroup } = this.props;
        const { actions, state } = this.props.redux;
        const { serial } = state;
        const { removeController } = actions.EzloActions;
        const { t } = this.props.useTranslate();

        closeSettingsDialog();
        removeController(serial, group, isControllerOnline, deleteGroup, groupKey, t);
    }

    unbindEzlo = () => {
        const { serial } = this.props.redux.state;
        const { email } = this.props.redux.state.account;
        // const { ezlos } = this.props.redux.state;
        const { unbindUser, removeEzloFromState } = this.props.redux.actions.EzloActions;
        const { linkTo, showError } = this.props.redux.actions.GenericActions;

        this.setState({
            isUnbinding: true,
        });

        unbindUser(serial, email, serial)
            .then(() => {
                this.setState({
                    isUnbinding: false,
                    showUnbindConfirmDialog: false,
                });

                removeEzloFromState(serial);

                linkTo(MAIN);
            })
            .catch((e) => {
                bugsnagNotify(e, { serial, email });
                showError(e);
            });
    };

    removeAllDevices = () => {
        const { removeAllDevices } = this.props.redux.actions.EzloActions;
        const { serial } = this.props.redux.state;
        const { showNotification, showError } = this.props.redux.actions.GenericActions;
        this.setState({
            isRemovingAll: true,
        });

        removeAllDevices(serial)
            .then(() => {
                setTimeout(() => {
                    showNotification(t`ezlo.remove_all_devices.success_message`);

                    this.setState({
                        showRemoveAllConfirmDialog: false,
                        isRemovingAll: false,
                    });
                }, 1000);
            })
            .catch((e) => {
                bugsnagNotify(e, { serial });
                this.setState({
                    isRemovingAll: false,
                });

                showError(e);
            });
    };

    confirmTurnOff = () => {
        const { serial } = this.props.redux.state;
        const { powerOffEzlo } = this.props.redux.actions.EzloActions;
        const { linkTo, showError } = this.props.redux.actions.GenericActions;

        powerOffEzlo(serial)
            .then(() => {
                this.hideConfirmDialog('TurnOff');
                setTimeout(() => {
                    linkTo(MAIN);
                }, 5000);
            })
            .catch((e) => {
                bugsnagNotify(e, { serial });
                showError(e);
            });
    };

    confirmReboot = () => {
        const { serial } = this.props.redux.state;
        const { rebootEzlo } = this.props.redux.actions.EzloActions;
        const { showNotification, showError } = this.props.redux.actions.GenericActions;

        this.props.closeSettingsDialog();

        rebootEzlo(serial)
            .then(() => {
                this.hideConfirmDialog('Reboot');
                showNotification(t`dialog_title_hub_reboot`);
            })
            .catch((e) => showError(e));
    };

    showConfirmDialog(dialogName) {
        this.setConfirmDialogVisibility(dialogName, true);
    }

    hideConfirmDialog(dialogName) {
        this.setConfirmDialogVisibility(dialogName, false);
    }

    setConfirmDialogVisibility(dialogName, visibility) {
        this.setState({
            [`show${dialogName}ConfirmDialog`]: visibility,
        });
    }

    switchClearDeviceInfoCheckbox = () => {
        this.setState({
            clearDeviceInfoChecked: !this.state.clearDeviceInfoChecked,
        });
    };

    render() {
        const { showRebootConfirmDialog, showRemoveConfirmDialog } = this.state;
        const { isControllerOnline } = this.props;
        const isOwnerPermission = checkOwnerPermission('ezlo');

        const RebootConfirmDialog = (
            <ConfirmCustomDialog
                maxWidth="xs"
                title={t`ezlo.msgbox.title.reboot_ezlo`}
                open={showRebootConfirmDialog}
                onClose={this.hideConfirmDialog.bind(this, 'Reboot')}
                onClick={this.confirmReboot}
            >
                <div className="confirm-custom-dialog__text">
                    <span>{t`ezlo.msgbox.message.reboot_ezlo`}</span>
                </div>
            </ConfirmCustomDialog>
        );

        const RemoveConfirmDialog = isOwnerPermission && (
            <ConfirmCustomDialog
                maxWidth="xs"
                title={t(EZLOGIC_TITLE_REMOVE_YOUR_HUB)}
                open={showRemoveConfirmDialog}
                onClose={this.hideConfirmDialog.bind(this, 'Remove')}
                onClick={this.removeEzlo.bind(this)}
            >
                <div className="confirm-custom-dialog__text">
                    <span>{t`ezlo.generic.are_you_sure_remove`}</span>
                </div>
            </ConfirmCustomDialog>
        );

        return (
            <div className="disable-remove-settings-block">
                <div className="maintenance-remove-block">
                    <div className="maintenance-remove__button-box">
                        <Button
                            disabled={!isControllerOnline}
                            fullWidth
                            className="maintenance-remove__button"
                            onClick={this.showConfirmDialog.bind(this, 'Reboot')}
                        >
                            {t`ezlo.msgbox.button.reboot_ezlo`}
                        </Button>
                    </div>
                    {RebootConfirmDialog}

                    {isOwnerPermission && (
                        <div className="maintenance-remove__button-box">
                            <Button
                                fullWidth
                                className="maintenance-remove__button additional"
                                onClick={this.showConfirmDialog.bind(this, 'Remove')}
                            >
                                {t`ezlo.button.remove_ezlo.title`}
                            </Button>
                        </div>
                    )}
                    {RemoveConfirmDialog}
                </div>
            </div>
        );
    }
}

export default WithTranslaion(DisableRemoveSettings);
