import React, { useState } from 'react';
import { EZLOGIC_TITLE_CHANGELOG } from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';
import PluginInformationChangelogList from '../PluginInformationChangelogList/PluginInformationChangelogList';
import styles from '../styles.module.scss';

const PluginInformationChangelogContainer = ({ changelog }) => {
    const { t } = useTranslate();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const handleToggleChangelog = () => {
        setIsOpenMenu((prevOpen) => !prevOpen);
    };

    return (
        <div className={styles.changelogWrapper}>
            <button className={styles.btn} onClick={handleToggleChangelog}>
                {t(EZLOGIC_TITLE_CHANGELOG)}
            </button>
            {isOpenMenu && <PluginInformationChangelogList changelog={changelog} />}
        </div>
    );
};

export default PluginInformationChangelogContainer;
