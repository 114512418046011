import { useMemo } from 'react';
import { isArray } from 'lodash';
import { createCloudMeshBotForTable, createMeshBotForTable, getUpdatedCloudMeshbots } from '../../utils';
import useMeshBotsFiltering from '../useMeshBotsFiltering';

const useMeshBotsState = (cloudMeshBot, rules, isMeshBotsLoading, isSystemMeshBotsChecked) => {
    const localMeshBotsForTable = useMemo(() => {
        if (!isArray(rules)) {
            return [];
        }

        return createMeshBotForTable(rules);
    }, [rules]);

    const cloudMeshBotsForTable = useMemo(() => {
        if (!isArray(cloudMeshBot)) {
            return [];
        }
        const cloudMeshsBotForTable = createCloudMeshBotForTable(cloudMeshBot);

        return getUpdatedCloudMeshbots(cloudMeshsBotForTable, isSystemMeshBotsChecked);
    }, [cloudMeshBot, isSystemMeshBotsChecked]);

    const allMeshBotsForTable = useMemo(() => {
        return [...cloudMeshBotsForTable, ...localMeshBotsForTable].filter((item) => item.id);
    }, [cloudMeshBotsForTable, localMeshBotsForTable]);

    const allMeshBotsWithFiltering = useMeshBotsFiltering(allMeshBotsForTable);

    const isMeshBots = useMemo(() => {
        return Boolean(localMeshBotsForTable.length || cloudMeshBot.length);
    }, [localMeshBotsForTable.length, cloudMeshBot.length]);

    const isEmptyMeshBots = useMemo(() => !isMeshBotsLoading && !isMeshBots, [isMeshBotsLoading, isMeshBots]);

    return {
        allMeshbots: allMeshBotsWithFiltering,
        isEmptyMeshBots,
        isMeshBots,
    };
};

export default useMeshBotsState;
