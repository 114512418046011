import React from 'react';

import { Header } from '../../../components';
import { EzlosDropdown } from '../../../containers';
import { Navigation } from '../../../containers';

import styles from './EzloOffline.module.scss';
import { EzloOfflineIcon } from '../../../assets/icons';

const EzloOffline = (props) => {
    const { currentEzlo } = props.redux.state;

    return (
        <>
            <Navigation />
            <Header headerClassName={styles.ezloOfflineHeader}>
                <EzlosDropdown currentEzlo={currentEzlo} />
            </Header>
            <div className={styles.ezloOfflineWrapper}>
                <section>
                    <EzloOfflineIcon className={styles.ezloOfflineIcon} />
                    <div className={styles.ezloOfflineContent}>
                        <h1>{`${currentEzlo?.PK_Device} is offline`}</h1>
                        <p className={styles.ezloOfflineSubtitle}>
                            For some reason your hub is not currently connected
                        </p>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EzloOffline;
