import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';

import {
    getActionExecutionPolicy,
    getExecutionPoliciesByLevel,
    getExecutionPolicyInfoByLevel,
} from '../MeshbotLocal/MeshBotLocalForm/utils';
import { ACTION_EXECUTION_POLICY_LEVELS } from '../constants';
import { MeshBotAction } from '../../../../actions';

const useExecutionPolicyState = (executionPolicyLevel, id, blockType) => {
    const dispatch = useDispatch();
    const exec_policy = useSelector(({ meshBot }) => {
        return getActionExecutionPolicy(meshBot.local.selectedRule, executionPolicyLevel, id, blockType);
    });
    const { actionExecutionPolicies, executionPolicyInfo } = useMemo(
        () => ({
            actionExecutionPolicies: getExecutionPoliciesByLevel(executionPolicyLevel),
            executionPolicyInfo: getExecutionPolicyInfoByLevel(executionPolicyLevel),
        }),
        [executionPolicyLevel],
    );

    const handlerChangeExecPolicy = useCallback(
        ({ target }) => {
            const { value } = target;

            if (executionPolicyLevel === ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION) {
                dispatch(MeshBotAction.setExecPolicyForAction(value, id, blockType));
            }

            if (executionPolicyLevel === ACTION_EXECUTION_POLICY_LEVELS.SCENE) {
                dispatch(MeshBotAction.setExecPolicyForActions(value));
            }
        },
        [executionPolicyLevel],
    );

    return useMemo(
        () => ({
            executionPolicyInfo,
            exec_policy,
            handlerChangeExecPolicy,
            actionExecutionPolicies,
        }),
        [exec_policy, actionExecutionPolicies, executionPolicyInfo],
    );
};

export default useExecutionPolicyState;
