import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { CONTROLLER_NODE_CAPABILITIES } from '../../../../../../../constants/MeshbotConstant';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const ControllerCapabilitySelect = (props) => {
    const { onHandleControllerCapabilityChange, initialControllerNodeCapabilityValue } = props;
    const { t } = useTranslate();

    return (
        <SelectComponent
            label={info.hints.capability}
            info={info.text.capability}
            value={initialControllerNodeCapabilityValue ?? ''}
            onChange={onHandleControllerCapabilityChange}
        >
            {CONTROLLER_NODE_CAPABILITIES.map((capability) => {
                return (
                    <MenuItem key={capability.id} value={capability.value}>
                        {t(capability.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ControllerCapabilitySelect;
