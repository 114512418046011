import React from 'react';
import { optionsSelect } from '../../constants/MeshbotConstant';
import { MenuItem, Select } from '@material-ui/core';
import oemManager from '../../services/oem';
import { buildEnvironmentsSelectOptions } from './utils';

const EnvironmentsSelect = (props) => {
    const { onChange, defaultValue } = props;
    const envs = buildEnvironmentsSelectOptions(oemManager.appConfig.getEnvironments());

    return (
        <Select onChange={onChange} defaultValue={defaultValue} MenuProps={optionsSelect}>
            {envs.map(({ value, label }) => {
                return (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default EnvironmentsSelect;
