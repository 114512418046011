import React from 'react';
import { info } from '../../../utils';
import { MenuItem } from '@material-ui/core';
import { localComparatorsTrig } from '../../../../../../constants/rulesInSelect';
import SelectComponent from '../../SelectComponent';
import { useTranslate } from '../../../../../../features/languages';

const ControllerCapabilityComparatorSelect = ({ selectedControllerCapabilityComparator, onChange }) => {
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={selectedControllerCapabilityComparator ? selectedControllerCapabilityComparator : ''}
            label={info.hints.comparator}
            info={info.text.comparator}
            onChange={(e) => onChange({ selectedControllerCapabilityComparator: e.target.value })}
        >
            {localComparatorsTrig.map((item) => {
                return (
                    <MenuItem key={item.id} value={item.value}>
                        {t(item.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ControllerCapabilityComparatorSelect;
