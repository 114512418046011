import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { ImportantDevicesOutlined } from '@material-ui/icons';

import { setListDevicesInstalledWifi } from '../../utils';

import { pluginsListCount, pluginsListSumPlugins } from '../../PluginStyled.module.scss';
import { prepareDevices } from '../../../EzloDevices/utils';

const CountInstalledIPDevices = (props) => {
    const data = useSelector((state) => state.ezlo.data);
    const privateIPTemplates = useSelector((state) => state.plugins.privateWifiTemplates);
    const [countInstalledDevices, setCountInstalledDevices] = useState({});
    const { params, onHandlerShowDevicesModal } = props;
    const devices = prepareDevices(data) || [];

    useEffect(() => {
        if (privateIPTemplates && devices) {
            setCountInstalledDevices(setListDevicesInstalledWifi(privateIPTemplates, devices));
        }
    }, [privateIPTemplates]);

    return (
        <div className={pluginsListCount}>
            <span className={pluginsListSumPlugins}>{countInstalledDevices[params?.row?.nameId]}</span>
            <IconButton onClick={() => onHandlerShowDevicesModal(params)}>
                <ImportantDevicesOutlined />
            </IconButton>
        </div>
    );
};

export default CountInstalledIPDevices;
