import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const PlusButtonIcon = (props) => (
    <SvgIcon
        {...props}
        color="primary"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0H13.5V10.5H24V13.5H13.5V24H10.5V13.5H0V10.5H10.5V0Z" />
    </SvgIcon>
);

export default PlusButtonIcon;
