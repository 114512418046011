import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ControllerSelect from './components/ControllerSelect';
import ControllerCapabilitySelect from './components/ControllerCapabilitySelect';
import ControllerCapabilityResetValue from './components/ControllerCapabilityResetValue';
import ActionDelay from '../../../../../components/ActionDelay';

import { MeshBotAction } from '../../../../../actions';

import { generateControllerNodeActionFields, generateControllerNodeResetCapabilityFields } from './utils';

import {
    CONTROLLER_CAPABILITY_RESET,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    IMMEDIATELY,
    ZERO_INT,
} from '../../../../../constants/MeshbotConstant';

const ControllerActionNode = (props) => {
    const {
        id,
        blockType,
        currentItem,
        currentRule,
        isShowedDelay,
        blocks,
        onSetIsShowedDelay,
        onUpdateActionDelay,
        onChangeDelay,
    } = props;

    const dispatch = useDispatch();

    const [delayDisabled, setDelayDisabled] = useState(true);

    const serial = useSelector((state) => state?.ezlo?.serial);
    const isControllerConnected = useSelector((state) => state?.ezlo?.isConnected);

    const initialControllerNodeCapabilityValue =
        currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockOptions?.method?.name;
    const initialControllerResetCapabilityValue =
        currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[INDEX_SELECTED_BLOCKS_ELEMENT]?.value;
    const initialDelayValue = currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay;

    useEffect(() => {
        if (blocks.length === ZERO_INT) {
            onSetIsShowedDelay(IMMEDIATELY);
        }

        setDelayDisabled(!!blocks.length === ZERO_INT);
    }, [blocks]);

    const handleControllerCapabilityChange = (e) => {
        const { value } = e.target;

        dispatch(
            MeshBotAction.updateControllerNodeAction(
                id,
                generateControllerNodeActionFields(blockType, value, initialDelayValue),
                blockType,
            ),
        );
    };

    const handleControllerResetCapabilityValueChange = (e) => {
        const { value } = e.target;

        dispatch(
            MeshBotAction.updateControllerNodeAction(
                id,
                generateControllerNodeResetCapabilityFields(blockType, value, initialDelayValue),
                blockType,
            ),
        );
    };

    return (
        <>
            <ControllerSelect serial={serial} isControllerConnected={isControllerConnected} />
            {isControllerConnected && (
                <ControllerCapabilitySelect
                    onHandleControllerCapabilityChange={handleControllerCapabilityChange}
                    initialControllerNodeCapabilityValue={initialControllerNodeCapabilityValue}
                />
            )}
            {initialControllerNodeCapabilityValue === CONTROLLER_CAPABILITY_RESET && (
                <ControllerCapabilityResetValue
                    onHandleControllerResetCapabilityValueChange={handleControllerResetCapabilityValueChange}
                    initialControllerResetCapabilityValue={initialControllerResetCapabilityValue}
                />
            )}
            {initialControllerNodeCapabilityValue && (
                <ActionDelay
                    id={id}
                    type={currentItem?.selectedFieldTrigger}
                    actionBlockName={blockType}
                    currentRule={currentRule}
                    isShowedDelay={isShowedDelay}
                    delayDisabled={delayDisabled}
                    onUpdateActionDelay={onUpdateActionDelay}
                    onSetIsShowedDelay={onSetIsShowedDelay}
                    onChangeDelay={onChangeDelay}
                />
            )}
        </>
    );
};

export default ControllerActionNode;
