import React, { useRef } from 'react';

import useDialogState from '../../../../../hooks/useDialogState/useDialogState';
import LabelsDropdownPopper from './LabelsDropdownPopper';
import { LabelDropdownContextProvider } from '../../hooks/useLabelDropdownContext';

const LabelsDropdown = ({ ButtonComponent, rootDivClassName, contextValue }) => {
    const { open, toggleOpenDialog, setOpen } = useDialogState();
    const anchorRef = useRef(null);

    return (
        <LabelDropdownContextProvider value={contextValue}>
            <div className={rootDivClassName}>
                <ButtonComponent toggleOpenDialog={toggleOpenDialog} anchorRef={anchorRef} open={open} />

                <LabelsDropdownPopper anchorRef={anchorRef} popperOpen={open} setPopperOpen={setOpen} />
            </div>
        </LabelDropdownContextProvider>
    );
};

export default LabelsDropdown;
