import React from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { PRIMARY } from '../../constants/Devices';

const MeshbotRun = ({ params, handleRun, icon }) => {
    return (
        <IconButton
            color={PRIMARY}
            onClick={handleRun}
            disabled={params.isDisabled}
            className={classNames({ disabled: params.isDisabled })}
        >
            {icon}
        </IconButton>
    );
};

export default MeshbotRun;
