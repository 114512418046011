export const getHoursForSpecialTimeOfTheDay = (hours, blocks) => {
    const [, minutes] = blocks[0].parameters[0].time;

    return { time: [parseInt(hours), minutes] };
};

export const getMinutesForSpecialTimeOfTheDay = (minutes, blocks) => {
    const [hours] = blocks[0].parameters[0].time;

    return { time: [hours, parseInt(minutes)] };
};

export const getHoursFromState = (blocks) => {
    return blocks[0].parameters[0].time[0];
};

export const getMinutesFromState = (blocks) => {
    return blocks[0].parameters[0].time[1];
};

export const getOffsetFromState = (blocks) => {
    return blocks[0].parameters[0].offset;
};

export const getSpecialTimeOptionFromState = (blocks) => {
    return blocks[0].parameters[0].name;
};
