import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as generator from '../../../../../constants/DevicePluginGenerator';
import { PRIVATE, PRIVATE_GENERATOR, WIFI_PLUGIN_MODEL } from '../../../../../constants/Plugins';
import ModalPlugin from '../../components/ModalPlugin';
import PluginsList from '../../components/PluginsList';

import { useRequestResult } from '../../customHooks';
import { EZLOGIC_TITLE_PRIVATE_IP_DEVICE } from '../../../../../constants/language_tokens';
import * as localStorageKeys from '../../../../../constants/localStorageKeys';
import { TRUE } from '../../../../../constants/MeshbotConstant';

const TemplatesPrivate = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);

    const {
        privateWifiTemplates,
        loaderList,
        history,
        devices,
        listInstalledPlugins,
        PluginActions,
        onSetIsActivePage,
        handleRouterEdit,
    } = props;

    useEffect(() => {
        PluginActions.getPrivatePlugins(WIFI_PLUGIN_MODEL);
        onSetIsActivePage(PRIVATE);
        localStorage.setItem(localStorageKeys.IS_FIRST_LOAD_INTEGRATIONS, TRUE);
        localStorage.setItem(localStorageKeys.IS_FIRST_LOAD_NEW_INTEGRATIONS, TRUE);
    }, []);

    useRequestResult();

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    const handleRouteToPage = useCallback((type, data) => {
        if (type === generator.DEVICES_PLUGIN_SETTING) {
            history.push(`/ezlo/plugins/wifi-generator/${data.id}/devices`);
        } else if (type === generator.DEVICES_PLUGIN_EDIT) {
            history.push(`/ezlo/plugins/wifi-generator/${data.id}`);
            handleRouterEdit('withoutTabs');
        }
    }, []);

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const handlerShowDevicesModal = useCallback((data) => {
        setPluginData(data);
        setIsModal(true);
        setTypeModal('devices');
    }, []);

    return (
        <>
            <PluginsList
                type={PRIVATE_GENERATOR}
                title={EZLOGIC_TITLE_PRIVATE_IP_DEVICE}
                listPlugins={privateWifiTemplates}
                loaderList={loaderList}
                onConfirm={onConfirm}
                onRouteToPage={handleRouteToPage}
                onHandlerShowDevicesModal={handlerShowDevicesModal}
            />
            <ModalPlugin
                typeList={PRIVATE_GENERATOR}
                open={isModal}
                onCancel={hideModal}
                typeModal={typeModal}
                currentAction={currentAction}
                pluginData={pluginData}
                devices={devices}
                listInstalledPlugins={listInstalledPlugins}
                PluginActions={PluginActions}
            />
        </>
    );
};

TemplatesPrivate.propTypes = {
    loaderList: PropTypes.bool,
    privateWifiTemplates: PropTypes.array,
    onSetIsActivePage: PropTypes.func,
    history: PropTypes.object,
    PluginActions: PropTypes.object,
};

export default TemplatesPrivate;
