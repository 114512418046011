import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ROWS_PER_PAGE } from '../../../EzloMeshbots/constants';
import { PRIVATE_GENERATOR, PUBLISH_GENERATOR, MARKETPLACE_GENERATOR } from '../../../../../constants/Plugins';
import GridTable from '../../../../../components/Table';
import {
    buildMeshBotsTableColumns,
    buildMeshBotsTableColumnsGenerator,
    createPluginTable,
    createPluginTableGenerator,
    filterByName,
} from '../../utils';
import './pluginsList.scss';
import { useSelector } from 'react-redux';
import { WaitModal } from '../modalTemplates/Wait';
import { useTranslate } from '../../../../../features/languages';

const PluginsList = (props) => {
    const [columns, setColumns] = useState([]);
    const [plugins, setPlugins] = useState([]);
    const [sortModel, setSortModel] = useState([]);
    const { t } = useTranslate();
    const pluginUpgradeWaiting = useSelector((state) => state.plugins.pluginUpgradeWaiting);

    const {
        title,
        type,
        listPlugins,
        loaderList,
        onConfirm,
        onHandlerShowInstallModal,
        onHandlerShowInfoModal,
        onHandlerShowDevicesModal,
        onRouteToPage,
        listInstalledPlugins,
        privatePlugins,
        devices,
        pluginData,
    } = props;

    useEffect(() => {
        buildPluginsTable(type, onConfirm, onRouteToPage, onHandlerShowDevicesModal, onHandlerShowInfoModal);
    }, []);

    useEffect(() => {
        if (listPlugins) {
            if (type === PRIVATE_GENERATOR || type === PUBLISH_GENERATOR || type === MARKETPLACE_GENERATOR) {
                setPlugins(createPluginTableGenerator(listPlugins));
            } else {
                setPlugins(filterByName(createPluginTable(listPlugins)));
            }
        }
    }, [listPlugins]);
    const buildPluginsTable = (type, onConfirm, onRouteToPage, onHandlerShowDevicesModal, onHandlerShowInfoModal) => {
        let columnsData;
        if (type === PRIVATE_GENERATOR || type === PUBLISH_GENERATOR || type === MARKETPLACE_GENERATOR) {
            columnsData = buildMeshBotsTableColumnsGenerator({
                type,
                onConfirm,
                onRouteToPage,
                onHandlerShowDevicesModal,
                onHandlerShowInfoModal,
            });
        } else {
            columnsData = buildMeshBotsTableColumns(
                type,
                onConfirm,
                onHandlerShowInstallModal,
                onHandlerShowInfoModal,
                onHandlerShowDevicesModal,
                devices,
                pluginData,
            );
        }
        const translatedHeaderNameColumns = columnsData.map((colData) => ({
            ...colData,
            headerName: t(colData?.headerName),
        }));
        setColumns(translatedHeaderNameColumns);
    };

    return (
        <div className="plugins-list">
            <h2>{t(title)}</h2>
            <GridTable
                columns={columns}
                pagination={true}
                rows={plugins}
                rowsPerPageOptions={ROWS_PER_PAGE}
                disableSelectionOnClick={true}
                disableExtendRowFullWidth={false}
                disableColumnReorder={true}
                loaderList={loaderList}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                listInstalledPlugins={listInstalledPlugins}
                privatePlugins={privatePlugins}
                devices={devices}
                pluginData={pluginData}
                onHandlerShowInfoModal={onHandlerShowInfoModal}
            />
            <WaitModal isOpened={pluginUpgradeWaiting} />
        </div>
    );
};

PluginsList.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    loaderList: PropTypes.bool,
    listPlugins: PropTypes.array,
    onConfirm: PropTypes.func,
    onHandlerShowInstallModal: PropTypes.func,
    onHandlerShowInfoModal: PropTypes.func,
};

export default React.memo(PluginsList);
