import _ from 'lodash';
import { SCHEMA_REF_KEY } from '../../../EzloVirtualDevice/virtual-templates';
import hash from '../../../../../constants/uniqueHash';
import { APP_JSON, KEYWORDS, RESPONSE_ELEMENTS, TYPE } from './constants';

const isArrayElementByRef = (property) => property?.items?.hasOwnProperty(SCHEMA_REF_KEY);
const addId = (element) => (element.elementId = hash());
const hasType = (element) => element.hasOwnProperty(TYPE);
const hasArrayType = (element) => element.type === RESPONSE_ELEMENTS.TYPES.ARRAY;

export const getElementByReference = (parjectJson, reference) => {
    if (!parjectJson || !reference) {
        return [{}, null];
    }
    const schemaRefArray = reference.split('/');
    const path = schemaRefArray.join('.').slice(2);
    const schemaRefName = schemaRefArray[schemaRefArray?.length - 1] || '';
    const elementByReference = _.get(parjectJson, path) || {};

    return [elementByReference, schemaRefName];
};

export const findAndGetKeyword = (element) => {
    if (!element) {
        return [false, null];
    }

    for (const keyword of KEYWORDS) {
        if (element.hasOwnProperty(keyword)) {
            return [true, keyword];
        }
    }

    return [false, null];
};

export const changePropertyWithoutType = (property, parjectJson) => {
    const [hasKeyword, keyword] = findAndGetKeyword(property);

    if (hasKeyword) {
        property.keywordTypes = [];
        property[keyword].forEach((keywordItem) => {
            const ref = keywordItem[SCHEMA_REF_KEY];
            const [elementByRef, elementName] = getElementByReference(parjectJson, ref);
            property.keywordTypes.push(elementName);
            property[elementName] = assembleAndGetTreeByElement(elementByRef, parjectJson);
        });
    }

    return property;
};

const generatorArrayProperties = (arrayItem) => {
    return {
        item: {
            elementId: hash(),
            parentType: RESPONSE_ELEMENTS.TYPES.ARRAY,
            ...arrayItem,
        },
    };
};

export const changePropertyWithArrayType = (property, parjectJson) => {
    if (isArrayElementByRef(property)) {
        const ref = property.items[SCHEMA_REF_KEY];
        const [elementByRef] = getElementByReference(parjectJson, ref);
        const item = assembleAndGetTreeByElement(elementByRef, parjectJson);
        property.properties = generatorArrayProperties(item);
    } else {
        property.properties = generatorArrayProperties(property.items);
    }

    return property;
};

export const getElementBlockWithProperties = (elementBlock, parjectJson) => {
    if (!elementBlock || !parjectJson) {
        return null;
    }

    const newElementBlock = _.cloneDeep(elementBlock);
    for (const propertyKey in newElementBlock.properties) {
        let property = newElementBlock.properties[propertyKey];
        if (!hasType(property)) {
            property = changePropertyWithoutType(property, parjectJson);
        }

        if (hasArrayType(property)) {
            property = changePropertyWithArrayType(property, parjectJson);
        }
        addId(property);
    }

    return newElementBlock;
};

const assembleAndGetTreeByElement = (elementBlock, parjectJson) => {
    if (elementBlock.hasOwnProperty(RESPONSE_ELEMENTS.PROPERTIES)) {
        return getElementBlockWithProperties(elementBlock, parjectJson);
    }

    return elementBlock;
};

export const getResponseTree = (integrationPreview, selectedMethod) => {
    if (!integrationPreview || !selectedMethod) {
        return null;
    }
    const { parjectJson } = integrationPreview?.nucal_parject_definition?.list;
    const methodPaths = parjectJson.paths[`/${selectedMethod}`];
    const { schema } = methodPaths?.post?.responses?.default?.content[APP_JSON];
    const schemaRef = schema[SCHEMA_REF_KEY];
    const [elementByReference] = getElementByReference(parjectJson, schemaRef);

    return {
        elementId: hash(),
        ...assembleAndGetTreeByElement(elementByReference, parjectJson),
    };
};
