import React, { useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { IconButton } from '@material-ui/core';

import {
    ABSTRACT_TYPE,
    MAX_DEVICE_NAME_LENGTH,
    VALUE_TO_UNASSIGN_ROOM,
    DEVICE_INFO,
    ZWAVE,
    SMALL,
    PRIMARY,
} from '../../constants/Devices';
import SpanToInput from '../SpanToInput';
import RoomsDropdown from './components/RoomsDropdown';
import DeviceSettings from './components/DeviceSettings';
import DeviceIconContainer from '../DeviceIconContainer';
import TooltipInfo from './components/TooltipInfo';
import { getRoomsWithValidData, isDeviceCannotBeMoved, getInfoData } from '../../containers/Ezlo/EzloDevices/utils';
import { getRoomName } from '../../containers/Ezlo/EzloDevice/utils';

import {
    EZLOGIC_TITLE_DEVICE_NAME,
    EZLOGIC_TITLE_REFRESH_INFO,
    EZLOGIC_TITLE_UNASSIGNED,
} from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';
import styles from './DeviceCard.module.scss';
import { RefreshIcon } from '../../assets/icons';

const DeviceCard = (props) => {
    const [isTooltip, setIsTooltip] = useState(false);
    const {
        serial,
        gateway,
        isConnected,
        reachable,
        device,
        name,
        info,
        settings,
        roomId,
        onNameChange,
        onHandlerSelectFunction,
        onHandlerRefreshDevice,
        onChangeDevicePlace,
        parentDeviceId,
        parentRoom,
        _id,
        isIntegration,
    } = props;

    const isAvailable = isConnected && reachable;
    const roomsListBySerial = useSelector((state) => state.ezlo?.data?.[serial]?.pages || []);
    const iconDeviceBlockStyles = classes(styles.iconDeviceBlock, {
        [styles.inaccessible]: !isAvailable,
    });
    const deviceCardStyles = classes(styles.deviceCard, { [styles.inactive]: !isAvailable });
    const { t } = useTranslate();
    const gatewayInfo = gateway?.label;
    const tooltipData = isTooltip ? getInfoData(info, _id, gatewayInfo) : [];

    const handlerShowTooltip = useCallback(() => {
        if (!isConnected) {
            return;
        }

        setIsTooltip(true);
    }, [isTooltip]);

    const handlerHideTooltip = useCallback(() => {
        setIsTooltip(false);
    }, [isTooltip]);

    const handleNameChange = (name) => {
        if (name) {
            onNameChange(device._id, name, serial);
        }
    };

    const handleRoomIdChange = (id) => {
        let roomId = null;

        roomId = id === VALUE_TO_UNASSIGN_ROOM ? '' : id;

        onChangeDevicePlace(serial, device._id, roomId, device);
    };

    const roomName = roomId
        ? getRoomName(getRoomsWithValidData({ roomsListBySerial }), roomId)
        : t(EZLOGIC_TITLE_UNASSIGNED);

    return (
        <div className={deviceCardStyles}>
            <div className={styles.content}>
                <header className={styles.header}>
                    <div className={iconDeviceBlockStyles} title={DEVICE_INFO}>
                        <DeviceIconContainer
                            category={device.category}
                            onClick={handlerShowTooltip}
                            iconStyles={styles.deviceIcon}
                        />
                        {isTooltip && (
                            <TooltipInfo info={info} onHideTooltip={handlerHideTooltip} tooltipData={tooltipData} />
                        )}
                    </div>
                    <div className={styles.roomControlUnit}>
                        <RoomsDropdown
                            isAvailable={isAvailable}
                            roomList={getRoomsWithValidData({ roomsListBySerial })}
                            onRoomIdChange={handleRoomIdChange}
                            isDeviceCannotBeMoved={isDeviceCannotBeMoved({ parentDeviceId, parentRoom })}
                            roomName={roomName}
                            isConnected={isConnected}
                        />
                        {device && device.gateway && device.gateway.name === ZWAVE && (
                            <IconButton
                                title={t(EZLOGIC_TITLE_REFRESH_INFO)}
                                className={styles.refresh}
                                size={SMALL}
                                color={PRIMARY}
                                onClick={() => onHandlerRefreshDevice(serial, device._id)}
                                disabled={!isAvailable}
                            >
                                <RefreshIcon />
                            </IconButton>
                        )}
                    </div>
                </header>
                <SpanToInput
                    title={t(EZLOGIC_TITLE_DEVICE_NAME)}
                    value={name}
                    maxLength={MAX_DEVICE_NAME_LENGTH}
                    onChange={handleNameChange}
                    className={styles.nameDevice}
                    disabled={device.type === ABSTRACT_TYPE || !isAvailable}
                />
            </div>
            <DeviceSettings
                gateway={gateway}
                isAvailable={isAvailable}
                device={device}
                settings={settings}
                onHandlerSelectFunction={onHandlerSelectFunction}
                isConnected={isConnected}
                isIntegration={isIntegration}
            />
        </div>
    );
};

export default memo(DeviceCard);

DeviceCard.propTypes = {
    serial: PropTypes.string,
    device: PropTypes.object,
    name: PropTypes.string,
    isConnected: PropTypes.bool,
    reachable: PropTypes.bool,
    info: PropTypes.object,
    settings: PropTypes.array,
    roomId: PropTypes.string,
    onNameChange: PropTypes.func,
    onHandlerSelectFunction: PropTypes.func,
    onChangeDevicePlace: PropTypes.func,
    onHandlerRefreshDevice: PropTypes.func,
    parentRoom: PropTypes.bool,
    parentDeviceId: PropTypes.string,
    gateway: PropTypes.object,
};
