import React from 'react';

const InProgressIcon = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M66.0589 133.941C57.3726 125.255 52 113.255 52 100"
            stroke="#3E46AE"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M52 100C52 86.7452 57.3726 74.7452 66.0589 66.0589C74.7452 57.3726 86.7452 52 100 52"
            stroke="#50C1D6"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M148 100C148 106.627 146.657 112.941 144.228 118.684"
            stroke="#00B491"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M52 100C52 93.3726 53.3432 87.0589 55.7721 81.3163"
            stroke="#3E46AE"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M144.228 118.684C141.799 124.426 138.284 129.598 133.941 133.941C129.598 138.284 124.427 141.799 118.684 144.228C112.941 146.657 106.628 148 100 148C93.3728 148 87.0591 146.657 81.3164 144.228C75.5738 141.799 70.4022 138.284 66.0591 133.941"
            stroke="#FE5C15"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path
            d="M144.228 118.684C141.799 124.426 138.284 129.598 133.941 133.941C129.598 138.284 124.426 141.799 118.684 144.228"
            stroke="#3E46AE"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
    </svg>
);

export default InProgressIcon;
