import React from 'react';
import { Autocomplete } from '@material-ui/lab';

import { TextField } from '../../../../components';

import { changeCapability, filterCapabilitiesByVirtualItemType } from '../utils';
import { capabilityNeedToReplace, NOT_ALLOWED_CUSTOM_CAPABILITY, virtualItemKeys } from '../virtual-templates';
import {
    EZLOGIC_TITLE_SELECT_CAPABILITY_TYPE,
    EZLOGIC_TITLE_SELECT_PROPERTY,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const { VIRTUAL_TRIGGERS } = virtualItemKeys;

export const CapabilitiesSelect = (props) => {
    const { capability, capabilities, virtualItemUpdateParams, setVirtualDevice } = props;
    const { virtualItemKey } = virtualItemUpdateParams;
    const { t } = useTranslate();
    const label =
        virtualItemKey === VIRTUAL_TRIGGERS
            ? t(EZLOGIC_TITLE_SELECT_PROPERTY)
            : t(EZLOGIC_TITLE_SELECT_CAPABILITY_TYPE);
    const { EXISTING_VALUE, EXPECTING_VALUE } = capabilityNeedToReplace;

    const handleChangeProperty = (e, { name }) => {
        const updatedVirtualDevice = changeCapability(virtualItemUpdateParams, name);

        setVirtualDevice(updatedVirtualDevice);
    };

    const capabilitiesValues = Object.values(capabilities);
    const capabilityNames = capabilitiesValues.map(({ capability_name }) => capability_name);

    const changedCapabilities = capabilityNames
        //TODO: remove when api_command will be renamed to api
        .map((item) => item.replace(EXISTING_VALUE, EXPECTING_VALUE))
        .filter((item) => !item.startsWith(NOT_ALLOWED_CUSTOM_CAPABILITY));

    const capabilitiesFilteredByType = filterCapabilitiesByVirtualItemType(
        changedCapabilities,
        capabilities,
        virtualItemKey,
    ).map((capability) => ({ name: capability, uuid: capability }));

    return (
        <Autocomplete
            defaultValue={{ name: capability }}
            disableClearable
            options={capabilitiesFilteredByType}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={handleChangeProperty}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
};
