import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { AuthorizationContextProvider } from '../context/AuthorizationContext';
import { CODE_GET_PARAM_NAME, USER_GET_PARAM_NAME } from '../constants/getParams';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS } from '../../../constants/toastConstants';
import toastsActions from '../../../actions/toastsActions';

import ResetPasswordFormContainer from './components/ResetPasswordForm';
import ResetPasswordSuccessContainer from './components/ResetPasswordSuccessContainer';
import useCloudResetPassword from './hooks/useCloudResetPassword';
import NotFoundPageContent from '../../NotFoundPage/NotFoundPageContent';

import styles from './ResetPasswordPage.module.scss';
import { LanguageSelector } from '../../../features/languages/components';
import { languageService } from '../../../services/language';
import { updateLang } from '../../../features/languages/utils';
import { AppLogo } from '../../../assets/icons';

const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const language = languageService.getLanguageKey();
    const { search } = useLocation();
    const { url } = useRouteMatch();
    const [params, setParams] = useState({ code: null, user: null });
    const [data, error, loading, setNewPassword] = useCloudResetPassword();
    const [currentLang, setCurrentLang] = useState(language);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const code = query.get(CODE_GET_PARAM_NAME);
        const user = query.get(USER_GET_PARAM_NAME);
        setParams({ code, user });
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(
                toastsActions.showToast({
                    message: error,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                }),
            );
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            history.replace({ pathname: '/passwordreset/success', state: { isActive: true } });
        }
    }, [data]);

    const resetUserPassword = async (values) => {
        const { newPassword } = values;
        const { user, code } = params;
        await setNewPassword({ pk_user: user, code, password: newPassword });
    };
    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);
    };

    useEffect(() => {
        updateLang(currentLang);
    }, [currentLang]);

    return (
        <AuthorizationContextProvider>
            <div className="logo">
                <AppLogo className="logo__icon" />
            </div>
            <div className={styles.language}>
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} isLoggin={true} />
            </div>
            <section className={styles.contentContainer}>
                <Switch>
                    <Route
                        path={`${url}/confirm`}
                        render={() => <ResetPasswordFormContainer isLoading={loading} onSubmit={resetUserPassword} />}
                    />
                    <Route path={`${url}/success`} render={() => <ResetPasswordSuccessContainer />} />
                    <Route render={() => <NotFoundPageContent loggedIn />} />
                </Switch>
            </section>
        </AuthorizationContextProvider>
    );
};

export default ResetPasswordPage;
