import React from 'react';
import { useFormikContext } from 'formik';
import { Checkbox } from '@material-ui/core';

import { DEVICE_STATUS } from '../../../../../constants/Users';

import styles from '../../UserManagement/PinCodeForm/style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { LocksDoorIcon } from '../../../../../assets/icons';

const PinCodeDeviceField = ({ device, deviceIndex }) => {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslate();
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    const handleChangeCheckbox = (e) => {
        const { checked } = e.target;
        setFieldValue(`devices[${deviceIndex}]`, {
            ...device,
            checked,
            isRemovePinCodeFromDevice: Boolean(device.pinCodeKey && !checked),
        });
    };
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // const handleChangeCheckbox = (e) => {
    //     const { checked } = e.target;
    //     setFieldValue(`devices[${deviceIndex}]`, { ...device, checked });
    // };

    return (
        <div>
            <div className={styles.item}>
                <div className={styles.label}>
                    <LocksDoorIcon className={styles.locksDoorIcon} />
                    <div className={styles.box}>
                        <div>{device.name}</div>
                        <span>{device.reachable ? t(DEVICE_STATUS.ONLINE) : t(DEVICE_STATUS.OFFLINE)}</span>
                    </div>
                </div>
                <div className={styles.actionBox}>
                    <Checkbox
                        onChange={handleChangeCheckbox}
                        name={`devices[${deviceIndex}].checked`}
                        checked={device.checked}
                        disabled={!device.reachable}
                        color="primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default PinCodeDeviceField;
