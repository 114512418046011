import React, { memo } from 'react';

import { ARRAY_FIELD_TYPES } from '../../../paas-constants';
import MultipleAutocomplete from './MultipleAutocomplete';

/**
 * Renders Multiple Autocomplete for type string
 * @param { object } props
 * @returns { JSX.Element } multiple Autocomplete
 * @example
 * <NumberAutocomplete
 *      value={['one', 'two']}
 *      fieldSchema={{type: 'array', items: {type: 'number' }}}
 *      description={'Favorite Strings'}
 *      fieldName={'favoriteStrings'}
 *      isRequired={true}
 *      onChange={func}
 * />
 */
const StringAutocomplete = ({ value, fieldSchema, fieldName, description, isRequired, onChange }) => {
    const { STRING } = ARRAY_FIELD_TYPES;
    const { type } = fieldSchema.items;

    const handleChangeField = (e, value) => {
        onChange(value, fieldName);
    };

    const handleInputBlur = (e, params) => {
        const inputValue = params?.inputProps?.value;
        const currentAutocompleteValue = value || [];

        if (inputValue) {
            handleChangeField(e, [...currentAutocompleteValue, inputValue]);
        }
    };

    if (type !== STRING) {
        return null;
    }

    return (
        <MultipleAutocomplete
            freeSolo
            value={value}
            description={description}
            handleChangeField={handleChangeField}
            isRequired={isRequired}
            handleInputBlur={handleInputBlur}
        />
    );
};

export default memo(StringAutocomplete);
