import { useState, useEffect } from 'react';

const useUnsavedChangesWarning = () => {
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        //Detecting browser closing
        window.onbeforeunload =
            isDirty &&
            ((e) => {
                e.preventDefault();
                e.returnValue = '';
            });

        return () => (window.onbeforeunload = null);
    }, [isDirty]);

    return (value) => setDirty(value);
};

export default useUnsavedChangesWarning;
