import { useSelector } from 'react-redux';

const useFeatureData = (featureName) => {
    const serial = useSelector((state) => state.ezlo.serial);
    const featuresList = useSelector((state) => state.ezlo?.data[serial]?.features) || {};

    return Object.values(featuresList[featureName]);
};

export default useFeatureData;
