import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { LocalVariable } from './components/LocalVariable/LocalVariable';
import { VariableContainer } from './components/Variable/VariableContainer';

import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import { MESHBOT_TYPES } from '../../../EzloMeshbots/constants';
import useMeshBotType from '../../hooks/useMeshBotType';

import style from './style.module.scss';

const SaveOutputBlock = (props) => {
    const {
        blockName,
        blocks,
        id,
        isEditing,
        isMethodsVisible,
        isIntegration,
        integrationPreviewResult,
        onSetCode,
        handleSetBlackListVariable,
    } = props;
    const { currentMeshBotType } = useMeshBotType();

    return (
        <div className={style.boxOfSaveOutput}>
            {currentMeshBotType === MESHBOT_TYPES.LOCAL && (
                <LocalVariable blocks={blocks} isEditing={isEditing} id={id} blockName={blockName} />
            )}

            {isIntegration && isMethodsVisible && (
                <VariableContainer
                    id={id}
                    blockName={blockName}
                    onSetCode={onSetCode}
                    integrationPreviewResult={integrationPreviewResult}
                    meshBotType={currentMeshBotType}
                    handleSetBlackListVariable={handleSetBlackListVariable}
                />
            )}
        </div>
    );
};

export default withErrorBoundary(memo(SaveOutputBlock), FallbackMeshbotNodeComponent);

SaveOutputBlock.propTypes = {
    id: PropTypes.string,
    blocks: PropTypes.array,
    isEditing: PropTypes.bool,
    onSetCode: PropTypes.func,
};
