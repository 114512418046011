import React from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

import { ZERO_INT, optionsSelect } from '../../constants/MeshbotConstant';
import InfoButton from '../../containers/Ezlo/EzloMeshbot/components/InfoButton';
import MeshBotFieldStyleBox from '../MeshBotFieldStyleBox/MeshBotFieldStyleBox';
import { useTranslate } from '../../features/languages';

const MeshBotSelectField = (props) => {
    const { t } = useTranslate();

    const {
        label,
        selectValue,
        handleChange,
        infoText,
        selectionAttributes,
        variant = 'standard',
        size = 'small',
        children,
        showHelperText,
    } = props;

    return (
        <MeshBotFieldStyleBox>
            <FormControl variant={variant} size={size}>
                <InputLabel>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(label),
                        }}
                    ></span>
                </InputLabel>
                <Select
                    MenuProps={optionsSelect}
                    value={selectValue}
                    onChange={handleChange}
                    endAdornment={
                        infoText ? (
                            <InfoButton>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(infoText),
                                    }}
                                ></span>
                            </InfoButton>
                        ) : (
                            <></>
                        )
                    }
                    {...selectionAttributes}
                >
                    {children}
                </Select>
                {showHelperText && selectValue?.length === ZERO_INT && (
                    <FormHelperText error={true}>{t(showHelperText)}</FormHelperText>
                )}
            </FormControl>
        </MeshBotFieldStyleBox>
    );
};

export default MeshBotSelectField;
