import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LocalVariableCapabilityField from './components/LocalVariableCapabilityField';
import LocalVariableSelect from './components/LocalVariableSelect';
import LocalVariableValueField from './components/LocalVariableValueField';

import { formatVariableValueDataTypeToString, generateLocalVariableActionFields } from './utils';

import { MeshBotAction } from '../../../../../actions';

import { localVariableBlockWrapper } from './LocalVariable.module.scss';
import { DATA_TYPES_LIST } from '../../../../../constants/Variables';
import { getExpressionsSelector } from '../../../EzloExpressions/utils';
import ActionDelay from '../../../../../components/ActionDelay';
import { IMMEDIATELY } from '../../../../../constants/MeshbotConstant';

const LocalVariable = (props) => {
    const {
        id,
        currentItem,
        blockType,
        currentRule,
        actionBlockName,
        selectedFieldTrigger,
        isShowedDelay,
        onUpdateActionDelay,
        onChangeDelay,
        onSetIsShowedDelay,
    } = props;
    const dispatch = useDispatch();
    const initialVariableValue = formatVariableValueDataTypeToString(
        currentItem?.blocks[0]?.fields[2].type,
        currentItem?.blocks[0]?.fields[2].value,
    );

    const initialScalableType = currentItem?.blocks[0]?.fields[2].type;

    const [selectedVariable, setSelectedVariable] = useState(currentItem?.blocks[0]?.fields[0].value ?? '');
    const [variableValue, setVariableValue] = useState(initialVariableValue ?? '');
    const [scalableType, setScalableType] = useState(initialScalableType ?? '');

    const expressionsList = useSelector(getExpressionsSelector);
    const variables = expressionsList?.filter((expression) => expression.variable);
    const selectedVariableValueType = variables?.find((variable) => variable.name === selectedVariable)?.valueType;
    const selectedScalableType = variables?.find((variable) => {
        return variable?.metadata?.scalableType === DATA_TYPES_LIST.TYPE_SCALABLE && variable.name === selectedVariable;
    });

    useEffect(() => {
        dispatch(
            MeshBotAction.updateLocalVariableAction(
                id,
                generateLocalVariableActionFields(
                    selectedVariable,
                    selectedScalableType?.metadata?.scalableType ? scalableType : selectedVariableValueType,
                    variableValue,
                    blockType,
                    selectedScalableType?.metadata?.scalableType,
                    currentItem?.blocks[0]?.delay,
                ),
                blockType,
            ),
        );
    }, [selectedVariable, variableValue, scalableType, selectedScalableType, selectedVariableValueType]);

    const handleVariableChange = (e) => {
        setSelectedVariable((prevValue) => {
            if (prevValue !== e.target.value) {
                setVariableValue('');
            }

            return e.target.value;
        });
        onSetIsShowedDelay(IMMEDIATELY);
    };

    return (
        <div className={localVariableBlockWrapper}>
            <LocalVariableSelect
                variablesList={variables}
                selectedVariable={selectedVariable}
                onSetSelectedVariable={handleVariableChange}
                selectedVariableValueType={selectedVariableValueType}
                selectedScalableType={selectedScalableType}
            />
            {selectedVariable && (
                <>
                    <LocalVariableCapabilityField />
                    <LocalVariableValueField
                        value={variableValue}
                        onSetVariableValue={setVariableValue}
                        selectedVariableValueType={selectedVariableValueType}
                        selectedScalableType={selectedScalableType}
                        scalableType={scalableType}
                        onChangeScalableType={setScalableType}
                    />
                    <ActionDelay
                        id={id}
                        currentRule={currentRule}
                        actionBlockName={actionBlockName}
                        type={selectedFieldTrigger}
                        isShowedDelay={isShowedDelay}
                        onUpdateActionDelay={onUpdateActionDelay}
                        onChangeDelay={onChangeDelay}
                        onSetIsShowedDelay={onSetIsShowedDelay}
                    />
                </>
            )}
        </div>
    );
};

export default LocalVariable;
