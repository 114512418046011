import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import MeshBotLocCreate from './MeshBotLocCreate';

const mapStateToProps = (state) => {
    const { serial } = state.ezlo;
    const controller = state.ezlo.data[serial];

    return {
        serial: state.ezlo.serial,
        rules: controller && controller.rules,
        selectedRule: state.meshBot.local.selectedRule,
        ruleTriggers: state.meshBot.local.ruleTriggers,
        errorsValidation: state.meshBot.local?.validationErrors,
        warningsValidation: state.meshBot.local?.validationWarnings,
        isApiProcessStarted: state.meshBot.local?.isApiProcessStarted,
    };
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(MeshBotLocCreate);
