import React from 'react';

import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { BLOCK_FIELD_TYPES, MESHBOT_NODE_TYPES } from '../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_EXPRESSION, EZLOGIC_TITLE_VALUE, EZLOGIC_TITLE_VARIABLE } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const SelectValueToCompareType = ({
    onChangeValueToCompareType,
    fieldValue,
    isExpressionComparison,
    isRangeComparators,
    expressionComparisonType,
}) => {
    const { EXPRESSION, VALUE, VARIABLE } = BLOCK_FIELD_TYPES;
    const isDisabledValueTypeItem = isExpressionComparison && isRangeComparators;
    const isDisabledExpressionItem =
        isDisabledValueTypeItem || expressionComparisonType === MESHBOT_NODE_TYPES.VARIABLE_COMPARISON;
    const isDisabledVariableItem =
        isDisabledValueTypeItem || expressionComparisonType === MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON;
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={fieldValue ?? ''}
            label={info.hints.valueType}
            info={info.text.valueType}
            onChange={onChangeValueToCompareType}
        >
            <MenuItem value={VALUE}>{t(EZLOGIC_TITLE_VALUE)}</MenuItem>
            <MenuItem value={EXPRESSION} disabled={isDisabledExpressionItem}>
                {t(EZLOGIC_TITLE_EXPRESSION)}
            </MenuItem>
            <MenuItem value={VARIABLE} disabled={isDisabledVariableItem}>
                {t(EZLOGIC_TITLE_VARIABLE)}
            </MenuItem>
        </SelectComponent>
    );
};

export default SelectValueToCompareType;
