export default {
    IS_BATTERY_STATE: 'isBatteryState',
    IS_BATTERY_LEVEL: 'isBatteryLevel',
    IS_CLOUD_STATE: 'isCloudState',
    IS_BUTTERY_STATE: 'isButteryState',
    IS_BUTTERY_LEVEL: 'isButteryLevel',
    LOCAL: 'local',
    CLOUD: 'cloud',
    NOTIFICATION: 'notification',
    NOTIFICATION_TEMPLATE: 'notificationTemplate',
    TRIGGER: 'trigger',
    GROUP: 'group',
    NOT: 'not',
    DEVICE: 'device',
    DEVICE_ADVANCED: 'deviceAdvanced',
    CLOUD_VARIABLES: 'cloudVariables',
    CLOUD_VARIABLES_NOT: 'cloudVariablesNot',
    NUCAL_SUBSCRIPTION: 'nucalSubscription',
    DATE: 'date',
    SCENE: 'scene',
    VIDOO: 'vidoo',
    TRIGGER_HOUSE_MODE: 'triggerHouseMode',
    CUSTOM: 'custom',
    DAILY: 'daily',
    SELECT: 'select',
    BLOCKS: 'blocks',
    BLOCK: 'block',
    LIST: 'list',
    INGROUP: 'inGroup',
    GROUP_NOT: 'groupNot',
    DEVICE_NOT: 'deviceNot',
    DEVICE_ADVANCED_NOT: 'deviceAdvancedNot',
    DATE_NOT: 'dateNot',
    SCENE_NOT: 'sceneNot',
    VIDOO_NOT: 'vidooNot',
    HOUSE_MODE_NOT: 'houseModeNot',
    IS_BATTERY_STATE_NOT: 'isBatteryStateNot',
    IS_BATTERY_LEVEL_NOT: 'isBatteryLevelNot',
    IS_BATTERY_STATUS_NOT: 'isBatteryStatusNot',
    IS_CLOUD_STATE_NOT: 'isCloudStateNot',
    IS_BUTTERY_STATE_NOT: 'isButteryStateNot',
    IS_BUTTERY_STATUS_NOT: 'isButteryStatusNot',
    HOUSE_MODE: 'houseMode',
    FUNCTION: 'function',
    DEVICE_STATE: 'deviceState',
    DATA_AND_TIME: 'dataAndTime',
    NAME_SUB_BLOCK: 'nameSubBlock',
    NOTIFICATION_TEMPLATE_OPTION: 'notificationTemplateOption',
    NOTIFICATION_TEMPLATE_VARIABLE: 'notificationTemplateVariable',
    NOTIFICATION_TEMPLATE_VARIABLE_VALUE: 'notificationTemplateVariableValue',
    NOTIFICATION_TEMPLATE_CAPABILITY: 'notificationTemplateCapability',
    VARIABLE: 'variable',
    VARIABLE_VALUE: 'variableValue',
    VALUE: 'value',
    VALUE_TYPE: 'Value',
    COLOR_RGB: 'color_rgb',
    COLOR_CMY: 'color_cmy',
    COLOR_TEMPERATURE_WARM: 'color_temperature_warmth',
    SELECTED_OPERATOR: 'selectedOperator',
    SCHEDULE: 'schedule',
    EXPRESSION: 'expression',
    DAYS: 'days',
    TARGET_WARM_WHITE: 'target_warm_white',
    TARGET_COOL_WHITE: 'target_cool_white',
    WARM_WHITE: 'warm_white',
    COOL_WHITE: 'cool_white',
    CYAN: 'cyan',
    RED: 'red',
    GREEN: 'green',
    BLUE: 'blue',
    MAGENTA: 'magenta',
    YELLOW: 'yellow',
    TARGET_BLUE: 'target_blue',
    SELECTED_FIELD_TRIGGER: 'selectedFieldTrigger',
    UPDATE_INTERFACE_JSON_ITEMS: 'UPDATE_INTERFACE_JSON_ITEMS',
    CLEAR_MESH_BOT: 'CLEAR_MESH_BOT',
    CLEAR_ACTION_LIST: 'CLEAR_ACTION_LIST',
    CLEAR_ACTION_SELECTED: 'CLEAR_ACTION_SELECTED',
    CLEAR_SCRIPT_NOTIFICATION: 'CLEAR_SCRIPT_NOTIFICATION',
    CLEAR_EDIT_SCRIPT: 'CLEAR_EDIT_SCRIPT',
    MESHBOT_CREATION: 'meshbotCreation',
    CALLBACK_SUBSCRIPTION: 'callBackSubscription',
    EXPRESSION_COMPARISON: 'expressionComparison',
    EXPRESSION_COMPARISON_NOT: 'expressionComparisonNot',
    VARIABLE_COMPARISON: 'variableComparison',
    VARIABLE_COMPARISON_NOT: 'variableComparisonNot',
    DEVICE_GROUP: 'deviceGroup',
    DEVICE_GROUP_NOT: 'deviceGroupNot',

    SET_UN_LATCH: {
        pending: 'SET_UN_LATCH/pending',
        success: 'SET_UN_LATCH/success',
        rejected: 'SET_UN_LATCH/rejected',
    },
    SET_NAME_MESH_BOT: {
        success: 'SET_NAME_MESH_BOT/success',
        rejected: 'SET_NAME_MESH_BOT/rejected',
    },
    SET_NAME_MESH_BOT_CLOUD: {
        success: 'SET_NAME_MESH_BOT_CLOUD/success',
        rejected: 'SET_NAME_MESH_BOT_CLOUD/rejected',
    },
    SET_EXEC_POLICY_FOR_ACTIONS: 'SET_EXEC_POLICY_FOR_ACTIONS',
    SET_EXEC_POLICY_FOR_ACTION: 'SET_EXEC_POLICY_FOR_ACTION',
    ADD_TRIGGER: {
        success: 'ADD_TRIGGER/success',
        rejected: 'ADD_TRIGGER/rejected',
    },
    DELETE_TRIGGER: {
        success: 'DELETE_TRIGGER/success',
        rejected: 'DELETE_TRIGGER/rejected',
    },
    UPDATE_TRIGGER: {
        success: 'UPDATE_TRIGGER/success',
        rejected: 'UPDATE_TRIGGER/rejected',
    },
    ADD_EXCEPTION: {
        success: 'ADD_EXCEPTION/success',
        rejected: 'ADD_EXCEPTION/rejected',
    },
    DELETE_EXCEPTION: {
        success: 'DELETE_EXCEPTION/success',
        rejected: 'DELETE_EXCEPTION/rejected',
    },
    TOGGLE_EXCEPTION_BLOCK: {
        success: 'TOGGLE_EXCEPTION_BLOCK/success',
        rejected: 'TOGGLE_EXCEPTION_BLOCK/rejected',
    },
    ADD_EXCEPTION_TRIGGER: {
        success: 'ADD_EXCEPTION_TRIGGER/success',
        rejected: 'ADD_EXCEPTION_TRIGGER/rejected',
    },
    DELETE_EXCEPTION_TRIGGER: {
        success: 'DELETE_EXCEPTION_TRIGGER/success',
        rejected: 'DELETE_EXCEPTION_TRIGGER/rejected',
    },
    UPDATE_EXCEPTION_TRIGGER: {
        success: 'UPDATE_EXCEPTION_TRIGGER/success',
        rejected: 'UPDATE_EXCEPTION_TRIGGER/rejected',
    },
    UPDATE_SELECT_DEVICE_EXCEPTION_TRIGGER: {
        success: 'UPDATE_SELECT_DEVICE_EXCEPTION_TRIGGER/success',
        rejected: 'UPDATE_SELECT_DEVICE_EXCEPTION_TRIGGER/rejected',
    },
    UPDATE_CONTROLLER_EXCEPTION_TRIGGER: {
        success: 'UPDATE_CONTROLLER_EXCEPTION_TRIGGER/success',
        rejected: 'UPDATE_CONTROLLER_EXCEPTION_TRIGGER/rejected',
    },
    UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER: {
        success: 'UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER/success',
        rejected: 'UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER/rejected',
    },
    RESET_MESHBOT_EXCEPTION_TRIGGER_STATE_VALUE: {
        success: 'RESET_MESHBOT_EXCEPTION_TRIGGER_STATE_VALUE/success',
        rejected: 'RESET_MESHBOT_EXCEPTION_TRIGGER_STATE_VALUE/rejected',
    },
    UPDATE_TRIGGER_SECTION_FUNCTION: 'UPDATE_TRIGGER_SECTION_FUNCTION',
    UPDATE_DEVICE_ADVANCED_SELECTED_RULE_WHEN: 'UPDATE_DEVICE_ADVANCED_SELECTED_RULE',
    UPDATE_DEVICE_GROUP_SELECTED_RULE_WHEN: 'UPDATE_DEVICE_GROUP_SELECTED_RULE',
    CLEAR_RULE_SELECTED_BLOCK: {
        success: 'CLEAR_RULE_SELECTED_BLOCK/success',
        rejected: 'CLEAR_RULE_SELECTED_BLOCK/rejected',
    },
    GET_DEVICE_BLOCKS: {
        pending: 'GET_DEVICE_BLOCKS/pending',
        success: 'GET_DEVICE_BLOCKS/success',
        rejected: 'GET_DEVICE_BLOCKS/rejected',
    },
    MANAGE_ACTION_DELAY: 'MANAGE_ACTION_DELAY',
    UPDATE_EXPRESSION: {
        success: 'UPDATE_EXPRESSION/success',
        rejected: 'UPDATE_EXPRESSION/rejected',
    },
    GET_EXPRESSION_LIST: {
        success: 'GET_EXPRESSION_LIST/success',
        rejected: 'GET_EXPRESSION_LIST/rejected',
    },
    CREATE_EXPRESSION: {
        success: 'CREATE_EXPRESSION/success',
        rejected: 'CREATE_EXPRESSION/rejected',
    },
    DELETE_EXPRESSION: {
        success: 'DELETE_EXPRESSION/success',
        rejected: 'DELETE_EXPRESSION/rejected',
    },
    GET_SCENE_STATUS: {
        success: 'GET_SCENE_STATUS/success',
        rejected: 'GET_SCENE_STATUS/rejected',
    },
    UPDATE_SELECTED_RULE_NOT: {
        success: 'UPDATE_SELECTED_RULE_NOT/success',
        rejected: 'UPDATE_SELECTED_RULE_NOT/rejected',
    },
    UPDATE_NAME_GROUP: {
        success: 'UPDATE_NAME_GROUP/success',
        rejected: 'UPDATE_NAME_GROUP/rejected',
    },
    UPDATE_FIELD_IN_GROUP_EXCEPTION: {
        success: 'UPDATE_FIELD_IN_GROUP_EXCEPTION/success',
        rejected: 'UPDATE_FIELD_IN_GROUP_EXCEPTION/rejected',
    },
    UPDATE_SELECT_DATE: {
        success: 'UPDATE_SELECT_DATE/success',
        rejected: 'UPDATE_SELECT_DATE/rejected',
    },
    SET_EXCEPTION_DATE_NODE: {
        success: 'SET_EXCEPTION_DATE_NODE/success',
        rejected: 'SET_EXCEPTION_DATE_NODE/rejected',
    },
    SET_EXCEPTION_FUNCTION_IN_TRIGGER: {
        success: 'SET_EXCEPTION_FUNCTION_IN_TRIGGER/success',
        rejected: 'SET_EXCEPTION_FUNCTION_IN_TRIGGER/rejected',
    },
    SET_EXCEPTION_DATE_VALUE: {
        success: 'SET_EXCEPTION_DATE_VALUE/success',
        rejected: 'SET_EXCEPTION_DATE_VALUE/rejected',
    },
    SET_EXCEPTION_OPTION_TYPE: {
        success: 'SET_EXCEPTION_OPTION_TYPE/success',
        rejected: 'SET_EXCEPTION_OPTION_TYPE/rejected',
    },
    SET_EXCEPTION_FUNCTION: {
        success: 'SET_EXCEPTION_FUNCTION/success',
        rejected: 'SET_EXCEPTION_FUNCTION/rejected',
    },
    UPDATE_SELECT_MESHBOT_TRIGGER: {
        success: 'UPDATE_SELECT_MESHBOT_TRIGGER/success',
        rejected: 'UPDATE_SELECT_MESHBOT_TRIGGER/rejected',
    },
    UPDATE_SELECT_CLOUD_VARIABLES_TRIGGER: 'UPDATE_SELECT_CLOUD_VARIABLES_TRIGGER',
    RESET_MESHBOT_TRIGGER_STATE_VALUE: {
        success: 'RESET_MESHBOT_TRIGGER_STATE_VALUE/success',
        rejected: 'RESET_MESHBOT_TRIGGER_STATE_VALUE/rejected',
    },
    UPDATE_FIELD_BLOCK: {
        success: 'UPDATE_FIELD_BLOCK/success',
        rejected: 'UPDATE_FIELD_BLOCK/rejected',
    },
    UPDATE_FIELD_SELECT_RULE: {
        success: 'UPDATE_FIELD_SELECT_RULE/success',
        rejected: 'UPDATE_FIELD_SELECT_RULE/rejected',
    },
    ADD_BLOCK_WHEN: {
        success: 'ADD_BLOCK_WHEN/success',
        rejected: 'ADD_BLOCK_WHEN/rejected',
    },
    CHANGE_ACTIVE_BLOCK: {
        success: 'CHANGE_ACTIVE_BLOCK/success',
        rejected: 'CHANGE_ACTIVE_BLOCK/rejected',
    },
    UPDATE_DATE_VALUE: {
        success: 'UPDATE_DATE_VALUE/success',
        rejected: 'UPDATE_DATE_VALUE/rejected',
    },
    UPDATE_TRIGGER_BLOCK: {
        success: 'UPDATE_TRIGGER_BLOCK/success',
        rejected: 'UPDATE_TRIGGER_BLOCK/rejected',
    },
    SAVE_DEVICE_BLOCKS: {
        success: 'SAVE_DEVICE_BLOCKS/success',
        rejected: 'SAVE_DEVICE_BLOCKS/rejected',
    },
    ADD_TRIGGER_ACTION: {
        success: 'ADD_TRIGGER_ACTION/success',
        rejected: 'ADD_TRIGGER_ACTION/rejected',
    },
    DELETE_TRIGGER_ACTION: {
        success: 'DELETE_TRIGGER_ACTION/success',
        rejected: 'DELETE_TRIGGER_ACTION/rejected',
    },
    UPDATE_TRIGGER_ACTION: {
        success: 'UPDATE_TRIGGER_ACTION/success',
        rejected: 'UPDATE_TRIGGER_ACTION/rejected',
    },
    UPDATE_ACTION_NODE: {
        success: 'UPDATE_ACTION_NODE/success',
        rejected: 'UPDATE_ACTION_NODE/rejected',
    },
    SET_ACTION_FIELD: {
        success: 'SET_ACTION_FIELD/success',
        rejected: 'SET_ACTION_FIELD/rejected',
    },
    GET_DEVICE_BLOCKS_ACTION: {
        success: 'GET_DEVICE_BLOCKS_ACTION/success',
        rejected: 'GET_DEVICE_BLOCKS_ACTION/rejected',
    },
    ADD_LUA_SCRIPT: {
        pending: 'ADD_LUA_SCRIPT/pending',
        success: 'ADD_LUA_SCRIPT/success',
        rejected: 'ADD_LUA_SCRIPT/rejected',
    },
    REMOVE_LUA_SCRIPT: {
        pending: 'REMOVE_LUA_SCRIPT/pending',
        success: 'REMOVE_LUA_SCRIPT/success',
        rejected: 'REMOVE_LUA_SCRIPT/rejected',
    },
    SET_LUA_SCRIPT: {
        pending: 'SET_LUA_SCRIPT/pending',
        success: 'SET_LUA_SCRIPT/success',
        rejected: 'SET_LUA_SCRIPT/rejected',
    },
    RUN_LUA_SCRIPT: {
        pending: 'RUN_LUA_SCRIPT/pending',
        success: 'RUN_LUA_SCRIPT/success',
        rejected: 'RUN_LUA_SCRIPT/rejected',
    },
    GET_LUA_SCRIPTS: {
        pending: 'GET_LUA_SCRIPTS/pending',
        success: 'GET_LUA_SCRIPTS/success',
        rejected: 'GET_LUA_SCRIPTS/rejected',
    },
    GET_LUA_SCRIPT: {
        pending: 'GET_LUA_SCRIPT/pending',
        success: 'GET_LUA_SCRIPT/success',
        rejected: 'GET_LUA_SCRIPT/rejected',
    },
    UPDATE_ACTION_DRAG_AND_DROP: {
        success: 'UPDATE_ACTION_DRAG_AND_DROP/success',
        rejected: 'UPDATE_ACTION_DRAG_AND_DROP/rejected',
    },
    ADD_BLOCK_THEN: {
        success: 'ADD_BLOCK_THEN/success',
        rejected: 'ADD_BLOCK_THEN/rejected',
    },
    CHANGE_ACTIVE_BLOCK_ACTION: {
        success: 'CHANGE_ACTIVE_BLOCK_ACTION/success',
        rejected: 'CHANGE_ACTIVE_BLOCK_ACTION/rejected',
    },
    UPDATE_FIELD_BLOCK_ACTION: {
        success: 'UPDATE_FIELD_BLOCK_ACTION/success',
        rejected: 'UPDATE_FIELD_BLOCK_ACTION/rejected',
    },
    UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS: {
        success: 'UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS',
        rejected: 'UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS',
    },
    GET_DEVICE_BLOCKS_EDIT: {
        pending: 'GET_DEVICE_BLOCKS_EDIT/pending',
        success: 'GET_DEVICE_BLOCKS_EDIT/success',
        rejected: 'GET_DEVICE_BLOCKS_EDIT/rejected',
    },
    SET_WS_REQUEST_STATUS: {
        success: 'SET_WS_REQUEST_STATUS/success',
        rejected: 'SET_WS_REQUEST_STATUS/rejected',
        clear: 'SET_WS_REQUEST_STATUS/clear',
    },
    UPDATE_MESHBOT: 'UPDATE_MESHBOT',
    REMOVE_MESHBOT: 'REMOVE_MESHBOT',
    //https://log.ezlo.com/new/hub/scenes/action_blocks/
    MESHBOT_LOCAL_ACTION_BLOCKS: {
        then: {
            name: 'then',
            actions: 'groupActions',
        },
        else: {
            name: 'else',
            actions: 'groupElseActions',
        },
    },

    MESHBOT_LOCAL_TRIGGER_BLOCKS: {
        when: 'when',
        exceptionWhen: 'exceptionWhen',
    },
    SET_LOCAL_MESHBOT_PAGE: 'SET_LOCAL_MESHBOT_PAGE',
    SET_VALIDATION_ERRORS: 'SET_VALIDATION_ERRORS',
    SET_VALIDATION_WARNINGS: 'SET_VALIDATION_WARNINGS',
    IS_START_API_PROCESS: 'IS_START_API_PROCESS',
    SET_ID_OF_EDITED_MESHBOT: 'SET_ID_OF_EDITED_MESHBOT',

    //------------------------------cloud meshbot------------------------------------------------

    GET_CLOUD_MESHBOT_LIST: {
        pending: 'GET_CLOUD_MESHBOT_LIST/pending',
        success: 'GET_CLOUD_MESHBOT_LIST/success',
        rejected: 'GET_CLOUD_MESHBOT_LIST/rejected',
    },
    SET_CLOUD_MESHBOT_LIST: 'SET_CLOUD_MESHBOT_LIST',
    GET_ABSTRACT_LIST: {
        pending: 'GET_ABSTRACT_LIST/pending',
        success: 'GET_ABSTRACT_LIST/success',
        rejected: 'GET_ABSTRACT_LIST/rejected',
    },
    GET_CAPABILITIES_LIST: {
        pending: 'GET_CAPABILITIES_LIST/pending',
        success: 'GET_CAPABILITIES_LIST/success',
        rejected: 'GET_CAPABILITIES_LIST/rejected',
    },
    CLOUD_DEVICE: 'cloudDevice',

    UPDATE_CONTROLLER_CAPABILITY: 'UPDATE_CONTROLLER_CAPABILITY',
    //------------------------------- edit local------------------------------------------------------

    SET_INITIAL_MESHBOT_TRIGGERS: 'SET_INITIAL_MESHBOT_TRIGGERS',
    SET_INITIAL_MESHBOT_EXCEPTIONS: 'SET_INITIAL_MESHBOT_EXCEPTIONS',
    SET_INITIAL_MESHBOT_ACTIONS: 'SET_INITIAL_MESHBOT_ACTIONS',
    SET_LOCAL_MESH_BOT_LABELS: 'SET_LOCAL_MESH_BOT_LABELS',
    SET_CLOUD_MESH_BOT_LABELS: 'SET_CLOUD_MESH_BOT_LABELS',
    SET_INITIAL_LOCAL_MESH_BOT_LABELS: 'SET_INITIAL_LOCAL_MESH_BOT_LABELS',
    SET_INITIAL_CLOUD_MESH_BOT_LABELS: 'SET_INITIAL_CLOUD_MESH_BOT_LABELS',
    SET_SELECTED_LOCAL_MESH_BOT_LABELS: 'SET_SELECTED_LOCAL_MESH_BOT_LABELS',
    SET_SELECTED_CLOUD_MESH_BOT_LABELS: 'SET_SELECTED_CLOUD_MESH_BOT_LABELS',
    SET_INITIAL_SELECTED_LOCAL_MESH_BOT_LABELS: 'SET_INITIAL_SELECTED_LOCAL_MESH_BOT_LABELS',
    SET_INITIAL_SELECTED_CLOUD_MESH_BOT_LABELS: 'SET_INITIAL_SELECTED_CLOUD_MESH_BOT_LABELS',
};
