import React, { useState, useEffect, useRef } from 'react';
import { LinearProgress, Box, Switch, Button, TextareaAutosize } from '@material-ui/core';
import { ObjectInspector } from 'react-inspector';

import { generatePaasAbstractCommand } from '../../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { apiAbstractCommand } from '../../api/apiScenes';

import styles from './TestBlockComponent.module.scss';
import { TRIGGER_FORM } from '../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_OBJECT, EZLOGIC_TITLE_RAW, EZLOGIC_TITLE_TEST } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

export const TestBlockComponent = ({ accountUuid, method, fields, onPreventDraggable, calledFrom }) => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [textField, setTextField] = useState(null);
    const currentMethod = useRef(method);
    const [isHover, setIsHover] = useState(false);
    const { t } = useTranslate();

    useEffect(() => {
        if (method !== currentMethod.current) {
            setTextField(null);
            currentMethod.current = method;
        }
    }, [method]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (calledFrom != TRIGGER_FORM) {
            onPreventDraggable(isHover);
        }
    }, [isHover, onPreventDraggable]);

    const handleRunTest = async () => {
        setLoading(true);
        try {
            const abstractCommand = generatePaasAbstractCommand({ accountUuid, method, fields });
            const response = await apiAbstractCommand(abstractCommand);
            const data = (response && response.data) || null;
            setLoading(false);
            setTextField(data);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleIsHover = () => {
        setIsHover((prev) => !prev);
    };

    return (
        <div className={styles.root}>
            {loading && (
                <div className={styles.loading}>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress size={20} />
                    </Box>
                </div>
            )}
            <Button color="primary" size="medium" variant="contained" className={styles.button} onClick={handleRunTest}>
                {t(EZLOGIC_TITLE_TEST)}
            </Button>
            <div className={styles.preview}>
                <div className="test-block__switch">
                    <span>{t(EZLOGIC_TITLE_OBJECT)}</span>
                    <Switch color="primary" checked={checked} onChange={handleChange} />
                    <span>{t(EZLOGIC_TITLE_RAW)}</span>
                </div>
                <div className={styles.inspector} onMouseLeave={handleIsHover} onMouseEnter={handleIsHover}>
                    <div className={styles.inner}>
                        {!checked && textField && <ObjectInspector data={textField} expandLevel={1} />}
                        {checked && textField && (
                            <div className={styles.raw}>
                                <TextareaAutosize
                                    className={styles.textarea}
                                    defaultValue={JSON.stringify(textField)}
                                    disabled
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
