import React from 'react';
import { useFormikContext } from 'formik';

import styles from './style.module.scss';
import { GppGoodIcon, GppMaybeIcon } from '../../../../../assets/icons';

const ValidatedIcon = ({ validated, name }) => {
    const { values, initialValues } = useFormikContext();
    const isVisible = values[name] && initialValues[name] === values[name];
    const iconBoxClassName = `${styles.validatedIconBox} ${isVisible ? styles.visibleIcon : styles.hiddenIcon}`;

    return (
        <div className={iconBoxClassName}>
            {Boolean(validated) ? <GppGoodIcon color="primary" /> : <GppMaybeIcon className={styles.gppMaybeIcon} />}
        </div>
    );
};

export default ValidatedIcon;
