import React from 'react';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Draggable } from 'react-beautiful-dnd';
import { EZLOGIC_TITLE_CONTROLLABLES_SORT } from '../../../constants';
import { useTranslate } from '../../../../../../features/languages';

import classes from '../../NodesControllables.module.scss';

const DraggableNodeSorterItem = ({ item, index, title }) => {
    const { t } = useTranslate();

    return (
        <Draggable
            key={title === t(EZLOGIC_TITLE_CONTROLLABLES_SORT) ? item.value : item.id}
            draggableId={title === t(EZLOGIC_TITLE_CONTROLLABLES_SORT) ? item.value : item.id}
            index={index}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.menuItem}
                >
                    <div {...provided.dragHandleProps}>
                        <DragHandleIcon className={classes.dragHandleIcon} />
                    </div>
                    {title === t(EZLOGIC_TITLE_CONTROLLABLES_SORT) ? t(item.name) : t(item.label)}
                </div>
            )}
        </Draggable>
    );
};

export default DraggableNodeSorterItem;
