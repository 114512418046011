import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import { getSingleData } from '../../../../../helpers/helpersMeshBot';
import MeshBotLocEdit from './MeshBotLocEdit';

const mapStateToProps = (state) => {
    return {
        serial: state.ezlo.serial,
        rules: getSingleData(state.ezlo.data, state.ezlo.serial)?.rules || [],
        items: getSingleData(state.ezlo.data, state.ezlo.serial)?.items,
        devices: getSingleData(state.ezlo.data, state.ezlo.serial)?.devices,
        comparisonOperatorsFamilies:
            state.ezlo.data[state.ezlo.serial]?.blockDataList?.comparisonOperators?.families || [],
        ruleTriggers: state.meshBot.local.ruleTriggers,
        selectedRule: state.meshBot.local.selectedRule,
        errorsValidation: state.meshBot.local?.validationErrors,
        warningsValidation: state.meshBot.local?.validationWarnings,
        isApiProcessStarted: state.meshBot.local?.isApiProcessStarted,
    };
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(MeshBotLocEdit);
