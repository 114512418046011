import oemManager from '../../services/oem';
import { useEffect, useState } from 'react';

/**
 * Hook that provides the state of oemManager in the format { isOemManagerConfig: true, oemId: 'vera' }
 * @returns {{ isOemManagerConfig: Boolean, oemId: string | null }} returns state of oemManager
 * */
export const useOemManagerState = () => {
    const [oemManagerState, setOemManagerState] = useState({ isOemManagerConfig: false, oemId: null });

    function getOemManagerState() {
        if (oemManager.oem.isConfig()) {
            setOemManagerState((prevState) => ({
                ...prevState,
                isOemManagerConfig: true,
                oemId: oemManager.oem.getOemId(),
            }));
        } else {
            setTimeout(() => getOemManagerState(), 10);
        }
    }

    useEffect(() => {
        getOemManagerState();
    }, []);

    return oemManagerState;
};
