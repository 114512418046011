import React, { forwardRef } from 'react';
import { Badge, Checkbox } from '@material-ui/core';

import useMeshBotsTableContext from '../../hooks/useMeshBotsTableContext';
import { INDETERMINATE } from '../../constants';

import styles from '../../styles.module.scss';

const SelectedMeshBotsBadge = ({ children }) => {
    const { selectedMeshBotsNumber } = useMeshBotsTableContext();

    return (
        <Badge className={styles.checkboxBadge} badgeContent={selectedMeshBotsNumber} color="primary">
            {children}
        </Badge>
    );
};

const SelectedMeshBotsCheckbox = (checkboxProps, ref) => {
    const renderedCheckbox = <Checkbox {...checkboxProps} ref={ref} />;

    if (checkboxProps.hasOwnProperty(INDETERMINATE)) {
        return <SelectedMeshBotsBadge>{renderedCheckbox}</SelectedMeshBotsBadge>;
    }

    return renderedCheckbox;
};

export default forwardRef(SelectedMeshBotsCheckbox);
