import { useState } from 'react';
import { apiLoginByUsernamePassword } from '../../../../services/auth';
import { changeUserPasswordCustomConfigApi } from '../../../../api/apiUsers';
import {
    CURRENT_PASSWORD_FIELD_NAME,
    NEW_PASSWORD_FIELD_NAME,
} from '../../../Ezlo/EzloAccount/UserManagement/ChangePassword/constants';
import { HTTP_STATUS_CODES } from '../../../../constants/common/http_status_codes';

const buildCustomAuthHeaders = (identity, identitySignature) => {
    return {
        headers: {
            MMSAuth: identity,
            MMSAuthSig: identitySignature,
        },
    };
};

/**
 * Hook, used to encapsulate logic related to SET initial password based on MMS API calls.
 * @returns {array} [data, error, loading, setPassword] values
 * */
const useMmsSetPassword = () => {
    // will keep necessary info to proceed with auth
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // encapsulated login function
    const login = async (username, password) => {
        if (!username || !password) {
            return;
        }

        return await apiLoginByUsernamePassword(username, password);
    };

    const setPassword = async (payload) => {
        setError(null);
        setData(null);
        setLoading(true);

        try {
            const {
                username,
                [CURRENT_PASSWORD_FIELD_NAME]: currentPassword,
                [NEW_PASSWORD_FIELD_NAME]: newPassword,
            } = payload;

            // login to the system
            const loginRes = await login(username, currentPassword);
            // send request to update the password
            const changePasswordPayload = {
                username,
                [CURRENT_PASSWORD_FIELD_NAME]: currentPassword,
                [NEW_PASSWORD_FIELD_NAME]: newPassword,
            };

            const config = buildCustomAuthHeaders(loginRes?.data?.Identity, loginRes?.data?.IdentitySignature);
            const setPasswordRes = await changeUserPasswordCustomConfigApi(changePasswordPayload, config);

            if (
                setPasswordRes?.status === HTTP_STATUS_CODES.OK ||
                setPasswordRes?.status === HTTP_STATUS_CODES.CREATED
            ) {
                setData({ Identity: loginRes.data.Identity });
            } else {
                setError(setPasswordRes?.status || 'Something went wrong');
            }
        } catch (err) {
            setError(err?.response || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return [data, error, loading, setPassword];
};

export default useMmsSetPassword;
