import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeviceGroupTreeContainerShowMore from '../DeviceGroupTreeContainerShowMore';
import { DEVICES_TREE_OBJECT } from '../../../../../constants/DevicesGroups';
import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';

const DeviceGroupTreeContainerPriorityOrder = () => {
    const dispatch = useDispatch();

    const serial = useSelector((state) => state.ezlo.serial);
    const deviceList = useSelector((state) => state.ezlo.data[serial]?.devices);
    const deviceGroupTreeState = useSelector((state) => state?.deviceGroups?.deviceGroupTreeState);

    useEffect(() => {
        dispatch(DeviceGroupsActions.buildDeviceGroupTreeState(DEVICES_TREE_OBJECT, deviceList));
    }, [serial]);

    return <DeviceGroupTreeContainerShowMore payload={deviceGroupTreeState} />;
};

export default DeviceGroupTreeContainerPriorityOrder;
