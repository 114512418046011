import React, { useCallback, useState } from 'react';

import LabelWithInput from '../TreeItemLabels/LabelWithInput';
import TreeItemComponent from '../components/TreeItemComponent';
import { RESPONSE_ELEMENTS } from '../constants';

const ArrayTreeElement = ({ treeElementValue, treeElementName, prevPartPath }) => {
    const [itemIndex, setItemIndex] = useState(0);
    const currentPath = `${prevPartPath}[${itemIndex}]`;
    const elementData = { treeElementName: `${treeElementName} ${itemIndex}`, path: currentPath };
    const strElementData = JSON.stringify(elementData);

    const handleIndexChange = useCallback((e) => {
        setItemIndex(e.target.value);
    }, []);

    const labelJSX = (
        <LabelWithInput
            treeItemName={treeElementName}
            elementData={strElementData}
            treeItemValue={treeElementValue}
            handleIndexChange={handleIndexChange}
            itemIndex={itemIndex}
        />
    );

    if (treeElementValue.hasOwnProperty(RESPONSE_ELEMENTS.PROPERTIES)) {
        const treeElementProperties = Object.entries(treeElementValue?.properties);

        return (
            <TreeItemComponent
                nodeId={treeElementValue.elementId}
                treeElementProperties={treeElementProperties}
                prevPartPath={currentPath}
                labelComponent={labelJSX}
            />
        );
    }

    return (
        <TreeItemComponent nodeId={treeElementValue.elementId} prevPartPath={currentPath} labelComponent={labelJSX} />
    );
};

export default ArrayTreeElement;
