import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../constants/ActionTypes';
import { LOCATION_CHANGE } from 'react-router-redux';
import { isAnotherPage } from '../containers/EzloToast/utils';

const initialState = {
    previousPath: '',
    toasts: {},
    isToastDismiss: false,
};

const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        updateToasts: (state, action) => {
            state.toasts = {
                ...state.toasts,
                ...action.payload.toast,
            };
        },
        updateToast: (state, action) => {
            const { toastId, toast } = action.payload;
            state.toasts[toastId] = toast;
        },
        deleteToast: (state, action) => {
            const { toastId } = action.payload;
            const toasts = { ...state.toasts };
            delete toasts[toastId];
            state.toasts = toasts;
        },
        clearToasts: (state) => {
            state.toasts = {};
            state.isToastDismiss = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LOCATION_CHANGE, (state, action) => {
                const { previousPath } = state;
                if (isAnotherPage(previousPath, action.payload.location.pathname)) {
                    state.isToastDismiss = true;
                }
                state.previousPath = action.payload.location.pathname;
            })
            .addCase(LOGOUT, () => initialState);
    },
});

export const { updateToasts, updateToast, deleteToast, clearToasts } = toastsSlice.actions;
export default toastsSlice;
