import React, { memo, useEffect } from 'react';

import FunctionSelect from './FunctionSelect';
import { EMPTY_LINE, RAW_JSON, SIMPLE_CODE_FUNCTIONS, TYPES_OF_FUNCTIONS } from '../../../../constants';

const FunctionSelectContainer = ({ currentFunc, value, setCurrentFunc, handleChangeValue }) => {
    useEffect(() => {
        if (value) {
            if (value.includes(TYPES_OF_FUNCTIONS.SLICE)) {
                setCurrentFunc(SIMPLE_CODE_FUNCTIONS.SLICE);
            } else if (value.includes(TYPES_OF_FUNCTIONS.FIND)) {
                setCurrentFunc(SIMPLE_CODE_FUNCTIONS.FIND);
            } else {
                setCurrentFunc(SIMPLE_CODE_FUNCTIONS.JSON);
            }
        }
    }, []);

    const handleChangeFunc = (e, opt) => {
        setCurrentFunc(opt);
        if (opt.uuid === TYPES_OF_FUNCTIONS.JSON) {
            handleChangeValue(RAW_JSON);
        } else {
            handleChangeValue(EMPTY_LINE);
        }
    };

    return <FunctionSelect currentFunc={currentFunc} handleChangeFunc={handleChangeFunc} />;
};

export default memo(FunctionSelectContainer);
