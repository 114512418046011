import React from 'react';
import { Modal } from '@material-ui/core';

import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_GETTING_READY,
    EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_ACCOUNT,
    EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_CONTROLLER,
} from '../../../../../constants/language_tokens';
import { IcVisualsSpinner } from '../../../../../assets/icons';

import style from '../EzloIntegrationsFailedToCreateModal/style.module.scss';

const EzloIntegrationsIntegrationInProcess = ({ open, onClose, isIntegrations }) => {
    const { t } = useTranslate();

    return (
        <Modal open={open} onClose={onClose} className={style.modal}>
            <div className={style.modalContainer}>
                <div className={style.spinnerWrapper}>
                    <IcVisualsSpinner />
                </div>
                <h4>{t(EZLOGIC_TITLE_GETTING_READY)}</h4>
                {isIntegrations ? (
                    <div className={style.waitText}>{t(EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_ACCOUNT)}</div>
                ) : (
                    <div className={style.waitText}>{t(EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_CONTROLLER)}</div>
                )}
            </div>
        </Modal>
    );
};
export default EzloIntegrationsIntegrationInProcess;
