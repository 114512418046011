import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    MARKETPLACE,
    PRIVATE,
    REJECTED,
    SUCCESS,
    CODE,
    DEFAULT_CREATOR_VALUE,
    ALL_OEMS_ID,
} from '../../../../../constants/Plugins';
import PluginsList from '../../components/PluginsList';
import ModalPlugin from '../../components/ModalPlugin';
import toastsActions from '../../../../../actions/toastsActions';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../../constants/toastConstants';
import { EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE } from '../../../../../constants/language_tokens';

const Marketplace = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);
    const dispatch = useDispatch();

    const {
        marketplacePlugins,
        loaderList,
        isRequestStatus,
        isRequestStatusMessage,
        history,
        onHandlerSetIsActivePage,
        PluginActions,
    } = props;

    useEffect(() => {
        PluginActions.getPublicPlugins(CODE, DEFAULT_CREATOR_VALUE, ALL_OEMS_ID);
    }, []);

    useEffect(() => {
        if (isRequestStatus === REJECTED) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    isSave: true,
                }),
            );
        }

        if (isRequestStatus === SUCCESS) {
            history.replace('/ezlo/plugins/edge/private');
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: SUCCESS_OPTIONS,
                }),
            );
            onHandlerSetIsActivePage(PRIVATE);
        }

        PluginActions.clearRequestStatus();
    }, [isRequestStatus]);

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    const handlerShowInfoModal = useCallback((data) => {
        setPluginData(data);
        setIsModal(true);
        setTypeModal('info');
    }, []);

    return (
        <div className="plugin-edge__list">
            <PluginsList
                type={MARKETPLACE}
                title={EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE}
                listPlugins={marketplacePlugins}
                loaderList={loaderList}
                onConfirm={onConfirm}
                onHandlerShowInfoModal={handlerShowInfoModal}
            />
            <ModalPlugin
                typeList={MARKETPLACE}
                open={isModal}
                pluginData={pluginData}
                currentAction={currentAction}
                typeModal={typeModal}
                onCancel={hideModal}
                PluginActions={PluginActions}
            />
        </div>
    );
};

Marketplace.propTypes = {
    marketplacePlugins: PropTypes.array,
    loaderList: PropTypes.bool,
    isRequestStatus: PropTypes.string,
    isRequestStatusMessage: PropTypes.string,
    PluginActions: PropTypes.object,
    onHandlerSetIsActivePage: PropTypes.func,
};

export default Marketplace;
