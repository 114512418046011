import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Checkbox, FormControlLabel, InputAdornment, IconButton } from '@material-ui/core';

import Headers from './headers';
import Input from '../../../../../components/InputMaterial';
import ActionDelay from '../../../../../components/ActionDelay';
import BlockOfSaveOutput from '../SaveOutputBlock/SaveOutputBlock';

import { info } from '../../utils';
import {
    requestMethods,
    GET,
    HTTP_REQUEST_SELECTED_FIELD,
    HTTP_REQUEST_FIELD_KIND,
} from '../../../../../constants/MeshbotConstant';

import styles from './HttpRequest.module.scss';
import SelectComponent from '../SelectComponent';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_HEADING_CREDENTIALS,
    EZLOGIC_TITLE_CONTENT,
    EZLOGIC_TITLE_CONTENT_TYPE,
    EZLOGIC_TITLE_IGNORE_SELF_SIGNED_INVALID_CERTIFICATES,
    EZLOGIC_TITLE_PASSWORD,
    EZLOGIC_TITLE_PUT_REQUEST_BODY_HERE,
    EZLOGIC_TITLE_SENDING_CONTENT,
    EZLOGIC_TITLE_TARGET_URL,
    EZLOGIC_TITLE_TYPE_OF_SENDING_CONTENT,
    EZLOGIC_TITLE_URL,
    EZLOGIC_TITLE_URL_PLACEHOLDER,
    EZLOGIC_TITLE_USER_ID,
} from '../../../../../constants/language_tokens';
import { getRequestMethodValue } from './utils';
import { EyeIcon, EyeOffIcon } from '../../../../../assets/icons';

const HttpRequest = (props) => {
    const [url, setUrl] = useState('');
    const [credentialUser, setCredentialUser] = useState('');
    const [credentialPassword, setCredentialPassword] = useState('');
    const [contentType, setContentType] = useState('');
    const [content, setContent] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslate();
    const {
        id,
        selectedHeaders,
        selectedRequest,
        selectedUrl,
        selectedContent,
        selectedContentType,
        selectedSecurity,
        selectedCredentialUser,
        selectedCredentialPassword,
        selectedFieldTrigger,
        isShowedDelay,
        currentRule,
        actionBlockName,
        onUpdateActionDelay,
        onHandlerSetActionField,
        onChangeDelay,
        onSetIsShowedDelay,
        blocks,
        isEditing,
    } = props;

    useEffect(() => {
        if (selectedUrl !== url) {
            setUrl(selectedUrl);
        }

        if (selectedCredentialUser !== credentialUser) {
            setCredentialUser(selectedCredentialUser);
        }

        if (selectedCredentialPassword !== credentialPassword) {
            setCredentialPassword(selectedCredentialPassword);
        }

        if (selectedContentType !== contentType) {
            setContentType(selectedContentType);
        }

        if (selectedContent !== content) {
            setContent(selectedContent);
        }
    }, [selectedUrl, selectedCredentialUser, selectedCredentialPassword, selectedContentType, selectedContent]);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const requestMethodValue = getRequestMethodValue(selectedRequest);

    return (
        <>
            <SelectComponent
                value={requestMethodValue}
                label={info.hints.capability}
                info={info.text.capability}
                onChange={(e) => onHandlerSetActionField(e.target.value, HTTP_REQUEST_SELECTED_FIELD.SELECTED_REQUEST)}
            >
                {requestMethods.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.value}>
                            {t(item.name).toUpperCase()}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
            {selectedRequest !== '' && (
                <>
                    <ActionDelay
                        id={id}
                        type={selectedFieldTrigger}
                        currentRule={currentRule}
                        isShowedDelay={isShowedDelay}
                        actionBlockName={actionBlockName}
                        onUpdateActionDelay={onUpdateActionDelay}
                        onChangeDelay={onChangeDelay}
                        onSetIsShowedDelay={onSetIsShowedDelay}
                    />

                    <div className={styles.wrapper}>
                        <section className={styles.section}>
                            <h4 className={styles.title}>{t(EZLOGIC_TITLE_TARGET_URL)}</h4>
                            <FormControl className={styles.input_block}>
                                <Input
                                    label={t(EZLOGIC_TITLE_URL)}
                                    placeholder={t(EZLOGIC_TITLE_URL_PLACEHOLDER)}
                                    multiline
                                    value={url ?? ''}
                                    onChange={(e) =>
                                        onHandlerSetActionField(
                                            e.target.value,
                                            HTTP_REQUEST_SELECTED_FIELD.SELECTED_URL,
                                            HTTP_REQUEST_FIELD_KIND.URL,
                                        )
                                    }
                                />
                            </FormControl>
                            <div className={styles.security}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedSecurity ?? false}
                                            onChange={(e) =>
                                                onHandlerSetActionField(
                                                    e.target.checked,
                                                    HTTP_REQUEST_SELECTED_FIELD.SELECTED_SECURITY,
                                                    HTTP_REQUEST_FIELD_KIND.SKIP_SECURITY,
                                                )
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={t(EZLOGIC_TITLE_IGNORE_SELF_SIGNED_INVALID_CERTIFICATES)}
                                />
                            </div>
                        </section>
                        <section className={styles.section}>
                            <h4 className={styles.title}>{t(EZLOGIC_HEADING_CREDENTIALS)}</h4>
                            <FormControl className={styles.input_block}>
                                <Input
                                    label={t(EZLOGIC_TITLE_USER_ID)}
                                    autoComplete="new-password"
                                    placeholder={t(EZLOGIC_TITLE_USER_ID)}
                                    value={credentialUser ?? ''}
                                    onChange={(e) =>
                                        onHandlerSetActionField(
                                            e.target.value,
                                            HTTP_REQUEST_SELECTED_FIELD.SELECTED_CREDENTIAL_USER,
                                            HTTP_REQUEST_FIELD_KIND.CREDENTIAL,
                                        )
                                    }
                                />
                            </FormControl>
                            <FormControl className={styles.input_block}>
                                <Input
                                    label={t(EZLOGIC_TITLE_PASSWORD)}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    placeholder={t(EZLOGIC_TITLE_PASSWORD)}
                                    value={credentialPassword ?? ''}
                                    onChange={(e) =>
                                        onHandlerSetActionField(
                                            e.target.value,
                                            HTTP_REQUEST_SELECTED_FIELD.SELECTED_CREDENTIAL_PASSWORD,
                                            HTTP_REQUEST_FIELD_KIND.CREDENTIAL,
                                        )
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? (
                                                    <EyeIcon className={styles.passwordIcon} />
                                                ) : (
                                                    <EyeOffIcon className={styles.passwordIcon} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </section>
                        <Headers selectedHeaders={selectedHeaders} onHandlerSetActionField={onHandlerSetActionField} />
                        {selectedRequest !== GET && (
                            <>
                                <section className={styles.section}>
                                    <h4 className={styles.title}>{t(EZLOGIC_TITLE_TYPE_OF_SENDING_CONTENT)}</h4>
                                    <FormControl className={styles.input_block}>
                                        <Input
                                            label={t(EZLOGIC_TITLE_CONTENT_TYPE)}
                                            placeholder={t(EZLOGIC_TITLE_CONTENT_TYPE)}
                                            value={contentType ?? ''}
                                            onChange={(e) =>
                                                onHandlerSetActionField(
                                                    e.target.value,
                                                    HTTP_REQUEST_SELECTED_FIELD.SELECTED_CONTENT_TYPE,
                                                    HTTP_REQUEST_FIELD_KIND.CONTENT_TYPE,
                                                )
                                            }
                                        />
                                    </FormControl>
                                </section>
                                <section className={styles.section}>
                                    <h4 className={styles.title}>{t(EZLOGIC_TITLE_SENDING_CONTENT)}</h4>
                                    <Input
                                        label={t(EZLOGIC_TITLE_CONTENT)}
                                        placeholder={t(EZLOGIC_TITLE_PUT_REQUEST_BODY_HERE)}
                                        multiline
                                        value={content ?? ''}
                                        onChange={(e) =>
                                            onHandlerSetActionField(
                                                e.target.value,
                                                HTTP_REQUEST_SELECTED_FIELD.SELECTED_CONTENT,
                                                HTTP_REQUEST_FIELD_KIND.CONTENT,
                                            )
                                        }
                                    />
                                </section>
                            </>
                        )}
                    </div>
                    <BlockOfSaveOutput
                        id={id}
                        blockName={actionBlockName}
                        blocks={blocks}
                        isEditing={isEditing}
                        infoButtonText={info.text.saveResultHttpRequestInVariableText}
                    />
                </>
            )}
        </>
    );
};

export default React.memo(HttpRequest);

HttpRequest.propTypes = {
    id: PropTypes.string,
    selectedRequest: PropTypes.string,
    selectedUrl: PropTypes.string,
    selectedContent: PropTypes.string,
    selectedContentType: PropTypes.string,
    selectedSecurity: PropTypes.bool,
    selectedCredentialUser: PropTypes.string,
    selectedCredentialPassword: PropTypes.string,
    selectedFieldTrigger: PropTypes.string,
    selectedHeaders: PropTypes.object,
    actionBlockName: PropTypes.string,
    isShowedDelay: PropTypes.string,
    currentRule: PropTypes.object,
    onUpdateActionDelay: PropTypes.func,
    onHandlerSetActionField: PropTypes.func,
    onSetIsShowedDelay: PropTypes.func,
    blocks: PropTypes.array,
    isEditing: PropTypes.bool,
};
