import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { NotificationActions } from '../../../../../actions';

import { SYSTEM_EVENTS_CAPABILITIES } from '../../../../../constants/Notifications';

import styles from './EzloNotificationCapabilityDropdown.module.scss';
import { Autocomplete } from '@material-ui/lab';
import { getCapabilitiesForNotificationTemplate, findSelectedCapabilityName } from '../../utils';
import { TextField } from '../../../../../components';

const EzloNotificationCapabilityDropdown = (props) => {
    const { isSystemNotificationTemplate, listCapabilities } = props;

    const dispatch = useDispatch();
    const capability = useSelector((state) => state?.notifications?.notificationSelectedCapability);
    const capabilities = isSystemNotificationTemplate ? SYSTEM_EVENTS_CAPABILITIES : listCapabilities;

    const handleNotificationTemplateCapability = (event, value) => {
        dispatch(NotificationActions.setNotificationSelectedCapability(value));
    };

    return (
        <Box className={styles.notificationCapabilityDropdownWrapper}>
            <div className={styles.notificationCapabilityDropdownSelect}>
                <Autocomplete
                    options={getCapabilitiesForNotificationTemplate(capabilities)}
                    autoComplete={true}
                    getOptionLabel={(option) => option ?? ''}
                    value={findSelectedCapabilityName(capability, capabilities) ?? ''}
                    disableClearable={true}
                    getOptionSelected={(option, value) => {
                        return option === value;
                    }}
                    onChange={handleNotificationTemplateCapability}
                    renderInput={(params) => {
                        return <TextField {...params} />;
                    }}
                />
            </div>
        </Box>
    );
};

export default EzloNotificationCapabilityDropdown;
