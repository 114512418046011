export default {
    CLEAR_JOB_UUID: 'CLEAR_JOB_UUID',
    SET_REQUEST_STATUS: {
        success: 'SET_REQUEST_STATUS/success',
        rejected: 'SET_REQUEST_STATUS/rejected',
        clear: 'SET_REQUEST_STATUS/clear',
    },
    UPLOAD_PLUGIN: {
        pending: 'UPLOAD_PLUGIN/pending',
        success: 'UPLOAD_PLUGIN/success',
        rejected: 'UPLOAD_PLUGIN/rejected',
    },
    CREATE_DASHBOARD: {
        pending: 'CREATE_DASHBOARD/pending',
        success: 'CREATE_DASHBOARD/success',
        rejected: 'CREATE_DASHBOARD/rejected',
    },
    CREATE_DASHBOARD_WIFI: {
        pending: 'CREATE_DASHBOARD_WIFI/pending',
        success: 'CREATE_DASHBOARD_WIFI/success',
        rejected: 'CREATE_DASHBOARD_WIFI/rejected',
    },
    SET_DASHBOARD_WIFI: {
        pending: 'SET_DASHBOARD_WIFI/pending',
        success: 'SET_DASHBOARD_WIFI/success',
        rejected: 'SET_DASHBOARD_WIFI/rejected',
    },
    DELETE_DASHBOARD: {
        pending: 'DELETE_DASHBOARD/pending',
        success: 'DELETE_DASHBOARD/success',
        rejected: 'DELETE_DASHBOARD/rejected',
    },
    DEPLOY_PLUGIN: {
        pending: 'DEPLOY_PLUGIN/pending',
        success: 'DEPLOY_PLUGIN/success',
        rejected: 'DEPLOY_PLUGIN/rejected',
    },
    REMOVE_PLUGIN: {
        pending: 'REMOVE_PLUGIN/pending',
        success: 'REMOVE_PLUGIN/success',
        rejected: 'REMOVE_PLUGIN/rejected',
    },
    UPGRADE_PLUGIN: {
        pending: 'UPGRADE_PLUGIN/pending',
        success: 'UPGRADE_PLUGIN/success',
        rejected: 'UPGRADE_PLUGIN/rejected',
    },
    GET_DASHBOARD_CREATE_LIST: {
        pending: 'GET_DASHBOARD_CREATE_LIST/pending',
        success: 'GET_DASHBOARD_CREATE_LIST/success',
        rejected: 'GET_DASHBOARD_CREATE_LIST/rejected',
    },
    GET_CAPABILITIES_PLUGIN_LIST: {
        pending: 'GET_CAPABILITIES_PLUGIN_LIST/pending',
        success: 'GET_CAPABILITIES_PLUGIN_LIST/success',
        rejected: 'GET_CAPABILITIES_PLUGIN_LIST/rejected',
    },
    GET_DEVICES_PLUGIN_LIST: {
        pending: 'GET_DEVICES_PLUGIN_LIST/pending',
        success: 'GET_DEVICES_PLUGIN_LIST/success',
        rejected: 'GET_DEVICES_PLUGIN_LIST/rejected',
    },
    GET_PRIVATE_PLUGINS: {
        pending: 'GET_PRIVATE_PLUGINS/pending',
        success: 'GET_PRIVATE_PLUGINS/success',
        rejected: 'GET_PRIVATE_PLUGINS/rejected',
    },
    GET_PUBLIC_PLUGINS: {
        pending: 'GET_PUBLIC_PLUGINS/pending',
        success: 'GET_PUBLIC_PLUGINS/success',
        rejected: 'GET_PUBLIC_PLUGINS/rejected',
    },
    GET_LIST_INSTALLED_PLUGINS: {
        pending: 'GET_LIST_INSTALLED_PLUGINS/pending',
        success: 'GET_LIST_INSTALLED_PLUGINS/success',
        rejected: 'GET_LIST_INSTALLED_PLUGINS/rejected',
    },
    SET_PUBLISH_PLUGIN: {
        pending: 'SET_PUBLISH_PLUGIN/pending',
        success: 'SET_PUBLISH_PLUGIN/success',
        rejected: 'SET_PUBLISH_PLUGIN/rejected',
    },
    DOWNLOAD_PLUGIN: {
        pending: 'DOWNLOAD_PLUGIN/pending',
        success: 'DOWNLOAD_PLUGIN/success',
        rejected: 'DOWNLOAD_PLUGIN/rejected',
    },
    GET_STORAGE_LIST: {
        pending: 'GET_STORAGE_LIST/pending',
        success: 'GET_STORAGE_LIST/success',
        rejected: 'GET_STORAGE_LIST/rejected',
    },
    SET_CONFIGURE_PLUGIN: {
        pending: 'SET_CONFIGURE_PLUGIN/pending',
        success: 'SET_CONFIGURE_PLUGIN/success',
        rejected: 'SET_CONFIGURE_PLUGIN/rejected',
    },
    SET_PUBLISH_TO_PRIVATE: {
        pending: 'SET_PUBLISH_TO_PRIVATE/pending',
        success: 'SET_PUBLISH_TO_PRIVATE/success',
        rejected: 'SET_PUBLISH_TO_PRIVATE/rejected',
    },
    ADD_DEVICE_WIFI_GENERATOR: {
        pending: 'ADD_DEVICE_WIFI_GENERATOR/pending',
        success: 'ADD_DEVICE_WIFI_GENERATOR/success',
        rejected: 'ADD_DEVICE_WIFI_GENERATOR/rejected',
    },
    GET_PREFIXES_LIST: {
        pending: 'GET_PREFIXES_LIST/pending',
        success: 'GET_PREFIXES_LIST/success',
        rejected: 'GET_PREFIXES_LIST/rejected',
    },
    SET_PREFIXES: {
        pending: 'SET_PREFIXES/pending',
        success: 'SET_PREFIXES/success',
        rejected: 'SET_PREFIXES/rejected',
    },
    SET_ACTIVE_INSTALLING: 'SET_ACTIVE_INSTALLING',
    REMOVE_ACTIVE_INSTALLING: 'REMOVE_ACTIVE_INSTALLING',
    SET_PLUGIN_INTEGRATION: {
        pending: 'SET_PLUGIN_INTEGRATION/pending',
        success: 'SET_PLUGIN_INTEGRATION/success',
        rejected: 'SET_PLUGIN_INTEGRATION/rejected',
    },
    HANDLE_CLOSE_SUCCESS_PLUGIN_MODAL: 'HANDLE_CLOSE_SUCCESS_PLUGIN_MODAL',
    HANDLE_CLOSE_REJECTED_MODAL: 'HANDLE_CLOSE_REJECTED_MODAL',
    HANDLE_CLOSE_PENDING_MODAL: 'HANDLE_CLOSE_PENDING_MODAL',
    SET_INTEGRATION_CONFIGURE_PLUGIN: {
        pending: 'SET_INTEGRATION_CONFIGURE_PLUGIN/pending',
        success: 'SET_INTEGRATION_CONFIGURE_PLUGIN/success',
        rejected: 'SET_INTEGRATION_CONFIGURE_PLUGIN/rejected',
    },
};
