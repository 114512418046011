import React from 'react';

import MeshBotButtonsWrapper from '../MeshBotButtonsWrapper';
import MeshBotLabelsContainer from '../../../../../features/Labels/MeshBotsLabels/components/MeshBotLabelsContainer';
import { content } from './MeshBotContent.module.scss';

const MeshBotContent = ({ children, isDisabledSaveButton, onClickSaveButton, isLoading, type }) => {
    return (
        <div className={content}>
            <MeshBotLabelsContainer type={type} />
            {children}
            <MeshBotButtonsWrapper isLoading={isLoading} disabled={isDisabledSaveButton} onClick={onClickSaveButton} />
        </div>
    );
};

export default MeshBotContent;
