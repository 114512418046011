import { useCallback, useMemo, useState } from 'react';
import { buildSelectedMeshBotsDataByType } from '../../utils';

const useSelectedMeshBotsState = (allMeshbots) => {
    const [selectedMeshBotsByType, setSelectedMeshBotsByType] = useState({});
    const [selectedMeshBotsNumber, setSelectedMeshBotsNumber] = useState(0);

    const updateSelectedMeshBots = useCallback(
        (selectedMeshBotsIds) => {
            const selectedMeshBots = buildSelectedMeshBotsDataByType(selectedMeshBotsIds, allMeshbots);
            setSelectedMeshBotsByType(selectedMeshBots);
            setSelectedMeshBotsNumber(selectedMeshBotsIds.length);
        },
        [allMeshbots, setSelectedMeshBotsByType, setSelectedMeshBotsNumber],
    );

    return useMemo(
        () => ({
            selectedMeshBotsNumber,
            selectedMeshBotsByType,
            updateSelectedMeshBots,
        }),
        [selectedMeshBotsByType, selectedMeshBotsNumber, updateSelectedMeshBots],
    );
};

export default useSelectedMeshBotsState;
