import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import hash from '../../../../constants/uniqueHash';
import { InputMaterial } from '../../../../components';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_TITLE_ENTER_TEXT,
    EZLOGIC_TITLE_FALSE,
    EZLOGIC_TITLE_TRUE,
} from '../../../../constants/language_tokens';

const TriggerFieldBlockCloud = (props) => {
    const [valueText, setValueText] = useState('');
    const { idBlock, idGroup, parameters, listCapabilities, nameBlock, onUpdateCloudTriggerBlock } = props;
    const { t } = useTranslate();
    const switchOptions = [
        { id: hash(), value: 1, label: EZLOGIC_TITLE_TRUE },
        { id: hash(), value: 0, label: EZLOGIC_TITLE_FALSE },
    ];

    useEffect(() => {
        setValueText(getValueFieldText(parameters));
    }, [parameters]);

    const getCapabilities = (name) => {
        const [currentCapabilities] = listCapabilities.filter((item) => item.capability_name === name);
        if (currentCapabilities) {
            const { variables } = currentCapabilities.definition;
            if (variables.text && variables.text.type === 'string') {
                return 'textInput';
            } else if (variables.status && variables.status.type === 'integer' && variables.status.max === 1) {
                return 'switch';
            } else if (variables.percentage && variables.percentage.type === 'integer') {
                return 'slider';
            }
        }
    };

    const createPercentage = () => {
        let result = [];
        for (let i = 1; i <= 100; i++) {
            result = [...result, { id: hash(), value: i, label: `${i}%` }];
        }

        return result;
    };

    const getValueField = (data) => data[1].parameters[0].value;
    const getValueFieldSlider = (data) => Number(data[1].parameters[0].value);
    const getValueFieldText = (data) => data[1].parameters[0].value;

    const changeFocus = () => {
        if (getValueFieldText(parameters) !== valueText) {
            onUpdateCloudTriggerBlock(idBlock, valueText, nameBlock, idGroup);
        }
    };

    const changeValueText = (e) => setValueText(e.target.value);
    const changeValueSlider = (e) => onUpdateCloudTriggerBlock(idBlock, e.target.value, nameBlock, idGroup);

    return (
        <div className="trigger-block__field-body">
            {getCapabilities(nameBlock) === 'switch' && (
                <FormControl className="trigger-block__select operator">
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        value={getValueField(parameters) ? getValueField(parameters) : ''}
                        onChange={(e) => changeValueSlider(e)}
                    >
                        {switchOptions.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.value}>
                                    {t(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
            {getCapabilities(nameBlock) === 'slider' && (
                <FormControl className="trigger-block__select operator">
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        value={getValueFieldSlider(parameters) ? getValueFieldSlider(parameters) : ''}
                        onChange={(e) => changeValueSlider(e)}
                    >
                        {createPercentage().map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
            {getCapabilities(nameBlock) === 'textInput' && (
                <InputMaterial
                    placeholder={t(EZLOGIC_TITLE_ENTER_TEXT)}
                    value={valueText ? valueText : ''}
                    onBlur={changeFocus}
                    onChange={(e) => changeValueText(e)}
                />
            )}
        </div>
    );
};

export default TriggerFieldBlockCloud;

TriggerFieldBlockCloud.propTypes = {
    idBlock: PropTypes.string,
    idGroup: PropTypes.string,
    nameBlock: PropTypes.string,
    listCapabilities: PropTypes.array,
    parameters: PropTypes.array,
    onUpdateCloudTriggerBlock: PropTypes.func,
};
