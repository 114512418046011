import React from 'react';
import { Switch } from '@material-ui/core';

const SimpleBooleanField = ({ value, onChange, name }) => {
    const handleChange = (e) => {
        onChange(e.target.checked, name);
    };

    return (
        <div>
            <Switch color="primary" defaultChecked={value} value={value} onChange={handleChange} />
        </div>
    );
};

export default SimpleBooleanField;
