import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getIn, useFormikContext } from 'formik';
import { CircularProgress } from '@material-ui/core';

import AutocompleteSelect from '../AutocompleteSelect';
import AccountActions from '../../../../../actions/AccountActions';
import { VALUE_TYPES } from '../../../../../constants/AccountInfo';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_STATE_OR_PROVINCE } from '../../../../../constants/language_tokens';

const StateSelect = ({ name, isDisabled, setPK_Region, PK_Country, addressName }) => {
    const dispatch = useDispatch();
    const { setFieldValue, values } = useFormikContext();
    const fieldValue = getIn(values, name);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(!options.length && !!PK_Country);
    const { t } = useTranslate();

    useEffect(() => {
        const fetchRegions = async () => {
            setLoading(true);
            const regions = await dispatch(AccountActions.getRegions(PK_Country));
            await setOptions(regions);
            setLoading(false);
        };

        if (PK_Country) {
            fetchRegions();
        }
    }, [PK_Country]);

    useEffect(() => {
        if (options.length && fieldValue && typeof fieldValue === VALUE_TYPES.STRING) {
            const value = options.find((option) => option.Name === fieldValue || option.PK_Region === fieldValue) || '';
            if (value.PK_Region) {
                setPK_Region(value.PK_Region);
            }
            setFieldValue(name, value);
        }
    }, [options]);

    const autocompleteProps = {
        options,
        loading,
        disabled: isDisabled || !PK_Country,
        name,
    };

    const handleChange = (e, value) => {
        setFieldValue(name, value);
        setFieldValue(`${addressName}.City`, '');
        setPK_Region(value.PK_Region);
    };

    return (
        <AutocompleteSelect
            label={t(EZLOGIC_TITLE_STATE_OR_PROVINCE)}
            autocompleteProps={autocompleteProps}
            inputEndAdornment={loading ? <CircularProgress color="inherit" size={20} /> : null}
            handleChange={handleChange}
        />
    );
};

export default StateSelect;
