import React from 'react';

import './styles.scss';

import AssociationsLayout from './DeviceAssociationsLayout';

const EzloAssociations = () => {
    return <AssociationsLayout />;
};

export default EzloAssociations;
