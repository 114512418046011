import * as types from '../constants/ActionTypes';
import ajax from 'xdr';
import { GEOCODE_API_URL, API_KEY } from '../constants/GeoLocation';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';

ajax.defaults = {
    method: 'GET',
    timeout: 10000,
    delay: 0,
    async: true,
    parameter: 'packet',
    jsonp: 'callback',
    error: 'error',
    status: 'code',
    headers: {},
};

const actions = {
    jumpToStep: (nextStep, name) => (dispatch) => {
        dispatch({
            type: '@react-redux-wizard/NEXT_STEP',
            payload: { name, nextStep },
        });
    },
    selectEZLOSerialId: (serialId) => (dispatch) => {
        dispatch({
            type: types.WIZARD_HUB_SERIAL_SELECTED_SERIAL_ID,
            payload: { serialId },
        });
    },
    setValidateState: (isValid) => (dispatch) => {
        dispatch({
            type: types.WIZARD_HUB_SET_VALIDATION_STATE,
            payload: { isValid },
        });
    },
    setEzloName: (ezloName) => (dispatch) => {
        dispatch({
            type: types.WIZARD_HUB_SET_EZLO_DEVICE_NAME,
            payload: { ezloName },
        });
    },
    getWifiList: () => () =>
        new Promise((res, rej) => {
            ajax(
                {
                    url: 'http://192.168.4.1:54540/wifi/networks',
                    method: 'GET',
                },
                (response) => {
                    const responseData = parseJsonData(response);

                    if (!response.error && responseData.status === 'success') {
                        res(responseData);
                    } else {
                        bugsnagNotify('Get Wifi List Error', { responseData });
                        rej(responseData);
                    }
                },
            );
        }),
    getWifiStatus: () => () =>
        new Promise((res, rej) => {
            ajax(
                {
                    url: 'http://192.168.4.1:54540/wifi/status',
                    method: 'GET',
                },
                (response) => {
                    const responseData = parseJsonData(response);

                    if (!response.error && responseData.status === 'success') {
                        res(responseData);
                    } else {
                        bugsnagNotify('Get Wifi Status Error', { responseData });
                        rej(responseData);
                    }
                },
            );
        }),
    getAddressByHubLocation: (latitude, longitude) => () => {
        return new Promise((res, rej) => {
            ajax(
                {
                    url: GEOCODE_API_URL,
                    method: 'POST',
                    data: JSON.stringify({
                        latlng: `${latitude},${longitude}`,
                        location_type: 'ROOFTOP',
                        result_type: 'street_address',
                        language: localStorage.getItem('language') || 'en-US',
                        key: API_KEY,
                    }),
                },
                (response) => {
                    const responseData = parseJsonData(response);

                    if (!response.error && responseData.status === 'success') {
                        res(responseData);
                    } else {
                        bugsnagNotify('Get Address By Hub Location Error', { responseData, latitude, longitude });
                        rej(responseData);
                    }
                },
            );
        });
    },
    connectToWifi: (ssid, passkey) => () => {
        return new Promise((res, rej) => {
            ajax(
                {
                    url: 'http://192.168.4.1:54540/wifi/network/connect',
                    method: 'POST',
                    data: JSON.stringify({ ssid, passkey }),
                },
                (response) => {
                    const responseData = parseJsonData(response);

                    if (!response.error && responseData.status === 'success') {
                        res(responseData);
                    } else {
                        bugsnagNotify('Connect To IP Error', { ssid, passkey, responseData });
                        rej(responseData);
                    }
                },
            );
        });
    },
    setEzloAtomSN: (SN) => (dispatch) => {
        dispatch({
            type: 'WIZARD_HUB_SET_EZLO_ATOM_SERIAL_NUMBER',
            payload: { SN },
        });
    },
    openAtomWindow: () => () => {
        //   function _calculatePopupSizeWindow({innerHeight, innerWidth}) {
        //     const cH = innerHeight/2;
        //     const cW = innerWidth/1.3;
        //     const MinWidth = 600;
        //     const MinHeight = 700;
        //     const width = cW < MinWidth ? MinWidth : cW;
        //     const height = cH < MinHeight ? innerHeight*.9 : cH;
        //
        //     return {width, height}
        //   }
        //
        //   const calculatedSize = _calculatePopupSizeWindow({innerHeight: window.innerHeight, innerWidth: window.innerWidth});
        //   const w = calculatedSize.width;
        //   const h = calculatedSize.height;
        //   const name = localStorage.getItem('name');
        //   const hash = localStorage.getItem('hash');
        //   const language = localStorage.getItem('language') || 'en_US';
        //
        //   let url = '';
        //   if (window.location.href.indexOf('//my-dev.') !== -1) {
        //     url = 'http://atom-wizard-dev.ezlo.com';
        //   } else if (window.location.href.indexOf('//my-qa.') !== -1) {
        //     url = 'http://atom-wizard-qa.ezlo.com';
        //   } else if (window.location.href.indexOf('//my-stage.') !== -1) {
        //     url = 'http://atom-wizard-stage.ezlo.com';
        //   } else if (window.location.href.indexOf('//my-us.') !== -1) {
        //     url = 'http://atom-wizard.ezlo.com';
        //   } else if (window.location.href.indexOf('//my.') !== -1) {
        //     url = 'http://atom-wizard.ezlo.com';
        //
        //     // zlink
        //   } else if (window.location.href.indexOf('//zlink.') !== -1) {
        //     url = 'http://zlink-wizard.ezlo.com';
        //   } else if (window.location.href.indexOf('//zlink-qa.') !== -1) {
        //     url = 'http://zlink-wizard-qa.ezlo.com';
        //
        //     // $5 hub servers
        //   } else if (window.location.href.indexOf('//my-tiny.') !== -1) {
        //     url = 'http://atom-wizard-tiny.ezlo.com';
        //   } else if (window.location.href.indexOf('localhost') !== -1) {
        //     url = 'http://localhost:4444/atom-wizard.html';
        //   } else if (window.location.href.indexOf('192.168.') !== -1) {
        //     url = `http://192.168.10.200:4444/atom-wizard.html`;
        //   } else {
        //     // default;
        //     url = 'http://atom-wizard.ezlo.com';
        //   }
        //
        //   url = `${url}?language=${language}&email=${name}&hash=${hash}${disableRegistrationAtomStep === true ? '&disableRegistrationAtomStep=true' : ''}`;
        //   const title = t('title_select_hub_atom');
        //
        //   // Fixes dual-screen position                         Most browsers      Firefox
        //   const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        //   const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
        //
        //   const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        //   const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
        //
        //   const left = ((width / 2) - (w / 2)) + dualScreenLeft;
        //   const top = ((height / 2) - (h / 2)) + dualScreenTop;
        //   let newWindow = window.open(url, title, `location=0, status=0, scrollbars=no, menubar=0, resizable=0, width=${w}, height=${h}, top=${top}, left=${left}`);
        //
        //   // Puts focus on the newWindow
        //   if (window.focus) {
        //     newWindow.addEventListener('beforeunload', (e) => {
        //       window.focus();
        //       resolve && resolve.call && resolve();
        //     });
        //
        //     newWindow.focus();
        //   }
    },
};

function parseJsonData(response) {
    let responseData = null;

    try {
        responseData = JSON.parse(response.data);
    } catch (e) {
        bugsnagNotify(e, { response });
    }

    return responseData;
}

export default actions;
