import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import PluginsList from '../../components/PluginsList';
import ModalPlugin from '../../components/ModalPlugin';
import {
    PUBLISH,
    PRIVATE,
    SUCCESS,
    ERROR,
    CODE,
    CONFIRM,
    DEFAULT_CREATOR_VALUE,
    ALL_OEMS_ID,
} from '../../../../../constants/Plugins';

import { pluginsArrayWithUpdateInformation } from '../../utils';
import { useRequestResult } from '../../customHooks';
import { EZLO_PLUGIN_EDGE_PRIVATE_MAIN_TITLE } from '../../../../../constants/language_tokens';
import { getControllers, getControllersAll } from '../../../../../helpers/helpersPlugins';
import usePreviousState from '../../../../../hooks/usePreviousState';

import styles from '../../../EzloAccount/components/Header/style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import FullScreenDialog from '../../../../../components/FullScreenDialog';

const PluginsPrivate = (props) => {
    const marketplacePlugins = useSelector((state) => state.plugins.marketplacePlugins || []);
    const { loaderInstalled, pluginUpgradeWaiting } = useSelector((state) => state.plugins);
    const ezloData = useSelector((state) => state.ezlo?.data) || [];
    const { serial } = useSelector((state) => state.ezlo);
    const [isModal, setIsModal] = useState(false);
    const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [jobInterval, setJobInterval] = useState(0);
    const [currentAction, setCurrentAction] = useState(null);
    const [currentCustomNode, setCurrentCustomNode] = useState(null);
    const [currentSerial, setCurrentSerial] = useState('');
    const controllers = getControllers(ezloData).length ? getControllers(ezloData) : getControllersAll(ezloData);

    const prevSerial = usePreviousState({ serial });
    const { t } = useTranslate();
    const {
        privatePlugins,
        loaderList,
        newJobUuid,
        currentJob,
        listInstalledPlugins,
        published,
        devices,
        PluginActions,
        EzloActions,
    } = props;

    useEffect(() => {
        PluginActions.getPrivatePlugins(CODE, controllers);
        PluginActions.getPublicPlugins(CODE, DEFAULT_CREATOR_VALUE, ALL_OEMS_ID);
    }, []);

    useEffect(() => {
        if (prevSerial && controllers) {
            controllers.forEach((controller) => {
                if (controller.isConnected) {
                    PluginActions.getListInstalledPlugins(controller.serial);
                }
            });
        }
    }, [serial]);

    useEffect(() => {
        if (newJobUuid) {
            PluginActions.getDashboardCreateList(newJobUuid, t);
        }
    }, [newJobUuid]);

    useRequestResult();

    useEffect(() => {
        if (newJobUuid) {
            if (currentJob?.status === SUCCESS) {
                clearInterval(jobInterval);
                if (published === PUBLISH) {
                    PluginActions.setPublishPlugin({ type: CODE, dashboard_uuid: currentJob.uuid }, t);
                }

                PluginActions.getPrivatePlugins(CODE);
                PluginActions.clearJobUuid();
            } else if (currentJob?.status === ERROR) {
                clearInterval(jobInterval);
                PluginActions.clearJobUuid();
            } else {
                setJobInterval(
                    setTimeout(() => {
                        PluginActions.getDashboardCreateList(newJobUuid, t);
                    }, 3000),
                );
            }
        }
    }, [currentJob, published]);

    useEffect(() => {
        if (!pluginUpgradeWaiting && typeModal === CONFIRM) {
            setTypeModal('install');
            setIsModal(true);
        }
    }, [pluginUpgradeWaiting]);

    const onCancelUpgrade = useCallback(() => {
        setTypeModal('install');
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
        setIsConfigureModalOpen(false);
    }, []);

    const handlerShowInstallModal = useCallback((data) => {
        setTypeModal('install');
        setIsModal(true);
        setPluginData(data);
    }, []);

    const handlerShowInfoModal = useCallback((data) => {
        setPluginData(data);
        setIsModal(true);
        setTypeModal('info');
    }, []);

    const handlerShowDevicesModal = useCallback((data) => {
        setPluginData(data);
        setIsModal(true);
        setTypeModal('devices');
    }, []);

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const handlerShowConfigureModal = useCallback(
        (serial) => {
            if (pluginData && pluginData.row && listInstalledPlugins) {
                setCurrentCustomNode(
                    listInstalledPlugins[serial].filter((item) => item.plugin === pluginData.row.nameId)[0],
                );
            }

            setCurrentSerial(serial);
            setTypeModal('configure');
            setIsConfigureModalOpen(true);
        },
        [listInstalledPlugins, pluginData],
    );

    const listPlugins =
        pluginsArrayWithUpdateInformation(privatePlugins, marketplacePlugins, listInstalledPlugins) || [];

    return (
        <div>
            {loaderInstalled && (
                <Box className={styles.linearProgressBoxPlugins}>
                    <LinearProgress className={styles.linearProgress} />
                </Box>
            )}
            <PluginsList
                type={PRIVATE}
                title={EZLO_PLUGIN_EDGE_PRIVATE_MAIN_TITLE}
                loaderList={loaderList}
                listPlugins={listPlugins}
                onConfirm={onConfirm}
                onHandlerShowInstallModal={handlerShowInstallModal}
                onHandlerShowInfoModal={handlerShowInfoModal}
                onHandlerShowDevicesModal={handlerShowDevicesModal}
                listInstalledPlugins={listInstalledPlugins}
                privatePlugins={privatePlugins}
                devices={devices}
                pluginData={pluginData}
            />
            <ModalPlugin
                typeList={PRIVATE}
                open={isModal}
                currentSerial={currentSerial}
                onCancel={hideModal}
                typeModal={typeModal}
                currentAction={currentAction}
                currentCustomNode={currentCustomNode}
                controllers={controllers}
                pluginData={pluginData}
                devices={devices}
                listInstalledPlugins={listInstalledPlugins}
                PluginActions={PluginActions}
                EzloActions={EzloActions}
                onHandlerShowConfigureModal={handlerShowConfigureModal}
                onHandlerShowUpdateModal={onConfirm}
                marketplacePlugins={marketplacePlugins}
                onCancelUpgrade={onCancelUpgrade}
            />
            <FullScreenDialog
                isConfigureModalOpen={isConfigureModalOpen}
                handleConfigureModalClose={hideModal}
                currentCustomNode={currentCustomNode}
                currentSerial={currentSerial}
            />
        </div>
    );
};

export default React.memo(PluginsPrivate);

PluginsPrivate.propTypes = {
    privatePlugins: PropTypes.array,
    loaderList: PropTypes.bool,
    newJobUuid: PropTypes.string,
    published: PropTypes.string,
    currentJob: PropTypes.object,
    controllers: PropTypes.array,
    devices: PropTypes.array,
    listInstalledPlugins: PropTypes.object,
    PluginActions: PropTypes.object,
    EzloActions: PropTypes.object,
};
