import { useMemo } from 'react';

import { getIsLabelSelected, getLabelItemUnCheckedIcon } from '../../utils';
import useLabelDropdownContext from '../useLabelDropdownContext';

const useLabelDropdownItemState = (label) => {
    const context = useLabelDropdownContext();

    return useMemo(() => {
        return {
            isSelected: getIsLabelSelected(label, context),
            UncheckIcon: getLabelItemUnCheckedIcon(label, context),
            selectLabel: context.selectLabel,
        };
    }, [label, context.labelsWithSelectStatus, context.selectedLabelsUuids, context.selectLabel]);
};

export default useLabelDropdownItemState;
