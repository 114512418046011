import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import InfoButton from '../../../InfoButton';

import { SELECTED_SAVE_KEY } from '../../constants';

import {
    EZLOGIC_HINT_PLEASE_BE_AWARE,
    EZLOGIC_TITLE_SAVE_OUTPUT_TO_CLOUD_VARIABLE,
} from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';
import style from '../../style.module.scss';

export const SelectedComponent = ({ selectedSaveOutput, onChangeState }) => {
    const { t } = useTranslate();

    const handleChecked = (e) => {
        onChangeState(e.target.checked, SELECTED_SAVE_KEY);
    };

    return (
        <div className={style.checkboxBlock}>
            <FormControlLabel
                control={
                    <Checkbox
                        className={style.checkBox}
                        checked={selectedSaveOutput}
                        onChange={handleChecked}
                        color="primary"
                    />
                }
                label={t(EZLOGIC_TITLE_SAVE_OUTPUT_TO_CLOUD_VARIABLE)}
            />
            <div className={style.infoButtonWrapper}>
                <InfoButton>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(EZLOGIC_HINT_PLEASE_BE_AWARE),
                        }}
                    ></span>
                </InfoButton>
            </div>
        </div>
    );
};
