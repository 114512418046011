import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { Button } from '@material-ui/core';

import { VALUE_TO_UNASSIGN_ROOM } from '../../../../../../../../../constants/Devices';
import styles from './DevicesFilteringBlock.module.scss';
import { EZLOGIC_BUTTON_ALL } from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';

const DevicesFilteringBlock = ({ serial, roomList, roomId, onGettingDevicesOfRoom, isOnline }) => {
    const buttonAllStyles = classes(styles.buttonFiltering, {
        [styles.active]: roomId === VALUE_TO_UNASSIGN_ROOM && isOnline,
        [styles.disabled]: !isOnline,
    });
    const { t } = useTranslate();

    return (
        <div className={styles.devicesFilteringBlock}>
            <Button
                className={buttonAllStyles}
                onClick={() => onGettingDevicesOfRoom(VALUE_TO_UNASSIGN_ROOM)}
                disabled={!isOnline}
            >
                {t(EZLOGIC_BUTTON_ALL)}
            </Button>

            {roomList?.length
                ? roomList
                      .filter((room) => room.serial === serial)
                      .map((room) => {
                          return (
                              <Button
                                  className={classes(styles.buttonFiltering, {
                                      [styles.active]: roomId === room._id,
                                  })}
                                  key={room._id}
                                  onClick={() => onGettingDevicesOfRoom(room._id)}
                              >
                                  {room.name}
                              </Button>
                          );
                      })
                : null}
        </div>
    );
};

export default DevicesFilteringBlock;

DevicesFilteringBlock.propTypes = {
    roomList: PropTypes.array,
    serial: PropTypes.string,
    roomId: PropTypes.string,
    onGettingDevicesOfRoom: PropTypes.func,
};
