import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';

import actions from '../../../../actions/CustomizationActions';
import EzloCustomization from '../../../../actions/EzloCustomization';
import {
    PARTNER_PERMISSION,
    RESTORE_TO_DEFAULT_CUSTOMIZATION_TEXT,
    RESTORE_TO_DEFAULT_CUSTOMIZATION_TITLE,
    TAB_DATA,
} from '../constants';
import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { CONTINUE } from '../../../../constants/ComponentConstants';
import { checkVeraUser } from '../../../Navigation/utils';
import {
    checkCustomizationChanged,
    checkCustomizationReset,
    getEncodedCustomizationData,
    getFinalCustomizationPayload,
    getUpdatedKvsPayloadData,
    setUpdatedColors,
} from '../utils';
import { MainAction } from '../../../../actions';
import { CUSTOMIZATION_EDITING_PAGE } from '../../../../constants/ActionConfirmDialog';
import useUnsavedChangesWarning from '../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';
import './style.scss';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_NEXT,
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_BUTTON_RESET_TO_DEFAULT,
} from '../../../../constants/language_tokens';

const CustomizationsTabs = () => {
    const dispatch = useDispatch();
    const setDirty = useUnsavedChangesWarning();
    const [active, setActive] = useState(0);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const currentCustomizationData = useSelector((state) => state.customizationData);
    const pkPermissionRole = useSelector((state) => state.account?.data?.PK_PermissionRole);
    const updatedCustomization = useSelector((state) => state.ezloCustomization);
    const currentEzlogicPortalCustomizationData = currentCustomizationData?.ezlogicPortal;
    const currentKvsPayloadCustomizationData = currentCustomizationData?.kvsPayloadData;
    const isVeraUser = checkVeraUser();

    const { t } = useTranslate();

    useEffect(() => {
        dispatch(actions.setInitialToCurrentCustomization(updatedCustomization));
        dispatch(MainAction.setStatePageBeingEdited(CUSTOMIZATION_EDITING_PAGE));

        return () => dispatch(MainAction.setStatePageBeingEdited({}));
    }, []);

    const handleClick = (e) => {
        setActive(parseInt(e.currentTarget.attributes.num.value));
    };

    const handleSwitchNextTab = () => {
        setActive((prevActive) => prevActive + 1);
    };

    const isCustomizationChanged = checkCustomizationChanged(
        updatedCustomization,
        currentEzlogicPortalCustomizationData,
        currentKvsPayloadCustomizationData,
    );
    const isCustomizationDefault = checkCustomizationReset(updatedCustomization, isVeraUser);
    useEffect(() => {
        setDirty(isCustomizationChanged);
        dispatch(MainAction.setConfirmationUser(isCustomizationChanged));
    }, [isCustomizationChanged]);

    const customizationPayload = _.cloneDeep(currentCustomizationData);
    let currentKvsPayload = _.cloneDeep(customizationPayload.kvsPayloadData);

    const handleUploadCustomizationData = () => {
        if (pkPermissionRole === PARTNER_PERMISSION) {
            currentKvsPayload = getUpdatedKvsPayloadData(
                customizationPayload?.ezlogicPortal?.colorTheme,
                currentKvsPayload,
            );
        }
        const isReset = false;
        const finalCustomizationPayload = getFinalCustomizationPayload(
            currentKvsPayload,
            isVeraUser,
            isReset,
            customizationPayload,
        );
        const encodedCustomizationData = getEncodedCustomizationData(finalCustomizationPayload);

        dispatch(actions.handleKvsSetCustomization(isVeraUser, encodedCustomizationData, t));
        if (isVeraUser) {
            dispatch(EzloCustomization.updateCustomization(finalCustomizationPayload));
            setUpdatedColors(finalCustomizationPayload?.ezlogicPortal?.colorTheme);
        }
        dispatch(actions.setCurrentCustomizationData(currentCustomizationData, isVeraUser));
    };

    const handleResetCustomization = () => {
        setUpdatedColors(updatedCustomization?.defaultColorTheme);
        const isReset = true;
        const finalCustomizationPayload = getFinalCustomizationPayload(currentKvsPayload, isVeraUser, isReset);
        const encodedCustomizationData = getEncodedCustomizationData(finalCustomizationPayload);
        dispatch(actions.handleKvsSetCustomization(isVeraUser, encodedCustomizationData, t, isReset));
        dispatch(
            actions.setDefaultStateToCurrentCustomization(
                updatedCustomization,
                isVeraUser,
                currentKvsPayloadCustomizationData,
            ),
        );
        if (isVeraUser) {
            dispatch(EzloCustomization.setDefaultStateToUpdatedCustomization(updatedCustomization));
        }
        dispatch(
            actions.setDefaultStateToInitialCustomization(
                updatedCustomization,
                isVeraUser,
                currentKvsPayloadCustomizationData,
            ),
        );
    };

    const handleRestoreModal = () => {
        setIsConfirmModalVisible(true);
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            handleResetCustomization();
        }
        setIsConfirmModalVisible(false);
    };

    let content = '';

    return (
        <section className="customization-tab-area">
            <ul>
                {TAB_DATA.map(([label, text], i) => {
                    content = active === i ? text : content;

                    return (
                        <Button
                            className={active === i ? 'customization-tabs active' : 'customization-tabs'}
                            key={label}
                            num={i}
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                        >
                            {t(label)}
                        </Button>
                    );
                })}
            </ul>
            <div className="customization-tab-content">
                {content}
                <div className="tab-content-save-button">
                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        startIcon={<RestoreIcon />}
                        disabled={isCustomizationDefault}
                        className="tab-content-save-button__reset"
                        onClick={handleRestoreModal}
                    >
                        {t(EZLOGIC_BUTTON_RESET_TO_DEFAULT)}
                    </Button>
                    {active < TAB_DATA.length - 1 && (
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            className="tab-content-save-button__next"
                            onClick={handleSwitchNextTab}
                        >
                            {t(EZLOGIC_BUTTON_NEXT)}
                        </Button>
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        className="tab-content-save-button__save"
                        onClick={handleUploadCustomizationData}
                        disabled={!isCustomizationChanged}
                    >
                        {t(EZLOGIC_BUTTON_SAVE)}
                    </Button>
                </div>
            </div>
            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={RESTORE_TO_DEFAULT_CUSTOMIZATION_TITLE}
                text={RESTORE_TO_DEFAULT_CUSTOMIZATION_TEXT}
                confirmButtonTitle={t(CONTINUE)}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </section>
    );
};

export default CustomizationsTabs;
