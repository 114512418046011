import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NotFoundPageContent from './NotFoundPageContent';
import styles from './NotFoundPage.module.scss';
import { LOGIN } from '../../constants/URLs';
import { LanguageSelector } from '../../features/languages/components';
import { languageService } from '../../services/language';
import { updateLang } from '../../features/languages/utils';
import { AppLogo } from '../../assets/icons';

const NotFoundPage = () => {
    const history = useHistory();
    const language = languageService.getLanguageKey();
    const logged = useSelector((state) => state.account.logged);
    const [currentLang, setCurrentLang] = useState(language);

    const handleBackToHome = useCallback(() => {
        if (logged) {
            history.push('/ezlo/dashboard');
        } else {
            history.push(LOGIN);
        }
    });

    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);
    };

    useEffect(() => {
        updateLang(currentLang);
    }, [currentLang]);

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <AppLogo className={styles.logoIcon} />
            </div>
            <div className={styles.language}>
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} isLoggin={true} />
            </div>
            <NotFoundPageContent onBackToHome={handleBackToHome} />
        </div>
    );
};

export default NotFoundPage;
