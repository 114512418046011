import React from 'react';
import PaginationArrows from './PaginationArrows';
import { useSelector } from 'react-redux';
import { PAGINATION_FIRST_PAGE_VALUE } from '../../constants';
import { mediaStoreSelector } from '../../utils';
import useGalleryContext from '../../hooks/useGalleryContext';

const PaginationArrowsContainer = () => {
    const { isEndPage, isLoading } = useSelector(mediaStoreSelector);
    const { handlerPageSwitching, currentPage } = useGalleryContext();

    return (
        <PaginationArrows
            prevDisabled={currentPage === PAGINATION_FIRST_PAGE_VALUE || isLoading}
            nextDisabled={isEndPage || isLoading}
            handlerPageSwitching={handlerPageSwitching}
        />
    );
};

export default PaginationArrowsContainer;
