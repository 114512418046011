import React, { Component } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import * as meshbot from '../../constants/MeshbotConstant';
import classes from 'classnames';

import { weeksMonth, weeksMonth_item, active } from './WeekOfTheMonth.module.scss';
import { EZLOGIC_TITLE_LAST } from '../../constants/language_tokens';

class WeekOfTheMonth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weeksMounth: meshbot.WEEKS_OF_THE_MONTH,
        };
    }

    componentDidMount() {
        this.setState({ weeksMounth: this.formattingWeeksMounth(this.props.isArrayWeek) });
    }

    // eslint-disable-next-line
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isArrayWeek) {
            this.setState({ week: this.formattingWeeksMounth(nextProps.isArrayWeek) });
        }
    }

    formattingWeeksMounth = (data) => {
        return this.state.weeksMounth.map((item) => {
            if (data.includes(item.name)) {
                item.checked = true;

                return item;
            }
            item.checked = false;

            return item;
        });
    };

    handleChange = (e, item) => {
        const { onChange } = this.props;
        onChange(e, item);
    };

    render() {
        const { weeksMounth } = this.state;
        const { t } = this.props;

        return (
            <>
                <ul className={weeksMonth}>
                    {weeksMounth.map((item, index) => {
                        return (
                            <li key={index + 1} className={classes(weeksMonth_item, { [active]: item.checked })}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={(e) => this.handleChange(e, index + 1)}
                                        />
                                    }
                                    label={item.name === -1 ? t(EZLOGIC_TITLE_LAST) : item.name}
                                />
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }
}

export default WeekOfTheMonth;
