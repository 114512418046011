import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import InfoButton from '../../../InfoButton';

import {
    DATA_TYPES_LIST,
    INPUT_ADORONMENT_END_POSITION,
    INPUT_TYPE_NUMBER,
    INPUT_TYPE_TEXT,
} from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const LocalVariableCommonTypesValueField = (props) => {
    const { value, selectedVariableValueType, onSetVariableValue } = props;
    const { t } = useTranslate();

    return (
        <TextField
            value={value}
            label={t(info.hints.value)}
            InputLabelProps={{ shrink: true }}
            placeholder={t(info.hints.value)}
            type={selectedVariableValueType === DATA_TYPES_LIST.TYPE_INT ? INPUT_TYPE_NUMBER : INPUT_TYPE_TEXT}
            onChange={onSetVariableValue}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                        <InfoButton>
                            <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                        </InfoButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default LocalVariableCommonTypesValueField;
