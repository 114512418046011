import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EzloActions from '../../../../../actions/EzloActions';
import DeviceHubSettings from '../../../EzloDevice/EditForm/DeviceHubSettings';

const HubSettingsContent = ({ controllerSerial }) => {
    const controllerData = useSelector((state) => state.ezlo.data[controllerSerial]);
    const dispatch = useDispatch();
    const properties = controllerData && controllerData.properties.settings;

    const onSaveHubSettings = (param) => {
        dispatch(EzloActions.setEzloPropertie(controllerSerial, param));
    };

    return <DeviceHubSettings properties={properties} onSaveHubSettings={onSaveHubSettings} />;
};

export default HubSettingsContent;
