import { useMediaActions } from './useMediaActions';
import { useEffect, useState } from 'react';
import { getFetchImagesFilter, getPageByPaginationParam } from '../utils';
import { PAGINATION_FIRST_PAGE_VALUE, TIME_INTERVALS } from '../constants';

const useFetchImagesByPagination = (clearSelectedImgList) => {
    const { fetchImages, clearImagesList } = useMediaActions();
    const [selectedTimeInterval, setTimeInterval] = useState(TIME_INTERVALS.ALL_TIME);
    const [currentPage, setCurrentPage] = useState(PAGINATION_FIRST_PAGE_VALUE);

    useEffect(() => {
        fetchImages(getFetchImagesFilter(selectedTimeInterval, PAGINATION_FIRST_PAGE_VALUE));
        setCurrentPage(PAGINATION_FIRST_PAGE_VALUE);
    }, [selectedTimeInterval]);

    const handlerPageSwitching = async (param) => {
        const page = getPageByPaginationParam(currentPage, param);
        const { isSuccessfulRequest } = await fetchImages(getFetchImagesFilter(selectedTimeInterval, page));

        if (isSuccessfulRequest) {
            setCurrentPage(page);
        }
    };

    const handlerChangeTimeInterval = (event) => {
        clearImagesList();
        setTimeInterval(event.target.value);
        clearSelectedImgList();
    };

    return { selectedTimeInterval, setTimeInterval, handlerPageSwitching, handlerChangeTimeInterval, currentPage };
};

export default useFetchImagesByPagination;
