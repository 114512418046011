import React from 'react';
import { InputAdornment, MenuItem } from '@material-ui/core';

import { InputMaterial } from '../../../../../../components';
import SelectComponent from '../../SelectComponent';
import InfoButton from '../../InfoButton';

import {
    intervalOptions,
    INTERVAL_MIN_INPUT_VALUE,
    INTERVAL_MAX_INPUT_VALUE,
} from '../../../../../../constants/CloudMeshbotConstant';
import {
    EZLOGIC_HINT_VALUE_TYPE,
    EZLOGIC_HINTS_VALUE_TYPE,
    EZLOGIC_HINTS_VARIABLE,
    EZLOGIC_TITLE_VALUE,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const IntervalNode = (props) => {
    const { t } = useTranslate();

    const {
        id,
        selectedFieldDate,
        idGroup,
        intervalNumber,
        intervalType,
        serIntervalType,
        serIntervalNumber,
        onUpdateDateTriggersCloud,
    } = props;
    const changeNumber = (e, name) => {
        const data = {};

        if (name === 'intervalNumber') {
            e.target.value = parseInt(Math.abs(e.target.value)).toFixed(0);
            data.value = Number(e.target.value);
            serIntervalNumber(e.target.value);
        }

        if (name === 'intervalType') {
            data.measurement = e.target.value;
            serIntervalType(e.target.value);
        }

        onUpdateDateTriggersCloud(data, id, selectedFieldDate, idGroup);
    };

    return (
        <>
            <InputMaterial
                placeholder={t(EZLOGIC_TITLE_VALUE)}
                label={t(EZLOGIC_TITLE_VALUE)}
                type="number"
                value={intervalNumber}
                max={INTERVAL_MAX_INPUT_VALUE}
                min={INTERVAL_MIN_INPUT_VALUE}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <InfoButton>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(EZLOGIC_HINTS_VARIABLE),
                                    }}
                                ></span>
                            </InfoButton>
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => changeNumber(e, 'intervalNumber')}
            />

            <SelectComponent
                value={intervalType ? intervalType : 1}
                label={t(EZLOGIC_HINTS_VALUE_TYPE)}
                info={t(EZLOGIC_HINT_VALUE_TYPE)}
                onChange={(e) => changeNumber(e, 'intervalType')}
            >
                {intervalOptions.map((item) => {
                    return (
                        <MenuItem key={item.value} value={item.value}>
                            {t(item.name)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </>
    );
};

export default IntervalNode;
