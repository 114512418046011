import {
    EZLOGIC_DESCRIPTION_ADD_EXPRESSION_FORM,
    EZLOGIC_DESCRIPTION_ADD_SCRIPT,
    EZLOGIC_DESCRIPTION_EDIT_EXPRESSION_FORM,
    EZLOGIC_DESCRIPTION_EDIT_SCRIPT,
    EZLOGIC_TITLE_ADD_EXPRESSION_FORM,
    EZLOGIC_TITLE_DISCARD_UNSAVED_CUSTOMIZATION_CHANGES,
    EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_DISCARD_EDITING_MESSAGE,
    EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_EXIT_MESSAGE,
    EZLOGIC_TITLE_ADD_SCRIPT,
    EZLOGIC_TITLE_EDIT_EXPRESSION_FORM,
    EZLOGIC_TITLE_EDIT_FORM_EXIT_MESSAGE,
    EZLOGIC_TITLE_EDIT_SCRIPT,
    EZLOGIC_TITLE_EXIT_CREATE_CONTAINER_DESCRIPTION,
    EZLOGIC_TITLE_EXIT_EDIT_CONTAINER_DESCRIPTION,
    EZLOGIC_TITLE_EXIT_THE_CREATE_CONTAINER,
    EZLOGIC_TITLE_EXIT_THE_EDIT_CONTAINER,
    EZLOGIC_TITLE_EXIT_UNSAVED_CUSTOMIZATION_CHANGES,
    EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_CREATE_MESHBOT,
    EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_EDIT_MESHBOT,
    EZLOGIC_TITLE_DISCARD_CREATING_MESHBOT,
    EZLOGIC_TITLE_DISCARD_EDIT_MESHBOT,
    EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM,
    EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM_DESCRIPTION,
    EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM,
    EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION,
    EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM,
    EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION,
    EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM,
    EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM_DESCRIPTION,
} from './language_tokens';

export const LOCAL_MESHBOT_CREATING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_CREATE_MESHBOT,
    dialogBoxDescription: EZLOGIC_TITLE_DISCARD_CREATING_MESHBOT,
};

export const CLOUD_MESHBOT_CREATING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_CREATE_MESHBOT,
    dialogBoxDescription: EZLOGIC_TITLE_DISCARD_CREATING_MESHBOT,
};

export const LOCAL_MESHBOT_EDITING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_EDIT_MESHBOT,
    dialogBoxDescription: EZLOGIC_TITLE_DISCARD_EDIT_MESHBOT,
};

export const FORM_ADDING_NEW_EXPRESSION = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_ADD_EXPRESSION_FORM,
    dialogBoxDescription: EZLOGIC_DESCRIPTION_ADD_EXPRESSION_FORM,
};

export const EXPRESSION_EDITING_FORM = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EDIT_EXPRESSION_FORM,
    dialogBoxDescription: EZLOGIC_DESCRIPTION_EDIT_EXPRESSION_FORM,
};

export const FORM_ADDING_NEW_VARIABLE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_EXIT_MESSAGE,
    dialogBoxDescription: EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_DISCARD_EDITING_MESSAGE,
};

export const VARIABLE_EDITING_FORM = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EDIT_FORM_EXIT_MESSAGE,
    dialogBoxDescription: EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_DISCARD_EDITING_MESSAGE,
};

export const SCRIPT_CREATING_FORM = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_ADD_SCRIPT,
    dialogBoxDescription: EZLOGIC_DESCRIPTION_ADD_SCRIPT,
};

export const SCRIPT_EDITING_FORM = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EDIT_SCRIPT,
    dialogBoxDescription: EZLOGIC_DESCRIPTION_EDIT_SCRIPT,
};

export const CUSTOMIZATION_EDITING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_UNSAVED_CUSTOMIZATION_CHANGES,
    dialogBoxDescription: EZLOGIC_TITLE_DISCARD_UNSAVED_CUSTOMIZATION_CHANGES,
};

export const FORM_CREATING_NEW_ASSOCIATION = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM_DESCRIPTION,
};

export const FORM_CREATING_NEW_CONTAINER = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_THE_CREATE_CONTAINER,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_CREATE_CONTAINER_DESCRIPTION,
};

export const CONTAINER_EDITING_FORM = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_THE_EDIT_CONTAINER,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_EDIT_CONTAINER_DESCRIPTION,
};

export const WIFI_DEVICE_TEMPLATE_CREATING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION,
};

export const WIFI_DEVICE_TEMPLATE_EDITING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION,
};

export const WIFI_DEVICE_CREATING_PAGE = {
    isDirty: false,
    dialogBoxTitle: EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM,
    dialogBoxDescription: EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM_DESCRIPTION,
};
