import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useMeshBotType = () => {
    const { pathname } = useLocation();
    const pathnameURL = pathname.split('/');
    const createType = useRef(pathnameURL[pathnameURL.length - 2]);

    return { currentMeshBotType: createType.current };
};

export default useMeshBotType;
