import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WIFI_PLUGIN_MODEL, PUBLISH_GENERATOR, CREATOR } from '../../../../../constants/Plugins';
import PluginsList from '../../components/PluginsList';
import ModalPlugin from '../../components/ModalPlugin';
import { useRequestResult } from '../../customHooks';
import { EZLOGIC_TITLE_PUBLISH_IP_DEVICE } from '../../../../../constants/language_tokens';

const TemplatesPublish = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);

    const { publicWifiTemplates, loaderList, PluginActions } = props;

    useEffect(() => {
        PluginActions.getPublicPlugins(WIFI_PLUGIN_MODEL, CREATOR);
    }, []);

    useRequestResult();

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    return (
        <>
            <PluginsList
                type={PUBLISH_GENERATOR}
                title={EZLOGIC_TITLE_PUBLISH_IP_DEVICE}
                listPlugins={publicWifiTemplates}
                loaderList={loaderList}
                onConfirm={onConfirm}
            />
            <ModalPlugin
                typeList={PUBLISH_GENERATOR}
                open={isModal}
                onCancel={hideModal}
                typeModal={typeModal}
                currentAction={currentAction}
                pluginData={pluginData}
                PluginActions={PluginActions}
            />
        </>
    );
};

TemplatesPublish.propTypes = {
    publicWifiTemplates: PropTypes.array,
    loaderList: PropTypes.bool,
    PluginActions: PropTypes.object,
};

export default TemplatesPublish;
