import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTriggerSelect from '../../../../../hooks/useTriggerSelect';
import { useParseIntegrationPreview } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/hooks/useParseIntegrationPreview';
import { useGetIntegration } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/hooks/useGetIntegration';
import MeshBotAction from '../../../../../actions/MeshBotAction';
import { Integrations } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/Integrations/Integrations';
import { Accounts } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/Accounts/Accounts';
import { MethodList } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/Methods/MethodList';
import { DynamicFieldsList } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/DynamicFields/DynamicFieldsList';
import { integrationsSelector, payloadsForNucalUpdate } from './utils';
import { NUCAL_FIELDS } from '../../constants';

export const LocalNucalTriggerBlock = ({ id, groupId, isException }) => {
    const dispatch = useDispatch();
    const [nucalComponent, setNucalComponent] = useState({});
    const { integrations, isAbstractsLoading, abstracts, PAASAuthUuid } = useSelector(integrationsSelector);
    const { accountUuid, integration, method, fields } = useTriggerSelect(id, groupId, isException);
    const { triggerMethods, paths, integrationPreviewResult } = useParseIntegrationPreview(integration?.uuid);
    const { foundIntegration, integrationsList } = useGetIntegration(integration?.uuid, true);
    const isVisible = {
        integrations: Boolean(integrations?.length),
        accounts: Boolean(integration?.uuid),
        methods: Boolean(triggerMethods?.length && accountUuid),
        dynamicFields: Boolean(method && integrationPreviewResult),
    };

    const onUpdateMeshbot = (updateData) => {
        dispatch(MeshBotAction.updateNucalNodeInTrigger(id, groupId, updateData));
    };
    const handleChangeMethod = (methodValue) => {
        onUpdateMeshbot(payloadsForNucalUpdate[NUCAL_FIELDS.METHOD](methodValue, integrationPreviewResult, paths));
    };
    const handleDynamicFieldChange = (value, propertyName) => {
        onUpdateMeshbot(payloadsForNucalUpdate[NUCAL_FIELDS.FIELDS](propertyName, value, fields));
    };
    const handleChangeIntegration = useCallback((integrationUuid) => {
        onUpdateMeshbot(payloadsForNucalUpdate[NUCAL_FIELDS.INTEGRATION](integrationUuid));
    }, []);
    const handleChangeAccount = useCallback(({ accountUuid }) => {
        onUpdateMeshbot(payloadsForNucalUpdate[NUCAL_FIELDS.ACCOUNT](accountUuid));
    }, []);

    return (
        <>
            {isVisible.integrations && (
                <Integrations
                    foundIntegration={foundIntegration}
                    onChangeIntegration={handleChangeIntegration}
                    integrationsList={integrationsList}
                />
            )}
            {isVisible.accounts && (
                <Accounts
                    abstracts={abstracts}
                    PAASAuthUuid={PAASAuthUuid}
                    isAbstractsLoading={isAbstractsLoading}
                    onUpdateMeshbot={handleChangeAccount}
                    abstractUuid={accountUuid}
                    foundIntegration={foundIntegration}
                />
            )}

            {isVisible.methods && (
                <MethodList
                    paths={paths}
                    parjectIcon={foundIntegration?.icon}
                    methods={triggerMethods}
                    methodValue={method}
                    onChangeMethod={handleChangeMethod}
                />
            )}

            {isVisible.dynamicFields && (
                <DynamicFieldsList
                    fields={fields}
                    accountUuid={accountUuid}
                    onDynamicFieldChange={handleDynamicFieldChange}
                    nucalComponent={nucalComponent}
                    integrationPreviewResult={integrationPreviewResult}
                    method={method}
                    setNucalComponent={setNucalComponent}
                />
            )}
        </>
    );
};

export default LocalNucalTriggerBlock;
