import {
    apiGetCities,
    apiGetCountries,
    apiGetInstallations,
    apiGetMailingBillingAddresses,
    apiGetRegions,
    apiUpdateInstallation,
    apiUpdateMailingBillingAddresses,
} from '../api/apiAccount';
import GenericActions from './GenericActions';
import at from '../constants/ActionTypes/AccountInfo';
import { toast, TOAST_TYPE } from '../components/Toast';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { filterAndSortCountries } from '../containers/Ezlo/EzloAccount/MyAccount/utils';
import {
    EZLOGIC_TITLE_ACCOUNT_UPDATED,
    EZLOGIC_TITLE_INSTALLATION_ADDRESS_UPDATED,
} from '../constants/language_tokens';

const AccountActions = {
    setInstallationsList: () => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                    countriesList,
                },
            } = getState();

            if (!countriesList.length) {
                await dispatch(AccountActions.setCountries());
            }
            const installations = await apiGetInstallations(PK_Account);

            await dispatch({ type: at.SET_INSTALLATIONS_LIST.success, installations });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_INSTALLATIONS_LIST.rejected });
        }
    },
    setMailingBillingAddresses: () => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            const mailingBillingAddresses = await apiGetMailingBillingAddresses(PK_Account);
            await dispatch({ type: at.SET_MAILING_BILLING_ADDRESSES.success, mailingBillingAddresses });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_MAILING_BILLING_ADDRESSES.rejected });
        }
    },
    setSecurityAccountInfo: () => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.setInstallationsList());
        await dispatch(AccountActions.setMailingBillingAddresses());
        dispatch(GenericActions.setLineLoading(false));
    },
    setAccountInfo: () => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.setInstallationsList());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateSecurityAccountInfo: (addressesData, t) => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.updateMailingBillingAddresses(addressesData, t));
        await dispatch(AccountActions.setMailingBillingAddresses());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateAccountInfo: (addressesData, t) => async (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));
        await dispatch(AccountActions.updateInstallationAddresses(addressesData, t));
        await dispatch(AccountActions.setInstallationsList());
        dispatch(GenericActions.setLineLoading(false));
    },
    updateInstallationAddresses: (installationsData, t) => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            const dispatchListOfUpdateInstallationsAddress = installationsData.map((address) => {
                return dispatch(AccountActions.updateInstallationAddress(PK_Account, address, t));
            });
            await Promise.all(dispatchListOfUpdateInstallationsAddress);
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_INSTALLATION_ADDRESSES.rejected });
        }
    },
    updateInstallationAddress: (PK_Account, address, t) => async () => {
        try {
            await apiUpdateInstallation(PK_Account, address);
            await toast(t(EZLOGIC_TITLE_INSTALLATION_ADDRESS_UPDATED), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_INSTALLATION_ADDRESS.rejected });
        }
    },
    updateMailingBillingAddresses: (addressesData, t) => async (dispatch, getState) => {
        try {
            const {
                account: {
                    data: { PK_Account },
                },
            } = getState();
            await apiUpdateMailingBillingAddresses(PK_Account, addressesData);
            await toast(t(EZLOGIC_TITLE_ACCOUNT_UPDATED), { type: TOAST_TYPE.SUCCESS });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.UPDATE_MAILING_BILLING_ADDRESSES.rejected });
        }
    },
    setCountries: () => async (dispatch) => {
        try {
            const countriesList = await apiGetCountries();
            const countries = filterAndSortCountries(countriesList);

            dispatch({ type: at.SET_COUNTRIES_LIST.success, countriesList: countries });
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_COUNTRIES_LIST.rejected });
        }
    },
    getRegions: (PK_Country) => async () => {
        try {
            const response = await apiGetRegions(PK_Country);

            return await response.data.Regions;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_REGIONS_LIST.rejected });
        }
    },
    getCities: (PK_Country, PK_Region) => async () => {
        try {
            const response = await apiGetCities(PK_Country, PK_Region);

            return await response.data.Cities;
        } catch (error) {
            bugsnagNotifyWrapper(error, { type: at.SET_CITIES_LIST.rejected });
        }
    },
};

export default AccountActions;
