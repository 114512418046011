import React from 'react';

import { Button } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import styles from './OpenButton.module.scss';

const OpenButton = ({ title, onHandleClick, icon, defaultTitle, anchorElAbstract }) => {
    const titleName = title || defaultTitle;

    return (
        <div className={styles.button_wrapper}>
            <Button aria-describedby="abstracts-menu" variant="contained" onClick={onHandleClick}>
                <div className={styles.title_container}>
                    {icon && <img className={styles.select_icon} src={icon} alt={title} />}
                    <span className={styles.title}>{titleName}</span>
                </div>

                {anchorElAbstract ? <ArrowDropUp /> : <ArrowDropDown />}
            </Button>
        </div>
    );
};

export default OpenButton;
