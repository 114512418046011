import React, { useMemo } from 'react';

import DeviceGroupCategory from '../DeviceGroupCategory';
import { sortCategoriesByPriority } from '../../../../utils';

const DeviceGroupCategories = (props) => {
    const { payload } = props;

    const orderedPayload = useMemo(() => sortCategoriesByPriority(payload), [payload]);

    return orderedPayload.map(([name, payload]) => {
        return <DeviceGroupCategory name={name} payload={payload} key={name} />;
    });
};

export default DeviceGroupCategories;
