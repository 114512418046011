import React, { useState, useEffect } from 'react';

import { InputMaterial } from '../../../../../../components';
import { HoursAndMinutesSelect } from '../../templateDateTimeBlocks/sectionCustom';

import {
    getCurrentYear,
    MONTH_MIN_INPUT_VALUE,
    MONTH_MAX_INPUT_VALUE,
    DAY_MAX_INPUT_VALUE,
    DAY_MIN_INPUT_VALUE,
    YEAR_MAX_INPUT_VALUE,
} from '../../../../../../constants/CloudMeshbotConstant';

import { getIntegerFromNumberInput } from '../../../utils';
import TimeZoneSelect from '../../DateAndTime/TimeZoneSelect';
import { useTranslate } from '../../../../../../features/languages';
import {
    EZLOGIC_TITLE_DAY,
    EZLOGIC_TITLE_MONTH,
    EZLOGIC_TITLE_YEAR,
} from '../../../../../../constants/language_tokens';

export const DateSelect = (props) => {
    const { day, month, year, handleChangeOnce } = props;
    const { t } = useTranslate();

    return (
        <>
            <InputMaterial
                label={t(EZLOGIC_TITLE_DAY)}
                placeholder={t(EZLOGIC_TITLE_DAY)}
                type="number"
                value={day}
                min={DAY_MIN_INPUT_VALUE}
                max={DAY_MAX_INPUT_VALUE}
                onChange={(e) => handleChangeOnce(getIntegerFromNumberInput(e), 'day')}
                small
            />

            <InputMaterial
                label={t(EZLOGIC_TITLE_MONTH)}
                type="number"
                placeholder={t(EZLOGIC_TITLE_MONTH)}
                min={MONTH_MIN_INPUT_VALUE}
                max={MONTH_MAX_INPUT_VALUE}
                value={month}
                onChange={(e) => handleChangeOnce(getIntegerFromNumberInput(e), 'month')}
                small
            />

            <InputMaterial
                label={t(EZLOGIC_TITLE_YEAR)}
                type="number"
                placeholder={t(EZLOGIC_TITLE_MONTH)}
                min={getCurrentYear()}
                max={YEAR_MAX_INPUT_VALUE}
                value={year}
                onChange={(e) => handleChangeOnce(getIntegerFromNumberInput(e), 'year')}
                small
            />
        </>
    );
};

export const CloudOnceNode = (props) => {
    const { id, selectedFieldDate, idGroup, onUpdateDateTriggersCloud, blocks, handleChangeTimezone } = props;
    const { day, month, year, hour, minute, isPm } = blocks?.[0]?.parameters[0];

    const [once, setOnce] = useState({});

    useEffect(() => {
        onUpdateDateTriggersCloud(once, id, selectedFieldDate, idGroup);
    }, [once]);

    const handleChangeOnce = (time, fieldKey, isPm) => {
        let params;

        if (fieldKey === 'time') {
            const [hour, minute] = time;

            params = { ...once, hour: parseInt(hour), minute: parseInt(minute), isPm: isPm === 'pm' };
        }

        if (['day', 'month', 'year'].includes(fieldKey)) {
            params = { ...once, [fieldKey]: parseInt(time) };
        }

        setOnce(params);
    };

    return (
        <>
            <DateSelect day={day} month={month} year={year} handleChangeOnce={handleChangeOnce} />

            <HoursAndMinutesSelect
                onChangeCustomTime={(time, isPm) => handleChangeOnce(time, 'time', isPm)}
                customHours={hour}
                customMinutes={minute}
                isPm={isPm}
            />

            <TimeZoneSelect handleChangeTimezone={handleChangeTimezone} blocks={blocks} />
        </>
    );
};
