import React from 'react';
import { FormControl, TextField, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import InfoButton from '../InfoButton';

import {
    EZLOGIC_HINT_CONTROLLABLE,
    EZLOGIC_HINT_ERROR_MESHBOT_DOESNT_EXIST,
    EZLOGIC_TITLE_CONTROLLABLE,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import styles from './MeshBotSelectBlock.module.scss';

// todo: enable & disable actions, immediately & delay options
const MeshBotSelectBlock = (props) => {
    const { options = [], onChangeMeshBotSelect, disableClearable, value = {}, sceneId } = props;
    const { t } = useTranslate();

    const isValueEmpty = Object.keys(value).length === 0;
    const showError = Boolean(sceneId && isValueEmpty);

    return (
        <div className={styles.wrapper}>
            <FormControl className={styles.select}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => (option.name ? option.name : '')}
                    disableClearable={disableClearable}
                    getOptionSelected={(option, value) => {
                        return option && option?._id === value?._id;
                    }}
                    onChange={(_event, newScene) => {
                        onChangeMeshBotSelect(newScene);
                    }}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            className={styles.autocomplete}
                            {...params}
                            label={t(EZLOGIC_TITLE_CONTROLLABLE)}
                            adornment={
                                (params.InputProps.startAdornment = (
                                    <InfoButton>
                                        {<div dangerouslySetInnerHTML={{ __html: t(EZLOGIC_HINT_CONTROLLABLE) }}></div>}
                                    </InfoButton>
                                ))
                            }
                        />
                    )}
                    renderOption={(option) => {
                        const { name } = option;

                        return (
                            <span {...props} className={styles.options}>
                                {name}
                            </span>
                        );
                    }}
                    title={value && (value.name ?? '')}
                />
                {showError && <FormHelperText>{t(EZLOGIC_HINT_ERROR_MESHBOT_DOESNT_EXIST)}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default MeshBotSelectBlock;
