// TODO: file should be moved to utils folder or file
import _ from 'lodash';

import hash from '../../../../../constants/uniqueHash';
import { filterCapabilitiesForCloudTriggers } from '../../../EzloVirtualDevice/utils';
import { getCurrentCapabilities } from '../../../utils';
import {
    MESHBOT_NODE_TYPES,
    APICALL_NAME,
    PAAS_ACTION,
    OPERATOR_NOT,
    SCHEDULE,
    DEVICE_STATE,
    DATE_AND_TIME,
    SUNSET,
    SUNRISE,
    SPECIAL_TIME_OF_THE_DAY,
    HOUR12,
    ZERO_INT,
    MANUAL,
    ON_CHANGE,
    MULTIPLE,
    CONTROLLER,
    INTEGRATION,
    SINGLE,
    STATIC,
    _COMMAND,
    CLOUD_VARIABLE,
    INDEX_OF_ONE,
    VALUE_TYPE,
    INDEX_OF_ZERO,
} from '../../../../../constants/MeshbotConstant';
import { checkIfNucalTrigger, extractDeviceDataFromBlocks } from '../../utils';
import { CUSTOM_PREFIX } from '../../components/SaveOutputBlock/constants';

const TRIGGER_TYPES = {
    schedule: 'dataAndTime',
    device: 'deviceState',
};

const convert24FormatToAmPm = (time) => {
    const [hours, minutes] = time;

    if (hours === ZERO_INT) {
        return [hours + HOUR12, minutes];
    }

    if (hours <= HOUR12 && hours !== ZERO_INT) {
        return time;
    }

    return [hours - HOUR12, minutes];
};

const getCapability = (capabilityName, commandName, capabilities) => {
    const { definition } = capabilities.find(({ capability_name }) => capability_name === capabilityName);
    const { commands } = definition;
    const { variables } = commands.find((command) => command[commandName])[commandName];
    const capabilityCommands = Object.keys(commands.find((el) => el));

    return { capabilityCommands, variables };
};

const getAbstract = (abstractUuid, abstracts) => {
    return abstracts?.find(({ uuid }) => uuid === abstractUuid);
};

const buildTriggerStructureForDateAndTime = (
    triggers,
    selectedFieldTrigger,
    selectedOperator,
    selectedFieldDateIfSunset,
) => {
    return {
        id: hash(),
        not: triggers.name === OPERATOR_NOT,
        selectedFieldTrigger,
        selectedOperator,
        target: {},
        variableValue: '',
        selectedFieldDate: selectedFieldDateIfSunset,
    };
};

const buildTriggerStructure = (
    triggers,
    capabilityReplaceCommand,
    variableReplace,
    currentVariables,
    abstractCapabilities,
    deviceName,
    isDeviceOnChange,
    abstractUuid,
) => {
    let newTriggers;
    triggers.name === OPERATOR_NOT
        ? (newTriggers = _.cloneDeep(triggers.parameters[0]))
        : (newTriggers = _.cloneDeep(triggers));

    const isNot = triggers.name === OPERATOR_NOT;
    const selectedFieldTrigger = TRIGGER_TYPES[newTriggers.name] || DEVICE_STATE;
    const selectedOperator = newTriggers.name;
    const selectedFieldDate = newTriggers?.parameters?.[0]?.name;
    const target = !isDeviceOnChange ? newTriggers?.parameters?.[1]?.parameters : null;
    const variableValue =
        // capability === 'color_temperature_warmth'
        //     ? returnCurrentTemperatureObject(variableReplace, newTriggers)
        //     :
        !isDeviceOnChange ? newTriggers?.parameters?.[1]?.parameters : null;
    const capabilities = filterCapabilitiesForCloudTriggers(abstractCapabilities);

    return {
        id: hash(),
        not: isNot,
        selectedFieldTrigger,
        selectedOperator,
        selectedFieldDate,
        nameSubBlock: capabilityReplaceCommand,
        capability: capabilityReplaceCommand,
        idDev: abstractUuid,
        currentVariable: variableReplace,
        variables: currentVariables,
        target: target,
        variableValue,
        capabilities,
        deviceName,
    };
};

const buildTriggerStructureForNucal = (triggers) => {
    const isNot = triggers.name === OPERATOR_NOT;
    const selectedFieldTrigger = MESHBOT_NODE_TYPES.PAAS;

    return {
        id: hash(),
        not: isNot,
        selectedFieldTrigger,
    };
};

const extractValueForCloudVariable = (triggers) => {
    const extractedParameters =
        triggers.name === OPERATOR_NOT ? triggers?.parameters[0]?.parameters : triggers?.parameters;
    const abstractValues = extractedParameters.filter((item) => {
        return item.name === STATIC;
    });
    const value = abstractValues[0]?.parameters[0]?.value;

    return value;
};

const extractCapabilityComparedForCloudVariable = (triggers) => {
    const extractedParameters =
        triggers.name === OPERATOR_NOT ? triggers?.parameters[0]?.parameters : triggers?.parameters;

    const comparedCapability = extractedParameters[INDEX_OF_ONE]?.parameters[0]?.capability;

    return comparedCapability;
};

const extractAbstractComparedForCloudVariable = (triggers) => {
    const extractedParameters =
        triggers.name === OPERATOR_NOT ? triggers?.parameters[0]?.parameters : triggers?.parameters;

    const comparedAbstract = extractedParameters[INDEX_OF_ONE]?.parameters[0]?.abstract;

    return comparedAbstract;
};

export const buildTriggerStructureForCloudVariable = (triggers, capability, details, abstractUuid) => {
    const isNot = triggers.name === OPERATOR_NOT;
    const selectedFieldTrigger = MESHBOT_NODE_TYPES.CLOUD_VARIABLES;
    const value = extractValueForCloudVariable(triggers);
    const capabilityCompared = extractCapabilityComparedForCloudVariable(triggers);
    const comparator = triggers.name === OPERATOR_NOT ? triggers.parameters[0].name : triggers.name;

    return {
        id: hash(),
        not: isNot,
        selectedFieldTrigger,
        selectedIntegrationId: details?.integration_uuid,
        selectedAbstract: {
            uuid: abstractUuid,
        },
        selectedComparator: comparator,
        selectedVariable: capability,
        ...(comparator !== ON_CHANGE && generateStateAccordingToComparisonType(value, triggers, capabilityCompared)),
    };
};

/*Function for checking comparison type for cloud variables and then set state in redux accordingly for edit mode */
export const generateStateAccordingToComparisonType = (value, triggers, capabilityCompared) => {
    if (value !== undefined) {
        return {
            selectedValueType: VALUE_TYPE,
            comparingValue: value,
        };
    } else {
        return {
            selectedAbstractForCompared: {
                uuid: extractAbstractComparedForCloudVariable(triggers),
            },
            selectedValueType: CLOUD_VARIABLE,
            selectedVariableCompared: capabilityCompared,
        };
    }
};

const buildTriggerBlockStructure = (triggers) => {
    const triggerName = triggers?.name === OPERATOR_NOT ? triggers?.parameters[0]?.name : triggers?.name;
    const triggerParameters =
        triggers?.name === OPERATOR_NOT ? triggers?.parameters[0]?.parameters : triggers?.parameters;

    const triggerBlocks = [
        {
            name: triggerName,
            parameters: triggerParameters.map((triggerParameter) => {
                const { time, times, hour, minute } = triggerParameter;
                if (time) {
                    return {
                        ...triggerParameter,
                        time: convert24FormatToAmPm(time),
                        isPm: time[0] >= HOUR12,
                    };
                }

                if (times) {
                    return {
                        ...triggerParameter,
                        times: [convert24FormatToAmPm(times[0])],
                        isPm: times[0][0] >= HOUR12,
                    };
                }

                if (hour >= ZERO_INT && minute >= ZERO_INT) {
                    const newTimeArray = [];
                    const newHour = hour;
                    const newMinute = minute;
                    newTimeArray.push(newHour);
                    newTimeArray.push(newMinute);

                    const updatedTime = convert24FormatToAmPm(newTimeArray);

                    return {
                        ...triggerParameter,
                        hour: updatedTime[0] === ZERO_INT ? HOUR12 : updatedTime[0],
                        minute: minute,
                        isPm: hour >= HOUR12,
                    };
                }

                return triggerParameter;
            }),
        },
    ];

    return triggerBlocks;
};

export const convertNucalCloudMeshbotTriggersStructureToUI = (triggers, triggerCount) => {
    const isManual = triggers.name === MANUAL;

    if (isManual) {
        return [];
    }

    const triggersForUI = buildTriggerStructureForNucal(triggers);
    triggersForUI.blocks = buildTriggerBlockStructure(triggers);

    if (triggerCount === MULTIPLE) {
        return triggersForUI;
    }

    return [triggersForUI];
};

export const convertCloudVariableMeshbotTriggersStructureToUI = (
    triggers,
    triggerCount,
    capability,
    details,
    abstractUuid,
) => {
    const isManual = triggers.name === MANUAL;

    if (isManual) {
        return [];
    }

    const triggersForUI = buildTriggerStructureForCloudVariable(triggers, capability, details, abstractUuid);
    triggersForUI.blocks = buildTriggerBlockStructure(triggers);

    if (triggerCount === MULTIPLE) {
        return triggersForUI;
    }

    return [triggersForUI];
};

export const convertCloudMeshbotTriggersStructureToUI = (triggers, abstracts = [], capabilities) => {
    const isManual = triggers?.name === MANUAL;
    if (isManual) {
        return [];
    }

    const isNotOperator = triggers?.name === OPERATOR_NOT;
    const isDeviceOnChange = isNotOperator
        ? triggers?.parameters?.[INDEX_OF_ZERO]?.name === ON_CHANGE
        : triggers?.name === ON_CHANGE;

    const {
        abstract: abstractUuid,
        capability = '',
        variable = '',
    } = extractDeviceDataFromBlocks(triggers, isNotOperator, isDeviceOnChange);
    const { name, capabilities: abstractCapabilities, details } = getAbstract(abstractUuid, abstracts) || {};

    return convertDeviceOrCloudVariable(
        triggers,
        name,
        abstractCapabilities,
        details,
        variable,
        abstractUuid,
        capabilities,
        capability,
        SINGLE,
        isDeviceOnChange,
    );
};

const convertDeviceOrCloudVariable = (
    triggers,
    name,
    abstractCapabilities,
    details,
    variable,
    abstractUuid,
    capabilities,
    capability,
    triggerCount,
    isDeviceOnChange,
) => {
    const isDeviceNode = details ? details.source === CONTROLLER : !capability.includes(CUSTOM_PREFIX);
    const isIntegrationNode = details ? details.source === INTEGRATION : capability.includes(CUSTOM_PREFIX);

    if (isDeviceNode) {
        const deviceName = name;
        const capabilityReplaceCommand = capability.replace(_COMMAND, '');
        const currentCapabilities = getCurrentCapabilities(capabilities, capabilityReplaceCommand);
        const variableReplace = variable.replace(/\,.*/, '');
        const currentVariables = currentCapabilities?.[0]?.definition?.variables
            ? Object.keys(currentCapabilities?.[0]?.definition?.variables)
            : {};

        const triggersForUI = buildTriggerStructure(
            triggers,
            capabilityReplaceCommand,
            variableReplace,
            currentVariables,
            abstractCapabilities,
            deviceName,
            isDeviceOnChange,
            abstractUuid,
        );
        triggersForUI.blocks = buildTriggerBlockStructure(triggers);

        if (triggerCount === MULTIPLE) {
            return triggersForUI;
        }

        return [triggersForUI];
    }

    if (isIntegrationNode) {
        return convertCloudVariableMeshbotTriggersStructureToUI(
            triggers,
            triggerCount,
            capability,
            details,
            abstractUuid,
        );
    }
};

export const convertDateAndTimeCloudMeshbotTriggersStructureToUI = (triggers) => {
    const isManual = triggers.name === MANUAL;
    if (isManual) {
        return [];
    }

    const selectedFieldTrigger =
        triggers?.name !== OPERATOR_NOT
            ? TRIGGER_TYPES[triggers?.name] || DATE_AND_TIME
            : TRIGGER_TYPES[triggers?.parameters?.[0]?.name] || DATE_AND_TIME;
    const selectedOperator = triggers?.name !== OPERATOR_NOT ? triggers?.name : triggers?.parameters?.[0]?.name;
    const selectedFieldDate =
        triggers?.name !== OPERATOR_NOT
            ? triggers?.parameters?.[0]?.name
            : triggers?.parameters?.[0]?.parameters?.[0]?.name;

    const selectedFieldDateIfSunset =
        selectedFieldDate === SUNSET || selectedFieldDate === SUNRISE ? SPECIAL_TIME_OF_THE_DAY : selectedFieldDate;

    const triggersForUI = buildTriggerStructureForDateAndTime(
        triggers,
        selectedFieldTrigger,
        selectedOperator,
        selectedFieldDateIfSunset,
    );

    triggersForUI.blocks = buildTriggerBlockStructure(triggers);

    return [triggersForUI];
};

export const convertCloudMeshbotNestedTriggersStructureToUI = (triggers, abstracts = [], capabilities) => {
    const isManual = triggers.name === MANUAL;
    if (isManual) {
        return [];
    }

    const nestedStructure = triggers?.parameters?.map((item) => {
        if (item?.name === SCHEDULE || item?.parameters[0]?.name === SCHEDULE) {
            const selectedFieldTrigger =
                item?.name !== OPERATOR_NOT
                    ? TRIGGER_TYPES[item?.name] || DATE_AND_TIME
                    : TRIGGER_TYPES[item?.parameters?.[0]?.name] || DATE_AND_TIME;
            const selectedOperator = item?.name !== OPERATOR_NOT ? item?.name : item?.parameters?.[0]?.name;
            const selectedFieldDate =
                item?.name !== OPERATOR_NOT
                    ? item?.parameters?.[0]?.name
                    : item?.parameters?.[0]?.parameters?.[0]?.name;

            const selectedFieldDateIfSunset =
                selectedFieldDate === SUNSET || selectedFieldDate === SUNRISE
                    ? SPECIAL_TIME_OF_THE_DAY
                    : selectedFieldDate;

            const triggersForUI = buildTriggerStructureForDateAndTime(
                item,
                selectedFieldTrigger,
                selectedOperator,
                selectedFieldDateIfSunset,
            );

            triggersForUI.blocks = buildTriggerBlockStructure(item);

            return triggersForUI;
        }

        if (checkIfNucalTrigger(item)) {
            return convertNucalCloudMeshbotTriggersStructureToUI(item, MULTIPLE);
        }

        const isNotOperator = item?.name === OPERATOR_NOT;
        const isDeviceOnChange = isNotOperator
            ? item?.parameters?.[INDEX_OF_ZERO]?.name === ON_CHANGE
            : item?.name === ON_CHANGE;

        const {
            abstract: abstractUuid,
            capability = '',
            variable = '',
        } = extractDeviceDataFromBlocks(item, isNotOperator, isDeviceOnChange);

        const { name, capabilities: abstractCapabilities, details } = getAbstract(abstractUuid, abstracts) || {};

        return convertDeviceOrCloudVariable(
            item,
            name,
            abstractCapabilities,
            details,
            variable,
            abstractUuid,
            capabilities,
            capability,
            MULTIPLE,
            isDeviceOnChange,
        );
    });

    return nestedStructure.filter((trigger) => Boolean(trigger));
};

export const convertCloudMeshbotActionsStructureToUI = (actions, abstracts = [], capabilities) => {
    return actions.map(({ name: nameAction, parameters }) => {
        const [{ abstract: abstractUuid, capability, command }, { name, transform, value }] = _.cloneDeep(parameters);
        const isDashboard = parameters.find((parameter) => parameter.name === MESHBOT_NODE_TYPES.DASHBOARD);
        const isPaas = nameAction === APICALL_NAME;
        if (abstractUuid) {
            const { name: deviceName, capabilities: abstractCapabilities } = getAbstract(abstractUuid, abstracts) || {};
            const { capabilityCommands, variables } = getCapability(capability, command, capabilities);

            return {
                id: hash(),
                blocks: [],
                selectedFieldTrigger: 'deviceState',
                command,
                capability,
                capabilityCommands,
                abstract: {
                    roomName: 'Unussigned',
                    uuid: abstractUuid,
                    name: deviceName,
                    capabilities: abstractCapabilities,
                    return: '',
                },
                inputType: {
                    variables,
                },
                target:
                    parameters.length > 1
                        ? [...parameters.filter((param, i) => i !== 0)]
                        : {
                              name,
                              transform,
                              value,
                          },
                deviceParameters: parameters,
                isEditingState: true,
            };
        } else {
            if (isPaas) {
                return {
                    id: hash(),
                    selectedFieldTrigger: PAAS_ACTION,
                    name: APICALL_NAME,
                    PAAS: {
                        name: 'apicall',
                        parameters,
                    },
                };
            } else {
                return {
                    id: hash(),
                    selectedFieldTrigger: isDashboard ? MESHBOT_NODE_TYPES.DASHBOARD : 'cloudNotification',
                    notification: {
                        name: 'notify-users',
                        parameters,
                    },
                };
            }
        }
    });
};
