import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { EZLOGIC_TITLE_ENABLE_FOR } from '../../../../constants/language_tokens';
import { optionsSelect } from '../../../languages/constants';
import { TIME_OPTIONS } from '../../constants';

import styles from '../../components/InstallerAccessRights/installerAccess.module.scss';
import { useTranslate } from '../../../languages';

const InstallerAccessRightsDropdown = ({ disabled, selectedTime, handleTimeSelection }) => {
    const { t } = useTranslate();

    return (
        disabled && (
            <FormControl className={styles['dropdown-container']}>
                <InputLabel data-testid="enableFor">{t(EZLOGIC_TITLE_ENABLE_FOR)}</InputLabel>
                <Select
                    value={selectedTime}
                    onChange={handleTimeSelection}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{ ...optionsSelect }}
                >
                    {TIME_OPTIONS?.map((option, index) => (
                        <MenuItem key={index} value={option?.value} disableGutters className={styles['dropdownchild']}>
                            {t(option?.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    );
};

export default InstallerAccessRightsDropdown;
