import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import UsersPage from './UsersPage/UsersPage';
import UserCreate from './UserCreate/UserCreate';
import UserEdit from './UserEdit/UserEdit';
import PinCodesPage from './PinCodesPage';
import PinCodeCreate from './PinCodeCreate';
import PinCodeEdit from './PinCodeEdit';
import ChangeUserPassword from './ChangePassword';

const Users = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}`} component={UsersPage} exact />
            <Route path={`${url}/userCreate`} component={UserCreate} />
            <Route path={`${url}/userEdit`} component={UserEdit} exact />
            <Route path={`${url}/userEdit/changePassword`} component={ChangeUserPassword} />
            <Route path={`${url}/pinCodes`} component={PinCodesPage} exact />
            <Route path={`${url}/pinCodes/create`} component={PinCodeCreate} />
            <Route path={`${url}/pinCodes/edit`} component={PinCodeEdit} />
        </Switch>
    );
};

export default Users;
