import {
    CHECK_CIRCLE_ICON,
    ERROR_ICON,
    FAILED,
    FINISHED,
    PLAY_ARROW_ICON,
    SPINNER_ICON,
    STARTED,
} from '../../constants/MeshbotConstant';
import { IcVisualsSpinner } from '../../assets/icons';
import React from 'react';
import { CheckCircle, Error, PlayArrow } from '@material-ui/icons';
import styles from './styles.module.scss';

export const updateSceneStatus = ({ local, cloud, scene_id, status, controller_id }) => {
    const updatedLocal = { ...local };

    if (controller_id) {
        updatedLocal[controller_id] = {
            ...(local?.[controller_id] || {}),
            [scene_id]: status,
        };
    }

    const updatedCloud = { ...cloud };

    return { local: { ...updatedLocal }, cloud: { ...updatedCloud } };
};
export const updateCloudSceneStatus = ({ local, cloud, scene_id, status }) => {
    const updatedCloud = {
        ...cloud,
        [scene_id]: { status },
    };
    const updatedLocal = { ...local };

    return {
        local: { ...updatedLocal },
        cloud: { ...updatedCloud },
    };
};

export const getIcon = (activeRunScenes, id, serial) => {
    if (isSceneRunFailed(activeRunScenes, serial, id)) {
        return <Error className={styles.error} data-testid={ERROR_ICON} />;
    } else if (isSceneRunFinished(activeRunScenes, serial, id)) {
        return <CheckCircle className={styles.success} data-testid={CHECK_CIRCLE_ICON} />;
    } else if (isSceneRunStarted(activeRunScenes, serial, id)) {
        return <IcVisualsSpinner className={styles.spinner} data-testid={SPINNER_ICON} />;
    } else {
        return <PlayArrow data-testid={PLAY_ARROW_ICON} />;
    }
};

export const getLocalSceneRunStatus = (data, serial, id) => {
    const { local } = data;

    if (local?.[serial] && local?.[serial]?.[id]) {
        return local?.[serial]?.[id];
    }

    return null;
};

export const getCloudSceneRunStatus = (data, serial, id) => {
    const { cloud } = data;

    if (cloud?.[id]?.status) {
        return cloud?.[id]?.status;
    }

    return null;
};

export const isSceneRunFailed = (activeRunScenes, serial, id) => {
    return (
        getLocalSceneRunStatus(activeRunScenes, serial, id) === FAILED ||
        getCloudSceneRunStatus(activeRunScenes, serial, id) === FAILED
    );
};
export const isSceneRunFinished = (activeRunScenes, serial, id) => {
    return (
        getLocalSceneRunStatus(activeRunScenes, serial, id) === FINISHED ||
        getCloudSceneRunStatus(activeRunScenes, serial, id) === FINISHED
    );
};

export const isSceneRunStarted = (activeRunScenes, serial, id) => {
    return (
        getLocalSceneRunStatus(activeRunScenes, serial, id) === STARTED ||
        getCloudSceneRunStatus(activeRunScenes, serial, id) === STARTED
    );
};
