import { useContext, useMemo } from 'react';
import { AuthorizationContext } from './AuthorizationContextProvider';
import { PARTNER_GET_PARAM_NAME } from '../../constants/getParams';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';
import { buildAuthContextState } from './utils';

/**
 * Used to manage Authorization Context.
 * By default, it will read GET params and set config based on them.
 * It is possible to manage auth context by returned values.
 *
 * @returns {array} [authContext, setAuthContext]
 * */
export const useAuthContext = () => {
    const params = useQueryParams();
    const [, setAuthContext] = useContext(AuthorizationContext);

    useMemo(() => {
        const partner = params[PARTNER_GET_PARAM_NAME];
        if (partner) {
            setAuthContext(buildAuthContextState(partner));
        }
    }, [params]);
};
