import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableNodeSorterItem from '../DraggableNodeSorterListItem';
import { EZLOGIC_TITLE_CONTROLLABLES_SORT } from '../../../constants';
import { useTranslate } from '../../../../../../features/languages';

import classes from '../../NodesControllables.module.scss';

const DraggableNodeSorterList = ({ listItems, onDragEnd, title, id }) => {
    const { t } = useTranslate();

    return (
        <div className={classes.styledList}>
            <DragDropContext onDragEnd={onDragEnd}>
                <h3 className={classes.title}>{title}</h3>
                <Droppable droppableId={id}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {listItems?.map((item, index) => (
                                <DraggableNodeSorterItem
                                    key={title === t(EZLOGIC_TITLE_CONTROLLABLES_SORT) ? item.value : item.id}
                                    item={item}
                                    index={index}
                                    title={title}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default DraggableNodeSorterList;
