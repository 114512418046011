import React, { useCallback, useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classes from 'classnames';

import ActionTriggerBlockWrapper from '../../../components/TriggerBlock/ActionTriggerBlockWrapper';
import TriggerForActions from '../../../components/triggerForActions';
import BuilderHeader from '../../../components/BuilderHeader';
import BuilderFooter from '../../../MeshBot/BuilderFooter';
import TriggerBlock from '../../../components/triggerBlock';
import GroupBlock from '../../../components/groupBlock';

import at from '../../../../../../constants/ActionTypes/MeshBot';
import { findValidatedBlock, getActionExceptionTriggers } from '../utils';
import * as meshBot from '../../../../../../constants/MeshbotConstant';
import { MESHBOT_TYPES } from '../../../../EzloMeshbots/constants';

import {
    triggerWrapperWithExceptionBlock,
    triggerExceptionBlock,
    list_actions,
} from './DraggableActionTrigger.module.scss';

import { mesh_bot__list_triggers } from '../../../EzloMeshbot.module.scss';
import { activeConnection } from '../../../components/TriggerBlock.module.scss';

const DraggableActionTrigger = (props) => {
    const [currentException, setCurrentException] = useState(null);
    const [showFunctionInException, setShowFunctionInException] = useState(false);
    const {
        itemsDevice,
        deviceBlocks,
        currentRule,
        currentMeshbot,
        currentDeviceSelect,
        currentExpression,
        currentDeviceAction,
        nameMeshBot,
        device,
        index,
        serial,
        items,
        model,
        devices,
        rooms,
        typeMeshbot,
        typeForm,
        actionBlockName,
        foundValidatedThenBlock,
        foundValidatedElseBlock,
        scenesList,
        channels,
        getNotificationData,
        usersData,
        blockType,
        isEditing,
        listLuaScripts,
        isDragDisabled,
        exceptions,
        idGroup,
        validationStateObject,
        MeshBotAction,
        onAddBlock,
        onUpdateTriggerAction,
        onSelected,
        onSelectedMeshBotAction,
        onUpdateFieldActionBlock,
        onAddActionBlock,
        onToggleExceptionBlock,
        onPreventDraggable,
        onAddExceptionTrigger,
        onChangeExpression,
        onUpdateTriggerNot,
        onUpdateTrigger,
        onDeleteTrigger,
        onSelectedDate,
        onSetDateValue,
        onSetSpecificDate,
        onSetFieldValue,
        onSelectedMeshBotTriggerState,
        onUpdateFieldBlock,
        onUpdateFieldVidoo,
        onGetCurrentDeviceId,
        onUpdateTriggerBlock,
        onUpdateTriggerBlockFunction,
        onSetExceptionFunctionInTrigger,
        onSelectedVidooTrigger,
        onSelectedException,
        onUpdateMeshBotTriggerValue,
        onResetMeshBotTriggerBlocks,
        onChangeNameGroup,
        onUpdateTriggerType,
        onUpdateHouseModeTrigger,
    } = props;
    const { isOpenedExceptionBlock } = device;

    useEffect(() => {
        const exception = getActionExceptionTriggers(exceptions, device.id);

        setShowFunctionInException(exception?.triggers?.length > 1);
        setCurrentException(exception);
    }, [exceptions]);

    const handleSetOptionType = useCallback(
        (value) => {
            MeshBotAction.setExceptionOptionType(value, device.id, idGroup);
        },
        [device.id, idGroup],
    );

    const handleSetFunction = useCallback(
        (value) => {
            MeshBotAction.setExceptionFunction({ value, id: device.id });
        },
        [device.id],
    );

    const classNameException = classes(mesh_bot__list_triggers, {
        [activeConnection]: currentException?.triggers.length > 1,
    });

    return (
        <Draggable draggableId={device.id ? device.id : device._tempId} index={index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => (
                <ActionTriggerBlockWrapper provided={provided} snapshot={snapshot}>
                    <div
                        className={classes(list_actions, {
                            [triggerWrapperWithExceptionBlock]: isOpenedExceptionBlock,
                        })}
                    >
                        {isOpenedExceptionBlock && (
                            <div className={triggerExceptionBlock}>
                                <BuilderHeader
                                    type={meshBot.TRIGGER}
                                    optionType={currentException?.optionType}
                                    showSectionFunction={showFunctionInException}
                                    sectionFunctionInitState={currentException?.function}
                                    listRuleTriggers={currentException?.triggers}
                                    onCheckedNotRadio={handleSetOptionType}
                                    onUpdateSectionFunction={handleSetFunction}
                                />
                                <div className={classNameException}>
                                    {currentException?.triggers.map((trigger) => {
                                        if (trigger.type === meshBot.GROUP) {
                                            return (
                                                <GroupBlock
                                                    key={trigger.id}
                                                    idGroup={trigger.id}
                                                    actionId={device.id}
                                                    typeTrigger={meshBot.EXCEPTION}
                                                    ezloSerial={serial}
                                                    model={model}
                                                    {...trigger}
                                                    typeMeshbot={typeMeshbot}
                                                    typeForm={typeForm}
                                                    currentItem={trigger}
                                                    items={items}
                                                    devices={devices}
                                                    rooms={rooms}
                                                    itemsDevice={itemsDevice}
                                                    deviceBlocks={deviceBlocks}
                                                    currentDeviceSelect={currentDeviceSelect}
                                                    currentExpression={currentExpression}
                                                    onUpdateTriggerNot={onUpdateTriggerNot}
                                                    onUpdateTrigger={onUpdateTrigger}
                                                    DeleteTrigger={onDeleteTrigger}
                                                    onSelected={onSelectedException}
                                                    onSelectedDate={onSelectedDate}
                                                    onSetDateValue={onSetDateValue}
                                                    onSetSpecificDate={onSetSpecificDate}
                                                    onSetFieldValue={onSetFieldValue}
                                                    onSelectedMeshBotTriggerState={onSelectedMeshBotTriggerState}
                                                    onUpdateFieldBlock={onUpdateFieldBlock}
                                                    onUpdateFieldVidoo={onUpdateFieldVidoo}
                                                    onGetCurrentDeviceId={onGetCurrentDeviceId}
                                                    onAddBlock={onAddBlock}
                                                    onUpdateMeshBotTriggerValue={onUpdateMeshBotTriggerValue}
                                                    onResetMeshBotTriggerBlocks={onResetMeshBotTriggerBlocks}
                                                    onAddTrigger={onAddExceptionTrigger}
                                                    UpdateExpression={MeshBotAction.updateExpression}
                                                    CreateExpression={MeshBotAction.createExpression}
                                                    DeleteExpression={MeshBotAction.deleteExpression}
                                                    changeExpression={onChangeExpression}
                                                    handlerUpdateTriggerBlock={onUpdateTriggerBlock}
                                                    foundValidatedWhenGroup={findValidatedBlock(
                                                        trigger.id,
                                                        at.MESHBOT_LOCAL_TRIGGER_BLOCKS.exceptionWhen,
                                                        validationStateObject,
                                                    )}
                                                    scenesList={scenesList}
                                                    onUpdateTriggerBlockFunction={onUpdateTriggerBlockFunction}
                                                    onSelectedVidooTrigger={onSelectedVidooTrigger}
                                                    nameMeshBot={nameMeshBot}
                                                    currentMeshbot={currentMeshbot}
                                                    actionBlockName={actionBlockName}
                                                    onChangeNameGroup={onChangeNameGroup}
                                                    onUpdateTriggerType={onUpdateTriggerType}
                                                    onUpdateHouseModeTrigger={onUpdateHouseModeTrigger}
                                                    MeshBotAction={MeshBotAction}
                                                    isOpenedExceptionBlock={isOpenedExceptionBlock}
                                                />
                                            );
                                        }

                                        return (
                                            <TriggerBlock
                                                key={trigger.id}
                                                actionId={device.id}
                                                typeTrigger={meshBot.EXCEPTION}
                                                typeForm={typeForm}
                                                ezloSerial={serial}
                                                model={model}
                                                {...trigger}
                                                typeMeshbot={typeMeshbot}
                                                currentItem={trigger}
                                                items={items}
                                                devices={devices}
                                                rooms={rooms}
                                                itemsDevice={itemsDevice}
                                                deviceBlocks={deviceBlocks}
                                                currentDeviceSelect={currentDeviceSelect}
                                                currentExpression={currentExpression}
                                                onUpdateTriggerNot={onUpdateTriggerNot}
                                                onUpdateTrigger={onUpdateTrigger}
                                                DeleteTrigger={onDeleteTrigger}
                                                onSelected={onSelectedException}
                                                onSelectedDate={onSelectedDate}
                                                onSetDateValue={onSetDateValue}
                                                onSetSpecificDate={onSetSpecificDate}
                                                onSetFieldValue={onSetFieldValue}
                                                onSelectedMeshBotTriggerState={onSelectedMeshBotTriggerState}
                                                onUpdateFieldBlock={onUpdateFieldBlock}
                                                onUpdateFieldVidoo={onUpdateFieldVidoo}
                                                onGetCurrentDeviceId={onGetCurrentDeviceId}
                                                onAddBlock={onAddBlock}
                                                onUpdateMeshBotTriggerValue={onUpdateMeshBotTriggerValue}
                                                onResetMeshBotTriggerBlocks={onResetMeshBotTriggerBlocks}
                                                UpdateExpression={MeshBotAction.updateExpression}
                                                CreateExpression={MeshBotAction.createExpression}
                                                DeleteExpression={MeshBotAction.deleteExpression}
                                                changeExpression={onChangeExpression}
                                                handlerUpdateTriggerBlock={onUpdateTriggerBlock}
                                                foundValidatedWhenBlock={findValidatedBlock(
                                                    trigger.id,
                                                    at.MESHBOT_LOCAL_TRIGGER_BLOCKS.exceptionWhen,
                                                    validationStateObject,
                                                )}
                                                scenesList={scenesList}
                                                onUpdateTriggerBlockFunction={onSetExceptionFunctionInTrigger}
                                                onSelectedVidooTrigger={onSelectedVidooTrigger}
                                                nameMeshBot={nameMeshBot}
                                                currentMeshbot={currentMeshbot}
                                                actionBlockName={actionBlockName}
                                                onUpdateHouseModeTrigger={onUpdateHouseModeTrigger}
                                                MeshBotAction={MeshBotAction}
                                                isOpenedExceptionBlock={isOpenedExceptionBlock}
                                            />
                                        );
                                    })}
                                </div>
                                <BuilderFooter
                                    actionId={device.id}
                                    type={meshBot.TRIGGER}
                                    typeTrigger={meshBot.EXCEPTION}
                                    meshbotType={MESHBOT_TYPES.LOCAL}
                                    actionBlockName={actionBlockName}
                                    onAddTrigger={onAddExceptionTrigger}
                                    showAddGroupButton={true}
                                    isException
                                />
                            </div>
                        )}

                        <TriggerForActions
                            provided={provided}
                            {...device}
                            index={index}
                            serial={serial}
                            items={items}
                            model={model}
                            devices={devices}
                            currentItem={device}
                            rooms={rooms}
                            typeMeshbot={typeMeshbot}
                            MeshBotAction={MeshBotAction}
                            currentDeviceAction={currentDeviceAction}
                            onUpdateTriggerAction={onUpdateTriggerAction}
                            onSelected={onSelected}
                            onSelectedMeshBotAction={onSelectedMeshBotAction}
                            deleteTriggerAction={MeshBotAction.deleteTriggerAction}
                            currentRule={currentRule}
                            onUpdateFieldActionBlock={onUpdateFieldActionBlock}
                            onAddActionBlock={onAddActionBlock}
                            actionBlockName={actionBlockName}
                            foundValidatedThenBlock={foundValidatedThenBlock}
                            foundValidatedElseBlock={foundValidatedElseBlock}
                            scenesList={scenesList}
                            channels={channels}
                            getNotificationData={getNotificationData}
                            usersData={usersData}
                            blockType={blockType}
                            isEditing={isEditing}
                            listLuaScripts={listLuaScripts}
                            actionExceptionTriggers={currentException?.triggers}
                            onToggleExceptionBlock={onToggleExceptionBlock}
                            onPreventDraggable={onPreventDraggable}
                            isOpenedExceptionBlock={isOpenedExceptionBlock}
                        />
                    </div>
                </ActionTriggerBlockWrapper>
            )}
        </Draggable>
    );
};

export default DraggableActionTrigger;
