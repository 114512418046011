import React, { useState, useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';

import SelectComponent from '../../SelectComponent';

import * as meshbot from '../../../../../../constants/MeshbotConstant';
import { customMeridiemSelect } from '../../../../../../constants/rulesInSelect';
import { HOUR_MIN_INPUT_VALUE, HOUR_MAX_INPUT_VALUE } from '../../../../../../constants/CloudMeshbotConstant';
import { InputMaterial } from '../../../../../../components';
import { getIntegerFromNumberInput } from '../../../utils';
import { useTranslate } from '../../../../../../features/languages';
import {
    EZLOGIC_HINTS_VARIABLE,
    EZLOGIC_TITLE_DAY,
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_MONTH,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_YEAR,
} from '../../../../../../constants/language_tokens';

export const HoursAndMinutesSelect = (props) => {
    const { customHours, customMinutes, onChangeCustomTime, isPm } = props;

    const [hours, setHours] = useState(customHours);
    const [minutes, setMinutes] = useState(customMinutes);
    const [modifier, setModifier] = useState(isPm ? meshbot.PM : meshbot.AM);
    const { t } = useTranslate();

    useEffect(() => {
        onChangeCustomTime([hours, minutes], modifier);
    }, [hours, minutes, modifier]);

    const handleOnceTime = (e) => {
        return Number(getIntegerFromNumberInput(e));
    };

    const handleBlurHoursOnceTime = (e) => {
        if (e.target.value < 1) {
            setHours(moment().format('h'));
        }
    };

    return (
        <>
            <InputMaterial
                label={t(EZLOGIC_TITLE_HOURS)}
                type="number"
                value={hours}
                min={HOUR_MIN_INPUT_VALUE}
                max={HOUR_MAX_INPUT_VALUE}
                step={1}
                onChange={(e) => setHours(handleOnceTime(e))}
                onBlur={handleBlurHoursOnceTime}
                xsSmall
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_MINUTES)}
                type="number"
                value={minutes}
                min={0}
                max={59}
                step={1}
                onChange={(e) => setMinutes(handleOnceTime(e))}
                xsSmall
            />

            <SelectComponent
                small
                value={modifier}
                label={t(EZLOGIC_TITLE_VALUE)}
                info={t(EZLOGIC_HINTS_VARIABLE)}
                onChange={(e) => setModifier(e.target.value)}
            >
                {customMeridiemSelect.map((item) => {
                    return (
                        <MenuItem key={item.name} value={item.name}>
                            {item.value}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </>
    );
};

const SectionCustom = (props) => {
    const {
        type,
        customHours,
        customMinutes,
        onChangeCustomTime,
        onSelectedCustomDate,
        onSelectedMeridiemCustom,
        onBlurCustomTime,
        selectedTimeType,
        selectYear,
        selectMonth,
        selectDay,
    } = props;
    const { t } = useTranslate();

    const isShowSelectMonth = () => {
        switch (typeof selectDay) {
            case meshbot.STRING:
            case meshbot.NUMBER:
                if (selectDay === meshbot.DEFAULT_CUSTOM_DAY) {
                    return false;
                }

                return true;
            default:
                if (type === meshbot.START_TIME && selectDay.startDay === meshbot.DEFAULT_CUSTOM_DAY) {
                    return false;
                } else if (type === meshbot.END_TIME && selectDay.endDay === meshbot.DEFAULT_CUSTOM_DAY) {
                    return false;
                } else {
                    return true;
                }
        }
    };

    const isShowSelectYear = () => {
        if (!isShowSelectMonth()) {
            return false;
        }
        switch (typeof selectMonth) {
            case meshbot.STRING:
            case meshbot.NUMBER:
                if (selectMonth === '' || selectMonth === meshbot.DEFAULT_CUSTOM_MONTH) {
                    return false;
                }

                return true;
            default:
                if (
                    (type === meshbot.START_TIME && !selectMonth.hasOwnProperty(meshbot.START_MONTH)) ||
                    (type === meshbot.START_TIME && selectMonth.startMonth === meshbot.DEFAULT_CUSTOM_MONTH)
                ) {
                    return false;
                } else if (
                    (type === meshbot.END_TIME && !selectMonth.hasOwnProperty(meshbot.END_MONTH)) ||
                    (type === meshbot.END_TIME && selectMonth.endMonth === meshbot.DEFAULT_CUSTOM_MONTH)
                ) {
                    return false;
                } else {
                    return true;
                }
        }
    };

    const getMeridiemValue = meshbot.getValueField(selectedTimeType, 'time', type);
    const valueMonth = selectMonth ? meshbot.getValueField(selectMonth, 'month', type) : 'everymonth';
    const valueDay = selectDay ? meshbot.getValueField(selectDay, 'day', type) : 'everyday';
    const valueYear = selectYear ? meshbot.getValueField(selectYear, 'year', type) : meshbot.DEFAULT_CUSTOM_YEAR;
    const hour = type ? meshbot.detectBetween('hour', type) : 'customHours';
    const minute = type ? meshbot.detectBetween('minute', type) : 'customMinutes';
    const inputHourValue = getMeridiemValue === 'am' && customHours === 0 ? meshbot.HOUR12 : customHours;

    return (
        <>
            <InputMaterial
                label={t(EZLOGIC_TITLE_HOURS)}
                type="number"
                value={inputHourValue}
                min={1}
                max={HOUR_MAX_INPUT_VALUE}
                step={1}
                onChange={(value) => onChangeCustomTime(value, hour, type)}
                xsSmall
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_MINUTES)}
                type="number"
                value={customMinutes}
                min={0}
                max={59}
                step={1}
                onBlur={(value) => onBlurCustomTime(value, minute, type)}
                onChange={(value) => onChangeCustomTime(value, minute, type)}
                xsSmall
            />

            <SelectComponent
                value={selectedTimeType ? getMeridiemValue : ''}
                label={t(EZLOGIC_TITLE_VALUE)}
                info={t(EZLOGIC_HINTS_VARIABLE)}
                onChange={(e) => onSelectedMeridiemCustom(e, type)}
            >
                {customMeridiemSelect.map((item) => {
                    return (
                        <MenuItem key={item.name} value={item.name}>
                            {item.value}
                        </MenuItem>
                    );
                })}
            </SelectComponent>

            <SelectComponent
                value={valueDay ? valueDay : meshbot.DEFAULT_CUSTOM_DAY}
                label={t(EZLOGIC_TITLE_DAY)}
                onChange={(e) => onSelectedCustomDate(e, type === 'startTime' ? 'startDay' : 'endDay', 'selectDay')}
            >
                {meshbot.DAYS_CUSTOM_ARRAY.map((item) => {
                    return (
                        <MenuItem key={item.name} value={item.name}>
                            {item.value}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
            {isShowSelectMonth() && (
                <SelectComponent
                    value={valueMonth ? valueMonth : meshbot.DEFAULT_CUSTOM_MONTH}
                    label={t(EZLOGIC_TITLE_MONTH)}
                    onChange={(e) =>
                        onSelectedCustomDate(e, type === 'startTime' ? 'startMonth' : 'endMonth', 'selectMonth')
                    }
                >
                    {meshbot.MONTH_CUSTOM_ARRAY.map((item) => {
                        return (
                            <MenuItem key={item.name} value={item.name}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            )}
            {isShowSelectYear() && (
                <SelectComponent
                    value={valueYear ? valueYear : meshbot.DEFAULT_CUSTOM_YEAR}
                    label={t(EZLOGIC_TITLE_YEAR)}
                    onChange={(e) =>
                        onSelectedCustomDate(e, type === 'startTime' ? 'startYear' : 'endYear', 'selectYear')
                    }
                >
                    {meshbot.YEARS_CUSTOM_ARRAY.map((item) => {
                        return (
                            <MenuItem key={item.name} value={item.name}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            )}
        </>
    );
};

export default React.memo(SectionCustom);
