import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomizationAction from '../../../../actions/CustomizationActions';
import DraggableNodeSorterList from './components/DraggableNodeSorterList';
import { getPayloadForNodes, getSortedNodesArray } from '../utils';
import { useTranslate } from '../../../../features/languages';
import {
    ACTION_LOCAL_NODES,
    TRIGGER_LOCAL_NODES,
    TRIGGER_CLOUD_NODES,
    ACTION_CLOUD_NODES,
    EZLOGIC_TITLE_NODES_SORT,
    EZLOGIC_TITLE_CONTROLLABLES_SORT,
} from '../constants';
import classes from './NodesControllables.module.scss';
import { EZLOGIC_TITLE_CLOUD_MESHBOT, EZLOGIC_TITLE_LOCAL_MESHBOT } from '../../../../constants/language_tokens';

const NodesControllables = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const initialNodesControllables = useSelector((state) => state.ezloCustomization?.initialNodesControllables);

    const [listNodesClouds, setListNodesCloud] = useState(initialNodesControllables?.listNodesCloud);
    const [actionCloudNodes, setActionCloudNodes] = useState(initialNodesControllables?.actionCloudNodes);
    const [listNodesLocals, setListNodesLocal] = useState(initialNodesControllables?.listNodesLocal);
    const [actionLocalNodes, setActionLocalNodes] = useState(initialNodesControllables?.actionLocalNodes);

    useEffect(() => {
        setListNodesCloud(initialNodesControllables?.listNodesCloud);
        setActionCloudNodes(initialNodesControllables?.actionCloudNodes);
        setListNodesLocal(initialNodesControllables?.listNodesLocal);
        setActionLocalNodes(initialNodesControllables?.actionLocalNodes);
    }, [initialNodesControllables]);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === TRIGGER_CLOUD_NODES && destination.droppableId === TRIGGER_CLOUD_NODES) {
            const updatedSortedArray = getSortedNodesArray(source, destination, listNodesClouds);
            setListNodesCloud(updatedSortedArray);
            const payloadNodes = getPayloadForNodes(
                updatedSortedArray,
                listNodesLocals,
                actionCloudNodes,
                actionLocalNodes,
            );
            dispatch(CustomizationAction.nodesControllablesAction(payloadNodes));
        }

        if (source.droppableId === ACTION_CLOUD_NODES && destination.droppableId === ACTION_CLOUD_NODES) {
            const updatedSortedArray = getSortedNodesArray(source, destination, actionCloudNodes);
            setActionCloudNodes(updatedSortedArray);
            const payloadNodes = getPayloadForNodes(
                listNodesClouds,
                listNodesLocals,
                updatedSortedArray,
                actionLocalNodes,
            );
            dispatch(CustomizationAction.nodesControllablesAction(payloadNodes));
        }

        if (source.droppableId === TRIGGER_LOCAL_NODES && destination.droppableId === TRIGGER_LOCAL_NODES) {
            const updatedSortedArray = getSortedNodesArray(source, destination, listNodesLocals);
            setListNodesLocal(updatedSortedArray);
            const payloadNodes = getPayloadForNodes(
                listNodesClouds,
                updatedSortedArray,
                actionCloudNodes,
                actionLocalNodes,
            );
            dispatch(CustomizationAction.nodesControllablesAction(payloadNodes));
        }

        if (source.droppableId === ACTION_LOCAL_NODES && destination.droppableId === ACTION_LOCAL_NODES) {
            const updatedSortedArray = getSortedNodesArray(source, destination, actionLocalNodes);
            setActionLocalNodes(updatedSortedArray);
            const payloadNodes = getPayloadForNodes(
                listNodesClouds,
                listNodesLocals,
                actionCloudNodes,
                updatedSortedArray,
            );
            dispatch(CustomizationAction.nodesControllablesAction(payloadNodes));
        }
    };

    return (
        <>
            <div className={classes.columns}>
                <div className={classes.column1}>
                    <h2 className={classes.name}>{t(EZLOGIC_TITLE_CLOUD_MESHBOT)}</h2>
                    <DraggableNodeSorterList
                        listItems={listNodesClouds}
                        onDragEnd={onDragEnd}
                        title={t(EZLOGIC_TITLE_NODES_SORT)}
                        id={TRIGGER_CLOUD_NODES}
                    />
                    <DraggableNodeSorterList
                        listItems={actionCloudNodes}
                        onDragEnd={onDragEnd}
                        title={t(EZLOGIC_TITLE_CONTROLLABLES_SORT)}
                        id={ACTION_CLOUD_NODES}
                    />
                </div>
                <div className={classes.column2}>
                    <h2 className={classes.name}>{t(EZLOGIC_TITLE_LOCAL_MESHBOT)}</h2>

                    <DraggableNodeSorterList
                        listItems={listNodesLocals}
                        onDragEnd={onDragEnd}
                        title={t(EZLOGIC_TITLE_NODES_SORT)}
                        id={TRIGGER_LOCAL_NODES}
                    />
                    <DraggableNodeSorterList
                        listItems={actionLocalNodes}
                        onDragEnd={onDragEnd}
                        title={t(EZLOGIC_TITLE_CONTROLLABLES_SORT)}
                        id={ACTION_LOCAL_NODES}
                    />
                </div>
            </div>
        </>
    );
};

export default NodesControllables;
