import React from 'react';
import {
    createTicketPageWrapper,
    createTicketForm,
    createTicketInput,
    createTicketTextarea,
    createTicketItem,
    createTicketLabel,
    buttonCreate,
    buttonCreateWrapper,
    deviceHubSettingsMenuItem,
} from './EzloCreateTicket.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { supportActions } from '../../../../actions/SupportActions';
import { getDataForUpdateTicket, getTicketCreatePayload } from '../utils';
import { Button } from 'antd';
import { MenuItem, Select } from '@material-ui/core';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_TITLE_ADD_TEXT,
    EZLOGIC_TITLE_CATEGORY,
    EZLOGIC_TITLE_CREATE_A_NEW_SUPPORT_TICKET,
    EZLOGIC_TITLE_CREATE_A_NEW_TICKET,
    EZLOGIC_TITLE_ISSUE_DESCRIPTION,
    EZLOGIC_TITLE_PRIORITY_LEVEL,
} from '../../../../constants/language_tokens';

export const CreateTicketPage = () => {
    const supportData = useSelector((state) => state.supportData);
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const {
        email,
        issue,
        first_name,
        last_name,
        phone,
        kvsToken,
        priority,
        listOfPriorities,
        userUuid,
        listOfHelptopics,
        helptopic,
    } = supportData;

    const onChange = (value, name) => {
        dispatch(supportActions.updateTicketDataFromUi(getDataForUpdateTicket(value, name)));
    };

    const onSave = () => {
        const objForCreateTicket = {
            first_name,
            last_name,
            email,
            phone,
            issue,
            priority,
            userUuid,
            helptopic,
        };

        dispatch(supportActions.createSupportTicket(getTicketCreatePayload(objForCreateTicket), kvsToken));
    };

    return (
        <div className={createTicketPageWrapper}>
            <h3>{t(EZLOGIC_TITLE_CREATE_A_NEW_SUPPORT_TICKET)}</h3>
            <form className={createTicketForm}>
                <p className={createTicketItem}>
                    <span className={createTicketLabel}>{t(EZLOGIC_TITLE_PRIORITY_LEVEL)}</span>
                    <Select
                        className={createTicketInput}
                        label={priority}
                        value={priority}
                        onChange={(e) => onChange(e.target.value, 'priority')}
                    >
                        {listOfPriorities.map((priority) => {
                            return (
                                <MenuItem className={deviceHubSettingsMenuItem} key={priority.id} value={priority.id}>
                                    {priority.attributes.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </p>
                <p className={createTicketItem}>
                    <span className={createTicketLabel}>{t(EZLOGIC_TITLE_CATEGORY)}</span>
                    <Select
                        className={createTicketInput}
                        value={helptopic}
                        onChange={(e) => onChange(e.target.value, 'helptopic')}
                    >
                        {listOfHelptopics.map((helptopic) => {
                            return (
                                <MenuItem className={deviceHubSettingsMenuItem} key={helptopic.id} value={helptopic.id}>
                                    {helptopic.attributes.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </p>
                <p className={createTicketItem}>
                    <span className={createTicketLabel}>{t(EZLOGIC_TITLE_ISSUE_DESCRIPTION)}</span>
                    <textarea
                        className={createTicketTextarea}
                        placeholder={t(EZLOGIC_TITLE_ADD_TEXT)}
                        onChange={(e) => onChange(e.target.value, 'issue')}
                        rows={10}
                    />
                </p>
                <div className={buttonCreateWrapper}>
                    <Button className={buttonCreate} onClick={onSave}>
                        {t(EZLOGIC_TITLE_CREATE_A_NEW_TICKET)}
                    </Button>
                </div>
            </form>
        </div>
    );
};
