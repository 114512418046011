import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import VariableNameBlock from '../VariableNameBlock';
import { generateCodeStringTemplate } from '../../../template';
import {
    IS_USE_VARIABLE_FIELD,
    ITEM_CONTAINED_VALUE,
    ITEM_OF_RESULTS,
    SIMPLE_CODE_FUNCTIONS,
    STRING,
    TYPES_OF_FUNCTIONS,
    VARIABLE,
} from '../../../constants';
import { GetStringFromPosition } from './GetStringFromPosition';
import { CheckStringHasWord } from './CheckStringHasWord';
import { GetStringFromJsonPath } from './GetStringFromJsonPath';
import UseVariableCheckBox from './UseVariableCheckBox';
import FunctionSelect from './FunctionSelect';
import { useFetchCodeTestRun } from '../../../hooks/useFetchCodeTestRun';
import { STRINGIFY } from '../../../../../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { getVariableValueAsStr } from '../../../utils';

import style, { runTextResultBlock, runTextResultBlockDisabled } from '../CloudVariable.module.scss';
import { EZLOGIC_TITLE_TEST_YOUR_VARIABLE } from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';

const VariableFields = (props) => {
    const {
        handleChangeCapability,
        handleSetBlackListVariable,
        currentActionData,
        createdVariable,
        handleChangeValue,
    } = props;
    const { abstractUuid, methodValue, fieldsList } = currentActionData;
    const { capability, value } = createdVariable;
    const { t } = useTranslate();
    const [currentFunc, setCurrentFunc] = useState(SIMPLE_CODE_FUNCTIONS.JSON);
    const buildCodeString = useMemo(() => {
        const skeleton = [[capability, VARIABLE, `${STRINGIFY}(${value})`, STRING]];

        return generateCodeStringTemplate(skeleton, true);
    }, [capability, value]);
    const { loading, error, response, callbackMemoized } = useFetchCodeTestRun(
        abstractUuid,
        methodValue,
        fieldsList,
        buildCodeString,
    );
    const isDisabled = !capability || loading || !currentFunc?.uuid;
    const resultValue = useMemo(() => {
        const value = response && response[ITEM_OF_RESULTS][ITEM_CONTAINED_VALUE];

        return getVariableValueAsStr(value);
    }, [response]);

    return (
        <div className={style.itemWrapper}>
            <div className={style.itemBlock}>
                <div className={style.variableBlock}>
                    <VariableNameBlock onChangeCapability={handleChangeCapability} capability={capability} />
                </div>
                <div className={style.variableBlock}>
                    <FunctionSelect
                        currentFunc={currentFunc}
                        value={value}
                        setCurrentFunc={setCurrentFunc}
                        handleChangeValue={handleChangeValue}
                    />
                </div>
                <div className={style.variableBlock}>
                    {currentFunc.uuid === TYPES_OF_FUNCTIONS.SLICE && (
                        <GetStringFromPosition onChangeValue={handleChangeValue} defaultValue={value} />
                    )}
                    {currentFunc.uuid === TYPES_OF_FUNCTIONS.FIND && (
                        <CheckStringHasWord onChangeValue={handleChangeValue} defaultValue={value} />
                    )}
                    {currentFunc.uuid === TYPES_OF_FUNCTIONS.JSON && (
                        <GetStringFromJsonPath onChangeValue={handleChangeValue} defaultValue={value} />
                    )}
                </div>
                {IS_USE_VARIABLE_FIELD && (
                    <UseVariableCheckBox
                        blackListVariable={currentActionData?.blackListVariable || []}
                        handleSetBlackListVariable={handleSetBlackListVariable}
                        variableData={createdVariable}
                    />
                )}
            </div>

            <div className={style.testButtonBlock}>
                <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={callbackMemoized}
                    disabled={isDisabled}
                >
                    {loading && <CircularProgress className={style.progress} size={16} />}
                    {t(EZLOGIC_TITLE_TEST_YOUR_VARIABLE)}
                </Button>
                <TextField
                    multiline
                    maxRows={1}
                    variant="outlined"
                    disabled
                    value={resultValue !== undefined ? resultValue : 'Value'}
                    className={classNames(runTextResultBlock, {
                        [runTextResultBlockDisabled]: resultValue === undefined,
                    })}
                />
                {error && <div className={style.error}>{error}</div>}
            </div>
        </div>
    );
};

export default VariableFields;
