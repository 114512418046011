import React from 'react';
import {
    CONFIRM_ACTION,
    getUpgradeText,
    PRIVATE_GENERATOR,
    PRIVATE_TEMPLATE_TO_MARKETPLACE,
    PUBLISH,
} from '../../../../../../constants/Plugins';
import { confirmationTitle } from './confirmationContent.module.scss';
import { COPY_AND_UPGRADE } from '../../../constants/constants';
import { useTranslate } from '../../../../../../features/languages';

const ConfirmationContent = ({ typeList, currentAction, pluginData }) => {
    const { t } = useTranslate();
    const actionContent =
        currentAction === COPY_AND_UPGRADE
            ? getUpgradeText(pluginData?.name, pluginData?.versionForUpdate, pluginData?.serial, t)
            : t(CONFIRM_ACTION);

    return (
        <div className={confirmationTitle}>
            {typeList === PRIVATE_GENERATOR && currentAction === PUBLISH ? (
                <div dangerouslySetInnerHTML={{ __html: t(PRIVATE_TEMPLATE_TO_MARKETPLACE) }}></div>
            ) : (
                actionContent
            )}
        </div>
    );
};

export default ConfirmationContent;
