import React from 'react';

import {
    EZLOGIC_TITLE_AUTHOR,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_LANGUAGE,
    EZLOGIC_TITLE_NAME,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const ListOfMetaPluginFields = ({ metaData }) => {
    const { t } = useTranslate();

    return (
        <ul>
            <li>
                <span>{t(EZLOGIC_TITLE_AUTHOR)}:</span> {metaData?.author && metaData?.author?.text}
            </li>
            <li>
                <span>{t(EZLOGIC_TITLE_NAME)}:</span> {metaData?.name && metaData?.name?.text}
            </li>
            <li>
                <span>{t(EZLOGIC_TITLE_LANGUAGE)}:</span> {metaData?.language}
            </li>
            <li>
                <span>{t(EZLOGIC_TITLE_DESCRIPTION)}:</span> {metaData?.description && metaData?.description?.text}
            </li>
        </ul>
    );
};

export default ListOfMetaPluginFields;
