import { useSelector } from 'react-redux';
import { TRIGGER_FORM } from '../../../../../../../../constants/MeshbotConstant';
import { MESHBOT_TYPES } from '../../../../../../EzloMeshbots/constants';
import { getCurrentCloudAction, getCurrentCloudTrigger, getCurrentLocalAction } from '../utils';
import { getIsCloudTypeMeshBot } from '../../../../../../EzloMeshbot/utils';

export const useActionSelect = (id, createType, actionBlockName, calledFrom) => {
    const selectedRuleLocal = useSelector((state) => state.meshBot.local.selectedRule);
    const ruleCloudAction = useSelector((state) => state.meshBot.cloud.ruleCloudAction);
    const ruleCloudTriggers = useSelector((state) => state.meshBot.cloud.ruleCloudTriggers);

    if (createType === MESHBOT_TYPES.LOCAL) {
        return getCurrentLocalAction(id, actionBlockName, selectedRuleLocal);
    } else if (createType === MESHBOT_TYPES.CLOUD && calledFrom !== TRIGGER_FORM) {
        return getCurrentCloudAction(id, ruleCloudAction);
    } else if (getIsCloudTypeMeshBot(createType) && calledFrom === TRIGGER_FORM) {
        return getCurrentCloudTrigger(id, ruleCloudTriggers);
    }
};
