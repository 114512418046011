import React from 'react';

const InfoIcon = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line
            d="M100 148C126.51 148 148 126.51 148 100C148 73.4903 126.51 52 100 52C73.4903 52 52 73.4903 52 100C52 126.51 73.4903 148 100 148Z"
            stroke="#3E46AE"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path d="M100 106.5V70.5" stroke="#3E46AE" strokeWidth="16" strokeMiterlimit="10" />
        <path
            // eslint-disable-next-line
            d="M100 130C104.418 130 108 126.418 108 122C108 117.582 104.418 114 100 114C95.5817 114 92 117.582 92 122C92 126.418 95.5817 130 100 130Z"
            fill="#3E46AE"
        />
        <path
            // eslint-disable-next-line
            d="M154.3 113.8L140 99.5C140 99.7 140 99.8 140 100C140 120.3 124.9 137 105.3 139.6L118.5 152.8C136.1 146.7 149.7 132 154.3 113.8Z"
            fill="#1F2581"
        />
    </svg>
);

export default InfoIcon;
