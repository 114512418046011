import {
    apiGetCustomizationData,
    apiGetTicketDataByPartnerUuid,
    createSupportTicket,
    getListOfHelptopics,
    getListOfPriorities,
    getPartnerUuid,
    getUsersFromKvs,
    getUserUuidForCreateTicket,
} from '../api/apiScenes';
import { bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import * as types from '../constants/ActionTypes';
import { toast, TOAST_TYPE } from '../components/Toast';
import { parseData } from '../containers/Ezlo/EzloCustomization/utils';
import { PARTNER_UUID_KEY } from '../containers/Ezlo/EzloCustomization/constants';
import actions from '../constants/ActionTypes/Navigation';
import { goToEzloPage } from '../containers/Navigation/utils';
import { SUPPORT_URL } from '../constants/URLs';
import { getEncodeValue } from '../containers/Ezlo/EzloSupport/utils';

export const supportActions = {
    getPartnerUuidData: (PK_Account) => async (dispatch) => {
        let partnerUuid = '';
        const accountData = await getPartnerUuid(PK_Account);
        accountData?.data?.map((item) => {
            if (item[PARTNER_UUID_KEY]) {
                partnerUuid = item[PARTNER_UUID_KEY];
            }
        });
        await dispatch(supportActions.updatePartnerUuid(partnerUuid));
    },

    routeToSupportPage:
        (parseDataPartnerPortalForSupport, confirmUserAction, menuItem, props, partnerUuid) => async (dispatch) => {
            dispatch(supportActions.getTicketDataFromPartnerUuid(partnerUuid));
            dispatch(
                supportActions.updateSupportData({
                    ...parseDataPartnerPortalForSupport,
                }),
            );

            return confirmUserAction(() => {
                dispatch({ type: actions.SET_ACTIVE_MENU_ITEM, data: menuItem?.willChange || [] });
                goToEzloPage(menuItem.link, menuItem.isAbsolutePath, props);
            });
        },

    getDataFromKvs: (menuItem, confirmUserAction, props) => async (dispatch, setState) => {
        const state = setState();
        const { partnerUuid } = state.supportData;
        const userDataFromKvs = await getUserUuidForCreateTicket();
        const encodeValueFromKvs = parseData(atob(getEncodeValue(userDataFromKvs)));

        if (encodeValueFromKvs) {
            const customizationData = await apiGetCustomizationData(partnerUuid);
            const getCustomizationData = getEncodeValue(customizationData);
            const parseCustomizationData = parseData(atob(getCustomizationData));
            const parseDataPartnerPortalForSupport = parseCustomizationData?.partnerPortal;

            dispatch(
                supportActions.routeToSupportPage(
                    parseDataPartnerPortalForSupport,
                    confirmUserAction,
                    menuItem,
                    props,
                    partnerUuid,
                ),
            );
        } else {
            window.open(SUPPORT_URL, '_blank');
        }
    },

    getUsers: (authorization) => async (dispatch) => {
        await getUsersFromKvs(authorization)
            .then((res) => {
                dispatch(supportActions.updateSupportUsers(res.data.data));
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err);
            });
    },

    createSupportTicket: (params, authorization) => async () => {
        await createSupportTicket(params, authorization)
            .then(() => {
                toast('Ticket has been successfully created', { type: TOAST_TYPE.SUCCESS });
            })
            .catch((err) => {
                toast(err, { type: TOAST_TYPE.ERROR });
                bugsnagNotifyWrapper(err);
            });
    },

    getListOfPriorities: (authorization) => async (dispatch) => {
        await getListOfPriorities(authorization)
            .then((res) => {
                const listOfPriorities = res.data.data;
                dispatch(supportActions.setListPriorities(listOfPriorities));
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err);
            });
    },

    getListOfHelptopics: (authorization) => async (dispatch) => {
        await getListOfHelptopics(authorization)
            .then((res) => {
                const listOfHelptopics = res.data.data;
                dispatch(supportActions.setListOfHelptopics(listOfHelptopics));
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err);
            });
    },

    getTicketDataFromPartnerUuid: (partner_uuid) => async (dispatch) => {
        await apiGetTicketDataByPartnerUuid(partner_uuid)
            .then((res) => {
                const encodedTicketData = res?.data?.data?.list[0]?.value;
                if (encodedTicketData) {
                    const ticketData = parseData(atob(encodedTicketData));
                    const authorization = btoa(ticketData.subdomain + ':' + ticketData.api_key);
                    dispatch(
                        supportActions.updateKvsToken({
                            authorization,
                            subdomain: ticketData.subdomain,
                            api_key: ticketData.api_key,
                        }),
                    );
                    dispatch(supportActions.getUsers(authorization));
                    dispatch(supportActions.getListOfPriorities(authorization));
                    dispatch(supportActions.getListOfHelptopics(authorization));
                    dispatch(supportActions.getUserUuidForCreateTicket());
                }
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err);
            });
    },

    getUserUuidForCreateTicket: () => async (dispatch) => {
        await getUserUuidForCreateTicket()
            .then((res) => {
                const userUuid = parseData(atob(res.data.data.list[0].value))[0]?.userId;
                dispatch(supportActions.updateUuidFromKvs(userUuid));
            })
            .catch((err) => {
                bugsnagNotifyWrapper(err);
            });
    },

    updateUuidFromKvs: (data) => ({
        type: types.UPDATE_USER_UUID_FROM_KVS,
        data,
    }),

    setListPriorities: (data) => ({
        type: types.UPDATE_LIST_OF_PRIORITIES,
        data,
    }),

    setListOfHelptopics: (data) => ({
        type: types.UPDATE_LIST_OF_HELPTOPICS,
        data,
    }),

    updateKvsToken: (data) => ({
        type: types.UPDATE_KVS_SUPPORT_TOKEN,
        data,
    }),

    updateSupportData: (data) => ({
        type: types.UPDATE_EZLO_SUPPORT_DATA,
        data,
    }),

    updateSupportUsers: (data) => ({
        type: types.UPDATE_SUPPORT_USERS,
        data,
    }),

    updateTicketDataFromUi: (data) => ({
        type: types.UPDATE_TICKET_DATA_FROM_UI,
        data,
    }),

    updatePartnerUuid: (data) => ({
        type: types.UPDATE_PARTNER_UUID,
        data,
    }),
};
