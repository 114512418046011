export const BUTTON_TEXTS = {
    TEST_JS_CODE: 'Test your variable',
    TEST_VARIABLES: 'Test your variables',
};
export const DATA_TREE_TITLE = 'Data Structure';
export const RESPONSE_NAME = 'data';
export const BODY_PATH = '.body';
export const RESPONSE_ELEMENTS = {
    TYPES: {
        ARRAY: 'array',
    },
    KEYWORD_TYPES: 'keywordTypes',
    PROPERTIES: 'properties',
};
export const RESPONSE_TREE_ELEMENT = 'responseTreeElement';
export const KEYWORDS = ['allOf', 'oneOf', 'anyOf'];
export const TYPE = 'type';
export const APP_JSON = 'application/json';
