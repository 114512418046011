import React from 'react';
import { Button } from '@material-ui/core';
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';

import MeshBotSelectField from '../../MeshBotSelectField/MeshBotSelectField';

import { ALL, CONTAINED, PRIMARY, SMALL } from '../../../constants/MeshbotConstant';
import {
    EZLOGIC_HINT_SELECT_CATEGORY,
    EZLOGIC_TITLE_CATEGORIES,
    EZLOGIC_TITLE_CATEGORY_NOT_FOUND,
    EZLOGIC_TITLE_MANAGE_CATEGORIES,
    EZLOGIC_TITLE_SELECT_ALL,
} from '../../../constants/language_tokens';
import { useTranslate } from '../../../features/languages';
import styles from '../NotificationCloudBlock.module.scss';

export const NotificationCloudCategories = (props) => {
    const { t } = useTranslate();

    const {
        selectedCategory,
        handleChangeCategory,
        isAllCategoriesSelected,
        handleShowCreatingCategoriesModal,
        notificationCategoriesList,
        isEmptyCategories,
    } = props;

    return (
        <>
            <MeshBotSelectField
                label={EZLOGIC_TITLE_CATEGORIES}
                selectValue={selectedCategory}
                handleChange={handleChangeCategory}
                infoText={EZLOGIC_HINT_SELECT_CATEGORY}
                showHelperText={isEmptyCategories && EZLOGIC_TITLE_CATEGORY_NOT_FOUND}
                selectionAttributes={{
                    renderValue: (selectedCategory) => selectedCategory.join(', '),
                    multiple: true,
                }}
            >
                <MenuItem className={styles.menuItem} value={ALL}>
                    <ListItemIcon>
                        <Checkbox
                            color={PRIMARY}
                            checked={isAllCategoriesSelected}
                            indeterminate={
                                selectedCategory.length && selectedCategory.length < notificationCategoriesList.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary={t(EZLOGIC_TITLE_SELECT_ALL)} />
                </MenuItem>
                {notificationCategoriesList &&
                    notificationCategoriesList.map((item) => (
                        <MenuItem className={styles.menuItem} key={item.display_name} value={item.display_name}>
                            <ListItemIcon>
                                <Checkbox color={PRIMARY} checked={selectedCategory.indexOf(item.display_name) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={item.display_name} />
                        </MenuItem>
                    ))}
            </MeshBotSelectField>
            <div className={styles.button_wrapper}>
                <Button
                    size={SMALL}
                    variant={CONTAINED}
                    color={PRIMARY}
                    onClick={handleShowCreatingCategoriesModal}
                    className={styles.button}
                >
                    {t(EZLOGIC_TITLE_MANAGE_CATEGORIES)}
                </Button>
            </div>
        </>
    );
};
