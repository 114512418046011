import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import InfoIconAndTooltipWithControllerInfo from './InfoIconAndTooltipWithControllerInfo';
import ControllerButtonSelect from './ControllerButtonSelect';

import {
    controller_item,
    is_unassigned,
    controller_block,
    icons_wrapper,
    controller_icon,
    online,
    info_block,
    online_info_block,
    status,
} from './ControllerCard.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_OFFLINE, EZLOGIC_TITLE_ONLINE } from '../../../../../../constants/language_tokens';
import { ControllerIcon } from '../../../../../../assets/icons';

const ControllerCard = (props) => {
    const { controller, isGroupActive, isUnassigned, groupKey, group, deleteGroup } = props;
    const ezloState = useSelector((state) => state.ezlo);
    const controllerSerial = controller.PK_Device;
    const controllerData = controllerSerial ? ezloState.data[controllerSerial] : null;
    const isOnline = controller.NMAControllerStatus ? true : false;
    const isShowSettings = isOnline;
    // Hidden for task 3085 called "Remove grouping of hubs on UI".
    // const isOnline = controller?.online ? controller?.online : controller?.connected;
    // const isShowSettings = controller.online && isGroupActive;
    const { t } = useTranslate();

    return (
        <div className={classNames(controller_item, { [is_unassigned]: isUnassigned })}>
            <div className={controller_block}>
                <div className={icons_wrapper}>
                    <span className={classNames(controller_icon, { [online]: isOnline })}>
                        <ControllerIcon />
                    </span>
                    <InfoIconAndTooltipWithControllerInfo
                        controller={controller}
                        controllerData={controllerData}
                        isGroupActive={isGroupActive}
                    />
                </div>

                <div className={classNames(info_block, { [online_info_block]: isOnline })}>
                    <span>{controllerSerial}</span>
                    <span className={status}>{isOnline ? t(EZLOGIC_TITLE_ONLINE) : t(EZLOGIC_TITLE_OFFLINE)}</span>
                </div>

                <ControllerButtonSelect
                    controllerSerial={controllerSerial}
                    controllerData={controllerData}
                    isShowSettings={isShowSettings}
                    isControllerOnline={isOnline}
                    groupKey={groupKey}
                    group={group}
                    deleteGroup={deleteGroup}
                />
            </div>
        </div>
    );
};

export default ControllerCard;
