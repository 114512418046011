import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { atomRE } from '../../../constants/Regexp';
import EzloOffline from './EzloOffline';

function mapStateToProps(state, ownProps) {
    return {
        account: state.account.data,
        ezlos: state.ezlos.items.slice(),
        isAtom: atomRE.test(ownProps.match.params.ezloSerial),
        currentEzlo: state.ezlos.items.find((ezlo) => ezlo.serial === ownProps.match.params.ezloSerial) || {},
    };
}

function mapDispatchToProps(dispatch) {
    const obj = {};

    Object.keys(Actions).map((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        redux: {
            state: stateProps,
            actions: dispatchProps,
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EzloOffline);
