import React from 'react';

import CompletedIcon from '../icons/CompletedIcon';
import classNames from 'classnames';
import { currentlyTime } from '../utils';
import {
    EZLOGIC_BUTTON_FINISH,
    EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_CREATED,
    EZLOGIC_TITLE_YOU_MAY_NOW_CONTINUE_USING,
} from '../../../../constants/language_tokens';
import { Button } from '../../../../components';
import { useTranslate } from '../../../../features/languages';

const BackupCreateCompleted = ({ onFinish }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <CompletedIcon className="icon-completed" />
            <span className={classNames('text', 'title')}>{`${currentlyTime()} ${t(
                EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_CREATED,
            )}`}</span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_YOU_MAY_NOW_CONTINUE_USING)}{' '}
            </span>
            <div className="main-content-footer">
                <Button color="primary" variant="contained" className="primary-btn" onClick={onFinish}>
                    {t(EZLOGIC_BUTTON_FINISH)}
                </Button>
            </div>
        </div>
    );
};

export default BackupCreateCompleted;
