import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, FormGroup, Button, styled } from '@material-ui/core';

import { axiosCloudFile } from '../../../../api/config';
import actions from '../../../../actions/CustomizationActions';

import { API_CLOUD_EZLO_MEDIA_URL, API_CLOUD_EZLO_UPLOAD_FILE } from '../../../../constants/URLs';
import { customizedMenuListIds, DASHBOARD, EZLOGIC_BUTTON_UPLOAD_LOGO } from '../constants';
import { MENU_ITEMS } from '../../../Navigation/constants';
import { getItemsAndNestedSubItems, handleCheckboxClick, payloadDataMenuItems, updatedMenuListItem } from '../utils';
import { checkVeraUser } from '../../../Navigation/utils';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_PREVIEW, EZLOGIC_TITLE_SHOW } from '../../../../constants/language_tokens';
import oemManager from '../../../../services/oem';

import './style.scss';
import { AppLogo, DropDownUpIcon } from '../../../../assets/icons';
import { OemLogo } from '../../../../assets/icons/app/OemLogo';

const Input = styled('input')({
    display: 'none',
});

const MenuUi = () => {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const newCustomizationData = useSelector((state) => state.customizationData);
    const isInitialized = useSelector((state) => state.oem.isInitialized);
    const updatedEzlogicPortalData = newCustomizationData?.ezlogicPortal;
    const updatedMenuBarListData = updatedEzlogicPortalData?.menuBar;
    const customizedImage = newCustomizationData?.kvsPayloadData?.images?.logo;

    const isUser = checkVeraUser();
    const oemId = isInitialized ? oemManager.oem.getOemId() : null;
    const [, setName] = useState('');
    const [, setImage] = useState('');
    const [menu, setMenu] = useState(updatedMenuListItem(MENU_ITEMS, customizedMenuListIds, isUser));
    const [updateMenu, setUpdateMenu] = useState(updatedMenuListItem(MENU_ITEMS, customizedMenuListIds, isUser));
    const [, setFinalPayload] = useState();

    useEffect(() => {
        if (updatedMenuBarListData?.length) {
            const menuListIdsPayload = getItemsAndNestedSubItems(updatedMenuBarListData);
            const updatedList = updatedMenuListItem(MENU_ITEMS, menuListIdsPayload, isUser);
            setUpdateMenu(updatedList);
            setMenu(updatedList);
        }
    }, [updatedMenuBarListData]);

    const handleChangeName = (e, index) => {
        const updatedName = e.target.value;
        setName(updatedName);
        const newMenuList = [...menu];
        newMenuList[index].title = e.target.value;
        const finalPayload = payloadDataMenuItems(updateMenu);
        setFinalPayload(finalPayload);
        dispatch(actions.menuUiAction(finalPayload));
    };

    const handleCheckboxStatus = (e) => {
        const newMenuList = [...menu];
        handleCheckboxClick(newMenuList, e.id);
        setUpdateMenu(newMenuList);
        const updatedMenuUi = payloadDataMenuItems(updateMenu);
        dispatch(actions.menuUiAction(updatedMenuUi));
    };

    const handleChangeLogo = (e) => {
        const [updatedLogo] = e.target.files;
        setImage(updatedLogo);
        const bodyFormData = new FormData();
        bodyFormData.append('file', updatedLogo);
        axios.post(API_CLOUD_EZLO_UPLOAD_FILE(), bodyFormData, axiosCloudFile(updatedLogo)).then((res) => {
            dispatch(actions.logoAction(`${API_CLOUD_EZLO_MEDIA_URL()}/${res.data.file.uuid}/${res.data.file.key}`));
        });
    };

    return (
        <div className="menu-ui">
            <Box className="section-edit">
                <div className="menu-ui__header">
                    <OemLogo oemId={oemId} className="logo__left-icon" />
                    {isUser && (
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                onChange={handleChangeLogo}
                            />
                            <Button variant="contained" color="primary" component="span">
                                {t(EZLOGIC_BUTTON_UPLOAD_LOGO)}
                            </Button>
                        </label>
                    )}
                </div>
                <hr />
                {menu?.length &&
                    menu.map((menuItem, index) => {
                        return (
                            <div key={index}>
                                <div className="menu-ui__items">
                                    <div className="menu-ui__list-items">
                                        {menuItem?.id === DASHBOARD ? (
                                            <div className="icon">{menuItem?.icon}</div>
                                        ) : (
                                            <div className="icon">{menuItem?.icon}</div>
                                        )}
                                        <div className="meshbot-title">
                                            <div>{t(menuItem?.title)}</div>
                                            {menuItem?.rename && (
                                                <TextField
                                                    className="outlined-basic"
                                                    hiddenLabel
                                                    variant="standard"
                                                    placeholder={menuItem?.title}
                                                    value={menuItem?.title}
                                                    inputProps={{ maxLength: 20 }}
                                                    onChange={(e) => handleChangeName(e, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="menu-ui__icons">
                                        {menuItem?.show && (
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={menuItem?.checked}
                                                            value={menuItem?.checked}
                                                            onChange={() => handleCheckboxStatus(menuItem, index)}
                                                            color="primary"
                                                            className="menu-ui__checkbox"
                                                        />
                                                    }
                                                    label={<span className="show-label">{t(EZLOGIC_TITLE_SHOW)}</span>}
                                                    labelPlacement="end"
                                                />
                                            </FormGroup>
                                        )}
                                        <div>{menuItem?.subItems && <DropDownUpIcon className="" width="15" />}</div>
                                    </div>
                                </div>
                                {menuItem?.subItems && (
                                    <>
                                        {menuItem.subItems.map((subMenu) => {
                                            return (
                                                <div key={subMenu?.id}>
                                                    <div className="menu-ui__subitems">
                                                        <div className="menu-ui__list-items">
                                                            {subMenu?.id === DASHBOARD ? (
                                                                <div className="icon">{subMenu?.icon}</div>
                                                            ) : (
                                                                <div className="icon"></div>
                                                            )}
                                                            <div className="meshbot-title">
                                                                <div>{t(subMenu?.title)}</div>
                                                                {subMenu?.rename && (
                                                                    <TextField
                                                                        className="outlined-basic"
                                                                        hiddenLabel
                                                                        variant="standard"
                                                                        placeholder={subMenu?.title}
                                                                        value={subMenu?.title}
                                                                        inputProps={{ maxLength: 20 }}
                                                                        onChange={(e) => handleChangeName(e, index)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="menu-ui__icons">
                                                            {subMenu?.show && (
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={subMenu?.checked}
                                                                                value={subMenu?.checked}
                                                                                onChange={() =>
                                                                                    handleCheckboxStatus(subMenu, index)
                                                                                }
                                                                                color="primary"
                                                                                className="menu-ui__checkbox"
                                                                            />
                                                                        }
                                                                        label={
                                                                            <span className="show-label">
                                                                                {t(EZLOGIC_TITLE_SHOW)}
                                                                            </span>
                                                                        }
                                                                        labelPlacement="end"
                                                                    />
                                                                </FormGroup>
                                                            )}

                                                            <div className="menu-ui__dropdown">
                                                                {subMenu?.subItems && (
                                                                    <DropDownUpIcon className="" width="15" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {subMenu?.subItems &&
                                                        subMenu.subItems.map((nestedSubMenu) => {
                                                            return (
                                                                <div className="menu-ui__nestedsubitems">
                                                                    <div className="menu-ui__list-items">
                                                                        {nestedSubMenu?.id === DASHBOARD ? (
                                                                            <div className="icon">
                                                                                {nestedSubMenu?.icon}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="icon">
                                                                                {nestedSubMenu?.icon}
                                                                            </div>
                                                                        )}
                                                                        <div className="meshbot-title">
                                                                            <div>{t(nestedSubMenu?.title)}</div>
                                                                            {nestedSubMenu?.rename && (
                                                                                <TextField
                                                                                    className="outlined-basic"
                                                                                    hiddenLabel
                                                                                    variant="standard"
                                                                                    placeholder={nestedSubMenu?.title}
                                                                                    value={nestedSubMenu?.title}
                                                                                    inputProps={{ maxLength: 20 }}
                                                                                    onChange={(e) =>
                                                                                        handleChangeName(e, index)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-ui__icons">
                                                                        {nestedSubMenu?.show && (
                                                                            <FormGroup>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                nestedSubMenu?.checked
                                                                                            }
                                                                                            value={
                                                                                                nestedSubMenu?.checked
                                                                                            }
                                                                                            onChange={() =>
                                                                                                handleCheckboxStatus(
                                                                                                    nestedSubMenu,
                                                                                                    index,
                                                                                                )
                                                                                            }
                                                                                            color="primary"
                                                                                            className="menu-ui__checkbox"
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span className="show-label">
                                                                                            {t(EZLOGIC_TITLE_SHOW)}
                                                                                        </span>
                                                                                    }
                                                                                    labelPlacement="end"
                                                                                />
                                                                            </FormGroup>
                                                                        )}

                                                                        <div className="menu-ui__dropdown">
                                                                            {nestedSubMenu?.subItems && (
                                                                                <DropDownUpIcon
                                                                                    className=""
                                                                                    width="15"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        );
                    })}
            </Box>
            <Box>
                <p className="section-top">{t(EZLOGIC_TITLE_PREVIEW)}</p>
                <div className="menu-ui__section-preview">
                    {menu?.logo ? (
                        <div className="menu-ui__logo-container">
                            <img src={URL.createObjectURL(menu.logo)} className="menu-ui__logo" />
                        </div>
                    ) : customizedImage ? (
                        <div className="menu-ui__logo-container">
                            <img src={customizedImage} className="menu-ui__logo" />
                        </div>
                    ) : (
                        <div className="menu-ui__logo-container">
                            <AppLogo className="logo__right-icon" />
                        </div>
                    )}
                    <hr />
                    {updateMenu &&
                        updateMenu.map((menuItem) => {
                            return (
                                <div className="menu-ui__list" key={menuItem?.id}>
                                    <div className={menuItem.checked && 'menu-ui__list-items'}>
                                        {menuItem?.checked && (
                                            <>
                                                <div className="icon">{menuItem?.icon}</div>
                                                <div>{t(menuItem?.title)}</div>
                                            </>
                                        )}
                                    </div>
                                    <div className="menu-ui__list-subitems">
                                        {menuItem?.subItems && (
                                            <>
                                                {menuItem?.subItems.map((subMenu) => {
                                                    return (
                                                        <div className="menu-ui__list" key={subMenu?.id}>
                                                            <div className={subMenu.checked && 'menu-ui__list-items'}>
                                                                <div className="title">
                                                                    {subMenu?.checked ? t(subMenu?.title) : ''}
                                                                </div>
                                                            </div>
                                                            <div className="menu-ui__list-nested-subitems">
                                                                {subMenu?.subItems &&
                                                                    subMenu?.subItems.map((nestedSubMenuItem) => {
                                                                        return (
                                                                            <div className="menu-ui__list">
                                                                                <div
                                                                                    className={
                                                                                        nestedSubMenuItem.checked &&
                                                                                        'menu-ui__list-items'
                                                                                    }
                                                                                >
                                                                                    <div className="title">
                                                                                        {nestedSubMenuItem?.checked &&
                                                                                            t(nestedSubMenuItem?.title)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Box>
        </div>
    );
};
export default MenuUi;
