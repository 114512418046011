import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import InputNumberBlock from '../../../../components/inputNumber';
import SelectBlock from '../../../../components/Select';
import InputAndColorPicker from '../../../../components/ColorInputAndColorPickers';
import DictionaryTypeTextArea from './DictionaryTypeTextArea';
import InputBlockIntegerType from './InputBlockIntegerType';

import {
    BLOCK_FIELD_TYPES,
    VALUES_FOR_BOOLEAN_TYPE,
    METHOD_NAME,
    MIN_NUMBER_INPUT_VALUE,
} from '../../../../constants/MeshbotConstant';
import { validateItemType, validateItemValue } from '../utils';

const TriggerFieldActionBlock = (props) => {
    const { fields, idDevice, _tempId, onUpdateFieldActionBlock, addLabelName, actionBlockName, onAddActionBlock } =
        props;
    const [toggleValue, setToggleValue] = useState('');
    const ezloState = useSelector((state) => state.ezlo);
    const serial = ezloState?.serial;
    const items = serial ? ezloState.data[serial].items : [];

    useEffect(() => {
        if (!validateItemValue(fields)) {
            setToggleValue(METHOD_NAME);
        }
    }, []);

    const detectedBlockFields = (fields) => {
        let type = null;
        fields.forEach((item) => {
            if (item.editable || item.type) {
                type = item?.type;
            }
        });

        return type;
    };

    const getValueField = (fields) => {
        let value = null;
        fields.forEach((item) => {
            if (item.editable) {
                value = item?.value;
            }

            switch (item.type) {
                case BLOCK_FIELD_TYPES.BOOL:
                    value = {
                        value: item.value,
                        labels: !item.enum || item.enum?.length === 0 ? VALUES_FOR_BOOLEAN_TYPE : item.enum,
                    };
                    break;
                case BLOCK_FIELD_TYPES.TOKEN:
                    value = {
                        value: item.value,
                        labels: item.enum,
                    };
                    break;
                default:
                    value = item?.value;
                    break;
            }
        });

        return value;
    };

    const handleUpdateFieldActionBlock = (value, idDevice, idBlock, blockName) => {
        if (value === METHOD_NAME) {
            setToggleValue(value);
        } else {
            setToggleValue('');
            onAddActionBlock(idBlock, idDevice, blockName);
        }
        onUpdateFieldActionBlock(value, idDevice, idBlock, blockName);
    };

    return (
        <>
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.FLOAT && (
                <InputNumberBlock
                    value={getValueField(fields)}
                    onChange={(e) =>
                        handleUpdateFieldActionBlock(Number(e.target.value), idDevice, _tempId, actionBlockName)
                    }
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.INT && (
                <InputBlockIntegerType
                    value={getValueField(fields)}
                    fields={fields}
                    items={items}
                    onUpdateFieldActionBlock={(e) =>
                        handleUpdateFieldActionBlock(Number(e.target.value), idDevice, _tempId, actionBlockName)
                    }
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.TEMPERATURE && (
                <InputNumberBlock
                    min={MIN_NUMBER_INPUT_VALUE}
                    value={getValueField(fields)}
                    step={0.1}
                    onChange={(e) =>
                        handleUpdateFieldActionBlock(
                            e.target.value && Number(e.target.value),
                            idDevice,
                            _tempId,
                            actionBlockName,
                        )
                    }
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.BOOL && (
                <SelectBlock
                    options={getValueField(fields)}
                    onChange={(e) => handleUpdateFieldActionBlock(e.target.value, idDevice, _tempId, actionBlockName)}
                    isValueType={detectedBlockFields(fields) === BLOCK_FIELD_TYPES.BOOL}
                    toggleValue={toggleValue}
                    isItemType={validateItemType(fields)}
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.TOKEN && (
                <SelectBlock
                    type="action"
                    options={getValueField(fields)}
                    onChange={(e) => handleUpdateFieldActionBlock(e.target.value, idDevice, _tempId, actionBlockName)}
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.RGB && (
                <InputAndColorPicker
                    actionBlockName={actionBlockName}
                    _tempId={_tempId}
                    idDevice={idDevice}
                    handleUpdateFieldActionBlock={handleUpdateFieldActionBlock}
                    value={getValueField(fields)}
                />
            )}
            {detectedBlockFields(fields) === BLOCK_FIELD_TYPES.DICTIONARY && (
                <DictionaryTypeTextArea
                    _tempId={_tempId}
                    idDevice={idDevice}
                    actionBlockName={actionBlockName}
                    handleUpdateFieldActionBlock={handleUpdateFieldActionBlock}
                    value={getValueField(fields)}
                />
            )}
            {detectedBlockFields(fields) === 'empty' && <b>{addLabelName('')}</b>}
        </>
    );
};

export default TriggerFieldActionBlock;

TriggerFieldActionBlock.propTypes = {
    _tempId: PropTypes.string,
    idDevice: PropTypes.string,
    fields: PropTypes.array,
    onUpdateFieldActionBlock: PropTypes.func,
    addLabelName: PropTypes.func,
};
