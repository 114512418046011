import React from 'react';
// import {func, object} from 'prop-types';
import './style.scss';

export default class DeviceResetSettings extends React.Component {
    // static propTypes = {
    //     onClick: func.isRequired,
    //     device: object.isRequired,
    // };

    clickHandler = () => {
        const { device, onClick } = this.props;

        onClick({ deviceId: device._id });
    };

    render() {
        return (
            <div className="device-reset-button__box" onClick={this.clickHandler}>
                <div className="device-alarm__block">
                    <div>
                        <h5 className="device-alarm__title">Reset to default settings</h5>
                        <span className="device-alarm__status">All parameters added manually will be deleted.</span>
                    </div>
                </div>
            </div>
        );
    }
}
