import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { systemHistoryActions } from '../../../../../actions/SystemHistoryActions';
import { buildSystemHistoryTableData, filterHistoryEvents, getLastWeek, getParamsForApiCall } from '../../utils';
import { SystemHistoryTableUI } from './SystemHistoryTableUI';
import { buildSystemHistoryTableColumns } from '../../../../../constants/SystemHistory';
import OEMConfigContainer from '../../../../../services/oem/src/OEMConfigContainer/OEMConfigContainer';
import { useTranslate } from '../../../../../features/languages';

export const SystemHistoryTableContainer = () => {
    const dispatch = useDispatch();
    const { isLoading, systemHistoryList, systemHistoryFilter } = useSelector(({ systemHistory }) => systemHistory);
    const { serial } = useSelector((state) => state.ezlo);
    const { t } = useTranslate();
    const translatedHeaderNameColumns = buildSystemHistoryTableColumns().map((colData) => ({
        ...colData,
        headerName: t(colData?.headerName).charAt(0).toUpperCase() + t(colData?.headerName).slice(1),
    }));

    const [columns] = useState(translatedHeaderNameColumns);

    useEffect(() => {
        const dateAfter = getLastWeek(new Date()).getTime();
        const dateBefore = new Date().getTime();

        if (serial) {
            dispatch(
                systemHistoryActions.getHistoryAlerts(
                    `${OEMConfigContainer.buildGetParamsStringWithQuestionMark(
                        getParamsForApiCall({
                            dateBefore,
                            dateAfter,
                            serial,
                        }),
                    )}`,
                    true,
                ),
            );
        }
    }, [serial]);

    const filteredEvents = useMemo(
        () => filterHistoryEvents(buildSystemHistoryTableData(systemHistoryList), systemHistoryFilter),
        [systemHistoryList, systemHistoryFilter],
    );

    return <SystemHistoryTableUI columns={columns} systemHistoryList={filteredEvents} loaderList={isLoading} />;
};
