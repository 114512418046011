import React from 'react';

export const IconSquareOfSquares = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0H0V6H6V0ZM15 0H9V6H15V0ZM18 0H24V6H18V0ZM6 9H0V15H6V9ZM9 9H15V15H9V9ZM24 9H18V15H24V9ZM0 18H6V24H0V18ZM15 18H9V24H15V18ZM18 18H24V24H18V18Z" />
        </svg>
    );
};

export default IconSquareOfSquares;
