import { useState } from 'react';
import { apiUserPasswordResetSet } from '../../../../services/auth';

/**
 * Hook, used to encapsulate logic related to reset password based on Cloud API call (not MMS).
 * */
const useCloudResetPassword = () => {
    // send the request and update the status
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const setNewPassword = async (payload) => {
        const { pk_user, code, password } = payload;
        setError(null);
        setData(null);
        setLoading(true);
        try {
            const response = await apiUserPasswordResetSet({ pk_user, code, password, action: 'set' });
            if (response?.data?.complete === 1 && response?.data.status === 1) {
                setData(response.data.data);
            } else {
                setError(response?.data?.data?.error_text || 'Something went wrong');
            }
        } catch (err) {
            setError(err?.message || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return [data, error, loading, setNewPassword];
};

export default useCloudResetPassword;
