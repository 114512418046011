import React from 'react';
import { Table, TableBody, Typography, TableContainer } from '@material-ui/core';

import {
    EZLOGIC_TITLE_SOURCE_DEVICE,
    EZOGIC_TITLE_CURRENT_ASSOCIATION,
    EZOGIC_TITLE_MAXIMUM_ASSOCIATION,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { getCurrentAssociation, getMaximumAssociationsFromAllGroups } from '../../utils';

import styles from '../DeviceAssociationList.module.scss';

const DeviceAssociateListHeader = ({ sourceDeviceName, settingId, sourceDeviceId, settings, associationsList }) => {
    const { t } = useTranslate();

    const maximumLengthOfDeviceAssociations = getMaximumAssociationsFromAllGroups(sourceDeviceId, settings);

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <div className={styles.headerRow}>
                        <div className={styles.sourceDeviceColumn}>
                            <Typography className={styles.deviceName}>{t(EZLOGIC_TITLE_SOURCE_DEVICE)}:</Typography>
                            <Typography className={styles.deviceSource}>{sourceDeviceName}</Typography>
                        </div>
                        <div className={styles.sourceDeviceColumn}>
                            <Typography className={styles.deviceName}>{t(EZOGIC_TITLE_CURRENT_ASSOCIATION)}</Typography>
                            <Typography className={styles.deviceSource}>
                                {getCurrentAssociation(settingId, associationsList)}
                            </Typography>
                        </div>
                        <div className={styles.sourceDeviceColumn}>
                            <Typography className={styles.deviceName}>{t(EZOGIC_TITLE_MAXIMUM_ASSOCIATION)}</Typography>
                            <Typography className={styles.deviceSource}>{maximumLengthOfDeviceAssociations}</Typography>
                        </div>
                    </div>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DeviceAssociateListHeader;
