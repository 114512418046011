import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as Actions from '../../actions';
import Component from './Component';

const mapStateToProps = (state) => ({
    logged: state.account.logged,
    isConnected: state.ezlo.isConnected,
    connectedEzloSerial: state.ezlo.serial,
    isLogging: state.account.isLogging,
    isCustomizationProgressing: state.account.isCustomizationProgressing,
    permissionRole: state.account.data?.PK_PermissionRole,
    ezloPolicies: state.ezloPolicy,
    router: state.router,
    installerAccessRights: state.installerAccessStore,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps, mergeProps))(Component);
