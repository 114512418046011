import produce from 'immer';

import {
    SET_PATHS,
    SET_PARAMETERS_NAME,
    SET_INTEGRATION_UUID,
    SET_METHOD,
    SET_INTEGRATION_PREVIEW,
    SET_NUCAL_COMPONENT,
    SET_FIELDS,
} from './actions';

export const initialState = {
    paths: [],
    integrationUuid: null,
    method: null,
    integrationPreview: null,
    nucalComponent: null,
    fields: {},
    parameters: {
        name: '',
        params: {},
    },
};

export const virtualDeviceReducer = produce((draft, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_INTEGRATION_UUID:
            draft.integrationUuid = payload.id;
            break;
        case SET_PATHS:
            draft.paths = payload.paths;
            break;
        case SET_PARAMETERS_NAME:
            draft.parameters.name = payload.name;
            break;
        case SET_METHOD:
            draft.method = payload.method;
            break;
        case SET_INTEGRATION_PREVIEW:
            draft.integrationPreview = payload.integrationPreview;
            break;
        case SET_NUCAL_COMPONENT:
            draft.nucalComponent = payload.nucalComponent;
            break;
        case SET_FIELDS:
            draft.fields = payload.fields;
            break;

        default:
            break;
    }
});
