import React, { useEffect, useState } from 'react';

import ExpressionItem from '../ExpressionItem';
import { EXPRESSIONS_TYPE } from '../../../../constants/Expressions';
import { formatDataTypeToString } from '../utils';
import { DATA_TYPES_LIST } from '../../../../constants/Variables';

const ExpressionList = (props) => {
    const { items, getEditingStatus, expressionType } = props;
    const [editingItemsArray, setEditingItemsArray] = useState([]);
    const isExpression = expressionType === EXPRESSIONS_TYPE.EXPRESSION;

    useEffect(() => {
        getEditingStatus(!!editingItemsArray.length);
    }, [editingItemsArray.length]);

    const handleAddEditingItem = (name) => {
        const uniqueValues = new Set([...editingItemsArray, name]);
        setEditingItemsArray(Array.from(uniqueValues));
    };

    const handleDeleteEditingItem = (name) => {
        setEditingItemsArray((prevArray) => prevArray.filter((item) => item !== name));
    };

    return (
        <ul className="expression-item-list">
            {items &&
                items?.map(({ code, name, value, error, params, valueType, metadata }) => {
                    return (
                        <ExpressionItem
                            key={name}
                            code={code}
                            name={name}
                            error={error}
                            initCreatedAt={metadata?.createdAt}
                            value={isExpression ? value : formatDataTypeToString(value)}
                            params={params}
                            onEditStart={handleAddEditingItem}
                            onEditEnd={handleDeleteEditingItem}
                            expressionType={expressionType}
                            valueType={
                                metadata?.scalableType === DATA_TYPES_LIST.TYPE_SCALABLE
                                    ? metadata.scalableType
                                    : valueType
                            }
                            tokenValue={metadata?.enumValue}
                            scalableType={valueType}
                        />
                    );
                })}
        </ul>
    );
};

export default ExpressionList;
