import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { mapTypes } from '../virtual-templates';
import { changeMapType } from '../utils';

import style from './VirtualItem.module.scss';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_TITLE_ADVANCED,
    EZLOGIC_TITLE_MAPTO,
    EZLOGIC_TITLE_STANDARD,
} from '../../../../constants/language_tokens';

export const MapTypeSwitcher = (props) => {
    const { mapType, setVirtualDevice, virtualItemUpdateParams } = props;
    const { t } = useTranslate();
    const handleChangeMapType = (e, mapType) => {
        if (mapType !== null) {
            const updatedVirtualDevice = changeMapType(virtualItemUpdateParams, mapType);

            setVirtualDevice(updatedVirtualDevice);
        }
    };

    return (
        <div className={style.modeSwitch}>
            <ToggleButtonGroup color="primary" onChange={handleChangeMapType} exclusive value={mapType}>
                <ToggleButton value={mapTypes.STANDARD}>{t(EZLOGIC_TITLE_STANDARD)}</ToggleButton>
                <ToggleButton value={mapTypes.ADVANCED}>{t(EZLOGIC_TITLE_ADVANCED)}</ToggleButton>
            </ToggleButtonGroup>

            <p>{t(EZLOGIC_TITLE_MAPTO)}</p>
        </div>
    );
};
