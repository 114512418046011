import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Hidden for task 3085 called "Remove grouping of hubs on UI".
// import Button from '@material-ui/core/Button';

import GroupsSection from './GroupsSection';
import ManageGroupDialog from './ManageGroupDialog';
import ActiveGroupSection from './ActiveGroupSection';
import DeleteGroupDialog from './DeleteGroupDialog/DeleteGroupDialog';
import { Header } from '../../../components';
import EmptyStateIcon from '../EzloHubBackups/icons/EmptyStateIcon';

import { buildControllerGroupSetParams, CLOSE_DIALOG_DELAY } from './utils';
// Hidden for task 3085 called "Remove grouping of hubs on UI".
// import { MANAGE_GROUP_CONTROLLERS_LIMIT } from './constants';

import './style.scss';
import {
    EZLOGIC_TITLE_CONTROLLERS,
    EZLOGIC_TITLE_CONTROLLER_DELETE_MESSAGE,
    // Hidden for task 3085 called "Remove grouping of hubs on UI".
    // EZLOGIC_TITLE_CREATE_NEW_GROUP,
    EZLOGIC_TITLE_NO_CONTROLLER_MESSAGE,
} from '../../../constants/language_tokens';
import { useTranslate } from '../../../features/languages';

const EzloGroups = (props) => {
    const [state, setState] = useState({ isGroupDialogOpen: false, group: null });
    const [deleteDialogState, setDeleteDialogState] = useState({
        isDeleteGroupDialogOpen: false,
        group: null,
        message: '',
        deletingGroupController: null,
    });
    const { t } = useTranslate();

    useEffect(() => {
        const { fetchAccountControllers } = props.redux.actions.GroupsActions;
        // Hidden for task 3085 called "Remove grouping of hubs on UI".
        // fetchControllerGroupList();
        fetchAccountControllers();
    }, []);

    const handleCreateGroup = (payload) => {
        const { updateControllerGroup } = props.redux.actions.GroupsActions;
        updateControllerGroup(payload);
    };

    const handleUpdateGroup = (params) => {
        const { updateAndConnectControllerGroup } = props.redux.actions.GroupsActions;
        updateAndConnectControllerGroup(params);
    };

    const handleDeleteGroup = async (params) => {
        const { updateControllerGroup } = props.redux.actions.GroupsActions;
        updateControllerGroup(params);
        if (deleteDialogState.deletingGroupController !== null) {
            await deleteDialogState.deletingGroupController();
        }
    };

    const handleSelectGroup = (group) => {
        const { selectGroup } = props.redux.actions.EzloGroupActions;
        selectGroup(group);
    };

    const handleOpenGroupDialog = (group) => {
        setState({ isGroupDialogOpen: true, group });
    };

    const handleCloseGroupDialog = () => {
        setState({ isGroupDialogOpen: false, group: null });
    };

    const handleOpenDeleteGroupDialog = (
        group,
        message = t(EZLOGIC_TITLE_CONTROLLER_DELETE_MESSAGE),
        deletingGroupController = null,
    ) => {
        setDeleteDialogState({ isDeleteGroupDialogOpen: true, group, message, deletingGroupController });
    };

    const handleCloseDeleteGroupDialog = () => {
        setDeleteDialogState({
            isDeleteGroupDialogOpen: false,
            group: null,
            message: '',
            deletingGroupController: null,
        });
    };

    const handleConfirmDeleteGroup = () => {
        const uuid = deleteDialogState.group && deleteDialogState.group.uuid;
        if (uuid) {
            const params = buildControllerGroupSetParams(
                [],
                '',
                deleteDialogState.group && deleteDialogState.group.uuid,
            );
            handleDeleteGroup(params);
        }
        setTimeout(() => {
            handleCloseDeleteGroupDialog();
        }, CLOSE_DIALOG_DELAY);
    };

    const { groups, ezlos, selectedGroup, allControllersInfo } = props.redux.state;
    // Hidden for task 3085 called "Remove grouping of hubs on UI".
    // const isCreateGroupAllowed = ezlos && ezlos.length > MANAGE_GROUP_CONTROLLERS_LIMIT;
    const isEzlosQuantityIsZero = ezlos.length == 0;

    return (
        <div className="settings-controllers">
            <Header className="header" headerClassName="settings-controllers__header">
                <h1 className="header__title">{t(EZLOGIC_TITLE_CONTROLLERS)}</h1>
                {/* Hidden for task 3085 called "Remove grouping of hubs on UI". */}
                {/* {isCreateGroupAllowed && (
                    <Button variant="outlined" className="header__btn" onClick={() => handleOpenGroupDialog()}>
                        <span className="icon-container">
                            <PlusIcon />
                        </span>
                        <div className="title-container" title={t(EZLOGIC_TITLE_CREATE_NEW_GROUP)}>
                            {t(EZLOGIC_TITLE_CREATE_NEW_GROUP)}
                        </div>
                    </Button>
                )} */}
            </Header>
            {!isEzlosQuantityIsZero && (
                <section className="ezlo-section-content">
                    <ActiveGroupSection
                        groups={groups}
                        selectedGroup={selectedGroup}
                        onSelectGroup={handleSelectGroup}
                    />
                    <GroupsSection
                        allControllers={allControllersInfo}
                        groups={groups}
                        selectedGroup={selectedGroup}
                        onDelete={handleOpenDeleteGroupDialog}
                        onEdit={handleOpenGroupDialog}
                    />
                </section>
            )}
            <ManageGroupDialog
                open={state.isGroupDialogOpen}
                onClose={handleCloseGroupDialog}
                onCreate={handleCreateGroup}
                onUpdate={handleUpdateGroup}
                onDelete={handleOpenDeleteGroupDialog}
                group={state.group}
                groups={groups}
                ezlos={ezlos}
            />
            <DeleteGroupDialog
                show={deleteDialogState.isDeleteGroupDialogOpen}
                message={deleteDialogState.message}
                onClose={handleCloseDeleteGroupDialog}
                onSubmit={handleConfirmDeleteGroup}
            />
            {isEzlosQuantityIsZero && (
                <div className="empty-meshbots">
                    <EmptyStateIcon className="empty-meshbots__icon" />
                    <span className="empty-meshbots__title">{t(EZLOGIC_TITLE_NO_CONTROLLER_MESSAGE)}</span>
                </div>
            )}
        </div>
    );
};

EzloGroups.propTypes = {
    params: PropTypes.object,
    redux: PropTypes.object,
};

export default EzloGroups;
