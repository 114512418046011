import React, { useMemo } from 'react';
import { FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { TextField } from '../../../../../components';
import InfoButton from '../InfoButton';
import { useTranslate } from '../../../../../features/languages';

import {
    EZLOGIC_HINT_CONTROLLABLE,
    EZLOGIC_HINT_NODE,
    EZLOGIC_TITLE_CONTROLLABLE,
    EZLOGIC_TITLE_DEVICE_NOT_FOUND,
    EZLOGIC_TITLE_NODE,
    EZLOGIC_TITLE_UNASSIGNED,
} from '../../../../../constants/language_tokens';
import { LOCAL } from '../../constant';
import styles from './DeviceBlock.module.scss';
import { determineExistenceOfDevice } from '../../utils';

const DevicesBlock = (props) => {
    const {
        value,
        options = [],
        onChange,
        disableClearable,
        typeMeshbot,
        actionSection,
        label,
        selectedDeviceId,
    } = props;
    const { t } = useTranslate();
    const getLabel = () => {
        if (label) {
            return label;
        }

        if (actionSection && !label) {
            return t(EZLOGIC_TITLE_CONTROLLABLE);
        } else {
            return t(EZLOGIC_TITLE_NODE);
        }
    };
    const isSelectedDeviceExists = useMemo(() => {
        return determineExistenceOfDevice(typeMeshbot, selectedDeviceId, options);
    }, [typeMeshbot, selectedDeviceId, options]);

    const fieldValue = useMemo(() => {
        return !isSelectedDeviceExists && selectedDeviceId ? null : value || null;
    }, [isSelectedDeviceExists, selectedDeviceId, value]);

    const getOptionSelected = (option, value) => {
        return option?.name === value?.name;
    };

    return (
        <div className={styles.wrapper}>
            <FormControl className={styles.select}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => (option?.name ? option.name : '')}
                    value={fieldValue}
                    groupBy={(option) =>
                        option?.roomName && typeMeshbot === LOCAL
                            ? option?.roomName
                            : option?.meta?.parent_room_name || t(EZLOGIC_TITLE_UNASSIGNED)
                    }
                    disableClearable={disableClearable}
                    getOptionSelected={getOptionSelected}
                    onChange={onChange}
                    renderInput={(params) => {
                        const isError = Boolean(
                            (selectedDeviceId || selectedDeviceId === null) && !isSelectedDeviceExists,
                        );

                        return (
                            <TextField
                                label={getLabel()}
                                error={isError}
                                helperText={isError && t(EZLOGIC_TITLE_DEVICE_NOT_FOUND)}
                                {...params}
                                adornment={
                                    !label
                                        ? (params.InputProps.startAdornment = (
                                              <InfoButton>
                                                  {actionSection ? (
                                                      <span
                                                          dangerouslySetInnerHTML={{
                                                              __html: t(EZLOGIC_HINT_CONTROLLABLE),
                                                          }}
                                                      ></span>
                                                  ) : (
                                                      <span
                                                          dangerouslySetInnerHTML={{
                                                              __html: t(EZLOGIC_HINT_NODE),
                                                          }}
                                                      ></span>
                                                  )}
                                              </InfoButton>
                                          ))
                                        : ''
                                }
                            />
                        );
                    }}
                />
            </FormControl>
        </div>
    );
};

export default DevicesBlock;
