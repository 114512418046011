import { toast, TOAST_TYPE } from '../../../../components/Toast';
import { MESHBOT_CONFIG_KVS_KEY, TIMEOUT_OF_SAVING_MESHBOTS_PAGE_CONFIG_IN_KVS, USER_TYPE } from '../constants';
import { debounce, isEmpty } from 'lodash';
import { SUCCESS_STATUS } from '../../../../constants/api';
import {
    buildKvsGetParam,
    uploadMeshbotConfigData,
    generateUpdateMeshBotsPageConfigPayload,
    parseKvsValue,
} from '../utils/utils';
import { getKvs, setKvsWithKeepalive } from '../network/kvsApi';
import { t } from '../../../../helpers/language';
import { EZLOGIC_TITLE_SOMETHING_WENT_WRONG } from '../../../../constants/language_tokens';
import { responseErrorHandling } from '../../../utilityService';

/**
 * Uploads the Mesh Bots page configuration to Key-Value Storage (KVS).
 *
 * @param {object} kvsSetPayload - The payload containing the configuration data to upload.
 */
const uploadMeshbotsPageConfigInKvs = (kvsSetPayload) => {
    const kvsSetParams = uploadMeshbotConfigData(JSON.stringify(kvsSetPayload));
    setKvsWithKeepalive(kvsSetParams).catch((err) => toast(err, { type: TOAST_TYPE.ERROR }));
};

/**
 * Retrieves the Mesh Bots page configuration from Key-Value Storage (KVS).
 *
 * @returns {Promise<object|undefined>} A promise that resolves to the configuration data or undefined if not found.
 */
export const getMeshbotsPageConfigFromKvs = async () => {
    try {
        const response = await getKvs(buildKvsGetParam(MESHBOT_CONFIG_KVS_KEY, USER_TYPE));

        responseErrorHandling(response);

        if (response.data?.status === SUCCESS_STATUS && response.data.data.list) {
            const value = response.data.data.list.find(({ key }) => key === MESHBOT_CONFIG_KVS_KEY)?.value;

            if (value) {
                return parseKvsValue(value);
            }
        }
    } catch (e) {
        const errorMessage = e.message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
        toast(errorMessage, { type: TOAST_TYPE.ERROR });
    }
};
/**
 * Updates the Mesh Bots page configuration and uploads it to KVS.
 *
 * @param {object} meshbotsTableState - The state of the Mesh Bots table.
 * @param {boolean} isSystemMeshBotsChecked - Indicates whether system Mesh Bots are checked.
 */
export const updateMeshbotsPageConfig = (meshbotsTableState, isSystemMeshBotsChecked) => {
    if (isEmpty(meshbotsTableState)) {
        return;
    }
    const payload = generateUpdateMeshBotsPageConfigPayload(meshbotsTableState, isSystemMeshBotsChecked);
    uploadMeshbotsPageConfigInKvs(payload);
};
/**
 * A debounced version of the 'updateMeshbotsPageConfig' function for delayed execution.
 *
 * @param {object} meshbotsTableState - The state of the Mesh Bots table.
 * @param {boolean} isSystemMeshBotsChecked - Indicates whether system Mesh Bots are checked.
 */
export const debounceFnForUpdateMeshbotsPageConfig = debounce(
    updateMeshbotsPageConfig,
    TIMEOUT_OF_SAVING_MESHBOTS_PAGE_CONFIG_IN_KVS,
    { trailing: true },
);
