import {
    ARRAY_FIELD_TYPES,
    TYPE_FIELD_ARRAY,
    TYPE_FIELD_BOOLEAN,
    TYPE_FIELD_ENUM,
    TYPE_FIELD_LIST,
    TYPE_FIELD_STRING,
} from '../../paas-constants';

export const createListEnumSelectOptions = (enumList) => {
    return enumList?.map((enumItem) => {
        return { id: enumItem, name: enumItem };
    });
};

export const createKeyValueEnumSelectOptions = (enumList, enumListKey, enumListValue) => {
    return enumList?.map((enumItem) => {
        return {
            id: enumItem[enumListKey],
            name: enumItem[enumListValue],
        };
    });
};

export const getTypeOfField = (enumListMethod, enumValue, type) => {
    if (type === TYPE_FIELD_ARRAY) {
        return TYPE_FIELD_ARRAY;
    } else if (enumListMethod) {
        return TYPE_FIELD_LIST;
    } else if (enumValue) {
        return TYPE_FIELD_ENUM;
    } else if (type === TYPE_FIELD_BOOLEAN) {
        return TYPE_FIELD_BOOLEAN;
    }

    return TYPE_FIELD_STRING;
};

export const buildSelectOptions = (enumValue) => {
    return (
        enumValue &&
        enumValue.map((value) => {
            const formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replace(/\W|_/g, ' ');

            return {
                id: value,
                label: formattedValue,
            };
        })
    );
};
/**
 * Function for determining the field type of property with type = array.
 * @param {object} property - Object that contains field data {type, enum, description}
 * @returns {String} - return field type
 * @example
 * getTypeOfArrayTypeField(property)
 */
export const getTypeOfArrayTypeField = (property) => {
    const { STRING, NUMBER, ENUM } = ARRAY_FIELD_TYPES;

    if (property.hasOwnProperty(ENUM)) {
        return ENUM;
    }

    if (property.type === NUMBER) {
        return NUMBER;
    }

    return STRING;
};
