import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PUBLISH, CODE, CREATOR } from '../../../../../constants/Plugins';
import PluginsList from '../../components/PluginsList';
import ModalPlugin from '../../components/ModalPlugin';
import { useRequestResult } from '../../customHooks';
import { EZLOGIC_HEADING_MY_PUBLISHED_PLUGIN } from '../../../../../constants/language_tokens';

const PluginsPublish = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);

    const { publicPlugins, loaderList, PluginActions } = props;

    useEffect(() => {
        PluginActions.getPublicPlugins(CODE, CREATOR);
    }, []);

    useRequestResult();

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    return (
        <div className="plugin-edge__list">
            <PluginsList
                type={PUBLISH}
                title={EZLOGIC_HEADING_MY_PUBLISHED_PLUGIN}
                listPlugins={publicPlugins}
                loaderList={loaderList}
                onConfirm={onConfirm}
            />
            <ModalPlugin
                typeList={PUBLISH}
                open={isModal}
                onCancel={hideModal}
                typeModal={typeModal}
                currentAction={currentAction}
                pluginData={pluginData}
                PluginActions={PluginActions}
            />
        </div>
    );
};

PluginsPublish.propTypes = {
    publicPlugins: PropTypes.array,
    loaderList: PropTypes.bool,
    PluginActions: PropTypes.object,
};

export default PluginsPublish;
