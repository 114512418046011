import React from 'react';
import { Button } from '@material-ui/core';
import { PAGINATION_ARROWS } from '../../constants';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { EZLOGIC_BUTTON_NEXT, EZLOGIC_TITLE_PREV } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';
import styles from './styles.module.scss';

const PaginationArrows = ({ prevDisabled, nextDisabled, handlerPageSwitching }) => {
    const { t } = useTranslate();

    return (
        <div>
            <Button
                disabled={prevDisabled}
                color="primary"
                onClick={() => handlerPageSwitching(PAGINATION_ARROWS.PREV)}
            >
                <ArrowBackIos fontSize="large" /> {t(EZLOGIC_TITLE_PREV)}
            </Button>
            <Button
                className={styles.paginationNextArrow}
                disabled={nextDisabled}
                color="primary"
                onClick={() => handlerPageSwitching(PAGINATION_ARROWS.NEXT)}
            >
                {t(EZLOGIC_BUTTON_NEXT)} <ArrowForwardIos fontSize="large" />
            </Button>
        </div>
    );
};

export default PaginationArrows;
