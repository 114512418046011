import React from 'react';
import PropTypes from 'prop-types';

const DeviceItemTemplate = (props) => {
    const { serial, deviceName } = props;

    return (
        <tr>
            <td>{deviceName}</td>
            <td>{serial}</td>
        </tr>
    );
};

DeviceItemTemplate.propTypes = {
    serial: PropTypes.string,
    deviceName: PropTypes.string,
};

export default DeviceItemTemplate;
