import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ControllerTemplate from '../ControllerTemplate';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../../constants/toastConstants';
import toastsActions from '../../../../../actions/toastsActions';
import { INSTALL, REMOVE, STATUSES } from '../../../../../constants/Plugins';
import {
    EZLOGIC_TITLE_MANAGE_PLUGIN_ENDING,
    EZLOGIC_TITLE_MANAGE_PLUGIN_STARTING,
    EZLOGIC_TITLE_NO_CONTROLLERS,
    EZLOGIC_TOAST_CORRECT_DATA_TO_DELETE,
    EZLOGIC_TOAST_PLUGIN_HAS_BEEN_SUCCESSFULLY,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import { PluginActions } from '../../../../../actions';
import style from './style.module.scss';
import { getIntegrationsCompatibleControllers } from '../../../EzloIntegrations/EzloIntegrationPages/EzloIntegrationsCreatePluginIntegration/utils';

const InstallPlugin = (props) => {
    const [currentPlugin, setCurrentPlugin] = useState(null);

    const {
        controllers,
        pluginData,
        listInstalledPlugins,
        onHandlerShowConfigureModal,
        onHandlerShowUpdateModal,
        marketplacePlugins,
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslate();

    useEffect(() => {
        setCurrentPlugin(pluginData);
    }, [pluginData]);

    const [filteredControllers, setFilteredControllers] = useState([]);
    // performance workaround
    useEffect(() => {
        const controllersObj = controllers?.reduce((obj, controller) => {
            obj[controller?.serial] = controller;

            return obj;
        }, {});

        const integrationsCompatibleControllersSerials = getIntegrationsCompatibleControllers(controllersObj);
        const integrationsCompatibleControllers = controllers.filter((c) => {
            return integrationsCompatibleControllersSerials?.includes(c?.serial);
        });

        setFilteredControllers(integrationsCompatibleControllers);
    }, [controllers]);

    const handlerInstallPlugin = useCallback(
        (uuid, serial) => {
            const params = { controller_uuid: uuid, dashboard_uuid: currentPlugin && currentPlugin.id };
            dispatch(PluginActions.subscribeUpdateController(serial, notificationUpdateController(serial, INSTALL)));
            dispatch(PluginActions.deployPlugin(params, serial));
        },
        [currentPlugin],
    );

    const handlerRemovePlugin = useCallback(
        (serial) => {
            if (currentPlugin.row || currentPlugin?.nameId) {
                const nameId = currentPlugin?.row?.nameId || currentPlugin?.nameId;
                dispatch(PluginActions.subscribeUpdateController(serial, notificationUpdateController(serial, REMOVE)));
                dispatch(PluginActions.removePlugin(serial, nameId));
            } else {
                dispatch(
                    toastsActions.showToast({
                        message: t(EZLOGIC_TOAST_CORRECT_DATA_TO_DELETE),
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
            }
        },
        [currentPlugin],
    );

    const notificationUpdateController = (serial, type) => {
        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                toast(result.error.message, { type: TOAST_TYPE.ERROR });
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }

            if (result.status === STATUSES.FINISHED) {
                dispatch(
                    toastsActions.showToast({
                        message: `${t(EZLOGIC_TOAST_PLUGIN_HAS_BEEN_SUCCESSFULLY)} ${
                            type === REMOVE ? 'removed' : 'installed'
                        }`,
                        options: SUCCESS_OPTIONS,
                    }),
                );
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }
        };
    };

    return (
        <div className="modal-plugin__install">
            <p className="modal-plugin__install-title">
                {t(EZLOGIC_TITLE_MANAGE_PLUGIN_STARTING) +
                    `${(currentPlugin && currentPlugin?.row?.name) || currentPlugin?.name}` +
                    t(EZLOGIC_TITLE_MANAGE_PLUGIN_ENDING)}
            </p>
            <ul className="modal-plugin__install-list">
                {filteredControllers?.length ? (
                    filteredControllers.map((item) => {
                        return (
                            <ControllerTemplate
                                key={item.serial}
                                serial={item.serial}
                                uuid={item.uuid}
                                isConnected={item.isConnected}
                                listInstalledPlugins={listInstalledPlugins}
                                currentPlugin={currentPlugin}
                                onHandlerInstallPlugin={handlerInstallPlugin}
                                onHandlerRemovePlugin={handlerRemovePlugin}
                                onHandlerShowConfigureModal={onHandlerShowConfigureModal}
                                onHandlerShowUpdateModal={onHandlerShowUpdateModal}
                                marketplacePlugins={marketplacePlugins}
                            />
                        );
                    })
                ) : (
                    <li className={style.noControllers}>{t(EZLOGIC_TITLE_NO_CONTROLLERS)}</li>
                )}
            </ul>
        </div>
    );
};

InstallPlugin.propTypes = {
    controllers: PropTypes.array,
    pluginData: PropTypes.object,
    listInstalledPlugins: PropTypes.object,
    PluginActions: PropTypes.object,
    onHandlerShowConfigureModal: PropTypes.func,
};

export default InstallPlugin;
