import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TreeItem } from '@material-ui/lab';

import DeviceGroupCategoryLabelContainer from '../../../DeviceGroupCategoryLabelContainer';
import DeviceGroupsActions from '../../../../../../../actions/DeviceGroupsActions';
import { getTreeElementsStateDeviceOptions, getMenuButtonState } from '../../../../utils';
import { DEVICE_GROUP_DEVICE_KEYS } from '../../../../../../../constants/DevicesGroups';

const DeviceGroupDevice = (props) => {
    const { payload, parentChainIds } = props;
    const dispatch = useDispatch();
    const categoryState = useSelector(
        (state) => state.deviceGroups.deviceGroupTreeState?.categories[parentChainIds.categoryId]?._ui.state,
    );
    const subcategoryState = useSelector(
        (state) =>
            state.deviceGroups.deviceGroupTreeState?.categories[parentChainIds.categoryId].subcategories[
                parentChainIds.subcategoryId
            ]?._ui.state,
    );
    const buttonState = getMenuButtonState(payload._ui.state);

    const handleChangeDeviceState = (newDeviceState) => {
        dispatch(
            DeviceGroupsActions.updateDeviceGroupTreeStateByDevice({
                newDeviceState,
                deviceId: payload.id,
                categoryId: parentChainIds.categoryId,
                subcategoryId: parentChainIds.subcategoryId,
            }),
        );
    };

    return (
        <TreeItem
            label={
                <DeviceGroupCategoryLabelContainer
                    value={payload?._ui?.state}
                    labelName={payload?.device_name}
                    onChange={handleChangeDeviceState}
                    options={getTreeElementsStateDeviceOptions(categoryState, subcategoryState)}
                    buttonState={buttonState}
                    {...{ [DEVICE_GROUP_DEVICE_KEYS.UI.KEY]: payload?._ui.type }}
                />
            }
            nodeId={payload?.device_name}
        ></TreeItem>
    );
};

export default DeviceGroupDevice;
