import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import DynamicFieldsBlockForActionBlock from '../DynamicFieldsBlockForActionBlock/DynamicFieldsBlockForActionBlock';
import TriggerFieldActionContainer from '../TriggerFieldActionContainer';

import {
    getInterfaceDataForSelectedBlock,
    getInterfaceJSONData,
    getInterfaceJSONItems,
    getNode,
} from '../DynamicFieldsBlockForActionBlock/utils';
import { MeshBotAction } from '../../../../../actions';

const BlockOfFieldsForDeviceAction = (props) => {
    const {
        id,
        idDev,
        type,
        blocks,
        nameDevice,
        selectedFieldTrigger,
        firstBlock,
        currentRule,
        actionBlockName,
        isShowedDelay,
        onUpdateFieldActionBlock,
        onAddActionBlock,
        onUpdateActionDelay,
        onChangeDelay,
        onSetIsShowedDelay,
    } = props;
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const plugins = state.plugins?.privatePlugins;
    const controllerData = state.ezlo?.data[state.ezlo.serial];

    const node = useMemo(() => {
        return getNode(controllerData, idDev);
    }, [controllerData, idDev]);

    const interfaceJSONData = useMemo(() => {
        return getInterfaceJSONData(plugins, node);
    }, [plugins, node]);

    const interfaceJSONItems = useMemo(() => {
        return getInterfaceJSONItems(interfaceJSONData);
    }, [interfaceJSONData]);

    useEffect(() => {
        if (interfaceJSONItems && interfaceJSONItems.length > 0) {
            dispatch(MeshBotAction.updateInterfaceJSONItems(interfaceJSONItems));
        }
    }, [interfaceJSONItems]);

    const interfaceItem = useMemo(() => {
        return getInterfaceDataForSelectedBlock(interfaceJSONItems, firstBlock, blocks);
    }, [interfaceJSONItems, firstBlock, blocks]);

    if (interfaceItem) {
        return (
            <DynamicFieldsBlockForActionBlock
                selectedFieldTrigger={selectedFieldTrigger}
                firstBlock={firstBlock}
                type={type}
                blocks={blocks}
                actionBlockName={actionBlockName}
                id={id}
                onUpdateFieldActionBlock={onUpdateFieldActionBlock}
                onUpdateActionDelay={onUpdateActionDelay}
                currentRule={currentRule}
                interfaceItem={interfaceItem}
                isShowedDelay={isShowedDelay}
                onChangeDelay={onChangeDelay}
                onSetIsShowedDelay={onSetIsShowedDelay}
            />
        );
    }

    return (
        <TriggerFieldActionContainer
            id={id}
            type={type}
            blocks={blocks}
            currentRule={currentRule}
            nameDevice={nameDevice}
            actionBlockName={actionBlockName}
            firstBlock={firstBlock}
            isShowedDelay={isShowedDelay}
            onUpdateFieldActionBlock={onUpdateFieldActionBlock}
            onAddActionBlock={onAddActionBlock}
            onUpdateActionDelay={onUpdateActionDelay}
            onChangeDelay={onChangeDelay}
            onSetIsShowedDelay={onSetIsShowedDelay}
        />
    );
};

BlockOfFieldsForDeviceAction.proptypes = {
    id: PropTypes.string,
    idDev: PropTypes.string,
    type: PropTypes.string,
    blocks: PropTypes.array,
    nameDevice: PropTypes.string,
    selectedFieldTrigger: PropTypes.string,
    firstBlock: PropTypes.string,
    currentRule: PropTypes.object,
    actionBlockName: PropTypes.string,
    isShowedDelay: PropTypes.string,
    onUpdateFieldActionBlock: PropTypes.func,
    onAddActionBlock: PropTypes.func,
    onUpdateActionDelay: PropTypes.func,
    onChangeDelay: PropTypes.func,
    onSetIsShowedDelay: PropTypes.func,
};
export default BlockOfFieldsForDeviceAction;
