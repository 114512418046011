import React from 'react';
import PropTypes from 'prop-types';
import hash from '../../../../constants/uniqueHash';
import TriggerFieldBlock from './triggerFieldBlock';
import TriggerFieldBlockCloud from './triggerFieldBlockCloud';
import at from '../../../../constants/ActionTypes/MeshBot';

const TriggerFields = (props) => {
    const {
        id,
        blocks,
        type,
        idDev,
        idGroup,
        firstBlock,
        nameDevice,
        nameBlock,
        deviceBlocks,
        currentExpression,
        listCapabilities,
        setChangeId,
        onGetNameCapability,
        onUpdateCloudTriggerBlock,
        handlerUpdateTriggerBlock,
        onUpdateFieldBlock,
    } = props;

    const addLabelName = (data) => {
        return data.replace(/_DEVICE_NAME_/gi, nameDevice);
    };

    return (
        <div className="trigger-block__body-field">
            {blocks?.map((item) => {
                if (type !== at.CLOUD && item.blockId === firstBlock) {
                    return (
                        <React.Fragment key={setChangeId(item)}>
                            <TriggerFieldBlock
                                {...item}
                                block={item}
                                idGroup={idGroup}
                                idDev={idDev}
                                idTrigger={id}
                                deviceBlocks={deviceBlocks}
                                currentExpression={currentExpression}
                                addLabelName={addLabelName}
                                onUpdateFieldBlock={onUpdateFieldBlock}
                                handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                            />
                        </React.Fragment>
                    );
                }

                if (type === at.CLOUD && onGetNameCapability(item) === nameBlock) {
                    return (
                        <React.Fragment key={hash()}>
                            <TriggerFieldBlockCloud
                                idBlock={id}
                                idGroup={idGroup}
                                nameBlock={nameBlock}
                                parameters={item.parameters}
                                listCapabilities={listCapabilities}
                                onUpdateCloudTriggerBlock={onUpdateCloudTriggerBlock}
                            />
                        </React.Fragment>
                    );
                }
            })}
        </div>
    );
};

export default TriggerFields;

TriggerFields.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    idGroup: PropTypes.string,
    firstBlock: PropTypes.string,
    nameDevice: PropTypes.string,
    nameBlock: PropTypes.string,
    blocks: PropTypes.array,
    listCapabilities: PropTypes.array,
    setChangeId: PropTypes.func,
    onGetNameCapability: PropTypes.func,
    onUpdateCloudTriggerBlock: PropTypes.func,
    handlerUpdateTriggerBlock: PropTypes.func,
    onUpdateFieldBlock: PropTypes.func,
};
