import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import TemplateForm from '../components/TemplateForm';

import { createRequestBody } from '../utils';

import * as notification from '../../../../constants/NotificationTemplates';
import * as url from '../../../../constants/URLs';
import { SYSTEM } from '../../../../constants/Notifications';
import { MESHBOT } from '../../EzloCustomization/constants';
import { AUTO_CREATE_ACTIVE } from '../../../../constants/URLs';

// TODO: avoid copy/paste, apply refactoring together with TemplateEdit
const TemplateEdit = (props) => {
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const {
        templateName,
        ruleCloudTriggers,
        ruleCloudAction,
        notifications,
        NotificationActions,
        selectedNotificationCapability,
        notificationType,
    } = props;
    const { id } = useParams();
    const history = useHistory();
    const parentTriggerOptionType = useSelector((state) => state.meshBot.cloud.parentTriggerOptionType);
    const isSystemNotificationTemplate = notificationType === SYSTEM;

    useEffect(() => {
        setCurrentTemplate(notifications.find((notification) => notification.uuid === id));
    }, [notifications]);

    const handleUpdateTemplate = useCallback(() => {
        const params = createRequestBody({
            typeAction: notification.UPDATE,
            name: templateName,
            optionType: parentTriggerOptionType,
            triggers: ruleCloudTriggers,
            actions: ruleCloudAction,
            uuid: id,
            id: currentTemplate.id,
            capability: selectedNotificationCapability,
            type: isSystemNotificationTemplate ? SYSTEM : MESHBOT,
            auto_create: AUTO_CREATE_ACTIVE,
        });

        NotificationActions.setNotificationTemplate(params);
        history.push(url.NOTIFICATIONS);
    }, [templateName, ruleCloudTriggers, ruleCloudAction, selectedNotificationCapability, parentTriggerOptionType]);

    return (
        <>
            <TemplateForm
                onSubmit={handleUpdateTemplate}
                uuid={id}
                currentTemplate={currentTemplate}
                selectedNotificationCapability={selectedNotificationCapability}
            />
        </>
    );
};

export default TemplateEdit;
