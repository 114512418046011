import React from 'react';
import InfoIcon from '../../icons/InfoIcon';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {
    EZLOGIC_BUTTON_START_CREATING_BACKUP,
    EZLOGIC_TITLE_DO_NOT_USE_DEVICES,
    EZLOGIC_TITLE_TO_AVOID_ERROR_MESSAGE,
} from '../../../../../constants/language_tokens';
import './style.scss';

const CreateBackupDialog = ({ onCreateBackup, t }) => {
    return (
        <div className="main-content-dialog">
            <InfoIcon color="primary" className="icon-dialog" />
            <span className={classNames('text', 'title-dialog')}>{t(EZLOGIC_TITLE_DO_NOT_USE_DEVICES)}</span>
            <span className={classNames('text', 'description')}>{t(EZLOGIC_TITLE_TO_AVOID_ERROR_MESSAGE)}</span>
            <div className="main-content-footer-dialog">
                <Button color="primary" variant="contained" className="primary-btn" onClick={onCreateBackup}>
                    {t(EZLOGIC_BUTTON_START_CREATING_BACKUP)}
                </Button>
            </div>
        </div>
    );
};

export default CreateBackupDialog;
