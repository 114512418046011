import * as types from '../constants/ActionTypes/VirtualDeviceManager';
import { LOGOUT } from '../constants/ActionTypes';
import produce from 'immer';
import { LOCATION_CHANGE } from 'react-router-redux';
import { ABSTRACT_SOURCES, VIRTUAL_DEVICE_PAGES } from '../containers/Ezlo/EzloVirtualDeviceManager/constants';

// vdm - virtual device manager
const INITIAL_STATE = {
    token: null, // cloud auth token to fetch 'virtual_device_list'
    virtualDeviceList: {}, // 'virtual_devices' object from 'virtual_device_list' response,
    isVirtualDeviceListLoading: true,
    fetchVirtualDeviceListError: null,
    virtualDeviceUUIDToDelete: null,
    virtualDeviceUUIDToDeleteError: null,
    abstracts: null, // 'abstracts' array from 'abstract_list_detailed' response
    isAbstractsLoading: false,
    abstractsError: null,
    devices: null, // abstract with source = 'controller'
    services: null, // abstract with source = 'integration'
    selectedVirtualDevice: null,
    textCommandOutputsMap: new Map(),
    virtualDevice: {},
    savedVirtualDevice: {},
    isInstallPlugin: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.VDM_FETCH_VIRTUAL_DEVICE_LIST: {
            draft.isVirtualDeviceListLoading = true;
            draft.virtualDeviceList = {};
            draft.fetchVirtualDeviceListError = null;
            break;
        }
        case types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_SUCCESS: {
            draft.isVirtualDeviceListLoading = true;
            draft.virtualDeviceList = action.virtualDeviceList;
            break;
        }
        case types.VDM_FETCH_VIRTUAL_DEVICE_LIST_ON_ERROR: {
            draft.isVirtualDeviceListLoading = false;
            draft.virtualDeviceList = {};
            draft.fetchVirtualDeviceListError = action.error;
            break;
        }
        case types.VDM_SET_TOKEN: {
            draft.token = action.token;
            break;
        }
        case types.VDM_DELETE_VIRTUAL_DEVICE: {
            draft.virtualDeviceUUIDToDelete = action.uuid;
            draft.virtualDeviceUUIDToDeleteError = null;
            break;
        }
        case types.VDM_DELETE_VIRTUAL_DEVICE_ON_SUCCESS: {
            draft.virtualDeviceUUIDToDelete = null;
            delete draft.virtualDeviceList[action.uuid];
            break;
        }
        case types.VDM_DELETE_VIRTUAL_DEVICE_ON_ERROR: {
            draft.virtualDeviceUUIDToDelete = null;
            draft.virtualDeviceList = action.error;
            break;
        }
        case types.VDM_EDIT_VIRTUAL_DEVICE_ON_SUCCESS: {
            draft.selectedVirtualDevice = null;
            break;
        }
        case types.VDM_FETCH_ABSTRACT_LIST_DETAILED: {
            draft.isAbstractsLoading = true;
            draft.abstracts = null;
            draft.devices = null;
            draft.services = null;
            draft.abstractsError = null;
            break;
        }
        case types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS: {
            draft.isAbstractsLoading = false;
            draft.abstracts = action.abstracts;
            draft.devices =
                action.abstracts &&
                action.abstracts.filter(
                    (abstract) => abstract.details && abstract.details.source === ABSTRACT_SOURCES.CONTROLLER,
                );
            draft.services =
                action.abstracts &&
                action.abstracts.filter(
                    (abstract) => abstract.details && abstract.details.source === ABSTRACT_SOURCES.INTEGRATION,
                );
            break;
        }
        case types.VDM_FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR: {
            draft.isAbstractsLoading = false;
            draft.abstracts = null;
            draft.devices = null;
            draft.services = null;
            draft.abstractsError = action.error;
            break;
        }
        case types.VDM_SELECT_VIRTUAL_DEVICE: {
            draft.selectedVirtualDevice = action.selectedVirtualDevice;
            break;
        }
        case types.VDM_SEND_TEXT_COMMAND: {
            const { uuid } = action.payload;
            draft.textCommandOutputsMap.set(uuid, '');
            break;
        }
        case types.VDM_SEND_TEXT_COMMAND_ON_SUCCESS: {
            const { uuid, output } = action.payload;
            draft.textCommandOutputsMap.set(uuid, output);
            break;
        }
        case types.VDM_SEND_TEXT_COMMAND_ON_ERROR: {
            const { uuid, error } = action.payload;
            draft.textCommandOutputsMap.set(uuid, error);
            break;
        }
        case LOCATION_CHANGE: {
            if (action.pathname && !action.pathname.includes(VIRTUAL_DEVICE_PAGES.INDEX)) {
                draft.virtualDeviceList = {};
                draft.abstracts = null;
                draft.services = null;
                draft.devices = null;
                draft.token = null;
                draft.virtualDeviceUUIDToDelete = null;
                draft.textCommandOutputsMap = new Map();
                draft.selectedVirtualDevice = null;
            }
            break;
        }
        case types.SET_VIRTUAL_DEVICE: {
            draft.virtualDevice = action.virtualDevice;

            break;
        }
        case types.SET_SAVED_VIRTUAL_DEVICE: {
            draft.savedVirtualDevice = action.virtualDevice;

            break;
        }
        case types.SET_IS_INSTALL_PLUGIN: {
            draft.isInstallPlugin = action.isInstallPlugin;

            break;
        }
        case LOGOUT: {
            return INITIAL_STATE;
            break;
        }
    }
}, INITIAL_STATE);
