import React from 'react';

import style from '../DynamicField.module.scss';

export const FieldDescription = (props) => {
    const { description, isRequired } = props;

    return (
        <p>
            {description}
            {isRequired && <span className={style.fieldDescription}>*</span>}
        </p>
    );
};
