import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { REJECTED, SUCCESS } from '../../../../constants/Plugins';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../constants/toastConstants';
import toastsActions from '../../../../actions/toastsActions';
import PluginActions from '../../../../actions/PluginActions';

export const useRequestResult = () => {
    const { isRequestStatus, isRequestStatusMessage } = useSelector((state) => state.plugins);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isRequestStatus === SUCCESS) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: SUCCESS_OPTIONS,
                }),
            );
        }

        if (isRequestStatus === REJECTED) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    isSave: true,
                }),
            );
        }

        dispatch(PluginActions.clearRequestStatus());
    }, [isRequestStatus]);
};
