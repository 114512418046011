import React, { memo, useMemo } from 'react';

import VariablesSelect from './components/VariablesSelect';
import useIntegrationAndAbstractState from '../../hooks/useIntegrationAndAbstractState';
import {
    generateCloudVariablesListForSelectWithFilter,
    getIntegrationIcon,
    getOptionOfSelectedVariable,
} from './utils';

import CurrentValue from './components/CurrentValue';
import useAbstractState from '../../hooks/useAbstractState';

/**
 * Container component. This component retrieves the list of variables, the selected variable value, the icon for the
 * variable and provides this data to the presentation component VariablesSelect.
 */
const CloudVariableSelectContainer = (props) => {
    const { updateSelectValue, selectedVariable, selectedAbstract, currentValueData, filterType, calledFrom } = props;
    const { abstractsList, integrationList } = useIntegrationAndAbstractState();
    const abstracts = abstractsList?.abstracts;
    const integrations = integrationList?.integrations;
    const variablesInfo = useAbstractState();

    const integrationIcon = getIntegrationIcon(selectedAbstract, integrations, abstracts);

    const options = useMemo(() => {
        return generateCloudVariablesListForSelectWithFilter(abstracts, integrations, filterType, variablesInfo);
    }, [abstracts, integrations, filterType, currentValueData]);

    const variableValue = useMemo(() => {
        return getOptionOfSelectedVariable(options, {
            selectedAbstract,
            selectedVariableName: selectedVariable,
        });
    }, [options, selectedAbstract, selectedVariable]);

    return (
        <VariablesSelect
            updateSelectValue={updateSelectValue}
            variableValue={variableValue}
            options={options}
            integrationIcon={integrationIcon}
            CurrentValueJsx={<CurrentValue currentValueData={currentValueData} calledFrom={calledFrom} />}
        />
    );
};

export default memo(CloudVariableSelectContainer);
