import React, { useRef } from 'react';

import InfoButton from '../../containers/Ezlo/EzloMeshbot/components/InfoButton';

import { getIntegrationCardClasses } from './utils';

import { integrationCardWrapper } from '../../containers/Ezlo/EzloIntegrations/EzloIntegrationsNewIntegrationsContainer/style.module.scss';
import EzloIntegrationsItem from '../../containers/Ezlo/EzloIntegrations/EzloIntegrationsItem';
import IntegrationContent from './IntegrationContent';
import styles from './IntegrationCard.module.scss';

export const INTEGRATION_CARD_SIZES = {
    large: 'large',
    small: 'small',
};

export const IntegrationCard = (props) => {
    const { name, size, icon, capabilities, description, handleParjectClick, isIntegrations } = props;
    const integrationRef = useRef(null);

    const { integrationContainerClasses } = getIntegrationCardClasses(size);

    return (
        <>
            {isIntegrations ? (
                <EzloIntegrationsItem integration={props} onClickInformationPage={handleParjectClick} />
            ) : (
                <div
                    ref={integrationRef}
                    className={isIntegrations ? integrationCardWrapper : integrationContainerClasses}
                    onClick={handleParjectClick}
                >
                    <IntegrationContent icon={icon} name={name} capabilities={capabilities} />

                    <div className={styles.help_icon}>
                        <InfoButton>{description}</InfoButton>
                    </div>
                </div>
            )}
        </>
    );
};
