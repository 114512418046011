import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { t } from '../../../../../helpers/language';
import { Button, ConfirmDialog } from '../../../../../components';
import DeviceConfigurator from '../DeviceConfigurator';
import DeviceResetSettings from '../DeviceResetSettings';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import { removeInfoText } from '../../utils';
import './style.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_ADD,
    EZLOGIC_BUTTON_ADD_NEW_PARAMETER,
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_HEADING_GENERAL,
    EZLOGIC_HEADING_LIST_OF_PARAMETERS,
    EZLOGIC_HEADING_NEW_Z_WAVE_PARAMETER,
    EZLOGIC_TITLE_BACK,
    EZLOGIC_TITLE_CHANGE_WILL_BE_APPLIED_MESSAGE,
    EZLOGIC_TITLE_CHECK_DEVICE_MANUAL,
    EZLOGIC_TITLE_DATA_SIZE,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_DESIRED_VALUE,
    EZLOGIC_TITLE_DISPLAY_FORMAT,
    EZLOGIC_TITLE_PARAMETER_ID,
    EZLOGIC_TITLE_PARAMETER_NAME,
    EZLOGIC_TITLE_RESET_YOUR_DEVICE_CONFIRM_MESSAGE,
    EZLOGIC_TITLE_Z_WAVE_ALLOW_MESSAGE,
} from '../../../../../constants/language_tokens';
import { useDispatch } from 'react-redux';
import { DeviceActions } from '../../../../../actions';

const DeviceCustomSettings = (props) => {
    const [showConfigurator, setShowConfigurator] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogText, setConfirmDialogText] = useState('');
    const [itemKey, setItemKey] = useState('');
    const [configuratorData, setConfiguratorData] = useState('');
    const [currentItem, setCurrentItem] = useState('');
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const { device, settings, onCancel } = props;
    const titles = {
        new_z_wave_parameter: t(EZLOGIC_HEADING_NEW_Z_WAVE_PARAMETER),
        check_device_manual: t(EZLOGIC_TITLE_CHECK_DEVICE_MANUAL),
        display_format: t(EZLOGIC_TITLE_DISPLAY_FORMAT),
        data_size: t(EZLOGIC_TITLE_DATA_SIZE),
        change_will_be_applied: t(EZLOGIC_TITLE_CHANGE_WILL_BE_APPLIED_MESSAGE),
        back: t(EZLOGIC_TITLE_BACK),
        add: t(EZLOGIC_BUTTON_ADD),
        edit: t(EZLOGIC_BUTTON_EDIT),
        parameter_name: t(EZLOGIC_TITLE_PARAMETER_NAME),
        parameter_id: t(EZLOGIC_TITLE_PARAMETER_ID),
        description: t(EZLOGIC_TITLE_DESCRIPTION),
        desired_value: t(EZLOGIC_TITLE_DESIRED_VALUE),
    };
    const onConfirm = () => {
        if (typeof currentItem === 'string') {
            onDeleteSetting(currentItem);
        } else {
            onResetDeviceSettings(currentItem);
        }
    };

    const clickHandler = () => setShowConfigurator(true);

    const onEditSetting = (item, key) => {
        setItemKey(key);
        setConfiguratorData({ ...item });
        setShowConfigurator(true);
    };

    const openRemoveConfirmDialog = (item) => {
        setShowConfirmDialog(true);
        setConfirmDialogText(t`ezlo.generic.are_you_sure_remove`);
        setCurrentItem(item);
    };

    const onDeleteSetting = (item) => {
        const data = { _id: settings._id, key: item };

        dispatch(DeviceActions.updateDeviceDictionarySettings(device.serial, data));
        closeConfirmDialog();
    };

    const openRemoveAllConfirmDialog = (params) => {
        setShowConfirmDialog(true);
        setConfirmDialogText(t(EZLOGIC_TITLE_RESET_YOUR_DEVICE_CONFIRM_MESSAGE));
        setCurrentItem(params);
    };

    const onResetDeviceSettings = (param) => {
        dispatch(DeviceActions.resetDeviceSettings(device.serial, param));
        closeConfirmDialog();
    };

    const closeConfirmDialog = () => {
        setShowConfirmDialog(false);
        setConfirmDialogText('');
    };

    const updateDeviceDictionarySettings = (id, value) => {
        if (configuratorData) {
            const data = { _id: id, value: value, key: itemKey };
            dispatch(DeviceActions.updateDeviceDictionarySettings(device.serial, data));
        } else {
            const data = { _id: id, value: value, key: new Date().getTime() };
            dispatch(DeviceActions.updateDeviceDictionarySettings(device.serial, data));
        }
        onCancel();
    };

    const getBack = () => {
        setShowConfigurator(false);
        setConfiguratorData('');
        setItemKey('');
    };

    const getCustomSettings = () => {
        const hasCustomSettings = settings.value && Object.keys(settings.value).length > 0;

        return (
            <div>
                <h2 className="device-info__title">{t(EZLOGIC_HEADING_GENERAL)}</h2>
                <DeviceResetSettings device={device} settings={settings} onClick={openRemoveAllConfirmDialog} />
                <h2 className="device-info__title">{t(EZLOGIC_HEADING_LIST_OF_PARAMETERS)}</h2>
                <p className="device-alarm__text">{t(EZLOGIC_TITLE_Z_WAVE_ALLOW_MESSAGE)}</p>

                {hasCustomSettings &&
                    Object.keys(settings.value).map((item, key) => {
                        const itemSetting = settings.value[item];

                        return (
                            <div key={key} className="device-custom-settings">
                                <div>
                                    <div className="device-custom-setting">
                                        <h5 className="device-custom-setting-text">{itemSetting.number}</h5>
                                        <h5 className="device-custom-setting-text">{itemSetting.name}</h5>
                                    </div>
                                    <div className="device-custom-setting-status">{itemSetting.status}</div>
                                </div>
                                <div className="device-custom-setting">
                                    <div
                                        className="device-custom-setting-icon-box"
                                        onClick={() => onEditSetting(itemSetting, item)}
                                    >
                                        <EditIcon className="device-custom-setting-icon" titleAccess={'Edit'} />
                                    </div>
                                    <div
                                        className="device-custom-setting-icon-box"
                                        onClick={() => openRemoveConfirmDialog(item)}
                                    >
                                        <DeleteIcon className="device-custom-setting-icon" titleAccess={'Delete'} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                <div className="device-range-footer">
                    <Button className="btn-rounded device-range__button-save" onClick={clickHandler}>
                        {t(EZLOGIC_BUTTON_ADD_NEW_PARAMETER)}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="device-alarm__wrapper">
            {showConfigurator ? (
                <DeviceConfigurator
                    device={device}
                    settings={settings}
                    onSave={updateDeviceDictionarySettings}
                    onGetBack={getBack}
                    configuratorData={configuratorData}
                    itemKey={itemKey}
                    titles={titles}
                />
            ) : (
                getCustomSettings()
            )}
            <ConfirmDialog open={showConfirmDialog} onHide={closeConfirmDialog} onConfirm={onConfirm}>
                {confirmDialogText}
            </ConfirmDialog>
        </div>
    );
};

export default DeviceCustomSettings;

DeviceCustomSettings.propTypes = {
    device: PropTypes.object,
    settings: PropTypes.object,
    onCancel: PropTypes.func,
};
