import React, { useEffect } from 'react';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';

import * as meshBot from '../../constants/MeshbotConstant';

import { calculateNewValue } from './utils';

import { component, arrows, drop_down, disabled_button } from './InputNumberArrows.module.scss';
import { DropDownUpIcon } from '../../assets/icons';

const InputNumberArrows = (props) => {
    const { onChange, value, max, min, disabled, name, step = 1 } = props;
    const stepTimeoutRef = React.useRef();

    // We increase or decrease the value by the step value
    const onStep = (up, inputValue) => {
        const newValue = calculateNewValue(inputValue, up, max, min, step);
        onChange(templateValueForInput(newValue));

        return newValue;
    };

    // We will interval update step when hold mouse down
    const onStepMouseDown = (e, up) => {
        e.preventDefault();
        // Loop step for interval
        function loopStep(value) {
            const newValue = onStep(up, value);
            stepTimeoutRef.current = setTimeout(() => loopStep(newValue), meshBot.INPUT_NUMBER_STEP_INTERVAL);
        }

        const newValue = onStep(up, value);
        // First time press will wait some time to trigger loop step update
        stepTimeoutRef.current = setTimeout(() => loopStep(newValue), meshBot.INPUT_NUMBER_STEP_DELAY);
    };

    const onStopStep = () => {
        clearTimeout(stepTimeoutRef.current);
    };

    useEffect(() => onStopStep, []);

    const templateValueForInput = (newValue) => {
        return {
            target: {
                value: newValue,
                name,
                min,
                max,
            },
        };
    };

    const sharedHandlerProps = {
        onMouseUp: onStopStep,
        onMouseLeave: onStopStep,
    };

    return (
        <div className={component}>
            <div
                className={classNames(arrows, {
                    [disabled_button]: disabled,
                })}
            >
                <IconButton
                    {...sharedHandlerProps}
                    onMouseDown={(e) => {
                        onStepMouseDown(e, true);
                    }}
                    disabled={disabled}
                >
                    <DropDownUpIcon />
                </IconButton>
                <IconButton
                    {...sharedHandlerProps}
                    onMouseDown={(e) => {
                        onStepMouseDown(e, false);
                    }}
                    className={drop_down}
                    disabled={disabled}
                >
                    <DropDownUpIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default InputNumberArrows;
