import React, { useEffect, useState } from 'react';

import { useTranslate } from '../../../features/languages';

import '../style.scss';
import { FORGOT_PASSWORD_PAGE_DESCRIPTION, PARTNER_DOMAIN_PAGE_TITLE } from '../../../constants/language_tokens';
import { LanguageSelector } from '../../../features/languages/components';
import { languageService } from '../../../services/language';
import { updateLang } from '../../../features/languages/utils';
import { AppLogo } from '../../../assets/icons';

// TODO: let's not put ALL the logic inside the single container.
// each component should be responsible to their part.
// Container could define common things like layout, header (logo).
// All the custom content and logic should be encapsulated in the children components
const Container = ({ isForgotPassword, title, children, staticForm, isLogin }) => {
    const { t } = useTranslate();
    const language = languageService.getLanguageKey();
    const [currentLang, setCurrentLang] = useState(language);
    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);
    };

    useEffect(() => {
        updateLang(currentLang);
    }, [currentLang]);

    return (
        <>
            {!staticForm && (
                <div className="logo">
                    <AppLogo />
                </div>
            )}
            <div className="language">
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} isLoggin={true} />
            </div>
            <section className="content-container">
                {isLogin ? (
                    <div className="login-container">
                        <h2 data-testid="loginTo">{t(PARTNER_DOMAIN_PAGE_TITLE) + ' '}</h2>
                        <AppLogo />
                    </div>
                ) : (
                    <h2>{title}</h2>
                )}
                {isForgotPassword && <div className="forgot_password__text">{t(FORGOT_PASSWORD_PAGE_DESCRIPTION)}</div>}
                <div className="form-container">{children}</div>
            </section>
        </>
    );
};

export default Container;
