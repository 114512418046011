import React, { memo } from 'react';
import { Button } from '@material-ui/core';

import { TABLE_BUTTONS } from '../../../../../../../constants/Users';

import { changePasswordBtn } from '../../style.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const ChangePasswordButton = ({ handleClick }) => {
    const { t } = useTranslate();

    return (
        <Button variant="contained" color="primary" className={changePasswordBtn} onClick={handleClick}>
            <div>{t(TABLE_BUTTONS.CHANGE_PASSWORD)}</div>
        </Button>
    );
};

export default memo(ChangePasswordButton);
