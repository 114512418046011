import { buildBounceSetRequestBody, buildDashboardBounceSetParams } from '../../../helpers/ssoUtils';
import {
    HASH,
    IDENTITY,
    IDENTITY_SIGNATURE,
    PK_User,
    SERVER_ACCOUNT,
    SERVER_ACCOUNT_ALT,
} from '../../../constants/localStorageKeys';
import { API_CLOUD_EZLO_REQUEST_URL, DASHBOARD_URL } from '../../../constants/URLs';
import { axiosCloudConfig } from '../../../api/config';
import { bugsnagNotify } from '../../ErrorBoundary/utils';
import { languageService } from '../../../services/language';

const getDashboardBounceSetParams = () => {
    const pkUserRaw = localStorage.getItem(PK_User);
    const pkUser = pkUserRaw ? +pkUserRaw : null;
    const language = languageService.getLanguageKey();

    return buildDashboardBounceSetParams(
        '',
        '',
        localStorage.getItem(IDENTITY),
        localStorage.getItem(IDENTITY_SIGNATURE),
        localStorage.getItem(SERVER_ACCOUNT),
        localStorage.getItem(SERVER_ACCOUNT_ALT),
        pkUser,
        localStorage.getItem(HASH),
        language,
    );
};

const generateDashboardSSOToken = async () => {
    const body = buildBounceSetRequestBody(getDashboardBounceSetParams());

    const rawResponse = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(body),
    });

    const res = await rawResponse.json();

    return res?.data?.key;
};

export async function getDashboardSsoSrc() {
    let src = DASHBOARD_URL();

    try {
        const token = await generateDashboardSSOToken();
        src = DASHBOARD_URL(token);
    } catch (err) {
        bugsnagNotify(err);
    }

    return src;
}
