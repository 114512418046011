import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, LinearProgress, Modal } from '@material-ui/core';

// eslint-disable-next-line
import { EnrollByTokenModal } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/Accounts/EnrollByTokenModal';

import {
    apiEnrollByAuthUrl,
    apiEnrollWithoutAuth,
} from '../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';

import { getAllAbstractsByUuid, isEnrollmentByToken } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { getAuthInfo } from '../utils';

// eslint-disable-next-line
import { useStartPollingToGetAbstracts } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/hooks/useStartPollingToGetAbstracts';

import { bugsnagNotify } from '../../../ErrorBoundary/utils';
import { AddBlock } from './AddBlock';
import { useTranslate } from '../../../../features/languages';
import {
    NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_END_TITLE,
    NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_START_TITLE,
    EZLOGIC_BUTTON_OK,
} from '../../../../constants/language_tokens';
import { PRIMARY } from '../../../../constants/Devices';
import { CONTAINED } from '../../../../constants/MeshbotConstant';
import EzloIntegrationsPluginIntegrationInProcess from '../../EzloIntegrations/EzloIntegrationsModals/EzloIntegrationsIntegrationInProcess';
import EzloIntegrationsIntegrationCreated from '../../EzloIntegrations/EzloIntegrationsModals/EzloIntegrationsIntegrationCreated';
import style from './NewEnrollmentPage.module.scss';
import { APIKEY } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';

export const IntegrationModal = ({
    open,
    onClose,
    integration,
    isEnrolmentLoading,
    handleSetIsEnrollmentLoading,
    setIsEnrolmentLoading,
    abstracts,
    onFetchInitialInfo,
    onHandleClose,
    isIntegrations,
}) => {
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const PAASAuthUuid = useSelector((state) => state.main?.paas?.paasAuthServiceUuid);
    const isAbstractsLoading = useSelector((state) => state.main?.abstractsList?.isAbstractsListLoading);
    const [isAddedNewEnroll, setIsAddedNewEnroll] = useState(false);

    const enrollments = getAllAbstractsByUuid(abstracts, integration?.uuid);

    const [auth, setAuth] = useState({
        parjectGeneratedId: '',
        authenticationType: '',
    });
    const { t } = useTranslate();
    useEffect(() => {
        if (isEnrolmentLoading) {
            startPollingToGetAbstracts();
        }
    }, [isEnrolmentLoading]);

    useEffect(() => {
        (async function () {
            try {
                const result = await getAuthInfo(integration?.uuid);
                setAuth(result);
            } catch (error) {
                bugsnagNotify(error);
            }
        })();
    }, [integration?.uuid]);

    const startAuthentication = async () => {
        const { uuid: currentParjectUuid } = integration;
        const { authenticationType, parjectGeneratedId } = auth;
        if (!currentParjectUuid) {
            return;
        }

        if (!authenticationType) {
            setIsEnrolmentLoading(true);
            await apiEnrollWithoutAuth(currentParjectUuid);
            setIsAddedNewEnroll(true);
            handleSetIsEnrolmentLoading();
        }

        if (isEnrollmentByToken(authenticationType)) {
            return setIsTokenModalOpen(true);
        }

        const authUrl = await apiEnrollByAuthUrl(PAASAuthUuid, authenticationType, parjectGeneratedId);
        if (authUrl) {
            window.open(authUrl, '_blank');
            handleSetIsEnrollmentLoading(true);
        }
    };

    const handleAddedNewEnroll = (value) => {
        setIsAddedNewEnroll(value);
    };
    const handleSetIsEnrolmentLoading = () => {
        setIsEnrolmentLoading(false);
    };
    const { startPollingToGetAbstracts } = useStartPollingToGetAbstracts(
        abstracts,
        integration.uuid,
        enrollments,
        handleSetIsEnrolmentLoading,
        handleAddedNewEnroll,
    );

    const handleCloseAllModals = () => {
        onHandleClose();
        onClose();
        onFetchInitialInfo();
        handleSetIsEnrolmentLoading();
    };
    const onCloseByTokenModal = () => {
        setIsTokenModalOpen(false);
    };

    let contentOfModal;
    if (isTokenModalOpen || (isTokenModalOpen && auth?.authenticationType === APIKEY)) {
        contentOfModal = (
            <div className={style.infoBlock}>
                <EnrollByTokenModal
                    auth={auth}
                    parjectName={integration.name}
                    PAASAuthUuid={PAASAuthUuid}
                    isTokenModalOpen={true}
                    authExplanation={integration?.authExplanation}
                    setIsTokenModalOpen={onCloseByTokenModal}
                    setIsEnrolmentLoading={handleSetIsEnrollmentLoading}
                    startPollingToGetAbstracts={startPollingToGetAbstracts}
                />
            </div>
        );
    } else if (isEnrolmentLoading || isAbstractsLoading || auth?.parjectGeneratedId === '') {
        contentOfModal = (
            <EzloIntegrationsPluginIntegrationInProcess open={true} onClose={onClose} isIntegrations={isIntegrations} />
        );
    } else {
        contentOfModal = (
            <AddBlock
                authExplanation={integration.authType !== 'Bearer' && integration.authExplanation}
                title={integration.title}
                onClose={onClose}
                onStartAuthentication={startAuthentication}
            />
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box className={isIntegrations ? style.integrationsModalWrapper : style.modalWrapper}>
                {Boolean(isEnrolmentLoading || isAbstractsLoading) && <LinearProgress />}
                {!isAddedNewEnroll ? (
                    <>{contentOfModal}</>
                ) : isIntegrations ? (
                    <EzloIntegrationsIntegrationCreated
                        open={isAddedNewEnroll}
                        onClose={onClose}
                        onCreateMore={onClose}
                    />
                ) : (
                    <div className={style.infoBlock}>
                        <h3>
                            {t(NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_START_TITLE)}
                            {integration.title}
                            {t(NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_END_TITLE)}
                        </h3>
                        <div className={style.buttonsBlock}>
                            <Button
                                className={style.headerButton}
                                variant={CONTAINED}
                                color={PRIMARY}
                                onClick={handleCloseAllModals}
                            >
                                {t(EZLOGIC_BUTTON_OK)}
                            </Button>
                        </div>
                    </div>
                )}
            </Box>
        </Modal>
    );
};
