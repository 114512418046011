import React from 'react';
import classes from 'classnames';
import { Button, IconButton } from '@material-ui/core';

import { MESHBOT_TYPES } from '../../../../EzloMeshbots/constants';

import { wrapper, button, activeButton, actionSectionButtonsWrapper } from './TriggerBlockButtonsWrapper.module.scss';
import useFeatureSupportChecker from '../../../../../../hooks/useFeatureSupportChecker';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../../../constants/ControllerFeatures';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_EXCEPTION } from '../../../../../../constants/language_tokens';
import ActionExecutionPolicyBlock from '../../../MeshBot/ExecutionPolicyBlock';
import { eachAction } from '../../../MeshBot/ExecutionPolicyBlock/styles.module.scss';
import { ACTION_EXECUTION_POLICY_LEVELS } from '../../../constants';
import useShowActionExecutionPolicy from '../../../hooks/useShowActionExecutionPolicy';
import { DeleteIcon } from '../../../../../../assets/icons';
import { ACTION_THEN, ACTION_ELSE } from '../../../../../../constants/MeshbotConstant';

const TriggerBlockButtonsWrapper = ({
    id,
    blockType,
    sectionType,
    typeMeshbot,
    actionExceptionTriggers,
    onDeleteTrigger,
    onToggleExceptionBlock,
    blocks,
    actionBlockName,
}) => {
    const { t } = useTranslate();
    const showActionExecutionPolicy = useShowActionExecutionPolicy(sectionType);

    const className = classes(button, {
        [activeButton]: actionExceptionTriggers?.length,
    });
    const wrapperClassNames = classes(wrapper, {
        [actionSectionButtonsWrapper]: actionBlockName === ACTION_ELSE || actionBlockName === ACTION_THEN,
    });

    const isExceptionsSupported = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_44,
    );
    const showAddExceptionButton = isExceptionsSupported && typeMeshbot === MESHBOT_TYPES.LOCAL;

    const disabled = !Boolean(blocks?.length);

    return (
        <div className={wrapperClassNames}>
            {showActionExecutionPolicy && (
                <ActionExecutionPolicyBlock
                    id={id}
                    blockType={blockType}
                    executionPolicyLevel={ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION}
                    className={eachAction}
                />
            )}
            {showAddExceptionButton && (
                <div className={className}>
                    <Button disabled={disabled} variant="contained" onClick={onToggleExceptionBlock}>
                        {t(EZLOGIC_TITLE_EXCEPTION)}
                    </Button>
                </div>
            )}

            <IconButton aria-label="delete" color="primary" onClick={onDeleteTrigger}>
                <DeleteIcon />
            </IconButton>
        </div>
    );
};

export default TriggerBlockButtonsWrapper;
