import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import DeviceTreeMoreButton from './components/DeviceTreeMoreButton';
import DeviceGroupTreeContainer from '../DeviceGroupTreeContainer';
import { changeCategoryListOption } from '../../utils';

const DeviceGroupTreeContainerShowMore = ({ payload }) => {
    const [isShowMore, setIsShowMore] = useState(false);

    const updatedPayload = useMemo(() => changeCategoryListOption(payload, isShowMore), [payload, isShowMore]);

    const handleShowAllItems = () => {
        setIsShowMore((prev) => !prev);
    };

    const handleHideSomeItems = () => {
        setIsShowMore((prev) => !prev);
    };

    return (
        <>
            <DeviceGroupTreeContainer payload={updatedPayload} />
            <DeviceTreeMoreButton
                onShowAllItems={handleShowAllItems}
                onHideSomeItems={handleHideSomeItems}
                isShowMore={isShowMore}
            />
        </>
    );
};

export default DeviceGroupTreeContainerShowMore;

DeviceGroupTreeContainerShowMore.propTypes = {
    payload: PropTypes.object,
};
