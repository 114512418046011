import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import UserForm from '../UserForm';
import Header from '../../components/Header';
import UsersActions from '../../../../../actions/UsersActions';
import { getInitialValuesForUserForm } from '../utils';
import { ACCOUNT_ADMIN_PERMISSION_ROLE } from '../../../../../constants/Users';
import { editValidationSchema } from '../UserForm/validationSchema';
import oemManager from '../../../../../services/oem';
import { EZLOGIC_TITLE_USER_DETAILS } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const UserEdit = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { selectedUserDetails, selectedUser } = useSelector(({ users }) => users);
    const { Username: loggedInUserUsername } = useSelector(({ account }) => account.data);
    const isLoggedInUser = loggedInUserUsername === selectedUser.Username;
    const isAccountAdministrator = selectedUser.PK_PermissionRole === ACCOUNT_ADMIN_PERMISSION_ROLE;
    const oemId = oemManager.oem.getOemId();

    useEffect(() => {
        if (!isAccountAdministrator) {
            dispatch(UsersActions.setSelectedUserDetails(selectedUser));
        }

        return () => {
            dispatch(UsersActions.clearSelectedUserDetails());
        };
    }, []);

    if (!selectedUserDetails && !isAccountAdministrator) {
        return <Header title={t(EZLOGIC_TITLE_USER_DETAILS)} />;
    }

    const initialValues = getInitialValuesForUserForm(selectedUser, selectedUserDetails);

    const handleClickSave = (values) => {
        const { PK_PermissionRole, FirstName, Name, Controllers, Email, Phone } = values;
        const numberControllerList = Controllers.map((controller) => Number(controller));

        const userData = {
            update: {
                FirstName,
                Name,
                Phone: {
                    PK_Phone: selectedUser.Phone.PK_Phone,
                    Phone,
                },
                PK_PermissionRole: PK_PermissionRole.value,
            },
            allowAccessOnControllers: {
                PK_PermissionRole: PK_PermissionRole.value,
                Arguments: PK_PermissionRole.value !== ACCOUNT_ADMIN_PERMISSION_ROLE ? numberControllerList : [],
            },
        };

        if (Email && Email !== initialValues.Email) {
            userData.update.Email = Email;
        }

        dispatch(UsersActions.updateUser(userData, selectedUser, t));
    };

    return (
        <>
            <Header title={t(EZLOGIC_TITLE_USER_DETAILS)} />

            <Formik
                initialValues={initialValues}
                validationSchema={editValidationSchema(oemId)}
                onSubmit={handleClickSave}
            >
                <UserForm isEditing={true} isLoggedInUser={isLoggedInUser} />
            </Formik>
        </>
    );
};

export default UserEdit;
