import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { useTranslate } from '../../../../languages';
import { EZLOGIC_TITLE_CREATE_NEW_LABEL } from '../../../../../constants/language_tokens';

import styles from './styles.module.scss';

const CreateLabelButton = ({ onClick }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.createLabelButtonWrapper}>
            <Button startIcon={<AddIcon />} color="primary" fullWidth onClick={onClick}>
                {t(EZLOGIC_TITLE_CREATE_NEW_LABEL)}
            </Button>
        </div>
    );
};

export default CreateLabelButton;
