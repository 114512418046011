import React, { useState } from 'react';
import classNames from 'classnames';
import { IconButton, InputAdornment, Button } from '@material-ui/core';

import LoadingEllipses from '../../../Login/components/LoadingEllipses';
import {
    CONFIRM_PASSWORD_TITLE,
    ENTER_YOUR_PASSWORD_TITLE,
    EZLOGIC_BUTTON_CONFIRM,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import TextFieldWithFadeContent from '../TextFieldWithFadeContent';
import { ConfirmPasswordRequirements, NewPasswordRequirements } from '../PaperPasswordRequirements';

import styles from './SetPasswordForm.module.scss';
import { EyeIcon, EyeOffIcon } from '../../../../../assets/icons';

const SetPasswordForm = ({ isLoading, formik }) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const getResetPasswordButtonContent = () => {
        return !isLoading ? t(EZLOGIC_BUTTON_CONFIRM) : <LoadingEllipses />;
    };
    const getDefaultInputProps = (name) => ({
        fullWidth: true,
        type: showPassword ? 'text' : 'password',
        className: styles.textField,
        onChange: formik.handleChange,
        name,
        value: formik.values[name],
        error: formik.touched[name] && Boolean(formik.errors[name]),
        helperText: formik.touched[name] && formik.errors[name],
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <TextFieldWithFadeContent
                inputProps={{
                    ...getDefaultInputProps('newPassword'),
                    id: 'reset_user_password',
                    label: t(ENTER_YOUR_PASSWORD_TITLE),
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <EyeIcon className={classNames(styles.toggleIcon, styles.eye)} />
                                    ) : (
                                        <EyeOffIcon className={classNames(styles.toggleIcon)} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
                FadeComponent={<NewPasswordRequirements passwordValue={formik.values.newPassword} />}
                className={styles.inputContainer}
            />

            <TextFieldWithFadeContent
                inputProps={{
                    ...getDefaultInputProps('confirmPassword'),
                    id: 'reset_user_confirm_password',
                    label: t(CONFIRM_PASSWORD_TITLE),
                }}
                FadeComponent={<ConfirmPasswordRequirements formik={formik} />}
                className={styles.inputContainer}
            />

            <Button
                color="primary"
                variant="contained"
                fullWidth
                type={'submit'}
                className={classNames(styles.submitBtn)}
                disabled={!formik?.isValid}
            >
                {getResetPasswordButtonContent()}
            </Button>
        </form>
    );
};

export default SetPasswordForm;
