import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getMeshBotSectionTitleByType } from './utils';
import { ACTION_EXECUTION_POLICY_LEVELS, MESHBOT_SECTION_TYPE } from '../../constants';
import { useTranslate } from '../../../../../features/languages';
import ActionExecutionPolicyBlock from '../../MeshBot/ExecutionPolicyBlock';
import useShowActionExecutionPolicy from '../../hooks/useShowActionExecutionPolicy';

import {
    meshbotCaption,
    meshbotCaption__title,
    trigger_block,
    action_block,
} from './MeshBotSectionCaption.module.scss';

const MeshBotSectionCaption = (props) => {
    const { type } = props;
    const [title, setTitle] = useState('');
    const showActionExecutionPolicy = useShowActionExecutionPolicy(type);
    const { t } = useTranslate();
    const className = classNames(meshbotCaption, {
        [trigger_block]: MESHBOT_SECTION_TYPE.TRIGGER,
        [action_block]: type === MESHBOT_SECTION_TYPE.ACTION,
    });
    useEffect(() => {
        setTitle(t(getMeshBotSectionTitleByType(type)));
    }, [type]);

    return (
        <div className={className}>
            <span className={meshbotCaption__title}>{t(title)}</span>
            {showActionExecutionPolicy && (
                <ActionExecutionPolicyBlock executionPolicyLevel={ACTION_EXECUTION_POLICY_LEVELS.SCENE} />
            )}
        </div>
    );
};

MeshBotSectionCaption.propTypes = {
    type: PropTypes.string.isRequired,
};

MeshBotSectionCaption.defaultProps = {};

export default MeshBotSectionCaption;
