import Axios from 'axios';
import {
    ALLOW_ACCESS_ON_CONTROLLERS_URL,
    API_CLOUD_EZLO_REQUEST_URL,
    GET_CHANGE_USER_PASSWORD_URL,
    GET_CREATE_USER_URL,
    GET_DELETE_USER_URL,
    GET_RESEND_EMAIL_URL,
    GET_UPDATE_USER_URL,
    GET_USER_DETAILS_URL,
} from '../constants/URLs';
import { axiosCloudConfig, simpleConfig } from './config';
import { MODE, PK_PERMISSION, RESEND_EMAIL_NUMBER, TOKEN_VERSION } from '../constants/Users';
import oemManager from '../services/oem';

function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

export const getUserDataFromIdentity = (Identity) => {
    return JSON.parse(b64_to_utf8(Identity));
};

export const createNewUserAPI = (userData, PK_Account_Parent) => {
    const pkOem = oemManager.oem.getPkOem();

    const data = {
        ...userData,
        PK_Oem: pkOem,
        TokenVersion: TOKEN_VERSION,
        PK_Account: PK_Account_Parent,
    };
    const options = { 'Content-Type': 'application/json' };

    return Axios.post(GET_CREATE_USER_URL(), data, simpleConfig(options));
};

export const updateUserAPI = (userData, PK_User) => {
    return Axios.put(GET_UPDATE_USER_URL(PK_User), userData, simpleConfig());
};

export const deleteSelectedUserAPI = (PK_Account_Parent, PK_User) => {
    return Axios.delete(GET_DELETE_USER_URL(PK_Account_Parent, PK_User), simpleConfig());
};

export const allowAccessOnControllersAPI = (userData, PK_User, isAccountAdministrator) => {
    const options = { 'Content-Type': 'application/json' };
    const { PK_PermissionRole, Arguments } = userData;

    const data = {
        PK_PermissionRole,
        Permissions: isAccountAdministrator
            ? []
            : [
                  {
                      PK_Permission: PK_PERMISSION,
                      Mode: MODE,
                      Arguments,
                  },
              ],
    };

    return Axios.put(ALLOW_ACCESS_ON_CONTROLLERS_URL(PK_User), data, simpleConfig(options));
};

export const getUserDetailsAPI = (PK_User) => {
    return Axios.get(GET_USER_DETAILS_URL(PK_User), simpleConfig());
};

export const getUserPinCodesAPI = async () => {
    const payload = {
        call: 'user_pins_manage',
        params: {
            action: 'list',
        },
    };
    const response = await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload, axiosCloudConfig());

    if (response?.data?.data?.list) {
        return response.data.data.list;
    }
};

export const pinCodeAPI = async (action, pinCodeData) => {
    const payload = {
        call: 'user_pins_manage',
        params: {
            action,
            ...pinCodeData,
        },
    };

    return await Axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload, axiosCloudConfig());
};

export const changeUserPasswordAPI = (changePasswordData) => {
    return changeUserPasswordCustomConfigApi(changePasswordData, simpleConfig());
};

/**
 * Sends MMS API request to change user's password
 * @param {object} changePasswordData - object, with the following structure {username, NewPassword, CurrentPassword}
 * @param {object} config - Axios config
 * @returns {Promise<Object>} API call response
 * */
export const changeUserPasswordCustomConfigApi = (changePasswordData, config) => {
    return Axios.put(GET_CHANGE_USER_PASSWORD_URL(changePasswordData), '', config);
};

export const resendEmailAPI = (PK_User) => {
    const config = {
        ...simpleConfig(),
        params: {
            Email: RESEND_EMAIL_NUMBER,
        },
    };

    return Axios.get(GET_RESEND_EMAIL_URL(PK_User), config);
};
