import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import { useTranslate } from '../../../../../features/languages';
import { EDIT_PAGE, CREATE_PAGE } from '../../LanguageTokensConstants';
import { EZLOGIC_BUTTON_INTEGRATED_DEVICES } from '../../../../../constants/language_tokens';
import { LARGE } from '../../../../../constants/DeviceAssociations';
import styles from './DeviceGroupHeader.module.scss';

const DeviceGroupHeader = (props) => {
    const { isEditing, isShowIntegratedDevices } = props;
    const { t } = useTranslate();

    const history = useHistory();

    const handleClickBack = () => {
        history.goBack();
    };

    return (
        <div className={styles.deviceGroupsCreateHeaderWrapper}>
            <IconButton onClick={handleClickBack} size={LARGE} className={styles.arrowBackBtn}>
                <ArrowBackIos />
            </IconButton>
            <h1 className={styles.title}>
                {isShowIntegratedDevices
                    ? t(EZLOGIC_BUTTON_INTEGRATED_DEVICES)
                    : isEditing
                    ? t(EDIT_PAGE)
                    : t(CREATE_PAGE)}
            </h1>
        </div>
    );
};

export default DeviceGroupHeader;

DeviceGroupHeader.propTypes = {
    isEditing: PropTypes.bool,
    isShowIntegratedDevices: PropTypes.bool,
};
