import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { Divider } from '@material-ui/core';

import styles from './ControllerDevicesGroupHeader.module.scss';
import { ControllerIcon } from '../../../../../../../../../assets/icons';

const ControllerDevicesGroupHeader = ({ serial, isOnline }) => {
    const controllerIconStyles = classes(styles.controllerIcon, { [styles.online]: isOnline });
    const controllerSerialStyles = classes(styles.controllerSerial, { [styles.offline]: !isOnline });

    return (
        <div className={styles.controllerWrapper}>
            <div className={controllerIconStyles}>
                <ControllerIcon className={styles.controllerIconSvg} />
            </div>
            <div className={controllerSerialStyles}>{serial}</div>
            <div className={styles.dividerWrapper}>
                <Divider />
            </div>
        </div>
    );
};

export default ControllerDevicesGroupHeader;

ControllerDevicesGroupHeader.propTypes = {
    serial: PropTypes.string,
    isOnline: PropTypes.bool,
};
