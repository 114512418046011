import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { apiDeleteAbstractByUuid } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';
import IntegrationsListContent from './IntegrationsListContent';
import { NUCAL_INTEGRATION_CONTENT } from '../constants';

import styles from './styles.module.scss';
import { useTranslate } from '../../../../features/languages';

const IntegrationsSection = (props) => {
    const {
        userIntegrations,
        detailedIntegrations,
        fetchAbstractListDetailed,
        getAbstractsList,
        setUserIntegrations,
        onEdit,
        isSearching,
    } = props;
    const { NO_RESULTS } = NUCAL_INTEGRATION_CONTENT;
    const { t } = useTranslate();
    const fetchAbstracts = async () => {
        await fetchAbstractListDetailed();
        await setUserIntegrations();
        await getAbstractsList();
    };

    const handleIntegrationDelete = (integrationId) => {
        return async (enrollmentId) => {
            const wait = (delay) => {
                return new Promise((resolve) => setTimeout(() => resolve(), delay));
            };

            await apiDeleteAbstractByUuid(enrollmentId, integrationId);
            await wait(2000);
            await fetchAbstracts();
        };
    };

    const handleIntegrationEdit = useCallback((abstract) => {
        onEdit(abstract);
    }, []);

    const getIntegrationsListContent = () => {
        if (isSearching && !userIntegrations.length) {
            return <div className={styles.helperText}>{t(NO_RESULTS)}</div>;
        }

        return Object.entries(userIntegrations).map(([key, integration]) => {
            return (
                <IntegrationsListContent
                    key={key}
                    integration={integration}
                    detailedIntegrations={detailedIntegrations}
                    onIntegrationEdit={handleIntegrationEdit}
                    onIntegrationDelete={handleIntegrationDelete}
                    fetchAbstracts={fetchAbstracts}
                />
            );
        });
    };

    return <div className={styles.settingsIntegrationsSection}>{getIntegrationsListContent()}</div>;
};

IntegrationsSection.propTypes = {
    userIntegrations: PropTypes.array,
    linkTo: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default IntegrationsSection;
