import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import DeviceAssociationControllerInfo from '../DeviceAssociationControllerInfo';
import DeviceAssociationBlockWithController from '../DeviceAssociationWithController';
import DeviceIconContainer from '../../../../../components/DeviceIconContainer';
import useTooltip from '../../hooks/useToolTip';
import { EZLOGIC_TITLE_TARGET_DEVICE } from '../../../../../constants/language_tokens';

import styles from '../../DeviceAssociationsList/DeviceAssociationList.module.scss';

const DeviceAssociationTargetDeviceBlock = (props) => {
    const { isConnected, serial, deviceInfo, device } = props;
    const { t } = useTranslate();
    const isDevice = Object.keys(device).length;
    const { showTooltip, tooltipRef, handleIconClick, hideToolTip } = useTooltip();

    return (
        <>
            <div className={styles.sourceDeviceColumn}>
                <p className={styles.deviceName}>{t(EZLOGIC_TITLE_TARGET_DEVICE)}:</p>
                <div className={styles.dataDevice}>
                    {isDevice ? (
                        <>
                            <div className={styles.icon}>
                                <DeviceIconContainer
                                    category={device?.category}
                                    onClick={handleIconClick}
                                    iconStyles={styles.deviceIcon}
                                />
                                {showTooltip && (
                                    <div className={` ${styles.tooltip}`} ref={tooltipRef}>
                                        <DeviceAssociationControllerInfo
                                            isConnected={isConnected}
                                            serial={serial}
                                            deviceInfo={deviceInfo}
                                            device={device}
                                            hideToolTip={hideToolTip}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.content}>
                                <span>{device?.name}</span>
                            </div>
                        </>
                    ) : (
                        <DeviceAssociationBlockWithController
                            isConnected={isConnected}
                            serial={serial}
                            deviceInfo={deviceInfo}
                            device={device}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceAssociationTargetDeviceBlock;
