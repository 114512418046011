import { useEffect, useState } from 'react';
import oemManager from '../../../services/oem';
import { OEM_IDS } from '../../../services/oem/src/constants/oems';

/**
 * Returns a boolean indicating whether to show the sign-up elements based on the OEM configuration.
 * @function
 * @returns {boolean} Returns a boolean indicating whether to show the sign-up elements or not.
 * @example
 * getIsShowSignUp();
 **/
function getIsShowSignUp() {
    if (!oemManager.oem.isConfig()) {
        return true;
    }
    const oemId = oemManager.oem.getOemId();
    const isSecurityOem = OEM_IDS.SECURITY_OEM_ID === oemId;

    return !isSecurityOem;
}

/**
 * A React custom hook that determine whether to show the sign-up elements and
 * provides a function to update the isSignUp param.
 * @function
 * @returns {Array} Returns an array containing the boolean indicating whether to show the sign-up elements and
 * the function to update the state of the boolean.
 * @example
 * const [isSignUp, updateIsSignUp] = useShowSignUp();
 **/
const useShowSignUp = () => {
    const [isSignUp, setIsSignUp] = useState(true);
    const configOemId = oemManager.oem.getConfig()?.OEM_ID;

    useEffect(() => {
        setIsSignUp(getIsShowSignUp());
    }, [configOemId]);

    function updateIsSignUp() {
        setIsSignUp(getIsShowSignUp());
    }

    return [isSignUp, updateIsSignUp];
};

export default useShowSignUp;
