import { connect } from 'react-redux';
import * as Actions from '../../../../../actions';
import { bindActionCreators } from 'redux';
import PluginsPublish from './PluginsPublish';

const mapStateToProps = (state) => ({
    publicPlugins: state.plugins.publicPlugins,
    loaderList: state.plugins.loaderList,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginsPublish);
