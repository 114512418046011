import { useSelector } from 'react-redux';

const useCloudVariableCurrentValue = (abstractUuid, cloudVariableName) => {
    const currentValueData = useSelector(({ integrations }) => {
        const currentValueData = integrations?.cloudVariables?.currentValue;
        if (currentValueData[abstractUuid] && currentValueData[abstractUuid][cloudVariableName]) {
            return integrations.cloudVariables.currentValue[abstractUuid][cloudVariableName];
        }

        return null;
    });

    return currentValueData;
};

export default useCloudVariableCurrentValue;
