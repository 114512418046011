import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from '../AccountForm/validationSchema';
import AccountForm from '../AccountForm';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialValues, getRequestAddressData } from '../utils';
import AccountActions from '../../../../../actions/AccountActions';
import EzloPoliciesLinks from '../../../../../features/EzloPolicies/components/EzloPoliciesLinks';

import classes from '../AccountLayout/style.module.scss';
import { useTranslate } from '../../../../../features/languages';

const AccountInfo = () => {
    const dispatch = useDispatch();
    const { lineLoading } = useSelector(({ app }) => app);
    const { installationsList, countriesList } = useSelector(({ account }) => account);
    const initialValues = getInitialValues(installationsList, countriesList);
    const { t } = useTranslate();
    useEffect(() => {
        dispatch(AccountActions.setAccountInfo());
    }, []);

    if (lineLoading) {
        return null;
    }

    const handleClickSubmit = (formValues) => {
        const { Installations } = formValues;

        const addressesData = Installations.map((installation) => {
            const installationAddress = {
                PK_Installation: installation.PK_Installation,
                payload: getRequestAddressData(installation),
            };
            delete installationAddress.payload.PK_Installation;

            return installationAddress;
        });

        dispatch(AccountActions.updateAccountInfo(addressesData, t));
    };

    return (
        <div className={classes.accountContainer}>
            <div className={classes.accountContent}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClickSubmit}>
                    <AccountForm />
                </Formik>
            </div>
            <div className={classes.policiesLinks}>
                <EzloPoliciesLinks />
            </div>
        </div>
    );
};

export default AccountInfo;
