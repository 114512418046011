import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as meshbot from '../../../../constants/MeshbotConstant';
import { PRIVATE } from '../../../../constants/Plugins';
import * as script from '../../../../constants/ScriptConstants';
import { toast, TOAST_TYPE } from '../../../../components/Toast';
import ScriptList from '../components/ScriptList';
import ModalScript from '../components/ModalScript';
import ResponseForm from '../components/ResponseForm';
import {
    EZLOGIC_TITLE_ERROR_OCCURRED_RUN_SCRIPT,
    EZLOGIC_TITLE_EXECUTE_LUA_SCRIPTS,
    EZLOGIC_TITLE_SCRIPT_STARTED,
    EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY_REMOVE,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const PrivateScripts = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [currentAction, setCurrentAction] = useState(null);
    const [scriptData, setScriptData] = useState(null);
    const [scriptResponse, setScriptResponse] = useState(null);
    const {
        serial,
        listLuaScripts,
        loaderScriptList,
        scriptNotification,
        errorsScript,
        history,
        MeshBotAction,
        ScriptsActions,
        isActivePage,
        onHandlerRouteList,
    } = props;
    const { t } = useTranslate();
    useEffect(() => {
        MeshBotAction.getLuaScripts(serial);
    }, [serial]);

    useEffect(() => {
        if (scriptNotification === meshbot.REMOVE_SUCCESS) {
            toast(t(EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY_REMOVE), { type: TOAST_TYPE.SUCCESS });
        }

        if (scriptNotification === meshbot.START_UPDATE) {
            toast(t(EZLOGIC_TITLE_SCRIPT_STARTED), { type: TOAST_TYPE.INFO });
        }

        if (scriptNotification === meshbot.REJECTED) {
            toast(errorsScript, { type: TOAST_TYPE.ERROR });
        }
        MeshBotAction.clearScriptNotification();
    }, [scriptNotification]);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    const onConfirm = useCallback((data, type) => {
        setTypeModal('confirm');
        setIsModal(true);
        setCurrentAction(type);
        setScriptData(data);
    }, []);

    const handlerRouteToPage = useCallback((type, data) => {
        if (type === 'edit') {
            history.replace(`${script.ROUTE_SCRIPT_LIST}/${type}/${data.id}`);
        } else {
            history.replace(`${script.ROUTE_SCRIPT_LIST}/${type}`);
        }
    }, []);

    const handlerRunScript = useCallback(
        (data) => {
            MeshBotAction.runLuaScript(serial, { _id: data.id });
            ScriptsActions.subscribeResultRun(serial, notificationResultRun(serial));
        },
        [serial],
    );

    const notificationResultRun = (serial) => {
        return ({ result }) => {
            if (result.status === 'failed') {
                toast(t(EZLOGIC_TITLE_ERROR_OCCURRED_RUN_SCRIPT), { type: TOAST_TYPE.ERROR });
                ScriptsActions.unSubscribeResultRun(serial);
                setScriptResponse(result);
            }

            if (result.status === 'finished') {
                toast(t(EZLOGIC_TITLE_EXECUTE_LUA_SCRIPTS), { type: TOAST_TYPE.SUCCESS });
                ScriptsActions.unSubscribeResultRun(serial);
                setScriptResponse(result);
            }
        };
    };

    return (
        <div>
            <ScriptList
                type={PRIVATE}
                serial={serial}
                loaderScriptList={loaderScriptList}
                listLuaScripts={listLuaScripts}
                onConfirm={onConfirm}
                onHandlerRunScript={handlerRunScript}
                onHandlerRouteToPage={handlerRouteToPage}
                isActivePage={isActivePage}
                onHandlerRouteList={onHandlerRouteList}
            />
            <ResponseForm scriptResponse={scriptResponse} />
            <ModalScript
                open={isModal}
                currentSerial={serial}
                onCancel={hideModal}
                typeModal={typeModal}
                currentAction={currentAction}
                scriptData={scriptData}
                MeshBotAction={MeshBotAction}
            />
        </div>
    );
};

PrivateScripts.proptypes = {
    serial: PropTypes.string,
    loaderScriptList: PropTypes.bool,
    scriptNotification: PropTypes.string,
    errorsScript: PropTypes.string,
    history: PropTypes.object,
    MeshBotAction: PropTypes.object,
    ScriptsActions: PropTypes.object,
};

export default PrivateScripts;
