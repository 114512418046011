import React, { useState } from 'react';
import { Fade } from '@material-ui/core';

import TextField from '../../../../../components/TextField';
import { SLIDE_ANIMATION_TIMEOUT } from '../SetPasswordForm/constants';

import styles from './TextFieldWithFadeContent.module.scss';

const TextFieldWithFadeContent = ({ inputProps, FadeComponent, className }) => {
    const [showFadeComponent, setShowFadeComponent] = useState(false);

    const handlerOfToggleFadeComponent = () => {
        setShowFadeComponent(!showFadeComponent);
    };

    return (
        <div className={className}>
            <div className={styles.fieldBox}>
                {
                    <TextField
                        {...inputProps}
                        onFocus={handlerOfToggleFadeComponent}
                        onBlur={handlerOfToggleFadeComponent}
                    />
                }
                <div className={styles.fadeBox}>
                    <Fade in={showFadeComponent} timeout={SLIDE_ANIMATION_TIMEOUT} mountOnEnter unmountOnExit>
                        {FadeComponent}
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default TextFieldWithFadeContent;
