import React from 'react';
import { Button, Dialog, LinearProgress } from '@material-ui/core';
import { IcControlsClose } from '../../assets/icons';

import styles from './styles.module.scss';

const DialogWithIconTitleSubTitleAndFullWidthButton = ({
    open,
    closeDialog,
    icon,
    title,
    subTitle,
    buttonText,
    onButtonClick,
    buttonProps,
    linearProgressProps,
    isLinearProgress,
}) => {
    return (
        <Dialog open={open} onClose={closeDialog} className={styles.dialog}>
            <div onClick={(e) => e.stopPropagation()} className={styles.dialogContainer}>
                {isLinearProgress && (
                    <div className={styles.linearProgress}>
                        <LinearProgress {...linearProgressProps} />
                    </div>
                )}

                <div className={styles.closeIconContainer} onClick={closeDialog}>
                    <IcControlsClose />
                </div>

                <div className={styles.mainIconContainer}>{icon}</div>

                <div className={styles.title}>{title}</div>
                <div className={styles.subTitle}>{subTitle}</div>

                <div className={styles.buttonContainer}>
                    <Button {...buttonProps} fullWidth onClick={onButtonClick}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogWithIconTitleSubTitleAndFullWidthButton;
