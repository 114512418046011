import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PARJECT_NAMES } from '../../../../../../../../constants/MeshbotConstant';

export const useGetIntegration = (integrationUuid, isCalledForm = false) => {
    const integrations = useSelector((state) => state.main.integrationList.integrations);
    const integrationListWithCallBack = useSelector((state) => state.main.integrationList.integrationsWithCallBack);

    const [foundIntegration, setFoundIntegration] = useState({});

    const integrationsList = useMemo(() => {
        if (isCalledForm) {
            return integrationListWithCallBack?.filter((item) => {
                return PARJECT_NAMES.includes(item.name);
            });
        }

        return integrations;
    }, [isCalledForm]);

    useEffect(() => {
        if (integrationUuid) {
            const currentIntegration = integrationsList.find(({ uuid }) => uuid === integrationUuid);
            setFoundIntegration(currentIntegration);
        }
    }, [integrationUuid]);

    return {
        foundIntegration,
        integrationsList,
    };
};
