import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../constants/ActionTypes';

const initialState = {
    local: {},
    cloud: {},
};

const meshBotStatuses = createSlice({
    name: 'meshBotStatuses',
    initialState,
    reducers: {
        setActiveLocalRunScenes: (state, action) => {
            const { local } = action.payload;

            if (Object.keys(local).length === 0) {
                state.local = {};

                return;
            }

            const updatedLocal = {};

            Object.entries(local).forEach(([controllerId, scenes]) => {
                updatedLocal[controllerId] = {};

                Object.entries(scenes).forEach(([sceneId, status]) => {
                    updatedLocal[controllerId][sceneId] = status;
                });
            });

            state.local = updatedLocal;
        },
        setActiveCloudRunScenes: (state, action) => {
            const { cloud } = action.payload;

            const updatedCloud = { ...state.cloud };

            Object.entries(cloud).forEach(([key, value]) => {
                if (updatedCloud[key] !== value) {
                    updatedCloud[key] = value;
                }
            });

            Object.keys(updatedCloud).forEach((key) => {
                if (!(key in cloud)) {
                    delete updatedCloud[key];
                }
            });

            state.cloud = updatedCloud;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => initialState);
    },
});
export const { extraReducers, setActiveCloudRunScenes, setActiveLocalRunScenes } = meshBotStatuses.actions;
export default meshBotStatuses;
