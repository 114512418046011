import { useSelector } from 'react-redux';

/**
 * Custom hook. Provides a list of abstracts and integration, also gets a list of abstracts.
 */
const useIntegrationAndAbstractState = () => {
    const { abstractsList, integrationList } = useSelector((state) => state.main);

    return {
        abstractsList,
        integrationList,
    };
};

export default useIntegrationAndAbstractState;
