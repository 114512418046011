import React from 'react';
// import {object} from 'prop-types';
import './style.scss';
import { Button } from '../../../../../components';
import { InputNumber } from 'antd';
import { SETTING_TYPE_TEMP } from '../../../../../constants/Devices';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';

export default class DeviceSettingInt extends React.Component {
    // static propTypes = {
    //     settings: object.isRequired,
    //     device: object,
    // };

    state = {
        value: this.props.settings.value || 0,
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    restoreHandler = () => {
        const iniValue = this.props.settings.value || 0;

        this.setState({ value: iniValue });
    };

    saveHandler = () => {
        const { onSave, settings } = this.props;
        const { value } = this.state;

        if (settings._id) {
            onSave(settings._id, Number(value));
        }
    };

    changeCallback = (value) => {
        this.setState({ value });
    };

    render() {
        const { settings, titles } = this.props;
        const { value } = this.state;
        const { description, label, status, valueMin, valueMax, info, valueDefault, valueType } = settings;
        const paramId = info && info['zwave.parameter_number'];
        const stepType = valueType === SETTING_TYPE_TEMP ? 0.5 : 1;

        return (
            <div key={paramId} className="device-alarm__wrapper">
                <h2 className="device-info__title">{label.text}</h2>

                {status && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.status}</h5>
                            <span className="device-alarm__status">{status}</span>
                        </div>
                        <DeviceSettingInfo />
                    </div>
                )}

                {paramId && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.parameterId}</h5>
                            <span className="device-alarm__status">{paramId}</span>
                        </div>
                    </div>
                )}

                {description && <p className="device-alarm__text">{description.text}</p>}

                {valueMax ? (
                    <div className="device-range-slider__box">
                        <h3>
                            {titles.value}: {value}
                        </h3>
                        <input
                            type="range"
                            value={value}
                            min={valueMin}
                            max={valueMax}
                            step={stepType}
                            className="device-range-slider"
                            onChange={(e) => this.changeCallback(e.target.value)}
                        />
                        <span className="device-range-start">{valueMin}</span>
                        <span className="device-range-finish">{valueMax}</span>
                    </div>
                ) : (
                    <InputNumber
                        value={value}
                        className="input-number__input"
                        min={0}
                        step={1}
                        onChange={(value) => this.changeCallback(value)}
                    />
                )}

                <div className="device-range-footer">
                    {valueDefault && (
                        <div className="device-range-restore">
                            <Button className="btn-basic small device-range__button" onClick={this.restoreHandler}>
                                {titles.restore}
                            </Button>
                            <span>{'Set parameter to the default value'}</span>
                        </div>
                    )}

                    <Button className="btn-rounded device-range__button-save" onClick={this.saveHandler}>
                        {titles.save}
                    </Button>
                </div>
            </div>
        );
    }
}
