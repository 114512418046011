import React from 'react';
import { CREATE_BACKUP_STEPS as STEPS } from '../constants';
import BackupCreateContent from '../BackupCreateContent';
import BackupCreateProgress from '../BackupCreateProgress';
import BackupCreateCompleted from '../BackupCreateCompleted';
import BackupCreateError from '../BackupCreateError';
import './style.scss';

const CreateBackup = ({ progress, step, onFinish, handleCreateBackup }) => {
    switch (step) {
        case STEPS.IN_PROGRESS:
            return <BackupCreateProgress progress={progress} />;
        case STEPS.BACKUP_COMPLETED:
            return <BackupCreateCompleted onFinish={onFinish} />;
        case STEPS.BACKUP_ERROR:
            return <BackupCreateError onFinish={onFinish} />;
        default:
            return <BackupCreateContent handleCreateBackup={handleCreateBackup} />; // todo: check and fix!
    }
};

export default CreateBackup;
