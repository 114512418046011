import React from 'react';
import { TreeItem } from '@material-ui/lab';

import TreeElement from '../TreeElement';

const TreeItemComponent = ({ nodeId, labelComponent, treeElementProperties, prevPartPath }) => {
    return (
        <TreeItem nodeId={nodeId} label={labelComponent}>
            {treeElementProperties
                ? treeElementProperties.map(([propertyName, propertyValue]) => (
                      <TreeElement
                          key={propertyValue.elementId}
                          treeElementName={propertyName}
                          treeElementValue={propertyValue}
                          prevPartPath={prevPartPath}
                      />
                  ))
                : null}
        </TreeItem>
    );
};

export default TreeItemComponent;
