import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../SelectComponent';
import { info } from '../../../utils';
import { filterCapabilitiesByHardwareSupportOfController } from '../utils';
import { BATTERY } from '../../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../../features/languages';

const ControllerCapabilityList = (props) => {
    const { selectedControllerCapability, onUpdateTriggerData, ezloSerial } = props;

    const dataController = useSelector((state) => state.ezlo.data?.[ezloSerial]);
    const isControllerWithBattery = dataController?.hasOwnProperty(BATTERY);
    const { t } = useTranslate();
    const filteredCapabilitiesByHardwareSupportOfController =
        filterCapabilitiesByHardwareSupportOfController(isControllerWithBattery);

    return (
        <SelectComponent
            value={selectedControllerCapability ? selectedControllerCapability : ''}
            label={info.hints.capability}
            info={info.text.capability}
            onChange={onUpdateTriggerData}
        >
            {filteredCapabilitiesByHardwareSupportOfController.map((item) => {
                return (
                    <MenuItem key={item.id} value={item.value}>
                        {t(item.label)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ControllerCapabilityList;

ControllerCapabilityList.propTypes = {
    onUpdateTriggerData: PropTypes.func,
    selectedControllerCapability: PropTypes.string,
};
