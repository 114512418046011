import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as generator from '../../../../../constants/DevicePluginGenerator';
import { REJECTED, SUCCESS, PRIVATE } from '../../../../../constants/Plugins';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../../constants/toastConstants';
import toastsActions from '../../../../../actions/toastsActions';

import Spinner from '../../../../../components/Spinner';
import Header from '../HeaderForGenerator';
import GeneratorForm from '../GeneratorForm';

import styles from '../../PluginWifiGenerator/pluginWifiGenerato.module.scss';
import { EZLOGIC_HEADING_EDIT_IP_DEVICE_TEMPLATE } from '../../../../../constants/language_tokens';
import { WIFI_GENERATOR_PRIVATE } from '../../../../../constants/DevicePluginGenerator';

const EditDeviceTemplate = (props) => {
    const [capabilities, setCapabilities] = useState({});
    const [isSpinner, setIsSpinner] = useState(false);
    const [template, setTemplate] = useState(null);
    const dispatch = useDispatch();
    const {
        serial,
        history,
        match,
        isRequestStatus,
        isRequestStatusMessage,
        privateWifiTemplates,
        onSetIsActivePage,
        PluginActions,
        MainAction,
    } = props;

    useEffect(() => {
        const currentTemplate = privateWifiTemplates.filter((template) => template.uuid === match?.params?.id);
        if (currentTemplate) {
            setTemplate(currentTemplate[0]);
        }
    }, []);

    useEffect(() => {
        if (serial) {
            PluginActions.getCapabilitiesPluginList(serial);
            PluginActions.subscribeBroadcastConfigure(serial, getCapabilities(serial));
            PluginActions.subscribeUpdateConfigure(serial, notificationGetCapabilities(serial));
        }
        setIsSpinner(true);
    }, [serial]);

    useEffect(() => {
        if (isRequestStatus === SUCCESS) {
            history.replace(generator.WIFI_GENERATOR_PRIVATE);
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: SUCCESS_OPTIONS,
                }),
            );
            onSetIsActivePage(PRIVATE);
        }

        if (isRequestStatus === REJECTED) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    isSave: true,
                }),
            );
        }

        PluginActions.clearRequestStatus();
    }, [isRequestStatus]);

    const notificationGetCapabilities = (serial) => {
        return ({ result }) => {
            if (result.error !== null) {
                dispatch(
                    toastsActions.showToast({
                        message: result.error?.message,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
                PluginActions.unSubscribeUpdateConfigure(serial);
                PluginActions.unSubscribeBroadcastConfigure(serial);
            }
            setIsSpinner(false);
        };
    };

    const getCapabilities = (serial) => {
        return ({ result }) => {
            if (result.hasOwnProperty('capabilities')) {
                setCapabilities(result?.capabilities);
                PluginActions.unSubscribeBroadcastConfigure(serial);
                PluginActions.unSubscribeUpdateConfigure(serial);
            }
            setIsSpinner(false);
        };
    };

    const handleRouteToPlugin = () => {
        history.replace(WIFI_GENERATOR_PRIVATE);
    };

    return (
        <>
            <Header
                title={EZLOGIC_HEADING_EDIT_IP_DEVICE_TEMPLATE}
                isEdit={true}
                handleRouteToPlugin={handleRouteToPlugin}
                isPlugins={true}
            />
            {isSpinner ? (
                <div className={styles.wifiGenerator__spinner_installed}>
                    <Spinner className={styles.wifiGenerator__spinner_component} />
                </div>
            ) : (
                <>
                    <GeneratorForm
                        serial={serial}
                        type={generator.DEVICES_PLUGIN_EDIT}
                        history={history}
                        capabilities={capabilities}
                        template={template}
                        PluginActions={PluginActions}
                        MainAction={MainAction}
                    />
                </>
            )}
        </>
    );
};

EditDeviceTemplate.propTypes = {
    serial: PropTypes.string,
    isRequestStatus: PropTypes.string,
    isRequestStatusMessage: PropTypes.string,
    onSetIsActivePage: PropTypes.func,
    PluginActions: PropTypes.object,
};

export default EditDeviceTemplate;
