import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import DeviceAssociationsConfirmationAction from '../DeviceAssociationConfirmationAction';

import { createOnRemovePayload, isDeleteBtnDisable } from '../utils';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_ASSOCIATED_WITH } from '../../../../constants/language_tokens';
import { GREY, PRIMARY, ZWAVE_NODE } from '../../../../constants/Devices';
import DeviceAssociationTargetDeviceBlock from './DeviceAssociationTargetDeviceBlock';
import DeviceAssociationSourceDeviceBlock from './DeviceAssociationSourceDeviceBlock';
import { LARGE } from '../../../../constants/DeviceAssociations';

import { deleteBtn } from './style.module.scss';

import styles from '../DeviceAssociationsList/DeviceAssociationList.module.scss';

const DeviceAssociationsItem = (props) => {
    const {
        sourceDevice,
        targetDevice,
        values,
        serial,
        isConnected,
        keyForRender,
        keyOfTargetNodes,
        settingId,
        onRemove,
    } = props;

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { t } = useTranslate();
    const handleOpenConfirmationModal = () => {
        setIsConfirmModalVisible(true);
    };

    const handleRemoveAssociation = () => {
        const zwaveNode = Number(targetDevice.info[ZWAVE_NODE]);

        onRemove(createOnRemovePayload(settingId, keyOfTargetNodes, zwaveNode, values));
        setIsConfirmModalVisible(false);
    };

    const sourceDeviceInfo = sourceDevice?.info;
    const targetDeviceInfo = targetDevice?.info;
    const isButtonDisabled = isDeleteBtnDisable(targetDevice);

    return (
        <>
            <div className={styles.devicesRow} key={keyForRender}>
                <DeviceAssociationSourceDeviceBlock
                    isConnected={isConnected}
                    serial={serial}
                    deviceInfo={sourceDeviceInfo}
                    device={sourceDevice}
                    keyOfTargetNodes={keyOfTargetNodes}
                />
                <div className={styles.associateWithColumn}>
                    <span>{t(EZLOGIC_TITLE_ASSOCIATED_WITH)}</span>
                </div>
                <DeviceAssociationTargetDeviceBlock
                    device={targetDevice}
                    deviceInfo={targetDeviceInfo}
                    serial={serial}
                    isConnected={isConnected}
                />
                <div className={styles.deleteIconColumn}>
                    <IconButton className={deleteBtn} onClick={handleOpenConfirmationModal} disabled={isButtonDisabled}>
                        <Delete fontSize={LARGE} color={isButtonDisabled ? GREY : PRIMARY} />
                    </IconButton>
                </div>
            </div>
            <DeviceAssociationsConfirmationAction
                isConfirmModalVisible={isConfirmModalVisible}
                setIsConfirmModalVisible={setIsConfirmModalVisible}
                onRemoveAssociation={handleRemoveAssociation}
                isVirtualContainerPage={true}
            />
        </>
    );
};

export default DeviceAssociationsItem;
