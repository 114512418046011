import React from 'react';
import { Box } from '@material-ui/core';

import EzloNotificationTemplateKeywordsItem from '../EzloNotificationTemplateKeywordsItem';

import { NOTIFICATION_TEMPLATE_KEYWORDS } from '../../../../../constants/Notifications';

import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_AVAILABLE_KEYWORDS,
    EZLOGIC_TITLE_KEYWORDS_USAGE,
} from '../../../../../constants/language_tokens';

import styles from './EzloNotificationTemplateKeywords.module.scss';

const EzloNotificationTemplateKeywords = () => {
    const { t } = useTranslate();

    return (
        <Box>
            <h3 className={styles.keywordsTitle}>{t(EZLOGIC_TITLE_AVAILABLE_KEYWORDS)}</h3>
            {NOTIFICATION_TEMPLATE_KEYWORDS.map((keyword, index) => (
                <EzloNotificationTemplateKeywordsItem key={index} name={t(keyword.name)} value={keyword.value} />
            ))}
            <p
                className={styles.keywordsUsageDescription}
                dangerouslySetInnerHTML={{
                    __html: t(EZLOGIC_TITLE_KEYWORDS_USAGE),
                }}
            ></p>
        </Box>
    );
};

export default EzloNotificationTemplateKeywords;
