import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { Header as HeaderWrapper } from '../../../../../components';
import { getListTabs } from '../../utils';
import header from './header.module.scss';
import { useTranslate } from '../../../../../features/languages';

const Header = (props) => {
    const { typePage, isActivePage, nameButton, onSubmit, onHandlerRouteList } = props;
    const { t } = useTranslate();

    return (
        <>
            <HeaderWrapper headerClassName="plugins-header" />
            <div className={`${header.pluginHeader}`}>
                {isActivePage !== 'withoutTabs' && (
                    <div className={`${header.pluginHeader__buttons} ${header.pluginHeader__group}`}>
                        {getListTabs(typePage).map((button) => {
                            return (
                                <Button
                                    key={button.id}
                                    className={button.type === isActivePage ? 'active' : ''}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={button.type === 'disabled'}
                                    onClick={() => onHandlerRouteList(button.type)}
                                >
                                    {t(button?.name)}
                                </Button>
                            );
                        })}
                    </div>
                )}
                {isActivePage === 'private' && (
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className="addButton"
                        onClick={onSubmit}
                        startIcon={<Add />}
                    >
                        {t(nameButton)}
                    </Button>
                )}
            </div>
        </>
    );
};

Header.propTypes = {
    typePage: PropTypes.string,
    isActivePage: PropTypes.string,
    nameButton: PropTypes.string,
    onSubmit: PropTypes.func,
    onHandlerRouteList: PropTypes.func,
};

export default React.memo(Header);
