import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const AutocompleteSelect = ({ autocompleteProps, label, inputEndAdornment, handleChange }) => {
    const { name } = autocompleteProps;
    const { touched, errors, values } = useFormikContext();
    const fieldTouched = getIn(touched, name);
    const fieldError = getIn(errors, name);
    const fieldValue = getIn(values, name);

    return (
        <Autocomplete
            {...autocompleteProps}
            getOptionLabel={(option) => option.Name || ''}
            id={`${name}Select`}
            clearOnEscape
            disableClearable
            value={fieldValue}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    required
                    {...params}
                    label={label}
                    variant="standard"
                    error={fieldTouched && Boolean(fieldError)}
                    helperText={fieldTouched && fieldError}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {inputEndAdornment}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutocompleteSelect;
