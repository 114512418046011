import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GridTable from '../../../../../../components/Table';
import { systemHistoryActions } from '../../../../../../actions/SystemHistoryActions';
import OEMConfigContainer from '../../../../../../services/oem/src/OEMConfigContainer/OEMConfigContainer';

import { getParamsForApiCall } from '../../../utils';
import { ROWS_PER_PAGE } from '../../../../EzloMeshbots/constants';

export const SystemHistoryTableUI = (props) => {
    const { columns, systemHistoryList, loaderList } = props;
    const dispatch = useDispatch();
    const { dateBefore, dateAfter } = useSelector((state) => state.systemHistory.systemHistoryFilter);
    const serial = useSelector((state) => state.ezlo.serial);
    const loadMoreData = (start, count) => {
        dispatch(
            systemHistoryActions.getHistoryAlerts(
                `${OEMConfigContainer.buildGetParamsStringWithQuestionMark(
                    getParamsForApiCall({ dateBefore, dateAfter, serial, start, count }),
                )}`,
            ),
        );
    };

    return (
        <GridTable
            columns={columns}
            pagination={true}
            rows={systemHistoryList}
            rowsPerPageOptions={ROWS_PER_PAGE}
            disableSelectionOnClick={true}
            disableExtendRowFullWidth={false}
            disableColumnReorder={true}
            loaderList={loaderList}
            isSystemHistory={true}
            loadMoreData={loadMoreData}
            rowCount={systemHistoryList.length}
        />
    );
};
