import './style.scss';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { buildEditIntegrationParams } from '../utils';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_EDIT,
    NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE,
    NUCAL_INTEGRATIONS_NAME_FIELD_TITLE,
    NUCAL_INTEGRATIONS_UPDATE_INTEGRATION_TITLE,
} from '../../../../constants/language_tokens';

const UpdateIntegrationDialog = (props) => {
    const [name, setName] = useState('');
    const { t } = useTranslate();
    useEffect(() => {
        setName('');
    }, [props.open]);

    useEffect(() => {
        if (props.abstract) {
            setName(props.abstract.name);
        }
    }, [props.abstract]);

    const handleSubmit = () => {
        const { onSubmit, abstract, onClose } = props;
        if (name && abstract.name !== name) {
            onSubmit(buildEditIntegrationParams(name, abstract));
            // close modal
            setTimeout(() => {
                onClose();
            }, 300);
        }
    };

    const handleClose = () => {
        const { onClose } = props;
        onClose();
    };

    const { abstract, open } = props;
    const isDisabled = !abstract || !name || abstract.name === name;

    return (
        <Dialog open={open} onClose={handleClose} className="update-integration-dialog">
            <DialogTitle className="dialog__title">
                <span className="title" style={{ fontSize: '18px' }}>
                    {t(NUCAL_INTEGRATIONS_UPDATE_INTEGRATION_TITLE)}
                </span>
            </DialogTitle>
            <DialogContent className="dialog__content">
                <div className="text-wrapper">
                    <TextField
                        label={t(NUCAL_INTEGRATIONS_NAME_FIELD_TITLE)}
                        className="text-field disabled"
                        type="text"
                        name="enrollment_name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    style={{
                        fontSize: '16px',
                        width: '100%',
                        height: '56px',
                        fontFamily: 'Ezlo Sans',
                        flexGrow: '1',
                        margin: '0 24px',
                        textTransform: 'none',
                    }}
                    onClick={handleClose}
                >
                    {t(NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE)}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    style={{
                        fontSize: '16px',
                        width: '100%',
                        height: '56px',
                        fontFamily: 'Ezlo Sans',
                        flexGrow: '1',
                        margin: '0 24px',
                        textTransform: 'none',
                    }}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {t(EZLOGIC_BUTTON_EDIT)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UpdateIntegrationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    abstract: PropTypes.object,
};

UpdateIntegrationDialog.defaultProps = {
    open: false,
};

export default UpdateIntegrationDialog;
