import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CloudVariablesSelect from '../../CloudVariablesSelect';
import useCloudVariableCurrentValue from '../../../../../../hooks/useCloudVariableCurrentValue';

import IntegrationsActions from '../../../../../../actions/IntegrationsActions';
import MeshBotActions from '../../../../../../actions/MeshBotAction';
import { LOCAL_FORM } from '../../../constants';

/**
 * Container component for cloud variables' select in local meshbot triggers. It collects and provides data for the select,
 * also provides a function to change this data.
 */
const CloudVariablesSelectForLocalMeshbot = ({ ruleId, groupId, ruleTrigger }) => {
    const dispatch = useDispatch();
    const { selectedAbstract, selectedVariable } = ruleTrigger;
    const currentValueData = useCloudVariableCurrentValue(selectedAbstract?.uuid, selectedVariable);

    useEffect(() => {
        if (selectedAbstract && selectedVariable) {
            dispatch(IntegrationsActions.setCloudVariableCurrentValue(selectedAbstract?.uuid, selectedVariable));
        }
    }, []);

    const updateSelectValue = (variableData) => {
        const triggerIds = { ruleId, idGroup: groupId };
        dispatch(MeshBotActions.updateCloudVariableInTrigger(triggerIds, variableData));
    };

    return (
        <CloudVariablesSelect
            updateSelectValue={updateSelectValue}
            selectedAbstract={selectedAbstract}
            selectedVariable={selectedVariable}
            currentValueData={currentValueData}
            calledFrom={LOCAL_FORM}
        />
    );
};

export default CloudVariablesSelectForLocalMeshbot;
