import {
    BOOLEAN_FALSE,
    BOOLEAN_TRUE,
    DATA_TYPES_LIST,
    DATA_TYPES_OBJ_VALID_LENGTH,
    DOT,
    ONE_INT,
    ZERO_INT,
} from '../../../constants/Variables';
import _ from 'lodash';

const isCommonDataTypesValid = (validationParams) => {
    const { name, code, dataType, initName, initCode, initValueType } = validationParams;
    const validateSupportedValueLength = dataType === DATA_TYPES_LIST.TYPE_STRING ? code.length >= 0 : code.length > 0;
    const isValuesNotEmpty = name.length > 0 && validateSupportedValueLength && dataType !== '';
    const isEdited = initName !== name || initCode !== code || initValueType !== dataType;

    if (dataType === DATA_TYPES_LIST.TYPE_INT && code?.includes(DOT)) {
        return false;
    }

    return isValuesNotEmpty && isEdited;
};

const isActionDataTypeValid = (validationParams) => {
    const { name, dataType, initName, initValueType, code, initCode } = validationParams;
    const isActionValuesNotEmpty = name.length > 0 && dataType !== '';
    const isActionObjectEdited = !_.isEqual(code, initCode);
    const isEdited = initName !== name || initValueType !== dataType || isActionObjectEdited;
    const actionObjectLength = Object.keys(code).length === DATA_TYPES_OBJ_VALID_LENGTH;

    return isActionValuesNotEmpty && actionObjectLength && isEdited;
};

const isExpressionValid = (validationParams) => {
    const { name, code, initName, initCode } = validationParams;
    const isExpressionEdited = initName !== name || initCode !== code;
    const isExpressionValuesNotEmpty = name.length > 0 && code.length > 0;

    return isExpressionValuesNotEmpty && isExpressionEdited;
};

const isRGBDataTypeValid = (validationParams) => {
    const { name, code, initName, initCode } = validationParams;
    const isEdited = initName !== name || !_.isEqual(code, initCode);
    const isValuesNotEmpty = name.length > 0 && Object.keys(code).length > 0;

    return isValuesNotEmpty && isEdited;
};

const isTokenDataTypeValid = (validationParams) => {
    const { name, code, initName, initCode } = validationParams;
    const isTokenObjectFieldsEmpty = Object.values(code)?.every((el) => el.value !== '' && el.text !== '');
    const isTokenChecked = Object.values(code)?.some((el) => el.checked);
    const isTokenObjectEdited = !_.isEqual(code, initCode);
    const isEdited = initName !== name || (isTokenObjectEdited && isTokenObjectFieldsEmpty);
    const isValuesNotEmpty = name.length > 0 && code !== '' && isTokenObjectFieldsEmpty && isTokenChecked;

    return isValuesNotEmpty && isEdited;
};

const isScalableDataTypeValid = (validationParams) => {
    const { name, code, initName, initCode, scalableType, dataType } = validationParams;
    const scalableValuesObjectLength = Object.keys(code).length === DATA_TYPES_OBJ_VALID_LENGTH;
    const isScalableDataTypeValuesNotEmpty =
        name.length > 0 && dataType !== '' && scalableType !== '' && scalableValuesObjectLength;
    const scalableValuesObjectEdited = !_.isEqual(code, initCode);
    const isEdited = initName !== name || scalableValuesObjectEdited;

    return isScalableDataTypeValuesNotEmpty && isEdited;
};

export const formatTokenValueToDataType = (value) => {
    return value.reduce((el, acc) => {
        return {
            ...el,
            [acc.value]: {
                text: acc.text,
                checked: acc.checked,
                value: acc.value,
            },
        };
    }, {});
};

export const findSelectedTokenDataTypeValue = (dataTypeValue) => {
    const selectedField = dataTypeValue.find((el) => {
        return el?.checked ? el?.value : null;
    });

    return selectedField.value;
};

export const isExpressionFormValid = (validationParams) => {
    const { name, code, initName, initCode, initValueType, dataType, scalableType } = validationParams;

    switch (dataType) {
        case DATA_TYPES_LIST.TYPE_STRING:
        case DATA_TYPES_LIST.TYPE_INT:
        case DATA_TYPES_LIST.TYPE_BOOLEAN:
            return isCommonDataTypesValid({ name, code, dataType, initName, initCode, initValueType });
        case DATA_TYPES_LIST.TYPE_ACTION:
            return isActionDataTypeValid({
                name,
                dataType,
                initName,
                initValueType,
                code,
                initCode,
            });
        case DATA_TYPES_LIST.TYPE_RGB:
            return isRGBDataTypeValid({ name, code, initName, initCode });
        case DATA_TYPES_LIST.TYPE_TOKEN:
            return isTokenDataTypeValid({ name, code, initName, initCode });
        case DATA_TYPES_LIST.TYPE_SCALABLE:
            return isScalableDataTypeValid({ name, code, initName, initCode, scalableType, dataType });
        default:
            return isExpressionValid({ name, code, initName, initCode });
    }
};

export const formatValueToDataType = (value, dataType) => {
    const isInt = Number.isInteger(+value) && dataType !== DATA_TYPES_LIST.TYPE_STRING;
    const isFloat = Number(+value) % ONE_INT !== ZERO_INT && dataType === DATA_TYPES_LIST.TYPE_FLOAT;
    const isBoolean = value === BOOLEAN_TRUE || value === BOOLEAN_FALSE;
    const isToken = Array.isArray(value);

    switch (true) {
        case isInt:
        case isFloat:
            return Number(value);
        case isBoolean:
            return JSON.parse(value);
        case isToken:
            return formatTokenValueToDataType(value);
        default:
            return value;
    }
};

export const formatDataTypeToString = (dataType) => {
    if (typeof dataType === 'string' || typeof dataType === 'object') {
        return dataType;
    }

    return JSON.stringify(dataType);
};

export const checkExpressionCreatingFormFields = (name, code, selectedDevice, selectedCapabilityId) => {
    if (!name && !code && Object.keys(selectedDevice).length === 0 && !selectedCapabilityId) {
        return false;
    }

    return true;
};

export const checkExpressionEditingFormFields = (initName, name, initCode, code) => {
    if ((initName && name && initName !== name) || (initCode && code && initCode !== code)) {
        return true;
    }

    return false;
};

export const sortScalableTypesArrayByName = (scalableTypesArr = []) => {
    return scalableTypesArr.sort((a, b) => a.name.localeCompare(b.name));
};

export const getSelectedScalableTypeObject = (scalableType, scalableTypesArr = []) => {
    return sortScalableTypesArrayByName(scalableTypesArr).find((el) => el.value === scalableType);
};

export const getExpressionsSelector = ({ ezlo }) => {
    return ezlo.data?.[ezlo.serial]?.expressions || [];
};

/**
 * This function returns the initial timestamp or the current timestamp
 * @param {string} initName - the initial name
 * @param {number} initCreatedAt - the initial timestamp
 * @returns {number} - the initial timestamp or the current timestamp
 */
export const getCurrentTimestamp = (initName, initCreatedAt) => {
    if (initCreatedAt && initName) {
        return initCreatedAt;
    }

    return new Date().getTime();
};

/**
 * This function gets/returns the expression list or the variable list
 * @param {array} data - the initial all expression list(expressions and variables)
 * @param {string} isExpression - the expression type
 * @returns {array} - the expression list or the variable list  or the empty array
 */
export const getListSameTypeExpressions = (data, isExpression) => {
    if (isExpression) {
        return data?.filter((item) => !item?.variable);
    }

    return data?.filter((item) => item?.variable);
};

/**
 * This function sorts the expression list and the variable list by creation time
 * @param {array} data - the initial all expression list(expressions and variables)
 * @param {string} isExpression - the expression type
 * @returns {array} - the sorted expression list or the sorted variable list or the empty array
 */
export const sortListAllExpressions = (data, isExpression) => {
    if (!data?.length) {
        return [];
    }

    const listSameTypeExpressions = getListSameTypeExpressions(data, isExpression);

    if (!listSameTypeExpressions.length) {
        return [];
    }

    listSameTypeExpressions.sort((a, b) => {
        if (a.metadata?.createdAt && b.metadata?.createdAt) {
            const dateA = new Date(a.metadata?.createdAt);
            const dateB = new Date(b.metadata?.createdAt);

            return dateA - dateB;
        } else if (a.metadata?.createdAt) {
            return 1; // b goes before a
        } else if (b.metadata?.createdAt) {
            return -1; // a goes before b
        } else {
            return 0; // no sorting needed, both are without createdAt
        }
    });

    return listSameTypeExpressions;
};

/**
 * This function creates a payload to create an expression depending on its types
 * @param {object} options - an options object with the following properties:
 * - expressionType: string
 * - isExpression: string
 * - name: string
 * - initName: string
 * - initCreatedAt: number
 * - code: string
 * - dataType: string
 * - paramsItems: array
 * - scalableType: string
 * @returns {object} - payload to create an expression
 */
export const buildExpressionPayload = (options) => {
    const { isExpression } = options;

    if (!isExpression) {
        return buildExpressionPayloadForVariableType(options);
    } else {
        return buildExpressionPayloadDefault(options);
    }
};

const createPayload = (options) => {
    const { name, expressionType, initName, initCreatedAt } = options;

    return {
        name,
        code: '',
        metadata: {
            dataType: expressionType,
            createdAt: getCurrentTimestamp(initName, initCreatedAt),
        },
    };
};

const buildExpressionPayloadForVariableType = (options) => {
    const { dataType } = options;

    if (dataType !== DATA_TYPES_LIST.TYPE_TOKEN) {
        return handleNonTokenCase(options);
    } else {
        return handleTokenCase(options);
    }
};

const buildExpressionPayloadDefault = (options) => {
    const { code, paramsItems } = options;
    const payload = createPayload(options);

    return {
        ...payload,
        code: code,
        params: {
            ...payload.params,
            items: paramsItems,
        },
    };
};

const handleNonTokenCase = (options) => {
    const { code, dataType, scalableType } = options;
    const payload = createPayload(options);

    return {
        ...payload,
        value: formatValueToDataType(code, dataType),
        variable: true,
        valueType: dataType === DATA_TYPES_LIST.TYPE_SCALABLE ? scalableType : dataType,
        metadata: {
            ...payload.metadata,
            scalableType: dataType,
        },
    };
};

const handleTokenCase = (options) => {
    const { code, dataType } = options;
    const payload = createPayload(options);

    return {
        ...payload,
        enum: formatValueToDataType(code),
        value: findSelectedTokenDataTypeValue(code),
        variable: true,
        valueType: dataType,
        metadata: {
            ...payload.metadata,
            enumValue: formatValueToDataType(code),
        },
    };
};

/**
 * This function checks expression name uniqueness
 * @param {array} expressionList - the initial all expression list(expressions and variables)
 * @param {string} name - the new expression name
 * @returns {boolean}
 */
export const isExpressionNameUnique = (expressionList = [], name) => {
    return !expressionList.some((expression) => expression.name === name);
};
