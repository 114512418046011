import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { filterDevicesByType } from '../../../../../../utils';

import { EZLOGIC_TITLE_NO_DEVICES } from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';
import DeviceCard from '../../../../../../../../../components/DeviceCard';
import styles from '../../ControllerDevicesGroup.module.scss';

const DevicesCardsList = (props) => {
    const [filteredDevices, setFilteredDevices] = useState([]);
    const {
        onNameChange,
        onHandlerSelectFunction,
        onHandlerRefreshDevice,
        onSetCurrentFunction,
        onChangeDevicePlace,
        devices,
    } = props;
    const { t } = useTranslate();
    useEffect(() => {
        const result = filterDevicesByType(devices);
        setFilteredDevices(result);
    }, [devices]);

    return (
        <div className={styles.devicesWrapper}>
            {filteredDevices.length ? (
                filteredDevices.map((device) => (
                    <DeviceCard
                        key={device._id}
                        device={device}
                        {...device}
                        onNameChange={onNameChange}
                        onHandlerSelectFunction={onHandlerSelectFunction}
                        onHandlerRefreshDevice={onHandlerRefreshDevice}
                        onSetCurrentFunction={onSetCurrentFunction}
                        onChangeDevicePlace={onChangeDevicePlace}
                    />
                ))
            ) : (
                <span className={styles.notification}>{t(EZLOGIC_TITLE_NO_DEVICES)}</span>
            )}
        </div>
    );
};

export default DevicesCardsList;

DevicesCardsList.propTypes = {
    onNameChange: PropTypes.func,
    onHandlerSelectFunction: PropTypes.func,
    onChangeDevicePlace: PropTypes.func,
    onHandlerRefreshDevice: PropTypes.func,
    devices: PropTypes.array,
    onSetCurrentFunction: PropTypes.func,
};
