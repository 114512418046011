import React, { memo, useState } from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { PHONE_NUMBER } from '../../../../../../constants/Users';
import { generateNationalUASFormatPhoneNumber, getOnlyNumbers } from '../../../UserManagement/utils';

import textInputStyles from '../../TextInput/styles.module.scss';

const USAPhoneNumberInputWithMask = ({ label, value, error, helperText, updatePhoneNumber }) => {
    const [phoneNumber, setPhoneNumber] = useState(generateNationalUASFormatPhoneNumber(value));
    const { NATIONAL_USA_MASK } = PHONE_NUMBER;

    const handleChange = (e) => {
        const { value } = e.target;
        const onlyNumber = getOnlyNumbers(value);
        setPhoneNumber(value);
        updatePhoneNumber(onlyNumber);
    };

    return (
        <InputMask
            label={label}
            error={error}
            helperText={helperText}
            className={textInputStyles.textField}
            mask={NATIONAL_USA_MASK}
            value={phoneNumber}
            maskChar=""
            onChange={handleChange}
        >
            {(inputProps) => {
                return <TextField {...inputProps} type="tel" fullWidth />;
            }}
        </InputMask>
    );
};

export default memo(USAPhoneNumberInputWithMask);
