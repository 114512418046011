import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import HeaderComponent from '../../components/Header';
import ChangeUserPasswordForm from './ChangeUserPasswordForm';
import UsersActions from '../../../../../actions/UsersActions';
import { validationSchema } from './validationSchema';
import ConfirmDialog from '../../components/ConfirmDialog';
import { SUCCESS } from '../../../../../constants/Users';
import {
    EZLOGIC_TITLE_CHANGE_PASSWORD,
    EZLOGIC_TITLE_PASSWORD_CONFIRM_WARNING_TEXT,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const ChangeUserPassword = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changePasswordData, setChangePasswordData] = useState();
    const { selectedUser, requestStatus, requestStatusMessage } = useSelector(({ users }) => users);

    useEffect(() => {
        if (requestStatus === SUCCESS) {
            toast(requestStatusMessage, { type: TOAST_TYPE.SUCCESS });
        }

        return () => {
            dispatch(UsersActions.clearRequestStatus());
        };
    }, [requestStatus]);

    const initialValues = {
        CurrentPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleOpen = () => {
        setIsModalVisible(true);
    };

    const handleChangePassword = () => {
        dispatch(UsersActions.changeUserPassword(changePasswordData, t));
        setIsModalVisible(false);
    };

    const handleClickSave = (values) => {
        const { NewPassword, CurrentPassword } = values;

        const changePasswordData = {
            username: selectedUser.Username,
            NewPassword,
            CurrentPassword,
        };

        handleOpen();
        setChangePasswordData(changePasswordData);
    };

    return (
        <>
            <HeaderComponent title={t(EZLOGIC_TITLE_CHANGE_PASSWORD)} />

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClickSave}>
                <ChangeUserPasswordForm />
            </Formik>
            <ConfirmDialog
                isModalVisible={isModalVisible}
                handleClose={handleClose}
                titleText={t(EZLOGIC_TITLE_CHANGE_PASSWORD)}
                warningText={t(EZLOGIC_TITLE_PASSWORD_CONFIRM_WARNING_TEXT)}
                confirmButtonText={t(EZLOGIC_TITLE_CHANGE_PASSWORD)}
                confirmFn={handleChangePassword}
            />
        </>
    );
};

export default withErrorBoundary(ChangeUserPassword, FallbackMeshbotNodeComponent);
