import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { MAP_TYPE_KEY } from '../../constants';
import { mapTypes } from '../../template';

import style from './CloudVariable.module.scss';
import { EZLOGIC_TITLE_ADVANCED, EZLOGIC_TITLE_SIMPLE } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

export const MapTypeGroup = ({ mapType, onSetCloudVariableState, disabled }) => {
    const { t } = useTranslate();
    const handleChangeMapType = (e, mapType) => {
        if (mapType !== null) {
            onSetCloudVariableState(mapType, MAP_TYPE_KEY);
        }
    };

    return (
        <div className={style.switchBlock}>
            <ToggleButtonGroup color="primary" onChange={handleChangeMapType} exclusive value={mapType}>
                <ToggleButton value={mapTypes.SIMPLE} disabled={disabled}>
                    {t(EZLOGIC_TITLE_SIMPLE)}
                </ToggleButton>
                <ToggleButton value={mapTypes.ADVANCED}>{t(EZLOGIC_TITLE_ADVANCED)}</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};
