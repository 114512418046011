import React, { useState, useEffect, useCallback } from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../SelectComponent';
import Month from '../../../../../components/Month';
import WeekOfTheMonth from '../../../../../components/WeekOfTheMonth';
import WeeksOfTheYear from '../../../../../components/WeeksOfTheYear';
import Week from '../../../../../components/Week';
import Year from './year';
import SectionCustom from './sectionCustom';
import TimeOffsetBlock from './TimeOffsetBlock';

import * as meshBot from '../../../../../constants/MeshbotConstant';
import { customDate, specificDate, specificDateLabel } from '../../../../../constants/rulesInSelect';
import { getIntegerFromNumberInput, info } from '../../utils';
import { HoursAndMinutesSelect } from './sectionCustom';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../../constants/ControllerFeatures';

import useTriggerSelect from '../../../../../hooks/useTriggerSelect';
import useExceptionSelect from '../../../../../hooks/useExceptionSelect';
import useFeatureSupportChecker from '../../../../../hooks/useFeatureSupportChecker';
import { useTranslate } from '../../../../../features/languages';

const TemplateDateTimeBlocks = (props) => {
    const [isArrayDate, setIsArrayDate] = useState([]);
    const [isArrayWeek, setIsArrayWeek] = useState([]);
    const [isArrayYear, setIsArrayYear] = useState([]);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [customHours, setCustomHours] = useState(0);
    const [customMinutes, setCustomMinutes] = useState(0);
    const [startHours, setStartHours] = useState('');
    const [startMinutes, setStartMinutes] = useState('');
    const [endHours, setEndHours] = useState('');
    const [endMinutes, setEndMinutes] = useState('');
    const [year, setYear] = useState(meshBot.YEAR);
    const [addedOffset, setAddedOffset] = useState(false);
    const [offsetDirection, setOffsetDirection] = useState(meshBot.PLUS);
    const { t } = useTranslate();
    const {
        id,
        actionId,
        idGroup,
        typeTrigger,
        className,
        onSolsticeTime,
        onSetSpecificDate,
        onSetDateValue,
        onSetFieldValue,
        typeMeshbot,
    } = props;

    const triggerSelect = useTriggerSelect(id, idGroup);
    const exceptionSelect = useExceptionSelect(id, actionId, idGroup);
    const isSupportsUpdatedAdvancedScenesForDays = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_53,
    );
    const currentTrigger = typeTrigger === meshBot.EXCEPTION ? exceptionSelect : triggerSelect;

    const {
        selectDay,
        selectMonth,
        selectYear,
        selectedTimeType,
        selectedFieldDate,
        selectedSpecificDate,
        selectedSpecificLabel,
        blocks = [],
    } = currentTrigger;

    useEffect(() => {
        if (blocks && blocks.length > 0) {
            if (selectedFieldDate === 'daily' || selectedFieldDate === 'weekdays' || selectedFieldDate === 'days') {
                unFormattingIsDate(blocks[0]);
            } else if (selectedFieldDate === 'weeks') {
                unFormattingIsWeek(blocks[0]);
            } else if (selectedFieldDate === 'yearWeeks' || selectedFieldDate === 'endYear') {
                unFormattingIsYear(blocks[0]);
            } else if (selectedFieldDate === 'custom') {
                unFormattingCustomTime(blocks[0]);
            }
        }
    }, [blocks]);

    useEffect(() => {
        if (addedOffset) {
            setOffsetDirection(meshBot.PLUS);
            if (selectedFieldDate === meshBot.DAILY) {
                onSetSpecificDate(meshBot.PLUS, id, selectedFieldDate, idGroup, actionId, selectedSpecificDate);
            }
        }
    }, [selectedSpecificDate]);

    useEffect(() => {
        if (!blocks.length || addedOffset) {
            setAddedOffset(false);
            onSetSpecificDate(meshBot.EMPTY_STRING, id, selectedFieldDate, idGroup, actionId, selectedSpecificDate);
        }
    }, [selectedSpecificLabel]);

    useEffect(() => {
        if (!blocks.length) {
            return;
        }

        const currentValue = blocks[meshBot.INDEX_SELECTED_BLOCKS_ELEMENT].fields?.find(
            (field) => field.name === meshBot.SUN_STATE.SUNRISE || field.name === meshBot.SUN_STATE.SUNSET,
        )?.value;

        if (currentValue !== meshBot.IN_TIME) {
            setAddedOffset(true);
        }

        if (currentValue === meshBot.BEFORE) {
            setOffsetDirection(meshBot.MINUS);
        }
    }, []);

    const handleChangeDisplayOffset = (event) => {
        setAddedOffset(event.target.checked);
        if (!event.target.checked) {
            onSetSpecificDate(meshBot.EMPTY_STRING, id, selectedFieldDate, idGroup, actionId, selectedSpecificDate);
        } else {
            setOffsetDirection(meshBot.PLUS);
            onSetSpecificDate(meshBot.PLUS, id, selectedFieldDate, idGroup, actionId, selectedSpecificDate);
        }
    };

    const handleSelectOffsetDirection = (event) => {
        setOffsetDirection(event.target.value);
        onSetSpecificDate(event, id, selectedFieldDate, idGroup, actionId, selectedSpecificDate);
    };

    const unFormattingIsWeek = (data) => {
        data.fields?.map((item) => {
            if (item.type === 'int_array') {
                if (item.value === meshBot.LAST_WEEK) {
                    setIsArrayWeek(-1);
                } else {
                    setIsArrayWeek(item.value);
                }
            }
        });
    };

    const unFormattingIsYear = (data) => {
        data.fields?.forEach((item) => {
            if (item.type === 'int_array') {
                setIsArrayYear(item.value);
            }

            if (item.type === 'int') {
                setYear(item.value);
            }
        });
    };

    const unFormattingIsDate = (data) => {
        data.fields?.map((item) => {
            if (item.type === '24_hours_time_array' || item.type === 'hms_interval') {
                if (typeof item.value === 'string') {
                    const time = item.value.split(':');
                    setSeconds(time[2]);
                    setMinutes(time[1]);
                    setHours(time[0]);
                }
            }

            if (item.type === 'int_array') {
                setIsArrayDate(item.value);
            }
        });
    };

    const unFormattingCustomTime = (data) => {
        const setTime = (setHour, setMinute, date) => {
            if (Number(date[0]) > meshBot.HOUR12) {
                const formatHour = Number(date[0]) - meshBot.HOUR12;

                setHour(formatHour);
                setMinute(Number(date[1]));
            } else {
                setHour(Number(date[0]));
                setMinute(Number(date[1]));
            }
        };

        data.fields?.map((item) => {
            if (item.type === '24_hours_time') {
                if (typeof item.value === 'string') {
                    const time = item.value.split(':');

                    if (selectedSpecificDate === meshBot.BETWEEN && item.name === meshBot.START_TIME) {
                        setTime(setStartHours, setStartMinutes, time);
                    } else if (selectedSpecificDate === meshBot.BETWEEN && item.name === meshBot.END_TIME) {
                        setTime(setEndHours, setEndMinutes, time);
                    } else {
                        setTime(setCustomHours, setCustomMinutes, time);
                    }
                }
            }

            if (item.type === '24_hours_time_array' && selectedSpecificDate === meshBot.TIME_OF_DAY) {
                const time = item.value[0].split(':');
                setTime(setHours, setMinutes, time);
            }
        });
    };

    const onChangeValue = (e, type) => {
        e.target.value =
            e.target.value.length > meshBot.ARRAY_LENGTH_LIMIT ? e.target.value.slice(1, 3) : e.target.value;
        e.target.value = e.target.value < e.target.min ? meshBot.SINGLE_ZERO : e.target.value;
        e.target.value = Number(e.target.value) > Number(e.target.max) ? e.target.max : e.target.value;
        e.target.value =
            e.target.value !== meshBot.ZERO && Number(e.target.value) < meshBot.OPERAND
                ? `0${e.target.value}`
                : e.target.value;
        const data = { time: meshBot.EMPTY_STRING };
        if (type === meshBot.HOURS) {
            data.time = [e.target.value, minutes, seconds].join(':');
        }

        if (type === meshBot.MINUTES) {
            data.time = [hours, e.target.value, seconds].join(':');
        }

        if (type === meshBot.SECONDS) {
            data.time = [hours, minutes, e.target.value].join(':');
        }

        onSetDateValue(data, id, idGroup, null, actionId);
    };

    const onChangeDays = useCallback(
        (e, item) => {
            const data = { item: item, bool: e.target.checked };

            onSetDateValue(data, id, idGroup, null, actionId);
        },
        [id, idGroup],
    );

    const onCustomTime = useCallback(
        (e) => {
            onSetSpecificDate(e, id, meshBot.CUSTOM, idGroup, actionId);
        },
        [id, idGroup],
    );

    const onSelectedMeridiemCustom = useCallback(
        (e, type) => {
            const { value } = e.target;
            let formatTime = Number(customHours);
            let minutes = customMinutes;

            if (selectedSpecificDate === meshBot.BETWEEN) {
                if (type === meshBot.START_TIME) {
                    formatTime = Number(startHours);
                    minutes = meshBot.formattingTime(startMinutes);
                }

                if (type === meshBot.END_TIME) {
                    formatTime = Number(endHours);
                    minutes = meshBot.formattingTime(endMinutes);
                }
            }

            if (value === 'pm' && formatTime < meshBot.HOUR12) {
                formatTime += meshBot.HOUR12;
            } else if (value === 'am' && formatTime === meshBot.HOUR12) {
                formatTime = meshBot.ZERO;
            }

            const data = {
                [type]: `${meshBot.formattingTime(formatTime)}:${meshBot.formattingTime(minutes)}`,
            };

            if (selectedSpecificDate === meshBot.BETWEEN) {
                selectedTimeType[type] = value;

                onSetFieldValue(selectedTimeType, id, meshBot.SELECTED_TIME_TYPE, idGroup, actionId);
            } else {
                onSetFieldValue(value, id, meshBot.SELECTED_TIME_TYPE, idGroup, actionId);
            }

            onSetDateValue(data, id, idGroup, null, actionId);
        },
        [customMinutes, customHours, selectedSpecificDate, startMinutes, startHours, endHours, endMinutes],
    );

    const setCustomTime = (e, type, typeField, typeFunction) => {
        const specificDate = selectedSpecificDate;
        const timeType = selectedTimeType;
        const { value } = e.target;

        let startCustomDate = '';
        let numberForTest = meshBot.LIMITED_MINUTES;

        if (type === 'startHours' || type === 'endHours') {
            numberForTest = meshBot.LIMITED_HOURS;
        }

        if (Number(value) >= numberForTest || Number(value) < 0 || Number(value) % 1 !== 0) {
            return;
        }

        const minutes = () => {
            if (specificDate === meshBot.BETWEEN) {
                if (type === 'startHours') {
                    return meshBot.formattingTime(startMinutes);
                }

                if (type === 'endHours') {
                    return meshBot.formattingTime(endMinutes);
                }
            }

            return customMinutes;
        };

        const hours = () => {
            if (specificDate === meshBot.BETWEEN) {
                if (type === 'startMinutes') {
                    return meshBot.formattingTime(startHours);
                }

                if (type === 'endMinutes') {
                    return meshBot.formattingTime(endHours);
                }
            }

            return customHours;
        };

        const detectTypeTime = (data) => {
            if (specificDate !== meshBot.BETWEEN && timeType === 'pm' && Number(data) !== meshBot.HOUR12) {
                return Number(data) + meshBot.HOUR12;
            } else if (specificDate !== meshBot.BETWEEN && timeType === 'am' && Number(data) === meshBot.HOUR12) {
                return Number(data) - meshBot.HOUR12;
            } else if (
                specificDate === meshBot.BETWEEN &&
                timeType[typeField] === 'pm' &&
                Number(data) !== meshBot.HOUR12
            ) {
                return Number(data) + meshBot.HOUR12;
            } else if (
                specificDate === meshBot.BETWEEN &&
                timeType[typeField] === meshBot.AM &&
                Number(data) === meshBot.HOUR12
            ) {
                return Number(data) - meshBot.HOUR12;
            }

            return data;
        };

        if (type === 'customHours' || type === 'startHours' || type === 'endHours') {
            startCustomDate = `${meshBot.formattingTime(detectTypeTime(value))}:${meshBot.formattingTime(minutes())}`;
        }

        if (type === 'customMinutes' || type === 'endMinutes' || type === 'startMinutes') {
            let hour = Number(detectTypeTime(hours()));
            const isMinutes = Number(value) === meshBot.MINUTES60;

            if (isMinutes && hour === meshBot.HOUR23 && timeType === 'pm') {
                startCustomDate = meshBot.pmDefault;
            } else if (isMinutes && hour === meshBot.HOUR11 && timeType === 'am') {
                startCustomDate = meshBot.amDefault;
            } else if (isMinutes && hour === meshBot.HOUR23 && timeType[typeField] === 'pm') {
                startCustomDate = meshBot.pmDefault;
            } else if (isMinutes && hour === meshBot.HOUR11 && timeType[typeField] === 'am') {
                startCustomDate = meshBot.amDefault;
            } else if (isMinutes && hour !== meshBot.HOUR23 && hour !== meshBot.HOUR11) {
                hour += 1;
                startCustomDate = `${hour}:00`;
            } else if (typeFunction === 'blur') {
                startCustomDate = [
                    hour === 0 ? meshBot.formattingTime(JSON.stringify(hour)) : meshBot.formattingTime(hour),
                    value && value !== '0' ? meshBot.formattingTime(value) : '00',
                ].join(':');
            } else {
                startCustomDate = [meshBot.formattingTime(JSON.stringify(hour)), meshBot.formattingTime(value)].join(
                    ':',
                );
            }
        }

        if (specificDate === meshBot.BETWEEN) {
            onSetDateValue({ [typeField]: startCustomDate }, id, idGroup, null, actionId);
        } else {
            onSetDateValue({ [specificDate]: startCustomDate }, id, idGroup, null, actionId);
        }
    };

    const blurCustomTime = (e, type, typeField) => {
        setCustomTime(e, type, typeField, 'blur');
    };

    const onChangeCustomTime = useCallback(
        (e, type, typeField) => {
            setCustomTime(e, type, typeField, 'change');
        },
        [
            startMinutes,
            endMinutes,
            startHours,
            endHours,
            customHours,
            customMinutes,
            selectedSpecificDate,
            selectedTimeType,
        ],
    );

    const onSelectedCustomDate = useCallback(
        (e, type, field) => {
            const { value } = e.target;
            const keysBlock = Object.keys(blocks[0].blockOptions.method.args);

            const detectType = (data) => {
                let value = '';
                if (data === 'selectMonth') {
                    value = meshBot.DEFAULT_CUSTOM_MONTH;
                } else if (data === 'selectYear') {
                    value = meshBot.DEFAULT_CUSTOM_YEAR;
                }

                return typeof data === 'number' || typeof data === 'string' ? { [type]: value } : data;
            };

            const selectFields = [
                { label: 'selectDay', state: selectDay },
                { label: 'selectMonth', state: selectMonth },
                { label: 'selectYear', state: selectYear },
            ];

            switch (value) {
                case 'everyday':
                    if (selectedSpecificDate === meshBot.BETWEEN) {
                        selectDay[type] = value;

                        if (type === 'startDay') {
                            delete selectMonth.startMonth;
                            delete selectYear.startYear;
                        }

                        if (type === 'endDay') {
                            delete selectMonth.endMonth;
                            delete selectYear.endYear;
                        }

                        selectFields.forEach((item) => {
                            onSetFieldValue(item.state, id, item.label, idGroup, actionId);
                        });
                    } else {
                        selectFields.forEach((item) => {
                            onSetFieldValue(
                                item.label === meshBot.SELECT_DAY ? value : '',
                                id,
                                item.label,
                                idGroup,
                                actionId,
                            );
                        });
                    }

                    onSetDateValue({ [type]: value }, id, idGroup, meshBot.CLEAR, actionId);

                    return;

                case meshBot.DEFAULT_CUSTOM_MONTH:
                    if (selectedSpecificDate === meshBot.BETWEEN) {
                        selectMonth[type] = value;

                        if (type === 'startMonth') {
                            delete selectYear.startYear;
                        }

                        if (type === 'endMonth') {
                            delete selectYear.endYear;
                        }

                        selectFields.forEach((item) => {
                            if (item.label !== meshBot.SELECT_DAY) {
                                onSetFieldValue(item.state, id, item.label, idGroup, actionId);
                            }
                        });
                    } else {
                        onSetFieldValue(value, id, meshBot.SELECT_MONTH, idGroup, actionId);
                        onSetFieldValue('', id, meshBot.SELECT_YEAR, idGroup, actionId);
                    }

                    onSetDateValue({ [type]: value }, id, idGroup, meshBot.CLEAR, actionId);

                    return;

                case meshBot.DEFAULT_CUSTOM_YEAR:
                    if (selectedSpecificDate === meshBot.BETWEEN) {
                        selectYear[type] = value;

                        if (type === 'startYear') {
                            delete selectYear.startYear;
                        }

                        if (type === 'endYear') {
                            delete selectYear.endYear;
                        }

                        selectFields.forEach((item) => {
                            if (item.label === meshBot.SELECT_YEAR) {
                                onSetFieldValue(item.state, id, item.label, idGroup, actionId);
                            }
                        });
                    } else {
                        onSetFieldValue('', id, meshBot.SELECT_YEAR, idGroup, actionId);
                    }

                    onSetDateValue({ [type]: value }, id, idGroup, meshBot.CLEAR, actionId);

                    return;

                default:
                    if (keysBlock.includes(type)) {
                        onSetDateValue({ [type]: value }, id, idGroup, null, actionId);
                    } else {
                        onSetDateValue({ [type]: value }, id, idGroup, meshBot.ADD, actionId);
                    }

                    if (selectedSpecificDate === meshBot.BETWEEN) {
                        let data = selectDay;

                        if (field === meshBot.SELECT_MONTH) {
                            data = detectType(selectMonth);
                        } else if (field === meshBot.SELECT_YEAR) {
                            data = detectType(selectYear);
                        }

                        data[type] = value;
                        onSetFieldValue(data, id, field, idGroup, actionId);
                    } else {
                        onSetFieldValue(value, id, field, idGroup, actionId);
                    }

                    return;
            }
        },
        [blocks, idGroup, id, selectedSpecificDate, selectDay, selectMonth, selectYear],
    );

    const handleChangeYear = useCallback(
        (e) => {
            const value = getIntegerFromNumberInput(e);
            const data = { startYear: Number(value), endYear: Number(value) };

            onSetDateValue(data, id, idGroup, null, actionId);
        },
        [id, idGroup],
    );

    const onChangeWeeksofTheMonth = useCallback(
        (e, item) => {
            let data = {};

            if (item === meshBot.DAYS_IN_WEEK) {
                data = { item: meshBot.LAST_WEEK_NAME, bool: e.target.checked };
            } else {
                data = { item: item, bool: e.target.checked };
            }

            onSetDateValue(data, id, idGroup, null, actionId);
        },
        [id, idGroup],
    );

    const onChangeWeeksOfTheYear = useCallback(
        (e, item) => {
            let data = {};

            if (item === meshBot.MAXIMUM_NUMBER_WEEKS_YEAR) {
                data = { item: meshBot.LAST_WEEK_NAME, bool: e.target.checked };
            } else {
                data = { item: item, bool: e.target.checked };
            }

            onSetDateValue(data, id, idGroup, null, actionId);
        },
        [id, idGroup],
    );

    const getTime = (time) => {
        if (Array.isArray(time)) {
            const [value] = time;

            time = value;
        }

        const [hour, minute] = time.split(':');
        let hours = hour % meshBot.HOUR12;

        hours = hours ? hours : meshBot.HOUR12;

        return {
            hours: hours,
            minutes: minute,
        };
    };

    const getMeridiem = () => {
        const hours = blocks?.[0]?.fields?.[1]?.value?.[0].split(':')[0];

        return hours >= meshBot.HOUR12;
    };

    const onChangeIsOnce = (value, name, modifier) => {
        let params;

        if (name === meshBot.TIME_NODE) {
            // eslint-disable-next-line
            let [hour, minute] = value;

            if (modifier === meshBot.AM && hour === meshBot.HOUR12) {
                hour = meshBot.ZERO;
            }

            if (modifier === meshBot.PM && hour !== meshBot.HOUR12) {
                hour = parseInt(hour) + meshBot.HOUR12;
            }

            params = { hour: parseInt(hour), minute: parseInt(minute) };
        }

        if (['day', 'month', 'year'].includes(name)) {
            params = { [name]: parseInt(Math.abs(value)) };
        }

        onSetDateValue(params, id, idGroup, null, actionId);
    };

    return (
        <>
            {className === 'days' && (
                <Month
                    id={id}
                    actionId={actionId}
                    idGroup={idGroup}
                    blocks={blocks}
                    onSetDateValue={onSetDateValue}
                    onChange={onChangeDays}
                    isArrayDate={isArrayDate}
                    isSupportsUpdatedAdvancedScenesForDays={isSupportsUpdatedAdvancedScenesForDays}
                    typeMeshbot={typeMeshbot}
                />
            )}

            <>
                {selectedFieldDate !== 'weekdays' &&
                    selectedFieldDate !== 'days' &&
                    selectedFieldDate !== 'endYear' &&
                    selectedFieldDate !== 'weeks' &&
                    selectedFieldDate !== 'yearWeeks' &&
                    selectedFieldDate !== 'custom' && (
                        <>
                            <SelectComponent
                                value={selectedSpecificLabel ? selectedSpecificLabel : ''}
                                onChange={(e) => onSolsticeTime(e)}
                                label={info.hints.event}
                                info={info.text.event}
                            >
                                {specificDate.map((item) => {
                                    return (
                                        <MenuItem key={item.name} value={item.name}>
                                            {t(item.value)}
                                        </MenuItem>
                                    );
                                })}
                            </SelectComponent>

                            {selectedSpecificLabel === meshBot.RISE || selectedSpecificLabel === meshBot.SET ? (
                                <>
                                    <TimeOffsetBlock
                                        id={id}
                                        idGroup={idGroup}
                                        blocks={blocks}
                                        hours={hours}
                                        minutes={minutes}
                                        seconds={seconds}
                                        selectedFieldDate={selectedFieldDate}
                                        onChangeValue={onChangeValue}
                                        onSetSpecificDate={onSetSpecificDate}
                                        addedOffset={addedOffset}
                                        offsetDirection={offsetDirection}
                                        onChangeDisplayOffset={handleChangeDisplayOffset}
                                        onSelectOffsetDirection={handleSelectOffsetDirection}
                                    />
                                    <SelectComponent
                                        value={selectedSpecificDate ?? ''}
                                        onChange={(e) => onSetSpecificDate(e, id, selectedFieldDate, idGroup, actionId)}
                                        label={info.hints.value}
                                        info={info.text.valueSpecialTimeOfDay}
                                    >
                                        {specificDateLabel[selectedSpecificLabel].map((item) => {
                                            return (
                                                <MenuItem key={item.name} value={item.name}>
                                                    {t(item.value)}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectComponent>
                                </>
                            ) : null}
                        </>
                    )}
                {selectedFieldDate === 'custom' && (
                    <>
                        <SelectComponent
                            value={selectedSpecificDate !== '' ? selectedSpecificDate : ''}
                            onChange={(e) => onCustomTime(e)}
                            label={info.hints.valueType}
                            info={info.text.valueType}
                        >
                            {customDate.map((item) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {t(item.value)}
                                    </MenuItem>
                                );
                            })}
                        </SelectComponent>
                        {selectedSpecificDate === meshBot.TIME_OF_DAY && (
                            <HoursAndMinutesSelect
                                onChangeCustomTime={(value, modifier) =>
                                    onChangeIsOnce(value, meshBot.TIME_NODE, modifier)
                                }
                                customHours={getTime(blocks?.[0]?.fields?.[1]?.value).hours}
                                customMinutes={getTime(blocks?.[0]?.fields?.[1]?.value).minutes}
                                isPm={getMeridiem()}
                                isCloud={false}
                            />
                        )}
                        {selectedSpecificDate === meshBot.END_TIME || selectedSpecificDate === meshBot.START_TIME ? (
                            <SectionCustom
                                type={selectedSpecificDate}
                                customHours={customHours}
                                customMinutes={customMinutes}
                                onCustomTime={onCustomTime}
                                onChangeCustomTime={onChangeCustomTime}
                                onSelectedCustomDate={onSelectedCustomDate}
                                onSelectedMeridiemCustom={onSelectedMeridiemCustom}
                                selectedTimeType={selectedTimeType}
                                selectYear={selectYear}
                                selectMonth={selectMonth}
                                selectDay={selectDay}
                                onBlurCustomTime={blurCustomTime}
                            />
                        ) : null}
                        {selectedSpecificDate === meshBot.BETWEEN ? (
                            <div>
                                {meshBot.betweenList.map((item) => {
                                    return (
                                        <div key={item.id} className="trigger-block__custom-section">
                                            <span className="custom__text">{t(item.label)}</span>
                                            <SectionCustom
                                                type={item.value}
                                                customHours={item.value === meshBot.START_TIME ? startHours : endHours}
                                                customMinutes={
                                                    item.value === meshBot.START_TIME ? startMinutes : endMinutes
                                                }
                                                onCustomTime={onCustomTime}
                                                onChangeCustomTime={onChangeCustomTime}
                                                onSelectedCustomDate={onSelectedCustomDate}
                                                onSelectedMeridiemCustom={onSelectedMeridiemCustom}
                                                selectedTimeType={selectedTimeType}
                                                selectYear={selectYear}
                                                selectMonth={selectMonth}
                                                selectDay={selectDay}
                                                onBlurCustomTime={blurCustomTime}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                )}
            </>
            {selectedSpecificDate === 'isDate' && selectedFieldDate === 'endYear' && (
                <Year year={year} onHandleChangeYear={handleChangeYear} />
            )}
            {className === 'weekdays' && (
                <Week
                    id={id}
                    actionId={actionId}
                    idGroup={idGroup}
                    blocks={blocks}
                    onSetDateValue={onSetDateValue}
                    onChange={onChangeDays}
                    isArrayDate={isArrayDate}
                    isSupportsUpdatedAdvancedScenesForDays={isSupportsUpdatedAdvancedScenesForDays}
                    typeMeshbot={typeMeshbot}
                />
            )}
            {className === 'weeks' && (
                <WeekOfTheMonth onChange={onChangeWeeksofTheMonth} isArrayWeek={isArrayWeek} t={t} />
            )}
            {className === 'yearWeeks' && (
                <WeeksOfTheYear onChange={onChangeWeeksOfTheYear} isArrayYear={isArrayYear} t={t} />
            )}
        </>
    );
};

export default TemplateDateTimeBlocks;
