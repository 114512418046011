import React, { useState } from 'react';
import { Button, Dialog, TextField } from '@material-ui/core';

import { useFullScreen } from '../../../../../hooks/useFullScreen';
import { INTEGRATION_BUTTON_TEXT, NUCAL_INTEGRATION_CONTENT } from '../../constants';

import style from '../EnrollmentsList/EnrollmentListItem.module.scss';
import { useTranslate } from '../../../../../features/languages';

const EnrollmentEditDialog = (props) => {
    const { t } = useTranslate();
    const {
        isShowEditDialog,
        setIsShowEditDialog,
        setIsShowDeleteConfirmDialog,
        abstract: { name },
        updateAbstractName,
    } = props;
    const { SAVE, DELETE, CANCEL } = INTEGRATION_BUTTON_TEXT;
    const { NAME_FIELD, DIALOG } = NUCAL_INTEGRATION_CONTENT;
    const fullScreen = useFullScreen(DIALOG.BREAKPOINT_FULL_SCREEN);
    const [abstractName, setAbstractName] = useState(name);

    const handleCloseEditDialog = () => {
        setAbstractName(name);
        setIsShowEditDialog(false);
    };

    const handleClickSave = () => updateAbstractName(abstractName);
    const handleOpenConfirmDialog = () => setIsShowDeleteConfirmDialog(true);
    const handleChangeAbstractName = (e) => setAbstractName(e.target.value);

    return (
        <Dialog
            fullWidth
            maxWidth={DIALOG.MAX_WIDTH}
            fullScreen={fullScreen}
            open={isShowEditDialog}
            onClose={handleCloseEditDialog}
        >
            <div className={style.settingsModalContent}>
                <div className={style.title}>{name}</div>
                <div className={style.fieldBox}>
                    <TextField
                        fullWidth
                        label={t(NAME_FIELD.LABEL)}
                        value={abstractName}
                        onChange={handleChangeAbstractName}
                    />
                </div>
                <div className={style.subtext}>{t(DIALOG.SUBTEXT)}</div>
                <div className={style.deleteButtonBox}>
                    <Button variant="contained" fullWidth onClick={handleOpenConfirmDialog}>
                        {t(DELETE)}
                    </Button>
                </div>
                <div className={style.buttonContainer}>
                    <Button variant="outlined" fullWidth color="primary" onClick={handleCloseEditDialog}>
                        {t(CANCEL)}
                    </Button>
                    <Button fullWidth onClick={handleClickSave} color="primary" variant="contained">
                        {t(SAVE)}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default EnrollmentEditDialog;
