import React from 'react';

import TreeItemLabel from '../TreeItemLabels/TreeItemLabel';
import TreeItemComponent from '../components/TreeItemComponent';

const RegularTypeTreeElement = ({ treeElementValue, treeElementName, prevPartPath, elementData }) => {
    const treeElementProperties = Object.entries(treeElementValue?.properties);
    const labelJSX = (
        <TreeItemLabel treeItemName={treeElementName} elementData={elementData} treeItemValue={treeElementValue} />
    );

    return (
        <TreeItemComponent
            nodeId={treeElementValue.elementId}
            treeElementProperties={treeElementProperties}
            prevPartPath={prevPartPath}
            labelComponent={labelJSX}
        />
    );
};

export default RegularTypeTreeElement;
