import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as meshbot from '../../../../constants/MeshbotConstant';
import * as script from '../../../../constants/ScriptConstants';
import { toast, TOAST_TYPE } from '../../../../components/Toast';
import ScriptForm from '../components/ScriptForm';

const EditScript = (props) => {
    const { serial, scriptNotification, singleLuaScript, errorsScript, history, MeshBotAction } = props;

    useEffect(() => {
        MeshBotAction.getLuaScript(serial, { _id: props.match.params.id });

        return () => {
            MeshBotAction.clearEditScript();
        };
    }, []);

    useEffect(() => {
        if (scriptNotification === meshbot.SET_SUCCESS) {
            history.replace(script.ROUTE_PRIVATE);
            toast('Script has been successfully added', { type: TOAST_TYPE.SUCCESS });
        }

        if (scriptNotification === meshbot.REJECTED) {
            toast(errorsScript, { type: TOAST_TYPE.ERROR });
        }
        MeshBotAction.clearScriptNotification();
    }, [scriptNotification]);

    return (
        <ScriptForm
            serial={serial}
            type={script.SCRIPT_EDIT}
            id={props.match.params.id}
            history={history}
            singleLuaScript={singleLuaScript}
            MeshBotAction={MeshBotAction}
        />
    );
};

EditScript.proptypes = {
    serial: PropTypes.string,
    // TODO: rename?
    scriptNotification: PropTypes.string,
    // TODO: rename?
    errorsScript: PropTypes.string,
    // TODO: lusScript - typo, rename property
    singleLuaScript: PropTypes.object,
    history: PropTypes.object,
    // TODO: rename/remove?
    MeshBotAction: PropTypes.object,
};

export default EditScript;
