import React from 'react';
import { Switch } from '@material-ui/core';
import { FieldDescription } from './FieldDescription';

export const SwitchComponent = ({ label, isRequired, propertyName, value, onChange }) => {
    const handleChange = (event) => {
        onChange(event.target.checked, event.target.name);
    };

    return (
        <>
            <FieldDescription description={label} isRequired={isRequired} />
            <Switch name={propertyName} color="primary" checked={!!value} onChange={handleChange} />
        </>
    );
};
