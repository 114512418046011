import React, { memo } from 'react';
import { LinearProgress } from '@material-ui/core';

import {
    CURRENT_VALUE,
    EZLOGIC_TITLE_CONVERSION_MESSAGE_FLOAT,
    EZLOGIC_TITLE_CONVERSION_MESSAGE_LOCAL,
    LOCAL_FORM,
} from '../../../constants';
import { checkIfValueIsFloat, getСurrentValueWithType } from '../utils';

import styles from '../styles.module.scss';
import { COLON, NULL } from '../../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_REQUEST_TO_GET_CURRENT_VALUE_FAILED } from '../../../../../../constants/language_tokens';

/**
 * Presentation component. Its responsibility to render the current value of the variable
 */
const CurrentValue = ({ currentValueData, calledFrom }) => {
    const { t } = useTranslate();

    if (!currentValueData) {
        return;
    }

    if (currentValueData.error) {
        return <div className={styles.error}>{t(EZLOGIC_TITLE_REQUEST_TO_GET_CURRENT_VALUE_FAILED)}</div>;
    }

    if (currentValueData.isLoading && currentValueData.value === undefined) {
        return (
            <div className={styles.currentValueBoxWithProgressBar}>
                <div className={styles.currentValueTitle}>{CURRENT_VALUE}</div>
                <LinearProgress variant="query" />
            </div>
        );
    }

    const currentValue = getСurrentValueWithType(currentValueData, calledFrom);
    const floatValue = checkIfValueIsFloat(currentValueData);

    if (!currentValue) {
        return (
            <div title={currentValue} className={styles.currentValueBoxWithProgressBar}>
                <div className={styles.currentValueTitle}>
                    {CURRENT_VALUE}
                    {COLON}
                </div>
                <span className={styles.currentValueNull}>{NULL}</span>
            </div>
        );
    }

    return (
        <>
            <div title={currentValue} className={styles.currentValueBox}>
                <div className={styles.currentValueTitle}>
                    {CURRENT_VALUE} {currentValue}
                </div>
                {calledFrom === LOCAL_FORM ? (
                    <p className={styles.floatToIntegerLabel}>{t(EZLOGIC_TITLE_CONVERSION_MESSAGE_LOCAL)}</p>
                ) : (
                    <p className={styles.floatToIntegerLabel}>
                        {floatValue && t(EZLOGIC_TITLE_CONVERSION_MESSAGE_FLOAT)}
                    </p>
                )}
            </div>
        </>
    );
};

export default memo(CurrentValue);
