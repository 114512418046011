import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../components/InputMaterial';
import { getScriptResponse } from '../../utils';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_SCRIPTS_RESULT } from '../../../../../constants/language_tokens';

import './styles.scss';

const ResponseForm = (props) => {
    const { scriptResponse } = props;
    const { t } = useTranslate();

    return (
        <div className="response-form">
            <Input
                otherClass="response-form__input"
                label={t(EZLOGIC_TITLE_SCRIPTS_RESULT)}
                multiline
                error={scriptResponse?.error && true}
                value={t(getScriptResponse(scriptResponse)) ?? ' '}
            />
        </div>
    );
};

ResponseForm.propTypes = {
    scriptResponse: PropTypes.object,
};

export default ResponseForm;
