import React from 'react';
import { Dialog } from '@material-ui/core';

import EzloNotificationTemplateTypes from '../EzloNotificationTemplateTypes';

const EzloNotificationTemplateSelectModal = (props) => {
    const { onHandleOpenNotificationTemplateTypesModal, isModalVisible } = props;

    return (
        <Dialog open={isModalVisible} onClose={onHandleOpenNotificationTemplateTypesModal}>
            <EzloNotificationTemplateTypes onClose={onHandleOpenNotificationTemplateTypesModal} />
        </Dialog>
    );
};

export default EzloNotificationTemplateSelectModal;
