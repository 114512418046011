import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import app from './app';
import account from './account';
import backups from './backups';
import main from './main';
import ezlos from './ezlos';
import ezlo from './ezlo';
import ezloCustomization from './ezloCustomization';
import supportData from './ezloSupport';
import systemHistory from './ezloSystemHistory';
import oem from './ezloInitialization';
import customizationData from './customizationData';
import ezloPolicy from '../features/EzloPolicies/reducers/EzloPoliciesReducer';
import { installerAccessStoreReducer } from '../features/InstallerAccess/reducers/installerAccessRights';
import gateways from './gateways';
import groups from './groups';
import wizard from './wizard';
import modals from './modals';
import ezloRules from './reducerRules';
import meshBot from './meshBot';
import plugins from './plugins';
import virtualDeviceManager from './virtualDeviceManager';
import integrations from './integrations';
import notifications from './notifications';
import navigation from './navigation';
import toasts from './toasts';
import users from './users';
import deviceSettings from './deviceSettings';
import subscriptions from './subscriptions';
import deviceGroups from '../containers/Ezlo/EzloDeviceGroups/reducer/deviceGroups';
import meshBotV2 from './meshBotV2';
import trackRunScenes from './trackRunScenes';
import staticData from './staticData';
import { reducer as wizards } from 'react-redux-wizard';
import { mediaStoreReducer } from '../features/MediaStoragePage/reducer/media';
import { KVS_NAME as KVS, kvsReducer } from './kvs';
import { LABELS, labelsReducer } from './labels';

function reduceReducers(...reducers) {
    return (previous, current) => reducers.reduce((p, r) => r(p, current), previous);
}

const rootReducer = (history) =>
    combineReducers({
        app,
        account,
        ezlos,
        ezlo,
        gateways,
        groups,
        ezloRules,
        wizards: reduceReducers(wizards, wizard),
        router: connectRouter(history),
        /*  routing: routerReducer,*/
        modals,
        backups,
        virtualDeviceManager,
        main,
        meshBot,
        notifications,
        plugins,
        toasts: toasts.reducer,
        users,
        integrations,
        deviceSettings,
        form: formReducer,
        ezloCustomization,
        mediaStore: mediaStoreReducer,
        customizationData,
        ezloPolicy,
        supportData,
        subscriptions,
        navigation,
        deviceGroups,
        oem,
        systemHistory,
        meshBotV2,
        [KVS]: kvsReducer,
        [LABELS]: labelsReducer,
        trackRunScenes: trackRunScenes.reducer,
        staticData,
        installerAccessStore: installerAccessStoreReducer,
    });

export default rootReducer;
