import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { NEXT, READ_AND_AGREE_TO_THE_PRIVACY_POLICY } from '../../constants';
import { parseMTML } from '../../utils';

import styles from '../../components/EzloPoliciesLayout/ezloPolicies.module.scss';

const PrivacyPolicy = ({ policies, onAccept, onNext, accepted }) => {
    const html = parseMTML(policies?.LongDescription);

    return (
        <div className={styles.innermain}>
            <div className={styles.title}>{policies?.title}</div>
            <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: html }}></p>
            </div>
            <div className={styles.footer}>
                <FormControlLabel
                    control={<Checkbox onChange={onAccept} className={styles.checkboxControl} color="primary" />}
                    label={<span className={styles.label}>{READ_AND_AGREE_TO_THE_PRIVACY_POLICY}</span>}
                />

                <div className={styles.button_wrapper}>
                    <Button
                        className={styles.button}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onNext}
                        disabled={!accepted}
                    >
                        {NEXT}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
