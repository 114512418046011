import { useState, useCallback } from 'react';
import { apiAbstractCommand, apiCodeTestRun } from '../../../../../../api/apiScenes';
import { bugsnagNotifyWrapper } from '../../../../../ErrorBoundary/utils';
import { generatePaasAbstractCommand } from '../../../../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { generateCodeTestRunCommand } from '../utils';

export const useFetchCodeTestRun = (accountUuid, method, fields, code, setIsDialogOpen) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [response, setResponse] = useState();

    const callbackMemoized = useCallback(async () => {
        setLoading(true);
        setError(undefined);
        try {
            const abstractCommand = generatePaasAbstractCommand({ accountUuid, method, fields });
            const rawJsonContent = await apiAbstractCommand(abstractCommand);
            const data = (rawJsonContent && rawJsonContent.data) || {};
            const codeTestCommand = generateCodeTestRunCommand(data, code);
            const response = await apiCodeTestRun(codeTestCommand);

            if (response.status === 0) {
                setError(response.data.error_message);
            }
            const responseState = response?.data?.state;
            setResponse(responseState);
            if (responseState && setIsDialogOpen) {
                setIsDialogOpen(true);
            }
        } catch (error) {
            setError(error);
            bugsnagNotifyWrapper(error);
        } finally {
            setLoading(false);
        }
    }, [accountUuid, method, fields, code]);

    return { loading, error, response, callbackMemoized };
};
