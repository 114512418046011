import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { routerActions } from 'react-router-redux';
import Form from '../components/AuthorizationPagesForm';
import Container from '../components/Container';
import { useFormik } from 'formik';
import { forgotPasswordValidationSchema } from '../validationSchema';
import ResponseDialog from '../components/ResponseDialog';
import { useTranslate } from '../../../features/languages';
import { FORGOT_PASSWORD_PAGE_TITLE } from '../../../constants/language_tokens';

const ForgotPassword = (props) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslate();

    const forgotUserPassword = ({ email }) => {
        const { forgotPassword } = props.redux.actions.GenericActions;
        forgotPassword(email, setOpen, setIsLoading, t);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: forgotUserPassword,
    });

    return (
        <Container isForgotPassword title={t(FORGOT_PASSWORD_PAGE_TITLE)}>
            <Form isLoading={isLoading} isForgotPassword formik={formik} />
            <ResponseDialog setOpen={setOpen} open={open} />
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName != '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });
    obj.routerActions = bindActionCreators(routerActions, dispatch);

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});

export default connect(null, mapDispatchToProps, mergeProps)(ForgotPassword);
