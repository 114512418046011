import React from 'react';
import classes from 'classnames';

import { radio_button, input_label, active, trigger_label } from './GroupRadioButton.module.scss';
const GroupRadioButton = (props) => {
    const { id, label, name, value, activeGroup, optionType, onClick, triggerType } = props;

    const changeType = (type) => type === value;
    const className = classes(
        input_label,
        { [active]: changeType(optionType), [trigger_label]: triggerType },
        { activeGroup: activeGroup },
    );

    return (
        <div className={radio_button}>
            <input type="radio" name={name} value={value} id={id} onClick={(e) => onClick(e)} />
            <label htmlFor={id} className={className}>
                {label}
            </label>
        </div>
    );
};

export default GroupRadioButton;
