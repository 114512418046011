import React, { memo } from 'react';
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';

import MeshBotSelectField from '../../../../../components/MeshBotSelectField/MeshBotSelectField';

import {
    getHouseModeWhenBlock,
    getSelectedHouseModes,
    getSelectedNode,
    getLabelByOemId,
    getModesListByOemId,
    getModeNodesByOemId,
} from './utils';
import { info } from '../../utils';
import oemManager from '../../../../../../src/services/oem';

import styles from './../../../../../components/MeshBotFieldStyleBox/styles.module.scss';
import { useTranslate } from '../../../../../features/languages';

const TriggerHouseMode = ({ id, blocks, idGroup, onUpdateHouseModeTrigger }) => {
    const oemId = oemManager.oem.getOemId();
    const selectNode = getSelectedNode(blocks);
    const selectedHouseModes = getSelectedHouseModes(blocks, oemId);
    const { t } = useTranslate();
    const updateHouseMode = (idMeshBot, block, idGroup) => {
        onUpdateHouseModeTrigger(idMeshBot, block, idGroup);
    };

    const handleChangeNode = (e) => {
        const node = e.target.value;
        updateHouseMode(id, getHouseModeWhenBlock(node, []), idGroup);
    };

    const handleChangeHouseModes = (event) => {
        const houseModes = event.target.value;
        const houseModesIdList = houseModes.map((mode) => mode.value);
        updateHouseMode(id, getHouseModeWhenBlock(selectNode, houseModesIdList), idGroup);
    };

    const getRenderValue = (selectedHouseModes) => selectedHouseModes.map((mode) => t(mode.name)).join(', ');

    return (
        <>
            <MeshBotSelectField
                label={info.hints.node}
                selectValue={selectNode}
                handleChange={handleChangeNode}
                infoText={info.text.houseModes}
            >
                {getModeNodesByOemId(oemId).map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.value}>
                            {t(item.label)}
                        </MenuItem>
                    );
                })}
            </MeshBotSelectField>
            {selectNode && (
                <MeshBotSelectField
                    label={t(getLabelByOemId(oemId))}
                    selectValue={selectedHouseModes}
                    handleChange={handleChangeHouseModes}
                    infoText={info.text.houseModes}
                    selectionAttributes={{
                        renderValue: getRenderValue,
                        multiple: true,
                    }}
                >
                    {getModesListByOemId(oemId).map((mode) => (
                        <MenuItem className={styles.menuItem} key={mode.id} value={mode}>
                            <ListItemIcon>
                                <Checkbox
                                    color="primary"
                                    checked={!!selectedHouseModes.find((i) => i.id === mode?.id)}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t(mode.name)} />
                        </MenuItem>
                    ))}
                </MeshBotSelectField>
            )}
        </>
    );
};

export default memo(TriggerHouseMode);
