import React from 'react';
import { IconButton, Switch } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Copy from '@material-ui/icons/FileCopy';
import { Delete } from '@material-ui/icons';

import { CLOUD_NOTIFICATION, DEVICE_STATE, ITEM, NOT, STATIC } from '../../../constants/MeshbotConstant';
import { getVariables } from '../EzloNotificationTemplate/utils';
import {
    DATA_FROM_BLOCK_WITH_NAME_AV,
    DATA_FROM_BLOCK_WITH_NAME_STATIC,
    DATA_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
} from '../../../constants/NotificationTemplates';
import {
    EZLOGIC_TITLE_ACTIVE,
    EZLOGIC_TITLE_COLUMN_TYPE,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DUPLICATE,
    EZLOGIC_TITLE_EDIT,
    EZLOGIC_TITLE_TEMPLATE_NAME,
} from '../../../constants/language_tokens';
import { NotificationTemplateNameCell } from './components/NotificationTemplateNameCell';
import { NOTIFICATION_TEMPLATE_ABSTRACT_UUID } from '../EzloMeshbots/constants';

export const findCurrentVariables = (listCapabilityNamesForNotificationTemplate, currentCapability) => {
    return listCapabilityNamesForNotificationTemplate.filter(
        (capability) => capability.capability_name === currentCapability,
    );
};

export const returnNotificationTemplateObjectForTriggersSection = (
    meshbotDefinition = {},
    listCapabilityNamesForNotificationTemplate = [],
) => {
    if (meshbotDefinition?.length > 1 && meshbotDefinition?.[DATA_FROM_FIRST_BLOCK]?.name !== STATIC) {
        return meshbotDefinition.map((trigger, index) => {
            const parametersForFindDefinition =
                trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]
                    ?.capability ||
                trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                    DATA_FROM_VALUE_FROM_FIRST_BLOCK
                ]?.capability;

            return {
                blocks: trigger.name !== NOT ? [trigger] : trigger?.parameters,
                not: trigger.name === NOT,
                selectedFieldTrigger: DEVICE_STATE,
                nameSubBlock:
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]
                        ?.capability ||
                    trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]
                        ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability,
                capability:
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]
                        ?.capability ||
                    trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]
                        ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability,
                capabilities: listCapabilityNamesForNotificationTemplate,
                currentVariable:
                    trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]
                        ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.variable ||
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]
                        ?.variable,
                notificationTemplateOption: ITEM,
                selectedOperator:
                    trigger?.name !== NOT
                        ? trigger?.name
                        : trigger?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.name,
                variableValue:
                    trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]
                        ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.value ||
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.value,
                currentVariableType:
                    trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]
                        ?.parameters[DATA_FROM_FIRST_BLOCK]?.transform ||
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]?.parameters?.[DATA_FROM_FIRST_BLOCK]
                        ?.transform,
                variables: getVariables(
                    trigger,
                    findCurrentVariables(listCapabilityNamesForNotificationTemplate, parametersForFindDefinition),
                ),
                id: String(index),
                isValidRgbValue: true,
                idDev:
                    trigger?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]
                        ?.abstract || NOTIFICATION_TEMPLATE_ABSTRACT_UUID,
            };
        });
    } else {
        const parametersForFindDefinition =
            meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                DATA_FROM_VALUE_FROM_FIRST_BLOCK
            ]?.capability ||
            meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]
                ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability;

        return [
            {
                blocks:
                    meshbotDefinition.triggers.name === NOT
                        ? meshbotDefinition?.triggers?.parameters
                        : [meshbotDefinition?.triggers],
                selectedFieldTrigger: DEVICE_STATE,
                nameSubBlock:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.capability ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_AV
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability,
                capability:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.capability ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_AV
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability,
                capabilities: listCapabilityNamesForNotificationTemplate,
                not: meshbotDefinition.triggers.name === NOT,
                currentVariable:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.variable ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_AV
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.variable,
                notificationTemplateVariableValue:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.value,
                notificationTemplateOption: ITEM,
                selectedOperator:
                    meshbotDefinition?.triggers.name !== NOT
                        ? meshbotDefinition?.triggers?.name
                        : meshbotDefinition?.triggers?.parameters[DATA_FROM_FIRST_BLOCK]?.name,
                target:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]?.parameters ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_STATIC
                    ]?.parameters,
                variableValue:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.value ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_STATIC
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.value,
                currentVariableType:
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.transform ||
                    meshbotDefinition?.triggers?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[
                        DATA_FROM_BLOCK_WITH_NAME_STATIC
                    ]?.parameters?.[DATA_FROM_FIRST_BLOCK]?.transform,
                variables: findCurrentVariables(listCapabilityNamesForNotificationTemplate, parametersForFindDefinition)
                    .length
                    ? Object.keys(
                          findCurrentVariables(
                              listCapabilityNamesForNotificationTemplate,
                              parametersForFindDefinition,
                          )?.[DATA_FROM_FIRST_BLOCK]?.definition?.variables,
                      )
                    : [],
                idDev:
                    meshbotDefinition?.triggers?.parameters[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.abstract || NOTIFICATION_TEMPLATE_ABSTRACT_UUID,
                id: 0,
                isValidRgbValue: true,
            },
        ];
    }
};

export const returnNotificationTemplateObjectForActionsSection = (meshbotDefinition) => {
    return [
        {
            selectedFieldTrigger: CLOUD_NOTIFICATION,
            notification: meshbotDefinition,
        },
    ];
};

export const buildNotificationTemplatesTableColumns = (onDuplicate, onEdit, handleSetActive, onDelete) => {
    return [
        {
            field: 'title',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_TEMPLATE_NAME,
            headerClassName: 'grid-empty-field grid-field',
            minWidth: 400,
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <NotificationTemplateNameCell
                        onClick={() => onEdit(params.row.meshbot_definition, params.row.uuid)}
                        name={params.value}
                    />
                );
            },
        },
        {
            field: 'type',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_COLUMN_TYPE,
            headerClassName: 'grid-empty-field grid-field',
            minWidth: 200,
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div className="align-center">
                        <span>{params.value}</span>
                    </div>
                );
            },
        },
        {
            field: 'auto_create',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_ACTIVE,
            headerClassName: 'grid-empty-field grid-field',
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Switch
                        className="align-center"
                        color="primary"
                        checked={Boolean(Number(params.row.auto_create))}
                        onChange={(e) => handleSetActive(e, params.row.uuid)}
                    />
                );
            },
        },
        {
            field: 'edit',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_EDIT,
            headerClassName: 'grid-empty-field grid-field',
            width: 100,
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => onEdit(params.row.meshbot_definition, params.row.uuid)}
                        className="meshbot-cell-icon align-center"
                    >
                        <IconButton>
                            <Edit color="primary" />
                        </IconButton>
                        <span>{params.row.name}</span>
                    </div>
                );
            },
        },
        {
            field: 'duplicate',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_DUPLICATE,
            headerClassName: 'grid-empty-field grid-field',
            width: 130,
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => onDuplicate(params.row.uuid, params.row.title)}
                        className="meshbot-cell-icon align-center"
                    >
                        <IconButton>
                            <Copy color="primary" />
                        </IconButton>
                        <span>{params.row.name}</span>
                    </div>
                );
            },
        },
        {
            field: 'delete',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_DELETE,
            headerClassName: 'grid-empty-field grid-field',
            width: 100,
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div
                        onClick={() => {
                            onDelete(params.row.uuid);
                        }}
                        className="meshbot-cell-icon align-center"
                    >
                        <IconButton>
                            <Delete color="primary" />
                        </IconButton>
                        <span>{params.row.name}</span>
                    </div>
                );
            },
        },
    ];
};
