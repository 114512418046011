import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { NAMES_OF_CATEGORIES } from '../../containers/Ezlo/EzloDeviceGroups/components/DeviceGroupTreeContainerPriorityOrder/constants/index';
import {
    formatNameWithUnderscores,
    getComparingValue,
    getFilteredDeviceItemsForTrigger,
    getSelectedComparator,
} from '../../helpers/helpersMeshBot';
import {
    BLOCK_FIELD_TYPES,
    PIN_CODE_USED,
    PIN_CODE_MENU_ITEM_CONTENT,
    USER_LOCK_OPERATION,
    VALUE,
    EMPTY_STRING,
    LOCK,
} from '../../constants/MeshbotConstant';

import { infoText } from './styles.module.scss';
import { EZLOGIC_TITLE_CURRENT_VALUE, EZLOGIC_TITLE_NO_OPTIONS } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const SelectDeviceCapability = (props) => {
    const {
        setIsVisibleUserLockOperationBlock,
        deviceId,
        selectedCapability,
        onDataUpdate,
        pinCodeMenuItemValue,
        setPinCodeMenuItemValue,
        setSelectedPinCode,
        setSelectedAction,
    } = props;

    const ezloState = useSelector((state) => state.ezlo);
    const serial = ezloState?.serial;
    const items = serial ? ezloState.data[serial].items : [];
    const devices = serial ? ezloState.data[serial].devices : [];
    const deviceCategory = devices.find((device) => device._id === deviceId)?.category;
    const currentCapabilityValue = items.find((item) => item._id === selectedCapability?._id)?.value;
    const itemsRelatedToDevice = useMemo(() => {
        return getFilteredDeviceItemsForTrigger(items, deviceId) || [];
    }, [deviceId]);
    const isItems = itemsRelatedToDevice.length > 0;
    const { t } = useTranslate();
    let valueToDisplay =
        typeof selectedCapability?.value === 'object'
            ? JSON.stringify(selectedCapability?.value)
            : currentCapabilityValue;

    if (selectedCapability?.valueType === BLOCK_FIELD_TYPES.TEMPERATURE) {
        valueToDisplay = valueToDisplay.toFixed(2);
    }

    useEffect(() => {
        setPinCodeMenuItemValue(null);
        setIsVisibleUserLockOperationBlock(false);
        setSelectedPinCode(EMPTY_STRING);
    }, [deviceId]);

    const pinCodeMenuItem = (
        <MenuItem key={PIN_CODE_USED} value={PIN_CODE_USED}>
            {PIN_CODE_MENU_ITEM_CONTENT}
        </MenuItem>
    );

    const handleSelectCapability = (event) => {
        const { value } = event?.target;

        if (value === PIN_CODE_USED) {
            setPinCodeMenuItemValue(PIN_CODE_USED);
            setIsVisibleUserLockOperationBlock(true);
            setSelectedAction(LOCK);
            onDataUpdate({
                selectedCapability: {},
                selectedComparator: {},
                compareTo: VALUE,
                comparingValue: EMPTY_STRING,
            });
        } else {
            setPinCodeMenuItemValue(null);
            setIsVisibleUserLockOperationBlock(false);
            setSelectedPinCode(EMPTY_STRING);
            onDataUpdate({
                selectedCapability: value,
                selectedComparator: getSelectedComparator(event),
                compareTo: VALUE,
                comparingValue: getComparingValue(event),
            });
        }
    };

    return (
        <SelectComponent
            value={pinCodeMenuItemValue ?? selectedCapability ?? {}}
            label={info.hints.capability}
            info={info.text.capability}
            onChange={handleSelectCapability}
            renderValue={(value) => (
                <div>
                    {value === pinCodeMenuItemValue
                        ? PIN_CODE_MENU_ITEM_CONTENT
                        : `${formatNameWithUnderscores(value?.name || '')} ${
                              selectedCapability?.value?.button_number ?? ''
                          }`}
                </div>
            )}
            deviceSetting={
                selectedCapability?.value !== undefined &&
                selectedCapability?.name !== USER_LOCK_OPERATION && (
                    <div>{`${t(EZLOGIC_TITLE_CURRENT_VALUE)} (${
                        selectedCapability.valueType
                    }): ${valueToDisplay}`}</div>
                )
            }
        >
            {isItems ? (
                itemsRelatedToDevice
                    .map((item) => (
                        <MenuItem key={item._id} value={item}>
                            {formatNameWithUnderscores(item.name)} {item?.value?.button_number ?? ''}
                        </MenuItem>
                    ))
                    .concat(deviceCategory === NAMES_OF_CATEGORIES.DOOR_LOCK ? pinCodeMenuItem : null)
            ) : (
                <div className={infoText}>{t(EZLOGIC_TITLE_NO_OPTIONS)}</div>
            )}
        </SelectComponent>
    );
};

export default SelectDeviceCapability;
