import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../../containers/Ezlo/EzloMeshbot/components/SelectComponent';
import { info } from '../../../../containers/Ezlo/EzloMeshbot/utils';
import { LOCK, LOCKED, VALUE, EMPTY_STRING, UNLOCK, UNLOCKED } from '../../../../constants/MeshbotConstant';

const SelectAction = (props) => {
    const { setSelectedAction, selectedAction, onDataUpdate, setSelectedPinCode } = props;
    const handleSelectCapability = (event) => {
        setSelectedAction(event.target.value);
        setSelectedPinCode(EMPTY_STRING);
        onDataUpdate({
            selectedCapability: {},
            selectedComparator: {},
            compareTo: VALUE,
            comparingValue: EMPTY_STRING,
        });
    };

    return (
        <SelectComponent value={selectedAction} label={info.hints.action} onChange={handleSelectCapability}>
            <MenuItem value={LOCK}>{LOCKED}</MenuItem>
            <MenuItem value={UNLOCK}>{UNLOCKED}</MenuItem>
        </SelectComponent>
    );
};

export default SelectAction;

SelectAction.propTypes = {
    selectedAction: PropTypes.string,
    setSelectedAction: PropTypes.func,
    onDataUpdate: PropTypes.func,
    setSelectedPinCode: PropTypes.func,
};
