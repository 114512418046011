import hash from '../../../constants/uniqueHash';
import { DEFAULT_VIRTUAL_DEVICE } from '../../../constants/VirtualDeviceConstant';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../constants/toastConstants';
import { CREATE_VIRTUAL_DEVICE } from '../../../constants/queryConstants';
import {
    EZLOGIC_TITLE_NOT_SUCCESSFULLY_CREATED,
    EZLOGIC_TITLE_SUCCESSFULLY_CREATED,
    EZLOGIC_TITLE_VIRTUAL_DEVICES_WITH,
} from '../../../constants/language_tokens';

const EMPTY_OBJECT_STRING = '{}';

export const getDefaultVirtualDevice = () => ({ id: hash(), ...DEFAULT_VIRTUAL_DEVICE });
export const isEmptyObject = (obj) => JSON.stringify(obj) === EMPTY_OBJECT_STRING;
export const getPayloadOfCreateVirtualDevice = (device, error, t) => {
    if (error) {
        return {
            isSave: true,
            message: `${t(EZLOGIC_TITLE_VIRTUAL_DEVICES_WITH)} '${device.device_type.uiLabel}' ${t(
                EZLOGIC_TITLE_NOT_SUCCESSFULLY_CREATED,
            )}`,
            options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
        };
    }

    return {
        isSave: false,
        message: `${t(EZLOGIC_TITLE_VIRTUAL_DEVICES_WITH)} '${device?.device_type?.uiLabel || '???'}' ${t(
            EZLOGIC_TITLE_SUCCESSFULLY_CREATED,
        )}`,
        options: SUCCESS_OPTIONS,
    };
};

export const getErrorData = (device, serial) => ({
    script: CREATE_VIRTUAL_DEVICE.SCRIPT,
    serial: serial,
    scriptParams: device,
});

export const getPayloadForErrorToast = (error) => ({
    isSave: true,
    message: error?.message || error || 'Error',
    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
});

export const getErrorDataForBugsnagNotify = (params, serial, method) => {
    return {
        params: params,
        serial: serial,
        method: method,
    };
};

export const getPayloadOfInstallPluginSuccessfully = () => {
    return {
        isSave: false,
        message: `Plugin successfully installed! `,
        options: SUCCESS_OPTIONS,
    };
};
