import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Eula, ConsentAndLegitimate, PrivacyPolicy } from '../index';
import PrivacyPolicyAction from '../../actions/EzloPoliciesAction';
import { NEXT, ONE, SAVE, POLICY_PAGE_INDEX, ZERO, TWO } from '../../constants';
import { getCurrentPage, getPoliciesNotAccepted } from '../../utils';

import styles from './ezloPolicies.module.scss';
import { AppLogo } from '../../../../assets/icons';

const EzloPoliciesLayout = ({ props }) => {
    const dispatch = useDispatch();

    const [accepted, setAccepted] = useState(false);
    const privacyPolicy = useSelector((state) => state?.ezloPolicy);
    const policiesNotAccepted = getPoliciesNotAccepted(privacyPolicy?.policiesAccepted);
    const currentPage = getCurrentPage(privacyPolicy?.policiesAccepted);
    const [page, setPage] = useState(currentPage);

    const handleAccept = (event) => {
        setAccepted(event.target.checked);
    };

    const handleNext = () => {
        if (accepted) {
            setPage(page + ONE);
            setAccepted(false);
        }
    };

    const handleSave = () => {
        dispatch(PrivacyPolicyAction.setPrivacyPolicy(privacyPolicy));
        props?.history?.push(privacyPolicy?.domainPath);
    };

    return (
        <div className={styles.ezloPolicies}>
            <div className={styles.main}>
                <div className={styles.logo}>
                    <AppLogo />
                </div>

                {page === POLICY_PAGE_INDEX[ZERO] && (
                    <Eula
                        policies={privacyPolicy.eula}
                        onAccept={handleAccept}
                        onNext={policiesNotAccepted?.length == ONE ? handleSave : handleNext}
                        accepted={accepted}
                        buttonTitle={policiesNotAccepted?.length == ONE ? SAVE : NEXT}
                    />
                )}

                {page === POLICY_PAGE_INDEX[ONE] && (
                    <PrivacyPolicy
                        policies={privacyPolicy.notices}
                        onAccept={handleAccept}
                        onNext={handleNext}
                        accepted={accepted}
                    />
                )}

                {page === POLICY_PAGE_INDEX[TWO] && (
                    <ConsentAndLegitimate policies={privacyPolicy} onSave={handleSave} accepted={accepted} />
                )}
            </div>
        </div>
    );
};

export default EzloPoliciesLayout;
