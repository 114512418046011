import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import AutocompleteSelect from '../AutocompleteSelect';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_COUNTRY } from '../../../../../constants/language_tokens';

const CountrySelect = ({ name, isDisabled, setPK_Country, setPK_Region, addressName }) => {
    const { countriesList } = useSelector(({ account }) => account);
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslate();

    const handleChange = (e, value) => {
        setFieldValue(name, value);
        setFieldValue(`${addressName}.City`, '');
        setFieldValue(`${addressName}.State`, '');
        setPK_Country(value.PK_Country);
        setPK_Region(null);
    };

    const autocompleteProps = {
        options: countriesList,
        disabled: isDisabled,
        name,
    };

    return (
        <AutocompleteSelect
            label={t(EZLOGIC_TITLE_COUNTRY)}
            autocompleteProps={autocompleteProps}
            handleChange={handleChange}
        />
    );
};

export default CountrySelect;
