import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { optionsSelect } from '../../../../../constants/MeshbotConstant';
import InfoButton from '../../components/InfoButton';
import { useTranslate } from '../../../../../features/languages';
import useExecutionPolicyState from '../../hooks/useExecutionPolicyState';
import { ACTION_EXECUTION_POLICY_LEVELS } from '../../constants';

import {
    executionPolicySelect,
    meshbotExecutionPolicyBox,
    infoIconButton,
    eachActionSelect,
} from './styles.module.scss';

const ActionExecutionPolicyBlock = ({ className, executionPolicyLevel, id, blockType }) => {
    const { t } = useTranslate();
    const { actionExecutionPolicies, exec_policy, handlerChangeExecPolicy, executionPolicyInfo } =
        useExecutionPolicyState(executionPolicyLevel, id, blockType);

    const selectClasses = classNames(executionPolicySelect, {
        [eachActionSelect]: executionPolicyLevel === ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION,
    });

    return (
        <div className={`${meshbotExecutionPolicyBox} ${className}`}>
            <FormControl>
                <Select
                    value={exec_policy}
                    onChange={handlerChangeExecPolicy}
                    className={selectClasses}
                    MenuProps={{ ...optionsSelect }}
                    endAdornment={
                        <InfoButton infoIconButtonClassName={infoIconButton}>
                            <div dangerouslySetInnerHTML={{ __html: t(executionPolicyInfo) }} />
                        </InfoButton>
                    }
                >
                    {actionExecutionPolicies.map(({ value, labelKey }) => (
                        <MenuItem key={labelKey} value={value}>
                            {t(labelKey)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

ActionExecutionPolicyBlock.propTypes = {
    className: PropTypes.string,
    executionPolicyLevel: PropTypes.string.isRequired,
    id: PropTypes.string,
    blockType: PropTypes.string,
};

export default ActionExecutionPolicyBlock;
