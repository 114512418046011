import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../SelectComponent';

import * as meshbot from '../../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_YEAR } from '../../../../../../constants/language_tokens';

const Year = (props) => {
    const { year, onHandleChangeYear } = props;
    const { t } = useTranslate();

    return (
        <SelectComponent value={year ? year : ''} onChange={onHandleChangeYear} label={t(EZLOGIC_TITLE_YEAR)} small>
            {meshbot.AVAILABLE_YEARS_ARRAY.map((item) => {
                return (
                    <MenuItem key={item.name} value={item.name}>
                        {item.value}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default React.memo(Year);

Year.propTypes = {
    year: PropTypes.number,
    onHandleChangeYear: PropTypes.func,
};
