import React, { memo } from 'react';

import DefaultActionCells from '../DefaultActionCells';
import ActionTableCellBox from '../ActionTableCellBox';
import EditButton from '../EditButton';

import { iconCell } from '../../style.module.scss';

const ActionCells = (props) => {
    const { isLoggedIn, handleEditBtnClick } = props;

    return (
        <>
            <DefaultActionCells {...props} />
            <ActionTableCellBox isDisplayingChildren={isLoggedIn} className={iconCell} align="center">
                <EditButton handleClick={handleEditBtnClick} />
            </ActionTableCellBox>
        </>
    );
};

export default memo(ActionCells);
