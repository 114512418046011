import './style.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import { Modal } from '../../components';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_GOT_IT } from '../../constants/language_tokens';
import { ErrorIcon } from '../../assets/icons';

export function WithTranslaion(ErrorDialog) {
    return function MakeTraslationFn(props) {
        const useTranslateHook = useTranslate();

        return <ErrorDialog {...props} useTranslate={() => useTranslateHook} />;
    };
}
class ErrorDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.close = this.close.bind(this);
    }

    close() {
        this.props.redux.actions.GenericActions.hideError();
    }

    render() {
        const {
            error: { show, message, errorTitle },
        } = this.props.redux.state;
        const { t } = this.props.useTranslate();

        return (
            <Modal
                footer={null}
                closable={false}
                centered
                visible={show}
                onCancel={this.close}
                className="error-modal"
                maskClosable={false}
                maskStyle={{
                    background: 'rgba(28, 30, 33, 0.75)',
                }}
            >
                <>
                    <ErrorIcon height={104} width={104} className="icon" />
                    <h2 className="title">{t(errorTitle)}</h2>
                    <div className="message">{t(message)}</div>
                    <Button focused={true} className={classNames('btn', 'warning')} onClick={this.close}>
                        {t(EZLOGIC_TITLE_GOT_IT)}
                    </Button>
                </>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    error: state.app.error,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    redux: {
        state: stateProps,
        actions: dispatchProps,
    },
});
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WithTranslaion(ErrorDialog));
