import React, { useEffect, useMemo, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, CheckboxButton } from '../../../../components';
import TriggerDateFieldsCloud from './TriggerDateFieldsCloud';
import InputTypeCloud from '../../../../components/InputTypeCloud';
import SelectComponent from './SelectComponent';
import DevicesBlock from './DeviceBlockComponent';
import TriggerBlockButtonsWrapper from './TriggerBlock/TriggerBlockButtonsWrapper';
import { PAAS } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/Paas';

import {
    checkForSingleOnChangeTrigger,
    determineToastMessageAccordingToTriggerNodeType,
    deviceOptions,
} from '../../utils';
import { SCHEDULE_DATE_TYPES } from '../../../../components/blockCloudTriggerTemplate/dateTemplatesForCloudMeshbot';
import {
    SINGLE_TRIGGER,
    TRIGGER_VALID_MODE,
    TRIGGER_EDIT_MODE,
    OPERATOR_NOT,
    TRIGGER,
    SCHEDULE,
    CLOUD_VARIABLE,
    VALUE_TYPE,
    INDEX_OF_ZERO,
    ON_CHANGE,
    SELECTED_OPERATOR,
} from '../../../../constants/MeshbotConstant';
import at from '../../../../constants/ActionTypes/MeshBot';
import { MESHBOT_NODE_TYPES, TRIGGER_FORM } from '../../../../constants/MeshbotConstant';

import { dateAndTimeCloud, listNodesLocal, listNodesCloud } from '../../../../constants/rulesInSelect';
import { getOperatorArray, isRgb } from '../../EzloVirtualDevice/utils';
import {
    getClassNameFromCloudTriggersValidation,
    getClassNameFromCloudTriggersValidationNested,
    getMenuOptionsListForTriggerBlock,
    getTriggerListData,
} from '../utils';

import { CloudVariableForTrigger } from './CloudVariableForTrigger';
import { body, inner, trigger_block__container } from './TriggerBlock.module.scss';
import styles from '../../../../components/InputTypeCloud/InputTypeCloud.module.scss';
import { getCapabilitiesForNotificationTemplate } from '../../EzloNotificationTemplate/utils';
import { SelectValueType } from './CloudVariableForTrigger/components/SelectValueType';
import { filterCloudTriggerNodesBySsoType, generateAbstractListWithCustomVariables } from './utils';
import * as localStorageKeys from '../../../../constants/localStorageKeys';
import ComparedCloudVariableSelectForCloudMeshBot from './CloudVariableForTrigger/components/ComparedCloudVariableSelectForCloudMeshBot';
import {
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_SECOND_BLOCK,
} from '../../../../constants/NotificationTemplates';
import useIntegrationAndAbstractState from '../hooks/useIntegrationAndAbstractState';
import actions from '../../../../actions/IntegrationsActions';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_HINT_NODE,
    EZLOGIC_HINT_NODE_TYPE,
    EZLOGIC_HINTS_COMPARATOR,
    EZLOGIC_HINTS_VARIABLE,
    EZLOGIC_LABEL_COMPARATOR,
    EZLOGIC_TITLE_ATTRIBUTE,
    EZLOGIC_TITLE_CAPABILITY,
    EZLOGIC_TITLE_NODE,
    EZLOGIC_TITLE_NODE_TYPE,
    EZLOGIC_TITLE_NOT,
    EZLOGIC_VARIABLES_VARIABLES_TITLE,
} from '../../../../constants/language_tokens';
import { toast, TOAST_TYPE } from '../../../../components/Toast';

// TODO: refactoring needed
export const CloudTriggerBlock = (props) => {
    const {
        id,
        currentItem,
        blocks,
        name,
        nameSubBlock,
        idGroup,
        typeMeshbot,
        currentDeviceSelect,
        selectedFieldTrigger,
        selectedOperator,
        selectedFieldDate,
        DeleteTrigger,
        onUpdateTriggerNot,
        not,
        onUpdateTrigger,
        onUpdateTriggerForNuCAL,
        onSelected,
        onSelectedDate,
        onUpdateDateTriggersCloud,
        updateTriggerNameBlock,
        rooms,
        variables,
        capabilities,
        currentVariable,
        ruleCloudTriggers,
        variableValue,
        deviceName,
        currentScene,
        notificationTemplateOption,
        notificationTemplate,
        updateValidation,
        validationCheckEdit,
        updateCloudVariablesTrigger,
        updateCloudVariablesTriggerBlocks,
        updateCloudRequiredFieldTrigger,
        optionType,
    } = props;

    const [isValid, setIsValid] = useState();
    const [isEdit, setIsEdit] = useState();
    const deviceValueType = currentItem?.selectedValueType;
    const dispatch = useDispatch();
    const { abstractsList } = useIntegrationAndAbstractState();
    const abstracts = abstractsList?.abstracts;
    const { t } = useTranslate();

    const abstractsUuidWithVariables = useMemo(() => {
        return generateAbstractListWithCustomVariables(abstracts);
    }, [abstracts]);

    const checkedNot = (e) => {
        onUpdateTriggerNot(e, id, OPERATOR_NOT, idGroup);
    };

    const handleUpdateTrigger = (e, field) => {
        onUpdateTrigger(e, id, field, idGroup);
    };

    const handleDeviceChange = (e) => {
        onSelected(e, id, selectedOperator, idGroup);
        clearDeviceStateData();
    };

    const handleChangeNotificationTemplate = (e) => {
        onSelected(e, id, at.NOTIFICATION_TEMPLATE, idGroup);
    };

    const options = deviceOptions(currentDeviceSelect, rooms);

    const handleSelectedDate = (e, idDevice, idGroup) => {
        onSelectedDate(e, idDevice, idGroup, 'schedule');
    };

    const handleVariableChange = (value, isValidRgbValue) => {
        const currentValue = value?.target?.value || value;
        updateTriggerNameBlock(
            String(currentValue),
            currentItem,
            !notificationTemplate ? at.VARIABLE_VALUE : at.NOTIFICATION_TEMPLATE_VARIABLE_VALUE,
            idGroup,
            capabilities,
            isValidRgbValue,
        );
    };

    const handleDeviceTriggerEditing = (currentItem) => {
        let data;
        if (currentItem && currentItem?.target?.[INDEX_OF_ZERO]?.capability) {
            data = {
                selectedValueType: CLOUD_VARIABLE,
                selectedVariableCompared: currentItem?.target[INDEX_OF_ZERO]?.capability,
                selectedAbstractForCompared: { uuid: currentItem?.target[INDEX_OF_ZERO]?.abstract },
            };
        } else if (currentItem && currentItem?.target[INDEX_OF_ZERO]?.value) {
            data = { selectedValueType: VALUE_TYPE };
            handleVariableChange(currentItem?.target[INDEX_OF_ZERO]?.value);
        }
        updateCloudVariablesTrigger(data, MESHBOT_NODE_TYPES.CLOUD_VARIABLES, id);
    };

    useEffect(() => {
        if (selectedFieldTrigger === at.DEVICE_STATE && !notificationTemplate && selectedOperator !== ON_CHANGE) {
            handleDeviceTriggerEditing(currentItem);
        }
    }, []);

    useEffect(() => {
        const mode =
            ruleCloudTriggers.length > SINGLE_TRIGGER
                ? getClassNameFromCloudTriggersValidationNested(currentItem, currentScene, notificationTemplate)
                : getClassNameFromCloudTriggersValidation(currentItem, currentScene, notificationTemplate);

        // TODO: Must be simplified, refactoring needed in scope of Cloud MeshBot Validation task!
        if (mode === TRIGGER_VALID_MODE) {
            setIsValid(true);

            setIsEdit(true);
        } else {
            setIsValid(false);
        }

        if (mode === TRIGGER_EDIT_MODE) {
            setIsEdit(false);
            setIsValid(true);
        } else {
            setIsEdit(true);
        }
    }, [blocks]);

    useEffect(() => {
        updateValidation(isValid, id);
        validationCheckEdit(isEdit, id);
    }, [isValid]);

    useEffect(() => {
        validationCheckEdit(isEdit, id);
    }, [isEdit]);

    useEffect(() => {
        if (selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
            dispatch(actions.getAbstractState(abstractsUuidWithVariables));
        }
    }, [selectedFieldTrigger]);

    const handleSetValueType = (value) => {
        const data = { selectedValueType: value };
        updateCloudVariablesTrigger(data, MESHBOT_NODE_TYPES.CLOUD_VARIABLES, id);
        handleVariableChange('');
    };

    const updatedTriggerNodes = useSelector((state) => state.ezloCustomization?.nodesControllables);

    const updateListTrigger = getTriggerListData(updatedTriggerNodes, listNodesLocal, listNodesCloud, typeMeshbot);
    const updatedNodes = getMenuOptionsListForTriggerBlock(notificationTemplate, updateListTrigger);
    const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
    const nodes = filterCloudTriggerNodesBySsoType(updatedNodes, ssoType);

    const isFormControlVisible = selectedFieldTrigger === at?.DATA_AND_TIME || selectedOperator === at.SCHEDULE;
    const isDeviceBlocksVisible =
        (selectedFieldTrigger === at.DEVICE_STATE || selectedFieldTrigger === at.DEVICE_ADVANCED) &&
        selectedOperator &&
        capabilities;

    const cloudInputTypeForEdit = () => {
        const cloudInputType = [];

        return cloudInputType;
    };

    const handleSelectedOperator = (e) => {
        if (!checkForSingleOnChangeTrigger(ruleCloudTriggers, e.target.value, optionType)) {
            return toast(t(determineToastMessageAccordingToTriggerNodeType(ruleCloudTriggers)), {
                type: TOAST_TYPE.WARNING,
            });
        }
        onUpdateTrigger(e, id, SELECTED_OPERATOR, idGroup);
    };

    const selectOperatorFormControl = (
        <SelectComponent
            value={selectedOperator}
            label={t(EZLOGIC_LABEL_COMPARATOR)}
            info={t(EZLOGIC_HINTS_COMPARATOR)}
            onChange={(e) => handleSelectedOperator(e)}
        >
            {getOperatorArray(currentVariable, notificationTemplate).map((item) => {
                return (
                    <MenuItem key={item.name} value={item.value}>
                        {t(item.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );

    const handleDeviceSelect = (event, value) => {
        updateTriggerNameBlock(value, currentItem, at.NAME_SUB_BLOCK, idGroup, capabilities);
        clearDeviceStateData();
    };

    const clearDeviceStateData = () => {
        handleVariableChange('');
    };

    const memoAutocompleteInput = useMemo(() => {
        return (
            <DevicesBlock
                selectedDeviceId={props.idDev}
                typeMeshbot={typeMeshbot}
                options={options}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                value={name ? { name } : { name: deviceName }}
                groupBy={(option) => option.roomName}
                disableClearable={true}
                onChange={(event, value) => (value ? handleDeviceChange(value) : {})}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }, [name, deviceName, selectedOperator]);

    return (
        <div
            className={
                ruleCloudTriggers.length > SINGLE_TRIGGER
                    ? getClassNameFromCloudTriggersValidationNested(currentItem, currentScene, notificationTemplate)
                    : getClassNameFromCloudTriggersValidation(currentItem, currentScene, notificationTemplate)
            }
        >
            <div className={trigger_block__container}>
                <div className={body}>
                    {selectedFieldTrigger !== at.EXPRESSION && (
                        <CheckboxButton
                            id={id}
                            label={t(EZLOGIC_TITLE_NOT)}
                            name={t(EZLOGIC_TITLE_NOT)}
                            value={not}
                            onChange={checkedNot}
                        />
                    )}

                    <div className={inner}>
                        <SelectComponent
                            value={selectedFieldTrigger ? selectedFieldTrigger : ''}
                            label={t(EZLOGIC_TITLE_NODE_TYPE)}
                            info={EZLOGIC_HINT_NODE_TYPE}
                            onChange={(e) => handleUpdateTrigger(e, at.SELECTED_FIELD_TRIGGER)}
                        >
                            {nodes.map(({ id, value, label }) => {
                                return (
                                    <MenuItem key={id} value={value}>
                                        {t(label)}
                                    </MenuItem>
                                );
                            })}
                        </SelectComponent>

                        {selectedFieldTrigger === at.DEVICE_STATE && !notificationTemplate && memoAutocompleteInput}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.PAAS && (
                            <div className="trigger-block__body">
                                <PAAS
                                    onUpdateTriggerForNuCAL={onUpdateTriggerForNuCAL}
                                    updateCloudRequiredFieldTrigger={updateCloudRequiredFieldTrigger}
                                    id={id}
                                    calledFrom={TRIGGER_FORM}
                                    ruleCloudTriggers={ruleCloudTriggers}
                                />
                            </div>
                        )}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.CLOUD_VARIABLES && (
                            <div className="trigger-block__body">
                                <CloudVariableForTrigger
                                    id={id}
                                    ruleCloudTriggers={ruleCloudTriggers}
                                    parentTriggerOperator={optionType}
                                    updateCloudVariablesTrigger={updateCloudVariablesTrigger}
                                    updateCloudVariablesTriggerBlocks={updateCloudVariablesTriggerBlocks}
                                />
                            </div>
                        )}

                        {selectedFieldTrigger === at.DEVICE_STATE && notificationTemplate && (
                            <SelectComponent
                                value={notificationTemplateOption ? notificationTemplateOption : nameSubBlock}
                                label={t(EZLOGIC_TITLE_CAPABILITY)}
                                info={EZLOGIC_TITLE_CAPABILITY}
                                onChange={(e) => handleChangeNotificationTemplate(e)}
                            >
                                <MenuItem key="item" value="item">
                                    item
                                </MenuItem>
                            </SelectComponent>
                        )}

                        {isFormControlVisible && (
                            <SelectComponent
                                value={selectedFieldDate ? selectedFieldDate : ''}
                                label={t(EZLOGIC_TITLE_NODE)}
                                info={EZLOGIC_HINT_NODE}
                                onChange={(e) => handleSelectedDate(e, id, idGroup, selectedOperator)}
                            >
                                {dateAndTimeCloud.map((type) => {
                                    const { name, value } = type;
                                    const { SPECIAL_TIME_OF_THE_DAY } = SCHEDULE_DATE_TYPES;

                                    // TODO make specialTimeOfTheDay main type for "sunrise"/"sunset" in convertors
                                    if (['sunrise', 'sunset'].includes(name)) {
                                        return (
                                            <MenuItem key="specialTimeOfTheDay" value={'specialTimeOfTheDay'}>
                                                {SPECIAL_TIME_OF_THE_DAY}
                                            </MenuItem>
                                        );
                                    }

                                    return (
                                        <MenuItem key={name} value={name}>
                                            {t(value)}
                                        </MenuItem>
                                    );
                                })}
                            </SelectComponent>
                        )}

                        {isDeviceBlocksVisible && (
                            <>
                                <div className='trigger-block__body autocomplete"'>
                                    <Autocomplete
                                        className={styles.input}
                                        options={
                                            !notificationTemplate
                                                ? capabilities.sort()
                                                : getCapabilitiesForNotificationTemplate(capabilities)
                                        }
                                        autoComplete={true}
                                        getOptionLabel={(option) => option ?? ''}
                                        value={nameSubBlock}
                                        disableClearable={true}
                                        getOptionSelected={(option, value) => {
                                            return option === value;
                                        }}
                                        onChange={handleDeviceSelect}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    label={
                                                        !notificationTemplate
                                                            ? t(EZLOGIC_TITLE_CAPABILITY)
                                                            : t(EZLOGIC_TITLE_ATTRIBUTE)
                                                    }
                                                    {...params}
                                                />
                                            );
                                        }}
                                    />
                                </div>

                                {Array.isArray(variables) ? (
                                    <SelectComponent
                                        value={currentVariable}
                                        label={t(EZLOGIC_VARIABLES_VARIABLES_TITLE)}
                                        info={EZLOGIC_HINTS_VARIABLE}
                                        onChange={(e) =>
                                            updateTriggerNameBlock(
                                                e.target.value,
                                                currentItem,
                                                !notificationTemplate ? at.VARIABLE : at.NOTIFICATION_TEMPLATE_VARIABLE,
                                                idGroup,
                                            )
                                        }
                                    >
                                        {variables.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={item.name ? item.name : item}
                                                    value={item.name ? item.name : item}
                                                >
                                                    {item.name ? item.name : item}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectComponent>
                                ) : (
                                    <>
                                        {isRgb(variables) ? selectOperatorFormControl : ''}
                                        <InputTypeCloud
                                            value={variableValue || cloudInputTypeForEdit()}
                                            currentInputType={variables || cloudInputTypeForEdit()}
                                            onChange={handleVariableChange}
                                            notificationTemplate={notificationTemplate}
                                            nameSubBlock={nameSubBlock}
                                        />
                                    </>
                                )}

                                {nameSubBlock && selectOperatorFormControl}

                                {nameSubBlock &&
                                    selectedOperator !== ON_CHANGE &&
                                    (deviceValueType || deviceValueType == '') &&
                                    !notificationTemplate && (
                                        <SelectValueType
                                            onDataUpdate={(e) => handleSetValueType(e?.selectedValueType)}
                                            selectedValueType={deviceValueType}
                                        />
                                    )}

                                {(notificationTemplate || deviceValueType === VALUE_TYPE) &&
                                    Array.isArray(variables) &&
                                    ruleCloudTriggers.map((rule) => {
                                        if (rule?.id === currentItem?.id) {
                                            return (
                                                <InputTypeCloud
                                                    key={rule?.id}
                                                    value={
                                                        blocks?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.parameters?.[
                                                            DATA_FROM_VALUE_FROM_SECOND_BLOCK
                                                        ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.value ||
                                                        variableValue
                                                    }
                                                    currentInputType={
                                                        currentItem.currentVariable || currentItem.currentInputType
                                                    }
                                                    nameSubBlock={currentItem.nameSubBlock}
                                                    onChange={handleVariableChange}
                                                    inputType={currentItem.currentVariableType}
                                                    calledFrom={TRIGGER}
                                                />
                                            );
                                        }
                                    })}
                                {deviceValueType === CLOUD_VARIABLE && (
                                    <ComparedCloudVariableSelectForCloudMeshBot
                                        ruleCloudTrigger={currentItem}
                                        ruleCloudTriggerId={id}
                                    />
                                )}
                            </>
                        )}

                        {selectedOperator === SCHEDULE && (
                            <TriggerDateFieldsCloud
                                id={id}
                                blocks={blocks}
                                idGroup={idGroup}
                                selectedFieldDate={selectedFieldDate}
                                onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                            />
                        )}
                    </div>
                </div>

                <TriggerBlockButtonsWrapper onDeleteTrigger={() => DeleteTrigger(id, idGroup)} />
            </div>
        </div>
    );
};
