import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../SelectComponent';

import {
    VIDOO_TYPES,
    SUMMARY_VIDOO_OPTIONS,
    INDEX_SELECTED_VALUE,
    SELECTED_VALUE,
} from '../../../../../constants/MeshbotConstant';
import { VIDOO_TYPES_LIST } from '../../../../../constants/constMeshBot';
import {
    createVidooTriggerBlock,
    getTypesVidooList,
    getVidooCamerasList,
    getVidooItemId,
    getCameraHotzones,
    getSelectedHotzoneId,
    getSelectedTypeCapability,
    setHotzoneId,
    info,
} from '../../utils';
import hash from '../../../../../constants/uniqueHash';

import styles from './VidooNodeForTrigger.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_ANY_OBJECT,
    EZLOGIC_TITLE_ANY_PERSON,
    EZLOGIC_TITLE_ANY_QR_CODE,
    EZLOGIC_TITLE_ANY_ZONE,
    EZLOGIC_TITLE_FULL_SCREEN,
    EZLOGIC_TITLE_IS_DETECTED_IN,
} from '../../../../../constants/language_tokens';

const VidooNodeForTrigger = (props) => {
    const {
        id,
        idGroup,
        items,
        devices,
        blocks,
        selectedFieldTrigger,
        onSelectedVidooTrigger,
        vidooTypeValue,
        capabilityValue,
        selectedCameraValue,
        selectedHotzoneValue,
    } = props;
    const initialState = {
        faceCapability: SUMMARY_VIDOO_OPTIONS.ANY_PERSON,
        objectCapability: SUMMARY_VIDOO_OPTIONS.ANY_OBJECT,
        qrCodeCapability: SUMMARY_VIDOO_OPTIONS.ANY_QRCODE,
    };
    const [vidooType, setVidooType] = useState(VIDOO_TYPES.ANY_MOTION);
    const [vidooTypeCapability, setVidooTypeCapability] = useState(initialState);
    const [selectedCamera, setSelectedCamera] = useState(getVidooCamerasList(devices)[INDEX_SELECTED_VALUE]?._id);
    const [selectedHotzone, setSelectedHotzone] = useState(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN);
    const { t } = useTranslate();

    useEffect(() => {
        if (blocks && blocks.length > 0 && vidooTypeValue) {
            setVidooType(vidooTypeValue);
            if (vidooTypeValue === VIDOO_TYPES.FACE_DETECTION) {
                setVidooTypeCapability({ ...vidooTypeCapability, faceCapability: capabilityValue });
            } else if (vidooTypeValue === VIDOO_TYPES.OBJECT_DETECTION) {
                setVidooTypeCapability({ ...vidooTypeCapability, objectCapability: capabilityValue });
            } else if (vidooTypeValue === VIDOO_TYPES.QR_CODE_DETECTION) {
                setVidooTypeCapability({ ...vidooTypeCapability, qrCodeCapability: capabilityValue });
            }
            setSelectedCamera(selectedCameraValue);
            setSelectedHotzone(selectedHotzoneValue);
        } else {
            const block = createVidooTriggerBlock(vidooType, null, null, null, setHotzoneId(selectedHotzone));
            onSelectedVidooTrigger(id, block, idGroup);
        }
    }, []);

    const handleVidooTypesChange = (e) => {
        const { value } = e.target;

        setVidooType(value);
        setVidooTypeCapability(initialState);
        setSelectedHotzone(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN));
        const block = createVidooTriggerBlock(
            value,
            vidooTypeCapability.faceCapability,
            vidooTypeCapability.objectCapability,
            vidooTypeCapability.qrCodeCapability,
            getVidooItemId(selectedCamera, items),
            setHotzoneId(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN)),
        );
        onSelectedVidooTrigger(id, block, idGroup);
    };

    const handleTypeCapabilityChange = (e) => {
        const value = getSelectedTypeCapability(e.target.value);

        if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
            setVidooTypeCapability({ ...vidooTypeCapability, faceCapability: value });
            const block = createVidooTriggerBlock(
                vidooType,
                value,
                null,
                null,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(selectedHotzone),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
            setVidooTypeCapability({ ...vidooTypeCapability, objectCapability: value });
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                value,
                null,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(selectedHotzone),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
            setVidooTypeCapability({ ...vidooTypeCapability, qrCodeCapability: value });
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                null,
                value,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(selectedHotzone),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        }
    };

    const handleSelectedCameraChange = (e) => {
        const { value } = e.target;

        setSelectedCamera(value);
        setSelectedHotzone(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN));
        if (vidooType === VIDOO_TYPES.ANY_MOTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                null,
                null,
                getVidooItemId(value, items),
                setHotzoneId(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN)),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                vidooTypeCapability.faceCapability,
                null,
                null,
                getVidooItemId(value, items),
                setHotzoneId(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN)),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                vidooTypeCapability.objectCapability,
                null,
                getVidooItemId(value, items),
                setHotzoneId(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN)),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                null,
                vidooTypeCapability.qrCodeCapability,
                getVidooItemId(value, items),
                setHotzoneId(getSelectedHotzoneId(SUMMARY_VIDOO_OPTIONS.FULL_SCREEN)),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        }
    };

    const handleSelectedHotzoneChange = (e) => {
        const value = getSelectedHotzoneId(e.target.value);

        setSelectedHotzone(value);
        if (vidooType === VIDOO_TYPES.ANY_MOTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                null,
                null,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(value),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                vidooTypeCapability.faceCapability,
                null,
                null,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(value),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                vidooTypeCapability.objectCapability,
                null,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(value),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        } else if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
            const block = createVidooTriggerBlock(
                vidooType,
                null,
                null,
                vidooTypeCapability.qrCodeCapability,
                getVidooItemId(selectedCamera, items),
                setHotzoneId(value),
            );
            onSelectedVidooTrigger(id, block, idGroup);
        }
    };

    const vidooTypes = () => {
        return (
            <SelectComponent
                value={vidooType}
                label={info.hints.capability}
                info={info.text.capability}
                onChange={handleVidooTypesChange}
            >
                {VIDOO_TYPES_LIST.map((item) => {
                    return (
                        <MenuItem key={hash()} value={item.value}>
                            {t(item.label)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        );
    };

    const vidooCapabilitiesList = () => {
        if (vidooType === VIDOO_TYPES.FACE_DETECTION) {
            return (
                <SelectComponent
                    value={vidooTypeCapability.faceCapability}
                    label={info.hints.value}
                    info={info.text.value}
                    onChange={handleTypeCapabilityChange}
                    small
                >
                    <MenuItem value={SUMMARY_VIDOO_OPTIONS.ANY_PERSON}>{t(EZLOGIC_TITLE_ANY_PERSON)}</MenuItem>
                    {getTypesVidooList(vidooType, items).map((item) => (
                        <MenuItem key={hash()} value={item[INDEX_SELECTED_VALUE]}>
                            {item[SELECTED_VALUE].name}
                        </MenuItem>
                    ))}
                </SelectComponent>
            );
        }

        if (vidooType === VIDOO_TYPES.OBJECT_DETECTION) {
            return (
                <SelectComponent
                    value={vidooTypeCapability.objectCapability}
                    label={info.hints.value}
                    info={info.text.value}
                    onChange={handleTypeCapabilityChange}
                    small
                >
                    <MenuItem value={SUMMARY_VIDOO_OPTIONS.ANY_OBJECT}>{t(EZLOGIC_TITLE_ANY_OBJECT)}</MenuItem>
                    {getTypesVidooList(vidooType, items).map((item) => (
                        <MenuItem key={hash()} value={item[INDEX_SELECTED_VALUE]}>
                            {item[SELECTED_VALUE].name}
                        </MenuItem>
                    ))}
                </SelectComponent>
            );
        }

        if (vidooType === VIDOO_TYPES.QR_CODE_DETECTION) {
            return (
                <SelectComponent
                    value={vidooTypeCapability.qrCodeCapability}
                    label={info.hints.value}
                    info={info.text.value}
                    onChange={handleTypeCapabilityChange}
                    small
                >
                    <MenuItem value={SUMMARY_VIDOO_OPTIONS.ANY_QRCODE}>{t(EZLOGIC_TITLE_ANY_QR_CODE)}</MenuItem>
                    {getTypesVidooList(vidooType, items).map((item) => (
                        <MenuItem key={hash()} value={item[INDEX_SELECTED_VALUE]}>
                            {item[SELECTED_VALUE].name}
                        </MenuItem>
                    ))}
                </SelectComponent>
            );
        }
    };

    const vidooCamerasList = () => {
        return (
            <div className={styles.select__cameras}>
                <span className={styles.description}>{t(EZLOGIC_TITLE_IS_DETECTED_IN)}</span>
                <SelectComponent
                    value={selectedCamera}
                    label={info.hints.cameras}
                    onChange={handleSelectedCameraChange}
                    small
                >
                    {getVidooCamerasList(devices).map((device) => (
                        <MenuItem key={hash()} value={device._id}>
                            {device.name}
                        </MenuItem>
                    ))}
                </SelectComponent>
            </div>
        );
    };

    const cameraHotzones = () => {
        return (
            <SelectComponent
                value={selectedHotzone}
                label={info.hints.zones}
                onChange={handleSelectedHotzoneChange}
                disabled={vidooType === VIDOO_TYPES.QR_CODE_DETECTION}
                small
            >
                <MenuItem value={SUMMARY_VIDOO_OPTIONS.FULL_SCREEN}>{t(EZLOGIC_TITLE_FULL_SCREEN)}</MenuItem>
                <MenuItem value={SUMMARY_VIDOO_OPTIONS.ANY_ZONE}>{t(EZLOGIC_TITLE_ANY_ZONE)}</MenuItem>
                {getCameraHotzones(selectedCamera, items).map((item) => {
                    if (item[0] !== '') {
                        return (
                            <MenuItem key={hash()} value={item[INDEX_SELECTED_VALUE]}>
                                {item[SELECTED_VALUE].name}
                            </MenuItem>
                        );
                    }
                })}
            </SelectComponent>
        );
    };

    return (
        <>
            {selectedFieldTrigger === 'vidoo' && vidooTypes()}
            {vidooType && vidooCapabilitiesList()}
            {(vidooTypeCapability.faceCapability === SUMMARY_VIDOO_OPTIONS.ANY_PERSON ||
                vidooTypeCapability.faceCapability.length > 0 ||
                vidooTypeCapability.objectCapability === SUMMARY_VIDOO_OPTIONS.ANY_OBJECT ||
                vidooTypeCapability.objectCapability.length > 0) &&
                vidooCamerasList()}
            {selectedCamera && cameraHotzones()}
        </>
    );
};

export default VidooNodeForTrigger;

VidooNodeForTrigger.propTypes = {
    id: PropTypes.string,
    idGroup: PropTypes.string,
    items: PropTypes.array,
    blocks: PropTypes.array,
    devices: PropTypes.array,
    selectedFieldTrigger: PropTypes.string,
    onSelectedVidooTrigger: PropTypes.func,
    vidooTypeValue: PropTypes.string,
    capabilityValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    selectedCameraValue: PropTypes.string,
    selectedHotzoneValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
