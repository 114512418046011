import React from 'react';

import { EZLOGIC_TITLE_FIRMWARE, EZLOGIC_TITLE_TYPE } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const ListOfMetaDependencies = ({ config }) => {
    const { t } = useTranslate();

    return (
        <ul>
            {config?.dependencies && (
                <li>
                    <span>{t(EZLOGIC_TITLE_FIRMWARE)}:</span>
                    {config?.dependencies?.firmware}
                </li>
            )}
            <li>
                <span>{t(EZLOGIC_TITLE_TYPE)}</span> {config?.type}
            </li>
        </ul>
    );
};

export default ListOfMetaDependencies;
