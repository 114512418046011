import React from 'react';

const EmptyStateIcon = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M147.08 146.16H55.0801C46.2401 146.16 39.0801 139 39.0801 130.16V67.16H147.08V146.16Z"
            fill="#3E46AE"
        />
        <path
            d="M79.0801 67.16H39.0801V55.16H67.6901C72.3901 55.16 76.6201 59.04 78.4301 65.01L79.0801 67.16Z"
            fill="#3E46AE"
        />
        <path
            d="M147.08 146.16H55.0801C46.2401 146.16 39.0801 139 39.0801 130.16V83.16H147.08V146.16Z"
            fill="#1F2581"
        />
        <path d="M147.08 146.16H55.0801L70.0801 83.16H162.08L147.08 146.16Z" fill="#50C1D6" />
        <path d="M147.08 146.16H55.0801L58.8901 130.16H150.86L147.08 146.16Z" fill="#38A7C1" />
        <path d="M138.99 52.69L150.7 40.98" stroke="#3E46AE" strokeWidth="4" strokeMiterlimit="10" />
        <path d="M143.14 59.06L158.26 52.29" stroke="#3E46AE" strokeWidth="4" strokeMiterlimit="10" />
    </svg>
);

export default EmptyStateIcon;
