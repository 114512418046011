import React from 'react';
import { HoursAndMinutesSelect } from '../../templateDateTimeBlocks/sectionCustom';
import TimeZoneSelect from '../TimeZoneSelect';
import { PM } from '../../../../../../constants/MeshbotConstant';

const DailyNode = (props) => {
    const { blocks, onChangeTime, handleChangeTimezone } = props;

    return (
        <>
            <HoursAndMinutesSelect
                onChangeCustomTime={(time, modifier) => onChangeTime({ times: [time], isPm: modifier === PM })}
                customHours={blocks?.[0]?.parameters?.[0]?.times?.[0]?.[0] || 12}
                customMinutes={blocks?.[0]?.parameters?.[0]?.times?.[0]?.[1] || 0}
                isPm={blocks?.[0]?.parameters?.[0]?.isPm}
            />
            <TimeZoneSelect handleChangeTimezone={handleChangeTimezone} blocks={blocks} />
        </>
    );
};

export default DailyNode;
