import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import HeaderComponent from '../../components/Header';
import PinCodeForm from '../PinCodeForm';
import UsersActions from '../../../../../actions/UsersActions';
import { getInitialDevices, setPanel } from '../utils';
import { validationSchema } from '../PinCodeForm/validationSchema';
import { EZLOGIC_TITLE_PIN_CODE } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const PinCodeCreate = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const ezloData = useSelector(({ ezlo }) => ezlo.data);
    const devices = getInitialDevices(ezloData);

    const initialValues = {
        name: '',
        pin: '',
        devices,
    };
    setPanel(initialValues, true);
    // todo [ENWT-3630] User Pin Codes old versions. Below is the new version(commented)
    const handleClickSave = (values) => {
        const { name, pin, panel, devices } = values;
        const selectedDevices = devices.filter(({ checked }) => Boolean(checked));

        const pinCodeData = {
            name,
            pin,
            meta: {},
        };

        setPanel(pinCodeData.meta, panel);

        dispatch(UsersActions.createPinCode(pinCodeData, selectedDevices, t));
    };
    // todo [ENWT-3630] User Pin Codes updates. Implementation.
    // const handleClickSave = (values) => {
    //     dispatch(UsersActions.createPinCode(values, t));
    // };

    return (
        <>
            <HeaderComponent title={t(EZLOGIC_TITLE_PIN_CODE)} />

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClickSave}>
                <PinCodeForm />
            </Formik>
        </>
    );
};

export default withErrorBoundary(PinCodeCreate, FallbackMeshbotNodeComponent);
