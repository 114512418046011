import React, { useMemo, memo } from 'react';

import ExpressionForCompareSelect from '../ExpressionForCompareSelect';
import ExpressionComparedValueTypes from '../ExpressionComparedValueTypes/ExpressionComparedValueTypes';

import {
    EXPRESSIONS_TYPE,
    COMPARED_TYPE_VALUE,
    ONE_INT,
    ZERO_INT,
    RGB_COLORS,
} from '../../../../../../../constants/Expressions';
import { DATA_TYPES_LIST } from '../../../../../../../constants/Variables';

import { convertExpressionTypeToDataType, findSelectedExpression } from '../../utils';
import { isSupportedExpressionValueType } from '../../utils';

const ExpressionComparedValue = (props) => {
    const {
        comparedType,
        expressionList,
        comparingValue,
        initialExpressionName,
        expressionListSelector,
        selectedExpressionType,
        onHandleExpressionComparisonFieldsChange,
        initialComparatorValue,
        isExpressionComparison,
        initialRangeStartValue,
        initialRangeEndValue,
    } = props;

    const selectedExpressionValue = findSelectedExpression(initialExpressionName, expressionList);
    const convertedExpressionType = convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue);

    const filteredExpressions = useMemo(() => {
        if (!isExpressionComparison) {
            return expressionListSelector?.filter((expression) => {
                switch (convertedExpressionType) {
                    case DATA_TYPES_LIST.TYPE_INT:
                        return (
                            !expression?.variable &&
                            isSupportedExpressionValueType(expression?.value) &&
                            convertExpressionTypeToDataType(typeof expression?.value) === convertedExpressionType &&
                            expression?.value % ONE_INT === ZERO_INT
                        );
                    case DATA_TYPES_LIST.TYPE_FLOAT:
                        return (
                            !expression?.variable &&
                            isSupportedExpressionValueType(expression?.value) &&
                            convertExpressionTypeToDataType(typeof expression?.value, expression?.value) ===
                                convertedExpressionType &&
                            expression?.value % ONE_INT !== ZERO_INT
                        );
                    default:
                        return (
                            !expression?.variable &&
                            isSupportedExpressionValueType(expression?.value) &&
                            convertExpressionTypeToDataType(typeof expression?.value) === convertedExpressionType
                        );
                }
            });
        }

        return expressionList?.filter((expression) => {
            switch (convertedExpressionType) {
                case DATA_TYPES_LIST.TYPE_FLOAT:
                    return (
                        expression.value % ONE_INT !== ZERO_INT &&
                        typeof expression.value === selectedExpressionType &&
                        expression.name !== initialExpressionName
                    );
                case DATA_TYPES_LIST.TYPE_RGB:
                    const isRGBType =
                        Object.keys(expression?.value).includes(RGB_COLORS.RED) &&
                        Object.keys(expression?.value).includes(RGB_COLORS.GREEN) &&
                        Object.keys(expression?.value).includes(RGB_COLORS.BLUE);

                    return (
                        isRGBType &&
                        expression.name !== initialExpressionName &&
                        typeof expression.value === selectedExpressionType
                    );
                case DATA_TYPES_LIST.TYPE_INT:
                    return (
                        expression.value % ONE_INT === ZERO_INT &&
                        expression.name !== initialExpressionName &&
                        typeof expression.value === selectedExpressionType
                    );
                default:
                    return (
                        expression.name !== initialExpressionName && typeof expression.value === selectedExpressionType
                    );
            }
        });
    }, [expressionList, convertedExpressionType, isExpressionComparison]);

    const variableList = useMemo(() => {
        return expressionListSelector?.filter((item) => {
            if (!isExpressionComparison) {
                return (
                    item?.variable &&
                    item?.valueType === selectedExpressionValue?.valueType &&
                    item?.name !== selectedExpressionValue?.name
                );
            }

            switch (convertedExpressionType) {
                case DATA_TYPES_LIST.TYPE_FLOAT:
                    return (
                        item.value % ONE_INT !== ZERO_INT &&
                        item?.variable &&
                        typeof item.value === selectedExpressionType
                    );
                case DATA_TYPES_LIST.TYPE_RGB:
                    return item?.valueType === DATA_TYPES_LIST.TYPE_RGB && item?.variable;
                default:
                    return item?.variable && typeof item.value === selectedExpressionType;
            }
        });
    }, [expressionListSelector, convertedExpressionType, isExpressionComparison]);

    const selectedExpressionObject = useMemo(() => {
        return findSelectedExpression(initialExpressionName, expressionList);
    }, [initialExpressionName, expressionList]);

    const handleChangeComparingValue = (e) => {
        onHandleExpressionComparisonFieldsChange(
            initialExpressionName,
            expressionList,
            initialComparatorValue,
            comparedType,
            e.target.value,
        );
    };

    const handleChangeExpressionComparingValue = (value) => {
        onHandleExpressionComparisonFieldsChange(
            initialExpressionName,
            expressionList,
            initialComparatorValue,
            comparedType,
            value,
        );
    };

    return (
        <>
            {comparedType === COMPARED_TYPE_VALUE && (
                <ExpressionComparedValueTypes
                    comparingValue={comparingValue}
                    onHandleChangeComparingValue={handleChangeComparingValue}
                    selectedExpressionType={selectedExpressionType}
                    onHandleChangeComparingRGBValue={handleChangeExpressionComparingValue}
                    selectedExpressionObject={selectedExpressionObject}
                    convertedExpressionType={convertedExpressionType}
                    comparatorValue={initialComparatorValue}
                    initialRangeStartValue={initialRangeStartValue}
                    initialRangeEndValue={initialRangeEndValue}
                />
            )}
            {(comparedType === EXPRESSIONS_TYPE.EXPRESSION || comparedType === EXPRESSIONS_TYPE.VARIABLE) && (
                <ExpressionForCompareSelect
                    expressionList={comparedType === EXPRESSIONS_TYPE.VARIABLE ? variableList : filteredExpressions}
                    initialExpressionName={comparingValue}
                    onHandleExpressionComparisonFieldsChange={handleChangeExpressionComparingValue}
                    isComparedValue={true}
                />
            )}
        </>
    );
};

export default memo(ExpressionComparedValue);
