import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { listOfFunctions } from '../../../../template';

import style from '../../CloudVariable.module.scss';
import { EMPTY_LINE } from '../../../../constants';
import { EZLOGIC_TITLE_FUNCTION } from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';

const FunctionSelect = ({ currentFunc, handleChangeFunc }) => {
    const { t } = useTranslate();

    const renderInput = (params) => (
        <TextField
            size="small"
            variant="outlined"
            required
            {...params}
            name="functionValue"
            label={t(EZLOGIC_TITLE_FUNCTION)}
        />
    );

    return (
        <Autocomplete
            value={currentFunc}
            options={listOfFunctions}
            getOptionLabel={(option) => (option.name ? option.name : EMPTY_LINE)}
            getOptionSelected={(option, value) => option.uuid === value.uuid}
            onChange={handleChangeFunc}
            disableClearable={true}
            renderInput={renderInput}
            renderOption={(option) => <div className={style.option}>{option.name}</div>}
        />
    );
};

export default memo(FunctionSelect);
