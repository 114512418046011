import React from 'react';
import { Modal, Button, IconButton } from '@material-ui/core';

import styles from './ConfirmationModal.module.scss';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_BUTTON_CANCEL } from '../../constants/language_tokens';
import { CloseIcon, DeleteModalIcon } from '../../assets/icons';

const ConfirmModalDefaultTemplate = ({
    title,
    text,
    confirmButtonTitle,
    onCancel,
    onConfirm,
    isConfirmButtonDisabled,
    isVirtualContainerPage,
    ConfirmModalFields,
}) => {
    const { t } = useTranslate();

    return (
        <>
            {isVirtualContainerPage ? (
                <>
                    <IconButton onClick={onCancel}>
                        <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                    <DeleteModalIcon className={styles.deleteIcon} />
                    <div className={styles.modalBody}>
                        <h3 className={styles.modalBodyTitle}>{t(title)}</h3>
                        <p className={styles.modalBodyText}>{t(text)}</p>
                        <Button
                            disabled={isConfirmButtonDisabled}
                            color="primary"
                            variant="contained"
                            onClick={onConfirm}
                        >
                            {t(confirmButtonTitle)}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <h3 className={styles.title}>{t(title)}</h3>
                    {text && <p className={styles.modalBodyText}>{t(text)}</p>}
                    {ConfirmModalFields && ConfirmModalFields}
                    <div className={styles.controls}>
                        <Button onClick={onCancel} variant="outlined">
                            {t(EZLOGIC_BUTTON_CANCEL)}
                        </Button>

                        <Button
                            disabled={isConfirmButtonDisabled}
                            color="primary"
                            variant="contained"
                            onClick={onConfirm}
                        >
                            {t(confirmButtonTitle)}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

ConfirmModalDefaultTemplate.defaultProps = {
    confirmButtonTitle: 'Confirm',
    isConfirmButtonDisabled: false,
};

export const ConfirmModal = (props) => {
    const {
        title,
        text,
        confirmButtonTitle,
        isOpened,
        children,
        onCancel,
        onConfirm,
        onClose,
        isConfirmButtonDisabled,
        isVirtualContainerPage,
        ConfirmModalFields,
    } = props;

    const handleClose = () => {
        onClose(false);
    };

    return (
        <Modal open={isOpened} onClose={handleClose}>
            <div
                className={isVirtualContainerPage ? styles.confirmationModalVirtualContainer : styles.confirmationModal}
            >
                {children ? (
                    children
                ) : (
                    <ConfirmModalDefaultTemplate
                        title={title}
                        text={text}
                        confirmButtonTitle={confirmButtonTitle}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        isConfirmButtonDisabled={isConfirmButtonDisabled}
                        isVirtualContainerPage={isVirtualContainerPage}
                        ConfirmModalFields={ConfirmModalFields}
                    />
                )}
            </div>
        </Modal>
    );
};
