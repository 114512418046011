import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { ConfirmDialog } from '../../../../../components';
import Dialog from '../../../../../components/Dialog';
import MeshBotLocalForm from '../MeshBotLocalForm';
import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

import at from '../../../../../constants/ActionTypes/MeshBot';
import { EDIT } from '../../../../../constants/MeshbotConstant';
// import { t } from '../../../../../helpers/language';
import { initialMeshbotTriggers, getActionsBlocks, getDeviceId } from '../../utils';
import { SAVE } from '../../../../../constants/ComponentConstants';
import { ExpressionsActions } from '../../../../../actions';
import {
    EZLOGIC_TITLE_SAVE_MESHBOT,
    EZLOGIC_TITLE_SAVE_MESHBOT_CONFIRMATION_MSG,
    EZLOGIC_TITLE_WARNING,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const MeshBotLocEdit = (props) => {
    //Todo rename
    const [currentMeshbot, setCurrentMeshbot] = useState({});
    const [isOptionType, setIsOptionType] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [validationWarnings, setValidationWarnings] = useState([]);
    const [showSaveWarningsConfirmDialog, setShowSaveWarningsConfirmDialog] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showValidation, setShowValidation] = useState(false);

    const { t } = useTranslate();
    const {
        serial,
        rules,
        items,
        devices,
        comparisonOperatorsFamilies,
        selectedRule,
        ruleTriggers,
        match,
        MeshBotAction,
        errorsValidation,
        warningsValidation,
        isApiProcessStarted,
    } = props;

    const allComparisonMethods = useMemo(
        () => comparisonOperatorsFamilies.reduce((allMethods, { methods }) => [...allMethods, ...methods], []),
        [JSON.stringify(comparisonOperatorsFamilies)],
    );

    const singleMeshbot = rules.find((item) => item._id === match.params.id);

    useEffect(() => {
        const thenActions = getActionsBlocks(singleMeshbot?.then, items, devices, rules);
        const elseActions = getActionsBlocks(singleMeshbot?.else, items, devices, rules);

        let trigger = {};

        if (singleMeshbot?.when[0]) {
            trigger = initialMeshbotTriggers(singleMeshbot.when[0], items, devices, rules, allComparisonMethods);

            const idDevices = () =>
                trigger.triggersDevicesBlock.map((item) => {
                    const result = getDeviceId(item.fields[0].value, items);

                    if (result) {
                        return result.deviceId;
                    }
                });

            const params = { blockType: 'when', devices: idDevices() };
            MeshBotAction.getDeviceBlocksEdit(serial, params, items);
        }

        if (singleMeshbot?.exceptions?.length) {
            const exceptionsData = singleMeshbot.exceptions.map((exception) => {
                return initialMeshbotTriggers(exception, items, devices, rules, allComparisonMethods);
            });

            MeshBotAction.setInitialMeshbotExceptions(exceptionsData, thenActions, elseActions);
        }

        // Setting Meshbots labels in Redux store for editing changes.
        MeshBotAction.setInitialLocalMeshBotLabels(singleMeshbot);

        MeshBotAction.setInitialMeshbotTriggers(trigger, singleMeshbot);
        MeshBotAction.setInitialMeshbotActions(thenActions, elseActions);
        ExpressionsActions.updateExpressions(serial, t);
        MeshBotAction.getLuaScripts(serial);
        //Todo rename
        setCurrentMeshbot(singleMeshbot);
    }, [JSON.stringify(comparisonOperatorsFamilies)]);

    useEffect(() => {
        MeshBotAction.setIdOfEditedMeshbot(match.params.id);

        return () => MeshBotAction.setIdOfEditedMeshbot(null);
    }, [match]);

    useEffect(() => {
        setCurrentMeshbot(singleMeshbot);
    }, [rules]);

    useEffect(() => {
        if (
            (!selectedRule.hasOwnProperty('optionType') || selectedRule.optionType === undefined) &&
            ruleTriggers.length > 1 &&
            !isOptionType
        ) {
            MeshBotAction.updateSelectedRuleNot('and', null, 'optionType');
            setIsOptionType(true);
        }
    }, [ruleTriggers, selectedRule]);

    useEffect(() => {
        if (errorsValidation && errorsValidation.length) {
            showValidationErrors(errorsValidation);
            setIsConfirmModalVisible(false);
        }
    }, [errorsValidation]);

    useEffect(() => {
        if (warningsValidation && warningsValidation.length) {
            setValidationWarnings(warningsValidation);
            openSaveWarningsConfirmDialog();
        }
    }, [warningsValidation]);

    useEffect(() => {
        if (isApiProcessStarted === false) {
            setIsConfirmModalVisible(false);
        }
    }, [isApiProcessStarted]);

    const closeSaveWarningsConfirmDialog = () => setShowSaveWarningsConfirmDialog(false);
    const openSaveWarningsConfirmDialog = () => setShowSaveWarningsConfirmDialog(true);

    const showValidationErrors = (errors) => {
        setShowValidation(true);
        setErrors(errors);
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            handlerSave();
        }
        setIsConfirmModalVisible(false);
    };

    const openSaveConfirmDialog = () => {
        if (!validationNameGroup(selectedRule.when)) {
            showValidationDialog('Need to add a group name');
        } else {
            setIsConfirmModalVisible(true);
        }
    };

    const validationNameGroup = (data) => {
        let result = true;
        data.map((item) => {
            if (item.hasOwnProperty('blockName') && item.blockName === '') {
                result = false;
            }
        });

        return result;
    };

    const showValidationDialog = (errors) => {
        closeSaveWarningsConfirmDialog();
        setIsConfirmModalVisible(false);
        setShowValidation(true);
        setErrors(errors);
    };

    const handlerSave = async () => {
        MeshBotAction.saveLocalMeshBot(t);
    };

    return (
        <>
            <MeshBotLocalForm
                isEditing
                typeForm={EDIT}
                typeMeshbot={at.LOCAL}
                onOpenSaveConfirmDialog={openSaveConfirmDialog}
                selectedRule={selectedRule}
                currentMeshbot={currentMeshbot}
                wizardMode={true}
            />

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={EZLOGIC_TITLE_SAVE_MESHBOT}
                text={EZLOGIC_TITLE_SAVE_MESHBOT_CONFIRMATION_MSG}
                confirmButtonTitle={SAVE}
                isConfirmButtonDisabled={isApiProcessStarted}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />

            <ConfirmDialog
                title={t(EZLOGIC_TITLE_WARNING)}
                open={showSaveWarningsConfirmDialog}
                onHide={closeSaveWarningsConfirmDialog}
                onConfirm={() => {
                    closeSaveWarningsConfirmDialog();
                    handlerSave();
                }}
            >
                {validationWarnings.map((warning, index) => (
                    <span key={index}>{warning}</span>
                ))}
                <br />
                {t`ezlo.generic.are_you_sure`}
            </ConfirmDialog>
            <Dialog title={t(EZLOGIC_TITLE_WARNING)} open={showValidation} onHide={() => setShowValidation(false)}>
                {errors}
            </Dialog>
        </>
    );
};

export default MeshBotLocEdit;

MeshBotLocEdit.propTypes = {
    rules: PropTypes.array,
    items: PropTypes.array,
    devices: PropTypes.array,
    comparisonOperatorsFamilies: PropTypes.array,
    ruleTriggers: PropTypes.array,
    selectedRule: PropTypes.object,
    match: PropTypes.object,
    MeshBotAction: PropTypes.object,
    isApiProcessStarted: PropTypes.bool,
    errorsValidation: PropTypes.array,
    warningsValidation: PropTypes.array,
};
