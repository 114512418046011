import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, IconButton, TextField } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import Input from '../../../../../components/InputMaterial';
import useUnsavedChangesWarning from '../../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';

import * as script from '../../../../../constants/ScriptConstants';
import { SCRIPT_CREATING_FORM, SCRIPT_EDITING_FORM } from '../../../../../constants/ActionConfirmDialog';
import { MainAction } from '../../../../../actions';

import { input, buttons, button, buttonWhite, textarea, scriptWrapper, backBtn } from './styles.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_SAVE_SCRIPT,
    EZLOGIC_LABEL_LUA_CODE,
    EZLOGIC_TITLE_BACK,
    EZLOGIC_TITLE_NAME_SCRIPT,
    EZLOGIC_TITLE_PUT_SCRIPT_BODY,
    EZLOGIC_TITLE_PUT_SCRIPT_NAME,
} from '../../../../../constants/language_tokens';

const ScriptForm = (props) => {
    const { serial, type, id, singleLuaScript, history, MeshBotAction } = props;
    const [name, setName] = useState(singleLuaScript?.name ?? '');
    const [code, setCode] = useState(singleLuaScript?.code ?? '');
    const dispatch = useDispatch();
    const setDirty = useUnsavedChangesWarning();
    const isDirty = useSelector((state) => state.main.editableComponent?.isDirty);
    const isDisabledSaveButton = singleLuaScript
        ? singleLuaScript?.name === name && singleLuaScript?.code === code
        : !code || name === '';
    const { t } = useTranslate();

    useEffect(() => {
        if (singleLuaScript) {
            setName(singleLuaScript.name);
            setCode(singleLuaScript.code);
        }
    }, [singleLuaScript]);

    useEffect(() => {
        if (type === script.SCRIPT_EDIT) {
            dispatch(MainAction.setStatePageBeingEdited(SCRIPT_EDITING_FORM));
        } else {
            dispatch(MainAction.setStatePageBeingEdited(SCRIPT_CREATING_FORM));
        }

        return () => dispatch(MainAction.setStatePageBeingEdited({}));
    }, []);

    useEffect(() => {
        if (!name && !code) {
            setDirty(false);
            dispatch(MainAction.setConfirmationUser(false));
        } else if (name || code) {
            if (!isDirty) {
                setDirty(true);
                dispatch(MainAction.setConfirmationUser(true));
            }
        }
    }, [name, code]);

    useEffect(() => {
        if (singleLuaScript && name) {
            if (singleLuaScript.name !== name || singleLuaScript.code !== code) {
                if (!isDirty) {
                    setDirty(true);
                    dispatch(MainAction.setConfirmationUser(true));
                }
            } else if (singleLuaScript.name === name || singleLuaScript.code === code) {
                setDirty(false);
                dispatch(MainAction.setConfirmationUser(false));
            }
        }
    }, [singleLuaScript, name, code]);

    const handlerSaveScript = () => {
        const params = { name, code };

        if (type === script.SCRIPT_CREATE) {
            MeshBotAction.addLuaScript(serial, params);
        }

        if (type === script.SCRIPT_EDIT) {
            MeshBotAction.setLuaScript(serial, { ...params, _id: id });
        }
    };

    const handlerBack = () => {
        dispatch(MainAction.confirmUserAction(() => history.replace(script.ROUTE_PRIVATE)));
    };

    return (
        <div className={scriptWrapper}>
            <IconButton size="small" className={backBtn} onClick={handlerBack}>
                <ArrowBackIos />
            </IconButton>
            <FormControl className={input}>
                <Input
                    label={t(EZLOGIC_TITLE_NAME_SCRIPT)}
                    placeholder={t(EZLOGIC_TITLE_PUT_SCRIPT_NAME)}
                    otherClass="input-block_script"
                    maxLength={script.LUA_SCRIPT_NAME_INPUT_MAX_LENGTH}
                    value={name ?? ''}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
            <TextField
                className={textarea}
                label={t(EZLOGIC_LABEL_LUA_CODE)}
                placeholder={t(EZLOGIC_TITLE_PUT_SCRIPT_BODY)}
                multiline
                value={code ?? ''}
                onChange={(e) => setCode(e.target.value)}
            />
            <div className={buttons}>
                <Button
                    className={`${button} ${buttonWhite}`}
                    variant="contained"
                    color="primary"
                    onClick={handlerBack}
                >
                    {t(EZLOGIC_TITLE_BACK)}
                </Button>
                <Button
                    className={button}
                    variant="contained"
                    color="primary"
                    disabled={isDisabledSaveButton}
                    onClick={handlerSaveScript}
                >
                    {t(EZLOGIC_BUTTON_SAVE_SCRIPT)}
                </Button>
            </div>
        </div>
    );
};

export default ScriptForm;
