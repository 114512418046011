import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { selectField } from '../../../EzloAccount/UserManagement/style.module.scss';
import CheckboxesList from './CheckboxesList';

export default function ChildDevicesAutocomplete({ options, onChange, roomList, checkedOptions, label }) {
    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            options={options}
            value={checkedOptions}
            onChange={(event, value) => {
                onChange(value);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => {
                return <CheckboxesList selected={selected} option={option} roomList={roomList} />;
            }}
            className={selectField}
            renderInput={(params) => <TextField {...params} size="small" label={label} />}
        />
    );
}
