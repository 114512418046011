import React from 'react';
import { Chip } from '@material-ui/core';
import { CUSTOM_PREFIX } from '../../../constants';

import { useTranslate } from '../../../../../../../../features/languages';
import { EZLOGIC_TITLE_DATA_VARIABLES } from '../../../../../../../../constants/language_tokens';

import styles from '../CloudVariable.module.scss';

const IntegrationSimpleCodeContainer = (props) => {
    const { codeArray, onChangeCodeArray, handleEdit } = props;
    const { t } = useTranslate();

    if (!codeArray || !codeArray.length) {
        return null;
    }

    const handleDeleteItemOfCodeArray = (uuidToFind) => {
        const updatedState = codeArray.filter(({ uuid }) => uuid !== uuidToFind);
        onChangeCodeArray(updatedState);
    };

    return (
        <div>
            <div className={styles.variablesListTitle}>{t(EZLOGIC_TITLE_DATA_VARIABLES)}</div>
            <div className={styles.variablesChipsBox}>
                {codeArray.map((codeItem) => (
                    <div className={styles.variablesChipBox} key={codeItem.uuid}>
                        <Chip
                            color="primary"
                            label={
                                codeItem?.capability.startsWith(CUSTOM_PREFIX)
                                    ? codeItem.capability.slice(CUSTOM_PREFIX.length)
                                    : codeItem.capability
                            }
                            onDelete={() => handleDeleteItemOfCodeArray(codeItem.uuid)}
                            onClick={(e) => handleEdit(e, codeItem)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IntegrationSimpleCodeContainer;
