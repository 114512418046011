import React, { useEffect, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { InputMaterial } from '../index';
import { ModalNotificationsCategoryCreating } from '../../containers/Ezlo/EzloMeshbot/components/ModalNotificationsCategoryCreating';
import EzloNotificationTemplateKeywords from '../../containers/Ezlo/EzloNotificationTemplate/components/EzloNotificationTemplateKeywords';
// eslint-disable-next-line max-len
import { NotificationCloudCategories } from './NotificationCloudCategories';
import { NotificationCloudChannels } from './NotificationCloudChannels';
import { NotificationCloudUsers } from './NotificationCloudUsers';

import {
    buildNotificationCategoryParams,
    buildNotificationCategoryParamsForDeleting,
    buildNotifyCategoriesObjects,
    buildNotifyCategoriesObjectsByName,
    buildNotifyUsersBlock,
} from '../../containers/Ezlo/EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { DATA_FROM_VALUE_FROM_BLOCK_STATIC, NOTIFICATION_TEMPLATE } from '../../constants/NotificationTemplates';
import { MAX_LENGTH_BODY, MAX_LENGTH_SUBJECT, MESHBOT_TEMPLATE } from '../../constants/Notifications';
import actions from '../../actions/NotificationActions';
import {
    getChannels,
    createUuid,
    getCategoriesListByUuid,
    returnUuidArray,
    getArrayForDeleteCategory,
    setIsEditCategory,
    getArrayForEditCategory,
    getCategoryArrayWithoutIsEditKey,
    isDuplicate,
    setCancelEditingCategory,
    getAllUsersFromAccount,
    isCurrentPkAccountOemTemplateMaster,
} from '../../containers/Ezlo/EzloNotificationTemplate/utils';
import { PARTNER_PERMISSION } from '../../containers/Ezlo/EzloCustomization/constants';
import { FIRST_BLOCK } from '../../constants/Variables';
import at from '../../constants/ActionTypes/Notification';
import { bugsnagNotifyWrapper } from '../../containers/ErrorBoundary/utils';
import { ACCOUNT } from '../../containers/Navigation/constants';
import { BODY_HTML, BODY, CLOUD_NOTIFICATION, SUBJECT, ALL, EMAIL, ZERO_INT } from '../../constants/MeshbotConstant';

import {
    EZLOGIC_TITLE_ALREADY_HAVE_CATEGORY,
    EZLOGIC_TITLE_BE_AWARE,
    EZLOGIC_TITLE_CHARACTERS,
    EZLOGIC_TITLE_HTML_MESSAGE_BODY,
    EZLOGIC_TITLE_LIMIT_FOR_ANDROID,
    EZLOGIC_TITLE_LIMIT_FOR_IOS,
    EZLOGIC_TITLE_MESSAGE_BODY,
    EZLOGIC_TITLE_MESSAGE_BODY_HTML,
    EZLOGIC_TITLE_SUBJECT,
    EZLOGIC_TITLE_SUCCESSFULLY_CREATE,
    EZLOGIC_TITLE_SUCCESSFULLY_DELETED,
    EZLOGIC_TITLE_SUCCESSFULLY_EDITED,
    EZLOGIC_TITLE_TITLES_LIMITS,
} from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';
import styles from './NotificationCloudBlock.module.scss';

const NotificationCloudBlock = ({
    channels = [],
    currentRule,
    // TODO: rename, it's SET (not GET)!
    getNotificationData,
    id,
    usersData,
    notificationType,
}) => {
    const notificationCategoriesList = useSelector((state) => state.notifications.notificationCategories || []);
    const notificationMeshbotType = useSelector((state) => state.notifications?.notificationType);
    const pkPermissionRole = useSelector((state) => state.account.data.PK_PermissionRole);
    const currentPkAccount = useSelector((state) => state.account?.data?.PK_Account);
    const [userData, setUsers] = useState([]);
    const [selectedUsers, setSelectedUser] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [isCategoriesEmpty, setIsCategoriesEmpty] = useState(false);
    const [isCreatingCategoriesModalShow, setShowCreatingCategoriesModal] = useState(false);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [body_html, setBodyHtml] = useState('');
    const [targets, setTargets] = useState([
        {
            type: 'user',
            uuid: '',
        },
    ]);
    const { t } = useTranslate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (usersData && usersData.length) {
            setUsers(usersData);
        }

        if (channels && channels.length) {
            const handleSelectedChannels = channels.map((item) => item.id);
            setSelectedChannel(handleSelectedChannels);
        }

        if (currentRule && currentRule.length > 0) {
            const rule = currentRule.find((item) => item.id === id);

            if (rule && rule.notification) {
                setInitialState(rule.notification, usersData, channels);
            }
        }
    }, [usersData, channels, notificationCategoriesList]);

    useEffect(() => {
        dispatch(actions.getNotificationCategories());
    }, []);

    const isAllSelected = channels.length > 0 && selectedChannel.length === channels.length;
    const isAllCategoriesSelected =
        notificationCategoriesList?.length > 0 && selectedCategory?.length === notificationCategoriesList?.length;

    // need refactoring
    const setInitialState = (values, usersData, channels) => {
        const [{ subject, body, body_html }] = values.parameters;
        const categories = getCategoriesListByUuid(values.parameters, notificationCategoriesList);
        setSelectedChannel(getChannels(values, channels));

        if (values?.parameters?.[FIRST_BLOCK]?.targets) {
            setTargets(values.parameters[FIRST_BLOCK].targets);
        }

        const getSelectedUuid = usersData.filter((item) => returnUuidArray(values).includes(item.uuid));

        const filterUuid = getSelectedUuid.map((item) => item.Username);

        if (categories) {
            setSelectedCategory(categories);
        }

        if (subject) {
            setSubject(subject);
        }

        if (body) {
            setBody(body);
        }

        if (body_html) {
            setBodyHtml(body_html);
        }

        if (filterUuid) {
            const type = values?.parameters?.[FIRST_BLOCK]?.targets?.[DATA_FROM_VALUE_FROM_BLOCK_STATIC]?.type;
            setSelectedUser(type === ACCOUNT ? getAllUsersFromAccount(usersData) : filterUuid);
        }

        if (categories?.length === ZERO_INT) {
            setIsCategoriesEmpty(true);
        }
    };

    const handleChangeUser = (event) => {
        const { value } = event.target;
        setSelectedUser(value);
        const filterUuid = usersData.filter((item) => value.includes(item.Username));
        const targets = filterUuid.map((user) => ({
            type: 'user',
            uuid: user.uuid,
            channels: selectedChannel,
        }));

        setTargets(targets);
        const data = buildNotifyUsersBlock(body, targets, subject);
        getNotificationData(data, id, CLOUD_NOTIFICATION);
    };

    const handleChangeCategory = (event) => {
        const { value } = event.target;
        const lastElementOfValues = value.length - 1;
        if (value[lastElementOfValues] === ALL) {
            const selectCategories =
                selectedCategory.length === notificationCategoriesList.length
                    ? []
                    : notificationCategoriesList.map((item) => item.display_name);
            setSelectedCategory(selectCategories);
            const data = buildNotifyUsersBlock(
                body,
                targets,
                subject,
                body_html,
                selectCategories.length ? buildNotifyCategoriesObjects(notificationCategoriesList) : [],
                notificationType,
                selectedChannel,
            );
            getNotificationData(data, id);
        } else {
            setSelectedCategory(value);
            const data = buildNotifyUsersBlock(
                body,
                targets,
                subject,
                body_html,
                buildNotifyCategoriesObjectsByName(notificationCategoriesList, value),
                notificationType,
                selectedChannel,
            );
            getNotificationData(data, id);
        }
    };

    const handleChangeChannel = (event) => {
        const { value } = event.target;
        const lastElementOfValues = value.length - 1;

        let checkSubject = subject;
        if (!value.includes(EMAIL) || value.length === 0) {
            setSubject('');
            checkSubject = '';
        }

        if (value[lastElementOfValues] === ALL) {
            const selectChannels = selectedChannel.length === channels.length ? [] : channels.map((item) => item.id);
            setSelectedChannel(selectChannels);
            const updateTargets = targets.map((item) => {
                if (selectChannels && selectChannels.length > 0) {
                    item.channels = selectChannels;
                } else {
                    delete item.channels;
                }

                return item;
            });

            const data = buildNotifyUsersBlock(
                body,
                updateTargets,
                checkSubject,
                body_html,
                buildNotifyCategoriesObjects(notificationCategoriesList, selectedCategory),
                notificationType,
                selectChannels,
            );
            getNotificationData(data, id, CLOUD_NOTIFICATION);
            setTargets(updateTargets);

            return;
        }

        const updateTargets = targets.map((item) => {
            if (value.length > 0) {
                item.channels = value;
            } else {
                delete item.channels;
            }

            return item;
        });

        setTargets(updateTargets);
        setSelectedChannel(value);

        const data = buildNotifyUsersBlock(
            body,
            updateTargets,
            checkSubject,
            body_html,
            buildNotifyCategoriesObjectsByName(notificationCategoriesList, selectedCategory),
            notificationType,
            value,
        );
        getNotificationData(data, id, CLOUD_NOTIFICATION);
    };

    const handleChangeValue = (e) => {
        const { value } = e.target;
        const { name } = e.target;

        if (name === SUBJECT) {
            setSubject(value);
            const data = buildNotifyUsersBlock(
                body,
                targets,
                value,
                body_html,
                buildNotifyCategoriesObjectsByName(notificationCategoriesList, selectedCategory),
                notificationType,
                selectedChannel,
            );
            getNotificationData(data, id, CLOUD_NOTIFICATION);
        }

        if (name === BODY) {
            setBody(value);
            const data = buildNotifyUsersBlock(
                value,
                targets,
                subject,
                body_html,
                buildNotifyCategoriesObjectsByName(notificationCategoriesList, selectedCategory),
                notificationType,
                selectedChannel,
            );
            getNotificationData(data, id, CLOUD_NOTIFICATION);
        }

        if (name === BODY_HTML) {
            setBodyHtml(value);
            const data = buildNotifyUsersBlock(
                body,
                targets,
                subject,
                value,
                buildNotifyCategoriesObjectsByName(notificationCategoriesList, selectedCategory),
                notificationType,
                selectedChannel,
            );
            getNotificationData(data, id);
        }
    };

    const handleShowCreatingCategoriesModal = () => {
        setShowCreatingCategoriesModal((prevState) => !prevState);
        setNewCategoryName('');
    };

    const onChangeCategoryName = (e) => {
        const { value } = e.target;
        setNewCategoryName(value);
    };

    const onSaveNewCategoryName = () => {
        if (!isDuplicate(notificationCategoriesList, newCategoryName)) {
            dispatch(
                actions.setNotificationCategory(
                    buildNotificationCategoryParams(notificationCategoriesList, newCategoryName, createUuid()),
                    EZLOGIC_TITLE_SUCCESSFULLY_CREATE,
                    t,
                ),
            );
            setNewCategoryName('');
        } else {
            bugsnagNotifyWrapper({ message: t(EZLOGIC_TITLE_ALREADY_HAVE_CATEGORY) });
        }
    };

    const onEditCategory = ({ uuid, value }) => {
        dispatch({
            type: at.GET_NOTIFICATION_CATEGORIES.success,
            data: setIsEditCategory({ notificationCategoriesList, uuid, value }),
        });
    };

    const onCancelEditingCategory = ({ uuid, value }) => {
        dispatch({
            type: at.GET_NOTIFICATION_CATEGORIES.success,
            data: setCancelEditingCategory({ notificationCategoriesList, uuid, value }),
        });
    };

    const onDeleteCategory = (uuid) => {
        dispatch(
            actions.setNotificationCategory(
                buildNotificationCategoryParamsForDeleting(
                    getCategoryArrayWithoutIsEditKey(
                        getArrayForDeleteCategory({
                            notificationCategoriesList,
                            uuid,
                        }),
                        uuid,
                    ),
                ),
                EZLOGIC_TITLE_SUCCESSFULLY_DELETED,
                t,
            ),
        );
    };

    const onEditingCategoryName = (e, uuid) => {
        const name = e.target.value;
        dispatch({
            type: at.GET_NOTIFICATION_CATEGORIES.success,
            data: getArrayForEditCategory({ notificationCategoriesList, uuid, name }),
        });
    };

    const onSaveEditingCategoryName = (uuid) => {
        onEditCategory({ uuid, value: false });
        dispatch(
            actions.setNotificationCategory(
                { list: getCategoryArrayWithoutIsEditKey(notificationCategoriesList, uuid) },
                EZLOGIC_TITLE_SUCCESSFULLY_EDITED,
                t,
            ),
        );
    };

    const isPartner = pkPermissionRole === PARTNER_PERMISSION;
    const isOemMaster = isCurrentPkAccountOemTemplateMaster(currentPkAccount);

    return (
        <>
            {notificationType !== NOTIFICATION_TEMPLATE && (
                <NotificationCloudUsers
                    selectedUsers={selectedUsers}
                    handleChangeUser={handleChangeUser}
                    userData={userData}
                />
            )}
            {(isPartner || isOemMaster) && (
                <NotificationCloudCategories
                    selectedCategory={selectedCategory}
                    handleChangeCategory={handleChangeCategory}
                    isAllCategoriesSelected={isAllCategoriesSelected}
                    notificationCategoriesList={notificationCategoriesList}
                    handleShowCreatingCategoriesModal={handleShowCreatingCategoriesModal}
                    isEmptyCategories={isCategoriesEmpty}
                />
            )}
            <NotificationCloudChannels
                selectedChannel={selectedChannel}
                handleChangeChannel={handleChangeChannel}
                isAllSelected={isAllSelected}
                channels={channels}
            />
            <div className={styles.area}>
                {selectedChannel && selectedChannel.includes('email') && (
                    <FormControl className={styles.subject}>
                        <span className={styles.label}>{t(EZLOGIC_TITLE_SUBJECT)}</span>
                        <InputMaterial
                            value={subject}
                            name="subject"
                            placeholder={t(EZLOGIC_TITLE_SUBJECT)}
                            onChange={handleChangeValue}
                            fullWidthInput
                            maxLength={MAX_LENGTH_SUBJECT}
                        />
                    </FormControl>
                )}
            </div>
            <div className={styles.area}>
                <FormControl className={styles.body_wrapper}>
                    <span className={styles.label}>{t(EZLOGIC_TITLE_MESSAGE_BODY)}</span>
                    <InputMaterial
                        value={body}
                        name="body"
                        placeholder={t(EZLOGIC_TITLE_MESSAGE_BODY)}
                        multiline
                        onChange={handleChangeValue}
                        fullWidthInput
                        maxLength={MAX_LENGTH_BODY}
                    />
                </FormControl>
            </div>
            {selectedChannel && selectedChannel.includes('email') && (
                <div className={styles.area}>
                    <FormControl className={styles.body_wrapper}>
                        <span className={styles.label}>{t(EZLOGIC_TITLE_HTML_MESSAGE_BODY)}</span>
                        <InputMaterial
                            value={body_html}
                            name={BODY_HTML}
                            placeholder={t(EZLOGIC_TITLE_MESSAGE_BODY_HTML)}
                            multiline
                            onChange={handleChangeValue}
                            fullWidthInput
                        />
                    </FormControl>
                </div>
            )}
            {notificationMeshbotType === MESHBOT_TEMPLATE && <EzloNotificationTemplateKeywords />}
            <div className={styles.character_information}>
                <p>
                    {t(EZLOGIC_TITLE_LIMIT_FOR_IOS)} <span>178</span> {t(EZLOGIC_TITLE_CHARACTERS)}
                </p>
                <p>
                    {t(EZLOGIC_TITLE_LIMIT_FOR_ANDROID)} <span>65</span>{' '}
                    <p
                        className={styles.limitWrapper}
                        dangerouslySetInnerHTML={{
                            __html: t(EZLOGIC_TITLE_TITLES_LIMITS),
                        }}
                    ></p>
                </p>
                <p>{t(EZLOGIC_TITLE_BE_AWARE)}</p>
            </div>
            {(isPartner || isOemMaster) && (
                <ModalNotificationsCategoryCreating
                    isModalVisible={isCreatingCategoriesModalShow}
                    onCancel={handleShowCreatingCategoriesModal}
                    onChange={onChangeCategoryName}
                    onSave={onSaveNewCategoryName}
                    notificationCategoriesList={notificationCategoriesList}
                    onEditCategory={onEditCategory}
                    onDeleteCategory={onDeleteCategory}
                    onEditingCategoryName={onEditingCategoryName}
                    onSaveEditing={onSaveEditingCategoryName}
                    inputCreatedValue={newCategoryName}
                    onCancelEditingCategory={onCancelEditingCategory}
                />
            )}
        </>
    );
};

export default NotificationCloudBlock;
