import React from 'react';
import styles from '../styles.module.scss';

const PluginInformationChangelogDescription = ({ item }) => {
    return (
        <ul className={styles.descriptionWrapper}>
            {item.description.map((descriptionItem) => (
                <li>{descriptionItem}</li>
            ))}
        </ul>
    );
};
export default PluginInformationChangelogDescription;
