import React from 'react';
import { useDispatch } from 'react-redux';
import { MESHBOT_LABELS } from '../../../../../services/kvs';

import { MeshBotAction } from '../../../../../actions';
import { DRAGGING_ELEMENT_DATA } from '../../../../../features/Labels/MeshBotsLabels/constants';
import { DATA_MESHBOT_ATTRIBUTE } from '../../../../../constants/MeshbotConstant';
import { findParentAttributeValueByName } from '../../../../../services/utilityService';

const MeshBotDragDropContainer = ({ children }) => {
    const dispatch = useDispatch();

    const onDrop = (e) => {
        e.preventDefault();
        const draggingElementData = JSON.parse(e.dataTransfer.getData(DRAGGING_ELEMENT_DATA));
        const meshbotData = findParentAttributeValueByName(e.target, DATA_MESHBOT_ATTRIBUTE);

        if (draggingElementData.type === MESHBOT_LABELS && draggingElementData.id && meshbotData) {
            dispatch(MeshBotAction.updateLabelsInMeshBot(meshbotData, draggingElementData.id));
        }
    };

    const allowDrop = (e) => e.preventDefault();

    return (
        <div onDragOver={allowDrop} onDrop={onDrop}>
            {children}
        </div>
    );
};

export default MeshBotDragDropContainer;
