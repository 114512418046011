import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { BLOCK_FIELD_TYPES, MAP_TO_JS_VALUE_TYPES } from '../../constants/MeshbotConstant';
import { getKeyFromObjectWithArrays } from '../../helpers/common';
import { getExpressionsSelector } from '../../containers/Ezlo/EzloExpressions/utils';

const useExpressionList = (expressionType, valueType) => {
    const serial = useSelector((state) => state.ezlo?.serial);
    const allExpressions = useSelector(getExpressionsSelector);
    const valueTypeFamilies = useSelector((state) => state.ezlo?.data[serial].blockDataList?.valueTypeFamilies);
    const filteredExpressionsList = useMemo(
        () => allExpressions.filter((expression) => !expression?.variable),
        [JSON.stringify(allExpressions)],
    );
    const filteredVariablesList = useMemo(
        () => allExpressions.filter((expression) => expression?.variable),
        [JSON.stringify(allExpressions)],
    );

    const mapExpressionType = {
        [BLOCK_FIELD_TYPES.VARIABLE]: filteredVariablesList,
        [BLOCK_FIELD_TYPES.EXPRESSION]: filteredExpressionsList,
    };

    if (valueType) {
        if (expressionType === BLOCK_FIELD_TYPES.EXPRESSION) {
            const valueTypeFamily = getKeyFromObjectWithArrays(valueTypeFamilies, valueType);
            const valueTypeJS = MAP_TO_JS_VALUE_TYPES[valueTypeFamily];

            return filteredExpressionsList.filter(({ value }) => typeof value === valueTypeJS);
        }
        const valueTypeFamily = getKeyFromObjectWithArrays(valueTypeFamilies, valueType);

        return filteredVariablesList.filter(
            (elem) =>
                valueTypeFamilies[valueTypeFamily]?.includes(elem?.valueType) === true ||
                valueTypeFamilies[valueTypeFamily]?.includes(elem?.metadata?.scalableType) === true,
        );
    }

    return mapExpressionType[expressionType];
};

useExpressionList.propTypes = {
    expressionType: PropTypes.string.isRequired,
    valueType: PropTypes.string,
};

export default useExpressionList;
