import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, FormControl, InputLabel, Select, Chip, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _uniqueId from 'lodash/uniqueId';

import { TextField } from '../../../../../components';

import { optionsSelect } from '../../../../../constants/MeshbotConstant';

import { useTranslate } from '../../../../../features/languages';

import styles from '../ExpressionAddForm.module.scss';
import { EXPRESSION_NAME_MAX_LENGTH } from '../../../../../constants/Variables';
import {
    EZLOGIC_ADD_REFERENCE_LABEL,
    EZLOGIC_DEVICE_ADVANCED_SECTION_ASSOCIATION_SELECT_DEVICE,
    EZLOGIC_EXPRESSIONS_LUA_CODE_PLACEHOLDER,
    EZLOGIC_VARIABLES_INPUT_UNIQUE_NAME_PLACEHOLDER,
    EZLOGIC_SELECT_CAPABILITY_LABEL,
    EZLOGIC_TITLE_UNASSIGNED,
    EZLOGIC_VARIABLES_EXPRESSIONS_HELPER_TEXT,
} from '../../../../../constants/language_tokens';

const ExpressionAddForm = (props) => {
    const {
        name,
        code,
        serial,
        initName,
        onChangeName,
        onChangeCode,
        paramsItems,
        setCode,
        setParamsItems,
        selectedDevice,
        setSelectedDevice,
        selectedCapabilityId,
        setSelectedCapabilityId,
        filteredCapabilitiesList,
        isInputNameNotValid,
    } = props;

    const [itemLabelId] = useState(_uniqueId('item-label-'));
    const devicesList = useSelector((state) => state.ezlo.data[serial].devices);
    const capabilitiesList = useSelector((state) => state.ezlo.data[serial].items);

    const { t } = useTranslate();

    const handleDeviceSelect = (event, value) => {
        setSelectedDevice(value);
        setSelectedCapabilityId('');
    };

    const handleCapabilitySelect = (event) => {
        setSelectedCapabilityId(event.target.value);
    };

    const handleGroupByAutocomplete = (option) => {
        return option.roomName ? option.roomName : option?.meta?.parent_room_name || t(EZLOGIC_TITLE_UNASSIGNED);
    };

    const handleAddDeviceReference = () => {
        if (selectedDevice._id && selectedCapabilityId) {
            const paramsReferenceIndex = paramsItems.findIndex(({ _id }) => _id === selectedCapabilityId);

            if (paramsReferenceIndex !== -1) {
                setCode((prevValue) => `${prevValue} params.${paramsItems[paramsReferenceIndex].name}`);
            } else {
                const nameDevice = devicesList.find((item) => item._id === selectedDevice._id).name.toLowerCase();
                const nameCapability = capabilitiesList
                    .find((item) => item._id === selectedCapabilityId)
                    .name.toLowerCase();
                const nameReference = `${nameDevice}_${nameCapability}`
                    .split(' ')
                    .join('_')
                    .replace(/[\[\]{}/&#@!%^*,.+-<>]/gim, '_');

                setParamsItems((prevState) => {
                    return [
                        ...prevState,
                        {
                            name: nameReference,
                            _id: selectedCapabilityId,
                        },
                    ];
                });

                setCode((prevValue) => `${prevValue} params.${nameReference}`);
            }
        }
    };

    const handleChipClick = (name) => {
        setCode((prevValue) => `${prevValue} params.${name}`);
    };

    const handleChipDelete = (name) => {
        setParamsItems((prevValue) => prevValue.filter((item) => item.name !== name));
        setCode((prevValue) => prevValue.split(`params.${name}`).join(''));
    };

    return (
        <>
            <TextField
                type="text"
                value={name}
                variant="outlined"
                inputProps={{ maxLength: EXPRESSION_NAME_MAX_LENGTH }}
                className={styles.title}
                placeholder={t(EZLOGIC_VARIABLES_INPUT_UNIQUE_NAME_PLACEHOLDER)}
                onChange={onChangeName}
                disabled={initName}
                error={isInputNameNotValid}
                helperText={isInputNameNotValid ? t(EZLOGIC_VARIABLES_EXPRESSIONS_HELPER_TEXT) : null}
            />
            <div className={styles.equalSymbol}>=</div>
            <div className={styles.codeBlock}>
                <textarea
                    value={code}
                    className={styles.body}
                    placeholder={t(EZLOGIC_EXPRESSIONS_LUA_CODE_PLACEHOLDER)}
                    onChange={onChangeCode}
                    rows={2}
                />
                {!!paramsItems.length && (
                    <div className={styles.chipsWrapper}>
                        {paramsItems.map((item) => (
                            <Chip
                                key={item.name}
                                label={item.name}
                                className={styles.chip}
                                onClick={() => {
                                    handleChipClick(item.name);
                                }}
                                onDelete={() => {
                                    handleChipDelete(item.name);
                                }}
                            />
                        ))}
                    </div>
                )}
                <div className={styles.deviceReferenceWrapper}>
                    <FormControl className={styles.selectControl}>
                        <Autocomplete
                            className={styles.select}
                            options={devicesList}
                            autoComplete={true}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={selectedDevice}
                            groupBy={handleGroupByAutocomplete}
                            disableClearable={true}
                            getOptionSelected={(option, value) => option._id === value._id}
                            onChange={handleDeviceSelect}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        label={t(EZLOGIC_DEVICE_ADVANCED_SECTION_ASSOCIATION_SELECT_DEVICE)}
                                        {...params}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl className={styles.selectControl}>
                        <InputLabel id={itemLabelId}>{t(EZLOGIC_SELECT_CAPABILITY_LABEL)}</InputLabel>
                        <Select
                            className={styles.select}
                            MenuProps={optionsSelect}
                            label={t(EZLOGIC_SELECT_CAPABILITY_LABEL)}
                            labelId={itemLabelId}
                            value={selectedCapabilityId}
                            onChange={handleCapabilitySelect}
                            disabled={!selectedDevice._id}
                        >
                            {filteredCapabilitiesList.map((capability) => (
                                <MenuItem key={capability._id} value={capability._id}>
                                    {capability.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.addReference}
                        type="button"
                        onClick={handleAddDeviceReference}
                        disabled={!(selectedDevice._id && selectedCapabilityId)}
                    >
                        {t(EZLOGIC_ADD_REFERENCE_LABEL)}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ExpressionAddForm;
