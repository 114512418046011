/**
 * Returns Dashboard 'params' object for Bounce Set request's payload.
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Bounce+-+Set here}
 * @param {(""|"edit")} path - Dashboard page path
 * @param {string|number} serial - controller's serial number
 * @param {string} identity - user's Identity value
 * @param {string} identitySignature - user's Identity_Signature value
 * @param {string} serverAccount - user's Server_Account value
 * @param {string} serverAccountAlt - user's Server_Account_Alt value
 * @param {number|null} [pkUser] - user's PK_User value, mainly used for child token management
 * @param {string} [SHA1Password] - user's SHA1Password value
 * @returns {object} prepared 'params' object
 * */
export const buildDashboardBounceSetParams = (
    path = '',
    serial,
    identity,
    identitySignature,
    serverAccount,
    serverAccountAlt,
    pkUser,
    SHA1Password,
    language,
) => {
    return {
        raw: JSON.stringify({
            path: path,
            controllerId: serial,
            mmsAuth: identity,
            mmsAuthSig: identitySignature,
            Server_Account: serverAccount,
            Server_Account_Alt: serverAccountAlt,
            PK_User: pkUser,
            password: SHA1Password,
            language: language,
        }),
    };
};

/**
 * Returns request body for Bounce Set API call.
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Bounce+-+Set here}
 * @param {object} params - params object
 * @returns {object} request body
 * */
export const buildBounceSetRequestBody = (params) => {
    return {
        call: 'bounce_set',
        params,
    };
};
