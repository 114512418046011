import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';

import TemplateCapability from '../TemplateCapability';

import hash from '../../../../../constants/uniqueHash';
import {
    DEVICE_TYPES,
    DEVICE_CATEGORY,
    WIFI_PLUGIN_MODEL,
    DEVICE_TYPE,
    MODEL,
    SUBCATEGORY,
    AUTHOR,
    DESCRIPTION,
    SUPPORTED_MODELS,
    OUTLINED,
    TEMPLATE_FORM_FIELD_SIZE,
    CATEGORY,
} from '../../../../../constants/Plugins';
import * as generator from '../../../../../constants/DevicePluginGenerator';
import {
    createCapabilityForSelect,
    transformCapabilitiesTo,
    getSubCategory,
    updatedCapabilityList,
    setCapabilityCommand,
    capabilityTransformForConfig,
    getDeviceTypeValue,
} from '../../utils';
import useUnsavedChangesWarning from '../../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';
import {
    WIFI_DEVICE_TEMPLATE_CREATING_PAGE,
    WIFI_DEVICE_TEMPLATE_EDITING_PAGE,
} from '../../../../../constants/ActionConfirmDialog';

import {
    EZLOGIC_BUTTON_ADD_CAPABILITY,
    EZLOGIC_BUTTON_SAVE_MODEL_TEMPLATE,
    EZLOGIC_HEADING_CAPATBILITY_WANT_ADD_DESCRIPTION,
    EZLOGIC_TITLE_ADD_CATEGORY,
    EZLOGIC_TITLE_ADD_SUB_CATEGORY,
    EZLOGIC_TITLE_ADD_TYPE,
    EZLOGIC_TITLE_AUTHOR,
    EZLOGIC_TITLE_CATEGORY,
    EZLOGIC_TITLE_DESCRIPTION,
    EZLOGIC_TITLE_DEVICE_TYPE,
    EZLOGIC_TITLE_PUT_AUTHOR_NAME,
    EZLOGIC_TITLE_PUT_DESCRIPTION,
    EZLOGIC_TITLE_PUT_SUPPORTED_MODELS,
    EZLOGIC_TITLE_PUT_TEMPLATE_NAME,
    EZLOGIC_TITLE_SUB_CATEGORY,
    EZLOGIC_TITLE_SUPPORTED_MODELS,
    EZLOGIC_TITLE_TEMPLATE_NAME,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import UploadImageField from '../UploadImageField';
import useLogoFieldWithImgDropzone from '../../customHooks/useLogoFieldWithImgDropzone';
import AutocompleteSelect from '../AutocompleteSelect';
import styles from './generatorForm.module.scss';

const GeneratorForm = (props) => {
    const logoFieldsState = useLogoFieldWithImgDropzone();
    const { logoFieldValue, setLogoFieldValue } = logoFieldsState;
    const [capabilityList, setCapabilityList] = useState([]);
    const [commandList, setCommandList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [deviceType, setDeviceType] = useState('');
    const [model, setModel] = useState('');
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [author, setAuthor] = useState('');
    const [description, setDescription] = useState('');
    const [supported_models, setSupportedModels] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const setDirty = useUnsavedChangesWarning();
    const { isDirty, isLoading } = useSelector(({ main, app }) => ({
        isDirty: main.editableComponent?.isDirty,
        isLoading: app.lineLoading,
    }));
    const { t } = useTranslate();

    const { type, capabilities, template, PluginActions, MainAction } = props;

    useEffect(() => {
        if (template) {
            const { data, name } = template;
            setModel(name);
            setDeviceType(data?.config?.device_type || '');
            setCategory(data?.config?.category || '');
            setSubcategory(data?.config?.subcategory || '');
            setAuthor(data?.meta?.author || '');
            setDescription(data?.meta?.description || '');
            setSupportedModels(data?.meta?.supported_models || '');
            setLogoFieldValue(data?.meta?.logo || '');
            setInitialValues({
                model: name,
                deviceType: data?.config?.device_type || '',
                category: data?.config?.category || '',
                subcategory: data?.config?.subcategory || '',
                author: data?.meta?.author || '',
                description: data?.meta?.description || '',
                supported_models: data?.meta?.supported_models || '',
                logo: data?.meta?.logo || '',
            });

            if (data?.config?.capabilities) {
                const initialCapabilities = transformCapabilitiesTo(data.config.capabilities);
                setInitialValues((previousState) => ({
                    ...previousState,
                    capabilityList: JSON.parse(JSON.stringify(initialCapabilities)),
                }));

                setCapabilityList(initialCapabilities);
            }

            if (data?.config?.category) {
                setSubcategoryList(getSubCategory(DEVICE_CATEGORY, data.config.category));
            }
        }
    }, [template]);

    useEffect(() => {
        const isEmptyForm = !model && !deviceType && !category && !subcategory && !capabilityList[0]?.command;
        const isNotModifiedForm =
            model === initialValues?.model &&
            deviceType === initialValues?.deviceType &&
            category === initialValues?.category &&
            subcategory === initialValues?.subcategory &&
            author === initialValues?.author &&
            description === initialValues?.description &&
            supported_models === initialValues?.supported_models &&
            logoFieldValue === initialValues?.logo &&
            JSON.stringify(capabilityList) === JSON.stringify(initialValues?.capabilityList || []);

        if (isEmptyForm || isNotModifiedForm) {
            setDirty(false);
            MainAction.setConfirmationUser(false);
        } else {
            if (!isDirty) {
                setDirty(true);
                MainAction.setConfirmationUser(true);
            }
        }
    }, [
        model,
        deviceType,
        category,
        subcategory,
        capabilityList,
        logoFieldValue,
        author,
        description,
        supported_models,
    ]);

    useEffect(() => {
        const dialogData =
            type === generator.DEVICES_PLUGIN_EDIT
                ? WIFI_DEVICE_TEMPLATE_EDITING_PAGE
                : WIFI_DEVICE_TEMPLATE_CREATING_PAGE;

        MainAction.setStatePageBeingEdited(dialogData);

        return () => MainAction.setStatePageBeingEdited({});
    }, []);

    useEffect(() => {
        setCommandList(createCapabilityForSelect(capabilities));
    }, [capabilities]);

    const handlerAddCapability = () => {
        setCapabilityList([
            ...capabilityList,
            {
                id: hash(),
                command: '',
                actions: [],
            },
        ]);
    };

    const handlerRemoveCapability = useCallback(
        (id) => {
            setCapabilityList(capabilityList.filter((item) => item.id !== id));
        },
        [capabilityList],
    );

    const handleSetValueItem = useCallback(
        (e, id, action, type) => {
            const { name } = e.target;
            let { value } = e.target;

            if (type === generator.INTEGER) {
                value = Number(value);
            }

            setCapabilityList(updatedCapabilityList(capabilityList, name, value, id, action));
        },
        [capabilityList],
    );

    const handleSetHeaders = useCallback(
        (value, name, id, action) => {
            setCapabilityList(updatedCapabilityList(capabilityList, name, value, id, action));
        },
        [capabilityList],
    );

    const handleSetCommand = useCallback(
        (e, id) => {
            const { value } = e.target;

            setCapabilityList(setCapabilityCommand(capabilityList, id, commandList, value));
        },
        [capabilityList],
    );

    const handleChangeSelect = (e, selectName) => {
        const value = e?.target?.value || e?.value;
        const name = e?.target?.name || selectName;

        switch (name) {
            case DEVICE_TYPE:
                setDeviceType(value);
                break;
            case MODEL:
                setModel(value);
                break;
            case SUBCATEGORY:
                setSubcategory(value);
                break;
            case AUTHOR:
                setAuthor(value);
                break;
            case DESCRIPTION:
                setDescription(value);
                break;
            case SUPPORTED_MODELS:
                setSupportedModels(value);
                break;
            default:
                break;
        }
    };

    const handleChangeSelectCategory = (e) => {
        const value = e.target?.value || e.value;
        DEVICE_CATEGORY.forEach((category) => {
            if (category.value === value && category.subcategory.length > 0) {
                setSubcategoryList(category.subcategory);
            } else if (category.value === value && category.subcategory.length === 0) {
                setSubcategory('');
                setSubcategoryList([]);
            }
        });

        setCategory(value);
    };

    const handleSave = () => {
        const paramsCreate = { name: model, type: WIFI_PLUGIN_MODEL };

        const paramsSet = {
            data: {
                config: {
                    model: model,
                    device_type: deviceType,
                    category: category,
                    subcategory: subcategory,
                    capabilities: capabilityTransformForConfig(capabilityList),
                },
                meta: { author, description, supported_models },
            },
        };

        if (type === generator.DEVICES_PLUGIN_EDIT) {
            paramsSet.name = model;
            paramsSet.uuid = template.uuid;

            PluginActions.setDashboardWifi(paramsSet, logoFieldsState, t);
        } else {
            PluginActions.createDashboardWifi(paramsCreate, paramsSet, logoFieldsState, t);
        }
    };

    return (
        <div className={styles.generatorForm}>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_AUTHOR)}</span>
                <TextField
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    label={t(EZLOGIC_TITLE_AUTHOR)}
                    name={AUTHOR}
                    placeholder={t(EZLOGIC_TITLE_PUT_AUTHOR_NAME)}
                    value={author ?? author}
                    variant={OUTLINED}
                    className={styles.generatorForm__input}
                    onChange={handleChangeSelect}
                />
            </div>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_DESCRIPTION)}</span>
                <TextField
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    label={t(EZLOGIC_TITLE_DESCRIPTION)}
                    name={DESCRIPTION}
                    placeholder={t(EZLOGIC_TITLE_PUT_DESCRIPTION)}
                    value={description ?? description}
                    variant={OUTLINED}
                    className={styles.generatorForm__input}
                    onChange={handleChangeSelect}
                />
            </div>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_SUPPORTED_MODELS)}</span>
                <TextField
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    label={t(EZLOGIC_TITLE_SUPPORTED_MODELS)}
                    name={SUPPORTED_MODELS}
                    placeholder={t(EZLOGIC_TITLE_PUT_SUPPORTED_MODELS)}
                    value={supported_models ?? supported_models}
                    variant={OUTLINED}
                    className={styles.generatorForm__input}
                    onChange={handleChangeSelect}
                />
            </div>

            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_TEMPLATE_NAME)}</span>
                <TextField
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    label={t(EZLOGIC_TITLE_TEMPLATE_NAME)}
                    name={MODEL}
                    placeholder={t(EZLOGIC_TITLE_PUT_TEMPLATE_NAME)}
                    value={model ?? model}
                    variant={OUTLINED}
                    className={styles.generatorForm__input}
                    onChange={handleChangeSelect}
                />
            </div>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_DEVICE_TYPE)}</span>
                <AutocompleteSelect
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    onChange={handleChangeSelect}
                    value={getDeviceTypeValue(deviceType, DEVICE_TYPES) ?? null}
                    name={DEVICE_TYPE}
                    options={DEVICE_TYPES}
                    label={t(EZLOGIC_TITLE_ADD_TYPE)}
                />
            </div>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_CATEGORY)}</span>
                <AutocompleteSelect
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    onChange={handleChangeSelectCategory}
                    value={getDeviceTypeValue(category, DEVICE_CATEGORY) ?? null}
                    name={CATEGORY}
                    options={DEVICE_CATEGORY}
                    label={t(EZLOGIC_TITLE_ADD_CATEGORY)}
                />
            </div>
            <div className={styles.generatorForm__block}>
                <span className={styles.generatorForm__label}>{t(EZLOGIC_TITLE_SUB_CATEGORY)}</span>
                <AutocompleteSelect
                    size={TEMPLATE_FORM_FIELD_SIZE}
                    onChange={handleChangeSelect}
                    value={getDeviceTypeValue(subcategory, subcategoryList) ?? null}
                    name={SUBCATEGORY}
                    options={subcategoryList}
                    disabled={!subcategoryList.length}
                    label={t(EZLOGIC_TITLE_ADD_SUB_CATEGORY)}
                />
            </div>

            <UploadImageField {...logoFieldsState} />

            <div className={styles.generatorForm__body}>
                <h3>{t(EZLOGIC_HEADING_CAPATBILITY_WANT_ADD_DESCRIPTION)}</h3>
                {capabilityList.map((capability) => {
                    return (
                        <TemplateCapability
                            key={capability.id}
                            capability={capability}
                            commandList={commandList}
                            onRemoveCapability={handlerRemoveCapability}
                            onSetValueItem={handleSetValueItem}
                            onSetHeaders={handleSetHeaders}
                            onSetCommand={handleSetCommand}
                        />
                    );
                })}
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.generatorForm__buttonAdd}
                    onClick={handlerAddCapability}
                >
                    {t(EZLOGIC_BUTTON_ADD_CAPABILITY)}
                </Button>
            </div>
            <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                className={styles.generatorForm__buttonCreate}
                onClick={handleSave}
            >
                {t(EZLOGIC_BUTTON_SAVE_MODEL_TEMPLATE)}
            </Button>
        </div>
    );
};

GeneratorForm.propTypes = {
    type: PropTypes.string,
    template: PropTypes.object,
    capabilities: PropTypes.object,
    PluginActions: PropTypes.object,
};

export default React.memo(GeneratorForm);
