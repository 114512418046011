import React from 'react';
import SystemHistoryEventTypeCell from '../containers/Ezlo/EzloSystemHistory/SystemHistoryLayout/SystemHistoryTableConrainer/SystemHistoryTableUI/SysytemHistoryEventTypeCell';
import {
    EZLOGIC_TITLE_ALL_EVENTS,
    EZLOGIC_TITLE_IMAGE,
    EZLOGIC_TITLE_VIDEO,
    EZLOGIC_TITLE_TRIGGER,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_EVENT_TYPE,
    EZLOGIC_TITLE_DATE_AND_TIME,
    EZLOGIC_TITLE_DESCRIPTION,
} from './language_tokens';

export const IMAGE = 'Image';
export const ALL_EVENTS_TYPE = 0;
export const VIDEO = 'Video';
export const TRIGGER = 'Trigger';
export const DEVICE = 'Device';
export const LOGIN = 'Login';
export const SIREN = 'siren';
export const SECURITY_SENSOR = 'security_sensor';
export const GENERIC_SENSOR = 'generic_sensor';
export const LEVEL_SENSOR = 'level_sensor';
export const LIGHT_SENSOR = 'light_sensor';
export const DIMMABLE_LIGHT = 'dimmable_light';
export const HVAC = 'hvac';
export const INTERFACE = 'interface';
export const SWITCH = 'switch';
export const TEMPERATURE = 'temperature';
export const DOOR_LOCK = 'door_lock';
export const WINDOW_COV = 'window_cov';
export const DOORBELL = 'doorbell';
export const FLOW_METER = 'flow_meter';
export const GARAGE_DOOR = 'garage_door';
export const POWER_METER = 'power_meter';
export const SCENE_CONTROLLER = 'scene_controller';
export const CAMERA_SUITE = 'camera_suite';
export const HUMIDITY = 'humidity';
export const VOLTAGE_SENSOR = 'voltage_sensor';
export const UV_SENSOR = 'uv_sensor';
export const STATE_SENSOR = 'state_sensor';
export const EVENT_TYPES = [
    { value: 0, label: EZLOGIC_TITLE_ALL_EVENTS },
    { value: 1, label: EZLOGIC_TITLE_IMAGE },
    { value: 2, label: EZLOGIC_TITLE_VIDEO },
    { value: 3, label: EZLOGIC_TITLE_TRIGGER },
    { value: 4, label: EZLOGIC_TITLE_DEVICE },
];
export const COUNT_NUMBER = '100';
export const START_NUMBER = '0';
export const COUNT = 'Count';
export const START = 'Start';
export const AFTER = 'After';
export const BEFORE = 'Before';
export const GATEWAY = 'Gateway';
export const HIDE = 'hide';

export const buildSystemHistoryTableColumns = () => {
    return [
        {
            field: 'eventType',
            headerAlign: 'left',
            headerName: EZLOGIC_TITLE_EVENT_TYPE,
            sortable: false,
            disableColumnMenu: true,
            bodyAlign: 'left',
            flex: 0.5,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return <SystemHistoryEventTypeCell params={params} />;
            },
        },
        {
            field: 'description',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_DESCRIPTION,
            headerClassName: 'grid-field',
            flex: 1,
            align: 'left',
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'dateAndTime',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_DATE_AND_TIME,
            headerClassName: 'grid-field',
            flex: 1,
            align: 'center',
            disableSelectionOnClick: true,
            disableColumnMenu: true,
            sortable: false,
        },
    ];
};
