import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

import { createTemplateForJsonPath, parseValueJsonFunction } from '../../../utils';
import { EZLOGIC_TITLE_CURRENT_WEATHER_TEMPERATURE } from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';

export const GetStringFromJsonPath = ({ onChangeValue, defaultValue }) => {
    const [value, setValue] = useState('');
    const { t } = useTranslate();
    useEffect(() => {
        if (parseValueJsonFunction(defaultValue)) {
            const value = parseValueJsonFunction(defaultValue);
            setValue(value);
        }
    }, []);

    const handleUpdateValue = (e) => {
        const { value } = e.target;
        const updatedValue = createTemplateForJsonPath(value);
        setValue(value);
        onChangeValue(updatedValue);
    };

    return (
        <div>
            <TextField
                name="getStringFromJsonPathValue"
                label={t(EZLOGIC_TITLE_CURRENT_WEATHER_TEMPERATURE)}
                value={value}
                variant="outlined"
                onChange={handleUpdateValue}
            />
        </div>
    );
};
