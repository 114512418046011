import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import ResetPasswordTitle from '../ResetPasswordTitle';
import ActionsWrapper from '../../../components/ActionsWrapper';
import { FORGOT_PASSWORD } from '../../../../../constants/URLs';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_INVALID_EXPIRED_PASSWORD_RESET_LINK,
    EZLOGIC_TITLE_REQUEST_A_NEW_PASSWORD_RESET_LINK,
    EZLOGIC_TITLE_YOUR_PASSWORD_RESET_LINK_SEEMS_TO_BE_INVALID,
} from '../../../../../constants/language_tokens';
import { IcWarningVisualsNotResponding } from '../../../../../assets/icons';

import styles from './styles.module.scss';

const InvalidResetPasswordLink = () => {
    const { t } = useTranslate();
    const history = useHistory();

    function handlerClick() {
        history.replace(FORGOT_PASSWORD);
    }

    return (
        <div className={styles.invalidPassResetLink}>
            <IcWarningVisualsNotResponding className={styles.img} />
            <ResetPasswordTitle
                title={t(EZLOGIC_TITLE_INVALID_EXPIRED_PASSWORD_RESET_LINK)}
                subTitle={t(EZLOGIC_TITLE_YOUR_PASSWORD_RESET_LINK_SEEMS_TO_BE_INVALID)}
            />
            <ActionsWrapper>
                <Button fullWidth variant="contained" onClick={handlerClick}>
                    {t(EZLOGIC_TITLE_REQUEST_A_NEW_PASSWORD_RESET_LINK)}
                </Button>
            </ActionsWrapper>
        </div>
    );
};

export default InvalidResetPasswordLink;
