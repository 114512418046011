import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import DevicesCardsList from './components/DevicesCardsList';
import ControllerDevicesGroupHeader from './components/ControllerDevicesGroupHeader';
import DevicesFilteringBlock from './components/DevicesFilteringBlock';
import { VALUE_TO_UNASSIGN_ROOM } from '../../../../../../../constants/Devices';
import { filterDevicesByRoom } from '../../../../utils';

import styles from './ControllerDevicesGroup.module.scss';
import { DropDownUpIcon } from '../../../../../../../assets/icons';

const ControllerDevicesGroup = (props) => {
    const [selectedRoomId, setSelectedRoomId] = useState(VALUE_TO_UNASSIGN_ROOM);

    const {
        serial,
        roomList,
        onNameChange,
        onHandlerSelectFunction,
        onHandlerRefreshDevice,
        onSetCurrentFunction,
        onChangeDevicePlace,
        devices,
    } = props;

    const [filteredDevices, setFilteredDevices] = useState([]);
    const isOnline = useSelector((state) => state.ezlo.data[serial].isConnected);

    useEffect(() => {
        const result = filterDevicesByRoom(selectedRoomId, devices);
        setFilteredDevices(result);
    }, [selectedRoomId]);

    useEffect(() => {
        const result = filterDevicesByRoom(selectedRoomId, devices);
        setFilteredDevices(result);
    }, [devices]);

    const handleGettingDevicesOfRoom = (roomId) => {
        setSelectedRoomId(roomId);
    };

    return (
        <div className={styles.controllerDevices}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<DropDownUpIcon className={styles.icon} data-testid="accordionSummary" />}
                >
                    <ControllerDevicesGroupHeader serial={serial} isOnline={isOnline} />
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                    <DevicesFilteringBlock
                        serial={serial}
                        roomList={roomList}
                        roomId={selectedRoomId}
                        onGettingDevicesOfRoom={handleGettingDevicesOfRoom}
                        isOnline={isOnline}
                    />
                    <DevicesCardsList
                        onNameChange={onNameChange}
                        onHandlerSelectFunction={onHandlerSelectFunction}
                        onHandlerRefreshDevice={onHandlerRefreshDevice}
                        onSetCurrentFunction={onSetCurrentFunction}
                        onChangeDevicePlace={onChangeDevicePlace}
                        devices={filteredDevices}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default memo(ControllerDevicesGroup);

ControllerDevicesGroup.propTypes = {
    serial: PropTypes.string,
    isOnline: PropTypes.bool,
    roomList: PropTypes.array,
    onNameChange: PropTypes.func,
    onHandlerSelectFunction: PropTypes.func,
    onHandlerRefreshDevice: PropTypes.func,
    onSetCurrentFunction: PropTypes.func,
    onChangeDevicePlace: PropTypes.func,
    devices: PropTypes.array,
};
