import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import styles from './NotFoundPage.module.scss';
import { useTranslate } from '../../features/languages';
import {
    EZLOGIC_BUTTON_BACK_TO_HOME,
    EZLOGIC_HEADING_PAGE_NOT_FOUND,
    EZLOGIC_TITLE_PAGE_NOT_FOUND_DESCRIPTION,
} from '../../constants/language_tokens';
import { PageNotFound } from '../../assets/icons';

const NotFoundPageContent = ({ loggedIn, onBackToHome }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.content}>
            <PageNotFound />
            <h1>{t(EZLOGIC_HEADING_PAGE_NOT_FOUND)}</h1>
            <p>{t(EZLOGIC_TITLE_PAGE_NOT_FOUND_DESCRIPTION)}</p>
            {!loggedIn && (
                <Button size="large" variant="contained" color="primary" onClick={onBackToHome}>
                    {t(EZLOGIC_BUTTON_BACK_TO_HOME)}
                </Button>
            )}
        </div>
    );
};

export default NotFoundPageContent;

NotFoundPageContent.propTypes = {
    loggedIn: PropTypes.bool,
    onBackToHome: PropTypes.func,
};

NotFoundPageContent.defaultProps = {
    loggedIn: false,
};
