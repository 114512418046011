import { CUSTOM_PREFIX, DETAILS_SOURCE_INTEGRATION } from '../PaasNodeForTrigger/constants';
import {
    COLON,
    CUSTOM_ENROLL_INFORMATION,
    CUSTOM_SUBSCRIPTION,
    SUBSCRIPTION,
} from '../../../../../constants/MeshbotConstant';
import hash from '../../../../../constants/uniqueHash';
import {
    CLOUD_VARIABLE_FILTERS,
    LOCAL_FORM,
    VARIABLE_ABSTRACT_INDEX,
    VARIABLE_NAME_INDEX,
    VARIABLE_VALUE_INDEX,
} from '../../constants';
import { determineVariableValueDataType } from '../CloudVariableForTrigger/utils';
import { generateLabelAccordingToDataType } from '../../../../../helpers/language';
import { ZERO } from '../../../EzloCustomization/constants';

/**
 * function finds integration data by id
 * @param {String} integrationId - integration id
 * @param {Array<Object>} integrations - List of all integrations
 * @returns {Object} - found integration
 */
const getIntegrationData = (integrationId, integrations) => {
    return integrations?.find(({ uuid }) => uuid === integrationId);
};

/**
 * function provides the src of the icon if it has one
 * @param {Object} selectedAbstract - selected abstract
 * @param {Array<Object>} integrations - List of all integrations
 * @param {Array<Object>} abstracts - List of all abstracts
 * @returns {String|null} - src of the icon if it exists
 */
export const getIntegrationIcon = (selectedAbstract, integrations, abstracts) => {
    if (!selectedAbstract || !integrations || !abstracts) {
        return null;
    }

    let integrationId = selectedAbstract?.details?.integration_uuid;
    if (!integrationId) {
        const selectedAbstractData = abstracts.find(({ uuid }) => uuid === selectedAbstract.uuid);
        integrationId = selectedAbstractData?.details?.integration_uuid;
    }

    return getIntegrationData(integrationId, integrations)?.icon || null;
};

/**
 * function that filters all abstracts by details.source = integration.
 * @param {Array<Object>} abstracts - List of all abstracts
 * @returns {Array<Object>} - list of abstracts that belong to integrations
 */
export const getIntegrationAbstracts = (abstracts = []) => {
    return abstracts?.filter(({ details }) => details?.source === DETAILS_SOURCE_INTEGRATION) || [];
};

/**
 * function that determines if the capability is a variable.
 * @param {String} capability - Capability
 * @returns {Boolean} - if capability is a variable then return true
 */
export const isVariable = (capability) => {
    return Boolean(
        capability.startsWith(CUSTOM_PREFIX) &&
            capability !== CUSTOM_ENROLL_INFORMATION &&
            capability !== CUSTOM_SUBSCRIPTION &&
            !capability.includes(`${SUBSCRIPTION}_`),
    );
};

/**
 * function collects abstracts that will have only variable names in their capabilities
 * @param {Array<Object>} abstracts - Abstracts' list
 * @returns {Array<Object>} - List of abstracts with capabilities that store only variable names
 */
export const getAbstractsWithVariables = (abstracts) => {
    const abstractsWithVariables = [];

    abstracts.forEach((currentAbstract) => {
        const { capabilities } = currentAbstract;

        if (capabilities.some(isVariable)) {
            const abstractWithOnlyVariables = { ...currentAbstract, capabilities: capabilities.filter(isVariable) };
            abstractsWithVariables.push(abstractWithOnlyVariables);
        }
    });

    return abstractsWithVariables;
};

/**
 * function that generates a variable object with information about abstract and integration
 * @param {string} capability - Cloud variable(capability) name
 * @param {Object} abstract - Abstract to which the variable belongs
 * @param {Object} integrationData - Integration data to which the variable belongs
 * @returns {Object} - Cloud variable object with info about abstract and integration
 */
const generateCloudVariableData = (capability, abstract, integrationData) => {
    return {
        uuid: hash(),
        UIVariableName: capability?.substring(7),
        cloudVariableName: capability,
        icon: integrationData?.icon,
        integrationUuid: abstract?.details?.integration_uuid,
        integrationName: integrationData?.name,
        abstract: abstract,
    };
};

/**
 * function collects all the variables from the abstract
 * @param {Object} abstract - Abstract object with info about it
 * @param {Array<Object>} integrations - List of all integrations
 * @returns {Array<Object>} - List of all variables of the abstract with info about abstract and integration
 */
const getCloudVariablesFromAbstract = (abstract, integrations) => {
    const integrationData = getIntegrationData(abstract?.details?.integration_uuid, integrations);

    return abstract.capabilities.map((capability) => {
        return generateCloudVariableData(capability, abstract, integrationData);
    });
};

/**
 * function goes through all the abstracts and collects all the variables into one list
 * @param {Array<Object>} abstractsWithOnlyVariables - List of abstracts filtered by variables
 * @param {Array<Object>} integrations - List of all integrations
 * @returns {Array<Object>} - List of all variables with info about abstract and integration
 */
const collectAllVariablesFromAbstracts = (abstractsWithOnlyVariables, integrations) => {
    return abstractsWithOnlyVariables?.reduce((collectedVariablesOfAbstract, abstract) => {
        const cloudVariablesOfAbstract = getCloudVariablesFromAbstract(abstract, integrations);

        return [...collectedVariablesOfAbstract, ...cloudVariablesOfAbstract];
    }, []);
};

/**
 * function generates a list of all variables with integration and abstraction information added to them
 * @param {Array<Object>} abstracts - List of all abstracts
 * @param {Array<Object>} integrations - List of all integrations
 * @returns {Array<Object>} - List of all cloud variables
 */
export const generateCloudVariablesListForSelect = (abstracts, integrations) => {
    if (!abstracts || !integrations) {
        return [];
    }
    const integrationAbstracts = getIntegrationAbstracts(abstracts);
    const abstractsWithVariables = getAbstractsWithVariables(integrationAbstracts);

    return collectAllVariablesFromAbstracts(abstractsWithVariables, integrations);
};

/**
 * function generates a list of all variables with integration and abstraction information added to them
 * @param {Array<Object>} abstracts - List of all abstracts
 * @param {Array<Object>} integrations - List of all integrations
 * @param {Object} filterType - Type of filteration
 * @param {Array<Object>} variablesInfo  - list of all the variables and their respective information eg: value and abstractUuid
 * @returns {Array<Object>} - List of all cloud variables
 */
export const generateCloudVariablesListForSelectWithFilter = (abstracts, integrations, filterType, variablesInfo) => {
    const options = generateCloudVariablesListForSelect(abstracts, integrations);

    if (!filterType || !filterType.value) {
        return options;
    }

    if (!options) {
        return [];
    }

    return filterCloudVariable(options, filterType, variablesInfo);
};

/**
 * function checks type of filter and then call filteration method
 * @param {Array<Object>} options - List of all cloudVariables
 * @param {Object} filterType - Type of filteration
 * @param {Array<Object>} variablesInfo  - list of all the variables and their respective information eg: value and abstractUuid
 * @returns {Array<Object>} - List of filtered cloud variables according to filterType
 **/
export const filterCloudVariable = (options, filterType, variablesInfo) => {
    if (!variablesInfo) {
        return [];
    }

    if (filterType.key === CLOUD_VARIABLE_FILTERS.VARIABLE_TYPE.KEY) {
        return filterCloudVariablesByVariableType(options, filterType.value, variablesInfo);
    }
};

/**
 * function checks type of filter and then call filteration method
 * @param {Array<Object>} options - List of all cloudVariables
 * @param {String} filterValue - Type of filteration value
 * @param {Array<Object>} variablesInfo  - list of all the variables and their respective information eg: value and abstractUuid
 * @returns {Array<Object>} - List of filtered cloud variables according to variable data type
 **/
export const filterCloudVariablesByVariableType = (options, filterValue, variablesInfo) => {
    if (!filterValue) {
        return [];
    }

    return options
        .map((option) => {
            const variableValue = getVariableValue(option?.abstract?.uuid, option?.cloudVariableName, variablesInfo);
            const variableValueDataType =
                variableValue && determineVariableValueDataType(variableValue[VARIABLE_VALUE_INDEX]);

            if (variableValue && variableValueDataType === filterValue) {
                return option;
            }
        })
        .filter((item) => !!item);
};

/**
 * function extracts variable value by matching abstractUuid and CloudvariableName in the abstract state
 * @param {String} abstract - abstractUuid
 * @param {String} cloudVariableName - variable name
 * @param {Array<Object>} variablesInfo  - list of all the variables and their respective information eg: value and abstractUuid
 * @returns {Array<Object>} - a single matching cloud variable info.
 **/
export const getVariableValue = (abstract, cloudVariableName, variablesInfo) => {
    return variablesInfo.find((item) => {
        if (item[VARIABLE_ABSTRACT_INDEX] === abstract && item[VARIABLE_NAME_INDEX] === cloudVariableName) {
            return item;
        }
    });
};

/**
 * function that determines whether an option is a variable you are looking for
 * @param {Object} option - Cloud variable
 * @param {String} selectedVariableName - Name of selected variable
 * @param {Object} selectedAbstract - Abstract to which the selected variable belongs
 * @returns {Boolean}
 */
const isSearingVariable = (option, selectedVariableName, selectedAbstract) => {
    const condition =
        option.cloudVariableName === selectedVariableName && selectedAbstract.uuid === option.abstract.uuid;

    return Boolean(condition);
};

/**
 * function finds the selected option(cloud variable) in the list of options(cloud variables)
 * @param {Array<Object>} options - List of cloud variables
 * @param {Object} variableData - Contains data about the variable (the name of the variable
 * and the abstract to which the variable belongs)
 * @returns {Object|String} - Variable option found for select
 */

export const getOptionOfSelectedVariable = (options, variableData = {}) => {
    const { selectedAbstract, selectedVariableName } = variableData;
    if (!selectedAbstract || !selectedVariableName || !options) {
        return '';
    }

    return options.find((option) => isSearingVariable(option, selectedVariableName, selectedAbstract)) || '';
};

/**
 * function that gets the current value from the variable object, also handles the null case.
 * @param {Object} currentValueData - Current Value data of selected cloud variable
 * @example currentValueData {
 *     isLoading: false,
 *     value: '200',
 *     error: null
 * }
 * @returns {String} - Cloud variable's current value
 */
export const getСurrentValueWithType = (currentValueData, calledFrom) => {
    const { value } = currentValueData;
    const getDataType = determineVariableValueDataType(value);
    const label = generateLabelAccordingToDataType(getDataType);

    if (getDataType !== null) {
        return calledFrom === LOCAL_FORM ? COLON + value : label + value;
    }

    return null;
};

/**
 * function that determines whether cloud variable value a float number or not
 * @param {Object} currentValueData - Cloud variable data
 * @returns {Boolean}
 */

export const checkIfValueIsFloat = (currentValueData) => {
    const { value } = currentValueData;
    const condition = value?.length !== ZERO && !isNaN(value) && value?.toString().indexOf('.') != -1 && value !== null;

    return Boolean(condition);
};
