import React from 'react';
import { EZLOGIC_TITLE_MODEL, EZLOGIC_TITLE_Z_WAVE_NODE_ID } from '../../../../../constants/language_tokens';
import { ZWAVE_NODE } from '../../../../../constants/DeviceAssociations';
import { dataDeviceRowControllerInfo, dataDeviceRowControllerInfoBlock } from '../style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux';
import { selectorOfCurrentControllerData } from '../../../EzloMeshbot/utils';
import ControllerInfoList from '../../../EzloGroups/GroupsSection/ControllersList/ControllerCard/ControllerInfoList';

import styles from '../../DeviceAssociationsItem/style.module.scss';

const DeviceAssociationControllerInfo = (props) => {
    const { deviceInfo, device, hideToolTip } = props;
    const { controllerData } = useSelector(selectorOfCurrentControllerData);
    const { t } = useTranslate();
    const isAssociationWithDevice = Object.keys(device).length;

    return (
        <span className={dataDeviceRowControllerInfo}>
            <ClearIcon className={styles.iconHide} onClick={hideToolTip} />
            {isAssociationWithDevice ? (
                <div className={dataDeviceRowControllerInfoBlock}>
                    <p>
                        {t(EZLOGIC_TITLE_MODEL)}: <span>{deviceInfo?.model}</span>
                    </p>
                    <p>
                        {t(EZLOGIC_TITLE_Z_WAVE_NODE_ID)}: <span>{deviceInfo?.[ZWAVE_NODE]}</span>
                    </p>
                </div>
            ) : (
                <div className={dataDeviceRowControllerInfoBlock}>
                    <ControllerInfoList controllerData={controllerData} />
                </div>
            )}
        </span>
    );
};

export default DeviceAssociationControllerInfo;
