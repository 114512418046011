import wsm from '../helpers/wsm';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { GenericActions } from './index';
import * as types from '../constants/ActionTypes';

const AssociationActions = {
    createAssociation: (serial, params) => (dispatch) => {
        dispatch({ type: types.CREATE_ASSOCIATION_PENDING });
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.setting.dictionary.value.set',
                params,
                (result) => {
                    resolve(result);
                },
                (error) => {
                    bugsnagNotify(error, { serial, params });
                    reject(error);
                    dispatch(GenericActions.showError(error));
                },
            );
        });
    },

    subscribeHubDeviceSettingDictionaryValueSet: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.device.setting.dictionary.updated', cb);
    },

    unsubscribeHubDeviceSettingDictionaryValueSet: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.device.setting.dictionary.updated');
    },
};

export default AssociationActions;
