import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

import styles from './VirtualDeviceItem.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_HEADING_DELETE_VIRTUAL_CONTAINER,
    EZLOGIC_TITLE_DELETE_VIRTUAL_CONTAINER_DESCRIPTION,
    EZLOGIC_TITLE_DELETE,
} from '../../../../../constants/language_tokens';
import { VirtualDeviceIcon } from '../../../../../assets/icons';

const VirtualDeviceItem = (props) => {
    const history = useHistory();
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { t } = useTranslate();
    const { onDelete, virtualDevice } = props;

    const handleEdit = useCallback(() => {
        const { abstract_uuid } = virtualDevice;
        history.push(`/ezlo/virtual-device/edit/${abstract_uuid}`);
    }, [virtualDevice]);

    const handleOpenConfirmationModal = () => {
        setIsConfirmModalVisible(true);
    };

    const handleCloseConfirmationModal = () => {
        setIsConfirmModalVisible(false);
    };

    const handleDelete = useCallback(
        (e) => {
            e.stopPropagation();
            onDelete(virtualDevice);
        },
        [virtualDevice, onDelete],
    );

    return (
        <>
            <li className={styles.virtualDeviceWrapper}>
                <div className={styles.virtualDeviceItem}>
                    <span className={styles.virtualDeviceIconContainer}>
                        <VirtualDeviceIcon />
                    </span>
                    <span className={styles.virtualDeviceName}>{virtualDevice.name}</span>
                    <span className={styles.virtualDeviceActions}>
                        <IconButton onClick={handleOpenConfirmationModal}>
                            <Delete fontSize="large" color="primary" />
                        </IconButton>
                        <IconButton className="disabled" onClick={handleEdit}>
                            <Edit fontSize="large" color="primary" />
                        </IconButton>
                    </span>
                </div>
            </li>

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={t(EZLOGIC_HEADING_DELETE_VIRTUAL_CONTAINER)}
                text={t(EZLOGIC_TITLE_DELETE_VIRTUAL_CONTAINER_DESCRIPTION)}
                confirmButtonTitle={t(EZLOGIC_TITLE_DELETE)}
                onCancel={handleCloseConfirmationModal}
                onConfirm={handleDelete}
                isVirtualContainerPage={true}
            />
        </>
    );
};

VirtualDeviceItem.propTypes = {
    virtualDevice: PropTypes.object.isRequired,
    abstractUUID: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default VirtualDeviceItem;
