import React from 'react';
import { IntegrationItem } from './IntegrationItem';

import styles from './Integrations.module.scss';

export const IntegrationList = (props) => {
    const { list, onParjectClick } = props;

    return (
        <div className={styles.list_container}>
            {list.map(({ name, uuid, icon, description }) => (
                <IntegrationItem
                    uuid={uuid}
                    icon={icon}
                    name={name}
                    onParjectClick={onParjectClick}
                    description={description}
                    key={uuid}
                />
            ))}
        </div>
    );
};
