import React from 'react';
import { useDispatch } from 'react-redux';

import UsersActions from '../../../../../actions/UsersActions';
import { EZLOGIC_TITLE_DELETE_WARNING } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import DeleteConfirmDialog from '../../components/DeleteConfirmDialog';

const PinCodeDelete = ({ pinCode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const deleteSelectedPinCode = () => {
        dispatch(UsersActions.deletePinCode(pinCode, t));
    };

    return (
        <DeleteConfirmDialog
            dialogText={`${t(EZLOGIC_TITLE_DELETE_WARNING)} ${pinCode.name}?`}
            confirmFn={deleteSelectedPinCode}
        />
    );
};

export default PinCodeDelete;
