/**
 * Default controllers group name
 * */
export const DEFAULT_CONTROLLERS_GROUP_NAME = 'Ezlo Main';

/**
 * Maximum amount of controllers in a group.
 * It used to prevent huge amount of websocket connections.
 * Cloud does not have this restriction(?), it is implemented only on the client side
 * */
export const MAX_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT = 24;

/**
 * Minimum amount of controllers in a group.
 * Cloud side restriction.
 * */
export const MIN_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT = 2;

/**
 * The same as MAX_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT,
 * but used to increase controllers limit for users in case when
 * user's account has a few controllers more than MAX_ALLOWED_CONTROLLERS_IN_GROUP_LIMIT value
 * and we allow user to create a default group for his amount of controllers.
 * Must be used only in default group case.
 * */
export const MAX_ALLOWED_CONTROLLERS_IN_GROUP_UPPER_LIMIT = 32;
export const MANAGE_GROUP_CONTROLLERS_LIMIT = 1;
export const MIN_ALLOWED_GROUPS_LIMIT = 1;
export const CONTROLLER_POPOVER_ID = 'controller-popover';
