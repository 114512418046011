import React, { useEffect, useMemo } from 'react';

import { CustomSelect } from './CustomSelect';

import { filterCapabilitiesByVirtualItemType, getMenuItems, updateMapConf } from '../utils';
import { mapTypes } from '../virtual-templates';

import { capabilitySelect } from './VirtualItem.module.scss';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_SELECT_DEVICE_SERVICE_CAPABILITY } from '../../../../constants/language_tokens';

export const CapabilitiesOfAbstractSelect = (props) => {
    const {
        abstract,
        abstracts,
        mapType,
        virtualItemKey,
        capabilities,
        capabilityOfAbstract,
        virtualItemUpdateParams,
        setVirtualDevice,
    } = props;
    const isStandard = mapType === mapTypes.STANDARD;
    const { t } = useTranslate();
    useEffect(() => {
        if (isStandard && capabilityOfAbstract) {
            const updatedVirtualDevice = updateMapConf(virtualItemUpdateParams, 'capability', capabilityOfAbstract);
            setVirtualDevice(updatedVirtualDevice);
        }
    }, [capabilityOfAbstract, isStandard, setVirtualDevice]);

    const handleChangeAbstractCapability = (e) => {
        const updatedVirtualDevice = updateMapConf(virtualItemUpdateParams, 'capability', e.target.value);

        setVirtualDevice(updatedVirtualDevice);
    };

    const appropriateCapabilities = useMemo(() => {
        if (abstracts.filter(({ uuid }) => uuid === abstract).length > 0) {
            const { capabilities: capabilitiesOfAbstract } = abstracts.find(({ uuid }) => uuid === abstract);

            return filterCapabilitiesByVirtualItemType(capabilitiesOfAbstract, capabilities, virtualItemKey);
        } else {
            return null;
        }
    }, [abstract, abstracts, capabilities, virtualItemKey]);

    return (
        <div className={capabilitySelect}>
            {appropriateCapabilities ? (
                <CustomSelect
                    menuItems={getMenuItems(appropriateCapabilities)}
                    placeholder={t(EZLOGIC_TITLE_SELECT_DEVICE_SERVICE_CAPABILITY)}
                    labelId="select-device-service-capability"
                    onChange={handleChangeAbstractCapability}
                    // value={isStandard ? capability : capabilityOfAbstract}
                    value={capabilityOfAbstract}
                />
            ) : null}
        </div>
    );
};
