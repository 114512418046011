import React, { useState } from 'react';
import TextField from '../../../components/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../constants/URLs';
import LoadingEllipses from '../Login/components/LoadingEllipses';
import { useTranslate } from '../../../features/languages';
import {
    BACK_TO_LOGIN,
    EZLOGIC_TITLE_EMAIL,
    EZLOGIC_TITLE_PASSWORD,
    EZLOGIC_TITLE_USER_NAME,
    REQUEST_RESET_LINK_BUTTON_TITLE,
    SIGN_UP_BUTTON_TITLE,
} from '../../../constants/language_tokens';
import { EyeIcon, EyeOffIcon } from '../../../assets/icons';

const AuthorizationPagesForm = ({ isLogin, isLoading, isSignUp, isForgotPassword, formik, children, staticForm }) => {
    const { t } = useTranslate();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const getLoginButtonContent = () => {
        return !isLoading ? (
            isSignUp ? (
                t(SIGN_UP_BUTTON_TITLE)
            ) : (
                t(REQUEST_RESET_LINK_BUTTON_TITLE)
            )
        ) : (
            <LoadingEllipses />
        );
    };

    const textFieldForName = (
        <TextField
            multiline={isSignUp ? true : false}
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            id="user_name"
            label={t(EZLOGIC_TITLE_USER_NAME)}
            name="name"
            type="text"
            autoComplete={isSignUp ? 'off' : 'on'}
            className="text-field"
        />
    );

    const textFieldForEmail = (
        <TextField
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id="email_address"
            label={t(EZLOGIC_TITLE_EMAIL)}
            name="email"
            type="text"
            className="text-field"
        />
    );

    const textFieldForPassword = (
        <TextField
            fullWidth
            id="user_password"
            name="pass"
            label={t(EZLOGIC_TITLE_PASSWORD)}
            type={showPassword ? 'text' : 'password'}
            className="text-field"
            value={formik.values.pass}
            onChange={formik.handleChange}
            error={formik.touched.pass && Boolean(formik.errors.pass)}
            helperText={formik.touched.pass && formik.errors.pass}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? (
                                <EyeIcon className="toggle-icon eye" />
                            ) : (
                                <EyeOffIcon className="toggle-icon" />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            {(isLogin || isSignUp) && textFieldForName}
            {(isSignUp || isForgotPassword) && textFieldForEmail}
            {(isLogin || isSignUp) && textFieldForPassword}

            {!isLogin && (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type={staticForm ? 'button' : 'submit'}
                        className={classNames('submit__btn')}
                    >
                        {getLoginButtonContent()}
                    </Button>
                    <div className="link-wrapper">
                        {!staticForm && (
                            <Link className="link" to={LOGIN}>
                                {t(BACK_TO_LOGIN)}
                            </Link>
                        )}
                    </div>
                </>
            )}
            {children}
        </form>
    );
};

export default AuthorizationPagesForm;
