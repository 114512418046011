import { BackupItemIcon } from '../../../../assets/icons';
import '../style.scss';
import React from 'react';

const BackupItem = (props) => {
    const { timestamp, timeOffset, formatBackupTimestamp, onBackupClick, uuid, selectedBackupId } = props;
    const backupListItemClasses = `${selectedBackupId === uuid ? 'backup-list-item active' : 'backup-list-item'}`;

    return (
        <li className={backupListItemClasses} onClick={() => onBackupClick(uuid)}>
            <BackupItemIcon className="item_icon" />
            <span className="date">{formatBackupTimestamp(timestamp, timeOffset)}</span>
        </li>
    );
};

export default BackupItem;
