import React from 'react';
import style from './spiner.module.scss';

const Spinner = (props = {}) => {
    return (
        <div {...props} className={`${style.spinner} ${props.className ? props.className : ''}`}>
            <img src={`${process.env.PUBLIC_URL}/assets/3.gif`} alt="loader" />
        </div>
    );
};

export default Spinner;
