import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@material-ui/core';

import EzloActions from '../../actions/EzloActions';

import { MESHBOT_CLOUD_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';

import './style.scss';

const MeshbotSwitch = ({ params }) => {
    const [switcher, setSwitcher] = useState(true);
    const dispatch = useDispatch();
    const isDisabledSwitchBtn = params && MESHBOT_CLOUD_TYPES.includes(params.type) ? true : params.isDisabled;

    useEffect(() => {
        setSwitcher(params.enabled);
    }, [params]);

    const handleChange = (event) => {
        if (params.serial && event.target.checked === true) {
            dispatch(EzloActions.onRule(params.serial, params.id));
        } else if (params.serial && event.target.checked === false) {
            dispatch(EzloActions.offRule(params.serial, params.id));
        }
        setSwitcher(event.target.checked);
    };

    return (
        <div className="switch-wrapper">
            <Switch
                disabled={isDisabledSwitchBtn}
                checked={switcher}
                onChange={handleChange}
                color="primary"
                name="switcher"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </div>
    );
};

export default MeshbotSwitch;
