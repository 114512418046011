import React from 'react';
import EzloActions from '../../actions/EzloActions';
import { MeshBotAction } from '../../actions';
import { STARTED } from '../../constants/MeshbotConstant';
import { MESHBOT_CLOUD_TYPES } from '../../containers/Ezlo/EzloMeshbots/constants';
import MesheneActions from '../../actions/MesheneActions';
import { useDispatch, useSelector } from 'react-redux';
import { getIcon } from '../MeshbotRun/utils';
import MeshbotRun from '../MeshbotRun';
import styles from './styles.module.scss';

const MeshbotRunWrapper = ({ params }) => {
    const dispatch = useDispatch();
    const { trackRunScenes } = useSelector((state) => state);
    const icon = getIcon(trackRunScenes, params.id, params.serial);

    const handleRun = () => {
        if (params.serial) {
            dispatch(EzloActions.onRuleRun(params.serial, params.id));
            dispatch(MeshBotAction.setBroadcastStatus(params.id, STARTED, params.serial));
        } else if (MESHBOT_CLOUD_TYPES.includes(params.type)) {
            dispatch(MeshBotAction.setFinishedCloudSceneStatus(params.id, STARTED));
            dispatch(MesheneActions.runMeshScenes(params.id));
        }
    };

    return (
        <div className={styles.meshbotRunWrapper}>
            <MeshbotRun handleRun={handleRun} icon={icon} params={params} />
        </div>
    );
};

export default MeshbotRunWrapper;
