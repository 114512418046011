import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import PrefixTemplate from '../components/prefixTemplate';
import { Header } from '../../../../components';

import firstImg from './exampleCode/firstImg.png';
import secondImg from './exampleCode/secondImg.png';
import thirdImg from './exampleCode/thirdImg.png';
import fourthImg from './exampleCode/fourthImg.png';

import { REJECTED, SUCCESS, CREATE, EDIT, TIMEOUT } from '../../../../constants/Plugins';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../constants/toastConstants';
import toastsActions from '../../../../actions/toastsActions';
import { useTranslate } from '../../../../features/languages';

import style from './style.module.scss';
import {
    EZLOGIC_BUTTON_ADD,
    EZLOGIC_HEADING_PREFIX_LIST,
    EZLOGIC_HEADING_SELECT_YOUR_PREFIX,
    EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE,
    EZLOGIC_TITLE_INVALID,
    EZLOGIC_TITLE_PLUGIN_SETTING_PARA_1,
    EZLOGIC_TITLE_PLUGIN_SETTING_PARA_2,
    EZLOGIC_TITLE_PLUGIN_SETTING_PARA_3,
    EZLOGIC_TITLE_PLUGIN_SETTING_PARA_4,
    EZLOGIC_TITLE_PLUGIN_SETTING_PARA_5,
    EZLOGIC_TITLE_PREFIX,
    EZLOGIC_TITLE_PREFIX_NAME,
    EZLOGIC_TITLE_VALID,
} from '../../../../constants/language_tokens';

const PluginSettings = (props) => {
    const [prefix, setPrefix] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const timerRef = useRef(0);

    const { isRequestStatus, isRequestStatusMessage, prefixesList, PluginActions } = props;

    useEffect(() => {
        PluginActions.getPrefixesList(t);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (isRequestStatus === SUCCESS) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: SUCCESS_OPTIONS,
                }),
            );
            setPrefix('');
        }

        if (isRequestStatus === REJECTED) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    isSave: true,
                }),
            );
        }

        if (isRequestStatus) {
            timerRef.current = setTimeout(() => {
                PluginActions.clearRequestStatus();
            }, TIMEOUT);
        }
    }, [isRequestStatus]);

    const handleSetPrefix = (event) => {
        setPrefix(event.target.value);
    };

    const handleSubmit = () => {
        const params = { action: CREATE, name: prefix };
        PluginActions.setPrefixes(params, t);
    };

    const handleEnablePrefix = useCallback((e, id) => {
        const params = { action: EDIT, uuid: id, enabled: 0 };
        PluginActions.setPrefixes(params, t);
    }, []);

    return (
        <div className={style.pluginSettings}>
            <Header headerClassName={style.pluginSettings__header} />
            <div className={style.pluginSettings__group}>
                <div>
                    <h2>{t(EZLOGIC_HEADING_SELECT_YOUR_PREFIX)}</h2>
                    <div className={style.pluginSettings__group}>
                        <div className={`${style.pluginSettings__group}, ${style.pluginSettings__block}`}>
                            <label htmlFor="outlined-basic" className={style.pluginSettings__block_label}>
                                {t(EZLOGIC_TITLE_PREFIX)}
                            </label>
                            <TextField
                                id="outlined-basic"
                                label={t(EZLOGIC_TITLE_PREFIX_NAME)}
                                variant="outlined"
                                value={prefix}
                                className={style.pluginSettings__input}
                                onChange={handleSetPrefix}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className={style.pluginSettings__button}
                                onClick={handleSubmit}
                            >
                                {t(EZLOGIC_BUTTON_ADD)}
                            </Button>
                        </div>
                        <div className={style.pluginSettings__status}>
                            {isRequestStatus === SUCCESS && (
                                <p>
                                    {t(EZLOGIC_TITLE_VALID)}
                                    <CheckIcon style={{ fill: 'green' }} />
                                </p>
                            )}
                            {isRequestStatus === REJECTED && (
                                <p>
                                    {t(EZLOGIC_TITLE_INVALID)}
                                    <ClearIcon color="secondary" />
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.pluginSettings__prefixes}>
                <h3>{t(EZLOGIC_HEADING_PREFIX_LIST)}</h3>
                <ul className={style.pluginSettings__list}>
                    {prefixesList.map((prefix) => {
                        return <PrefixTemplate key={prefix.uuid} prefix={prefix} onEnablePrefix={handleEnablePrefix} />;
                    })}
                </ul>
            </div>
            <div className={style.pluginSettings__json}>
                <p className={style.pluginSettings__json_label}>{t(EZLOGIC_TITLE_PLUGIN_SETTING_PARA_1)}</p>
                <p className={style.pluginSettings__json_hints}> {t(EZLOGIC_TITLE_PLUGIN_SETTING_PARA_2)}</p>
                <img
                    className={style.pluginSettings__json_img}
                    src={firstImg}
                    alt={t(EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE)}
                />
                <p className={style.pluginSettings__json_hints}>{t(EZLOGIC_TITLE_PLUGIN_SETTING_PARA_3)}</p>
                <img
                    className={style.pluginSettings__json_img}
                    src={secondImg}
                    alt={t(EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE)}
                />
                <p className={style.pluginSettings__json_hints}>{t(EZLOGIC_TITLE_PLUGIN_SETTING_PARA_4)}</p>
                <img
                    className={style.pluginSettings__json_img}
                    src={thirdImg}
                    alt={t(EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE)}
                />
                <p className={style.pluginSettings__json_hints}> {t(EZLOGIC_TITLE_PLUGIN_SETTING_PARA_5)} </p>
                <img
                    className={style.pluginSettings__json_img}
                    src={fourthImg}
                    alt={t(EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE)}
                />
            </div>
        </div>
    );
};

PluginSettings.propTypes = {
    isRequestStatus: PropTypes.string,
    isRequestStatusMessage: PropTypes.string,
    prefixesList: PropTypes.array,
    PluginActions: PropTypes.object,
};

export default PluginSettings;
