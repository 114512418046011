import React, { useMemo } from 'react';
import { useTranslate } from '../../../../../features/languages';

import { NUCAL_INTEGRATION_CONTENT } from '../../constants';

import styles from '../styles.module.scss';

const IntegrationSectionHeader = (props) => {
    const { integration, abstractsAmount, icon } = props;
    const { ACCOUNT, ACCOUNTS, ABSTRACT_AMOUNT } = NUCAL_INTEGRATION_CONTENT;

    const { t } = useTranslate();
    const accountAmountTile = useMemo(() => {
        return abstractsAmount === ABSTRACT_AMOUNT.ONE ? t(ACCOUNT) : t(ACCOUNTS);
    }, [abstractsAmount]);

    return useMemo(() => {
        return (
            <div className={styles.integrationHeader}>
                {icon && <img className={styles.integrationHeaderIcon} src={icon} />}
                <div className={styles.integrationHeaderBox}>
                    <div className={styles.integrationHeaderTitle}>{integration.name}</div>
                    <span
                        className={styles.integrationHeaderSubtitle}
                    >{`${abstractsAmount} ${accountAmountTile}`}</span>
                </div>
                <div className={styles.integrationHeaderLine} />
            </div>
        );
    }, [icon, integration, abstractsAmount, accountAmountTile]);
};

export default IntegrationSectionHeader;
