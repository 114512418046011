import React from 'react';
import {
    EZLOGIC_TITLE_ADVANCED_SCENES,
    EZLOGIC_TITLE_CONTROLLER_ID,
    EZLOGIC_TITLE_FIRMWARE,
    EZLOGIC_TITLE_MODEL,
    EZLOGIC_TITLE_UPTIME_INFO,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const ControllerInfoList = ({ controllerData, controller }) => {
    const { t } = useTranslate();

    return (
        <ul>
            {controllerData?.info?.model && (
                <li>
                    <span>{t(EZLOGIC_TITLE_MODEL)}: </span> {controllerData.info.model}
                </li>
            )}
            {controllerData?.info?.serial ? (
                <li>
                    <span>{t(EZLOGIC_TITLE_CONTROLLER_ID)}: </span> {controllerData.info.serial}
                </li>
            ) : (
                <li>
                    <span>{t(EZLOGIC_TITLE_CONTROLLER_ID)}: </span>
                    {controller.id || controller.serial}
                </li>
            )}
            {controllerData?.info?.uptime && (
                <li>
                    <span>{t(EZLOGIC_TITLE_UPTIME_INFO)}: </span> {controllerData.info.uptime}
                </li>
            )}
            {controllerData?.info?.firmware && (
                <li>
                    <span>{t(EZLOGIC_TITLE_FIRMWARE)?.toUpperCase()}: </span> {controllerData.info.firmware}
                </li>
            )}
            {controllerData?.features?.advanced_scenes?.version && (
                <li>
                    <span>{t(EZLOGIC_TITLE_ADVANCED_SCENES)}: </span> {controllerData.features.advanced_scenes.version}
                </li>
            )}
        </ul>
    );
};

export default ControllerInfoList;
