import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { Button } from '@material-ui/core';

import { getInstalledPluginFromRow, getPluginFromMarketplace, getUpdatedPluginRow } from '../../utils';
import { checkVersionSupport } from '../../../EzloMeshbot/utils';

import { EZLOGIC_BUTTON_INSTALL } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import ControllerTemplateButtons from '../ControllerTemplateButtons';
import style from './style.module.scss';
import { InstallIcon } from '../../../../../assets/icons';

const ControllerTemplate = (props) => {
    const { activeInstalling } = useSelector((state) => state.plugins);
    const [currentInstallPlugin, setCurrentInstallPlugin] = useState(null);
    const [currentPluginShouldUpdate, setCurrentPluginShouldUpdate] = useState(false);
    const [pluginWithUpdatedInformation, setPluginInformationForUpdate] = useState({});
    const {
        uuid,
        serial,
        isConnected,
        currentPlugin,
        listInstalledPlugins,
        onHandlerInstallPlugin,
        onHandlerRemovePlugin,
        onHandlerShowConfigureModal,
        onHandlerShowUpdateModal,
        marketplacePlugins,
    } = props;
    const { t } = useTranslate();

    useEffect(() => {
        findCurrentPluginName(listInstalledPlugins[serial], currentPlugin);
    }, [listInstalledPlugins, serial, currentPlugin]);

    const findCurrentPluginName = (pluginsList, plugin) => {
        if (plugin && pluginsList) {
            const installedPlugin = getInstalledPluginFromRow({ pluginsList, plugin });
            const pluginFromMarketPlace = getPluginFromMarketplace({ marketplacePlugins, installedPlugin });

            setCurrentInstallPlugin(installedPlugin);
            setCurrentPluginShouldUpdate(
                !checkVersionSupport(
                    installedPlugin?.version || '',
                    pluginFromMarketPlace?.meta?.config?.version || '',
                ),
            );
            const currentPluginRow = currentPlugin?.row || currentPlugin;
            if (pluginFromMarketPlace) {
                setPluginInformationForUpdate(getUpdatedPluginRow({ currentPluginRow, pluginFromMarketPlace }));
            }
        }
    };

    const className = classes('modal-plugin__install-online', { isOnline: isConnected });

    const isControllerInProcess = activeInstalling.includes(serial);

    return (
        <li className={style.modalPluginInstallController}>
            <div className="modal-plugin__group">
                <span className={className} />
                <span className="modal-plugin__install-serial">{serial}</span>
                {currentInstallPlugin && <span className={style.pluginVersion}>v.{currentInstallPlugin?.version}</span>}
            </div>
            <div className="modal-plugin__install-buttons">
                {currentInstallPlugin ? (
                    <ControllerTemplateButtons
                        currentPluginShouldUpdate={currentPluginShouldUpdate}
                        onHandlerShowConfigureModal={onHandlerShowConfigureModal}
                        onHandlerRemovePlugin={onHandlerRemovePlugin}
                        onHandlerShowUpdateModal={onHandlerShowUpdateModal}
                        pluginWithUpdatedInformation={pluginWithUpdatedInformation}
                        serial={serial}
                        isControllerInProcess={isControllerInProcess}
                        currentInstallPlugin={currentInstallPlugin}
                    />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onHandlerInstallPlugin(uuid, serial)}
                        disabled={isControllerInProcess}
                    >
                        <div className={style.iconInstall}>
                            <InstallIcon></InstallIcon>
                        </div>
                        <span>{t(EZLOGIC_BUTTON_INSTALL)}</span>
                    </Button>
                )}
            </div>
        </li>
    );
};

ControllerTemplate.propTypes = {
    uuid: PropTypes.string,
    serial: PropTypes.string,
    isConnected: PropTypes.bool,
    currentPlugin: PropTypes.object,
    listInstalledPlugins: PropTypes.object,
    onHandlerInstallPlugin: PropTypes.func,
    onHandlerRemovePlugin: PropTypes.func,
    onHandlerShowConfigureModal: PropTypes.func,
};

export default ControllerTemplate;
