import React, { useState, useReducer, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { IconButton, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { MapTypeSwitcher } from './MapTypeSwitcher';
import { CommandsSelect } from './CommandSelect';
import { AbstractSelect } from './AbstractSelect';
import { VariableSelect } from './VariableSelect';
import { CapabilitiesSelect } from './CapabilitiesSelect';
import { CapabilitiesOfAbstractSelect } from './CapabilitiesOfAbstractSelect';
import { MethodsSelect } from './MethodsSelect';
import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';

import {
    formJsArgumentForCommandIntegration,
    mapTypes,
    virtualItemKeys,
    VIRTUAL_DEVICE_CODE,
} from '../virtual-templates';
import {
    buildParsedCode,
    clearMapConf,
    fetchIntegrationPreview,
    getNucalComponentBySchemaKey,
    getNucalSchemaKey,
    prettierPath,
    updateMapConf,
    validate,
} from '../utils';
import { API_CAPABILITY_ABSTRACT } from '../../../../constants/VirtualDeviceConstant';

import { initialState, virtualDeviceReducer } from './store/reducer';
import {
    SET_FIELDS,
    SET_INTEGRATION_PREVIEW,
    SET_INTEGRATION_UUID,
    SET_METHOD,
    SET_NUCAL_COMPONENT,
    SET_PATHS,
} from './store/actions';

import { toast, TOAST_TYPE } from '../../../../components/Toast';
// eslint-disable-next-line
import { DynamicFieldsList } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasSteps/DynamicFields/DynamicFieldsList';
import style from './VirtualItem.module.scss';
import {
    EZLOGIC_HEADING_DELETE_DEVICE_CAPABILITY,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DELETE_DEVICE_CAPABILITY_DESCRIPTION,
    EZLOGIC_TITLE_ENTER_JS_CODE,
    EZLOGIC_TOAST_VIRTUAL_CONTAINER_CONFIGURATION,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const { VIRTUAL_TRIGGERS, VIRTUAL_ACTIONS } = virtualItemKeys;

const VirtualTrigger = (props) => {
    const { redux, virtualItem, virtualItemKey, virtualItemIndex, deleteTriggerFromVirtualDevice, handleErrors } =
        props;
    const {
        state,
        actions: { VirtualContainerActions },
    } = redux;
    const { virtualDevice, abstractsList, abstractCapabilities } = state;
    const { capabilities } = abstractCapabilities;
    const { setVirtualDevice, validateDynamicFields } = VirtualContainerActions;
    const { uuid, mapType, capability, map_conf } = virtualItem;
    const { code, command, abstract, variable, capability: capabilityOfAbstract } = map_conf;
    const virtualItems = virtualDevice[virtualItemKey];

    const virtualItemUpdateParams = useMemo(() => {
        return {
            mapType,
            virtualDevice,
            virtualItems,
            virtualItemIndex,
            virtualItemKey,
            virtualItemToUpdate: virtualItem,
        };
    }, [mapType, virtualDevice, virtualItems, virtualItemIndex, virtualItemKey, virtualItem]);

    const { abstractUuid } = useParams();
    const isStandardMode = mapType === mapTypes.STANDARD;
    const isVirtualTrigger = virtualItemKey === VIRTUAL_TRIGGERS;
    const isVirtualAction = virtualItemKey === VIRTUAL_ACTIONS;
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [initialRequiredValues, setInitialRequiredValue] = useState([]);
    const [virtualState, virtualDispatch] = useReducer(virtualDeviceReducer, initialState);
    const { paths, integrationUuid, method, integrationPreview, nucalComponent, fields } = virtualState;
    const isDynamicFieldsVisible = Boolean(method);
    const isCapabilitiesAndAbstractReceived = capability && abstract;
    const isCapabilitiesAndAbstractsListReceived = capability && abstractsList.abstracts;
    const { t } = useTranslate();

    useEffect(() => {
        if (abstract && abstractsList.abstracts.filter(({ uuid }) => uuid === abstract).length === 0) {
            const updatedVirtualDevice = clearMapConf(virtualItemUpdateParams);
            setVirtualDevice(updatedVirtualDevice);

            toast(t(EZLOGIC_TOAST_VIRTUAL_CONTAINER_CONFIGURATION), {
                type: TOAST_TYPE.INFO,
            });
        }
    }, [abstract, abstractsList.abstracts, setVirtualDevice, virtualItemUpdateParams]);

    const handleDeleteVirtualTrigger = () => {
        deleteTriggerFromVirtualDevice(virtualItemUpdateParams, uuid);
    };

    const handleOpenConfirmationModal = () => {
        setIsConfirmModalVisible(true);
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            handleDeleteVirtualTrigger();
        }
        setIsConfirmModalVisible(false);
    };

    const handleChangeJavaScriptCode = (e) => {
        const updatedVirtualDevice = updateMapConf(virtualItemUpdateParams, 'code', e.target.value);
        setVirtualDevice(updatedVirtualDevice);
    };

    useEffect(() => {
        if (abstractUuid && abstractsList.abstracts.length && capabilityOfAbstract === API_CAPABILITY_ABSTRACT) {
            const integration = abstractsList.abstracts.find((item) => item.uuid === abstract)?.details;
            if (integration) {
                const { integration_uuid } = integration;
                virtualDispatch({ type: SET_INTEGRATION_UUID, payload: { id: integration_uuid } });
            }
        }
    }, [abstractUuid, abstract, abstractsList.abstracts]);

    useEffect(() => {
        if (abstractUuid && integrationUuid && code && isStandardMode) {
            const { method, fields } = buildParsedCode(code);
            virtualDispatch({ type: SET_METHOD, payload: { method: `/${method}` ?? null } });
            virtualDispatch({ type: SET_FIELDS, payload: { fields: fields ?? {} } });
        }
    }, [abstractUuid, integrationUuid, code, isStandardMode]);

    useEffect(() => {
        if (method && fields) {
            const jsCode = formJsArgumentForCommandIntegration(method, fields);
            const updatedDevice = updateMapConf(virtualItemUpdateParams, VIRTUAL_DEVICE_CODE, jsCode);
            setVirtualDevice(updatedDevice);
        }
    }, [fields, method, setVirtualDevice]);

    useEffect(() => {
        if (nucalComponent && nucalComponent.required) {
            setInitialRequiredValue(nucalComponent.required);
        } else {
            setInitialRequiredValue([]);
        }
    }, [nucalComponent]);

    const setIntegrationUuid = (id) => {
        virtualDispatch({ type: SET_METHOD, payload: { method: null } });
        virtualDispatch({ type: SET_INTEGRATION_UUID, payload: { id } });
    };

    useEffect(() => {
        if (integrationUuid) {
            (async () => {
                const integrations = await fetchIntegrationPreview(integrationUuid);

                const paths = prettierPath(integrations, integrationUuid);

                virtualDispatch({ type: SET_PATHS, payload: { paths } });
                virtualDispatch({
                    type: SET_INTEGRATION_PREVIEW,
                    payload: { integrationPreview: integrations },
                });
            })();
        }
    }, [integrationUuid]);

    useEffect(() => {
        if (integrationUuid && method && integrationPreview) {
            const schemaKeys = getNucalSchemaKey(integrationPreview, integrationUuid, method);
            const component = getNucalComponentBySchemaKey(integrationPreview, integrationUuid, schemaKeys);

            virtualDispatch({
                type: SET_NUCAL_COMPONENT,
                payload: { nucalComponent: component },
            });
        }
    }, [integrationPreview, integrationUuid, method]);

    const handleSelectMethod = useCallback((method) => {
        virtualDispatch({ type: SET_FIELDS, payload: { fields: {} } });
        virtualDispatch({ type: SET_METHOD, payload: { method } });
    }, []);

    const handleDynamicFieldChange = (value, propertyName) => {
        virtualDispatch({
            type: SET_FIELDS,
            payload: {
                fields: {
                    ...fields,
                    [propertyName]: value,
                },
            },
        });
    };

    useEffect(() => {
        if (isDynamicFieldsVisible && isVirtualAction) {
            const res = validate(initialRequiredValues, fields);
            handleErrors(res);
        }
    }, [fields, initialRequiredValues, handleErrors, isDynamicFieldsVisible, isVirtualAction]);

    return (
        <div className={style.triggerItemWrapper}>
            <div className={style.triggerItem}>
                <CapabilitiesSelect
                    capability={capability}
                    capabilities={capabilities}
                    virtualItemUpdateParams={virtualItemUpdateParams}
                    setVirtualDevice={setVirtualDevice}
                />

                <MapTypeSwitcher
                    mapType={mapType}
                    setVirtualDevice={setVirtualDevice}
                    virtualItemUpdateParams={virtualItemUpdateParams}
                />

                {isCapabilitiesAndAbstractsListReceived && (
                    <AbstractSelect
                        abstract={abstract}
                        mapType={mapType}
                        abstracts={abstractsList.abstracts}
                        virtualItemUpdateParams={virtualItemUpdateParams}
                        setVirtualDevice={setVirtualDevice}
                        onSetIntegrationUuid={setIntegrationUuid}
                        isVirtualTrigger={isVirtualTrigger}
                    />
                )}

                {isCapabilitiesAndAbstractReceived && isCapabilitiesAndAbstractsListReceived && (
                    <CapabilitiesOfAbstractSelect
                        abstract={abstract}
                        mapType={mapType}
                        virtualItemKey={virtualItemKey}
                        abstracts={abstractsList.abstracts}
                        capabilities={capabilities}
                        capabilityOfAbstract={capabilityOfAbstract}
                        virtualItemUpdateParams={virtualItemUpdateParams}
                        setVirtualDevice={setVirtualDevice}
                    />
                )}

                {abstract && isVirtualTrigger && (isStandardMode ? capability : capabilityOfAbstract) && (
                    <VariableSelect
                        variable={variable}
                        capabilityAbstract={isStandardMode ? capability : capabilityOfAbstract}
                        abstractCapabilities={abstractCapabilities}
                        virtualItemUpdateParams={virtualItemUpdateParams}
                        setVirtualDevice={setVirtualDevice}
                    />
                )}

                {isCapabilitiesAndAbstractReceived && capabilityOfAbstract && isVirtualAction && (
                    <CommandsSelect
                        command={command}
                        isEdit={Boolean(abstractUuid)}
                        capabilityAbstract={capabilityOfAbstract}
                        abstractCapabilities={abstractCapabilities}
                        virtualItemUpdateParams={virtualItemUpdateParams}
                        setVirtualDevice={setVirtualDevice}
                    />
                )}

                {isStandardMode && integrationUuid && capabilityOfAbstract && (
                    <MethodsSelect paths={paths} onSelectMethod={handleSelectMethod} method={method} />
                )}

                {isStandardMode && capabilityOfAbstract === API_CAPABILITY_ABSTRACT && isDynamicFieldsVisible && (
                    <DynamicFieldsList
                        fields={fields}
                        accountUuid={abstract}
                        nucalComponent={nucalComponent}
                        onDynamicFieldChange={handleDynamicFieldChange}
                        method={method}
                        validateDynamicFields={validateDynamicFields}
                    />
                )}

                {mapType === mapTypes.ADVANCED && (
                    <TextField
                        multiline
                        variant="outlined"
                        className={style.codeField}
                        placeholder={t(EZLOGIC_TITLE_ENTER_JS_CODE)}
                        value={code}
                        onChange={handleChangeJavaScriptCode}
                    />
                )}

                <ConfirmModal
                    isOpened={isConfirmModalVisible}
                    onClose={setIsConfirmModalVisible}
                    title={t(EZLOGIC_HEADING_DELETE_DEVICE_CAPABILITY)}
                    text={t(EZLOGIC_TITLE_DELETE_DEVICE_CAPABILITY_DESCRIPTION)}
                    confirmButtonTitle={t(EZLOGIC_TITLE_DELETE)}
                    onCancel={() => handleConfirmation(false)}
                    onConfirm={() => handleConfirmation(true)}
                    isVirtualContainerPage={true}
                />
            </div>
            <IconButton className={style.deleteItemBtn} onClick={handleOpenConfirmationModal}>
                <Delete fontSize="large" color="primary" />
            </IconButton>
        </div>
    );
};

export default VirtualTrigger;
