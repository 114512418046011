import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';

import SelectComponent from '../SelectComponent';

import hash from '../../../../../constants/uniqueHash';
import { INPUT_PROPS, VIDOO_CAMERA_CAPABILITIES, CAMERA } from '../../../../../constants/MeshbotConstant';
import {
    getVidooCamerasList,
    getCameraCapabilitiesList,
    validateSelectedCameraCapability,
    getCurrentTempId,
    info,
} from '../../utils';

import styles from './VidooNodeForAction.module.scss';
import {
    EZLOGIC_TITLE_CAMERAS,
    EZLOGIC_TITLE_RECORDING_LENGTH,
    EZLOGIC_TITLE_SECS,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const VidooNodeForAction = (props) => {
    const [selectedCamera, setSelectedCamera] = useState('');
    const [selectedCapability, setSelectedCapability] = useState('');
    const [capabilityValue, setCapabilityValue] = useState('');
    const { t } = useTranslate();
    const {
        id,
        items,
        devices,
        blocks,
        firstBlock,
        actionBlockName,
        selectedFieldTrigger,
        onSelected,
        onAddActionBlock,
        onUpdateFieldActionBlock,
        selectedCameraId,
        selectedCapabilityValue,
        selectedCapabilityId,
    } = props;

    useEffect(() => {
        if (blocks && blocks.length > 0 && selectedCameraId) {
            setSelectedCamera(selectedCameraId);
            setSelectedCapability(selectedCapabilityId);
            setCapabilityValue(selectedCapabilityValue);
        } else {
            if (getVidooCamerasList(devices)) {
                const defaultCamera = getVidooCamerasList(devices)[0]?._id;
                const defaultCapability = getCameraCapabilitiesList(defaultCamera, items)[0]?._id;

                setSelectedCamera(defaultCamera);
                setSelectedCapability(defaultCapability);
                onSelected(null, id, actionBlockName, defaultCamera, CAMERA);
            }
        }
    }, []);

    const handleSelectedCameraChange = (e) => {
        const { value } = e.target;
        const defaultCapability = getCameraCapabilitiesList(value, items)[0]?._id;

        setSelectedCamera(value);
        setSelectedCapability(defaultCapability);
        onSelected(null, id, actionBlockName, value, CAMERA);
    };

    const handleCameraCapabilityChange = (e) => {
        const { value } = e.target;

        setSelectedCapability(value);
        onAddActionBlock(getCurrentTempId(value, blocks), id, actionBlockName);
        setCapabilityValue('');
        onUpdateFieldActionBlock(1, id, firstBlock, actionBlockName);
    };

    const handleCapabilityValueChange = (e) => {
        const value = Number(e.target.value);

        setCapabilityValue(value);
        onUpdateFieldActionBlock(value, id, firstBlock, actionBlockName);
    };

    const vidooCamerasList = () => {
        return (
            <SelectComponent value={selectedCamera} label={EZLOGIC_TITLE_CAMERAS} onChange={handleSelectedCameraChange}>
                {getVidooCamerasList(devices)?.map((device) => (
                    <MenuItem key={hash()} value={device._id}>
                        {device.name}
                    </MenuItem>
                ))}
            </SelectComponent>
        );
    };

    const cameraCapabilitiesList = () => {
        return (
            <div className={styles.mode_block__make_recording}>
                <SelectComponent
                    value={selectedCapability}
                    label={info.hints.capability}
                    info={info.text.capability}
                    onChange={handleCameraCapabilityChange}
                >
                    {getCameraCapabilitiesList(selectedCamera, items).map((item) => {
                        return (
                            <MenuItem key={hash()} value={item._id}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
                {validateSelectedCameraCapability(selectedCapability, items) ===
                    VIDOO_CAMERA_CAPABILITIES.MAKE_RECORDING && (
                    <div className={styles.inputRecordingLength}>
                        <TextField
                            variant="standard"
                            label={t(EZLOGIC_TITLE_RECORDING_LENGTH)}
                            id="recording"
                            size="small"
                            type="number"
                            value={capabilityValue}
                            inputProps={INPUT_PROPS}
                            onChange={handleCapabilityValueChange}
                        />
                        <span className={styles.unit}>{t(EZLOGIC_TITLE_SECS)}</span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            {selectedFieldTrigger === 'vidoo' && vidooCamerasList()}
            {selectedCamera && cameraCapabilitiesList()}
        </div>
    );
};

export default VidooNodeForAction;

VidooNodeForAction.propTypes = {
    id: PropTypes.string,
    items: PropTypes.array,
    blocks: PropTypes.array,
    devices: PropTypes.array,
    firstBlock: PropTypes.string,
    actionBlockName: PropTypes.string,
    selectedFieldTrigger: PropTypes.string,
    onSelected: PropTypes.func,
    onAddActionBlock: PropTypes.func,
    onUpdateFieldActionBlock: PropTypes.func,
    selectedCameraId: PropTypes.string,
    selectedCapabilityValue: PropTypes.number,
    selectedCapabilityId: PropTypes.string,
};
