import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import throttle from 'lodash/throttle';

import {
    Navigation,
    EzloDashboard,
    EzloHubBackups,
    EzloDevice,
    EzloVirtualContainer,
    EzloGroups,
    EzloMeshbots,
    EzloMeshBotLoc,
    MeshBotCloud,
    VirtualDevices,
    EzloParjectsSettings,
    EzloPlugins,
    EzloVirtualDevice,
    Customization,
    NotificationsTemplateCreate,
    NotificationsTemplateEdit,
    EzloDeviceGroups,
    DebouncedWebSocketComponent,
} from '../../containers';
import { CreateTicketPage } from './EzloSupport/EzloSupportCreateTicket';
import { SupportLayout } from './EzloSupport/EzloSupportLayout';
import UserManagement from './EzloAccount/UserManagement/index';
import EzloExpressions from './EzloExpressions';
import EzloAssociations from './EzloDeviceAssociations';
import EzloAccount from './EzloAccount/MyAccount';
import EzloLuaScripts from './EzloLuaScripts';
import MediaStoragePage from '../../features/MediaStoragePage';
import EzloVariables from './EzloExpressions/EzloVariables';
import ActionConfirmDialog from '../../components/ActionConfirmDialog';
import NotFoundPageContent from '../../containers/NotFoundPage/NotFoundPageContent';
import { PARTNER_PERMISSION } from './EzloCustomization/constants';
import { HUB_GATEWAY_THROTTLE_TIME } from '../../constants/Gateway';
import { CONTENT_WRAPPER, CONTENT_WRAPPER_FOR_CUSTOMIZATION } from '../../constants/SecurityClasses';
import EzloAccountSettings from './EzloAccount/AccountSettings';

import { isIntegrationsPage, isPageValue } from './utils';
import { checkVeraUser } from '../Navigation/utils';

import './style.scss';
import EzloSystemHistory from './EzloSystemHistory';
import EzloOEMNTemplateNotifications from './EzloOEMNotifications/EzloOEMNotifications';
import { useTranslate } from '../../features/languages';
import { checkPartnerDomain } from '../AuthorizationPages/Login/utils';
import { MEDIA_STORAGE_PATH } from '../Navigation/constants';
import { INTEGRATION_CONTAINER } from '../../constants/Integrations';

const Ezlo = (props) => {
    const {
        serial,
        ezloData,
        isShowModalClearMeshBot,
        savedAction,
        editableComponent,
        onlineControllers,
        isControllerAvailable,
    } = props.redux.state;
    const { location } = props;
    const { hideModal, clearSavedAction } = props.redux.actions.MainAction;
    const { clearMeshBot } = props.redux.actions.MeshBotAction;
    const isUser = checkVeraUser();
    const isPartnerDomain = checkPartnerDomain();
    const { subscribeHubDeviceSettingDictionaryValueSet, unsubscribeHubDeviceSettingDictionaryValueSet } =
        props.redux.actions.AssociationActions;

    const {
        updateItem,
        addNewItem,
        updateDevice,
        unsubscribeDeviceItemsUpdateState,
        unsubscribeDeviceUpdateState,
        subscribeDeviceItemsUpdateState,
        subscribeDeviceUpdateState,
    } = props.redux.actions.ItemActions;
    const {
        updateGatewayData,
        subscribeToGatewayMethod,
        subscribeToGatewayRemoved,
        unsubscribeToGatewayMethod,
        unsubscribeToGatewayRemoved,
        getEzloDeviceSettings,
        subscribeDeviceAdded,
        unsubscribeDeviceAdded,
        subscribeDeviceRemoved,
        subscribeItemAdded,
        unsubscribeItemAdded,
        subscribeItemUpdated,
        unsubscribeItemUpdated,
        unsubscribeDeviceRemoved,
        addNewDevice,
        removeDevice,
    } = props.redux.actions.EzloActions;
    const { offline } = props;
    const { url } = useRouteMatch();
    const { t } = useTranslate();
    const continueMeshBot = () => {
        clearSavedAction();
        hideModal();
    };

    const discardMeshBot = () => {
        const executeSavedAction = savedAction;

        clearMeshBot();
        hideModal();
        executeSavedAction();
        clearSavedAction();
    };

    useEffect(() => {
        onlineControllers.forEach((controller) => {
            subscribeToGatewayMethod(controller, throttle(handlerUpdateGateway(controller), HUB_GATEWAY_THROTTLE_TIME));
            subscribeToGatewayRemoved(controller, handlerUpdateGatewayRemoved(controller));
        });

        if (onlineControllers && onlineControllers.length > 0) {
            subscribeDeviceItemsUpdateState(onlineControllers, deviceUpdateItems);
            subscribeDeviceUpdateState(onlineControllers, deviceUpdate);
            subscribeHubDeviceSettingDictionaryValueSet(serial, hubDeviceSettingDictionaryValueSet);
            subscribeDeviceAdded(onlineControllers, handleAddDevice);
            subscribeDeviceRemoved(onlineControllers, handleRemoveDevice);
            subscribeItemAdded(onlineControllers, handleAddItem);
            subscribeItemUpdated(onlineControllers, handleItemUpdated);

            return () => {
                unsubscribeDeviceItemsUpdateState(onlineControllers);
                unsubscribeDeviceUpdateState(onlineControllers);
                unsubscribeHubDeviceSettingDictionaryValueSet(serial);
                unsubscribeDeviceAdded(onlineControllers);
                unsubscribeDeviceRemoved(onlineControllers);
                unsubscribeItemAdded(onlineControllers);
                unsubscribeItemUpdated(onlineControllers);
                onlineControllers.forEach((controller) => {
                    unsubscribeToGatewayMethod(controller);
                    unsubscribeToGatewayRemoved(controller);
                });
            };
        }
        // eslint-disable-next-line
    }, [isControllerAvailable, onlineControllers]);

    const hubDeviceSettingDictionaryValueSet = () => {
        getEzloDeviceSettings(serial, {});
    };

    const handlerUpdateGateway = (serial) => {
        return ({ result }) => {
            if (result.status === 'ready') {
                updateGatewayData(serial);
            }
        };
    };

    const handlerUpdateGatewayRemoved = (serial) => {
        return ({ result }) => {
            if (result.syncNotification) {
                updateGatewayData(serial);
            }
        };
    };

    // TODO: MOVE TO REDUX ACTIONS
    const deviceUpdateItems = (data) => {
        // TODO: simplify if-else
        if (data && data.controller_id) {
            updateItem(data.controller_id, data);
        } else {
            if (onlineControllers && onlineControllers?.length) {
                onlineControllers?.map((serial) => {
                    const itemIndex = ezloData[serial]?.items?.findIndex((device) => device._id === data._id);
                    if (itemIndex && itemIndex !== -1) {
                        updateItem(serial, data);
                    }
                });
            }
        }

        return data;
    };

    // TODO: MOVE TO REDUX ACTIONS
    const deviceUpdate = (data) => {
        if (onlineControllers && onlineControllers.length) {
            onlineControllers.map((serial) => {
                const deviceIndex = ezloData[serial]?.devices?.findIndex((device) => device._id === data._id);
                if (deviceIndex !== -1) {
                    updateDevice(serial, data);
                }
            });
        }
    };

    const pkPermissionData = useSelector((state) => state.account.data);

    const permission = pkPermissionData ? pkPermissionData.PK_PermissionRole : null;
    const handleAddDevice = (data) => {
        const { result, serial } = data;
        const deviceIndex = ezloData[serial].devices.findIndex((device) => device._id === result._id);

        if (deviceIndex === -1) {
            addNewDevice(serial, result);
        }
    };

    const handleRemoveDevice = (data) => {
        if (onlineControllers && onlineControllers.length) {
            onlineControllers.map((serial) => {
                const deviceIndex = ezloData[serial]?.devices?.findIndex((device) => device._id === data._id);
                if (deviceIndex !== -1) {
                    removeDevice(serial, data._id);
                }
            });
        }
    };

    const handleAddItem = (data) => {
        const itemIndex = ezloData[serial]?.items?.findIndex((item) => item._id === data._id);
        if (itemIndex === -1) {
            addNewItem(serial, data, t);
        }
    };

    const handleItemUpdated = (data) => {
        const itemIndex = ezloData?.[serial]?.items?.findIndex((item) => item._id === data._id);
        if (itemIndex === -1) {
            updateItem(serial, data);
        }
    };
    const getWrapperClassName = () => {
        if (isPageValue()) {
            return CONTENT_WRAPPER_FOR_CUSTOMIZATION;
        }

        if (isIntegrationsPage(location)) {
            return INTEGRATION_CONTAINER;
        }

        return CONTENT_WRAPPER;
    };
    const wrapperClassName = getWrapperClassName();

    return (
        <div className="ezlo-page-wrapper">
            {!isPageValue() && <Navigation />}
            <div className={wrapperClassName}>
                <Switch>
                    <Route exact path="/ezlo/groups" component={EzloGroups} />
                    <Route
                        path={['/ezlo/parjects', '/ezlo/parjects/new', '/ezlo/parjects/edit']}
                        component={EzloParjectsSettings}
                    />
                    <Route
                        path={[
                            '/ezlo/virtual-container',
                            '/ezlo/virtual-container/create',
                            '/ezlo/virtual-container/edit',
                        ]}
                        component={EzloVirtualContainer}
                    />
                    <Route
                        path={['/ezlo/virtual-device/create', '/ezlo/virtual-device/edit/:abstractUuid']}
                        component={EzloVirtualDevice}
                    />
                    <Route path={`${url}/dashboard`} component={EzloDashboard} />
                    <Route path={`${url}/backups`} component={EzloHubBackups} />
                    <Route path={`${url}/devices`} component={DebouncedWebSocketComponent} />
                    <Route path={'/ezlo/device-groups'} component={EzloDeviceGroups} />
                    <Route path="/ezlo/account-users" component={UserManagement} />
                    <Route path={`${url}/:ezloSerial/devices`} component={EzloDevice} />
                    <Route path={`${url}/:ezloSerial/device/:deviceId`} component={EzloDevice} />
                    <Route path={`${url}/meshbots`} component={EzloMeshbots} />
                    <Route path={`${url}/automation/local`} component={EzloMeshBotLoc} />
                    <Route path={`${url}/automation/cloud`} component={MeshBotCloud} />
                    <Route path={`${url}/automation/notification`} component={MeshBotCloud} />
                    <Route path={`${url}/automation/interaction`} component={MeshBotCloud} />
                    <Route path={`${url}/notification`} component={MeshBotCloud} />
                    <Route path={`${url}/interaction`} component={MeshBotCloud} />
                    <Route path={`${url}/plugins`} component={EzloPlugins} />
                    <Route path={`${url}/virtualDevices`} component={VirtualDevices} />
                    <Route path={`${url}/expressions`} render={() => <EzloExpressions {...props} />} />
                    <Route path={`${url}/scripts`} render={() => <EzloLuaScripts {...props} />} />
                    <Route path={`${url}/device-associations`} component={EzloAssociations} />
                    <Route exact path={`${url}/notifications`} component={EzloOEMNTemplateNotifications} />
                    <Route exact path={`${url}/notifications/create`} component={NotificationsTemplateCreate} />
                    <Route exact path={`${url}/notifications/:id`} component={NotificationsTemplateEdit} />
                    {/* <Route path={`${url}/notification-settings`} component={NotificationSettings} /> */}
                    <Route path={`${url}/my-account`} component={EzloAccount} />
                    <Route path={`${url}/account-settings`} component={EzloAccountSettings} />
                    <Route path={`${url}/support`} component={SupportLayout} />
                    <Route path={`${url}/create-ticket`} component={CreateTicketPage} />
                    <Route path={`${url}/system-history`} component={EzloSystemHistory} />
                    <Route path={`${url}${MEDIA_STORAGE_PATH}`} component={MediaStoragePage} />
                    {/*<Route path={`${url}/integrations`} component={EzloIntegrationsLayout} />*/}
                    {/*<Route path={`${url}/my-integrations`} component={EzloIntegrationsMyIntegrations} />*/}
                    {/*<Route path={`${url}/create-integration`} component={EzloIntegrationsGridContainer} />*/}
                    {/*<Route*/}
                    {/*    path={`${url}/create-integration-plugin-device/:uuid`}*/}
                    {/*    component={EzloIntegrationsCreatePluginIntegration}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path={`${url}/create-integration-exist-template-device/:uuid`}*/}
                    {/*    render={() => <EzloIntegrationsConfigureDeviceForm isExist={true} />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path={`${url}/create-integration-template-device/:uuid`}*/}
                    {/*    render={() => <EzloIntegrationsConfigureDeviceForm isExist={false} />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path={'/ezlo/integration-info/:type/:uuid'}*/}
                    {/*    render={({ match }) => {*/}
                    {/*        const { uuid, type } = match.params;*/}
                    {/*        switch (type) {*/}
                    {/*            case NUCAL:*/}
                    {/*                return <EzloIntegrationsNuCALInformation uuid={uuid} />;*/}
                    {/*            case PLUGIN:*/}
                    {/*                return <EzloIntegrationsPluginInformation uuid={uuid} />;*/}
                    {/*            case TEMPLATE:*/}
                    {/*                return <EzloIntegrationsIpTemplateInformation uuid={uuid} />;*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path={'/ezlo/integration-info-create/:type/:uuid'}*/}
                    {/*    render={({ match }) => {*/}
                    {/*        const { uuid, type } = match.params;*/}
                    {/*        switch (type) {*/}
                    {/*            case NUCAL:*/}
                    {/*                return <EzloIntegrationsNuCALInformation uuid={uuid} isCreating={true} />;*/}
                    {/*            case PLUGIN:*/}
                    {/*                return <EzloIntegrationsPluginCreateInformation uuid={uuid} />;*/}
                    {/*            case TEMPLATE:*/}
                    {/*                return <EzloIntegrationsIpTemplateInformation uuid={uuid} isCreating={true} />;*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {(permission === PARTNER_PERMISSION || isUser) && isPartnerDomain === false ? (
                        <Route path={`${url}/customization`} component={Customization} />
                    ) : null}
                    <Route path={`${url}/variables`} render={() => <EzloVariables {...props} />} />
                    {!offline && <Route render={() => <NotFoundPageContent loggedIn />} />}
                </Switch>
            </div>
            <ActionConfirmDialog
                title={editableComponent?.dialogBoxTitle}
                content={editableComponent?.dialogBoxDescription}
                isModalVisible={isShowModalClearMeshBot}
                onCancel={hideModal}
                continueMeshBot={continueMeshBot}
                discardMeshBot={discardMeshBot}
            />
        </div>
    );
};

export default Ezlo;
