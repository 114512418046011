import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as script from '../../../../constants/ScriptConstants';
import * as meshbot from '../../../../constants/MeshbotConstant';
import { toast, TOAST_TYPE } from '../../../../components/Toast';
import ScriptForm from '../components/ScriptForm';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TOAST_SCRIPT_SUCCESSFULLY_ADDED } from '../../../../constants/language_tokens';

const CreateScript = (props) => {
    const { serial, scriptNotification, errorsScript, history, MeshBotAction } = props;
    const { t } = useTranslate();

    useEffect(() => {
        if (scriptNotification === meshbot.ADD_SUCCESS) {
            history.replace(script.ROUTE_PRIVATE);
            toast(t(EZLOGIC_TOAST_SCRIPT_SUCCESSFULLY_ADDED), { type: TOAST_TYPE.SUCCESS });
        }

        if (scriptNotification === meshbot.REJECTED) {
            toast(errorsScript, { type: TOAST_TYPE.ERROR });
        }
        MeshBotAction.clearScriptNotification();
    }, [scriptNotification]);

    return <ScriptForm serial={serial} type={script.SCRIPT_CREATE} history={history} MeshBotAction={MeshBotAction} />;
};

CreateScript.propTypes = {
    serial: PropTypes.string,
    scriptNotification: PropTypes.string,
    history: PropTypes.object,
    MeshBotAction: PropTypes.object,
};

export default CreateScript;
