import React from 'react';
import RestoreIcon from '../icons/RestoreIcon';
import classNames from 'classnames';
import { formatBackupTimestamp } from '../utils';
import {
    EZLOGIC_BUTTON_RESTORE_THIS_BACKUP,
    EZLOGIC_TITLE_BACKUP_INCLUDE_SCENES,
} from '../../../../constants/language_tokens';
import Button from '@material-ui/core/Button';
import { useTranslate } from '../../../../features/languages';

const BackupRestoreContent = ({ backup, timeOffset, isCurrentControllerConnected, handleOpenRestoreBackupModal }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <RestoreIcon className="icon-restore" />
            <span className={classNames('text', 'title')}>
                {formatBackupTimestamp(backup ? backup.timestamp : null, timeOffset)}
            </span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_BACKUP_INCLUDE_SCENES)}
            </span>
            <div className="main-content-footer">
                <Button
                    color="primary"
                    variant="contained"
                    className="primary-btn"
                    disabled={!isCurrentControllerConnected}
                    onClick={handleOpenRestoreBackupModal}
                >
                    {t(EZLOGIC_BUTTON_RESTORE_THIS_BACKUP)}
                </Button>
            </div>
        </div>
    );
};
export default BackupRestoreContent;
