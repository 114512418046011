import React, { memo } from 'react';
import { EZLOGIC_TITLE_SERVICE_ICON } from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

import style from '../styles.module.scss';

const IntegrationImg = ({ integrationIcon }) => {
    const { t } = useTranslate();

    return (
        <img loading="lazy" className={style.serviceImg} src={integrationIcon} alt={t(EZLOGIC_TITLE_SERVICE_ICON)} />
    );
};

export default memo(IntegrationImg);
