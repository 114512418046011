import React, { memo } from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { EXPRESSION_COMPARATORS } from '../../../../../../../constants/MeshbotConstant';
import { DATA_TYPES_LIST, BOOLEAN } from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const ExpressionComparatorDropdown = (props) => {
    const {
        initialComparatorValue,
        onSetComparator,
        initialExpressionName,
        expressionList,
        selectedExpressionValueType,
        isExpression,
        selectedExpressionType,
    } = props;
    const { t } = useTranslate();
    const handleComparatorChange = (e) => {
        onSetComparator(initialExpressionName, expressionList, e.target.value);
    };

    const getComparatorOptionsByExpressionType = (type) => {
        if (!type && isExpression) {
            switch (selectedExpressionType) {
                case DATA_TYPES_LIST.TYPE_STRING:
                case BOOLEAN:
                    return EXPRESSION_COMPARATORS.GENERAL_COMPARATORS;
                default:
                    return EXPRESSION_COMPARATORS.INTEGER_COMPARATORS;
            }
        }

        switch (type) {
            case DATA_TYPES_LIST.TYPE_INT:
            case DATA_TYPES_LIST.TYPE_FLOAT:
                return EXPRESSION_COMPARATORS.INTEGER_COMPARATORS;
            default:
                return EXPRESSION_COMPARATORS.GENERAL_COMPARATORS;
        }
    };

    return (
        <SelectComponent
            info={info.text.comparator}
            label={info.hints.comparator}
            value={initialComparatorValue ?? ''}
            onChange={handleComparatorChange}
        >
            {getComparatorOptionsByExpressionType(selectedExpressionValueType)?.map((el) => {
                return (
                    <MenuItem key={el.id} value={el.value}>
                        {t(el.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default memo(ExpressionComparatorDropdown);
