import React from 'react';
import { InputMaterial } from '../../../../../components';
import { isTemperatureCapability } from '../../utils';

import { MIN_TEMPERATURE } from '../../../../../constants/NotificationTemplates';

import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VARIABLES_VARIABLE_SCALE_LABEL,
} from '../../../../../constants/language_tokens';

import styles from '../../../../../components/InputTypeCloud/InputTypeCloud.module.scss';

const FieldsForInputTypeObject = (props) => {
    const { onChangeMultipleSelect, value, nameSubBlock } = props;
    const { t } = useTranslate();

    return (
        <div className={styles.group}>
            <InputMaterial
                placeholder={t(EZLOGIC_TITLE_VARIABLES_VARIABLE_SCALE_LABEL)}
                type={'string'}
                onChange={(e) => onChangeMultipleSelect({ scale: e.target.value, target_value: value?.[0]?.value })}
                value={value?.[1]?.value || ''}
            />
            <InputMaterial
                placeholder={t(EZLOGIC_TITLE_VALUE)}
                type={'number'}
                onChange={(e) => onChangeMultipleSelect({ target_value: e.target.value, scale: value?.[1]?.value })}
                value={value?.[0]?.value || ''}
                min={isTemperatureCapability(nameSubBlock) ? MIN_TEMPERATURE : 0}
            />
        </div>
    );
};

export default FieldsForInputTypeObject;
