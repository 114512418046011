import React from 'react';
import DeviceGroupDevice from '../DeviceGroupDevice';

const DeviceGroupDevices = (props) => {
    const { payload, parentChainIds } = props;

    return <DeviceGroupDevice payload={payload} parentChainIds={parentChainIds} />;
};

export default DeviceGroupDevices;
