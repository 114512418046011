import React from 'react';
import {
    EZLOGIC_TITLE_CHANNEL,
    EZLOGIC_TITLE_GROUP,
    EZLOGIC_TITLE_MODEL,
    EZLOGIC_TITLE_Z_WAVE_NODE_ID,
} from '../../../../../../constants/language_tokens';
import ClearIcon from '@material-ui/icons/Clear';
import { ZWAVE_NODE } from '../../../../../../constants/DeviceAssociations';
import { useTranslate } from '../../../../../../features/languages';

import { dataDeviceRowControllerInfoBlock, iconHide } from '../../style.module.scss';

const DeviceAssociationSourceDeviceInfo = ({ deviceInfo, keyOfTargetNodes, hideToolTip }) => {
    const { t } = useTranslate();

    return (
        <div className={dataDeviceRowControllerInfoBlock}>
            <ClearIcon className={iconHide} onClick={hideToolTip} />
            <p>
                {t(EZLOGIC_TITLE_MODEL)}: <span>{deviceInfo?.model}</span>
            </p>
            <p>
                {t(EZLOGIC_TITLE_Z_WAVE_NODE_ID)}: <span>{deviceInfo?.[ZWAVE_NODE]}</span>
            </p>
            <p>
                {t(EZLOGIC_TITLE_CHANNEL)}: <span>{keyOfTargetNodes.split('_')[0]}</span>
            </p>
            <p>
                {t(EZLOGIC_TITLE_GROUP)}: <span>{keyOfTargetNodes.split('_')[1]}</span>
            </p>
        </div>
    );
};

export default DeviceAssociationSourceDeviceInfo;
