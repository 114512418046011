import React from 'react';

import {
    EZLOGIC_HEADING_DEPENDENCIES,
    EZLOGIC_HEADING_META_DATA_TITLE,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';
import ListOfMetaPluginFields from './ListOfMetaPluginFields';
import ListOfMetaDependencies from './ListOfMetaDependencies';
import PluginInformationChangelogContainer from './PluginInformationChangelog/PluginInformationChangelogContainer/PluginInformationChangelogContainer';
import styles from '../style.module.scss';

const PluginInformationContent = ({ metaData, config, name, changelog = [] }) => {
    const { t } = useTranslate();

    return (
        <>
            <h2 className={styles.title}>{name}</h2>
            <div className={styles.group}>
                <section className={styles.section}>
                    <h3 className={styles.label}>{t(EZLOGIC_HEADING_META_DATA_TITLE)}</h3>
                    {metaData && <ListOfMetaPluginFields metaData={metaData} />}
                </section>
                <section className={styles.section}>
                    <h3 className={styles.label}>{t(EZLOGIC_HEADING_DEPENDENCIES)}</h3>
                    <ListOfMetaDependencies config={config} />
                </section>
            </div>
            {Boolean(changelog.length) && <PluginInformationChangelogContainer changelog={changelog} />}
        </>
    );
};

export default PluginInformationContent;
