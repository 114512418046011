import './style.scss';
import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';
import { Layer } from '../../../../../../components';
import { PlusModalIcon } from '../../../../../../assets/icons';

/**
 * Create a new dialog.
 *
 * <Dialog
 *    title="Title",
 *    open={true|false},
 *    actions={<Button>}
 *    onHide={function () {}},
 * >
 *     ..........
 * </Dialog>
 */
class Dialog extends Component {
    // static propTypes = {
    //   title: PropTypes.string,
    //   open: PropTypes.bool,
    //   showCloseButton: PropTypes.bool,
    //   actions: PropTypes.node,
    //   style: PropTypes.object,
    //   headerStyle: PropTypes.object,
    //   bodyStyle: PropTypes.object,
    //   footerStyle: PropTypes.object,
    //   onHide: PropTypes.func,
    // };

    static defaultProps = {
        open: false,
        showCloseButton: true,
        actions: null,
        onHide: function () {},
    };

    render() {
        const { open, showCloseButton, onHide, children, actions, className, style, bodyStyle, footerStyle } =
            this.props;

        const containerClassName = classNames('dialog-container-backups', className);

        return (
            <Layer className="dialog-layer" style={{ display: open ? 'block' : 'none' }}>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="transition"
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={200}
                >
                    {open && (
                        <div className={containerClassName} style={style}>
                            {showCloseButton && <PlusModalIcon className="dialog-close" onClick={onHide} />}
                            {/* <div className="dialog-header" style={headerStyle}>
                {title}
              </div> */}
                            <div className="dialog-body" style={bodyStyle}>
                                {children}
                            </div>
                            {actions && (
                                <div className="dialog-footer" style={footerStyle}>
                                    {actions}
                                </div>
                            )}
                        </div>
                    )}
                    {open && <div className="dialog-overlay" onClick={onHide} />}
                </ReactCSSTransitionGroup>
            </Layer>
        );
    }
}

export default Dialog;
