import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import { toast, TOAST_TYPE } from '../../../../components/Toast';
import VariableAddForm from './VariableAddForm';
import ExpressionAddForm from './ExpressionAddForm';

import useUnsavedChangesWarning from '../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';
import {
    FORM_ADDING_NEW_EXPRESSION,
    EXPRESSION_EDITING_FORM,
    FORM_ADDING_NEW_VARIABLE,
    VARIABLE_EDITING_FORM,
} from '../../../../constants/ActionConfirmDialog';
import { EXPRESSIONS_TYPE } from '../../../../constants/Expressions';
import { DATA_TYPES_LIST } from '../../../../constants/Variables';

import {
    isExpressionFormValid,
    checkExpressionCreatingFormFields,
    checkExpressionEditingFormFields,
    buildExpressionPayload,
    isExpressionNameUnique,
} from '../utils';
import { ExpressionsActions, MainAction } from '../../../../actions';
import { useTranslate } from '../../../../features/languages';

import './styles.scss';
import * as styles from './ExpressionAddForm.module.scss';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_BUTTON_CREATE,
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_BUTTON_WAITING_TITLE,
} from '../../../../constants/language_tokens';

const ExpressionsAddForm = (props) => {
    const {
        onCancel,
        serial,
        initName,
        initCode,
        initParamsItems,
        expressionType,
        initValueType,
        initScalableType,
        initCreatedAt,
        expressionListSelector,
    } = props;

    const dispatch = useDispatch();
    const capabilitiesList = useSelector((state) => state.ezlo.data[serial].items);
    const [isFormValid, setIsFormValid] = useState(false);
    const [name, setName] = useState(initName ?? '');
    const [code, setCode] = useState(initCode ?? '');
    const [filteredCapabilitiesList, setFilteredCapabilitiesList] = useState(capabilitiesList);
    const [selectedDevice, setSelectedDevice] = useState({});
    const [selectedCapabilityId, setSelectedCapabilityId] = useState('');
    const [paramsItems, setParamsItems] = useState(initParamsItems ?? []);
    const [isWaitingSave, setIsWaitingSave] = useState(false);
    const [dataType, setDataType] = useState(initValueType ?? '');
    const isExpression = expressionType === EXPRESSIONS_TYPE.EXPRESSION;
    const setDirty = useUnsavedChangesWarning();
    const isDirty = useSelector((state) => state.main.editableComponent?.isDirty);
    const [scalableType, setScalableType] = useState(initScalableType ?? '');
    const [isInputNameNotValid, setIsInputNameNotValid] = useState(false);
    const { t } = useTranslate();

    const handleNameChange = (event) => {
        setName(event.target.value);
        if (isExpressionNameUnique(expressionListSelector, event.target.value)) {
            setIsInputNameNotValid(false);
        } else {
            setIsInputNameNotValid(true);
        }
    };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const notifyAddingError = (error) => {
        setIsWaitingSave(false);
        toast(error.message, { type: TOAST_TYPE.ERROR });
        dispatch(ExpressionsActions.unsubscribeAddedExpression(serial));
        dispatch(ExpressionsActions.unsubscribeChangedExpression(serial));
    };

    const notifyAddingSuccess = (response) => {
        setIsWaitingSave(false);
        toast(`"${response.result.name}" ${t('ezlo.successfully_saved.message')}`, { type: TOAST_TYPE.SUCCESS });
        onCancel();
        dispatch(ExpressionsActions.unsubscribeChangedExpression(serial));
        dispatch(ExpressionsActions.unsubscribeAddedExpression(serial));
    };

    const handleSaveExpression = () => {
        dispatch(MainAction.setConfirmationUser(false));
        setIsWaitingSave(true);

        const options = {
            expressionType,
            isExpression,
            name,
            initName,
            initCreatedAt,
            code,
            dataType,
            paramsItems,
            scalableType,
        };

        const expressionPayload = buildExpressionPayload(options);

        dispatch(ExpressionsActions.createExpression(serial, expressionPayload, notifyAddingError, t));
        dispatch(ExpressionsActions.subscribeAddedExpression(serial, notifyAddingSuccess));
        dispatch(ExpressionsActions.subscribeChangedExpression(serial, notifyAddingSuccess));
    };

    useEffect(() => {
        dataType === DATA_TYPES_LIST.TYPE_TOKEN &&
            !initCode &&
            setCode([
                {
                    value: '',
                    text: '',
                    checked: false,
                },
            ]);
    }, [dataType]);

    useEffect(() => {
        const validationParams = {
            name: name,
            code: code,
            initName: initName,
            initCode: initCode,
            initValueType: initValueType,
            dataType: dataType,
            scalableType: scalableType,
            initScalableType: initScalableType,
        };

        if (isExpressionFormValid(validationParams) !== isFormValid) {
            setIsFormValid((prevValue) => !prevValue);
        }
    }, [name, code, dataType, scalableType]);

    useEffect(() => {
        if (selectedDevice._id) {
            setFilteredCapabilitiesList(capabilitiesList.filter(({ deviceId }) => deviceId === selectedDevice._id));
        } else {
            setFilteredCapabilitiesList(capabilitiesList);
        }
    }, [selectedDevice._id]);

    useEffect(() => {
        if (expressionType === EXPRESSIONS_TYPE.EXPRESSION) {
            if (initName && initCode) {
                dispatch(MainAction.setStatePageBeingEdited(EXPRESSION_EDITING_FORM));
            } else {
                dispatch(MainAction.setStatePageBeingEdited(FORM_ADDING_NEW_EXPRESSION));
            }
        } else {
            if (initName && initCode) {
                dispatch(MainAction.setStatePageBeingEdited(VARIABLE_EDITING_FORM));
            } else {
                dispatch(MainAction.setStatePageBeingEdited(FORM_ADDING_NEW_VARIABLE));
            }
        }

        return () => dispatch(MainAction.setStatePageBeingEdited({}));
    }, []);

    useEffect(() => {
        if (initName && initCode) {
            if (checkExpressionEditingFormFields(initName, name, initCode, code)) {
                if (!isDirty) {
                    setDirty(true);
                    dispatch(MainAction.setConfirmationUser(true));
                }
            } else {
                setDirty(false);
                dispatch(MainAction.setConfirmationUser(false));
            }
        } else {
            if (!checkExpressionCreatingFormFields(name, code, selectedDevice, selectedCapabilityId)) {
                setDirty(false);
                dispatch(MainAction.setConfirmationUser(false));
            } else {
                if (!isDirty) {
                    setDirty(true);
                    dispatch(MainAction.setConfirmationUser(true));
                }
            }
        }
    }, [initName, name, initCode, code, selectedDevice, selectedCapabilityId]);

    return (
        <form
            data-testid="expressionsForm"
            className={`${styles.expressionItem} ${isExpression ? styles.expressionItemFlexWrap : ''}`}
        >
            <div className={isExpression ? styles.data : styles.variablesData}>
                {isExpression ? (
                    <ExpressionAddForm
                        code={code}
                        name={name}
                        serial={serial}
                        initName={initName}
                        isInputNameNotValid={isInputNameNotValid}
                        onChangeName={handleNameChange}
                        onChangeCode={handleCodeChange}
                        paramsItems={paramsItems}
                        selectedDevice={selectedDevice}
                        selectedCapabilityId={selectedCapabilityId}
                        filteredCapabilitiesList={filteredCapabilitiesList}
                        setParamsItems={setParamsItems}
                        setCode={setCode}
                        setSelectedDevice={setSelectedDevice}
                        setSelectedCapabilityId={setSelectedCapabilityId}
                    />
                ) : (
                    <VariableAddForm
                        code={code}
                        name={name}
                        dataType={dataType}
                        initName={initName}
                        scalableType={scalableType}
                        isExpression={isExpression}
                        isInputNameNotValid={isInputNameNotValid}
                        onChangeName={handleNameChange}
                        onSetCode={setCode}
                        onSetDataType={setDataType}
                        onSetScalableType={setScalableType}
                    />
                )}
            </div>
            <footer className={isExpression && styles.expressionControls}>
                <div className={`${styles.actions} ${isExpression ? styles.expressionActionsMargin : ''}`}>
                    <Button color="primary" variant="contained" className={styles.cancel} onClick={onCancel}>
                        {t(EZLOGIC_BUTTON_CANCEL)}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.create}
                        disabled={!isFormValid || isWaitingSave || isInputNameNotValid}
                        onClick={handleSaveExpression}
                    >
                        {!isWaitingSave && (initName ? t(EZLOGIC_BUTTON_SAVE) : t(EZLOGIC_BUTTON_CREATE))}
                        {isWaitingSave && t(EZLOGIC_BUTTON_WAITING_TITLE)}
                    </Button>
                </div>
            </footer>
        </form>
    );
};

export default ExpressionsAddForm;
