import React, { memo } from 'react';
import { TextField } from '@material-ui/core';

import { CUSTOM_PREFIX, EMPTY_LINE } from '../../constants';

import style from './CloudVariable.module.scss';
import { EZLOGIC_TITLE_VARIABLE_NAME } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

const VariableNameBlock = ({ capability, onChangeCapability }) => {
    const { t } = useTranslate();
    const handleChangeName = (e) => {
        const { value } = e.target;
        const newValue = !value ? EMPTY_LINE : `${CUSTOM_PREFIX}${e.target.value}`;
        onChangeCapability(newValue);
    };

    return (
        <div className={style.variableBlock}>
            <TextField
                required
                label={t(EZLOGIC_TITLE_VARIABLE_NAME)}
                variant="outlined"
                name="variable_name"
                value={capability?.startsWith(CUSTOM_PREFIX) ? capability.slice(CUSTOM_PREFIX.length) : capability}
                onChange={handleChangeName}
            />
        </div>
    );
};

export default memo(VariableNameBlock);
