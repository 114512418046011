import { useMemo, useState } from 'react';
import useDialogState from '../../../../../hooks/useDialogState/useDialogState';
import useMeshBotsTableContext from '../../../../../containers/Ezlo/EzloMeshbots/hooks/useMeshBotsTableContext';
import { useDispatch } from 'react-redux';
import { toggleStatus } from '../../utils';
import { MeshBotAction } from '../../../../../actions';
import useSelectedMeshBotsLabelsStatuses from '../useSelectedMeshBotsLabelsStatuses';

const useSelectedMeshBotsSelectedLabelsDropdownState = () => {
    const { open, toggleOpenDialog, setOpen } = useDialogState();
    const labelsWithStatuses = useSelectedMeshBotsLabelsStatuses();
    const { selectedMeshBotsByType } = useMeshBotsTableContext();
    const [labelsWithSelectStatus, setLabelsWithSelectStatus] = useState({});
    const [initLabelsWithSelectStatus, setInitLabelsWithSelectStatus] = useState({});
    const dispatch = useDispatch();

    const setLabelsStatuses = () => {
        setLabelsWithSelectStatus(labelsWithStatuses);
        setInitLabelsWithSelectStatus(labelsWithStatuses);
    };

    const isEdit = useMemo(() => {
        return JSON.stringify(initLabelsWithSelectStatus) !== JSON.stringify(labelsWithSelectStatus);
    }, [initLabelsWithSelectStatus, labelsWithSelectStatus]);

    const selectLabel = (value) => {
        setLabelsWithSelectStatus((prevState) => ({
            ...prevState,
            [value.id]: toggleStatus(value.id, labelsWithSelectStatus, initLabelsWithSelectStatus),
        }));
    };
    const toggleOpen = () => {
        if (!open) {
            setLabelsStatuses();
        }
        toggleOpenDialog();
    };

    const applyLabelSelectionChanges = () => {
        dispatch(MeshBotAction.applyLabelsUpdatesInSelectedMeshBots(labelsWithSelectStatus, selectedMeshBotsByType));
        setOpen(false);
    };

    return {
        applyLabelSelectionChanges,
        toggleOpen,
        selectLabel,
        setOpen,
        isEdit,
        labelsWithSelectStatus,
        open,
    };
};

export default useSelectedMeshBotsSelectedLabelsDropdownState;
