import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_CREATE_NEW_MESHBOT, EZLOGIC_TITLE_MESHBOTS } from '../../../../../constants/language_tokens';
import { PlusIcon } from '../../../../../assets/icons';

import styles from './styles.module.scss';

const ListHeader = (props) => {
    const { onClick } = props;
    const { t } = useTranslate();
    const updatedlistingUIName = useSelector((state) => state?.ezloCustomization?.listUi?.listingMeshbotName);

    return (
        <div className={styles.headerContainer}>
            <h1>{updatedlistingUIName ?? t(EZLOGIC_TITLE_MESHBOTS)}</h1>
            <div className={styles.listHeaderButtons}>
                <Button variant="contained" color="primary" startIcon={<PlusIcon />} onClick={() => onClick()}>
                    {t(EZLOGIC_BUTTON_CREATE_NEW_MESHBOT)}
                </Button>
            </div>
        </div>
    );
};

export default ListHeader;
