import React from 'react';

import useInstallerAccessRights from '../../hooks/useInstallerAccessRights/useInstallerAccessRights';
import InstallerAccessRightsInfo from '../InstallerAccessRightsInfo/InstallerAccessRightsInfo';
import InstallerAccessRightsToggle from '../InstallerAccessRightsToggle/InstallerAccessRightsToggle';
import InstallerAccessRightsDropdown from '../InstallerAccessRightsDropDown/InstallerAccessRightsDropdown';
import InstallerAccessRightsSaveButton from '../InstallerAccessRightsSaveButton/InstallerAccessRightsSaveButton';
import styles from './installerAccess.module.scss';

const InstallerAccessRights = ({ onShowModal, saveButtonDisabled }) => {
    const {
        toggleValue,
        selectedTime,
        enableDropdown,
        enableSaveButton,
        finalTime,
        handleTimeSelection,
        handleToggleSwitch,
        handleSaveButtonClick,
    } = useInstallerAccessRights({ onShowModal, saveButtonDisabled });

    return (
        <>
            <div className={styles['installer-access-container']}>
                <InstallerAccessRightsToggle value={toggleValue} onClick={handleToggleSwitch} />
                <InstallerAccessRightsInfo finalTime={finalTime} />
                <InstallerAccessRightsDropdown
                    disabled={enableDropdown}
                    selectedTime={selectedTime}
                    handleTimeSelection={handleTimeSelection}
                />
                <InstallerAccessRightsSaveButton disabled={!enableSaveButton} onClick={handleSaveButtonClick} />
            </div>
        </>
    );
};

export default InstallerAccessRights;
