import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataState } from '../../../helpers/helpersMeshBot';
import * as Actions from '../../../actions';
import EzloMeshBots from './EzloMeshBots';
import { validateDataLoading } from '../utils';

const mapStateToProps = (state) => ({
    serial: state.ezlo.serial,
    rules: getDataState(state.ezlo.data, 'rules'),
    cloudMeshBot: state.meshBot.cloud.cloudMeshBot,
    isShowModalCreateRule: state.main.isShowModalCreateRule,
    ezlo: state.ezlo,
    localMashBot: state.meshBot.local,
    isMeshBotsLoading: validateDataLoading(state),
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(EzloMeshBots);
