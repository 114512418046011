import React from 'react';
import { TextField } from '@material-ui/core';

const DateField = ({ value, onChange, name, isRequired }) => {
    const handleChange = (e) => {
        onChange(e.target.value, name, isRequired);
    };

    return (
        <TextField
            id="date"
            type="date"
            value={value}
            onChange={handleChange}
            variant="outlined"
            size="small"
            sx={{ width: 220 }}
            InputLabelProps={{
                shrink: true,
                style: { fontSize: '14px' },
            }}
        />
    );
};

export default DateField;
