import React, { useCallback, useContext } from 'react';

import { DragAndDropContext } from '../DragAndDropWithContext';
import { RESPONSE_TREE_ELEMENT } from '../constants';

const withDraggingState = (Component) => {
    const NewComponent = (props) => {
        const { setIsDragging } = useContext(DragAndDropContext);

        const handleDragStart = useCallback((e) => {
            e.stopPropagation();
            setIsDragging(true);
            const { element } = e.target.dataset;
            e.dataTransfer.setData(RESPONSE_TREE_ELEMENT, element);
        }, []);
        const handleDragEnd = useCallback((e) => {
            e.stopPropagation();
            setIsDragging(false);
        }, []);

        return <Component {...props} handleDragStart={handleDragStart} handleDragEnd={handleDragEnd} />;
    };

    return NewComponent;
};

export default withDraggingState;
