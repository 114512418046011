import React from 'react';
import { MAIN_CONTENT_VIEW } from '../constants';
import CreateBackup from '../SectionContent/CreateBackup';
import RestoreBackup from '../SectionContent/RestoreBackup';
import NotSupported from '../SectionContent/NotSupported';
import { withBackups } from '../../../../components/HOCs/withBackups';

const BackupsSectionContent = (props) => {
    const {
        isCurrentControllerConnected,
        createBackupProgressValue,
        view,
        restoreBackupStep,
        createBackupStep,
        isBackupsLoading,
        restoreBackupProgressValue,
        selectedBackup,
        timeOffset,
        handleFinish,
        handleRestoreBackup,
        handleConfirmRestoreBackup,
        handleOpenRestoreBackupModal,
        handleCreateBackup,
    } = props;

    switch (view) {
        case MAIN_CONTENT_VIEW.CREATE:
            return (
                <CreateBackup
                    progress={createBackupProgressValue}
                    step={createBackupStep}
                    onFinish={handleFinish}
                    handleCreateBackup={handleCreateBackup}
                />
            );
        case MAIN_CONTENT_VIEW.RESTORE:
            return (
                !isBackupsLoading && (
                    <RestoreBackup
                        step={restoreBackupStep}
                        progress={restoreBackupProgressValue}
                        backup={selectedBackup}
                        timeOffset={timeOffset}
                        onRestoreBackup={handleRestoreBackup}
                        onConfirmRestoreBackup={handleConfirmRestoreBackup}
                        onFinish={handleFinish}
                        onOpenRestoreBackupModal={handleOpenRestoreBackupModal}
                        isCurrentControllerConnected={isCurrentControllerConnected}
                    />
                )
            );
        case MAIN_CONTENT_VIEW.NOT_SUPPORTED:
            return <NotSupported />;
        default:
            return (
                <RestoreBackup
                    step={restoreBackupStep}
                    progress={restoreBackupProgressValue}
                    backup={selectedBackup}
                    timeOffset={timeOffset}
                    onRestoreBackup={handleRestoreBackup}
                    onFinish={handleFinish}
                    isCurrentControllerConnected={!isCurrentControllerConnected}
                    onConfirmRestoreBackup={handleConfirmRestoreBackup}
                    onOpenRestoreBackupModal={handleOpenRestoreBackupModal}
                />
            );
    }
};
export default withBackups(BackupsSectionContent);
