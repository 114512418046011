import {
    DATA_TYPES_LIST,
    EXPRESSION_TYPE_VARIABLE,
    LOCAL_VARIABLE_ACTION_FIELDS_VALUES,
} from '../../../../../constants/Variables';
import { formatTokenValueToDataType } from '../../../EzloExpressions/utils';

export const generateLocalVariableActionFields = (
    variableName,
    variableType,
    value,
    blockType,
    scalableType,
    delay,
) => ({
    blockOptions: {
        method: {
            args: {
                name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.NAME,
                type: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.TYPE,
                value: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.VALUE,
                valueType: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.VALUE_TYPE,
                metadata: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.METADATA,
            },
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.BLOCKOPTIONS_METHOD_NAME,
        },
    },
    blockType: blockType,
    fields: [
        {
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.NAME,
            type: variableType,
            value: variableName,
        },
        {
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.TYPE,
            type: variableType,
            value: variableType,
        },
        {
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.VALUE,
            type: variableType,
            value: formatValueToDataType(variableType, value),
        },
        {
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.VALUE_TYPE,
            type: variableType,
            value: variableType,
        },
        {
            name: LOCAL_VARIABLE_ACTION_FIELDS_VALUES.METADATA,
            type: variableType,
            value: {
                dataType: EXPRESSION_TYPE_VARIABLE,
                scalableType: scalableType ?? scalableType,
                enumValue: variableType === DATA_TYPES_LIST.TYPE_TOKEN && value && formatTokenValueToDataType(value),
            },
        },
    ],
    delay,
});

export const formatVariableValueDataTypeToString = (variableType, value) => {
    if (variableType === DATA_TYPES_LIST.TYPE_BOOLEAN || variableType === DATA_TYPES_LIST.TYPE_INT) {
        return JSON.stringify(value);
    }

    return value;
};

export const formatValueToDataType = (variableType, value) => {
    switch (variableType) {
        case DATA_TYPES_LIST.TYPE_BOOLEAN:
            return value && JSON.parse(value);
        case DATA_TYPES_LIST.TYPE_INT:
            if (value === '') {
                return '';
            }

            return Number(value);
        default:
            return value;
    }
};
