import Bugsnag from '@bugsnag/js';
import { toast } from 'react-toastify';

import { TOAST_TYPE } from '../../components/Toast';
import { isEzlogicProdEnv } from '../../constants/envs';
import { TOAST_MESSAGE_ON_ERROR } from '../../constants/toasts';
import { ERROR_CODE_METHOD_NOT_FOUND, RPC_METHOD_NOTFOUND } from '../../constants/MeshbotConstant';
import { t } from '../../helpers/language';

export const BUGSNAG_ENVS = {
    PROD: 'production',
    STAGE: 'staging',
};

export const getBugsnagEnv = () => {
    return isEzlogicProdEnv() ? BUGSNAG_ENVS.PROD : BUGSNAG_ENVS.STAGE;
};

export const bugsnagNotifyWrapper = (error, data = {}) => {
    bugsnagNotify(error, data);

    if ((error && error?.code === ERROR_CODE_METHOD_NOT_FOUND) || error?.data === RPC_METHOD_NOTFOUND) {
        return;
    }

    toast((error?.message || error) ?? t(TOAST_MESSAGE_ON_ERROR), { type: TOAST_TYPE.ERROR });
};

export const bugsnagNotify = (error, data = {}) => {
    return Bugsnag.notify(error, (event) => {
        event.addMetadata('Additional info:', data);
    });
};
