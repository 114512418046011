import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    ACCEPT_FILE_FORMAT_FOR_UPLOAD_IMG,
    FIRST_ITEM_IN_ARRAY,
    ONE_MB_IN_BYTES,
} from '../../../../features/MediaStoragePage/constants';
import { imgValidation } from '../utils';

const useLogoFieldWithImgDropzone = () => {
    const [isNotValidImgName, setIsNotValidImgName] = useState(false);
    const [logoFieldValue, setLogoFieldValue] = useState('');
    const [file, setFile] = useState(null);
    const dropzoneState = useDropzone({
        accept: ACCEPT_FILE_FORMAT_FOR_UPLOAD_IMG,
        maxSize: ONE_MB_IN_BYTES,
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles?.[0]) {
                setIsNotValidImgName(false);
                setFile(null);

                return;
            }

            const isValid = imgValidation(acceptedFiles);

            if (!isValid) {
                setIsNotValidImgName(true);
                setFile(null);
            }

            if (isValid) {
                setFile(
                    Object.assign(acceptedFiles[FIRST_ITEM_IN_ARRAY], {
                        preview: URL.createObjectURL(acceptedFiles[FIRST_ITEM_IN_ARRAY]),
                    }),
                );
                setIsNotValidImgName(false);
            }
            setLogoFieldValue('');
        },
    });

    useEffect(() => {
        return () => {
            if (file) {
                URL.revokeObjectURL(file.preview);
            }
        };
    }, [file]);

    const handleLogoChange = ({ target }) => {
        setLogoFieldValue(target.value);
        setFile(null);
    };

    return { dropzoneState, handleLogoChange, isNotValidImgName, logoFieldValue, imgFile: file, setLogoFieldValue };
};

export default useLogoFieldWithImgDropzone;
