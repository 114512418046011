export const generateHouseModeField = (value, blockType) => ({
    blockType,
    blockOptions: {
        method: {
            name: 'switchHouseMode',
            args: {
                houseMode: 'houseMode',
            },
        },
    },
    fields: [
        {
            name: 'houseMode',
            type: 'houseModeId',
            value,
        },
    ],
});
