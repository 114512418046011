import React from 'react';
import {
    EZLOGIC_TITLE_DEVICE_ASSOCIATION_NOT_SUPPORT,
    EZLOGIC_TITLE_MINIMUM_ALLOW_VERSION,
} from '../../../../constants/language_tokens';
import { DEVICE_ASSOCIATIONS } from '../../../../constants/DeviceAssociations';
import { useTranslate } from '../../../../features/languages';
import { supportNotification } from './styles.module.scss';

const DeviceAssociationsNotSupported = (props) => {
    const { currentFirmwareVersionNotification } = props;
    const { t } = useTranslate();

    return (
        <div className={supportNotification}>
            <p>
                {t(EZLOGIC_TITLE_DEVICE_ASSOCIATION_NOT_SUPPORT)}:{' '}
                {currentFirmwareVersionNotification.dataBeforeBuildVersion}.
                <u>{currentFirmwareVersionNotification.buildVersion}</u>
            </p>
            <p>
                {t(EZLOGIC_TITLE_MINIMUM_ALLOW_VERSION)}: <u>{DEVICE_ASSOCIATIONS.FIRMWARE_BUILD_VERSION}</u>.
            </p>
        </div>
    );
};

export default DeviceAssociationsNotSupported;
