import React from 'react';
import classes from 'classnames';

import { triggerOnDrag } from './ActionTriggerBlockWrapper.module.scss';

const ActionTriggerBlockWrapper = ({ provided, snapshot, children }) => {
    const getItemStyle = (draggableStyle) => ({
        userSelect: 'none',
        ...draggableStyle,
    });

    return (
        <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={classes({
                [triggerOnDrag]: snapshot.isDragging,
            })}
            style={getItemStyle(provided.draggableProps.style)}
        >
            {children}
        </li>
    );
};

export default ActionTriggerBlockWrapper;
