import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';

import TimeInputsBlock from '../../containers/Ezlo/EzloMeshbot/components/TimeInputsBlock';
import CheckBoxComponent from '../CheckBoxComponent';
import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { buildInteractionDashboardBlock } from '../../containers/Ezlo/EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { MESHBOT_NODE_TYPES } from '../../constants/MeshbotConstant';
import { EZLOGIC_TITLE_SELECT_A_DASHBOARD, EZLOGIC_TITLE_SHOW_ONLY } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const DashboardActionBlock = ({ getNotificationData, id, usersData, currentRule }) => {
    const { t } = useTranslate();
    const dashboardsList = useSelector((state) => state.notifications.dashboards);
    const [isTimeApplied, setIsTimeApplied] = useState(false);
    const [selectedDashboard, setSelectedDashboard] = useState({});
    const [seconds, setSeconds] = useState(0);
    const [targets, setTargets] = useState([
        {
            type: 'user',
            uuid: '',
        },
    ]);

    useEffect(() => {
        if (usersData?.length) {
            setTargets(usersData.map(({ uuid }) => ({ type: 'user', uuid, channels: ['push'] })));
        }

        if (currentRule?.length) {
            const rule = currentRule.find((ruleItem) => ruleItem.id === id);
            if (rule?.notification) {
                setInitialState(rule?.notification);
            }
        }
    }, [dashboardsList]);

    useEffect(() => {
        if (selectedDashboard?.uuid) {
            const data = buildInteractionDashboardBlock({
                targets,
                dashboardData: selectedDashboard,
                showForSeconds: isTimeApplied ? seconds : null,
            });

            getNotificationData(data, id, MESHBOT_NODE_TYPES.DASHBOARD);
        }
    }, [selectedDashboard, seconds, targets, isTimeApplied]);

    const setInitialState = (notificationBlock) => {
        setTargets(notificationBlock.parameters.find(({ name }) => name === 'target').targets);
        const urlObject = new URL(notificationBlock.parameters.find(({ name }) => name === 'dashboard').list[0].url);
        const dashboardUUID = urlObject.searchParams.get('dashboard_uuid');
        const findDashboard = dashboardsList.find(({ uuid }) => uuid === dashboardUUID);
        const showForSeconds = urlObject.searchParams.get('show_for_seconds');

        setSelectedDashboard(findDashboard);

        if (showForSeconds) {
            setIsTimeApplied(true);
            setSeconds(+showForSeconds);
        }
    };

    const handleChangeDashboard = (event) => {
        const { value } = event.target;
        setSelectedDashboard(value);
    };

    const handleSecondsUpdate = (seconds) => {
        setSeconds(seconds);
    };

    const handleShowTimeInputBlock = () => {
        setIsTimeApplied((prevState) => !prevState);
        setSeconds(0);
    };

    return (
        <>
            <SelectComponent
                value={selectedDashboard}
                label={t(EZLOGIC_TITLE_SELECT_A_DASHBOARD)}
                onChange={handleChangeDashboard}
            >
                {dashboardsList?.map((dashboardData) => (
                    <MenuItem className="notification-text" key={dashboardData.uuid} value={dashboardData}>
                        {dashboardData.name}
                    </MenuItem>
                ))}
            </SelectComponent>

            <CheckBoxComponent
                onClick={handleShowTimeInputBlock}
                checked={isTimeApplied}
                label={t(EZLOGIC_TITLE_SHOW_ONLY)}
            />
            <TimeInputsBlock
                hideContent
                onTimeUpdate={handleSecondsUpdate}
                mainStateSeconds={seconds}
                disabled={!isTimeApplied}
            />
        </>
    );
};

export default DashboardActionBlock;
