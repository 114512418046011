import React, { useEffect, useMemo, useState } from 'react';

import ResponseTreeContainer from '../../../ResponseTree/ResponseTreeContainer';
import IntegrationSimpleCodeContainer from './IntegrationSimpleCode/IntegrationSimpleCodeContainer';
import DropZoneOfVariables from './components/DropZoneOfVariables/DropZoneOfVariables';
import VariableFields from './components/VariableFields';
import VariableDialogBox from './components/VariableDialogBox';
import DragAndDropWithContext from '../../../ResponseTree/DragAndDropWithContext';

import { addItemToCodeArray, getIsSaveDisabled, updateCloudVariableState } from '../../utils';
import { CODE_ARRAY_KEY, CODE_KEY } from '../../constants';
import { generateCodeStringTemplateFromCodeArray, getItemTemplate } from '../../template';

import styles from '../../../ResponseTree/styles.module.scss';

const SimpleCodeContainer = (props) => {
    const {
        currentActionData,
        mappingState,
        handleSetMappingConfig,
        handleSetBlackListVariable,
        onSetCode,
        integrationPreviewResult,
        ComponentOfToTestVariables,
    } = props;
    const [dropElement, setDropElement] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [selectedVariable, setSelectedVariable] = useState(getItemTemplate());
    const [initialStateVariable, setInitialStateVariable] = useState();
    const { codeArray } = mappingState;
    const isCloudVariables = Boolean(codeArray?.length);

    useEffect(() => {
        if (dropElement) {
            handleAddItemToCodeArray(dropElement);
        }
    }, [dropElement]);

    const isSaveButtonDisabled = useMemo(() => {
        return getIsSaveDisabled(selectedVariable, initialStateVariable, currentActionData);
    }, [selectedVariable, initialStateVariable, currentActionData]);

    const handleAddItemToCodeArray = (dropElement) => {
        const state = addItemToCodeArray(mappingState, CODE_ARRAY_KEY, dropElement);
        const jsFromState = generateCodeStringTemplateFromCodeArray(state.codeArray);
        const updatedState = updateCloudVariableState(state, CODE_ARRAY_KEY, state.codeArray, CODE_KEY, jsFromState);
        handleSetMappingConfig(updatedState);
        onSetCode(jsFromState);
    };
    const handleChangeCodeArray = (value) => {
        const jsFromState = value.length > 0 ? generateCodeStringTemplateFromCodeArray(value) : '';
        const updatedState = updateCloudVariableState(mappingState, CODE_ARRAY_KEY, value, CODE_KEY, jsFromState);

        handleSetMappingConfig(updatedState);
        onSetCode(jsFromState);
    };
    const handleCreateVariable = (e) => {
        e.stopPropagation();
        setIsCreate(true);
        setIsOpen(true);
        setSelectedVariable(getItemTemplate());
    };
    const handleEdit = (e, variableData) => {
        e.stopPropagation();
        setIsOpen(true);
        setSelectedVariable(variableData);
        setInitialStateVariable({ variableData, currentActionData });
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleSave = () => {
        handleClose();
        let updatedState;
        if (isCreate) {
            updatedState = [...codeArray, selectedVariable];
        } else {
            updatedState = codeArray.map((variable) =>
                variable.uuid !== selectedVariable?.uuid ? variable : selectedVariable,
            );
        }

        handleChangeCodeArray(updatedState);
        setIsCreate(false);
    };

    const fieldsProps = {
        currentActionData,
        handleChangeCapability: (value) => {
            setSelectedVariable((state) => ({ ...state, capability: value }));
        },
        handleChangeValue: (value) => {
            setSelectedVariable((state) => ({ ...state, value }));
        },
        handleSetBlackListVariable,
        createdVariable: selectedVariable,
    };

    return (
        <DragAndDropWithContext>
            <div className={styles.treeViewWithDraggingBox}>
                <ResponseTreeContainer
                    method={currentActionData.methodValue}
                    integrationPreviewResult={integrationPreviewResult}
                />
                <div className={styles.dropContainer}>
                    <DropZoneOfVariables
                        setDropElement={setDropElement}
                        isCloudVariables={isCloudVariables}
                        handleCreateVariable={handleCreateVariable}
                    >
                        <IntegrationSimpleCodeContainer
                            codeArray={codeArray}
                            onChangeCodeArray={handleChangeCodeArray}
                            handleEdit={handleEdit}
                        />
                    </DropZoneOfVariables>
                    {ComponentOfToTestVariables}
                </div>

                <VariableDialogBox
                    handleClose={handleClose}
                    handleSave={handleSave}
                    isCreate={isCreate}
                    isOpen={isOpen}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                >
                    <VariableFields {...fieldsProps} />
                </VariableDialogBox>
            </div>
        </DragAndDropWithContext>
    );
};

export default SimpleCodeContainer;
