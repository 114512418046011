import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormHelperText, MenuItem } from '@material-ui/core';

import { CheckboxButton, TextField } from '../../../../components';
import TriggerFields from './triggerFields';
import TriggerDateFields from './triggerDateFields';
import TriggerDateFieldsCloud from './TriggerDateFieldsCloud';
import TriggerExpression from './triggerExpression';
import VidooNodeForTrigger from './VidooNodeForTrigger';
import FunctionForTriggerBlock from './FunctionForTriggerBlock';
import DeviceComparisonBlock from '../../../../components/DeviceComparisonBlock';
import DevicesBlock from './DeviceBlockComponent';
import TriggerHouseMode from './TriggerHouseMode/TriggerHouseMode';
import SelectComponent from './SelectComponent';
import TriggerBlockButtonsWrapper from './TriggerBlock/TriggerBlockButtonsWrapper';
import LocalNucalTriggerBlock from './LocalNucalTriggerBlock';
import DeviceGroupNodeForTriggerSection from './DeviceGroupNodeForTriggerSection';

import hash from '../../../../constants/uniqueHash';
import {
    dateAndTime,
    dateAndTimeCloud,
    cloudOperatorTrig,
    listNodesCloud,
    listNodesLocal,
} from '../../../../constants/rulesInSelect';
import * as meshBot from '../../../../constants/MeshbotConstant';
import { getMethodName } from '../../../../constants/MethodBlock';

import { ARMED } from '../../../../constants/MeshbotConstant';
import { deviceOptions, selectedFieldDateValue } from '../../utils';
import { MESHBOT_STATE_VALUES } from '../../../../constants/constMeshBot';

import {
    createMeshBotTriggerBlock,
    info,
    getClassesNamesForThenBlocks,
    getTriggerListData,
    filterDeviceGroupNodeByVersionAdvancedScenes,
    isVisibleNotOperator,
} from '../utils';

import { trigger_block, disabled_validation, body, inner, trigger_block__container } from './TriggerBlock.module.scss';
import useTriggerSelect from '../../../../hooks/useTriggerSelect';
import { PaasNodeForTrigger } from './PaasNodeForTrigger';
import ControllerNodeForTrigger from './ControllerNodeForTrigger';
import ExpressionComparison from './ExpressionComparison';
import oemManager from '../../../../../src/services/oem';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_NOT, EZLOGIC_TITLE_SPECIAL_TIME_OF_A_DAY } from '../../../../constants/language_tokens';
import useFeatureSupportChecker from '../../../../hooks/useFeatureSupportChecker';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../constants/ControllerFeatures';

const TriggerBlock = (props) => {
    const [isFirstBlock, setIsFirstBlock] = useState(false);
    const [currentIdDev, setCurrentIdDev] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState({});
    const serial = useSelector((state) => state.ezlo.serial);
    const DeviceGroupsList = useSelector((state) => state.ezlo.data?.[serial].deviceGroups?.deviceGroupsList);
    const [currentDeviceGroups, setCurrentDeviceGroups] = useState([]);
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState('');

    let coin = 0;

    const {
        id,
        idFunction,
        currentItem,
        items,
        devices,
        idDev,
        blocks,
        name,
        model,
        not,
        nameItem,
        nameBlock,
        code,
        nameVar,
        nameSubBlock,
        idGroup,
        typeMeshbot,
        typeTrigger,
        typeForm,
        ezloSerial,
        selectExpression,
        itemsDevice,
        currentDeviceSelect,
        firstBlock,
        selectedFieldTrigger,
        meshBotTriggerValue,
        meshBotStateValue,
        selectedOperator,
        selectedFieldDate,
        currentExpression,
        DeleteTrigger,
        onUpdateFieldBlock,
        onUpdateTriggerNot,
        onUpdateTrigger,
        onUpdateMeshBotTriggerValue,
        onGetNameCapability,
        onSelected,
        onAddBlock,
        onSelectedDate,
        onSetDateValue,
        onSetSpecificDate,
        onSetFieldValue,
        onGetCurrentDeviceId,
        UpdateExpression,
        nameExpression,
        CreateExpression,
        changeExpression,
        deviceBlocks,
        listCapabilities,
        handlerUpdateTriggerBlock,
        onUpdateCloudTriggerBlock,
        onUpdateDateTriggersCloud,
        updateTriggerNameBlock,
        onSelectedSpecificLabel,
        foundValidatedWhenBlock,
        rooms,
        onSelectedMeshBotTriggerState,
        onResetMeshBotTriggerBlocks,
        scenesList,
        onUpdateTriggerBlockFunction,
        onSelectedVidooTrigger,
        vidooTypeValue,
        capabilityValue,
        selectedCameraValue,
        selectedHotzoneValue,
        currentMeshbot,
        actionId,
        actionBlockName,
        MeshBotAction,
        onUpdateHouseModeTrigger,
        isOpenedExceptionBlock,
        selectedControllerCapability,
        selectedControllerCapabilityValue,
        selectedControllerCapabilityComparator,
        selectedControllerCapabilityComparatorValue,
        deviceId,
    } = props;

    const isException = typeTrigger === meshBot.EXCEPTION;
    const ruleTrigger = useTriggerSelect(id, idGroup, isException);
    const triggerBlockRef = useRef();
    const oemId = oemManager.oem.getOemId();
    const { t } = useTranslate();
    const isSupportedDeviceGroupNodeForLocalMeshBot = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_67,
    );

    useEffect(() => {
        if (typeMeshbot !== meshBot.CLOUD) {
            if (blocks && blocks.length > 0 && !isFirstBlock) {
                onAddBlock(blocks[0].blockId, id, idGroup);
                setIsFirstBlock(true);
            }

            if (idDev !== currentIdDev && blocks && blocks.length > 0 && isFirstBlock) {
                onAddBlock(blocks[0].blockId, id, idGroup);
                setCurrentIdDev(idDev);
            }
        }
    }, [blocks, idDev]);

    // This useEffect must run once on init of triggerBlock
    useEffect(() => {
        if (
            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE ||
            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED
        ) {
            setSelectedDevice(devices.find((device) => device._id === ruleTrigger?.deviceId));
        }
    }, []);

    useEffect(() => {
        if (DeviceGroupsList?.length) {
            setCurrentDeviceGroups(DeviceGroupsList);
        }
    }, [DeviceGroupsList]);

    useEffect(() => {
        if (
            typeMeshbot !== meshBot.CLOUD &&
            blocks &&
            blocks.length &&
            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_GROUP
        ) {
            setSelectedDeviceGroup(ruleTrigger?.deviceGroupId);
        }
    }, []);

    const meshbotsIdsArray = scenesList?.map(({ _id }) => _id);

    let isMeshbotExist = true;

    if (meshBotTriggerValue) {
        isMeshbotExist = meshbotsIdsArray.includes(meshBotTriggerValue);
    }

    const checkedNot = (e) => {
        onUpdateTriggerNot(e.target.checked, id, meshBot.NOT, idGroup);
    };

    const handleCheckedNotException = (e) => {
        onSetFieldValue(e.target.checked, id, meshBot.NOT, idGroup, actionId);
    };

    const updateDataInRuleTrigger = (data) => {
        if (selectedDeviceGroup) {
            MeshBotAction.updateDeviceGroupTrigger({
                ruleId: id,
                idGroup,
                ...data,
            });
        } else {
            MeshBotAction.updateDeviceAdvancedNode({
                ruleId: id,
                idGroup,
                ...data,
            });
        }
    };

    const updateCloudVariableRuleTrigger = (data) => {
        MeshBotAction.updateCloudVariablesTrigger({
            ruleId: id,
            idGroup,
            ...data,
        });
    };

    const updateDataInExceptionTrigger = (data) => {
        MeshBotAction.updateDeviceExceptionTrigger({
            ruleId: id,
            idGroup,
            actionId,
            ...data,
        });
    };

    const updateDataInControllerRuleTrigger = (data) => {
        MeshBotAction.updateControllerTrigger({
            ruleId: id,
            idGroup,
            ...data,
        });
    };

    const updateDataInControllerExceptionRuleTrigger = (data) => {
        MeshBotAction.updateControllerExceptionTrigger({
            ruleId: id,
            idGroup,
            actionId,
            data,
        });
    };

    const handleUpdateTriggerNode = (e, field) => {
        onUpdateTrigger(e, id, field, idGroup, actionId);
        setSelectedDevice({});
        setSelectedDeviceGroup('');
    };

    const handleUpdateMeshBotTriggerValue = (e) => {
        onUpdateMeshBotTriggerValue(e, id, idGroup);

        if (meshBotStateValue) {
            onResetMeshBotTriggerBlocks(id, idGroup);
        }
    };

    const handleUpdateMeshBotStateValue = (e) => {
        const { value } = e.target;
        const block = createMeshBotTriggerBlock(meshBotTriggerValue, value);
        onSelectedMeshBotTriggerState(value, id, block, idGroup);
    };

    const setChangeId = (item) => {
        if (getMethodName(item.blockOptions) === 'isButtonState') {
            coin += 1;

            return `${id}/${item.blockId}${coin}`;
        }

        return `${id}/${item.blockId}`;
    };

    const handleDeviceChange = (e) => {
        if (typeMeshbot === meshBot.CLOUD) {
            onSelected(e, id, selectedOperator, idGroup);

            return;
        }

        if (isException) {
            setSelectedDevice(e);
            MeshBotAction.updateDeviceExceptionTrigger({
                deviceId: e._id,
                name: e.name,
                ruleId: id,
                idGroup,
            });

            return;
        }

        setSelectedDevice(e);
        onSelected(e, id, idGroup, selectedFieldTrigger);
    };

    const options = deviceOptions(currentDeviceSelect, rooms);

    const handleDeleteTrigger = () => DeleteTrigger(id, idGroup, actionId, actionBlockName);

    // TODO: Replace with MeshBotSelectBlock
    const meshBotList = (
        <SelectComponent
            value={meshBotTriggerValue ?? ''}
            label={info.hints.capability}
            info={info.text.capability}
            onChange={handleUpdateMeshBotTriggerValue}
            deviceSetting={!isMeshbotExist && <FormHelperText>{info.text.errorMeshbotDoesntExist}</FormHelperText>}
        >
            {scenesList &&
                scenesList.map((item) => {
                    return (
                        <MenuItem
                            key={typeMeshbot === meshBot.CLOUD ? item.uuid : item._id}
                            value={typeMeshbot === meshBot.CLOUD ? item.uuid : item._id}
                        >
                            {item.name}
                        </MenuItem>
                    );
                })}
        </SelectComponent>
    );

    const meshBotStateList = (
        <SelectComponent
            value={meshBotStateValue ?? ''}
            label={info.hints.valueType}
            info={info.text.valueType}
            onChange={handleUpdateMeshBotStateValue}
        >
            {MESHBOT_STATE_VALUES &&
                MESHBOT_STATE_VALUES.map((item, index) => {
                    return (
                        <MenuItem key={`${item}-${index}`} value={item.value}>
                            {t(item.label)}
                        </MenuItem>
                    );
                })}
        </SelectComponent>
    );

    const handleChangeExpression = (e, id, idGroup) => {
        changeExpression(e, id, idGroup);
    };

    const handleSelectedDate = (e, idDevice, idGroup) => {
        onSelectedDate(e, idDevice, idGroup, 'schedule', actionId);
    };

    const handleFunctionBlockUpdate = (functionData) => {
        onUpdateTriggerBlockFunction(functionData, id, idGroup, actionId);
    };

    const updateClassesNamesForWhenBlocks = (block) => {
        if (block) {
            return getClassesNamesForThenBlocks(block);
        } else {
            return `${trigger_block} ${disabled_validation}`;
        }
    };

    const dateAndTimeType = typeMeshbot === meshBot.CLOUD ? dateAndTimeCloud : dateAndTime;

    const currentCapabilities =
        listCapabilities && listCapabilities.filter((item) => item.capability_name === nameBlock)[0];

    const variables = currentCapabilities && Object.keys(currentCapabilities.definition.variables);

    // TODO remove device OptionsWithoutProtocolPluginItems before implementing protocol plugin in Trigger
    const deviceOptionsWithoutProtocolPluginItems = options.filter(({ deviceTypeId }) => {
        const protocolPluginItems = ['http_manager', 'udp_manager', 'tcp_manager'];

        return !protocolPluginItems.includes(deviceTypeId);
    });

    const updatedTriggerNodes = useSelector((state) => state.ezloCustomization?.nodesControllables);

    const updateListTrigger = getTriggerListData(
        updatedTriggerNodes,
        filterDeviceGroupNodeByVersionAdvancedScenes(listNodesLocal, isSupportedDeviceGroupNodeForLocalMeshBot),
        listNodesCloud,
        typeMeshbot,
        oemId,
        isException,
    );

    const handleDeviceBlockChange = (event, value) => (value ? handleDeviceChange(value) : {});

    const handleDeviceGroupChange = (event) => {
        const { value } = event.target;

        setSelectedDeviceGroup(value);

        MeshBotAction.updateDeviceGroupTrigger({
            deviceGroupId: value,
            ruleId: id,
            idGroup,
        });
    };
    const memoAutocompleteInput = useMemo(() => {
        return (
            <DevicesBlock
                typeMeshbot={typeMeshbot}
                //TODO replace options={deviceOptionsWithoutProtocolPluginItems} with options={options}
                //TODO before implementing protocol plugin in Trigger
                options={deviceOptionsWithoutProtocolPluginItems}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                value={name ? { name } : {}}
                groupBy={(option) => option.roomName}
                disableClearable={true}
                onChange={handleDeviceBlockChange}
                renderInput={(params) => <TextField {...params} />}
                selectedDeviceId={props.deviceId}
            />
        );
    }, [name, deviceId]);

    return (
        <div className={updateClassesNamesForWhenBlocks(foundValidatedWhenBlock)} ref={triggerBlockRef}>
            <div className={trigger_block__container} id={`trigger-block__container-${id}`}>
                <div className={body}>
                    {/*The Not operator for the Device Group is hidden according to ticket 3556*/}
                    {isVisibleNotOperator(selectedFieldTrigger) && (
                        <CheckboxButton
                            id={id}
                            label={t(EZLOGIC_TITLE_NOT)}
                            name={meshBot.NOT}
                            value={not}
                            onChange={typeTrigger === meshBot.EXCEPTION ? handleCheckedNotException : checkedNot}
                        />
                    )}

                    <div className={inner}>
                        <SelectComponent
                            value={selectedFieldTrigger ?? ''}
                            label={info.hints.nodeType}
                            info={info.text.nodeType}
                            onChange={(e) => handleUpdateTriggerNode(e, 'selectedFieldTrigger')}
                        >
                            {updateListTrigger.map(({ id, value, label }) => {
                                return (
                                    <MenuItem key={id} value={value}>
                                        {t(label)}
                                    </MenuItem>
                                );
                            })}
                            {model === 'vhub' && <MenuItem value="vidoo">Vidoo</MenuItem>}
                        </SelectComponent>

                        {selectedFieldTrigger === meshBot.CONTROLLER && (
                            <ControllerNodeForTrigger
                                selectedControllerCapability={selectedControllerCapability}
                                selectedControllerCapabilityValue={selectedControllerCapabilityValue}
                                selectedControllerCapabilityComparator={selectedControllerCapabilityComparator}
                                selectedControllerCapabilityComparatorValue={
                                    selectedControllerCapabilityComparatorValue
                                }
                                onChange={
                                    isException
                                        ? updateDataInControllerExceptionRuleTrigger
                                        : updateDataInControllerRuleTrigger
                                }
                                ezloSerial={ezloSerial}
                            />
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.EXPRESSION && (
                            <SelectComponent
                                value={selectExpression ? selectExpression : ''}
                                label={info.hints.node}
                                info={info.text.node}
                                onChange={(e) => changeExpression(e, id, idGroup)}
                            >
                                {currentExpression &&
                                    currentExpression.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                            </SelectComponent>
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE && (
                            <TriggerHouseMode
                                id={id}
                                blocks={blocks}
                                idGroup={idGroup}
                                onUpdateHouseModeTrigger={onUpdateHouseModeTrigger}
                            />
                        )}

                        {(selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE ||
                            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED) &&
                            memoAutocompleteInput}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_GROUP && (
                            <DeviceGroupNodeForTriggerSection
                                onDeviceGroupChange={handleDeviceGroupChange}
                                currentDeviceGroups={currentDeviceGroups}
                                selectedDeviceGroup={selectedDeviceGroup}
                            />
                        )}

                        {(selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE ||
                            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED ||
                            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_GROUP) &&
                            (!!selectedDevice?._id || !!selectedDeviceGroup) && (
                                <DeviceComparisonBlock
                                    deviceId={selectedDevice?._id}
                                    deviceGroupId={selectedDeviceGroup}
                                    hasDeviceArmedProperty={selectedDevice.hasOwnProperty(ARMED)}
                                    ruleId={id}
                                    groupId={idGroup}
                                    updateTriggerData={
                                        isException ? updateDataInExceptionTrigger : updateDataInRuleTrigger
                                    }
                                    isException={isException}
                                    items={items}
                                />
                            )}
                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.MESHBOT_STATE && meshBotList}
                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.VIDOO && (
                            <VidooNodeForTrigger
                                id={id}
                                idGroup={idGroup}
                                items={items}
                                devices={devices}
                                blocks={blocks}
                                selectedFieldTrigger={selectedFieldTrigger}
                                vidooTypeValue={vidooTypeValue}
                                capabilityValue={capabilityValue}
                                selectedCameraValue={selectedCameraValue}
                                selectedHotzoneValue={selectedHotzoneValue}
                                onChange={handleDeviceChange}
                                onSelectedVidooTrigger={onSelectedVidooTrigger}
                            />
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.EXPRESSION && (
                            <SelectComponent
                                value={selectExpression ? selectExpression : ''}
                                label={info.hints.node}
                                info={info.text.node}
                                onChange={(e) => handleChangeExpression(e, id, idGroup)}
                            >
                                {currentExpression &&
                                    currentExpression.map((item) => {
                                        return (
                                            <MenuItem key={item.name} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </SelectComponent>
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.EXPRESSION && (
                            <TriggerExpression
                                id={id}
                                ezloSerial={ezloSerial}
                                idGroup={idGroup}
                                nameVar={nameVar}
                                name={name}
                                code={code}
                                nameItem={nameItem}
                                itemsDevice={itemsDevice}
                                nameExpression={nameExpression}
                                currentDeviceSelect={currentDeviceSelect}
                                onGetCurrentDeviceId={onGetCurrentDeviceId}
                                UpdateExpression={UpdateExpression}
                                CreateExpression={CreateExpression}
                                DeleteTrigger={DeleteTrigger}
                            />
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DATE_AND_TIME ||
                        selectedOperator === 'schedule' ? (
                            <SelectComponent
                                value={selectedFieldDateValue(selectedFieldDate)}
                                label={info.hints.node}
                                info={info.text.node}
                                onChange={(e) => handleSelectedDate(e, id, idGroup, selectedOperator)}
                            >
                                {dateAndTimeType.map((type) => {
                                    const { name, value } = type;

                                    return (
                                        <MenuItem key={name} value={name}>
                                            {t(value)}
                                        </MenuItem>
                                    );
                                })}
                                {!isOpenedExceptionBlock && (
                                    <MenuItem value="daily">{t(EZLOGIC_TITLE_SPECIAL_TIME_OF_A_DAY)}</MenuItem>
                                )}
                            </SelectComponent>
                        ) : null}
                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.MESHBOT_STATE &&
                            meshBotTriggerValue &&
                            isMeshbotExist &&
                            meshBotStateList}
                        {nameBlock && nameBlock !== '' && blocks && blocks.length > 0 && (
                            <>
                                <SelectComponent
                                    value={nameBlock ? nameBlock : ''}
                                    label={info.hints.nodeType}
                                    info={info.text.nodeType}
                                    onChange={(e) => updateTriggerNameBlock(e, currentItem, 'nameBlock', idGroup)}
                                >
                                    {blocks.map((item) => {
                                        return (
                                            <MenuItem key={hash()} value={onGetNameCapability(item)}>
                                                {onGetNameCapability(item)}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectComponent>

                                <SelectComponent
                                    value={selectedOperator ? selectedOperator : ''}
                                    label={info.hints.value}
                                    info={info.text.value}
                                    onChange={(e) => onUpdateTrigger(e, id, 'selectedOperator', idGroup)}
                                >
                                    {cloudOperatorTrig.map((item) => {
                                        return (
                                            <MenuItem key={item.name} value={item.value}>
                                                {t(item.name)}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectComponent>
                                {variables.length > 1 && (
                                    <SelectComponent
                                        value={nameSubBlock ? nameSubBlock : ''}
                                        label={info.hints.nodeType}
                                        info={info.text.nodeType}
                                        onChange={(e) =>
                                            updateTriggerNameBlock(e, currentItem, 'nameSubBlock', idGroup)
                                        }
                                    >
                                        {variables.map((item) => {
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectComponent>
                                )}
                            </>
                        )}
                        {blocks &&
                        blocks.length > 0 &&
                        typeMeshbot === meshBot.CLOUD &&
                        blocks[0].hasOwnProperty('name') &&
                        selectedOperator !== 'schedule' ? (
                            <TriggerFields
                                id={id}
                                idDev={idDev}
                                type={typeMeshbot}
                                idGroup={idGroup}
                                blocks={blocks}
                                nameDevice={name}
                                nameBlock={nameBlock}
                                listCapabilities={listCapabilities}
                                setChangeId={setChangeId}
                                firstBlock={firstBlock}
                                currentExpression={currentExpression}
                                deviceBlocks={deviceBlocks}
                                onGetNameCapability={onGetNameCapability}
                                onUpdateFieldBlock={onUpdateFieldBlock}
                                onUpdateCloudTriggerBlock={onUpdateCloudTriggerBlock}
                                handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                            />
                        ) : null}
                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DATE_AND_TIME &&
                            selectedFieldDate !== '' &&
                            typeMeshbot !== meshBot.CLOUD && (
                                <TriggerDateFields
                                    id={id}
                                    actionId={actionId}
                                    typeTrigger={typeTrigger}
                                    selectedFieldDate={selectedFieldDate}
                                    blocks={blocks}
                                    idGroup={idGroup}
                                    onSetDateValue={onSetDateValue}
                                    onSetSpecificDate={onSetSpecificDate}
                                    onSetFieldValue={onSetFieldValue}
                                    onSelectedSpecificLabel={onSelectedSpecificLabel}
                                    typeMeshbot={typeMeshbot}
                                />
                            )}
                        {selectedOperator === 'schedule' && (
                            <TriggerDateFieldsCloud
                                id={id}
                                blocks={blocks}
                                idGroup={idGroup}
                                selectedFieldDate={selectedFieldDate}
                                onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                            />
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.CLOUD_VARIABLES && (
                            <PaasNodeForTrigger
                                updateTriggerData={updateCloudVariableRuleTrigger}
                                ruleId={id}
                                groupId={idGroup}
                                isException={isException}
                            />
                        )}

                        {selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.PAAS && (
                            <LocalNucalTriggerBlock id={id} groupId={idGroup} isException={isException} />
                        )}

                        {(selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON ||
                            selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.VARIABLE_COMPARISON) && (
                            <ExpressionComparison
                                id={id}
                                idGroup={idGroup}
                                currentItem={currentItem}
                                isException={isException}
                                expressionComparisonType={selectedFieldTrigger}
                            />
                        )}
                    </div>
                </div>

                {(selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE ||
                    selectedFieldTrigger === meshBot.MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED) && (
                    <FunctionForTriggerBlock
                        alignRight
                        triggerType={meshBot.TRIGGER_TYPE_LIST.SINGLE_TRIGGER}
                        onUpdate={handleFunctionBlockUpdate}
                        initState={currentItem.function}
                        idFunction={idFunction}
                        ezloSerial={ezloSerial}
                        typeForm={typeForm}
                        currentMeshbot={currentMeshbot}
                        MeshBotAction={MeshBotAction}
                        isPortal
                        id={id}
                    />
                )}

                <TriggerBlockButtonsWrapper onDeleteTrigger={handleDeleteTrigger} />
            </div>
        </div>
    );
};

export default TriggerBlock;

TriggerBlock.protoType = {
    id: PropTypes.string,
    idGroup: PropTypes.string,
    name: PropTypes.string,
    not: PropTypes.bool,
    blocks: PropTypes.array,
    rooms: PropTypes.array,
    currentDeviceSelect: PropTypes.array,
    firstBlock: PropTypes.string,
    typeForm: PropTypes.string,
    selectedFieldDate: PropTypes.string,
    selectedSpecificDate: PropTypes.string,
    selectedSpecificLabel: PropTypes.string,
    selectedFieldTrigger: PropTypes.string,
    meshBotTriggerValue: PropTypes.string,
    meshBotStateValue: PropTypes.string,
    selectedTimeType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    SelectedField: PropTypes.func,
    onUpdateTrigger: PropTypes.func,
    onUpdateMeshBotTriggerValue: PropTypes.func,
    onResetMeshBotTriggerBlocks: PropTypes.func,
    onSelected: PropTypes.func,
    onUpdateFieldBlock: PropTypes.func,
    DeleteTrigger: PropTypes.func,
    onUpdateTriggerNot: PropTypes.func,
    onAddBlock: PropTypes.func,
    onSelectedDate: PropTypes.func,
    updateTriggerNameBlock: PropTypes.func,
    onUpdateHouseModeTrigger: PropTypes.func,
};
