import React from 'react';
import SwitchBool from '../../../../../components/SwitchBool';

const InputBoolean = () => {
    return (
        <div className="modal-plugin__configure-field">
            <SwitchBool />
        </div>
    );
};

export default InputBoolean;
