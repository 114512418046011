import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { PhonelinkSetup } from '@material-ui/icons';

import { getSumInstalledPlugins } from '../../utils';

import { pluginsListCount, pluginsListSumPlugins } from '../../PluginStyled.module.scss';

const CountInstalledPlugin = (props) => {
    const { privatePlugins, listInstalledPlugins } = useSelector((state) => state.plugins);

    const [countInstalledPlugin, setCountInstalledPlugin] = useState({});
    const { onClick } = props;
    const { row } = props.params || {};

    useEffect(() => {
        if (privatePlugins && listInstalledPlugins) {
            setCountInstalledPlugin(getSumInstalledPlugins(privatePlugins, listInstalledPlugins));
        }
    }, [listInstalledPlugins, privatePlugins]);

    const handleClick = () => {
        onClick(props.params);
    };

    return (
        <div className={pluginsListCount}>
            <span className={pluginsListSumPlugins}>{countInstalledPlugin[`${row?.nameId + '_' + row?.version}`]}</span>
            <IconButton onClick={handleClick}>
                <PhonelinkSetup />
            </IconButton>
        </div>
    );
};

export default CountInstalledPlugin;
