import React from 'react';
import { SupportOptionsList } from '../EzloSupportOptionsList';
import { supportPageWrapper } from '../EzloSupport.module.scss';

export const SupportLayout = () => {
    return (
        <div className={supportPageWrapper}>
            <SupportOptionsList />
        </div>
    );
};
