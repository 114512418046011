import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const NotificationMeshbot = () => (
    <SvgIcon color="primary" width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26 23.7238V25.0238H23H3H0V23.7238C0 22.6238 0.4 21.6238 1.2 20.9238L3 19.0238H6H7.9H13V3.02378C9.1 3.02378 6 6.12378 6 10.0238C6 12.0238 6 14.2238 6 16.0238H3V10.0238C3 4.32378 7.8 -0.376217 13.7 0.0237827C19 0.423783 23 5.02378 23 10.3238V19.0238L24.8 20.8238C25.6 21.6238 26 22.6238 26 23.7238ZM13 31.0238C14.7 31.0238 16 29.7238 16 28.0238H10C10 29.7238 11.3 31.0238 13 31.0238Z" />
    </SvgIcon>
);

export default NotificationMeshbot;
