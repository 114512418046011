import './style.scss';
import React, { useEffect, useState } from 'react';
import { DialogRight as Dialog } from '../../../../components';
import { DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DEFAULT_CONTROLLERS_GROUP_NAME } from '../constants';
import DialogControllersList from './DialogControllersList';
import { buildControllerGroupSetParams, CLOSE_DIALOG_DELAY, filterAvailableEzlosFromGroups } from '../utils';
import Button from '@material-ui/core/Button';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_CREATE_NEW_GROUP,
    EZLOGIC_TITLE_EDIT_GROUP,
    EZLOGIC_TITLE_MESSAGE_OF_CONTROLLERS_ARE_REQUIRED,
    EZLOGIC_TITLE_MESSAGE_OF_DELETING_GROUP,
    EZLOGIC_TITLE_MESSAGE_OF_PROVIDING_NAME_REQUIRED,
    EZLOGIC_TITLE_NAME,
} from '../../../../constants/language_tokens';
import { CloseIcon, Info } from '../../../../assets/icons';
// import { CloseIcon } from '../../../../assets/icons/visuals/generalIcons/CloseIcon';

export const getFilteredControllers = (ezlos, groups) => {
    const allGroups = Object.assign({}, { ...groups });

    return filterAvailableEzlosFromGroups(ezlos, allGroups);
};

const buildInitialState = (ezlos, groups, group) => {
    const state = {
        isEdit: !!group,
        name: group ? group.name : '',
    };
    let controllers;
    if (group) {
        controllers = [
            ...group.controllers.map((controller) => {
                return { ...controller, serial: controller.id, checked: true };
            }),
            ...getFilteredControllers(ezlos, groups, group && group.uuid).map((i) => ({
                ...i,
                id: i.serial,
                checked: false,
            })),
        ];
    } else {
        controllers = getFilteredControllers(ezlos, groups, group && group.uuid).map((i) => ({
            ...i,
            id: i.serial,
            checked: false,
        }));
    }

    state.controllers = controllers;

    return state;
};

const ManageGroupDialog = (props) => {
    const [state, setState] = useState({
        isEdit: false,
        name: '',
        controllers: [],
    });
    const { t } = useTranslate();
    useEffect(() => {
        const { open, group, ezlos, groups } = props;
        if (open && group) {
            setState(buildInitialState(ezlos, groups, group));
        } else {
            // prevent components flickering
            setTimeout(() => {
                setState(buildInitialState(ezlos, groups, null));
            }, CLOSE_DIALOG_DELAY);
        }
    }, [props.open]);

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setState({ ...state, name: newName });
    };

    const handleSubmit = () => {
        const { group, onCreate, onUpdate, onDelete } = props;
        const controllers = state.controllers
            .filter((i) => i.checked)
            .map((controller) => ({ id: Number.parseInt(controller.id) }));
        if (state.isEdit) {
            if (controllers.length >= 2) {
                onUpdate(buildControllerGroupSetParams(controllers, state.name, group.uuid));
            } else {
                onDelete(group, t(EZLOGIC_TITLE_MESSAGE_OF_DELETING_GROUP));
            }
        } else {
            onCreate(buildControllerGroupSetParams(controllers, state.name));
        }

        setTimeout(() => {
            onClose();
        }, CLOSE_DIALOG_DELAY);
    };

    const getDialogTitle = () => {
        return state.isEdit ? t(EZLOGIC_TITLE_EDIT_GROUP) : t(EZLOGIC_TITLE_CREATE_NEW_GROUP);
    };

    const handleControllerSelect = (serial) => {
        // todo: very bad code! Need refactoring
        const controller = state.controllers.find((i) => i.serial === serial);
        controller.checked = !controller.checked;
        setState({ ...state, controllers: [...state.controllers] });
    };

    const isNotEnoughControllers = () => {
        return state.controllers.filter((i) => i.checked).length < 2;
    };

    const isSubmitDisabled = () => {
        if (state.isEdit) {
            return !state.name;
        } else {
            return !state.name || isNotEnoughControllers();
        }
    };

    const { open, onClose, group } = props;
    // it is not possible to rename default group (only client side restriction)
    const isDefaultGroup = group && group.name === DEFAULT_CONTROLLERS_GROUP_NAME;
    const errorMessage = t(EZLOGIC_TITLE_MESSAGE_OF_PROVIDING_NAME_REQUIRED);

    return (
        <Dialog open={open} onClose={onClose} className="dialog">
            <DialogTitle className="dialog__title">
                <IconButton className="icon-container close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <span className="title">{getDialogTitle()}</span>
                <div className="text-wrapper">
                    <TextField
                        label={t(EZLOGIC_TITLE_NAME)}
                        className="text-field"
                        type="text"
                        name="group_name"
                        value={state.name}
                        onChange={handleNameChange}
                        error={!state.name}
                        helperText={!state.name && errorMessage}
                        inputProps={{ readOnly: isDefaultGroup }}
                    />
                </div>
            </DialogTitle>
            <DialogContent className="dialog__content">
                <DialogControllersList controllers={state.controllers} onChange={handleControllerSelect} />
            </DialogContent>
            <DialogActions style={{ display: 'flex', flexDirection: 'column', padding: '12px 24px 24px' }}>
                {!state.isEdit && isNotEnoughControllers() && (
                    <div
                        style={{
                            display: 'flex',
                            fontSize: '13px',
                            lineHeight: '1.2',
                            fontFamily: 'Ezlo Sans',
                            fontWeight: '500',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: '#FE5C15',
                            marginBottom: '24px',
                        }}
                    >
                        <Info className="info" />
                        {t(EZLOGIC_TITLE_MESSAGE_OF_CONTROLLERS_ARE_REQUIRED)}
                    </div>
                )}
                <Button
                    color="primary"
                    variant="contained"
                    style={{
                        fontSize: '16px',
                        width: '100%',
                        height: '56px',
                        fontFamily: 'Ezlo Sans',
                        flexGrow: '1',
                        margin: '0 24px',
                        textTransform: 'none',
                    }}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled()}
                >
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ManageGroupDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    ezlos: PropTypes.array.isRequired,
    groups: PropTypes.object.isRequired,
};

ManageGroupDialog.defaultProps = {
    open: false,
};

export default ManageGroupDialog;
