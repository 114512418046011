import React, { useMemo } from 'react';
import { useFormikContext, getIn } from 'formik';
import { TextField } from '@material-ui/core';

import { AUTO_COMPLETE_VALUES } from '../../../../../constants/Users';

import styles from './styles.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_ENTER_YOUR } from '../../../../../constants/language_tokens';
import { capitalizeFirstLetter } from '../../UserManagement/utils';

const TextInput = (props) => {
    const { values, touched, errors, handleChange } = useFormikContext();
    const {
        name,
        label,
        className,
        otherArguments,
        specialHandleChange,
        required = false,
        IconComponent,
        FieldButtonComponent,
        isDisabled = false,
    } = props;
    const fieldTouched = getIn(touched, name);
    const fieldError = getIn(errors, name);
    const fieldValue = getIn(values, name);
    const { t } = useTranslate();

    const memoTextInput = useMemo(() => {
        return (
            <div className={styles.textFieldBox}>
                <TextField
                    required={required}
                    value={fieldValue}
                    name={name}
                    onChange={!specialHandleChange ? handleChange : specialHandleChange(name)}
                    className={`${styles.textField} ${className}`}
                    label={label}
                    placeholder={capitalizeFirstLetter(`${t(EZLOGIC_TITLE_ENTER_YOUR)} ${label}`)}
                    size="small"
                    fullWidth
                    error={fieldTouched && Boolean(fieldError)}
                    helperText={fieldTouched && fieldError}
                    disabled={isDisabled}
                    InputProps={{
                        autoComplete: t(AUTO_COMPLETE_VALUES.NEW_PASSWORD),
                    }}
                    {...otherArguments}
                />
                {IconComponent}
                {FieldButtonComponent}
            </div>
        );
    }, [
        name,
        fieldTouched,
        fieldError,
        fieldValue,
        label,
        className,
        otherArguments,
        specialHandleChange,
        required,
        IconComponent,
        isDisabled,
    ]);

    return memoTextInput;
};

export default TextInput;
