import React from 'react';
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';

import MeshBotSelectField from '../../MeshBotSelectField/MeshBotSelectField';

import { EZLOGIC_HEADING_USERS, EZLOGIC_HINT_SELECT_USERS } from '../../../constants/language_tokens';

import styles from '../NotificationCloudBlock.module.scss';

export const NotificationCloudUsers = (props) => {
    const { selectedUsers, handleChangeUser, userData } = props;

    return (
        <div className={styles.select}>
            <MeshBotSelectField
                label={EZLOGIC_HEADING_USERS}
                selectValue={selectedUsers}
                handleChange={handleChangeUser}
                infoText={EZLOGIC_HINT_SELECT_USERS}
                selectionAttributes={{
                    renderValue: (selectedUsers) => selectedUsers.join(', '),
                    multiple: true,
                }}
            >
                {userData &&
                    userData.map((item) => (
                        <MenuItem className={styles.menuItem} key={item.PK_User} value={item.Username}>
                            <ListItemIcon>
                                <Checkbox color="primary" checked={selectedUsers.indexOf(item.Username) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={item.Username} />
                        </MenuItem>
                    ))}
            </MeshBotSelectField>
        </div>
    );
};
