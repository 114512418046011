import React from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';

import styles from './CheckBoxComponent.module.scss';

const CheckBoxComponent = ({ label, checked, onClick }) => {
    return (
        <FormControl className={styles.wrapper}>
            <FormControlLabel
                control={<Checkbox color="primary" onClick={onClick} checked={checked} />}
                label={label}
            />
        </FormControl>
    );
};

export default CheckBoxComponent;
