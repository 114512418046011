import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { Button, Card, CardActionArea, CardActions, CardContent, Tooltip } from '@material-ui/core';
import { Check, FileCopyOutlined } from '@material-ui/icons';

import { EZLOGIC_TITLE_COPIED, EZLOGIC_TITLE_COPY_LINK } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';
import { CARD_SHOW_TIME_MS, IMAGE_CARD_WIDTH } from '../../constants';
import { getCardElevation } from '../../utils';

import styles from './style.module.scss';

const ImageCard = ({ imageSrc, isSelectedImg, copyText, onClickImg, filename }) => {
    const imgRef = useRef(null);
    const { t } = useTranslate();
    const [load, setLoad] = useState(false);
    const [copied, setCopied] = useState(false);
    const [cardWidth, setCardWidth] = useState(IMAGE_CARD_WIDTH);

    const handlerLoad = () => setLoad(true);

    useEffect(() => {
        if (imgRef.current?.offsetWidth && load) {
            setCardWidth(imgRef.current?.offsetWidth);
        }
    }, [load]);

    const onCopyLink = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), CARD_SHOW_TIME_MS);
    };

    return (
        <Card elevation={getCardElevation(isSelectedImg)} style={{ maxWidth: cardWidth }} className={styles.imgCard}>
            <CardActionArea className={styles.cardActionArea} onClick={onClickImg}>
                <img
                    ref={imgRef}
                    className={load ? styles.images : styles.imgSkeleton}
                    onLoad={handlerLoad}
                    src={imageSrc}
                    alt={filename}
                />
                {isSelectedImg && (
                    <div role="imgCheckBox" className={styles.imageCheck}>
                        <Check color="primary" fontSize="large" />
                    </div>
                )}
            </CardActionArea>
            <CardContent>
                <div className={styles.cardTitle}>{filename}</div>
            </CardContent>
            <CardActions>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    open={copied}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={`${t(EZLOGIC_TITLE_COPIED)}!`}
                    placement="top-end"
                >
                    <CopyToClipboard text={copyText} onCopy={onCopyLink}>
                        <Button size="small">
                            <span className={styles.copyLinkText}>{t(EZLOGIC_TITLE_COPY_LINK)}</span>
                            <FileCopyOutlined color="primary" fontSize="medium" />
                        </Button>
                    </CopyToClipboard>
                </Tooltip>
            </CardActions>
        </Card>
    );
};

export default ImageCard;
