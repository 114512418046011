import React, { useEffect } from 'react';

import { DATA_TYPES_LIST } from '../../../../../../../constants/Variables';

import LocalVariableBooleanTypeSelect from '../LocalVariableBooleanTypeSelect';
import LocalVariableCommonTypesValueField from '../LocalVariableCommonTypesValueField';
import LocalVariableActionTypeValueBlock from '../LocalVariableActionTypeValueBlock';
import InputAndColorPicker from '../../../../../../../components/ColorInputAndColorPickers';
import VariablesTokenDataType from '../../../../../EzloExpressions/components/VariablesTokenDataType';
import LocalVariableScalableTypeValueBlock from '../LocalVariableScalableTypeValueBlock';

const LocalVariableValueField = (props) => {
    const {
        value,
        onSetVariableValue,
        selectedVariableValueType,
        selectedScalableType,
        onChangeScalableType,
        scalableType,
    } = props;

    useEffect(() => {
        if (selectedVariableValueType === DATA_TYPES_LIST.TYPE_TOKEN && !value) {
            onSetVariableValue([
                {
                    value: '',
                    text: '',
                    checked: false,
                },
            ]);
        }
    }, [selectedVariableValueType]);

    const handleChange = (e) => {
        onSetVariableValue(e.target.value);
    };

    const handleChangeActionTypeValue = (e) => {
        onSetVariableValue((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleChangeRGBTypeValue = (value) => {
        onSetVariableValue(value);
    };

    const handleChangeScalableTypeValue = (e) => {
        onSetVariableValue((prevState) => {
            return {
                ...prevState,
                [e.target.name]: isNaN(e.target.value) ? e.target.value : Number(e.target.value),
            };
        });
    };

    return (
        <>
            {(selectedVariableValueType === DATA_TYPES_LIST.TYPE_INT ||
                selectedVariableValueType === DATA_TYPES_LIST.TYPE_STRING) && (
                <LocalVariableCommonTypesValueField
                    value={value}
                    onSetVariableValue={handleChange}
                    selectedVariableValueType={selectedVariableValueType}
                />
            )}
            {selectedVariableValueType === DATA_TYPES_LIST.TYPE_BOOLEAN && (
                <LocalVariableBooleanTypeSelect value={value} onSetVariableValue={handleChange} />
            )}
            {selectedVariableValueType === DATA_TYPES_LIST.TYPE_ACTION && (
                <LocalVariableActionTypeValueBlock value={value} onSetVariableValue={handleChangeActionTypeValue} />
            )}
            {selectedVariableValueType === DATA_TYPES_LIST.TYPE_RGB && (
                <InputAndColorPicker
                    isVariablePage={true}
                    isMeshbotPage={true}
                    value={value}
                    onChangeRgbValue={handleChangeRGBTypeValue}
                />
            )}
            {selectedVariableValueType === DATA_TYPES_LIST.TYPE_TOKEN && (
                <VariablesTokenDataType code={value} isMeshbotPage={true} onChangeTokenValue={onSetVariableValue} />
            )}
            {selectedScalableType?.metadata?.scalableType === DATA_TYPES_LIST.TYPE_SCALABLE && (
                <LocalVariableScalableTypeValueBlock
                    value={value}
                    onChangeScalableType={onChangeScalableType}
                    scalableType={scalableType}
                    onHandleChangeScalableTypeValue={handleChangeScalableTypeValue}
                />
            )}
        </>
    );
};

export default LocalVariableValueField;
