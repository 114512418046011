import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';

import useFilterLabelsBySearchValue from '../../hooks/useFilterLabelsBySearchValue';
import useDialogState from '../../../../../hooks/useDialogState/useDialogState';
import { TAB } from '../../../../../constants/Devices';
import SearchField from '../SearchField';
import LabelsDropdownList from './LabelsDropdownList';
import { CreateLabelDialog } from '../LabelsManager';
import useMeshBotLabelsStateForListing from '../../hooks/useMeshBotLabelsStateForListing';
import DropdownPopperButtons from '../DropdownPopperButtons';

import styles from './styles.module.scss';

const LabelsDropdownPopper = ({ popperOpen, setPopperOpen, anchorRef }) => {
    const labelsList = useMeshBotLabelsStateForListing();
    const { searchValue, handleChangeSearch, filteredLabels } = useFilterLabelsBySearchValue(labelsList);
    const { open, toggleOpenDialog, setOpen } = useDialogState();

    const handleClickAway = () => {
        setPopperOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === TAB) {
            event.preventDefault();
            setPopperOpen(false);
        }
    };

    return (
        <>
            <Popper
                className={styles.popper}
                open={popperOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div>
                                    <SearchField value={searchValue} onChangeValue={handleChangeSearch} />

                                    <LabelsDropdownList
                                        labelsList={filteredLabels}
                                        handleListKeyDown={handleListKeyDown}
                                    />

                                    <DropdownPopperButtons toggleOpenDialog={toggleOpenDialog} />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <CreateLabelDialog open={open} closeDialog={() => setOpen(false)} />
        </>
    );
};

export default LabelsDropdownPopper;
