import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import { ARMED_SELECT_OPTIONS, ARMED_SELECT_VALUES } from '../../constants/MeshbotConstant';
import {
    generateArmedStateFieldData,
    generateComparisonValueFieldData,
    getComparingValue,
    itemStateValuesToSelect,
} from '../../containers/Ezlo/EzloMeshbot/MeshbotLocal/MeshBotLocalForm/utils';
import { useTranslate } from '../../features/languages';

const SelectItemState = ({ hasArmedProperty, selectedCapability, onDataUpdate, comparingValue, armedState }) => {
    const { t } = useTranslate();

    const handleSelectValue = (event) => onDataUpdate(generateComparisonValueFieldData(event.target.value));
    const handleArmedStateSelect = (event) => onDataUpdate(generateArmedStateFieldData(event.target.value));
    const comparisonOptions = useMemo(() => itemStateValuesToSelect(selectedCapability), [selectedCapability]);
    const selectedComparisonValue = useMemo(() => getComparingValue(comparingValue), [comparingValue]);

    return (
        <>
            {hasArmedProperty && (
                <SelectComponent
                    value={armedState || ARMED_SELECT_VALUES.ANY}
                    label={info.hints.armedState}
                    info={info.text.armedState}
                    onChange={handleArmedStateSelect}
                >
                    {ARMED_SELECT_OPTIONS.map((option, id) => {
                        return (
                            <MenuItem key={`${option.value}_${id}`} value={option.value}>
                                {t(option.label)}
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            )}

            <SelectComponent
                value={selectedComparisonValue}
                label={info.hints.value}
                info={info.text.value}
                onChange={handleSelectValue}
            >
                {comparisonOptions.map((option, id) => {
                    return (
                        <MenuItem key={`${option.value}_${id}`} value={option.value}>
                            {t(option.label)}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </>
    );
};

export default SelectItemState;
