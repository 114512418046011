import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { info } from '../../../../utils';

import styles from './LocalVariableSelect.module.scss';
import { EZLOGIC_TITLE_VARIABLE_TYPE } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

const LocalVariableSelect = (props) => {
    const { variablesList, onSetSelectedVariable, selectedVariable, selectedVariableValueType, selectedScalableType } =
        props;
    const { t } = useTranslate();

    return (
        <div>
            <SelectComponent
                value={selectedVariable}
                label={info.hints.variables}
                info={info.text.value}
                onChange={onSetSelectedVariable}
            >
                {variablesList?.map((variable, index) => {
                    return (
                        <MenuItem key={index} value={variable.name}>
                            {variable.name}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
            {selectedVariable && (
                <span className={styles.variableType}>
                    {t(EZLOGIC_TITLE_VARIABLE_TYPE)}:{' '}
                    {selectedScalableType ? selectedScalableType?.metadata?.scalableType : selectedVariableValueType}
                </span>
            )}
        </div>
    );
};

export default LocalVariableSelect;
