import React from 'react';
import './style.scss';
import PasswordField from '../PasswordField/PasswordField';
import DateField from '../DateField/DateField';
import EmailField from '../EmailField/EmailField';
import StringSelectField from '../StringSelectField/StringSelectField';
import SimpleStringField from '../SimpleStringField/SimpleStringField';

const DynamicStringField = ({ input, onChange, value, name, className }) => {
    if (input.enum && input.enum.length > 0) {
        return (
            <div className={`${className} dynamic-string-field-block__select`}>
                <StringSelectField value={value} name={name} onChange={onChange} options={input.enum} />
            </div>
        );
    }

    if (input.format === 'password') {
        return (
            <div className={`${className}`}>
                <PasswordField onChange={onChange} value={value} name={input.name} isRequired={input.required} />
            </div>
        );
    }

    if (input.format === 'date') {
        return (
            <div className={`${className}`}>
                <DateField onChange={onChange} value={value} name={input.name} isRequired={input.required} />
            </div>
        );
    }

    if (input.format === 'email') {
        return (
            <div className={`${className}`}>
                <EmailField onChange={onChange} value={value} name={input.name} isRequired={input.required} />
            </div>
        );
    }

    return (
        <div className={`${className}`}>
            <SimpleStringField onChange={onChange} value={value} name={input.name} isRequired={input.required} />
        </div>
    );
};

export default DynamicStringField;
