import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import './styles.scss';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_BUTTON_CANCEL, EZLOGIC_BUTTON_OK } from '../../constants/language_tokens';

const ConfirmCustomDialog = ({ maxWidth, open, onClose, onClick, title, children }) => {
    const { t } = useTranslate();

    return (
        <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose} className="enrollment-dialog">
            <div className="confirm-custom-dialog__wrapper">
                <DialogContent>
                    <div className="text-wrapper">
                        <div className="confirm-custom-dialog__title">{title}</div>
                        {children}
                    </div>
                </DialogContent>
                <DialogActions className="confirm-custom-dialog__actions-box">
                    <Button variant="outlined" className="confirm-custom-dialog__button" onClick={onClose}>
                        {t(EZLOGIC_BUTTON_CANCEL)}
                    </Button>
                    <Button
                        className="confirm-custom-dialog__button"
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                    >
                        {t(EZLOGIC_BUTTON_OK)}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default ConfirmCustomDialog;
