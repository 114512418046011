import { JAVASCRIPT_TYPES } from '../../../../../constants/common/js_types';

/**
 * Returns uppercase request method value
 * @param {string} requestMethodValue - original request method value
 * @returns {string} uppercase request method value: GET, POST, PUT, DELETE
 * */
export const getRequestMethodValue = (requestMethodValue) => {
    if (requestMethodValue && typeof requestMethodValue === JAVASCRIPT_TYPES.STRING) {
        return requestMethodValue.toUpperCase();
    }

    return '';
};
