import * as yup from 'yup';
import { t } from '../../helpers/language';
import * as regexp from '../../constants/Regexp';

// legacy compatible 'pass' validation
function validatePassword(message) {
    return this.test('forPassword', message, function (value) {
        const { path, createError } = this;

        if (!value || value.length < 8) {
            return createError({
                path,
                message:
                    message ??
                    t('ezlo.error.message.password_at_least') + ' 8 ' + t('ezlo.error.message.symbols_length'),
            });
        }

        if (value.length > 100) {
            return createError({
                path,
                message:
                    message ??
                    t('ezlo.error.message.password_maximum') + ' 100 ' + t('ezlo.error.message.symbols_length'),
            });
        }

        return true;
    });
}

// legacy compatible 'name' validation
function validateName(message) {
    return this.test('forName', message, function (value) {
        const { path, createError } = this;

        if (!value) {
            return createError({
                path,
                message: message ?? t`ezlo.error.message.login_should_not_empty`,
            });
        }

        return true;
    });
}

function validateEmail(message) {
    return this.test('forEmail', message, function (value) {
        const { path, createError } = this;

        if (!value) {
            return createError({
                path,
                message: message ?? t`ezlo.error.message.provide_email`,
            });
        } else {
            if (!regexp.EMAIL_REGEXP.test(value)) {
                return createError({
                    path,
                    message: message ?? t`ezlo.error.message.email_should_be_valid`,
                });
            }
        }

        return true;
    });
}

yup.addMethod(yup.string, 'validatePassword', validatePassword);
yup.addMethod(yup.string, 'validateName', validateName);
yup.addMethod(yup.string, 'validateEmail', validateEmail);

export const loginValidationSchema = yup.object({
    name: yup.string().validateName(),
    pass: yup.string().validatePassword(),
});

export const signUpValidationSchema = yup.object({
    name: yup.string().validateName(),
    pass: yup.string().validatePassword(),
    email: yup.string().validateEmail(),
});
export const forgotPasswordValidationSchema = yup.object({
    email: yup.string().validateEmail(),
});
