import { useSelector } from 'react-redux';
import { selectorOfCurrentControllerData } from '../../EzloMeshbot/utils';
import { useMemo } from 'react';

export const useVirtualDevicesPageLoading = () => {
    const { serial, controllerData } = useSelector(selectorOfCurrentControllerData);
    const { lineLoading } = useSelector(({ app }) => app);

    return useMemo(() => {
        return !serial || !controllerData || !controllerData?.isEzloData || lineLoading;
    }, [serial, controllerData, lineLoading]);
};
