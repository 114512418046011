import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import useTriggerSelectCloud from '../../../../../hooks/useTriggerSelectCloud';
import SelectComparatorCloud from '../SelectComparatorCloud';
import CloudVariableSelectForCloudMeshBot from './components/CloudVariableSelectForCloudMeshBot';

import { ON_CHANGE, MESHBOT_NODE_TYPES } from '../../../../../constants/MeshbotConstant';
import { buildCloudVariableTriggerBlockStructure } from './utils';
import { DynamicComparisonFields } from './components/DynamicComparisonFields';

/**
 * Component for the selected node cloud variables in cloud meshbot triggers
 */
const CloudVariableForTrigger = ({
    updateCloudVariablesTrigger,
    id: ruleCloudTriggerId,
    updateCloudVariablesTriggerBlocks,
    ruleCloudTriggers,
    parentTriggerOperator,
}) => {
    const integrations = useSelector((state) => state.main.integrationList.integrations);
    const ruleCloudTrigger = useTriggerSelectCloud(ruleCloudTriggerId);

    const {
        selectedAbstract,
        selectedComparator,
        comparingValue,
        selectedVariable,
        selectedValueType,
        selectedVariableCompared,
        variableValueType,
    } = ruleCloudTrigger;
    const showSelectValueToCompare = selectedAbstract?.uuid && selectedComparator;
    const showSelectValueType = selectedAbstract?.uuid && selectedValueType;
    const isIntegrationsVisible = Boolean(integrations?.length);

    useEffect(() => {
        updateCloudVariablesTriggerBlocks(
            buildCloudVariableTriggerBlockStructure(ruleCloudTrigger),
            MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
            ruleCloudTriggerId,
        );
    }, [
        comparingValue,
        selectedComparator,
        selectedVariable,
        selectedVariableCompared,
        selectedValueType,
        variableValueType,
    ]);

    const handleCloudVariableTriggerUpdate = (data) => {
        updateCloudVariablesTrigger(data, MESHBOT_NODE_TYPES.CLOUD_VARIABLES, ruleCloudTriggerId);
    };

    return (
        <>
            {isIntegrationsVisible ? (
                <CloudVariableSelectForCloudMeshBot
                    ruleCloudTriggerId={ruleCloudTriggerId}
                    ruleCloudTrigger={ruleCloudTrigger}
                    onUpdateVariableValueDataType={handleCloudVariableTriggerUpdate}
                />
            ) : (
                <CircularProgress />
            )}

            {selectedVariable && (
                <SelectComparatorCloud
                    selectedComparator={selectedComparator}
                    parentTriggerOperator={parentTriggerOperator}
                    onDataUpdate={handleCloudVariableTriggerUpdate}
                    ruleCloudTriggers={ruleCloudTriggers}
                />
            )}
            {selectedComparator !== ON_CHANGE && (
                <DynamicComparisonFields
                    showSelectValueToCompare={showSelectValueToCompare}
                    showSelectValueType={showSelectValueType}
                    handleCloudVariableTriggerUpdate={handleCloudVariableTriggerUpdate}
                    ruleCloudTriggerId={ruleCloudTriggerId}
                    ruleCloudTrigger={ruleCloudTrigger}
                />
            )}
        </>
    );
};

export { CloudVariableForTrigger };
