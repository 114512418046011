import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useRouteMatch } from 'react-router-dom';
import classes from 'classnames';
import { removePlugin, isPluginDownloadedFromMarketplace } from '../../utils';
import { getInstalledPlugin, getInstalledPluginFromRow, getPluginsInstalled } from '../../utils';
import {
    PRIVATE,
    REMOVE,
    PRIVATE_GENERATOR,
    PUBLISH_GENERATOR,
    MARKETPLACE_GENERATOR,
    CODE,
    WIFI_PLUGIN_MODEL,
    PUBLISH,
    GetRemoveContent,
    PLUGIN_ALREADY_EXIST,
} from '../../../../../constants/Plugins';
import ConfirmationContent from './ConfirmationContent/ConfirmationContent';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_CANCEL, EZLOGIC_BUTTON_CONFIRM } from '../../../../../constants/language_tokens';
import { toast } from 'react-toastify';
import { TOAST_TYPE } from '../../../../../components/Toast';
import { COPY_AND_UPGRADE } from '../../constants/constants';
import { PluginActions } from '../../../../../actions';

const ConfirmationAction = (props) => {
    const [countInstalled, setCountInstalled] = useState([]);
    const { typeList, pluginData, currentAction, listInstalledPlugins, onCancel, onCancelUpgrade } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const { marketplacePlugins, privatePlugins } = useSelector((state) => state.plugins);
    const { url } = useRouteMatch();
    const sendRequest = () => {
        const { row, id } = pluginData;
        switch (currentAction) {
            case 'remove':
                const pluginsList = getPluginsInstalled(listInstalledPlugins);
                const params = { uuid: pluginData.row.id, delete: 1, type: CODE };
                if (typeList === PRIVATE_GENERATOR || typeList === PUBLISH_GENERATOR) {
                    params.type = WIFI_PLUGIN_MODEL;
                }
                const type = url.split('/').pop();

                if (getInstalledPluginFromRow({ pluginsList, plugin: pluginData }) && typeList === PRIVATE) {
                    dispatch(PluginActions.deleteDashboard(params, type, t));
                    removePlugin(listInstalledPlugins, pluginData, dispatch, t);
                } else {
                    dispatch(PluginActions.deleteDashboard(params, type, t));
                }

                break;
            case 'download':
                dispatch(PluginActions.downloadPlugin(pluginData.row.downloadUrl));
                break;
            case 'publish':
                const typeCode = { type: CODE, dashboard_uuid: id };
                const typeWifi = {
                    type: WIFI_PLUGIN_MODEL,
                    name: row?.nameId,
                    data: row?.data,
                };

                dispatch(PluginActions.setPublishPlugin(typeList === PRIVATE_GENERATOR ? typeWifi : typeCode, t));
                break;
            case 'copy':
                if (
                    isPluginDownloadedFromMarketplace({
                        marketplacePlugins,
                        hash_md5: pluginData.archive_hash_md5FromMarketPlace,
                        privatePlugins,
                        pluginData,
                    })
                ) {
                    toast(PLUGIN_ALREADY_EXIST, { type: TOAST_TYPE.ERROR });
                } else {
                    dispatch(
                        PluginActions.setPublishToPrivate(
                            typeList === MARKETPLACE_GENERATOR ? WIFI_PLUGIN_MODEL : CODE,
                            pluginData.id,
                            t,
                        ),
                    );
                }
                break;
            case 'copy_and_upgrade':
                dispatch(PluginActions.copyAndUpgradePlugin({ pluginData, marketplacePlugins, privatePlugins }, t));

                break;
        }

        onCancel();
    };

    useEffect(() => {
        if (typeList === PRIVATE && currentAction === REMOVE) {
            detectInstallPlugin();
        }
    }, []);

    const detectInstallPlugin = () => {
        setCountInstalled(getInstalledPlugin(listInstalledPlugins, pluginData));
    };

    const className = classes('modal-plugin__confirmation', {
        publish: typeList === PRIVATE_GENERATOR && currentAction === PUBLISH,
    });

    const removeContent = GetRemoveContent(countInstalled.length, t);

    const handleCancel = () => {
        if (currentAction === COPY_AND_UPGRADE) {
            onCancelUpgrade();
        } else {
            onCancel();
        }
    };

    return (
        <div className={className}>
            {countInstalled.length > 0 ? (
                removeContent
            ) : (
                <ConfirmationContent typeList={typeList} currentAction={currentAction} pluginData={pluginData} />
            )}
            <div className="modal-plugin__confirmation-buttons">
                <Button variant="outlined" onClick={handleCancel}>
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </Button>
                <Button variant="contained" color="primary" onClick={sendRequest}>
                    {t(EZLOGIC_BUTTON_CONFIRM)}
                </Button>
            </div>
        </div>
    );
};

ConfirmationAction.propTypes = {
    typeList: PropTypes.string,
    pluginData: PropTypes.object,
    listInstalledPlugins: PropTypes.object,
    currentAction: PropTypes.string,
    PluginActions: PropTypes.object,
    onCancel: PropTypes.func,
};

export default ConfirmationAction;
