import React from 'react';

const RestoreIcon = (props) => (
    <svg {...props} viewBox="0 0 390 234" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M194.748 233.805L319.663 233.805L319.663 163.809L194.748 163.809L194.748 233.805Z" fill="#1F2581" />
        <path d="M69.937 233.805L194.853 233.805L194.853 163.809L69.937 163.809L69.937 233.805Z" fill="black" />
        <path
            // eslint-disable-next-line
            d="M319.716 233.928C358.312 233.928 389.6 204.631 389.6 168.492C389.6 132.353 358.312 103.056 319.716 103.056C281.119 103.056 249.831 132.353 249.831 168.492C249.831 204.631 281.119 233.928 319.716 233.928Z"
            fill="#50C1D6"
        />
        <path
            // eslint-disable-next-line
            d="M69.8845 233.928C108.481 233.928 139.769 204.631 139.769 168.492C139.769 132.353 108.481 103.056 69.8845 103.056C31.2884 103.056 0 132.353 0 168.492C0 204.631 31.2884 233.928 69.8845 233.928Z"
            fill="#FE5C15"
        />
        <path
            // eslint-disable-next-line
            d="M194.8 233.928C263.789 233.928 319.715 181.561 319.715 116.964C319.715 52.3666 263.789 0 194.8 0C125.811 0 69.8843 52.3666 69.8843 116.964C69.8843 181.561 125.811 233.928 194.8 233.928Z"
            fill="#3E46AE"
        />
        <path
            // eslint-disable-next-line
            d="M319.716 116.964C319.716 112.176 319.472 107.616 318.742 102.828C280.512 103.284 249.831 132.468 249.831 168.264C249.831 184.908 256.649 200.184 267.607 211.812C299.018 190.836 319.716 156.18 319.716 116.964Z"
            fill="#1F2581"
        />
        <path
            // eslint-disable-next-line
            d="M70.8583 103.056C70.3713 107.616 69.8843 112.404 69.8843 117.192C69.8843 156.408 90.5818 191.064 121.993 212.268C132.951 200.64 139.769 185.364 139.769 168.72C139.769 132.468 109.088 103.512 70.8583 103.056Z"
            fill="black"
        />
        <path d="M144.396 128.136L193.826 186.048L243.013 128.136H144.396Z" fill="white" />
        <path d="M212.332 47.4241H175.32V128.136H212.332V47.4241Z" fill="white" />
    </svg>
);

export default RestoreIcon;
