import React from 'react';
import { EZLOGIC_PLACEHOLDER_ENTER_NUMBER } from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';
import { InputMaterial } from '../index';

/**
 * Create input number
 * @param { object } props
 * @returns { node } Input number block
 * @example
 * <InputNumberBlock
 *      value="example"
 *      label="example"
 *      step="example"
 *      onChange={func}
 *      onClick={func}
 * />
 */

const InputNumberBlock = (props) => {
    const { label, step, onChange, value, InputProps, endAdornment, xsSmall, small, min } = props;
    const { t } = useTranslate();

    return (
        <InputMaterial
            label={t(label)}
            placeholder={t(EZLOGIC_PLACEHOLDER_ENTER_NUMBER)}
            type="number"
            value={value}
            step={step}
            onChange={onChange}
            xsSmall={xsSmall}
            small={small}
            min={min}
            InputProps={{
                ...endAdornment,
                ...InputProps,
            }}
        />
    );
};

export default InputNumberBlock;
