import React from 'react';
import PropTypes from 'prop-types';

import SwitchControllers from '../../../../../components/SwitchControllers';
import { Header as HeaderWrapper } from '../../../../../components';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_MY_PRIVATE_SCRIPT } from '../../../../../constants/language_tokens';

import { scriptHeader, title, titleEdit } from './EzloLuaScriptsHeader.module.scss';

const Header = (props) => {
    const { history } = props;
    const isEdit = history.location.pathname.split('/').includes('edit');
    const { t } = useTranslate();

    return (
        <div className={scriptHeader}>
            <HeaderWrapper>
                <h2 className={isEdit ? titleEdit : title}>{t(EZLOGIC_BUTTON_MY_PRIVATE_SCRIPT)}</h2>
                {!isEdit && <SwitchControllers />}
            </HeaderWrapper>
        </div>
    );
};

Header.propTypes = {
    isActivePage: PropTypes.string,
    history: PropTypes.object,
    onHandlerRouteList: PropTypes.func,
};

export default Header;
