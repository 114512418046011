import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { toast, TOAST_TYPE } from '../../../components/Toast';
import DeviceHeader from './components/devicesHeader';
import DeviceModal from './components/deviceModal';
import { Header } from '../../../components';
import {
    getCheckedDevices,
    getSeparateChildDevices,
    getIdFromUncheckedDevices,
    getParentDevice,
    getUncheckedDevices,
    isDeviceHasParentRoomFalse,
    filterDevicesByName,
} from './utils';
import { CONFIRMATION, REMOVE_DEVICE, MOVING_CHILD_DEVICE } from '../../../constants/Devices';
import DeviceRoomManagement from './components/DeviceRoomManagement';
import ControllerDevicesGroupList from './components/ControllerDevicesGroupList';
import BoxWithLinearProgress from '../../../components/BoxWithLinearProgress';
import actions from '../../../actions/DeviceActions';

import styles from './ezloDevices.module.scss';
import {
    EZLOGIC_HEADING_DEVICES,
    EZLOGIC_TOAST_ALL_DEVICES_REMOVE,
    EZLOGIC_TOAST_DEVICE_DATA_UPDATED,
    EZLOGIC_TOAST_DEVICE_ERROR_WHILE_SAVING,
    EZLOGIC_TOAST_REQUEST_PROCEED,
} from '../../../constants/language_tokens';
import { useTranslate } from '../../../features/languages';

const EzloDevices = (props) => {
    const [roomList, setRoomList] = useState([]);
    const [currentFunction, setCurrentFunction] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [modalRoomChangeShow, isModalRoomChangeShow] = useState(false);
    const [childDevicesList, setChildDevicesList] = useState([]);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [parentDevice, setParentDevice] = useState({});
    const [chosenRoomId, setChosenRoomId] = useState('');
    const [chosenDeviceId, setChosenDeviceId] = useState('');
    const {
        devices,
        rooms,
        errors,
        isShowModalDevice,
        MainAction,
        DeviceActions,
        EzloActions,
        devicesContainer,
        isConnected,
    } = props;

    const dispatch = useDispatch();
    const serial = useSelector((state) => state.ezlo.serial);
    const [filteredDevices, setFilteredDevices] = useState({});
    const { t } = useTranslate();
    useEffect(() => {
        if (errors) {
            toast(errors.message, { type: TOAST_TYPE.ERROR });
            DeviceActions.clearErrors();
        }
    }, [errors]);

    useEffect(() => {
        if (devices && currentFunction?.setting?._id) {
            const getSection = (data) => data?.settings?.find((item) => item._id === currentFunction.setting._id);
            const [device] = devices.filter((item) => getSection(item));
            setCurrentFunction({ device: device, setting: getSection(device) });
        }

        if (rooms !== roomList) {
            setRoomList(rooms);
        }
    }, [devices, rooms]);

    useEffect(() => {
        const result = filterDevicesByName(searchValue, devicesContainer);
        setFilteredDevices(result);
    }, [devicesContainer]);

    useEffect(() => {
        const result = filterDevicesByName(searchValue, devicesContainer);
        setFilteredDevices(result);
    }, [searchValue]);

    const handleDeviceNameChange = useCallback((deviceId, name, serial) => {
        const { setDeviceName } = EzloActions;
        setDeviceName(deviceId, name, serial);
    }, []);

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value);
    };

    const onHandlerSelectFunction = useCallback((value) => {
        setCurrentFunction(value);
        MainAction.ShowDeviceFunction();
    }, []);

    const onSaveSettings = useCallback(
        (id, value) => {
            DeviceActions.setDeviceSetting(currentFunction.device.serial, id, value);

            setTimeout(() => {
                MainAction.hideModal();
            }, 1000);
        },
        [currentFunction],
    );

    const subscribeBroadcast = useCallback((serial, name, id) => {
        DeviceActions.subscribeDeviceSettingUpdate(
            serial,
            name,
            id ? initialNotification(serial, name, id) : initialNotificationRemove(serial, name),
        );
    }, []);

    const handlerRefreshDevice = useCallback((serial, idDev) => {
        const params = { _id: idDev, check: 'value' };
        DeviceActions.deviceCheck(serial, params);
    }, []);

    const initialNotification = (serial, name, id) => {
        return ({ result }) => {
            if (result.status === 'failed') {
                DeviceActions.unSubscribeDeviceSettingUpdate(serial, name);
                toast(t(EZLOGIC_TOAST_DEVICE_ERROR_WHILE_SAVING), { type: TOAST_TYPE.ERROR });
            } else {
                DeviceActions.getSettingDevice(serial, { ids: [id] });
                if (result.hasOwnProperty('status')) {
                    if (result.status === 'synced') {
                        DeviceActions.unSubscribeDeviceSettingUpdate(serial, name);
                        toast(t(EZLOGIC_TOAST_DEVICE_DATA_UPDATED), { type: TOAST_TYPE.SUCCESS });
                    } else {
                        toast(t(EZLOGIC_TOAST_REQUEST_PROCEED), { type: TOAST_TYPE.INFO });
                    }
                } else {
                    DeviceActions.unSubscribeDeviceSettingUpdate(serial, name);
                    toast(t(EZLOGIC_TOAST_DEVICE_DATA_UPDATED), { type: TOAST_TYPE.SUCCESS });
                }
            }
        };
    };

    const initialNotificationRemove = (serial, name) => {
        return () => {
            DeviceActions.getDevicesList(serial);
            DeviceActions.unSubscribeDeviceSettingUpdate(serial, name);
            toast(t(EZLOGIC_TOAST_ALL_DEVICES_REMOVE), { type: TOAST_TYPE.SUCCESS });
        };
    };

    const hideModal = useCallback((data, name) => {
        MainAction.hideModal();
        if (data && currentFunction !== CONFIRMATION) {
            DeviceActions.unSubscribeDeviceSettingUpdate(data.device?.serial, name);
        }
    }, []);

    const handleChangeDevicePlace = useCallback(
        (serial, deviceId, roomId, device) => {
            if (isDeviceHasParentRoomFalse(getSeparateChildDevices(devices, deviceId))) {
                setChildDevicesList(getSeparateChildDevices(devices, deviceId));
                isModalRoomChangeShow(true);
                setParentDevice(getParentDevice(devices, deviceId));
                setChosenRoomId(roomId);
                setChosenDeviceId(deviceId);
                setCheckedOptions(getCheckedDevices(getSeparateChildDevices(devices, deviceId), roomId));
            } else {
                DeviceActions.changeDevicePlace(serial, deviceId, roomId);
                setCurrentFunction({ setting: { valueType: MOVING_CHILD_DEVICE }, device });
            }
        },
        [devices],
    );

    const hideModalRoomChange = () => {
        isModalRoomChangeShow((prevState) => !prevState);
    };

    const handleChangeChildrenDeviceRoom = (e) => {
        setCheckedOptions(e);
    };

    const onSave = () => {
        dispatch(
            actions.replaceChildDevices(
                serial,
                chosenDeviceId,
                chosenRoomId,
                getIdFromUncheckedDevices(getUncheckedDevices(childDevicesList, checkedOptions)),
            ),
        );
        hideModalRoomChange();
    };

    return (
        <BoxWithLinearProgress isLinearProgress={!isConnected}>
            <Header headerClassName={styles.headerDevices}>
                <h1 className={styles.title}>{t(EZLOGIC_HEADING_DEVICES)}</h1>
            </Header>
            <div className={styles.devices}>
                <DeviceHeader value={searchValue} onSearchValueChange={handleSearchValueChange} />
                <div className={styles.devicesList}>
                    <ControllerDevicesGroupList
                        roomList={roomList}
                        onNameChange={handleDeviceNameChange}
                        onHandlerSelectFunction={onHandlerSelectFunction}
                        onHandlerRefreshDevice={handlerRefreshDevice}
                        onSetCurrentFunction={setCurrentFunction}
                        onChangeDevicePlace={handleChangeDevicePlace}
                        filteredDevices={filteredDevices}
                    />
                </div>

                {isShowModalDevice && currentFunction?.setting !== REMOVE_DEVICE && (
                    <DeviceModal
                        isModalVisible={isShowModalDevice}
                        onOk={MainAction.hideModal}
                        onCancel={hideModal}
                        currentFunction={currentFunction}
                        DeviceActions={DeviceActions}
                        onCreate={onSaveSettings}
                        onSubscribeBroadcast={subscribeBroadcast}
                    />
                )}
                {modalRoomChangeShow && (
                    <DeviceRoomManagement
                        isModalVisible={modalRoomChangeShow}
                        onCancel={hideModalRoomChange}
                        childDevices={childDevicesList}
                        onChange={handleChangeChildrenDeviceRoom}
                        checkedOptions={checkedOptions}
                        parentDevice={parentDevice}
                        onSave={onSave}
                        roomList={roomList}
                    />
                )}
            </div>
        </BoxWithLinearProgress>
    );
};

export default EzloDevices;

EzloDevices.propTypes = {
    devices: PropTypes.array,
    rooms: PropTypes.array,
    isShowModalDevice: PropTypes.bool,
    errors: PropTypes.object,
    MainAction: PropTypes.object,
    DeviceActions: PropTypes.object,
    EzloActions: PropTypes.object,
    devicesContainer: PropTypes.object,
};
