import React from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';

import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';

import { INPUT_PLACEHOLDER } from '../../LanguageTokensConstants';

import { deviceGroupNameWrapper } from './DeviceGroupNameInput.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_GROUP_NAME } from '../../../../../constants/language_tokens';

const DeviceGroupNameInput = (props) => {
    const { deviceGroupName } = props;
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const handleDeviceGroupNameChange = (e) => {
        const { value } = e.target;
        dispatch(DeviceGroupsActions.setDeviceGroupName(value));
    };

    return (
        <div className={deviceGroupNameWrapper}>
            <h2>{t(EZLOGIC_TITLE_GROUP_NAME)}</h2>
            <TextField
                variant="outlined"
                size="small"
                placeholder={t(INPUT_PLACEHOLDER)}
                value={deviceGroupName ?? ''}
                onChange={handleDeviceGroupNameChange}
            />
        </div>
    );
};

export default DeviceGroupNameInput;
