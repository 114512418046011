import { TEST_PLUGIN_ID } from './VirtualDeviceConstant';

export const CREATE_VIRTUAL_DEVICE = {
    METHOD: 'hub.extensions.plugin.run',
    SCRIPT: 'HUB:test_plugin/scripts/generate_devices',
};
export const INSTALL_TEST_PLUGIN = {
    METHOD: 'hub.software.update.execute',
    BROADCAST_NAME: 'hub.software.update.progress',
};
export const OPERATIONS = {
    INSTALL: 'install',
};

export const TYPES = {
    PLUGIN: 'plugin',
};

export const BROADCAST_STATUSES = {
    FINISHED: 'finished',
};
export const GET_HUB_UPDATE_PLAN = {
    METHOD: 'hub.software.update.plan',
    PARAMS: {
        operation: OPERATIONS.INSTALL,
        type: TYPES.PLUGIN,
        id: TEST_PLUGIN_ID,
    },
    BROADCAST_NAME: 'hub.software.update.actions',
};
