import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import ImageContainer from '../ImageCard';
import {
    EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_ON_THIS_PAGE_YET,
    EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_YET,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';
import { mediaStoreSelector } from '../../utils';

import styles from './styles.module.scss';

const GalleryBody = ({ imagesList }) => {
    const { isLoading, isEndPage } = useSelector(mediaStoreSelector);
    const { t } = useTranslate();

    if (isEmpty(imagesList) && !isLoading) {
        const emptyStorageTextKey = isEndPage
            ? EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_ON_THIS_PAGE_YET
            : EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_YET;

        return <div className={styles.emptyMediaStore}>{t(emptyStorageTextKey)}</div>;
    }

    return (
        <div className={styles.galleryBox}>
            {imagesList.map((imageData) => (
                <ImageContainer key={imageData.uuid} imageData={imageData} />
            ))}
        </div>
    );
};

export default GalleryBody;
