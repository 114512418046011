import { EXPRESSIONS_TYPE, RGB_COLORS } from '../../../../../constants/Expressions';
import {
    INDEX_SELECTED_BLOCKS_ELEMENT,
    ZERO_INT,
    INDEX_OF_ONE,
    BETWEEN,
    NOT_BETWEEN,
} from '../../../../../constants/MeshbotConstant';
import { DATA_TYPES_LIST, OBJECT_TYPE } from '../../../../../constants/Variables';

/**
 * Generates expression comparison blcoks structure
 * @param {string} expressionName current expression name for comapare
 * @param {array} expressionList list of existing expressions
 * @param {string} comparator comparator
 * @param {string} comparedValue type of compared value
 * @param {any} comparingValue value to compare
 */

export const generateExpressionComparisonBlock = (
    isExpressionComparison,
    expressionName,
    expressionList,
    comparator,
    comparedValue,
    comparingValue,
) => {
    const selectedExpressionType = typeof findSelectedExpression(expressionName, expressionList)?.value;
    const selectedExpressionValue = findSelectedExpression(expressionName, expressionList);
    const isRangeComparators = comparator && (comparator === BETWEEN || comparator === NOT_BETWEEN);

    return [
        {
            blockType: 'when',
            blockMeta: {
                comparedValue: comparedValue,
                isExpressionComparison: isExpressionComparison,
                expressionList: expressionList,
            },
            blockOptions: {
                method: {
                    name: isRangeComparators ? 'compareNumberRange' : 'compareValues',
                    args: {
                        expression: 'expression',
                        ...(!isRangeComparators && { valueType: 'valueType' }),
                        comparator: 'comparator',
                        ...(!isRangeComparators && { value: 'value' }),
                        ...(isRangeComparators && { startValue: 'startValue' }),
                        ...(isRangeComparators && { endValue: 'endValue' }),
                    },
                },
            },
            fields: [
                {
                    name: 'expression',
                    type: 'expression',
                    value: expressionName,
                },
                isRangeComparators
                    ? {
                          name: 'startValue',
                          type: convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue),
                          value: comparingValue?.startValue,
                      }
                    : {
                          name: 'valueType',
                          type: 'string',
                          value: convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue),
                      },
                {
                    name: 'comparator',
                    type: 'string',
                    value: comparator,
                },
                isRangeComparators
                    ? {
                          name: 'endValue',
                          type: convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue),
                          value: comparingValue?.endValue,
                      }
                    : {
                          name: 'value',
                          type: getExpressionComparedValueForBlockStructure(
                              comparedValue,
                              convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue),
                          ),
                          value: convertExpressionValueToDataType(
                              convertExpressionTypeToDataType(selectedExpressionType, selectedExpressionValue),
                              comparingValue,
                              comparedValue,
                          ),
                      },
            ],
        },
    ];
};

/**
 *
 * @param {string} expression current expression name
 * @param {array} expressionList list of existing variables
 * @returns {object} object with selected expression
 */

export const findSelectedExpression = (expression, expressionList = []) => {
    return expressionList?.find((el) => el?.name === expression);
};

/**
 *
 * @param {object} currentItem current scenes item
 * @param {string} fieldValue field name to search
 * @returns {string} returns value  of found field
 */

export const getExpressionComparisonInitialValuesByFieldName = (currentItem, fieldValue) => {
    return currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find((field) => field.name === fieldValue)
        ?.value;
};

/**
 *
 * @param {object} currentItem current scenes item
 * @param {string} fieldValue field type to search
 * @returns {string} returns value of found field
 */

export const getExpressionComparisonInitialValuesByFieldType = (currentItem, fieldType) => {
    return currentItem?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find((field) => field.type === fieldType)?.value;
};

/**
 *
 * @param {string} expressionType
 * @returns returns converted data types name
 */

export const convertExpressionTypeToDataType = (expressionType, expressionValue) => {
    switch (expressionType) {
        case 'number':
            if (expressionValue && expressionValue?.value % INDEX_OF_ONE !== ZERO_INT) {
                return DATA_TYPES_LIST.TYPE_FLOAT;
            }

            return DATA_TYPES_LIST.TYPE_INT;
        case 'boolean':
            return DATA_TYPES_LIST.TYPE_BOOLEAN;
        case 'object':
            return DATA_TYPES_LIST.TYPE_RGB;
        default:
            return expressionType;
    }
};

const getExpressionComparedValueForBlockStructure = (comparedValue, comparedType) => {
    if (comparedValue === EXPRESSIONS_TYPE.EXPRESSION || comparedValue === EXPRESSIONS_TYPE.VARIABLE) {
        return EXPRESSIONS_TYPE.EXPRESSION;
    }

    return comparedType;
};

/**
 *
 * @param {string} expressionType data type of current expression value
 * @param {any} expressionValue current expression value
 * @param {string} comparedValue type of compared value
 * @returns converted value to data type
 */

const convertExpressionValueToDataType = (expressionType, expressionValue, comparedValue) => {
    if (comparedValue === EXPRESSIONS_TYPE.EXPRESSION || comparedValue === EXPRESSIONS_TYPE.VARIABLE) {
        return expressionValue;
    }

    if (!expressionValue) {
        return undefined;
    }

    switch (expressionType) {
        case DATA_TYPES_LIST.TYPE_INT:
            return Number(parseInt(expressionValue));
        case DATA_TYPES_LIST.TYPE_FLOAT:
            return Number(expressionValue);
        case DATA_TYPES_LIST.TYPE_STRING:
            return String(expressionValue);
        case DATA_TYPES_LIST.TYPE_BOOLEAN:
            return expressionValue && JSON.parse(expressionValue);
        default:
            return expressionValue;
    }
};

/**
 *
 * @param {object} trigger current trigger
 * @returns {boolean} if current trigger exist expression comparison options
 */

export const isExpressionComparisonTrigger = (trigger) => {
    return !!trigger?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockOptions?.method?.args?.expression;
};

/**
 * Function for find block options in trigger
 * @param {object} currentTrigger currentTrigger
 * @returns {object} current trigger blcokOptions object
 */

export const getExpressionComparisonBlockOptions = (currentTrigger) => {
    return currentTrigger?.fields?.find((field) => field?.value)?.value?.blockOptions;
};

/**
 * Function for checking is supported expression value type
 * @param {object} expressionValue expression value to check
 * @returns {boolean} returns true or false depending on support value type
 */

export const isSupportedExpressionValueType = (expressionValue) => {
    switch (typeof expressionValue) {
        case OBJECT_TYPE:
            if (
                expressionValue &&
                Object.keys(expressionValue).includes(RGB_COLORS.RED) &&
                Object.keys(expressionValue).includes(RGB_COLORS.GREEN) &&
                Object.keys(expressionValue).includes(RGB_COLORS.BLUE)
            ) {
                return true;
            }

            return false;

        default:
            return true;
    }
};

/**
 * Function for find block meta in trigger
 * @param {object} currentTrigger currentTrigger
 * @returns {object} current trigger blockMeta object
 */

export const getExpressionComparisonBlockMeta = (currentTrigger) => {
    return currentTrigger?.fields?.find((field) => field?.value)?.value?.blockMeta;
};

/**
 * Function for checking is suported variable type
 * @param {string} valueType type of variable
 * @returns {boolean} returns true or false depending on supported value type
 */

export const isSupportedVariableValueType = (valueType) => {
    switch (valueType) {
        case DATA_TYPES_LIST.TYPE_INT:
        case DATA_TYPES_LIST.TYPE_FLOAT:
        case DATA_TYPES_LIST.TYPE_STRING:
        case DATA_TYPES_LIST.TYPE_BOOLEAN:
        case DATA_TYPES_LIST.TYPE_RGB:
            return true;
        default:
            return false;
    }
};
