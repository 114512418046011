import React from 'react';
import hash from '../../../constants/uniqueHash';
import IconButton from '@material-ui/core/IconButton';
import {
    Delete,
    CloudDownloadOutlined,
    InfoOutlined,
    FileCopy,
    Edit,
    Public,
    AddCircleOutline,
} from '@material-ui/icons';
import {
    WIFI_DEVICE_GENERATOR,
    PRIVATE_GENERATOR,
    PUBLISH_GENERATOR,
    MARKETPLACE_GENERATOR,
    WIFI,
    EDGE,
    ENUM,
    VALVE,
    UPGRADE,
    TEMPLATE_INFO,
} from '../../../constants/Plugins';
import _ from 'lodash';
import * as generator from '../../../constants/DevicePluginGenerator';
import { TYPE_ERROR } from '../../../constants/MeshbotConstant';
import { formatNameWithUnderscores } from '../../../helpers/helpersMeshBot';
import { JAVASCRIPT_TYPES } from '../../../constants/common/js_types';
import { EZLO_PLUGINS_BUILD_PLUGIN_DOWNLOAD_URL_INVALID_PARAMS } from './constants/errors';
import { API_CLOUD_EZLO_DOWNLOAD_PLUGIN_URL } from '../../../constants/URLs';
import { DASHBOARD_FILE_KEY_PROPERTY_NAME } from './constants/constants';
import PluginVersionCell from './PluginEdge/PluginsPrivate/PluginVersionCell/PluginVersionCell';
import { checkVersionSupport } from '../EzloMeshbot/utils';
import CountInstalledPlugin from './components/CountInstalledPlugin';
import CountInstalledDevices from './components/CountInstalledDevices';
import CountInstalledIPDevices from './components/CountInstalledIPDevices';
import {
    EZLOGIC_TITLE_PLUGIN_NAME,
    EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_PRIVATE_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_LIST_PUBLISH_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PRIVATE_BTN_TITLE,
    EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PUBLISH_BTN_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
    EZLOGIC_BUTTON_CREATE_DEVICE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
    EZLOGIC_HEADING_DEVICES,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DOWNLOAD_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_INFO_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_IP_DEVICE_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_MANAGE_INSTALLATION_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_PUBLISH_TITLE,
    EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_STATUS_TITLE,
    EZLOGIC_TITLE_VERSION,
    EZLOGIC_BUTTON_EDIT,
    EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_UPDATED,
    EZLOGIC_TITLE_CONTROLLER_SUCCESSFULLY_UPDATED,
    EZLOGIC_TITLE_INFORMATION,
} from '../../../constants/language_tokens';
import { PluginActions } from '../../../actions';
import { PLUGIN, TEMPLATE } from '../../../constants/Integrations';
import { isFileNameValid } from '../../../features/MediaStoragePage/utils';

/**
 * Remove empty fields in object
 * @param {object} data - object fields for new device
 * @returns {object} returned object with full fields
 * @example
 * removeEmptyFields(data)
 * */
export const removeEmptyFields = (data) => {
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
        return {};
    }

    return Object.entries(data).reduce((acc, [field, value]) => {
        if (!value) {
            return acc;
        }

        return { ...acc, [field]: isNaN(value) ? value : Number(value) };
    }, {});
};

/**
 * Remove empty fields in object
 * @param {array} configureInputs - array inputs
 * @param {object} inputs - object fields for new device
 * @returns {object} returned object fields with errors
 * @example
 * validateRequiredFields(data)
 * */
export const validateRequiredFields = (configureInputs, inputs) => {
    if (!configureInputs || !inputs || !Array.isArray(configureInputs) || Array.isArray(inputs)) {
        return {};
    }

    return configureInputs.reduce((acc, input) => {
        if (!inputs[input.name] && input.required) {
            return { ...acc, [input.name]: true };
        }

        return acc;
    }, {});
};

/**
 * Get list data for buttons tabs
 * @param {string} page - current page
 * @returns {array} returned array objects for  buttons tabs
 * @example
 * getListTabs('wifi')
 * */
export const getListTabs = (page) => {
    switch (page) {
        case WIFI:
            return listTabsWifiGenerator;
        case EDGE:
            return listButtons;
        default:
            return defaultListButtons;
    }
};

/**
 * Default list buttons for plugin
 * @returns {array} array of rows
 * */
export const defaultListButtons = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE, type: 'disabled' },
];

/**
 * List buttons for plugin edge
 * @returns {array} array of rows
 * */
export const listButtons = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_PRIVATE_BTN_TITLE, type: 'private' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_PUBLISH_BTN_TITLE, type: 'publish' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE, type: 'market' },
];

/**
 * List buttons for plugin wifi generator
 * @returns {array} array of rows
 * */
export const listTabsWifiGenerator = [
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PRIVATE_BTN_TITLE, type: 'private' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PUBLISH_BTN_TITLE, type: 'publish' },
    { id: hash(), name: EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE, type: 'market' },
];

const isBuildPluginDownloadUrlParamsValid = (file) => {
    return file && typeof file === JAVASCRIPT_TYPES.STRING;
};

const validateBuildPluginDownloadUrlParams = (file) => {
    if (isBuildPluginDownloadUrlParamsValid(file)) {
        return true;
    }

    throw new Error(EZLO_PLUGINS_BUILD_PLUGIN_DOWNLOAD_URL_INVALID_PARAMS);
};

const extractCloudDownloadPluginParams = (file) => {
    const url = new URL(file);
    const uuid = url.pathname.substring(2);
    const key = url.searchParams.get(DASHBOARD_FILE_KEY_PROPERTY_NAME);

    const params = { uuid };
    if (key) {
        params.key = key;
    }

    return params;
};

/**
 * Convert dashboard file value to Plugin download URL
 * @param {string} file - dashboard's file value
 * @return {string} URL to download the file
 *
 * @see {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Custom+Scripts+v.10+-+Updates| Custom Scripts v.10}
 * */
export const buildPluginDownloadUrl = (file) => {
    validateBuildPluginDownloadUrlParams(file);
    const params = extractCloudDownloadPluginParams(file);

    return API_CLOUD_EZLO_DOWNLOAD_PLUGIN_URL(params);
};

/**
 * Rows array for Plugin table
 * @param {array} data - list of Plugin
 * @returns {array} array of rows
 * @example
 * createPluginTable(data)
 * */
export const createPluginTable = (data) => {
    return data.map((item) => {
        const row = {};

        if (Object.keys(item.meta).length > 0) {
            row.config = item.meta?.config && item.meta.config;
            row.name = item.name && item.name;
            row.nameId = item.meta?.config?.id && item.meta?.config?.id;
            row.status = '-';
            row.certified = '-';
            row.version = item.meta?.config?.version && item.meta.config.version;
            row.shouldUpdate = item?.shouldUpdate;
            row.versionForUpdate = item.versionForUpdate;
            row.archive_hash_md5 = item?.meta?.archive_hash_md5;
            row.id = item?.meta?.config?.id;
            row.uuid = item.uuid;
            row.uuidFromMarketPlace = item.uuidFromMarketPlace;
            row.linkForDownloadFromMarketPlace = item.linkForDownloadFromMarketPlace;
            row.archive_hash_md5FromMarketPlace = item.archive_hash_md5FromMarketPlace;
            row.changelog = item.meta?.changelog?.changelog;
        }

        if (item?.data?.file) {
            row.downloadUrl = buildPluginDownloadUrl(item?.data?.file);
        }

        row.id = item.uuid;

        return row;
    });
};

export const createPluginTableGenerator = (data) => {
    return data.map((item) => {
        return {
            nameId: item.name,
            id: item.uuid,
            data: item.data,
        };
    });
};

export const buildMeshBotsTableColumnsGenerator = ({
    type,
    onConfirm,
    onRouteToPage,
    onHandlerShowDevicesModal,
    onHandlerShowInfoModal,
}) => {
    return [
        {
            field: 'id',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
            sortable: false,
            disableColumnMenu: true,
            bodyAlign: 'center',
            hide: true,
            disableSelectionOnClick: true,
        },
        {
            field: 'nameId',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_IP_DEVICE_TITLE,
            headerClassName: 'grid-field',
            minWidth: 250,
            align: 'left',
            disableSelectionOnClick: true,
        },
        type === PRIVATE_GENERATOR && {
            field: 'devices',
            headerAlign: 'center',
            headerName: EZLOGIC_HEADING_DEVICES,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <CountInstalledIPDevices params={params} onHandlerShowDevicesModal={onHandlerShowDevicesModal} />
                );
            },
        },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                field: 'publish',
                headerAlign: 'center',
                headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_PUBLISH_TITLE,
                width: 120,
                align: 'center',
                cellClassName: 'plugins-list__empty-field',
                sortable: false,
                disableColumnMenu: true,
                disableClickEventBubbling: true,
                disableSelectionOnClick: true,
                renderCell: (params) => {
                    return (
                        <IconButton onClick={() => onConfirm(params, 'publish')}>
                            <Public />
                        </IconButton>
                    );
                },
            },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                field: 'edit',
                headerAlign: 'center',
                headerName: EZLOGIC_BUTTON_EDIT,
                width: 120,
                align: 'center',
                cellClassName: 'plugins-list__empty-field',
                sortable: false,
                disableColumnMenu: true,
                disableClickEventBubbling: true,
                disableSelectionOnClick: true,
                renderCell: (params) => {
                    return (
                        <IconButton onClick={() => onRouteToPage('edit', params)}>
                            <Edit color="primary" />
                        </IconButton>
                    );
                },
            },
        type !== MARKETPLACE_GENERATOR && {
            field: 'delete',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'remove')}>
                        <Delete color="primary" />
                    </IconButton>
                );
            },
        },
        type !== MARKETPLACE_GENERATOR &&
            type !== PUBLISH_GENERATOR && {
                field: 'createDevice',
                headerAlign: 'center',
                headerName: EZLOGIC_BUTTON_CREATE_DEVICE,
                width: 160,
                align: 'center',
                cellClassName: 'plugins-list__empty-field',
                sortable: false,
                disableColumnMenu: true,
                disableClickEventBubbling: true,
                disableSelectionOnClick: true,
                renderCell: (params) => {
                    return (
                        <IconButton onClick={() => onRouteToPage('settings', params)}>
                            <AddCircleOutline />
                        </IconButton>
                    );
                },
            },
        type === MARKETPLACE_GENERATOR && {
            field: 'copyToPrivate',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
            width: 180,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'copy')}>
                        <FileCopy />
                    </IconButton>
                );
            },
        },
        type === MARKETPLACE_GENERATOR && {
            field: TEMPLATE_INFO,
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_INFORMATION,
            width: 180,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onHandlerShowInfoModal(params, TEMPLATE_INFO)}>
                        <InfoOutlined />
                    </IconButton>
                );
            },
        },
    ].filter((columnItem) => columnItem !== false);
};

/**
 * Columns config for Plugin table
 // * @param {array} props
 * @returns {array} array of columns
 * @example
 * buildMeshBotsTableColumns(props)
 * */
// TODO: rename!
export const buildMeshBotsTableColumns = (...args) => {
    const [
        type,
        onConfirm,
        onHandlerShowInstallModal,
        onHandlerShowInfoModal,
        onHandlerShowDevicesModal,
        devices,
        pluginData,
    ] = args;

    return [
        {
            field: 'id',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE,
            sortable: false,
            disableColumnMenu: true,
            bodyAlign: 'center',
            hide: true,
            disableSelectionOnClick: true,
        },
        {
            field: 'name',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_PLUGIN_NAME,
            headerClassName: 'grid-field',
            minWidth: 250,
            align: 'left',
            disableSelectionOnClick: true,
        },
        {
            field: 'version',
            headerAlign: 'center',
            headerName: EZLOGIC_TITLE_VERSION,
            headerClassName: 'grid-field',
            minWidth: 150,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return <PluginVersionCell params={params} onClick={onHandlerShowInstallModal} type={type} />;
            },
        },
        type === 'marketplace' && {
            field: 'certified',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE,
            headerClassName: 'grid-field',
            minWidth: 150,
            disableSelectionOnClick: true,
        },
        type !== 'publish' && {
            field: 'info',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_INFO_TITLE,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onHandlerShowInfoModal(params)}>
                        <InfoOutlined />
                    </IconButton>
                );
            },
        },
        type === 'publish' && {
            field: 'status',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_STATUS_TITLE,
            headerClassName: 'grid-field',
            minWidth: 250,
            align: 'center',
            disableSelectionOnClick: true,
        },
        type === 'private' && {
            field: 'download',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DOWNLOAD_TITLE,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'download')}>
                        <CloudDownloadOutlined />
                    </IconButton>
                );
            },
        },
        type === 'private' && {
            field: 'install',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_MANAGE_INSTALLATION_TITLE,
            width: 220,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return <CountInstalledPlugin params={params} onClick={onHandlerShowInstallModal} />;
            },
        },
        type === 'private' && {
            field: 'devices',
            headerAlign: 'center',
            headerName: EZLOGIC_HEADING_DEVICES,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <CountInstalledDevices
                        params={params}
                        onHandlerShowDevicesModal={onHandlerShowDevicesModal}
                        devices={devices}
                        pluginData={pluginData}
                    />
                );
            },
        },
        type !== 'marketplace' && {
            field: 'delete',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE,
            width: 120,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'remove')}>
                        <Delete color="primary" />
                    </IconButton>
                );
            },
        },
        type === 'marketplace' && {
            field: 'copyToPrivate',
            headerAlign: 'center',
            headerName: EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE,
            width: 180,
            align: 'center',
            cellClassName: 'plugins-list__empty-field',
            sortable: false,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            disableSelectionOnClick: true,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => onConfirm(params, 'copy')}>
                        <FileCopy />
                    </IconButton>
                );
            },
        },
    ].filter((columnItem) => columnItem !== false);
};

export const colors = [
    '#b71c1c',
    '#f44336',
    '#880e4f',
    '#e91e63',
    '#4a148c',
    '#673ab7',
    '#1a237e',
    '#3f50b5',
    '#28579b',
    '#4ba9f4',
    '#194e33',
    '#4caf50',
    '#827717',
    '#cddc39',
    '#f26f04',
    '#f6c108',
    '#263238',
    '#607d8b',
    '#000000',
    '#969696',
];

/**
 * Get sum installed plugins
 * @param {object} plugins - list of Plugin
 * @param {object} installedPlugins - list of installed plugins
 * @returns {object} object plugins and count installed plugins
 * @example
 * getSumInstalledPlugins(plugins, installedPlugins)
 * */

export const getSumInstalledPlugins = (plugins, installedPlugins) => {
    return Object.values(plugins).reduce((acc, item) => {
        return {
            ...acc,
            [`${item.meta?.config?.id + '_' + item.meta?.config?.version}`]: Object.values(installedPlugins).reduce(
                (sum, controller) => {
                    let sumPlugins = 0;
                    controller?.forEach((elemFromController) => {
                        if (
                            elemFromController.plugin === item?.meta?.config?.id &&
                            elemFromController.version === item.meta.config.version
                        ) {
                            sumPlugins += 1;
                        }
                    });

                    return sum + sumPlugins;
                },
                0,
            ),
        };
    }, {});
};

/**
 * Get controllers installed plugins
 * @param {object} plugins - list of Plugin
 * @param {object} installedPlugins - list of installed plugins
 * @returns {array} returns objects in array which have controller serial
 * @example
 * getControllersPluginInstalled(plugins, installedPlugins)
 * */

export const getControllersPluginInstalled = (plugins, installedPlugins) => {
    return plugins.map((plugin) => {
        return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
            const installed = controller?.find(
                (item) => item.plugin === plugin.meta?.config?.id && item?.version === plugin.meta?.config?.version,
            );

            return [
                ...acc,
                {
                    id: `${plugin.meta?.config?.id + '_' + plugin.meta?.config?.version}`,
                    serial: installed ? serial : false,
                    version: plugin.meta?.config?.version,
                },
            ];
        }, []);
    });
};

/**
 * Set list devices installed
 * @param {array} data - list of plugin
 * @param {array} devices - list devices
 * @returns {object} returns objects with count installed devices
 * @example
 * setListDevicesInstalled(data, devices)
 * */

export const setListDevicesInstalled = (data, devices) => {
    return data.reduce((acc, plugin) => {
        return {
            ...acc,
            [plugin[0]?.id]: devices.reduce((sum, device) => {
                let sumPlugins = 0;

                plugin.forEach((elem) => {
                    if (
                        device.serial === elem.serial &&
                        `${device?.gateway?.pluginId + '_' + device?.version}` === elem.id
                    ) {
                        sumPlugins += 1;
                    }
                });

                return sum + sumPlugins;
            }, 0),
        };
    }, {});
};

export const getDevicesWithVersion = (devices, listInstalledPlugins, gateways) => {
    return devices.map((device) => {
        if (listInstalledPlugins[device.serial]) {
            const pluginId = gateways?.find((gateway) => gateway?._id === device?.gatewayId)?.pluginId;
            device.version =
                listInstalledPlugins?.[device?.serial]?.find((plugin) => plugin?.plugin === pluginId)?.version || '';
        }

        return device;
    });
};

/**
 * Set list devices installed wifi
 * @param {array} data - list of plugin
 * @param {array} devices - list devices
 * @returns {object} returns objects with count installed devices
 * @example
 * setListDevicesInstalledWifi(data, devices)
 * */

export const setListDevicesInstalledWifi = (data, devices) => {
    if (!Array.isArray(data) || !Array.isArray(devices)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return data.reduce((acc, plugin) => {
        return {
            ...acc,
            [plugin.name]: devices.reduce((sum, device) => {
                let sumPlugins = 0;

                if (device.info.hasOwnProperty('template')) {
                    if (plugin.uuid === device.info.template) {
                        sumPlugins += 1;
                    }
                }

                return sum + sumPlugins;
            }, 0),
        };
    }, {});
};

/**
 * Remove plugin
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @param t - translation
 * @param  dispatch - dispatch
 * @example
 * removePlugin(installedPlugins, pluginData, actions)
 * */

export const removePlugin = (installedPlugins, pluginData, dispatch, t) => {
    const namePlugin = pluginData?.row?.nameId;

    Object.entries(installedPlugins).forEach((item) => {
        if (
            item[1].find(
                (plugin) => plugin.plugin === pluginData.row.nameId && plugin.version === pluginData.row.version,
            )
        ) {
            dispatch(
                PluginActions.subscribeUpdateController(
                    item[0],
                    dispatch(PluginActions.notificationUpdateController(item[0], 'remove', t)),
                ),
            );
            dispatch(PluginActions.removePlugin(item[0], namePlugin));
        }
    });
};

/**
 * Get installed plugins
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @example
 * getInstalledPlugin(installedPlugins, pluginData)
 * */

export const getInstalledPlugin = (installedPlugins, pluginData) => {
    if (!installedPlugins || !pluginData) {
        throw TYPE_ERROR;
    }

    return Object.entries(installedPlugins).reduce((acc, controller) => {
        const installed = controller?.[1]?.some((item) => {
            return item.plugin === pluginData?.row?.nameId && item.version === pluginData?.row?.version;
        });

        if (!installed) {
            return acc;
        }

        return [...acc, installed];
    }, []);
};

/**
 * Controllers plugin installed
 * @param {object} installedPlugins - list of plugin
 * @param {object} pluginData - current data
 * @example
 * controllersPluginInstalled(installedPlugins, pluginData)
 * */

export const controllersPluginInstalled = (installedPlugins, pluginData) => {
    return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find(
            (item) => item.plugin === pluginData?.row?.nameId && item.version === pluginData?.row?.version,
        );

        if (!installed) {
            return acc;
        }

        return { ...acc, [serial]: { id: installed.plugin, serial: serial, version: installed.version } };
    }, {});
};

export const getPluginsInstalledForIntegration = (installedPlugins, pluginData) => {
    return Object.entries(installedPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find((item) => item.plugin === pluginData?.meta?.config?.id);

        if (!installed) {
            return acc;
        }

        return { ...acc, [serial]: { id: installed.plugin, serial: serial, version: installed.version } };
    }, {});
};

/**
 * List devices installed
 * @param {array} devices - list devices
 * @param {object} pluginInstalled - controllers plugin installed
 * @example
 * listDevicesInstalled(devices, pluginInstalled)
 * */

export const listDevicesInstalled = (devices, pluginInstalled) => {
    return devices.reduce((acc, device) => {
        const controller = pluginInstalled && pluginInstalled[device.serial];
        if (controller && device.serial === controller.serial && device?.gateway?.pluginId === controller.id) {
            return [...acc, { serial: device.serial, deviceName: device.name, ...device }];
        }

        return acc;
    }, []);
};

export const filterDevicesBySerial = (devices, serial) => {
    return devices.filter((device) => device.serial === serial);
};

export const getCountInstalledDevicesByIntegration = (devices, integration, integrationType) => {
    if (integrationType === PLUGIN) {
        return devices.filter((device) => device?.gateway?.pluginId === integration?.meta?.config?.id);
    }

    if (integrationType === TEMPLATE) {
        return devices.filter((device) => device?.info?.template === integration?.uuid);
    }
};

/**
 * List devices installed
 * @param {object} template - list current template
 * @param {array} devices - list devices
 * @example
 * listDevicesInstalledWifi(template, devices)
 * */
export const listDevicesInstalledWifi = (template, devices) => {
    if (!Array.isArray(devices)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return devices.reduce((acc, device) => {
        if (device.info.hasOwnProperty('template')) {
            if (template.id === device.info.template || template.uuid === device.info.template) {
                return [...acc, { serial: device.serial, deviceName: device.name, ...device }];
            }
        }

        return [...acc];
    }, []);
};

/**
 * List installed node
 * @param {object} listPlugins - list installed nodes
 * @returns {object} returns object with serials number controllers which node installed
 * @example
 * installedOnControllers(listInstalledPlugins)
 * */

export const installedOnControllers = (listPlugins) => {
    if (!listPlugins) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(listPlugins).reduce((acc, [serial, controller]) => {
        const installed = controller?.find((node) => node.shortName === WIFI_DEVICE_GENERATOR);

        return { ...acc, [serial]: installed ? true : false };
    }, {});
};

/**
 * List capabilities for select
 * @param {object} capabilities - list capabilities
 * @returns {array} returns an array with the correct structure for the select
 * @example
 * createCapabilityForSelect(capabilities)
 * */

export const createCapabilityForSelect = (capabilities) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, value]) => {
        return [...acc, { id: hash(), name: action, actions: value }];
    }, []);
};

/**
 * Capabilities transform for add devices
 * @param {object} capabilities - list capabilities
 * @returns {object} returns an object with the correct structure
 * @example
 * transformCapabilitiesFrom(data)
 * */

export const transformCapabilitiesFrom = (capabilities) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, capability]) => {
        return { ...acc, [action]: createActionsFrom(capability) };
    }, {});
};

/**
 * Transform incoming capabilities
 * @param {object} capabilities - list capabilities
 * @returns {array} returns an array with the correct structure
 * @example
 * transformCapabilitiesTo(data)
 * */

export const transformCapabilitiesTo = (capabilities) => {
    if (!capabilities) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return Object.entries(capabilities).reduce((acc, [action, capability]) => {
        return [...acc, { id: hash(), command: action, actions: _.cloneDeep(capability) }];
    }, []);
};

/**
 * Search for sub-categories by category name
 * @param {array} categories - list categories
 * @param {string} nameCategory - name category
 * @returns {array} returns an array with subcategory
 * @example
 * getSubCategory(nameCategory)
 * */

export const getSubCategory = (categories, nameCategory) => {
    let subcategory = [];

    if (!Array.isArray(categories)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    categories.forEach((category) => {
        if (category.value === nameCategory && category.subcategory.length > 0) {
            // eslint-disable-next-line
            subcategory = category.subcategory;
        }
    });

    return subcategory;
};

/**
 * Update capability item
 * @param {array} list - capability list
 * @param {string} name - action name
 * @param {string} value - action value
 * @param {string} id - id item
 * @param {string} actionName - id action
 * @returns {array} returned updated capability array
 * @example
 * updatedCapabilityList(data, commands, name, value, id, action)
 * */
export const updatedCapabilityList = (list, name, value, id, actionName) => {
    if (!Array.isArray(list)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            item.actions.map((action) => {
                if (action.id === actionName) {
                    action[name] = value;
                }

                return action;
            });
        }

        return item;
    });
};

/**
 * Set capability command
 * @param {array} list - capability list
 * @param {string} id - id item
 * @param {array} commands - commands list
 * @param {string} value - action value
 * @returns {array} returned updated capability array
 * @example
 * setCapabilityCommand(list, id, commands, value)
 * */

export const setCapabilityCommand = (list, id, commands, value) => {
    if (!Array.isArray(list) || !Array.isArray(commands)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            const currentCommand = commands.find((command) => command.name === value);

            if (currentCommand) {
                item['actions'] = Object.entries(currentCommand?.actions).map(([item, data]) => {
                    let action = { id: item };

                    if (typeof data === generator.STRING) {
                        action[item] = '';
                        action.type = data;
                    }

                    if (typeof data === generator.OBJECT) {
                        action = { ...action, ...getRequestFields(data) };
                    }

                    return action;
                });
            }

            item.command = value;
        }

        return item;
    });
};

const getRequestFields = (data) => {
    if (typeof data === generator.OBJECT) {
        if (data.hasOwnProperty(ENUM)) {
            return Object.entries(data).reduce((acc, [field, value]) => {
                if (field === ENUM) {
                    return {
                        ...acc,
                        [field]: value.split(',').map((item) => {
                            return { value: item, label: formatNameWithUnderscores(item) };
                        }),
                    };
                }

                return { ...acc, [field]: value };
            }, {});
        } else {
            return Object.entries(data).reduce((acc, [field]) => {
                return { ...acc, [field]: '' };
            }, {});
        }
    }

    return data;
};

/**
 * Capability transform for config
 * @param {array} capabilityList - capability list
 * @returns {object} returns an object with capability
 * @example
 * capabilityTransformForConfig(capabilityList)
 * */

export const capabilityTransformForConfig = (capabilityList) => {
    if (!Array.isArray(capabilityList)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return capabilityList.reduce((acc, item) => {
        return { ...acc, [item.command]: item.actions };
    }, {});
};

const createActionsFrom = (data) => {
    return Array.from(data)?.reduce((sum, action) => {
        if (action.hasOwnProperty(generator.METHOD)) {
            const key = action.id;
            const fields = _.cloneDeep(action);
            delete fields.id;

            return { ...sum, [key]: fields };
        }

        return { ...sum, [action.id]: action[action.id] };
    }, {});
};

/**
 * Set headers in create template
 // * @param {array} capabilityList - capability list
 * @returns {object} returns an object with capability
 * @example
 * setHeaders(list, id, field, value)
 * */

export const setHeaders = (list, id, field, value) => {
    if (!Array.isArray(list)) {
        throw 'Error! The type of the transmitted data does not match the required one';
    }

    return list.map((item) => {
        if (item.id === id) {
            if (field === generator.KEY && !value) {
                item[field] = `__empty-${hash()}`;

                return item;
            }

            item[field] = value;
        }

        return item;
    });
};

export const isPluginShouldUpgrade = ({ marketplacePlugin = {}, privatePlugin = {}, listInstalledPlugins = [] }) => {
    const currentPlugin = getPluginsInstalled(listInstalledPlugins).find(
        (plugin) =>
            plugin?.plugin === privatePlugin?.meta?.config?.id &&
            plugin?.version === privatePlugin?.meta?.config?.version,
    );

    return (
        privatePlugin?.meta?.config?.version &&
        marketplacePlugin?.meta?.config?.version &&
        privatePlugin?.meta?.config?.version !== marketplacePlugin?.meta?.config?.version &&
        currentPlugin?.plugin === marketplacePlugin?.meta?.config?.id &&
        currentPlugin?.version === privatePlugin?.meta?.config?.version &&
        !checkVersionSupport(currentPlugin?.version, marketplacePlugin?.meta?.config?.version)
    );
};

export const pluginsArrayWithUpdateInformation = (
    privatePlugins = [],
    marketplacePlugins = [],
    listInstalledPlugins,
) => {
    return privatePlugins?.map((privatePlugin) => {
        marketplacePlugins?.forEach((marketplacePlugin) => {
            if (
                isPluginShouldUpgrade({
                    marketplacePlugin,
                    privatePlugin,
                    listInstalledPlugins,
                })
            ) {
                privatePlugin = {
                    ...privatePlugin,
                    shouldUpdate: true,
                    versionForUpdate: marketplacePlugin?.meta?.config?.version,
                    uuidFromMarketPlace: marketplacePlugin?.uuid,
                    linkForDownloadFromMarketPlace: buildPluginDownloadUrl(JSON.parse(marketplacePlugin.data)?.file),
                    archive_hash_md5FromMarketPlace: marketplacePlugin?.meta?.archive_hash_md5,
                };
            }
        });

        return privatePlugin;
    });
};

export const getPluginsInstalled = (listInstalledPlugins = []) => {
    return Object.values(listInstalledPlugins).reduce((acc = [], controller) => {
        return acc.concat(controller);
    }, []);
};
export const filterByName = (data = []) => {
    return data.sort((a, b) => a.name.localeCompare(b.name));
};

export const getFilteredControllers = (controllers) => {
    return controllers.filter((controller) => {
        return controller?.hardware !== VALVE;
    });
};

export const getFilteredControllersForIPDevices = (controllers, integration) => {
    return controllers.filter((controller) => {
        return (
            controller?.hardware !== VALVE &&
            controller?.devices?.find((item) => item?.info?.template === integration?.uuid)
        );
    });
};

export const getValveControllers = (controllers) => {
    return controllers.filter((controller) => controller?.hardware === VALVE);
};

export const filteredEzlosControllers = (controllers, valveControllers) => {
    return controllers.filter((controller) => controller.serial !== findControllerById(valveControllers)?.serial);
};

export const findControllerById = (valveControllers) => {
    return valveControllers.find((valveController) => valveController.serial);
};

export const isPluginDownloadedFromMarketplace = ({ marketplacePlugins, hash_md5, privatePlugins, pluginData }) => {
    const pluginFromMarketPlace = getPluginFromMarketplaceByHash({ marketplacePlugins, hash_md5, pluginData });

    return privatePlugins.find(
        (plugin) =>
            plugin?.meta?.config?.id === pluginFromMarketPlace?.meta?.config?.id &&
            plugin?.meta?.config?.version === pluginFromMarketPlace?.meta?.config?.version,
    );
};

const getPluginFromMarketplaceByHash = ({ marketplacePlugins = [], hash_md5 = '', pluginData }) => {
    return marketplacePlugins?.find((plugin) => {
        return plugin?.meta.archive_hash_md5 === hash_md5 || plugin?.uuid === pluginData?.id;
    });
};

export const getPluginFromMarketplace = ({ marketplacePlugins = [], installedPlugin = {} }) => {
    return marketplacePlugins.find(
        (plugin) =>
            plugin?.meta?.config?.id === installedPlugin?.plugin ||
            plugin?.meta?.config?.id === installedPlugin?.meta?.config?.id,
    );
};
export const getInstalledPluginFromRow = ({ pluginsList = [], plugin = {} }) => {
    return pluginsList?.find(
        (item) =>
            item?.plugin === plugin?.row?.nameId ||
            item?.plugin === plugin?.nameId ||
            item?.plugin === plugin?.meta?.config?.id,
    );
};

export const getUpdatedPluginRow = ({ currentPluginRow, pluginFromMarketPlace }) => {
    return {
        ...currentPluginRow,
        versionForUpdate: pluginFromMarketPlace?.meta?.config?.version,
        uuidFromMarketPlace: pluginFromMarketPlace?.uuid,
        linkForDownloadFromMarketPlace: buildPluginDownloadUrl(JSON.parse(pluginFromMarketPlace?.data)?.file),
        archive_hash_md5FromMarketPlace: pluginFromMarketPlace?.meta?.archive_hash_md5,
    };
};

export const notificationControllerUpdateMessage = (type) => {
    return type === UPGRADE ? EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_UPDATED : EZLOGIC_TITLE_CONTROLLER_SUCCESSFULLY_UPDATED;
};

export const isPluginInstalled = (listInstalledPlugins, serial, integration) => {
    return listInstalledPlugins[serial]?.filter(
        (item) => item.plugin === integration.meta?.config?.id || item.plugin === integration,
    )?.length;
};
/**
 * Checks only first img file for validity
 * @param {File[]} acceptedFiles - img file list
 * @returns {Boolean} If the file is valid, it will return true
 * @example
 * imgValidation([{name: 'logo.png'}])
 */
export const imgValidation = (acceptedFiles) => {
    const imgFile = acceptedFiles?.[0];
    const isValid = isFileNameValid(imgFile?.name);

    return Boolean(isValid && imgFile);
};

export const getDeviceTypeValue = (value, arr) => {
    return arr.find((device) => device.value === value);
};
