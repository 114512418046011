import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

import EzlosDropdown from '../../../EzlosDropdown';
import ExpressionList from './../ExpressionList';
import ExpressionsAddForm from './../ExpressionsAddForm';
import { Header } from '../../../../components';
import { ConfirmModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import BoxWithLinearProgress from '../../../../components/BoxWithLinearProgress';

import { ExpressionsActions, EzloActions, MainAction } from '../../../../actions';
import { EXPRESSIONS_TYPE } from '../../../../constants/Expressions';
import { CONTINUE } from '../../../../constants/ComponentConstants';

import { useTranslate } from '../../../../features/languages';
import { selectorOfCurrentControllerData } from '../../EzloMeshbot/utils';
import { sortListAllExpressions } from '../utils';
import {
    EZLOGIC_VARIABLES_ADD_BUTTON,
    EZLOGIC_VARIABLES_ADD_EXPRESSION_BUTTON,
    EZLOGIC_VARIABLES_ADD_VARIABLE_BUTTON,
    EZLOGIC_HEADING_CHANGE_THE_CONTROLLER,
    EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS,
    EZLOGIC_VARIABLES_EXPRESSIONS_TITLE,
    EZLOGIC_VARIABLES_VARIABLES_TITLE,
} from '../../../../constants/language_tokens';

const ExpressionsLayout = ({ expressionType, withHeader = true }) => {
    const dispatch = useDispatch();
    const { serial, controllerData, lineLoading, isConnected } = useSelector(selectorOfCurrentControllerData);
    const expressionListSelector = controllerData?.expressions;
    const [expressionList, setExpressionList] = useState([]);
    const [showAddExpressionForm, setShowExpressionForm] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [hasEditingItems, setHasEditingItems] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [newSerial, setNewSerial] = useState('');
    const isExpression = expressionType === EXPRESSIONS_TYPE.EXPRESSION;
    const { t } = useTranslate();

    useEffect(() => {
        if (serial && isConnected) {
            dispatch(ExpressionsActions.updateExpressions(serial, t));
        }
    }, [serial, isConnected]);

    useEffect(() => {
        if (isConfirmed && !isConfirmModalVisible) {
            dispatch(EzloActions.selectController(newSerial));
            setShowExpressionForm(false);
            setIsConfirmed(false);
        }
    }, [isConfirmed, isConfirmModalVisible, newSerial]);

    useEffect(() => {
        setExpressionList(sortListAllExpressions(expressionListSelector, isExpression));
    }, [expressionListSelector]);

    const isLoading = useMemo(() => {
        return lineLoading || !isConnected || !controllerData;
    }, [serial, controllerData, lineLoading, isConnected]);

    const handleExpressionAddButton = () => {
        setHasEditingItems(true);
        setShowExpressionForm(true);
    };

    const handleHideExpressionForm = () => {
        dispatch(MainAction.confirmUserAction(() => setShowExpressionForm(false)));
    };

    const openConfirmModal = (newSerial) => {
        setNewSerial(newSerial);
        if (hasEditingItems) {
            setIsConfirmModalVisible(true);
        } else {
            setIsConfirmed(true);
        }
    };

    const handleConfirmation = (isConfirmed) => {
        setIsConfirmed(isConfirmed);
        setIsConfirmModalVisible(false);
    };

    const handleGetEditingStatus = (isEditing) => {
        setHasEditingItems(isEditing);
    };

    return (
        <BoxWithLinearProgress isLinearProgress={isLoading}>
            <section className="expressions-wrapper">
                {withHeader && (
                    <Header className="list-header">
                        <h1>
                            {isExpression
                                ? t(EZLOGIC_VARIABLES_EXPRESSIONS_TITLE)
                                : t(EZLOGIC_VARIABLES_VARIABLES_TITLE)}
                        </h1>
                        <EzlosDropdown isShowOnlyOnlineControllers={true} openConfirmModal={openConfirmModal} />

                        <ConfirmModal
                            isOpened={isConfirmModalVisible}
                            onClose={setIsConfirmModalVisible}
                            title={t(EZLOGIC_HEADING_CHANGE_THE_CONTROLLER)}
                            text={t(EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS)}
                            confirmButtonTitle={t(CONTINUE)}
                            onCancel={() => handleConfirmation(false)}
                            onConfirm={() => handleConfirmation(true)}
                        />
                    </Header>
                )}
                <main>
                    <ExpressionList
                        items={expressionList}
                        getEditingStatus={handleGetEditingStatus}
                        expressionType={expressionType}
                    />
                    {showAddExpressionForm && (
                        <ExpressionsAddForm
                            onCancel={handleHideExpressionForm}
                            serial={serial}
                            expressionType={expressionType}
                            expressionListSelector={expressionListSelector}
                        />
                    )}
                </main>
                <footer>
                    {!showAddExpressionForm && (
                        <Button
                            data-testid="createExpressionButton"
                            disabled={isLoading}
                            color="primary"
                            variant="contained"
                            className="expression-add-button"
                            onClick={handleExpressionAddButton}
                        >
                            {`${t(EZLOGIC_VARIABLES_ADD_BUTTON)} ${
                                isExpression
                                    ? t(EZLOGIC_VARIABLES_ADD_EXPRESSION_BUTTON)
                                    : t(EZLOGIC_VARIABLES_ADD_VARIABLE_BUTTON)
                            }`}
                        </Button>
                    )}
                </footer>
            </section>
        </BoxWithLinearProgress>
    );
};

export default ExpressionsLayout;
