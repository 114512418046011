import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, DialogContent, DialogContentText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import hash from '../../../../../constants/uniqueHash';
import { STATUSES } from '../../../../../constants/Plugins';
import { removeEmptyFields, validateRequiredFields } from '../../utils';
import * as plugin from '../../../../../constants/Plugins';
import {
    ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
    INFO_OPTIONS,
    SUCCESS_OPTIONS,
} from '../../../../../constants/toastConstants';
import ConfigureFormField from './ConfigureFormField';
import toastsActions from '../../../../../actions/toastsActions';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_SAVE_NEW_CONFIGURE,
    EZLOGIC_HEADING_CONFIGURE_PLUGIN,
    EZLOGIC_TOAST_DEVICE_ADDED,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY,
} from '../../../../../constants/language_tokens';
import { EzloActions, PluginActions } from '../../../../../actions';

import styles from './style.module.scss';
import { CONTAINED } from '../../../../../constants/MeshbotConstant';
import { PRIMARY } from '../../../../../constants/Devices';
import { NUMBER } from '../../../EzloMeshbot/constants';

const ConfigureForm = (props) => {
    const [configureInputs, setConfigureInputs] = useState([]);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState(null);
    const [errors, setErrors] = useState(null);
    const { currentCustomNode, currentSerial, onCancel, open } = props;
    const { t } = useTranslate();

    useEffect(() => {
        if (currentCustomNode.configuration) {
            const fields = currentCustomNode.configuration.inputs.map((item) => item.field);

            setInputs(
                fields.reduce((acc, field) => {
                    return { ...acc, [field]: '' };
                }, {}),
            );

            setConfigureInputs(
                currentCustomNode.configuration.inputs.map((item) => {
                    const data = {
                        id: hash(),
                        name: item.field,
                        type: item.type,
                        required: item.required,
                        label: item.name,
                        description: item.description,
                    };

                    if (item.hasOwnProperty(plugin.ENUM)) {
                        data.enumList = item.enum;
                    }

                    return data;
                }),
            );
        }
    }, [currentCustomNode]);
    const handlerSetConfigure = () => {
        if (Object.keys(validateRequiredFields(configureInputs, inputs)).length) {
            setErrors(validateRequiredFields(configureInputs, inputs));
        } else {
            if (currentCustomNode.configuration) {
                const data = {
                    script: currentCustomNode.configuration.script,
                    inputs: removeEmptyFields(inputs),
                };
                dispatch(PluginActions.setConfigurePlugins(currentSerial, data));
                dispatch(
                    PluginActions.subscribeUpdateConfigure(currentSerial, notificationUpdateConfigure(currentSerial)),
                );
                dispatch(
                    PluginActions.subscribeBroadcastConfigure(
                        currentSerial,
                        notificationUpdateConfigure(currentSerial),
                    ),
                );

                onCancel();
                setInputs(null);
                setErrors(null);
            }
            onCancel();
        }
    };
    const setInputsValue = (e) => {
        const { name, type, value } = e.target;

        setInputs({
            ...inputs,
            [name]: type === NUMBER && value ? Number(value) : type === NUMBER && !value ? '' : value,
        });
    };
    const notificationUpdateConfigure = (serial) => {
        return ({ result }) => {
            dispatch(PluginActions.setPluginIntegrationSuccess());

            if (result.status === STATUSES.ERROR || result.status === STATUSES.ADD_DEVICE_FAIL) {
                dispatch(
                    toastsActions.showToast({
                        message: result.message ?? result.error,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
                dispatch(PluginActions.unSubscribeBroadcastConfigure(serial));
                dispatch(PluginActions.unSubscribeUpdateConfigure(serial));
            } else {
                if (result.status === STATUSES.SUCCESS || result.status === STATUSES.OK) {
                    dispatch(
                        toastsActions.showToast({
                            message: result.message ?? t(EZLOGIC_TOAST_DEVICE_ADDED),
                            options: SUCCESS_OPTIONS,
                        }),
                    );
                    //TODO simplified and moved to redux actions
                    dispatch(PluginActions.unSubscribeBroadcastConfigure(serial));
                    dispatch(EzloActions.loadEzloDataByPluginUpdate(serial));
                    dispatch(PluginActions.getListInstalledPlugins(serial));
                    dispatch(PluginActions.handleCloseSuccessPluginModal());
                }

                if (result.status === STATUSES.FAILED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR),
                            options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                            isSave: true,
                        }),
                    );
                    dispatch(PluginActions.unSubscribeUpdateConfigure(serial));
                }

                if (result.status === STATUSES.FINISHED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY),
                            options: INFO_OPTIONS,
                        }),
                    );
                    //TODO simplified and moved to redux actions
                    dispatch(PluginActions.unSubscribeUpdateConfigure(serial));
                    dispatch(EzloActions.loadEzloDataByPluginUpdate(serial));
                    dispatch(PluginActions.getListInstalledPlugins(serial));
                    dispatch(PluginActions.getListInstalledPlugins(serial));
                    dispatch(PluginActions.handleCloseSuccessPluginModal());
                }
            }
        };
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            <h2>
                <span onClick={onCancel}>
                    <CloseIcon />
                </span>
                <span>{t(EZLOGIC_HEADING_CONFIGURE_PLUGIN)}</span>
            </h2>
            <DialogContent dividers={true}>
                <DialogContentText ref={descriptionElementRef}>
                    <ul className={styles.modalPluginConfigureList}>
                        {configureInputs.map((input) => {
                            return (
                                <ConfigureFormField
                                    key={input.id}
                                    input={input}
                                    inputs={inputs}
                                    error={errors && errors[input.name]}
                                    onSetInputsValue={setInputsValue}
                                />
                            );
                        })}
                    </ul>
                </DialogContentText>
            </DialogContent>
            <div className={styles.buttonWrapper}>
                <Button variant={CONTAINED} color={PRIMARY} onClick={handlerSetConfigure}>
                    {t(EZLOGIC_BUTTON_SAVE_NEW_CONFIGURE)}
                </Button>
            </div>
        </>
    );
};

ConfigureForm.propTypes = {
    currentSerial: PropTypes.string,
    currentCustomNode: PropTypes.object,
    PluginActions: PropTypes.object,
    EzloActions: PropTypes.object,
    onCancel: PropTypes.func,
};

export default ConfigureForm;
