import React from 'react';
import classNames from 'classnames';

import styles from './NotificationTemplateNameCell.module.scss';

export const NotificationTemplateNameCell = ({ name, onClick }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <div onClick={handleClick} title={name} className={classNames(styles.cell)}>
            <span className={styles.name}>{name}</span>
        </div>
    );
};
