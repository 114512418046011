import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MeshbotNewIcon = () => (
    <SvgIcon color="primary" width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2552 3.68333L19.2414 9.75H27.7931H31L22.6621 1.3C21.8069 0.433333 20.7379 0 19.5621 0H13.8966H11.4379C10.2621 0 9.1931 0.433333 8.33793 1.3L0 9.75H3.2069V22.75C3.2069 24.5917 4.59655 26 6.41379 26H24.5862C26.4034 26 27.7931 24.5917 27.7931 22.75V13H24.5862H22.4483H19.4552C18.6 13 17.7448 12.675 17.2103 12.025L9.62069 4.33333L10.2621 3.68333C11.1172 2.81667 12.4 2.81667 13.2552 3.68333ZM6.41379 15.1667H13.8966V22.75H6.41379V15.1667Z" />
    </SvgIcon>
);

export default MeshbotNewIcon;
