import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Box, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import { info_button, hide, wrapper, buttonTabTrue, buttonTabFalse, box } from './InfoButton.module.scss';

const InfoButton = (props) => {
    const { children, tabTrue, tabFalse, infoIconButtonClassName } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHover, setIsHover] = useState(null);
    const timeout = useRef(null);
    const popperRef = useRef(null);

    const className = classNames(info_button, {
        [buttonTabTrue]: tabTrue,
        [buttonTabFalse]: tabFalse,
        [infoIconButtonClassName]: Boolean(infoIconButtonClassName),
    });

    const handlePopper = (e) => {
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
        setOpen((prev) => !prev);
        setIsHover((prev) => !prev);
    };
    const handleMouseEnter = () => {
        if (!isHover) {
            setIsHover(true);
        }

        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
    };
    const handleMouseOut = () => {
        if (isHover) {
            timeout.current = setTimeout(() => {
                setIsHover(false);
                timeout.current = setTimeout(() => {
                    handlePopperClose();
                }, 2500);
            }, 2000);
        }
    };

    const handlePopperClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (open && !popperRef.current.contains(event.target)) {
            handlePopperClose();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [open]);

    return (
        <div className={wrapper}>
            <IconButton className={className} aria-describedby="transition-popper" onClick={handlePopper}>
                <InfoIcon fontSize="small" />
            </IconButton>
            <Popper id="transition-popper" open={open} anchorEl={anchorEl} placement="top" transition ref={popperRef}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={500}>
                        <Paper
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseOut}
                            className={classNames({ [hide]: !isHover })}
                        >
                            <Box className={box}>
                                <IconButton size="small" className={className} onClick={handlePopper}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                                {children}
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default InfoButton;
