import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../../actions';
import { prepareDevices } from '../../../EzloDevices/utils';
import TemplatesPrivate from './TemplatesPrivate';

const mapStateToProps = (state) => ({
    devices: prepareDevices(state.ezlo.data),
    listInstalledPlugins: state.plugins.listInstalledPlugins,
    privateWifiTemplates: state.plugins.privateWifiTemplates,
    loaderList: state.plugins.loaderList,
});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPrivate);
