import { useSelector } from 'react-redux';

/**
 * Custom hook. Provides abstractState which consists of custom variables and their respective values.
 */
const useAbstractState = () => {
    const variablesInfo = useSelector((state) => state.integrations.abstractState);

    return variablesInfo;
};

export default useAbstractState;
