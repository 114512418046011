import { useMemo } from 'react';
import { TRIGGER_FORM } from '../../../../constants/MeshbotConstant';
import { getIsCloudTypeMeshBot } from '../utils';

/**
 * Hook that determines the state of some part of the meshbot
 * @param {'cloud' | 'local' | 'notification' | 'interaction'} currentMeshBotType - current meshbot type
 * @param {'action' | 'triggerForm' | undefined} calledFromPart - string indicating in which section the block is called by default calledFrom === "action";
 * @return {{isCloudMeshBot: boolean, isCloudMeshBotTrigger: boolean}} return ui state meshbot
 * */
export const useCloudMeshBotPartUiState = (currentMeshBotType, calledFromPart = 'action') => {
    if (!currentMeshBotType) {
        throw new Error('Missing currentMeshBotType in useCloudMeshBotPartUiState');
    }

    return useMemo(() => {
        const isCloudMeshBot = getIsCloudTypeMeshBot(currentMeshBotType);

        return { isCloudMeshBot, isCloudMeshBotTrigger: isCloudMeshBot && calledFromPart === TRIGGER_FORM };
    }, [currentMeshBotType, calledFromPart]);
};
