import React from 'react';
import { MenuItem } from '@material-ui/core';

import { info } from '../../../../utils';
import SelectComponent from '../../../SelectComponent';
import { OFFSET_DIRECTION } from '../../../../../../../constants/rulesInSelect';
import { useTranslate } from '../../../../../../../features/languages';

const OffsetDirectionSelectionComponent = ({ offsetDirection, onSelectOffsetDirection }) => {
    const { t } = useTranslate();

    return (
        <SelectComponent
            value={offsetDirection}
            onChange={onSelectOffsetDirection}
            label={info.hints.offset}
            info={info.text.offset}
        >
            {OFFSET_DIRECTION.map((item) => {
                return (
                    <MenuItem key={item.value} value={item.value}>
                        {t(item.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default OffsetDirectionSelectionComponent;
