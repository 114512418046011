import { ACCOUNT_TYPES_VALUES } from '../../../../../constants/Users';

// TODO: hotfix to make styles work again
export const mapAccountTypeValueToStyleName = (accountTypeValue) => {
    if (accountTypeValue === ACCOUNT_TYPES_VALUES.BASIC_USER) {
        return 'Basic';
    } else if (accountTypeValue === ACCOUNT_TYPES_VALUES.ACCOUNT_ADMINISTRATOR) {
        return 'Account';
    } else if (accountTypeValue === ACCOUNT_TYPES_VALUES.NOTIFICATION_ONLY_RECIPIENT) {
        return 'Notification-Only';
    } else if (accountTypeValue === ACCOUNT_TYPES_VALUES.ADVANCED_USER) {
        return 'Advanced';
    }

    return '';
};
