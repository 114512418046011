import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import classes from 'classnames';

import { checkedEmptyKey, transformHeadersToString } from '../../../../../EzloMeshbot/utils';
import { setHeaders } from '../../../../utils';
import * as generator from '../../../../../../../constants/DevicePluginGenerator';
import hash from '../../../../../../../constants/uniqueHash';

import Input from '../../../../../../../components/InputMaterial';

import style from '../../templateCapability.module.scss';
import field from '../RequestFields/requestFields.module.scss';
import { formatNameWithUnderscores } from '../../../../../../../helpers/helpersMeshBot';

const RequestHeaders = (props) => {
    const [headersList, setHeadersList] = useState([]);
    const { action, capabilityId, onSetHeaders } = props;

    useEffect(() => {
        if (action.headers) {
            const listHeaders = action.headers.split('&');

            setHeadersList(
                listHeaders.map((header) => {
                    const headerElements = header.split(':');

                    return { id: hash(), key: headerElements[0], value: headerElements[1] };
                }),
            );
        }
    }, [action.headers]);

    const handleAddHeader = () => {
        const header = { id: hash(), key: `__empty-${hash()}`, value: '', valid: true };

        setHeadersList([...headersList, header]);
    };

    const handleDelete = (id) => {
        const result = headersList.filter((item) => item.id !== id);
        const value = transformHeadersToString(result);

        onSetHeaders(value, generator.HEADERS, capabilityId, action?.id);
        setHeadersList(result);
    };

    const handleChangeHeaders = (e, field, id) => {
        const { value } = e.target;

        setHeadersList(setHeaders(headersList, id, field, value));
    };

    const validationHeader = () => {
        const value = transformHeadersToString(headersList);

        onSetHeaders(value, generator.HEADERS, capabilityId, action?.id);
    };

    return (
        <div className={style.templateCapability__actions_headers}>
            <p className={field.requestFields__label}>Set of custom HTTP headers</p>
            {headersList.map((item) => {
                return (
                    <div key={item.id} className={field.requestFields__headersItem}>
                        <FormControl
                            className={classes(field.requestFields__headersItem_input, { error: item.key === '' })}
                        >
                            <Input
                                label={formatNameWithUnderscores(generator.KEY)}
                                error={!checkedEmptyKey(item.key)}
                                placeholder="key header"
                                value={checkedEmptyKey(item.key)}
                                onBlur={validationHeader}
                                onChange={(e) => handleChangeHeaders(e, generator.KEY, item.id)}
                            />
                        </FormControl>
                        <FormControl
                            className={classes(field.requestFields__headersItem_input, { error: item.value === '' })}
                        >
                            <Input
                                label={formatNameWithUnderscores(generator.VALUE)}
                                error={!checkedEmptyKey(item.value)}
                                placeholder="value header"
                                value={item.value}
                                disabled={!checkedEmptyKey(item.key)}
                                onBlur={validationHeader}
                                onChange={(e) => handleChangeHeaders(e, generator.VALUE, item.id)}
                            />
                        </FormControl>
                        <IconButton
                            color="secondary"
                            className={field.requestFields__buttonDelete}
                            onClick={() => handleDelete(item.id)}
                        >
                            <DeleteIcon color="action" />
                        </IconButton>
                    </div>
                );
            })}
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                className={field.requestFields__buttonAdd}
                onClick={handleAddHeader}
            >
                Add header
            </Button>
        </div>
    );
};

RequestHeaders.propTypes = {
    action: PropTypes.object,
    capabilityId: PropTypes.string,
    onSetHeaders: PropTypes.func,
};

export default RequestHeaders;
