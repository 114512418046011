import React from 'react';

import DeviceAssociationControllerInfo from '../DeviceAssociationControllerInfo';
import useTooltip from '../../hooks/useToolTip';

import styles from '../../DeviceAssociationsList/DeviceAssociationList.module.scss';
import { ControllerIcon } from '../../../../../assets/icons';

const DeviceAssociationBlockWithController = (props) => {
    const { isConnected, serial, deviceInfo, device } = props;
    const { showTooltip, tooltipRef, handleIconClick, hideToolTip } = useTooltip();

    return (
        <>
            <div className={styles.dataDevice}>
                <span className={`${styles.controllerIconWrapper} ${!isConnected && styles.controllerOffline}`}>
                    <ControllerIcon onClick={handleIconClick} />
                    {showTooltip && (
                        <div className={` ${styles.tooltip} `} ref={tooltipRef}>
                            <DeviceAssociationControllerInfo
                                isConnected={isConnected}
                                serial={serial}
                                deviceInfo={deviceInfo}
                                device={device}
                                hideToolTip={hideToolTip}
                            />
                        </div>
                    )}
                </span>
                <span className={`${styles.withControllerSerial} ${styles.targetDeviceName}`}>{serial}</span>
            </div>
        </>
    );
};

export default DeviceAssociationBlockWithController;
