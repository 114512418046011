import React from 'react';
import { Button } from '@material-ui/core';

import CreateLabelButton from '../LabelsDropdown/CreateLabelButton';
import useLabelDropdownContext from '../../hooks/useLabelDropdownContext';
import { useTranslate } from '../../../../languages';
import { EZLOGIC_TITLE_APPLY } from '../../../../../constants/language_tokens';

import styles from '../LabelsDropdown/styles.module.scss';

const DropdownPopperButtons = ({ toggleOpenDialog }) => {
    const context = useLabelDropdownContext();
    const { t } = useTranslate();

    if (context.isEdit) {
        return (
            <div className={styles.createLabelButtonWrapper}>
                <Button color="primary" fullWidth onClick={context.onApplyClick}>
                    {t(EZLOGIC_TITLE_APPLY)}
                </Button>
            </div>
        );
    }

    return <CreateLabelButton onClick={toggleOpenDialog} />;
};

export default DropdownPopperButtons;
