import React, { useCallback, useMemo } from 'react';

import { buildUrlForCopy, determineIsSelectedImg } from '../../utils';
import ImageCard from './ImageCard';
import useGalleryContext from '../../hooks/useGalleryContext';

const ImageCardContainer = ({ imageData: { uuid, link, filename } }) => {
    const { toggleClickImg, selectedImgUuids } = useGalleryContext();
    const copyUrl = useMemo(() => buildUrlForCopy(link), [link]);
    const handlerClickImg = useCallback(() => toggleClickImg(uuid), [uuid]);
    const isSelectedImg = useMemo(() => determineIsSelectedImg(uuid, selectedImgUuids), [uuid, selectedImgUuids]);

    return (
        <ImageCard
            onClickImg={handlerClickImg}
            isSelectedImg={isSelectedImg}
            imageSrc={link}
            copyText={copyUrl}
            filename={filename}
        />
    );
};

export default ImageCardContainer;
