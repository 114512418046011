import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import './devicesHeader.scss';
import { EZLOGIC_TITLE_SEARCH_DEVICES } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DeviceHeader = (props) => {
    const { value, onSearchValueChange } = props;
    const { t } = useTranslate();

    return (
        <header className="devices-header">
            <div className="devices-header__block">
                <SearchIcon className="devices-header__icon" />
                <TextField
                    id="outlined-multiline-flexible"
                    placeholder={t(EZLOGIC_TITLE_SEARCH_DEVICES)}
                    value={value}
                    onChange={onSearchValueChange}
                    variant="outlined"
                />
            </div>
        </header>
    );
};

export default DeviceHeader;

DeviceHeader.propTypes = {
    value: PropTypes.string,
    onHandlerChange: PropTypes.func,
};
