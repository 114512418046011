import React, { createContext, useState } from 'react';

export const DragAndDropContext = createContext();

const DragAndDropWithContext = ({ children }) => {
    const [isDragging, setIsDragging] = useState(false);

    return <DragAndDropContext.Provider value={{ isDragging, setIsDragging }}>{children}</DragAndDropContext.Provider>;
};

export default DragAndDropWithContext;
