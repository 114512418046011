import React from 'react';

import { useTranslate } from '../../../languages';
import { IcControlInfo32 } from '../../../../assets/icons';
import { EZLOGIC_TITLE_ENABLED_UNTIL } from '../../../../constants/language_tokens';

import styles from '../../components/InstallerAccessRights/installerAccess.module.scss';

const InstallerAccessRightsInfo = ({ finalTime }) => {
    const { t } = useTranslate();

    return (
        <>
            {finalTime && (
                <p className={styles['installer-access-time']}>
                    <span className={styles['control-info-icon']}>
                        <IcControlInfo32 />
                    </span>
                    {t(EZLOGIC_TITLE_ENABLED_UNTIL)} {finalTime}
                </p>
            )}
        </>
    );
};

export default InstallerAccessRightsInfo;
