import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Collapse, List, ListItem, useMediaQuery } from '@material-ui/core';

import { WEBUI_VERSION } from '../../constants/App';
import DrawerMenu from './DrawerMenu';
import meta from '../../meta/';

import './style.scss';
import {
    checkVeraUser,
    getMenuCustomizationData,
    goToEzloPage,
    updatedMenuListItems,
    updateNavigationMenuItemsBySsoType,
} from './utils';
import oemManager from '../../services/oem';
import { HELP } from '../Ezlo/EzloCustomization/constants';
import actions from '../../constants/ActionTypes/Navigation';
import AppVersion from './components/AppVersion';
import { MENU_ITEMS_IDS, MENU_ITEMS_TITLE, supportObj } from './constants';
import * as localStorageKeys from '../../constants/localStorageKeys';
import { supportActions } from '../../actions/SupportActions';
import { useTranslate } from '../../features/languages';
import { AppLogo, DropDownUpIcon, LeftMenuSupportIcon, LogoutIcon } from '../../assets/icons';

const Navigation = (props) => {
    const { isCustomizationProgressing, currentPkAccount } = props.redux.state;
    const { confirmUserAction } = props.redux.actions.MainAction;
    const oemTemplateMasterPkAccount = oemManager.oem.getOemTemplateMasterPkAccount();
    const userType = checkVeraUser();
    const { t } = useTranslate();
    const isOemTemplateMasterUser =
        oemTemplateMasterPkAccount && oemTemplateMasterPkAccount === currentPkAccount?.toString();

    // used to track open/close menu state
    const [menu, setMenu] = useState({ settings: false });
    const [menuPlugin, setMenuPlugin] = useState({ settings: false });
    // eslint-disable-next-line
    const [activeSubmenu, setActiveSubmenu] = useState('');
    // eslint-disable-next-line
    const [activeSubmenuPlugin, setActiveSubmenuPlugin] = useState('');
    const dispatch = useDispatch();
    const menuBarData = useSelector((state) => state.ezloCustomization?.menuBar);
    // const groupsList = useSelector((state) => state.groups.groups);
    const activeMenuItems = useSelector((state) => state.navigation.activeMenuItems);

    // TODO: need to check and apply refactoring later for controller with grouping.
    const isControllerAvailable = useSelector((state) => state.groups?.allControllersInfo)?.length;

    // TODO: move all menu items filtering to the separate function
    const updatedMenuItems = useMemo(() => {
        return getMenuCustomizationData(menuBarData, isControllerAvailable);
    }, [menuBarData, isControllerAvailable]);
    const updatedMenuByUserType = updatedMenuListItems(updatedMenuItems, userType, isOemTemplateMasterUser);
    // TODO: temp solution, should be moved to store
    const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
    const updatedMenu = updateNavigationMenuItemsBySsoType(updatedMenuByUserType, ssoType);

    const handleLogout = () => {
        const { logout } = props.redux.actions.GenericActions;

        confirmUserAction(() => logout());
    };

    const hasSubItems = (menuItem) => {
        return menuItem && menuItem.subItems && menuItem.subItems.length > 0;
    };

    /**
     * Used to expand/collapse nested menu item list
     * */
    const toggleMenuItem = (menuItemId, stateMenu, setFunc) => {
        setFunc({ ...stateMenu, [menuItemId]: !stateMenu[menuItemId] });
    };

    /**
     * Handle menu and submenu items click
     * */

    const handleMenuItemClick = (menuItem, menuLevel, isSubmenu) => () => {
        let activeVariableFunc = setActiveSubmenu;
        let variableMenu = menu;
        let variableMenuFunc = setMenu;

        switch (menuLevel) {
            case 'first':
                activeVariableFunc = setActiveSubmenuPlugin;
                variableMenu = menu;
                variableMenuFunc = setMenu;
                break;
            case 'second':
                activeVariableFunc = setActiveSubmenuPlugin;
                variableMenu = menuPlugin;
                variableMenuFunc = setMenuPlugin;
                break;
            default:
                break;
        }

        if (isSubmenu) {
            activeVariableFunc(menuItem.id);
        } else {
            activeVariableFunc('');
        }

        if (hasSubItems(menuItem)) {
            return toggleMenuItem(menuItem.id, variableMenu, variableMenuFunc, updatedMenu);
        }

        if (menuItem.id === HELP) {
            return dispatch(supportActions.getDataFromKvs(menuItem, confirmUserAction, props));
        }

        return confirmUserAction(() => {
            dispatch({ type: actions.SET_ACTIVE_MENU_ITEM, data: menuItem?.willChange || [] });
            goToEzloPage(menuItem.link, menuItem.isAbsolutePath, props);
        });
    };

    const isSubItemsOpened = (menuItem, stateMenu) => {
        return menuItem && stateMenu[menuItem.id];
    };

    const renderToggleIcon = (menuItem, stateMenu) => {
        if (hasSubItems(menuItem)) {
            return (
                <DropDownUpIcon
                    className={classNames('menu-item__arrow', { 'menu-item__arrow_down': !stateMenu[menuItem.id] })}
                />
            );
        }
    };

    const getSkeletonItem = (menuItem) => {
        return (
            <React.Fragment key={menuItem.id}>
                <ListItem className={'menu-item menu-item_loading'}>
                    <div className="menu-item__icon">
                        <span className="skeleton-icon" />
                    </div>
                    <div className="menu-item__title">
                        <div className="skeleton-title" />
                    </div>
                </ListItem>
                {hasSubItems(menuItem) && (
                    <List component="div" disablePadding className="menu">
                        {menuItem.subItems.map((subItem) => {
                            return (
                                <ListItem key={subItem.id} className={'menu-item menu-item_loading'}>
                                    <div className="menu-item__title" style={{ marginLeft: '60px' }}>
                                        <div className="skeleton-title" />
                                    </div>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </React.Fragment>
        );
    };

    const getClassName = (menuItem) => {
        if (
            activeMenuItems.includes(menuItem.id) &&
            !menuItem?.subItems &&
            menuItem.id !== MENU_ITEMS_IDS.SYSTEM_HISTORY
        ) {
            return 'sub_checked menu-tem';
        } else if (
            (activeMenuItems.includes(menuItem.id) && menuItem?.subItems) ||
            (activeMenuItems.includes(menuItem.id) && menuItem.id === MENU_ITEMS_IDS.SYSTEM_HISTORY)
        ) {
            return 'checked';
        }

        return '';
    };

    const getMenuItem = (menuItem) => {
        return (
            <Fragment key={menuItem.id}>
                <ListItem
                    button
                    className={`menu-item ${getClassName(menuItem)}`}
                    onClick={handleMenuItemClick(menuItem, 'first')}
                >
                    <div className="menu-item__icon">{menuItem.icon}</div>
                    <div className="menu-item__title" title={t(menuItem?.title)}>
                        {t(menuItem?.title)}
                    </div>
                    {renderToggleIcon(menuItem, menu)}
                </ListItem>

                <Collapse in={isSubItemsOpened(menuItem, menu)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {menuItem?.subItems?.map((subItem) => {
                            return (
                                <Fragment key={subItem.id}>
                                    <ListItem
                                        button
                                        onClick={handleMenuItemClick(
                                            subItem,
                                            'first',
                                            subItem.id !== 'settings_plugins' && true,
                                        )}
                                        className={`submenu__item ${getClassName(subItem)}`}
                                    >
                                        <div className="sub_menu-item_title" title={t(subItem.title)}>
                                            {t(subItem.title)}
                                        </div>
                                        {renderToggleIcon(subItem, menu)}
                                    </ListItem>
                                    <Collapse
                                        key={`collapse_${subItem.id}`}
                                        in={isSubItemsOpened(subItem, menu)}
                                        timeout="auto"
                                        collapsedSize={0}
                                    >
                                        <List component="div" disablePadding className="submenu">
                                            {subItem?.subItems?.map((item) => {
                                                return (
                                                    <ListItem
                                                        key={item.id}
                                                        button
                                                        onClick={handleMenuItemClick(
                                                            item,
                                                            'second',
                                                            item.id !== 'settings_plugins' && true,
                                                        )}
                                                        className={`submenu__item ${getClassName(item)}`}
                                                    >
                                                        <div className="sub_menu_sub-item_title" title={t(item.title)}>
                                                            {t(item.title)}
                                                        </div>
                                                        {renderToggleIcon(item, menuPlugin)}
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            );
                        })}
                    </List>
                </Collapse>
            </Fragment>
        );
    };

    const isBurgerBtnHidden = useMediaQuery('(min-width: 1201px)');

    return (
        <DrawerMenu>
            <aside className="navigation">
                <div className="logo-container">
                    <div className={`navigation-logo  ${isBurgerBtnHidden ? '' : 'mobile-width'}`}>{<AppLogo />}</div>
                </div>
                <div className="menu-container">
                    <List component="nav" aria-labelledby="navigation" className="menu">
                        {
                            // todo: need to refactor
                            updatedMenu.map((menuItem, indexMenuItem) => {
                                return isCustomizationProgressing
                                    ? getSkeletonItem(menuItem)
                                    : getMenuItem(menuItem, indexMenuItem);
                            })
                        }
                    </List>
                </div>
                <List className="bottom-items-wrapper">
                    <ListItem
                        button
                        key="support"
                        className="support"
                        onClick={handleMenuItemClick(supportObj, 'first')}
                    >
                        <div className="menu-item__icon menu-item__svgicon">
                            <LeftMenuSupportIcon />
                        </div>
                        <div className="menu-item__title">{t(MENU_ITEMS_TITLE.HELP)}</div>
                    </ListItem>
                    <ListItem button key="logout" className="logout" onClick={handleLogout}>
                        <div className="menu-item__icon menu-item__svgicon">
                            <LogoutIcon />
                        </div>
                        <div className="menu-item__title">{t(MENU_ITEMS_TITLE.LOGOUT)}</div>
                        <AppVersion
                            className="menu-item__version"
                            publicVersion={WEBUI_VERSION}
                            buildVersion={meta?.build_version}
                        />
                    </ListItem>
                </List>
            </aside>
        </DrawerMenu>
    );
};

export default Navigation;
