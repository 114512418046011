/**
 * Actual version of WebUI.
 * <br />
 * Given a version number MAJOR.MINOR.PATCH, increment the: <br />
 * MAJOR - version when you make incompatible API changes. <br />
 * MINOR - version when you add functionality in a backwards compatible manner. <br />
 * PATCH - version when you make backwards compatible bug fixes. <br />
 * More info {@link https://semver.org/ here}
 * */
export const WEBUI_VERSION = '1.65.4';

export const REFRESH_FUNCTION_DELAY = 82800000;

/**
 * Defined by Cloud team, boolean + integer
 * */
export const BOOLINT = { TRUE: 1, FALSE: 0 };
export const VERSIONS = { FIRST: '1' };
