import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Button, Modal, Typography } from '@material-ui/core';

import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../constants/URLs';
import { axiosCloudConfig } from '../../../../../api/config';

import { INTEGRATION_BUTTON_TEXT, ACCOUNT_INFO_VALUE } from '../../constants';

import EnrollmentEditDialog from '../EnrollmentEditDialog';
import { useTranslate } from '../../../../../features/languages';
import {
    NUCAL_INTEGRATIONS_ENROLLMENT_ITEM_DELETE_CONFIRMATION_TITLE,
    EZLOGIC_BTN_DELETE_ENROLLMENT_TITLE,
} from '../../../../../constants/language_tokens';
import EzloIntegrationsEnrollmentBox from '../../../EzloIntegrations/components/EzloIntegrationsEnrollmentBox';

import style from './EnrollmentListItem.module.scss';
import styles from '../../../EzloIntegrations/EzloIntegrationsInformation/styles.module.scss';

const EnrollmentListItem = (props) => {
    const { abstract, icon, fetchAbstracts, isIntegrationInfo } = props;
    const { accountsInfo } = useSelector(({ integrations }) => integrations);
    const { uuid } = abstract;
    const [isShowDeleteConfirmDialog, setIsShowDeleteConfirmDialog] = useState(false);
    const [isShowEditDialog, setIsShowEditDialog] = useState(false);
    const { CANCEL, SETTINGS, DELETE } = INTEGRATION_BUTTON_TEXT;

    const { t } = useTranslate();
    const additionInfo = useMemo(() => {
        const isAccountInfo = Object.keys(accountsInfo).length > 0 && accountsInfo[uuid];

        if (isAccountInfo) {
            return isAccountInfo[ACCOUNT_INFO_VALUE.OBFUSCATED_TOKEN] || isAccountInfo[ACCOUNT_INFO_VALUE.EMAIL];
        }

        return '';
    }, [accountsInfo, uuid]);

    const handleAbstractEdit = () => {
        setIsShowEditDialog(true);
    };

    const handleEditAbstractName = async (abstractName) => {
        setIsShowEditDialog(false);
        const requestParams = {
            call: 'abstract_command',
            params: {
                capability: 'name_command',
                command: 'set',
                uuid: uuid,
                parameters: {
                    target_name: abstractName,
                },
            },
            id: uuid,
            version: '1',
        };

        try {
            await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
                method: 'post',
                ...axiosCloudConfig(),
                body: JSON.stringify(requestParams),
            });

            await fetchAbstracts();
        } catch (err) {
            // eslint-disable-next-line
            console.error(err);
        }
    };

    const closeDeleteConfirmDialog = () => {
        setIsShowDeleteConfirmDialog(false);
    };
    const handleAbstractDelete = () => {
        const { onDelete } = props;
        onDelete(abstract);
    };

    const handleDeleteEnrollment = () => {
        handleAbstractDelete();
        closeDeleteConfirmDialog();
        setIsShowEditDialog(false);
    };

    return (
        <>
            <li
                className={isIntegrationInfo ? styles.enrollmentListItem : style.enrollmentListItem}
                key={abstract.uuid}
                title={`${abstract.name}`}
            >
                {isIntegrationInfo ? (
                    <EzloIntegrationsEnrollmentBox
                        abstract={abstract}
                        additionInfo={additionInfo}
                        handleAbstractEdit={handleAbstractEdit}
                        icon={icon}
                    />
                ) : (
                    <div className={style.enrollmentBox}>
                        <div className={style.enrollment}>
                            <div className={style.enrollmentIconContainer}>
                                {icon ? (
                                    <img src={icon} className={style.enrollmentImg} />
                                ) : (
                                    <SettingsApplicationsIcon />
                                )}
                            </div>
                            <div>
                                <div className={style.name}>{abstract.name}</div>
                                <div className={style.info}>{additionInfo}</div>
                            </div>

                            <div className={style.settingsTextButtonBox}>
                                <Typography color="primary" onClick={handleAbstractEdit}>
                                    {t(SETTINGS)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </li>

            <Modal open={isShowDeleteConfirmDialog} onClose={closeDeleteConfirmDialog}>
                <div className="confirmation-modal-content">
                    <h3>{t(EZLOGIC_BTN_DELETE_ENROLLMENT_TITLE)}</h3>
                    <p>{t(NUCAL_INTEGRATIONS_ENROLLMENT_ITEM_DELETE_CONFIRMATION_TITLE)}</p>
                    <div className="button-container">
                        <Button onClick={closeDeleteConfirmDialog} variant="outlined">
                            {t(CANCEL)}
                        </Button>
                        <Button onClick={handleDeleteEnrollment} color="primary" variant="contained">
                            {t(DELETE)}
                        </Button>
                    </div>
                </div>
            </Modal>

            <EnrollmentEditDialog
                isShowEditDialog={isShowEditDialog}
                setIsShowEditDialog={setIsShowEditDialog}
                updateAbstractName={handleEditAbstractName}
                setIsShowDeleteConfirmDialog={setIsShowDeleteConfirmDialog}
                abstract={abstract}
            />
        </>
    );
};

EnrollmentListItem.propTypes = {
    abstract: PropTypes.object.isRequired,
    onOpen: PropTypes.func.isRequired,
};

export default EnrollmentListItem;
