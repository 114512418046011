import React from 'react';

import { PASSWORD_REQUIREMENTS } from '../../../../../constants/Users';
import PasswordRequirements from '../../../../../components/PasswordRequirements';
import { isRequirementDone } from '../../../../../components/PasswordRequirements/utils';

import styles from '../../../../../components/PasswordRequirements/style.module.scss';

const NewPasswordRequirements = ({ passwordValue }) => {
    const getClassName = (requirement) => {
        if (isRequirementDone(requirement, passwordValue)) {
            return styles.isRequirementFulfilled;
        }

        return '';
    };

    return <PasswordRequirements getClassName={getClassName} passwordRequirements={PASSWORD_REQUIREMENTS} />;
};

export default NewPasswordRequirements;
