import React, { useState } from 'react';
import { Box, ClickAwayListener, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import AccountTypesList from '../AccountTypesList';
import AccountPermissionsTable from '../AccountPermissionsTable';

import styles from './styles.module.scss';
import { Info } from '../../../../../assets/icons';
import { mapAccountTypeValueToStyleName } from './utils';

const InfoPopper = ({ label, selectedAccountType }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopper = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    // TODO: hotfix applied to fix styles, see ENWT-3627
    const getActiveAccountTypeClassName = ({ value, className }, isTableBody) => {
        if (isTableBody) {
            const accountTypeClassName = mapAccountTypeValueToStyleName(value);

            return `${className} ${accountTypeClassName}`;
        }

        return selectedAccountType.value === value ? className : '';
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.infoPopperBox}>
                <IconButton onClick={handlePopper}>
                    <Info />
                </IconButton>
                <Popper className={styles.popperContainer} open={open} transition anchorEl={anchorEl}>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={500}>
                            <Paper elevation={3}>
                                <Box className={styles.paperBox}>
                                    <IconButton className={styles.closeBtn} onClick={handlePopper}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                    <h2 className={styles.popperTitle}>{label}</h2>
                                    <AccountTypesList onGetActiveAccountTypeClassName={getActiveAccountTypeClassName} />
                                    <AccountPermissionsTable
                                        selectedAccountType={selectedAccountType}
                                        onGetActiveAccountTypeClassName={getActiveAccountTypeClassName}
                                    />
                                </Box>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default InfoPopper;
