import React from 'react';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import EzloVariablesDataTypesSelect from '../../components/EzloVariablesDataTypesSelect';
import VariablesCommonDataTypes from '../../components/VariablesCommonDataTypes';
import VariablesNameInput from '../../components/VariablesNameInput';
import VariablesActionDataType from '../../components/VariablesActionDataType';
import InputAndColorPicker from '../../../../../components/ColorInputAndColorPickers';
import VariablesTokenDataType from '../../components/VariablesTokenDataType';
import VariablesScalableDataType from '../../components/VariablesScalableDataType';
import ExpressionDelete from '../../ExpressionsAddForm/ExpressionDelete';

import { toast, TOAST_TYPE } from '../../../../../components/Toast';
import { bugsnagNotify } from '../../../../ErrorBoundary/utils';
import { ExpressionsActions } from '../../../../../actions';
import { DATA_TYPES_LIST, COMMON_DATA_TYPES_LIST } from '../../../../../constants/Variables';

import { useTranslate } from '../../../../../features/languages';

import styles from './ExpressionItemInfo.module.scss';
import { EZLOGIC_TITLE_VALUE } from '../../../../../constants/language_tokens';
import {
    EZLOGIC_TITLE_ERROR,
    EZLOGIC_TOAST_EXPRESSION_WAS_NOT_DELETED_MESSAGE,
    EZLOGIC_TOAST_SUCCESSFULLY_DELETED_MESSAGE,
} from '../../../../../constants/language_tokens';

const ExpressionItemInfo = (props) => {
    const {
        serial,
        isExpression,
        isEditing,
        error,
        valueType,
        name,
        code,
        value,
        variableCode,
        scalableType,
        onSetIsEditing,
    } = props;
    const dispatch = useDispatch();
    const expressionValue = typeof value === 'object' ? JSON.stringify(value) : value?.toString();
    const expressionErrorText = typeof error === 'object' ? JSON.stringify(error) : error;

    const { t } = useTranslate();

    const notifyDeletingSuccess = (response) => {
        dispatch(ExpressionsActions.unsubscribeDeletedExpression(serial));
        toast(`"${response.result.name}" ${t(EZLOGIC_TOAST_SUCCESSFULLY_DELETED_MESSAGE)}`, {
            type: TOAST_TYPE.SUCCESS,
        });
    };

    const notifyDeletingError = () => {
        dispatch(ExpressionsActions.unsubscribeDeletedExpression(serial));
        bugsnagNotify(t(EZLOGIC_TOAST_EXPRESSION_WAS_NOT_DELETED_MESSAGE), { type: TOAST_TYPE.ERROR });
        toast(t(EZLOGIC_TOAST_EXPRESSION_WAS_NOT_DELETED_MESSAGE), { type: TOAST_TYPE.ERROR });
    };

    const handleDeleteExpression = () => {
        dispatch(ExpressionsActions.deleteExpression(serial, { name }, notifyDeletingError, t));
        dispatch(ExpressionsActions.subscribeDeletedExpression(serial, notifyDeletingSuccess));
    };

    const handleEditClick = () => {
        onSetIsEditing(true);
    };

    return (
        <li className={styles.expressionItem}>
            <div className={styles.expressionItemData}>
                {!isExpression && <EzloVariablesDataTypesSelect valueType={valueType} disabled={!isEditing} />}
                {isExpression ? (
                    <div className={styles.expressionItemTitle}>{name}</div>
                ) : (
                    valueType !== DATA_TYPES_LIST.TYPE_SCALABLE && (
                        <VariablesNameInput name={name} disabled={!isEditing} />
                    )
                )}
                {valueType !== DATA_TYPES_LIST.TYPE_SCALABLE && (
                    <div className={styles.expressionItemEqualSymbol}>=</div>
                )}
                <div className={isExpression ? styles.expressionItemInfo : styles.variableItemInfo}>
                    {isExpression && <div className={styles.expressionItemBody}>{code}</div>}
                    {!isExpression && COMMON_DATA_TYPES_LIST.includes(valueType) && (
                        <VariablesCommonDataTypes code={variableCode} dataType={valueType} disabled={!isEditing} />
                    )}
                    {valueType === DATA_TYPES_LIST.TYPE_ACTION && (
                        <VariablesActionDataType code={variableCode} disabled={!isEditing} />
                    )}
                    {valueType === DATA_TYPES_LIST.TYPE_RGB && (
                        <InputAndColorPicker isVariablePage={true} value={variableCode} disabled={!isEditing} />
                    )}
                    {valueType === DATA_TYPES_LIST.TYPE_TOKEN && (
                        <VariablesTokenDataType code={variableCode} disabled={!isEditing} />
                    )}
                    {valueType === DATA_TYPES_LIST.TYPE_SCALABLE && (
                        <VariablesScalableDataType
                            code={variableCode}
                            name={name}
                            scalableType={scalableType}
                            disabled={!isEditing}
                        />
                    )}
                    {(value || value === false) && isExpression && (
                        <p className={`${styles.expressionItemText} ${styles.valueColor}`}>
                            {t(EZLOGIC_TITLE_VALUE).toUpperCase()}:{expressionValue}
                        </p>
                    )}
                    {error && (
                        <p className={`${styles.expressionItemText} ${styles.errorColor}`}>
                            {t(EZLOGIC_TITLE_ERROR)}:{expressionErrorText}
                        </p>
                    )}
                </div>
            </div>
            <div className={styles.expressionItemActions}>
                <IconButton onClick={handleEditClick}>
                    <Edit fontSize="large" color="primary" />
                </IconButton>
                <ExpressionDelete name={name} onDeleteExpression={handleDeleteExpression} />
            </div>
        </li>
    );
};

export default ExpressionItemInfo;
