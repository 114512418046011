import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';

import styles from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_SAVE } from '../../../../../constants/language_tokens';

const SaveButton = ({ isDisabled }) => {
    const { t } = useTranslate();
    const { values, initialValues } = useFormikContext();
    const isUnchangedForm = JSON.stringify(initialValues) === JSON.stringify(values);

    return (
        <div className={styles.saveButton}>
            <Button
                disabled={isDisabled || isUnchangedForm}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
            >
                {t(EZLOGIC_BUTTON_SAVE)}
            </Button>
        </div>
    );
};

export default SaveButton;
