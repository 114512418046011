import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { BOOLEAN_TYPE_VALUES } from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const LocalVariableBooleanTypeSelect = (props) => {
    const { value, onSetVariableValue } = props;
    const { t } = useTranslate();

    return (
        <SelectComponent value={value} info={info.text.value} label={info.hints.value} onChange={onSetVariableValue}>
            {BOOLEAN_TYPE_VALUES.map((type, index) => {
                return (
                    <MenuItem key={index} value={type.value}>
                        {t(type.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default LocalVariableBooleanTypeSelect;
