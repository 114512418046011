import React, { memo, useMemo } from 'react';

import IntegrationSectionHeader from '../IntegrationSectionHeader';
import EnrollmentsList from '../EnrollmentsList/EnrollmentsList';

const IntegrationsListContent = (props) => {
    const { detailedIntegrations, integration, onIntegrationEdit, onIntegrationDelete, fetchAbstracts } = props;

    const { icon } = useMemo(() => {
        return detailedIntegrations?.find(({ uuid }) => uuid === integration.uuid) || {};
    }, [integration, detailedIntegrations]);

    return (
        <div>
            <IntegrationSectionHeader
                integration={integration}
                abstractsAmount={integration.abstracts.length}
                icon={icon}
            />
            <EnrollmentsList
                abstracts={integration.abstracts}
                onEdit={onIntegrationEdit}
                onDelete={onIntegrationDelete(integration.uuid)}
                fetchAbstracts={fetchAbstracts}
                icon={icon}
            />
        </div>
    );
};

export default memo(IntegrationsListContent);
