import React, { useState, useEffect, useMemo } from 'react';
import { Button, LinearProgress } from '@material-ui/core';

import { EnrollByTokenModal } from './EnrollByTokenModal';
import OpenButton from '../OpenButton';
import SearchPopover from '../../../../../../../../../components/SearchPopover';
import { IntegrationModal } from '../../../../../../../EzloParjectsSettings/NewEnrollmentPage/IntegrationModal';

import { ACCOUNT_INFO_VALUE } from '../../../../../../../EzloParjectsSettings/constants';
import { getAccountInfo, getAllAbstractsByUuid, getEnrolmentNumber, isEnrollmentByToken } from '../../utils';
import { apiEnrollByAuthUrl } from '../../PaasAPI/paas-api-requests';
import { useStartPollingToGetAbstracts } from '../../hooks/useStartPollingToGetAbstracts';
import { useParseIntegrationPreview } from '../../hooks/useParseIntegrationPreview';

import { IntegrationsActions, MainAction } from '../../../../../../../../../actions/index';

import styles from './Account.module.scss';
import {
    EZLOGIC_PLACEHOLDER_ACCOUNTS,
    EZLOGIC_TITLE_ADD_ACCOUNT,
    EZLOGIC_TITLE_SELECT_ACCOUNT,
} from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';

const Account = (props) => {
    const { uuid, name, parjectIcon, handleAccountSelect, accountsInfo, capabilities } = props;

    const additionInfo = useMemo(() => {
        const isAccountInfo = accountsInfo ? accountsInfo?.[uuid] : null;

        if (isAccountInfo) {
            return isAccountInfo[ACCOUNT_INFO_VALUE.OBFUSCATED_TOKEN] || isAccountInfo[ACCOUNT_INFO_VALUE.EMAIL];
        }

        return '';
    }, [accountsInfo, uuid]);

    return (
        <div
            data-testid={uuid}
            key={uuid}
            className={styles.paas_account}
            onClick={() => handleAccountSelect(uuid, capabilities)}
        >
            <div className={styles.inner}>
                {<img className={styles.icon} src={parjectIcon} alt="" />}
                <div className={styles.name_block}>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.info}>{additionInfo}</span>
                </div>
            </div>
        </div>
    );
};

const AccountsList = (props) => {
    const { accounts, filter, handleCloseAbstract, accountsInfo, onUpdateMeshbot, foundIntegration } = props;

    const [filteredAccounts, setFilteredAccounts] = useState([]);

    useEffect(() => {
        if (Array.isArray(accounts)) {
            const filteredAccounts = accounts
                .filter(({ name }) => {
                    return !name.toLowerCase().indexOf(filter.toLowerCase());
                })
                .sort((a, b) => {
                    return getEnrolmentNumber(b.name) - getEnrolmentNumber(a.name);
                });

            setFilteredAccounts(filteredAccounts);
        }
    }, [filter, accounts]);

    const handleAccountSelect = (uuid, capabilities) => {
        handleCloseAbstract();
        onUpdateMeshbot({
            accountUuid: uuid,
            fields: {},
            requiredFields: undefined,
            method: '',
            code: '',
            capabilities,
        });
    };

    return (
        <div className={styles.account_list}>
            {filteredAccounts.map(({ uuid, name, capabilities }) => (
                <Account
                    key={uuid}
                    uuid={uuid}
                    name={name}
                    capabilities={capabilities}
                    parjectIcon={foundIntegration?.icon}
                    handleAccountSelect={handleAccountSelect}
                    accountsInfo={accountsInfo}
                />
            ))}
        </div>
    );
};

export const Accounts = (props) => {
    const { PAASAuthUuid, abstracts, isAbstractsLoading, abstractUuid, onUpdateMeshbot, foundIntegration } = props;

    const auth = useParseIntegrationPreview(foundIntegration.uuid);
    const { parjectGeneratedId, authenticationType } = auth;
    const [anchorElAbstract, setAnchorElAbstract] = useState(null);
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [accountsInfo, setAccountsInfo] = useState({});
    const [filter, setFilter] = useState('');
    const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
    const [isEnrolmentLoading, setIsEnrolmentLoading] = useState(false);
    const { t } = useTranslate();

    const enrollments = getAllAbstractsByUuid(abstracts, foundIntegration.uuid);

    const currentAbstract = useMemo(() => {
        return abstracts?.find(({ uuid }) => uuid === abstractUuid);
    }, [abstractUuid]);

    const stopEnrollmentLoading = () => {
        setIsEnrolmentLoading(false);
    };

    const { startPollingToGetAbstracts } = useStartPollingToGetAbstracts(
        abstracts,
        foundIntegration.uuid,
        enrollments,
        stopEnrollmentLoading,
    );

    useEffect(() => {
        setAccounts(enrollments);
    }, [foundIntegration.uuid]);

    useEffect(() => {
        if (isEnrolmentLoading) {
            startPollingToGetAbstracts();
        }
        setAccounts(enrollments);
    }, [isEnrolmentLoading]);

    const handleGetAccountsInfo = async (accounts) => {
        setAccountsInfo(await getAccountInfo(accounts));
    };
    useEffect(() => {
        if (accounts.length > 0) {
            handleGetAccountsInfo(accounts);
        }
    }, [accounts]);

    const handleCloseAbstract = () => {
        setAnchorElAbstract(null);
        setIsEnrolmentLoading(false);
    };

    const handleClickAbstract = (event) => {
        handleCloseAbstract();
        setAnchorElAbstract(event.currentTarget);
    };

    const handleCloseIntegrationModal = () => {
        setIsIntegrationModalOpen(false);
    };

    const fetchInitialInfo = () => {
        IntegrationsActions.fetchAbstractListDetailed();
        IntegrationsActions.setUserIntegrations();
        MainAction.getAbstractsList();
    };

    const startAuthentication = async () => {
        const { uuid: currentParjectUuid } = foundIntegration;

        if (!currentParjectUuid) {
            return;
        }

        if (!authenticationType) {
            setIsIntegrationModalOpen(true);
        }

        if (isEnrollmentByToken(authenticationType)) {
            return setIsTokenModalOpen(true);
        }

        const authUrl = await apiEnrollByAuthUrl(PAASAuthUuid, authenticationType, parjectGeneratedId);

        if (authUrl) {
            window.open(authUrl, '_blank');

            setIsEnrolmentLoading(true);
        }
    };

    return (
        <>
            <OpenButton
                title={currentAbstract?.name}
                onHandleClick={handleClickAbstract}
                icon={foundIntegration?.icon}
                defaultTitle={t(EZLOGIC_TITLE_SELECT_ACCOUNT)}
                anchorElAbstract={anchorElAbstract}
            />

            <SearchPopover
                placeholder={t(EZLOGIC_PLACEHOLDER_ACCOUNTS)}
                anchor={anchorElAbstract}
                onChangeFilter={setFilter}
                handleClose={handleCloseAbstract}
            >
                {Boolean(isEnrolmentLoading || isAbstractsLoading) && <LinearProgress />}
                <AccountsList
                    accounts={accounts}
                    filter={filter}
                    handleCloseAbstract={handleCloseAbstract}
                    accountsInfo={accountsInfo}
                    onUpdateMeshbot={onUpdateMeshbot}
                    foundIntegration={foundIntegration}
                />

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!PAASAuthUuid}
                    className={styles.buttonAdd}
                    onClick={startAuthentication}
                >
                    {t(EZLOGIC_TITLE_ADD_ACCOUNT)}
                </Button>
            </SearchPopover>

            <EnrollByTokenModal
                auth={auth}
                parjectName={foundIntegration?.name}
                PAASAuthUuid={PAASAuthUuid}
                isTokenModalOpen={isTokenModalOpen}
                authExplanation={foundIntegration?.authExplanation}
                setIsTokenModalOpen={setIsTokenModalOpen}
                setIsEnrolmentLoading={setIsEnrolmentLoading}
                startPollingToGetAbstracts={startPollingToGetAbstracts}
                shouldOpenInModal
            />
            <IntegrationModal
                open={isIntegrationModalOpen}
                onClose={handleCloseIntegrationModal}
                isEnrolmentLoading={isEnrolmentLoading}
                setIsEnrolmentLoading={setIsEnrolmentLoading}
                abstracts={abstracts}
                integration={foundIntegration}
                onHandleClose={handleCloseIntegrationModal}
                onFetchInitialInfo={fetchInitialInfo}
            />
        </>
    );
};
