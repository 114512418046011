import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const InteractionIcon = () => (
    <SvgIcon color="primary" viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4487 21.4487V24.3088L28.5889 19.3039L21.4487 14.2993V17.159H19.948C18.1281 17.159 16.4524 16.1682 15.5752 14.5735C13.245 10.3369 9.74389 3.97129 9.74389 3.97129C9.36708 3.28562 8.64675 2.86008 7.86464 2.86008H0V7.14982H6.59625L11.8167 16.6408C13.4475 19.6065 16.5636 21.4487 19.948 21.4487H21.4487Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.53316 13.6379L10.5638 17.3299C10.7 17.578 10.8455 17.8196 10.9999 18.054L9.74389 20.3375C9.36708 21.0232 8.64675 21.4487 7.86464 21.4487H0V17.159H6.59625L8.53316 13.6379V13.6379ZM12.6887 6.35843C14.4314 4.16997 17.0945 2.86006 19.948 2.86006H21.4487V0L28.5889 5.00493L21.4487 10.0095V7.1498H19.948C18.1281 7.1498 16.4524 8.14056 15.5752 9.73531L15.0608 10.6708L12.6887 6.35843V6.35843Z"
        />
    </SvgIcon>
);

export default InteractionIcon;
