import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, LinearProgress } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { EzloOEMNotificationsDuplicateModal } from './EzloOEMNotificationsModal';
import EzloNotificationTemplateSelectModal from '../EzloNotificationTemplate/components/EzloNotificationTemplateSelectModal';

import { CloudMeshbotActions } from '../../../actions';

import * as url from '../../../constants/URLs';
import * as notification from '../../../constants/NotificationTemplates';
import { MESHBOT_TYPES } from '../EzloMeshbots/constants';
import hash from '../../../constants/uniqueHash';

import { createRequestBody } from '../EzloNotificationTemplate/utils';

import {
    notificationsWrapper,
    title,
    notificationInformationWrapper,
    createTemplateBtn,
    titleWrapper,
} from './EzloOEMNotiications.module.scss';
import NotificationActions from '../../../actions/NotificationActions';
import EzloOEMNotificationTemplatesTableContainer from './EzloOEMNotificationTemplatesTableCantainer';
import styles from '../EzloAccount/components/Header/style.module.scss';
import { useTranslate } from '../../../features/languages';
import {
    EZLOGIC_TITLE_CREATE_NEW_TEMPLATE,
    EZLOGIC_TITLE_SYSTEM_NOTIFICATION_TEMPLATES,
    EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_ENABLED_AND_DISABLED,
    EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_TEMPLATES,
} from '../../../constants/language_tokens';
import { Info } from '../../../assets/icons';

const EzloOEMNTemplateNotifications = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [sortModel, setSortModel] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [uuid, setUuid] = useState('');
    const { notificationTemplates, isLoading } = useSelector((state) => state.notifications);
    const { t } = useTranslate();

    const onClickCreateNewTemplateButton = () => {
        dispatch(CloudMeshbotActions.clearCloudCurrentScene());
        setIsModalVisible(true);
    };

    const onDuplicate = (uuid, title) => {
        setUuid(uuid);
        setIsDuplicateModalVisible((prevState) => !prevState);
        setTemplateName(notification.copy + ' ' + title);
    };

    const onSaveDuplicateTemplate = () => {
        handleOpen();
        const currentNotification = notificationTemplates.find((notification) => notification.uuid === uuid);

        const params = createRequestBody({
            typeAction: notification.CREATE,
            name: templateName,
            optionType: MESHBOT_TYPES.NOTIFICATION_TEMPLATE,
            templateCapability: currentNotification.capability,
            meshBotDefinition: currentNotification.meshbot_definition,
            auto_create: notification.DISABLED,
            id: hash(),
            type: currentNotification.type,
        });

        dispatch(NotificationActions.setNotificationTemplate(params));
    };

    const onChangeTemplateName = (e) => {
        const { value } = e.target;

        setTemplateName(value);
    };

    const onDelete = (uuid) => {
        const params = {
            action: 'delete',
            delete: 1,
            uuid,
        };
        dispatch(NotificationActions.setNotificationTemplate(params));
    };

    const onEdit = (meshbot_definition, uuid) => {
        history.push(url.NOTIFICATIONS_EDIT(uuid));
    };

    const handleOpen = () => {
        setIsDuplicateModalVisible((prevState) => !prevState);
    };

    const handleSetActive = useCallback(
        (e, id) => {
            const params = {
                action: notification.UPDATE,
                uuid: id,
                auto_create: e.target.checked ? notification.ENABLED : notification.DISABLED,
            };

            dispatch(NotificationActions.setNotificationTemplate(params));
        },
        [notificationTemplates],
    );

    const handleOpenNotificationTemplateTypesModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <section className={notificationsWrapper}>
            {isLoading && (
                <Box className={styles.linearProgressBoxPlugins}>
                    <LinearProgress className={styles.linearProgress} />
                </Box>
            )}
            <div className={titleWrapper}>
                <h1 className={title}>{t(EZLOGIC_TITLE_SYSTEM_NOTIFICATION_TEMPLATES)}</h1>
                <Button
                    color="primary"
                    variant="contained"
                    className={createTemplateBtn}
                    onClick={onClickCreateNewTemplateButton}
                >
                    <Add />
                    <span>{t(EZLOGIC_TITLE_CREATE_NEW_TEMPLATE)}</span>
                </Button>
            </div>
            {notificationTemplates && (
                <EzloOEMNotificationTemplatesTableContainer
                    notificationTemplates={notificationTemplates}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    uuid={uuid}
                    onDuplicate={onDuplicate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    handleSetActive={handleSetActive}
                />
            )}
            <div className={notificationInformationWrapper}>
                <p>
                    <Info />
                    {t(EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_TEMPLATES)}
                </p>
                <p>
                    <Info />
                    {t(EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_ENABLED_AND_DISABLED)}
                </p>
            </div>
            <EzloOEMNotificationsDuplicateModal
                onChangeTemplateName={onChangeTemplateName}
                templateName={templateName}
                isDuplicateModalVisible={isDuplicateModalVisible}
                handleOpen={handleOpen}
                onSave={onSaveDuplicateTemplate}
                handleSetActive={handleSetActive}
                onDelete={onDelete}
            />
            {isModalVisible && (
                <EzloNotificationTemplateSelectModal
                    isModalVisible={isModalVisible}
                    onHandleOpenNotificationTemplateTypesModal={handleOpenNotificationTemplateTypesModal}
                />
            )}
        </section>
    );
};

export default EzloOEMNTemplateNotifications;
