import React from 'react';
import { Button, Dialog } from '../../components';
import { useTranslate } from '../../features/languages';
// import { t } from '../../helpers/language';

/**
 * Create a new confirm dialog.
 *
 * <ConfirmDialog
 *    open={true|false},
 *    onHide={function () {}},
 *    onConfirm={function () {}}
 * >
 *     ..........
 * </ConfirmDialog>
 */
const ConfirmDialog = ({ title, open, onHide, onConfirm, disabled, children, bodyStyle }) => {
    const { t } = useTranslate();
    const actions = [
        <Button key="button1" className="btn-rounded" onClick={onConfirm} focused={true} disabled={disabled}>
            {t`ezlogic.popup.button.yes.title`}
        </Button>,
        <Button key="button2" className="btn-transparent" onClick={onHide} disabled={disabled}>
            {t`ezlo.popup.button.no.title`}
        </Button>,
    ];

    return (
        <Dialog
            title={title || t`ezlo.popup.title.confirmation`}
            open={open}
            onHide={onHide}
            actions={actions}
            bodyStyle={bodyStyle}
        >
            {children}
        </Dialog>
    );
};

ConfirmDialog.defaultProps = {
    open: false,
    onHide: function () {},
    onConfirm: function () {},
    disabled: false,
    bodyStyle: {},
};

export default ConfirmDialog;
