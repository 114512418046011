/**
 * Returns state for Authorization Context
 * @param {string} partner - "partner" GET param value. See possible values {@link PARTNER_GET_PARAM_VALUES here}
 * @returns auth context state
 *
 * @see PARTNER_GET_PARAM_VALUES
 * */
export const buildAuthContextState = (partner) => {
    return { partner };
};
