import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { WEBUI_VERSION } from '../../constants/App';
import { NAME } from '../../constants/localStorageKeys';
import { BUGSNAG_ENVS, getBugsnagEnv } from './utils';

Bugsnag.start({
    apiKey: 'eef878b4530654af70311e8bc2ef9ce4',
    appVersion: WEBUI_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage: getBugsnagEnv(),
    enabledReleaseStages: [BUGSNAG_ENVS.PROD, BUGSNAG_ENVS.STAGE],
    enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
    onError: function (event) {
        // could be a wrong NAME for cases:
        // 1. That happen before authorization (Login, Register etc)
        // 2. If user manually changes localStorage value
        event.setUser(localStorage.getItem(NAME), '', localStorage.getItem(NAME));
    },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default ErrorBoundary;
