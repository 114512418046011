import './style.scss';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const DialogControllersList = (props) => {
    const handleSelect = (serial) => () => {
        const { onChange } = props;
        onChange(serial);
    };

    const { controllers } = props;

    return (
        <List className="dialog-controllers-list">
            {controllers.map((controller) => {
                const labelId = `checkbox-list-label-${controller.serial}`;

                return (
                    <ListItem
                        key={controller.serial}
                        button
                        onClick={handleSelect(controller.serial)}
                        style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                color="primary"
                                edge="start"
                                checked={!!controller.checked}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId}>{controller.serial}</ListItemText>
                        {/*<ListItemSecondaryAction>*/}
                        {/*    <IconButton edge="end" aria-label="comments">*/}
                        {/*        <CommentIcon />*/}
                        {/*    </IconButton>*/}
                        {/*</ListItemSecondaryAction>*/}
                    </ListItem>
                );
            })}
        </List>
    );
};

export default DialogControllersList;
