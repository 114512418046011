import produce from 'immer';

import { SET_MAP_CONF } from './action';

export const initialState = {
    mappingState: {
        code: '',
        codeArray: [],
        mapType: 'Simple',
        selectedSaveOutput: false,
        isSimpleDisabled: false,
    },
    initialMethod: null,
};

export const reducer = produce((draft, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_MAP_CONF:
            draft.mappingState = payload.mappingState;
            draft.initialMethod = payload.initialMethod;
            break;
        default:
            break;
    }
});
