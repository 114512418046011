import {
    getNucalComponentBySchemaKey,
    getNucalSchemaKey,
} from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-responce-extractors';
import { extractCallbackEventName } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { NUCAL_FIELDS } from '../../constants';

export const payloadsForNucalUpdate = {
    [NUCAL_FIELDS.INTEGRATION]: (integrationUuid) => ({
        integration: {
            uuid: integrationUuid,
        },
        accountUuid: null,
        method: null,
        callbackEventName: null,
        requiredFields: [],
        fields: {},
    }),
    [NUCAL_FIELDS.METHOD]: (methodValue, integrationPreviewResult, paths) => {
        const schemaKeys = getNucalSchemaKey(integrationPreviewResult, methodValue);
        const methodComponent = getNucalComponentBySchemaKey(integrationPreviewResult, schemaKeys);
        const callbackEventName = extractCallbackEventName(paths, `/${methodValue}`);

        return {
            method: methodValue,
            callbackEventName,
            requiredFields: methodComponent?.required || [],
            fields: {},
        };
    },
    [NUCAL_FIELDS.FIELDS]: (propertyName, value, fields) => ({
        fields: {
            ...fields,
            [propertyName]: value,
        },
    }),
    [NUCAL_FIELDS.ACCOUNT]: (accountUuid) => ({
        accountUuid,
        method: null,
        callbackEventName: null,
        requiredFields: [],
        fields: {},
    }),
};

export const integrationsSelector = (state) => ({
    abstracts: state?.main?.abstractsList?.abstracts,
    isAbstractsLoading: state?.main?.abstractsList?.isAbstractsListLoading,
    integrations: state?.main?.integrationList?.integrations,
    PAASAuthUuid: state?.main?.paas?.paasAuthServiceUuid,
});
