import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_BUTTON_UPLOAD_TO_MARKETPLACE_TITLE,
    EZLOGIC_HEADING_UPLOAD_TO_MARKETPLACE_TITLE,
    EZLOGIC_PlACEHOLDER_ADD_NAME,
    EZLOGIC_PlACEHOLDER_ADD_VERSION,
    EZLOGIC_TITLE_PLUGIN_NAME,
    EZLOGIC_TITLE_VERSION,
} from '../../../../../constants/language_tokens';

const UploadToMarketplace = () => {
    const { t } = useTranslate();

    return (
        <div className="modal-plugin__update">
            <h3>{t(EZLOGIC_HEADING_UPLOAD_TO_MARKETPLACE_TITLE)}</h3>
            <section>
                <div className="modal-plugin__update-field">
                    <span className="modal-plugin__update-label">{t(EZLOGIC_TITLE_PLUGIN_NAME)}</span>
                    <TextField placeholder={t(EZLOGIC_PlACEHOLDER_ADD_NAME)} variant="outlined" />
                </div>
                <div className="modal-plugin__update-field">
                    <span className="modal-plugin__update-label">{t(EZLOGIC_TITLE_VERSION)}</span>
                    <TextField placeholder={t(EZLOGIC_PlACEHOLDER_ADD_VERSION)} variant="outlined" />
                </div>
            </section>

            <div className="modal-plugin__update-buttons center">
                <Button variant="contained" color="primary">
                    {t(EZLOGIC_BUTTON_UPLOAD_TO_MARKETPLACE_TITLE)}
                </Button>
            </div>
        </div>
    );
};

export default UploadToMarketplace;
