import React from 'react';
import styles from './style.module.scss';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { EZLOGIC_BUTTON_CANCEL } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const ConfirmDialog = (props) => {
    const { isModalVisible, handleClose, dialogText, confirmFn, confirmButtonText, warningText, titleText } = props;
    const { t } = useTranslate();

    return (
        <Dialog className={styles.dialog} open={isModalVisible} onClose={handleClose}>
            <div className={styles.dialogContainer}>
                <div className={styles.dialogClearButton}>
                    <IconButton onClick={handleClose}>
                        <Clear />
                    </IconButton>
                </div>
                {titleText && <div className={styles.titleText}>{titleText}</div>}
                {dialogText && <div className={styles.dialogDescription}>{dialogText}</div>}
                {warningText && <div className={styles.warningText}>{warningText}</div>}

                <div className={styles.dialogButtons}>
                    <Button variant="outlined" onClick={handleClose}>
                        {t(EZLOGIC_BUTTON_CANCEL)}
                    </Button>
                    <Button variant="contained" color="primary" onClick={confirmFn}>
                        {confirmButtonText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
