import { BLOCK_WITH_FIELDS_ARRAY, COMPARISON_DATA } from '../../../../../constants/MeshbotConstant';
import { listCapabilitiesForController } from '../../../../../constants/rulesInSelect';

export const getControllerNodeValueForTrigger = (currentLocalVariableBlock, name) => {
    return currentLocalVariableBlock?.blocks[BLOCK_WITH_FIELDS_ARRAY]?.fields?.find((field) => field.name === name)
        ?.value;
};

export const getControllerNodeValueObjectFromNotBlock = (block) => {
    return block?.fields?.find((field) => field?.value)?.value || {};
};

export const getControllerNodeValueFromNotBlockByName = (block, name) => {
    return block?.fields?.find((field) => field?.name === name)?.value || '';
};

/**
 * This function filters the capabilities by hardware support of the controller
 * @param {boolean} isControllerWithBattery - determines if the controller has a battery
 * @return {array} - the filtered capabilities
 */
export const filterCapabilitiesByHardwareSupportOfController = (isControllerWithBattery) => {
    if (isControllerWithBattery) {
        return listCapabilitiesForController;
    }

    if (isControllerWithBattery === false) {
        return listCapabilitiesForController.filter(
            (capability) => capability.value === COMPARISON_DATA.METHOD.CLOUD_CONNECTION,
        );
    }

    return [];
};
