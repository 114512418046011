import * as yup from 'yup';
import {
    CANADA_ZIP_VALIDATION_REGEX,
    PK_COUNTRY,
    USA_ZIP_VALIDATION_REGEX,
} from '../../../../../constants/AccountInfo';

const addressFields = {
    FirstName: yup.string().required('First Name is required.'),
    LastName: yup.string().required('Last Name is required.'),
    Address1: yup.string().required('Street Address is required.'),
    Country: yup.object().required('Country is required.'),
    State: yup.object().required('State is required.'),
    City: yup.object().required('City is required.'),
    PostalCode: yup
        .string()
        .when('Country', {
            is: (selectedCountry) => selectedCountry?.PK_Country === PK_COUNTRY.USA,
            then: yup.string().matches(USA_ZIP_VALIDATION_REGEX, 'Enter a valid Zip.').required('Zip is required.'),
        })
        .when('Country', {
            is: (selectedCountry) => selectedCountry?.PK_Country === PK_COUNTRY.CANADA,
            then: yup.string().matches(CANADA_ZIP_VALIDATION_REGEX, 'Enter a valid Zip.').required('Zip is required.'),
        }),
};

export const validationSchema = yup.object({
    Installations: yup.array().of(yup.object().shape(addressFields)),
});

export const securityValidationSchema = yup.object({
    Billing: yup.object(addressFields),
    Mailing: yup.object(addressFields),
});
