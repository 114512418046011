import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, InputAdornment } from '@material-ui/core';

import SelectComponent from './SelectComponent';
import Dialog from '../../../../components/Dialog';
import { InputMaterial } from '../../../../components';
import TemplateDateTimeBlocks from './templateDateTimeBlocks';
import InfoButton from '../components/InfoButton';
import { HoursAndMinutesSelect } from './templateDateTimeBlocks/sectionCustom';
import { DateSelect } from './CloudDateAndTimeSubnodes/CloudOnceSubnode/CloudOnceSubnode';

import { info, formatInterval } from '../utils';
import isEmpty from '../../../../constants/IsEmpty';
import * as meshbot from '../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_EVERY, EZLOGIC_TITLE_WARNING } from '../../../../constants/language_tokens';

const TriggerDateFields = (props) => {
    const [showValidation, setShowValidation] = useState(false);
    const [errors] = useState(null);
    const [intervalNumber, setIntervalNumber] = useState(meshbot.INITIAL_VALUE);
    const [intervalType, setIntervalType] = useState('s');
    const { t } = useTranslate();

    const {
        id,
        actionId,
        idGroup,
        typeTrigger,
        blocks = [],
        selectedFieldDate,
        onSetSpecificDate,
        onSetDateValue,
        onSetFieldValue,
        typeMeshbot,
    } = props;

    useEffect(() => {
        if (blocks && blocks.length > 0) {
            if (selectedFieldDate === 'interval') {
                unFormattingInterval(blocks[0]);
            }
        }
    }, [selectedFieldDate, blocks]);

    const unFormattingInterval = (data) => {
        const result = data.fields?.find((item) => item.type === 'interval');
        const length = result?.value?.length;

        if (!isEmpty(result?.value)) {
            setIntervalNumber(result?.value.slice(0, length - 1));
            setIntervalType(result?.value.slice(-1));
        }
    };

    const onSolsticeTime = useCallback(
        (e) => {
            onSetFieldValue(e.target.value, id, meshbot.SELECTED_SPECIFIC_LABEL, idGroup, actionId);
            if (e.target.value === meshbot.RISE) {
                onSetSpecificDate(
                    meshbot.EMPTY_STRING,
                    id,
                    selectedFieldDate,
                    idGroup,
                    actionId,
                    meshbot.SUN_STATE.AFTER_SUNRISE,
                );
            } else {
                onSetSpecificDate(
                    meshbot.EMPTY_STRING,
                    id,
                    selectedFieldDate,
                    idGroup,
                    actionId,
                    meshbot.SUN_STATE.AFTER_SUNSET,
                );
            }
        },
        [id, idGroup, actionId],
    );

    const changeNumber = (e, name) => {
        const value = e.target.value === meshbot.SINGLE_ZERO ? meshbot.STRING_UNIT : e.target.value;

        onSetDateValue(formatInterval({ [name]: value }, intervalNumber, intervalType), id, idGroup, null, actionId);
        if (name === 'intervalNumber') {
            setIntervalNumber(value);
        }

        if (name === 'intervalType') {
            setIntervalType(e.target.value);
        }
    };

    const handleChangeNumber = (e) => {
        e.target.value = e.target.value !== '' ? parseInt(Math.abs(e.target.value)) : e.target.value;
        changeNumber(e, 'intervalNumber');
    };

    const onChangeIsOnce = (value, name, modifier) => {
        let params;

        if (name === meshbot.TIME_NODE) {
            // eslint-disable-next-line
            let [hour, minute] = value;
            if (modifier === meshbot.AM && hour === meshbot.HOUR12) {
                hour = meshbot.ZERO;
            }

            if (modifier === meshbot.PM && hour !== meshbot.HOUR12) {
                hour = parseInt(hour) + meshbot.HOUR12;
            }

            params = { hour: parseInt(hour), minute: parseInt(minute) };
        }

        if (['day', 'month', 'year'].includes(name)) {
            params = { [name]: parseInt(Math.abs(value)) };
        }

        onSetDateValue(params, id, idGroup, null, actionId);
    };

    const getTime = (time) => {
        const [hour, minute] = time.split(':');
        let hours = hour % meshbot.HOUR12;

        hours = hours ? hours : meshbot.HOUR12;

        return {
            hours: hours,
            minutes: minute,
        };
    };

    const isShowOnceNode = selectedFieldDate === 'isOnce';

    const getMeridiem = () => {
        const hours = blocks?.[0]?.fields?.[0]?.value.split(':')[0];

        return hours >= meshbot.HOUR12;
    };

    return (
        <>
            {selectedFieldDate === 'interval' && (
                <>
                    <InputMaterial
                        placeholder={t(EZLOGIC_TITLE_EVERY)}
                        label={t(info.hints.value)}
                        type="number"
                        value={intervalNumber}
                        max={meshbot.MAXIMUM_VALUE_FOR_INTERVAL_TYPE}
                        min={meshbot.MINIMUM_VALUE_FOR_INTERVAL_TYPE}
                        xsSmall
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangeNumber}
                    />

                    <SelectComponent
                        value={intervalType ? intervalType : 1}
                        onChange={(e) => changeNumber(e, 'intervalType')}
                        label={info.hints.valueType}
                        info={info.text.valueType}
                    >
                        {meshbot.intervalSelect.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value}>
                                    {t(item.name)}
                                </MenuItem>
                            );
                        })}
                    </SelectComponent>
                </>
            )}

            {isShowOnceNode && (
                <>
                    <HoursAndMinutesSelect
                        onChangeCustomTime={(value, modifier) => onChangeIsOnce(value, meshbot.TIME_NODE, modifier)}
                        customHours={getTime(blocks?.[0]?.fields?.[0]?.value).hours}
                        customMinutes={getTime(blocks?.[0]?.fields?.[0]?.value).minutes}
                        isPm={getMeridiem()}
                        isCloud={false}
                    />
                    <DateSelect
                        day={blocks?.[0]?.fields?.[1]?.value}
                        month={blocks?.[0]?.fields?.[2].value}
                        year={blocks?.[0]?.fields?.[3].value}
                        handleChangeOnce={(value, type) => onChangeIsOnce(value, type)}
                    />
                </>
            )}

            {selectedFieldDate !== 'isOnce' &&
                meshbot.listDateTime.map((item) => {
                    if (item.value === selectedFieldDate) {
                        return (
                            <TemplateDateTimeBlocks
                                key={item.id}
                                id={id}
                                actionId={actionId}
                                typeTrigger={typeTrigger}
                                idGroup={idGroup}
                                className={item.value}
                                onSetSpecificDate={onSetSpecificDate}
                                onSetDateValue={onSetDateValue}
                                onSetFieldValue={onSetFieldValue}
                                onSolsticeTime={onSolsticeTime}
                                typeMeshbot={typeMeshbot}
                            />
                        );
                    }
                })}
            <Dialog title={t(EZLOGIC_TITLE_WARNING)} open={showValidation} onHide={() => setShowValidation(false)}>
                {errors}
            </Dialog>
        </>
    );
};

export default TriggerDateFields;

TriggerDateFields.propTypes = {
    id: PropTypes.string,
    actionId: PropTypes.string,
    idGroup: PropTypes.string,
    blocks: PropTypes.array,
    onSetFieldValue: PropTypes.func,
    onSetDateValue: PropTypes.func,
    onSetSpecificDate: PropTypes.func,
};
