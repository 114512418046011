import React from 'react';
import { useTranslate } from '../../../../../features/languages';

const MainSettingsContent = ({ controllerData }) => {
    const { t } = useTranslate();

    return (
        <>
            <div className="dialog__item">
                <div>{controllerData?.info?.serial}</div>
                <div className="controller-settings-dialog__info-description">{t`ezlo.about.title.serial`}</div>
            </div>
            <div className="dialog__item">
                <div>{controllerData?.info?.uptime}</div>
                <div className="controller-settings-dialog__info-description">{t`ezlo.about.title.uptime_info`}</div>
            </div>
        </>
    );
};

export default MainSettingsContent;
