import React from 'react';

export const FallbackMeshbotNodeComponent = () => {
    return <h2>Error</h2>;
};

export const withErrorBoundary = (Component, FallbackComponent) => {
    return class ErrorBoundary extends React.Component {
        state = {
            hasError: false,
        };

        static getDerivedStateFromError = () => {
            return { hasError: true };
        };

        render() {
            const { hasError } = this.state;

            return hasError ? <FallbackComponent /> : <Component {...this.props} />;
        }
    };
};
