import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';

import ExpressionsLayout from '../ExpressionsLayout';

import { EXPRESSIONS_TYPE } from '../../../../constants/Expressions';
// import { GET_OFFLINE_EZLO } from '../../../../constants/URLs';

const EzloVariables = (props) => {
    const { offline, withHeader } = props;
    // const ezlos = useSelector((state) => state.ezlos);

    useEffect(() => {
        if (offline) {
            // TODO: must be fixed, because throw the error here!
            // history.push(GET_OFFLINE_EZLO(ezlos[0].serial));
        }
    }, [offline]);

    return <ExpressionsLayout expressionType={EXPRESSIONS_TYPE.VARIABLE} withHeader={withHeader} />;
};

export default EzloVariables;
