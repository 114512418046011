import React from 'react';
import { MenuList } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { MENU_LIST_GROW } from '../../../../../constants/Devices';
import { EZLOGIC_TITLE_NO_OPTIONS } from '../../../../../constants/language_tokens';
import LabelItem from './LabelItem';
import { useTranslate } from '../../../../languages';

import styles from './styles.module.scss';

const LabelsDropdownList = ({ handleListKeyDown, labelsList }) => {
    const { t } = useTranslate();

    return (
        <MenuList id={MENU_LIST_GROW} onKeyDown={handleListKeyDown}>
            {labelsList.map((label) => (
                <LabelItem key={label.id} label={label} />
            ))}

            {isEmpty(labelsList) && <div className={styles.noOptionsBox}>{t(EZLOGIC_TITLE_NO_OPTIONS)}</div>}
        </MenuList>
    );
};

export default LabelsDropdownList;
