import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentCloudTrigger } from '../../containers/Ezlo/EzloMeshbot/utils';

const useTriggerSelectCloud = (ruleCloudId) => {
    const triggers = useSelector((state) => state.meshBot?.cloud?.ruleCloudTriggers) || {};

    return getCurrentCloudTrigger(triggers, ruleCloudId);
};

useTriggerSelectCloud.propTypes = {
    ruleCloudId: PropTypes.string,
};

export default useTriggerSelectCloud;
