import hash from '../../../constants/uniqueHash';
import {
    virtualItemKeys,
    virtualItemMapTypes,
    getVirtualItemTemplate,
    mapTypes,
    uniqKeyForVirtualItem,
    VARIABLES_DEFINITION,
    COMMANDS_DEFINITION,
    SCHEMA_REF_KEY,
    capabilityNeedToReplace,
    REGEXP_TEMPLATE_METHOD_NAME,
    NOT_ALLOWED_CUSTOM_CAPABILITY,
} from './virtual-templates';
import at from '../../../constants/ActionTypes/MeshBot';
import { apiGetIntegrationPreview } from '../EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';
import {
    cloudOperatorTrig,
    cloudOperatorTrigForBoolean,
    cloudOperatorTrigForBooleanNotificationTemp,
    cloudOperatorTrigForNotificationTemp,
} from '../../../constants/rulesInSelect';
import {
    AV,
    CUSTOM_TEMPLATE,
    DATA_FROM_BLOCK_WITH_NAME_AV,
    DATA_FROM_BLOCK_WITH_NAME_STATIC,
    DATA_FROM_VALUE_FROM_BLOCK_STATIC,
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    STATIC,
    STATUS,
    VALUE_FROM_FIRST_VARIABLE,
} from '../../../constants/NotificationTemplates';
import { PDM_REQUESTED_HIDDEN_CLOUD_CAPABILITIES } from '../EzloMeshbot/constants';

const { FUNCTION_X_COMMAND, FUNCTION_X_STATE } = virtualItemMapTypes;
const { VIRTUAL_TRIGGERS } = virtualItemKeys;

// State utils

export const addTriggerToVirtualDevice = (virtualDevice, virtualItemKey) => {
    const mapType = virtualItemKey === VIRTUAL_TRIGGERS ? FUNCTION_X_STATE : FUNCTION_X_COMMAND;
    const virtualTriggerTemplate = getVirtualItemTemplate(mapType);
    const updatedVirtualTriggers = [...virtualDevice[virtualItemKey], virtualTriggerTemplate];

    return {
        ...virtualDevice,
        [virtualItemKey]: updatedVirtualTriggers,
    };
};

export const deleteTriggerFromVirtualDevice = (virtualItemUpdateParams, uuidToFind) => {
    const { virtualDevice, virtualItems, virtualItemKey } = virtualItemUpdateParams;
    const updatedVirtualTriggers = virtualItems.filter(({ uuid }) => uuid !== uuidToFind);

    return {
        ...virtualDevice,
        [virtualItemKey]: updatedVirtualTriggers,
    };
};

export const changeVirtualDeviceMapType = (virtualDevice, uuidToFind) => {
    const { virtualTriggers } = virtualDevice;
    const updatedVirtualTriggers = virtualTriggers.filter(({ uuid }) => uuid !== uuidToFind);

    return {
        ...virtualDevice,
        virtualTriggers: updatedVirtualTriggers,
    };
};

export const findVirtualItemByUuid = (virtualItems, uuidToFind) => {
    let indexOfVirtualItem;

    const virtualItemToUpdate = virtualItems.find(({ uuid }, index) => {
        if (uuid === uuidToFind) {
            indexOfVirtualItem = index;

            return true;
        }
    });

    return { indexOfVirtualItem, virtualItemToUpdate };
};

export const changeMapType = (virtualItemUpdatParams, mapType) => {
    const { virtualDevice, virtualItems, virtualItemToUpdate, virtualItemIndex, virtualItemKey } =
        virtualItemUpdatParams;
    const tmpVirtualItems = [...virtualItems];

    tmpVirtualItems[virtualItemIndex] = {
        ...virtualItemToUpdate,
        mapType,
    };

    return {
        ...virtualDevice,
        [virtualItemKey]: tmpVirtualItems,
    };
};

export const changeCapability = (virtualItemUpdatParams, capability) => {
    const { virtualDevice, virtualItems, virtualItemToUpdate, virtualItemIndex, virtualItemKey } =
        virtualItemUpdatParams;
    const tmpVirtualItems = [...virtualItems];
    const { map_conf } = virtualItemToUpdate;

    tmpVirtualItems[virtualItemIndex] = {
        ...virtualItemToUpdate,
        capability,
        map_conf: {
            ...map_conf,
        },
    };

    return {
        ...virtualDevice,
        [virtualItemKey]: tmpVirtualItems,
    };
};

export const updateMapConf = (virtualItemUpdateParams, fieldKeyToUpdate, value) => {
    const { virtualDevice, virtualItems, virtualItemToUpdate, virtualItemIndex, virtualItemKey } =
        virtualItemUpdateParams;
    const tmpVirtualItems = [...virtualItems];
    const { map_conf } = virtualItemToUpdate;

    tmpVirtualItems[virtualItemIndex] = {
        ...virtualItemToUpdate,
        map_conf: {
            ...map_conf,
            [fieldKeyToUpdate]: value,
        },
    };

    return {
        ...virtualDevice,
        [virtualItemKey]: tmpVirtualItems,
    };
};

export const updateAbstract = (virtualItemUpdateParams, uuid) => {
    const { virtualDevice, virtualItems, virtualItemToUpdate, virtualItemIndex, virtualItemKey } =
        virtualItemUpdateParams;
    const tmpVirtualItems = [...virtualItems];
    const { map_conf } = virtualItemToUpdate;

    tmpVirtualItems[virtualItemIndex] = {
        ...virtualItemToUpdate,
        map_conf: {
            ...map_conf,
            abstract: uuid,
            capability: '',
            code: '',
            [uniqKeyForVirtualItem[virtualItemKey]]: '',
        },
    };

    return {
        ...virtualDevice,
        [virtualItemKey]: tmpVirtualItems,
    };
};

export const clearMapConf = (virtualItemUpdateParams) => {
    const { virtualDevice, virtualItems, virtualItemToUpdate, virtualItemIndex, virtualItemKey } =
        virtualItemUpdateParams;
    const tmpVirtualItems = [...virtualItems];

    tmpVirtualItems[virtualItemIndex] = {
        ...virtualItemToUpdate,
        map_conf: {
            code: '',
            language: 'javascript',
        },
    };

    return {
        ...virtualDevice,
        [virtualItemKey]: tmpVirtualItems,
    };
};

export const convertVirtualItemsForStore = (virtualItems) => {
    return virtualItems.map(({ capability, map_type, map_conf }) => {
        return {
            uuid: hash(),
            capability: capability,
            map_type: map_type,
            map_conf: map_conf,
            mapType: mapTypes.ADVANCED,
        };
    });
};

export const convertVirtualDeviceApiStructureToState = (virtualDevice) => {
    const {
        name,
        configuration: { map: virtualItems },
    } = virtualDevice;
    const virtualTriggers = [];
    const virtualActions = [];

    virtualItems.forEach((item) => {
        const { map_type } = item;

        if (map_type === virtualItemMapTypes.FUNCTION_X_STATE) {
            virtualTriggers.push(item);
        }

        if (map_type === virtualItemMapTypes.FUNCTION_X_COMMAND) {
            virtualActions.push(item);
        }
    });

    return {
        name,
        virtualTriggers: convertVirtualItemsForStore(virtualTriggers),
        virtualActions: convertVirtualItemsForStore(virtualActions),
    };
};

export const convertVirtualItemsForApi = (virtualItems) => {
    return virtualItems.map((virtualItem) => {
        const { capability, map_type, map_conf } = virtualItem;

        return { capability, map_type, map_conf };
    });
};

export const getVirtualDeviceTemplateForCreate = (virtualDeviceParams, uuid) => {
    const { name, virtualTriggers, virtualActions } = virtualDeviceParams;
    const virtualDevice = {
        name,
        configuration: {
            map: [...convertVirtualItemsForApi(virtualTriggers), ...convertVirtualItemsForApi(virtualActions)],
        },
    };

    if (uuid) {
        virtualDevice.uuid = uuid;
    }

    return virtualDevice;
};

// UI utils

export const filterCapabilitiesByVirtualItemType = (capabilitiesOfAbstract, capabilities, type) => {
    const { EXISTING_VALUE, EXPECTING_VALUE } = capabilityNeedToReplace;
    const allCapabilities = Object.values(capabilities);
    const changedCapabilities = allCapabilities.map((item) =>
        item.capability_name === EXISTING_VALUE ? { ...item, capability_name: EXPECTING_VALUE } : item,
    ); //TODO: remove when api_command will be renamed to api on BE side

    return capabilitiesOfAbstract
        .filter((item) => !item.startsWith(NOT_ALLOWED_CUSTOM_CAPABILITY))
        .filter((capability) => {
            const { definition } = changedCapabilities.find(({ capability_name }) => capability_name === capability);
            const definitionKeys = Object.keys(definition);
            const keyToFind = type === VIRTUAL_TRIGGERS ? VARIABLES_DEFINITION : COMMANDS_DEFINITION;

            return definitionKeys.includes(keyToFind);
        });
};

export const filterCapabilitiesForCloudAction = (capabilitiesOfAbstract = []) => {
    return capabilitiesOfAbstract.filter((capability) => {
        return capability.includes('command');
    });
};
/**
 * Get rid of hidden capability, returns a list of open capability
 * @param { Array } capabilities abstract device capabilities list
 * @returns { Array } Array of open capability
 * @example
 * prepareDevices(state.ezlo.data)
 */
export const filterPdmRequestedCloudCapabilities = (capabilities) => {
    return capabilities.filter((capability) => {
        const capabilityName = capability?.capability_name || capability;

        return !PDM_REQUESTED_HIDDEN_CLOUD_CAPABILITIES.includes(capabilityName);
    });
};
/**
 * Filters all abstract device capabilities for trigger
 * @param { Array } capabilitiesOfAbstract - abstract device capabilities list
 * @return { Array } filtered abstract device capabilities
 * @example
 * filterCapabilitiesForCloudTriggers(['name', 'name_command'])
 */
export const filterCapabilitiesForCloudTriggers = (capabilitiesOfAbstract = []) => {
    const capabilities = filterPdmRequestedCloudCapabilities(capabilitiesOfAbstract);

    return capabilities.filter((capability) => {
        return capability?.capability_name
            ? capability?.capability_name?.includes('command')
            : !capability?.includes('command');
    });
};

export const getMenuItems = (items) => {
    return items.map((item) => ({ label: item, value: item }));
};

export const isSimpleInput = (currentInputType, nameSubBlock) => {
    return (
        currentInputType?.target_name ||
        currentInputType.target_percentage ||
        currentInputType.target_delta ||
        currentInputType === 'value' ||
        currentInputType === 'integer' ||
        currentInputType === 'text' ||
        currentInputType === 'percentage' ||
        currentInputType === at.COOL_WHITE ||
        currentInputType === at.WARM_WHITE ||
        currentInputType === at.GREEN ||
        currentInputType === at.BLUE ||
        currentInputType === at.TARGET_WARM_WHITE ||
        currentInputType === at.TARGET_COOL_WHITE ||
        currentInputType === at.CYAN ||
        currentInputType === at.MAGENTA ||
        currentInputType === at.YELLOW ||
        currentInputType === at.RED ||
        nameSubBlock === 'thermostat_mode' ||
        nameSubBlock === 'thermostat_state' ||
        nameSubBlock === 'fan_mode' ||
        nameSubBlock === 'current_temperature' ||
        nameSubBlock === 'tamper_cover' ||
        nameSubBlock === 'lock_error' ||
        currentInputType === 'scale' ||
        currentInputType === 'range'
    );
};

export const isRgb = (currentInputType) => {
    return (
        (currentInputType?.target_cyan && currentInputType?.target_magenta && currentInputType?.target_yellow) ||
        currentInputType?.cyan ||
        currentInputType?.[0]?.name === at.CYAN ||
        currentInputType === at.CYAN ||
        currentInputType === at.MAGENTA ||
        currentInputType === at.YELLOW ||
        currentInputType?.[0]?.name === at.WARM_WHITE ||
        currentInputType?.[0]?.name === at.WARM_WHITE ||
        currentInputType?.[0] === at.WARM_WHITE ||
        currentInputType === at.WARM_WHITE ||
        currentInputType?.[0] === at.CYAN ||
        currentInputType === at.GREEN ||
        currentInputType === at.BLUE ||
        currentInputType === at.RED
    );
};

export const getOperatorArray = (currentVariable, notificationTemplate) => {
    if (!notificationTemplate) {
        return currentVariable === STATUS ? cloudOperatorTrigForBoolean : cloudOperatorTrig;
    } else {
        return currentVariable === STATUS
            ? cloudOperatorTrigForBooleanNotificationTemp
            : cloudOperatorTrigForNotificationTemp;
    }
};

export const isString = (currentInputType, nameSubBlock) => {
    return (
        currentInputType?.target_name ||
        (currentInputType === 'value' &&
            nameSubBlock !== 'setpoint_single' &&
            nameSubBlock !== 'setpoint_heat' &&
            nameSubBlock !== 'setpoint_cool' &&
            nameSubBlock !== 'current_temperature') ||
        currentInputType === 'text' ||
        nameSubBlock === 'thermostat_mode' ||
        nameSubBlock === 'thermostat_state' ||
        nameSubBlock === 'fan_mode' ||
        (currentInputType === 'status' && nameSubBlock === 'current_temperature') ||
        nameSubBlock === 'lock_error' ||
        nameSubBlock === 'tamper_cover' ||
        currentInputType === 'range' ||
        currentInputType === 'scale'
    );
};

export const isTemperature = (currentInputType) => {
    return (
        (currentInputType.target_cool_white && currentInputType.target_warm_white) ||
        currentInputType[0] === at.WARM_WHITE ||
        currentInputType[0] === at.TARGET_WARM_WHITE ||
        currentInputType[0]?.name === at.WARM_WHITE
    );
};

export const updateClassesNamesForWhenBlocks = (block) => {
    if (block) {
        if (block.isValid && !block.isEdited) {
            return 'trigger-block editing connections';
        } else if (block.isValid && block.isEdited) {
            return 'trigger-block is-valid connections';
        } else if (!block.isValid && block.isEdited) {
            return 'trigger-block connections';
        }
    } else {
        return 'trigger-block disabled-validation';
    }
};

export const buildTemperatureObject = (variable, value) => {
    if (variable === at.COOL_WHITE) {
        return [
            { name: at.TARGET_COOL_WHITE, value },
            { name: at.TARGET_WARM_WHITE, value: 0 },
        ];
    } else {
        return [
            { name: at.TARGET_WARM_WHITE, value },
            { name: at.TARGET_COOL_WHITE, value: 0 },
        ];
    }
};

export const buildRgbObject = (value) => {
    return {
        blue: value.blue,
        red: value.red,
        green: value.green,
    };
};

export const isShowVariables = (variables) => {
    return (
        variables &&
        variables?.[0]?.name !== at.WARM_WHITE &&
        variables?.[0] !== at.WARM_WHITE &&
        variables?.[0].name !== at.CYAN &&
        variables?.[0] !== at.CYAN &&
        variables?.[0] !== at.WARM_WHITE
    );
};

const extractCallDefinitionListWithCallBack = (root, integrationUuid) => {
    return (
        root.integrations[integrationUuid]?.call_definition.list.reduce((acc, item) => {
            if (item.hasCallBack) {
                acc.push(item.name);
            }

            return acc;
        }, []) ?? []
    );
};

export const getPaths = (root, integrationUuid) => {
    return root.integrations[integrationUuid]?.nucal_parject_definition.list.parjectJson.paths;
};

export const prettierPath = (root, integrationUuid) => {
    const paths = getPaths(root, integrationUuid);
    const callDefinitionListWithCallBack = extractCallDefinitionListWithCallBack(root, integrationUuid);

    return Object.entries(paths)
        .filter(([key]) => !callDefinitionListWithCallBack.includes(key.replace(REGEXP_TEMPLATE_METHOD_NAME, '')))
        .map(([key, val]) => {
            return {
                name: val.summary,
                description: val.description,
                uuid: key.replace(REGEXP_TEMPLATE_METHOD_NAME, ''), // replace method name starting with /
            };
        });
};

const getNucalParjectDefinition = (root, integrationUuid) => {
    return root.integrations[integrationUuid]?.nucal_parject_definition;
};

export const getNucalSchemaKey = (root, integrationUuid, methodName) => {
    const nucalParjectDefinition = getNucalParjectDefinition(root, integrationUuid);

    if (!nucalParjectDefinition) {
        return;
    }

    const { schema } =
        nucalParjectDefinition.list.parjectJson.paths[methodName].post.requestBody.content['application/json'];
    const splittedSchema = schema[SCHEMA_REF_KEY].split('/');
    const schemaKey = splittedSchema[splittedSchema.length - 1];

    return schemaKey;
};

export const getNucalComponentBySchemaKey = (root, integrationUuid, schemaKey) => {
    const nucalParjectDefinition = getNucalParjectDefinition(root, integrationUuid);

    if (!nucalParjectDefinition) {
        return;
    }

    return nucalParjectDefinition.list.parjectJson.components.schemas[schemaKey];
};

export const parseJsCode = (code) => {
    const expression = `${code}; return output`;
    const f = new Function(expression);

    return f();
};

export const buildParsedCode = (code) => {
    const { argument } = parseJsCode(code);

    const [method, fields] = argument;

    const [, methodName] = method;
    const [, fieldsValues] = fields;

    return {
        method: methodName,
        fields: fieldsValues,
    };
};

export const validate = (initialValue, fields) => {
    const errors = {};
    for (const field of initialValue) {
        if (!fields[field] || fields[field] == '') {
            errors[field] = 'This field is required!';
        }
    }

    return errors;
};

export const fetchIntegrationPreview = async (integrationUuid) => {
    const { status, integrationPreview } = await apiGetIntegrationPreview(integrationUuid);

    if (status === 0) {
        return;
    }

    return integrationPreview;
};

export const checkItemNameBlockAndReturnParameters = (item, value) => {
    if (item.name === STATIC) {
        item.parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].value = '';
        item.parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].transform = '';
    }

    if (item.name === AV) {
        item.parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].capability = value.nameSubBlock;
        item.parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].variable = value.variables?.[VALUE_FROM_FIRST_VARIABLE];
    }

    return item;
};

export const updateBlocksInCaseNotificationTemplateCapability = (item, value) => {
    return item.blocks.map((item) => {
        if (item?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]) {
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_AV].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].capability =
                value.nameSubBlock;
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_AV].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].variable =
                value.variables?.[VALUE_FROM_FIRST_VARIABLE];
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].transform =
                value.currentVariableType;
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].value = '';
        } else {
            checkItemNameBlockAndReturnParameters(item, value);
        }

        return item;
    });
};

export const updateBlocksInCaseNotificationTemplateVariable = (item, value) => {
    return item.blocks.map((item) => {
        item.parameters[DATA_FROM_BLOCK_WITH_NAME_AV].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].variable =
            value.currentVariable;
        item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].transform =
            value.currentVariableType;
        item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_FIRST_BLOCK].value =
            value.variableValue;

        return item;
    });
};
export const updateBlocksInCaseNotificationTemplateVariableValue = (triggerItem, value) => {
    return triggerItem.blocks.map((item) => {
        if (item?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]) {
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_BLOCK_STATIC].transform =
                triggerItem?.currentVariableType;
            item.parameters[DATA_FROM_BLOCK_WITH_NAME_STATIC].parameters[DATA_FROM_VALUE_FROM_BLOCK_STATIC].value =
                value.value;
        }

        return item;
    });
};

export const returnTemplatesListWithCustomDetected = (templates) => {
    return templates?.map((template) => {
        template.isCustom = CUSTOM_TEMPLATE;

        return template;
    });
};

export const isValidRgbValue = (e, nameSubBlock = '') => {
    if (
        nameSubBlock?.includes(at.COLOR_CMY) ||
        nameSubBlock?.includes(at.COLOR_RGB) ||
        nameSubBlock?.includes(at.COLOR_TEMPERATURE_WARM)
    ) {
        return Number(e?.target?.value) < 0 || Number(e?.target?.value) > 255;
    }
};
