import React from 'react';
import { TextField, IconButton, Checkbox, InputAdornment } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import {
    VARIABLES_FIELDS_LABELS,
    INPUT_ADORONMENT_END_POSITION,
    INPUT_APPEARANCE_VARIANT_OUTLINED,
    INPUT_APPEARANCE_VARIANT_STANDARD,
} from '../../../../../constants/Variables';
import { info } from '../../../EzloMeshbot/utils';
import InfoButton from '../../../EzloMeshbot/components/InfoButton';

import { useTranslate } from '../../../../../features/languages';

import styles from './VariablesTokenDataType.module.scss';

const VariablesTokenDataType = (props) => {
    const { disabled, onChangeTokenValue, code, isMeshbotPage } = props;
    const tokenFieldsValues = Object.values(code);

    const { t } = useTranslate();

    const handleAddTokenField = () => {
        onChangeTokenValue([...tokenFieldsValues, { value: '', text: '', checked: false }]);
    };

    const handleTokenDataTypeValues = (event, index) => {
        const { name, checked } = event.target;
        const updatedTokenslist = [...tokenFieldsValues];
        if (!name) {
            updatedTokenslist.forEach((el) => {
                el.checked = false;
            });
            updatedTokenslist[index]['checked'] = checked;
        } else {
            updatedTokenslist[index][name] = event.target.value;
        }
        onChangeTokenValue(updatedTokenslist);
    };

    const handleRemoveTokenField = (index) => {
        const fieldsList = [...tokenFieldsValues];
        fieldsList.splice(index, 1);
        onChangeTokenValue(fieldsList);
    };

    return (
        <div className={styles.variablesTokenTypeWrapper}>
            {tokenFieldsValues?.map((tokenField, index) => {
                return (
                    <div className={styles.variablesTokenFields} key={index}>
                        <TextField
                            type="text"
                            className={
                                isMeshbotPage ? styles.meshbotVariableTokenInput : styles.variablesTokenTypeInput
                            }
                            placeholder={t(VARIABLES_FIELDS_LABELS.VARIABLE_VALUE)}
                            name="value"
                            value={tokenFieldsValues[index]?.value}
                            variant={
                                isMeshbotPage ? INPUT_APPEARANCE_VARIANT_STANDARD : INPUT_APPEARANCE_VARIANT_OUTLINED
                            }
                            size="small"
                            label={isMeshbotPage ? t(info.hints.value) : t(VARIABLES_FIELDS_LABELS.VARIABLE_VALUE)}
                            onChange={(e) => handleTokenDataTypeValues(e, index)}
                            disabled={disabled}
                            InputProps={{
                                endAdornment: isMeshbotPage && (
                                    <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                                        <InfoButton>
                                            <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                        </InfoButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <span
                            className={
                                isMeshbotPage ? styles.meshbotVariableTokenColon : styles.variablesTokenTypeColon
                            }
                        >
                            :
                        </span>
                        <TextField
                            type="text"
                            className={
                                isMeshbotPage ? styles.meshbotVariableTokenInput : styles.variablesTokenTypeInput
                            }
                            placeholder={t(VARIABLES_FIELDS_LABELS.ACTION.TEXT)}
                            name="text"
                            value={tokenFieldsValues[index]?.text}
                            variant={
                                isMeshbotPage ? INPUT_APPEARANCE_VARIANT_STANDARD : INPUT_APPEARANCE_VARIANT_OUTLINED
                            }
                            size="small"
                            label={t(VARIABLES_FIELDS_LABELS.ACTION.TEXT)}
                            onChange={(e) => handleTokenDataTypeValues(e, index)}
                            disabled={disabled}
                        />
                        <div className={styles.variableTokenTypeFieldsControls}>
                            <Checkbox
                                className={styles.variablesTokenTypeCheckbox}
                                color="primary"
                                checked={tokenFieldsValues[index]?.checked}
                                disabled={disabled}
                                onChange={(e) => handleTokenDataTypeValues(e, index)}
                            />
                            {tokenFieldsValues.length > 1 && !disabled && (
                                <IconButton
                                    className={`${styles.variableTokenTypeControlIcon} ${styles.alignRemoveButton}`}
                                    onClick={() => handleRemoveTokenField(index)}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                );
            })}
            {!disabled && (
                <IconButton
                    className={`${styles.variableTokenTypeControlIcon} ${styles.alignButton} ${
                        isMeshbotPage && styles.meshbotAddIcon
                    }`}
                    onClick={handleAddTokenField}
                >
                    <AddIcon />
                </IconButton>
            )}
        </div>
    );
};

export default VariablesTokenDataType;
