import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as generator from '../../../../../constants/DevicePluginGenerator';
import { EDGE_PRIVATE, REJECTED, SUCCESS, PRIVATE, IP_DEVICE_GENERATOR } from '../../../../../constants/Plugins';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, SUCCESS_OPTIONS } from '../../../../../constants/toastConstants';
import toastsActions from '../../../../../actions/toastsActions';

import Spinner from '../../../../../components/Spinner';
import Header from '../HeaderForGenerator';
import GeneratorForm from '../GeneratorForm';

import styles from '../../PluginWifiGenerator/pluginWifiGenerato.module.scss';
import { EZLOGIC_HEADING_CREATE_IP_DEVICE_TEMPLATE } from '../../../../../constants/language_tokens';
import DeviceGeneratorNotInstalled from '../DeviceGeneratorNotInstalled';
import { isPluginInstalled } from '../../utils';

const CreateDeviceTemplate = (props) => {
    const [capabilities, setCapabilities] = useState({});
    const [isInstalled, setIsInstalled] = useState(true);
    const [isSpinner, setIsSpinner] = useState(false);
    const dispatch = useDispatch();
    const { listInstalledPlugins } = useSelector((state) => state?.plugins);
    const { serial, history, isRequestStatus, isRequestStatusMessage, onSetIsActivePage, PluginActions, MainAction } =
        props;

    useEffect(() => {
        return () => {
            PluginActions.unSubscribeBroadcastConfigure(serial);
            PluginActions.unSubscribeUpdateConfigure(serial);
        };
    }, []);

    useEffect(() => {
        setIsSpinner(true);
        if (serial && isPluginInstalled(listInstalledPlugins, serial, IP_DEVICE_GENERATOR)) {
            PluginActions.getCapabilitiesPluginList(serial);
            PluginActions.subscribeBroadcastConfigure(serial, getCapabilities(serial));
            PluginActions.subscribeUpdateConfigure(serial, notificationGetCapabilities(serial));
        } else {
            setIsInstalled(false);
            setIsSpinner(false);
        }
    }, [serial]);

    useEffect(() => {
        if (isRequestStatus === SUCCESS) {
            history.replace(generator.WIFI_GENERATOR_PRIVATE);
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: SUCCESS_OPTIONS,
                }),
            );
            onSetIsActivePage(PRIVATE);
        }

        if (isRequestStatus === REJECTED) {
            dispatch(
                toastsActions.showToast({
                    message: isRequestStatusMessage,
                    options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    isSave: true,
                }),
            );
        }

        PluginActions.clearRequestStatus();
    }, [isRequestStatus]);

    const notificationGetCapabilities = (serial) => {
        return ({ result }) => {
            if (result.error !== null) {
                dispatch(
                    toastsActions.showToast({
                        message: result.error?.message,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
                PluginActions.unSubscribeUpdateConfigure(serial);
                PluginActions.unSubscribeBroadcastConfigure(serial);
                setIsInstalled(false);
            }

            setIsSpinner(false);
        };
    };

    const getCapabilities = (serial) => {
        return ({ result }) => {
            if (result.hasOwnProperty('capabilities')) {
                setCapabilities(result?.capabilities);
                PluginActions.unSubscribeBroadcastConfigure(serial);
                PluginActions.unSubscribeUpdateConfigure(serial);
                setIsInstalled(true);
            }

            setIsSpinner(false);
        };
    };

    const handleRouteToPlugin = () => {
        history.replace(EDGE_PRIVATE);
    };

    return (
        <>
            <Header title={EZLOGIC_HEADING_CREATE_IP_DEVICE_TEMPLATE} isEdit={false} />
            {isSpinner ? (
                <div className={styles.wifiGenerator__spinner_installed}>
                    <Spinner className={styles.wifiGenerator__spinner_component} />
                </div>
            ) : isInstalled ? (
                <GeneratorForm
                    serial={serial}
                    type={generator.DEVICES_PLUGIN_CREATE}
                    history={history}
                    capabilities={capabilities}
                    PluginActions={PluginActions}
                    MainAction={MainAction}
                />
            ) : (
                <DeviceGeneratorNotInstalled handleRouteToPlugin={handleRouteToPlugin} />
            )}
        </>
    );
};

CreateDeviceTemplate.propTypes = {
    serial: PropTypes.string,
    isRequestStatus: PropTypes.string,
    isRequestStatusMessage: PropTypes.string,
    onSetIsActivePage: PropTypes.func,
    PluginActions: PropTypes.object,
};

export default CreateDeviceTemplate;
