import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaActions } from '../../hooks/useMediaActions';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import UploadImagesDialogInfo from '../UploadImagesDialogInfo';
import DropzoneSection from '../DropzoneSection/DropzoneSection';
import useDropzoneWithPreviewState from '../../../../hooks/useDropzoneWithPreviewState';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_UPLOAD_IMAGES,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';
import { ACCEPT_FILE_FORMAT_FOR_UPLOAD_IMG } from '../../constants';
import { mediaStoreSelector } from '../../utils';

import styles from './styles.module.scss';

function UploadImagesDialog({ dialogState: { open, toggleOpenDialog } }) {
    const { uploadingImages } = useSelector(mediaStoreSelector);
    const { uploadImages } = useMediaActions();
    const dropzoneState = useDropzoneWithPreviewState(ACCEPT_FILE_FORMAT_FOR_UPLOAD_IMG);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { t } = useTranslate();

    const onCloseDialog = () => {
        toggleOpenDialog();
        dropzoneState.clearDropzoneState();
    };

    const saveHandler = async () => {
        await uploadImages(dropzoneState.filesData);
        await onCloseDialog();
    };

    return (
        <Dialog
            className={styles.uploadImagesDialog}
            maxWidth="lg"
            fullWidth
            open={open}
            fullScreen={fullScreen}
            onClose={onCloseDialog}
        >
            {uploadingImages && <LinearProgress />}
            <DialogTitle>{t(EZLOGIC_TITLE_UPLOAD_IMAGES)}</DialogTitle>
            <DialogContent>
                <UploadImagesDialogInfo />
                <DropzoneSection {...dropzoneState} />
            </DialogContent>
            <DialogActions className={styles.saveButtonBox}>
                <Button fullWidth variant="outlined" onClick={onCloseDialog}>
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </Button>
                <Button
                    disabled={uploadingImages}
                    fullWidth
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={saveHandler}
                >
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadImagesDialog;
