import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useTranslate } from '../../../../../features/languages';

import styles from '../TooltipInfo.module.scss';

const InfoItem = ({ item, info }) => {
    const { t } = useTranslate();

    return (
        <>
            {Boolean(item.value) ? (
                <li>
                    <span className={styles.label}>{t(item.label)}:</span>
                    <span className={styles.value}>{isEmpty(info) ? t(item.value) : item.value}</span>
                </li>
            ) : (
                <></>
            )}
        </>
    );
};

export default InfoItem;

InfoItem.propTypes = {
    item: PropTypes.object,
};
