import React from 'react';
import { Checkbox, MenuItem } from '@material-ui/core';
import { CheckBox, Label } from '@material-ui/icons';
import classname from 'classnames';

import useLabelDropdownItemState from '../../hooks/useLabelDropdownItemState';

import styles from './styles.module.scss';

const LabelItem = ({ label }) => {
    const { isSelected, selectLabel, UncheckIcon } = useLabelDropdownItemState(label);
    const menuItemClassName = classname(styles.menuItem, {
        [styles.selectedItem]: isSelected,
    });

    return (
        <MenuItem className={menuItemClassName} onClick={() => selectLabel(label)}>
            <Checkbox
                disableRipple={true}
                icon={<UncheckIcon />}
                checkedIcon={<CheckBox />}
                checked={isSelected}
                color="primary"
            />
            <Label className={styles.labelBackgroundIcon} style={{ color: label.backgroundColor }} />
            <span className={styles.labelName}>{label?.name}</span>
        </MenuItem>
    );
};

export default LabelItem;
