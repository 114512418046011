import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, InputAdornment } from '@material-ui/core';
import InputNumberBlock from '../../../../components/inputNumber';
import SelectBlock from '../../../../components/Select';

import { getMethodName } from '../../../../constants/MethodBlock';
import { BLOCK_FIELD_TYPES, optionsSelect } from '../../../../constants/MeshbotConstant';
import { info } from '../utils';
import InfoButton from '../components/InfoButton';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_TITLE_EXPRESSION,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VARIABLE,
} from '../../../../constants/language_tokens';

const TriggerFieldBlock = (props) => {
    const [fieldValue, setFieldValue] = useState('valueBlock');
    const [nameExpression, setNameExpression] = useState('');
    const [nameVariable, setNameVariable] = useState('');
    const { BOOL, TOKEN, FLOAT, INT, EMPTY, EXPRESSION, VALUE_BLOCK, VARIABLE } = BLOCK_FIELD_TYPES;
    const {
        fields,
        idTrigger,
        idDev,
        blockId,
        idGroup,
        currentExpression,
        deviceBlocks,
        blockOptions,
        handlerUpdateTriggerBlock,
        onUpdateFieldBlock,
    } = props;
    // TODO currentExpression is sometimes undefined
    const expressionItems = currentExpression?.filter((item) => item?.metadata?.dataType !== VARIABLE);
    const variableItems = currentExpression?.filter((item) => item?.metadata?.dataType === VARIABLE);
    const { t } = useTranslate();
    useEffect(() => {
        fields.forEach((item) => {
            if (item.type === EXPRESSION) {
                const selectedExpression = currentExpression.find((expression) => expression.name === item.value);
                if (selectedExpression?.metadata?.dataType === VARIABLE) {
                    setNameVariable(item.value);
                    setFieldValue(VARIABLE);
                } else {
                    setNameExpression(item.value);
                    setFieldValue(EXPRESSION);
                }
            } else {
                setFieldValue(VALUE_BLOCK);
            }
        });
    }, []);

    const detectedBlockFields = (fields) => {
        let blockType = null;
        let isPreDefinedField = false;

        fields.forEach((item) => {
            const { options, name, type } = item;
            const isTypeBoolOrToken = type === BOOL || type === TOKEN;

            if (name === 'value' && !options && isTypeBoolOrToken) {
                isPreDefinedField = true;
            }

            if (item.editable) {
                blockType = type;
            } else if (type === BOOL) {
                blockType = type;
            } else if (type === EXPRESSION) {
                blockType = type;
            } else if (type === TOKEN && options) {
                blockType = type;
            } else {
                blockType = EMPTY;
            }
        });

        if (isPreDefinedField) {
            return;
        }

        return blockType;
    };

    const getValueField = (fields) => {
        let value = null;

        fields.forEach((item) => {
            if (item.editable) {
                // eslint-disable-next-line
                value = item.value;
            } else if (item.type === BOOL) {
                value = {
                    value: item.value,
                    // labels: item.options,
                    labels: [
                        { value: true, label: { text: 'true' } },
                        { value: false, label: { text: 'false' } },
                    ],
                };
            } else if (item.type === TOKEN && item.options) {
                value = { value: item.value, labels: item.options };
            }
        });

        return value;
    };

    const handleChangeBlock = (value) => {
        if (value.target.value === VALUE_BLOCK) {
            for (const item in deviceBlocks) {
                if (item === idDev) {
                    const [block] = deviceBlocks[item].filter((elem) => elem.blockId === blockId);
                    handlerUpdateTriggerBlock(idTrigger, block, blockId, idGroup);
                }
            }
        }

        setNameExpression('');
        setFieldValue(value.target.value);
    };

    const changeNameExpression = (event, expressionType) => {
        onUpdateFieldBlock(event.target.value, idTrigger, blockId, idGroup, EXPRESSION);
        if (expressionType === EXPRESSION) {
            setNameExpression(event.target.value);
        } else {
            setNameVariable(event.target.value);
        }
    };

    const handleUpdateFieldBlock = (value, idDevice, idBlock, idGroup, typeComp) => {
        onUpdateFieldBlock(value, idDevice, idBlock, idGroup, typeComp);
    };

    const templatesPanel = () => {
        return (
            <>
                {detectedBlockFields(fields) === FLOAT && (
                    <InputNumberBlock
                        value={getValueField(fields)}
                        label={info.hints.value}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => handleUpdateFieldBlock(Number(e.target.value), idTrigger, blockId, idGroup)}
                    />
                )}
                {detectedBlockFields(fields) === INT && (
                    <InputNumberBlock
                        value={getValueField(fields)}
                        step={0.1}
                        label={info.hints.value}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                    </InfoButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => handleUpdateFieldBlock(Number(e.target.value), idTrigger, blockId, idGroup)}
                    />
                )}
                {detectedBlockFields(fields) === BOOL && (
                    <SelectBlock
                        className="small"
                        options={getValueField(fields)}
                        onChange={(e) => handleUpdateFieldBlock(e.target.value, idTrigger, blockId, idGroup)}
                    />
                )}
                {detectedBlockFields(fields) === TOKEN && (
                    <SelectBlock
                        options={getValueField(fields)}
                        onChange={(e) => handleUpdateFieldBlock(e.target.value, idTrigger, blockId, idGroup)}
                    />
                )}
            </>
        );
    };

    if (detectedBlockFields(fields) === EMPTY || !detectedBlockFields(fields)) {
        return null;
    }

    return (
        <div className="trigger-block__field">
            {getMethodName(blockOptions) === 'compareNumbers' || getMethodName(blockOptions) === 'isItemState' ? (
                <>
                    <FormControl className="trigger-block__select" variant="standard">
                        <InputLabel>{t(info.hints.valueType)}</InputLabel>
                        <Select
                            MenuProps={optionsSelect}
                            value={fieldValue ? fieldValue : ''}
                            onChange={(e) => handleChangeBlock(e)}
                            endAdornment={
                                <InfoButton>
                                    <div dangerouslySetInnerHTML={{ __html: t(info.text.valueType) }}></div>
                                </InfoButton>
                            }
                        >
                            <MenuItem value={VALUE_BLOCK}>{t(EZLOGIC_TITLE_VALUE)}</MenuItem>
                            <MenuItem value={EXPRESSION}>{t(EZLOGIC_TITLE_EXPRESSION)}</MenuItem>
                            <MenuItem value={VARIABLE}>{t(EZLOGIC_TITLE_VARIABLE)}</MenuItem>
                        </Select>
                    </FormControl>
                    {fieldValue === VALUE_BLOCK && templatesPanel()}
                    {fieldValue === EXPRESSION && (
                        <FormControl className="trigger-block__select" variant="standard">
                            <InputLabel>{t(info.hints.value)}</InputLabel>
                            <Select
                                MenuProps={optionsSelect}
                                value={nameExpression ? nameExpression : ''}
                                onChange={(event) => {
                                    changeNameExpression(event, EXPRESSION);
                                }}
                                endAdornment={
                                    <InfoButton>
                                        <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                    </InfoButton>
                                }
                            >
                                {expressionItems &&
                                    expressionItems.map((item) => {
                                        return (
                                            <MenuItem key={item.name} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    )}
                    {fieldValue === VARIABLE && (
                        <FormControl className="trigger-block__select">
                            <Select
                                MenuProps={optionsSelect}
                                value={nameVariable ?? ''}
                                onChange={(event) => {
                                    changeNameExpression(event, VARIABLE);
                                }}
                            >
                                {variableItems &&
                                    variableItems.map((item) => {
                                        return (
                                            <MenuItem key={item.name} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    )}
                </>
            ) : (
                templatesPanel()
            )}
        </div>
    );
};

export default TriggerFieldBlock;

TriggerFieldBlock.propTypes = {
    fields: PropTypes.array,
    label: PropTypes.object,
    idDev: PropTypes.string,
    idTrigger: PropTypes.string,
    blockId: PropTypes.string,
    idGroup: PropTypes.string,
    addLabelName: PropTypes.func,
    blockOptions: PropTypes.object,
    handlerUpdateTriggerBlock: PropTypes.func,
    onUpdateFieldBlock: PropTypes.func,
};
