import './styles.scss';
import React from 'react';
import EmptyStateIcon from '../../../EzloHubBackups/icons/EmptyStateIcon';
import { useTranslate } from '../../../../../features/languages';

const EmptyMeshBotsTableOverlay = ({ title, description }) => {
    const { t } = useTranslate();

    return (
        <div className="empty-meshbots">
            <EmptyStateIcon className="empty-meshbots__icon" />
            <span className="empty-meshbots__title">{t(title)}.</span>
            <span className="empty-meshbots__text">{t(description)}</span>
        </div>
    );
};

export default EmptyMeshBotsTableOverlay;
