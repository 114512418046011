import React from 'react';
import PropTypes from 'prop-types';
import SwitchBool from '../../../../../components/SwitchBool';
import style from './style.module.scss';

const PrefixTemplate = (props) => {
    const { prefix, onEnablePrefix } = props;
    const { display_name, enabled, uuid } = prefix;

    return (
        <li className={style.prefixTemplate}>
            <span className={style.prefixTemplate__name}>{display_name}</span>
            <SwitchBool
                noOffOn={true}
                checked={enabled === 1 ? true : false}
                disabled={enabled === 0}
                color="primary"
                onChange={(e) => onEnablePrefix(e, uuid)}
            />
        </li>
    );
};

PrefixTemplate.propTypes = {
    prefix: PropTypes.object,
    onEnablePrefix: PropTypes.func,
};

export default PrefixTemplate;
