import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MeshBotAction } from '../../../../../actions';
import { meshBotLabelsUuidByTypeSelector } from '../../utils';
import LabelsDropdown from '../LabelsDropdown';
import SelectButton from '../SelectButton';

import styles from './styles.module.scss';

const MeshBotLabelsDropdown = ({ type }) => {
    const { meshBotLabelsUuids } = useSelector(meshBotLabelsUuidByTypeSelector(type));
    const dispatch = useDispatch();

    const selectLabel = (value) => {
        const isLabelUuidInMeshBot = meshBotLabelsUuids.includes(value.id);
        if (!isLabelUuidInMeshBot) {
            dispatch(MeshBotAction.setMehBotLabels(value, type));
        }

        if (isLabelUuidInMeshBot) {
            dispatch(MeshBotAction.removeSelectedMeshBotLabel(value.id, type));
        }
    };

    return (
        <LabelsDropdown
            ButtonComponent={SelectButton}
            rootDivClassName={styles.selectDropdownWrapper}
            contextValue={{
                selectLabel,
                selectedLabelsUuids: meshBotLabelsUuids,
            }}
        />
    );
};

export default MeshBotLabelsDropdown;
