import moment from 'moment';

export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return moment(tomorrow).format('YYYY-MM-DD hh:mm:ss');
};

export const getIssueForSupportTicket = ({ issue }) => {
    return issue;
};

export const getTicketCreatePayload = ({
    first_name,
    last_name,
    email,
    phone,
    issue,
    userUuid,
    priority,
    helptopic,
}) => {
    return {
        data: {
            type: 'tickets',
            attributes: {
                duedate: getTomorrowDate(),
                ticket_thread: {
                    subject: 'Resident Request',
                    detail: getIssueForSupportTicket({ first_name, last_name, email, phone, issue }),
                    notes: '',
                },
            },
            relationships: {
                slaplan: {
                    data: {
                        type: 'slaplans',
                        id: '1',
                    },
                },
                user: {
                    data: {
                        type: 'users',
                        id: String(userUuid),
                    },
                },
                staff: {
                    data: {
                        type: 'users',
                        id: String(userUuid),
                    },
                },
                helptopic: {
                    data: {
                        type: 'helptopics',
                        id: helptopic,
                    },
                },
                ticketpriority: {
                    data: {
                        type: 'ticketpriorities',
                        id: priority,
                    },
                },
            },
        },
    };
};

export const getDataForUpdateTicket = (value, name) => {
    return {
        key: name,
        value,
    };
};

export const getEncodeValue = (customizationData) => {
    return customizationData?.data?.data?.list?.find((data) => data.value)?.value || '';
};
