import moment from 'moment';
import { BACKUP_SUPPORTED_ARCHITECTURES, BACKUPS_DEFAULT_UNIT_AMOUNT, BACKUPS_DEFAULT_UNIT_PERIOD } from './constants';

export const formatBackupTimestamp = (timestamp = 0, offset = 0) =>
    moment(new Date(Number(timestamp)))
        .utc()
        .add(offset, 'seconds')
        .format('DD MMM, h:mm a');
export const currentlyTime = () => moment().format('DD MMM, h:mm a');
export const isBackupSupportedDevice = (architecture) => {
    if (typeof architecture === 'string' && architecture !== '') {
        return BACKUP_SUPPORTED_ARCHITECTURES.includes(architecture);
    }

    return false;
};

export const getFilteredBackups = (currentController, backups) => {
    return backups.filter((backup) => {
        const serial = backup?.meta?.controller_info?.config?.serial;

        return currentController === serial;
    });
};
export const getCurrentControllerBackups = (currentController, backups) => {
    const filteredBackupsByController = getFilteredBackups(currentController, backups);

    return filteredBackupsByController.sort(sortByTimestamp) || [];
};

export const sortByTimestamp = (a, b) => {
    return b.timestamp - a.timestamp;
};

/**
 * Returns subtracted timestamp based on amount and periodName values
 * @param {number} amount - amount of units
 * @param {string} unit - unit name, e.g. 'days', 'weeks'. See "moment" docs
 * @returns {number} timestamp in millis
 * */
export const getSubstractedTimestamp = (amount = BACKUPS_DEFAULT_UNIT_AMOUNT, unit = BACKUPS_DEFAULT_UNIT_PERIOD) => {
    return moment().subtract(amount, unit).valueOf();
};
