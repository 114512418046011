import './style.scss';
import React, { useEffect, useState } from 'react';
import { Box, Slider, TextField, InputAdornment } from '@material-ui/core';

import { InputMaterial } from '../index';
import { getDesiredFormatValue, getHex, isHex } from './utils';
import {
    VARIABLES_FIELDS_LABELS,
    HEX_COLOR_SYMBOLS_MAX_LENGTH,
    INPUT_ADORONMENT_END_POSITION,
    INPUT_APPEARANCE_VARIANT_STANDARD,
    INPUT_APPEARANCE_VARIANT_OUTLINED,
} from '../../constants/Variables';
import { useTranslate } from '../../features/languages';

import { info } from '../../containers/Ezlo/EzloMeshbot/utils';
import InfoButton from '../../containers/Ezlo/EzloMeshbot/components/InfoButton';
import * as styles from './ColorInputAndColorPickers.module.scss';
import { EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE } from '../../constants/language_tokens';

const InputAndColorPicker = ({
    value = {},
    idDevice,
    handleUpdateFieldActionBlock,
    _tempId,
    actionBlockName,
    isCloud,
    isVariablePage,
    onChangeRgbValue,
    disabled,
    isMeshbotPage,
}) => {
    const { red = 0, green = 0, blue = 0, wwhite, cwhite } = value;
    const [color, setColor] = useState(getHex(red, green, blue));
    const [isChangingColorPicker, setIsChangingColorPicker] = useState(false);
    const desiredFormatValue = getDesiredFormatValue(color, value, isChangingColorPicker);

    const { t } = useTranslate();

    useEffect(() => {
        if (isHex(color)) {
            isVariablePage
                ? isChangingColorPicker && onChangeRgbValue(desiredFormatValue)
                : handleUpdateFieldActionBlock(desiredFormatValue, idDevice, _tempId, actionBlockName);
        }
    }, [color]);
    useEffect(() => {
        setColor(getHex(red, green, blue));
    }, [red, green, blue]);

    const onChangeColorPicker = (e) => {
        setColor(e.target.value);
        setIsChangingColorPicker(true);
    };
    const onChangeSlider = (value) => {
        isVariablePage
            ? onChangeRgbValue(value)
            : handleUpdateFieldActionBlock(value, idDevice, _tempId, actionBlockName);
        setIsChangingColorPicker(false);
    };

    return (
        <div className={isVariablePage && styles.flexWrap}>
            {isVariablePage ? (
                <TextField
                    type="text"
                    value={color}
                    className={isMeshbotPage ? styles.meshbotColorInput : styles.variableColorInput}
                    label={isMeshbotPage ? t(info.hints.value) : t(VARIABLES_FIELDS_LABELS.VARIABLE_VALUE)}
                    variant={isMeshbotPage ? INPUT_APPEARANCE_VARIANT_STANDARD : INPUT_APPEARANCE_VARIANT_OUTLINED}
                    size="small"
                    placeholder={t(EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE)}
                    disabled={disabled}
                    onChange={onChangeColorPicker}
                    inputProps={{ maxLength: HEX_COLOR_SYMBOLS_MAX_LENGTH }}
                    InputProps={{
                        endAdornment: isMeshbotPage && (
                            <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                                <InfoButton>
                                    <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                </InfoButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ) : (
                <InputMaterial value={color} onChange={onChangeColorPicker} />
            )}
            <input
                className={`${isVariablePage && styles.variableColorPicker} color-picker__input`}
                onChange={onChangeColorPicker}
                type="color"
                value={isHex(color) ? color : getHex(red, green, blue)}
                disabled={disabled}
            />
            {!isCloud && (
                <>
                    <span className={isVariablePage && styles.variableColorSpan}>{t('ezlo.color_picker.or')}</span>
                    <Box className={isVariablePage ? styles.variableColorSliderBox : 'sliders-box'}>
                        <ColorSlider
                            sliderView="wwhite"
                            value={wwhite}
                            onChangeSlider={onChangeSlider}
                            disabled={disabled}
                        />
                        <ColorSlider
                            sliderView="cwhite"
                            value={cwhite}
                            onChangeSlider={onChangeSlider}
                            disabled={disabled}
                        />
                    </Box>
                </>
            )}
        </div>
    );
};

const getValue = (e, sliderValue, onChangeSlider, sliderView) => {
    const zeroedRGB = { red: 0, green: 0, blue: 0 };
    let newSliderValue = {};
    if (sliderView === 'cwhite') {
        newSliderValue = { wwhite: 0, cwhite: sliderValue, ...zeroedRGB };
    } else if (sliderView === 'wwhite') {
        newSliderValue = { cwhite: 0, wwhite: sliderValue, ...zeroedRGB };
    } else if (sliderView === 'target_cool_white') {
        newSliderValue = { target_cool_white: sliderValue, target_warm_white: 0 };
    } else {
        newSliderValue = { target_warm_white: sliderValue, target_cool_white: 0 };
    }

    onChangeSlider(newSliderValue, sliderValue, sliderView);
};

export const ColorSlider = ({ sliderView, value = 0, onChangeSlider, disabled }) => {
    return (
        <>
            <div className={`color-picker color-picker__${sliderView}`}>
                <Slider
                    className={value === 0 ? 'slider__inactive' : ''}
                    min={0}
                    max={255}
                    color="secondary"
                    onChange={(e, sliderValue) => {
                        getValue(e, sliderValue, onChangeSlider, sliderView);
                    }}
                    value={value}
                    track={false}
                    disabled={disabled}
                />
            </div>
            <span className={'color-picker_value'}>{value}</span>
        </>
    );
};

export default InputAndColorPicker;
