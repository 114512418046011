import React from 'react';

import { InputMaterial } from '../../../../../../components';

import * as meshBot from '../../../../../../constants/MeshbotConstant';
import ToggleSwitchAddingOffset from './ToggleSwitchAddingOffset';
import OffsetDirectionSelectionComponent from './OffsetDirectionSelectionComponent';

import styles from './TimeOffsetBlock.module.scss';
import {
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_SECONDS,
} from '../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../features/languages';

const TimeOffsetBlock = (props) => {
    const {
        hours,
        minutes,
        seconds,
        onChangeValue,
        addedOffset,
        offsetDirection,
        onChangeDisplayOffset,
        onSelectOffsetDirection,
    } = props;
    const { t } = useTranslate();

    return (
        <>
            <ToggleSwitchAddingOffset addedOffset={addedOffset} onChangeDisplayOffset={onChangeDisplayOffset} />
            <div className={styles.offset_template}>
                {addedOffset && (
                    <>
                        <OffsetDirectionSelectionComponent
                            offsetDirection={offsetDirection}
                            onSelectOffsetDirection={onSelectOffsetDirection}
                        />
                        <InputMaterial
                            label={t(EZLOGIC_TITLE_HOURS)}
                            placeholder={t(EZLOGIC_TITLE_HOURS)}
                            type={meshBot.NUMBER}
                            value={hours}
                            min={meshBot.ZERO_INT}
                            max={meshBot.HOUR11}
                            step={meshBot.STEP_1}
                            onChange={(value) => onChangeValue(value, meshBot.HOURS, meshBot.IS_DATE_TIME)}
                            xsSmall
                        />
                        <InputMaterial
                            label={t(EZLOGIC_TITLE_MINUTES)}
                            placeholder={t(EZLOGIC_TITLE_MINUTES)}
                            type={meshBot.NUMBER}
                            value={minutes}
                            min={meshBot.ZERO_INT}
                            max={meshBot.MAX_VALUE_FOR_TIME}
                            step={meshBot.STEP_1}
                            onChange={(value) => onChangeValue(value, meshBot.MINUTES, meshBot.IS_DATE_TIME)}
                            xsSmall
                        />
                        <InputMaterial
                            label={t(EZLOGIC_TITLE_SECONDS)}
                            placeholder={t(EZLOGIC_TITLE_SECONDS)}
                            type={meshBot.NUMBER}
                            value={seconds}
                            min={meshBot.ZERO_INT}
                            max={meshBot.MAX_VALUE_FOR_TIME}
                            step={meshBot.STEP_1}
                            onChange={(value) => onChangeValue(value, meshBot.SECONDS, meshBot.IS_DATE_TIME)}
                            xsSmall
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default TimeOffsetBlock;
