import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { VALUE_TYPES_FAMILIES } from '../../constants/MeshbotConstant';

const useComparatorOptions = (valueType) => {
    const [currentValueType, setCurrentValueType] = useState(valueType);

    useEffect(() => {
        setCurrentValueType(valueType);
    }, [valueType]);

    const serial = useSelector((state) => state.ezlo.serial);
    const valueTypeFamilies = useSelector((state) => state.ezlo.data[serial].blockDataList?.valueTypeFamilies);

    const comparisonOperatorsByFamilies = useSelector(
        (state) => state.ezlo.data[serial].blockDataList?.comparisonOperators?.families,
    );

    if (valueTypeFamilies) {
        const { numeric, strings } = valueTypeFamilies;

        let familyName;

        if (numeric.includes(currentValueType)) {
            familyName = VALUE_TYPES_FAMILIES.NUMERIC;
        } else if (strings.includes(currentValueType)) {
            familyName = VALUE_TYPES_FAMILIES.STRINGS;
        } else {
            familyName = VALUE_TYPES_FAMILIES.VALUES_WITHOUT_LESS;
        }

        return comparisonOperatorsByFamilies.find(({ family }) => familyName === family) || {};
    }
};

export default useComparatorOptions;
