import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationAction from '../modalTemplates/ConfirmationAction';
import Modal from '../Modal';
import './modalScript.scss';

const ModalScript = (props) => {
    const { open, onCancel, scriptData, currentSerial, currentAction, MeshBotAction, typeModal } = props;

    const createTemplateSettings = (type) => {
        switch (type) {
            case 'confirm':
                return (
                    <ConfirmationAction
                        currentSerial={currentSerial}
                        scriptData={scriptData}
                        currentAction={currentAction}
                        MeshBotAction={MeshBotAction}
                        onCancel={onCancel}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal open={open} handleClose={onCancel} className="device-modal">
            {createTemplateSettings(typeModal)}
        </Modal>
    );
};

ModalScript.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    typeModal: PropTypes.string,
    currentSerial: PropTypes.string,
    currentAction: PropTypes.string,
    scriptData: PropTypes.object,
    MeshBotAction: PropTypes.object,
};

export default React.memo(ModalScript);
