import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const SearchInput = ({ value, onChangeInput, placeholder }) => {
    return (
        <TextField
            value={value}
            onChange={onChangeInput}
            color="primary"
            placeholder={placeholder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search fontSize="large" color="action" />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchInput;
