import Cloud from '@material-ui/icons/Cloud';
import { ControllerIcon, NotificationMeshbot, InteractionIcon, IcActionWrench } from '../../../assets/icons';
import {
    EZLOGIC_TITLE_AUTOMATION,
    EZLOGIC_TITLE_CLOUD_MESHBOT,
    EZLOGIC_TITLE_INTEGRATION,
    EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT,
    EZLOGIC_TITLE_LOCAL_MESHBOT,
    EZLOGIC_TITLE_NOTIFICATION,
    EZLOGIC_TITLE_NOTIFICATION_MESHBOT,
    EZLOGIC_TITLE_SYSTEM,
    EZLOGIC_TITLE_SYSTEM_MESHBOT,
} from '../../../constants/language_tokens';

/**
 * Rows per page, used in GridTable
 * */
export const ROWS_PER_PAGE = [10, 25, 50];

/**
 * Rows per page, used in MeshBots table
 * */
export const MESHBOTS_TABLE_ROWS_PER_PAGE = 50;
/**
 * Initial page number, used in MeshBots table
 * */
export const INITIAL_TABLE_PAGE_NUMBER = 0;
/**
 * MeshBot types
 * */
export const MESHBOT_TYPES = {
    CLOUD: 'cloud',
    CLOUD_NOTIFICATION: 'notification',
    CLOUD_INTERACTION: 'interaction',
    LOCAL: 'local',
    NOTIFICATION_TEMPLATE: 'notificationTemplate',
};
export const CLOUD_MESHBOT_TYPES = [
    MESHBOT_TYPES.CLOUD,
    MESHBOT_TYPES.CLOUD_NOTIFICATION,
    MESHBOT_TYPES.CLOUD_INTERACTION,
];

/**
 * Default MeshBot listing page title
 * */
export const MESHBOTS_PAGE_TITLE = 'MeshBots';

/**
 * Default MeshBots sorting model
 */
export const MESHBOT_SORTING_MODEL = {
    field: 'name',
    modelSort: 'asc',
};

/**
 * MeshBot Cloud types
 * */

export const DUPLICATE_MESHBOT = 'Copy of ';
export const MESHBOT_CLOUD_TYPES = ['cloud', 'notification', 'interaction'];

export const CLOUD_MESHBOT = 'cloudMeshBot';
export const SYSTEM = 'system';
export const NOTIFICATION = 'notification';
export const NOTIFICATION_TEMPLATE_ABSTRACT_UUID = '{abstract.uuid}';

export const INDETERMINATE = 'indeterminate';
/**
 * Configuration object that maps Meshbot types to their respective configuration data.
 *
 * @type {Object}
 */
export const meshBotsTypeCellConfig = {
    [MESHBOT_TYPES.CLOUD]: {
        tableTitleKey: EZLOGIC_TITLE_AUTOMATION,
        hoverTitleKey: EZLOGIC_TITLE_CLOUD_MESHBOT,
        MeshBotTypeIcon: Cloud,
    },
    [MESHBOT_TYPES.CLOUD_NOTIFICATION]: {
        tableTitleKey: EZLOGIC_TITLE_NOTIFICATION,
        hoverTitleKey: EZLOGIC_TITLE_NOTIFICATION_MESHBOT,
        MeshBotTypeIcon: NotificationMeshbot,
    },
    [MESHBOT_TYPES.CLOUD_INTERACTION]: {
        tableTitleKey: EZLOGIC_TITLE_INTEGRATION,
        hoverTitleKey: EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT,
        MeshBotTypeIcon: InteractionIcon,
    },
    [MESHBOT_TYPES.LOCAL]: {
        tableTitleKey: EZLOGIC_TITLE_AUTOMATION,
        hoverTitleKey: EZLOGIC_TITLE_LOCAL_MESHBOT,
        MeshBotTypeIcon: ControllerIcon,
    },
    [SYSTEM]: {
        tableTitleKey: EZLOGIC_TITLE_SYSTEM,
        hoverTitleKey: EZLOGIC_TITLE_SYSTEM_MESHBOT,
        MeshBotTypeIcon: IcActionWrench,
    },
};

/**
 * Anchor setups of more menu
 * */
export const moreMenuAnchorOrigin = { vertical: 'bottom', horizontal: 'left' };
/**
 * Transform setups of more menu
 * */
export const moreMenuTransformOrigin = { vertical: 'top', horizontal: 'left' };
