import React from 'react';
import { MenuItem } from '@material-ui/core';

import { InputMaterial } from '../../../../../../components';
import SelectComponent from '../../SelectComponent';
import TimeZoneSelect from '../../DateAndTime/TimeZoneSelect';

import {
    HOUR_MIN_INPUT_VALUE,
    HOUR_MAX_INPUT_VALUE,
    offsetOptions,
    specialTimeOfTheDayOptions,
} from '../../../../../../constants/CloudMeshbotConstant';

import {
    getHoursForSpecialTimeOfTheDay,
    getHoursFromState,
    getMinutesForSpecialTimeOfTheDay,
    getMinutesFromState,
    getOffsetFromState,
    getSpecialTimeOptionFromState,
} from './utils';

import styles from './CloudOffset.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import {
    EZLOGIC_HINT_CAPABILITY,
    EZLOGIC_HINT_VALUE_TYPE,
    EZLOGIC_LABEL_VALUE_TYPE,
    EZLOGIC_TITLE_CAPABILITY,
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_OFFSET,
} from '../../../../../../constants/language_tokens';

const CloudOffset = (props) => {
    const { id, selectedFieldDate, blocks, idGroup, onUpdateDateTriggersCloud } = props;
    const hours = getHoursFromState(blocks);
    const minutes = getMinutesFromState(blocks);
    const { t } = useTranslate();

    const handleChangeHours = (e) => {
        e.target.value = e.target.value.length > 2 ? e.target.value.slice(1, 3) : e.target.value;
        e.target.value = Number(e.target.value) > Number(e.target.max) ? e.target.max : e.target.value;

        const time = getHoursForSpecialTimeOfTheDay(e.target.value, blocks);

        onUpdateDateTriggersCloud(time, id, selectedFieldDate, idGroup);
    };

    const handleChangeMinutes = (e) => {
        e.target.value = e.target.value.length > 2 ? e.target.value.slice(1, 3) : e.target.value;
        e.target.value = Number(e.target.value) > Number(e.target.max) ? e.target.max : e.target.value;

        const time = getMinutesForSpecialTimeOfTheDay(e.target.value, blocks);

        onUpdateDateTriggersCloud(time, id, selectedFieldDate, idGroup);
    };

    return (
        <>
            <p className={styles.offset}>{t(EZLOGIC_TITLE_OFFSET)}</p>
            <InputMaterial
                label={t(EZLOGIC_TITLE_HOURS)}
                placeholder={t(EZLOGIC_TITLE_HOURS)}
                type="number"
                value={hours}
                min={HOUR_MIN_INPUT_VALUE}
                max={HOUR_MAX_INPUT_VALUE}
                step={1}
                onChange={handleChangeHours}
                small
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_MINUTES)}
                placeholder={t(EZLOGIC_TITLE_MINUTES)}
                type="number"
                value={minutes}
                min={0}
                max={59}
                step={1}
                onChange={handleChangeMinutes}
                small
            />
        </>
    );
};

export const CloudSpecialTimeOfTheDay = (props) => {
    const { id, selectedFieldDate, blocks, idGroup, onUpdateDateTriggersCloud, handleChangeTimezone } = props;
    const offset = getOffsetFromState(blocks);
    const specialTimeOption = getSpecialTimeOptionFromState(blocks);
    const { t } = useTranslate();

    const handleChangeName = (e) => {
        const name = e.target.value;

        onUpdateDateTriggersCloud({ name }, id, selectedFieldDate, idGroup);
    };

    const handleChangeOffset = (e) => {
        const offset = e.target.value;

        onUpdateDateTriggersCloud({ offset }, id, selectedFieldDate, idGroup);
    };

    return (
        <>
            <SelectComponent
                value={specialTimeOption}
                label={t(EZLOGIC_TITLE_CAPABILITY)}
                info={t(EZLOGIC_HINT_CAPABILITY)}
                onChange={handleChangeName}
            >
                {specialTimeOfTheDayOptions.map(({ id, value, label }) => (
                    <MenuItem key={id} value={value}>
                        {t(label)}
                    </MenuItem>
                ))}
            </SelectComponent>

            <SelectComponent
                value={offset}
                label={t(EZLOGIC_LABEL_VALUE_TYPE)}
                info={t(EZLOGIC_HINT_VALUE_TYPE)}
                onChange={handleChangeOffset}
            >
                {offsetOptions.map(({ id, value, label }) => (
                    <MenuItem key={id} value={value}>
                        {t(label)}
                    </MenuItem>
                ))}
            </SelectComponent>

            {offset !== 0 && (
                <CloudOffset
                    id={id}
                    selectedFieldDate={selectedFieldDate}
                    blocks={blocks}
                    idGroup={idGroup}
                    onUpdateDateTriggersCloud={onUpdateDateTriggersCloud}
                />
            )}

            <TimeZoneSelect handleChangeTimezone={handleChangeTimezone} blocks={blocks} />
        </>
    );
};
