import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { AuthorizationContextProvider } from '../context/AuthorizationContext';
import { USER_PASSWORD_INFO_GET_PARAM_NAME } from '../constants/getParams';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS } from '../../../constants/toastConstants';
import toastsActions from '../../../actions/toastsActions';

import SetPasswordFormContainer from './components/SetPasswordForm';
import SetPasswordSuccessContainer from './components/SetPasswordSuccessContainer';
import InvalidSetPasswordLink from './components/InvalidSetPasswordLink';
import useMmsSetPassword from './hooks/useMmsSetPassword';
import NotFoundPageContent from '../../NotFoundPage/NotFoundPageContent';

import styles from './FirstLoginPage.module.scss';
import { LanguageSelector } from '../../../features/languages/components';
import { languageService } from '../../../services/language';
import { updateLang } from '../../../features/languages/utils';
import { writeFirstLoginConfig } from './utils/utils';
import {
    CURRENT_PASSWORD_FIELD_NAME,
    NEW_PASSWORD_FIELD_NAME,
} from '../../Ezlo/EzloAccount/UserManagement/ChangePassword/constants';
import { HTTP_STATUS_CODES } from '../../../constants/common/http_status_codes';
import { AppLogo } from '../../../assets/icons';

/**
 * The page is used to SET first password for the user right after account creation.
 * PRD: https://confluence.mios.com/pages/viewpage.action?spaceKey=PDMT&title=PRD+-+Initial+password+creation+flow
 * */
const FirstLoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const language = languageService.getLanguageKey();
    const { search } = useLocation();
    const { url } = useRouteMatch();

    const [params, setParams] = useState({ username: null, password: null });
    const [data, error, loading, setPassword] = useMmsSetPassword();
    const [currentLang, setCurrentLang] = useState(language);

    useEffect(() => {
        const query = new URLSearchParams(search);

        const userPasswordInfo = query.get(USER_PASSWORD_INFO_GET_PARAM_NAME);
        try {
            const decodedUserPasswordInfo = JSON.parse(atob(userPasswordInfo));
            setParams(decodedUserPasswordInfo);
        } catch (e) {
            //TODO: handle error, right now error will be displayed as a toast message
        }
    }, []);

    useEffect(() => {
        if (error) {
            if (error.status === HTTP_STATUS_CODES.NOT_FOUND) {
                history.replace({ pathname: '/firstlogin/error', state: { isActive: true } });
            } else {
                dispatch(
                    toastsActions.showToast({
                        message: error,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                    }),
                );
            }
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            // TODO: could be replaced by auth context?
            writeFirstLoginConfig(data?.Identity);
            history.replace({ pathname: '/firstlogin/success', state: { isActive: true } });
        }
    }, [data]);

    const setUserPassword = async (values) => {
        const { newPassword } = values;
        const { username, password } = params;
        await setPassword({
            username,
            [CURRENT_PASSWORD_FIELD_NAME]: password,
            [NEW_PASSWORD_FIELD_NAME]: newPassword,
        });
    };

    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);
    };

    useEffect(() => {
        updateLang(currentLang);
    }, [currentLang]);

    return (
        <AuthorizationContextProvider>
            <div className="logo">
                <AppLogo className="logo__icon" />
            </div>
            <div className={styles.language}>
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} isLoggin={true} />
            </div>
            <section className={styles.contentContainer}>
                <Switch>
                    <Route
                        exact
                        path={url}
                        render={() => <SetPasswordFormContainer isLoading={loading} onSubmit={setUserPassword} />}
                    />
                    <Route path={`${url}/error`} render={() => <InvalidSetPasswordLink />} />
                    <Route path={`${url}/success`} render={() => <SetPasswordSuccessContainer />} />
                    <Route render={() => <NotFoundPageContent loggedIn />} />
                </Switch>
            </section>
        </AuthorizationContextProvider>
    );
};

export default FirstLoginPage;
