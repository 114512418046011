import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableContainer,
    Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ZERO } from '../../EzloCustomization/constants';
import DeviceAssociationsItem from '../DeviceAssociationsItem';
import AssociationsLayout from '../DeviceAssociationsLayout';
import DeviceAssociateListHeader from './DeviceAssociationListHeader';
import {
    EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION,
    EZLOGIC_TITLE_DEVICE_SUPPORTS_ONLY_ONE_ASSOCIATION,
    EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS_REACHED,
} from '../../../../constants/language_tokens';
import InfoIcon from '../../EzloGroups/DeleteGroupDialog/InfoIcon';
import { useTranslate } from '../../../../features/languages';
import { getMaximumAssociationsFromAllGroups } from '../utils';

import styles from './DeviceAssociationList.module.scss';
import { warning } from '../DeviceAssociationsLayout/style.module.scss';
import { PlusIcon } from '../../../../assets/icons';

const DeviceAssociationsList = (props) => {
    const { associationsList, serial, isConnected, onRemove } = props;
    const settings = useSelector((state) => state.ezlo?.data[serial]?.settings);
    const [openForm, setOpenForm] = useState(false);
    const [associationId, setAssociationId] = useState(false);
    const uniqueAssociationSettingIds = [...new Set(associationsList?.map((item) => item.settingId))];
    const { t } = useTranslate();
    const handleCreateForm = (settingId) => {
        setOpenForm(true);
        setAssociationId(settingId);
    };

    return (
        <div className={styles.associationList}>
            {uniqueAssociationSettingIds?.map((settingId) => {
                const associationsForSetting = associationsList?.filter((item) => item.settingId === settingId);
                const sourceDeviceName = associationsForSetting[ZERO]?.sourceDevice.name;
                const sourceDeviceId = associationsForSetting[ZERO]?.sourceDevice._id;
                const isButtonVisible = !openForm || associationId !== settingId;
                const maximumAssociationsFromAllGroups = getMaximumAssociationsFromAllGroups(sourceDeviceId, settings);

                return (
                    <Accordion key={settingId} className={styles.associationListContent}>
                        <AccordionSummary
                            className={styles.accordionSummary}
                            expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                        >
                            <DeviceAssociateListHeader
                                sourceDeviceName={sourceDeviceName}
                                settingId={settingId}
                                sourceDeviceId={sourceDeviceId}
                                settings={settings}
                                associationsList={associationsList}
                            />
                        </AccordionSummary>
                        <AccordionDetails className={styles.associationListData}>
                            <div data-testid="association-form">
                                <TableContainer className={styles.tableContainer}>
                                    <Table key={settingId} className={styles.table}>
                                        <TableBody key={settingId}>
                                            {associationsForSetting?.map(
                                                ({
                                                    sourceDevice = {},
                                                    targetDevice = {},
                                                    values = {},
                                                    keyForRender,
                                                    keyOfTargetNodes,
                                                    settingId,
                                                }) => (
                                                    <DeviceAssociationsItem
                                                        sourceDevice={sourceDevice}
                                                        targetDevice={targetDevice}
                                                        values={values}
                                                        serial={serial}
                                                        isConnected={isConnected}
                                                        keyForRender={keyForRender}
                                                        keyOfTargetNodes={keyOfTargetNodes}
                                                        settingId={settingId}
                                                        onRemove={onRemove}
                                                    />
                                                ),
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            {isButtonVisible && maximumAssociationsFromAllGroups !== associationsForSetting.length && (
                                <Button
                                    className={styles.createAssociationBtn}
                                    onClick={() => handleCreateForm(settingId)}
                                >
                                    <span className={styles.iconContainer}>
                                        <PlusIcon />
                                    </span>
                                    <div>{t(EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION)}</div>
                                </Button>
                            )}
                            {maximumAssociationsFromAllGroups === associationsForSetting.length && (
                                <p className={warning}>
                                    <InfoIcon height={30} width={30} />
                                    {t(EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS_REACHED)}
                                </p>
                            )}
                            {maximumAssociationsFromAllGroups === 1 && (
                                <p className={warning}>
                                    <InfoIcon height={30} width={30} />
                                    {t(EZLOGIC_TITLE_DEVICE_SUPPORTS_ONLY_ONE_ASSOCIATION)}
                                </p>
                            )}

                            {associationId === settingId && openForm && (
                                <AssociationsLayout
                                    isInnerAssociationForm={true}
                                    setOpenForm={setOpenForm}
                                    settingId={sourceDeviceId}
                                    sourceDevice={sourceDeviceName}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};

export default DeviceAssociationsList;
