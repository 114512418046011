import React from 'react';
import { useDispatch } from 'react-redux';
import { Search } from '@material-ui/icons';

import { InputMaterial } from '../../../../../../components';

import { STRING } from '../../../../../../constants/MeshbotConstant';

import { systemHistoryActions } from '../../../../../../actions/SystemHistoryActions';

import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_SEARCH_TITLE } from '../../../../../../constants/language_tokens';

import { searchByDescriptionContainer } from './systemHistorySearchByDescription.module.scss';

const SystemHistorySearchByDescription = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const onChange = (e) => {
        const { value } = e.target;
        dispatch(systemHistoryActions.setHistorySearchByDescription(value));
    };

    return (
        <div className={searchByDescriptionContainer}>
            <Search />
            <InputMaterial placeholder={t(EZLOGIC_SEARCH_TITLE)} type={STRING} onChange={(e) => onChange(e)} />
        </div>
    );
};

export default SystemHistorySearchByDescription;
