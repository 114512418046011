import React from 'react';

export const LogoutIcon = (props) => (
    <svg
        {...props}
        version="1.1"
        viewBox="0 0 24 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
    >
        <path d="M2.4 2.5C1 2.8 0 4 0 5.5V24.6C0 26 1 27.3 2.4 27.5L15 30V0L2.4 2.5ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13V17ZM21 3C22.7 3 24 4.3 24 6V24C24 25.7 22.7 27 21 27H18V24.2H21C21 24.1 21 5.8 21 5.7H18V3H21Z" />
    </svg>
);

export default LogoutIcon;
