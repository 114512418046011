import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentExceptionTrigger } from '../../containers/Ezlo/EzloMeshbot/utils';

const useExceptionSelect = (id, actionId, idGroup) => {
    const triggers = useSelector((state) => state.meshBot?.local?.exceptions) || {};

    return getCurrentExceptionTrigger(triggers, id, actionId, idGroup);
};

useExceptionSelect.propTypes = {
    id: PropTypes.string,
    actionId: PropTypes.string,
    idGroup: PropTypes.string,
};

export default useExceptionSelect;
