import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { BROADCAST_DEVICE_REMOVER } from '../../../../../constants/Devices';
import './styles.scss';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_BUTTON_CONFIRM,
    EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { useDispatch } from 'react-redux';
import { DeviceActions } from '../../../../../actions';

const ConfirmationModal = (props) => {
    const { onCancel, currentFunction, onSubscribeBroadcast } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const sendRequest = () => {
        dispatch(
            DeviceActions.deviceForceRemove(currentFunction?.device?.serial, { _id: currentFunction?.device?._id }),
        );
        onSubscribeBroadcast(currentFunction?.device?.serial, BROADCAST_DEVICE_REMOVER);
        onCancel();
    };

    return (
        <div className="modal-confirmation">
            <p className="modal-confirmation__title">{t(EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE)}</p>
            <div className="modal-confirmation__buttons">
                <Button variant="outlined" onClick={onCancel}>
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </Button>
                <Button variant="contained" color="primary" onClick={sendRequest}>
                    {t(EZLOGIC_BUTTON_CONFIRM)}
                </Button>
            </div>
        </div>
    );
};

ConfirmationModal.propTypes = {
    currentFunction: PropTypes.object,
    DeviceActions: PropTypes.object,
    onCancel: PropTypes.func,
    onSubscribeBroadcast: PropTypes.func,
};

export default ConfirmationModal;
