import useMeshBotType from './useMeshBotType';
import { useMemo } from 'react';

import { MESHBOT_SECTION_TYPE } from '../constants';
import { MESHBOT_TYPES } from '../../EzloMeshbots/constants';

const useShowActionExecutionPolicy = (sectionType) => {
    const { currentMeshBotType } = useMeshBotType();

    return useMemo(() => {
        return sectionType === MESHBOT_SECTION_TYPE.ACTION && currentMeshBotType === MESHBOT_TYPES.LOCAL;
    }, [sectionType, currentMeshBotType]);
};

export default useShowActionExecutionPolicy;
