import React from 'react';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

export const NotificationButtonsForDeleteAndEditingCategory = (props) => {
    const { category, onDeleteCategory, onEditCategory } = props;

    return (
        <span>
            <IconButton color="primary" size="medium" onClick={() => onDeleteCategory(category.uuid)}>
                <Delete />
            </IconButton>
            <IconButton
                color="primary"
                size="medium"
                onClick={() => onEditCategory({ uuid: category.uuid, value: true })}
            >
                <Edit />
            </IconButton>
        </span>
    );
};
