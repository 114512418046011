import React, { memo, useContext } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';

import { DragAndDropContext } from '../../../../../ResponseTree/DragAndDropWithContext';
import { RESPONSE_TREE_ELEMENT } from '../../../../../ResponseTree/constants';
import styles from './styles.module.scss';
import {
    EZLOGIC_TITLE_ADD_MANUALLY,
    EZLOGIC_TITLE_DRAG_YOUR_ITEMS_HERE,
} from '../../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../../features/languages';

const useStyles = makeStyles((theme) => ({
    dropZone: {
        borderColor: theme.palette.primary.main,
    },
}));

const DropZoneOfVariables = ({ setDropElement, handleCreateVariable, isCloudVariables, children }) => {
    const { isDragging } = useContext(DragAndDropContext);
    const classes = useStyles();
    const { t } = useTranslate();
    const handleDrop = (e) => {
        e.preventDefault();
        const elementData = e.dataTransfer.getData(RESPONSE_TREE_ELEMENT);
        setDropElement(JSON.parse(elementData));
    };
    const allowDrop = (e) => e.preventDefault();
    const dropContainerClassName = classNames(styles.dragAndDropContainer, {
        [classes.dropZone]: isDragging,
    });

    return (
        <div onDragOver={allowDrop} onDrop={handleDrop} className={dropContainerClassName}>
            {children}
            {!isCloudVariables && <div className={styles.dragAndDropText}>{t(EZLOGIC_TITLE_DRAG_YOUR_ITEMS_HERE)}</div>}
            <div className={styles.button}>
                <Button startIcon={<AddIcon />} variant="outlined" onClick={handleCreateVariable}>
                    {t(EZLOGIC_TITLE_ADD_MANUALLY)}
                </Button>
            </div>
        </div>
    );
};

export default memo(DropZoneOfVariables);
