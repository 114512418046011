import React from 'react';
// import {object} from 'prop-types';
import './style.scss';
import { Button } from '../../../../../components';
import DeviceSettingInfo from '../DeviceHubSettings/DeviceSettingsSyncInfo';

export default class DeviceSettingGRBLight extends React.Component {
    // static propTypes = {
    //     settings: object.isRequired,
    //     device: object,
    // };

    state = {
        red: this.props.value || 0,
        green: this.props.value || 0,
        blue: this.props.value || 0,
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    changeCallback = (e, name) => {
        const { value } = e.target;

        this.setState({ [name]: value });
    };

    restoreHandler = () => {
        const iniValue = this.props.settings.value || [];

        this.setState({ value: iniValue });
    };

    saveHandler = () => {
        const { onSave, settings } = this.props;
        const { value } = this.state;

        if (settings._id) {
            onSave(settings._id, value);
        }
    };

    render() {
        const { settings, titles } = this.props;
        const { red, green, blue } = this.state;
        const { description, status, info, valueDefault } = settings;
        const paramId = info && info['zwave.parameter_number'];

        return (
            <div key={paramId} className="device-alarm__wrapper">
                {status && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.status}</h5>
                            <span className="device-alarm__status">{status}</span>
                        </div>
                        <DeviceSettingInfo />
                    </div>
                )}

                {paramId && (
                    <div className="device-alarm__block">
                        <div>
                            <h5 className="device-alarm__title">{titles.parameterId}</h5>
                            <span className="device-alarm__status">{paramId}</span>
                        </div>
                    </div>
                )}

                {description && <p className="device-alarm__text">{description.text}</p>}

                <div className="device-rgb-preview__box" style={{ backgroundColor: `rgb(${[red, green, blue]}` }}></div>

                <div className="device-rgb-slider__box">
                    <h3>{titles.red}</h3>
                    <input
                        type="range"
                        value={red}
                        min="0"
                        max="255"
                        step="1"
                        className="device-range-slider"
                        onChange={(e) => this.changeCallback(e, 'red')}
                    />
                </div>
                <div className="device-rgb-slider__box">
                    <h3>{titles.green}</h3>
                    <input
                        type="range"
                        value={green}
                        min="0"
                        max="255"
                        step="1"
                        className="device-range-slider"
                        onChange={(e) => this.changeCallback(e, 'green')}
                    />
                </div>
                <div className="device-rgb-slider__box">
                    <h3>{titles.blue}</h3>
                    <input
                        type="range"
                        value={blue}
                        min="0"
                        max="255"
                        step="1"
                        className="device-range-slider"
                        onChange={(e) => this.changeCallback(e, 'blue')}
                    />
                </div>

                <div className="device-range-footer">
                    {valueDefault && (
                        <div className="device-range-restore">
                            <Button className="btn-basic small device-range__button" onClick={this.restoreHandler}>
                                {titles.restore}
                            </Button>
                            <span>{'Set parameter to the default value'}</span>
                        </div>
                    )}

                    <Button className="btn-rounded device-range__button-save" onClick={this.saveHandler}>
                        {titles.save}
                    </Button>
                </div>
            </div>
        );
    }
}
