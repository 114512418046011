import { CONTROLLER_CAPABILITY_RESET, INDEX_SELECTED_BLOCKS_ELEMENT } from '../../../../../constants/MeshbotConstant';
import { TYPE, ENUM } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';

export const generateControllerNodeActionFields = (blockType, methodName, delayValue) => ({
    blockOptions: {
        method: {
            name: methodName,
            args: {},
        },
    },
    blockType: blockType,
    fields: [],
    ...(delayValue && { delay: delayValue }),
});

export const generateControllerNodeResetCapabilityFields = (blockType, value, delayValue) => ({
    blockOptions: {
        method: {
            name: CONTROLLER_CAPABILITY_RESET,
            args: {
                type: TYPE,
            },
        },
    },
    blockType: blockType,
    fields: [
        {
            name: TYPE,
            type: ENUM,
            value: value,
        },
    ],
    ...(delayValue && { delay: delayValue }),
});

export const getControllerNodeValue = (controllerBlock) => {
    return controllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find((field) => field.value)?.value;
};
