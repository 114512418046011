import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    ALL_OEMS_ID,
    DEFAULT_CREATOR_VALUE,
    MARKETPLACE_GENERATOR,
    WIFI_PLUGIN_MODEL,
} from '../../../../../constants/Plugins';
import PluginsList from '../../components/PluginsList';
import ModalPlugin from '../../components/ModalPlugin';

import { useRequestResult } from '../../customHooks';
import { CONFIRMATION } from '../../../../../constants/Devices';
import { EZLOGIC_TITLE_MARKET_PLACE_DEVICE } from '../../../../../constants/language_tokens';

const Marketplace = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [pluginData, setPluginData] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);

    const { marketplaceWifiTemplates, loaderList, PluginActions } = props;

    useEffect(() => {
        PluginActions.getPublicPlugins(WIFI_PLUGIN_MODEL, DEFAULT_CREATOR_VALUE, ALL_OEMS_ID);
    }, []);

    useRequestResult();

    const onConfirm = useCallback((data, type) => {
        setTypeModal(CONFIRMATION);
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const onHandlerShowInfoModal = useCallback((data, type) => {
        setTypeModal(type);
        setIsModal(true);
        setCurrentAction(type);
        setPluginData(data);
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    return (
        <>
            <PluginsList
                type={MARKETPLACE_GENERATOR}
                title={EZLOGIC_TITLE_MARKET_PLACE_DEVICE}
                listPlugins={marketplaceWifiTemplates}
                loaderList={loaderList}
                onConfirm={onConfirm}
                onHandlerShowInfoModal={onHandlerShowInfoModal}
            />
            <ModalPlugin
                typeList={MARKETPLACE_GENERATOR}
                open={isModal}
                pluginData={pluginData}
                currentAction={currentAction}
                typeModal={typeModal}
                onCancel={hideModal}
                PluginActions={PluginActions}
            />
        </>
    );
};

Marketplace.propTypes = {
    marketplaceWifiTemplates: PropTypes.array,
    loaderList: PropTypes.bool,
    PluginActions: PropTypes.object,
};

export default Marketplace;
