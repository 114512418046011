import React from 'react';
import ErrorIcon from '../icons/ErrorIcon';
import classNames from 'classnames';
import {
    EZLOGIC_BUTTON_GOT_IT,
    EZLOGIC_TITLE_CONNECTION_ISSUE,
    EZLOGIC_TITLE_RESTORE_PROCESS_WAS_INTERRUPTED,
} from '../../../../constants/language_tokens';
import Button from '@material-ui/core/Button';
import { useTranslate } from '../../../../features/languages';

const BackupRestoreErrorContent = ({ handleFinish }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <ErrorIcon className="icon-completed" />
            <span className={classNames('text', 'title')}>{t(EZLOGIC_TITLE_RESTORE_PROCESS_WAS_INTERRUPTED)}</span>
            <span className={classNames('text-auto-width', 'description')}>{t(EZLOGIC_TITLE_CONNECTION_ISSUE)}</span>
            <div className="main-content-footer">
                <Button
                    color="primary"
                    variant="contained"
                    className={classNames('primary-btn', 'warning')}
                    onClick={handleFinish}
                    aria-label="Got It"
                >
                    {t(EZLOGIC_BUTTON_GOT_IT)}
                </Button>
            </div>
        </div>
    );
};
export default BackupRestoreErrorContent;
