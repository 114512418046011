import oemManager from '../../../../services/oem';
import { useHistory } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';
import { LOGIN } from '../../../../constants/URLs';
import { JAVASCRIPT_TYPES } from '../../../../constants/common/js_types';
import { PARTNER_GET_PARAM_NAME, PARTNER_GET_PARAM_VALUES } from '../../constants/getParams';
import {
    PK_PERMISSION_ROLE,
    PK_PERMISSION_ROLE_PROP_NAME,
} from '../../../../services/permission/src/constants/permissions';

/**
 * A hook which is responsible to navigate to login page or other apps based on auth context and current OS.
 * @function
 * @returns {Array} An array with "goToLogin" function.
 * @example
 * const [goToLogin] = useGoToLogin();
 **/
export const useGoToLogin = () => {
    const history = useHistory();

    /**
     * Redirects to login page, partner portal or open proper mobile application based on config.
     * If no config provided follows default behaviour.
     * @param {object} config - object, which could contain the following props: partner, PK_PermissionRole.
     * @returns {undefined}
     * */
    const goToLogin = (config) => {
        if (config && typeof config === JAVASCRIPT_TYPES.OBJECT) {
            return goToLoginByConfig(config);
        }

        return goToLoginDefault();
    };

    const goToLoginByUrl = (url) => {
        // additional check to make sure we have URL, if no - go to "Login" page
        if (url) {
            return window.location.replace(url);
        }

        return history.replace(LOGIN);
    };

    /**
     * Redirects to login page based on OS. Default behaviour.
     * @returns {undefined}
     * */
    const goToLoginDefault = () => {
        if (isIOS) {
            return goToLoginByUrl(oemManager?.oem?.getIosResidentAppUrl());
        } else if (isAndroid) {
            return goToLoginByUrl(oemManager?.oem?.getAndroidResidentAppUrl());
        }

        return history.replace(LOGIN);
    };

    /**
     * Redirects to login page, partner portal or open proper mobile application based on config.
     * @param {object} config - object, which could contain the following props: partner, PK_PermissionRole.
     * @returns {undefined}
     * */
    const goToLoginByConfig = (config) => {
        // try to unify possible config object
        if (config[PARTNER_GET_PARAM_NAME] && config[PARTNER_GET_PARAM_NAME] === PARTNER_GET_PARAM_VALUES.PARTNER) {
            return goToLoginByConfigPartner();
        } else if (config[PK_PERMISSION_ROLE_PROP_NAME]) {
            return goToLoginByPkPermissionRole(config);
        }

        return goToLoginDefault();
    };

    /**
     * Redirects to portal or open mobile application based on PK_PermissionRole value.
     * @param {object} config - object, which could contain the following props: .
     * @returns {undefined}
     * */
    const goToLoginByPkPermissionRole = (config) => {
        if (config[PK_PERMISSION_ROLE_PROP_NAME] === PK_PERMISSION_ROLE.PARTNER) {
            return goToLoginByConfigPartner();
        } else if (config[PK_PERMISSION_ROLE_PROP_NAME] === PK_PERMISSION_ROLE.INSTALLER) {
            // for installers same behaviour as for partners, based on PRD
            return goToLoginByConfigPartner();
        }

        return goToLoginDefault();
    };

    /**
     * Redirects to partner portal or open mobile application for partners.
     * @returns {undefined}
     * */
    const goToLoginByConfigPartner = () => {
        const partnerPortalUrl = oemManager.oem?.getPartnerPortalUrl();
        if (isIOS) {
            return goToLoginByUrl(oemManager?.oem?.getIosInstallerAppUrl());
        } else if (isAndroid) {
            return goToLoginByUrl(oemManager?.oem?.getAndroidInstallerAppUrl());
        }

        return goToLoginByUrl(partnerPortalUrl);
    };

    return [goToLogin];
};
