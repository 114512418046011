import React from 'react';

import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

import DeviceGroupTreeItemStateMenu from './components/DeviceGroupTreeItemStateMenu';
import DeviceIconContainer from '../../../../../components/DeviceIconContainer';
import { DEVICE_GROUP_TREE_ITEM_TYPES, MENU_BUTTONS_STATES } from '../../../../../constants/DevicesGroups';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_LABEL_DEVICE_LABEL } from '../../../../../constants/language_tokens';

import styles from './components/DeviceGroupTreeItemStateMenu/DeviceGroupTreeItemStateMenu.module.scss';

const DeviceGroupCategoryLabelContainer = (props) => {
    const { value, labelName, onChange, options, buttonState, deviceId, type } = props;
    const { t } = useTranslate();
    const deviceLabelStyles = classNames(styles.deviceLabelWrapper, {
        [styles.inherited]:
            buttonState === MENU_BUTTONS_STATES.INHERITED || buttonState === MENU_BUTTONS_STATES.EXCLUDED,
        [styles.included]: buttonState === MENU_BUTTONS_STATES.INCLUDED,
        [styles.inheritedIncluded]: buttonState === MENU_BUTTONS_STATES.INHERITED_INCLUDED,
    });

    return (
        <>
            <DeviceGroupTreeItemStateMenu
                value={value}
                onChange={onChange}
                options={options}
                labelName={labelName}
                buttonState={buttonState}
            />
            {type === DEVICE_GROUP_TREE_ITEM_TYPES.CATEGORY && (
                <DeviceIconContainer category={deviceId} iconStyles={styles.deviceIcon} />
            )}
            <span className={styles.buttonLadel}>{labelName}</span>
            {type === DEVICE_GROUP_TREE_ITEM_TYPES.DEVICE && (
                <div className={deviceLabelStyles} data-testid="chip-wrapper">
                    <Chip label={t(EZLOGIC_LABEL_DEVICE_LABEL)} />
                </div>
            )}
        </>
    );
};

export default DeviceGroupCategoryLabelContainer;
