import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import InfoButton from '../../../InfoButton';
import ExpressionNumberRangeInputs from '../ExpressionNumberRangeInputs';

import { BETWEEN, NOT_BETWEEN, STRING } from '../../../../../../../constants/MeshbotConstant';
import {
    INPUT_ADORONMENT_END_POSITION,
    INPUT_TYPE_TEXT,
    INPUT_TYPE_NUMBER,
    DATA_TYPES_LIST,
    INPUT_TYPE_NUMBER_STEP,
    DOT,
    COMMA,
} from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const ExpressionComparedCommonTypes = (props) => {
    const {
        comparingValue,
        onHandleChangeComparingValue,
        selectedExpressionType,
        convertedExpressionType,
        comparatorValue,
        onHandleChangeComparingRangeValue,
        initialRangeStartValue,
        initialRangeEndValue,
    } = props;
    const { t } = useTranslate();

    const preventIntToFloatComparison = (e) => {
        if (convertedExpressionType === DATA_TYPES_LIST.TYPE_INT && (e.key === COMMA || e.key === DOT)) {
            e.preventDefault();

            return false;
        }
    };

    return (
        <>
            {comparatorValue === BETWEEN || comparatorValue === NOT_BETWEEN ? (
                <ExpressionNumberRangeInputs
                    convertedExpressionType={convertedExpressionType}
                    onHandleChangeComparingValue={onHandleChangeComparingRangeValue}
                    initialRangeStartValue={initialRangeStartValue}
                    initialRangeEndValue={initialRangeEndValue}
                />
            ) : (
                <TextField
                    label={t(info.hints.value)}
                    value={comparingValue ?? ''}
                    onChange={onHandleChangeComparingValue}
                    onKeyPress={preventIntToFloatComparison}
                    type={selectedExpressionType === STRING ? INPUT_TYPE_TEXT : INPUT_TYPE_NUMBER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                                <InfoButton>
                                    <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                                </InfoButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        step: convertedExpressionType === DATA_TYPES_LIST.TYPE_FLOAT && INPUT_TYPE_NUMBER_STEP,
                    }}
                />
            )}
        </>
    );
};

export default ExpressionComparedCommonTypes;
