import React from 'react';
import PropTypes from 'prop-types';
// Temporarily hidden until a single data source is obtained regarding the list of integrated devices
// import DeviceTreeIntegratedDevicesButton from '../DeviceTreeIntegratedDevicesButton';
import DeviceTreeSaveButton from '../DeviceTreeSaveButton';
import DeviceTreeResetButton from '../DeviceTreeResetButton';
import styles from './DeviceTreeButtonGroup.module.scss';

const DeviceTreeButtonGroup = (props) => {
    const { isEditing } = props;

    return (
        <>
            <div className={styles.integratedDevicesButtonWrapper}>
                {/* Temporarily hidden until a single data source is obtained regarding the list of integrated devices */}
                {/* <DeviceTreeIntegratedDevicesButton
                    onIntegratedDevicesPageNavigation={onIntegratedDevicesPageNavigation}
                /> */}
            </div>
            <div className={styles.buttonsContainer}>
                <DeviceTreeResetButton />
                <DeviceTreeSaveButton isEditing={isEditing} />
            </div>
        </>
    );
};

export default DeviceTreeButtonGroup;
DeviceTreeButtonGroup.propTypes = {
    // Temporarily hidden until a single data source is obtained regarding the list of integrated devices
    // onIntegratedDevicesPageNavigation: PropTypes.func,
    isEditing: PropTypes.bool,
};
