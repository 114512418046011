import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, LinearProgress } from '@material-ui/core';

import AssociationsList from '../DeviceAssociationsList';
import DeviceAssociationsAddForm from '../DeviceAssociationsAddForm';

import AssociationsActions from '../../../../actions/AssociationsActions';
import { EzloActions, MainAction } from '../../../../actions';
import DeviceActions from '../../../../actions/DeviceActions';

import { deviceOptions } from '../../utils';
import {
    createCurrentKey,
    createOnSavePayload,
    filterTargetOptions,
    getArrayAssociations,
    getArrayAssociationsList,
    getAssociationCommands,
    getCurrentDevice,
    getCurrentSourceChanel,
    getMaximumCountOfAssociations,
    isAssociationsFull,
    isButteryDevice,
    isDeviceWithOneSupportedAssociation,
    isMaximumAssociation,
    isTargetChannelSupported,
    returnAssociationsChannels,
    returnAssociationsGroups,
    returnFirmwareVersion,
    validationForAssociations,
} from '../utils';
import { ADDED, DEVICE_ASSOCIATIONS, REMOVED } from '../../../../constants/DeviceAssociations';
import useFeatureSupportFirmware from '../../../../hooks/useFeatureSupportFirmware';
import useUnsavedChangesWarning from '../../EzloMeshbot/MeshBot/CustomHooks/useUnsavedChangesWarning';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION,
    EZLOGIC_HEADING_EXISTING_ASSOCIATIONS,
    EZLOGIC_TITLE_YOUR_ASSOCIATION_WILL_APPEAR,
    EZLOGIC_TITLE_YOUR_CONTROLLER_IS_OFFLINE,
    EZLOGIC_TITLE_YOU_HAVE_NO_ASSOCIATIONS_YET,
} from '../../../../constants/language_tokens';
import EmptyMeshBotsTableOverlay from '../../EzloMeshbots/components/EmptyMeshBotsTableOverlay';
import DeviceAssociationsHeader from '../DeviceAssociationsHeader';
import DeviceAssociationsNotSupported from '../DeviceAssociationsNotSupported';

import styles from '../../EzloAccount/components/Header/style.module.scss';
import { iconContainer } from '../DeviceAssociationsList/DeviceAssociationList.module.scss';
import { headerChild, headingExistingAssociation } from '../DeviceAssociationsHeader/styles.module.scss';
import { PlusIcon } from '../../../../assets/icons';

const AssociationsLayout = ({ isInnerAssociationForm, settingId, sourceDevice, setOpenForm }) => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [newSerial, setNewSerial] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [targetChannelHide, isTargetChannelHide] = useState(false);
    const [showAddAssociationsForm, setShowAssociationsForm] = useState(false);
    const [sourceChannels, setSourceChannels] = useState([]);
    const [sourceGroups, setSourceGroups] = useState([]);
    const [targetChannels, setTargetChannels] = useState([]);
    const [currentSettingId, setCurrentSettingId] = useState('');
    const [currentDeviceId, setCurrentDeviceId] = useState('');
    const [sourceDeviceName, setSourceDeviceName] = useState({});
    const [targetDeviceName, setTargetDeviceName] = useState({});
    const [targetChannelValue, setTargetChannelValue] = useState('');
    const [targetNode, setTargetNode] = useState(0);
    const [noAssociations, setNoAssociations] = useState(true);
    const [sourceChannelValue, setSourceChannelValue] = useState('');
    const [sourceGroupValue, setSourceGroupValue] = useState('');
    const [associationsFull, setAssociationsFull] = useState(false);
    const [groupWithOneSupportedAssociation, setGroupWithOneSupportedAssociation] = useState(false);
    const dispatch = useDispatch();
    const isCurrentFirmwareVersion = useFeatureSupportFirmware(DEVICE_ASSOCIATIONS.FIRMWARE_BUILD_VERSION);
    const setDirty = useUnsavedChangesWarning();

    const serial = useSelector((state) => state.ezlo.serial);
    const isConnected = useSelector((state) => state.ezlo?.data[serial]?.isConnected);
    const devicesList = useSelector((state) => state.ezlo?.data[serial]?.devices);
    const rooms = useSelector((state) => state.ezlo?.data[serial]?.pages);
    const settings = useSelector((state) => state.ezlo?.data[serial]?.settings);
    const firmwareVersion = useSelector((state) => state.ezlo?.data[serial]?.firmware);
    const isDirty = useSelector((state) => state.main.editableComponent?.isDirty);
    const { isLoading } = useSelector((state) => state.deviceSettings);
    const { t } = useTranslate();

    useEffect(() => {
        if (isConfirmed && !isConfirmModalVisible) {
            dispatch(EzloActions.selectController(newSerial));
            setIsConfirmed(false);
            onRemove();
        }
    }, [isConfirmed, isConfirmModalVisible]);

    useEffect(() => {
        if (currentDeviceId && sourceChannelValue && sourceGroupValue) {
            setAssociationsFull(
                isAssociationsFull({
                    currentKey: createCurrentKey(sourceChannelValue, sourceGroupValue),
                    settings,
                    deviceId: currentDeviceId,
                }),
            );

            setGroupWithOneSupportedAssociation(
                isDeviceWithOneSupportedAssociation({
                    currentKey: createCurrentKey(sourceChannelValue, sourceGroupValue),
                    settings,
                    deviceId: currentDeviceId,
                }),
            );
        }
    }, [currentDeviceId, sourceChannelValue, sourceGroupValue]);

    useEffect(() => {
        if ((!currentDeviceId && !targetNode && !targetChannelValue) || isInnerAssociationForm) {
            setDirty(false);
            dispatch(MainAction.setConfirmationUser(false));
        } else if (currentDeviceId || targetNode || targetChannelValue) {
            if (!isDirty) {
                setDirty(true);
                dispatch(MainAction.setConfirmationUser(true));
            }
        }
    }, [currentDeviceId, targetNode, targetChannelValue]);

    const openConfirmModal = (newSerial) => {
        setNewSerial(newSerial);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmation = (isConfirmed) => {
        setIsConfirmed(isConfirmed);
        setIsConfirmModalVisible(false);
    };

    const handleAssociationsAddButton = () => {
        setShowAssociationsForm(true);
    };

    const onRemove = () => {
        if (currentDeviceId || targetNode || targetChannelValue) {
            dispatch(
                MainAction.confirmUserAction(() => {
                    setShowAssociationsForm(false);
                    setSourceGroupValue('');
                    setSourceChannelValue('');
                    setTargetChannelValue('');
                    setCurrentDeviceId('');
                    setTargetChannels([]);
                    setSourceGroups([]);
                    setSourceChannels([]);
                    setNoAssociations(true);
                }),
            );
        } else if (!currentDeviceId && !targetNode && !targetChannelValue) {
            setShowAssociationsForm(false);
            setSourceGroupValue('');
            setSourceChannelValue('');
            setTargetChannelValue('');
            setCurrentDeviceId('');
            setTargetChannels([]);
            setSourceGroups([]);
            setSourceChannels([]);
            setNoAssociations(true);
        }
    };

    const onChangeDevice = (id, name) => {
        setSourceDeviceName({ name });
        if (!getCurrentSourceChanel(id, settings)?.[0]) {
            setCurrentDeviceId(id);
            setNoAssociations(true);

            return;
        }

        if (!isTargetChannelSupported(id, settings)) {
            isTargetChannelHide(true);
        } else {
            isTargetChannelHide(false);
        }

        const { value } = getCurrentSourceChanel(id, settings)?.[0];
        const { _id, deviceId } = getArrayAssociations(id, settings)?.[0];

        const associationsChannelsAndGroups = Object.keys(value);
        setCurrentSettingId(_id);
        setCurrentDeviceId(deviceId);
        setSourceChannels(returnAssociationsChannels(associationsChannelsAndGroups));
        setSourceGroups(returnAssociationsGroups(associationsChannelsAndGroups));

        setSourceChannelValue(returnAssociationsChannels(associationsChannelsAndGroups)[0]);
        setSourceGroupValue(returnAssociationsGroups(associationsChannelsAndGroups)[0]);

        setNoAssociations(false);
    };
    useEffect(() => {
        if (isInnerAssociationForm) {
            onChangeDevice(settingId, sourceDevice);
        }
    }, []);

    const onChangeTargetDevice = (id, name) => {
        setTargetDeviceName({ name });
        setTargetChannels(
            Array.from({ length: Number(getCurrentDevice(devicesList, id).info['channels.count']) }, (x, i) => i),
        );
        setTargetChannelValue('non-specified');
        setTargetNode(Number(getCurrentDevice(devicesList, id).info['zwave.node']));
    };

    const onChangeSourceChannel = (e) => {
        const { value } = e.target;
        setSourceChannelValue(value);
    };

    const onChangeSourceGroup = (e) => {
        const { value } = e.target;
        setSourceGroupValue(value);
    };

    const onChangeTargetChannelValue = (e) => {
        const { value } = e.target;

        setTargetChannelValue(value);
    };

    const notifyAddingSuccess = () => {
        dispatch(DeviceActions.updateSettings(serial, {}, ADDED, t));
        clearState();
    };

    const notifyRemovedSuccess = () => {
        dispatch(DeviceActions.updateSettings(serial, {}, REMOVED, t));
    };

    const onSave = () => {
        dispatch(
            AssociationsActions.createAssociation(serial, {
                _id: currentSettingId,
                key: createCurrentKey(sourceChannelValue, sourceGroupValue),
                value: createOnSavePayload(
                    currentDeviceId,
                    sourceChannelValue,
                    sourceGroupValue,
                    targetChannelValue,
                    targetNode,
                    settings,
                ),
            }),
        );
        dispatch(AssociationsActions.subscribeHubDeviceSettingDictionaryValueSet(serial, notifyAddingSuccess));
    };

    const clearState = () => {
        setSourceGroupValue('');
        setSourceChannelValue('');
        setTargetChannelValue('');
        setCurrentDeviceId('');
        setSourceDeviceName('');
        setTargetDeviceName('');
        setTargetChannels([]);
        setSourceGroups([]);
        setSourceChannels([]);
        setNoAssociations(true);
        setShowAssociationsForm(false);
    };

    const onRemoveAssociation = (payload) => {
        dispatch(AssociationsActions.createAssociation(serial, payload));
        dispatch(AssociationsActions.subscribeHubDeviceSettingDictionaryValueSet(serial, notifyRemovedSuccess));
    };

    const currentFirmwareVersionNotification = returnFirmwareVersion(firmwareVersion);
    const options = deviceOptions(devicesList, rooms);
    const isAssociationDevice = getArrayAssociationsList(settings, devicesList);
    const targetOptions = filterTargetOptions(options, currentDeviceId);

    return (
        <>
            <section className={`${!isInnerAssociationForm ? 'associations-wrapper' : ''} `}>
                {!isInnerAssociationForm && (
                    <DeviceAssociationsHeader
                        isLoading={isLoading}
                        openConfirmModal={openConfirmModal}
                        isConfirmModalVisible={isConfirmModalVisible}
                        setIsConfirmModalVisible={setIsConfirmModalVisible}
                        handleConfirmation={handleConfirmation}
                        handleClick={handleAssociationsAddButton}
                        isCurrentFirmwareVersion={isCurrentFirmwareVersion}
                    />
                )}
                {!isInnerAssociationForm && (
                    <div className={headerChild}>
                        <h2 className={headingExistingAssociation}>{t(EZLOGIC_HEADING_EXISTING_ASSOCIATIONS)}</h2>
                        {isCurrentFirmwareVersion && (
                            <Button color="primary" variant="contained" onClick={handleAssociationsAddButton}>
                                <span className={iconContainer}>
                                    <PlusIcon />
                                </span>
                                <div> {t(EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION)}</div>
                            </Button>
                        )}
                    </div>
                )}
                {isLoading && !isInnerAssociationForm && (
                    <Box className={styles.linearProgressBoxPlugins}>
                        <LinearProgress className={styles.linearProgress} />
                    </Box>
                )}
                {isCurrentFirmwareVersion && (
                    <main>
                        {(showAddAssociationsForm || isInnerAssociationForm) && (
                            <>
                                <DeviceAssociationsAddForm
                                    devicesList={devicesList}
                                    options={options}
                                    noAssociations={noAssociations}
                                    onChangeDevice={onChangeDevice}
                                    onChangeTargetDevice={onChangeTargetDevice}
                                    onChangeSourceChannel={onChangeSourceChannel}
                                    onChangeSourceGroup={onChangeSourceGroup}
                                    onChangeTargetChannelValue={onChangeTargetChannelValue}
                                    onRemove={onRemove}
                                    onSave={onSave}
                                    sourceChannels={sourceChannels}
                                    sourceChannelValue={sourceChannelValue}
                                    sourceGroupValue={sourceGroupValue}
                                    sourceGroups={sourceGroups}
                                    targetChannelHide={targetChannelHide}
                                    targetChannels={targetChannels}
                                    targetChannelValue={targetChannelValue}
                                    currentDeviceId={currentDeviceId}
                                    targetDeviceName={targetDeviceName}
                                    sourceDeviceName={sourceDeviceName}
                                    disabled={validationForAssociations(
                                        sourceChannelValue,
                                        sourceGroupValue,
                                        targetChannelValue,
                                        associationsFull,
                                    )}
                                    isMaximumAssociation={isMaximumAssociation(
                                        currentDeviceId,
                                        settings,
                                        createCurrentKey(sourceChannelValue, sourceGroupValue),
                                    )}
                                    associationCommands={getAssociationCommands(
                                        currentDeviceId,
                                        settings,
                                        createCurrentKey(sourceChannelValue, sourceGroupValue),
                                    )}
                                    maximumCountOfAssociations={getMaximumCountOfAssociations(
                                        currentDeviceId,
                                        settings,
                                        createCurrentKey(sourceChannelValue, sourceGroupValue),
                                    )}
                                    isButteryDevice={isButteryDevice(devicesList, currentDeviceId)}
                                    isInnerAssociationForm={isInnerAssociationForm}
                                    setOpenForm={setOpenForm}
                                    associationsFull={associationsFull}
                                    groupWithOneSupportedAssociation={groupWithOneSupportedAssociation}
                                    targetOptions={targetOptions}
                                />
                            </>
                        )}
                        <div>
                            {!isInnerAssociationForm && Boolean(isAssociationDevice?.length) ? (
                                <AssociationsList
                                    associationsList={getArrayAssociationsList(settings, devicesList)}
                                    serial={serial}
                                    isConnected={isConnected}
                                    onRemove={onRemoveAssociation}
                                    onCreate={handleAssociationsAddButton}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </main>
                )}
                {!isConnected && !isInnerAssociationForm && Boolean(isAssociationDevice?.length) ? (
                    <div className="associations-wrapper_support-notification">
                        {t(EZLOGIC_TITLE_YOUR_CONTROLLER_IS_OFFLINE)}
                    </div>
                ) : (
                    ''
                )}
            </section>
            {!isAssociationDevice?.length && !showAddAssociationsForm && isCurrentFirmwareVersion && (
                <EmptyMeshBotsTableOverlay
                    title={EZLOGIC_TITLE_YOU_HAVE_NO_ASSOCIATIONS_YET}
                    description={EZLOGIC_TITLE_YOUR_ASSOCIATION_WILL_APPEAR}
                />
            )}
            {isConnected && !isCurrentFirmwareVersion && (
                <DeviceAssociationsNotSupported
                    currentFirmwareVersionNotification={currentFirmwareVersionNotification}
                />
            )}
        </>
    );
};

export default AssociationsLayout;
