import React from 'react';

import DeviceGroupRoot from '../DeviceGroupRoot';
import DeviceGroupCategories from '../DeviceGroupCategories';
import DeviceGroupSubcategories from '../DeviceGroupSubcategories';
import DeviceGroupDevices from '../DeviceGroupDevices';
import { DEVICE_GROUP_TREE_ITEM_TYPES } from '../../../../../../../constants/DevicesGroups';

const GeneralTreeItem = (props) => {
    const { payload, parentChainIds, categoryId } = props;

    const GeneralTreeItemComponent = {
        [DEVICE_GROUP_TREE_ITEM_TYPES.ROOT]: <DeviceGroupRoot payload={payload} />,
        [DEVICE_GROUP_TREE_ITEM_TYPES.CATEGORY]: <DeviceGroupCategories payload={payload} />,
        [DEVICE_GROUP_TREE_ITEM_TYPES.SUBCATEGORY]: (
            <DeviceGroupSubcategories payload={payload} categoryId={categoryId} />
        ),
        [DEVICE_GROUP_TREE_ITEM_TYPES.DEVICE]: <DeviceGroupDevices payload={payload} parentChainIds={parentChainIds} />,
    };

    return <>{GeneralTreeItemComponent[payload?._ui?.type]} </>;
};

export default GeneralTreeItem;
