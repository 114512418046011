import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import * as meshBot from '../../../../../../../constants/MeshbotConstant';
import { info } from '../../../../utils';
import InfoButton from '../../../InfoButton';

import styles from './ToggleSwitchAddingOffset.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const ToggleSwitchAddingOffset = ({ addedOffset, onChangeDisplayOffset }) => {
    const { t } = useTranslate();

    return (
        <div className={styles.checkbox}>
            <FormControlLabel
                control={<Checkbox checked={addedOffset} onChange={onChangeDisplayOffset} color={meshBot.PRIMARY} />}
                label={t(info.hints.addOffset)}
            />
            <InfoButton>
                <div dangerouslySetInnerHTML={{ __html: t(info.text.addingOffset) }}></div>
            </InfoButton>
        </div>
    );
};

export default ToggleSwitchAddingOffset;
