import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { TIMEZONES_LIST } from '../../../../../../constants/timezones';
import { getCurrentTimezone } from '../../../utils';

import styles from './TimeZoneSelect.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_TIMEZONE } from '../../../../../../constants/language_tokens';

const TimeZoneSelect = (props) => {
    const { blocks, handleChangeTimezone } = props;
    const { t } = useTranslate();

    return (
        <div className={styles.wrapper}>
            <Autocomplete
                defaultValue={getCurrentTimezone(blocks)}
                options={TIMEZONES_LIST}
                getOptionLabel={(timezone) => timezone}
                onChange={handleChangeTimezone}
                renderInput={(params) => <TextField {...params} label={t(EZLOGIC_TITLE_TIMEZONE)} />}
                disableClearable={true}
                renderOption={(option) => {
                    return (
                        <span {...props} className={styles.options}>
                            {option}
                        </span>
                    );
                }}
            />
        </div>
    );
};

export default TimeZoneSelect;
