import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import classes from 'classnames';

import NotificationBlock from '../../../../components/NotificationBlock';
import NotificationCloudBlock from '../../../../components/NotificationCloudBlock';
import DashboardActionBlock from '../../../../components/DashboardActionBlock';
import DevicesBlock from './DeviceBlockComponent';

import MeshBotSelectBlock from './MeshBotSelectBlock';
import InputTypeCloud from '../../../../components/InputTypeCloud';
import HttpRequest from './HttpRequest';
import VidooNodeForAction from './VidooNodeForAction';
import LuaScript from './LuaScript';
import { PAAS } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/Paas';
import BlockOfFieldsForDeviceAction from './BlockOfFieldsForDeviceAction/BlockOfFieldsForDeviceAction';
import ActionDelay from '../../../../components/ActionDelay';
import TriggerBlockButtonsWrapper from './TriggerBlock/TriggerBlockButtonsWrapper';
import { HouseMode } from './HouseMode';
import LocalVariable from './LocalVariable';
import ControllerActionNode from './ControllerActionNode';

import { MESHBOT_TYPES } from '../../EzloMeshbots/constants';
import { filterCapabilitiesForCloudAction } from '../../EzloVirtualDevice/utils';
import {
    generateOptionsByNotificationType,
    getClassNameFromValidation,
    getClassesNamesForThenBlocks,
    getActionListData,
    isDelayValues,
} from '../utils';
import { deviceOptions } from '../../utils';
import {
    LUA_SCRIPT,
    IMMEDIATELY,
    DELAY,
    HTTP_REQUEST,
    MESHBOT_NOTIFICATION,
    MESHBOT_NODE_TYPES,
    MESHBOT_ACTION_SELECT_VALUES,
    UNLATCH_SCENE,
    RESET_LATCH,
    UNLATCH_ALL_LATCHES,
    RESET_SCENE_LATCHES,
    ALL,
    CLOUD,
    SELECTED_REQUEST,
    SELECTED_POINT,
    ACTIONS,
    ACTION_VALID_MODE,
    ACTION_EDIT_MODE,
    CLOUD_NOTIFICATION,
    DEVICE_STATE,
    ACTION_THEN,
    ACTION_ELSE,
} from '../../../../constants/MeshbotConstant';
import at from '../../../../constants/ActionTypes/MeshBot';
import useFeatureSupportChecker from '../../../../hooks/useFeatureSupportChecker';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../constants/ControllerFeatures';
import SelectDeviceCapability from './SelectDeviceCapability';

import { body, inner, drag_handle, fields_block, actionSectionBody } from './TriggerBlock.module.scss';
import SelectComponent from './SelectComponent';
import { DATA_FROM_FIRST_SCENE } from '../../../../constants/NotificationTemplates';
import oemManager from '../../../../../src/services/oem';
import { getNodesListForActionsLocalSectionByOemId } from '../components/TriggerHouseMode/utils';
import * as localStorageKeys from '../../../../constants/localStorageKeys';
import { filterCloudActionNodesBySsoType } from './utils';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_ALL,
    EZLOGIC_HINT_CAPABILITY,
    EZLOGIC_HINT_CONTROLLABLE_TYPE,
    EZLOGIC_HINT_VALUE_TYPE,
    EZLOGIC_LABEL_VALUE_TYPE,
    EZLOGIC_TITLE_CAPABILITY,
    EZLOGIC_TITLE_LATCH_NAME,
} from '../../../../constants/language_tokens';
import { MESHBOT_SECTION_TYPE } from '../constants';

// TODO: rename, refactoring needed
const TriggerForActions = (props) => {
    const [selectedMeshBot, setSelectedMeshBot] = useState({});
    const [currentInputType, setCurrentInputType] = useState(null);
    const [currentCapabilities, setCurrentCapabilities] = useState([]);
    const [isShowedDelay, setIsShowedDelay] = useState(IMMEDIATELY);
    const [latchName, setLatchName] = useState({});
    const [currentIndex, setCurrentIndex] = useState({});
    const selectedRule = useSelector(({ meshBot }) => meshBot.local.selectedRule);
    const groupActions = useSelector(({ meshBot }) => meshBot.local.groupActions);

    const triggerBlockRef = useRef();
    const { t } = useTranslate();

    const { else: elseField, then } = selectedRule;

    const actionTabs = {
        then,
        else: elseField,
    };
    const { blockOptions, fields } = actionTabs?.[props.blockType]?.[currentIndex]?.blocks?.[0] || {};

    const meshbotActionName = blockOptions?.method?.name;
    const isEnabled = meshbotActionName === 'setSceneState' ? fields[1].value : null;
    let meshbotCapability = meshbotActionName;

    if (meshbotActionName === 'setSceneState') {
        meshbotCapability = isEnabled ? 'enableScene' : 'disableScene';
    }

    if (meshbotActionName === RESET_LATCH || meshbotActionName === RESET_SCENE_LATCHES) {
        meshbotCapability = UNLATCH_SCENE;
    }

    const [selectedMeshBotAction, setSelectedMeshBotAction] = useState(meshbotCapability || 'runScene');
    const oemId = oemManager.oem.getOemId();
    let optionsFirstSelect = getNodesListForActionsLocalSectionByOemId(oemId);

    const {
        serial,
        name,
        id,
        type,
        items,
        idDev,
        model,
        blocks,
        devices,
        firstBlock,
        currentRule,
        currentItem,
        currentDeviceAction,
        selectedFieldTrigger,
        onUpdateTriggerAction,
        onSelected,
        onSelectedMeshBotAction,
        deleteTriggerAction,
        onUpdateFieldActionBlock,
        onAddActionBlock,
        actionBlockName,
        foundValidatedThenBlock,
        foundValidatedElseBlock,
        channels,
        rooms,
        usersData,
        getNotificationData,
        typeMeshbot,
        abstractsCapabilities,
        scenesList,
        selectedContent,
        selectedRequest,
        selectedUrl,
        selectedCredentialUser,
        selectedCredentialPassword,
        selectedSecurity,
        selectedContentType,
        selectedHeaders,
        selectedPoint,
        selectedName,
        selectedCode,
        MeshBotAction,
        abstract,
        currentScene,
        blockType,
        actionsList,
        isEditing,
        listLuaScripts,
        notificationType,
        selectedCameraId,
        selectedCapabilityValue,
        selectedCapabilityId,
        actionExceptionTriggers,
        onToggleExceptionBlock,
        index,
        onPreventDraggable,
        handlerSetError,
        isOpenedExceptionBlock,
        updateCloudRequiredFieldAction,
        updateValidationEdit,
        updateValidation,
        provided,
    } = props;

    const updatedNodesControllables = useSelector((state) => state.ezloCustomization?.nodesControllables);

    const customizedActionNodes = getActionListData(
        updatedNodesControllables,
        notificationType,
        optionsFirstSelect,
        type,
    );
    // TODO: temp solution, should be moved to store
    const ssoType = localStorage.getItem(localStorageKeys.LOCALSTORAGE_SSO_TYPE);
    const updateListAction = filterCloudActionNodesBySsoType(customizedActionNodes, ssoType);
    const currentLatchIdValue = then[index]?.blocks[0]?.fields?.[1]?.value || ALL;
    const currentListOfLatches = groupActions[index]?.latchesList;

    const sceneId = currentItem?.meshBotId || currentItem?.idDev;
    const selectedRuleCloudNucal = currentItem.requiredFields;
    const [isValid, setIsValid] = useState();
    const [isEdit, setIsEdit] = useState();
    const bodyClassNames = classes(body, {
        [actionSectionBody]: actionBlockName === ACTION_ELSE || actionBlockName === ACTION_THEN,
    });

    if (type === at.CLOUD) {
        optionsFirstSelect = notificationType
            ? generateOptionsByNotificationType(notificationType)
            : generateOptionsByNotificationType(CLOUD);
    }

    useEffect(() => {
        if (selectedFieldTrigger === 'meshBotState' && currentItem) {
            const sceneId = currentItem.meshBotId || currentItem.idDev;
            const selectedScene = scenesList?.find((scene) => scene._id === sceneId);
            const { meshBotAction } = currentItem;
            setSelectedMeshBot(selectedScene);
            setSelectedMeshBotAction(meshBotAction);
        }
    }, []);

    useEffect(() => {
        const action = currentRule?.[actionBlockName]?.find((action) => action.id === id);

        if (action) {
            if (isDelayValues(action.blocks?.[0])) {
                setIsShowedDelay(DELAY);
            }
        }
    }, [currentRule, actionBlockName]);

    useEffect(() => {
        if (selectedFieldTrigger !== MESHBOT_NODE_TYPES.MESHBOT_STATE) {
            setSelectedMeshBot({});
        }
    }, [selectedFieldTrigger]);

    useEffect(() => {
        setCurrentCapabilities(filterCapabilitiesForCloudAction(abstract?.capabilities));
    }, [abstract]);

    useEffect(() => {
        if (selectedMeshBot) {
            const { _id } = selectedMeshBot;
            if (_id) {
                MeshBotAction.getScenesStatus(serial, _id);
            }
        }
        setCurrentIndex(index);
    }, [MeshBotAction, selectedMeshBot, serial, meshbotCapability]);
    const updateClassesNamesForThenBlocks = (block) => {
        if (block) {
            return getClassesNamesForThenBlocks(block);
        }
    };
    const updateClassesNamesForElseBlocks = (block) => {
        if (block) {
            return getClassesNamesForThenBlocks(block);
        } else {
            const { actions } =
                notificationType !== at.NOTIFICATION_TEMPLATE
                    ? currentScene
                    : currentScene?.[DATA_FROM_FIRST_SCENE]?.meshbot_definition
                    ? JSON.parse(currentScene?.[DATA_FROM_FIRST_SCENE]?.meshbot_definition)
                    : {};

            const getClassName = getClassNameFromValidation(
                currentItem,
                actions,
                actionsList,
                currentItem.id,
                selectedRuleCloudNucal,
                notificationType,
            );

            return getClassName;
        }
    };

    useEffect(() => {
        const mode = type === at.CLOUD && updateClassesNamesForElseBlocks(foundValidatedElseBlock);
        if (mode === ACTION_VALID_MODE && type === at.CLOUD) {
            setIsValid(true);

            setIsEdit(true);
        } else {
            setIsValid(false);
        }

        if (mode === ACTION_EDIT_MODE && type === at.CLOUD) {
            setIsEdit(false);
            setIsValid(true);
        } else {
            setIsEdit(true);
        }
    }, [currentItem]);

    useEffect(() => {
        if (type === at.CLOUD) {
            updateValidation(isValid, id);
            updateValidationEdit(isEdit, id);
        }
    }, [isValid]);

    useEffect(() => {
        if (type === at.CLOUD) {
            updateValidationEdit(isEdit, id);
        }
    }, [isEdit]);
    const handleUpdateTriggerAction = (e, id, field, blockName) => {
        // todo: check and apply refactoring
        if (type === MESHBOT_TYPES.CLOUD) {
            onUpdateTriggerAction(e, id, field, blockName);
        } else {
            MeshBotAction.updateActionNode(e.target.value, id, actionBlockName);
            setIsShowedDelay(IMMEDIATELY);
            if (actionExceptionTriggers?.length) {
                MeshBotAction.deleteException(id);
            }

            if (isOpenedExceptionBlock) {
                MeshBotAction.toggleExceptionBlock(id, actionBlockName);
            }
        }
    };

    // TODO: rename handlerSetActionField -> handleChangeActionField
    const handlerSetActionField = useCallback(
        (value, field, nameField) => {
            if (field === SELECTED_REQUEST) {
                MeshBotAction.clearActionList(value, id, MESHBOT_NODE_TYPES.HTTP_REQUEST, actionBlockName);
            }

            if (field === SELECTED_POINT) {
                MeshBotAction.clearActionList(value, id, MESHBOT_NODE_TYPES.LUA_SCRIPT, actionBlockName);
                MeshBotAction.clearActionSelected(value, id, MESHBOT_NODE_TYPES.LUA_SCRIPT, actionBlockName);

                if (actionExceptionTriggers?.length) {
                    MeshBotAction.deleteException(id);
                }

                if (isOpenedExceptionBlock) {
                    MeshBotAction.toggleExceptionBlock(id, actionBlockName);
                }
            }

            MeshBotAction.onChangeActionField(value, id, field, actionBlockName, nameField);
        },
        [actionBlockName, id, actionExceptionTriggers, isOpenedExceptionBlock],
    );
    const handleSelected = (e, idDevice, blockName) => {
        onSelected(e, idDevice, blockName);
        setIsShowedDelay(IMMEDIATELY);
    };
    const handleAddActionBlock = (blockId) => {
        onAddActionBlock(blockId, id, actionBlockName);
        setIsShowedDelay(IMMEDIATELY);
    };
    const handleDeleteTriggerAction = () => deleteTriggerAction(id, actionBlockName);
    const handleToggleExceptionBlock = () => onToggleExceptionBlock(id, actionBlockName);
    const changeCapability = (value) => {
        onSelected(value, id, 'capability');
        getCommandsFromAbstract(value);
    };
    const getCommandsFromAbstract = (value, commandValue) => {
        if (abstractsCapabilities) {
            Object.keys(abstractsCapabilities).forEach((key) => {
                if (abstractsCapabilities[key].capability_name === value) {
                    const { commands } = abstractsCapabilities[key].definition;

                    getCommands(commands, commandValue);
                }
            });
        }
    };
    const getCommands = (commands = {}, commandValue) => {
        const arrComm = [];
        const arrOfSettings = Object.values(commands);

        Object.keys(commands).forEach((key) => {
            const comm = Object.keys(commands[key]);
            for (let i = 0; i < comm.length; i++) {
                arrComm.push(comm[i]);
            }
        });

        onSelected(arrComm, id, 'capabilityCommands');
        getInputType(arrOfSettings, commandValue);
    };
    const changeTypeOfCommand = (value) => {
        onSelected(value, id, 'command');
        getCommandsFromAbstract(props?.capability, value);
    };
    const getInputType = (arrSettings = [], e) => {
        arrSettings.map((item) => {
            for (const i in item) {
                if (i === e) {
                    if (item[i].variables[at.TARGET_BLUE]) {
                        setCurrentInputType({
                            target_percentage: {
                                max: 255,
                                min: 0,
                                type: 'integer',
                            },
                        });
                        onSelected(
                            {
                                target_percentage: {
                                    max: 255,
                                    min: 0,
                                    type: 'integer',
                                },
                            },
                            id,
                            'inputType',
                        );
                    } else {
                        setCurrentInputType(item[i].variables);
                        onSelected(item[i].variables, id, 'inputType');
                    }
                }
            }
        });
    };
    const getCurrentRule = () => {
        const findOnRules = currentRule.find((rule) => rule.id === currentItem.id);

        return findOnRules.inputType.variables;
    };
    const changeTargetStatus = (value) => {
        const keysOfCurrentInputTypeVariables = Object.keys(currentInputType || getCurrentRule());
        const data = { value: value?.target?.value ? String(value.target.value) : String(value) };
        const dataArrForRequest = [];

        const getInputType = () => {
            return currentInputType || getCurrentRule();
        };

        let inputType = getInputType();

        Object.keys(inputType).forEach((key) => {
            data.name = Object.keys(inputType[key]).toString();
        });

        keysOfCurrentInputTypeVariables.forEach((key) => {
            data.name = key;
            data.transform = inputType[key].type;
        });

        // TODO: MUST NOT BE THERE, fix in scope of any task related to RGB
        if (keysOfCurrentInputTypeVariables.length > 1) {
            keysOfCurrentInputTypeVariables.forEach((key) => {
                if (inputType?.target_cool_white) {
                    inputType = {
                        ...getInputType(),
                        target_cool_white: {
                            value: value.target_cool_white || '0',
                        },
                        target_warm_white: {
                            value: value.target_warm_white || '0',
                        },
                    };
                } else if (inputType?.target_red) {
                    inputType = {
                        ...getInputType(),
                        target_red: {
                            value: value.red || '0',
                        },
                        target_green: {
                            value: value.green || '0',
                        },
                        target_blue: {
                            value: value.blue || '0',
                        },
                    };
                } else if (inputType?.target_cyan) {
                    inputType = {
                        ...getInputType(),
                        target_cyan: {
                            value: value.red || '0',
                        },
                        target_magenta: {
                            value: value.green || '0',
                        },
                        target_yellow: {
                            value: value.blue || '0',
                        },
                    };
                } else {
                    inputType = {
                        ...getInputType(),
                        scale: {
                            value: value.scale,
                        },
                        target_value: {
                            value: value.target_value,
                        },
                    };
                }

                dataArrForRequest.push({
                    name: key,
                    transform: inputType[key].type,
                    value: inputType[key].value ? inputType[key].value.toString() : '',
                });

                setCurrentInputType(inputType);
            });
            onSelected(dataArrForRequest, id, 'target');
        } else {
            onSelected(data, id, 'target');
        }
        setIsShowedDelay(IMMEDIATELY);
    };

    const options = deviceOptions(currentDeviceAction, rooms);
    const handleMeshBotSelect = (value) => {
        onSelectedMeshBotAction({ ...value, meshBotAction: selectedMeshBotAction }, id, actionBlockName);
        setIsShowedDelay(IMMEDIATELY);
        setSelectedMeshBot(value);
    };
    const handleMeshBotSelectAction = (value) => {
        onSelectedMeshBotAction(
            {
                ...selectedMeshBot,
                meshBotAction: value === UNLATCH_SCENE ? UNLATCH_ALL_LATCHES : value,
                isEnabled: value === 'enableScene',
            },
            id,
            actionBlockName,
        );

        setIsShowedDelay(IMMEDIATELY);
        setSelectedMeshBotAction(value);
    };
    const handleLatchName = (value) => {
        onSelectedMeshBotAction(
            { ...selectedMeshBot, meshBotAction: value === ALL ? UNLATCH_ALL_LATCHES : UNLATCH_SCENE },
            id,
            actionBlockName,
            value,
        );
        setLatchName(value);
    };

    const changeDelay = useCallback((value) => {
        if (value === DELAY) {
            const delay = { seconds: 0, minutes: 0, hours: 0, days: 0 };
            MeshBotAction.updateActionDelay(id, delay, actionBlockName);
        } else {
            MeshBotAction.removeActionDelay(id, [], actionBlockName);
        }

        setIsShowedDelay(value);
    }, []);

    const updateActionDelay = useCallback(
        (id, params) => {
            MeshBotAction.updateActionDelay(id, params, actionBlockName);
        },
        [actionBlockName],
    );

    const isUnlatchAllVisible = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_41,
    );
    const getInputTypeForEditing = (data) => {
        if (data?.[at.TARGET_BLUE]) {
            return {
                target_percentage: {
                    max: 255,
                    min: 0,
                    type: 'integer',
                },
            };
        } else {
            return data;
        }
    };

    const memoAutocompleteInput = useMemo(() => {
        return (
            <DevicesBlock
                typeMeshbot={typeMeshbot}
                options={options}
                value={name ? { name } : {}}
                disableClearable={true}
                onChange={(event, value) => (value ? handleSelected(value, id, actionBlockName) : {})}
                actionSection={true}
                selectedDeviceId={abstract ? abstract.uuid : idDev}
            />
        );
    }, [name]);

    return (
        <div
            className={
                foundValidatedThenBlock
                    ? updateClassesNamesForThenBlocks(foundValidatedThenBlock)
                    : updateClassesNamesForElseBlocks(foundValidatedElseBlock)
            }
            ref={triggerBlockRef}
        >
            <div className={drag_handle} {...provided.dragHandleProps} />

            <div className={fields_block}>
                <div className={bodyClassNames}>
                    <div className={inner}>
                        <SelectComponent
                            value={selectedFieldTrigger ? selectedFieldTrigger : ''}
                            info={EZLOGIC_HINT_CONTROLLABLE_TYPE}
                            onChange={(e) =>
                                handleUpdateTriggerAction(e, id, at.SELECTED_FIELD_TRIGGER, actionBlockName)
                            }
                        >
                            {updateListAction.map((item) => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>
                                        {t(item.name)}
                                    </MenuItem>
                                );
                            })}
                            {model === 'vhub' && <MenuItem value="vidoo">Vidoo</MenuItem>}
                        </SelectComponent>

                        {selectedFieldTrigger === DEVICE_STATE && memoAutocompleteInput}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.MESHBOT_STATE && (
                            <MeshBotSelectBlock
                                options={scenesList}
                                disableClearable={true}
                                onChangeMeshBotSelect={(value) => (value?._id ? handleMeshBotSelect(value) : '')}
                                value={selectedMeshBot}
                                sceneId={sceneId}
                            />
                        )}

                        {selectedMeshBot?._id && (
                            <>
                                <SelectComponent
                                    value={meshbotCapability || selectedMeshBotAction}
                                    label={t(EZLOGIC_TITLE_CAPABILITY)}
                                    info={t(EZLOGIC_HINT_CAPABILITY)}
                                    onChange={(e) => handleMeshBotSelectAction(e.target.value)}
                                >
                                    {MESHBOT_ACTION_SELECT_VALUES.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </SelectComponent>
                                {meshbotCapability !== UNLATCH_SCENE && (
                                    <ActionDelay
                                        id={id}
                                        currentRule={currentRule}
                                        actionBlockName={actionBlockName}
                                        type={selectedFieldTrigger}
                                        isShowedDelay={isShowedDelay}
                                        onUpdateActionDelay={updateActionDelay}
                                        onChangeDelay={changeDelay}
                                        onSetIsShowedDelay={setIsShowedDelay}
                                    />
                                )}
                                {meshbotCapability === UNLATCH_SCENE && (
                                    <SelectComponent
                                        value={!isEditing ? latchName : currentLatchIdValue}
                                        label={t(EZLOGIC_TITLE_LATCH_NAME)}
                                        onChange={(e) => handleLatchName(e.target.value)}
                                    >
                                        {isUnlatchAllVisible && currentListOfLatches && (
                                            <MenuItem value={ALL}>{t(EZLOGIC_BUTTON_ALL)}</MenuItem>
                                        )}
                                        {currentListOfLatches?.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </SelectComponent>
                                )}
                            </>
                        )}

                        {currentCapabilities &&
                            currentCapabilities.length > 0 &&
                            selectedFieldTrigger === DEVICE_STATE && (
                                <SelectComponent
                                    value={props.capability ? props.capability : ''}
                                    label={t(EZLOGIC_TITLE_CAPABILITY)}
                                    info={t(EZLOGIC_HINT_CAPABILITY)}
                                    onChange={(e) => changeCapability(e.target.value)}
                                >
                                    {isUnlatchAllVisible && currentListOfLatches && (
                                        <MenuItem value={ALL}>{t(EZLOGIC_BUTTON_ALL)}</MenuItem>
                                    )}
                                    {currentCapabilities.map((item) => {
                                        return (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectComponent>
                            )}

                        {props?.capabilityCommands &&
                            props?.capabilityCommands?.length > 0 &&
                            selectedFieldTrigger === DEVICE_STATE && (
                                <SelectComponent
                                    value={props.command ? props.command : ''}
                                    label={t(EZLOGIC_LABEL_VALUE_TYPE)}
                                    info={t(EZLOGIC_HINT_VALUE_TYPE)}
                                    onChange={(e) => changeTypeOfCommand(e.target.value)}
                                >
                                    {isUnlatchAllVisible && currentListOfLatches && (
                                        <MenuItem value={ALL}>{t(EZLOGIC_BUTTON_ALL)}</MenuItem>
                                    )}
                                    {props.capabilityCommands.map((item) => {
                                        return (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectComponent>
                            )}

                        {props?.command && props?.command.length > 0 && (
                            <>
                                {currentRule.map((rule) => {
                                    if (rule.id === currentItem.id) {
                                        return (
                                            <InputTypeCloud
                                                key={rule.id}
                                                value={rule?.target}
                                                currentInputType={
                                                    currentInputType ||
                                                    getInputTypeForEditing(rule.inputType?.variables)
                                                }
                                                onChange={changeTargetStatus}
                                                calledFrom={ACTIONS}
                                                nameSubBlock={props.capability}
                                            />
                                        );
                                    }
                                })}
                            </>
                        )}

                        {blocks && blocks.length > 0 && selectedFieldTrigger === DEVICE_STATE && (
                            <SelectDeviceCapability
                                firstBlock={firstBlock}
                                onAddActionBlock={handleAddActionBlock}
                                idDev={idDev}
                                blocks={blocks}
                                currentDeviceAction={currentDeviceAction}
                                id={id}
                                items={items}
                            />
                        )}
                        {selectedFieldTrigger === LUA_SCRIPT && (
                            <LuaScript
                                id={id}
                                serial={serial}
                                selectedPoint={selectedPoint}
                                selectedName={selectedName}
                                selectedCode={selectedCode}
                                selectedFieldTrigger={selectedFieldTrigger}
                                isEditing={isEditing}
                                blocks={blocks}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                actionBlockName={actionBlockName}
                                listLuaScripts={listLuaScripts}
                                MeshBotAction={MeshBotAction}
                                onUpdateActionDelay={updateActionDelay}
                                onHandlerSetActionField={handlerSetActionField}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                            />
                        )}
                        {selectedFieldTrigger === HTTP_REQUEST && (
                            <HttpRequest
                                id={id}
                                selectedHeaders={selectedHeaders}
                                selectedContent={selectedContent}
                                selectedContentType={selectedContentType}
                                selectedSecurity={selectedSecurity}
                                selectedRequest={selectedRequest}
                                selectedUrl={selectedUrl}
                                selectedFieldTrigger={selectedFieldTrigger}
                                selectedCredentialUser={selectedCredentialUser}
                                selectedCredentialPassword={selectedCredentialPassword}
                                currentRule={currentRule}
                                actionBlockName={actionBlockName}
                                isShowedDelay={isShowedDelay}
                                onUpdateActionDelay={updateActionDelay}
                                onHandlerSetActionField={handlerSetActionField}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                                blocks={blocks}
                                isEditing={isEditing}
                            />
                        )}
                        {selectedFieldTrigger === MESHBOT_NOTIFICATION && (
                            <NotificationBlock
                                id={id}
                                getNotificationData={getNotificationData}
                                blocks={blocks}
                                isShowedDelay={isShowedDelay}
                                actionBlockName={actionBlockName}
                                currentRule={currentRule}
                                usersData={usersData}
                                channels={channels}
                                blockType={blockType}
                                selectedFieldTrigger={selectedFieldTrigger}
                                onUpdateActionDelay={updateActionDelay}
                                onHandlerSetActionField={handlerSetActionField}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                            />
                        )}
                        {selectedFieldTrigger === CLOUD_NOTIFICATION && (
                            <NotificationCloudBlock
                                id={id}
                                getNotificationData={getNotificationData}
                                usersData={usersData}
                                channels={channels}
                                currentRule={currentRule}
                                notificationType={notificationType}
                            />
                        )}
                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.DASHBOARD && (
                            <DashboardActionBlock
                                id={id}
                                getNotificationData={getNotificationData}
                                usersData={usersData}
                                channels={channels}
                                currentRule={currentRule}
                            />
                        )}
                        {blocks && blocks.length > 0 && selectedFieldTrigger === DEVICE_STATE && (
                            <BlockOfFieldsForDeviceAction
                                id={id}
                                idDev={idDev}
                                blocks={blocks}
                                type={typeMeshbot}
                                nameDevice={name}
                                actionBlockName={actionBlockName}
                                selectedFieldTrigger={selectedFieldTrigger}
                                isShowedDelay={isShowedDelay}
                                firstBlock={firstBlock}
                                currentItem={currentItem}
                                currentRule={currentRule}
                                onUpdateFieldActionBlock={onUpdateFieldActionBlock}
                                onAddActionBlock={onAddActionBlock}
                                onUpdateActionDelay={updateActionDelay}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                            />
                        )}
                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.VIDOO && (
                            <VidooNodeForAction
                                id={id}
                                items={items}
                                devices={devices}
                                blocks={blocks}
                                firstBlock={firstBlock}
                                selectedFieldTrigger={selectedFieldTrigger}
                                actionBlockName={actionBlockName}
                                onSelected={onSelected}
                                onAddActionBlock={onAddActionBlock}
                                onUpdateFieldActionBlock={onUpdateFieldActionBlock}
                                selectedCameraId={selectedCameraId}
                                selectedCapabilityValue={selectedCapabilityValue}
                                selectedCapabilityId={selectedCapabilityId}
                            />
                        )}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.PAAS && (
                            <PAAS
                                getNotificationData={getNotificationData}
                                id={id}
                                onPreventDraggable={onPreventDraggable}
                                handlerSetError={handlerSetError}
                                blocks={blocks}
                                isEditing={isEditing}
                                actionBlockName={actionBlockName}
                                updateCloudRequiredFieldAction={updateCloudRequiredFieldAction}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                onUpdateActionDelay={updateActionDelay}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                                selectedFieldTrigger={selectedFieldTrigger}
                                typeMeshbot={typeMeshbot}
                            />
                        )}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.LOCAL_VARIABLE && (
                            <LocalVariable
                                id={id}
                                blockType={blockType}
                                currentItem={currentItem}
                                currentRule={currentRule}
                                actionBlockName={actionBlockName}
                                selectedFieldTrigger={selectedFieldTrigger}
                                isShowedDelay={isShowedDelay}
                                onUpdateActionDelay={updateActionDelay}
                                onChangeDelay={changeDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                            />
                        )}

                        {selectedFieldTrigger === MESHBOT_NODE_TYPES.CONTROLLER && (
                            <ControllerActionNode
                                id={id}
                                blocks={blocks}
                                blockType={blockType}
                                currentItem={currentItem}
                                currentRule={currentRule}
                                isShowedDelay={isShowedDelay}
                                onSetIsShowedDelay={setIsShowedDelay}
                                onUpdateActionDelay={updateActionDelay}
                                onChangeDelay={changeDelay}
                            />
                        )}
                    </div>
                </div>
                {selectedFieldTrigger === MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE && (
                    <HouseMode
                        id={id}
                        currentItem={currentItem}
                        blockType={blockType}
                        currentRule={currentRule}
                        isShowedDelay={isShowedDelay}
                        onUpdateActionDelay={updateActionDelay}
                        onChangeDelay={changeDelay}
                        onSetIsShowedDelay={setIsShowedDelay}
                        actionBlockName={actionBlockName}
                        selectedFieldTrigger={selectedFieldTrigger}
                    />
                )}
                <TriggerBlockButtonsWrapper
                    id={id}
                    blockType={blockType}
                    sectionType={MESHBOT_SECTION_TYPE.ACTION}
                    typeMeshbot={typeMeshbot}
                    actionExceptionTriggers={actionExceptionTriggers}
                    onDeleteTrigger={handleDeleteTriggerAction}
                    onToggleExceptionBlock={handleToggleExceptionBlock}
                    blocks={blocks}
                    actionBlockName={actionBlockName}
                />
            </div>
        </div>
    );
};

export default TriggerForActions;
