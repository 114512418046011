import { ERROR_STATUS } from '../../../../constants/api';
import { t } from '../../../../helpers/language';
import { EZLOGIC_TITLE_SOMETHING_WENT_WRONG } from '../../../../constants/language_tokens';

/**
 * Handles query response errors
 * @param {Object} response - query response
 * @returns {undefined} In case of an error, it will create new Error and throw it out
 * @example
 * responseErrorHandling({data: { data: { error_text: 'Error'}}})
 * */
export const responseErrorHandling = (response) => {
    if (response.data.status === ERROR_STATUS) {
        const message =
            response.data.data?.error_text ||
            response.data.data?.error_message ||
            t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
        throw new Error(message);
    }
};
