import React from 'react';
import { Box, MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { offlineControllerMesage } from './ControllerSelect.module.scss';
import {
    EZLOGIC_HINT_CONTROLLABLE,
    EZLOGIC_TITLE_CONTROLLABLE,
    EZLOGIC_TITLE_YOUR_CONTROLLER_IS_OFFLINE,
} from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

const ControllerSelect = (props) => {
    const { serial, isControllerConnected } = props;
    const { t } = useTranslate();

    return (
        <Box>
            <SelectComponent
                label={t(EZLOGIC_TITLE_CONTROLLABLE)}
                info={t(EZLOGIC_HINT_CONTROLLABLE)}
                value={serial}
                error={!isControllerConnected}
            >
                <MenuItem value={serial}>{serial}</MenuItem>
            </SelectComponent>
            {!isControllerConnected && (
                <span className={offlineControllerMesage}>{t(EZLOGIC_TITLE_YOUR_CONTROLLER_IS_OFFLINE)}</span>
            )}
        </Box>
    );
};

export default ControllerSelect;
