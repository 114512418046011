import React from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { MENU_LIST_GROW, TRUE_STRING } from '../../../../../constants/Devices';
import { EZLOGIC_TITLE_ADD_LABEL } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../languages';

const SelectButton = ({ toggleOpenDialog, anchorRef, open }) => {
    const { t } = useTranslate();

    return (
        <button
            onClick={toggleOpenDialog}
            ref={anchorRef}
            aria-controls={open ? MENU_LIST_GROW : undefined}
            aria-haspopup={TRUE_STRING}
        >
            {t(EZLOGIC_TITLE_ADD_LABEL)}
            {open ? <ExpandLess /> : <ExpandMore />}
        </button>
    );
};

export default SelectButton;
