import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import SelectComponent from '../../containers/Ezlo/EzloMeshbot/components/SelectComponent';
import { info, filterCapabilitiesList } from '../../containers/Ezlo/EzloMeshbot/utils';
import { formatNameWithUnderscores } from '../../helpers/helpersMeshBot';
import { ZERO_INT, VALUE } from '../../constants/MeshbotConstant';

const SelectCapabilityFromList = ({ devicesCapabilitiesList, selectedCapability, onDataUpdate }) => {
    const handleSelectCapability = (event) =>
        onDataUpdate({
            selectedCapability: event.target.value,
            selectedComparator: {},
            compareTo: VALUE,
            comparingValue: ZERO_INT,
        });

    return (
        <SelectComponent
            value={selectedCapability || {}}
            label={info.hints.capability}
            info={info.text.capability}
            onChange={handleSelectCapability}
            renderValue={(value) => <div>{formatNameWithUnderscores(value?.name || '')}</div>}
            deviceSetting={<div>{`Value type: ${selectedCapability?.valueType}`}</div>}
        >
            {filterCapabilitiesList(devicesCapabilitiesList).map((item) => (
                <MenuItem key={item.name} value={item}>
                    {formatNameWithUnderscores(item.name)}
                </MenuItem>
            ))}
        </SelectComponent>
    );
};

export default SelectCapabilityFromList;

SelectCapabilityFromList.propTypes = {
    selectedCapability: PropTypes.object,
    devicesCapabilitiesList: PropTypes.array,
    onDataUpdate: PropTypes.func,
};
