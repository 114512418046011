import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MeshBotCloudCreate from './MeshBotCloudCreate';
import NotFoundPageContent from '../../../../containers/NotFoundPage/NotFoundPageContent';

// TODO: bad naming, why loc?
const MeshBotLoc = (props) => {
    const { MeshBotAction, NotificationActions, MainAction, router } = props;
    const abstracts = useSelector((state) => state.main?.abstractsList?.abstracts);
    const { url } = useRouteMatch();

    useEffect(() => {
        const notification = /\b(notification)\b/g.test(router);
        const interaction = /\b(interaction)\b/g.test(router);

        if (notification) {
            NotificationActions.updateNotificationType('notification');
        } else if (interaction) {
            NotificationActions.updateNotificationType('interaction');
        } else {
            NotificationActions.updateNotificationType('');
        }

        MeshBotAction.getAbstractList();
        MeshBotAction.getCapabilities();
        // update abstracts details to get the newly created abstracts
        MainAction.getAbstractsList(abstracts);
    }, []);

    // TODO: do we need any switch here?
    return (
        <Switch>
            <Route path={`${url}/new`} component={MeshBotCloudCreate} />
            <Route path={`${url}/:id`} component={MeshBotCloudCreate} />
            <Route render={() => <NotFoundPageContent loggedIn />} />
        </Switch>
    );
};

export default MeshBotLoc;
