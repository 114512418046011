import classNames from 'classnames';

import { integration, description, integration_large, description_large } from './IntegrationCard.module.scss';

import { INTEGRATION_CARD_SIZES } from './IntegrationCard';

export const getIntegrationCardClasses = (size) => {
    const isLarge = size === INTEGRATION_CARD_SIZES.large;

    const integrationContainerClasses = classNames(integration, { [integration_large]: isLarge });
    const integrationDescriptionClasses = classNames(description, {
        [description_large]: isLarge,
    });

    return { integrationContainerClasses, integrationDescriptionClasses };
};
