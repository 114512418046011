import { useMemo } from 'react';
import useMeshBotsTableContext from '../../../../../containers/Ezlo/EzloMeshbots/hooks/useMeshBotsTableContext';
import { buildLabelsStateWithStatuses, getSelectedMeshbotsLabelsStatus } from '../../utils';
import useMeshBotLabelsStateForListing from '../useMeshBotLabelsStateForListing';

const useSelectedMeshBotsLabelsStatuses = () => {
    const { allMeshbots, selectedMeshBotsByType } = useMeshBotsTableContext();
    const labelsList = useMeshBotLabelsStateForListing();

    return useMemo(() => {
        const labelsStatusForSelectedMeshBots = getSelectedMeshbotsLabelsStatus(selectedMeshBotsByType, allMeshbots);

        return buildLabelsStateWithStatuses(labelsList, labelsStatusForSelectedMeshBots);
    }, [selectedMeshBotsByType, allMeshbots, labelsList]);
};

export default useSelectedMeshBotsLabelsStatuses;
