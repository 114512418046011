import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpressionsAddForm from '../ExpressionsAddForm';
import ExpressionItemInfo from './ExpressionItemInfo';

import { MainAction } from '../../../../actions';
import { EXPRESSIONS_TYPE } from '../../../../constants/Expressions';
import { DATA_TYPES_LIST } from '../../../../constants/Variables';

const ExpressionItem = (props) => {
    const {
        code,
        name,
        value,
        error,
        params,
        onEditStart,
        onEditEnd,
        expressionType,
        valueType,
        tokenValue,
        scalableType,
        initCreatedAt,
    } = props;
    const [isEditing, setIsEditing] = useState(false);
    const serial = useSelector((state) => state.ezlo.serial);
    const isExpression = expressionType === EXPRESSIONS_TYPE.EXPRESSION;
    let variableCode;

    if (!isExpression) {
        valueType === DATA_TYPES_LIST.TYPE_TOKEN ? (variableCode = tokenValue) : (variableCode = value);
    }

    useEffect(() => {
        if (isEditing) {
            onEditStart(name);
        } else {
            onEditEnd(name);
        }
    }, [isEditing]);

    const dispatch = useDispatch();

    const handleEditCancel = () => {
        dispatch(MainAction.confirmUserAction(() => setIsEditing(false)));
    };

    return isEditing ? (
        <ExpressionsAddForm
            serial={serial}
            initName={name}
            initCode={isExpression ? code : variableCode}
            initParamsItems={params.items}
            initCreatedAt={initCreatedAt}
            onCancel={handleEditCancel}
            expressionType={expressionType}
            initValueType={valueType}
            initActionDataTypeValue={variableCode}
            initRgbDataTypeValue={variableCode}
            initTokenDataTypeValue={variableCode}
            initScalableType={scalableType}
        />
    ) : (
        <ExpressionItemInfo
            serial={serial}
            isExpression={isExpression}
            isEditing={isEditing}
            error={error}
            valueType={valueType}
            name={name}
            code={code}
            value={value}
            variableCode={variableCode}
            scalableType={scalableType}
            onSetIsEditing={setIsEditing}
        />
    );
};

export default ExpressionItem;
