import React from 'react';
import EmptyStateIcon from '../icons/EmptyStateIcon';
import classNames from 'classnames';
import {
    EZLOGIC_TITLE_YOUR_BACKUP_WILL_APPEAR,
    EZLOGIC_TITLE_YOU_HAVE_NO_BACKUP,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import './style.scss';

const NoBackups = () => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <EmptyStateIcon className="icon-completed" />
            <span className={classNames('text', 'title')}>{t(EZLOGIC_TITLE_YOU_HAVE_NO_BACKUP)}</span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_YOUR_BACKUP_WILL_APPEAR)}
            </span>
        </div>
    );
};

export default NoBackups;
