import { isEmpty } from 'lodash';
import { CODE_GET_PARAM_NAME, USER_GET_PARAM_NAME } from '../../constants/getParams';
import { VALID_PASSWORD_RESET_LINK_ACTION, VALID_PASSWORD_RESET_LINK_STATUS } from '../../constants/networkConstants';

/**
 * Based on the response, the link is valid or not.
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=User+-+Password+Reset+Set here}
 * @param {object} response - configuration of virtual device
 * @returns {boolean} return true if the link is valid.
 * */
export function isValidPasswordResetLink(response) {
    return response?.status === VALID_PASSWORD_RESET_LINK_STATUS && isEmpty(response?.data);
}

/**
 * Determines whether the parameters passed are valid.
 * @param {object} params - query params from url
 * @returns {boolean} if there are all the necessary parameters, it will return true.
 * */
export function isValidQueryParams(params) {
    return Boolean(params && params[USER_GET_PARAM_NAME] && params[CODE_GET_PARAM_NAME]);
}

/**
 * Builds a payload for a link validation request
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=User+-+Password+Reset+Set here}
 * @param {object} params - configuration of virtual device
 * @returns {object} payload for password reset link validate
 * */
export function buildResetPasswordLinkValidatePayload(params) {
    return {
        pk_user: params[USER_GET_PARAM_NAME],
        code: params[CODE_GET_PARAM_NAME],
        action: VALID_PASSWORD_RESET_LINK_ACTION,
    };
}
