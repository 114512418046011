import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import VirtualDevicesForm from '../VirtualDevicesForm';
import HeaderWithControllersSelect from '../components/HeaderWithControllersSelect';
import VirtualDevicesContent from './VirtualDevicesContent';
import BoxWithLinearProgress from '../../../../components/BoxWithLinearProgress';

import { validationSchema } from '../VirtualDevicesForm/validationSchema';
import { useVirtualDevicesPageLoading } from '../customHooks/useVirtualDevicesPageLoading';
import { getDefaultVirtualDevice } from '../utils';
import virtualDevicesActions from '../../../../actions/virtualDevicesActions';
import { VIRTUAL_DEVICE_ARRAY_NAME } from '../../../../constants/VirtualDeviceConstant';

import styles from '../styles.module.scss';
import { EZLOGIC_HEADING_CREATE_VIRTUAL_DEVICE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const VirtualDevicesCreate = () => {
    const dispatch = useDispatch();
    const isLoading = useVirtualDevicesPageLoading();
    const initialValues = {
        [VIRTUAL_DEVICE_ARRAY_NAME]: [getDefaultVirtualDevice()],
    };
    const { t } = useTranslate();

    const handleClickSave = useCallback(({ virtualDevices }, { resetForm }) => {
        dispatch(virtualDevicesActions.createNewVirtualDevices(virtualDevices, t));
        resetForm();
    }, []);

    return (
        <BoxWithLinearProgress isLinearProgress={isLoading}>
            <section className={styles.virtualDevicesContainer}>
                <HeaderWithControllersSelect title={EZLOGIC_HEADING_CREATE_VIRTUAL_DEVICE} />

                {!isLoading && (
                    <VirtualDevicesContent>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleClickSave}
                        >
                            <VirtualDevicesForm />
                        </Formik>
                    </VirtualDevicesContent>
                )}
            </section>
        </BoxWithLinearProgress>
    );
};

export default VirtualDevicesCreate;
