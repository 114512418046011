import { Button, Input, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import {
    EZLOGIC_BUTTON_ADD,
    NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TEXTFIELD_TITLE,
    NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TITLE,
} from '../../../../../../../../../constants/language_tokens';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../../../../../constants/URLs';
import { useTranslate } from '../../../../../../../../../features/languages';
import { bugsnagNotify } from '../../../../../../../../ErrorBoundary/utils';
import { buildAuthUrlConfigRequestParams } from '../../PaasAPI/paas-request-params-generators';
import { IcVisualsLink } from '../../../../../../../../../assets/icons/visuals/neutralVisuals/ic_visuals_link';
import { IcControlsClose } from '../../../../../../../../../assets/icons';

const ConditionalWrap = ({ condition, wrap, children }) => {
    return condition ? wrap(children) : children;
};

export const EnrollByTokenModal = (props) => {
    const {
        auth,
        parjectName,
        PAASAuthUuid,
        isTokenModalOpen,
        authExplanation,
        setIsTokenModalOpen,
        setIsEnrolmentLoading,
        startPollingToGetAbstracts,
        shouldOpenInModal,
    } = props;
    const { authenticationType, parjectGeneratedId } = auth || {};

    const [token, setToken] = useState(false);

    const { t } = useTranslate();
    const handleModalClose = () => {
        setIsTokenModalOpen(false);
    };

    const handleSetToken = (e) => {
        setToken(e.target.value);
    };

    const enrollByToken = async () => {
        const authByApikeyConfigRequestParams = buildAuthUrlConfigRequestParams(
            PAASAuthUuid,
            authenticationType,
            parjectGeneratedId,
            token,
        );

        setIsEnrolmentLoading(true);
        setIsTokenModalOpen(false);

        try {
            await fetch(API_CLOUD_EZLO_REQUEST_URL(), authByApikeyConfigRequestParams);

            startPollingToGetAbstracts();
        } catch (error) {
            bugsnagNotify(error);
        }
    };

    return (
        <ConditionalWrap
            condition={shouldOpenInModal}
            wrap={(wrappedChildren) => (
                <Modal open={isTokenModalOpen} onClose={handleModalClose}>
                    {wrappedChildren}
                </Modal>
            )}
        >
            <div className="confirmation-modal-content">
                <p className="close-confirmation-modal-btn" onClick={handleModalClose}>
                    <IcControlsClose />
                </p>
                <IcVisualsLink />
                <h3>
                    {t(NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TITLE)}
                    {parjectName}
                </h3>
                <p>{authExplanation}</p>
                <div className="paas__token-input">
                    <Input
                        placeholder={t(NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TEXTFIELD_TITLE)}
                        onChange={handleSetToken}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="button-enrollByTokenContainer">
                    <Button onClick={enrollByToken} color="primary" variant="contained" disabled={!token}>
                        {t(EZLOGIC_BUTTON_ADD)}
                    </Button>
                </div>
            </div>
        </ConditionalWrap>
    );
};
