import React, { Component } from 'react';
import classes from 'classnames';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import * as meshbot from '../../constants/MeshbotConstant';
import CustomTooltip from './CustomTooltip';
import { getDatesRangeByWeek, getTitleForTooltip } from './utils';

import { weeksYear, weeksYear__item, active } from './WeeksOfTheYear.module.scss';

class WeeksOfTheYear extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weeksMounth: meshbot.WEEKS_OF_THE_YEAR,
        };
    }

    componentDidMount() {
        this.setState({ weeksMounth: this.formattingWeeksMounth(this.props.isArrayYear) });
    }

    // eslint-disable-next-line
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isArrayYear) {
            this.setState({ week: this.formattingWeeksMounth(nextProps.isArrayYear) });
        }
    }

    formattingWeeksMounth = (data) => {
        return this.state.weeksMounth.map((item) => {
            if (data.includes(item.name)) {
                item.checked = true;

                return item;
            }
            item.checked = false;

            return item;
        });
    };

    handleChange = (e, item) => {
        const { onChange } = this.props;
        onChange(e, item);
    };

    render() {
        const { weeksMounth } = this.state;
        const { t } = this.props;

        return (
            <>
                <ul className={weeksYear}>
                    {weeksMounth.map((item, index) => {
                        return (
                            <CustomTooltip
                                title={
                                    getTitleForTooltip(getDatesRangeByWeek(item, index)) ||
                                    t(meshbot.NOTICE_FOR_AVAILABLE_WEEK)
                                }
                                placement="right-start"
                                enterDelay={meshbot.TOOLTIPS_DELAY}
                                enterNextDelay={meshbot.TOOLTIPS_DELAY}
                            >
                                <li
                                    key={index + meshbot.CORRECTION_FACTOR}
                                    className={classes(weeksYear__item, { [active]: item.checked })}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.checked}
                                                onChange={(e) =>
                                                    this.handleChange(e, index + meshbot.CORRECTION_FACTOR)
                                                }
                                            />
                                        }
                                        label={
                                            item.name === meshbot.LAST_WEEK_NAME
                                                ? t(meshbot.LAST_WEEK_NAME_ON_UI)
                                                : item.name
                                        }
                                    />
                                </li>
                            </CustomTooltip>
                        );
                    })}
                </ul>
            </>
        );
    }
}

export default WeeksOfTheYear;
