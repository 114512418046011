import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import DevicesBlock from '../../EzloMeshbot/components/DeviceBlockComponent';
import DeviceAssociationsNotification from '../EzloDeviceAssociationsNotification';

import { filterSourceOptions } from '../utils';
import { optionsSelect } from '../../../../constants/MeshbotConstant';
import InfoIcon from '../../EzloGroups/DeleteGroupDialog/InfoIcon';
import { MainAction } from '../../../../actions';
import { FORM_CREATING_NEW_ASSOCIATION } from '../../../../constants/ActionConfirmDialog';
import { useTranslate } from '../../../../features/languages';
import {
    EZLOGIC_BUTTON_CANCEL,
    EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION,
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_ASSOCIATED_WITH,
    EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS_REACHED,
    EZLOGIC_TITLE_NON_SPECIFIED,
    EZLOGIC_TITLE_SOURCE_CHANNEL,
    EZLOGIC_TITLE_SOURCE_DEVICE,
    EZLOGIC_TITLE_SOURCE_GROUP,
    EZLOGIC_TITLE_TARGET_CHANNEL,
    EZLOGIC_TITLE_TARGET_DEVICE,
    EZLOGIC_TITLE_THIS_DEVICE_NOT_SUPPORT,
    EZLOGIC_TITLE_TO_CREATE_ASSOCIATION,
} from '../../../../constants/language_tokens';

import styles from './DeviceAssociationsAddForm.module.scss';
import { warning } from '../DeviceAssociationsLayout/style.module.scss';

const DeviceAssociationsAddForm = (props) => {
    const {
        options = [],
        disabled,
        onRemove,
        onChangeDevice,
        onChangeTargetDevice,
        onChangeSourceChannel,
        onChangeSourceGroup,
        onChangeTargetChannelValue,
        noAssociations,
        sourceChannels,
        sourceGroups,
        sourceChannelValue,
        sourceGroupValue,
        targetChannelHide,
        targetChannels,
        targetChannelValue,
        onSave,
        isButteryDevice,
        associationCommands,
        isMaximumAssociation,
        maximumCountOfAssociations,
        currentDeviceId,
        targetDeviceName,
        sourceDeviceName,
        setOpenForm,
        isInnerAssociationForm,
        associationsFull,
        targetOptions,
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const onChangeSourceDevice = (event, value) => {
        onChangeDevice(value._id, value.name);
    };

    const changeTargetDevice = (event, value) => {
        onChangeTargetDevice(value._id, value.name);
    };

    useEffect(() => {
        dispatch(MainAction.setStatePageBeingEdited(FORM_CREATING_NEW_ASSOCIATION));

        return () => dispatch(MainAction.setStatePageBeingEdited({}));
    }, []);

    const handleSaveAssociation = () => {
        onSave();
        if (isInnerAssociationForm) {
            setOpenForm(false);
        }
    };

    const handleRemove = () => {
        onRemove();
        if (isInnerAssociationForm) {
            setOpenForm(false);
        }
    };

    return (
        <div className={!isInnerAssociationForm ? 'associations-item' : 'associations-item-inner-form'}>
            <div className={styles.createNewAssociation}>{t(EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION)}</div>
            {isButteryDevice && !noAssociations && (
                <p className={warning}>
                    <InfoIcon height={30} width={30} />
                    {t(EZLOGIC_TITLE_TO_CREATE_ASSOCIATION)}
                </p>
            )}
            <div className={`associations-item__data ${styles.associationItemData}`}>
                <div>
                    {isInnerAssociationForm ? (
                        <div className={styles.sourceDeviceColumn}>
                            <p className={styles.deviceName}>{t(EZLOGIC_TITLE_SOURCE_DEVICE)}</p>
                            <div className={styles.dataDevice}>
                                <span className={styles.targetDeviceName}>{sourceDeviceName?.name}</span>
                            </div>
                        </div>
                    ) : (
                        <DevicesBlock
                            typeMeshbot={'local'}
                            options={filterSourceOptions(options)}
                            label={t(EZLOGIC_TITLE_SOURCE_DEVICE)}
                            disableClearable={true}
                            onChange={onChangeSourceDevice}
                            value={sourceDeviceName}
                        />
                    )}
                </div>
                {!noAssociations && (
                    <>
                        <div>
                            <FormControl variant="outlined" className="associations-item__data__select">
                                <label>{t(EZLOGIC_TITLE_SOURCE_CHANNEL)}</label>
                                <Select
                                    MenuProps={optionsSelect}
                                    value={sourceChannelValue}
                                    onChange={onChangeSourceChannel}
                                    disabled={sourceChannels.length === 1}
                                >
                                    {sourceChannels.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl variant="outlined" className="associations-item__data__select">
                                <label>{t(EZLOGIC_TITLE_SOURCE_GROUP)}</label>
                                <Select
                                    MenuProps={optionsSelect}
                                    value={sourceGroupValue}
                                    onChange={onChangeSourceGroup}
                                    disabled={sourceGroups.length === 1}
                                >
                                    {sourceGroups.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={`associations-item__data-associate-with`}>
                            {t(EZLOGIC_TITLE_ASSOCIATED_WITH)}
                        </div>
                        <div>
                            <DevicesBlock
                                typeMeshbot={'local'}
                                options={targetOptions}
                                disableClearable={true}
                                label={t(EZLOGIC_TITLE_TARGET_DEVICE)}
                                onChange={changeTargetDevice}
                                value={targetDeviceName}
                            />
                        </div>
                        <div>
                            {!targetChannelHide && targetChannels?.length && !isNaN(targetChannels[0]) ? (
                                <FormControl variant="outlined" className="associations-item__data__select__target">
                                    <label>{t(EZLOGIC_TITLE_TARGET_CHANNEL)}</label>
                                    <Select
                                        MenuProps={optionsSelect}
                                        value={targetChannelValue}
                                        onChange={onChangeTargetChannelValue}
                                    >
                                        <MenuItem value={'non-specified'}>{t(EZLOGIC_TITLE_NON_SPECIFIED)}</MenuItem>
                                        {targetChannels.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                )}
                {noAssociations && currentDeviceId.length ? (
                    <div className="no-associations">{t(EZLOGIC_TITLE_THIS_DEVICE_NOT_SUPPORT)}</div>
                ) : (
                    ''
                )}
                {associationsFull && (
                    <p className={warning}>
                        <InfoIcon height={30} width={30} />
                        {t(EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS_REACHED)}
                    </p>
                )}
            </div>
            {associationCommands && !noAssociations && (
                <DeviceAssociationsNotification
                    isMaximumAssociation={isMaximumAssociation}
                    associationCommands={associationCommands}
                    maximumCountOfAssociations={maximumCountOfAssociations}
                />
            )}
            <div className="associations-buttons">
                <button className={`associations-cancel-button`} onClick={handleRemove}>
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </button>
                <button
                    className={`associations-save-button ${disabled ? 'disabled' : 'active'}`}
                    disabled={disabled}
                    onClick={() => handleSaveAssociation()}
                >
                    {t(EZLOGIC_BUTTON_SAVE)}
                </button>
            </div>
        </div>
    );
};

export default DeviceAssociationsAddForm;
