import React, { memo } from 'react';

import EnumAutocomplete from './components/EnumAutocomplete';
import StringAutocomplete from './components/StringAutocomplete';
import NumberAutocomplete from './components/NumberAutocomplete';

import { getTypeOfArrayTypeField } from './utils';
import { ARRAY_FIELD_TYPES } from '../../paas-constants';

/**
 * Defines and renders a field depending on the property data
 * @param { object } props
 * @returns { JSX.Element } multiple Autocomplete
 * @example
 * <ArrayTypeField
 *      value={['one', 'two']}
 *      fieldSchema={{type: 'array', items: {type: 'string' }}}
 *      fieldName={'something'}
 *      description={'Big big'}
 *      isRequired={true}
 *      onChange={func}
 * />
 */

const ArrayTypeField = (props) => {
    const { ENUM, NUMBER, STRING } = ARRAY_FIELD_TYPES;
    const fieldType = getTypeOfArrayTypeField(props.fieldSchema.items);

    const ArrayTypeFields = {
        [ENUM]: <EnumAutocomplete {...props} />,
        [STRING]: <StringAutocomplete {...props} />,
        [NUMBER]: <NumberAutocomplete {...props} />,
    };

    return ArrayTypeFields[fieldType];
};

export default memo(ArrayTypeField);
