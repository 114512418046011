import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';

import { setLocalStorage } from '../../helpers/helpersMeshBot';

import './style.scss';

const MeshbotEditRule = ({ params, onClick }) => {
    // TODO: refactoring needed, no localstorage here!!!
    const handleEdit = () => {
        setLocalStorage('createType', params.type);
        onClick(params);
    };

    return (
        <div className="meshbot-edit__wrapper">
            <IconButton
                color="primary"
                onClick={handleEdit}
                disabled={params.isDisabled}
                className={classNames({ disabled: params.isDisabled })}
            >
                <Edit color="primary" />
            </IconButton>
        </div>
    );
};

export default MeshbotEditRule;

MeshbotEditRule.propTypes = {
    params: PropTypes.object,
    onClick: PropTypes.func,
};
