import React from 'react';

import GridTable from '../../../../components/Table';
import { ROWS_PER_PAGE } from '../../EzloMeshbots/constants';
import { notificationTableWrapper } from '../EzloOEMNotiications.module.scss';

const EzloOEMNotificationTemplateTable = (props) => {
    const { columns, notificationTemplates, uuid } = props;

    return (
        <div className={notificationTableWrapper}>
            <GridTable
                columns={columns}
                pagination={true}
                rows={notificationTemplates}
                disableSelectionOnClick={true}
                rowsPerPageOptions={ROWS_PER_PAGE}
                showColumnRightBorder={false}
                showCellRightBorder={true}
                hideFooterRowCount={true}
                disableExtendRowFullWidth={false}
                disableColumnReorder={true}
                disableColumnResize={false}
                autoHeight={true}
                autoPageSize={true}
                uuid={uuid}
            />
        </div>
    );
};

export default EzloOEMNotificationTemplateTable;
