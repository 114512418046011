import React, { memo, useState } from 'react';

import { Popover } from '@material-ui/core';
import { Info, Clear } from '@material-ui/icons';

import { PRIMARY } from '../../../../../../constants/MeshbotConstant';
import { MUI_PROPS } from '../../../../../../constants/DevicesGroups';
import { CONTROLLER_POPOVER_ID } from '../../../constants';
import ControllerInfoList from './ControllerInfoList';
import styles from './ControllerCard.module.scss';

const InfoIconAndTooltipWithControllerInfo = ({ controllerData, controller }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const infoIconId = open ? CONTROLLER_POPOVER_ID : undefined;

    return (
        <div className={styles.iconInfo}>
            <Info color={PRIMARY} onClick={handlePopoverOpen} aria-describedby={infoIconId} />
            <Popover
                id={infoIconId}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: MUI_PROPS.CENTER,
                    horizontal: MUI_PROPS.RIGHT,
                }}
                transformOrigin={{
                    vertical: MUI_PROPS.CENTER,
                    horizontal: MUI_PROPS.LEFT,
                }}
            >
                <div className={styles.popoverBody}>
                    <Clear className={styles.iconClose} onClick={handlePopoverClose} />
                    <ControllerInfoList controllerData={controllerData} controller={controller} />
                </div>
            </Popover>
        </div>
    );
};
export default memo(InfoIconAndTooltipWithControllerInfo);
