import React from 'react';

import { EZLOGIC_TITLE_LIST_OF_NON_RECEIVED_FILES_WARNING } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';

import styles from './styles.module.scss';

const ImagesValidInfo = ({ notValidImgNames }) => {
    const { t } = useTranslate();

    return (
        notValidImgNames.length > 0 && (
            <div className={styles.notValidFilesBox}>
                {t(EZLOGIC_TITLE_LIST_OF_NON_RECEIVED_FILES_WARNING)}
                <ul>
                    {notValidImgNames.map((name) => (
                        <li key={name}>{name}</li>
                    ))}
                </ul>
            </div>
        )
    );
};

export default ImagesValidInfo;
