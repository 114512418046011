import React from 'react';
import { useEffect, useState } from 'react';

import { InputMaterial } from '../../../../../components';
import { HOURS, MINUTES, SECONDS } from '../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../features/languages';
import { convertTimeToSeconds, getIntegerFromNumberInput } from '../../utils';

import styles from './TimeInputsBlock.module.scss';
import {
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_SECONDS,
} from '../../../../../constants/language_tokens';

const TimeInputsBlock = (props) => {
    const { mainStateSeconds, onTimeUpdate, disabled, styleForFunctionBlock, hideContent } = props;
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const { t } = useTranslate();
    // synchronization of seconds of the main state with the state of the component
    useEffect(() => {
        setHours(Math.floor(mainStateSeconds / 3600));
        setMinutes(Math.floor((mainStateSeconds % 3600) / 60));
        setSeconds(mainStateSeconds % 60);
    }, [mainStateSeconds]);

    // preparing data and storing it in a redux state
    const handleOnChangeTime = (e, type) => {
        const time = {
            [SECONDS]: seconds,
            [MINUTES]: minutes,
            [HOURS]: hours,
        };
        time[type] = getIntegerFromNumberInput(e);
        const timeInSeconds = +time[SECONDS] + convertTimeToSeconds(time[MINUTES], time[HOURS]);
        onTimeUpdate(timeInSeconds);
    };

    return (
        <div className={styles.wrapper}>
            <InputMaterial
                label={t(EZLOGIC_TITLE_SECONDS)}
                type="number"
                placeholder={t(EZLOGIC_TITLE_SECONDS)}
                name="seconds"
                value={seconds}
                min={0}
                max={59}
                step={1}
                onChange={(e) => handleOnChangeTime(e, SECONDS)}
                disabled={disabled}
                small
                styleForFunctionBlock={styleForFunctionBlock}
                secsContentType={!hideContent}
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_MINUTES)}
                placeholder={t(EZLOGIC_TITLE_MINUTES)}
                type="number"
                name="minutes"
                value={minutes}
                min={0}
                max={59}
                step={1}
                onChange={(e) => handleOnChangeTime(e, MINUTES)}
                disabled={disabled}
                small
                styleForFunctionBlock={styleForFunctionBlock}
                minsContentType={!hideContent}
            />
            <InputMaterial
                label={t(EZLOGIC_TITLE_HOURS)}
                placeholder={t(EZLOGIC_TITLE_HOURS)}
                type="number"
                name="hours"
                value={hours}
                min={0}
                step={1}
                onChange={(e) => handleOnChangeTime(e, HOURS)}
                disabled={disabled}
                small
                styleForFunctionBlock={styleForFunctionBlock}
                hoursContentType={!hideContent}
            />
        </div>
    );
};

export default TimeInputsBlock;
