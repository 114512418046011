import React from 'react';
import { TextField } from '@material-ui/core';

const SimpleIntegerField = ({ min, max, getError, value, onChange, name, isRequired }) => {
    const handleChange = (e) => {
        const newValue = e.target.value;
        const integerValue = newValue !== '' ? parseInt(newValue) : newValue;
        onChange(integerValue, name, isRequired);
    };

    return (
        <TextField
            fullWidth
            step={1}
            min={min}
            max={max}
            error={!!getError()}
            helperText={getError() && getError()}
            InputProps={{
                inputProps: { autoComplete: 'new-password' },
            }}
            type="number"
            onChange={handleChange}
            value={value}
            variant="outlined"
            size="small"
        />
    );
};

export default SimpleIntegerField;
