import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';

import { buildDeviceGroupPayload } from '../../utils';
import { EZLOGIC_BUTTON_SAVE } from '../../../../../constants/language_tokens';

import { saveBtn } from './DeviceTreeSaveButton.module.scss';
import { useTranslate } from '../../../../../features/languages';

const DeviceTreeSaveButton = (props) => {
    const { isEditing } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslate();

    const serial = useSelector((state) => state.ezlo.serial);
    const deviceGroupObject = useSelector((state) => state.deviceGroups.currentDeviceGroup);
    const deviceGroupTreeState = useSelector((state) => state.deviceGroups.deviceGroupTreeState);

    const notifiAddedGroupSuccess = (response) => {
        if (isEditing) {
            toast(`"${response.result.name}" successfully updated!`, { type: TOAST_TYPE.SUCCESS });
            dispatch(DeviceGroupsActions.unsubscribeUpdatedDeviceGroup(deviceGroupObject?.serial));
        } else {
            toast(`"${response.result.name}" successfully saved!`, { type: TOAST_TYPE.SUCCESS });
            dispatch(DeviceGroupsActions.unsubscribeAddedDeviceGroup(serial));
        }
    };

    const handleSaveDeviceGroup = () => {
        if (deviceGroupObject && !isEditing) {
            dispatch(
                DeviceGroupsActions.createDeviceGroup(
                    serial,
                    buildDeviceGroupPayload(deviceGroupTreeState, deviceGroupObject.name),
                ),
            );
            dispatch(DeviceGroupsActions.subscribeAddedDeviceGroup(serial, notifiAddedGroupSuccess));
        }

        if (isEditing) {
            dispatch(
                DeviceGroupsActions.updateDeviceGroup(
                    deviceGroupObject.serial,
                    buildDeviceGroupPayload(deviceGroupTreeState, deviceGroupObject.name, deviceGroupObject.id),
                ),
            );
            dispatch(
                DeviceGroupsActions.subscribeUpdatedDeviceGroup(deviceGroupObject.serial, notifiAddedGroupSuccess),
            );
        }

        history.goBack();
        dispatch(DeviceGroupsActions.fetchDeviceGroupsList(serial));
    };

    return (
        <Button
            className={saveBtn}
            color="primary"
            variant="contained"
            disabled={!deviceGroupObject.name}
            onClick={handleSaveDeviceGroup}
        >
            {t(EZLOGIC_BUTTON_SAVE)}
        </Button>
    );
};

export default DeviceTreeSaveButton;
