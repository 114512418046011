import { toast as libToast } from 'react-toastify';
import { buildToastOptions, TOAST_TYPE } from './utils';

/**
 * Wrapper around "react-toastify" toast function.
 * More info {here: https://fkhadra.github.io/react-toastify/installation}
 * @param {React.ReactNode} content - content of toast
 * @param {Object} [options] - toast options
 * */
export const toast = (content, options) => {
    const mergedOptions = buildToastOptions(options);

    return libToast(content, mergedOptions);
};

export { default as ToastContent } from './ToastContent';
export { TOAST_TYPE };
