import React from 'react';

import styles from './styles.module.scss';
import { SpinnerIcon } from '../../assets/icons';

const AnimatedSpinner = (props) => {
    return (
        <SpinnerIcon data-testid="animatedSpinner" height={200} width={200} className={styles.loadingIcon} {...props} />
    );
};

export default AnimatedSpinner;
