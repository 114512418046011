import * as Actions from '../../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import HouseMode from './HouseMode';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).forEach((actionsName) => {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

const HouseModeConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(HouseMode);
const HouseModeWithBoundary = withErrorBoundary(HouseModeConnectedToRedux, FallbackMeshbotNodeComponent);

export { HouseModeWithBoundary as HouseMode };
