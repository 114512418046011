import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { ROWS_PER_PAGE } from '../../../EzloMeshbots/constants';
import { buildMeshBotsTableColumns, createScriptTable, listButtons } from '../../utils';
import GridTable from '../../../../../components/Table';
import './styles.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_CREATE_SCRIPT } from '../../../../../constants/language_tokens';

const ScriptList = (props) => {
    const [columns, setColumns] = useState([]);
    const [plugins, setPlugins] = useState([]);
    const { t } = useTranslate();

    const {
        type,
        serial,
        listLuaScripts,
        loaderScriptList,
        onHandlerRouteToPage,
        onHandlerRunScript,
        onConfirm,
        isActivePage,
        onHandlerRouteList,
    } = props;

    useEffect(() => {
        const translatedHeaderNameColumns = buildMeshBotsTableColumns(
            type,
            onConfirm,
            onHandlerRouteToPage,
            onHandlerRunScript,
        ).map((colData) => ({
            ...colData,
            headerName: t(colData?.headerName),
        }));

        setColumns(translatedHeaderNameColumns);
    }, [serial]);

    useEffect(() => {
        if (listLuaScripts) {
            setPlugins(createScriptTable(listLuaScripts));
        }
    }, [listLuaScripts]);

    return (
        <div className="script-list">
            <div className="script-list__header">
                <div className="script-list__buttons">
                    {listButtons.map((button) => {
                        return (
                            <Button
                                key={button.id}
                                className={button.type === isActivePage ? 'active' : ''}
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={() => onHandlerRouteList(button.type)}
                            >
                                {t(button.name)}
                            </Button>
                        );
                    })}
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className="script-list__create"
                    startIcon={<Add />}
                    onClick={() => onHandlerRouteToPage('create')}
                >
                    {t(EZLOGIC_BUTTON_CREATE_SCRIPT)}
                </Button>
            </div>
            <GridTable
                columns={columns}
                pagination={true}
                rows={plugins}
                rowsPerPageOptions={ROWS_PER_PAGE}
                disableSelectionOnClick={true}
                disableExtendRowFullWidth={false}
                loaderList={loaderScriptList && listLuaScripts === null}
            />
        </div>
    );
};

ScriptList.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    serial: PropTypes.string,
    loaderScriptList: PropTypes.bool,
    listLuaScripts: PropTypes.array,
    onConfirm: PropTypes.func,
    onHandlerRouteToPage: PropTypes.func,
};

export default ScriptList;
