import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import DeviceGroupCategorySubcategoryTitle from '../DeviceGroupCategorySubcategoryTitle';
import DeviceGroupNameInput from '../DeviceGroupNameInput';
import DeviceTreeButtonGroup from '../DeviceTreeButtonGroup';
import DeviceGroupTreeContainerPriorityOrder from '../DeviceGroupTreeContainerPriorityOrder';
import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';
import { buildDeviceGroupObject } from '../../utils';
import styles from './DeviceGroupTreeLayout.module.scss';
import useFeatureSupportChecker from '../../../../../hooks/useFeatureSupportChecker';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../../constants/ControllerFeatures';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_CONTROLLER_SUPPORT_FOR_GROUP_OPERATIONS } from '../../../../../constants/language_tokens';

const DeviceGroupTreeLayout = (props) => {
    const { isEditing } = props;
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const { currentDeviceGroup } = useSelector((state) => state.deviceGroups);
    const { t } = useTranslate();
    const isSupportsGroupOperations = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_67,
    );

    useEffect(() => {
        if (!isEditing) {
            dispatch(DeviceGroupsActions.buildDeviceGroupObject(buildDeviceGroupObject()));
        }
    }, [isEditing]);

    const handleIntegratedDevicesPageNavigation = () => {
        history.push(`${location.pathname}/integrated-devices`);
    };

    return (
        <>
            {isSupportsGroupOperations ? (
                <div className={styles.devicesTreeWrapper}>
                    <DeviceGroupNameInput deviceGroupName={currentDeviceGroup?.name} />
                    <DeviceGroupCategorySubcategoryTitle />
                    <DeviceGroupTreeContainerPriorityOrder isEditing={isEditing} />
                    <DeviceTreeButtonGroup
                        onIntegratedDevicesPageNavigation={handleIntegratedDevicesPageNavigation}
                        isEditing={isEditing}
                    />
                </div>
            ) : (
                <h2 className={styles.notification}>
                    <span>{t(EZLOGIC_TITLE_CONTROLLER_SUPPORT_FOR_GROUP_OPERATIONS)}</span>
                </h2>
            )}
        </>
    );
};

export default DeviceGroupTreeLayout;

DeviceGroupTreeLayout.propTypes = {
    isEditing: PropTypes.bool,
};
