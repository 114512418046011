import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import ConfirmDialog from '../ConfirmDialog';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_DELETE } from '../../../../../constants/language_tokens';

const DeleteConfirmDialog = ({ isDisabled = false, dialogText, confirmFn }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslate();
    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleOpen = () => {
        setIsModalVisible(true);
    };

    const handleDelete = () => {
        confirmFn();
        setIsModalVisible(false);
    };

    return (
        <>
            <IconButton disabled={isDisabled} onClick={handleOpen}>
                <Delete fontSize="large" color="primary" />
            </IconButton>

            <ConfirmDialog
                confirmFn={handleDelete}
                handleClose={handleClose}
                dialogText={dialogText}
                confirmButtonText={t(EZLOGIC_TITLE_DELETE)}
                isModalVisible={isModalVisible}
            />
        </>
    );
};

export default DeleteConfirmDialog;
