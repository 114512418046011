import { ITARIAN_SSO_TYPE } from '../../../../../services/sso/src/common/constants';

/**
 * Checks is Interaction Flow MeshBot section allowed by SSO Type
 * @param {string} ssoType - SSO Type
 * @returns {boolean} is allowed result
 * */
export const checkIsInteractionFlowMeshBotAllowedBySsoType = (ssoType) => {
    if (ssoType === ITARIAN_SSO_TYPE) {
        return false;
    }

    return true;
};

/**
 * Checks is Local MeshBot section allowed by SSO Type
 * @param {string} ssoType - SSO Type
 * @returns {boolean} is allowed result
 * */
export const checkIsLocalMeshBotAllowedBySsoType = (ssoType) => {
    if (ssoType === ITARIAN_SSO_TYPE) {
        return false;
    }

    return true;
};
