import React, { useState } from 'react';
import styles from '../container/language.module.scss';
import { useTranslate } from '../hooks';
import { updateLang } from '../utils';
import { Button } from '@material-ui/core';
import { languageService } from '../../../services/language';
import { EZLOGIC_BUTTON_SAVE } from '../../../constants/language_tokens';
import { LanguageSelector } from '../components/index';

const Languages = ({ title }) => {
    const language = languageService.getLanguageKey();
    const { t } = useTranslate();
    const [disabled, setDisabled] = useState(true);
    const [currentLang, setCurrentLang] = useState(language);

    const switchLanguage = (lang) => {
        const { value } = lang?.target;
        setCurrentLang(value);

        if (value === language) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    };

    const saveLang = () => {
        updateLang(currentLang);
        setDisabled(true);
    };

    return (
        <>
            <div className={styles.languageDropDown}>
                {title && <p>{title}</p>}
                <LanguageSelector switchLanguage={switchLanguage} currentLang={currentLang} />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    disabled={disabled}
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={saveLang}
                >
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </div>
        </>
    );
};

export default Languages;
