import React, { useState } from 'react';
import MainSettingsContent from '../../../ControllerSettings/MainSettingsContent/MainSettingsContent';
import FirmwareVersionContent from '../../../ControllerSettings/FirmwareVersionContent/FirmwareVersionContent';
import HubSettingsContent from '../../../ControllerSettings/HubSettingsContent/HubSettingsContent';
import DisableRemoveSettings from '../../../../EzloSettings/blocks/DisableRemove';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { optionsSelect } from '../../../../../../constants/MeshbotConstant';
import ControllerSettingsDialog from '../../../ControllerSettings/ControllerSettingsDialog/ControllerSettingsDialog';
import { useDispatch } from 'react-redux';
import EzloActions from '../../../../../../actions/EzloActions';

import styles from './ControllerCard.module.scss';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_TITLE_FUNCTION } from '../../../../../../constants/language_tokens';

const ControllerButtonSelect = (props) => {
    const [nameOfSettingsDialog, setNameOfSettingsDialog] = useState();
    const [isOpenSettingsDialog, setIsOpenSettingsDialog] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { isShowSettings, controllerSerial, controllerData, isControllerOnline, groupKey, group, deleteGroup } =
        props;

    const closeSettingsDialog = () => setIsOpenSettingsDialog(false);

    const settingsItems = [
        {
            anchor: 'main',
            title: 'ezlo.settings.main.title',
            isShow: isShowSettings,
            content: <MainSettingsContent controllerData={controllerData} />,
        },
        {
            anchor: 'firmware',
            title: 'ezlo.settings.firmware_version.title',
            isShow: isShowSettings,
            content: <FirmwareVersionContent controllerData={controllerData} />,
        },
        {
            anchor: 'hubSettings',
            title: 'ezlo.settings.hub-settings.title',
            isShow: isShowSettings,
            content: <HubSettingsContent controllerSerial={controllerSerial} />,
        },
        {
            anchor: 'disableRemove',
            title: 'ezlo.settings.disable_remove.title',
            isShow: true,
            content: (
                <DisableRemoveSettings
                    deleteGroup={deleteGroup}
                    isControllerOnline={isControllerOnline}
                    closeSettingsDialog={closeSettingsDialog}
                    groupKey={groupKey}
                    group={group}
                />
            ),
        },
    ];

    const handleChange = (e) => {
        dispatch(EzloActions.selectController(controllerSerial));
        setNameOfSettingsDialog(e.target.value);
        setIsOpenSettingsDialog(true);
    };

    return (
        <>
            <FormControl variant="outlined" className={styles.controller_select}>
                <Select MenuProps={optionsSelect} value={'placeholder'} onChange={handleChange}>
                    <MenuItem disabled value="placeholder">
                        {t(EZLOGIC_TITLE_FUNCTION)}
                    </MenuItem>
                    {settingsItems.map(({ anchor, title, isShow }) =>
                        isShow ? (
                            <MenuItem key={`${anchor}${controllerSerial}`} value={anchor}>
                                {t(title)}
                            </MenuItem>
                        ) : null,
                    )}
                </Select>
            </FormControl>

            {settingsItems.map(({ anchor, title, isShow, content }) =>
                isShow ? (
                    <ControllerSettingsDialog
                        key={`${title}${controllerSerial}`}
                        isOpenDialogOfSettings={isOpenSettingsDialog && nameOfSettingsDialog === anchor}
                        setIsOpenDialogOfSettings={setIsOpenSettingsDialog}
                        title={t(title)}
                    >
                        {content}
                    </ControllerSettingsDialog>
                ) : null,
            )}
        </>
    );
};

export default ControllerButtonSelect;
