import React, { useEffect, useState } from 'react';

import { DEVICE_ON, DEVICE_OFF, BLOCK_FIELD_TYPES } from '../../constants/MeshbotConstant';

import styles from './DeviceCurrentSetting.module.scss';
import { useTranslate } from '../../features/languages';
import {
    EZLOGIC_TITLE_ARMED,
    EZLOGIC_TITLE_CURRENT_VALUE,
    EZLOGIC_TITLE_CURRENT_VALUE_BOOLEAN,
    EZLOGIC_TITLE_DISARMED,
} from '../../constants/language_tokens';

const DeviceCurrentSetting = ({ blocks, items, idDev, firstBlock, currentDevice }) => {
    const [itemIndex, setItemIndex] = useState('');
    const [isDeviceItem, setIsDeviceItem] = useState(false);
    const { t } = useTranslate();

    useEffect(() => {
        if (blocks && blocks[0] && firstBlock && firstBlock !== '') {
            let id;
            if (blocks[0] && blocks[0].blockId) {
                id = blocks.find((item) => item.blockId === firstBlock).fields[0].value;
            }

            if (blocks[0] && blocks[0]._tempId) {
                id = blocks.find((item) => item._tempId === firstBlock).fields[0].value;
            }
            const itemName = items.find((item) => item._id === id)?.name;
            if (!itemName) {
                setIsDeviceItem(true);
            }

            if (itemName) {
                setIsDeviceItem(false);
                let itemIndex;
                if (
                    itemName.search('_up') !== -1 ||
                    itemName.search('_down') !== -1 ||
                    itemName.search('_stop') !== -1
                ) {
                    const cutSubOption = itemName.split('_').shift();
                    itemIndex = items.findIndex((item) => item.name === cutSubOption && item.deviceId === idDev);
                } else {
                    itemIndex = items.findIndex((item) => item._id === id);
                }
                setItemIndex(itemIndex);
            } else {
                setItemIndex(id);
            }
        } else {
            setItemIndex('');
        }
    }, [blocks, firstBlock]);

    const getNumberValue = (number) => {
        if (Number.isInteger(number)) {
            return number;
        }

        return number.toFixed(1);
    };

    const getTypeValue = (type) => {
        if (type === 'bool') {
            return '(boolean)';
        }

        if (type === 'int') {
            return '(integer)';
        }

        if (type === 'token') {
            return '(string)';
        } else {
            return `(${type})`;
        }
    };

    const getCurrentValue = (items, itemIndex) => {
        if ((items && itemIndex === 0) || (items && itemIndex !== '')) {
            let currentValue = '';
            if (isDeviceItem) {
                currentValue = '';
            } else {
                if (items[itemIndex]?.value === true) {
                    currentValue = DEVICE_ON;
                } else if (items[itemIndex].value === false) {
                    currentValue = DEVICE_OFF;
                } else if (items[itemIndex].value && items[itemIndex].value.action) {
                    currentValue = items[itemIndex].value.action;
                } else if (
                    (items[itemIndex].value &&
                        items[itemIndex].value &&
                        items[itemIndex].value.hasOwnProperty('blue')) ||
                    items[itemIndex].valueType === BLOCK_FIELD_TYPES.RGB
                ) {
                    currentValue = items[itemIndex].valueFormatted;
                } else if (items[itemIndex].value && items[itemIndex].value.button_number) {
                    currentValue = items[itemIndex].value.button_number;
                } else if (items[itemIndex].valueType === BLOCK_FIELD_TYPES.RGB) {
                    currentValue = items[itemIndex].valueFormatted;
                } else if (items[itemIndex].valueType === BLOCK_FIELD_TYPES.DICTIONARY) {
                    currentValue = items[itemIndex].valueType;
                } else {
                    currentValue =
                        typeof items[itemIndex].value === 'number'
                            ? getNumberValue(items[itemIndex].value)
                            : items[itemIndex].value;
                }
            }

            const device = currentDevice.find((item) => item._id === itemIndex);

            return !isDeviceItem ? (
                <div className={styles.items}>
                    <span>
                        {t(EZLOGIC_TITLE_CURRENT_VALUE)} {getTypeValue(items[itemIndex].valueType)}:{' '}
                    </span>
                    <span>
                        {currentValue} {items[itemIndex] && items[itemIndex].scale ? items[itemIndex].scale : null}
                    </span>
                </div>
            ) : (
                <div className={styles.items}>
                    <span>{t(EZLOGIC_TITLE_CURRENT_VALUE_BOOLEAN)}</span>
                    <span>
                        : {device && device.armed === true ? t(EZLOGIC_TITLE_ARMED) : t(EZLOGIC_TITLE_DISARMED)}
                    </span>
                </div>
            );
        }
    };

    return <>{getCurrentValue(items, itemIndex)}</>;
};

export default DeviceCurrentSetting;
