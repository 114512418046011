import React, { useState } from 'react';
import { isObject } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

import { Tooltip } from '../../../../../components';

import styles from './styles.module.scss';

const DeviceSettingInfo = ({ titles }) => {
    const [isShowTooltip, setShowTooltip] = useState(false);

    const showTooltip = () => {
        setShowTooltip(true);
    };

    const hideTooltip = () => {
        setShowTooltip(false);
    };

    return (
        <div className={styles.iconTooltipBox} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {isObject(titles) && <InfoIcon role="infoIcon" color="action" />}
            <Tooltip
                show={isShowTooltip}
                className={styles.tooltipBox}
                position="right"
                hideAfterClick={true}
                onHide={hideTooltip}
            >
                <div>{isObject(titles) && Object.values(titles).map((value) => <p key={value}>{value}</p>)}</div>
            </Tooltip>
        </div>
    );
};

export default DeviceSettingInfo;
