import OEMConfigContainer from '../OEMConfigContainer/OEMConfigContainer';
import AppConfigContainer from '../AppConfigContainer/AppConfigContainer';
import { readApplicationConfig } from './utils/config';

/**
 * @class OEMManager
 * Used to manage OEM Configs.
 * Available OEMs:
 * - Vera OEM: https://confluence.mios.com/display/EDOT/Vera+Environment
 * - Security OEM: https://confluence.mios.com/display/EDOT/Ezlo+Security+Oem
 * - Property OEM: https://confluence.mios.com/display/EDOT/Ezlo+Property+Oem
 * - Horus OEM: https://confluence.mios.com/display/EDOT/Horus
 * */
export default class OEMManager {
    /**
     * Unique environment identifier.
     * @example
     * "vera", "vera-qa", "vera-stg".
     * */
    #environmentKey;
    #appConfig;

    constructor() {
        /**
         * Used as OEM config, keeps specific OEM and ENV values
         * */
        this.oem = new OEMConfigContainer();
        /**
         * Contains whole application config
         * TODO: it could be moved to external service, but no need for now as no other options and methods needed
         * */
        this.#appConfig = new AppConfigContainer();
    }

    #setEnvironmentKey(key) {
        this.#environmentKey = key;
    }

    #isValidEnvironmentKey(key) {
        const envs = this.#appConfig.getEnvironments();
        const value = envs[key];

        return !!value;
    }

    #isCurrentEnvironmentKey(key) {
        return key === this.#environmentKey;
    }

    get appConfig() {
        return this.#appConfig;
    }

    getEnvironmentKey() {
        return this.#environmentKey;
    }

    /**
     * Initialize OEM Manager instance.
     * */
    async initialize() {
        const appConfig = await readApplicationConfig();
        this.#appConfig.setConfig(appConfig);
        const [envKey, config] = this.#appConfig.getDefaultEnvironmentConfigEntry();

        this.#setEnvironmentKey(envKey);
        this.oem.setConfig(config);
    }

    /**
     * Set an environment config by key
     * @param {string} key - environment key from config.json file, example: "vera", "vera-qa" ...
     * @return {undefined}
     * */
    setEnvironmentByKey(key) {
        if (this.#isCurrentEnvironmentKey(key)) {
            return;
        }

        if (!this.#isValidEnvironmentKey(key)) {
            return;
        }

        // extract environment config by key
        const envConfig = this.#appConfig.getEnvironmentConfigByKey(key);
        this.#setEnvironmentKey(key);
        this.oem.setConfig(envConfig);
    }
}
