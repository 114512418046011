import React from 'react';

const ErrorIcon = (props) => (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M100.001 148.002C126.511 148.002 148.001 126.511 148.001 100.002C148.001 73.492 126.511 52.0017 100.001 52.0017C73.4918 52.0017 52.0015 73.492 52.0015 100.002C52.0015 126.511 73.4918 148.002 100.001 148.002Z"
            stroke="#FE5C15"
            strokeWidth="16"
            strokeMiterlimit="10"
        />
        <path d="M150.913 49.0901L49.0898 150.913" stroke="#FE5C15" strokeWidth="16" strokeMiterlimit="10" />
        <path
            d="M133.378 77.9391C136.653 82.8709 138.772 88.4514 139.601 94.3436L152.771 81.1739C150.902 75.9228 148.24 70.9726 144.854 66.4639L133.378 77.9391Z"
            fill="#E82E10"
        />
        <path
            d="M94.3433 139.601C88.4512 138.772 82.8708 136.653 77.9391 133.378L66.4639 144.853C70.9726 148.24 75.9227 150.902 81.1737 152.771L94.3433 139.601Z"
            fill="#E82E10"
        />
    </svg>
);

export default ErrorIcon;
