import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import CloudVariableSelect from '../../CloudVariablesSelect';
import useCloudVariableCurrentValue from '../../../../../../hooks/useCloudVariableCurrentValue';

import IntegrationsActions from '../../../../../../actions/IntegrationsActions';
import { CloudMeshbotActions } from '../../../../../../actions';
import { CLOUD_VARIABLE_FILTERS, COMPARED_CLOUD_VARIABLE, DEVICE_STATE, CLOUD_VARIABLES } from '../../../constants';

/**
 * Container component for cloud variables' select in cloud meshbot triggers. Whose data is used for comparison.
 * It collects and provides data for the select, also provides a function to change this data.
 */
const ComparedCloudVariableSelectForCloudMeshBot = ({ ruleCloudTriggerId, ruleCloudTrigger }) => {
    const dispatch = useDispatch();
    const { selectedVariableCompared, selectedAbstractForCompared, selectedFieldTrigger, variableValueType } =
        ruleCloudTrigger;
    const currentValueData = useCloudVariableCurrentValue(selectedAbstractForCompared?.uuid, selectedVariableCompared);

    const filterType = useMemo(
        () => ({ key: CLOUD_VARIABLE_FILTERS.VARIABLE_TYPE.KEY, value: variableValueType }),
        [variableValueType],
    );

    useEffect(() => {
        if (selectedAbstractForCompared && selectedVariableCompared && ruleCloudTriggerId) {
            dispatch(
                IntegrationsActions.setCloudVariableCurrentValue(
                    selectedAbstractForCompared.uuid,
                    selectedVariableCompared,
                ),
            );
        }
    }, []);

    const updateSelectValue = (variableData) => {
        dispatch(
            CloudMeshbotActions.updateCloudVariableInTrigger(variableData, ruleCloudTriggerId, COMPARED_CLOUD_VARIABLE),
        );
        if (selectedFieldTrigger === DEVICE_STATE) {
            dispatch(CloudMeshbotActions.updateCloudTrigger(variableData, ruleCloudTriggerId, null, CLOUD_VARIABLES));
        }
    };

    return (
        <CloudVariableSelect
            selectedVariable={selectedVariableCompared}
            selectedAbstract={selectedAbstractForCompared}
            updateSelectValue={updateSelectValue}
            currentValueData={currentValueData}
            filterType={filterType}
        />
    );
};

export default ComparedCloudVariableSelectForCloudMeshBot;
