import React from 'react';

import withDraggingState from '../HOC/withDraggingState';

import styles from '../styles.module.scss';

const TreeItemLabel = ({ elementData, treeItemName, treeItemValue, handleDragStart, handleDragEnd }) => {
    return (
        <div
            className={styles.treeItemLabelBox}
            draggable={true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            data-element={elementData}
        >
            {treeItemName}
            <span className={styles.elementType}>: {treeItemValue?.type}</span>
        </div>
    );
};

export default withDraggingState(TreeItemLabel);
