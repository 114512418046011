import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import {
    NO_STREAM,
    SELECTED_ELEMENT_INDEX,
    SELECTED_ELEMENT_VALUE_INDEX,
    DELAY,
} from '../../../../../../constants/Devices';

import styles from './SettingsContent.module.scss';

const SettingsContent = ({ element }) => {
    const [copied, setCopied] = useState(false);

    const hideCopy = () => {
        setCopied(false);
    };

    const showCopy = () => {
        setCopied(true);
        setTimeout(hideCopy, [DELAY]);
    };

    return (
        <p className={styles.container}>
            <span className={styles.text}>{element[SELECTED_ELEMENT_INDEX]}:</span>
            <span className={styles.text}>
                {element[SELECTED_ELEMENT_VALUE_INDEX] ? element[SELECTED_ELEMENT_VALUE_INDEX] : NO_STREAM}
            </span>
            <CopyToClipboard text={element[SELECTED_ELEMENT_VALUE_INDEX]} onCopy={showCopy}>
                <IconButton className={styles.iconButton} disabled={!element[SELECTED_ELEMENT_VALUE_INDEX]}>
                    <FileCopyOutlined />
                </IconButton>
            </CopyToClipboard>
            {copied ? <span className={styles.marker}>Copied</span> : null}
        </p>
    );
};

export default SettingsContent;

SettingsContent.propTypes = {
    element: PropTypes.array,
};
