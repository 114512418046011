import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classes from 'classnames';

import BuilderHeader from '../../../components/BuilderHeader';
import BuilderFooter from '../../../MeshBot/BuilderFooter';
import GroupBlock from '../../../components/groupBlock';
import { CloudTriggerBlock } from '../../../components/CloudTriggerBlock';
import MeshBotSection from '../../../MeshBot/MeshBotSection';
import MeshBotSectionCaption from '../../../components/MeshBotSectionCaption';
import templateBlock from '../../../../../../components/blockCloudTriggerTemplate';

import at from '../../../../../../constants/ActionTypes/MeshBot';
import hash from '../../../../../../constants/uniqueHash';
import { getNameCapability } from '../../../../../../helpers/helpersMeshBot';
import {
    returnDataForBlocksInCloudDevice,
    returnDataForUpdateTriggerNameBlock,
    getDataForBlocksInCloudDevice,
    checkForSingleOnChangeTrigger,
    determineToastMessageAccordingToTriggerNodeType,
} from '../../../../utils';
import { AND, MESHBOT_NODE_TYPES } from '../../../../../../constants/MeshbotConstant';
import { MESHBOT_SECTION_TYPE } from '../../../constants';
import { OPERATOR_TYPE } from '../../../../../../constants/MeshbotConstant';
import {
    mesh_bot__builder,
    mesh_bot__builder_trigger,
    mesh_bot__list_triggers,
} from '../../../EzloMeshbot.module.scss';
import { activeConnection } from '../../../../EzloMeshbot/components/TriggerBlock.module.scss';
import { CLOUD_MESHBOT } from '../../../../EzloMeshbots/constants';
import { toast, TOAST_TYPE } from '../../../../../../components/Toast';
import { useTranslate } from '../../../../../../features/languages';

export const CloudMeshbotFormTriggers = (props) => {
    const {
        listCapabilities,
        abstractDeviceTriggers,
        ruleCloudTriggers,
        CloudMeshbotActions,
        currentScene,
        notificationTemplatePage,
        notificationType,
        isSystemNotificationTemplate,
        parentTriggerOptionType,
    } = props;
    const dispatch = useDispatch();

    const [listCloudTriggers, setListCloudTriggers] = useState([]);
    const { t } = useTranslate();

    useEffect(() => {
        if (ruleCloudTriggers !== listCloudTriggers) {
            setListCloudTriggers(ruleCloudTriggers);
        }
    }, [ruleCloudTriggers]);

    const handleChangeBooleanOperator = (e, id, field, idGroup) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateCloudTrigger(e.target.value, id, idGroup, field));
        } else {
            CloudMeshbotActions.updateCloudTrigger(e.target.value, id, idGroup, field);
        }
        // TODO add logic for change boolean operator (OR, NOT XOR) to cloud meshbot
    };

    const checkedParentOperatorRadio = (value) => {
        if (notificationTemplatePage) {
            return dispatch(CloudMeshbotActions.updateSelectedParentOperator(value, null, OPERATOR_TYPE));
        }

        if (!checkForSingleOnChangeTrigger(ruleCloudTriggers, value)) {
            return toast(t(determineToastMessageAccordingToTriggerNodeType(ruleCloudTriggers)), {
                type: TOAST_TYPE.WARNING,
            });
        }
        CloudMeshbotActions.updateSelectedParentOperator(value, null, OPERATOR_TYPE);
    };

    const updateDateTriggersCloud = (value, id, type, idGroup, parameterType) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateDateTriggersCloud(id, value, type, idGroup, parameterType));
        } else {
            CloudMeshbotActions.updateDateTriggersCloud(id, value, type, idGroup, parameterType);
        }
    };

    const selectedFieldDate = (e, id, idGroup, operator) => {
        const block = templateBlock(operator, null, null, e.target.value);
        const data = {
            selectedFieldDate: e.target.value,
            blocks: [block],
        };

        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateCloudTrigger(data, id, idGroup));
        } else {
            CloudMeshbotActions.updateCloudTrigger(data, id, idGroup);
        }
    };

    const getBlocksInDevice = (device, id, operator, idGroup) => {
        if (notificationTemplatePage) {
            dispatch(
                CloudMeshbotActions.updateCloudTrigger(
                    returnDataForBlocksInCloudDevice(listCapabilities, operator),
                    id,
                    idGroup,
                    at.NOTIFICATION_TEMPLATE,
                ),
            );
        } else {
            CloudMeshbotActions.updateCloudTrigger(
                getDataForBlocksInCloudDevice(abstractDeviceTriggers, device, listCapabilities, operator),
                id,
                idGroup,
                'cloudDevice',
            );
        }
    };

    const updateTriggerForNuCAL = (cloudTriggerCreationData, cloudTriggerNucalSubscriptionData, id, field) => {
        if (field === MESHBOT_NODE_TYPES.PAAS) {
            CloudMeshbotActions.updateCloudTriggerForNuCAL(
                cloudTriggerCreationData,
                cloudTriggerNucalSubscriptionData,
                id,
                field,
            );
        }
    };

    const updateCloudVariablesTriggerBlocks = (data, field, id) => {
        if (field === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
            CloudMeshbotActions.updateCloudVariablesTriggerBlocks(data, field, id);
        }
    };

    const updateCloudRequiredFieldTrigger = (id, required) => {
        CloudMeshbotActions.updateCloudRequiredTrigger(id, required);
    };

    const updateCloudVariablesTrigger = (data, field, id) => {
        if (field === MESHBOT_NODE_TYPES.CLOUD_VARIABLES) {
            CloudMeshbotActions.updateCloudVariablesTrigger(data, field, id);
        }
    };

    const deleteTrigger = (id, idGroup) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.deleteCloudTrigger(id, idGroup));
        } else {
            CloudMeshbotActions.deleteCloudTrigger(id, idGroup);
        }
    };

    const addTrigger = (type, idGroup, specialType) => {
        let data = {};

        if (type === at.TRIGGER) {
            data = { id: hash(), blocks: [{}], not: false, isValid: false, isEdit: false };
        }

        if (type === at.GROUP) {
            data = {
                id: hash(),
                not: false,
                blockName: '',
                type: at.GROUP,
                optionType: AND,
                blocks: [
                    { id: hash(), blocks: [{}], not: false },
                    { id: hash(), blocks: [{}], not: false },
                ],
                isValid: false,
                isEdit: false,
            };
        }

        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.addCloudTrigger(data, idGroup, specialType));
        } else {
            CloudMeshbotActions.addCloudTrigger(data, idGroup, specialType);
        }
    };

    const updateTriggerNameBlock = (value, currentItem, field, idGroup, capabilities, isValidRgbValue) => {
        if (notificationTemplatePage) {
            dispatch(
                CloudMeshbotActions.updateCloudTrigger(
                    returnDataForUpdateTriggerNameBlock(
                        listCapabilities,
                        currentItem,
                        idGroup,
                        abstractDeviceTriggers,
                        field,
                        value,
                        CLOUD_MESHBOT,
                        capabilities,
                        isValidRgbValue,
                    ),
                    currentItem.id,
                    idGroup,
                    field,
                    CLOUD_MESHBOT,
                ),
            );
        } else {
            CloudMeshbotActions.updateCloudTrigger(
                returnDataForUpdateTriggerNameBlock(
                    listCapabilities,
                    currentItem,
                    idGroup,
                    abstractDeviceTriggers,
                    field,
                    value,
                    CLOUD_MESHBOT,
                    capabilities,
                    isValidRgbValue,
                ),
                currentItem.id,
                idGroup,
                field,
                CLOUD_MESHBOT,
            );
        }
    };

    const validationCheck = (props, id) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.validateCloudTrigger(props, id));
        } else {
            CloudMeshbotActions.validateCloudTrigger(props, id);
        }
    };

    const validationCheckEdit = (props, id) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.validateCloudTriggerEdit(props, id));
        } else {
            CloudMeshbotActions.validateCloudTriggerEdit(props, id);
        }
    };

    const updateTriggerNot = (e, id, field, idGroup) => {
        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateCloudTrigger(e.target.checked, id, idGroup, field));
        } else {
            CloudMeshbotActions.updateCloudTrigger(e.target.checked, id, idGroup, field);
        }
    };

    const updateTrigger = (e, id, field, idGroup) => {
        let data = {};
        const { value } = e.target;

        if (e.target.value === 'deviceState') {
            data = { selectedOperator: '==', selectedFieldTrigger: value };
        }

        if (e.target.value === 'dataAndTime') {
            data = { selectedOperator: 'schedule', selectedFieldTrigger: value };
        }

        if (field === 'selectedOperator') {
            data = { selectedOperator: value };
        }

        if (field !== 'selectedOperator') {
            if (notificationTemplatePage) {
                dispatch(CloudMeshbotActions.clearTrigger(id, idGroup));
            } else {
                CloudMeshbotActions.clearTrigger(id, idGroup);
            }
        }

        if (field === 'selectedFieldTrigger') {
            if (notificationTemplatePage) {
                dispatch(CloudMeshbotActions.updateCloudTrigger(value, id, idGroup, field));
            }

            if (!checkForSingleOnChangeTrigger(ruleCloudTriggers, value, parentTriggerOptionType)) {
                return toast(t(determineToastMessageAccordingToTriggerNodeType(ruleCloudTriggers, value)), {
                    type: TOAST_TYPE.WARNING,
                });
            }
            CloudMeshbotActions.updateCloudTrigger(value, id, idGroup, field);
        }

        if (notificationTemplatePage) {
            dispatch(CloudMeshbotActions.updateCloudTrigger(data, id, idGroup, field));
        } else {
            CloudMeshbotActions.updateCloudTrigger(data, id, idGroup, field);
        }
    };

    const className = classes(mesh_bot__list_triggers, { [activeConnection]: listCloudTriggers.length > 1 });

    return (
        <MeshBotSection withMargin>
            <MeshBotSectionCaption type={MESHBOT_SECTION_TYPE.TRIGGER} />
            <div className={`${mesh_bot__builder} ${mesh_bot__builder_trigger}`}>
                <BuilderHeader
                    type={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION}
                    meshbotType={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION}
                    typePage={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION}
                    optionType={parentTriggerOptionType}
                    onCheckedOperatorRadio={checkedParentOperatorRadio}
                    listRuleTriggers={listCloudTriggers}
                />
                <div className={className}>
                    {listCloudTriggers.map((item, index) => {
                        if (item.type === at.GROUP) {
                            return (
                                <GroupBlock
                                    key={!notificationTemplatePage ? item.id : index}
                                    idGroup={!notificationTemplatePage ? item.id : index}
                                    type={item.type}
                                    typeMeshbot={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION}
                                    {...item}
                                    listCapabilities={listCapabilities}
                                    currentDeviceSelect={abstractDeviceTriggers}
                                    onAddTrigger={addTrigger}
                                    onUpdateTriggerType={handleChangeBooleanOperator}
                                    onUpdateTriggerNot={updateTriggerNot}
                                    updateTriggerNameBlock={updateTriggerNameBlock}
                                    onUpdateTrigger={updateTrigger}
                                    DeleteTrigger={deleteTrigger}
                                    onSelected={getBlocksInDevice}
                                    onSelectedDate={selectedFieldDate}
                                    onGetNameCapability={getNameCapability}
                                    onUpdateCloudTriggerBlock={CloudMeshbotActions.updateCloudTriggerBlock}
                                    onUpdateDateTriggersCloud={updateDateTriggersCloud}
                                    notificationTemplate={notificationTemplatePage}
                                    ruleCloudTriggers={ruleCloudTriggers}
                                    currentScene={currentScene}
                                    CloudMeshbotActions={CloudMeshbotActions}
                                />
                            );
                        }

                        return (
                            <CloudTriggerBlock
                                key={!notificationTemplatePage ? item.id : index}
                                id={!notificationTemplatePage ? item.id : index}
                                typeMeshbot={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION_TEMPLATE}
                                currentItem={item}
                                {...item}
                                currentDeviceSelect={abstractDeviceTriggers}
                                onUpdateTriggerNot={updateTriggerNot}
                                onUpdateTrigger={updateTrigger}
                                onUpdateTriggerForNuCAL={updateTriggerForNuCAL}
                                updateTriggerNameBlock={updateTriggerNameBlock}
                                DeleteTrigger={deleteTrigger}
                                onSelected={getBlocksInDevice}
                                onSelectedDate={selectedFieldDate}
                                onGetNameCapability={getNameCapability}
                                onUpdateCloudTriggerBlock={CloudMeshbotActions.updateCloudTriggerBlock}
                                onUpdateDateTriggersCloud={updateDateTriggersCloud}
                                ruleCloudTriggers={ruleCloudTriggers}
                                currentScene={currentScene}
                                notificationTemplate={notificationTemplatePage}
                                listCapabilities={listCapabilities}
                                notificationType={notificationType}
                                listRuleTriggers={listCloudTriggers}
                                updateValidation={validationCheck}
                                validationCheckEdit={validationCheckEdit}
                                updateCloudVariablesTrigger={updateCloudVariablesTrigger}
                                updateCloudVariablesTriggerBlocks={updateCloudVariablesTriggerBlocks}
                                updateCloudRequiredFieldTrigger={updateCloudRequiredFieldTrigger}
                                optionType={parentTriggerOptionType}
                            />
                        );
                    })}
                </div>

                <BuilderFooter
                    meshbotType={!notificationTemplatePage ? at.CLOUD : at.NOTIFICATION}
                    type={MESHBOT_SECTION_TYPE.TRIGGER}
                    onAddTrigger={addTrigger}
                    listCloudTriggers={listCloudTriggers}
                    disabled={isSystemNotificationTemplate}
                />
            </div>
        </MeshBotSection>
    );
};
