import * as yup from 'yup';
import { getIsValidPhoneNumber } from '../utils';

export const validationSchema = yup.object({
    FirstName: yup.string().required('First Name is required.'),
    Name: yup.string().required('Last Name is required.'),
    Username: yup.string().required('User Name is required.'),
    PK_PermissionRole: yup.object().required('Account Type is required.'),
    Email: yup.string().email('Enter a valid Email.').required('Email is required.'),
    Password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
            'At least one uppercase, lowercase, number and non-alphabetic character',
        )
        .required('Password is required.'),
    ConfirmPassword: yup
        .string()
        .oneOf([yup.ref('Password'), null], 'Passwords must match.')
        .required('Confirm Password is required.'),
});

export const editValidationSchema = (oemId) =>
    yup.object({
        FirstName: yup.string().required('First Name is required.'),
        Name: yup.string().required('Last Name is required.'),
        PK_PermissionRole: yup.object().required('Account Type is required.'),
        Email: yup.string().email('Enter a valid Email.'),
        Phone: yup.string().test({
            message: 'Phone number is not valid',
            test: (phoneNumber) => {
                if (phoneNumber) {
                    return getIsValidPhoneNumber(phoneNumber, oemId);
                }

                return true;
            },
        }),
    });
