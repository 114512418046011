import React from 'react';
import { FormControl, MenuItem } from '@material-ui/core';

import SelectComponent from '../../SelectComponent';

import style from '../CloudVariableForTrigger.module.scss';
import { VALUE_TYPES_FOR_CLOUD_VARIABLE } from '../../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../../features/languages';
import { EZLOGIC_LABEL_VALUE_TYPE } from '../../../../../../constants/language_tokens';

export const SelectValueType = ({ onDataUpdate, selectedValueType }) => {
    const handleSelectValue = (item) => {
        onDataUpdate({
            selectedValueType: item,
            comparingValue: '',
        });
    };
    const { t } = useTranslate();

    return (
        <div className={style.field_container}>
            <FormControl className={style.select}>
                <SelectComponent
                    value={selectedValueType}
                    label={t(EZLOGIC_LABEL_VALUE_TYPE)}
                    onChange={(e) => handleSelectValue(e.target.value)}
                >
                    {VALUE_TYPES_FOR_CLOUD_VARIABLE.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                <div className={style.list}>{t(item.name)}</div>
                            </MenuItem>
                        );
                    })}
                </SelectComponent>
            </FormControl>
        </div>
    );
};
