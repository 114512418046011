import React from 'react';

import { Button, LeftPanel, Spinner } from '../../../../components';
import {
    EZLOGIC_BUTTON_CREATE_BACKUP,
    EZLOGIC_TITLE_BACKUP_TIME_MESSAGE,
    EZLOGIC_TITLE_BACKUPS,
} from '../../../../constants/language_tokens';
import ControllersDropdown from '../ControllersDropdown';
import { formatBackupTimestamp } from '../utils';
import BackupItem from '../SectionContent/BackupItem';
import { useTranslate } from '../../../../features/languages';
import { withBackups } from '../../../../components/HOCs/withBackups';
import { PlusButtonIcon } from '../../../../assets/icons';
import { isEmpty } from 'lodash';

const BackupsLeftPanel = (props) => {
    const {
        isBackupsLoading,
        isBackupSupported,
        timeOffset,
        selectedBackup,
        handleOpenCreateBackupModal,
        handleBackupClick,
        handleSelectController,
        handleGetStatusController,
        currentController,
        isCurrentControllerConnected,
        controllersList,
        serial,
        selectedControllerBackups,
    } = props;
    const showSpinner = isBackupsLoading && isBackupSupported;
    const showList = !isBackupsLoading && isBackupSupported && !isEmpty(selectedControllerBackups);
    const { t } = useTranslate();

    return (
        <LeftPanel>
            <span className="title">{t(EZLOGIC_TITLE_BACKUPS)}</span>
            <span className="text">{t(EZLOGIC_TITLE_BACKUP_TIME_MESSAGE)}</span>
            <ControllersDropdown
                controllersList={controllersList}
                currentController={currentController}
                onSelectController={handleSelectController}
                onGetStatusController={handleGetStatusController}
                ezloSerial={serial}
            />
            <Button
                variant="contained"
                color="primary"
                className="secondary-btn create-backup"
                onClick={handleOpenCreateBackupModal}
                disabled={!isCurrentControllerConnected}
            >
                <PlusButtonIcon />
                <span>{t(EZLOGIC_BUTTON_CREATE_BACKUP)}</span>
            </Button>
            {showSpinner && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}

            {showList && (
                <ul className="backup-list">
                    {selectedControllerBackups.map((backup) => (
                        <BackupItem
                            key={backup.uuid}
                            uuid={backup.uuid}
                            timestamp={backup.timestamp}
                            timeOffset={timeOffset}
                            formatBackupTimestamp={formatBackupTimestamp}
                            onBackupClick={handleBackupClick}
                            selectedBackupId={selectedBackup?.uuid}
                        />
                    ))}
                </ul>
            )}
        </LeftPanel>
    );
};

export default withBackups(BackupsLeftPanel);
