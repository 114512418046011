import { useCallback, useMemo, useState } from 'react';
import { toggleSelectedImgUuids } from '../utils';

const useSelectedImagesState = () => {
    const [selectedImgUuids, setSelectedImgUuids] = useState([]);

    const toggleClickImg = useCallback(
        (uuid) => {
            setSelectedImgUuids((selectedImgUuids) => toggleSelectedImgUuids(uuid, selectedImgUuids));
        },
        [setSelectedImgUuids],
    );

    const clearSelectedImgList = () => setSelectedImgUuids([]);
    const isSelectedImg = useMemo(() => Boolean(selectedImgUuids.length), [selectedImgUuids]);

    return { toggleClickImg, selectedImgUuids, clearSelectedImgList, isSelectedImg };
};

export default useSelectedImagesState;
