import React from 'react';
import './style.scss';
import DynamicIntegerField from '../../components/DynamicFields/DynamicIntegerField/DynamicIntegerField';
import DynamicNumberField from '../../components/DynamicFields/DynamicNumberField/DynamicNumberField';
import DynamicStringField from '../../components/DynamicFields/DynamicStringField/DynamicStringField';
import SimpleBooleanField from '../../components/DynamicFields/SimpleBooleanField/SimpleBooleanField';
import { DYNAMIC_FIELDS_TYPES } from '../../constants/DynamicUI';

export const DynamicFields = ({ inputs, onChange, values }) => {
    return inputs.map((input) => {
        const fieldValue = values[input.name] === 0 ? 0 : values[input.name] || '';

        return (
            <div key={`${input.name}${input.type}`} className="dynamic-field-wrapper">
                {input.description && <div className="dynamic-field__description">{input.description}</div>}

                <div className="dynamic-field-block">
                    <div className="dynamic-field-block_label">
                        {input.name} {input.required && <span>*</span>}
                    </div>

                    <div className="dynamic-field-block_input">
                        {input.type === DYNAMIC_FIELDS_TYPES.string && (
                            <DynamicStringField
                                onChange={onChange}
                                value={values[input.name] || ''}
                                input={input}
                                name={input.name}
                                className="dynamic-string-field-block"
                            />
                        )}
                        {input.type === DYNAMIC_FIELDS_TYPES.integer && (
                            <DynamicIntegerField
                                onChange={onChange}
                                value={fieldValue}
                                input={input}
                                name={input.name}
                                className="dynamic-integer-field-block"
                            />
                        )}
                        {input.type === DYNAMIC_FIELDS_TYPES.number && (
                            <DynamicNumberField
                                onChange={onChange}
                                input={input}
                                value={fieldValue}
                                name={input.name}
                                className="dynamic-number-field-block"
                            />
                        )}
                        {input.type === DYNAMIC_FIELDS_TYPES.boolean && (
                            <SimpleBooleanField
                                onChange={onChange}
                                value={values[input.name] || input.default || false}
                                name={input.name}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    });
};

export default DynamicFields;
