import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import { EZLOGIC_TITLE_RECORDING_LENGTH, EZLOGIC_TITLE_SECS } from '../../../../../constants/language_tokens';
import { MUI_PROPS } from '../../../../../constants/DevicesGroups';
import { INPUT_PROPS, KEY_NAME } from '../../../../../constants/MeshbotConstant';
import { useTranslate } from '../../../../../features/languages';
import styles from './InputRecordingLengthValue.module.scss';

const InputRecordingLengthValue = (props) => {
    const { onUpdateFieldActionBlock, value } = props;
    const { t } = useTranslate();

    const handleKeyDown = (event) => {
        const key = event?.key;

        if (key !== KEY_NAME) {
            event.preventDefault();
        }
    };

    return (
        <>
            <TextField
                label={t(EZLOGIC_TITLE_RECORDING_LENGTH)}
                type={MUI_PROPS.NUMBER}
                value={value}
                inputProps={INPUT_PROPS}
                onKeyDown={handleKeyDown}
                onChange={onUpdateFieldActionBlock}
            />
            <span className={styles.unitTime}>{t(EZLOGIC_TITLE_SECS)}</span>
        </>
    );
};

export default InputRecordingLengthValue;

InputRecordingLengthValue.propTypes = {
    value: PropTypes.number,
    onUpdateFieldActionBlock: PropTypes.func,
};
