import React from 'react';

import PreviewImages from './PreviewImages';
import ImagesValidInfo from './ImagesValidInfo';
import DropzoneBox from './DropzoneBox';

import styles from './styles.module.scss';

function DropzoneSection({ getInputProps, getRootProps, open, filesData, removeFile, notValidImgNames }) {
    return (
        <div className={styles.container}>
            <DropzoneBox getInputProps={getInputProps} getRootProps={getRootProps} open={open} />

            <aside className={styles.thumbsContainer}>
                <PreviewImages previewFiles={filesData} removeFile={removeFile} />
            </aside>

            <ImagesValidInfo notValidImgNames={notValidImgNames} />
        </div>
    );
}

export default DropzoneSection;
