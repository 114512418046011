import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import InfoIcon from '../../containers/Ezlo/EzloHubBackups/icons/InfoIcon';
import { dialogWrap, infoIconWrap, closeIconWrap } from './ActionConfirmDialog.module.scss';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_BUTTON_CANCEL, EZLOGIC_BUTTON_CONTINUE } from '../../constants/language_tokens';

const ActionConfirmDialog = (props) => {
    const { isModalVisible, onCancel, continueMeshBot, discardMeshBot, title, content } = props;
    const { t } = useTranslate();

    return (
        <Dialog
            className={dialogWrap}
            open={isModalVisible}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={closeIconWrap}>
                <IconButton onClick={continueMeshBot}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={infoIconWrap}>
                <InfoIcon />
            </div>
            <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{t(content)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={continueMeshBot} size="large" variant="contained">
                    {t(EZLOGIC_BUTTON_CANCEL)}
                </Button>
                <Button onClick={discardMeshBot} size="large" variant="contained" color="primary">
                    {t(EZLOGIC_BUTTON_CONTINUE)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ActionConfirmDialog;

ActionConfirmDialog.propTypes = {
    isModalVisible: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    discardMeshBot: PropTypes.func,
    continueMeshBot: PropTypes.func,
    onCancel: PropTypes.func,
};

ActionConfirmDialog.defaultProps = {
    title: 'Your data has not been saved!',
    content: `If you don't want to lose your progress, click "Cancel".`,
};
