import './style.scss';
import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';

const Button = ({ onClick, className, children, disabled, focused }) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        if (focused) {
            buttonRef.current.focus();
        }
    }, [focused]);

    return (
        <button ref={buttonRef} className={classNames('btn-base', className)} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    focused: false,
    disabled: false,
    onClick: function () {},
};

export default Button;
