import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

import useMeshBotsTableContext from '../../hooks/useMeshBotsTableContext';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_DISPLAY_SYSTEM_MESHBOTS } from '../../../../../constants/language_tokens';

import styles from './style.module.scss';

const SystemMeshBotsSwitch = () => {
    const {
        systemMeshBotsState: { isSystemMeshBotsChecked, handleSystemMeshBotsToggle },
    } = useMeshBotsTableContext();
    const { t } = useTranslate();

    return (
        <div className={styles.switchToolbarContainer}>
            <FormControlLabel
                control={
                    <Switch
                        size="small"
                        color="primary"
                        checked={isSystemMeshBotsChecked}
                        onChange={handleSystemMeshBotsToggle}
                    />
                }
                label={t(EZLOGIC_TITLE_DISPLAY_SYSTEM_MESHBOTS)}
            />
        </div>
    );
};

export default SystemMeshBotsSwitch;
