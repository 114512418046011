import React from 'react';
import { Button, Tooltip } from '@material-ui/core';

import { IcControlsDelete } from '../../../../../assets/icons';
import useDeletingOfSelectedMeshbots from '../../hooks/useDeletingOfSelectedMeshbots';
import {
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS,
    EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS_MESSAGE,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

import styles from './styles.module.scss';

const DeleteSelectedMeshBotsButton = () => {
    const {
        isConfirmModalVisible,
        showDeleteButton,
        handleClickDeleteButton,
        handleConfirmation,
        setIsConfirmModalVisible,
    } = useDeletingOfSelectedMeshbots();
    const { t } = useTranslate();
    const title = t(EZLOGIC_TITLE_DELETE);

    if (!showDeleteButton) {
        return null;
    }

    return (
        <div>
            <Tooltip title={title}>
                <Button
                    onClick={handleClickDeleteButton}
                    className={styles.toolbarButton}
                    startIcon={<IcControlsDelete />}
                    size="small"
                    color="primary"
                >
                    {title}
                </Button>
            </Tooltip>

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={t(EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS)}
                text={t(EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS_MESSAGE)}
                confirmButtonTitle={t(EZLOGIC_TITLE_DELETE)}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />
        </div>
    );
};

export default DeleteSelectedMeshBotsButton;
