import React from 'react';
import TextField from '@material-ui/core/TextField';

import { CODE_KEY, IS_SIMPLE_DISABLED_KEY, STARTED_TEMPLATE_FOR_CODE_SIMPLE } from '../../constants';

import style from './CloudVariable.module.scss';
import { EZLOGIC_TITLE_ENTER_JS_CODE } from '../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../features/languages';

export const AdvancedTextArea = ({ handleChangeState, code, onSetCode }) => {
    const { t } = useTranslate();
    const handleChange = (e) => {
        let temp = e.target.value;
        temp = temp.replace(STARTED_TEMPLATE_FOR_CODE_SIMPLE, '');
        handleChangeState(temp, CODE_KEY, true, IS_SIMPLE_DISABLED_KEY);
        onSetCode(temp);
    };

    return (
        <div className={style.itemBlock}>
            <TextField
                placeholder={t(EZLOGIC_TITLE_ENTER_JS_CODE)}
                onChange={handleChange}
                value={code}
                multiline
                maxRows={5}
                variant="outlined"
                className={style.runTextResultBlock}
            />
        </div>
    );
};
