/**
 * The status of a valid link for resetting a password
 * More info
 * {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=User+-+Password+Reset+Set here:}
 * */
export const VALID_PASSWORD_RESET_LINK_STATUS = 1;

/**
 * Action to check the validity of the link to reset the password
 * More info
 * {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=User+-+Password+Reset+Set here:}
 * */
export const VALID_PASSWORD_RESET_LINK_ACTION = 'validate';
