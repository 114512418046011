import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import MainAction from '../../../../../../../../actions/MainAction';
import { sleep } from '../../../../../../../../helpers/sleep';

import { bugsnagNotify } from '../../../../../../../ErrorBoundary/utils';
import { MAX_POLLING_REQUESTS_AMOUNT, SUCCESS_STATUS, SLEEP_TIME } from '../paas-constants';
import { getAllAbstractsByUuid } from '../utils';

export const useStartPollingToGetAbstracts = (abstracts, parjectUuid, enrollments, handler, additionHandler) => {
    const isLoadingRef = useRef(true);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            isLoadingRef.current = false;
        };
    }, []);

    const fetchAbstracts = async () => {
        let isSuccess = false;

        try {
            const abstractsList = await dispatch(MainAction.getAbstractsList(abstracts));
            const { status, data } = abstractsList;
            const updatedEnrollments = getAllAbstractsByUuid(data.abstracts, parjectUuid);
            isSuccess = Boolean(status === SUCCESS_STATUS && updatedEnrollments.length > enrollments.length);
            additionHandler?.(isSuccess);
        } catch (err) {
            bugsnagNotify(err, { functionName: 'fetchAbstracts' });
            isSuccess = false;
        }

        return isSuccess;
    };

    const startPollingToGetAbstracts = async () => {
        let pollingRequestAmount = 0;

        const startRecursivePolling = async () => {
            if (isLoadingRef.current) {
                await sleep(SLEEP_TIME);
                const isSuccess = await fetchAbstracts();
                const isLastPollingRequest = pollingRequestAmount === MAX_POLLING_REQUESTS_AMOUNT;

                if (isSuccess || isLastPollingRequest) {
                    isLoadingRef.current = false;

                    handler?.();
                } else {
                    pollingRequestAmount += 1;
                    await sleep(SLEEP_TIME);
                    await startRecursivePolling();
                }
            } else {
                isLoadingRef.current = true;
            }
        };

        await startRecursivePolling();
    };

    return {
        startPollingToGetAbstracts,
    };
};
