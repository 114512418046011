import React from 'react';
import { TextField } from '@material-ui/core';
import { getNumberError } from '../utils';

const DynamicNumberField = ({ input, name, value, onChange, className }) => {
    const handleChange = (e) => {
        const newValue = e.target.value !== '' ? Number(e.target.value) : '';
        onChange(newValue, name, input.required);
    };

    const getError = () => {
        return getNumberError(input.minimum, input.maximum, value);
    };

    return (
        <div className={`${className}`}>
            <TextField
                min={input.minimum}
                max={input.maximum}
                variant="outlined"
                size="small"
                error={!!getError()}
                helperText={getError() && getError()}
                fullWidth
                step={0.01}
                value={value}
                onChange={handleChange}
                type="number"
                InputProps={{
                    inputProps: { autoComplete: 'new-password' },
                }}
            />
        </div>
    );
};

export default DynamicNumberField;
