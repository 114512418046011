import {
    EZLOGIC_TITLE_ALL_TIME,
    EZLOGIC_TITLE_MONTH,
    EZLOGIC_TITLE_NEW,
    EZLOGIC_TITLE_OLD,
    EZLOGIC_TITLE_DEFAULT,
    EZLOGIC_TITLE_TODAY,
    EZLOGIC_TITLE_WEEK,
    EZLOGIC_TITLE_YEAR,
} from '../../../constants/language_tokens';

export const ACCEPT_FILE_FORMAT_FOR_UPLOAD_IMG = ['.png', '.svg', '.jpg'];
export const ONE_MB_IN_BYTES = 1000000;
export const FIRST_ITEM_IN_ARRAY = 0;

export const ORDER_SORTING_VALUES = {
    NEW: 'new',
    OLD: 'old',
    DEFAULT: 'default',
};

export const OPTIONS_OF_ORDER_SORTING = [
    { value: ORDER_SORTING_VALUES.NEW, label: EZLOGIC_TITLE_NEW },
    { value: ORDER_SORTING_VALUES.OLD, label: EZLOGIC_TITLE_OLD },
    { value: ORDER_SORTING_VALUES.DEFAULT, label: EZLOGIC_TITLE_DEFAULT },
];

export const TIME_INTERVALS = {
    TODAY: 'today',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
    ALL_TIME: 'all_time',
};

export const OPTIONS_OF_TIME_INTERVALS = [
    { value: TIME_INTERVALS.TODAY, label: EZLOGIC_TITLE_TODAY },
    { value: TIME_INTERVALS.WEEK, label: EZLOGIC_TITLE_WEEK },
    { value: TIME_INTERVALS.MONTH, label: EZLOGIC_TITLE_MONTH },
    { value: TIME_INTERVALS.YEAR, label: EZLOGIC_TITLE_YEAR },
    { value: TIME_INTERVALS.ALL_TIME, label: EZLOGIC_TITLE_ALL_TIME },
];
export const PAGINATION_ARROWS = {
    PREV: 'prev',
    NEXT: 'next',
};

export const PAGINATION_STEP = 1;
export const PAGINATION_PER_PAGE_VALUE = 100;
export const PAGINATION_FIRST_PAGE_VALUE = 1;
export const IMAGE_CARD_WIDTH = 160;
export const CARD_SHOW_TIME_MS = 1500;
export const CARD_ELEVATION = 1;
export const SELECTED_CARD_ELEVATION = 5;
export const SVG_FILE_FORMAT = 'svg';
export const MEDIA_STORE_NAME = 'mediaStore';
export const ADD_ICON_SHOW_BREAKPOINT = '(min-width:600px)';
