import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

export const history = createHashHistory();
const middleware = [thunk, routerMiddleware(history)];

export default function configureEzlogicStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer(history),
        middleware: [...middleware],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState,
    });

    // Hot reloading

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            store.replaceReducer(rootReducer(history));
        });
    }

    window.app = {};
    window.app.store = store;

    return store;
}

export const store = configureEzlogicStore();
