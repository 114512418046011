import React from 'react';
import { TextField } from '@material-ui/core';

const EmailField = ({ value, onChange, name, isRequired }) => {
    const handleChange = (e) => {
        onChange(e.target.value, name, isRequired);
    };

    return (
        <TextField
            InputProps={{
                inputProps: { autoComplete: 'new-password' },
            }}
            type="email"
            value={value}
            onChange={handleChange}
        />
    );
};

export default EmailField;
