import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const useFeatureSupportChecker = (featureName, lowestFeatureSupportVersion) => {
    const serial = useSelector((state) => state.ezlo.serial);
    const featuresList = useSelector((state) => state.ezlo?.data[serial]?.features) || {};
    if (featuresList[featureName]) {
        const { status, version } = featuresList[featureName];
        const versionNumbersForCompare = lowestFeatureSupportVersion.split('.');

        const isVersionSupportsFeature = version.split('.').every((versionNumber, index) => {
            const versionNumberForCompare = versionNumbersForCompare[index] || '0';

            return Number(versionNumber) >= Number(versionNumberForCompare);
        });

        return status === 'on' && isVersionSupportsFeature;
    }

    return false;
};

useFeatureSupportChecker.propTypes = {
    featureName: PropTypes.string,
    lowestFeatureSupportVersion: PropTypes.string,
};

export default useFeatureSupportChecker;
