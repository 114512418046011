import React, { memo, useState, useEffect } from 'react';
import { FormControl, TextareaAutosize } from '@material-ui/core';
import classNames from 'classnames';

import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';

import { OBJECT } from '../../../../../constants/MeshbotConstant';

import styles from './DictionaryTypeTextArea.module.scss';
import { EZLOGIC_TITLE_THE_ENTERED_JSON_NOT_VALID } from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const DictionaryTypeTextArea = (props) => {
    const { value, handleUpdateFieldActionBlock, _tempId, idDevice, actionBlockName } = props;
    const { t } = useTranslate();
    const [isDictionaryValueWrong, setIsDictionaryValueWrong] = useState(false);
    const [dictionaryValue, setDictionaryValue] = useState(JSON.stringify(value, null, 1));

    useEffect(() => {
        try {
            if (typeof JSON.parse(dictionaryValue) === OBJECT) {
                handleUpdateFieldActionBlock(JSON.parse(dictionaryValue), idDevice, _tempId, actionBlockName);
                setIsDictionaryValueWrong(false);
            }
        } catch {
            setIsDictionaryValueWrong(true);
            handleUpdateFieldActionBlock(dictionaryValue, idDevice, _tempId, actionBlockName);
        }
    }, [dictionaryValue, isDictionaryValueWrong]);

    const handleChange = (e) => {
        setDictionaryValue(e.target.value);
    };

    return (
        <FormControl
            className={classNames(styles.codeArea, {
                invalidValue: isDictionaryValueWrong,
            })}
        >
            <TextareaAutosize value={dictionaryValue} maxRows={6} onChange={handleChange} />
            {isDictionaryValueWrong && (
                <span className={styles.errorText}>{t(EZLOGIC_TITLE_THE_ENTERED_JSON_NOT_VALID)}</span>
            )}
        </FormControl>
    );
};

export default withErrorBoundary(memo(DictionaryTypeTextArea), FallbackMeshbotNodeComponent);
