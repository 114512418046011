import React, { memo } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Delete } from '@material-ui/icons';
import { IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { InputMaterial } from '../../../../components';
import useFieldsState from '../customHooks/useFieldsState';
import {
    SETTINGS_OF_VIRTUAL_DEVICE_FIELD,
    VIRTUAL_DEVICE_ARRAY_NAME,
} from '../../../../constants/VirtualDeviceConstant';

import styles from '../styles.module.scss';
import { EZLOGIC_TITLE_QUATITY, EZLOGIC_TITLE_VIRTUAL_DEVICE_TYPE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const DeviceFieldsRow = ({ virtualDevice, arrayHelpers, virtualDeviceKey }) => {
    const { errors } = useFormikContext();
    const { t } = useTranslate();
    const { devices_count, device_type } = virtualDevice;
    const isVisibleDeleteButton = Boolean(virtualDeviceKey !== 0);
    const deviceTypeName = `${VIRTUAL_DEVICE_ARRAY_NAME}.${virtualDeviceKey}.device_type`;
    const deviceCountName = `${VIRTUAL_DEVICE_ARRAY_NAME}.${virtualDeviceKey}.devices_count`;
    const quantityFieldError = getIn(errors, deviceCountName);
    const { QUANTITY } = SETTINGS_OF_VIRTUAL_DEVICE_FIELD;
    const { filteredDeviceTypes, handleDeviceTypeChange, handleQuantityChange, handleDeleteButtonClick } =
        useFieldsState(deviceTypeName, deviceCountName, arrayHelpers, virtualDeviceKey);

    const autocompleteTextField = (params) => (
        <TextField required {...params} label={t(EZLOGIC_TITLE_VIRTUAL_DEVICE_TYPE)} variant="standard" />
    );
    const getOptionSelected = (option, value) => option.apiName === value.apiName;
    const getOptionLabel = (option) => option.uiLabel;

    return (
        <div className={styles.virtualDevice}>
            <div className={styles.fieldsBox}>
                <div className={styles.fieldBox}>
                    <Autocomplete
                        fullWidth
                        className={styles.autocomplete}
                        options={filteredDeviceTypes}
                        getOptionSelected={getOptionSelected}
                        getOptionLabel={getOptionLabel}
                        id={deviceTypeName}
                        clearOnEscape
                        disableClearable
                        name={deviceTypeName}
                        value={device_type}
                        onChange={handleDeviceTypeChange}
                        renderInput={autocompleteTextField}
                    />
                </div>

                <div className={styles.fieldBox}>
                    <InputMaterial
                        required
                        error={Boolean(quantityFieldError)}
                        helperText={quantityFieldError}
                        label={t(EZLOGIC_TITLE_QUATITY)}
                        type="number"
                        name={deviceCountName}
                        value={devices_count}
                        max={QUANTITY.MAX}
                        min={QUANTITY.MIN}
                        step={QUANTITY.STEP}
                        onChange={handleQuantityChange}
                        withoutMargin
                    />
                </div>
            </div>

            {isVisibleDeleteButton && (
                <IconButton className={styles.deleteButton} onClick={handleDeleteButtonClick} aria-label="delete">
                    <Delete fontSize="large" color="primary" />
                </IconButton>
            )}
        </div>
    );
};

export default memo(DeviceFieldsRow);
