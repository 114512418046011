import styles from '../styles.module.scss';
import { EZLOGIC_TITLE_ADD_INTEGRATION } from '../../../../../constants/language_tokens';
import React from 'react';
import { useTranslate } from '../../../../../features/languages';
import { PlusIcon } from '../../../../../assets/icons';

const EzloIntegrationCreateNewIntegrationCard = ({ handleClick }) => {
    const { t } = useTranslate();

    return (
        <li className={styles.addNewIntegrationItem} onClick={handleClick}>
            <PlusIcon />
            <span>{t(EZLOGIC_TITLE_ADD_INTEGRATION)}</span>
        </li>
    );
};

export default EzloIntegrationCreateNewIntegrationCard;
