import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import EzloNotificationTemplateTypeItem from '../EzloNotificationTemplateTypeItem';

import { NOTIFICATIONS_TEMPLATE_TYPES } from '../../../../../constants/Notifications';

import {
    templateTypesWrapper,
    templateTypesItemWrapper,
    templateTypesCloseBtn,
} from './EzloNotificationTemplateTypes.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_TITLE_TEMPLATE_CREATE_NOTIFICATION_TEMPLATE } from '../../../../../constants/language_tokens';

const EzloNotificationTemplateTypes = (props) => {
    const { onClose } = props;
    const { t } = useTranslate();

    return (
        <Box className={templateTypesWrapper}>
            <h2>{t(EZLOGIC_TITLE_TEMPLATE_CREATE_NOTIFICATION_TEMPLATE)}</h2>
            <IconButton size="small" className={templateTypesCloseBtn} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <div className={templateTypesItemWrapper}>
                {NOTIFICATIONS_TEMPLATE_TYPES.map((type) => (
                    <EzloNotificationTemplateTypeItem
                        key={type.id}
                        icon={type.icon}
                        title={t(type.title)}
                        name={type.name}
                        onClose={onClose}
                    />
                ))}
            </div>
        </Box>
    );
};

export default EzloNotificationTemplateTypes;
