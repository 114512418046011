import { connect } from 'react-redux';
import * as Actions from '../../../../actions';
import { bindActionCreators } from 'redux';
import PluginEdge from './PluginEdge';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    const obj = {};

    Object.keys(Actions).map(function (actionsName) {
        if (actionsName !== '__esModule') {
            obj[actionsName] = bindActionCreators(Actions[actionsName], dispatch);
        }
    });

    return obj;
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginEdge);
