import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

import {
    Login,
    Ezlo,
    EzloOffline,
    SignUp,
    ResetPasswordPage,
    FirstLoginPage,
    ForgotPassword,
    ErrorDialog,
    Preloader,
    LoginWithToken,
    ItarianSso,
    ValidateEmailPage,
} from '../index';
// eslint-disable-next-line
import { POLICIES_AGREEMENT_PATH, LOGIN } from '../../constants/URLs';
import { Switch, Route, useLocation } from 'react-router-dom';
// import ResendConfirmation from '../ResendConfirmation/ResendConfirmation';
// import EmailConfirmation from '../EmailConfirmation/EmailConfirmation';
import PrivacyPolicyAction from '../../features/EzloPolicies/actions/EzloPoliciesAction';
import NotFoundPage from '../../containers/NotFoundPage';
import { API_ERROR_MESSAGES } from '../../constants/Errors';
import { ACCOUNT_TYPES } from '../../constants/Users';
import {
    getTheme,
    getPartnerUuidFromDomain,
    removePartnerDomainDataFromLocalStorage,
    getRootDomain,
    checkUuidFormat,
} from '../Ezlo/utils';
import EzloCustomizationAction from '../../actions/EzloCustomization';
import InstallerActions from '../../features/InstallerAccess/actions/InstallerAccessRights';
import AccessRevoked from '../../features/InstallerAccess/components/AccessRevoked/AccessRevoked';
import { colors, ENDUSER_PERMISSION, INSTALLER_PERMISSION, ZERO } from '../Ezlo/EzloCustomization/constants';
import { getUpdatedColors, getUpdatedColorTheme, setUpdatedColors } from '../Ezlo/EzloCustomization/utils';
import { checkPartnerDomain } from '../AuthorizationPages/Login/utils';
import { MainAction } from '../../actions';
import genericActions from '../../actions/GenericActions';
import EzloPolicies from '../../features/EzloPolicies/container';
import { getPoliciesAccepted, checkPoliciesAccepted } from '../../features/EzloPolicies/utils';
import { HASH, HASH_CS, NAME } from '../../constants/localStorageKeys';
import * as actions from '../../actions';
import { useTranslate } from '../../features/languages';
import { EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT } from '../../constants/language_tokens';

// TODO: refactoring needed
const Main = (props) => {
    const location = useLocation();
    const [offline] = useState(false);
    const [delayNotFoundPage, setDelayNotFoundPage] = useState(false);
    const [currentThemeData, setCurrentThemeData] = useState();
    const {
        logged,
        isLogging,
        permissionRole,
        ezloPolicies,
        isCustomizationProgressing,
        router,
        installerAccessRights,
    } = props.redux.state;
    const defaultColorTheme = useSelector((state) => state?.ezloCustomization?.defaultColorTheme);
    const [policiesNotAccepted, setPoliciesNotAccepted] = useState();
    const policiesAccepted = useRef();
    // eslint-disable-next-line
    const { t } = useTranslate();

    const colorTheme = useSelector((state) => state?.ezloCustomization?.initialColorTheme);
    const dispatch = useDispatch();
    const { logout, showError } = props.redux.actions.GenericActions;

    // TODO: set ENV only once!
    useEffect(() => {
        removePartnerDomainDataFromLocalStorage();
        const { hostname, protocol } = window?.location;
        const partnerUuidFromDomain = getPartnerUuidFromDomain(hostname);
        let domain = hostname;
        if (partnerUuidFromDomain?.length) {
            domain = getRootDomain(hostname);
        }

        dispatch(MainAction.initialSetup(domain));

        const isUuidFormat = checkUuidFormat(hostname);
        if (isUuidFormat) {
            dispatch(EzloCustomizationAction.uuidDomainCustomization(hostname, protocol));
        } else {
            dispatch(EzloCustomizationAction.topLevelDomainCustomization(hostname));
        }
        const defaultUpdatedColors = getUpdatedColors(colors);
        dispatch(EzloCustomizationAction.defaultColorTheme(defaultUpdatedColors));
    }, []);

    useEffect(() => {
        if (logged && installerAccessRights?.isInstallerLoggedInAsResident) {
            const cleanupInterval = dispatch(InstallerActions.startInstallerAccessCheckInterval);

            return cleanupInterval;
        }
    }, [logged, installerAccessRights?.isInstallerLoggedInAsResident]);

    // todo: login case

    useEffect(() => {
        if (colorTheme?.length === ZERO) {
            setUpdatedColors(defaultColorTheme);
        }
        const isPartnerDomain = checkPartnerDomain();
        const updatedColorTheme = getUpdatedColorTheme(colors, colorTheme, permissionRole, isPartnerDomain);
        if (permissionRole === ENDUSER_PERMISSION || permissionRole === INSTALLER_PERMISSION || isPartnerDomain) {
            setUpdatedColors(colorTheme);
        }
        const updatedTheme = getTheme(updatedColorTheme);
        setCurrentThemeData(updatedTheme);
    }, [colorTheme]);

    const isPreloader = useMemo(() => {
        return logged && isCustomizationProgressing;
    }, [isCustomizationProgressing, logged]);

    const isAccountReady = useMemo(() => {
        return logged && !isCustomizationProgressing;
    }, [isCustomizationProgressing, logged]);

    const getRedirectPath = () => {
        return dispatch(genericActions.getPathOfFirstPageAfterLogin);
    };

    useEffect(() => {
        if (
            !location.pathname.includes('passwordreset') &&
            !location.pathname.includes('verify') &&
            !location.pathname.includes('login-user-with-token') &&
            !location.pathname.includes('firstlogin')
        ) {
            // TODO: used to keep legacy behaviour, login if possible
            const name = localStorage.getItem(NAME);
            const hash = localStorage.getItem(HASH);
            const hashCS = localStorage.getItem(HASH_CS);
            if (name && hash && hashCS) {
                dispatch(actions.GenericActions.login(name, hash, hashCS));
            }
        }
    }, []);

    useEffect(() => {
        if (isAccountReady) {
            if (
                permissionRole ===
                ACCOUNT_TYPES.find((type) => t(type.name) === t(EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT)).value
            ) {
                logout();
                dispatch(showError(API_ERROR_MESSAGES.NO_ACCESS));
            }

            const path = getRedirectPath();

            if (policiesAccepted.current && path) {
                dispatch(PrivacyPolicyAction.setPreviousPath(path));
                props.history.push(POLICIES_AGREEMENT_PATH);
            } else if (path) {
                props.history.push(path);
            }
            setDelayNotFoundPage(true);
        } else if (
            !location.pathname.includes('passwordreset') &&
            !location.pathname.includes('verify') &&
            !location.pathname.includes('login-user-with-token') &&
            !location.pathname.includes('firstlogin')
        ) {
            // TODO: more possible issues here, applied only a hotfix.
            // TODO: we must not redirect all the time when isAccountReady = false and policiesAccepted.current changes.
            // TODO: allocate time and design application loading scheme on miro together with permissions tasks
            props.history.push(LOGIN);
            setDelayNotFoundPage(false);
        }
    }, [isAccountReady, policiesAccepted.current]);

    // todo: offline case
    // useEffect(() => {
    //     if (ezlos) {
    //         const firstAvailableEzlo = ezlos.find((ezlo) => ezlo.connected);
    //         if (!firstAvailableEzlo) {
    //             setOffline(true)
    //             props.history.push(GET_OFFLINE_EZLO(ezlos[0].serial))
    //         }
    //     }
    // }, [ezlos]);

    useEffect(() => {
        const policiesAccepted = getPoliciesAccepted(ezloPolicies);
        dispatch(PrivacyPolicyAction.policiesAccepted(policiesAccepted));
    }, [ezloPolicies?.eula, ezloPolicies?.setConsent, ezloPolicies?.notices]);

    useEffect(() => {
        const isPoliciesNotAccepted = checkPoliciesAccepted(ezloPolicies?.policiesAccepted);
        policiesAccepted.current = isPoliciesNotAccepted;
        setPoliciesNotAccepted(policiesAccepted.current);
    }, [ezloPolicies?.policiesAccepted]);

    useEffect(() => {
        if (policiesAccepted.current) {
            props.history.push(POLICIES_AGREEMENT_PATH);
        }
    }, [router.location.pathname]);

    return (
        <ThemeProvider theme={currentThemeData}>
            <main className="page-container">
                {isPreloader && <Preloader />}
                {!logged && (
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/login-user-with-token" component={LoginWithToken} />
                        <Route path="/access-revoked" component={AccessRevoked} />
                        <Route path="/itarian-sso" component={ItarianSso} />
                        <Route path="/passwordreset" component={ResetPasswordPage} />
                        <Route path="/firstlogin" component={FirstLoginPage} />
                        <Route path="/register" component={SignUp} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/verify" component={ValidateEmailPage} />
                        {/* <Route path="/resend-confirmation" component={ResendConfirmation} /> */}
                        {!isLogging ? <Route component={NotFoundPage} /> : null}
                    </Switch>
                )}
                {offline && logged && !policiesNotAccepted && (
                    <Switch>
                        <Route path="/offline/:ezloSerial" component={EzloOffline} />
                        {/*<Route path="/offline" component={EzloOffline}/>*/}
                        {/*<Route path="/ezlo/new" component={WizardContainer}/>*/}
                    </Switch>
                )}
                {logged && policiesNotAccepted && <Route path={POLICIES_AGREEMENT_PATH} component={EzloPolicies} />}
                {isAccountReady && !policiesNotAccepted && (
                    <Switch>
                        <Route path="/ezlo" render={() => <Ezlo {...props} offline={offline} />} />
                        {!offline && delayNotFoundPage ? <Route component={NotFoundPage} /> : null}
                    </Switch>
                )}
                <ErrorDialog />
            </main>
        </ThemeProvider>
    );
};

export default Main;
