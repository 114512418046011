import React from 'react';
import { TextField } from '@material-ui/core';

import { VARIABLES_FIELDS_LABELS, EXPRESSION_NAME_MAX_LENGTH } from '../../../../../constants/Variables';
import {
    EZLOGIC_VARIABLES_INPUT_UNIQUE_NAME_PLACEHOLDER,
    EZLOGIC_VARIABLES_EXPRESSIONS_HELPER_TEXT,
} from '../../../../../constants/language_tokens';

import { useTranslate } from '../../../../../features/languages';

import { variableTitle } from './VariablesNameInput.module.scss';

const VariablesNameInput = (props) => {
    const { name, onChangeName, disabled, isInputNameNotValid } = props;

    const { t } = useTranslate();

    return (
        <TextField
            type="text"
            value={name}
            name="name"
            className={variableTitle}
            label={t(VARIABLES_FIELDS_LABELS.VARIABLE_NAME)}
            variant="outlined"
            size="small"
            placeholder={t(EZLOGIC_VARIABLES_INPUT_UNIQUE_NAME_PLACEHOLDER)}
            onChange={onChangeName}
            disabled={disabled}
            error={isInputNameNotValid}
            helperText={isInputNameNotValid ? t(EZLOGIC_VARIABLES_EXPRESSIONS_HELPER_TEXT) : null}
            inputProps={{ maxLength: EXPRESSION_NAME_MAX_LENGTH }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default VariablesNameInput;
