import React, { memo, useState } from 'react';
import { getIn, useFormikContext } from 'formik';

import TextInput from '../../components/TextInput';
import CountrySelect from '../../components/CountrySelect';
import StateSelect from '../../components/StateSelect';
import CitySelect from '../../components/CitySelect';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_FIRST_NAME,
    EZLOGIC_TITLE_LAST_NAME,
    EZLOGIC_TITLE_STREET_ADDRESS_1,
    EZLOGIC_TITLE_STREET_ADDRESS_2,
    EZLOGIC_TITLE_ZIP,
} from '../../../../../constants/language_tokens';
import { getIsPostalCodeRequired } from '../utils';

import { addressRow, addressRowRow } from '../AccountForm/style.module.scss';

const AddressSectionContent = ({ addressName, isDisabled }) => {
    const { values } = useFormikContext();
    const [PK_Country, setPK_Country] = useState(getIn(values, `${addressName}.Country`)?.PK_Country);
    const [PK_Region, setPK_Region] = useState();
    const { t } = useTranslate();

    return (
        <>
            <div className={addressRow}>
                <TextInput
                    name={`${addressName}.FirstName`}
                    label={t(EZLOGIC_TITLE_FIRST_NAME)}
                    required
                    isDisabled={isDisabled}
                />
                <TextInput
                    name={`${addressName}.LastName`}
                    label={t(EZLOGIC_TITLE_LAST_NAME)}
                    required
                    isDisabled={isDisabled}
                />
            </div>

            <div className={addressRowRow}>
                <TextInput
                    name={`${addressName}.Address1`}
                    label={t(EZLOGIC_TITLE_STREET_ADDRESS_1)}
                    required
                    isDisabled={isDisabled}
                />
            </div>

            <div className={addressRowRow}>
                <TextInput
                    name={`${addressName}.Address2`}
                    label={t(EZLOGIC_TITLE_STREET_ADDRESS_2)}
                    isDisabled={isDisabled}
                />
            </div>

            <div className={addressRow}>
                <CountrySelect
                    setPK_Region={setPK_Region}
                    name={`${addressName}.Country`}
                    isDisabled={isDisabled}
                    setPK_Country={setPK_Country}
                    addressName={addressName}
                />
                <StateSelect
                    name={`${addressName}.State`}
                    isDisabled={isDisabled}
                    setPK_Region={setPK_Region}
                    PK_Country={PK_Country}
                    addressName={addressName}
                />
            </div>

            <div className={addressRow}>
                <CitySelect
                    name={`${addressName}.City`}
                    isDisabled={isDisabled}
                    PK_Country={PK_Country}
                    PK_Region={PK_Region}
                />
                <TextInput
                    name={`${addressName}.PostalCode`}
                    label={t(EZLOGIC_TITLE_ZIP)}
                    required={getIsPostalCodeRequired(PK_Country)}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    );
};

export default memo(AddressSectionContent);
