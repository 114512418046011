import React from 'react';
import SelectComponent from '../SelectComponent';
import { info } from '../../utils';
import { MenuItem } from '@material-ui/core';
import { COMPARISON_DATA } from '../../../../../constants/MeshbotConstant';
import ControllerCapabilityComparatorValue from './ControllerCapabilityComparatorValue';
import ControllerCapabilityValue from './ControllerCapabilityValue';
import ControllerCapabilityComparatorSelect from './ControllerCapabilityComparator';
import ControllerCapabilityList from './ControllerCapabilityList';

const ControllerNodeForTrigger = (props) => {
    const {
        selectedControllerCapability,
        selectedControllerCapabilityValue,
        selectedControllerCapabilityComparator,
        selectedControllerCapabilityComparatorValue,
        onChange,
        ezloSerial,
    } = props;

    const handleUpdateTriggerData = (data) => {
        onChange(data);
    };

    return (
        <>
            <SelectComponent value={ezloSerial ? ezloSerial : ''} label={info.hints.node} info={info.text.node}>
                <MenuItem key={ezloSerial} value={ezloSerial}>
                    {ezloSerial}
                </MenuItem>
            </SelectComponent>

            <ControllerCapabilityList
                selectedControllerCapability={selectedControllerCapability}
                onUpdateTriggerData={(e) => handleUpdateTriggerData({ selectedControllerCapability: e.target.value })}
                ezloSerial={ezloSerial}
            />

            {selectedControllerCapability === COMPARISON_DATA.METHOD.BATTERY_LEVEL && (
                <ControllerCapabilityComparatorSelect
                    onChange={handleUpdateTriggerData}
                    selectedControllerCapabilityComparator={selectedControllerCapabilityComparator}
                />
            )}

            {selectedControllerCapability && selectedControllerCapability === COMPARISON_DATA.METHOD.BATTERY_LEVEL && (
                <ControllerCapabilityComparatorValue
                    selectedControllerCapabilityComparatorValue={selectedControllerCapabilityComparatorValue}
                    onChange={handleUpdateTriggerData}
                />
            )}

            {selectedControllerCapability && selectedControllerCapability !== COMPARISON_DATA.METHOD.BATTERY_LEVEL && (
                <ControllerCapabilityValue
                    selectedControllerCapabilityValue={selectedControllerCapabilityValue}
                    selectedControllerCapability={selectedControllerCapability}
                    onChange={handleUpdateTriggerData}
                />
            )}
        </>
    );
};

export default ControllerNodeForTrigger;
