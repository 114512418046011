import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TemplateForm from '../components/TemplateForm';

import hash from '../../../../constants/uniqueHash';
import * as notification from '../../../../constants/NotificationTemplates';
import * as url from '../../../../constants/URLs';
import { SYSTEM } from '../../../../constants/Notifications';
import { MESHBOT } from '../../EzloCustomization/constants';

import { createRequestBody } from '../utils';

// TODO: avoid copy/paste, apply refactoring together with TemplateEdit
const TemplateCreate = (props) => {
    const {
        templateName,
        ruleCloudTriggers,
        ruleCloudAction,
        NotificationActions,
        selectedNotificationCapability,
        notificationType,
    } = props;

    const history = useHistory();
    const parentTriggerOptionType = useSelector((state) => state.meshBot.cloud.parentTriggerOptionType);

    const isSystemNotificationTemplate = notificationType === SYSTEM;

    const handleCreateTemplate = useCallback(() => {
        const params = createRequestBody({
            typeAction: notification.CREATE,
            name: templateName,
            optionType: parentTriggerOptionType,
            triggers: ruleCloudTriggers,
            actions: ruleCloudAction,
            id: hash(),
            capability: selectedNotificationCapability,
            type: isSystemNotificationTemplate ? SYSTEM : MESHBOT,
        });

        NotificationActions.setNotificationTemplate(params);
        history.push(url.NOTIFICATIONS);
    }, [templateName, ruleCloudTriggers, ruleCloudAction, selectedNotificationCapability]);

    return (
        <TemplateForm onSubmit={handleCreateTemplate} selectedNotificationCapability={selectedNotificationCapability} />
    );
};

export default TemplateCreate;
