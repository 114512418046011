import React from 'react';
import { useSelector } from 'react-redux';

import BoxWithLinearProgress from '../../../../components/BoxWithLinearProgress';
import { mediaStoreSelector } from '../../utils';

import styles from './styles.module.scss';

const MediaContainer = ({ children }) => {
    const { isLoading } = useSelector(mediaStoreSelector);

    return (
        <BoxWithLinearProgress isLinearProgress={isLoading}>
            <div className={styles.mediaBox}>{children}</div>
        </BoxWithLinearProgress>
    );
};

export default MediaContainer;
