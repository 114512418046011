import React, { useEffect, useState } from 'react';

import InfoButton from '../../../../../../../../../containers/Ezlo/EzloMeshbot/components/InfoButton';
import { SettingsApplications } from '@material-ui/icons';

import { integrationIcon } from '../../../../../../../EzloIntegrations/utils';
import styles from './Integrations.module.scss';

export const IntegrationItem = ({ uuid, icon, name, onParjectClick, description }) => {
    const [isImageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        const fetchImage = async () => {
            const isImageLoaded = await integrationIcon(icon);
            setImageLoaded(isImageLoaded);
        };

        fetchImage();
    }, []);

    return (
        <div data-testid={uuid} className={styles.card} key={uuid} onClick={() => onParjectClick(uuid)}>
            {icon && isImageLoaded ? <img src={icon} alt={name} className={styles.img} /> : <SettingsApplications />}
            <p className={styles.title}>{name}</p>
            <div className={styles.info_button}>
                <InfoButton>{description}</InfoButton>
            </div>
        </div>
    );
};
