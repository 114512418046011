import React from 'react';
import { versionCell, upgradeBtn, tooltipTextUpgrade } from './pluginVersionCell.module.scss';
import { Upgrade } from '../../../../../../assets/icons';

const PluginVersionCell = (props) => {
    const { row, name } = props.params || {};
    const { version } = row.config || {};
    const { onClick } = props;
    const handleClick = () => {
        onClick(props.params);
    };

    return (
        <div className={versionCell} title={name}>
            <span>{version}</span>
            {row.shouldUpdate && <Upgrade className={upgradeBtn} onClick={handleClick} />}
            {row.shouldUpdate && (
                <span className={tooltipTextUpgrade}>
                    New version {row?.versionForUpdate} available! <br />
                    Upgrade now
                </span>
            )}
        </div>
    );
};

export default PluginVersionCell;
