import React from 'react';
import ControllerCard from './ControllerCard/ControllerCard';

import styles from './ControllersList.module.scss';

const ControllersList = (props) => {
    const { controllers, isGroupActive, isUnassigned, groupKey, group, deleteGroup } = props;

    return (
        <div className={styles.controllers_list}>
            {controllers?.map((controller) => {
                return (
                    <ControllerCard
                        isUnassigned={isUnassigned}
                        key={controller.id ? controller.id : controller.serial}
                        controller={controller}
                        isGroupActive={isGroupActive}
                        groupKey={groupKey}
                        group={group}
                        deleteGroup={deleteGroup}
                    />
                );
            })}
        </div>
    );
};

export default ControllersList;
