import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Select, Button } from '@material-ui/core';
import { InputMaterial } from '../../../../components';
import {
    EZLOGIC_BUTTON_SAVE,
    EZLOGIC_TITLE_CODE_FOR_EXPRESSION,
    EZLOGIC_TITLE_NAME_VARIABLE,
    EZLOGIC_TITLE_SELECT_DEVICE,
    EZLOGIC_TITLE_SELECT_ITEM,
    EZLOGIC_TITLE_VALUE_NAME_EXPRESSION,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const TriggerExpression = (props) => {
    const [textarea, setTextarea] = useState('return');
    const {
        id,
        name,
        code,
        ezloSerial,
        nameVar,
        nameExpression,
        nameItem,
        itemsDevice,
        idGroup,
        currentDeviceSelect,
        onGetCurrentDeviceId,
        CreateExpression,
        UpdateExpression,
        DeleteTrigger,
    } = props;

    useEffect(() => {
        setTextarea(code);
    }, [code]);
    const { t } = useTranslate();
    const createExpression = () => {
        const params = {
            name: nameExpression,
            code: code,
            params: {
                device_item_names: [
                    {
                        name: nameVar,
                        deviceName: name,
                        itemName: nameItem,
                    },
                ],
            },
        };

        CreateExpression(ezloSerial, params, id, idGroup, t);
        DeleteTrigger(id, idGroup);
    };

    const updateField = (e, field) => {
        let data = { [field]: e.target.value, code: `return params.${nameVar}` };
        if (field === 'nameVar') {
            data = { [field]: e.target.value, code: `return params.${e.target.value}` };
        }

        UpdateExpression(id, data, idGroup);
        setTextarea(`return params.${nameVar}`);
    };

    return (
        <div className="rule-settings__trigger-footer">
            <div className="rule-settings__expression-group">
                <div className="rule-settings__expression-block">
                    <InputMaterial
                        value={nameExpression}
                        placeholder={t(EZLOGIC_TITLE_VALUE_NAME_EXPRESSION)}
                        onChange={(e) => UpdateExpression(id, { nameExpression: e.target.value }, idGroup)}
                    />
                </div>
                <div className="rule-settings__expression-block variable">
                    <InputMaterial
                        value={nameVar}
                        placeholder={t(EZLOGIC_TITLE_NAME_VARIABLE)}
                        onChange={(e) => updateField(e, 'nameVar')}
                    />
                </div>
            </div>

            <div className="rule-settings__expression-block">
                <InputMaterial
                    value={textarea}
                    placeholder={t(EZLOGIC_TITLE_CODE_FOR_EXPRESSION)}
                    multiline
                    rows={4}
                    onChange={(e) => UpdateExpression(id, { code: e.target.value }, idGroup)}
                />
            </div>

            <FormControl className="trigger-block__select">
                <Select
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    placeholder={t(EZLOGIC_TITLE_SELECT_DEVICE)}
                    onChange={(e) => onGetCurrentDeviceId(e, id, idGroup)}
                >
                    {currentDeviceSelect &&
                        currentDeviceSelect.map((item) => {
                            return (
                                <MenuItem key={item._id} value={`${item._id}/${item.name}`}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>

            {name && itemsDevice && itemsDevice.length > 0 && (
                <FormControl className="trigger-block__select">
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        labelId="select-label-7"
                        placeholder={t(EZLOGIC_TITLE_SELECT_ITEM)}
                        value={nameItem ? nameItem : ''}
                        onChange={(e) => updateField(e, 'nameItem')}
                    >
                        {itemsDevice &&
                            itemsDevice.map((item) => {
                                return (
                                    <MenuItem key={item._id} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            )}
            <div className="trigger-block__save">
                <Button variant="contained" color="primary" size="large" onClick={() => createExpression()}>
                    {t(EZLOGIC_BUTTON_SAVE)}
                </Button>
            </div>
        </div>
    );
};

export default TriggerExpression;

TriggerExpression.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    ezloSerial: PropTypes.string,
    nameVar: PropTypes.string,
    nameExpression: PropTypes.string,
    nameItem: PropTypes.string,
    itemsDevice: PropTypes.array,
    idGroup: PropTypes.string,
    currentDeviceSelect: PropTypes.array,
    onGetCurrentDeviceId: PropTypes.func,
    CreateExpression: PropTypes.func,
    UpdateExpression: PropTypes.func,
    DeleteTrigger: PropTypes.func,
};
