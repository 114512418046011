import React, { memo } from 'react';

import UserDelete from '../../../UserDelete';
import DefaultActionCells from '../DefaultActionCells';
import ActionTableCellBox from '../ActionTableCellBox';
import EditButton from '../EditButton';

import { iconCell } from '../../style.module.scss';

const AdminActionsCells = (props) => {
    const { user, isLoggedIn, handleEditBtnClick } = props;

    return (
        <>
            <DefaultActionCells {...props} />
            <ActionTableCellBox className={iconCell} align="center">
                <EditButton handleClick={handleEditBtnClick} />
            </ActionTableCellBox>
            <ActionTableCellBox isDisplayingChildren={!isLoggedIn} className={iconCell} align="center">
                <UserDelete selectedUser={user} />
            </ActionTableCellBox>
        </>
    );
};

export default memo(AdminActionsCells);
