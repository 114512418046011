import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SelectAction from '../SelectAction';
import SelectPinCode from '../SelectPinCode';

const UserLockOperationBlock = (props) => {
    const { onDataUpdate, deviceId, selectedPinCode, setSelectedPinCode, selectedAction, setSelectedAction } = props;
    const ezloState = useSelector((state) => state.ezlo);
    const serial = ezloState?.serial;
    const items = serial ? ezloState.data[serial].items : [];

    return (
        <>
            <SelectAction
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                setSelectedPinCode={setSelectedPinCode}
                onDataUpdate={onDataUpdate}
            />
            <SelectPinCode
                items={items}
                deviceId={deviceId}
                selectedAction={selectedAction}
                onDataUpdate={onDataUpdate}
                selectedPinCode={selectedPinCode}
                setSelectedPinCode={setSelectedPinCode}
            />
        </>
    );
};

export default UserLockOperationBlock;

UserLockOperationBlock.propTypes = {
    deviceId: PropTypes.string,
    onDataUpdate: PropTypes.func,
    selectedPinCode: PropTypes.string,
    setSelectedPinCode: PropTypes.func,
    selectedAction: PropTypes.string,
    setSelectedAction: PropTypes.func,
};
