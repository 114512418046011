import { apiGetSystemHistoryList } from '../api/apiSystemHistory';
import at from '../constants/ActionTypes/SystemHistory';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { toast, TOAST_TYPE } from '../components/Toast';
import { getUniqueObjectsFromArray } from '../containers/Ezlo/EzloSystemHistory/utils';

export const systemHistoryActions = {
    getHistoryAlerts: (params, isRewriteEventsList) => async (dispatch, getState) => {
        const { systemHistory } = getState();
        const { systemHistoryList } = systemHistory;
        dispatch(systemHistoryActions.setListOfSystemHistoryPending(true));

        return await apiGetSystemHistoryList(params)
            .then((res) => {
                const list = res.data.Records;
                dispatch(
                    systemHistoryActions.setListOfSystemHistorySuccess({
                        list: isRewriteEventsList ? list : getUniqueObjectsFromArray([...systemHistoryList, ...list]),
                        isLoading: false,
                    }),
                );

                return res.data.Records;
            })
            .catch((err) => {
                bugsnagNotify(err, { type: at.CANNOT_GET_HISTORY_LIST });
                toast('Cannot get history list', { type: TOAST_TYPE.ERROR });
                dispatch(systemHistoryActions.setListOfSystemHistoryReject(false));
            });
    },

    setListOfSystemHistoryPending: (data) => ({
        type: at.UPDATE_SYSTEM_HISTORY_TABLE.PENDING,
        data,
    }),

    setListOfSystemHistorySuccess: (data) => ({
        type: at.UPDATE_SYSTEM_HISTORY_TABLE.SUCCESS,
        data,
    }),

    setListOfSystemHistoryReject: (data) => ({
        type: at.UPDATE_SYSTEM_HISTORY_TABLE.REJECT,
        data,
    }),

    setHistorySearchByDateBefore: (data) => ({
        type: at.SET_HISTORY_SEARCH_BY_DATE_BEFORE,
        data,
    }),

    setHistorySearchByDateAfter: (data) => ({
        type: at.SET_HISTORY_SEARCH_BY_DATE_AFTER,
        data,
    }),

    setHistorySearchByEventType: (data) => ({
        type: at.SET_HISTORY_SEARCH_BY_EVENT_TYPE,
        data,
    }),

    setHistorySearchByDescription: (data) => ({
        type: at.SET_HISTORY_SEARCH_BY_DESCRIPTION,
        data,
    }),

    setIsValidDate: (data) => ({
        type: at.SET_IS_VALID_DATE,
        data,
    }),
};
