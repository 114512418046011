import React, { memo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import InfoButton from '../../../InfoButton';

import { MESHBOT_NODE_TYPES } from '../../../../../../../constants/MeshbotConstant';

import { findSelectedExpression } from '../../utils';
import { info } from '../../../../utils';

import { useTranslate } from '../../../../../../../features/languages';
import { expressionsAutocomplete } from './ExpressionForCompareSelect.module.scss';
import {
    EZLOGIC_TITLE_EXPRESSION_DELETED_ERROR_HELPER_TEXT,
    EZLOGIC_TITLE_NODE,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_VARIABLE_DELETED_ERROR_HELPER_TEXT,
} from '../../../../../../../constants/language_tokens';

const ExpressionForCompareSelect = (props) => {
    const {
        expressionList,
        initialExpressionName,
        onHandleExpressionComparisonFieldsChange,
        isComparedValue,
        expressionComparisonType,
    } = props;
    const { t } = useTranslate();

    const isSelectedExpressionDeleted =
        initialExpressionName && !findSelectedExpression(initialExpressionName, expressionList);
    const isExpressionDeleted =
        isSelectedExpressionDeleted && expressionComparisonType === MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON;
    const isVariableDeleted =
        isSelectedExpressionDeleted && expressionComparisonType === MESHBOT_NODE_TYPES.VARIABLE_COMPARISON;
    const deletedExpressionTypeText =
        (isExpressionDeleted && t(EZLOGIC_TITLE_EXPRESSION_DELETED_ERROR_HELPER_TEXT)) ||
        (isVariableDeleted && t(EZLOGIC_TITLE_VARIABLE_DELETED_ERROR_HELPER_TEXT));

    return (
        <Autocomplete
            className={expressionsAutocomplete}
            options={expressionList}
            disableClearable={true}
            value={findSelectedExpression(initialExpressionName, expressionList) ?? ''}
            getOptionLabel={(option) => option.name ?? ''}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(e, option) => onHandleExpressionComparisonFieldsChange(option.name)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={isExpressionDeleted || isVariableDeleted}
                    helperText={deletedExpressionTypeText}
                    label={isComparedValue ? t(EZLOGIC_TITLE_VALUE) : t(EZLOGIC_TITLE_NODE)}
                    adornment={
                        (params.InputProps.startAdornment = (
                            <InfoButton>
                                {
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: isComparedValue ? t(info.text.value) : t(info.text.node),
                                        }}
                                    ></div>
                                }
                            </InfoButton>
                        ))
                    }
                />
            )}
        />
    );
};

export default memo(ExpressionForCompareSelect);
