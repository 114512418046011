import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { TextField } from '../../../../components';

import { ABSTRACT_TYPES } from '../../../../constants/VirtualDeviceConstant';
import { PAASAuth } from '../../EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { ABSTRACT_SOURCES, CAPABILITIES } from '../../EzloVirtualDeviceManager/constants';
import { INTEGRATION_SOURCE, INTEGRATION_UUID, VIRTUAL_DEVICE_SOURCE } from '../virtual-templates';

import { updateAbstract } from '../utils';
import { EZLOGIC_TITLE_SELECT_DEVICE_SERVICE } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

// const filterAbstractsByCapability = (abstracts, capability) => {
//     return abstracts.filter(({ capabilities }) => {
//         return capabilities.includes(capability);
//     });
// };

export const AbstractSelect = (props) => {
    const { abstract, abstracts, setVirtualDevice, virtualItemUpdateParams, onSetIntegrationUuid, isVirtualTrigger } =
        props;

    const [value, setValue] = useState({
        name: '',
        uuid: '',
    });
    const [filteredAbstractsList, setFilteredAbstractsList] = useState([]);
    const { t } = useTranslate();
    useEffect(() => {
        setFilteredAbstractsList(
            abstracts?.filter((abstract) => {
                return !abstract.name.includes(PAASAuth);
            }),
        );
    }, [abstracts]);

    useEffect(() => {
        if (abstract && filteredAbstractsList.filter(({ uuid }) => uuid === abstract).length > 0) {
            const { name, uuid } = filteredAbstractsList.find(({ uuid }) => uuid === abstract);

            setValue({
                name,
                uuid,
            });
        }
    }, [abstract, filteredAbstractsList]);

    const handleChangeAbstract = (e, option) => {
        const updatedVirtualDevice = updateAbstract(virtualItemUpdateParams, option.uuid, option[INTEGRATION_UUID]);

        if (option?.source === INTEGRATION_SOURCE) {
            onSetIntegrationUuid(option[INTEGRATION_UUID]);
        } else {
            onSetIntegrationUuid(null);
        }
        setVirtualDevice(updatedVirtualDevice);
    };

    const sortedAbstracts = filteredAbstractsList?.reduce((acc, abstract) => {
        const { uuid, name, capabilities, details } = abstract;
        const abstractForSelect = { name, uuid, source: ABSTRACT_SOURCES.DEVICE };

        if (details?.source) {
            abstractForSelect.source = details.source;
        }

        if (abstractForSelect.source === ABSTRACT_SOURCES.CONTROLLER) {
            abstractForSelect.source = ABSTRACT_SOURCES.DEVICE;
        }

        if (capabilities.includes(CAPABILITIES.SCENE_RUN)) {
            abstractForSelect.source = ABSTRACT_SOURCES.MESHBOT;
        }

        if (details?.source === ABSTRACT_SOURCES.VIRTUAL_DEVICE) {
            abstractForSelect.source = ABSTRACT_SOURCES.VIRTUAL_DEVICE;
        }

        if (details?.source === INTEGRATION_SOURCE) {
            abstractForSelect.source = INTEGRATION_SOURCE;

            abstractForSelect.integration_uuid = details[INTEGRATION_UUID];
        }

        const sourceKey = abstractForSelect.source;

        return {
            ...acc,
            [sourceKey]: acc[sourceKey] ? [...acc[sourceKey], abstractForSelect] : [abstractForSelect],
        };
    }, {});

    const menuItems = [].concat(...Object.values(sortedAbstracts));

    return (
        <div>
            <Autocomplete
                value={value}
                options={menuItems}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                groupBy={({ source }) => ABSTRACT_TYPES[source]}
                getOptionDisabled={({ source }) =>
                    isVirtualTrigger && [INTEGRATION_SOURCE, VIRTUAL_DEVICE_SOURCE].includes(source)
                }
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={handleChangeAbstract}
                disableClearable={true}
                renderInput={(params) => <TextField {...params} label={t(EZLOGIC_TITLE_SELECT_DEVICE_SERVICE)} />}
            />
        </div>
    );
};
