import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Button, TextField } from '@material-ui/core';

import { LABEL_FIELDS, MAX_LENGTH_TEXT_FIELD } from '../../../constants';
import { useTranslate } from '../../../../../languages';
import {
    EZLOGIC_TITLE_EXAMPLE,
    EZLOGIC_TITLE_MESHBOT_NAME,
    EZLOGIC_TITLE_NAME_LABEL,
} from '../../../../../../constants/language_tokens';
import MeshBotLabelInTableCell from '../../MeshBotLabelsInTableCell/MeshBotLabelInTableCell';

import styles from './styles.module.scss';

const LabelForm = ({ submitButtonText }) => {
    const { values, handleChange, isSubmitting, handleSubmit, isValid, dirty } = useFormikContext();
    const { t } = useTranslate();

    const isSubmitButtonDisabled = useMemo(() => {
        return isSubmitting || !isValid || !dirty;
    }, [isSubmitting, isValid, dirty]);

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.contentContainer}>
                <div className={styles.previewLabelContainer}>
                    <span>{t(EZLOGIC_TITLE_EXAMPLE)}:</span>
                    {values.name && (
                        <MeshBotLabelInTableCell
                            name={values[LABEL_FIELDS.NAME]}
                            backgroundColor={values[LABEL_FIELDS.BACKGROUND_COLOR]}
                        />
                    )}
                    {t(EZLOGIC_TITLE_MESHBOT_NAME)}
                </div>
                <div className={styles.fieldsContainer}>
                    <TextField
                        size="medium"
                        name={LABEL_FIELDS.NAME}
                        value={values[LABEL_FIELDS.NAME]}
                        onChange={handleChange}
                        placeholder={t(EZLOGIC_TITLE_NAME_LABEL)}
                        fullWidth
                        variant="standard"
                        inputProps={{ maxLength: MAX_LENGTH_TEXT_FIELD }}
                    />
                    <input
                        className={styles.colorInput}
                        name={LABEL_FIELDS.BACKGROUND_COLOR}
                        type="color"
                        value={values[LABEL_FIELDS.BACKGROUND_COLOR]}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <Button type="submit" disabled={isSubmitButtonDisabled} fullWidth color="primary" variant="contained">
                {submitButtonText}
            </Button>
        </form>
    );
};

export default LabelForm;
