import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import { MenuItem, Button, Grow, Paper, Popper, MenuList, ClickAwayListener } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
    VALUE_TO_UNASSIGN_ROOM,
    TAB,
    TRUE_STRING,
    CENTER_TOP,
    BOTTOM,
    MENU_LIST_GROW,
    CENTER_BOTTOM,
} from '../../../../constants/Devices';
import styles from '../../DeviceCard.module.scss';
import { useTranslate } from '../../../../features/languages';
import { EZLOGIC_TITLE_NO_ROOMS, EZLOGIC_TITLE_UNASSIGNED } from '../../../../constants/language_tokens';

const RoomsDropdown = (props) => {
    const { isDeviceCannotBeMoved, onRoomIdChange, roomList, roomName, isAvailable, isConnected, isIntegration } =
        props;
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const anchorRef = useRef(null);
    const deviceRoomNameStyles = classes(styles.deviceRoomName, { [styles.unreachable]: !isAvailable });
    const { t } = useTranslate();
    const handleToggleMenu = () => {
        setIsOpenMenu((prevOpen) => !prevOpen);
    };

    const handleSelectRoom = (event, id) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (id) {
            onRoomIdChange(id);
        }

        setIsOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === TAB) {
            event.preventDefault();
            setIsOpenMenu(false);
        }
    }

    return (
        <div className={isIntegration ? styles.blockStatusWrapperIntegration : styles.blockStatusWrapper}>
            {isDeviceCannotBeMoved ? (
                <span className={deviceRoomNameStyles}>
                    {roomList.length ? roomName : <span>{t(EZLOGIC_TITLE_UNASSIGNED)}</span>}
                </span>
            ) : (
                <div className={isIntegration ? styles.menuButtonWrapperIntegration : styles.menuButtonWrapper}>
                    <Button
                        ref={anchorRef}
                        aria-controls={isOpenMenu ? MENU_LIST_GROW : undefined}
                        aria-haspopup={TRUE_STRING}
                        onClick={handleToggleMenu}
                        disabled={!isConnected}
                    >
                        {roomName}
                    </Button>
                    <Popper open={isOpenMenu} anchorEl={anchorRef.current} role={undefined} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === BOTTOM ? CENTER_TOP : CENTER_BOTTOM }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleSelectRoom}>
                                        <MenuList
                                            autoFocusItem={isOpenMenu}
                                            id={MENU_LIST_GROW}
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {roomList.length ? (
                                                <>
                                                    {roomList.map((room) => {
                                                        return (
                                                            <MenuItem
                                                                key={room._id}
                                                                onClick={(e) => handleSelectRoom(e, room._id)}
                                                            >
                                                                {room.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    <hr className={styles.dividingLine}></hr>
                                                    <MenuItem
                                                        onClick={(e) => handleSelectRoom(e, VALUE_TO_UNASSIGN_ROOM)}
                                                    >
                                                        {t(EZLOGIC_TITLE_UNASSIGNED)}
                                                    </MenuItem>
                                                </>
                                            ) : (
                                                <span className={styles.noRooms}>{t(EZLOGIC_TITLE_NO_ROOMS)}</span>
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <ArrowDropDownIcon className={styles.arrow} />
                </div>
            )}
        </div>
    );
};

export default RoomsDropdown;

RoomsDropdown.propTypes = {
    isAvailable: PropTypes.bool,
    isDeviceCannotBeMoved: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onRoomIdChange: PropTypes.func,
    roomName: PropTypes.string,
    roomList: PropTypes.array,
    isConnected: PropTypes.bool,
};
