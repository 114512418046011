import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import * as meshBot from '../../../../../constants/MeshbotConstant';
import { MESHBOT_TYPES } from '../../../EzloMeshbots/constants';
import { triggersSection, actionsSection, triggersSection_exception } from './BuilderFooter.module.scss';
import {
    EZLOGIC_TITLE_ADD_ACTION,
    EZLOGIC_TITLE_ADD_EXCEPTION,
    EZLOGIC_TITLE_ADD_GROUP,
    EZLOGIC_TITLE_ADD_TRIGGER,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const BuilderFooter = (props) => {
    const {
        type,
        typeTrigger,
        meshbotType,
        actionId,
        disabled,
        actionBlockName,
        onAddTrigger,
        showAddGroupButton = true,
        isException = false,
    } = props;
    const { t } = useTranslate();

    const handleAddTrigger = (blockName) => {
        if (typeTrigger === meshBot.EXCEPTION) {
            onAddTrigger(actionId, actionBlockName, blockName);
        } else {
            onAddTrigger(blockName);
        }
    };

    return (
        <footer>
            {type === meshBot.TRIGGER && (
                <>
                    <div
                        className={classNames(triggersSection, {
                            [triggersSection_exception]: isException,
                        })}
                    >
                        <Button
                            color="primary"
                            disabled={disabled}
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddTrigger(meshBot.TRIGGER)}
                        >
                            {isException ? t(EZLOGIC_TITLE_ADD_EXCEPTION) : t(EZLOGIC_TITLE_ADD_TRIGGER)}
                        </Button>
                        {meshbotType === MESHBOT_TYPES.LOCAL && showAddGroupButton && (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => handleAddTrigger(meshBot.GROUP)}
                            >
                                {t(EZLOGIC_TITLE_ADD_GROUP)}
                            </Button>
                        )}
                    </div>
                </>
            )}
            {type === meshBot.ACTIONS && (
                <div className={actionsSection}>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        disabled={disabled}
                        onClick={() => handleAddTrigger(actionBlockName)}
                    >
                        {t(EZLOGIC_TITLE_ADD_ACTION)}
                    </Button>
                </div>
            )}
        </footer>
    );
};

export default BuilderFooter;

BuilderFooter.propTypes = {
    type: PropTypes.string,
    meshbotType: PropTypes.string,
    listRuleTriggers: PropTypes.array,
    onAddTrigger: PropTypes.func,
};
