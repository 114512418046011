import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PRIVATE } from '../../../../constants/Plugins';
import Header from '../components/Header';
import ModalPlugin from '../components/ModalPlugin';
import PluginsPrivate from './PluginsPrivate';
import PluginsPublish from './PluginsPublish';
import PluginsMarketplace from './Marketplace';
import NotFoundPageContent from '../../../../containers/NotFoundPage/NotFoundPageContent';
import './pluginEdge.scss';
import { EZLO_PLUGIN_EDGE_HEADER_PRIVATE_PAGE_BTN_TITLE } from '../../../../constants/language_tokens';

const PluginEdge = (props) => {
    const [typeModal, setTypeModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isActivePage, setIsActivePage] = useState(PRIVATE);
    const [published, setPublished] = useState(null);

    const { history, PluginActions } = props;
    const { url } = useRouteMatch();

    useEffect(() => {
        history.push(`${url}/private`);
    }, []);

    const handlerRouteList = useCallback(
        (type) => {
            history.push(`${url}/${type}`);
            setIsActivePage(type);
        },
        [history, url],
    );

    const handlerUploadPlugin = useCallback(() => {
        setIsModal(true);
        setTypeModal('update');
    }, []);

    const hideModal = useCallback(() => {
        setIsModal(false);
    }, []);

    const handlerModalType = useCallback((type) => {
        setTypeModal(type);
    }, []);

    const handlerSetIsActivePage = useCallback((type) => {
        setIsActivePage(type);
    }, []);

    const handleSetStatusPage = useCallback((data) => {
        setPublished(data);
    }, []);

    return (
        <div className="plugin-edge">
            <Header
                typePage="edge"
                isActivePage={isActivePage}
                isInstalled={true}
                nameButton={EZLO_PLUGIN_EDGE_HEADER_PRIVATE_PAGE_BTN_TITLE}
                onHandlerRouteList={handlerRouteList}
                onSubmit={handlerUploadPlugin}
            />
            <Switch>
                <Route path={`${url}/private`} render={() => <PluginsPrivate published={published} />} />
                <Route path={`${url}/publish`} render={() => <PluginsPublish />} />
                <Route
                    path={`${url}/market`}
                    render={() => <PluginsMarketplace onHandlerSetIsActivePage={handlerSetIsActivePage} />}
                />
                <Route render={() => <NotFoundPageContent loggedIn />} />
            </Switch>
            <ModalPlugin
                open={isModal}
                onCancel={hideModal}
                typeModal={typeModal}
                PluginActions={PluginActions}
                onHandlerModalType={handlerModalType}
                setStatusPage={handleSetStatusPage}
            />
        </div>
    );
};

PluginEdge.propTypes = {
    history: PropTypes.object,
    PluginActions: PropTypes.object,
};

export default PluginEdge;
