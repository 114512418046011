import React from 'react';
import { Dialog, DialogContent, Grid, Slide } from '@material-ui/core';
import letterImg from '../../../assets/images/letter.png';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../constants/URLs';
import { t } from '../../../helpers/language';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResponseDialog = ({ isSignUp, open, setOpen }) => {
    return (
        <Dialog
            className="response-dialog"
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <Grid spacing={2} container direction="column" alignItems="center">
                    <Grid className="response-dialog__img" item>
                        <img src={letterImg} alt="letter" />
                    </Grid>
                    <Grid item>
                        <div className="response-dialog__title">Check your inbox</div>
                    </Grid>
                    <Grid container direction="column" alignItems="center" item>
                        <Grid className="response-dialog__text" item>
                            You&apos;ll find {isSignUp ? 'registering your account' : 'password reset'} instructions in
                            the email we sent to.
                        </Grid>
                        <Grid className="response-dialog__text response-dialog__subtext" item>
                            If you can&apos;t find an email, please check the spam folder in your inbox
                        </Grid>
                    </Grid>
                </Grid>

                <div onClick={() => setOpen(false)} className="link-wrapper">
                    <Link className="link" to={LOGIN}>
                        {t('ezlo.register.back_to_login')}
                    </Link>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ResponseDialog;
