import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ConfirmDialog } from '../../../../../components';
import Dialog from '../../../../../components/Dialog';
import MeshBotLocalForm from '../MeshBotLocalForm';
import { ConfirmModal } from '../../../../../components/ConfirmationModal/ConfirmationModal';

import at from '../../../../../constants/ActionTypes/MeshBot';
import { CREATE } from '../../../../../constants/MeshbotConstant';
import useUnsavedChangesWarning from '../../MeshBot/CustomHooks/useUnsavedChangesWarning';
import { SAVE } from '../../../../../constants/ComponentConstants';
import { ExpressionsActions } from '../../../../../actions';

import './style.scss';
import {
    EZLOGIC_TITLE_NEED_TO_ADD_A_GROUP_NAME,
    EZLOGIC_TITLE_SAVE_MESHBOT,
    EZLOGIC_TITLE_SAVE_MESHBOT_CONFIRMATION_MSG,
    EZLOGIC_TITLE_WARNING,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';

const MeshBotLocCreate = (props) => {
    const [validationWarnings, setValidationWarnings] = useState([]);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [showSaveWarningsConfirmDialog, setShowSaveWarningsConfirmDialog] = useState(false);
    const [isOptionType, setIsOptionType] = useState(false);
    const [errors, setErrors] = useState(null);
    const setDirty = useUnsavedChangesWarning();
    const { t } = useTranslate();

    const {
        serial,
        selectedRule,
        ruleTriggers,
        MeshBotAction,
        errorsValidation,
        warningsValidation,
        isApiProcessStarted,
    } = props;

    useEffect(() => {
        ExpressionsActions.updateExpressions(serial, t);
        MeshBotAction.getLuaScripts(serial);
    }, [serial]);

    useEffect(() => {
        MeshBotAction.setIsLocalMeshbotPage(true);

        return () => MeshBotAction.setIsLocalMeshbotPage(false);
    }, []);

    useEffect(() => {
        //Todo move to actions
        if (ruleTriggers.length > 1 && !isOptionType) {
            MeshBotAction.updateSelectedRuleNot('and', null, 'optionType');
            setIsOptionType(true);
        }
    }, [selectedRule, ruleTriggers]);

    useEffect(() => {
        if (errorsValidation && errorsValidation.length) {
            showValidationErrors(errorsValidation);
            setIsConfirmModalVisible(false);
        }
    }, [errorsValidation]);

    useEffect(() => {
        if (warningsValidation && warningsValidation.length) {
            setValidationWarnings(warningsValidation);
            openSaveWarningsConfirmDialog();
        }
    }, [warningsValidation]);

    useEffect(() => {
        if (isApiProcessStarted === false) {
            setIsConfirmModalVisible(false);
        }
    }, [isApiProcessStarted]);

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            handlerSave();
        }
        setIsConfirmModalVisible(false);
    };

    const showValidationErrors = (errors) => {
        setShowValidation(true);
        setErrors(errors);
    };

    const openSaveConfirmDialog = () => {
        if (!validationNameGroup(selectedRule.when)) {
            showValidationDialog(t(EZLOGIC_TITLE_NEED_TO_ADD_A_GROUP_NAME));
        } else {
            setIsConfirmModalVisible(true);
        }
    };

    const validationNameGroup = (data) => {
        let result = true;
        data.map((item) => {
            if (item.hasOwnProperty('blockName') && item.blockName === '') {
                result = false;
            }
        });

        return result;
    };

    const showValidationDialog = (errors) => {
        closeSaveWarningsConfirmDialog();
        setIsConfirmModalVisible(false);
        setShowValidation(true);
        setErrors(errors);
    };

    const closeSaveWarningsConfirmDialog = () => setShowSaveWarningsConfirmDialog(false);
    const openSaveWarningsConfirmDialog = () => setShowSaveWarningsConfirmDialog(true);

    const handlerSave = async () => {
        setDirty(false);
        MeshBotAction.saveLocalMeshBot(t);
    };

    return (
        <>
            <MeshBotLocalForm
                typeForm={CREATE}
                typeMeshbot={at.LOCAL}
                onOpenSaveConfirmDialog={openSaveConfirmDialog}
                onShowValidationErrors={showValidationErrors}
                selectedRule={selectedRule}
                wizardMode={false}
            />

            <ConfirmModal
                isOpened={isConfirmModalVisible}
                onClose={setIsConfirmModalVisible}
                title={EZLOGIC_TITLE_SAVE_MESHBOT}
                text={EZLOGIC_TITLE_SAVE_MESHBOT_CONFIRMATION_MSG}
                confirmButtonTitle={SAVE}
                isConfirmButtonDisabled={isApiProcessStarted}
                onCancel={() => handleConfirmation(false)}
                onConfirm={() => handleConfirmation(true)}
            />

            <ConfirmDialog
                title={t(EZLOGIC_TITLE_WARNING)}
                open={showSaveWarningsConfirmDialog}
                onHide={closeSaveWarningsConfirmDialog}
                onConfirm={() => {
                    closeSaveWarningsConfirmDialog();
                    handlerSave();
                }}
            >
                {validationWarnings.map((warning, index) => (
                    <span key={index}>{warning}</span>
                ))}
                <br />
                {t`ezlo.generic.are_you_sure`}
            </ConfirmDialog>
            <Dialog title={t(EZLOGIC_TITLE_WARNING)} open={showValidation} onHide={() => setShowValidation(false)}>
                {errors}
            </Dialog>
        </>
    );
};

export default MeshBotLocCreate;

MeshBotLocCreate.propTypes = {
    serial: PropTypes.string,
    rules: PropTypes.array,
    ruleTriggers: PropTypes.array,
    selectedRule: PropTypes.object,
    MeshBotAction: PropTypes.object,
    isApiProcessStarted: PropTypes.bool,
    errorsValidation: PropTypes.array,
    warningsValidation: PropTypes.array,
};
