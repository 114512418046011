import React from 'react';

import { Modal } from '../../../../../components';
import { NotificationButtonsForEditingCategory } from './NotificationButtonsForEditingCatetgory';
import { NotificationButtonsForDeleteAndEditingCategory } from './NotificationButtonsForDeleteAndEditingCategory';
import { NotificationAddingSection } from './NotificationAddingSection';

import {
    row,
    categoriesTable,
    inputEdit,
    disabled,
    cancelEditing,
} from './modalNotificationCategoryCreating.module.scss';

export const ModalNotificationsCategoryCreating = (props) => {
    const {
        isModalVisible,
        onCancel,
        onChange,
        onSave,
        notificationCategoriesList,
        onDeleteCategory,
        onEditCategory,
        onSaveEditing,
        onEditingCategoryName,
        inputCreatedValue,
        onCancelEditingCategory,
    } = props;

    return (
        <Modal visible={isModalVisible} onCancel={onCancel}>
            <NotificationAddingSection
                onChange={onChange}
                inputCreatedValue={inputCreatedValue}
                disabled={disabled}
                onSave={onSave}
            />
            <div className={categoriesTable}>
                {notificationCategoriesList?.map((category) => (
                    <div className={row}>
                        <input
                            className={category.isEdit && inputEdit}
                            value={category.display_name}
                            disabled={!category.isEdit}
                            onChange={(e) => onEditingCategoryName(e, category.uuid)}
                        />
                        {category.isEdit && (
                            <NotificationButtonsForEditingCategory
                                category={category}
                                onSaveEditing={onSaveEditing}
                                onCancelEditingCategory={onCancelEditingCategory}
                            />
                        )}
                        {!category.isEdit && (
                            <NotificationButtonsForDeleteAndEditingCategory
                                category={category}
                                onSaveEditing={onSaveEditing}
                                cancelEditing={cancelEditing}
                                onEditCategory={onEditCategory}
                                onDeleteCategory={onDeleteCategory}
                            />
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};
