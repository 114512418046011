import React from 'react';

import PasswordTitle from '../PasswordTitle';
import { LoginButtonContainer } from '../../../components/LoginButtonContainer';
import {
    EZLOGIC_BUTTON_CONTINUE_TO_LOGIN,
    EZLOGIC_TITLE_PASSWORD_SET_SUCCESSFUL,
    EZLOGIC_TITLE_USE_YOUR_NEW_PASSWORD,
} from '../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../features/languages';
import ActionsWrapper from '../../../components/ActionsWrapper';

import styles from './SetPasswordSuccessContainer.module.scss';
import { ResetPasswordLock } from '../../../../../assets/icons';

const SetPasswordSuccessContainer = () => {
    const { t } = useTranslate();

    return (
        <>
            <ResetPasswordLock className={styles.image} />
            <PasswordTitle
                title={t(EZLOGIC_TITLE_PASSWORD_SET_SUCCESSFUL)}
                subTitle={t(EZLOGIC_TITLE_USE_YOUR_NEW_PASSWORD)}
            />
            <ActionsWrapper>
                <LoginButtonContainer label={t(EZLOGIC_BUTTON_CONTINUE_TO_LOGIN)} />
            </ActionsWrapper>
        </>
    );
};

export default SetPasswordSuccessContainer;
