import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { InputMaterial } from '../index';
import ActionDelay from '../ActionDelay';
import MeshBotSelectField from '../MeshBotSelectField/MeshBotSelectField';

import { MAX_LENGTH_BODY, MAX_LENGTH_SUBJECT, SELECT_ALL } from '../../constants/Notifications';
import { SUBJECT, BODY, PRIMARY, ALL, BODY_HTML } from '../../constants/MeshbotConstant';
import { removeLineBreaksFromString } from '../../containers/Ezlo/EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { info, transformSpecialCharacters } from '../../containers/Ezlo/EzloMeshbot/utils';

import styles from './NotificationBlock.module.scss';
import notificationStyles from '../NotificationCloudBlock/NotificationCloudBlock.module.scss';
import {
    EZLOGIC_TITLE_CHANNELS,
    EZLOGIC_TITLE_MESSAGE_BODY,
    EZLOGIC_TITLE_MESSAGE_BODY_HTML,
    EZLOGIC_TITLE_SUBJECT,
    EZLOGIC_TITLE_USER,
} from '../../constants/language_tokens';
import { useTranslate } from '../../features/languages';

const NotificationBlock = ({
    blocks,
    blockType,
    channels,
    getNotificationData,
    isShowedDelay,
    id,
    onUpdateActionDelay,
    usersData,
    currentRule,
    actionBlockName,
    selectedFieldTrigger,
    onChangeDelay,
    onSetIsShowedDelay,
}) => {
    const [userData, setUsers] = React.useState([]);
    const [uuids, setUuid] = React.useState([]);
    const [channel, setChannel] = React.useState([]);
    const [selectedUsers, setSelectedUser] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [bodyHtml, setBodyHtml] = useState('');
    const { t } = useTranslate();

    useEffect(() => {
        getUsers(usersData);
        getChannels(channels);
        if (blocks && blocks.length === 0) {
            getNotificationData(id, null, blockType);
        }

        if (blocks && blocks.length > 0 && blocks[0].fields && blocks[0].fields[2] && blocks[0].fields[2].value) {
            const values = JSON.parse(blocks[0].fields[2].value);
            handleChangeState(values, usersData, channels);
        }
    }, [usersData, channels]);

    const isAllSelected = channels.length > 0 && selectedChannel.length === channels.length;

    const getUsers = (usersData) => {
        setUsers(usersData);
    };

    const getChannels = (channels) => {
        const handleSelectedChannels = channels.map((item) => item.id);
        setSelectedChannel(handleSelectedChannels);
        setChannel(channels);
    };

    const handleChangeState = (values, usersData, channels) => {
        if (values && values.notification && values.notification.parameters.length > 0) {
            const [{ subject, body, body_html }] = values.notification.parameters;
            const channelsData = values.notification.parameters[1].targets[0].channels;
            const uuidsData = values.notification.parameters[1].targets.map((item) => item.uuid);

            const getSelectedUuid = usersData.filter((item) => uuidsData.includes(item.uuid));
            const filterUuid = getSelectedUuid.map((item) => item.Username);

            if (subject) {
                setSubject(subject);
            }

            if (body) {
                setBody(body);
            }

            if (body_html) {
                setBodyHtml(body_html);
            }

            if (channelsData) {
                setSelectedChannel(channelsData);
            }

            if (!channelsData) {
                const allChannel = channels && channels > 0 ? channels.map((item) => item.id) : [];
                setSelectedChannel(allChannel);
            }

            if (filterUuid) {
                setUuid(uuidsData);
                setSelectedUser(filterUuid);
            }
        }
    };

    const handleChangeUser = (event) => {
        const { value } = event.target;
        setSelectedUser(value);
        const filterUuid = usersData.filter((item) => value.includes(item.Username));
        const uuids = filterUuid.map((item) => item.uuid);
        const data = {
            channels: selectedChannel,
            uuids,
            subject: transformSpecialCharacters(subject),
            body: transformSpecialCharacters(body),
            body_html: removeLineBreaksFromString(transformSpecialCharacters(bodyHtml)),
        };
        setUuid(uuids);
        getNotificationData(id, data, blockType);
    };

    const handleChangeChannel = (event) => {
        const { value } = event.target;
        let checkSubject = subject;
        if (!value.includes('email') || value.length === 0) {
            setSubject('');
            checkSubject = '';
        }

        const data = {
            channels: value,
            uuids,
            subject: transformSpecialCharacters(checkSubject),
            body: transformSpecialCharacters(body),
            body_html: removeLineBreaksFromString(transformSpecialCharacters(bodyHtml)),
        };
        if (value[value.length - 1] === ALL) {
            setSelectedChannel(selectedChannel.length === channels.length ? [] : channels.map((item) => item.id));

            return;
        }
        setSelectedChannel(value);
        getNotificationData(id, data, blockType);
    };

    const handleChangeValue = (e) => {
        const { value, name } = e.target;
        const updatedData = {
            channels: selectedChannel,
            uuids,
            subject: transformSpecialCharacters(subject),
            body: transformSpecialCharacters(body),
            body_html: removeLineBreaksFromString(transformSpecialCharacters(bodyHtml)),
        };

        if (name === SUBJECT) {
            setSubject(value);
            updatedData.subject = transformSpecialCharacters(value);
        }

        if (name === BODY) {
            setBody(value);
            updatedData.body = transformSpecialCharacters(value);
        }

        if (name === BODY_HTML) {
            setBodyHtml(value);
            updatedData.body_html = removeLineBreaksFromString(transformSpecialCharacters(value));
        }
        getNotificationData(id, updatedData, blockType);
    };

    return (
        <>
            <MeshBotSelectField
                label={t(EZLOGIC_TITLE_USER)}
                selectValue={selectedUsers}
                handleChange={handleChangeUser}
                infoText={info.text.selectUsers}
                selectionAttributes={{
                    renderValue: (selectedUsers) => selectedUsers.join(', '),
                    multiple: true,
                }}
            >
                {userData &&
                    userData.map((item) => (
                        <MenuItem className={styles.menuItem} key={item.PK_User} value={item.Username}>
                            <ListItemIcon>
                                <Checkbox color={PRIMARY} checked={selectedUsers.indexOf(item.Username) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={item.Username} />
                        </MenuItem>
                    ))}
            </MeshBotSelectField>
            <MeshBotSelectField
                selectValue={selectedChannel}
                handleChange={handleChangeChannel}
                infoText={info.text.selectChannels}
                label={t(EZLOGIC_TITLE_CHANNELS)}
                selectionAttributes={{
                    renderValue: (selectedChannel) => selectedChannel.join(', '),
                    multiple: true,
                }}
            >
                <MenuItem className={styles.menuItem} value={ALL}>
                    <ListItemIcon>
                        <Checkbox
                            color={PRIMARY}
                            checked={isAllSelected}
                            indeterminate={selectedChannel.length > 0 && selectedChannel.length < channels.length}
                        />
                    </ListItemIcon>
                    <ListItemText primary={SELECT_ALL} />
                </MenuItem>
                {channel &&
                    channel.map((item) => (
                        <MenuItem className={styles.menuItem} key={item.id} value={item.id}>
                            <ListItemIcon>
                                <Checkbox color={PRIMARY} checked={selectedChannel.indexOf(item.id) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={item.id} />
                        </MenuItem>
                    ))}
            </MeshBotSelectField>
            <ActionDelay
                id={id}
                delayDisabled={true}
                currentRule={currentRule}
                actionBlockName={actionBlockName}
                type={selectedFieldTrigger}
                isShowedDelay={isShowedDelay}
                onUpdateActionDelay={onUpdateActionDelay}
                onChangeDelay={onChangeDelay}
                onSetIsShowedDelay={onSetIsShowedDelay}
            />
            <div className={notificationStyles.area}>
                {(selectedChannel && selectedChannel.includes('email')) || selectedChannel.length === 0 ? (
                    <FormControl className={notificationStyles.subject}>
                        <span className={notificationStyles.label}>{t(EZLOGIC_TITLE_SUBJECT)}</span>
                        <InputMaterial
                            value={subject}
                            name={SUBJECT}
                            placeholder={t(EZLOGIC_TITLE_SUBJECT)}
                            onChange={handleChangeValue}
                            fullWidthInput
                            maxLength={MAX_LENGTH_SUBJECT}
                        />
                    </FormControl>
                ) : null}
            </div>
            <div className={notificationStyles.area}>
                <FormControl className={notificationStyles.body_wrapper}>
                    <span className={notificationStyles.label}>{t(EZLOGIC_TITLE_MESSAGE_BODY)}</span>
                    <InputMaterial
                        value={body}
                        name={BODY}
                        placeholder={t(EZLOGIC_TITLE_MESSAGE_BODY)}
                        multiline
                        fullWidth
                        onChange={handleChangeValue}
                        fullWidthInput
                        maxLength={MAX_LENGTH_BODY}
                    />
                </FormControl>
            </div>
            <div className={notificationStyles.area}>
                <FormControl className={notificationStyles.body_wrapper}>
                    <span className={notificationStyles.label}>{t(EZLOGIC_TITLE_MESSAGE_BODY_HTML)}</span>
                    <InputMaterial
                        value={bodyHtml}
                        name={BODY_HTML}
                        placeholder={t(EZLOGIC_TITLE_MESSAGE_BODY_HTML)}
                        multiline
                        fullWidth
                        onChange={handleChangeValue}
                        fullWidthInput
                    />
                </FormControl>
            </div>
        </>
    );
};

export default NotificationBlock;
