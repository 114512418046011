import React from 'react';

import { SystemHistoryTableContainer } from './SystemHistoryTableConrainer';
import SystemHistoryHeaderContainer from './SystemHistoryHeaderContainer';
import SystemHistorySearchContainer from './SystemHistorySearchContainer';

import { systemHistoryWrapper } from '../systemHistory.module.scss';

const SystemHistoryLayout = () => {
    return (
        <section className={systemHistoryWrapper}>
            <SystemHistoryHeaderContainer />
            <SystemHistorySearchContainer />
            <SystemHistoryTableContainer />
        </section>
    );
};

export default SystemHistoryLayout;
