import React, { memo, useMemo } from 'react';

import DataTreeView from './DataTreeView';

import { getResponseTree } from './utils';

const ResponseTreeContainer = ({ integrationPreviewResult, method }) => {
    const dataTree = useMemo(() => {
        if (!integrationPreviewResult || !method) {
            return null;
        }

        return getResponseTree(integrationPreviewResult, method);
    }, [method]);

    return dataTree && <DataTreeView dataTree={dataTree} />;
};

export default memo(ResponseTreeContainer);
