import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import { createTemplateForIncludeFunc, parseValueFromIncludeOrSliceFunction } from '../../../utils';
import { EMPTY_LINE } from '../../../constants';
import { EZLOGIC_TITLE_WORD_TO_BE_SEARCHED_FOR } from '../../../../../../../../constants/language_tokens';
import { useTranslate } from '../../../../../../../../features/languages';

export const CheckStringHasWord = ({ onChangeValue, defaultValue }) => {
    const [foundValue, setFoundValue] = useState('');
    const { t } = useTranslate();
    useEffect(() => {
        if (parseValueFromIncludeOrSliceFunction(defaultValue)) {
            const parsedValue = parseValueFromIncludeOrSliceFunction(defaultValue);
            setFoundValue(parsedValue);
        }
    }, []);

    const handleUpdateFoundValue = (e) => {
        const { value } = e.target;
        const updatedValue = value !== EMPTY_LINE ? createTemplateForIncludeFunc(value) : EMPTY_LINE;
        setFoundValue(value);
        onChangeValue(updatedValue);
    };

    return (
        <TextField
            required
            name="findMethodValue"
            label={t(EZLOGIC_TITLE_WORD_TO_BE_SEARCHED_FOR)}
            variant="outlined"
            value={foundValue}
            onChange={handleUpdateFoundValue}
        />
    );
};
