import React from 'react';
import { MenuItem } from '@material-ui/core';

import SelectComponent from '../../../SelectComponent';

import { BOOLEAN_TYPE_VALUES } from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';
import { useTranslate } from '../../../../../../../features/languages';

const ExpressionComparedBooleanValue = (props) => {
    const { comparingValue, onHandleChangeComparingValue } = props;
    const { t } = useTranslate();

    return (
        <SelectComponent
            info={info.text.value}
            label={info.hints.value}
            value={comparingValue}
            onChange={onHandleChangeComparingValue}
        >
            {BOOLEAN_TYPE_VALUES.map((valueType) => {
                return (
                    <MenuItem key={valueType.value} value={valueType.value}>
                        {t(valueType.name)}
                    </MenuItem>
                );
            })}
        </SelectComponent>
    );
};

export default ExpressionComparedBooleanValue;
