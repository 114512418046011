import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import InfoButton from '../../../InfoButton';

import {
    ACTION_LANG_TAG_INPUT_NAME,
    INPUT_ADORONMENT_END_POSITION,
    INPUT_TYPE_TEXT,
    VARIABLES_FIELDS_LABELS,
} from '../../../../../../../constants/Variables';

import { info } from '../../../../utils';

import { actionTypeValueField } from './LocalVariableActionTypeValueBlock.module.scss';
import { useTranslate } from '../../../../../../../features/languages';

const LocalVariableActionTypeValueBlock = (props) => {
    const { value, onSetVariableValue } = props;
    const { t } = useTranslate();

    return (
        <>
            <TextField
                value={value?.text}
                type={INPUT_TYPE_TEXT}
                placeholder={t(VARIABLES_FIELDS_LABELS.ACTION.TEXT)}
                name={INPUT_TYPE_TEXT}
                onChange={onSetVariableValue}
                label={t(info.hints.value)}
                className={actionTypeValueField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={INPUT_ADORONMENT_END_POSITION}>
                            <InfoButton>
                                <div dangerouslySetInnerHTML={{ __html: t(info.text.value) }}></div>
                            </InfoButton>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                type={INPUT_TYPE_TEXT}
                value={value?.lang_tag}
                className={actionTypeValueField}
                placeholder={t(VARIABLES_FIELDS_LABELS.ACTION.LANGUAGE_TAG)}
                name={ACTION_LANG_TAG_INPUT_NAME}
                onChange={onSetVariableValue}
                label={t(VARIABLES_FIELDS_LABELS.ACTION.LANGUAGE_TAG)}
                InputLabelProps={{ shrink: true }}
            />
        </>
    );
};

export default LocalVariableActionTypeValueBlock;
