import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { checkAllConsentMandatoryAccepted, getResetConsentPayload, parseMTML, updateConsentPayload } from '../../utils';
import PrivacyPolicyAction from '../../actions/EzloPoliciesAction';
import { SAVE } from '../../constants';

import styles from '../../components/EzloPoliciesLayout/ezloPolicies.module.scss';

const ConsentAndLegitimate = ({ policies, onSave }) => {
    const [checkboxState, setCheckboxState] = useState({});
    const [isAllMandatoryAccepted, setIsAllMandatoryAccepted] = useState(false);
    const dispatch = useDispatch();

    const html = parseMTML(policies?.consent?.description);

    const handleAccept = (consentItem) => {
        setCheckboxState({
            ...checkboxState,
            [consentItem.PK_ConsentItem]: !checkboxState[consentItem.PK_ConsentItem],
        });
    };

    useEffect(() => {
        getResetConsentPayload(policies?.setConsent);
    }, []);

    useEffect(() => {
        const updatedConsent = updateConsentPayload(policies?.setConsent, checkboxState);
        const isAllMandatoryAccepted = checkAllConsentMandatoryAccepted(updatedConsent);
        setIsAllMandatoryAccepted(isAllMandatoryAccepted);
        dispatch(PrivacyPolicyAction.setConsent(updatedConsent));
    }, [checkboxState]);

    return (
        <div className={styles.innermain}>
            <div className={styles.title}>{policies?.consent?.title}</div>
            <div className={styles.text}>
                <p dangerouslySetInnerHTML={{ __html: html }}></p>
            </div>
            <div className={styles.text}>
                {policies?.consent?.consentSections?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div>
                                <h3 className={styles.consentTitle}>{item?.Title}</h3>
                                <p className={styles.description}> {item?.Description}</p>
                            </div>

                            {item?.ConsentItems?.map((consentItem, index) => {
                                return (
                                    <div key={index}>
                                        <FormControlLabel
                                            className={styles.formControl}
                                            classes={{
                                                root: styles.formControlLabelRoot,
                                                label: styles.formControlLabel,
                                            }}
                                            control={
                                                <Checkbox
                                                    disableRipple
                                                    checked={checkboxState[consentItem.PK_ConsentItem] || false}
                                                    onChange={() => handleAccept(consentItem)}
                                                    className={styles.checkboxControl}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span
                                                    className={styles.label}
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseMTML(consentItem?.ShortDescription),
                                                    }}
                                                ></span>
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div className={styles.footer}>
                <div className={styles.button_wrapper}>
                    <Button
                        className={styles.button}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        disabled={!isAllMandatoryAccepted}
                    >
                        {SAVE}
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default ConsentAndLegitimate;
