import React, { memo } from 'react';

import AddressSectionContent from './AddressSectionContent';

import { addressColumn } from '../AccountForm/style.module.scss';

const AddressSection = ({ title, addressName, isDisabled }) => {
    return (
        <div className={addressColumn}>
            <h2>{title}</h2>

            <AddressSectionContent isDisabled={isDisabled} addressName={addressName} />
        </div>
    );
};

export default memo(AddressSection);
