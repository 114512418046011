import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classes from 'classnames';

import MeshBotWrapper from '../../MeshBot/MeshBotWrapper';
import MeshBotNameBlock from '../../MeshBot/MeshBotNameBlock';
import BuilderHeader from '../../components/BuilderHeader';
import GroupBlock from '../../components/groupBlock';
import TriggerBlock from '../../components/triggerBlock';
import BuilderFooter from '../../MeshBot/BuilderFooter';
import InfoButton from '../../components/InfoButton';
import MeshBotContent from '../../MeshBot/MeshBotContent';
import MeshBotSection from '../../MeshBot/MeshBotSection';
import DraggableActionTrigger from './DraggableActionTrigger';
import MeshBotSectionCaption from '../../components/MeshBotSectionCaption';
import { Header } from '../../../../../components';

import at from '../../../../../constants/ActionTypes/MeshBot';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';
import hash from '../../../../../constants/uniqueHash';
import blockDateTemplate from '../../../../../components/blockDateTemplate';
import {
    buildLocalMeshBotValidationState,
    findValidatedBlock,
    generateTriggerData,
    getTriggerValue,
    isDisabledSaveButton,
    checkMeshbotEditingFormFields,
    verifyForAbsenceIsOnceNode,
    verifyForAbsenceIntervalNode,
} from './utils';
import * as meshbot from '../../../../../constants/MeshbotConstant';
import { info } from '../../utils';
import { MESHBOT_SECTION_TYPE } from '../../constants';
import { MESHBOT_TYPES } from '../../../EzloMeshbots/constants';
import { LOCAL_MESHBOT_CREATING_PAGE, LOCAL_MESHBOT_EDITING_PAGE } from '../../../../../constants/ActionConfirmDialog';
import useUnsavedChangesWarning from '../../MeshBot/CustomHooks/useUnsavedChangesWarning';
import { DAILY, MESHBOT_NODE_TYPES } from '../../../../../constants/MeshbotConstant';
import useFeatureSupportChecker from '../../../../../hooks/useFeatureSupportChecker';

import {
    mesh_bot__builder,
    mesh_bot__builder_action,
    mesh_bot__builder_trigger,
    mesh_bot__list_triggers,
    mesh_bot__tab,
    trueTab,
    mesh_bot__list_actions,
    mesh_bot__list_actions_empty,
} from '../../EzloMeshbot.module.scss';

import { activeConnection } from '../../components/TriggerBlock.module.scss';
import { ADVANCED_SCENES, CONTROLLER_FEATURES_NAMES } from '../../../../../constants/ControllerFeatures';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_ADDED,
    EZLOGIC_TITLE_CANNOT_BE_USED_WITH_OTHER_DATE_AND_TIME,
    EZLOGIC_TITLE_FALSE,
    EZLOGIC_TITLE_FIRMWARE_SHOULD_INCLUDE_ADVANCED_SCENES,
    EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY,
    EZLOGIC_TITLE_TRUE,
    EZLOGIC_TITLE_UPDATED,
} from '../../../../../constants/language_tokens';
import { ITEM_GROUPS_STATUS } from '../../../../../constants/ItemGroups';
import ItemGroupsActions from '../../../../../actions/ItemGroupsActions';
import meshBotAction from '../../../../../actions/MeshBotAction';
import { SUBSCRIPTIONS_STATUS } from '../../../../../constants/Subscription';
import SubscriptionActions from '../../../../../actions/SubscriptionActions';
import { LOCAL } from '../../constant';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && [children]}
        </div>
    );
}

const MeshBotLocalForm = (props) => {
    const [nameMeshBot, setNameMeshBot] = useState('');
    const [listRuleTriggers, setListRuleTriggers] = useState([]);
    const [currentDevices, setCurrentDevices] = useState([]);
    const [currentDevicesForAction, setCurrentDevicesForAction] = useState([]);
    const [itemsDevice, setItemsDevice] = useState([]);
    const [deviceBlocks, setDeviceBlocks] = useState([]);
    const [currentExpression, setCurrentExpression] = useState([]);
    const [groupActionsList, setGroupActionsList] = useState([]);
    const [groupElseActionsList, setGroupElseActionsList] = useState([]);
    const [valueTab, setValue] = useState(0);
    const [validationStateObject, setValidationStateObject] = useState(meshbot.VALIDATION_STATE);
    const [actionsList, updateActionsList] = useState([]);
    const [elseList, updateElseList] = useState([]);
    const [showSectionFunction, setShowSectionFunction] = useState(false);
    const [isDraggableAction, setIsDraggableAction] = useState(false);

    const setDirty = useUnsavedChangesWarning();
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const {
        serial,
        model,
        typeMeshbot,
        typeForm,
        devices,
        devicesForAction,
        items,
        ruleTriggers,
        listDevicesBlocks,
        expressionList,
        MeshBotAction,
        NotificationActions,
        onOpenSaveConfirmDialog,
        initialRule,
        rooms,
        selectedRule,
        scenesList,
        channels,
        uuids,
        users,
        isEditing,
        listLuaScripts,
        scriptNotification,
        interfaceJSONItems,
        errorsScript,
        currentMeshbot,
        exceptions,
        isWsRequestStatus,
        isWsRequestStatusMessage,
        MainAction,
        isDirty,
        wizardMode,
        optionType,
        subscriptionStatus,
        itemGroupsStatus,
    } = props;

    const [previousSerial, setPreviousSerial] = useState(serial);
    const [whenBlockSelectedRule] = selectedRule.when;
    const [thenBlockSelectedRule] = selectedRule.then;
    const [elseBlockSelectedRule] = selectedRule.else;
    const inputNameClasses = `meshbot-name ${nameMeshBot ? 'is-valid' : ''}`;

    const isMultipleSupportedDateAndTime = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_50,
    );
    const isSupportsUpdatedAdvancedScenesForWeeks = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_52,
    );

    const isSupportsIntervalTogetherWithOtherTimeEvents = useFeatureSupportChecker(
        CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
        ADVANCED_SCENES.VERSION_1_55,
    );

    const handlePreventDraggable = useCallback((value) => {
        setIsDraggableAction(value);
    }, []);

    useEffect(() => {
        if (ruleTriggers.length > 1) {
            setShowSectionFunction(true);
        } else {
            setShowSectionFunction(false);
        }
    }, [ruleTriggers]);

    useEffect(() => {
        if (isEditing) {
            MainAction.setStatePageBeingEdited(LOCAL_MESHBOT_EDITING_PAGE);
        } else {
            MainAction.setStatePageBeingEdited(LOCAL_MESHBOT_CREATING_PAGE);
        }

        return () => MainAction.setStatePageBeingEdited({});
    }, []);

    useEffect(() => {
        if (!nameMeshBot && !whenBlockSelectedRule && !thenBlockSelectedRule && !elseBlockSelectedRule) {
            setDirty(false);
            MainAction.setConfirmationUser(false);
        } else if (
            checkMeshbotEditingFormFields(
                validationStateObject.isEdit,
                nameMeshBot,
                whenBlockSelectedRule,
                thenBlockSelectedRule,
                elseBlockSelectedRule,
            )
        ) {
            setDirty(false);
            MainAction.setConfirmationUser(false);
        } else if (validationStateObject.isEdit) {
            if (!isDirty) {
                setDirty(true);
                MainAction.setConfirmationUser(true);
            }
        }
    }, [
        nameMeshBot,
        whenBlockSelectedRule,
        thenBlockSelectedRule,
        elseBlockSelectedRule,
        validationStateObject.isEdit,
    ]);

    // TODO: many dependencies in one use effect, do we need them all?
    useEffect(() => {
        if (ruleTriggers !== listRuleTriggers) {
            setListRuleTriggers(ruleTriggers);
        }

        if (listDevicesBlocks !== deviceBlocks) {
            setDeviceBlocks(listDevicesBlocks);
        }

        if (expressionList !== currentExpression) {
            setCurrentExpression(expressionList);
        }

        if (props[at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions] !== groupActionsList) {
            updateActionsList(props[at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions]);
            setGroupActionsList(props[at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions]);
        }

        if (props[at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions] !== groupElseActionsList) {
            updateElseList(props[at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions]);
            setGroupElseActionsList(props[at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions]);
        }

        if (devices !== currentDevices) {
            setCurrentDevices(devices);
        }

        if (devicesForAction !== currentDevicesForAction) {
            setCurrentDevicesForAction(devicesForAction);
        }
    }, [
        ruleTriggers,
        initialRule,
        listDevicesBlocks,
        expressionList,
        devices,
        props[at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions],
        props[at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions],
    ]);

    useEffect(() => {
        if (selectedRule && selectedRule.name !== nameMeshBot) {
            setNameMeshBot(selectedRule.name);
        }

        setValidationStateObject(buildLocalMeshBotValidationState(selectedRule, initialRule, interfaceJSONItems));
    }, [selectedRule]);

    useEffect(() => {
        if (scriptNotification === meshbot.SET_SUCCESS || scriptNotification === meshbot.ADD_SUCCESS) {
            toast(
                `${t(EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY)} ${
                    scriptNotification === meshbot.ADD_SUCCESS ? t(EZLOGIC_TITLE_ADDED) : t(EZLOGIC_TITLE_UPDATED)
                }`,
                {
                    type: TOAST_TYPE.SUCCESS,
                },
            );
        }

        if (scriptNotification === meshbot.REJECTED) {
            toast(errorsScript, { type: TOAST_TYPE.ERROR });
        }
        MeshBotAction.clearScriptNotification();
    }, [scriptNotification]);

    useEffect(() => {
        if (isWsRequestStatus === 'success') {
            toast(isWsRequestStatusMessage, { type: TOAST_TYPE.SUCCESS });
        }

        if (isWsRequestStatus === 'rejected') {
            toast(isWsRequestStatusMessage, { type: TOAST_TYPE.ERROR });
        }

        MeshBotAction.clearWsRequestStatus();
    }, [isWsRequestStatus]);

    useEffect(() => {
        dispatch(ItemGroupsActions.subscribeItemGroupCreated(previousSerial, onItemGroupCreatedBroadcast));

        if (serial !== previousSerial) {
            dispatch(ItemGroupsActions.subscribeItemGroupCreated(serial, onItemGroupCreatedBroadcast));
            dispatch(ItemGroupsActions.unsubscribeItemGroupCreated(previousSerial));
            setPreviousSerial(serial);
        }

        return () => {
            dispatch(ItemGroupsActions.unsubscribeItemGroupCreated(previousSerial));
        };
    }, [serial]);

    useEffect(() => {
        if (
            itemGroupsStatus === ITEM_GROUPS_STATUS.COMPLETED &&
            subscriptionStatus === SUBSCRIPTIONS_STATUS.COMPLETED
        ) {
            dispatch(meshBotAction.savingMeshBot(selectedRule, t));
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.DEFAULT));
            dispatch(ItemGroupsActions.setCapabilitiesList({}));
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.DEFAULT));
            dispatch(SubscriptionActions.clearSubscriptionState());
        }

        if (itemGroupsStatus === ITEM_GROUPS_STATUS.COMPLETED && subscriptionStatus === SUBSCRIPTIONS_STATUS.NOT_USED) {
            dispatch(meshBotAction.savingMeshBot(selectedRule, t));
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.DEFAULT));
            dispatch(ItemGroupsActions.setCapabilitiesList({}));
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.DEFAULT));
        }

        if (itemGroupsStatus === ITEM_GROUPS_STATUS.NOT_USED && subscriptionStatus === SUBSCRIPTIONS_STATUS.NOT_USED) {
            dispatch(meshBotAction.savingMeshBot(selectedRule, t));
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.DEFAULT));
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.DEFAULT));
        }

        if (itemGroupsStatus === ITEM_GROUPS_STATUS.NOT_USED && subscriptionStatus === SUBSCRIPTIONS_STATUS.COMPLETED) {
            dispatch(meshBotAction.savingMeshBot(selectedRule, t));
            dispatch(ItemGroupsActions.setItemGroupsFlagStatus(ITEM_GROUPS_STATUS.DEFAULT));
            dispatch(SubscriptionActions.setSubscriptionFlagStatus(SUBSCRIPTIONS_STATUS.DEFAULT));
            dispatch(SubscriptionActions.clearSubscriptionState());
        }
    }, [subscriptionStatus, itemGroupsStatus]);

    const onItemGroupCreatedBroadcast = (response) => {
        dispatch(ItemGroupsActions.processCreatedItemGroupForLocalMeshBot(response));
    };

    const handleChangeTabs = (event, newValue) => setValue(newValue);

    const handleAddTrigger = (type, idGroup, specialType) => {
        const { triggerData } = generateTriggerData(type);

        MeshBotAction.addTriggerWhen(triggerData, idGroup, specialType);
    };

    const handleAddExceptionTrigger = (actionId, actionBlockName, type, idGroup) => {
        const { triggerData } = generateTriggerData(type);

        MeshBotAction.addExceptionTriggerWhen({ data: triggerData, idGroup, actionId, actionBlockName });
    };

    const handleDeleteExceptionTrigger = (id, idGroup, actionId, actionBlockName) => {
        MeshBotAction.deleteExceptionTrigger({ id, actionId, actionBlockName, idGroup });
    };

    const handleToggleExceptionBlock = (actionId, actionBlockName) => {
        MeshBotAction.toggleExceptionBlock(actionId, actionBlockName);
    };

    useEffect(() => {
        if (users && users.length === 0) {
            NotificationActions.getUsersData();
        }

        if (channels && channels.length === 0) {
            NotificationActions.getChannelsData();
        }
    }, []);

    const handleUpdateTriggerNode = (e, id, field, idGroup) => {
        const { value } = e.target;
        const result = getTriggerValue(
            value,
            id,
            idGroup,
            listRuleTriggers,
            isMultipleSupportedDateAndTime,
            optionType,
            isSupportsIntervalTogetherWithOtherTimeEvents,
        );

        if (result) {
            MeshBotAction.updateTriggerNode(result, id, idGroup);
        }
    };

    const handleUpdateExceptionTrigger = (e, id, field, idGroup, actionId) => {
        const { value } = e.target;
        const currentException = exceptions.find((exception) => exception.actionId === actionId);
        const result = getTriggerValue(value, id, idGroup, currentException?.triggers, isMultipleSupportedDateAndTime);

        if (result) {
            MeshBotAction.updateExceptionTriggerNode(result, id, idGroup);
        }
    };

    const handleUpdateMeshBotTriggerValue = (e, id, idGroup) => {
        const { value } = e.target;

        MeshBotAction.updateMeshBotTriggerValue(value, id, idGroup);
    };

    const handleUpdateMeshBotExceptionTriggerValue = (e, id, idGroup) => {
        const { value } = e.target;

        MeshBotAction.updateMeshBotExceptionTriggerValue(value, id, idGroup);
    };

    const getCurrentDeviceId = (e, currentTriggerId, idGroup) => {
        const deviceId = e.target.value.split('/');
        const itemsList = items.filter((device) => device.deviceId === deviceId[0]);

        setItemsDevice(
            itemsList.map((device) => {
                return { name: device.name, value: device.name, _id: device._id };
            }),
        );

        MeshBotAction.updateExpression(currentTriggerId, { name: deviceId[1] }, idGroup);
    };

    const handleUpdateTriggerNot = (value, id, field, idGroup) => {
        MeshBotAction.updateTriggerWhen(value, id, field, idGroup);
        MeshBotAction.updateSelectedRuleNot(value, id, field, idGroup);
    };

    const updateTriggerType = (value, id, field, idGroup) => {
        if (value === meshbot.OPERATOR_AND) {
            if (!verifyForAbsenceIsOnceNode(ruleTriggers, id)) {
                toast(t(EZLOGIC_TITLE_CANNOT_BE_USED_WITH_OTHER_DATE_AND_TIME), {
                    type: TOAST_TYPE.WARNING,
                });

                return;
            }

            if (!verifyForAbsenceIntervalNode(ruleTriggers, id) && !isSupportsIntervalTogetherWithOtherTimeEvents) {
                toast(t(EZLOGIC_TITLE_FIRMWARE_SHOULD_INCLUDE_ADVANCED_SCENES), {
                    type: TOAST_TYPE.WARNING,
                    autoClose: meshbot.DELAY_FOR_WARNING_FOR_THE_INTERVAL,
                });

                return;
            }
        }

        MeshBotAction.updateTriggerWhen(value, id, field, idGroup);
        MeshBotAction.updateSelectedRuleNot(value, id, field, idGroup);
    };

    const changeNameGroup = (value, id) => {
        MeshBotAction.updateNameGroup(id, value);
    };

    const handleSetFieldInGroupException = useCallback((value, idGroup, field, prevGroup, actionId) => {
        MeshBotAction.updateFieldInGroupException(idGroup, actionId, value, field);
    }, []);

    const handleUpdateHouseModeTrigger = (idMeshBot, block, idGroup) => {
        MeshBotAction.updateHouseModeTrigger(idMeshBot, block, idGroup);
    };

    const handleUpdateHouseModeExceptionTrigger = (idMeshBot, block, idGroup) => {
        MeshBotAction.updateHouseModeExceptionTrigger(idMeshBot, block, idGroup);
    };

    const handleSelectDevice = (e, triggerId, idGroup, selectedFieldTrigger) => {
        if (
            selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE ||
            selectedFieldTrigger === MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED
        ) {
            const { _id: deviceId, name: deviceName } = e;

            MeshBotAction.updateDeviceAdvancedNode({
                ruleId: triggerId,
                name: deviceName,
                deviceId,
                idGroup,
            });
        }
    };

    const getBlocksInDevice = (e, idDevice, idGroup) => {
        const { value } = e?.target;

        const device = value ? value.split(/\/(.+)/) : e._id;

        const data = {
            blockType: at.MESHBOT_LOCAL_TRIGGER_BLOCKS.when,
            devices: value ? [device[0]] : [e._id],
        };

        MeshBotAction.getDeviceBlocks(serial, idDevice, data, items, e?.target?.value ? device[1] : e.name, idGroup);
    };

    const updateFieldVidoo = useCallback(
        (id, field, idGroup) => (e) => {
            MeshBotAction.updateTriggerWhen(e.target.value, id, field, idGroup);
        },
        [],
    );

    const selectedFieldDate = (e, id, idGroup) => {
        const { value } = e.target;
        const result = getTriggerValue(
            value,
            id,
            idGroup,
            listRuleTriggers,
            isMultipleSupportedDateAndTime,
            optionType,
            isSupportsIntervalTogetherWithOtherTimeEvents,
        );

        if (result) {
            const block =
                result === at.CUSTOM || result === at.DAILY
                    ? null
                    : [blockDateTemplate(result, null, isSupportsUpdatedAdvancedScenesForWeeks)];

            MeshBotAction.updateDateTriggers(result, id, meshbot.SELECTED_FIELD_DATE, block, idGroup);
        }
    };

    const handleSetDateValue = (value, idDevice, idGroup, tag) => {
        MeshBotAction.updateDateValue(value, idDevice, idGroup, tag);
    };

    const handleUpdateExceptionDateNode = (e, idDevice, idGroup, type, actionId) => {
        const { value } = e.target;
        const block = value === at.CUSTOM || value === at.DAILY ? null : [blockDateTemplate(value)];

        MeshBotAction.setExceptionDateNode(value, idDevice, actionId, meshbot.SELECTED_FIELD_DATE, block, idGroup);
    };

    const handleUpdateExceptionDateValue = (value, idDevice, idGroup, tag, actionId) => {
        MeshBotAction.setExceptionDateValue(value, idDevice, idGroup, tag, actionId);
    };

    const handleUpdateExceptionSpecificDate = (e, idDevice, type, idGroup, actionId, sunState) => {
        let value = null;

        if (typeof e === meshbot.OBJECT) {
            value = e?.target?.value;
        } else {
            value = e;
        }
        const blocks = [blockDateTemplate(value, type, null, sunState)];
        let betweenType = meshbot.unBetweenType;

        MeshBotAction.setExceptionDateNode(value, idDevice, actionId, meshbot.SELECTED_SPECIFIC_DATE, blocks, idGroup);

        if (type === meshbot.CUSTOM) {
            if (value === meshbot.BETWEEN) {
                // eslint-disable-next-line
                betweenType = meshbot.betweenType;
            }

            betweenType.forEach((dateType) => {
                MeshBotAction.setExceptionDateNode(dateType.value, idDevice, actionId, dateType.label, null, idGroup);
            });
        }
    };

    const selectedMeshBotTriggerState = (value, idMeshBot, block, idGroup) => {
        MeshBotAction.updateMeshBotTrigger(value, idMeshBot, block, idGroup);
    };

    const selectedMeshBotExceptionTriggerState = (value, idMeshBot, block, idGroup) => {
        MeshBotAction.updateMeshBotExceptionTrigger(value, idMeshBot, block, idGroup);
    };

    const handleResetMeshBotTriggerBlocks = (idMeshBot, idGroup) => {
        MeshBotAction.resetMeshBotTriggerBlocks(idMeshBot, idGroup);
    };

    const handleResetMeshBotExceptionTriggerBlocks = (idMeshBot, idGroup) => {
        MeshBotAction.resetMeshBotExceptionTriggerBlocks(idMeshBot, idGroup);
    };

    const selectedVidooTrigger = (idTrigger, block, idGroup) => {
        MeshBotAction.updateMeshBotTrigger(null, idTrigger, block, idGroup);
    };

    const handleSetExceptionFieldValue = useCallback((value, idDevice, field, idGroup, actionId) => {
        MeshBotAction.setExceptionDateNode(value, idDevice, actionId, field, null, idGroup);
    }, []);

    const handleSetExceptionFunctionInTrigger = useCallback((value, id, idGroup, actionId) => {
        MeshBotAction.setExceptionFunctionInTrigger({ value, id, idGroup, actionId });
    }, []);

    const handleSetFieldValue = useCallback((value, idDevice, field, idGroup) => {
        MeshBotAction.updateDateTriggers(value, idDevice, field, null, idGroup);
    }, []);
    // eslint-disable-next-line
    const selectedSpecificDate = (e, idDevice, type, idGroup, actionId = '', sunState) => {
        if (type !== meshbot.CUSTOM && type !== DAILY) {
            return;
        }

        let value = null;

        if (typeof e === meshbot.OBJECT) {
            value = e?.target?.value;
        } else {
            value = e;
        }

        const blocks = [blockDateTemplate(value, type, null, sunState)];
        let betweenType = meshbot.unBetweenType;

        MeshBotAction.updateDateTriggers(value, idDevice, meshbot.SELECTED_SPECIFIC_DATE, blocks, idGroup);
        if (type === meshbot.CUSTOM) {
            if (value === meshbot.BETWEEN) {
                // eslint-disable-next-line
                betweenType = meshbot.betweenType;
            }

            betweenType.forEach((dateType) => {
                MeshBotAction.updateDateTriggers(dateType.value, idDevice, dateType.label, null, idGroup);
            });
        }
    };

    const updateFieldBlock = (value, idDevice, idBlock, idGroup, typeComp) => {
        MeshBotAction.updateRuleTriggers(idDevice, idBlock, value, idGroup, typeComp);
        MeshBotAction.updateSelectedRule(idDevice, idBlock, value, idGroup, typeComp);
    };

    const addBlock = (blockId, id, idGroup) => {
        if (blockId) {
            let result = [];

            const getBlock = (data) => {
                return data.map((device) => {
                    if (device.id === id) {
                        result = device.blocks.filter((elem) => elem.blockId === blockId);
                    }
                });
            };

            if (idGroup) {
                const observerTrigger = (blocks) => {
                    blocks.forEach((block) => {
                        if (block && block.id === idGroup) {
                            getBlock(block.blocks);
                        } else if (block && block.type === at.GROUP) {
                            observerTrigger(block.blocks);
                        }
                    });
                };

                observerTrigger(ruleTriggers);
            } else {
                getBlock(ruleTriggers);
            }

            MeshBotAction.addBlockWhen(result, id, idGroup);
            MeshBotAction.changeActiveBlock(blockId, id, idGroup);
        }
    };

    const addActionBlock = (blockId, id, blockName) => {
        if (blockId) {
            const getActionBlock = (actionList) => {
                actionList.forEach((device) => {
                    if (device.id === id) {
                        const result = device.blocks.filter((elem) => elem._tempId === blockId);
                        MeshBotAction.addBlockAction(result, id, blockName);
                    }
                });
            };

            if (blockName === 'then') {
                getActionBlock(groupActionsList);
            } else {
                getActionBlock(groupElseActionsList);
            }

            MeshBotAction.setActiveBlockAction(blockId, id, blockName);
        }
    };

    const changeExpression = (e, id, idGroup) => {
        const [expression] = currentExpression.filter((device) => device.name === e.target.value);
        const newExpression = {
            code: expression.code,
            nameExpression: expression.name,
            selectExpression: expression.name,
            name: expression.params.device_item_names[0].deviceName,
            nameVar: expression.params.device_item_names[0].name,
            nameItem: expression.params.device_item_names[0].itemName,
        };
        MeshBotAction.updateExpression(id, newExpression, idGroup);
    };

    const handlerUpdateTriggerBlock = (idTrig, block, idBlock, idGroup) => {
        MeshBotAction.updateTriggerBlock(idTrig, block, idBlock, idGroup);
    };

    const checkedNotRadio = (value) => {
        if (value === meshbot.OPERATOR_AND) {
            if (!verifyForAbsenceIsOnceNode(ruleTriggers)) {
                toast(t(EZLOGIC_TITLE_CANNOT_BE_USED_WITH_OTHER_DATE_AND_TIME), {
                    type: TOAST_TYPE.WARNING,
                });

                return;
            }

            if (!verifyForAbsenceIntervalNode(ruleTriggers) && !isSupportsIntervalTogetherWithOtherTimeEvents) {
                toast(t(EZLOGIC_TITLE_FIRMWARE_SHOULD_INCLUDE_ADVANCED_SCENES), {
                    type: TOAST_TYPE.WARNING,
                    autoClose: meshbot.DELAY_FOR_WARNING_FOR_THE_INTERVAL,
                });

                return;
            }
        }

        MeshBotAction.updateSelectedRuleNot(value, null, meshbot.OPERATOR_TYPE);
    };

    const AddActionThen = (blockName) =>
        MeshBotAction.addTriggerThen({ id: hash(), _tempId: hash(), isOpenedExceptionBlock: false }, blockName);

    const updateTriggerAction = (e, id, field, blockName) => {
        MeshBotAction.updateTriggerThen(e.target.value, id, field, null, null, blockName);
    };

    const onSelectedMeshBotAction = (value, id, blockName, blockId) => {
        MeshBotAction.updateMeshBotAction(value, id, blockName, blockId);
    };

    const getNotificationData = (idDevice, params, blockName) =>
        MeshBotAction.getNotificationAction(idDevice, params, blockName);

    const getDeviceInBlocksAction = (e, idDevice, blockName, _id, deviceCategory) => {
        if (!e && deviceCategory && deviceCategory === 'camera') {
            const data = { deviceIds: [_id], value: 1, type: 'vidoo' };

            MeshBotAction.getDeviceBlocksAction(serial, idDevice, data, blockName);
        } else {
            const data = {
                deviceIds: e?.target?.value ? [e.target.value] : [e._id] ? [e._id] : '',
            };

            MeshBotAction.getDeviceBlocksAction(serial, idDevice, data, blockName);
        }
    };

    const updateFieldActionBlock = (value, idDevice, idBlock, blockName) => {
        MeshBotAction.setGroupActionsValue(idDevice, idBlock, value, blockName);
        MeshBotAction.setSelectedValueAction(idDevice, idBlock, value, blockName);
    };

    const updateTriggerBlockFunction = (functionData, idBlock, idGroup) => {
        MeshBotAction.updateTriggerFunction(functionData, idBlock, idGroup);
    };

    const updateTriggerSectionFunction = (functionData) => {
        MeshBotAction.updateTriggerSectionFunction(functionData);
    };

    const setNameRule = (e) => {
        MeshBotAction.setNameMeshBot(e.target.value, at.LOCAL);
        setNameMeshBot(e.target.value);
    };

    const className = classes(mesh_bot__list_triggers, {
        [activeConnection]: listRuleTriggers.length > 1,
    });

    const handleOnDragEndDrop = (result, actionsList, type) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(actionsList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        if (type === 'then') {
            updateActionsList(items);
        }

        if (type === 'else') {
            updateElseList(items);
        }
        MeshBotAction.updateActionDragAndDrop(items, type);
    };

    const classTabs = classes(mesh_bot__tab, {
        [trueTab]: valueTab,
    });

    return (
        <MeshBotWrapper>
            <Header headerClassName="cloud-header" />
            <MeshBotNameBlock
                otherClass={inputNameClasses}
                value={nameMeshBot ? nameMeshBot : ''}
                title={nameMeshBot}
                onChange={(e) => setNameRule(e)}
                showDropdown
                wizardMode={wizardMode}
            />
            <MeshBotContent
                isDisabledSaveButton={isDisabledSaveButton(validationStateObject, subscriptionStatus, itemGroupsStatus)}
                isLoading={
                    subscriptionStatus === SUBSCRIPTIONS_STATUS.IN_PROGRESS ||
                    itemGroupsStatus === ITEM_GROUPS_STATUS.IN_PROGRESS
                }
                onClickSaveButton={() => onOpenSaveConfirmDialog()}
                type={LOCAL}
            >
                <MeshBotSection withMargin>
                    <MeshBotSectionCaption type={MESHBOT_SECTION_TYPE.TRIGGER} />
                    <div className={`${mesh_bot__builder} ${mesh_bot__builder_trigger}`}>
                        <BuilderHeader
                            type={at.TRIGGER}
                            optionType={selectedRule && selectedRule.optionType}
                            meshbotType={MESHBOT_TYPES.LOCAL}
                            onCheckedNotRadio={checkedNotRadio}
                            listRuleTriggers={listRuleTriggers}
                            showSectionFunction={showSectionFunction}
                            sectionFunctionInitState={selectedRule?.function}
                            onUpdateSectionFunction={updateTriggerSectionFunction}
                        />
                        <div className={className}>
                            {listRuleTriggers.map((device) => {
                                if (device.type === at.GROUP) {
                                    return (
                                        <GroupBlock
                                            key={device.id}
                                            idGroup={device.id}
                                            ezloSerial={serial}
                                            model={model}
                                            {...device}
                                            currentItem={device}
                                            typeMeshbot={typeMeshbot}
                                            typeForm={typeForm}
                                            items={items}
                                            devices={devices}
                                            itemsDevice={itemsDevice}
                                            deviceBlocks={deviceBlocks}
                                            currentDeviceSelect={currentDevices}
                                            currentExpression={currentExpression}
                                            onUpdateTriggerNot={handleUpdateTriggerNot}
                                            onUpdateTrigger={handleUpdateTriggerNode}
                                            onUpdateMeshBotTriggerValue={handleUpdateMeshBotTriggerValue}
                                            onUpdateTriggerType={updateTriggerType}
                                            DeleteTrigger={MeshBotAction.deleteTrigger}
                                            onAddTrigger={handleAddTrigger}
                                            onChangeNameGroup={changeNameGroup}
                                            onSelected={handleSelectDevice}
                                            onSelectedDate={selectedFieldDate}
                                            onSetDateValue={handleSetDateValue}
                                            onSetSpecificDate={selectedSpecificDate}
                                            onSetFieldValue={handleSetFieldValue}
                                            onUpdateFieldBlock={updateFieldBlock}
                                            onAddBlock={addBlock}
                                            onUpdateFieldVidoo={updateFieldVidoo}
                                            onGetCurrentDeviceId={getCurrentDeviceId}
                                            UpdateExpression={MeshBotAction.updateExpression}
                                            changeExpression={changeExpression}
                                            CreateExpression={MeshBotAction.createExpression}
                                            DeleteExpression={MeshBotAction.deleteExpression}
                                            handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                            foundValidatedWhenGroup={findValidatedBlock(
                                                device.id,
                                                at.MESHBOT_LOCAL_TRIGGER_BLOCKS.when,
                                                validationStateObject,
                                            )}
                                            scenesList={scenesList}
                                            onSelectedMeshBotTriggerState={selectedMeshBotTriggerState}
                                            onResetMeshBotTriggerBlocks={handleResetMeshBotTriggerBlocks}
                                            onUpdateTriggerBlockFunction={updateTriggerBlockFunction}
                                            onSelectedVidooTrigger={selectedVidooTrigger}
                                            currentMeshbot={currentMeshbot}
                                            MeshBotAction={MeshBotAction}
                                            unlatch={MeshBotAction.unlatch}
                                            onUpdateHouseModeTrigger={handleUpdateHouseModeTrigger}
                                        />
                                    );
                                }

                                return (
                                    <TriggerBlock
                                        key={device.id}
                                        ezloSerial={serial}
                                        model={model}
                                        {...device}
                                        typeMeshbot={typeMeshbot}
                                        typeForm={typeForm}
                                        currentItem={device}
                                        items={items}
                                        devices={devices}
                                        rooms={rooms}
                                        itemsDevice={itemsDevice}
                                        deviceBlocks={deviceBlocks}
                                        currentDeviceSelect={currentDevices}
                                        currentExpression={currentExpression}
                                        onUpdateTriggerNot={handleUpdateTriggerNot}
                                        onUpdateTrigger={handleUpdateTriggerNode}
                                        onUpdateMeshBotTriggerValue={handleUpdateMeshBotTriggerValue}
                                        DeleteTrigger={MeshBotAction.deleteTrigger}
                                        onSelected={handleSelectDevice}
                                        onSelectedDate={selectedFieldDate}
                                        onSetDateValue={handleSetDateValue}
                                        onSetSpecificDate={selectedSpecificDate}
                                        onSetFieldValue={handleSetFieldValue}
                                        onSelectedMeshBotTriggerState={selectedMeshBotTriggerState}
                                        onResetMeshBotTriggerBlocks={handleResetMeshBotTriggerBlocks}
                                        onUpdateFieldBlock={updateFieldBlock}
                                        onUpdateFieldVidoo={updateFieldVidoo}
                                        onGetCurrentDeviceId={getCurrentDeviceId}
                                        onAddBlock={addBlock}
                                        UpdateExpression={MeshBotAction.updateExpression}
                                        changeExpression={changeExpression}
                                        CreateExpression={MeshBotAction.createExpression}
                                        DeleteExpression={MeshBotAction.deleteExpression}
                                        handlerUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                        foundValidatedWhenBlock={findValidatedBlock(
                                            device.id,
                                            at.MESHBOT_LOCAL_TRIGGER_BLOCKS.when,
                                            validationStateObject,
                                        )}
                                        scenesList={scenesList}
                                        onUpdateTriggerBlockFunction={updateTriggerBlockFunction}
                                        onSelectedVidooTrigger={selectedVidooTrigger}
                                        currentMeshbot={currentMeshbot}
                                        MeshBotAction={MeshBotAction}
                                        onUpdateHouseModeTrigger={handleUpdateHouseModeTrigger}
                                        optionType={optionType}
                                    />
                                );
                            })}
                        </div>
                        <BuilderFooter
                            type={at.TRIGGER}
                            meshbotType={MESHBOT_TYPES.LOCAL}
                            onAddTrigger={handleAddTrigger}
                            listRuleTriggers={listRuleTriggers}
                            onUpdateSectionFunction={updateTriggerSectionFunction}
                            showSectionFunction={showSectionFunction}
                            sectionFunctionInitState={selectedRule?.function}
                        />
                    </div>
                </MeshBotSection>
                <MeshBotSection>
                    <MeshBotSectionCaption type={MESHBOT_SECTION_TYPE.ACTION} />
                    <div className={`${mesh_bot__builder} ${mesh_bot__builder_action}`}>
                        <Tabs className={classTabs} value={valueTab} onChange={handleChangeTabs}>
                            <Tab disableRipple label={t(EZLOGIC_TITLE_TRUE).toUpperCase()} />
                            <Tab disableRipple label={t(EZLOGIC_TITLE_FALSE).toUpperCase()} />
                            <InfoButton tabTrue>
                                <div dangerouslySetInnerHTML={{ __html: t(info.text.trueAction) }}></div>
                            </InfoButton>
                            <InfoButton tabFalse>
                                <div dangerouslySetInnerHTML={{ __html: t(info.text.falseAction) }}></div>
                            </InfoButton>
                        </Tabs>

                        <TabPanel value={valueTab} index={0}>
                            <div
                                className={classes(mesh_bot__list_actions, {
                                    [mesh_bot__list_actions_empty]: actionsList.length === 0,
                                })}
                            >
                                <DragDropContext onDragEnd={(e) => handleOnDragEndDrop(e, actionsList, 'then')}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (
                                            <ul
                                                className="characters"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {actionsList.map((device, index) => {
                                                    return (
                                                        <DraggableActionTrigger
                                                            key={device.id ? device.id : device._tempId}
                                                            device={device}
                                                            index={index}
                                                            serial={serial}
                                                            items={items}
                                                            model={model}
                                                            devices={devices}
                                                            rooms={rooms}
                                                            typeMeshbot={typeMeshbot}
                                                            typeForm={typeForm}
                                                            exceptions={exceptions}
                                                            MeshBotAction={MeshBotAction}
                                                            currentDeviceAction={currentDevicesForAction}
                                                            onUpdateTriggerAction={updateTriggerAction}
                                                            onSelected={getDeviceInBlocksAction}
                                                            onSelectedMeshBotAction={onSelectedMeshBotAction}
                                                            currentRule={selectedRule}
                                                            onUpdateFieldActionBlock={updateFieldActionBlock}
                                                            onAddActionBlock={addActionBlock}
                                                            actionBlockName={meshbot.ACTION_THEN}
                                                            foundValidatedThenBlock={findValidatedBlock(
                                                                device.id,
                                                                'then',
                                                                validationStateObject,
                                                            )}
                                                            scenesList={scenesList}
                                                            channels={channels}
                                                            getNotificationData={getNotificationData}
                                                            usersData={uuids}
                                                            blockType="then"
                                                            isEditing={isEditing}
                                                            listLuaScripts={listLuaScripts}
                                                            onToggleExceptionBlock={handleToggleExceptionBlock}
                                                            isDragDisabled={isDraggableAction}
                                                            onPreventDraggable={handlePreventDraggable}
                                                            validationStateObject={validationStateObject}
                                                            onAddExceptionTrigger={handleAddExceptionTrigger}
                                                            itemsDevice={itemsDevice}
                                                            deviceBlocks={deviceBlocks}
                                                            currentDeviceSelect={currentDevices}
                                                            currentExpression={currentExpression}
                                                            onChangeExpression={changeExpression}
                                                            nameMeshBot={nameMeshBot}
                                                            currentMeshbot={currentMeshbot}
                                                            onAddBlock={addBlock}
                                                            onUpdateMeshBotTriggerValue={
                                                                handleUpdateMeshBotExceptionTriggerValue
                                                            }
                                                            onResetMeshBotTriggerBlocks={
                                                                handleResetMeshBotExceptionTriggerBlocks
                                                            }
                                                            onUpdateTrigger={handleUpdateExceptionTrigger}
                                                            onDeleteTrigger={handleDeleteExceptionTrigger}
                                                            onSelectedException={getBlocksInDevice}
                                                            onSelectedDate={handleUpdateExceptionDateNode}
                                                            onSetDateValue={handleUpdateExceptionDateValue}
                                                            onSetSpecificDate={handleUpdateExceptionSpecificDate}
                                                            onSetFieldValue={handleSetExceptionFieldValue}
                                                            onSelectedMeshBotTriggerState={
                                                                selectedMeshBotExceptionTriggerState
                                                            }
                                                            onUpdateFieldBlock={updateFieldBlock}
                                                            onUpdateFieldVidoo={updateFieldVidoo}
                                                            onGetCurrentDeviceId={getCurrentDeviceId}
                                                            onUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                                            onUpdateTriggerBlockFunction={
                                                                handleSetFieldInGroupException
                                                            }
                                                            onSetExceptionFunctionInTrigger={
                                                                handleSetExceptionFunctionInTrigger
                                                            }
                                                            onSelectedVidooTrigger={selectedVidooTrigger}
                                                            onChangeNameGroup={handleSetFieldInGroupException}
                                                            onUpdateTriggerType={handleSetFieldInGroupException}
                                                            onUpdateTriggerNot={handleSetFieldInGroupException}
                                                            onUpdateHouseModeTrigger={
                                                                handleUpdateHouseModeExceptionTrigger
                                                            }
                                                        />
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <BuilderFooter
                                type="actions"
                                meshbotType={MESHBOT_TYPES.LOCAL}
                                actionBlockName={at.MESHBOT_LOCAL_ACTION_BLOCKS.then.name}
                                onAddTrigger={AddActionThen}
                            />
                        </TabPanel>
                        <TabPanel value={valueTab} index={1}>
                            <div
                                className={classes(mesh_bot__list_actions, {
                                    [mesh_bot__list_actions_empty]: elseList.length === 0,
                                })}
                            >
                                <DragDropContext onDragEnd={(e) => handleOnDragEndDrop(e, elseList, 'else')}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (
                                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                {elseList.map((device, index) => {
                                                    return (
                                                        <DraggableActionTrigger
                                                            key={device.id ? device.id : device._tempId}
                                                            device={device}
                                                            index={index}
                                                            serial={serial}
                                                            items={items}
                                                            model={model}
                                                            devices={devices}
                                                            rooms={rooms}
                                                            typeMeshbot={typeMeshbot}
                                                            exceptions={exceptions}
                                                            MeshBotAction={MeshBotAction}
                                                            currentDeviceAction={currentDevicesForAction}
                                                            onUpdateTriggerAction={updateTriggerAction}
                                                            onSelected={getDeviceInBlocksAction}
                                                            onSelectedMeshBotAction={onSelectedMeshBotAction}
                                                            currentRule={selectedRule}
                                                            onUpdateFieldActionBlock={updateFieldActionBlock}
                                                            onAddActionBlock={addActionBlock}
                                                            actionBlockName={meshbot.ACTION_ELSE}
                                                            foundValidatedElseBlock={findValidatedBlock(
                                                                device.id,
                                                                'else',
                                                                validationStateObject,
                                                            )}
                                                            scenesList={scenesList}
                                                            channels={channels}
                                                            getNotificationData={getNotificationData}
                                                            usersData={uuids}
                                                            blockType="else"
                                                            isEditing={isEditing}
                                                            listLuaScripts={listLuaScripts}
                                                            onToggleExceptionBlock={handleToggleExceptionBlock}
                                                            isDragDisabled={isDraggableAction}
                                                            onPreventDraggable={handlePreventDraggable}
                                                            validationStateObject={validationStateObject}
                                                            onAddExceptionTrigger={handleAddExceptionTrigger}
                                                            itemsDevice={itemsDevice}
                                                            deviceBlocks={deviceBlocks}
                                                            currentDeviceSelect={currentDevices}
                                                            currentExpression={currentExpression}
                                                            onChangeExpression={changeExpression}
                                                            nameMeshBot={nameMeshBot}
                                                            currentMeshbot={currentMeshbot}
                                                            onAddBlock={addBlock}
                                                            onUpdateMeshBotTriggerValue={
                                                                handleUpdateMeshBotExceptionTriggerValue
                                                            }
                                                            onResetMeshBotTriggerBlocks={
                                                                handleResetMeshBotExceptionTriggerBlocks
                                                            }
                                                            onUpdateTrigger={handleUpdateExceptionTrigger}
                                                            onDeleteTrigger={handleDeleteExceptionTrigger}
                                                            onSelectedException={getBlocksInDevice}
                                                            onSelectedDate={handleUpdateExceptionDateNode}
                                                            onSetDateValue={handleUpdateExceptionDateValue}
                                                            onSetSpecificDate={handleUpdateExceptionSpecificDate}
                                                            onSetFieldValue={handleSetExceptionFieldValue}
                                                            onSelectedMeshBotTriggerState={
                                                                selectedMeshBotExceptionTriggerState
                                                            }
                                                            onUpdateFieldBlock={updateFieldBlock}
                                                            onUpdateFieldVidoo={updateFieldVidoo}
                                                            onGetCurrentDeviceId={getCurrentDeviceId}
                                                            onUpdateTriggerBlock={handlerUpdateTriggerBlock}
                                                            onUpdateTriggerBlockFunction={
                                                                handleSetFieldInGroupException
                                                            }
                                                            onSetExceptionFunctionInTrigger={
                                                                handleSetExceptionFunctionInTrigger
                                                            }
                                                            onSelectedVidooTrigger={selectedVidooTrigger}
                                                            onChangeNameGroup={handleSetFieldInGroupException}
                                                            onUpdateTriggerType={handleSetFieldInGroupException}
                                                            onUpdateTriggerNot={handleSetFieldInGroupException}
                                                        />
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <BuilderFooter
                                type="actions"
                                meshbotType={MESHBOT_TYPES.LOCAL}
                                actionBlockName={at.MESHBOT_LOCAL_ACTION_BLOCKS.else.name}
                                onAddTrigger={AddActionThen}
                            />
                        </TabPanel>
                    </div>
                </MeshBotSection>
            </MeshBotContent>
        </MeshBotWrapper>
    );
};

export default MeshBotLocalForm;

MeshBotLocalForm.propTypes = {
    serial: PropTypes.string,
    isWsRequestStatus: PropTypes.string,
    typeMeshbot: PropTypes.string,
    ruleTriggers: PropTypes.array,
    items: PropTypes.array,
    rooms: PropTypes.array,
    devices: PropTypes.array,
    expressionList: PropTypes.array,
    selectedRule: PropTypes.object,
    MeshBotAction: PropTypes.object,
    onOpenSaveConfirmDialog: PropTypes.func,
    scenesList: PropTypes.array,
    listLuaScripts: PropTypes.array,
    scriptNotification: PropTypes.string,
    errorsScript: PropTypes.string,
    exceptions: PropTypes.array,
};
