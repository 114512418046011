import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Error, Replay } from '@material-ui/icons';

import './style.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_SOME_THING_WENT_WRONG,
    EZLOGIC_TITLE_TRY_RELOAD,
} from '../../../../../constants/language_tokens';

export const VirtualItemsErrorPlug = (props) => {
    const { getAbstractCapabilities, getAbstractsList, isAbstractsListLoading, isAbstractsCapabilitiesLoading } = props;
    const { t } = useTranslate();
    const getCapabilitiesAndAbstracts = () => {
        getAbstractCapabilities();
        getAbstractsList();
    };

    if (isAbstractsListLoading || isAbstractsCapabilitiesLoading) {
        return (
            <div className="virtual-items-error-plug">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="virtual-items-error-plug">
            <div className="error-icon">
                <Error />
            </div>
            <div>{t(EZLOGIC_TITLE_SOME_THING_WENT_WRONG)}</div>
            <div>{t(EZLOGIC_TITLE_TRY_RELOAD)}</div>
            <div className="reload-icon">
                <Replay onClick={getCapabilitiesAndAbstracts} />
            </div>
        </div>
    );
};
