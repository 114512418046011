import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';

import { FallbackMeshbotNodeComponent, withErrorBoundary } from '../../../../../components/HOCs/withErrorBoundary';
import TextInput from '../../components/TextInput';
import AccountTypeSelectWithInfo from '../../components/AccountTypeSelectWithInfo';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import SaveButton from '../../components/SaveButton/SaveButton';
import UsersActions from '../../../../../actions/UsersActions';
import {
    ACCOUNT_ADMIN_PERMISSION_ROLE,
    AUTO_COMPLETE_VALUES,
    CHANGE_USER_PASSWORD,
    FIELD_TYPES,
    SUCCESS,
} from '../../../../../constants/Users';
import PasswordIconAdornment from '../../components/PasswordIconAdornment';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import NewPasswordRequirements from '../../components/NewPasswordRequirements';
import ValidatedIcon from '../../components/ValidatedIcon';
import ResendEmailButton from '../../components/ResendEmailButton';

import styles from './styles.module.scss';
import { useTranslate } from '../../../../../features/languages';
import {
    EZLOGIC_TITLE_ACCOUNT_TYPE,
    EZLOGIC_TITLE_CHANGE_PASSWORD,
    EZLOGIC_TITLE_CONFIRM_PASSWORD,
    EZLOGIC_TITLE_CONTROLLERS,
    EZLOGIC_TITLE_EMAIL,
    EZLOGIC_TITLE_EMAIL_FIELD_DESCRIPTION,
    EZLOGIC_TITLE_FIRST_NAME,
    EZLOGIC_TITLE_LAST_NAME,
    EZLOGIC_TITLE_PASSWORD,
    EZLOGIC_TITLE_USER_NAME,
} from '../../../../../constants/language_tokens';

const UserForm = ({ isEditing, isLoggedInUser }) => {
    const { requestStatus, requestStatusMessage, isLoading } = useSelector(({ users }) => users);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { handleSubmit, values } = useFormikContext();
    const [showPassword, setShowPassword] = useState(false);
    const passwordType = showPassword ? FIELD_TYPES.TEXT : FIELD_TYPES.PASSWORD;
    const { t } = useTranslate();

    useEffect(() => {
        if (requestStatus === SUCCESS) {
            toast(requestStatusMessage, { type: TOAST_TYPE.SUCCESS });
            history.goBack();
        }

        return () => {
            dispatch(UsersActions.clearRequestStatus());
        };
    }, [requestStatus]);

    const handleRouteToPage = useCallback((type) => {
        if (type === CHANGE_USER_PASSWORD) {
            history.push(`${location.pathname}/changePassword`);
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit} autoComplete={t(AUTO_COMPLETE_VALUES.OFF)}>
            <div className={styles.userForm}>
                <TextInput required={true} name="FirstName" label={t(EZLOGIC_TITLE_FIRST_NAME)} />
                <TextInput required={true} name="Name" label={t(EZLOGIC_TITLE_LAST_NAME)} />
                {!isLoggedInUser && (
                    <AccountTypeSelectWithInfo
                        label={t(EZLOGIC_TITLE_ACCOUNT_TYPE)}
                        name="PK_PermissionRole"
                        required={true}
                    />
                )}

                {isEditing && isLoggedInUser && (
                    <>
                        <PhoneNumberInput />
                        <div>
                            <TextInput
                                name="Email"
                                label={t(EZLOGIC_TITLE_EMAIL)}
                                IconComponent={<ValidatedIcon name="Email" validated={values.EmailValidated} />}
                                FieldButtonComponent={!values.EmailValidated && <ResendEmailButton />}
                            />
                            <span className={styles.fieldInfo}>{t(EZLOGIC_TITLE_EMAIL_FIELD_DESCRIPTION)}</span>
                        </div>
                    </>
                )}

                {values?.PK_PermissionRole?.value &&
                    values.PK_PermissionRole.value !== ACCOUNT_ADMIN_PERMISSION_ROLE && (
                        <MultipleSelect label={t(EZLOGIC_TITLE_CONTROLLERS)} name="Controllers" isEditing={isEditing} />
                    )}

                {!isEditing && (
                    <>
                        <TextInput required={true} name="Username" label={t(EZLOGIC_TITLE_USER_NAME)} />
                        <TextInput required={true} name="Email" label={t(EZLOGIC_TITLE_EMAIL)} />

                        <div className={styles.passwordTitle}>{t(EZLOGIC_TITLE_PASSWORD)}</div>

                        <TextInput
                            required={true}
                            name="Password"
                            label={t(EZLOGIC_TITLE_PASSWORD)}
                            className={styles.passwordField}
                            otherArguments={{
                                type: passwordType,
                                InputProps: {
                                    autoComplete: t(AUTO_COMPLETE_VALUES.NEW_PASSWORD),
                                    endAdornment: (
                                        <PasswordIconAdornment
                                            showPassword={showPassword}
                                            setShowPassword={setShowPassword}
                                        />
                                    ),
                                },
                            }}
                        />

                        <TextInput
                            required={true}
                            name="ConfirmPassword"
                            label={t(EZLOGIC_TITLE_CONFIRM_PASSWORD)}
                            otherArguments={{
                                type: passwordType,
                            }}
                        />

                        <NewPasswordRequirements passwordValue={values.Password} />
                    </>
                )}

                {isEditing && isLoggedInUser && (
                    <div className={styles.changePasswordButton}>
                        <Button onClick={() => handleRouteToPage(CHANGE_USER_PASSWORD)} fullWidth variant="outlined">
                            {t(EZLOGIC_TITLE_CHANGE_PASSWORD)}
                        </Button>
                    </div>
                )}

                <SaveButton isDisabled={isLoading} />
            </div>
        </Form>
    );
};

export default withErrorBoundary(UserForm, FallbackMeshbotNodeComponent);
