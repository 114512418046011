import React from 'react';

import Info from '../../../../assets/icons/visuals/generalIcons/Info';
import {
    EZLOGIC_TITLE_STORAGE_LIMIT_INFO_TEXT,
    EZLOGIC_TITLE_UPLOAD_IMAGES_INFO_TEXT,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../languages';

import styles from '../UploadImagesDialog/styles.module.scss';

const UploadImagesDialogInfo = () => {
    const { t } = useTranslate();

    return (
        <div className={styles.infoBox}>
            <div>
                <Info role="icon" />
            </div>
            <div>
                <div>{t(EZLOGIC_TITLE_UPLOAD_IMAGES_INFO_TEXT)}</div>
                <div>{t(EZLOGIC_TITLE_STORAGE_LIMIT_INFO_TEXT)}</div>
            </div>
        </div>
    );
};

export default UploadImagesDialogInfo;
