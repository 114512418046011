import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { useTranslate } from '../../../languages';
import { LOGIN } from '../../../../constants/URLs';
import {
    EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED,
    EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED_MESSAGE,
    EZLOGIC_TITLE_GO_TO_LOGIN,
} from '../../../../constants/language_tokens';

import styles from './AccessRevoked.module.scss';

const AccessRevoked = () => {
    const history = useHistory();
    const { t } = useTranslate();

    const redirectToLoginPage = () => {
        history.push(LOGIN);
    };

    return (
        <div className={styles['access-revoked-container']}>
            <div className={styles.box}>
                <h1 className={styles['access-revoked-title']}>{t(EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED)}</h1>
                <p className={styles['access-revoked-paragraph']}>{t(EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED_MESSAGE)}</p>
                <Button variant="contained" color="primary" onClick={redirectToLoginPage}>
                    {t(EZLOGIC_TITLE_GO_TO_LOGIN)}
                </Button>
            </div>
        </div>
    );
};

export default AccessRevoked;
