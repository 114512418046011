import React from 'react';
import { Button } from '@material-ui/core';

import styles from './style.module.scss';
import { PlusIcon } from '../../../../../assets/icons';

const AddButton = ({ handleClick, label }) => {
    return (
        <Button
            startIcon={<PlusIcon />}
            color="primary"
            variant="contained"
            className={styles.newUserBtn}
            onClick={handleClick}
        >
            <span>{label}</span>
        </Button>
    );
};

export default AddButton;
