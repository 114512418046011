import React from 'react';
import { TextField } from '@material-ui/core';

import {
    DATA_TYPES_LIST,
    INPUT_TYPE_NUMBER,
    INPUT_TYPE_TEXT,
    VARIABLES_FIELDS_LABELS,
    INPUT_TYPE_NUMBER_STEP,
} from '../../../../../constants/Variables';

import { useTranslate } from '../../../../../features/languages';

import { variableBody } from './VariablesCommonDataTypes.module.scss';
import { EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE } from '../../../../../constants/language_tokens';

const VariablesCommonDataTypes = (props) => {
    const { code, dataType, onSetCode, disabled } = props;
    const isStringDataType = dataType && dataType === DATA_TYPES_LIST.TYPE_STRING;
    const isFloatDataType = dataType && dataType === DATA_TYPES_LIST.TYPE_FLOAT;
    const isBooleanDataType = dataType && dataType === DATA_TYPES_LIST.TYPE_BOOLEAN;

    const { t } = useTranslate();

    return (
        <TextField
            value={code}
            type={isStringDataType || isBooleanDataType ? INPUT_TYPE_TEXT : INPUT_TYPE_NUMBER}
            className={variableBody}
            variant="outlined"
            size="small"
            multiline={isStringDataType}
            label={t(VARIABLES_FIELDS_LABELS.VARIABLE_VALUE)}
            placeholder={isStringDataType ? '' : t(EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE)}
            onChange={(event) => onSetCode(event.target.value)}
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            inputProps={{
                step: isFloatDataType && INPUT_TYPE_NUMBER_STEP,
            }}
        />
    );
};

export default VariablesCommonDataTypes;
