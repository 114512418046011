import React from 'react';
import { LinearProgress } from '@material-ui/core';

import { lineProgress, lineProgressBox } from './styles.module.scss';

const BoxWithLinearProgress = ({ isLinearProgress, children }) => {
    return (
        <div className={lineProgressBox}>
            {isLinearProgress && <LinearProgress className={lineProgress} />}
            {children}
        </div>
    );
};

export default BoxWithLinearProgress;
