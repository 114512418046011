import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';

import Input from '../../../../../../../components/InputMaterial';
import InfoButton from '../../../../../EzloMeshbot/components/InfoButton';

import { formatNameWithUnderscores } from '../../../../../../../helpers/helpersMeshBot';
import * as plugin from '../../../../../../../constants/Plugins';

import style from '../RequestFields/requestFields.module.scss';

const InputString = (props) => {
    const { action, name, type, label, className, multiline, capabilityId, placeholder, onSetValueItem } = props;

    return (
        <FormControl className={`${style.requestFields} ${className}`}>
            <p className={style.requestFields__label}>{label ?? formatNameWithUnderscores(name)}</p>
            <Input
                label={formatNameWithUnderscores(name)}
                name={name}
                type={type}
                placeholder={placeholder}
                multiline={multiline}
                value={action[name] ?? ''}
                endAdornment={
                    name === plugin.PATTERN || name === plugin.INTERVAL ? (
                        <InfoButton>{plugin.info[name]}</InfoButton>
                    ) : null
                }
                onChange={(e) => onSetValueItem(e, capabilityId, action?.id, action?.type)}
            />
        </FormControl>
    );
};

InputString.propTypes = {
    action: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    capabilityId: PropTypes.string,
    onSetValueItem: PropTypes.func,
};

export default InputString;
