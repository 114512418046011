import * as localStorageKeys from '../../../../constants/localStorageKeys';
import oemManager from '../../../oem';
import axios from 'axios';
import {
    API_CLOUD_EZLO_DOWNLOAD_IMG_URL,
    API_CLOUD_EZLO_REQUEST_URL,
    API_CLOUD_EZLO_UPLOAD_FILE,
} from '../../../../constants/URLs';
import { CALL_VERSION } from '../../../../containers/Ezlo/EzloHubBackups/constants';
import { axiosCloudConfig } from '../../../../api/config';
import { getDownloadImgLinkParams, getFileExtension, getImgLinkParams } from '../utils';
import { MEDIA_BUCKET_NAME, MULTIMEDIA_TYPE, STORAGE_DELETE_CALL, STORAGE_LIST_CALL } from '../constants/network';

/**
 * Build headers for 'upload' Cloud API call for image files.
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+v3+-+Upload}
 * @param {File} file - img payload for upload
 * @returns {Object} API headers
 * */
const buildUploadImgHeaders = (file) => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);
    const fileExtension = getFileExtension(file);

    return {
        headers: {
            'Authorization': `Bearer ${cloudToken}`,
            'X-APP': oemManager.oem.getXApp(),
            'p-bucket': MEDIA_BUCKET_NAME,
            'p-type': MULTIMEDIA_TYPE,
            'p-file_format': fileExtension,
            'p-filename': file.name,
        },
    };
};
/**
 * Build payload for 'storage_list' Cloud API call with images filters.
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+-+List+Files+v.2}
 * @param {Object} filters - request filters
 * @returns {Object} payload
 * */
function buildGetImagesApiPayload(filters) {
    return {
        call: STORAGE_LIST_CALL,
        version: CALL_VERSION,
        params: {
            filters: {
                type: MULTIMEDIA_TYPE,
                bucket: MEDIA_BUCKET_NAME,
                ...filters,
            },
        },
    };
}

/**
 * Build payload for 'storage_delete' Cloud API call.
 * More info: {@link https://confluence.mios.com/display/EPD/Storage+-+Delete+Files}
 * @param {string[]} fileUuidList - array of uuids
 * @returns {Object} payload
 * */
function buildDeleteImgApiPayload(fileUuidList) {
    return {
        call: STORAGE_DELETE_CALL,
        params: {
            files: fileUuidList,
        },
    };
}

/**
 * Build link for image file.
 * Info with redirect: {@link https://confluence.mios.com/pages/viewpage.action?pageId=61999813#Storagev.37-_Examples_-Uploadwithredirectwithjson}
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+v3+-+Download}
 * @param {Object} imgData - img data
 * @returns {string} img link
 * */
export function buildImgLink({ uuid, file_format, key }) {
    const params = getImgLinkParams(uuid, file_format, key);

    return API_CLOUD_EZLO_DOWNLOAD_IMG_URL(params);
}

/**
 * Build link for download image file .
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+v3+-+Download}
 * @param {Object} imgData - img data
 * @returns {string} img link
 * */
export function buildDownloadImgLink(imgData) {
    const params = getDownloadImgLinkParams(imgData.uuid, imgData.key);

    return API_CLOUD_EZLO_DOWNLOAD_IMG_URL(params);
}

/**
 * Build 'upload' Cloud API call for image files.
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+v3+-+Upload}
 * @param {Object} imgData - img payload for upload
 * @param {File} file - file data
 * @returns {Promise<Object>} API request
 * */
export const uploadImageApi = (imgData, file) => {
    return axios.post(API_CLOUD_EZLO_UPLOAD_FILE(), imgData, buildUploadImgHeaders(file));
};

/**
 * Build 'storage_list' Cloud API call.
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+-+List+Files+v.2}
 * @param {Object} payload - payload for 'storage_list' call
 * @returns {Promise<Object>} API request
 * */
function getStorageListAPI(payload) {
    return axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload, axiosCloudConfig());
}

/**
 * Sends 'storage_list' Cloud API call with images filters.
 * More info: {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+-+List+Files+v.2}
 * @param {Object} filters - request filters
 * @returns {Promise<Object>} API response
 * */
export const getImagesFromCloud = async (filters) => {
    const payload = buildGetImagesApiPayload(filters);
    const response = await getStorageListAPI(payload);

    return response.data;
};

/**
 * Build 'storage_delete' Cloud API call.
 * More info: {@link https://confluence.mios.com/display/EPD/Storage+-+Delete+Files}
 * @param {Object} payload - payload for 'storage_delete' call
 * @returns {Promise<Object>} API request
 * */
function deleteFileApi(payload) {
    return axios.post(API_CLOUD_EZLO_REQUEST_URL(), payload, axiosCloudConfig());
}

/**
 * Sends 'storage_delete' Cloud API call.
 * More info: {@link https://confluence.mios.com/display/EPD/Storage+-+Delete+Files}
 * @param {string[]} fileUuidList - array of uuids
 * @returns {Promise<Object>} API response
 * */
export const deleteFileFromCloud = async (fileUuidList) => {
    const payload = buildDeleteImgApiPayload(fileUuidList);

    return await deleteFileApi(payload);
};
