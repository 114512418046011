import * as yup from 'yup';
import { ONLY_NUMBER_REGEX } from '../../../../../constants/regexConstants';

export const validationSchema = yup.object({
    name: yup.string().required('Pin name is required.'),
    pin: yup
        .string()
        .matches(ONLY_NUMBER_REGEX, 'Access code must be positive integer.')
        .min(4, 'Access code must have at least four numbers.')
        .required('Access code is required.'),
});
