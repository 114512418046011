import React from 'react';
import './style.scss';
import { Button, Dropdown, DropdownMenuItem, Input } from '../../../../../components';
import { deviceSettingFormatList, deviceSettingSizeList } from '../../../../../constants/Devices';

export default class DeviceConfigurator extends React.Component {
    constructor(props) {
        super(props);

        const { name, number, description, format, value, size } = props.configuratorData;

        this.state = {
            name: name || '',
            id: number || '',
            description: description || '',
            format: format || 'dec',
            value: value || value === 0 ? value : '',
            size: size || 1,
            typeOfValue: typeof value,
        };
    }

    saveHandler = () => {
        const { onSave, settings, itemKey } = this.props;
        const { name, id, description, format, value, size, typeOfValue } = this.state;
        let typedValue = value;

        if (typeOfValue === 'number') {
            typedValue = Number(value);
        }

        const data = {
            number: Number(id),
            name: name,
            size: size,
            value: typedValue,
            description: description,
            format: format,
        };

        if (settings._id) {
            onSave(settings._id, { ...data }, itemKey);
        }
    };
    onHandleInput = (e, name) => {
        const { value } = e.target;

        this.setState({ [name]: value });
    };

    selectRange = (format) => {
        this.setState({ format });
    };

    selectSize = (size) => {
        this.setState({ size });
    };

    render() {
        const { onGetBack, configuratorData, titles } = this.props;
        const { name, id, description, value, format, size } = this.state;

        return (
            <div className="device-alarm__wrapper">
                <h2 className="device-info__title">{titles.new_z_wave_parameter}</h2>
                <p className="device-alarm__text">{titles.check_device_manual}</p>
                <div className="device-configurator__box">
                    <Input
                        inputLabel={titles.parameter_name + ' *'}
                        value={name}
                        onChange={(e) => this.onHandleInput(e, 'name')}
                    />
                    <Input
                        inputLabel={titles.parameter_id + ' *'}
                        type="number"
                        min="1"
                        value={id}
                        onChange={(e) => this.onHandleInput(e, 'id')}
                    />
                    <Input
                        inputLabel={titles.description.toUpperCase()}
                        value={description}
                        onChange={(e) => this.onHandleInput(e, 'description')}
                    />
                    <div className="device-settings-row">
                        <div className="device-settings-row-name">{titles.display_format}</div>
                        <Dropdown className="select" value={format}>
                            {deviceSettingFormatList.map((item, index) => (
                                <DropdownMenuItem
                                    key={index}
                                    value={item.value}
                                    label={item.name}
                                    onClick={() => this.selectRange(item.value)}
                                />
                            ))}
                        </Dropdown>
                    </div>
                    <Input
                        inputLabel={titles.desired_value + ' *'}
                        value={value}
                        onChange={(e) => this.onHandleInput(e, 'value')}
                    />
                    <div className="device-settings-row">
                        <div className="device-settings-row-name">{titles.data_size}</div>
                        <Dropdown className="select" value={size}>
                            {deviceSettingSizeList.map((item, index) => (
                                <DropdownMenuItem
                                    key={index}
                                    value={item.value}
                                    label={item.name}
                                    onClick={() => this.selectSize(item.value)}
                                />
                            ))}
                        </Dropdown>
                    </div>
                </div>

                <div className="device-range-footer">
                    <p className="device-alarm__text">{titles.change_will_be_applied}</p>
                    <Button
                        className="btn-rounded device-range__button-save"
                        disabled={!name || !id || (!value && value !== 0)}
                        onClick={this.saveHandler}
                    >
                        {configuratorData ? titles.edit : titles.add}
                    </Button>
                </div>
                <div className="device-range-footer">
                    <Button className="btn-rounded device-range__button-save" onClick={onGetBack}>
                        {titles.back}
                    </Button>
                </div>
            </div>
        );
    }
}
