import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { isFileNameValid } from '../../features/MediaStoragePage/utils';

const useDropzoneWithPreviewState = (accept) => {
    const [filesData, setFilesData] = useState([]);
    const [notValidImgNames, setNotValidImgNames] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept,
        onDrop: (acceptedFiles) => {
            const notValidFileNames = [];
            const filteredImages = acceptedFiles.filter((file) => {
                const isValid = isFileNameValid(file.name);
                if (!isValid) {
                    notValidFileNames.push(file.name);
                }

                return isValid;
            });
            setNotValidImgNames(notValidFileNames);
            setFilesData(
                filteredImages.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            filesData.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [filesData],
    );

    const removeFile = (file) => () => {
        const newFiles = [...filesData];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFilesData(newFiles);
    };

    const clearDropzoneState = () => {
        setFilesData([]);
        setNotValidImgNames([]);
    };

    return {
        getInputProps,
        getRootProps,
        open,
        removeFile,
        notValidImgNames,
        filesData,
        clearDropzoneState,
    };
};

export default useDropzoneWithPreviewState;
