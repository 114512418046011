import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import DeviceGroupsActions from '../../../../../actions/DeviceGroupsActions';
import { DEVICES_TREE_OBJECT, MUI_PROPS } from '../../../../../constants/DevicesGroups';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_REVERT_CHANGES } from '../../../../../constants/language_tokens';
import styles from './DeviceTreeResetButton.module.scss';

const DeviceTreeResetButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const serial = useSelector((state) => state.ezlo.serial);
    const deviceList = useSelector((state) => state.ezlo.data[serial]?.devices);

    const handleResetDeviceGroup = () => {
        dispatch(DeviceGroupsActions.buildDeviceGroupTreeState(DEVICES_TREE_OBJECT, deviceList));
    };

    return (
        <Button
            className={styles.revertChangesButton}
            variant={MUI_PROPS.OUTLINED}
            startIcon={<ReplayIcon />}
            onClick={handleResetDeviceGroup}
        >
            {t(EZLOGIC_BUTTON_REVERT_CHANGES)}
        </Button>
    );
};

export default DeviceTreeResetButton;
