import React, { memo } from 'react';

import ActionTableCellBox from '../ActionTableCellBox';
import PinCodeButton from '../PinCodeButton';
import ChangePasswordButton from '../ChangePasswordButton';

import { buttonCell } from '../../style.module.scss';
// eslint-disable-next-line
const DefaultActionCells = ({ isLoggedIn, handleChangePasswordBtnClick, handlePinCodeBtnClick }) => {
    return (
        <>
            <ActionTableCellBox isDisplayingChildren={isLoggedIn} className={buttonCell}>
                <PinCodeButton handleClick={handlePinCodeBtnClick} />
            </ActionTableCellBox>
            <ActionTableCellBox className={buttonCell} isDisplayingChildren={isLoggedIn}>
                <ChangePasswordButton handleClick={handleChangePasswordBtnClick} />
            </ActionTableCellBox>
        </>
    );
};

export default memo(DefaultActionCells);
