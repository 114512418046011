import React from 'react';
import { EZLOGIC_TITLE_CLOUD } from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';
import './pluginCloud.scss';

const PluginCloud = () => {
    const { t } = useTranslate();

    return <div>{t(EZLOGIC_TITLE_CLOUD)}</div>;
};

export default PluginCloud;
