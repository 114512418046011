import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import VirtualDevicesCreatePage from './VirtualDevicesCreatePage';

const VirtualDevices = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}/create`} component={VirtualDevicesCreatePage} />
        </Switch>
    );
};

export default VirtualDevices;
