import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogContent, DialogContentText, Button } from '@material-ui/core';

import {
    EZLOGIC_BUTTON_CREATE,
    EZLOGIC_TITLE_CONFIGURE_YOUR_NEW_DEVICE,
} from '../../../../../../constants/language_tokens';
import * as plugin from '../../../../../../constants/Plugins';

import { useTranslate } from '../../../../../../features/languages';
import { removeEmptyFields, validateRequiredFields } from '../../../../EzloPlugins/utils';
import { PluginActions, IntegrationsActions } from '../../../../../../actions';
import { NUMBER } from '../../../../EzloMeshbot/constants';

import ConfigureFormField from '../../../../EzloPlugins/components/modalTemplates/ConfigureFormField';
import { CONTAINED } from '../../../../../../constants/MeshbotConstant';
import { PRIMARY } from '../../../../../../constants/Devices';
import hash from '../../../../../../constants/uniqueHash';
import { IcControlsClose } from '../../../../../../assets/icons';
import styles from '../../../../../../components/FullScreenDialog/styles.module.scss';
import { replaceConfigureToNewConfigure } from '../../../utils';

const EzloIntegrationsCreateNewDeviceFromPlugin = (props) => {
    const [configureInputs, setConfigureInputs] = useState([]);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState(null);
    const [errors, setErrors] = useState(null);
    const { currentCustomNode, currentSerial, onCancel, open } = props;
    const { t } = useTranslate();

    useEffect(() => {
        if (currentCustomNode.configuration) {
            const fields = currentCustomNode.configuration.inputs.map((item) => item.field);

            setInputs(
                fields.reduce((acc, field) => {
                    return { ...acc, [field]: '' };
                }, {}),
            );

            setConfigureInputs(
                currentCustomNode.configuration.inputs.map((item) => {
                    const data = {
                        id: hash(),
                        name: item.field,
                        type: item.type,
                        required: item.required,
                        label: item.name,
                        description: item.description,
                    };

                    if (item.hasOwnProperty(plugin.ENUM)) {
                        data.enumList = item.enum;
                    }

                    return data;
                }),
            );
        }
    }, [currentCustomNode]);

    const handlerSetConfigure = () => {
        if (Object.keys(validateRequiredFields(configureInputs, inputs)).length) {
            setErrors(validateRequiredFields(configureInputs, inputs));
        } else {
            if (currentCustomNode.configuration) {
                dispatch(PluginActions.setPluginIntegrationPending());

                const data = {
                    script: replaceConfigureToNewConfigure(currentCustomNode.configuration.script),
                    inputs: removeEmptyFields(inputs),
                };
                dispatch(PluginActions.setConfigurePlugins(currentSerial, data));
                dispatch(
                    PluginActions.subscribeUpdateConfigure(
                        currentSerial,
                        dispatch(IntegrationsActions.onBroadcastConfigureResponse(currentSerial)),
                    ),
                );
                dispatch(
                    PluginActions.subscribeBroadcastConfigure(
                        currentSerial,
                        dispatch(IntegrationsActions.onBroadcastCreatingDeviceByPluginResponse(currentSerial)),
                    ),
                );

                onCancel();
                setInputs(null);
                setErrors(null);
            }
            onCancel();
        }
    };
    const setInputsValue = (e) => {
        const { name, type, value } = e.target;

        setInputs({
            ...inputs,
            [name]: type === NUMBER && value ? Number(value) : type === NUMBER && !value ? '' : value,
        });
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            <span onClick={onCancel} className={styles.closeIconWrapper}>
                <IcControlsClose />
            </span>
            <DialogContent dividers={true}>
                <h2>{t(EZLOGIC_TITLE_CONFIGURE_YOUR_NEW_DEVICE)}</h2>
                <DialogContentText ref={descriptionElementRef}>
                    <ul>
                        {configureInputs.map((input) => {
                            return (
                                <ConfigureFormField
                                    key={input.id}
                                    input={input}
                                    inputs={inputs}
                                    error={errors && errors[input.name]}
                                    onSetInputsValue={setInputsValue}
                                />
                            );
                        })}
                    </ul>
                </DialogContentText>
                <div>
                    <Button variant={CONTAINED} color={PRIMARY} onClick={handlerSetConfigure}>
                        {t(EZLOGIC_BUTTON_CREATE)}
                    </Button>
                </div>
            </DialogContent>
        </>
    );
};

export default EzloIntegrationsCreateNewDeviceFromPlugin;
