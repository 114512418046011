import React from 'react';

import { Switch, IconButton } from '@material-ui/core';
import { PlayArrow, Delete, Edit, Cloud } from '@material-ui/icons';
import MenuUi from './MenuUi';
import './ListingUi/style.scss';
import navigationImg from '../../../assets/images/customization/navigationMenuTextColor.png';
import triggerActionbg from '../../../assets/images/customization/triggeractionbgcolor.png';
import primaryColor from '../../../assets/images/customization/primarycolor.png';
import primaryButton from '../../../assets/images/customization/primaryButton.png';

import MeshbotSerial from '../../../components/MeshbotSerial';
import { MESHBOT_TYPES } from '../EzloMeshbots/constants';
import ColorTheme from './ColorTheme';

import classes from './styles.module.css';
import { MENU_ITEMS_IDS, MENU_ITEMS_TITLE } from '../../Navigation/constants';
import NodesControllables from './NodesControllables';
import {
    EZLOGIC_TITLE_ACTIVE,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_EDGE_COMPUTER,
    EZLOGIC_TITLE_EDIT,
    EZLOGIC_TITLE_GROUP_NAME,
    EZLOGIC_TITLE_ID_MESHBOT,
    EZLOGIC_TITLE_MESHBOT_NAME,
    EZLOGIC_TITLE_MESHBOT_TYPE,
    EZLOGIC_TITLE_RUN_ONCE,
} from '../../../constants/language_tokens';
import {
    ControllerIcon,
    Dashboard,
    LeftMenuIntegrationIcon,
    LeftMenuSettingsIcon,
    LeftMenuSupportIcon,
    MeshbotIcon,
} from '../../../assets/icons';

export const ROWS_PER_PAGE = [10, 25, 50];
export const EZLOGIC_TITLE_NODES_SORT = 'ezlogic.title.nodes_sort';
export const EZLOGIC_TITLE_CONTROLLABLES_SORT = 'ezlogic.title.controllables_sort';
export const ACTION_LOCAL_NODES = 'actionLocalNodes';
export const TRIGGER_LOCAL_NODES = 'listNodesLocals';
export const TRIGGER_CLOUD_NODES = 'listNodesClouds';
export const ACTION_CLOUD_NODES = 'actionCloudNodes';
export const TRIGGER_COMPARE_PROPERTY = 'label';
export const ACTION_COMPARE_PROPERTY = 'name';
export const PRIMARY_COLOR_KEY = '--primary-color-theme';
export const HEX = 'hex';
export const PALETTE_COLOR = 'paletteColor';
export const COLORS = 'colors';

export const KVS_KEY = 'partner_custom';
export const KVS_USER_KEY = 'user_custom';
export const VERA_USER = 'user';
export const TICKET_DATA = 'ticket-data';
export const TICKET_USER = 'ticket_user';
export const PARTNER_UUID_KEY = 'partner-uuid';
export const PARTNER_TICKET_DATA = 'ticket-data';
// TODO: move permissions constants to permissions service layer
export const PARTNER_PERMISSION = 5;
export const OEM_MASTER_USER = 3;
export const INSTALLER_PERMISSION = 7;
export const ENDUSER_PERMISSION = 10;
export const MASTER_OEM = 3;
export const LOCATION = 'location';
export const CUSTOMIZATION = 'customization';
export const DISABLE = 1;
export const SETTING_INTEGRATION = 'settings_integrations';
export const VIRTUAL = 'virtual';
export const SETTINGS_PLUGIN = 'settings_plugins';
export const SETTINGS_PLUGIN_EDGE = 'settings_plugins_edge';
export const DASHBOARD = 'dashboard';
export const EZLOGIC_BUTTON_UPLOAD_LOGO = 'ezlogic.button.uploadlogo';
export const MESHBOT = 'meshbot';
export const AUTOMATION = 'automation';
export const EZLOGIC = 'ezlogic';
export const NOTIFICATION = 'notification';
export const HELP = 'help';
export const MESHBOT_AUTOMATION = 'Meshbot Automation';
export const OEM_NOTIFICATIONS = 'oem_notifications';
export const NOTIFICATIONS = 'notifications';
export const NOTIFICATIONS_LINK = 'ezlo/notifications';
export const OEM_SETTINGS = 'oem_settings';
export const ZERO = 0;
export const ENCODED_EMPTY_OBJECT = 'e30';
export const INCREMENT_BY_ONE = 1;
export const SPLIT_DOMAIN_BY_DOT = '.';
export const CUSTOMIZED_DOMAIN_LENGTH = 4;
export const NOT_VALID = -1;
export const DOT = '.';
export const MESHBOTS = 'MeshBots';
export const LISTING_UI_ID = 'id';
export const VERSION = '1';
export const HIDE = 'hide';
export const SUB_ITEMS = 'subItems';
export const CHECKED = 'checked';
export const CUSTOMIZATION_SAVED_SUCCESSFULLY = 'ezlogic.title.customization_data_saved_successfully';
export const CUSTOMIZATION_RESET_SUCCESSFULLY = 'ezlogic.title.customization_data_reset_successfully';
export const RESTORE_TO_DEFAULT_CUSTOMIZATION_TITLE = 'ezlogic.title.confirm_restore_default_value';
export const RESTORE_TO_DEFAULT_CUSTOMIZATION_TEXT = 'ezlogic.title.customization_changes_will_removed';
export const EZLOGIC_TITLE_SELECT_CUSTOMIZATION = 'ezlogic.title.select_customization';
export const EZLOGIC_NAVIGATION_MENU_TEXT_COLOR = 'ezlogic.title.navigation_text_color';

export const UUID_VALIDATION_REGEXP =
    /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
export const UUID_REGEXP = /([\w]+-[\w]+-[\w]+-[\w]+-[\w]+)/;

export const NOTIFICATION_NAME = 'Notification';
export const COLUMNS = [
    {
        field: 'id',
        headerName: EZLOGIC_TITLE_ID_MESHBOT,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        minWidth: 260,
        hide: true,
    },
    {
        field: 'name',
        headerName: EZLOGIC_TITLE_MESHBOT_NAME,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        minWidth: 280,
        hide: false,
        renderCell: (params) => {
            return (
                <div className={classes.meshbotCellIcon}>
                    <MeshbotIcon />
                    <span>{params.row.name}</span>
                </div>
            );
        },
    },
    {
        field: 'groupId',
        headerName: EZLOGIC_TITLE_GROUP_NAME,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        minWidth: 168,
        hide: false,
    },
    {
        field: 'type',
        headerName: EZLOGIC_TITLE_MESHBOT_TYPE,
        minWidth: 184,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        hide: false,
        renderCell: (params) => {
            return (
                <div className={`${classes.meshbotsCellIcon}`}>
                    <span className={classes.meshbotsCellIconType}>
                        {params.row.type === MESHBOT_TYPES.CLOUD || params.row.type === MESHBOT_TYPES.LOCAL
                            ? 'automation'
                            : params.row.type}
                    </span>
                    &ensp;
                    {params.row.type === MESHBOT_TYPES.CLOUD && (
                        <span
                            className={`${classes.meshbotsCellIconTypecloud} ${classes.meshbotsCellIconType} ${params.row.type}`}
                        >
                            <Cloud />
                        </span>
                    )}
                    {params.row.type === MESHBOT_TYPES.LOCAL && (
                        <span
                            className={`${classes.meshbotsCellIconTypelocal} ${classes.meshbotsCellIconType} ${params.row.type} `}
                        >
                            <ControllerIcon />
                        </span>
                    )}
                </div>
            );
        },
    },
    {
        field: 'serial',
        headerName: EZLOGIC_TITLE_EDGE_COMPUTER,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        minWidth: 196,
        hide: false,
        renderCell: (params) => {
            return <MeshbotSerial params={params.row} />;
        },
    },
    {
        field: 'active',
        headerName: EZLOGIC_TITLE_ACTIVE,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        sortable: false,
        minWidth: 110,
        width: 130,
        hide: false,
        align: 'center',
        renderCell: (params) => {
            return <Switch checked={params.id !== DISABLE} color="primary" />;
        },
    },
    {
        field: 'run',
        headerName: EZLOGIC_TITLE_RUN_ONCE,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        sortable: false,
        minWidth: 110,
        width: 130,
        hide: false,
        align: 'center',
        renderCell: (params) => {
            return (
                <IconButton color="primary" size="medium" disabled={params.id === DISABLE}>
                    <PlayArrow />
                </IconButton>
            );
        },
    },
    {
        field: 'edit',
        headerName: EZLOGIC_TITLE_EDIT,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        sortable: false,
        minWidth: 110,
        width: 130,
        hide: false,
        align: 'center',
        renderCell: (params) => {
            return (
                <IconButton color="primary" size="medium" disabled={params.id === DISABLE}>
                    <Edit />
                </IconButton>
            );
        },
    },
    {
        field: 'delete',
        headerName: EZLOGIC_TITLE_DELETE,
        headerClassName: 'grid-field',
        headerAlign: 'center',
        sortable: false,
        minWidth: 110,
        hide: false,
        width: 130,
        align: 'center',
        renderCell: (params) => {
            return (
                <IconButton color="primary" size="medium" disabled={params.id === DISABLE}>
                    <Delete />
                </IconButton>
            );
        },
    },
];
export const DEFAULT_COLUMNS = [
    {
        field: 'id',
        headerName: EZLOGIC_TITLE_ID_MESHBOT,
        hide: true,
    },
    {
        field: 'name',
        headerName: EZLOGIC_TITLE_MESHBOT_NAME,
        hide: false,
    },
    {
        field: 'groupId',
        headerName: EZLOGIC_TITLE_GROUP_NAME,
        hide: false,
    },
    {
        field: 'type',
        headerName: EZLOGIC_TITLE_MESHBOT_TYPE,
        hide: false,
    },
    {
        field: 'serial',
        headerName: EZLOGIC_TITLE_EDGE_COMPUTER,

        hide: false,
    },
    {
        field: 'active',
        headerName: EZLOGIC_TITLE_ACTIVE,

        hide: false,
    },
    {
        field: 'run',
        headerName: EZLOGIC_TITLE_RUN_ONCE,
        hide: false,
    },
    {
        field: 'edit',
        headerName: EZLOGIC_TITLE_EDIT,

        hide: false,
    },
    {
        field: 'delete',
        headerName: EZLOGIC_TITLE_DELETE,
        hide: false,
    },
];

export const ROW = [
    {
        id: 1,
        name: 'Weekly Sunset',
        groupId: 'not group',
        serial: '9000045',
        type: 'local',
        isDisabled: true,
    },
    {
        id: 2,
        name: 'Sunset light on',
        groupId: 'not group',
        serial: '9000045',
        type: 'local',
        isDisabled: true,
    },
    {
        id: 3,
        name: 'Trisensor motion',
        groupId: 'group',
        serial: '9000345',
        type: 'local',
        isDisabled: true,
    },
    {
        id: 4,
        name: 'Trisensormotionoff',
        groupId: 'group',
        serial: '9000017',
        type: 'cloud',
        isDisabled: true,
    },
    {
        id: 5,
        name: 'Global Sunset',
        groupId: 'not group',
        serial: '9000067',
        type: 'cloud',
        isDisabled: true,
    },
    {
        id: 6,
        name: 'asd',
        groupId: 'not group',
        serial: '9006345',
        type: 'cloud',
        isDisabled: true,
    },
    {
        id: 7,
        name: 'Trigger',
        groupId: 'not group',
        serial: '9001045',
        type: 'local',
        isDisabled: true,
    },
    {
        id: 8,
        name: 'scene2',
        groupId: 'not group',
        serial: '9009045',
        type: 'local',
        isDisabled: true,
    },
];

export const TAB_DATA = [
    ['ezlogic.title.menu_ui', <MenuUi />],
    ['ezlogic.title.color_theme', <ColorTheme />],
    ['ezlogic.title.nodes_controllables', <NodesControllables />],
];

export const defaultListingUi = {
    listingMeshbotName: 'Meshbots',
    listingColumns: DEFAULT_COLUMNS,
};

export const defaultMenuList = {
    menuName: '',
    logo: null,
    menuList: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            checked: true,
        },
        {
            id: 'meshbot',
            title: 'MeshBot Automation',
            checked: true,
            rename: true,
        },
        {
            id: 'expressions',
            title: 'Expressions',
            checked: true,
        },
        {
            id: 'variables',
            title: 'Variables',
            checked: true,
        },
        {
            id: 'luaScripts',
            title: 'Lua Scripts',
            checked: true,
        },
        {
            id: 'settings_integrations',
            title: 'NuCAL',
            checked: true,
        },
        {
            id: 'settings_plugins',
            title: 'Plugins',
            checked: true,
            subItems: [
                {
                    id: 'settings_plugins_edge',
                    title: 'Edge Plugins',
                    checked: true,
                },
            ],
        },

        {
            id: 'virtual',
            title: 'Virtual Container',
            checked: true,
        },
        {
            id: 'settings',
            title: 'Settings',
            checked: true,

            subItems: [
                {
                    id: 'settings_controllers',
                    title: 'Controllers',
                    checked: true,
                },
                {
                    id: 'settings_device_groups',
                    title: 'Device Groups',
                    checked: true,
                },
                {
                    id: 'settings_backups',
                    title: 'Backups',
                    checked: true,
                },
                {
                    id: 'settings_devices',
                    title: 'Devices',
                    checked: true,
                },
                {
                    id: 'device-associations',
                    title: 'Device Associations',
                    checked: true,
                },
            ],
        },
        {
            id: MENU_ITEMS_IDS.SYSTEM_HISTORY,
            title: 'Alert/Event History',
            checked: true,
        },

        {
            id: 'help',
            title: 'Help',
            checked: true,
        },
    ],
};

export const customizedMenuListIds = [
    { id: MENU_ITEMS_IDS.DASHBOARD, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.DASHBOARD },
    { id: MENU_ITEMS_IDS.AUTOMATION, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.AUTOMATION },
    { id: MENU_ITEMS_IDS.MESHBOTS, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.MESHBOTS },
    {
        id: MENU_ITEMS_IDS.ADVANCED_SCRIPTING,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.ADVANCED_SCRIPTING,
    },
    { id: MENU_ITEMS_IDS.VARIABLES, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.VARIABLES },
    { id: MENU_ITEMS_IDS.EXPRESSIONS, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.EXPRESSIONS },
    { id: MENU_ITEMS_IDS.LUA_SCRIPTS, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.LUA_SCRIPTS },
    { id: MENU_ITEMS_IDS.INTEGRATION, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.INTEGRATION },
    {
        id: MENU_ITEMS_IDS.INTEGRATION_NUCAL,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.INTEGRATION_NUCAL,
    },
    {
        id: MENU_ITEMS_IDS.GENERATOR_PLUGIN,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.GENERATOR_PLUGIN,
    },
    {
        id: MENU_ITEMS_IDS.INTEGRATION_VIRTUAL_CONTAINER,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.INTEGRATION_VIRTUAL_CONTAINER,
    },
    {
        id: MENU_ITEMS_IDS.VIRTUAL_DEVICES,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.VIRTUAL_DEVICES,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_PLUGINS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_PLUGINS,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_PLUGINS_EDGE,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_PLUGINS_EDGE,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_PLUGIN,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_PLUGIN,
    },
    { id: MENU_ITEMS_IDS.SETTINGS, show: false, rename: false, checked: true, title: MENU_ITEMS_TITLE.SETTINGS },
    {
        id: MENU_ITEMS_IDS.SETTINGS_CONTROLLERS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_CONTROLLERS,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_DEVICES,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_DEVICES,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_DEVICES_GROUPS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.DEVICE_GROUPS,
    },
    {
        id: MENU_ITEMS_IDS.DEVICE_ASSOCIATIONS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.DEVICE_ASSOCIATIONS,
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_BACKUPS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.SETTINGS_BACKUPS,
    },
    {
        id: MENU_ITEMS_IDS.CUSTOMIZATION,
        show: false,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.CUSTOMIZATION,
    },
    {
        id: MENU_ITEMS_IDS.SYSTEM_HISTORY,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.ALERT_EVENT_HISTORY,
    },

    { id: MENU_ITEMS_IDS.ACCOUNT, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.ACCOUNT },
    { id: MENU_ITEMS_IDS.MY_ACCOUNT, show: true, rename: false, checked: true, title: MENU_ITEMS_TITLE.MY_ACCOUNT },
    {
        id: MENU_ITEMS_IDS.ACCOUNT_USERS,
        show: true,
        rename: false,
        checked: true,
        title: MENU_ITEMS_TITLE.ACCOUNT_USERS,
    },
];
export const CUSTOMIZATION_DATA = {
    menuBar: {
        menuName: '',
        logo: null,
        menuList: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                checked: true,
                icon: <Dashboard />,
            },
            {
                id: 'meshbot',
                title: 'MeshBot Automation',
                checked: true,
                rename: true,
                icon: <MeshbotIcon />,
            },
            {
                id: 'integration',
                title: 'Integration',
                icon: <LeftMenuIntegrationIcon />,
                checked: true,
                subItems: [
                    {
                        id: 'settings_integrations',
                        title: 'NuCAL',
                        checked: true,
                    },
                    {
                        id: 'virtual',
                        title: 'Virtual Container',
                        checked: true,
                    },
                ],
            },
            {
                id: 'advancedScripting',
                title: 'Advanced Scripting',
                checked: true,
                subItems: [
                    {
                        id: 'variables',
                        title: 'Variables',
                        checked: true,
                    },
                    {
                        id: 'expressions',
                        title: 'Expressions',
                        checked: true,
                    },
                    {
                        id: 'luaScripts',
                        title: 'Lua Scripts',
                        checked: true,
                    },
                ],
            },
            {
                id: 'settings_plugins',
                title: 'Plugins',
                checked: true,
                subItems: [
                    {
                        id: 'settings_plugins_edge',
                        title: 'Edge Plugins',
                        checked: true,
                    },
                ],
            },
            {
                id: 'settings',
                title: 'Settings',
                checked: true,
                icon: <LeftMenuSettingsIcon />,
                subItems: [
                    {
                        id: 'settings_controllers',
                        title: 'Controllers',
                        checked: true,
                    },
                    {
                        id: 'settings_backups',
                        title: 'Backups',
                        checked: true,
                    },
                    {
                        id: 'settings_devices',
                        title: 'Devices',
                        checked: true,
                    },
                    {
                        id: 'settings_device_groups',
                        title: 'Device Groups',
                        checked: true,
                    },
                    {
                        id: 'device-associations',
                        title: 'Device Associations',
                        checked: true,
                    },
                ],
            },

            {
                id: 'help',
                title: 'Help',
                checked: true,
                icon: <LeftMenuSupportIcon />,
            },
        ],
    },
    listUi: {
        listingMeshbotName: 'Meshbots',
        listingColumns: COLUMNS,
    },
    meshbot: {
        triggers: [
            {
                id: 'deviceState',
                name: 'Device',
                value: 'deviceState',
                checked: true,
            },
            {
                id: 'dataAndTime',
                name: 'Date and Time',
                value: 'dataAndTime',
                checked: true,
            },
        ],

        actions: [
            {
                id: 'deviceState',
                name: 'Device',
                value: 'deviceState',
                checked: true,
            },
            {
                id: 'cloudNotification',
                name: 'Notification',
                value: 'cloudNotification',
                checked: true,
            },

            {
                id: 'dashboard',
                name: 'Dashboard',
                value: 'dashboard',
                checked: true,
            },
            {
                id: 'PAAS',
                name: 'NuCAL',
                value: 'PAAS',
                checked: true,
            },
        ],
    },
    signup: {
        logo: '',
    },
    login: {
        logo: '',
        title: '',
    },
};

export const colors = [
    { name: 'ezlogic.title.primary_theme_color', key: '--primary-color-theme', img: primaryColor },
    { name: 'ezlogic.title.navigation_text_color', key: '--navigation-menu-text-color', img: navigationImg },
    { name: 'ezlogic.title.trigger_action_bg_color', key: '--trigger-action-bgcolor', img: triggerActionbg },
    { name: 'ezlogic.title.primary_button_color', key: '--primary-button-color', img: primaryButton },
];
