import React from 'react';
import PropTypes from 'prop-types';

import InputString from '../InputString';
import HttpMethod from '../HttpMethod';
import RequestHeaders from '../RequestHeaders';

import * as generator from '../../../../../../../constants/DevicePluginGenerator';

import style from '../../templateCapability.module.scss';

const RequestFields = (props) => {
    const { action, capabilityId, onSetHeaders, onSetValueItem } = props;

    const createFieldRequest = (data) => {
        const [field] = data;
        switch (field) {
            case generator.HEADERS:
                return (
                    <RequestHeaders
                        key={field}
                        action={action}
                        capabilityId={capabilityId}
                        onSetHeaders={onSetHeaders}
                    />
                );
            case generator.METHOD:
                return (
                    <HttpMethod
                        key={field}
                        action={action}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                    />
                );

            case generator.URI:
                return (
                    <InputString
                        key={field}
                        action={action}
                        name={generator.URI}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                        label="IP Device Command"
                        placeholder="Put code body here..."
                        multiline={true}
                        className={style.templateCapability__actions_uri}
                    />
                );
            case generator.DATA:
                return (
                    <InputString
                        key={field}
                        action={action}
                        name={generator.DATA}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                        placeholder="{ level: {{VALUE}} }"
                        className={style.templateCapability__actions_data}
                    />
                );
            case generator.CONTENT_TYPE:
                return (
                    <InputString
                        key={field}
                        action={action}
                        name={generator.CONTENT_TYPE}
                        capabilityId={capabilityId}
                        onSetValueItem={onSetValueItem}
                        placeholder="application/json"
                        className={style.templateCapability__actions_contentType}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {Object.entries(action).map((item) => {
                return createFieldRequest(item);
            })}
        </>
    );
};

RequestFields.propTypes = {
    action: PropTypes.object,
    capabilityId: PropTypes.string,
    onSetHeaders: PropTypes.func,
    onSetValueItem: PropTypes.func,
};

export default RequestFields;
