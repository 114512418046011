import React from 'react';
import { useSelector } from 'react-redux';

import {
    getDeviceOrAccountNaming,
    getIntegrationDevicesCount,
    getIntegrationTypeIcon,
    getNuCalAccountsCount,
    getPrefixText,
    getPrefixType,
} from '../utils';

import { ADD_NEW_INTEGRATION, NUCAL } from '../../../../constants/Integrations';

import EzloIntegrationCardInfo from './EzloIntegrationCardInfo';
import EzloIntegrationCreateNewIntegrationCard from './EzloIntegrationCreateNewIntegrationCard';
import { useTranslate } from '../../../../features/languages';

const EzloIntegrationsItem = (props) => {
    const { integration, onClickInformationPage } = props;
    const { integrationType } = integration;
    const { uuid, id } = integration;
    const { t } = useTranslate();
    const integrationTypeIcon = getIntegrationTypeIcon(integration);
    const { data } = useSelector((state) => state.ezlo);
    const state = useSelector((state) => state);
    const { listInstalledPlugins = {} } = useSelector((state) => state.plugins);
    const integrationsWithAbstracts = useSelector((state) => state.integrations.userIntegrations);
    const handleClick = () => {
        onClickInformationPage(uuid, integrationType);
    };

    const count =
        integrationType === NUCAL
            ? getNuCalAccountsCount(integrationsWithAbstracts, uuid)
            : getIntegrationDevicesCount(integration, listInstalledPlugins, state, data, integrationType);

    const integrationName = integration.name || integration?.meta?.name;
    const icon = integration?.icon || integration?.meta?.config?.meta?.list_preview;
    const prefix = getPrefixText(integrationType, integration, t);
    const prefixType = getPrefixType(integrationType, integration);
    const integrationCountNaming = getDeviceOrAccountNaming(integrationType, count, t);

    return (
        <>
            {id !== ADD_NEW_INTEGRATION ? (
                <EzloIntegrationCardInfo
                    icon={icon}
                    handleClick={handleClick}
                    integrationName={integrationName}
                    integrationTypeIcon={integrationTypeIcon}
                    count={count}
                    integrationType={integrationType}
                    prefix={prefix}
                    prefixType={prefixType}
                    integrationCountNaming={integrationCountNaming}
                />
            ) : (
                <EzloIntegrationCreateNewIntegrationCard handleClick={handleClick} />
            )}
        </>
    );
};

export default EzloIntegrationsItem;
