import { useState } from 'react';
import useMeshBotsTableContext from '../useMeshBotsTableContext';
import { useDispatch } from 'react-redux';
import { MeshBotAction } from '../../../../../actions';

const useDeletingOfSelectedMeshbots = () => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { selectedMeshBotsNumber, selectedMeshBotsByType } = useMeshBotsTableContext();
    const dispatch = useDispatch();

    const handleClickDeleteButton = (e) => {
        e.stopPropagation();
        setIsConfirmModalVisible(true);
    };

    const handleConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            dispatch(MeshBotAction.deleteSelectedMeshBots(selectedMeshBotsByType));
        }
        setIsConfirmModalVisible(false);
    };

    return {
        isConfirmModalVisible,
        setIsConfirmModalVisible,
        showDeleteButton: Boolean(selectedMeshBotsNumber),
        handleClickDeleteButton,
        handleConfirmation,
    };
};

export default useDeletingOfSelectedMeshbots;
