import React, { memo } from 'react';
import { useFormikContext } from 'formik';

import DeviceFieldsRow from './DeviceFieldsRow';
import AddVirtualDeviceButton from './AddVirtualDeviceButton';

const VirtualDevices = ({ arrayHelpers }) => {
    const { values } = useFormikContext();

    return (
        <div>
            {values.virtualDevices.map((virtualDevice, key) => (
                <DeviceFieldsRow
                    key={virtualDevice.id}
                    virtualDevice={virtualDevice}
                    arrayHelpers={arrayHelpers}
                    virtualDeviceKey={key}
                />
            ))}
            <AddVirtualDeviceButton arrayHelpers={arrayHelpers} />
        </div>
    );
};

export default memo(VirtualDevices);
