import React from 'react';
import { useSelector } from 'react-redux';
import { LOCAL } from '../../../../../containers/Ezlo/EzloMeshbot/constant';
import MeshBotLabel from '../MeshBotLabel';

import styles from './styles.module.scss';

const MeshBotLabelsList = ({ onDelete, type }) => {
    const { localMetaLabels, cloudMetaLabels } = useSelector(({ meshBot }) => {
        return {
            localMetaLabels: meshBot.local.selectedMeshBotLabels,
            cloudMetaLabels: meshBot.cloud.selectedMeshBotLabels,
        };
    });
    const isLabelsVisible = type === LOCAL ? Boolean(localMetaLabels?.length) : Boolean(cloudMetaLabels?.length);

    const labels = type === LOCAL ? localMetaLabels : cloudMetaLabels;

    return (
        <>
            {isLabelsVisible && (
                <ul className={styles.labelsListWrapper}>
                    {labels.map((label) => (
                        <MeshBotLabel key={label.id} label={label} onDelete={onDelete} />
                    ))}
                </ul>
            )}
        </>
    );
};

export default MeshBotLabelsList;
