import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

import UsersActions from '../../../../../actions/UsersActions';

import { resendButton } from './style.module.scss';
import { useTranslate } from '../../../../../features/languages';
import { EZLOGIC_BUTTON_RESEND } from '../../../../../constants/language_tokens';

const ResendEmailButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const handleClick = () => {
        dispatch(UsersActions.resendEmail(undefined, t));
    };

    return (
        <div className={resendButton}>
            <Button onClick={handleClick} fullWidth variant="outlined">
                {t(EZLOGIC_BUTTON_RESEND)}
            </Button>
        </div>
    );
};

export default ResendEmailButton;
