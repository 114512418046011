import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const SpanToInput = (props) => {
    const { value, onChange, maxLength, disabled, ...rest } = props;
    const [isEditMode, setEditMode] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    const handleDivClick = () => {
        setEditMode(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleBlur = () => {
        setEditMode(false);

        if (!inputValue) {
            setInputValue(value);

            return;
        }

        if (inputValue !== value) {
            onChange(inputValue);
        }
    };

    return (
        <>
            {disabled ? (
                <TextField
                    {...rest}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    autoFocus={true}
                    inputProps={{ maxLength }}
                    variant="standard"
                    disabled={disabled}
                    fullWidth
                />
            ) : !isEditMode ? (
                <div {...rest}>
                    <span onClick={handleDivClick}>{inputValue}</span>
                </div>
            ) : (
                <TextField
                    {...rest}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    autoFocus={true}
                    inputProps={{ maxLength }}
                    variant="standard"
                    fullWidth
                />
            )}
        </>
    );
};

SpanToInput.defaultProps = {
    value: '',
    maxLength: 128,
};

SpanToInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
};

export default SpanToInput;
