import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

import * as generator from '../../../../../../../constants/DevicePluginGenerator';

import SelectComponent from '../../../../../EzloMeshbot/components/SelectComponent';

import style from '../RequestFields/requestFields.module.scss';
import template from '../../templateCapability.module.scss';

const HttpMethod = (props) => {
    const { action, capabilityId, onSetValueItem } = props;

    return (
        <div className={template.templateCapability__actions_method}>
            <p className={style.requestFields__label}>HTTP Method</p>
            <SelectComponent
                name={generator.METHOD}
                value={action?.method ?? ''}
                label="Method"
                onChange={(e) => onSetValueItem(e, capabilityId, action?.id)}
            >
                {generator.LIST_PROTOCOLS.map((protocol) => {
                    return (
                        <MenuItem key={protocol.id} value={protocol.value}>
                            {protocol.label}
                        </MenuItem>
                    );
                })}
            </SelectComponent>
        </div>
    );
};

HttpMethod.propTypes = {
    action: PropTypes.object,
    capabilityId: PropTypes.string,
    onSetValueItem: PropTypes.func,
};

export default HttpMethod;
