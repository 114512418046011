import React from 'react';

import InProgressIcon from '../icons/InProgressIcon';
import classNames from 'classnames';
import {
    EZLOGIC_TITLE_CREAING_BACKUP,
    EZLOGIC_TITLE_PROCESS_WILL_TAKE_3_5_MINUTES,
} from '../../../../constants/language_tokens';
import { useTranslate } from '../../../../features/languages';

const BackupCreateProgress = ({ progress }) => {
    const { t } = useTranslate();

    return (
        <div className="main-content">
            <span className="backup_progress">
                <InProgressIcon className="backup_progress__icon" />
                <span className="backup_progress__percentage">{progress + '%' || '0%'}</span>
            </span>
            <span className={classNames('text', 'title', 'animated-dots')}>{t(EZLOGIC_TITLE_CREAING_BACKUP)}..</span>
            <span className={classNames('text-auto-width', 'description')}>
                {t(EZLOGIC_TITLE_PROCESS_WILL_TAKE_3_5_MINUTES)}
            </span>
        </div>
    );
};

export default BackupCreateProgress;
