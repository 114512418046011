import React from 'react';
import './style.scss';
import { nameParser } from './index';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { optionsSelect } from '../../../../../constants/MeshbotConstant';

export default class DeviceHubSettingToken extends React.Component {
    state = {
        value: this.props.settings.value || '',
    };

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;
        const { settings: prevSettings } = prevProps;

        if (prevSettings.value !== settings.value) {
            this.setState({ value: settings.value });
        }
    }

    saveHandler = (item) => {
        const { onSave, settings } = this.props;
        const data = {
            name: settings.name,
            value: item,
        };

        onSave(data);
    };

    handleDropdownChange = (e) => {
        const { value } = e.target;
        this.setState(
            {
                value,
            },
            () => this.saveHandler(value),
        );
    };

    render() {
        const { settings } = this.props;
        const { value } = this.state;
        const { enum: enumList } = settings;
        const hasDeviceSettings = enumList && enumList.length;
        const inputLabel = nameParser(settings.name);

        return (
            <div className="device-hub-settings device-hub-settings__wrapper">
                <FormControl fullWidth variant="standard">
                    <InputLabel>{inputLabel}</InputLabel>
                    <Select
                        label={inputLabel}
                        value={value}
                        onChange={this.handleDropdownChange}
                        MenuProps={optionsSelect}
                    >
                        {hasDeviceSettings &&
                            enumList.map((item) => (
                                <MenuItem className="device-hub-settings__menu-item" key={item} value={item}>
                                    {nameParser(item)}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
}
