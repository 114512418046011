import React from 'react';
import { TextField } from '@material-ui/core';

import { VARIABLES_FIELDS_LABELS } from '../../../../../constants/Variables';

import { useTranslate } from '../../../../../features/languages';

import { variablesActionTypeInput } from './VariablesActionDataType.module.scss';

const VariablesActionDataType = (props) => {
    const { code, onChangeActionValue, disabled } = props;

    const { t } = useTranslate();

    return (
        <>
            <TextField
                type="text"
                className={variablesActionTypeInput}
                placeholder={t(VARIABLES_FIELDS_LABELS.ACTION.TEXT)}
                name="text"
                value={code?.text}
                variant="outlined"
                size="small"
                label={t(VARIABLES_FIELDS_LABELS.ACTION.TEXT)}
                onChange={onChangeActionValue}
                disabled={disabled}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                type="text"
                className={variablesActionTypeInput}
                placeholder={t(VARIABLES_FIELDS_LABELS.ACTION.LANGUAGE_TAG)}
                name="lang_tag"
                value={code?.lang_tag}
                variant="outlined"
                size="small"
                label={t(VARIABLES_FIELDS_LABELS.ACTION.LANGUAGE_TAG)}
                onChange={onChangeActionValue}
                disabled={disabled}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    );
};

export default VariablesActionDataType;
