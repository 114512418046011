import { getCachedDataFromStorage } from '../../../../../../../../helpers/mainActionUtils';
import {
    getAuthenticationType,
    getNucalMethods,
    getNucalTriggerMethods,
    getParjectGeneratedId,
    getPaths,
} from '../PaasAPI/paas-responce-extractors';

export const useParseIntegrationPreview = (parjectUuid) => {
    const { parsedData } = getCachedDataFromStorage();

    if (!parsedData) {
        return;
    }
    const { integrations_preview } = parsedData;

    const integrationPreviewResult = integrations_preview[parjectUuid];

    const paths = getPaths(integrationPreviewResult);
    const methods = getNucalMethods(integrationPreviewResult);
    const triggerMethods = getNucalTriggerMethods(integrationPreviewResult);
    const parjectGeneratedId = getParjectGeneratedId(integrationPreviewResult);
    const authenticationType = getAuthenticationType(integrationPreviewResult);

    return {
        paths,
        methods,
        triggerMethods,
        parjectGeneratedId,
        authenticationType,
        integrationPreviewResult,
    };
};
